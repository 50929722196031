

import { makeStyles, Theme, createStyles, Typography, TypographyProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { FormInputItem } from "../../../../stores/models/FormInputItem";
import { FormItemTypes, FormTextItem } from "../../../../stores/models/FormSection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        },
        required: {
            color: 'red'
        },
    }),
);

interface ElementTitleProps extends TypographyProps {
    item: FormInputItem | FormTextItem;
    bold?: boolean;
}

const ElementTitle = observer((props: ElementTitleProps) => {

    const classes = useStyles();

    const isRequired = props.item.type !== FormItemTypes.Text && props.item.required;
    return (
        <Typography className={props.bold ? classes.bold : undefined} {...props}>
            {props.item.title}{isRequired && <span className={classes.required}> *</span>}
        </Typography>
    );
});

export default ElementTitle;