import React from "react";
import { makeStyles, Theme, createStyles, InputAdornment, CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Alert as AlertIcon } from "mdi-material-ui";
import { Alert, AlertTitle } from "@material-ui/lab";
import useDebouncedEffect from "./Hooks/DebouncedEffect";
import EditableField, { EditableFieldProps } from "./EditableField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warningIcon: {
            color: theme.palette.warning.main
        },
        checkIcon: {
            color: theme.palette.primary.main
        },
        alert: {
            marginBottom: theme.spacing(1)
        },
        bold: {
            fontWeight: 'bold'
        }
    })
);

interface DebouncedInputFieldProps extends EditableFieldProps {
    warning?: { title: string, message: JSX.Element };
    loading: boolean;
    validityCheck: (value: string) => boolean;
    debouncedRequest: (value: string) => Promise<any> | undefined;
}

const DebouncedInputField = observer((props: DebouncedInputFieldProps) => {

    const classes = useStyles();

    /********** Effects *********/

    const debouncedEffect = useDebouncedEffect({
        trigger: props.value,
        effect: () => {
            makeRequest();
        }
    });

    /********** Helper methods *********/

    const makeRequest = () => {
        props.debouncedRequest(props.value);
    }

    /********** Render *********/

    return (
        <React.Fragment>
            {props.warning
                ? <Alert severity="warning" className={classes.alert}>
                    <AlertTitle>{props.warning.title}</AlertTitle>
                    {props.warning.message}
                </Alert>
                : null
            }
            <EditableField
                {...props}
                TextFieldProps={{
                    ...props.TextFieldProps,
                    InputProps: {
                        endAdornment: props.validityCheck(props.value)
                            ? <InputAdornment position="end">
                                {props.loading
                                    ? <CircularProgress size={20} />
                                    : props.warning === undefined
                                        ? <CheckCircleIcon className={classes.checkIcon} />
                                        : <AlertIcon className={classes.warningIcon} />
                                }
                            </InputAdornment>
                            : null,
                        ...props.TextFieldProps?.InputProps,
                    }
                }}
            />
        </React.Fragment>
    )
});

export default DebouncedInputField;