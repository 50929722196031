import { TextField, makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { StepProps } from "../../../../stores/models/Step";
import { StubOrganizationValidationChecks, OrganizationType } from "../../../../stores/models/StubOrganization";
import TermsOfServiceAcceptance from "../Shared/TermsOfServiceAcceptance";
import { StubUser } from "../../../../stores/models/StubUser";
import TextFieldWithCharacterLimit from "../../../Shared/TextFieldWithCharacterLimit";
import { ORGANIZATION_MISSION_MAX_LENGTH, ORGANIZATION_DESCRIPTION_MAX_LENGTH } from "../../../../logic/ValidationChecks/FieldLengths";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
            width: '50ch',
            transition: theme.transitions.create('width'),
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
                flexGrow: 1,
            }
        },
        title: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(3)
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                alignItems: 'center'
            }
        },
        termsOfService: {
            color: theme.palette.text.secondary,
        }
    }),
);

const OrganizationDetails = observer((props: StepProps<StubUser>) => {

    const stubUser = props.stepState.stepObject;
    const stubOrganization = stubUser.stubOrganization!;

    /***** React hooks *****/

    const classes = useStyles();

    /***** Effects *****/

    useEffect(() => {
        // Update which validation checks should be run for the stub organization and stub user
        stubOrganization.setValidationChecks(StubOrganizationValidationChecks.OrganizationDetails);
    }, []);

    useEffect(() => {
        if (props.stepState.validationRun) {
            // After validation is run, mark the fields as dirty so that errors will be displayed
            stubOrganization.setFieldsDirty(['mission', 'description', 'website']);
            if (stubOrganization.type === OrganizationType.Nonprofit) {
                stubUser.setFieldsDirty(['termsOfServiceAccepted']);
            }
        }
    }, [props.stepState.validationRun]);

    /***** Event handlers *****/

    const handleMissionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        stubOrganization.setMission(event.target.value);
    }

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        stubOrganization.setDescription(event.target.value);
    }

    const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        stubOrganization.setWebsite(event.target.value);
    }

    /***** Helper constants *****/

    const validationRun = props.stepState.validationRun;
    const missionError = validationRun ? stubOrganization.errors.mission : '';
    const descriptionError = validationRun ? stubOrganization.errors.description : '';
    const websiteError = validationRun ? stubOrganization.errors.website : '';

    /***** Render *****/

    return (
        <div>
            <Typography variant="h5" className={classes.title}>Give volunteers some information about your organization.</Typography>
            <div className={classes.flex}>
                {stubOrganization.isForProfit
                    ? null
                    : <TextFieldWithCharacterLimit
                        characterLimit={ORGANIZATION_MISSION_MAX_LENGTH}
                        TextFieldProps={{
                            id: "standard-mission-input",
                            label: "Mission",
                            variant: "outlined",
                            required: true,
                            disabled: props.stepState.loading,
                            value: stubOrganization.mission,
                            onChange: handleMissionChange,
                            autoFocus: true,
                            multiline: true,
                            rows: 2,
                            rowsMax: 5,
                            className: classes.textField,
                            error: missionError.length > 0,
                            helperText:
                                missionError.length > 0
                                    ? missionError
                                    : "In a sentence or two, explain your organization's aim."
                        }}
                    />
                }
                < TextFieldWithCharacterLimit
                    characterLimit={ORGANIZATION_DESCRIPTION_MAX_LENGTH}
                    TextFieldProps={{
                        id: "standard-description-input",
                        label: "Description",
                        variant: "outlined",
                        required: true,
                        multiline: true,
                        rows: 4,
                        rowsMax: 10,
                        value: stubOrganization.description,
                        onChange: handleDescriptionChange,
                        className: classes.textField,
                        disabled: props.stepState.loading,
                        error: descriptionError.length > 0,
                        helperText:
                            descriptionError.length > 0
                                ? descriptionError
                                : stubOrganization.isForProfit
                                    ? "Describe what your organization does."
                                    : "Add some more details about what your organization does."
                    }}
                />
                <TextField
                    id="standard-website-input"
                    label="Website"
                    variant="outlined"
                    value={stubOrganization.website}
                    onChange={handleWebsiteChange}
                    className={classes.textField}
                    disabled={props.stepState.loading}
                    error={websiteError.length > 0}
                    helperText={websiteError.length > 0
                        ? websiteError
                        : "If your organization has a webpage, you can add its link here."}
                />
                {stubOrganization.type === OrganizationType.Nonprofit
                    ? <div className={classes.termsOfService}>
                        <TermsOfServiceAcceptance
                            checked={stubUser.termsOfServiceAccepted}
                            onChange={(accepted) => stubUser.setTermsOfServiceAccepted(accepted)}
                            error={validationRun ? stubUser.getErrorForField('termsOfServiceAccepted') : undefined}
                            organizationTerms
                        />
                    </div>
                    : null
                }
            </div>
        </div>
    );
});

export default OrganizationDetails;