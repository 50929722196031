import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ListItemAvatar as MUIListItemAvatar, ListItemAvatarProps as MUIListItemAvatarProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '3.5rem',
        },
    })
);

interface ListItemAvatarProps extends MUIListItemAvatarProps {

}

const REMListItemAvatar = observer((props: ListItemAvatarProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIListItemAvatar
            {...props}
            classes={{
                ...props.classes,
                root: clsx(classes.root, props.classes?.root),
            }}
        >
            {props.children}
        </MUIListItemAvatar>
    );
});

export default REMListItemAvatar;