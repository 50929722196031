export const ShiftLabels: Record<number, string> = {
    [1711]: "Floater",
    [1715]: "Set Up",
    [1716]: "Set Up",
    [1717]: "Volunteer Check-in Table",
    [1718]: "Event Tabler Check-in",
    [1719]: "Outdoor Stage Assistance",
    [1720]: "Indoor Stage Assistance",
}

export const DefaultShiftListViewOppIds = [684];