import { action, makeObservable, observable } from "mobx";
import { DialogState } from "../../../../stores/models/DialogState";

export class ConfirmationDialogState extends DialogState {
    @observable confirmed: boolean | undefined;

    constructor(open?: boolean) {
        super(open);
        makeObservable(this);
    }

    @action setConfirmed(confirmed: boolean) {
        this.confirmed = confirmed;
    }

    @action reset() {
        this.confirmed = undefined;
    }
}