import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Select as MUISelect, SelectProps as MUISelectProps } from "@material-ui/core";
import clsx from "clsx";
import { ForwardedRef, forwardRef } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            top: 'calc(50% - 0.75rem)'
        },
        select: {
            paddingRight: '1.5rem !important'
        },
        outlined: {
            paddingRight: '2rem !important'
        }
    })
);

interface SelectProps extends MUISelectProps {

}

const REMSelect = observer(forwardRef((props: SelectProps, ref: ForwardedRef<HTMLElement>) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUISelect
            {...props}
            ref={ref}
            classes={{
                ...props.classes,
                icon: clsx(classes.icon, props.classes?.icon),
                select: clsx(classes.select, props.classes?.select),
                outlined: clsx(classes.outlined, props.classes?.outlined),
            }}
        >
            {props.children}
        </MUISelect>
    );
}));

export default REMSelect;