import { action, computed, makeObservable, observable, reaction } from "mobx";
import { RootStore } from "../../../../../stores/RootStore";
import { TablePaginationState } from "../../../../../stores/models/TablePaginationState";
import { TableSortingState } from "../../../../../stores/models/TableSortingState";
import { FilterState } from "../../../../../stores/models/FilterState";
import { Option } from "../../../../../stores/models/Option";
import { IDisposer, deepObserve } from "mobx-utils";
import { NewOptionCollection, ObjectWithIdentifier, IdentityKey } from "../../../../../stores/models/NewOptionCollection";
import { IAPIGetRequestParameters } from "../../../../../stores/models/APIGetRequestParameters";

/*
 * Maintains a collection of selectable records and handles updating the pagination state and display window.
 */
export abstract class RecordListStore<RecordType extends ObjectWithIdentifier<RecordType>, SortableKeys extends string = Extract<keyof RecordType, string>> {
    @observable total?: number;
    @observable collection = new NewOptionCollection(this.getRecordIdentityKey(), [] as RecordType[]);
    @observable paginationState = new TablePaginationState();
    @observable sortingState = new TableSortingState<SortableKeys>();
    @observable filters = new FilterState();
    @observable unloadedSelected: boolean = false;
    @observable recordsToDisplay: Option<RecordType>[] = [];
    @observable reloadNeeded: boolean = false;

    private optionsChangedReaction?: IDisposer;
    private pageChangedReaction?: IDisposer;
    private rowsPerPageChangedReaction?: IDisposer;
    private sortingChangedReaction?: IDisposer;
    private totalChangedReaction?: IDisposer;

    rootStore = {} as RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
        this.setupReactions();
    }

    abstract getRecordIdentityKey(): IdentityKey<RecordType>;

    @action private setupReactions() {
        this.optionsChangedReaction = deepObserve(this.collection, () => {
            this.updateRecordsToDisplay();
        });

        this.sortingChangedReaction = reaction(
            () => [this.sortingState.stringifiedSortConfiguration],
            () => { this.resetRecords(); }
        );
        this.rowsPerPageChangedReaction = reaction(
            () => [this.paginationState.rowsPerPage],
            () => { this.paginationState.setPage(0) }
        );
        this.pageChangedReaction = reaction(
            () => [
                this.paginationState.page,
                this.paginationState.rowsPerPage
            ],
            () => {
                this.updateRecordsToDisplay();
            }
        );
        this.totalChangedReaction = reaction(
            () => [this.total],
            () => {
                this.stayWithinMaxPage();
            }
        )
    }

    @action private updateRecordsToDisplay() {
        const page = this.paginationState.page;
        const recordsPerPage = this.paginationState.rowsPerPage;
        this.recordsToDisplay = this.collection.options.slice(page * recordsPerPage, page * recordsPerPage + recordsPerPage);
    }

    @action private stayWithinMaxPage() {
        if (this.paginationState.page > this.maxPage) {
            this.paginationState.setPage(this.maxPage);
        }
    }

    @action setTotal(total: number) {
        this.total = total;
    }

    @action setUnloadedSelected(unloadedSelected: boolean) {
        this.unloadedSelected = unloadedSelected;
    }

    @action setReloadNeeded(reloadNeeded: boolean) {
        this.reloadNeeded = reloadNeeded;
    }

    @action reset() {
        this.sortingState = new TableSortingState();
        this.filters = new FilterState();
        this.resetRecords(true);
    }

    @action resetRecords(maintainPage?: boolean) {
        if (!maintainPage) {
            this.resetPage();
        }
        this.collection.clearOptions();
        this.setUnloadedSelected(false);
    }

    @action private resetPage() {
        this.paginationState.setPage(0);
    }

    @computed private get maxPage() {
        if (this.total === undefined) return 0;
        return Math.max(Math.ceil(this.total / this.paginationState.rowsPerPage) - 1, 0);
    }

    @computed private get numUnloadedRecords() {
        if (this.total === undefined) return;
        return this.total - this.collection.options.length;
    }

    @computed get numSelectedIncludingUnloaded() {
        if (this.unloadedSelected) {
            const unloadedRecords = this.numUnloadedRecords || 0;
            return this.collection.selectedOptions.length + unloadedRecords;
        } else {
            return this.collection.selectedOptions.length;
        }
    }

    @computed private get selectionType(): 'exclude' | 'include' {
        return (this.unloadedSelected || this.collection.selections.length === 0) ? 'exclude' : 'include';
    }

    @computed get selectionCriteria() {
        if (this.selectionType === 'exclude') {
            return {
                ids: this.unloadedSelected
                    ? this.collection.options.filter(option => !option.selected).map(option => option.object[this.collection.identifier])
                    : [],
                type: this.selectionType,
                filters: this.filters
            }
        } else {
            return {
                ids: this.collection.selectedOptions.map(selection => selection[this.collection.identifier]),
                type: this.selectionType
            }
        }
    }

    /***** Record loading *****/ 

    @computed get hasRecordsToLoad() {
        return this.numUnloadedRecords === undefined || this.numUnloadedRecords > 0;
    }

    @computed get loadRequest(): IAPIGetRequestParameters<SortableKeys> {
        return {
            limit: this.paginationState.rowsPerPage,
            offset: this.paginationState.offset,
            sort: this.sortingState.sortConfiguration,
            filterState: this.filters
        }
    }

    @computed get requestingUnloadedRecords() {
        return (this.hasRecordsToLoad) &&
            (this.loadRequest.offset + this.loadRequest.limit > this.collection.options.length);
    }

    // Brought in from TableController
    // TODO: Determine where it makes sense for this to be implemented. Perhaps in NewOptionCollection?

    /*** Merging Records ***/

    // @action async mergeSelectedRecords(mergedRecord: MergedRecord) {
    //     if (this.records.selectedOptions.length !== 2 || this.mergeRecords === undefined) return;

    //     const id1 = this.records.selectedOptions[0][this.records.identifier];
    //     const id2 = this.records.selectedOptions[1][this.records.identifier];
    //     const response = await this.mergeRecords(id1, id2, mergedRecord);
    //     if (response) {
    //         this.removeSelectedRecords();
    //     }
    //     return response;
    // }
}