import { useEffect, useState } from 'react';

interface PromiseResolverProps<T> {
    promise: () => Promise<T>;
}

export default function usePromiseResolver<T>(props: PromiseResolverProps<T>) {

    const [result, setResult] = useState<T>();
    const [loading, setLoading] = useState(true);

    const resolvePromise = async () => {
        setLoading(true);
        const response = await props.promise();
        setResult(response);
        setLoading(false);
    }

    useEffect(() => {
        resolvePromise();
    }, [])

    return { result, loading };
}