import React, { Fragment, useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, Grid, Paper, Container, Typography, IconButton, TextField, MenuItem } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext } from "../../../../../stores";
import { useParams } from "react-router-dom";
import { getVolunteersLink } from "../../../../Navigation/Links/UrlConstructors";
import LoadingIndicator from "../../../../Shared/LoadingIndicator";
import FormError from "../../../../Organization/VolunteerOpportunities/FormError";
import FieldHeader from "../../../../Shared/FieldHeader";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import InactiveSubscriptionMessage from "../Subscription/InactiveSubscriptionMessage";
import ZeroState from "../../../../Shared/ZeroState";
import VolunteerUpcomingShiftsTable from "./VolunteerUpcomingShiftsTable";
import { VolunteerDetails } from "../../../../../stores/models/VolunteerDetails";
import Editor from "../../../Editor";
import EditorHeaderButtons from "../EditorHeaderButtons";
import EditableField from "../../../../Shared/EditableField";
import { VOLUNTEER_NAME_MAX_LENGTH } from "../../../../../logic/ValidationChecks/FieldLengths";
import { DateFormatter } from "../../../../../logic/DateFormatter";
import { CakeVariant, Email } from "mdi-material-ui";
import { Permission } from "../../../../../stores/models/Permission";
import { Role } from "../../../../../stores/models/Role";
import EmailInUseWarningField from "./EmailInUseWarningField";
import FormLoadingSpinner from "../../../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import { Shift } from "../../../../../stores/models/Shift";
import ServiceDataPresenter from "../../../../../modules/volunteer-hours/components/ServiceDataPresenter";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import REMSelect from "../../../../../shared/modules/rem-conversion/components/Select";
import REMAutocomplete from "../../../../../shared/modules/rem-conversion/components/Autocomplete";
import REMChip from "../../../../../shared/modules/rem-conversion/components/Chip";
import REMKeyboardDatePicker from "../../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%'
        },
        contentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        paper: {
            paddingTop: '15px',
            paddingBottom: '15px',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word'
        },
        section: {
            paddingTop: '15px'
        },
        floatingButtonWrapper: {
            display: 'flex',
            flexDirection: 'column'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            wordBreak: 'break-word'
        },
        mobileBreadcrumbs: {
            marginBottom: theme.spacing(2),
            display: 'flex'
        },
        backButton: {
            padding: 0
        },
        dropdown: {
            minWidth: 200
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        chip: {
            background: theme.palette.primaryBackground.main,
            marginBottom: theme.spacing(.5),
            '&:not(:last-child)': {
                marginRight: theme.spacing(.5)
            }
        },
        detail: {
            marginBottom: theme.spacing(2)
        },
        detailLine: {
            display: 'flex',
            '&:not(:first-child)': {
                marginTop: theme.spacing(1)
            }
        },
        textField: {
            marginRight: theme.spacing(2)
        },
        icon: {
            marginRight: theme.spacing(1.5),
            color: theme.palette.action.disabled
        },
        birthdate: {
            paddingTop: 3
        },
        relative: {
            position: 'relative'
        }
    })
);

const VOLUNTEER_DETAILS_ID = 'volunteer-details';

const VolunteerDetailsPage = observer(() => {

    /********* React hooks *********/
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const volunteerStore = rootStore.volunteerStore;
    const shiftStore = rootStore.shiftStore;
    const organization = rootStore.userStore.user.organization;
    const organizationPermissions = organization?.permissions || [];
    const organizationRoles = organization?.roles || [];
    const organizationVolunteerStatuses = organization?.volunteerStatuses || [];
    const { volId } = useParams() as { volId: string };
    const navigate = useNavigateInternally();

    /********* State *********/
    const parsedVolId = parseInt(volId || "");
    const parsedOrgId = organization?.id || -1;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialLoad, setIsInitialLoad] = React.useState(true);
    const [hasErrors, setHasErrors] = React.useState(false);
    const [upcomingShiftsTotal, setUpcomingShiftsTotal] = React.useState(0);
    const [shiftsToDisplay, setShiftsToDisplay] = React.useState<Shift[]>([]);

    const [volunteerDetails, setVolunteerDetails] = React.useState<VolunteerDetails>();
    const [draft, setDraft] = React.useState<VolunteerDetails>();
    const [editing, setEditing] = React.useState(false);

    /********* Effects *********/

    useEffect(() => {
        sendGetVolunteerRequest();
    }, [parsedVolId, parsedOrgId]);

    useEffect(() => {
        if (volunteerDetails) {
            setDraft(new VolunteerDetails(volunteerDetails));
        }
    }, [volunteerDetails]);

    useEffect(() => {
        if (!editing && volunteerDetails) {
            setDraft(new VolunteerDetails(volunteerDetails));
        }
    }, [editing]);

    /********* API Requests *********/

    // Request the opportunity matching the id in the URL
    const sendGetVolunteerRequest = async () => {
        setIsInitialLoad(true);
        const volunteerData = await volunteerStore.getOrganizationVolunteerInfo(parsedVolId);
        if (volunteerData) {
            setVolunteerDetails(volunteerData.volunteerDetails);
            setShiftsToDisplay(volunteerData.volunteerDetails.upcomingShifts);
            setUpcomingShiftsTotal(volunteerData.shiftTotal);
            // TODO: Decide how to handle this:
            // setServiceEntriesToDisplay(volunteerData.volunteerDetails.serviceEntries);
            // setServiceEntriesTotal(volunteerData.serviceEntriesTotal)
        }
        setIsInitialLoad(false);
    }

    const loadVolunteerShifts = async (limit: number, offset: number) => {
        const response = await shiftStore.getShiftsForOrganizationVolunteer(parsedVolId, { limit: limit, offset: offset });
        if (response?.results) {
            volunteerDetails?.setShifts(response.results);
            if (volunteerDetails?.upcomingShifts) {
                setShiftsToDisplay(volunteerDetails?.upcomingShifts);
            }
            setUpcomingShiftsTotal(response.total);
        }
    }

    /********* Helper methods *********/

    const returnToVolunteersTable = () => {
        navigate(getVolunteersLink());
    }

    /********* Event handlers *********/

    const onEditDetails = () => {
        setEditing(!editing);
    }

    const onSaveDetails = async () => {
        if (draft && draft.validated) {
            setHasErrors(false);
            setIsLoading(true);
            const response = await volunteerStore.updateOrganizationVolunteerDetails(draft);
            if (response) {
                setVolunteerDetails(response);
                setEditing(!editing);
                // TODO: Don't apply the shifts and service entries from the updated details
            }
            setIsLoading(false);
        } else {
            setHasErrors(true);
        }
    }

    const onSelectedStatusChanged = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        draft?.setStatus(event.target.value as string);
    }

    const onRegistrationFormStatusChanged = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        draft?.setRegistrationFormStatus(event.target.value as string);
    }

    const onPermissionsChanged = (value: Permission[]) => {
        draft?.setPermissions(value);
    }

    const onRolesChanged = (value: Role[]) => {
        draft?.setRoles(value);
    }

    const handleBirthdateChange = (date: Date | null) => {
        draft?.volunteer.setBirthdate(date ? date.toUTCString() : undefined);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        draft?.setEmail(event.target.value);
    }

    /********* Render *********/

    if (isInitialLoad) {
        return <LoadingIndicator />;
    }

    return (
        <div className={classes.root} id={VOLUNTEER_DETAILS_ID}>
            {!userStore.user.active
                ? <ZeroState content={
                    <Container>
                        <InactiveSubscriptionMessage content={'Resubscribe to view your volunteers.'}
                        />
                    </Container>
                }
                />
                : <div className={classes.contentWrapper}>
                    {/* Breadcrumbs */}
                    <Container>
                        <div className={classes.mobileBreadcrumbs}>
                            <IconButton className={classes.backButton} onClick={returnToVolunteersTable}>
                                <NavigateBeforeIcon />
                            </IconButton>
                            <REMChip label={`Back to Volunteers`} onClick={returnToVolunteersTable} />
                        </div>
                    </Container>
                    {/* Main Details */}
                    <Container>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.floatingButtonWrapper}>
                                    <Paper className={classes.paper}>
                                        {volunteerDetails
                                            ? <Container>
                                                {editing && hasErrors ? <FormError /> : null}
                                                {/* Name */}
                                                <div className={classes.header}>
                                                    <Typography variant="h4">
                                                        {volunteerDetails.volunteer.fullName}
                                                    </Typography>
                                                    <EditorHeaderButtons
                                                        editing={editing}
                                                        onEdit={onEditDetails}
                                                        onSave={onSaveDetails}
                                                    />
                                                </div>
                                                {editing && draft
                                                    // Editing Mode:
                                                    ? <Fragment>
                                                        <div className={classes.section}>
                                                            <FieldHeader title={'First Name'} required />
                                                            <EditableField
                                                                fieldName="firstName"
                                                                value={draft?.volunteer.firstName || ''}
                                                                editMode={editing}
                                                                onFieldEdited={(fieldName, event) => draft?.volunteer.setFirstName(event.target.value)}
                                                                TextFieldProps={{
                                                                    className: classes.textField,
                                                                    variant: 'outlined',
                                                                    error: draft?.volunteer.isFieldInvalid('firstName'),
                                                                    helperText: draft?.volunteer.getErrorForField('firstName'),
                                                                    inputProps: { maxLength: VOLUNTEER_NAME_MAX_LENGTH }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={classes.section}>
                                                            <FieldHeader title={'Last Name'} required />
                                                            <EditableField
                                                                fieldName="lastName"
                                                                value={draft?.volunteer.lastName}
                                                                editMode={editing}
                                                                onFieldEdited={(fieldName, event) => draft?.volunteer.setLastName(event.target.value)}
                                                                TextFieldProps={{
                                                                    className: classes.textField,
                                                                    variant: 'outlined',
                                                                    error: draft?.volunteer.isFieldInvalid('lastName'),
                                                                    helperText: draft?.volunteer.getErrorForField('lastName'),
                                                                    inputProps: { maxLength: VOLUNTEER_NAME_MAX_LENGTH }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={classes.section}>
                                                            <FieldHeader title={'Birthdate'} />
                                                            {!editing
                                                                ? <Typography>
                                                                    {draft?.volunteer.birthdate
                                                                        ? DateFormatter.getDateInUTC(draft?.volunteer.birthdate, true)
                                                                        : ""}
                                                                </Typography>
                                                                : <REMKeyboardDatePicker
                                                                    id="birthdate-picker"
                                                                    format="MM/dd/yyyy"
                                                                    minDate={new Date(1900, 1, 1)}
                                                                    value={draft?.volunteer.birthdate ? draft.volunteer.birthdate : null}
                                                                    onChange={handleBirthdateChange}
                                                                    inputVariant="outlined"
                                                                    required
                                                                    disableFuture
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                    error={draft?.volunteer.isFieldInvalid('birthdate')}
                                                                    helperText={draft?.volunteer.getErrorForField('birthdate')}
                                                                />
                                                            }
                                                        </div>
                                                        <div className={classes.section}>
                                                            <FieldHeader title={'Email'} />
                                                            <EmailInUseWarningField
                                                                email={draft.email}
                                                                error={draft.getErrorForField('email')}
                                                                onEmailChanged={handleEmailChange}
                                                                currentVolunteerId={draft.volunteer.id}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                    : (volunteerDetails.volunteer.birthdate || volunteerDetails.email) &&
                                                    <div className={classes.section}>
                                                        {/* Non-editing mode: */}
                                                        <FieldHeader
                                                            title={'Basic Information'}
                                                        />
                                                        {/* Birthday */}
                                                        {volunteerDetails.volunteer.birthdate &&
                                                            <div className={classes.detailLine}>
                                                                <CakeVariant className={classes.icon} />
                                                                <Typography className={classes.birthdate}>
                                                                    {volunteerDetails.volunteer.birthdate}
                                                                </Typography>
                                                            </div>
                                                        }
                                                        {/* Email */}
                                                        {volunteerDetails.email &&
                                                            <div className={classes.detailLine}>
                                                                <Email className={classes.icon} />
                                                                <Typography>
                                                                    {volunteerDetails.email}
                                                                </Typography>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {/* Status */}
                                                {editing
                                                    ? <div className={classes.section}>
                                                        <FieldHeader title={'Status'} required />
                                                        <REMSelect
                                                            value={draft?.status}
                                                            variant={'outlined'}
                                                            onChange={onSelectedStatusChanged}
                                                            className={classes.dropdown}
                                                        >
                                                            {organizationVolunteerStatuses.map(status => {
                                                                return (
                                                                    <MenuItem value={status.status} key={status.id}>{status.status}</MenuItem>
                                                                )
                                                            })}
                                                        </REMSelect>
                                                    </div>
                                                    : <div className={classes.section}>
                                                        <FieldHeader
                                                            title={'Status'}
                                                        />
                                                        {volunteerDetails.status}
                                                    </div>
                                                }
                                                {/* Registration Form */}
                                                {volunteerDetails.registrationFormStatus !== undefined
                                                    ? editing
                                                        ? <div className={classes.section}>
                                                            <FieldHeader title={'Registration Form'} />
                                                            <REMSelect
                                                                value={draft?.registrationFormStatus}
                                                                variant={'outlined'}
                                                                onChange={onRegistrationFormStatusChanged}
                                                                className={classes.dropdown}
                                                            >
                                                                <MenuItem value={'Submitted'}>Submitted</MenuItem>
                                                                <MenuItem value={'Not Submitted'}>Not Submitted</MenuItem>
                                                            </REMSelect>
                                                        </div>
                                                        : <div className={classes.section}>
                                                            <FieldHeader
                                                                title={'Registration Form'}
                                                            />
                                                            {volunteerDetails.registrationFormStatus}
                                                        </div>
                                                    : null
                                                }
                                                {/* Permissions */}
                                                <div className={classes.section}>
                                                    <FieldHeader
                                                        title={'Permissions'}
                                                        instructions={editing ? "Assign opportunity or organization level permissions." : undefined}
                                                    />
                                                    {editing
                                                        ? <REMAutocomplete
                                                            // className={classes.autocomplete}
                                                            multiple
                                                            // id={ADD_TAGS_AUTOCOMPLETE_ID}
                                                            value={draft?.permissions}
                                                            options={organizationPermissions}
                                                            getOptionLabel={(option) => option.label}
                                                            // freeSolo
                                                            filterSelectedOptions
                                                            getOptionSelected={(option, value) => {
                                                                return option.id === value.id
                                                            }}
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <REMChip
                                                                        variant="outlined"
                                                                        label={option.label}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                // id={ADD_TAGS_TEXT_FIELD_ID}
                                                                />
                                                            )}
                                                            onChange={(event, value) => onPermissionsChanged(value as Permission[])}
                                                        />
                                                        : <Typography>
                                                            {volunteerDetails.permissions.length > 0
                                                                ? volunteerDetails.permissions.map((permission, index) => {
                                                                    if (index === 0) {
                                                                        return permission.label;
                                                                    } else {
                                                                        return `, ${permission.label}`;
                                                                    }
                                                                })
                                                                : 'None Assigned'
                                                            }
                                                        </Typography>
                                                    }
                                                </div>
                                                {/* Current Roles */}
                                                <div className={classes.section}>
                                                    <FieldHeader
                                                        title={'Current Roles'}
                                                    />
                                                    {editing
                                                        ? <REMAutocomplete
                                                            // className={classes.autocomplete}
                                                            multiple
                                                            // id={ADD_TAGS_AUTOCOMPLETE_ID}
                                                            value={draft?.roles}
                                                            options={organizationRoles}
                                                            getOptionLabel={(option) => option.position}
                                                            // freeSolo
                                                            filterSelectedOptions
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <REMChip
                                                                        variant="outlined"
                                                                        label={option.position}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                // id={ADD_TAGS_TEXT_FIELD_ID}
                                                                />
                                                            )}
                                                            onChange={(event, value) => onRolesChanged(value as Role[])}
                                                        />
                                                        : volunteerDetails.roles.length > 0
                                                            ? <div className={classes.chips}>
                                                                {volunteerDetails.roles.map((role, index) => {
                                                                    return (
                                                                        <REMChip
                                                                            label={role.position}
                                                                            className={classes.chip}
                                                                            key={`role-chip-${index}`}
                                                                        />
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                            : <Typography>None Assigned</Typography>
                                                    }
                                                </div>
                                            </Container>
                                            : null
                                        }
                                        {isLoading && <FormLoadingSpinner />}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div >
                    </Container>
                    {volunteerDetails
                        ? <Fragment>
                            {/* Upcoming Shifts */}
                            <div className={classes.section}>
                                <VolunteerUpcomingShiftsTable
                                    shifts={shiftsToDisplay}
                                    shiftOpportunityMapping={volunteerDetails.shiftOpportunityMapping}
                                    total={upcomingShiftsTotal}
                                    loadResults={loadVolunteerShifts}
                                />
                            </div>
                            {/* Volunteer Hours */}
                            <div className={classes.section}>
                                <Editor editsMade={false}>
                                    {(editorState) => (
                                        <Container>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        <Container className={classes.relative}>
                                                            <Typography variant="h5">
                                                                Volunteer Hours
                                                            </Typography>
                                                            <ServiceDataPresenter
                                                                volunteer={volunteerDetails.volunteer}
                                                            />
                                                        </Container>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    )}
                                </Editor>
                            </div>
                        </Fragment>
                        : null
                    }
                </div>
            }
        </div>
    );
});

export default VolunteerDetailsPage;