import { observer } from 'mobx-react'
import { Link, LinkProps } from "@material-ui/core";

interface AnchorLinkProps extends LinkProps {
    anchorId: string;
}

const AnchorLink = observer((props: AnchorLinkProps) => {

    return (
        <Link {...props} href={`#${props.anchorId}`} />
    );
});

export default AnchorLink;