import React from "react";
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles, Theme, createStyles, Avatar } from "@material-ui/core";
import clsx from 'clsx';
import { DaysOfTheWeek } from "../../../stores/models/RepetitionPattern";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            border: 'none',
            padding: '0rem',
            borderRadius: '1.5625rem !important',
            width: '1.5625rem',
            height: '1.5625rem',
            background: 'none !important',
            margin: '0.25rem',
        },
        selected: {
            '&$button $circle': {
                background: theme.palette.primary.main,
                color: 'white',
            }
        },
        circle: {
            width: '1.5625rem',
            height: '1.5625rem',
            fontSize: '1em'
        }
    })
);

interface WeekdayButtonsProps {
    selectedDays: DaysOfTheWeek[];
    onSelectedDaysChanged: (selectedDays: DaysOfTheWeek[]) => void;
}

const WeekdayButtons = (props: WeekdayButtonsProps) => {

    const classes = useStyles();

    const handleSelectionsChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
        props.onSelectedDaysChanged(value);
    };

    return (
        <ToggleButtonGroup
            value={props.selectedDays}
            onChange={handleSelectionsChanged}
            aria-label="days of the week"
        >
            {Object.keys(DaysOfTheWeek).map((key: string, index: number) => {
                return (
                    <ToggleButton
                        key={`day-of-the-week-${index}`}
                        value={key}
                        aria-label={key}
                        className={
                            clsx(
                                classes.button,
                                props.selectedDays.indexOf(key as DaysOfTheWeek) > -1
                                    ? classes.selected
                                    : undefined
                            )
                        }
                    >
                        <Avatar className={classes.circle}>
                            {DaysOfTheWeek[key as keyof typeof DaysOfTheWeek].substring(0, 1)}
                        </Avatar>
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup >
    );
}

export default WeekdayButtons;