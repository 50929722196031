import React from "react";
import { useObserver } from 'mobx-react'
import {
    makeStyles,
    Theme,
    createStyles,
    InputBase,
    alpha,
    useMediaQuery,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.black, 0.07),
            // border: `1px solid ${theme.palette.action.disabled}`,
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.black, 0.10),
            },
            marginLeft: 0,
            // marginRight: theme.spacing(1)
        },
        searchOutlined: {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.action.disabled}`,
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        inputRoot: {
            // color: 'inherit',
            flexGrow: 1,
            height: '2.5rem'
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
        }
    })
);

interface SearchInputProps {
    searchText: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    placeholder?: string;
    outlined?: boolean;
    className?: string;
}

function SearchInput(props: SearchInputProps) {

    const classes = useStyles();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return useObserver(() => (
        <div className={clsx(classes.search, props.outlined ? classes.searchOutlined : undefined, props.className ? props.className : undefined)}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={props.placeholder ? props.placeholder : "Search by keyword…"}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={props.onChange}
                autoFocus={smUp}
                value={props.searchText}
            />
        </div>
    ));
}

export default SearchInput;