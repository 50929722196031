import { useEffect, useState } from "react";
import { Dictionary } from "../../../logic/Dictionaries";
import { Shift } from "../../../stores/models/Shift";
import { ShiftCollection } from "../../../stores/models/ShiftCollection";

const getShiftsToDisplay = (startDate: Date, endDate: Date, shiftCollection: ShiftCollection) => {
    return shiftCollection.getShiftInstancesBetweenDates(startDate, endDate);
}

export default function useShiftsToDisplay(startDate: Date, endDate: Date, shiftCollection?: ShiftCollection, refreshNeeded?: boolean) {

    const [shiftsToDisplay, setShiftsToDisplay] = useState<Dictionary<number, Shift[]>>({});
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    useEffect(() => {
        // timeoutId for debouncing
        if (timeoutId) {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
        }

        setTimeoutId(setTimeout(() => {
            if (shiftCollection) {
                setShiftsToDisplay(getShiftsToDisplay(startDate, endDate, shiftCollection))
            }
        }, 150));
    }, [startDate.getTime(), endDate.getTime(), shiftCollection?.shifts.length]);

    useEffect(() => {
        if (refreshNeeded && shiftCollection) {
            setShiftsToDisplay(getShiftsToDisplay(startDate, endDate, shiftCollection));
        }
    }, [refreshNeeded]);

    return shiftsToDisplay;
}