import {
    observable,
    action,
    computed,
    reaction,
    IReactionDisposer,
    override,
    makeObservable,
} from "mobx";
import { OptionCollection, StringIdentifiedOptionCollection } from "./OptionCollection";
import {
    searchTypeOptions,
    SearchTypeOptions,
    OrganizationTypeOptions,
    OpportunityTypeOptions,
} from "../../data/SearchOptions";
import { Fields } from "./Fields";
import validateSearchCriteria from "../../logic/ValidationChecks/SearchCriteriaValidation";
import { IOptionObject, Option } from "./Option";
import { OptionCategoryCollection } from "./OptionCategoryCollection";
import { ICauseOption } from "../CauseStore";
import { ISkillOption } from "../SkillCategoryStore";
import { containsStateAbbreviationRegex, stateAbbreviations, zipCodeRegex } from "../../logic/RegexValidation";
import { commitmentFilterOptions, SearchOption, organizationTypeOptions, opportunityTypeOptions, volunteerLocationFilterOptions, CommitmentLevelOptions, VolunteerLocationOptions, locationCheckboxFilterOptions, opportunityDetailsFilterOptions, OpportunityDetailsOptions } from "../../components/Search/FilterBar/FilterOptions";

export enum SearchType {
    Opportunities,
    Organizations
}

interface Filter {
    text: string;
    onDelete?: () => void;
}

enum OptionsType {
    Location = 'locationOptions',
    Organization = 'organizationOptions',
    OpportunityType = 'opportunityTypeOptions',
    Commitment = 'commitmentOptions',
    LocationCheckbox = 'locationCheckboxOptions',
    OpportunityDetails = 'opportunityDetailsOptions'
}

type CauseOptionCollection = StringIdentifiedOptionCollection<ICauseOption>;
type SearchTypeOptionCollection = StringIdentifiedOptionCollection<SearchOption<SearchTypeOptions>>;
type SearchOptionCollection = StringIdentifiedOptionCollection<SearchOption<any>>;

type OptionsInterface = {
    [key in OptionsType]: SearchOptionCollection;
}

export interface ISearchCriteria extends OptionsInterface {
    searchText: string;
    cityText: string;
    localOnly: boolean;
    causeOptions: CauseOptionCollection;
    skillOptions: OptionCategoryCollection<{ value: string }, ISkillOption>;
    searchOptions: SearchTypeOptionCollection;
    orgSlug?: string;
}

const cityStateSplitRegex = new RegExp(', (?=' + stateAbbreviations + '$)', "i");

export class SearchCriteria extends Fields<ISearchCriteria, SearchCriteria> implements ISearchCriteria {
    @observable searchText = "";
    @observable cityText = "";
    @observable localOnly: boolean = false;
    @observable causeOptions = new OptionCollection<'value', IOptionObject, ICauseOption>('value', []);
    @observable skillOptions = new OptionCategoryCollection<IOptionObject, ISkillOption>([]);
    @observable searchOptions = new OptionCollection('value', searchTypeOptions, [searchTypeOptions[0]] as IOptionObject[]);
    @observable locationOptions = new OptionCollection('value', volunteerLocationFilterOptions, [{ value: 'All' }]);
    @observable organizationOptions = new OptionCollection('value', organizationTypeOptions, [{ value: 'Either' }]);
    @observable opportunityTypeOptions = new OptionCollection('value', opportunityTypeOptions, [{ value: 'Either' }]);
    @observable commitmentOptions = new OptionCollection('value', commitmentFilterOptions, [{ value: 'Either' }]);
    @observable locationCheckboxOptions = new OptionCollection('value', locationCheckboxFilterOptions, [] as IOptionObject[]);
    @observable opportunityDetailsOptions = new OptionCollection('value', opportunityDetailsFilterOptions);
    @observable numResultsRequested = 30;
    @observable offset = 0;
    @observable orgSlug?: string;
    @observable refreshNeeded: boolean = false;

    private searchFilterChangedReaction: IReactionDisposer;

    constructor(searchCritera?: ISearchCriteria) {
        super();
        makeObservable(this);

        if (searchCritera) {
            this.searchText = searchCritera.searchText;
            this.cityText = searchCritera.cityText;
            this.localOnly = searchCritera.localOnly;
            this.causeOptions = searchCritera.causeOptions;
            this.skillOptions = searchCritera.skillOptions;
            this.orgSlug = searchCritera.orgSlug;
            this.searchOptions.setSelections(searchCritera.searchOptions.selectedOptions);
            Object.values(OptionsType).forEach(optionsType => {
                this[optionsType].setSelections(searchCritera[optionsType].selectedOptions);
            });
        }
        this.searchFilterChangedReaction = reaction(
            () => [this.filterData, this.searchType],
            () => { this.setOffset(0); }
        );
    }

    @computed get validationErrors() {
        return validateSearchCriteria(this);
    }

    /***** Setters *****/

    @action setOffset(offset: number) {
        this.offset = offset;
    }

    @action setSearchText(searchText: string) {
        this.searchText = searchText;
    }

    @action setCityText(cityText: string) {
        this.cityText = cityText;
    }

    @action setLocalOnly(localOnly: boolean) {
        this.localOnly = localOnly;
    }

    @action setCauseOptions(causeOptions: CauseOptionCollection) {
        this.causeOptions = causeOptions;
    }

    @action setSkillOptions(skillOptions: OptionCategoryCollection<IOptionObject, ISkillOption>) {
        this.skillOptions = skillOptions;
    }

    @action setSearchType(searchType: SearchTypeOptions) {
        this.searchOptions.setSelections([{ value: searchType }]);
    }

    @action setOrgSlug(orgSlug?: string) {
        this.orgSlug = orgSlug;
    }

    @action setRefreshNeeded(refreshNeeded: boolean) {
        this.refreshNeeded = refreshNeeded;
    }

    /***** Filters *****/

    @computed get filters() {
        let filters = [] as Filter[];
        Object.values(OptionsType).forEach(optionsType => {
            const filter = this.getFilter(optionsType as OptionsType);
            if (filter) {
                filters = filters.concat(filter);
            }
        });
        return filters;
    }

    private getFilter(optionsType: keyof OptionsInterface): Filter[] | undefined {
        if (optionsType === 'locationOptions') {
            return;
        }

        return (this[optionsType].selections as Option<SearchOption<any>>[]).map(selection => {
            return {
                text: selection.object.chipText ? selection.object.chipText : selection.object.primaryText,
                // icon: selection.object.icon,
                onDelete: optionsType === OptionsType.LocationCheckbox || optionsType === OptionsType.OpportunityDetails
                    ? () => selection.toggleSelection()
                    : () => this.resetOptions(optionsType)
            }
        })
    }

    @action private resetOptions(optionsType: OptionsType) {
        this[optionsType] = this.getDefaultValue(optionsType) as SearchOptionCollection;
    }

    private getDefaultValue(optionsType: OptionsType) {
        switch (optionsType) {
            case OptionsType.Location:
                return new OptionCollection('value', volunteerLocationFilterOptions, volunteerLocationFilterOptions);
            // case OptionsType.Scheduling:
            //     return new OptionCollection('value', schedulingFilterOptions, schedulingFilterOptions);
            case OptionsType.OpportunityType:
                return new OptionCollection('value', opportunityTypeOptions, opportunityTypeOptions);
            case OptionsType.Organization:
                return new OptionCollection('value', organizationTypeOptions, organizationTypeOptions);
            case OptionsType.Commitment:
                return new OptionCollection('value', commitmentFilterOptions, commitmentFilterOptions);
        }
    }

    /***** Location filter *****/

    @computed get locationFilterText() {
        if (this.locationEntered) {
            return this.trimmed.cityText;
        } else {
            return 'Wherever you are'
        }
    }

    @computed get locationEntered() {
        return this.trimmed.cityText && this.trimmed.cityText.length > 0;
    }

    /***** Location type *****/

    @computed get locationType() {
        if (this.remote && this.onSite) {
            return VolunteerLocationOptions.All;
        } else if (this.remote) {
            return VolunteerLocationOptions.Remote;
        } else {
            return VolunteerLocationOptions.OnSite;
        }
    }

    /***** Search type *****/

    @computed get isOpportunitySearch() {
        const index = this.searchOptions.selections.findIndex(selection => selection.object.value === SearchTypeOptions.Opportunities);
        return index !== -1;
    }

    @computed get searchTypeFilter(): Filter {
        return {
            text: this.searchTypeSelection!.object.filterText,
            // icon: this.searchTypeSelection!.object.icon,
            onDelete: undefined
        }
    }

    @computed get searchType() {
        return this.searchTypeSelection?.object.value;
    }

    @computed private get searchTypeSelection() {
        try {
            return this.searchOptions.selections[0];
        } catch (error) {
            console.log(error);
        }
    }

    /***** Request data *****/

    @computed get filterData() {
        return {
            searchText: this.searchText.trim(),
            causeIds: this.causeIds,
            skillIds: this.skillIds,
            city: this.city,
            state: this.state.toUpperCase(),
            onSite: this.onSite,
            remote: this.remote,
            localOnly: this.localOnly,
            nonprofit: true,//this.nonprofit,
            forProfit: true,//this.forProfit,
            orgSlug: this.orgSlug,

            flexible: true,//this.schedulingFilterApplied ? this.flexible : undefined,
            shifts: true,//this.schedulingFilterApplied ? this.shifts : undefined,
            oneTime: true,//this.opportunityTypeFilterApplied ? this.oneTime : undefined,
            ongoing: true,//this.opportunityTypeFilterApplied ? this.ongoing : undefined,
            shortTerm: true,//this.commitmentFilterApplied ? this.shortTerm : undefined,
            longTerm: true,//this.commitmentFilterApplied ? this.longTerm : undefined

            childcareAvailable: this.childcareAvailable,
            familyOpportunity: this.familyOpportunity,
            groupOpportunity: this.groupOpportunity,
            shortStandingTime: this.shortStandingTime,
            noHeavyLifting: this.noHeavyLifting,
            courtOrdered: this.courtOrdered
        }
    }

    @computed get serializedRequestData() {
        return {
            ...this.filterData,
            numRequested: this.numResultsRequested,
            offset: this.offset,
        }
    }

    private optionsContainSelection(optionsType: OptionsType, selectedValue: string) {
        const index = this[optionsType].selectedOptions.findIndex((selection: IOptionObject) => selection.value === selectedValue);
        return index !== -1;
    }

    private filterForTypeApplied(optionsType: OptionsType) {
        return this[optionsType].selections.length < this[optionsType].options.length;
    }

    @computed private get onSite() {
        return this.optionsContainSelection(OptionsType.Location, VolunteerLocationOptions.OnSite)
            || this.optionsContainSelection(OptionsType.Location, VolunteerLocationOptions.All);

    }

    @computed private get remote() {
        return this.optionsContainSelection(OptionsType.Location, VolunteerLocationOptions.Remote)
            || this.optionsContainSelection(OptionsType.Location, VolunteerLocationOptions.All);
    }

    // @computed private get schedulingFilterApplied() {
    //     return this.filterForTypeApplied(OptionsType.Scheduling);
    // }

    // @computed private get flexible() {
    //     return this.optionsContainSelection(OptionsType.Scheduling, SchedulingOptions.Flexible);
    // }

    // @computed private get shifts() {
    //     return this.optionsContainSelection(OptionsType.Scheduling, SchedulingOptions.Shifts);
    // }

    @computed private get childcareAvailable() {
        return this.optionsContainSelection(OptionsType.OpportunityDetails, OpportunityDetailsOptions.Childcare);
    }

    @computed private get familyOpportunity() {
        return this.optionsContainSelection(OptionsType.OpportunityDetails, OpportunityDetailsOptions.Families);
    }

    @computed private get groupOpportunity() {
        return this.optionsContainSelection(OptionsType.OpportunityDetails, OpportunityDetailsOptions.Groups);
    }

    @computed private get shortStandingTime() {
        return this.optionsContainSelection(OptionsType.OpportunityDetails, OpportunityDetailsOptions.ShortStanding);
    }

    @computed private get noHeavyLifting() {
        return this.optionsContainSelection(OptionsType.OpportunityDetails, OpportunityDetailsOptions.NoHeavyLifting);
    }

    @computed private get courtOrdered() {
        return this.optionsContainSelection(OptionsType.OpportunityDetails, OpportunityDetailsOptions.CourtMandated);
    }

    @computed private get nonprofit() {
        return this.optionsContainSelection(OptionsType.Organization, OrganizationTypeOptions.Nonprofit);
    }

    @computed private get forProfit() {
        return this.optionsContainSelection(OptionsType.Organization, OrganizationTypeOptions.ForProfit);
    }

    @computed private get opportunityTypeFilterApplied() {
        return this.filterForTypeApplied(OptionsType.OpportunityType);
    }

    @computed private get oneTime() {
        return this.optionsContainSelection(OptionsType.OpportunityType, OpportunityTypeOptions.OneTime);
    }

    @computed private get ongoing() {
        return this.optionsContainSelection(OptionsType.OpportunityType, OpportunityTypeOptions.Ongoing);
    }

    @computed private get commitmentFilterApplied() {
        return this.filterForTypeApplied(OptionsType.Commitment);
    }

    @computed private get shortTerm() {
        return this.optionsContainSelection(OptionsType.Commitment, CommitmentLevelOptions.ShortTerm);
    }

    @computed private get longTerm() {
        return this.optionsContainSelection(OptionsType.Commitment, CommitmentLevelOptions.LongTerm);
    }

    @computed private get zipCode() {
        return this.cityText.match(zipCodeRegex);
    }

    @computed private get zipCodeSplit() {
        const splitRegex = new RegExp(this.zipCode + ',', 'i');
        return this.cityText.split(splitRegex);
    }

    @computed private get cityStateSplit() {
        if (this.zipCodeSplit.length > 1) {
            return this.zipCodeSplit[1].split(cityStateSplitRegex);
        } else {
            return this.cityText.split(cityStateSplitRegex);
        }
    }

    @computed private get city() {
        return this.cityStateSplit.length > 0 ? this.cityStateSplit[0].trim() : "";
    }

    @computed private get state() {
        const stateMatches = this.cityStateSplit.length > 1 ? this.cityStateSplit[1].match(containsStateAbbreviationRegex) : [];
        return stateMatches && stateMatches.length > 0 ? stateMatches[0] : "";
    }

    @computed private get causeIds() {
        return this.causeOptions.selectedOptions.map(cause => { return cause.id; });
    }

    @computed private get skillIds() {
        return this.skillOptions.selectedObjects.map(skill => { return skill.id; });
    }
}