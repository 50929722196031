import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Button, ButtonProps } from "@material-ui/core";
import { Fragment, MouseEventHandler, useEffect, useState } from 'react';
import { ImagePlus } from 'mdi-material-ui';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            display: 'none'
        },
        label: {
            display: 'block'
        },
        button: {
            marginBottom: theme.spacing(2)
        }
    })
);

interface ImageUploaderProps extends ButtonProps {
    imageClassname?: string;
    initialImageSrc?: string | null;
    onImageSelected?: (image: File) => void;
}

const ImageUploader = observer((props: ImageUploaderProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const [imageSrc, setImageSrc] = useState(props.initialImageSrc);

    useEffect(() => {
        if (props.initialImageSrc) {
            setImageSrc(props.initialImageSrc);
        }
    }, [props.initialImageSrc]);

    /********* Helper methods *********/

    const onImageLoad = () => {
        if (imageSrc) {
            URL.revokeObjectURL(imageSrc);  // no longer needed, free memory
        }
    }

    const onImageSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const url = URL.createObjectURL(event.target.files[0]);
            setImageSrc(url);
            if (props.onImageSelected) {
                props.onImageSelected(event.target.files[0]);
            }
        }
    }

    const onButtonClicked: MouseEventHandler<HTMLButtonElement> = (event) => {
        let label = document.querySelector('#image-select-label') as HTMLImageElement;
        label.click();
        if (props.onClick) {
            props.onClick(event);
        }
    }

    /********* Render *********/

    return (
        <Fragment>
            <input
                type='file'
                accept='image/*'
                onChange={onImageSelected}
                className={classes.input}
                id={'image-input'}
            />
            <label htmlFor="image-input" id="image-select-label">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ImagePlus />}
                    className={classes.button}
                    {...props}
                    onClick={onButtonClicked}
                >
                    {props.children}
                </Button>
            </label>
            <div>
                {imageSrc
                    ? <img id="uploaded-logo" src={imageSrc} className={props.imageClassname} onLoad={onImageLoad} />
                    : null
                }
            </div>
        </Fragment>
    );
});

export default ImageUploader;