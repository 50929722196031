import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Fragment } from "react";
import Footer from "./Footer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // height: '100%'
            flexGrow: 1
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        }
    })
);

interface PageWithFooterProps {
    children: JSX.Element;
}

const PageWithFooter = (props: PageWithFooterProps) => {

    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.content}>
                {props.children}
            </div>
            <Footer />
        </Fragment>
    )
};

export default PageWithFooter;