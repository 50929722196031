import React from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import ErrorMessage from "../../Shared/ErrorMessage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                paddingBottom: theme.spacing(2)
            }
        },
    })
);

interface InvalidUserTypeProps {
    onLogoutClicked: () => void;
}

const InvalidUserType = observer((props: InvalidUserTypeProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Event handler *********/

    const onButtonClicked = () => {
        props.onLogoutClicked();
    }

    /********* Render *********/

    return (
        <div className={classes.root}>
            <ErrorMessage
                title={`You're currently logged in as an organization.`}
                details={`Please logout of your organization account in order to continue.`}
                ButtonProps={{
                    children: "Logout",
                    onClick: onButtonClicked
                }}
            />
        </div>
    );
});

export default InvalidUserType;