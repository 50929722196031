import { IOptionObject, IOption, Option } from "./Option";
import { observable, computed, action, makeObservable } from "mobx";

/*** Option Category ***/

interface IOptionCategory<T extends IOptionObject> {
    name: string;
    options: IOption<T>[];
}

export class OptionCategory<T extends IOptionObject = IOptionObject, U extends T = T> implements IOptionCategory<U> {
    name = "";
    options = [] as Option<U>[];

    constructor(categoryOrOptionCategory: string | OptionCategory<U>, optionObjects: U[], selectedOptions?: T[]) {
        makeObservable(this, {
            name: observable,
            options: observable,
            optionObjects: computed,
            selections: computed,
            selectedObjects: computed
        });

        if (typeof categoryOrOptionCategory === 'string') {
            this.name = categoryOrOptionCategory;
            this.options = optionObjects.map(object => {
                const selected = selectedOptions ? selectedOptions.findIndex(selection => selection.value === object.value) !== -1 : false;
                return new Option(object, selected);
            });
        } else {
            this.name = categoryOrOptionCategory.name;
            this.options = categoryOrOptionCategory.options.map(option => new Option(option.object, option.selected));
        }
    }

    get optionObjects(): U[] {
        return this.options.map(option => option.object);
    }

    get selections(): Option<U>[] {
        return this.options.filter(option => option.selected);
    }

    get selectedObjects(): U[] {
        return this.selections.map(selection => selection.object);
    }
}

/*** Option Category Collection ***/

interface IOptionCategoryCollection<T extends IOptionObject = IOptionObject, U extends T = T> {
    categories: OptionCategory<T, U>[];
}

export class OptionCategoryCollection<T extends IOptionObject = IOptionObject, U extends T = T> implements IOptionCategoryCollection<T, U> {
    categories: OptionCategory<T, U>[];

    constructor(optionCategoriesOrOptionCategoryCollection: OptionCategory<T, U>[] | OptionCategoryCollection<T, U>, selectedOptions?: T[]) {
        makeObservable(this, {
            categories: observable,
            selections: computed,
            selectedObjects: computed,
            setSelections: action
        });

        if (Array.isArray(optionCategoriesOrOptionCategoryCollection)) {
            this.categories = optionCategoriesOrOptionCategoryCollection.map(category => {
                return new OptionCategory(category.name, category.optionObjects, selectedOptions);
            });
        } else {
            this.categories = optionCategoriesOrOptionCategoryCollection.categories.map(category => {
                return new OptionCategory(category.name, category.optionObjects, category.selectedObjects);
            });
        }
    }

    get selections() {
        let selections = [] as Option<U>[];
        this.categories.forEach(category => {
            selections = selections.concat(category.options.filter(option => option.selected));
        })
        return selections;
    }

    get selectedObjects(): U[] {
        return this.selections.map(selection => selection.object);
    }

    setSelections(selections: T[]) {
        this.categories.forEach(category => {
            category.options.forEach(option => {
                const selected = selections.findIndex(selection => selection.value === option.object.value) !== -1;
                if (option.selected !== selected) {
                    option.toggleSelection();
                }
            });
        });
    }
}