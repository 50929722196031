import React, { useContext } from "react";
import { observer } from "mobx-react";
import InactivationWarningDialog from "./InactivationWarningDialog";
import { RootContext } from "../../../../../stores";

const ActiveCitiesLimitDialog = observer(() => {

    /********* React hook *********/

    const navigationStore = useContext(RootContext).navigationStore;

    /********* Render *********/

    return (
        <InactivationWarningDialog
            state={navigationStore.appwideDialogStates.activeCitiesLimitDialog}
            title={'Active Recruitment Cities Limit Exceeded'}
            details={`Please either upgrade your subscription to increase the number of cities ` +
                `you can simultaneously recruit in or adjust the cities that you're currently ` +
                `recruiting in.`
            }
        />
    );
});

export default ActiveCitiesLimitDialog;