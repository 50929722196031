import { useContext, useEffect } from "react";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { RootContext } from "../../stores";
import { Alert, AlertTitle } from "@material-ui/lab";
import { observer } from "mobx-react";
import { APIError } from "../../stores/models/APIError";

interface APIErrorDialogProps {
    error?: APIError;
}

const APIErrorDialog =  observer((props: APIErrorDialogProps) => {

    const rootStore = useContext(RootContext);
    const navigationStore = rootStore.navigationStore;
    const state = navigationStore.appwideDialogStates.apiErrorDialog;

    useEffect(() => {
        if (!state.open) {
            rootStore.setAPIError(undefined);
        }
    }, [state.open]);

    return (
        <ConfirmationDialog
            state={state}
            title={props.error?.dialogTitle}
            confirmText={'Okay'}
            hideCancelButton
            onConfirm={() => state.setOpen(false)}
            content={
                <Alert severity={props.error?.severity ? props.error.severity : "error"}>
                    {props.error?.errorTitle && <AlertTitle>{props.error.errorTitle}</AlertTitle>}
                    {props.error?.message}
                </Alert>
            }
        />
    )
});

export default APIErrorDialog;