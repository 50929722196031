import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import { getAccountSettingsLink, getReportPagePath, reportNameParam } from "../../../../Navigation/Links/UrlConstructors";
import { RootContext } from "../../../../../stores";
import ReportPage from "./ReportPage";
import ReportingSummaryPage from "./ReportingSummaryPage";
import { CSVExporter } from "../../../../../shared/general/services/CSVExporter";
import { ReportData, ReportNames } from "../../../../../stores/models/ReportData";
import { TableSortingState } from "../../../../../stores/models/TableSortingState";
import { useMatchCurrentPath } from "../../../../Navigation/Hooks";

const today = new Date();
const isJanuary = today.getMonth() === 0;
const currentYear = today.getFullYear();
const previousYear = currentYear - 1;
const startOfPreviousYear = new Date(previousYear, 0, 1);
const endOfPreviousYear = new Date(previousYear, 11, 31);
const startOfCurrentYear = new Date(currentYear, 0, 1);
const defaultStartDate = isJanuary ? startOfPreviousYear : startOfCurrentYear;
const defaultEndDate = isJanuary ? endOfPreviousYear : today;

const ReportingSection = observer(() => {

    /********* React hooks *********/

    const rootStore = useContext(RootContext);
    const { userStore, organizationStore } = rootStore;
    const matchPath = useMatchCurrentPath();
    const match = matchPath({ path: `${getAccountSettingsLink()}/${getReportPagePath()}` })

    /********* State *********/

    const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
    const [lastRequestedDates, setLastRequestedDates] = useState({ startDate: defaultStartDate, endDate: defaultEndDate });
    const [reportData, setReportData] = useState<ReportData | undefined>();
    const [csvExporter] = useState(new CSVExporter());
    const [loading, setLoading] = useState(true);
    const [sortState, setSortState] = useState(new TableSortingState());
    const [lastReport, setLastReport] = useState<string | undefined>();

    /********* Effects *********/

    useEffect(() => {
        if (validateReportDates()) {
            setLastRequestedDates({ startDate: startDate!, endDate: endDate! })
            getReportData();
        }
    }, [startDate, endDate]);

    useEffect(() => {
        // if (match?.params.reportName) {
        setLastReport(match?.params.reportName);
        // }
    }, [match?.params.reportName]);

    useEffect(() => {
        // Reset the sorting state whenever an individual report is opened
        setSortState(new TableSortingState());
    }, [lastReport]);

    /********* Event handlers *********/

    const downloadButtonPushed = (filename: string, data: unknown[]) => {
        var csvData = csvExporter.exportToCSV(data);
        var blob = new Blob([csvData], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);

        // if (navigator.msSaveOrOpenBlob) {
        //     navigator.msSaveBlob(blob, filename);
        // } else {
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // }
        window.URL.revokeObjectURL(url);
    }

    const onDownloadButtonPushedForReport = (report: ReportNames) => {
        if (reportData) {
            const csvReport = reportData.getCSVReport(
                report,
                {
                    startDate: lastRequestedDates.startDate,
                    endDate: lastRequestedDates.endDate,
                    sorting: JSON.parse(sortState.stringifiedSortConfiguration)
                }
            );
            downloadButtonPushed(csvReport.filename, csvReport.data);
        }
    }

    const onDownloadButtonPushedForDefaultReport = (report: ReportNames) => {
        if (reportData) {
            const csvReport = reportData.getCSVReport(report, { startDate: lastRequestedDates.startDate, endDate: lastRequestedDates.endDate });
            downloadButtonPushed(csvReport.filename, csvReport.data);
        }
    }

    /********* Helper methods *********/

    const validateReportDates = () => {
        return startDate && endDate && startDate <= endDate;
    }

    const getReportData = async () => {
        setLoading(true);
        const data = await organizationStore.getReportData(userStore.user.organization!.id, startDate!, endDate!);
        setReportData(data);
        setLoading(false);
    }

    /********* Helper constants *********/

    const datesValid = validateReportDates();
    const rangeError = datesValid === null ? 'Enter a date range.' : datesValid === false ? 'The start date must come before the end date.' : '';

    /********* Render *********/

    return (
        <Routes>
            <Route path={reportNameParam} element={
                <ReportPage
                    reportData={reportData}
                    startDate={startDate}
                    endDate={endDate}
                    rangeError={rangeError}
                    loading={loading}
                    sortState={sortState}
                    onStartDateChanged={(date) => setStartDate(date)}
                    onEndDateChanged={(date) => setEndDate(date)}
                    onDownload={onDownloadButtonPushedForReport}
                />
            }
            />
            <Route
                path="/"
                element={
                    <ReportingSummaryPage
                        reportData={reportData}
                        startDate={startDate}
                        endDate={endDate}
                        rangeError={rangeError}
                        loading={loading}
                        onStartDateChanged={(date) => setStartDate(date)}
                        onEndDateChanged={(date) => setEndDate(date)}
                        onDownload={onDownloadButtonPushedForDefaultReport}
                    />
                }
            />
        </Routes>
    );
});

export default ReportingSection;