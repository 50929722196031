import { useEffect } from "react";
import { observer } from "mobx-react";
import { StepProps } from "../../../../../stores/models/Step";
import AuthenticateToLinkAccount from "../../../../Authentication/LinkVolunteerToOrganizationSteps/AuthenticateToLinkAccount";
import { RegistrationFormStepObject } from "./RegistrationFormStepObject";

const AuthenticateToLinkAccountStep = observer((props: StepProps<RegistrationFormStepObject>) => {

    const stepObject = props.stepState.stepObject;

    /***** Effects *****/

    useEffect(() => {
        if (!props.stepState.completed) {
            stepObject.setCreatingNewUser(false);
        }

        return function cleanup() {
            resetCreatingNewUser();
        }
    }, []);

    /***** Helper methods *****/

    const resetCreatingNewUser = () => {
        if (!props.stepState.completed) {
            stepObject.setCreatingNewUser(false);
        }
    }

    /***** Event handlers *****/

    const onSwitchToSignUp = () => {
        stepObject.setCreatingNewUser(true);
        setTimeout(() => { // Give the sign up step a chance to be added
            props.stepState.setCompleted(true);
        }, 0);
    }

    const onLoadingStateChanged = (isLoading: boolean) => {
        props.stepState.setLoading(isLoading);
    }

    /***** Render *****/

    return (
        <AuthenticateToLinkAccount
            prompt={'Please login or sign up to fill out the registration form.'}
            onSwitchToSignUp={onSwitchToSignUp}
            onLoadingStateChanged={onLoadingStateChanged}
        />
    );
});

export default AuthenticateToLinkAccountStep;