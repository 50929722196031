import { observer } from "mobx-react";
import { getSubscriptionLink } from "../../../../Navigation/Links/UrlConstructors";
import ErrorMessage from "../../../../Shared/ErrorMessage";
import { useNavigateInternally } from "../../../../Navigation/Hooks";

interface InactiveSubscriptionMessageProps {
    content: string;
}

const InactiveSubscriptionMessage = observer((props: InactiveSubscriptionMessageProps) => {

    /********* React hooks *********/

    const navigate = useNavigateInternally();

    /********* Helper methods *********/

    const navigateToSubscriptionDetails = () => {
        navigate(getSubscriptionLink());
    }

    /********* Render *********/

    return (
        <ErrorMessage
            title={'Uh oh! Your subscription is inactive.'}
            details={props.content}
            ButtonProps={{ children: 'View subscription', onClick: navigateToSubscriptionDetails }}
        />
    );
});

export default InactiveSubscriptionMessage;