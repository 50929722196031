import { makeStyles, Theme, createStyles, TableRow, Typography, TableCell, TableRowProps, TableCellProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { StylizedInternalLink } from "../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'rgba(0, 0, 0, 0.04)'
        },
        flex: {
            display: 'flex',
            justifyContent: 'center',
            '& > :first-child': {
                marginRight: '4px'
            }
        }
    }),
);

interface SelectAllTableRowProps extends TableRowProps {
    TableCellProps: TableCellProps;
    rowsOnPage: number;
    totalRows: number;
    onSelectAllClicked: () => void;
}

const SelectAllTableRow = observer((props: SelectAllTableRowProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <TableRow className={classes.root} {...props}>
            <TableCell {...props.TableCellProps}>
                <div className={classes.flex}>
                    <Typography variant="body2">
                        All {props.rowsOnPage} rows on this page are selected.
                    </Typography>
                    <StylizedInternalLink
                        onClick={props.onSelectAllClicked}
                        children={
                            <Typography variant="body2">
                                Select all {props.totalRows} rows.
                            </Typography>
                        }
                    />
                </div>
            </TableCell>
        </TableRow>
    );
});

export default SelectAllTableRow;