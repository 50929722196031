import { makeStyles, Theme, createStyles, useMediaQuery, Typography } from "@material-ui/core";
import { useContext } from "react";
import { RootContext } from "../../stores";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getOrganizationSignUpLink } from "../Navigation/Links";
import { useNavigateInternally } from "../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        numberedList: {
            paddingInlineStart: '18px',
            marginBlockStart: '0.5em',
            marginBlockEnd: '0.5em'
        }
    }),
);

const LogoutOfVolunteerAccountDialog = observer(() => {

    /********** React hooks **********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const navigate = useNavigateInternally();

    /********** Helper constants **********/

    const dialogState = rootStore.navigationStore.appwideDialogStates.logoutOfVolunteerAccountDialog;

    /********** Event handlers **********/

    const logout = async () => {
        dialogState.setLoading(true);
        await rootStore.userStore.logout();
        dialogState.setOpen(false);
        dialogState.setLoading(false);
    }

    const handleContinue = async () => {
        await logout();
        navigate(getOrganizationSignUpLink());
    }

    /********** Render **********/

    return (
        <ThemedDialogWithSpinner
            title={'Recruit Volunteers?'}
            primaryButtonProps={{ children: 'Continue' }}
            state={dialogState}
            DialogProps={{ fullScreen: xsDown, maxWidth: 'xs' }}
            onSubmit={handleContinue}
        >
            <Alert severity="warning">
                <AlertTitle>Recruiting is restricted to organizations.</AlertTitle>
                <Typography>Continue to:
                    <ol className={classes.numberedList}>
                        <li>Log out of your volunteer account.</li>
                        <li>Register or sign in as an organization instead.</li>
                    </ol>
                </Typography>
            </Alert>
        </ThemedDialogWithSpinner >
    );
});

export default LogoutOfVolunteerAccountDialog;