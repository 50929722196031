import { Fragment } from "react";
import { observer } from "mobx-react";
import DemographicsFields from "./DemographicsFields";
import { StubUser } from "../../../../stores/models/StubUser";
import CredentialsFields from "../Shared/CredentialsFields";


interface FullVolunteerSignUpProps {
    stubUser: StubUser;
    validationRun?: boolean;
}

const FullVolunteerSignUp = observer((props: FullVolunteerSignUpProps) => {

    /********** Render **********/

    return (
        <Fragment>
            <CredentialsFields credentials={props.stubUser.credentials} validationRun={props.validationRun} />
            <DemographicsFields stubUser={props.stubUser} validationRun={props.validationRun} />
        </Fragment>
    );
});

export default FullVolunteerSignUp;