

import { makeStyles, Theme, createStyles, Button, Typography, Paper } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { observer } from "mobx-react";
import { DetailedHTMLProps, FormHTMLAttributes, useState } from "react";
import { APIError, isAPIError } from "../../../../stores/models/APIError";
import { Form } from "../../../../stores/models/Form";
import FormError from "../../../Organization/VolunteerOpportunities/FormError";
import FormSection from "./FormSection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(0, 2, 2, 2),
            marginTop: theme.spacing(2),
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            overflow: 'hidden'
        },
        form: {
            textAlign: 'left',
            maxWidth: '700px'
        },
        section: {
            marginTop: theme.spacing(3),
            '& > :not(:first-child)': {
                marginTop: theme.spacing(1)
            }
        },
        sectionTitle: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: theme.spacing(-3, -3, 1, -3),
            padding: theme.spacing(1.5, 3)
        },
        button: {
            marginTop: theme.spacing(2),
            float: 'right'
        },
        errorAlert: {
            borderColor: theme.palette.error.light,
            marginTop: theme.spacing(2)
        }
    }),
);

interface EmbeddedFormProps extends DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    formStructure: Form;
    onFormSubmit: () => Promise<{ succeeded: boolean, error?: string | APIError }>;
}

const EmbeddedForm = observer((props: EmbeddedFormProps) => {

    const classes = useStyles();

    const [displayError, setDisplayError] = useState(false);
    const [responseError, setResponseError] = useState<string | APIError>();

    const scrollToTopOfForm = () => {
        window.scroll({ top: 0 });
    }

    const onSubmitClicked = async () => {
        props.formStructure.setAllFieldsDirty();
        if (props.formStructure.validated) {
            setDisplayError(false);
            const response = await props.onFormSubmit();
            if (response.error !== undefined) {
                setResponseError(response.error);
                scrollToTopOfForm();
            }
        } else {
            setDisplayError(true);
            scrollToTopOfForm();
        }
    }

    /********* Render *********/

    return (
        <form className={classes.form} {...props}>
            {responseError !== undefined
                ? isAPIError(responseError)
                    ? <Alert severity={responseError.severity} className={classes.errorAlert}>
                        {responseError.errorTitle && <AlertTitle>{responseError.errorTitle}</AlertTitle>}
                        {responseError.message}
                    </Alert>
                    : <Alert severity="error" className={classes.errorAlert}>
                        {responseError}
                    </Alert>
                : displayError && <FormError className={classes.errorAlert} />
            }
            <Paper className={classes.paper}>
                <div className={classes.section}>
                    <Typography variant="h4" className={classes.sectionTitle}>{props.formStructure.title}</Typography>
                    {props.formStructure.description !== undefined && <Typography>{props.formStructure.description}</Typography>}
                </div>
            </Paper>
            {props.formStructure.sections.map((section, index) => {
                return (
                    <Paper className={classes.paper}>
                        <FormSection section={section} />
                    </Paper>
                )
            })}
            <Button variant='contained' color='primary' onClick={onSubmitClicked} className={classes.button}>
                Submit
            </Button>
        </form>
    );
});

export default EmbeddedForm;