import { makeStyles, Theme, createStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { RootContext } from "../../../../stores";
import { StubOrganization, StubOrganizationValidationChecks } from "../../../../stores/models/StubOrganization";
import { LevelOnePlan, LevelTwoPlan, LevelThreePlan, FeatureHeaders, SubscriptionPlanOptions } from "./Plans";
import { StepProps } from "../../../../stores/models/Step";
import { observer } from "mobx-react";
import PricingGrid from "./PricingGrid";
import SubscriptionPlanCards from "../../../AccountSettings/Pages/Organization/Subscription/SubscriptionPlanCards";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(3),
            '& $emphasized': {
                color: theme.palette.primary.main,
            }
        },
        demo: {
            color: 'gray',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1)
        },
        link: {
            color: theme.palette.primary.main,
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        },
    }),
);

const SubscriptionPlans = observer((props: StepProps<StubOrganization>) => {

    // React hooks
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const displayMobileSubscriptionPlans = useMediaQuery((theme: Theme) => theme.breakpoints.down(800));
    const stubOrganization = props.stepState.stepObject;

    useEffect(() => {
        stubOrganization.setValidationChecks(StubOrganizationValidationChecks.SubscriptionPlan);
    }, []);

    // Event handlers
    const handlePlanClicked = (option: SubscriptionPlanOptions) => {
        stubOrganization.setSubscriptionPlanType(option);
    }

    const handleDemoClicked = () => {
        rootStore.navigationStore.appwideDialogStates.contactUsDialog.setOpen(true);
        rootStore.helpStore.setHelpMessageOverrides({
            subject: 'Demo Request',
            message: `Hello,\n\nI would like to schedule a demo of the Voltage Volunteering platform.`
        });
    };

    const plans = [LevelOnePlan, LevelTwoPlan, LevelThreePlan];

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h5">Which plan matches your needs?</Typography>
                <Typography
                    variant="body2"
                    className={classes.demo}
                >
                    Request a <span onClick={handleDemoClicked} className={classes.link}>demo</span>.
                </Typography>
            </div>
            {displayMobileSubscriptionPlans
                ? <SubscriptionPlanCards
                    planLevel={stubOrganization.subscriptionPlanLevel}
                    onPlanSelected={(planName) => handlePlanClicked(planName as SubscriptionPlanOptions)}
                />
                : <PricingGrid
                    features={FeatureHeaders}
                    plans={plans}
                    onPlanSelected={(planName) => handlePlanClicked(planName as SubscriptionPlanOptions)}
                />
            }
        </div>
    );
});

export default SubscriptionPlans;