import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card, Typography, CardContent, CardActionArea, Button, Tooltip, useMediaQuery, Divider, CardActions } from '@material-ui/core';
import ClampLines from 'react-clamp-lines';
import { observer } from 'mobx-react';
import SparkButton, { SparkObject } from './SparkButton';

const useStyles = makeStyles(theme => ({
    gridItem: {
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'center'
    },
    cardContent: {
        paddingBottom: theme.spacing(1.5),
        overflow: 'hidden'
    },
    chips: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    },
    cardActions: {
        justifyContent: 'space-between',
        display: 'flex',
        padding: theme.spacing(1.5)
    },
    button: {
        flexShrink: 0,
        alignSelf: 'flex-end',
        marginLeft: theme.spacing(2),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    titleAndSubtitle: {
        flexShrink: 1,
        overflow: 'hidden'
    },
    title: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        wordBreak: 'break-word',
        '& > h2': {
            fontWeight: 600,
            marginBottom: '2px',
            marginTop: theme.spacing(1)
        }
    },
    subtitle: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        textOverflow: 'ellipsis',
        marginBottom: theme.spacing(1)
    },
    growAnimation: {
        flexGrow: 1,
        maxWidth: 650,
        overflow: 'hidden',
        '&:hover': {
            transform: 'scale(1.01)'
        }
    },
    bottomContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(0, -2, 0, -2),
    }
}));

interface CardGridItemProps<T> {
    title: string;
    subtitle?: string | JSX.Element;
    description: string;
    id: string;
    onClick: () => void;
    object?: T;
    columns: 1 | 2 | 3 | 4;
    chips?: React.ReactNode[];
}

enum TitleWrapSetting {
    OneLine = 1,
    TwoLines = 2,
    ThreeLines = 3
}

// TODO: adjust for width of card in sm and xs

const maxTitleLengthOneLine = 36;
const maxTitleLengthTwoLines = 72;
const MAX_DESCRIPTION_LINES = 6;

const getTitleWrapSetting = (title: string) => {
    let titleWrap = TitleWrapSetting.ThreeLines;
    if (title.length < maxTitleLengthOneLine) {
        titleWrap = TitleWrapSetting.OneLine;
    } else if (title.length < maxTitleLengthTwoLines) {
        titleWrap = TitleWrapSetting.TwoLines;
    }
    return titleWrap;
}

const CardGridItem = observer(<T extends SparkObject>(props: CardGridItemProps<T>) => {

    /********* React hooks *********/
    const classes = useStyles();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    // TODO: Link after animation
    // const onAnimationEnding = () => {
    //     navigate(props.link);
    // }

    /********* Helper constants *********/

    const titleWrap = smDown ? undefined : getTitleWrapSetting(props.title);

    const gridItemSize = 12 / props.columns as 3 | 4 | 6 | 12;

    const learnMoreButton = (
        <Button variant='outlined' onClick={props.onClick} className={classes.button}>
            Learn More
        </Button>
    );

    const chipsSection = props.chips ? <div className={classes.chips}>{props.chips}</div> : null;

    /********* Render *********/

    return (
        <Grid item xs={gridItemSize} className={classes.gridItem} id={props.id}>
            <div className={classes.growAnimation}>
                <Card>
                    <CardActionArea onClick={props.onClick}>
                        <CardContent className={classes.cardContent}>
                            <div className={classes.header}>
                                <div className={classes.titleAndSubtitle}>
                                    <Tooltip title={props.title}>
                                        <span>
                                            <ClampLines
                                                lines={titleWrap}
                                                text={props.title || ''}
                                                id={props.id + '-title'}
                                                buttons={false}
                                                className={classes.title}
                                                innerElement='h2'
                                            />

                                        </span>
                                    </Tooltip>
                                    {props.subtitle &&
                                        <Tooltip title={props.subtitle}>
                                            <Typography variant="body2" noWrap className={classes.subtitle}>
                                                {props.subtitle || ''}
                                            </Typography>
                                        </Tooltip>
                                    }
                                </div>
                                {props.object &&
                                    <SparkButton sparkObject={props.object} displayHoverText={false} />
                                }
                            </div>
                            <Typography component="div">
                                <ClampLines
                                    lines={titleWrap ? MAX_DESCRIPTION_LINES - titleWrap : titleWrap}
                                    text={props.description || ''}
                                    id={props.id + '-description'}
                                    buttons={false}
                                />
                            </Typography>
                            {props.chips
                                ? props.object
                                    ? chipsSection
                                    : <div className={classes.bottomContent}>
                                        {chipsSection}
                                        {/* {learnMoreButton} */}
                                    </div>
                                : null
                            }
                        </CardContent>
                    </CardActionArea>
                    {/* {props.object &&
                        <React.Fragment>
                            <Divider variant='fullWidth' className={classes.divider} />
                            <CardActions className={classes.cardActions}>
                                <SparkButton sparkObject={props.object} displayHoverText={true} />
                                {learnMoreButton}
                            </CardActions>
                        </React.Fragment>
                    } */}
                </Card>
            </div>
        </Grid>
    );
});

export default CardGridItem;