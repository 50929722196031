import { observable, action, makeObservable } from "mobx";

interface IMenuState {
    open: boolean;
}

export class MenuState implements IMenuState {
    open: boolean;

    constructor(open?: boolean) {
        makeObservable(this, {
            open: observable,
            setOpen: action
        });

        this.open = open || false;
    }

    setOpen(open: boolean) {
        this.open = open;
    }
}