import { observable, action, makeObservable } from "mobx";
import { Address, IAddress } from "./Address";

export interface IAddressCollection {
    addresses: Address[];
}

export class AddressCollection implements IAddressCollection {
    addresses = [] as Address[];

    constructor(addresses?: IAddress[]) {
        makeObservable(this, {
            addresses: observable,
            setAddresses: action,
            onAddressUpdated: action.bound,
            onAddressDeleted: action.bound
        });

        if (addresses !== undefined) {
            this.addresses = addresses.map(address => new Address(address));
        }
    }

    setAddresses(addresses: Address[]) {
        this.addresses = addresses;
    }

    onAddressUpdated(data: { address: Address }) {
        try {
            const updatedAddress = data.address;
            if (updatedAddress) {
                const index = this.addresses.findIndex(address => address.id === updatedAddress.id);
                if (index === -1) {
                    this.addresses.push(updatedAddress);
                } else {
                    this.addresses[index] = updatedAddress;
                }
            }
        } catch (error) {
            if (typeof error === 'string') {
                throw new Error(error);
            }
        }
    }

    onAddressDeleted(data: { address: Address }) {
        try {
            const deletedAddress = data.address;
            if (deletedAddress) {
                let newAddresses = [] as Address[];
                this.addresses.forEach(address => {
                    if (address.id !== deletedAddress.id) {
                        newAddresses.push(address);
                    }
                })
                this.addresses = newAddresses;
            }
        } catch (error) {
            if (typeof error === 'string') {
                throw new Error(error);
            }
        }
    }
}