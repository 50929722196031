import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { PowerSocketUs } from "mdi-material-ui";
import REMAvatar from "../../shared/modules/rem-conversion/components/Avatar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
            width: '75px',
            height: '75px',
        },
    })
);

const ErrorAvatar = () => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <REMAvatar className={classes.avatar}>
            <PowerSocketUs fontSize='large' />
        </REMAvatar>
    );
};

export default ErrorAvatar;