import { makeStyles, Theme, createStyles, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Store, HomeHeart } from "mdi-material-ui";
import { OrganizationType, StubOrganization, StubOrganizationValidationChecks } from "../../../../stores/models/StubOrganization";
import { SingleSelectionOptionCollection } from "../../../../stores/models/SingleSelectionOptionCollection";
import IconTileSelector, { IIconTileSelectorOption } from "../../../Shared/IconTileSelector";
import { StepProps } from "../../../../stores/models/Step";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: theme.spacing(1)
        },
        title: {
            marginBottom: theme.spacing(3),
        },
        gridItem: {
            maxWidth: '275px',
            [theme.breakpoints.down('xs')]: {
                flexBasis: '100%',
                '&:first-child': {
                    marginBottom: theme.spacing(1)
                }
            }
        },
        organizationTypes: {
            display: 'flex',
            justifyContent: 'center'
        },
        hidden: {
            display: 'none'
        },
        selected: {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        }
    }),
);

enum OrganizationTypeOption {
    Nonprofit = 'Nonprofit Organization',
    Forprofit = 'For-profit Organization'
}

const organizationTypeOptions: IIconTileSelectorOption[] = [{
    primaryText: OrganizationTypeOption.Nonprofit,
    secondaryText: 'A tax-exempt organization looking to recruit volunteers',
    value: OrganizationTypeOption.Nonprofit,
    icon: HomeHeart
}, {
    primaryText: OrganizationTypeOption.Forprofit,
    secondaryText: 'A business looking to recruit volunteers',
    value: OrganizationTypeOption.Forprofit,
    icon: Store
}];

const OrganizationTypeSelector = observer((props: StepProps<StubOrganization>) => {

    /***** Helper constant and method *****/

    const stubOrganization = props.stepState.stepObject;

    const getOptions = () => {
        const organizationType = stubOrganization.type;
        if (organizationType === OrganizationType.None) {
            return new SingleSelectionOptionCollection('value', organizationTypeOptions);
        } else {
            const selectedValue = organizationType === OrganizationType.Nonprofit
                ? OrganizationTypeOption.Nonprofit
                : OrganizationTypeOption.Forprofit;
            return new SingleSelectionOptionCollection('value', organizationTypeOptions, [{ value: selectedValue as string }]);
        }
    }

    /***** React hooks *****/

    const classes = useStyles();
    const submitButton = useRef<HTMLButtonElement>(null);

    /***** State *****/

    const [options] = useState(getOptions());

    /***** Effects *****/

    useEffect(() => {
        // Update which validation checks should be run for the stub organization
        options.setSelections([]);
        stubOrganization.setValidationChecks(StubOrganizationValidationChecks.OrganizationType);
    }, []);

    useEffect(() => {
        updateOrganizationType();
    }, [options.selection]);

    /***** Helper method *****/

    const updateOrganizationType = () => {
        if (options.selection === undefined) {
            stubOrganization.setOrganizationType(OrganizationType.None);
        } else {
            if (options.selection?.object.value === OrganizationTypeOption.Nonprofit) {
                stubOrganization.setOrganizationType(OrganizationType.Nonprofit);
            } else if (options.selection?.object.value === OrganizationTypeOption.Forprofit) {
                stubOrganization.setOrganizationType(OrganizationType.Forprofit);
            }
            if (submitButton.current) {
                submitButton.current.click();
            }
        }
    }

    /***** Render *****/

    return (
        <div className={classes.root}>
            <Typography variant="h5" color="textSecondary" className={classes.title}>
                Select your organization type to get started.
            </Typography>
            <div className={classes.organizationTypes}>
                <IconTileSelector optionCollection={options} classes={{ gridItem: classes.gridItem, selected: classes.selected }} />
            </div>
            <Button type='submit' ref={submitButton} className={classes.hidden}></Button>
        </div>
    );
});

export default OrganizationTypeSelector;