import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { getDate, isPast, isSameMonth, isToday, startOfDay } from "date-fns";
import { Button, createStyles, makeStyles, Popover, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Dictionary } from "../../../logic/Dictionaries";
import clsx from 'clsx';
import { CalendarEvent } from "../../../stores/models/CalendarEvent";
import useWindowResizeEvent from "../Hooks/OnResize";
import { DAYS_OF_THE_WEEK } from "./DatesToDisplay";
import useBrowserFontSize, { DEFAULT_BROWSER_FONT_SIZE_PX } from "../../../shared/general/hooks/useBrowserFontSize";

const EVENT_LABEL_LINE_HEIGHT = 1.3125; // rem

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& > $row:first-child': {
                borderTop: '1px lightgray solid'
            }
        },
        row: {
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            '& > $cell:last-child': {
                borderRight: '1px lightgray solid',
            }
        },
        fullScreenDateRow: {
            flex: '1 1 0px',
        },
        dayOfWeek: {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        dateRow: {
            // flex: '1 1 100%'
            height: 120
        },
        headerRow: {
            flexShrink: 0,
            background: '#08c4d038',
            '& > $cell:not(:first-child)': {
                borderLeft: 'none',
            }
        },
        dateCell: {
            // minHeight: '72px',
        },
        cell: {
            width: 'calc(100% / 7)',
            overflow: 'hidden',
            textAlign: 'left',
            borderLeft: '1px lightgray solid',
            borderBottom: '1px lightgray solid',
            padding: theme.spacing(.5, 1),
            display: 'flex',
            flexDirection: 'column'
        },
        popover: {
            '& $today': {
                background: 'white',
            },
            width: '250px',
            display: 'flex',
            '& > $cell': {
                width: '100%',
                border: 'none',
                padding: theme.spacing(1, 2)
            }
        },
        today: {
            background: '#08c4d038',
        },
        eventList: {
            flexGrow: 1,
            // overflow: 'hidden',
            '& > ::marker': {
                color: theme.palette.success.main
            },
        },
        listItem: {
            '&:hover': {
                background: theme.palette.action.hover
            },
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            lineHeight: `${EVENT_LABEL_LINE_HEIGHT}rem`,
        },
        bullet: {
            backgroundColor: theme.palette.success.main,
            borderRadius: '50%',
            width: 6,
            height: 6,
            flex: '0 0 6px',
            marginRight: theme.spacing(.75),
        },
        eventLabel: {
            minWidth: 0
        },
        textWrap: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block',
            textOverflow: 'ellipsis'
        },
        bold: {
            fontWeight: 550
        },
        moreIndicator: {
            background: theme.palette.success.main,
            color: 'white',
            cursor: 'pointer',
            textAlign: 'center',
            lineHeight: `${EVENT_LABEL_LINE_HEIGHT}rem`,
            height: `${EVENT_LABEL_LINE_HEIGHT - 0.25}rem`,
            padding: 4,
            width: '100%',
            textTransform: 'none',
            '&:hover': {
                background: theme.palette.success.dark,
                boxShadow: 'none'
            },
        },
        past: {
            color: 'gray'
        }
    })
);

/********* Helper constants *********/

const MONTH_VIEW_ID = 'month-view';
const DAYS_OF_THE_WEEK_HEADER_ID = 'days-of-the-week-header';

/********* Calendar Month Component *********/

interface CalendarMonthProps<T> {
    date: Date;
    datesToDisplay: Dictionary<number, Date[]>;
    eventsToDisplay: Dictionary<number, CalendarEvent<T>[]>; // Key: startOfDay(Date).getTime()
    onDateClicked: (date: Date) => void;
    onEventClicked: (event: CalendarEvent<T>) => void;
    fullScreen?: boolean;
    eventPopout?: (event: CalendarEvent<T>) => JSX.Element;
    replaceSelectedEvent?: (currentEvent: CalendarEvent<T>) => CalendarEvent<T> | undefined;
    widthToShowDayAbbreviations?: number;
}

const CalendarMonth = observer(<T extends any>(props: CalendarMonthProps<T>) => {

    /********* React Hooks *********/

    const classes = useStyles();
    const theme = useTheme();
    const switchToDayAbbreviations = useMediaQuery(theme.breakpoints.down(props.widthToShowDayAbbreviations !== undefined ? props.widthToShowDayAbbreviations : 1030));
    const browserFontSize = useBrowserFontSize() || DEFAULT_BROWSER_FONT_SIZE_PX;

    /********* State *********/

    const [selectedAnchor, setSelectedAnchor] = useState<Element>();
    const [selectedEventAnchor, setSelectedEventAnchor] = useState<Element>();
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent<T>>();

    const calculateMaxEventsToDisplayPerCell = () => {
        if (props.fullScreen) {
            const eventLists = document.getElementsByClassName(classes.eventList);
            if (eventLists.length > 0) {
                const eventList = eventLists[0];
                const eventListHeight = eventList.clientHeight;
                const numEventsToDisplay = eventListHeight / (EVENT_LABEL_LINE_HEIGHT * browserFontSize);
                return Math.floor(numEventsToDisplay);
            }
            return 1;
        }
        return 4;
    }

    const [maxEventsPerCell, setMaxEventsPerCell] = useState(calculateMaxEventsToDisplayPerCell());

    /********* Effects *********/

    useEffect(() => {
        setMaxEventsPerCell(calculateMaxEventsToDisplayPerCell());
    }, [props.datesToDisplay]);

    useEffect(() => {
        if (selectedEvent && props.replaceSelectedEvent) {
            const replacementEvent = props.replaceSelectedEvent(selectedEvent);
            setSelectedEvent(replacementEvent);
        }
    }, [props.eventsToDisplay]);

    const onWindowResizedCallback = (width: number, height: number) => {
        setMaxEventsPerCell(calculateMaxEventsToDisplayPerCell());
    }

    const onWindowResized = useWindowResizeEvent(onWindowResizedCallback);

    /********* Event Handlers *********/

    const onMoreIndicatorClicked = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, date: Date) => {
        setSelectedAnchor(event.currentTarget);
        setSelectedDate(date);
        event.stopPropagation();
    }

    const onEventClicked = (mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, calendarEvent: CalendarEvent<T>) => {
        props.onEventClicked(calendarEvent);
        setSelectedEvent(calendarEvent);
        setSelectedEventAnchor(mouseEvent.currentTarget);
        mouseEvent.stopPropagation();
    }

    /********* Helper Methods *********/

    const getCell = (date: Date, expanded?: boolean) => {
        const dateNotInCurrentMonth = !isSameMonth(props.date, date);
        const dayId = startOfDay(date).getTime();
        const eventsForDay = props.eventsToDisplay ? props.eventsToDisplay[dayId] : [];
        return (
            <div
                className={clsx(classes.cell, classes.dateCell, isToday(date) ? classes.today : undefined)}
                key={`${date.toDateString()}`}
                onClick={() => props.onDateClicked(date)}
            >
                <Typography color={dateNotInCurrentMonth ? 'textSecondary' : undefined}>
                    {getDate(date)}
                </Typography>
                <div className={classes.eventList}>
                    {eventsForDay?.map((event, eventIndex) => {
                        if (!expanded) {
                            const pastMaxDisplayableEvents = eventIndex + 1 > maxEventsPerCell;
                            const notLastEvent = eventsForDay.length > eventIndex + 1;
                            const shouldReplaceEventWithMoreIndicator = eventIndex + 1 === maxEventsPerCell && notLastEvent;
                            if (pastMaxDisplayableEvents) {
                                // Display nothing for the event
                                return null;
                            } else if (shouldReplaceEventWithMoreIndicator) {
                                // Replace the event with a "+ <# events not displayed> more" button
                                const remainingEvents = eventsForDay.length - eventIndex;
                                return (
                                    <Button
                                        variant='contained'
                                        className={classes.moreIndicator}
                                        onClick={(event) => onMoreIndicatorClicked(event, date)}
                                        key={`event-${eventIndex}`}
                                    >
                                        <span className={classes.textWrap}>{`+ ${remainingEvents} more`}</span>
                                    </Button>
                                )
                            }
                        }

                        // Display the event
                        const ended = isPast(event.endTime);
                        return (
                            <div
                                key={`event-${eventIndex}`}
                                onClick={(clickEvent) => onEventClicked(clickEvent, event)}
                                className={clsx(classes.listItem, ended ? classes.past : undefined)}
                            >
                                {event.icon
                                    ? event.icon
                                    : <span className={classes.bullet} />
                                }
                                <Typography variant='body2' className={clsx(classes.textWrap, classes.eventLabel)}>
                                    <span className={classes.bold}>{event.formattedStartTime}</span>
                                    <span>{` ${event.label}`}</span>
                                </Typography>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const getRows = () => {
        let rows: JSX.Element[] = [];
        const numWeeks = Object.keys(props.datesToDisplay).length;
        for (let week = 1; week <= numWeeks; week++) {
            rows.push(
                <div className={clsx(classes.row, props.fullScreen ? classes.fullScreenDateRow : classes.dateRow)} key={week}>
                    {props.datesToDisplay[week].map((day) => getCell(day))}
                </div>
            )
        }
        return rows;
    }

    /********* Render *********/

    return (
        <div id={MONTH_VIEW_ID} className={classes.root}>
            <div className={clsx(classes.row, classes.headerRow)} id={DAYS_OF_THE_WEEK_HEADER_ID}>
                {DAYS_OF_THE_WEEK.map((day, index) => {
                    return (
                        <div className={classes.cell} key={`header-cell-${index}`}>
                            <Typography className={classes.dayOfWeek}>
                                {switchToDayAbbreviations ? day.abbreviation : day.full}
                            </Typography>
                        </div>
                    )
                })}
            </div>
            {getRows()}
            <Popover
                open={selectedAnchor !== undefined}
                onClose={() => setSelectedAnchor(undefined)}
                anchorEl={selectedAnchor}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <div className={classes.popover}>
                    {selectedDate && getCell(selectedDate, true)}
                </div>
            </Popover>
            {props.eventPopout &&
                <Popover
                    open={selectedEventAnchor !== undefined}
                    onClose={() => setSelectedEventAnchor(undefined)}
                    anchorEl={selectedEventAnchor}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    {selectedEvent && props.eventPopout(selectedEvent)}
                </Popover>
            }
        </div>
    )
});

export default CalendarMonth;