import { useIsOrgPortal } from ".";
import { getAboutPagePath } from "../../Links";
import { useContext } from "react";
import { RootContext } from "../../../../stores";

// Gets the current organization's public page link
export const useOrgPublicPageLink = () => {

    const rootStore = useContext(RootContext);
    const { userStore } = rootStore;
    const isOrgPortal = useIsOrgPortal();
    
    if (isOrgPortal) {
        return getAboutPagePath();
    } else {
        return userStore.user.organization?.organizationPage || '#'
    }
}