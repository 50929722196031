import { action, computed, makeObservable, observable } from "mobx";
import { validateFormInputItem } from "../../logic/ValidationChecks/FormInputItemValidation";
import { Fields } from "./Fields";
import { FormItemTypes, IFormItem } from "./FormSection";

export type InputType = FormItemTypes.ShortAnswer | FormItemTypes.MultipleChoice | FormItemTypes.Checkbox;

export const isInputItem = (x: IFormItem): x is FormInputItem => {
    return x.type === FormItemTypes.ShortAnswer ||
        x.type === FormItemTypes.MultipleChoice ||
        x.type === FormItemTypes.Checkbox;
}

export interface IFormInputItemFields {
    value?: string | string[];
    otherValue?: string;
}

export interface IFormInputItem extends IFormInputItemFields {
    id: string;
    title: string;
    description?: string;
    required: boolean;
    type: InputType;
    options?: string[];
    includeOtherOption?: boolean;
}

export class FormInputItem extends Fields<IFormInputItemFields, FormInputItem> implements IFormInputItem {
    @observable id: string;
    @observable title: string;
    @observable description?: string;
    @observable required: boolean;
    @observable type: InputType;
    @observable options?: string[];
    @observable includeOtherOption?: boolean;
    @observable value?: string | string[];
    @observable otherSelected?: boolean;
    @observable otherValue?: string;

    constructor(item: IFormInputItem) {
        super();
        makeObservable(this);

        this.id = item.id;
        this.title = item.title;
        this.description = item.description;
        this.required = item.required;
        this.type = item.type;
        this.options = item.options;
        this.includeOtherOption = item.includeOtherOption;
    }

    @action setValue(value: string | string[]) {
        this.value = value;
    }

    @action addValue(value: string) {
        if (this.value === undefined) {
            this.value = [value];
        } else if (Array.isArray(this.value) && this.value.findIndex(option => option === value) === -1) {
            this.value.push(value);
        }
    }

    @action removeValue(value: string) {
        if (Array.isArray(this.value)) {
            const indexOfValue = this.value.findIndex(option => option === value)
            if (indexOfValue !== -1) {
                this.value.splice(indexOfValue, 1);
            }
        }
    }

    @action setOtherSelected(selected: boolean) {
        this.otherSelected = selected;
    }

    @action setOtherValue(value: string) {
        this.otherValue = value;
    }

    @computed get validationErrors() {
        return validateFormInputItem(this);
    }
}