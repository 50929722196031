import { useContext, useEffect, useState } from "react";
import { Grid, Typography, makeStyles, Theme, createStyles, Paper, Container, Card, CardContent, TableCell, IconButton, Tooltip, useMediaQuery, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext } from "../../../../../stores";
import RecordTable from "../RecordTable";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import { Option } from "../../../../../stores/models/Option";
import { ISortableTableHeader, TableHeader } from "../../../../../stores/models/TableHeader";
import { getOpportunitiesTableLink, getSchedulingLink, getServiceDataLink, getVolunteerDetailsLink, getVolunteersLink } from "../../../../Navigation/Links/UrlConstructors";
import BarGraphWidget from "./BarGraphWidget";
import DonutGraphWidget from "./DonutGraphWidget";
import CalendarHeatMapWidget from "./CalendarHeatMapWidget";
import { DashboardData, TopVolunteer } from "../../../../../stores/models/DashboardData";
import LoadingIndicator from "../../../../Shared/LoadingIndicator";
import { roundToNumDecimalPlaces } from "../../../../../logic/UtilityFunctions";
import useShiftsToDisplay from "../../../../Shared/Calendar/ShiftsToDisplay";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { ShiftCollection } from "../../../../../stores/models/ShiftCollection";
import { Dictionary } from "../../../../../logic/Dictionaries";
import { ArrowTopRight, Information } from "mdi-material-ui";
import clsx from "clsx";
import useCurrentWidth from "../../../../Shared/Hooks/WindowResize";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import { LATEST_DOLLAR_VALUE_OF_VOLUNTEER_HOUR } from "../../../../../data/config";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            padding: theme.spacing(2),
            position: 'relative',
            overflow: 'hidden'
        },
        card: {
            position: 'relative'
        },
        cardTitle: {
            marginRight: '2.5rem'
        },
        cardUnits: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap'
        },
        number: {
            color: theme.palette.primary.main
        },
        widgetTitle: {
            marginBottom: theme.spacing(1)
        },
        multiItemGridCell: {
            '& > :not(:first-child)': {
                marginTop: theme.spacing(2)
            }
        },
        linkIcon: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: theme.palette.action.active
        },
        // zeroStateButton: {
        //     marginTop: theme.spacing(1),
        // }
        sectionTitle: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: theme.spacing(-2, -3, 2, -3),
            padding: theme.spacing(1.5, 3)
        },
        button: {
            marginTop: `.5rem`
        }
    }),
);

enum NumericalDataCards {
    OpportunityCount = 'Opportunities',
    ActiveVolunteers = 'Active Volunteers',
    Inquiries = 'Inquiries',
    UnconfirmedHours = 'Unconfirmed Hours'
}

type CardData = { title: string, field: keyof DashboardData, link?: string } //, zeroStateButtonText: string };

const cardData: CardData[] = [{
    title: NumericalDataCards.OpportunityCount,
    field: 'numActivePosts',
    link: getOpportunitiesTableLink(),
    // zeroStateButtonText: 'Add Opportunity'
}, {
    title: NumericalDataCards.ActiveVolunteers,
    field: 'numActiveVolunteers',
    link: getVolunteersLink(),
    // zeroStateButtonText: 'Add Volunteer'
}, {
    title: NumericalDataCards.Inquiries,
    field: 'numInquiries',
    link: getVolunteersLink(),
    // zeroStateButtonText: 'Add Volunteer'
}, {
    title: NumericalDataCards.UnconfirmedHours,
    field: 'unconfirmedHours',
    link: getServiceDataLink(),
    // zeroStateButtonText: 'Add Service Hours'
}];

const headCells = ([
    { id: 'name', alignment: 'left', disablePadding: false, label: 'Volunteer', sortable: false },
    { id: 'hours', alignment: 'right', disablePadding: false, label: 'Hours', sortable: true, sortValue: 'hours' }
] as ISortableTableHeader<TopVolunteer>[]).map(headCell => new TableHeader(headCell));

const graphColors = ["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560", "#f1e15b", "#e8a838"];

const TWO_MONTHS_WIDTH = 300;
const THREE_MONTHS_WIDTH = 330;
const FOUR_MONTHS_WIDTH = 355;
const FIVE_MONTHS_WIDTH = 385;
const SIX_MONTHS_WIDTH = 415;

const Dashboard = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const organizationStore = rootStore.organizationStore;
    const organization = rootStore.userStore.user?.organization;
    const organizationId = organization?.id;
    const width = useCurrentWidth();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const navigate = useNavigateInternally();

    /********* Helper methods *********/

    const getUnfilledShiftsData = () => {
        let unfilledShifts = {} as Dictionary<number, { value: number, day: string }>;
        let dayInCurrentMonth = new Date();
        (Object.keys(shiftsToDisplay) as unknown as number[]).forEach(date => {
            shiftsToDisplay[date].forEach(shift => {
                if (!shift.unlimitedSlots) {
                    if (unfilledShifts[date]) {
                        unfilledShifts[date].value += shift.slotsLeft;
                    } else {
                        dayInCurrentMonth.setTime(date);
                        unfilledShifts[date] = {
                            value: shift.slotsLeft,
                            day: format(dayInCurrentMonth, 'yyyy-MM-dd')
                        }
                    }
                }
            });
        });
        return Object.values(unfilledShifts);
    }

    /********* State *********/

    const [today] = useState(new Date());
    const [topVolunteers, setTopVolunteers] = useState<OptionCollection<'id', TopVolunteer>>();
    const [dashboardData, setDashboardData] = useState<DashboardData>();
    const [shiftCollection, setShiftCollection] = useState(new ShiftCollection());
    const [refreshNeeded, setRefreshNeeded] = useState(false);

    const shiftsToDisplay = useShiftsToDisplay(
        startOfMonth(today),
        endOfMonth(today),
        shiftCollection,
        refreshNeeded
    );

    const [unfilledShifts, setUnfilledShifts] = useState(getUnfilledShiftsData());

    /********* Effects *********/

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (dashboardData) {
            let numMonthsToDisplay: number;
            if (width < TWO_MONTHS_WIDTH) {
                numMonthsToDisplay = 1;
            } else if (width < THREE_MONTHS_WIDTH) {
                numMonthsToDisplay = 2;
            } else if (width < FOUR_MONTHS_WIDTH) {
                numMonthsToDisplay = 3;
            } else if (width < FIVE_MONTHS_WIDTH) {
                numMonthsToDisplay = 4;
            } else if (width < SIX_MONTHS_WIDTH) {
                numMonthsToDisplay = 5;
            } else {
                numMonthsToDisplay = 6;
            }
            dashboardData.setNumMonthsToDisplay(numMonthsToDisplay);
        }
    }, [width, dashboardData])

    useEffect(() => {
        if (dashboardData) {
            setTopVolunteers(new OptionCollection('id', dashboardData.topVolunteers));
        }
    }, [dashboardData]);

    useEffect(() => {
        if (dashboardData?.shifts) {
            const newShiftCollection = new ShiftCollection(dashboardData?.shifts);
            setShiftCollection(newShiftCollection);
            setRefreshNeeded(true);
        }
    }, [dashboardData?.shifts]);

    useEffect(() => {
        setUnfilledShifts(getUnfilledShiftsData());
    }, [shiftsToDisplay]);

    /********* Helper methods *********/

    const loadData = async () => {
        if (organizationId === undefined) return;
        const response = await organizationStore.getDashboardData(organizationId);
        if (!response) return;
        setDashboardData(response);
    }

    /********* Event handlers *********/

    const onVolunteerClicked = (volunteerId: number) => {
        followLink(getVolunteerDetailsLink(volunteerId));
    }

    const followLink = (link: string) => {
        navigate(link);
    }

    const onPublishClicked = () => {
        organizationStore.patchOrganization({ portalMode: "Published" });
    }

    /********* Render *********/

    const linkIcon = (link?: string) => (
        <IconButton className={classes.linkIcon} onClick={link ? () => followLink(link) : undefined}>
            <ArrowTopRight fontSize="small" />
        </IconButton>
    )

    const barGraph = dashboardData?.hasServiceHoursDataInLastXMonths && (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="h6" className={classes.cardTitle}>
                    Monthly Volunteer Hours
                </Typography>
                {linkIcon(getServiceDataLink())}
                <BarGraphWidget
                    data={dashboardData.monthlyServiceHoursBarGraphData}
                    colors={graphColors}
                />
            </CardContent>
        </Card>
    );

    const donutGraph = dashboardData && (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="h6" className={classes.cardTitle}>
                    Hours by Opportunity (YTD)
                </Typography>
                {linkIcon(getServiceDataLink())}
                <DonutGraphWidget
                    data={dashboardData.yearlyTotalsByOpportunity}
                    fieldMapping={{ id: 'position', label: 'position', value: 'total', colorPosition: 'colorPosition' }}
                    colors={graphColors}
                />
            </CardContent>
        </Card>
    );

    if (!dashboardData) {
        return <LoadingIndicator />;
    }
    return (
        <div className={classes.root}>
            <Container>
                {/* Welcome Message */}
                <Grid container spacing={2}>
                    {organization?.portalMode !== 'Published'
                        ? <Grid item xs={12}>
                            <Alert severity="info">
                                <AlertTitle>You're in Setup Mode</AlertTitle>
                                While in Setup Mode, no one else will be able to access your organization's opportunity posts without a direct link. You can use this mode to prepare your opportunity posts, import your volunteers, assign roles and permissions to volunteers, and set up your branded organization-specific portal. When you're ready for your opportunity posts to be public and your volunteers to be invited to the platform, click the Publish button.
                                <div>
                                    <Button variant="contained" color="primary" className={classes.button} onClick={onPublishClicked}>
                                        Publish
                                    </Button>
                                </div>
                            </Alert>
                        </Grid>
                        : null
                    }
                    {dashboardData.monthlyServiceHours.length === 0 &&
                        <Grid item xs={12}>
                            <Paper className={clsx(classes.paper)}>
                                <div className={classes.sectionTitle}>
                                    <Typography variant="h5">
                                        Welcome to your Dashboard!
                                    </Typography>
                                </div>
                                <Typography>
                                    As you create opportunities, recruit volunteers, and track their service hours, you'll be able to track your progress here.
                                </Typography>
                            </Paper>
                        </Grid>
                    }
                    {/* Cards with Data Totals */}
                    {cardData.map((dataElement, index) => {
                        return (
                            <Grid item xs={12} sm={6} md={6} lg={3} key={`grid-item-${index}`}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h4" className={classes.cardTitle}>
                                        <span className={classes.number}>{dashboardData[dataElement.field]}</span>
                                    </Typography>
                                    {linkIcon(dataElement.link)}
                                    <Typography variant="h6" className={classes.cardUnits}>
                                        {dataElement.title}
                                    </Typography>
                                    {/* {dashboardData[dataElement.field] === 0 &&
                                        <Button
                                            // variant="contained"
                                            color={'primary'}
                                            className={classes.zeroStateButton}
                                        >
                                            {dataElement.zeroStateButtonText}
                                        </Button>
                                    } */}
                                </Paper>
                            </Grid>
                        )
                    })}
                    {/* Full width Bar Graph of Monthly Service Data */}
                    {(smDown && dashboardData?.hasServiceHoursDataInLastXMonths) &&
                        <Grid item xs={12}>
                            {barGraph}
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} md={7} lg={8} className={classes.multiItemGridCell}>
                        {/* Bar Graph of Monthly Service Data */}
                        {!smDown && barGraph}
                        {/* Top Volunteers Table */}
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h6" className={clsx(classes.widgetTitle, classes.cardTitle)}>
                                    Top Volunteers (YTD)
                                </Typography>
                                {linkIcon(getVolunteersLink())}
                                {topVolunteers &&
                                    <RecordTable
                                        records={topVolunteers}
                                        tableHeaderCells={headCells}
                                        orderBy={'hours'}
                                        order={'desc'}
                                        displayFunctions={[
                                            (option: Option<TopVolunteer>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                                        {option.object.firstName} {option.object.lastName}
                                                    </TableCell>
                                                );
                                            },
                                            (option: Option<TopVolunteer>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="right" key={`${rowIndex}-${cellIndex}`}>
                                                        {roundToNumDecimalPlaces(option.object.hours, 2)}
                                                    </TableCell>
                                                );
                                            },
                                        ]}
                                        onRowClicked={(option: Option<TopVolunteer>, rowIndex: number) => {
                                            onVolunteerClicked(option.object.id);
                                        }}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4} className={classes.multiItemGridCell}>
                        {/* Donut Graph of Yearly Hours by Opportunity */}
                        {dashboardData.hasDataFromCurrentYear && donutGraph}
                        {/* Dollar Value of Volunteer Hours */}
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h6" className={classes.cardTitle}>
                                    Value of Volunteer Hours (YTD)
                                </Typography>
                                <Tooltip
                                    title={
                                        <Typography align="center">
                                            The estimated national value of each volunteer hour is currently ${LATEST_DOLLAR_VALUE_OF_VOLUNTEER_HOUR.toFixed(2)} according to Independent Sector.
                                        </Typography>
                                    }
                                    arrow
                                >
                                    <IconButton className={classes.linkIcon}>
                                        <Information />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="h4">
                                    <span className={classes.number}>
                                        ${Number((roundToNumDecimalPlaces(dashboardData?.totalHoursYTD, 1) * LATEST_DOLLAR_VALUE_OF_VOLUNTEER_HOUR)).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                    </span>
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* Unfilled Shifts Heat Map */}
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h6" className={classes.cardTitle}>
                                    Unfilled Shifts
                                </Typography>
                                {linkIcon(getSchedulingLink())}
                                <CalendarHeatMapWidget data={unfilledShifts} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div >
    )
});

export default Dashboard;