import { useContext } from "react";
import { RootContext } from "../../../../stores";
import { observer } from "mobx-react";
import ScheduleViewer from "../../Shared/ScheduleViewer";
import { Shift } from "../../../../stores/models/Shift";
import { getVolunteerShiftDetailsLink, getVolunteerShiftsLink, volunteerShiftDetailsPathSegment } from "../../../Navigation/Links/UrlConstructors";
import { Container, Theme, useMediaQuery } from "@material-ui/core";
import { useNavigateInternally } from "../../../Navigation/Hooks";

const VolunteerShiftsPage = observer(() => {

    /********* React hooks *********/

    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    /********* Event handler *********/

    const loadShiftsForVolunteer = async (startTimeStamp: Date, endTimestamp: Date) => {
        const response = await volunteerStore.getVolunteerShifts(startTimeStamp, endTimestamp);
        response?.shifts.forEach(shift => {
            shift.setRegistered(true);
        });
        return response;
    }

    const onShiftClicked = (shift: Shift) => {
        navigate(getVolunteerShiftDetailsLink(shift.identificationData));
    }

    /********* Render *********/

    return (
        <Container>
            <ScheduleViewer
                title="Schedule"
                shiftDetailsPath={volunteerShiftDetailsPathSegment}
                loadInitialData={loadShiftsForVolunteer}
                onShiftClicked={onShiftClicked}
                scheduleLink={getVolunteerShiftsLink()}
                fullScreen
                useCardList={xsDown}
            />
        </Container>
    )
});

export default VolunteerShiftsPage;