import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import REMKeyboardDatePicker from "../../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        datePicker: {
            width: '10.9375rem',
            marginBottom: theme.spacing(1)
        },
        marginRight: {
            marginRight: theme.spacing(2)
        },
        bold: {
            fontWeight: 'bold'
        },
    }),
);

interface ReportRangeSelectionProps {
    startDate: Date | null;
    endDate: Date | null;
    rangeError: string;
    onStartDateChanged: (date: Date | null) => void;
    onEndDateChanged: (date: Date | null) => void;
}

const ReportRangeSelection = observer((props: ReportRangeSelectionProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <div className={classes.flex}>
            <div className={classes.marginRight}>
                <Typography className={classes.bold}>Start Date (inclusive)</Typography>
                <REMKeyboardDatePicker
                    id="date-picker"
                    format="MM/dd/yy"
                    // minDate={new Date(2019, 0, 1)}
                    maxDate={new Date()}
                    value={props.startDate}
                    onChange={(date, value) => props.onStartDateChanged(date)}
                    inputVariant="outlined"
                    required
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    className={classes.datePicker}
                    error={props.rangeError.length > 0}
                    helperText={props.rangeError.length > 0 ? props.rangeError : ""}
                />
            </div>
            <div>
                <Typography className={classes.bold}>End Date (inclusive)</Typography>
                <REMKeyboardDatePicker
                    id="date-picker"
                    format="MM/dd/yy"
                    maxDate={new Date()}
                    value={props.endDate}
                    onChange={(date, value) => props.onEndDateChanged(date)}
                    inputVariant="outlined"
                    required
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    className={classes.datePicker}
                    error={props.rangeError.length > 0}
                    helperText={""}
                />
            </div>
        </div>
    )
});

export default ReportRangeSelection;