import { RepetitionFrequency } from "../stores/models/RepetitionPattern";

export enum SingularFrequencies {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

export enum PluralFrequencies {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
    Years = 'years'
}

export default class RepetitionPatternMapper {

    static getSingularFrequencyDescriptor(frequency: RepetitionFrequency) {
        switch (frequency) {
            case RepetitionFrequency.Daily:
                return SingularFrequencies.Day;
            case RepetitionFrequency.Weekly:
                return SingularFrequencies.Week;
            case RepetitionFrequency.MonthlyOnDate:
                return SingularFrequencies.Month;
            case RepetitionFrequency.MonthlyOnDayOfWeek:
                return SingularFrequencies.Month;
            case RepetitionFrequency.Yearly:
                return SingularFrequencies.Year;
            default:
                return SingularFrequencies.Day;
        }
    }

    static getPluralFrequencyDescriptor(frequency: RepetitionFrequency) {
        switch (frequency) {
            case RepetitionFrequency.Daily:
                return PluralFrequencies.Days;
            case RepetitionFrequency.Weekly:
                return PluralFrequencies.Weeks;
            case RepetitionFrequency.MonthlyOnDate:
                return PluralFrequencies.Months;
            case RepetitionFrequency.MonthlyOnDayOfWeek:
                return PluralFrequencies.Months;
            case RepetitionFrequency.Yearly:
                return PluralFrequencies.Years;
            default:
                return PluralFrequencies.Days;
        }
    }

    static getCustomFrequencyForDescriptor(descriptor: SingularFrequencies | PluralFrequencies) {
        switch (descriptor) {
            case SingularFrequencies.Day:
            case PluralFrequencies.Days:
                return RepetitionFrequency.Daily;
            case SingularFrequencies.Week:
            case PluralFrequencies.Weeks:
                return RepetitionFrequency.Weekly;
            case SingularFrequencies.Month:
            case PluralFrequencies.Months:
                return RepetitionFrequency.MonthlyOnDayOfWeek;
            default:
                return RepetitionFrequency.Yearly;
        }
    }
}