import { observable, action, reaction, makeObservable } from "mobx";
import { ErrorDisplay } from "../../data/ErrorCodes";
import { IDisposer } from "mobx-utils";

export interface IDialogState {
    open: boolean;
    loading: boolean;
    error?: ErrorDisplay;
}

export class DialogState implements IDialogState {
    @observable open = false;
    @observable loading = false;
    @observable error?: ErrorDisplay;

    private openStateChangedReaction: IDisposer;

    constructor(open?: boolean) {
        makeObservable(this);

        if (open) {
            this.open = open;
        }

        this.openStateChangedReaction = reaction(
            () => [this.open],
            () => {
                if (this.open) {
                    this.error = undefined;
                }
            }
        );
    }

    @action setOpen(open: boolean) {
        this.open = open;
    }

    @action setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action setError(error?: ErrorDisplay) {
        this.error = error;
    }
}