import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";
import { PasswordResetData } from "../../stores/models/PasswordResetData";
import { MIN_PASSWORD_LENGTH } from "./CredentialsValidation";
import { hasLeadingOrTrailingSpace } from "../RegexValidation";

const passwordResetDataChecks: ValidationCheck<PasswordResetData>[] = [{
    name: 'password no leading or trailing spaces',
    condition: (passwordResetData: PasswordResetData) => {
        return !hasLeadingOrTrailingSpace(passwordResetData.newPassword);
    },
    error: new ValidationError('Your password should not have any leading or trailing spaces.', ['newPassword'])
}, {
    name: 'password length',
    condition: (passwordResetData: PasswordResetData) => {
        return passwordResetData.newPassword.length >= MIN_PASSWORD_LENGTH;
    },
    error: new ValidationError('Your password must be at least 6 characters long.', ['newPassword'])
}, {
    name: 'password confirmed',
    condition: (passwordResetData: PasswordResetData) => {
        return passwordResetData.passwordsMatch;
    },
    error: new ValidationError('Your passwords must match.', ['newPassword', 'confirmedPassword'], 'confirmedPassword')
}, {
    name: 'current password provided if required',
    condition: (passwordResetData: PasswordResetData) => {
        return !passwordResetData.currentPasswordRequired || (passwordResetData.currentPassword !== undefined && passwordResetData.currentPassword.length > 0);
    },
    error: new ValidationError('Please enter your current password.', ['currentPassword'], 'currentPassword')
}];

export default function validatePasswordResetData(passwordResetData: PasswordResetData) {
    return getValidationErrors(passwordResetDataChecks, passwordResetData);
}