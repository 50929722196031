import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { KeyboardDatePicker as MUIKeyboardDatePicker, KeyboardDatePickerProps as MUIKeyboardDatePickerProps } from "@material-ui/pickers";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            '& .MuiPickersCalendarHeader-transitionContainer': {
                height: '1.4375rem'
            }
        },
        dialog: {
            '& .MuiPickersCalendarHeader-transitionContainer': {
                height: '1.4375rem'
            },
            '& .MuiPickersToolbar-toolbar': {
                height: '6.25rem'
            }
        }
    })
);

interface KeyboardDatePickerProps extends MUIKeyboardDatePickerProps {

}

const REMKeyboardDatePicker = observer((props: KeyboardDatePickerProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIKeyboardDatePicker
            {...props}
            PopoverProps={{
                className: classes.popover
            }}
            DialogProps={{
                className: classes.dialog
            }}
        >
            {props.children}
        </MUIKeyboardDatePicker>
    );
});

export default REMKeyboardDatePicker;