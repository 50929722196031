

import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import FormElement from "../FormElement";
import { FormSection as FormSectionType } from "../../../../stores/models/FormSection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            '&:not(:first-child)': {
                marginTop: theme.spacing(3),
            },
            '& > :not(:first-child)': {
                marginTop: theme.spacing(1)
            }
        },
    }),
);

interface DialogFormSectionProps {
    section: FormSectionType;
}

const DialogFormSection = observer((props: DialogFormSectionProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <div className={classes.section}>
            {props.section.title.length > 0
                ? <Typography variant="h5">{props.section.title}</Typography>
                : null
            }
            {props.section.items.map((item, index) => {
                return (
                    <FormElement
                        item={item}
                        key={`section-${props.section.title}-item-${index}`}
                    />
                )
            })}
        </div>
    );
});

export default DialogFormSection;