import { useState, useEffect } from "react";
import { Shift } from "../../../stores/models/Shift";
import ShiftEntry from "./ShiftEntry";
import { Button, Typography, useMediaQuery, Theme, makeStyles, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import { ShiftAction } from "./ShiftCard";
import { ShiftCollection } from "../../../stores/models/ShiftCollection";
import { DialogState } from "../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../Shared/Dialogs/ThemedDialogWithSpinner";
import { Address } from "../../../stores/models/Address";
import { NumericallyIdentifiedOptionCollection } from "../../../stores/models/OptionCollection";
import { Option } from '../../../stores/models/Option';
import { Alert } from "@material-ui/lab";
import ShiftList from "./ShiftList";
import ShiftListDisplayMode, { ShiftListDisplayModeState } from "./ShiftListDisplayMode";
import { CalendarPlus } from "mdi-material-ui";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        },
        buttons: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                height: '2.5rem'
            },
            height: '3rem'
        }
    }),
);

interface ShiftListEditorProps {
    shiftCollection: ShiftCollection;
    locationCollection: NumericallyIdentifiedOptionCollection<Address>;
    locationsLoaded: boolean;
}

const ShiftListEditor = observer((props: ShiftListEditorProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* State *********/

    const [dialogState] = useState(new DialogState());
    const [deletionConfirmationState] = useState(new DialogState());
    const [unlinkConfirmationState] = useState(new DialogState());
    const [addressRemovalConfirmationState] = useState(new DialogState());
    const [unselectedLocationOption, setUnselectedLocationOption] = useState<Option<Address>>();
    const [removedLocation, setRemovedLocation] = useState<Address>();
    const [selectedShift, setSelectedShift] = useState<Shift>(new Shift());
    const [shiftDraft, setShiftDraft] = useState<Shift>(new Shift(selectedShift));
    const [addingShift, setAddingShift] = useState(true); // Adding new shift vs. updating shift
    const [shiftToDelete, setShiftToDelete] = useState<Shift>();
    const [displayMode] = useState(new ShiftListDisplayModeState());

    /********* Effects *********/

    // If a location is changed, update any shifts that link to it.
    useEffect(() => {
        if (props.locationsLoaded) {
            updateShiftAddresses();
        }
    }, [props.locationCollection.selections]);

    // If the address unlinking dialog is closed without clearing the 
    // unselected address option, reselect the address.
    useEffect(() => {
        if (!unlinkConfirmationState.open && unselectedLocationOption) {
            unselectedLocationOption.toggleSelection();
        }
    }, [unlinkConfirmationState.open]);

    // If the address removal dialog is closed without clearing the 
    // removed address, re-add the address.
    useEffect(() => {
        if (!addressRemovalConfirmationState.open && removedLocation) {
            props.locationCollection.addOrUpdateOption(removedLocation, true);
        }
    }, [addressRemovalConfirmationState.open]);

    useEffect(() => {
        if (dialogState.open) {
            setShiftDraft(new Shift(selectedShift));
        }
    }, [dialogState.open]);

    /********* Event handlers *********/

    const onAddButtonClicked = () => {
        reset();
        openDialog();
    }

    const onConfirmShift = () => {
        shiftDraft.setAllFieldsDirty();
        shiftDraft.repetitionPattern.setAllFieldsDirty();
        shiftDraft.repetitionPattern.ending.setAllFieldsDirty();
        if (shiftDraft.validated && shiftDraft.repetitionPattern.validated) {
            props.shiftCollection.addOrUpdateShift(shiftDraft);
            closeDialog();
        }
    }

    const onShiftActionSelected = (shift: Shift, action: ShiftAction) => {
        if (action === ShiftAction.Edit) {
            editShift(shift);
        } else if (action === ShiftAction.Delete) {
            setShiftToDelete(shift);
            deletionConfirmationState.setOpen(true);
        }
    }

    const onDeletionConfirmed = () => {
        if (shiftToDelete) {
            deleteShift(shiftToDelete);
        }
        deletionConfirmationState.setOpen(false);
    }

    const onUnlinkingConfirmed = () => {
        if (unselectedLocationOption) {
            unlinkAddressFromShifts(unselectedLocationOption?.object.id);
            setUnselectedLocationOption(undefined);
        }
        unlinkConfirmationState.setOpen(false);
    }

    const onAddressRemovalConfirmed = () => {
        if (removedLocation) {
            unlinkAddressFromShifts(removedLocation.id);
            setRemovedLocation(undefined);
        }
        addressRemovalConfirmationState.setOpen(false);
    }

    /********* Helper methods *********/

    const reset = () => {
        setSelectedShift(new Shift());
        setAddingShift(true);
    }

    const openDialog = () => {
        dialogState.setOpen(true);
    }

    const closeDialog = () => {
        dialogState.setOpen(false);
    }

    const editShift = (shift: Shift) => {
        setAddingShift(false);
        setSelectedShift(shift);
        openDialog();
    }

    const deleteShift = (shift: Shift) => {
        props.shiftCollection.deleteShift(shift);
    }

    const updateShiftAddresses = () => {
        if (!checkForRemovedAddress()) {
            checkForUnselectedAddress();
        }
    };

    const checkForRemovedAddress = () => {
        const addressDictionary = props.shiftCollection.addressDictionary;
        Object.keys(addressDictionary).forEach(linkedAddressId => {
            const numericalAddressId = parseInt(linkedAddressId);
            const addressRemoved = props.locationCollection.optionObjects.findIndex(object => object.id === numericalAddressId) === -1;
            if (addressRemoved) {
                const affectedShifts = addressDictionary[numericalAddressId];
                if (affectedShifts.length > 0) {
                    setRemovedLocation(affectedShifts[0].address);
                    addressRemovalConfirmationState.setOpen(true);
                    return true;
                }
            }
        });
        return false;
    }

    const checkForUnselectedAddress = () => {
        const addressDictionary = props.shiftCollection.addressDictionary;
        props.locationCollection.options.forEach(option => {
            const linkedShifts = addressDictionary[option.object.id];
            const addressLinkedToShift = linkedShifts && linkedShifts.length > 0;
            if (addressLinkedToShift) {
                if (!option.selected) {
                    setUnselectedLocationOption(option);
                    // Warn the user that they are about to unlink an address from at least one shift
                    unlinkConfirmationState.setOpen(true);
                } else {
                    linkedShifts.forEach(shift => {
                        shift.setAddress(option.object);
                    });
                }
            }
        });
    }

    const unlinkAddressFromShifts = (addressId: number) => {
        const addressDictionary = props.shiftCollection.addressDictionary;
        const linkedShifts = addressDictionary[addressId];
        linkedShifts.forEach(shift => {
            shift.setAddress();
        });
    }

    /********* Render *********/

    return (
        <div>
            {/* Add Shift Button */}
            <div className={classes.buttons}>
                <Button onClick={onAddButtonClicked} variant="contained" color="primary" size={xsDown ? 'small' : "large"} startIcon={<CalendarPlus />}>
                    New Shift
                </Button>

                {/* Display Mode */}
                {props.shiftCollection.shifts.length > 0 &&
                    <ShiftListDisplayMode displayModeState={displayMode} hideToggleButtonGroup /> // TODO: Remove hideToggleButtonGroup after demo
                }
            </div>

            {/* List of Shifts */}
            {displayMode.shiftsExpanded
                ? <ShiftList records={props.shiftCollection.getOrderedShiftInstances(15, { timestamp: new Date() })} editable={true} onShiftActionSelected={onShiftActionSelected} hideNoResultsText />
                : <ShiftList records={props.shiftCollection.orderedShifts} editable={true} onShiftActionSelected={onShiftActionSelected} hideNoResultsText />
            }


            {/* Shift Entry Dialog */}
            <ThemedDialogWithSpinner
                state={dialogState}
                title={addingShift ? 'Add Shift' : 'Update Shift'}
                primaryButtonProps={{ children: addingShift ? 'Add' : 'Update' }}
                onSubmit={onConfirmShift}
                DialogProps={{ fullScreen: xsDown }}
            >
                <ShiftEntry shift={shiftDraft} locationOptions={props.locationCollection.selectedOptions} />
            </ThemedDialogWithSpinner>

            {/* Confirmation Dialog for Deletion */}
            <ConfirmationDialog
                state={deletionConfirmationState}
                title="Shift Removal"
                content={(
                    <Typography>Are you sure you want to remove this shift?</Typography>
                )}
                confirmText="Remove"
                onConfirm={onDeletionConfirmed}
                fullScreen={false}
            />

            {/* Confirmation Dialog for Unlinking a Location */}
            <ConfirmationDialog
                state={unlinkConfirmationState}
                title="Unlink Address"
                content={(
                    <Alert severity="warning">
                        <Typography className={classes.bold}>This address is linked to at least one shift. </Typography>
                        <Typography>If you unselect it, you will also unlink it from any associated shifts. Are you sure you want to continue?</Typography>
                    </Alert>
                )}
                confirmText="Unlink"
                onConfirm={onUnlinkingConfirmed}
            />

            {/* Confirmation Dialog for Removing a Location */}
            <ConfirmationDialog
                state={addressRemovalConfirmationState}
                title="Remove Address"
                content={(
                    <Alert severity="warning">
                        <Typography className={classes.bold}>This address is linked to at least one shift. </Typography>
                        <Typography>If you remove it, it will also be removed from any associated shifts. Are you sure you want to continue?</Typography>
                    </Alert>
                )}
                confirmText="Remove"
                onConfirm={onAddressRemovalConfirmed}
            />
        </div >
    )
});

export default ShiftListEditor;