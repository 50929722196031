import React, { useContext } from "react";
import { makeStyles, Theme, createStyles, useMediaQuery, IconButton, Breadcrumbs } from "@material-ui/core";
import { observer } from "mobx-react";
import { getSchedulingLink, getVolunteerShiftsLink } from "../../../../Navigation/Links/UrlConstructors";
import clsx from 'clsx';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import { RootContext } from "../../../../../stores";
import REMChip from "../../../../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadcrumbs: {
            marginBottom: theme.spacing(2),
        },
        mobileBreadcrumbs: {
            marginBottom: theme.spacing(2),
            display: 'flex'
        },
        backButton: {
            padding: 0
        },
        active: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:focus, &:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText
            }
        },
        chip: {
            marginBottom: '2px'
        },
    })
);

const ShiftDetailsBreadcrumbs = observer(() => {

    /********* React hooks *********/
    const classes = useStyles();
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;

    const returnToSchedulingPage = () => {
        const schedulingLink = userStore.user.isVolunteer ? getVolunteerShiftsLink() : getSchedulingLink();
        navigate(schedulingLink);
    }

    const scheduleChipLabel = userStore.user.isVolunteer ? 'Schedule' : `Scheduling`;

    /********* Render *********/

    return (
        <React.Fragment>
            {xsDown
                ? <div className={classes.mobileBreadcrumbs}>
                    <IconButton className={classes.backButton} onClick={returnToSchedulingPage}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <REMChip label={scheduleChipLabel} onClick={returnToSchedulingPage} />
                </div>
                : <Breadcrumbs
                    className={classes.breadcrumbs}
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <REMChip
                        label={scheduleChipLabel}
                        className={classes.chip}
                        onClick={returnToSchedulingPage}
                        key={`breadcrumb-scheduling-page`}
                    />
                    <REMChip
                        label={'Shift Details'}
                        onClick={() => { return; }}
                        className={clsx(classes.active, classes.chip)}
                        key={`breadcrumb-shift-editor`}
                    />
                </Breadcrumbs>
            }
        </React.Fragment>
    );
});

export default ShiftDetailsBreadcrumbs;