import { To, useLocation, useMatch } from "react-router-dom"
import { ORG_PORTAL_PATH_PATTERN } from "./OrgPortalPrefix";

// Gets the current URI without any organization-specific portal prefix (if there is one).
export const useOrgPortalAgnosticPath = () => {

    const match = useMatch(ORG_PORTAL_PATH_PATTERN);
    const location = useLocation();

    let link: To;
    const path = location.pathname + location.hash

    if (match) {
        link = "/" + match.params['*'] || path;
    } else {
        link = path;
    }

    return link;
}