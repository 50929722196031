import {
    observable,
    action,
    computed,
    reaction,
    IReactionDisposer,
    makeObservable,
} from "mobx";
import { add, format, isValid, endOfDay, getDay, setDay, sub, getMonth, formatDuration, intervalToDuration, getDate, subMilliseconds, isSameDay, differenceInCalendarDays, addMonths } from "date-fns";
import { RepetitionEndType, IRepetitionEnding, RepetitionEnding } from "./RepetitionEnding";
import validateRepetitionPattern from "../../logic/ValidationChecks/RepetitionPatternValidation";
import { DateFormatter } from "../../logic/DateFormatter";
import RepetitionPatternMapper, { PluralFrequencies, SingularFrequencies } from "../../logic/RepetitionPatternLogic";
import { Fields } from "./Fields";
import addDays from "date-fns/addDays";

const DOES_NOT_REPEAT_OPTION = "Never";
const DAILY_OPTION = "Daily";
const CUSTOM_OPTION = "Custom";

const DEFAULT_DURATION_IN_HOURS = 1;

export enum RepetitionFrequency {
    Once = 'Once',
    Daily = 'Daily',
    Weekly = 'Weekly',
    MonthlyOnDate = 'MonthlyOnDate',
    MonthlyOnDayOfWeek = 'MonthlyOnDayOfWeek',
    Yearly = 'Yearly',
    Custom = 'Custom'
};

export type MonthlyRepetitionType = Extract<RepetitionFrequency, RepetitionFrequency.MonthlyOnDate | RepetitionFrequency.MonthlyOnDayOfWeek>

type FrequencyOptions = {
    [key in RepetitionFrequency]: string;
};

type MonthlyOptions = {
    [key in MonthlyRepetitionType]: string;
}

export enum DaysOfTheWeek {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday"
}

type NumericDaysOfTheWeek = { [key in DaysOfTheWeek]: number };

const DaysOfTheWeekNumericMapping: NumericDaysOfTheWeek = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
}

export interface INonRepeatingPattern {
    startTimestamp: Date | null;
    endTimestamp: Date | null;
}

export interface IRepetitionPatternFields {
    startDate: Date | null;
    startTime: Date | null;
    endDate: Date | null;
    endTime: Date | null;
    frequency: RepetitionFrequency;
    interval: number;
    customFrequencyDescriptor: SingularFrequencies | PluralFrequencies;
    daysOfTheWeek: DaysOfTheWeek[];
    monthlyRepetitionPattern: MonthlyRepetitionType;
    ending: IRepetitionEnding;
}

export interface IRepetitionPattern extends IRepetitionPatternFields { }

export class RepetitionPattern extends Fields<IRepetitionPatternFields, RepetitionPattern> implements IRepetitionPattern {
    // Fields
    @observable startDate: Date | null;
    @observable startTime: Date | null;
    @observable endDate: Date | null;
    @observable endTime: Date | null;
    @observable frequency = RepetitionFrequency.Once;
    @observable interval = 1;
    @observable ending = new RepetitionEnding();
    @observable customFrequencyDescriptor: SingularFrequencies | PluralFrequencies = SingularFrequencies.Week;
    @observable daysOfTheWeek: DaysOfTheWeek[] = [];
    @observable monthlyRepetitionPattern: MonthlyRepetitionType = RepetitionFrequency.MonthlyOnDayOfWeek;

    private duration: number;

    private frequencyChangedReaction: IReactionDisposer;
    private intervalChangedReaction: IReactionDisposer;
    private startDateChangedReaction: IReactionDisposer;
    private startTimestampChangedReaction: IReactionDisposer;
    private endTimestampChangedReaction: IReactionDisposer;
    private daysOfTheWeekChangedReaction: IReactionDisposer;
    private firstOccurrenceStartTimestampChangedReaction: IReactionDisposer;

    constructor(repetitionPattern?: IRepetitionPattern | INonRepeatingPattern) {
        super();

        makeObservable(this);

        if (repetitionPattern) {
            if ('startTimestamp' in repetitionPattern) {
                this.startTime = repetitionPattern.startTimestamp;
                this.startDate = repetitionPattern.startTimestamp;
                this.endTime = repetitionPattern.endTimestamp;
                this.endDate = repetitionPattern.endTimestamp;
            } else {
                this.startTime = repetitionPattern.startTime;
                this.startDate = repetitionPattern.startDate;
                this.endTime = repetitionPattern.endTime;
                this.endDate = repetitionPattern.endDate;
            }
            this.duration = DateFormatter.getDuration(this.startDate, this.startTime, this.endDate, this.endTime);
            if ('frequency' in repetitionPattern) {
                this.frequency = repetitionPattern.frequency;
                this.interval = repetitionPattern.interval;
                this.ending = new RepetitionEnding(repetitionPattern.ending);
                this.customFrequencyDescriptor = repetitionPattern.customFrequencyDescriptor;
                this.daysOfTheWeek = repetitionPattern.daysOfTheWeek;
                this.monthlyRepetitionPattern = repetitionPattern.monthlyRepetitionPattern;
            }
        } else {
            const now = new Date();
            const tomorrowAtTen = add(now.setHours(10, 0, 0, 0), { days: 1 });
            const defaultEndTime = add(tomorrowAtTen, { hours: DEFAULT_DURATION_IN_HOURS });
            this.startDate = tomorrowAtTen;
            this.startTime = tomorrowAtTen;
            this.endDate = defaultEndTime;
            this.endTime = defaultEndTime;
            this.duration = DEFAULT_DURATION_IN_HOURS * 3600000;
        }
        if (this.daysOfTheWeek.length === 0) {
            this.updateDaysOfTheWeek();
        }
        this.frequencyChangedReaction = this.getFrequencyChangedReaction();
        this.startDateChangedReaction = this.getStartDateChangedReaction();
        this.startTimestampChangedReaction = this.getStartTimestampChangedReaction();
        this.endTimestampChangedReaction = this.getEndTimestampChangedReaction();
        this.intervalChangedReaction = this.getIntervalChangedReaction();
        this.daysOfTheWeekChangedReaction = this.getDaysOfTheWeekChangedReaction();
        this.firstOccurrenceStartTimestampChangedReaction = this.getFirstOccurrenceStartTimestampChangedReaction();
    }

    /***** Reactions *****/

    private getFrequencyChangedReaction() {
        return reaction(
            () => { return this.frequency },
            (frequency) => {

                // Reset the selected days of the week if the standard weekly option is selected
                if (frequency === RepetitionFrequency.Weekly) {
                    this.updateDaysOfTheWeek();
                }

                if (this.isMonthlyFrequency(frequency)) {
                    this.monthlyRepetitionPattern = frequency as MonthlyRepetitionType;
                }

                if (this.isRepeatingFrequency(frequency)) {
                    this.setCustomFrequencyDescriptor(this.frequencyDescriptor);
                }

                // Reset the interval to 1 if a standard frequency option is selected
                if (frequency !== RepetitionFrequency.Custom) {
                    this.setInterval(1);
                }
            }
        );
    }

    private getStartDateChangedReaction() {
        return reaction(
            () => { return this.startDate },
            (startDate) => {
                this.updateDaysOfTheWeek();
            }
        );
    }

    private getStartTimestampChangedReaction() {
        return reaction(
            () => { return this.startTimestamp },
            (startTimestamp) => {
                this.updateEndTimestampBasedOnDuration();
            }
        );
    }

    private getEndTimestampChangedReaction() {
        return reaction(
            () => { return this.endTimestamp },
            (endTimestamp) => {
                this.saveDuration();
            }
        );
    }

    private getIntervalChangedReaction() {
        return reaction(
            () => { return this.interval },
            (interval) => {
                this.refreshCustomFrequencyDescriptor();
                if (interval > 1) {
                    this.setFrequency(RepetitionFrequency.Custom);
                }
            }
        )
    }

    private getDaysOfTheWeekChangedReaction() {
        return reaction(
            () => { return this.daysOfTheWeek },
            (daysOfTheWeek) => {
                if (daysOfTheWeek.length > 1) {
                    this.setFrequency(RepetitionFrequency.Custom);
                }
            }
        )
    }

    private getFirstOccurrenceStartTimestampChangedReaction() {
        return reaction(
            () => { return this.firstOccurrenceStartTimestamp },
            (firstOccurrenceStartTimestamp) => {
                if (firstOccurrenceStartTimestamp) {
                    this.ending.setMinimumEndDate(firstOccurrenceStartTimestamp);
                }
            }
        )
    }

    /***** Setters *****/

    @action setFrequency(frequency: RepetitionFrequency) {
        this.frequency = frequency;
    }

    @action setCustomFrequencyDescriptor(descriptor: SingularFrequencies | PluralFrequencies) {
        this.customFrequencyDescriptor = descriptor;
    }

    @action setInterval(interval: number) {
        const updatedInterval = isNaN(interval) ? 1 : interval;
        this.interval = updatedInterval;
    }

    @action setDaysOfTheWeek(daysOfTheWeek: DaysOfTheWeek[]) {
        this.daysOfTheWeek = daysOfTheWeek;
    }

    @action setMonthlyRepetitionPattern(monthlyRepetitionPattern: MonthlyRepetitionType) {
        this.monthlyRepetitionPattern = monthlyRepetitionPattern;
    }

    @action setStartDate(startDate: Date | null) {
        this.startDate = startDate;
    }

    @action setEndDate(endDate: Date | null) {
        this.endDate = endDate;
    }

    @action setStartTime(startTime: Date | null) {
        this.startTime = startTime;
    }

    @action setEndTime(endTime: Date | null) {
        this.endTime = endTime;
    }

    @action private saveDuration() {
        if (!this.startTimestampInvalid && !this.endTimestampInvalid) {
            this.duration = DateFormatter.getDurationBetweenTimestamps(this.startTimestamp!, this.endTimestamp!);
        }
    }

    /***** Public computed properties *****/

    @computed get startTimestamp() {
        if (isValid(this.startDate) && isValid(this.startTime)) {
            return DateFormatter.getTimestampFromDateAndTime(this.startDate!, this.startTime!);
        } else {
            return null;
        }
    }

    @computed get endTimestamp() {
        if (isValid(this.endDate) && isValid(this.endTime)) {
            return DateFormatter.getTimestampFromDateAndTime(this.endDate!, this.endTime!);
        } else {
            return null;
        }
    }

    @computed get defaultRepetitionEndDate() {
        return this.startDate ? add(this.startDate, { months: 3 }) : null;
    }

    @computed get repeats() {
        return this.frequency !== RepetitionFrequency.Once;
    }

    @computed get isCustomPattern() {
        return this.frequency === RepetitionFrequency.Custom;
    }

    @computed get isWeeklyPattern() {
        return this.frequencyType === RepetitionFrequency.Weekly;
    }

    @computed get isCustomMonthlyPattern() {
        return this.isCustomPattern && this.isMonthlyFrequency(this.customFrequency);
    }

    @computed private get isCustomWeeklyPattern() {
        return this.baseFrequency === RepetitionFrequency.Weekly && !this.weeklyPatternMatchesDropdownOption;
    }

    @computed get frequencyDescriptor() {
        if (this.isCustomPattern) {
            return this.customFrequencyDescriptor;
        } else {
            return this.getDescriptorForFrequency(this.frequency);
        }
    }

    @computed get customFrequency() {
        const freq = RepetitionPatternMapper.getCustomFrequencyForDescriptor(this.customFrequencyDescriptor);
        if (this.isMonthlyFrequency(freq)) {
            return this.monthlyRepetitionPattern;
        } else {
            return freq;
        }
    }

    @computed get baseFrequency() {
        return this.frequencyType === RepetitionFrequency.Custom ? this.customFrequency : this.frequencyType;
    }

    /* --- Repetition Occurences --- */

    @computed get hasEnded() {
        return (this.finalOccurenceEndTimestamp && this.finalOccurenceEndTimestamp < new Date()) || false;
    }

    @computed private get finalOccurenceEndTimestamp() {
        if (!this.repeats) {
            return this.endTimestamp;
        } else {
            if (this.finalOccurrence) {
                return this.addDurationToTimestamp(this.finalOccurrence);
            }
        }
    }

    @computed private get finalOccurrence() {
        if (!this.repeats) {
            return this.startTimestamp;
        } else {
            switch (this.ending.endType) {
                case RepetitionEndType.EndDate:
                case RepetitionEndType.AfterOccurrences:
                    return this.occurrencesBeforeEnd[this.occurrencesBeforeEnd?.length - 1];
                default:
                    return null;
            }
        }
    }

    @computed private get occurrencesBeforeEnd(): Date[] {
        let occurrences: Date[] = [];
        if (!this.repeats || this.startTimestamp === null || this.ending.endType === RepetitionEndType.Never) {
            return occurrences;
        } else {
            let whileCheck: (currentOccurrence: Date) => boolean;
            if (this.ending.endType === RepetitionEndType.EndDate) {
                // The end date is inclusive, so it's the last possible date that an occurrence can happen
                whileCheck = (currentOccurrence: Date) => currentOccurrence < endOfDay(this.ending.endDate!);
            } else {
                whileCheck = (currentOccurrence: Date) => occurrences.length < this.ending.numOccurrences;
            }

            let currentOccurrence: Date | undefined = this.firstOccurrenceStartTimestamp;
            while (currentOccurrence && whileCheck(currentOccurrence)) {
                occurrences.push(currentOccurrence);
                currentOccurrence = this.getNextOccurrence(currentOccurrence);
            }
            return occurrences;
        }
    }

    getNextOccurrenceAfterTimestamp(timestamp: Date): Date | undefined {
        if (this.repeats) {
            if (this.ending.endType === RepetitionEndType.Never) {
                return this.nextOccurrenceAfterTimestampCalculator(timestamp);
            } else {
                return this.occurrencesBeforeEnd.find(occurrence => timestamp < occurrence);
            }
        }
    }

    // Returns a function that can be used to find the occurence following a (valid) provided occurrence
    @computed private get getNextOccurrence(): (currentOccurrence: Date) => Date | undefined {
        return (currentOccurrence: Date) => {
            if (this.interval > 0) {
                if (this.firstOccurrenceStartTimestamp) {
                    if (currentOccurrence < this.firstOccurrenceStartTimestamp) {
                        return this.firstOccurrenceStartTimestamp;
                    } else {
                        return this.getNextOccurrenceFromCurrentOccurrence(currentOccurrence);
                    }
                }
            }
        }
    }

    public getDaysFromStartDate(comparisonDate: Date | null) {
        return this.startTimestamp && comparisonDate
        ? differenceInCalendarDays(comparisonDate, this.startTimestamp)
        : -1;
    }
    
    public getRemainingOccurrencesFromInstance(defaultDaysFromStartDate: number) {
        if (!this.startTimestamp) return -1;
        const defaultStartTimestamp = addDays(this.startTimestamp, defaultDaysFromStartDate);
        const occurrenceNumber = this.getInstanceOccurrenceNumber(defaultStartTimestamp);
        if (occurrenceNumber >= 0) {
            return this.ending.numOccurrences - occurrenceNumber;
        } else {
            return -1;
        }
    }
    
    // The first instance's occurrence number is 1,
    // and the occurrence number increases by 1 with 
    // each successive instance.
    private getInstanceOccurrenceNumber(instanceTimestamp: Date) {
        const index = this.occurrencesBeforeEnd.findIndex(occurrence => instanceTimestamp.getTime() === occurrence.getTime());
        return index === -1 ? -1 : index;
    }

    public verifyValidDaysFromStartDate(defaultDaysFromStartDate: number): boolean {
        // TODO: Might be able to rewrite verification code to improve performance
        if (!this.startTimestamp) {
            return false;
        }
        const startTimestamp = addDays(this.startTimestamp, defaultDaysFromStartDate);
        return this.verifyValidOccurrence(startTimestamp);
    }

    public verifyValidOccurrence(occurrence: Date): boolean {
        const timestampBeforeOccurrence = subMilliseconds(occurrence, 1);
        const nextOccurrenceAfterTimestamp = this.getNextOccurrenceAfterTimestamp(timestampBeforeOccurrence);
        return nextOccurrenceAfterTimestamp !== undefined && isSameDay(nextOccurrenceAfterTimestamp, occurrence);
    }

    // Returns the next valid occurrence that takes place after the provided timestamp
    @computed get nextOccurrenceAfterTimestampCalculator(): (timestamp: Date) => Date | undefined {
        return (timestamp: Date) => {
            if (this.interval > 0) {
                if (this.firstOccurrenceStartTimestamp) {
                    if (timestamp < this.firstOccurrenceStartTimestamp) {
                        return this.firstOccurrenceStartTimestamp;
                    } else {
                        return this.getNextOccurrenceFromCurrentOccurrence(this.firstOccurrenceStartTimestamp, timestamp);
                    }
                }
            }
        }
    }


    @computed private get repetitionDuration(): keyof Duration {
        return RepetitionPatternMapper.getPluralFrequencyDescriptor(this.frequencyType);
    }

    private getNextOccurrenceFromCurrentOccurrence(currentOccurrence: Date, timestampToSurpass?: Date) {
        let nextOccurrence = currentOccurrence;
        while (!nextOccurrence || (timestampToSurpass && nextOccurrence <= timestampToSurpass) || nextOccurrence <= currentOccurrence) {
            nextOccurrence = this.nextOccurrenceCalculator(nextOccurrence);
        }
        return nextOccurrence;
    }

    @computed private get isRepetitionBasedOnDate() {
        return this.baseFrequency === RepetitionFrequency.MonthlyOnDate || this.baseFrequency === RepetitionFrequency.Yearly;
    }

    @computed private get nextOccurrenceCalculator() {
        if (this.isCustomWeeklyPattern) {
            return (occurrence: Date) => this.getOccurrenceOnNextRepeatingDayOfWeek(occurrence);
        } else if (this.baseFrequency === RepetitionFrequency.MonthlyOnDayOfWeek) {
            return (occurrence: Date) => this.getNextOccurrenceForMonthlyOnDayOfWeekRepetition(occurrence);
        } else if (this.isRepetitionBasedOnDate) {
            return (occurrence: Date) => this.getNextOccurrenceForDateBasedRepetition(occurrence);
        } else {
            return (occurrence: Date) => add(occurrence, { [this.repetitionDuration]: this.interval });
        }
    }

    private getOccurrenceOnNextRepeatingDayOfWeek(currentOccurrence: Date) {
        const currentDay = getDay(currentOccurrence);
        const firstDay = this.numericDaysOfTheWeek[0];
        const nextDay = this.numericDaysOfTheWeek.find((day) => currentDay < day) || firstDay; // Loop back to the front of the week if needed
        const wrappedToBeginningOfWeek = currentDay >= nextDay;
        const weeksToAdd = wrappedToBeginningOfWeek ? this.interval - 1 : 0;
        const daysToAdd = wrappedToBeginningOfWeek ? (7 + nextDay) - currentDay : nextDay - currentDay;
        return add(currentOccurrence, { weeks: weeksToAdd, days: daysToAdd });
    }

    @computed private get numericDaysOfTheWeek() {
        const mapping = this.daysOfTheWeek.map((dayOfTheWeek) => {
            return DaysOfTheWeekNumericMapping[dayOfTheWeek];
        });
        return mapping.sort();
    }

    private getNextOccurrenceForDateBasedRepetition(currentOccurrence: Date) {
        let intervalMultiplier = 1;
        let nextOccurrence = new Date(currentOccurrence);
        const dayOfMonthToMatch = getDate(currentOccurrence);
        while (nextOccurrence <= currentOccurrence || getDate(nextOccurrence) !== dayOfMonthToMatch) {
            nextOccurrence = add(currentOccurrence, { [this.repetitionDuration]: this.interval * intervalMultiplier });
            intervalMultiplier++;
        }
        return nextOccurrence;
    }

    private getNextOccurrenceForMonthlyOnDayOfWeekRepetition(currentOccurrence: Date) {
        // Get the next month based on the interval
        let nextOccurrence = add(currentOccurrence, { months: this.interval });

        // Set the day of the week to match the repetition pattern
        const dayOfWeek = getDay(currentOccurrence);
        nextOccurrence = setDay(nextOccurrence, dayOfWeek);

        // If setting the weekday pushed the date into the following month, shift to the previous week.
        const keepDateWithinCorrectMonth = () => {
            const nextOccurrenceMonth = getMonth(nextOccurrence);
            const correctMonth = (getMonth(currentOccurrence) + this.interval) % 12;
            if (nextOccurrenceMonth !== correctMonth) {
                const nextOccurrenceWrappedToNewYear = nextOccurrenceMonth === 0 && correctMonth === 11;
                const isPastCorrectMonth = nextOccurrenceMonth === correctMonth + 1 || nextOccurrenceWrappedToNewYear;
                const weeksToAdd = isPastCorrectMonth ? -1 : 1;
                nextOccurrence = add(nextOccurrence, { weeks: weeksToAdd });
            };
        }

        keepDateWithinCorrectMonth();

        if (!this.firstOccurrenceStartTimestamp) {
            throw new Error('No starting timestamp');
        }

        const initialWeekOfMonth = DateFormatter.getDayOfWeekPositionInMonth(this.firstOccurrenceStartTimestamp);
        const nextWeekOfMonth = DateFormatter.getDayOfWeekPositionInMonth(nextOccurrence);

        // If setting the weekday pushed the date into the previous or following week of the month, adjust the week.
        if (nextWeekOfMonth > initialWeekOfMonth) {
            nextOccurrence = sub(nextOccurrence, { weeks: 1 });
        } else if (nextWeekOfMonth < initialWeekOfMonth) {
            nextOccurrence = add(nextOccurrence, { weeks: 1 });
            // Since some months have five weeks and others don't, make sure we're still in the right month:
            keepDateWithinCorrectMonth();
        }
        return nextOccurrence;
    }

    @computed private get firstOccurrenceStartTimestamp() {
        if (this.startTimestamp) {
            if (this.startTimestampIsValidOccurrence) {
                return this.startTimestamp;
            } else {
                return this.getOccurrenceOnNextRepeatingDayOfWeek(this.startTimestamp);
            }
        }
    }

    @computed private get startTimestampIsValidOccurrence() {
        return this.baseFrequency !== RepetitionFrequency.Weekly || this.daysOfTheWeekContainsStartTimestampDay;
    }

    @computed private get indexOfUpcomingOccurrence() {
        return this.occurrencesBeforeEnd.findIndex(
            occurrence => this.addDurationToTimestamp(occurrence) > new Date()
        );
    }

    @computed get unmodifiedOccurrencesLeft() {
        if (this.indexOfUpcomingOccurrence < 0) {
            return 0;
        } else {
            return this.occurrencesBeforeEnd.length - this.indexOfUpcomingOccurrence;
        }
    }

    @computed get upcomingOccurrence() {
        if (this.ending.endType === RepetitionEndType.Never) {
            return this.getUpcomingOccurrenceForInfiniteRepetition();
        } else {
            const upcomingIndex = this.indexOfUpcomingOccurrence;
            const upcoming = upcomingIndex !== -1 ? this.occurrencesBeforeEnd[upcomingIndex] : undefined;
            return upcoming;
        }
    }

    @computed get sortOrderStartTimestamp() {
        if (this.hasEnded) {
            return this.finalOccurrence;
        } else {
            return this.upcomingOccurrence;
        }
    }

    @computed get sortOrderEndTimestamp() {
        if (this.sortOrderStartTimestamp) {
            return this.addDurationToTimestamp(this.sortOrderStartTimestamp);
        }
    }

    // Calculates the next occurrence of a repeating shift with an end type of Never.
    private getUpcomingOccurrenceForInfiniteRepetition() {
        const now = new Date();
        let upcomingOccurrence = this.firstOccurrenceStartTimestamp;
        while (upcomingOccurrence && this.addDurationToTimestamp(upcomingOccurrence) < now) {
            upcomingOccurrence = this.getNextOccurrence(upcomingOccurrence);
        }
        return upcomingOccurrence;
    }

    /* --- Timeline display --- */

    @computed private get startTimestampDayOfWeek() {
        return this.startTimestamp
            ? format(this.startTimestamp, "EEEE") as DaysOfTheWeek
            : DaysOfTheWeek.Sunday;
    }

    // The first occurrence doesn't fall on the start date in some cases 
    // (ex: when there's a custom weekly repetition pattern that doesn't include 
    // the day of the week that the start date falls on), so this value could 
    // differ from the startTimestampDayOfWeek.
    @computed get firstOccurrenceDayOfWeek() {
        return this.firstOccurrenceStartTimestamp
            ? format(this.firstOccurrenceStartTimestamp, "EEEE") as DaysOfTheWeek
            : DaysOfTheWeek.Sunday;
    }

    @computed get formattedStartDate() {
        if (!this.sortOrderStartTimestamp) {
            return '';
        } else {
            return DateFormatter.formatDate(this.sortOrderStartTimestamp);
        }
    }

    @computed get formattedTime() {
        if (!this.sortOrderStartTimestamp || !this.sortOrderEndTimestamp) {
            return '';
        }

        const startDate = format(this.sortOrderStartTimestamp, 'EEE, MMMM d');
        const startTime = format(this.sortOrderStartTimestamp, 'h:mm a');
        const endDate = format(this.sortOrderEndTimestamp, this.repeats ? 'EEE' : 'EEE, MMMM d');
        const endTime = format(this.sortOrderEndTimestamp, 'h:mm a');

        if (startDate === endDate) {
            return `${startTime} - ${endTime}`;
        } else {
            return `${startTime} - ${endDate}, ${endTime}`;
        }
    }

    @computed get formattedDuration() {
        if (this.startTimestamp && this.endTimestamp) {
            const duration = intervalToDuration({ start: this.startTimestamp, end: this.endTimestamp })
            return formatDuration(
                duration,
                { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ', ' }
            );
        }
    }

    @computed get formattedFinalOccurrence() {
        if (!this.finalOccurrence) {
            return '';
        } else if (DateFormatter.occursThisYear(this.finalOccurrence)) {
            return format(this.finalOccurrence, 'MMMM d');
        } else {
            return format(this.finalOccurrence, 'MMMM d, yyyy');
        }
    }

    @computed get repetitionDescription() {
        switch (this.frequency) {
            case RepetitionFrequency.Once:
                return '';
            case RepetitionFrequency.Custom:
                if (this.matchesStandardPattern) {
                    return this.frequencyDropdownOptions[this.customFrequency];
                } else {
                    return this.customRepetitionDescription;
                }
            default:
                return this.frequencyDropdownOptions[this.frequency];
        }
    }

    @computed get repetitionAndEndingDescription() {
        const shouldAddComma = this.ending.endType === RepetitionEndType.AfterOccurrences;
        const repetitionDescription = `${this.repetitionDescription}${shouldAddComma ? ',' : ''} ${this.ending.description}`;
        return repetitionDescription.trim();
    }

    /* --- Dropdown options --- */

    @computed get frequencyDropdownOptions() {
        const weeklyText = this.startTimestampInvalid ? 'Weekly' : `Weekly on ${format(this.startTimestamp!, "EEEE")}`;
        const yearlyText = this.startTimestampInvalid ? 'Annually' : `Annually on ${format(this.startTimestamp!, "MMMM do")}`;

        return {
            [RepetitionFrequency.Once]: DOES_NOT_REPEAT_OPTION,
            [RepetitionFrequency.Daily]: DAILY_OPTION,
            [RepetitionFrequency.Weekly]: weeklyText,
            [RepetitionFrequency.MonthlyOnDayOfWeek]: `Monthly ${this.monthlyOnDayOfWeekDescription}`,
            [RepetitionFrequency.MonthlyOnDate]: `Monthly ${this.monthlyOnDateDescription}`,
            [RepetitionFrequency.Yearly]: yearlyText,
            [RepetitionFrequency.Custom]: CUSTOM_OPTION
        }
    }

    @computed get customFrequencyDescriptors(): string[] {
        if (this.interval === 1) {
            return Object.values(SingularFrequencies);
        } else {
            return Object.values(PluralFrequencies);
        }
    }

    @computed get monthlyDropdownOptions() {
        const monthlyOnDayOfWeekText = this.startTimestampInvalid
            ? 'A particular week of the month'
            : `The ${DateFormatter.getDayOfWeekPositionInMonthString(this.startTimestamp!)} ${format(this.startTimestamp!, "EEEE")} of the month`;
        const monthlyOnDateText = this.startTimestampInvalid
            ? 'A particular date of the month'
            : `The ${format(this.startTimestamp!, "do")} of the month`;
        return {
            [RepetitionFrequency.MonthlyOnDayOfWeek]: monthlyOnDayOfWeekText,
            [RepetitionFrequency.MonthlyOnDate]: monthlyOnDateText,
            // TODO: Add option for last in addition to the fourth
        }
    }

    /* --- Validation --- */

    @computed get validationErrors() {
        return validateRepetitionPattern(this);
    }

    @computed get validated() {
        return this.validationErrors.length === 0 && this.ending.validated;
    }

    /***** Private computed properties *****/

    @computed private get frequencyType() {
        if (this.isCustomPattern) {
            return this.customFrequency;
        } else {
            return this.frequency;
        }
    }

    @computed private get startTimestampInvalid() {
        return this.startTimestamp === null;
    }

    @computed private get endTimestampInvalid() {
        return this.endTimestamp === null;
    }

    @computed private get timestampsInvalid() {
        return this.startTimestampInvalid || this.endTimestampInvalid;
    }

    @computed private get matchesStandardPattern() {
        if (this.frequency !== RepetitionFrequency.Custom) {
            return true;
        } else if (this.interval === 1) {
            if (this.customFrequency !== RepetitionFrequency.Weekly || this.weeklyPatternMatchesDropdownOption) {
                return true;
            }
        }
        return false;
    }

    @computed private get weeklyPatternMatchesDropdownOption() {
        return this.daysOfTheWeek.length === 1 && this.daysOfTheWeekContainsStartTimestampDay;
    }

    @computed private get daysOfTheWeekContainsStartTimestampDay() {
        return this.daysOfTheWeek.indexOf(this.startTimestampDayOfWeek) !== -1;
    }

    @computed private get customRepetitionDescription() {
        let customRepetitionDescription = '';
        if (this.customFrequency === RepetitionFrequency.Weekly && this.interval === 1) {
            customRepetitionDescription = 'Weekly on ' + this.getDaysOfTheWeekDescriptor;
        } else {
            customRepetitionDescription = `Every ${this.interval} ${this.frequencyDescriptor}`;
            if (this.customFrequency === RepetitionFrequency.Weekly) {
                customRepetitionDescription += ` on ${this.getDaysOfTheWeekDescriptor}`;
            } else if (this.isCustomMonthlyPattern) {
                customRepetitionDescription += ` ${this.monthlyRepetitionDescription}`
            }
        }
        return customRepetitionDescription;
    }

    @computed private get getDaysOfTheWeekDescriptor() {
        let daysOfTheWeek = '';
        if (this.daysOfTheWeek.length > 2) {
            daysOfTheWeek = this.orderedDaysOfTheWeek.slice(0, -1).join(', ') + ', and ' + this.orderedDaysOfTheWeek.slice(-1);
        } else if (this.daysOfTheWeek.length === 2) {
            daysOfTheWeek = this.orderedDaysOfTheWeek.join(' and ');
        } else if (this.daysOfTheWeek.length === 1) {
            daysOfTheWeek = this.daysOfTheWeek[0];
        }
        return daysOfTheWeek;
    }

    @computed private get orderedDaysOfTheWeek() {
        return Object.keys(DaysOfTheWeek).filter((key) => {
            return this.daysOfTheWeek.indexOf(key as DaysOfTheWeek) !== -1;
        })
    }

    @computed private get monthlyRepetitionDescription() {
        const freq = this.isCustomMonthlyPattern ? this.customFrequency : this.frequency;
        switch (freq) {
            case RepetitionFrequency.MonthlyOnDate:
                return this.monthlyOnDateDescription;
            case RepetitionFrequency.MonthlyOnDayOfWeek:
                return this.monthlyOnDayOfWeekDescription;
            default:
                return '';
        }
    }

    @computed private get monthlyOnDateDescription() {
        return this.startTimestamp
            ? `on the ${format(this.startTimestamp, "do")}`
            : 'on the same date';
    }

    @computed private get monthlyOnDayOfWeekDescription() {
        return this.startTimestamp
            ? `on the ${DateFormatter.getDayOfWeekPositionInMonthString(this.startTimestamp)} ${format(this.startTimestamp, "EEEE")}`
            : 'on the same week of the month';
    }

    /***** Private helpers *****/

    private refreshCustomFrequencyDescriptor() {
        this.setCustomFrequencyDescriptor(this.getDescriptorForFrequency(this.customFrequency));
    }

    private getDescriptorForFrequency(frequency: RepetitionFrequency) {
        if (this.interval === 1) {
            return RepetitionPatternMapper.getSingularFrequencyDescriptor(frequency);
        } else {
            return RepetitionPatternMapper.getPluralFrequencyDescriptor(frequency);
        }
    }

    private checkIfOccurrenceMatchesDaysOfWeek(occurrence: Date) {
        const dayOfWeekOfOccurrence = format(occurrence, "EEEE") as DaysOfTheWeek;
        return this.daysOfTheWeek.indexOf(dayOfWeekOfOccurrence) !== -1;
    }

    private updateDaysOfTheWeek() {
        if (!this.startTimestampInvalid) {
            let daysOfTheWeek = [] as DaysOfTheWeek[];
            daysOfTheWeek.push(this.startTimestampDayOfWeek);
            this.setDaysOfTheWeek(daysOfTheWeek);
        }
    }

    public addDurationToTimestamp(timestamp: Date) {
        return new Date(add(timestamp, { seconds: this.duration / 1000 }));
    }

    private updateEndTimestampBasedOnDuration() {
        if (!this.startTimestampInvalid) {
            const newEndTimestamp = this.addDurationToTimestamp(this.startTimestamp!);
            this.setEndDate(new Date(newEndTimestamp.getFullYear(), newEndTimestamp.getMonth(), newEndTimestamp.getDate()));
            this.setEndTime(newEndTimestamp);
        }
    }

    private isMonthlyFrequency(repetitionFrequency: RepetitionFrequency) {
        return repetitionFrequency === RepetitionFrequency.MonthlyOnDate
            || repetitionFrequency === RepetitionFrequency.MonthlyOnDayOfWeek;
    }

    private isRepeatingFrequency(frequency: RepetitionFrequency) {
        return frequency !== RepetitionFrequency.Custom && frequency !== RepetitionFrequency.Once;
    }
}