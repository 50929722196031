export class ValidationError<T> extends Error {
    errorProperties: (keyof T)[] | undefined = [];
    // The property to display the error message for if the error pertains to more than one
    // messageProperty: keyof T | undefined; 
    
    constructor(message: string, errorProperties?: (keyof T)[], messageProperty?: keyof T) {
        super(message);
        this.name = "ValidationError";
        this.errorProperties = errorProperties || undefined;
        // this.messageProperty = messageProperty || undefined;
    }
}