import React, { useEffect } from "react";
import { observer } from 'mobx-react'
import { Address } from "../../stores/models/Address";
import AddressDialog from "../Shared/AddressDialog";
import { DialogState } from "../../stores/models/DialogState";
import AddressRemovalConfirmation from "./AddressRemovalConfirmation";
import SaveIcon from '@material-ui/icons/Save';
import { MapMarkerPlus } from "mdi-material-ui";
import { initializeObjectDictionary } from "../../logic/Dictionaries";

enum DialogType {
    AddressEditForm = "addressEditForm",
    AddressRemovalConfirmation = "addressRemovalConfirmation"
}

interface AddressEditorProps {
    state: DialogState;
    address: Address;
    addressRemovable?: boolean; // TODO: Eventually remove this option
    onAddressUpdated: (address: Address) => void;
    onAddressRemoved: (address: Address) => void;
}

const AddressEditor = observer((props: AddressEditorProps) => {

    /********* State *********/

    const [dialogStates] = React.useState(initializeObjectDictionary(Object.values(DialogType), () => new DialogState()));

    /********* Effects *********/

    useEffect(() => {
        if (props.state.open) {
            dialogStates.addressEditForm.setOpen(true);
        } else {
            closeEditor();
        }
    }, [props.state.open]);

    useEffect(() => {
        Object.keys(dialogStates).forEach(dialog => {
            dialogStates[dialog as DialogType].setLoading(props.state.loading);
        });
    }, [props.state.loading]);

    useEffect(() => {
        if (!dialogStates.addressEditForm.open) {
            closeEditor();
        }
    }, [dialogStates.addressEditForm.open]);

    /********* Helper methods *********/

    const closeEditor = () => {
        Object.values(dialogStates).forEach(dialogState => {
            dialogState.setOpen(false);
        });
        props.state.setOpen(false);
    };

    /********* Event handlers *********/

    const handleConfirmAddressEdits = (address: Address) => {
        props.onAddressUpdated(address);
    };

    const handleRemoveAddressClicked = (address: Address) => {
        dialogStates.addressRemovalConfirmation.setOpen(true);
    }

    const handleAddressRemovalConfirmed = () => {
        props.onAddressRemoved(props.address);
    }

    /********* Render *********/

    return (
        <div>
            <AddressDialog
                state={dialogStates.addressEditForm}
                title={props.address.id === -1 ? "Add Address" : "Edit Address"}
                address={props.address}
                confirmText={props.address.id === -1 ? "Add" : "Save"}
                confirmIcon={props.address.id === -1 ? MapMarkerPlus : SaveIcon}
                displayRemoveButton={props.addressRemovable !== undefined ? props.addressRemovable : false}
                onConfirm={handleConfirmAddressEdits}
                onRemove={handleRemoveAddressClicked}
            />
            <AddressRemovalConfirmation
                dialogState={dialogStates.addressRemovalConfirmation}
                address={props.address}
                onConfirm={handleAddressRemovalConfirmed}
            />
        </div>
    );
});

export default AddressEditor;