import { makeStyles, Theme, createStyles, Button, Card, CardActions, CardContent, CircularProgress, ButtonProps, CardProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { DialogState } from "../../../../stores/models/DialogState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardActions: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        buttonLoad: {
            width: '20px !important',
            height: '20px !important',
            color: 'white'
        }
    }),
);

interface PopperCardProps extends CardProps {
    state: DialogState;
    actionButtonText: string;
    ActionButtonProps: ButtonProps;
}

const PopperCard = observer((props: PopperCardProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <Card {...props}>
            <CardContent>
                {props.children}
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    color='primary'
                    variant='contained'
                    startIcon={props.state.loading
                        ? <CircularProgress className={classes.buttonLoad} />
                        : undefined}
                    {...props.ActionButtonProps}
                >
                    {props.actionButtonText}
                </Button>
            </CardActions>
        </Card>
    );
});

export default PopperCard;