import { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper, Container, makeStyles, Theme, createStyles } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Editor, { Action } from "../Editor";
import { RootContext, Organization, Volunteer } from "../../../stores";
import CloseIcon from '@material-ui/icons/Close';
import VolunteerProfile from "./Volunteer/VolunteerProfile";
import OrganizationProfile from "./Organization/Information/OrganizationProfile";
import { observer } from "mobx-react";
import LoadingIndicator from "../../Shared/LoadingIndicator";
import FormError from "../../Organization/VolunteerOpportunities/FormError";
import { EditorState } from "../../../stores/models/EditorState";
import EditorHeaderButtons from "./Organization/EditorHeaderButtons";
import { getOrganizationLink } from "../../Navigation/Links/UrlConstructors";
import { subHours } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line',
            wordBreak: 'break-word'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        title: {
            wordBreak: 'normal',
            textOverflow: 'ellipsis',
            overflowX: 'hidden'
        },
        floatingButton: {
            background: theme.palette.darkBackground.main,
            color: theme.palette.darkBackground.contrastText,
            '&:hover': {
                background: theme.palette.darkBackground.light,
            },
            '& svg': {
                marginRight: theme.spacing(1),
            }
        },
    }),
);

const Profile = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const organizationStore = rootStore.organizationStore;
    const volunteerStore = rootStore.volunteerStore;

    /********* Helper methods *********/

    const fetchUserData = async () => {
        setIsLoading(true);
        await userStore.checkForAuthenticatedUser();
        setIsLoading(false);
    }

    const getOrganizationProfileData = () => {
        return userStore.user.organization;
    }

    const getDraftOrganization = () => {
        return userStore.user.isOrganization
            ? new Organization(getOrganizationProfileData()!)
            : undefined
    }

    const getDraftVolunteer = () => {
        return userStore.user.isVolunteer
            ? new Volunteer(userStore.user.volunteer!)
            : undefined
    }

    /********* State *********/

    const [isLoading, setIsLoading] = useState(true);
    const [organizationDraft, setOrganizationDraft] = useState(getDraftOrganization());
    const [volunteerDraft, setVolunteerDraft] = useState(getDraftVolunteer());
    const [formHasErrors, setFormHasErrors] = useState(false);

    /********* Effects *********/

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        resetFields();
    }, [userStore.user.profileData]);

    /********* Event handlers *********/

    const handleSave = async (editorState?: EditorState) => {
        if (draftIsValid()) {
            setFormHasErrors(false);
            editorState?.setLoading(true);
            await saveDraft();
            resetFields();
            scrollToTopOfForm();
            editorState?.setLoading(false);
            editorState?.setEditing(false);
        } else {
            setFormHasErrors(true);
            scrollToTopOfForm();
            setAllDraftFieldsDirty();
        }
    }

    /********* Helper methods *********/

    const resetFields = () => {
        setFormHasErrors(false);
        if (userStore.user.isOrganization) {
            setOrganizationDraft(getDraftOrganization());
        } else {
            setVolunteerDraft(getDraftVolunteer());
        }
    }

    const draftIsValid = () => {
        return (volunteerDraft && volunteerDraft.validated)
            || (organizationDraft && organizationDraft.validated);
    }

    const setAllDraftFieldsDirty = () => {
        if (volunteerDraft) {
            volunteerDraft.setAllFieldsDirty();
        } else if (organizationDraft) {
            organizationDraft.setAllFieldsDirty();
        }
    }

    const saveDraft = async () => {
        if (volunteerDraft) {
            await volunteerStore.updateInformation(volunteerDraft);
        } else if (organizationDraft) {
            await organizationStore.updateInformation(organizationDraft);
        }
    }

    const shouldDisplayFormError = (editorState: EditorState) => {
        return editorState.editing && formHasErrors;
    }

    const scrollToTopOfForm = () => {
        window.scroll({ top: 0 });
    }

    const hasDraftBeenEdited = () => {
        if (userStore.user.isVolunteer) {
            return volunteerDraft?.isDirty || false;
        } else if (userStore.user.isOrganization) {
            return organizationDraft?.isDirty || false;
        } else {
            return false;
        }
    }

    const shouldBeInEditMode = () => {
        if (userStore.user.isOrganization) {
            const registeredInPastHour = userStore.user.registrationDate > subHours(new Date(), 1);
            if (registeredInPastHour) {
                const noCausesAdded = userStore.user.organization?.causes.length === 0;
                const noLocationsAdded = userStore.user.organization?.addressCollection.addresses.length === 0;
                return noCausesAdded && noLocationsAdded;
            }
        }
        return false;
    }

    /********* Actions *********/

    const getActions = (editorState: EditorState): Action[] => {
        if (editorState.editing) {
            return [{
                label: 'Save',
                onClick: (editorState) => handleSave(editorState),
                icon: SaveIcon,
                className: classes.floatingButton
            }, {
                label: 'Cancel',
                togglesEditMode: true,
                icon: CloseIcon,
                onClick: resetFields,
                className: classes.floatingButton
            }];
        } else {
            return [{
                label: 'Edit Profile',
                togglesEditMode: true,
                icon: EditIcon,
                className: classes.floatingButton
            }];
        }
    };

    /********* Render *********/

    if (isLoading) {
        return <LoadingIndicator />
    }
    return (
        <Editor actions={getActions} editsMade={hasDraftBeenEdited()} editing={shouldBeInEditMode()}>
            {(editorState: EditorState) => (
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Container>
                                    {shouldDisplayFormError(editorState) ? <FormError /> : null}
                                    <div>
                                        <div className={classes.header}>
                                            <Typography variant="h4" className={classes.title}>
                                                Information
                                            </Typography>
                                            <EditorHeaderButtons
                                                editing={editorState.editing}
                                                viewLink={userStore.user.isOrganization ? getOrganizationLink(userStore.user.organization!.id) : undefined}
                                                viewTooltip={'View Public Page'}
                                                onEdit={() => editorState.setEditing(true)}
                                                onSave={() => handleSave(editorState)}
                                            />
                                        </div>
                                        {userStore.user.isVolunteer
                                            ? <VolunteerProfile
                                                editing={editorState.editing}
                                                volunteerDraft={volunteerDraft!}
                                            />
                                            : userStore.user.isOrganization
                                                ? <OrganizationProfile
                                                    editing={editorState.editing}
                                                    organizationDraft={organizationDraft!}
                                                    organizationToDisplay={editorState.editing ? organizationDraft! : getOrganizationProfileData()!}
                                                />
                                                : null
                                        }
                                    </div>
                                </Container>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container >
            )}
        </Editor >
    )
});

export default Profile;