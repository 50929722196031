import { Button, createStyles, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Calendar, ChevronDoubleDown, ChevronDoubleUp, ViewList } from "mdi-material-ui";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        },
        displayMode: {
            display: 'flex',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        }
    }),
);

export class ShiftListDisplayModeState {
    listView: boolean = true;
    shiftsExpanded: boolean = false;

    constructor(listView?: boolean) {
        makeAutoObservable(this);

        if (listView !== undefined) {
            this.listView = listView;
        }
    }

    setListView(listView: boolean) {
        this.listView = listView;
    }

    toggleShiftsExpanded() {
        this.shiftsExpanded = !this.shiftsExpanded;
    }
}

const listButton = (
    <ToggleButton value="list" aria-label="List View">
        <ViewList />
    </ToggleButton>
);

const calendarButton = (
    <ToggleButton value="calendar" aria-label="Calendar View">
        <Calendar />
    </ToggleButton>
)

interface ShiftListDisplayModeProps {
    displayModeState: ShiftListDisplayModeState;
    hideToggleButtonGroup?: boolean; // TODO: Remove after demo
    hideExpandShiftsButton?: boolean;
    calendarButtonFirst?: boolean;
}

const ShiftListDisplayMode = observer((props: ShiftListDisplayModeProps) => {

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Event Handlers *********/

    const onDisplayModeChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
        if (value) {
            props.displayModeState.setListView(value === 'list');
        }
    }

    /********* Render *********/

    return (
        <div className={classes.displayMode}>
            {/* TODO: Remove check after demo */}
            {props.hideToggleButtonGroup !== true &&
                <ToggleButtonGroup
                    value={props.displayModeState.listView ? 'list' : 'calendar'}
                    onChange={onDisplayModeChanged}
                    exclusive
                    aria-label="display view"
                >
                    {props.calendarButtonFirst ? calendarButton : listButton}
                    {props.calendarButtonFirst ? listButton : calendarButton}
                </ToggleButtonGroup>
            }
            {props.displayModeState.listView && !props.hideExpandShiftsButton
                ? <Button
                    startIcon={props.displayModeState.shiftsExpanded ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
                    onClick={() => props.displayModeState.toggleShiftsExpanded()}
                    variant="outlined"
                    size={xsDown ? 'small' : 'large'}
                >
                    {props.displayModeState.shiftsExpanded ? xsDown ? 'Collapse' : 'Collapse All' : xsDown ? 'Expand' : 'Expand All'}
                </Button>
                : null
            }
        </div>
    )
});

export default ShiftListDisplayMode;