import { observer } from "mobx-react";
import { useMediaQuery, Theme, Typography, List, ListItem, ListItemText } from "@material-ui/core";
import { DialogState } from "../../../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../../../Shared/Dialogs/ThemedDialogWithSpinner";
import { Download, Upload } from "mdi-material-ui";
import { Edit, Save } from "@material-ui/icons";
import { StylizedExternalLink } from "../../../../Navigation/Components";
import REMAvatar from "../../../../../shared/modules/rem-conversion/components/Avatar";
import REMListItemAvatar from "../../../../../shared/modules/rem-conversion/components/ListItemAvatar";

interface VolunteerImportDirectionsDialogProps {
    state: DialogState;
    onSubmit: () => void;
}

const VOLUNTEER_IMPORT_TEMPLATE_DOWNLOAD_URL = "https://docs.google.com/spreadsheets/u/1/d/1Gzpp9KaAqmQ6Gvn6GOeHDx9RonAV3BSOXzbrdooMj9k/export";

const instuctions = [{
    avatar: <Download />,
    primary: <Typography>Download this <StylizedExternalLink to={VOLUNTEER_IMPORT_TEMPLATE_DOWNLOAD_URL}>volunteer import template.</StylizedExternalLink></Typography>
}, {
    avatar: <Edit />,
    primary: "Fill out the template with your volunteer data."
}, {
    avatar: <Save />,
    primary: "Save the document as a .csv file."
}, {
    avatar: <Upload />,
    primary: "Click the Upload button below and select your .csv file."
}]

const VolunteerImportDirectionsDialog = observer((props: VolunteerImportDirectionsDialogProps) => {

    /*********************** React hooks ***********************/

    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /*********************** Render ***********************/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            title="Instructions to Import Volunteers"
            DialogProps={{ fullScreen: xsDown, maxWidth: 'sm' }}
            primaryButtonProps={{ children: 'Upload' }}
            onSubmit={props.onSubmit}
        >
            <List>
                {instuctions.map((step, index) => {
                    return (
                        <ListItem key={`instruction-step-${index}`}>
                            <REMListItemAvatar>
                                <REMAvatar>
                                    {step.avatar}
                                </REMAvatar>
                            </REMListItemAvatar>
                            <ListItemText primary={step.primary} />
                        </ListItem>
                    )
                })}
            </List>
        </ThemedDialogWithSpinner>
    );
});

export default VolunteerImportDirectionsDialog;