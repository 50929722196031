import { PortalForm } from "./PortalFormFields";
import { ValidationCheck } from "../../../../../stores/models/ValidationCheck";
import { CUSTOM_PORTAL_SLUG_MAX_LENGTH } from "../../../../../logic/ValidationChecks/FieldLengths";
import { getFieldRequiredValidationCheck, getMaxLengthValidationCheck, getValidationErrors } from "../../../../../logic/ValidationChecks/Validation";
import { ValidationError } from "../../../../../stores/models/ValidationError";

const isPortalSlugValid = (portalSlug: string) => {
    const urlSafeRegex = /^[a-zA-Z0-9\-]+$/;
    return urlSafeRegex.test(portalSlug);
}

const validationChecks: (portalForm: PortalForm) => ValidationCheck<PortalForm>[] =
    (portalForm: PortalForm) => [
        getFieldRequiredValidationCheck(portalForm.portalSlug, 'portalSlug', 'This field is required.'),
        getMaxLengthValidationCheck(portalForm.portalSlug!, 'portalSlug', CUSTOM_PORTAL_SLUG_MAX_LENGTH),
        {
            name: 'valid portal slug characters',
            condition: (portalForm: PortalForm) => {
                return portalForm.portalSlug?.length === 0 || isPortalSlugValid(portalForm.portalSlug!);
            },
            error: new ValidationError('Please enter only valid characters (letters, numbers, or hyphens).', ['portalSlug'])
        },
        {
            name: 'valid logo',
            condition: (portalForm: PortalForm) => {
                return portalForm.logoExists || portalForm.logo !== undefined;
            },
            error: new ValidationError('Please upload a logo.', ['logo'])
        }
    ];

export default function validatePortalForm(portalForm: PortalForm) {
    return getValidationErrors(validationChecks, portalForm);
}