import { ShiftOpportunities } from "../VolunteerStore";
import { Permission } from "./Permission";
import { IServerRole } from "./Role";
import { IServerAddress } from "./server/ServerAddress";
import { IServerVolunteer } from "./server/ServerVolunteer";
import { IServiceEntryTag } from "./ServiceEntryTag";
import { IStatus } from "./Status";

type SupplementalDataKeys = 'locations'
    | 'volunteers'
    | 'roles'
    | 'serviceEntryTags'
    | 'serviceEntryStatuses'
    | 'volunteerStatuses'
    | 'volunteerRegistrationStatuses'
    | 'shiftOpportunities'
    | 'organizationPermissions';

export type SupplementalOrganizationDataRequest = { [key in SupplementalDataKeys]?: boolean };

type SupplementalOrganizationData = {
    locations: IServerAddress[];
    volunteers: IServerVolunteer[];
    serviceEntryTags: IServiceEntryTag[];
    roles: IServerRole[];
    serviceEntryStatuses: IStatus[];
    volunteerStatuses: IStatus[];
    volunteerRegistrationStatuses: IStatus[];
    shiftOpportunities: ShiftOpportunities;
    organizationPermissions: Permission[];
}

export type ISupplementalOrganizationData = { supplementalOrganizationData: SupplementalOrganizationData };

export const isSupplementalOrganizationData = (x: any): x is ISupplementalOrganizationData => {
    if (typeof x === 'object') {
        return ('supplementalOrganizationData' in x);
    } else {
        return false;
    }
}

export const getSupplementalDataRequest = (dataToLoad: SupplementalDataKeys[]) => {
    let dataRequest = {} as SupplementalOrganizationDataRequest;
    dataToLoad.forEach(key => {
        dataRequest[key] = true;
    });

    return dataRequest;
}