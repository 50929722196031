import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { InternalLink } from '../Navigation/Components';
import { getPrivacyPolicyLink, getTermsOfServiceLink } from '../Navigation/Links';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        copyright: {
            display: 'flex',
            flexDirection: 'column',
        },
        legalLinks: {
            color: 'inherit',
            '&:not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        },
    })
);

const getCopyrightYear = () => {
    return new Date().getFullYear();
}

interface FooterCopyrightProps {
    linksClassName?: string;
}

const FooterCopyright = observer((props: FooterCopyrightProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const copyrightYear = getCopyrightYear();

    /********* Render *********/

    return (
        <div className={classes.copyright}>
            <Typography variant="caption">
                © {copyrightYear} Voltage Volunteering, LLC
            </Typography>
            <Typography variant="caption">
                All Rights Reserved
            </Typography>
            <Typography variant="caption" className={props.linksClassName}>
                <InternalLink to={getTermsOfServiceLink()} className={classes.legalLinks}>Terms of Service</InternalLink>
                <InternalLink to={getPrivacyPolicyLink()} className={classes.legalLinks}>Privacy Policy</InternalLink>
            </Typography>
        </div>
    );
});

export default FooterCopyright;