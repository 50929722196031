import { RecordListStore } from "../stores/RecordListStore";
import { ObjectWithIdentifier } from "../../../../../stores/models/NewOptionCollection";
import { APIAction } from "./APIAction";

export abstract class BulkEditRecordListAction<
    RecordType extends ObjectWithIdentifier<RecordType>,
    ServerRecordType = RecordType, Param = unknown
> extends APIAction<RecordType, Extract<keyof RecordType, string>, ServerRecordType, Param> {

    constructor(store: RecordListStore<RecordType>) {
        super(store);
    }

    abstract editData(param: Param): Promise<ServerRecordType[] | undefined>;

    async makeAPICall(param: Param) {
        let response = await this.editData(param);
        if (response) {
            const records = this.transformData(response);
            records.forEach(record => {
                this.store.collection.addOrUpdateOption(record);
            });
            if (this.store.requestingUnloadedRecords) {
                this.store.setReloadNeeded(true);
            }
        }
    }
}
