import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Routes, Route } from "react-router-dom";
import { organizationOpportunityToken } from "../../Navigation/Links/UrlConstructors";
import OpportunityInformation from "./OpportunityInformation";
import VolunteerOpportunities from "./VolunteerOpportunities";
import { BreadcrumbPath } from "../../../stores/models/BreadcrumbPath";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%'
        },
    }));

interface OrganizationOpportunitesProps {
    breadcrumbs: BreadcrumbPath;
}

const OrganizationOpportunites = observer((props: OrganizationOpportunitesProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <div id="organization-opportunities" className={classes.root}>
            <Routes>
                <Route path={organizationOpportunityToken} element={<OpportunityInformation breadcrumbs={props.breadcrumbs} />} />
                <Route path="/" element={<VolunteerOpportunities />} />
            </Routes>
        </div >
    );
});

export default OrganizationOpportunites;