import { NavigateOptions, To, generatePath, matchPath, useLocation, useNavigate } from "react-router-dom"
import { ORG_PORTAL_PATH_PATTERN, useOrgPortalPrefix } from "./OrganizationPortal/OrgPortalPrefix";
import { URITokens } from "../Links";

// Used to navigate within the app and retain an 
// organization-specific portal URI prefix if appropriate.
export const useNavigateInternally = () => {

    const navigate = useNavigate();
    const currentOrgPortalPrefix = useOrgPortalPrefix();
    const location = useLocation();

    const navigateInternally = (to: To, options?: NavigateOptions | undefined, portalSlug?: string | false) => {
        let link = to;
        const match = matchPath(ORG_PORTAL_PATH_PATTERN, to.toString());
        const prefix = portalSlug
            ? generatePath(ORG_PORTAL_PATH_PATTERN, { [(URITokens.Organization).replace(':', "")]: portalSlug })
            : portalSlug === false
                ? ''
                : currentOrgPortalPrefix;
        if (prefix && !match) {
            link = prefix.toString() + to;
        }

        // Replace the existing history instead of creating a new one if the path matches.
        if (link === location.pathname) {
            if (options) {
                options.replace = true;
            } else {
                options = { replace: true };
            }
        }
        navigate(link, options);
    }

    return navigateInternally;
}