import { Organization, RootContext } from "../../stores";
import CardGridItem from "./CardGridItem";
import { createElement, useContext } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { getAbsoluteLink, getOrganizationLink } from "../Navigation/Links/UrlConstructors";
import { useIsOrgPortal, useNavigateInternally } from "../Navigation/Hooks";
import REMChip from "../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles(theme => ({
    chip: {
        marginBottom: '4px',
        backgroundColor: theme.palette.primaryBackground.main,
        '& .MuiChip-icon, .MuiChip-label': {
            color: theme.palette.darkBackground.main
        }
    },
}));

interface OrganizationGridProps {
    organizations: Organization[];
    idPrefix: string;
    columns: 1 | 2 | 3 | 4;
    onCardClicked?: (cardId: string, organization: Organization) => void;
}

const OrganizationGrid = observer((props: OrganizationGridProps) => {

    const navigate = useNavigateInternally();
    const classes = useStyles();
    const isOrgPortal = useIsOrgPortal();
    const { organizationStore } = useContext(RootContext);

    /********* Event handlers *********/

    const handleCardClicked = (cardId: string, organization: Organization) => {
        if (props.onCardClicked) {
            props.onCardClicked(cardId, organization);
        } else {
            if (isOrgPortal && organizationStore.organization.id !== organization.id) {
                window.open(getAbsoluteLink(getOrganizationLink(organization.id)), "_blank");
            } else {
                navigate(getOrganizationLink(organization.id));
            }
        }
    }

    /********* Helper method *********/

    const getCardId = (orgId: number) => {
        return `${props.idPrefix}-${orgId}`;
    }

    /********* Render *********/

    return (
        <Grid container spacing={2}>
            {props.organizations?.map(organization => {
                const cardId = getCardId(organization.id);
                return (
                    <CardGridItem
                        key={cardId}
                        id={cardId}
                        title={organization.name}
                        object={organization}
                        description={organization.description}
                        onClick={() => handleCardClicked(cardId, organization)}
                        columns={1}//props.columns}
                        chips={organization.causes.map(cause => {
                            return (
                                <REMChip
                                    icon={createElement(cause.icon)}
                                    label={cause.cause}
                                    className={classes.chip}
                                />
                            )
                        })}
                    />
                );
            })}
        </Grid>
    )
});

export default OrganizationGrid;