import React, { useEffect, useState, useContext } from "react";
import { Tabs, Tab, Theme, makeStyles, createStyles, SvgIconProps, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import BusinessIcon from '@material-ui/icons/Business';
import clsx from 'clsx';
import useOrganizationLink from "../../Navigation/Hooks/OrganizationProfile/OrganizationLink";
import { useLocation, PathPattern } from "react-router-dom";
import { getOrganizationPath, getOrgOpportunitiesPath } from "../../Navigation/Links/UrlConstructors";
import { RootContext } from "../../../stores/RootStore";
import { observer } from "mobx-react";
import { ClipboardTextMultipleOutline } from "mdi-material-ui";
import useCurrentOpportunitiesTabLink from "../../Navigation/Hooks/OrganizationProfile/CurrentOrgOpportunitiesLink";
import { useMatchCurrentPath, useNavigateInternally } from "../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginTop: theme.spacing(1),
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden'
        },
        mobileHeader: {
            textAlign: 'center'
        },
        tabs: {
            flexGrow: 1,
        },
        desktopTabs: {
            '& > .MuiTabs-scroller': {
                display: 'flex'
            }
        },
        desktopTab: {
            minHeight: '50px',
            '& > span': {
                flexDirection: 'row'
            }
        },
        icon: {
            marginRight: theme.spacing(1)
        },
        selected: {
            color: 'white'
        },
        unselected: {
            // color: 'lightgray'
            // color: 'white'
        },
        tabIndicator: {
            backgroundColor: theme.palette.primary.main,
            height: '6px'
        },
    })
);

interface Tab {
    label: string;
    icon: (props: SvgIconProps) => JSX.Element;
    link: string;
    matchPathProps: PathPattern<string>;
}

function useTabs(): Tab[] {
    const organizationLink = useOrganizationLink();
    const opportunitiesLink = useCurrentOpportunitiesTabLink();

    return [{
        label: 'Organization',
        icon: BusinessIcon,
        link: organizationLink,
        matchPathProps: {
            path: getOrganizationPath(),
            end: true
        }
    }, {
        label: 'Opportunities',
        icon: ClipboardTextMultipleOutline,
        link: opportunitiesLink,
        matchPathProps: {
            path: getOrgOpportunitiesPath(),
            end: false
        }
    }];
}

function useActiveTab(tabs: Tab[]) {
    const routeMatch = useLocation();
    const matchPath = useMatchCurrentPath();

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        for (let index = 0; index < tabs.length; index++) {
            const routeProps = tabs[index].matchPathProps;
            const match = matchPath({ ...routeProps });
            if (match) {
                setActiveTab(index);
                break;
            }
        }
    }, [routeMatch]);

    return activeTab;
}

const OrganizationTabBar = observer(() => {

    // React hooks
    const classes = useStyles();
    const tabs = useTabs();
    const activeTab = useActiveTab(tabs);
    const navigate = useNavigateInternally();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const organization = useContext(RootContext).organizationStore.organization;

    return (
        <div>
            <div className={xsDown ? classes.mobileHeader : undefined}>
                <Typography variant="h6" className={classes.title}>
                    {organization.name}
                </Typography>
                <Typography variant="body2">{organization.isForProfit ? 'For-profit' : 'Nonprofit'}</Typography>
            </div>
            <Tabs
                value={activeTab}
                variant={xsDown ? 'fullWidth' : 'standard'}
                className={clsx(classes.tabs, (xsDown ? null : classes.desktopTabs))}
                TabIndicatorProps={{ className: classes.tabIndicator }}
            >
                {tabs.map((tab, index) => {
                    return (
                        <Tab
                            key={`organization-tab-${index}`}
                            label={tab.label}
                            icon={React.createElement(tab.icon, { className: clsx(xsDown ? null : classes.icon) })}
                            className={clsx(
                                (xsDown ? null : classes.desktopTab),
                                (activeTab === index ? classes.selected : classes.unselected))}
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                event.preventDefault();
                                navigate(tab.link);
                            }}
                        />
                    )
                })}
            </Tabs>
        </div >
    )
});

export default OrganizationTabBar;