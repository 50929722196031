import { useContext } from "react";
import { observer } from "mobx-react";
import { OrganizationStubVolunteerLinking } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { StepProps } from "../../../../stores/models/Step";
import { RootContext } from "../../../../stores";
import VerifyCorrectAccount from "../VerifyCorrectAccount";

const VerifyCorrectAccountStep = observer((props: StepProps<OrganizationStubVolunteerLinking>) => {

    const rootStore = useContext(RootContext);
    const user = rootStore.userStore.user;
    const volunteer = props.stepState.stepObject.linkDetails.volunteer;

    /***** Event handlers *****/

    const onLogoutClicked = async () => {
        props.stepState.setLoading(true);
        await rootStore.userStore.logout();
        props.stepState.setLoading(false);
    }

    const onConfirmed = async () => {
        props.stepState.setCompleted(true);
    }

    /***** Render *****/

    return (
        <VerifyCorrectAccount
            email={user.email}
            usersName={user.volunteer?.fullName || ''}
            prompt={`Does this account belong to ${volunteer.firstName} ${volunteer.lastName}?`}
            onLogoutClicked={onLogoutClicked}
            onConfirmed={onConfirmed}
        />
    );
});

export default VerifyCorrectAccountStep;