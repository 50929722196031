import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from 'mobx-react'
import { useParams } from "react-router-dom";
import { RootContext } from "../../../stores";
import LoadingIndicator from "../../Shared/LoadingIndicator";
import ZeroState from "../../Shared/ZeroState";
import OpportunityGrid from "../../Shared/OpportunityGrid";
import useGridColumns from "../../Shared/GridColumnsHook";
import { makeStyles, Theme, createStyles, Container } from "@material-ui/core";
import clsx from 'clsx';
import { Pagination } from "@material-ui/lab";
import PageWithFooter from "../../Shared/PageWithFooter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1
        },
        flexGrow: {
            flexGrow: 1,
        },
        pagination: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
        gridWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%'
        },
        flex: {
            display: 'flex',
            justifyContent: 'center',
        }
    })
);

const NUM_OPPORTUNITIES_TO_FETCH = 30;

const VolunteerOpportunities = observer(() => {

    /********* React hooks *********/
    const organizationStore = useContext(RootContext).organizationStore;
    const { orgId } = useParams() as { orgId: string };
    const organizationId = parseInt(orgId || "");
    const numColumns = useGridColumns();
    const classes = useStyles();

    /********* State *********/
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(isInitialLoad);
    const [page, setPage] = useState(1);

    /********* Effects *********/

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
        }
        requestOrganizationOpportunities();
    }, [organizationId, page]);

    /********* Helper methods *********/

    const requestOrganizationOpportunities = async () => {
        try {
            if (organizationId && organizationId > 0) {
                setIsLoading(true);
                const offset = (page - 1) * NUM_OPPORTUNITIES_TO_FETCH;
                await organizationStore.getOpportunitiesForOrganization(organizationId, NUM_OPPORTUNITIES_TO_FETCH, offset);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /********* Event handlers *********/

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }
    /********* Helper constants *********/

    const opportunities = organizationStore.organization.opportunities;
    const hasOpportunities = opportunities !== undefined && opportunities.length > 0;

    /********* Render *********/

    return (
        <Fragment>
            <PageWithFooter>
                <div className={clsx(classes.root, hasOpportunities ? undefined : classes.flexGrow)}>
                    <Container className={clsx(classes.flex, classes.flexGrow)}>
                        {isLoading
                            ? <LoadingIndicator />
                            : !hasOpportunities
                                ? <ZeroState content="Check back later! There aren't any opportunities posted right now." />
                                : <div className={classes.gridWrapper}>
                                    <OpportunityGrid opportunities={opportunities} idPrefix="organization-opportunities" columns={numColumns} />
                                    <div className={classes.flex}>
                                        <Pagination
                                            count={Math.ceil(organizationStore.organization.totalOpportunities / NUM_OPPORTUNITIES_TO_FETCH)}
                                            page={page}
                                            color="primary"
                                            className={classes.pagination}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                        }
                    </Container>
                </div>
            </PageWithFooter>
        </Fragment>
    )
});

export default VolunteerOpportunities;