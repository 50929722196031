import { MenuList, Paper, PaperProps } from "@material-ui/core";
import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { DialogState } from "../../stores/models/DialogState";
import NestedMenuItem, { NestedMenuItems } from "./NestedMenuItem";
import { UniqueIdentification } from "../../logic/UniqueIdentification";

interface NestedMenuProps extends PaperProps {
    state: DialogState;
    menuItems: NestedMenuItems<any>[];
}

const NestedMenu = observer((props: NestedMenuProps) => {

    const listId = useMemo(() => `menu-list-${UniqueIdentification.getClientId()}`, []);

    /********* Event Handlers *********/

    const handleListKeyDown: React.KeyboardEventHandler<HTMLUListElement> = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            props.state.setOpen(false);
        }
    }

    /********* Render *********/

    return (
        <Paper {...props}>
            <MenuList autoFocusItem={props.state.open} variant="menu" id={listId} onKeyDown={handleListKeyDown}>
                {props.menuItems.map((menuItem, index) => {
                    return <NestedMenuItem menuItem={menuItem} key={`${listId}-item-${index}`} />
                })}
            </MenuList>
        </Paper >
    );
});

export default NestedMenu;