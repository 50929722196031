import { observer } from 'mobx-react'
import { Link, LinkProps } from 'react-router-dom';

interface ExternalLinkProps extends LinkProps { }

const ExternalLink = observer((props: ExternalLinkProps) => {

    /********* Render *********/

    return (
        <Link {...props} />
    );
});

export default ExternalLink;