import { Fragment, useContext, useState } from "react";
import { Container, Paper, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import useResetScrollPosition from "../Shared/Hooks/ResetScrollPosition";
import Footer from "../Shared/Footer";
import { RootContext } from "../../stores";
import EmailPreferencesSelection from "./EmailPreferencesSelection";
import ErrorMessage from "../Shared/ErrorMessage";
import SuccessMessage from "../Shared/SuccessMessage";
import { ArrowLeft } from "mdi-material-ui";
import { getAccountProfileLink } from "../Navigation/Links";
import { useNavigateInternally } from "../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 0, 1),
            textAlign: 'left',
            flexGrow: 1
        },
        wrapperContainer: {
            maxWidth: 900
        },
        content: {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2)
            },
            padding: theme.spacing(3),
            textAlign: 'center'
        },
        backButton: {
            marginTop: theme.spacing(2),
            color: theme.palette.action.active
        }
    })
);

const EmailPreferencesPage = observer(() => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const navigate = useNavigateInternally();

    useResetScrollPosition();

    /***** State *****/

    const [preferencesUpdated, setPreferencesUpdated] = useState(false);
    const [error, setError] = useState(false);

    /***** Event handlers *****/

    const onPreferencesUpdated = () => {
        setPreferencesUpdated(true);
    }

    const onError = () => {
        setError(true);
    }

    const onClickContactUs = () => {
        rootStore.navigationStore.appwideDialogStates.contactUsDialog.setOpen(true);
        rootStore.helpStore.setHelpMessageOverrides({
            subject: 'Issue Adjusting Email Preferences',
        });
    }

    const navigateToProfile = () => {
        navigate(getAccountProfileLink());
    }

    /***** Render *****/

    return (
        <Fragment>
            <div id="unsubscription" className={classes.root}>
                <Container className={classes.wrapperContainer}>
                    <Paper>
                        <Container>
                            <div className={classes.content}>
                                {error
                                    ? <ErrorMessage
                                        title="Uh oh! This link is invalid."
                                        details="Please make sure the link you're using matches the link in your email. If you're still having trouble, please contact us."
                                        ButtonProps={{ children: 'Contact Us', onClick: onClickContactUs }}
                                    />
                                    : !preferencesUpdated
                                        ? <EmailPreferencesSelection
                                            onPreferencesUpdated={onPreferencesUpdated}
                                            onError={onError}
                                            onContactUs={onClickContactUs}
                                        />
                                        : <Fragment>
                                            <SuccessMessage
                                                details={(
                                                    <Fragment>
                                                        Your email preferences have been updated!
                                                    </Fragment>
                                                )}
                                            />
                                            {rootStore.userStore.isAuthenticated
                                                && <div>
                                                    <Button color='primary' variant='contained' onClick={navigateToProfile}>
                                                        Go To Your Profile
                                                    </Button>
                                                </div>
                                            }
                                            <Button
                                                onClick={() => setPreferencesUpdated(false)}
                                                className={classes.backButton}
                                                startIcon={<ArrowLeft />}
                                            >
                                                Back to Email Preferences
                                            </Button>
                                        </Fragment>
                                }
                            </div>
                        </Container>
                    </Paper>
                </Container>
            </div>
            <Footer />
        </Fragment>
    )
});

export default EmailPreferencesPage;