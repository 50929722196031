import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import { RootContext, ICause, Organization } from "../../../../../stores";
import EditableField from "../../../../Shared/EditableField";
import { MapMarker, Web } from "mdi-material-ui";
import { getExternalUrl } from "../../../../../logic";
import FieldHeader from "../../../../Shared/FieldHeader";
import LocationEditor from "../../../../Organization/LocationEditor";
import { Address } from "../../../../../stores/models/Address";
import ChipEditor from "../../../../Shared/ChipEditor";
import CausesDialog from "../../../../Organization/CausesDialog";
import { DialogState } from "../../../../../stores/models/DialogState";
import { observer } from "mobx-react";
import AddressDisplay from "../../../../Shared/AddressDisplay";
import { AddressCollection } from "../../../../../stores/models/Addressable";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import { INumericalIdOptionObject } from "../../../../../stores/models/Option";
import LoadingIndicator from "../../../../Shared/LoadingIndicator";
import { ORGANIZATION_NAME_MAX_LENGTH, ORGANIZATION_MISSION_MAX_LENGTH, ORGANIZATION_DESCRIPTION_MAX_LENGTH } from "../../../../../logic/ValidationChecks/FieldLengths";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subsection: {
            marginTop: theme.spacing(2),
        },
        detail: {
            marginBottom: theme.spacing(2)
        },
        title: {
            marginBottom: theme.spacing(1)
        },
        link: {
            textTransform: 'none'
        },
        textField: {
            marginRight: theme.spacing(2)
        },
        websiteLink: {
            textTransform: 'none'
        },
        address: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },
        icon: {
            marginRight: theme.spacing(1)
        }
    }),
);

enum DialogType {
    Causes = "causes"
}

const MAX_CAUSE_SELECTIONS = 5;

interface OrganizationProfileProps {
    editing: boolean;
    organizationDraft: Organization;
    organizationToDisplay: Organization;
}

const OrganizationProfile = observer((props: OrganizationProfileProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const locationStore = rootStore.locationStore;
    const organization = rootStore.userStore.user.organization;

    /********* Helper methods *********/

    const organizationDraft = props.organizationDraft;
    const orgToDisplay = props.organizationToDisplay;

    const getInitialDialogStates = () => {
        let state = {} as { [key in DialogType]: DialogState };
        Object.values(DialogType).forEach(dialog => { state[dialog as DialogType] = new DialogState(); });
        return state;
    }

    const getLocationCollection = () => {
        return new OptionCollection<'id', INumericalIdOptionObject, Address>(
            'id',
            organization?.locations.addresses || [],
            organizationDraft?.addressCollection.addresses
        );
    }

    /********* State *********/

    const [dialogStates] = useState(getInitialDialogStates());
    const [locationCollection, setLocationCollection] = useState(getLocationCollection());
    const [locationsLoaded, setLocationsLoaded] = useState(false);

    /********* Effects *********/

    useEffect(() => {
        if (props.editing) {
            setLocationsLoaded(false);
            loadLocations();
        }
    }, [props.editing]);

    useEffect(() => {
        setLocationCollection(getLocationCollection());
    }, [organization?.locations]);

    useEffect(() => {
        if (locationsLoaded) {
            const updatedAddressCollection = new AddressCollection(locationCollection.selectedOptions);
            organizationDraft?.setAddressCollection(updatedAddressCollection);
        }
    }, [locationCollection.selections]);

    /********* Helper methods *********/

    const loadLocations = async () => {
        await locationStore.getAllLocationsForOrganization();
        setLocationsLoaded(true);
    };

    /********* Event handlers *********/

    const handleOpenDialog = (dialog: DialogType) => { dialogStates[dialog].setOpen(true); };
    const handleCloseDialog = (dialog: DialogType) => { dialogStates[dialog].setOpen(false); };

    const handleConfirmCauses = (selectedCauses: ICause[]) => {
        organizationDraft?.setCauses(selectedCauses);
        handleCloseDialog(DialogType.Causes);
    };

    const handleCauseRemoved = (id: number) => {
        const newSelections = organizationDraft?.causes.filter(cause => cause.id !== id);
        if (newSelections) {
            organizationDraft?.setCauses(newSelections);
        }
    }

    /********* Render *********/

    return (
        <div className={classes.subsection}>
            {orgToDisplay === undefined
                ? null
                : <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.detail}>
                            <FieldHeader title="Organization Name" required={props.editing} />
                            <Grid item xs={12} sm={props.editing ? 6 : 12}>
                                <EditableField
                                    fieldName="name"
                                    value={orgToDisplay.name}
                                    editMode={props.editing}
                                    characterLimit={ORGANIZATION_NAME_MAX_LENGTH}
                                    onFieldEdited={(fieldName, event) => organizationDraft?.setName(event.target.value)}
                                    TextFieldProps={{
                                        className: classes.textField,
                                        fullWidth: true,
                                        variant: 'outlined',
                                        error: organizationDraft.isFieldInvalid('name'),
                                        helperText: organizationDraft.getErrorForField('name')
                                    }}
                                />
                            </Grid>
                        </div>
                        <div className={classes.detail}>
                            <FieldHeader title="Website" />
                            {props.editing
                                ?
                                <Grid item sm={props.editing ? 4 : 12}>
                                    <EditableField
                                        fieldName='website'
                                        value={orgToDisplay.website}
                                        editMode={props.editing}
                                        TextFieldProps={{
                                            variant: 'outlined',
                                            fullWidth: true,
                                            error: organizationDraft.isFieldInvalid('website'),
                                            helperText: organizationDraft.getErrorForField('website')
                                        }}
                                        onFieldEdited={(fieldName, event) => organizationDraft?.setWebsite(event.target.value)}
                                    />
                                </Grid>
                                : orgToDisplay.website
                                    ? <Button
                                        aria-label="visit website"
                                        href={getExternalUrl(orgToDisplay.website)}
                                        target='_blank'
                                        color="primary"
                                        startIcon={<Web />}
                                        className={classes.websiteLink}
                                    >
                                        <Typography noWrap>{orgToDisplay.website}</Typography>
                                    </Button>
                                    : <Typography>Not Provided</Typography>
                            }
                        </div>
                        {orgToDisplay.isForProfit
                            ? null
                            : <div className={classes.detail}>
                                <FieldHeader title="Mission" required={props.editing} />
                                <Grid item xs={12} sm={props.editing ? 9 : 12}>
                                    <EditableField
                                        fieldName='mission'
                                        value={orgToDisplay.mission}
                                        characterLimit={ORGANIZATION_MISSION_MAX_LENGTH}
                                        editMode={props.editing}
                                        onFieldEdited={(fieldName, event) => organizationDraft?.setMission(event.target.value)}
                                        TextFieldProps={{
                                            variant: 'outlined',
                                            fullWidth: true,
                                            multiline: true,
                                            rows: 2,
                                            rowsMax: 10,
                                            error: organizationDraft.isFieldInvalid('mission'),
                                            helperText: organizationDraft.getErrorForField('mission')
                                        }}
                                    />
                                </Grid>
                            </div>
                        }
                        <div className={classes.detail}>
                            <FieldHeader title="Description" required={props.editing} />
                            <EditableField
                                fieldName='description'
                                value={orgToDisplay.description}
                                characterLimit={ORGANIZATION_DESCRIPTION_MAX_LENGTH}
                                editMode={props.editing}
                                onFieldEdited={(fieldName, event) => organizationDraft?.setDescription(event.target.value)}
                                TextFieldProps={{
                                    variant: 'outlined',
                                    fullWidth: true,
                                    multiline: true,
                                    rows: 2,
                                    rowsMax: 20,
                                    error: organizationDraft.isFieldInvalid('description'),
                                    helperText: organizationDraft.getErrorForField('description')
                                }}
                            />
                        </div>
                        {/* Causes */}
                        <div className={classes.detail}>
                            <FieldHeader
                                title="Causes"
                                instructions={props.editing
                                    ? `What causes does your organization focus on? (Select up to ${MAX_CAUSE_SELECTIONS})`
                                    : undefined}
                            />
                            {props.editing ?
                                <div className={classes.subsection}>
                                    <ChipEditor
                                        options={orgToDisplay.causes}
                                        labelProperty={'cause'}
                                        buttonText={'Add Causes'}
                                        onAddButtonClicked={() => { handleOpenDialog(DialogType.Causes); }}
                                        onChipRemoved={handleCauseRemoved}
                                    />
                                    <CausesDialog
                                        state={dialogStates.causes}
                                        selectedCauses={orgToDisplay.causes}
                                        onConfirm={handleConfirmCauses}
                                        maxSelections={MAX_CAUSE_SELECTIONS}
                                    />
                                </div>
                                : <Typography>
                                    {orgToDisplay.causes.length > 0
                                        ? orgToDisplay.causes.map((cause, index) => {
                                            if (index < orgToDisplay.causes.length - 1) {
                                                return cause.cause + ", ";
                                            }
                                            return cause.cause;
                                        })
                                        : <Typography>None Listed</Typography>
                                    }
                                </Typography>
                            }
                        </div>
                        {/* Locations */}
                        <div className={classes.detail}>
                            <FieldHeader
                                title="Locations"
                                instructions={props.editing ? "Where is your organization located?" : undefined}
                                required={false}
                            />
                            {props.editing
                                ? locationsLoaded
                                    ? <LocationEditor
                                        addressOptions={locationCollection}
                                        addressRemovable={(selectedAddress: Address) => selectedAddress.id < -1}
                                    />
                                    : <LoadingIndicator />
                                : <div>
                                    {orgToDisplay.addressCollection.addresses.length > 0
                                        ? orgToDisplay.addressCollection.addresses.map((address, index) => {
                                            return (
                                                <div className={classes.address} key={`address-${index}`}>
                                                    <MapMarker fontSize='small' color={"primary"} className={classes.icon} />
                                                    <AddressDisplay address={address} />
                                                </div>
                                            );
                                        })
                                        : <Typography>None Listed</Typography>
                                    }
                                </div>
                            }
                        </div>
                    </Grid>
                    {/* <Grid item xs={6}>

                    <div className={classes.title}>
                        <Typography variant="h6">
                            Your Public Page
                        </Typography>
                    </div>
                    <Button
                        aria-label="your organization's page"
                        href={userStore.user.organization?.organizationPage}
                        color="primary"
                        startIcon={<Domain />}
                        className={classes.link}
                    >
                        <Typography noWrap>View or Edit</Typography>
                    </Button>
                </Grid> */}
                </Grid>
            }
        </div>
    )
});

export default OrganizationProfile;