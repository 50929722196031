import { observable, computed, action, makeObservable } from "mobx";
import { Volunteer, Organization } from ".";
import { isVolunteer } from "./Volunteer";

export enum UserType {
    Volunteer,
    Organization,
    None
}

export interface IUser {
    id: number;
    email: string;
    active: boolean;
    requiresSubscription: boolean;
    readonly registrationTimestamp: number;
    profileData?: Volunteer | Organization;
}

export class User implements IUser {
    id = -1;
    email = "";
    active = false;
    requiresSubscription = true;
    profileData?: Volunteer | Organization = undefined;

    readonly registrationTimestamp: number = 0;

    constructor(idOrUser?: number | IUser) {
        makeObservable(this, {
            id: observable,
            email: observable,
            active: observable,
            requiresSubscription: observable,
            profileData: observable,
            registrationDate: computed,
            volunteer: computed,
            organization: computed,
            isVolunteer: computed,
            isOrganization: computed,
            name: computed,
            setActive: action,
            setProfileData: action
        });

        if (idOrUser === undefined || typeof idOrUser === 'number') {
            this.id = idOrUser || -1;
        } else {
            this.id = idOrUser.id;
            this.email = idOrUser.email;
            if (idOrUser.profileData) {
                if (isVolunteer(idOrUser.profileData)) {
                    this.profileData = new Volunteer(idOrUser.profileData);
                } else {
                    this.profileData = new Organization(idOrUser.profileData);
                }
            }
            this.active = idOrUser.active;
            this.requiresSubscription = idOrUser.requiresSubscription;
            this.registrationTimestamp = idOrUser.registrationTimestamp;
        }
    }

    get registrationDate(): Date {
        return new Date(this.registrationTimestamp);
    }

    get volunteer(): Volunteer | undefined {
        if (this.profileData instanceof Volunteer) {
            return this.profileData;
        }
    }

    get organization(): Organization | undefined {
        if (this.profileData instanceof Organization) {
            return this.profileData;
        }
    }

    get isVolunteer(): boolean {
        return this.profileData instanceof Volunteer;
    }

    get isOrganization(): boolean {
        return this.profileData instanceof Organization;
    }

    get name(): string {
        if (this.profileData === undefined) {
            return '';
        } else if (this.profileData instanceof Volunteer) {
            return this.profileData.firstName;
        } else {
            return (this.profileData as Organization).name;
        }
    }

    // Setters

    setActive(active: boolean) {
        this.active = active;
    }

    // TODO: Should be able to remove after the volunteer API requests are updated to 
    // apply changes to the user's profileData property rather than the volunteer 
    // store's volunteer property.
    setProfileData(profileData: Volunteer | Organization) {
        this.profileData = profileData;
    }
}