import { ValidationError } from "./ValidationError";
import { computed, IReactionDisposer, reaction, action, observable, makeObservable } from "mobx";
import { trimStringValues } from "../../logic/Serialization";

type ErrorDictionary<T> = {
    [key in keyof T]: ValidationError<T>[];
};

type ErrorMessageDictionary<T> = {
    [key in keyof T]: string;
}

type DirtyFieldsDictionary<T> = {
    [key in keyof T]: boolean;
}

// export type KeysEnum<T> = Record<keyof Omit<Required<T>, keyof IFields<{}, {}>>, true>

export interface IFields<T, U extends T> {
    validated: boolean;
    errors: ErrorMessageDictionary<T>;
    setAllFieldsDirty: () => void;
    setFieldsDirty: (fields: Array<keyof T>) => void;
}

export abstract class Fields<FieldKeysInterface = {}, ObjectToValidate extends FieldKeysInterface = FieldKeysInterface> implements IFields<FieldKeysInterface, ObjectToValidate> {

    private dirtyFields = {} as DirtyFieldsDictionary<FieldKeysInterface>;
    allFieldsDirty: boolean = false;
    private fieldChangedReactions = [] as IReactionDisposer[];

    constructor() {
        makeObservable<Fields<FieldKeysInterface, ObjectToValidate>, "dirtyFields" | "setUpReactions" | "markRelatedFieldsDirty" | "errorDictionary" | "dirtyFieldErrorDictionary">(this, {
            dirtyFields: observable,
            allFieldsDirty: observable,
            setAllFieldsDirty: action,
            setUpFieldTracking: action,
            setFieldsDirty: action,
            trimmed: computed,
            isDirty: computed,
            validationErrors: computed,
            validated: computed,
            errors: computed,
            setUpReactions: action,
            markRelatedFieldsDirty: action,
            errorDictionary: computed,
            dirtyFieldErrorDictionary: computed
        });

        // Complete setup asynchronously to ensure the 
        // child's constructor has been run and that the 
        // child's properties can be observed.
        setTimeout(() => {
            this.setUpFieldTracking();
        }, 0);
    }

    /***** Public methods *****/

    setAllFieldsDirty() {
        this.allFieldsDirty = true;
        this.disposeAllReactions();
    }

    setUpFieldTracking() {
        this.initializeDirtyFields();
        this.setUpReactions();
    }

    setFieldsDirty(fields: Array<keyof FieldKeysInterface>) {
        fields.forEach(field => {
            this.dirtyFields[field] = true;
        });
    }

    private disposeAllReactions() {
        this.fieldChangedReactions.forEach(reaction => { reaction(); });
    }

    /***** Public computed properties *****/

    get trimmed(): ObjectToValidate {
        return {
            ...this,
            ...trimStringValues(this)
        };
    }

    // Returns whether or not any fields have been marked as dirty
    get isDirty() {
        for (let i = 0; i < Object.values(this.dirtyFields).length; i++) {
            if (Object.values(this.dirtyFields)[i]) {
                return true;
            }
        }
        return false;
    }

    abstract get validationErrors(): ValidationError<ObjectToValidate>[];

    // Returns whether or not all of the object's validations checks have passed
    get validated() {
        return this.validationErrors ? this.validationErrors.length === 0 : false;
    }

    // Returns errors formatted for display
    get errors() {
        let formattedErrors = {} as ErrorMessageDictionary<FieldKeysInterface>;
        Object.keys(this).forEach(property => {
            let errors = [] as ValidationError<ObjectToValidate>[];
            if (this.allFieldsDirty) {
                errors = this.errorDictionary[property as keyof FieldKeysInterface];
            } else if (property in this.dirtyFieldErrorDictionary) {
                errors = this.dirtyFieldErrorDictionary[property as keyof FieldKeysInterface];
            }
            formattedErrors[property as keyof FieldKeysInterface] = this.formatErrorMessage(errors);
        });
        return formattedErrors;
    }

    getErrorForField(field: keyof FieldKeysInterface) {
        return this.errors[field];
    }

    isFieldInvalid(field: keyof FieldKeysInterface) {
        const fieldErrors = this.getErrorForField(field);
        return fieldErrors.length > 0;
    }

    /***** Setup *****/

    private initializeDirtyFields() {
        let dirtyFields = {} as DirtyFieldsDictionary<FieldKeysInterface>;
        Object.keys(this).forEach(property => {
            dirtyFields[property as keyof FieldKeysInterface] = false;
        });
        return dirtyFields;
    }

    private async setUpReactions() {
        this.fieldChangedReactions = Object.keys(this).map(property => {
            return reaction(
                // Note: In actuality, more than just the FieldKeysInterface properties will be tracked, but the
                // alternative is passing in a dummy object with the same properties as the FieldKeysInterface
                // to iterate over, which also isn't a great way of doing this.
                () => { return (this as unknown as FieldKeysInterface)[property as keyof FieldKeysInterface]; },
                (newValue, prevValue, reaction) => {
                    this.dirtyFields[property as keyof FieldKeysInterface] = true;
                    this.markRelatedFieldsDirty(property as keyof FieldKeysInterface);
                    reaction.dispose();
                }
            )
        });
    }

    /***** Private error logic *****/

    // Marks all fields that share an error message as dirty when 
    // a change to one of them triggers the error.
    private markRelatedFieldsDirty(field: keyof FieldKeysInterface) {
        if (this.errorDictionary[field]) {
            this.errorDictionary[field].forEach(error => {
                error.errorProperties?.forEach(property => {
                    this.dirtyFields[property as keyof FieldKeysInterface] = true;
                });
            });
        }
    }

    protected get errorDictionary() {
        let dictionary = {} as ErrorDictionary<ObjectToValidate>;
        this.validationErrors.forEach(error => {
            error.errorProperties?.forEach(property => {
                if (property in dictionary) {
                    dictionary[property].push(error);
                } else {
                    dictionary[property] = [error];
                }
            });
        });
        return dictionary;
    }

    private get dirtyFieldErrorDictionary() {
        let dictionary = {} as ErrorDictionary<ObjectToValidate>;
        Object.keys(this.dirtyFields).forEach(key => {
            const property = key as keyof ObjectToValidate;
            if (this.dirtyFields[key as keyof FieldKeysInterface]) {
                dictionary[property] = this.errorDictionary[property];
            }
        });
        return dictionary;
    }

    private formatErrorMessage(errors: ValidationError<ObjectToValidate>[]) {
        let errorMessage = '';
        if (errors) {
            errors.forEach((error, index) => {
                if (index > 0) {
                    errorMessage += '\n';
                }
                errorMessage += error.message;
            });
        }
        return errorMessage;
    }
}