import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { StubOrganization, OrganizationType } from "../../stores/models/StubOrganization";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, getFieldRequiredValidationCheck, getMaxLengthValidationCheck } from "./Validation";
import { SubscriptionPlanOptions } from "../../components/Authentication/RegistrationSteps/Organization/Plans";
import { einIsValid, websiteIsValid } from "../RegexValidation";
import { ORGANIZATION_NAME_MAX_LENGTH, ORGANIZATION_MISSION_MAX_LENGTH, ORGANIZATION_DESCRIPTION_MAX_LENGTH } from "./FieldLengths";

//Organization type validation checks
const organizationTypeChecks: ValidationCheck<StubOrganization>[] = [{
    name: 'organization type selected',
    condition: (stubOrganization: StubOrganization) => {
        return stubOrganization.type !== OrganizationType.None;
    },
    error: new ValidationError('You must select an organization type.')
}];

export function validateOrganizationType(stubOrganization: StubOrganization) {
    return getValidationErrors(organizationTypeChecks, stubOrganization);
}

//Organization type validation checks
const subscriptionPlanChecks: ValidationCheck<StubOrganization>[] = [{
    name: 'subscription plan selected',
    condition: (stubOrganization: StubOrganization) => {
        return stubOrganization.subscriptionPlanType !== SubscriptionPlanOptions.Unselected;
    },
    error: new ValidationError('You must select an subscription plan.')
}];

export function validateSubscriptionPlan(stubOrganization: StubOrganization) {
    return getValidationErrors(subscriptionPlanChecks, stubOrganization);
}

//Organization identity validation checks
const organizationIdentityChecks: (stubOrganization: StubOrganization) => ValidationCheck<StubOrganization>[] =
    (stubOrganization: StubOrganization) => [
        getFieldRequiredValidationCheck(stubOrganization.name, 'name', `Please enter your organization's name.`),
        getMaxLengthValidationCheck(stubOrganization.name, 'name', ORGANIZATION_NAME_MAX_LENGTH),
        {
            name: 'valid ein',
            condition: (stubOrganization: StubOrganization) => {
                return stubOrganization.type === OrganizationType.Forprofit || einIsValid(stubOrganization.ein)
            },
            error: new ValidationError(`Please enter your organization's 9 digit EIN.`, ['ein'])
        },
        getFieldRequiredValidationCheck(stubOrganization.signUpCode, 'signUpCode', `Please enter the sign up code that was emailed to you.`),
    ];
// TODO: add check for unique organization by ein

export function validateOrganizationIdentity(stubOrganization: StubOrganization) {
    return getValidationErrors(organizationIdentityChecks, stubOrganization);
}

// Organization details validation checks
const organizationChecks: (stubOrganization: StubOrganization) => ValidationCheck<StubOrganization>[] =
    (stubOrganization: StubOrganization) => {
        let checks: ValidationCheck<StubOrganization>[] = [
            getFieldRequiredValidationCheck(stubOrganization.description, 'description', `Please enter a description for your organization.`),
            getMaxLengthValidationCheck(stubOrganization.description, 'description', ORGANIZATION_DESCRIPTION_MAX_LENGTH),
            {
                name: 'valid website',
                condition: (stubOrganization: StubOrganization) => websiteIsValid(stubOrganization.website),
                error: new ValidationError(`Please enter a valid website.`, ['website'])
            }
        ];
        if (!stubOrganization.isForProfit) {
            checks.push(
                getFieldRequiredValidationCheck(stubOrganization.mission, 'mission', `Please enter your organization's mission.`),
                getMaxLengthValidationCheck(stubOrganization.mission, 'mission', ORGANIZATION_MISSION_MAX_LENGTH),
            )
        }
        return checks;
    };

export function validateOrganizationDetails(stubOrganization: StubOrganization) {
    return getValidationErrors(organizationChecks, stubOrganization);
} 