import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Avatar as MUIAvatar, AvatarProps as MUIAvatarProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '2.5rem',
            width: '2.5rem',
        },
    })
);

interface AvatarProps extends MUIAvatarProps {

}

const REMAvatar = observer((props: AvatarProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIAvatar
            {...props}
            classes={{
                ...props.classes,
                root: clsx(classes.root, props.classes?.root),
            }}
        >
            {props.children}
        </MUIAvatar>
    );
});

export default REMAvatar;