import { action, makeObservable, observable, override } from 'mobx';
import { RootStore } from './RootStore';
import { IDashboardData } from './models/DashboardData';
import { DataStore } from './DataStore';

enum ImageRequestType {
    ValidateImageUrl = 'validate-image-url'
}

/*** Maintains the data for a single organization ***/

export class ImageStore extends DataStore<ImageRequestType> {

    @observable imageCacheDates = new Map<string, Date>(); // Maps an image's src to a Date to append to the src in order to reload the image at appropriate times

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    /*************** API Methods ***************/

    /*  ------------ Request URLs ------------  */

    @override get requestURLs() {
        return {
            [ImageRequestType.ValidateImageUrl]: (data: { url: string }) => data.url
        };
    }

    /*  ------------ GET Methods ------------  */

    @override get getRequests() {
        return [ImageRequestType.ValidateImageUrl];
    }

    async validateImageUrl(url: string) {
        try {
            await this.makeRequest<IDashboardData>(ImageRequestType.ValidateImageUrl, { url });
            return true;
        } catch (err) {
            return false;
        }
    };

    /*************** Utility Methods ***************/

    @action resetCacheDateForImage(src: string) {
        const srcWithoutQuery = src.replace(/\?.*/, '');
        if (this.imageCacheDates.has(srcWithoutQuery)) {
            this.imageCacheDates.set(srcWithoutQuery, new Date());
        }
    }
}


