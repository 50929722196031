import { Fragment } from "react";
import { Container, Paper, makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import PrivacyPolicy from "./PrivacyPolicy";
import Footer from "../../../Shared/Footer";
import useResetScrollPosition from "../../../Shared/Hooks/ResetScrollPosition";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 0, 1),
            textAlign: 'left'
        },
        wrapperContainer: {
            maxWidth: 900
        },
        content: {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2)
            },
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        title: {
            marginBottom: theme.spacing(3)
        },
    })
);

const PrivacyPolicyPage = observer(() => {

    /***** React hooks *****/

    const classes = useStyles();
    useResetScrollPosition();

    /***** Render *****/

    return (
        <Fragment>
            <div id="privacy-policy" className={classes.root}>
                <Container className={classes.wrapperContainer}>
                    <Paper>
                        <Container>
                            <div className={classes.content}>
                                <h1 className={classes.title}>
                                    Privacy Policy
                                </h1>
                                <PrivacyPolicy />
                            </div>
                        </Container>
                    </Paper>
                </Container>
            </div>
            <Footer />
        </Fragment>
    )
});

export default PrivacyPolicyPage;