import React from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import LoginOrSignUpForm from "../LoginOrSignUpForm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(2),
            color: theme.palette.action.active
        },
        prompt: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(1),
            maxWidth: 700,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        loginWrapper: {
            maxWidth: 400,
            margin: 'auto',
            marginTop: theme.spacing(3)
        }
    })
);

interface AuthenticateToLinkAccountProps {
    title?: string;
    prompt: string;
    onSwitchToSignUp: () => void;
    onLoadingStateChanged: (isLoading: boolean) => void;
}

const AuthenticateToLinkAccount = observer((props: AuthenticateToLinkAccountProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <React.Fragment>
            {props.title
                ? <Typography variant="h3" className={classes.title}>
                    {props.title}
                </Typography>
                : null
            }
            <Typography variant="h5" className={classes.prompt}>
                {props.prompt}
            </Typography>
            <div className={classes.loginWrapper}>
                <LoginOrSignUpForm
                    onSwitchToSignUp={props.onSwitchToSignUp}
                    onLoadingStateChanged={props.onLoadingStateChanged}
                />
            </div>
        </React.Fragment>
    );
});

export default AuthenticateToLinkAccount;