import { TextField, makeStyles, Theme, createStyles, InputAdornment, CircularProgress, Typography } from "@material-ui/core";
import { useState, useContext, ChangeEvent } from "react";
import { observer } from "mobx-react";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Credentials } from "../../../../stores/models/Credentials";
import { RootContext } from "../../../../stores";
import { CancellablePromise } from "mobx/dist/internal";
import useDebouncedEffect from "../../../Shared/Hooks/DebouncedEffect";

// TODO: Refactor registration steps to use this component.

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
            minWidth: '40ch',
            [theme.breakpoints.down('sm')]: {
                flexGrow: 1,
                minWidth: 'auto'
            },
            '& > .MuiFormHelperText-root': {
                whiteSpace: 'pre-line'
            }
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        errorIcon: {
            color: theme.palette.accent.main
        },
        matchIcon: {
            color: theme.palette.primary.main
        },
        title: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(3),
            textAlign: 'center'
        },
    }),
);

interface CredentialsFieldsProps {
    credentials: Credentials;
    validationRun?: boolean;
}

const CredentialsFields = observer((props: CredentialsFieldsProps) => {

    const credentials = props.credentials;

    /***** React hooks *****/

    const classes = useStyles();
    const registrationStore = useContext(RootContext).registrationStore;

    /***** State *****/

    const [uniqueEmailCheck, setUniqueEmailCheck] = useState<CancellablePromise<boolean>>();

    /***** Effects *****/

    useDebouncedEffect({
        trigger: credentials.email,
        effect: () => {
            if (credentials.emailIsValid) {
                verifyEmailIsUnique();
            }
        }
    })

    /***** Helper methods *****/

    const verifyEmailIsUnique = async () => {
        credentials.setFieldsDirty(['email']);
        if (uniqueEmailCheck) {
            uniqueEmailCheck.cancel();
        }
        const promise = registrationStore.checkUsernameUniqueness(credentials.email);
        setUniqueEmailCheck(promise);
        const usernameAlreadyExists = await promise;
        credentials.setUnique(!usernameAlreadyExists);
    }

    /***** Event handlers *****/

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        credentials.setEmail(event.target.value);
    }

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        credentials.setPassword(event.target.value);
    }

    const handleConfirmedPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        credentials.setConfirmedPassword(event.target.value);
    }

    /***** Helper constants *****/

    const validationRun = props.validationRun;
    const emailError = validationRun || credentials.emailIsValid ? credentials.errors.email : '';
    const passwordError = validationRun ? credentials.errors.password : '';
    const confirmedPasswordError = validationRun ? credentials.errors.confirmedPassword : '';

    /***** Render *****/

    return (
        <div className={classes.flex}>
            <div className={classes.title}>
                <Typography variant="h5">Create your account.</Typography>
            </div>
            <TextField
                id="standard-email-input"
                label="Email"
                autoComplete="current-email"
                variant="outlined"
                required
                value={credentials.email}
                onChange={handleEmailChange}
                autoFocus
                className={classes.textField}
                error={emailError.length > 0 && (!credentials.emailIsValid || credentials.unique === false)}
                helperText={emailError.length > 0 ? emailError : undefined}
                InputProps={{
                    endAdornment: credentials.emailIsValid
                        ? <InputAdornment position="end">
                            {credentials.unique === undefined
                                ? <CircularProgress size={20} />
                                : credentials.unique
                                    ? <CheckCircleIcon className={classes.matchIcon} />
                                    : <ErrorIcon className={classes.errorIcon} />
                            }
                        </InputAdornment>
                        : null
                }}
            />
            <TextField
                id="standard-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
                required
                value={credentials.password}
                onChange={handlePasswordChange}
                className={classes.textField}
                error={passwordError.length > 0}
                helperText={passwordError.length > 0 ? passwordError : undefined}
            />
            <TextField
                id="confim-password-input"
                label="Confirm Password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
                required
                value={credentials.confirmedPassword}
                onChange={handleConfirmedPasswordChange}
                className={classes.textField}
                error={confirmedPasswordError.length > 0}
                helperText={confirmedPasswordError.length > 0 ? confirmedPasswordError : undefined}
                InputProps={{
                    endAdornment: credentials.confirmedPassword.length > 0
                        ? <InputAdornment position="end">
                            {credentials.passwordsMatch
                                ? <CheckCircleIcon className={classes.matchIcon} />
                                : <ErrorIcon className={classes.errorIcon} />
                            }
                        </InputAdornment>
                        : null
                }}
            />
        </div>
    );
});

export default CredentialsFields;