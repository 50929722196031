import {
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    SvgIconProps,
    makeStyles,
    Theme,
    createStyles,
    FormHelperText,
    FormControl,
    FormGroup,
    Checkbox,
    ListItemSecondaryAction,
} from "@material-ui/core";
import React from "react";
import { StringIdentifiedOptionCollection } from "../../stores/models/OptionCollection";
import { observer } from "mobx-react";
import { IOptionObject } from "../../stores/models/Option";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        secondaryAction: {
            paddingRight: '3rem'
        },
        selected: {
            '& $avatar': {
                color: theme.palette.primary.light
            }
        },
        avatar: {
            color: theme.palette.action.disabled,
            backgroundColor: 'rgba(255,255,255,0)'
        },
        instructions: {
            marginRight: theme.spacing(1)
        },
        hidden: {
            display: 'none'
        }
    }),
);

export interface ICheckboxListOption extends IOptionObject {
    primaryText: string;
    secondaryText?: string;
    icon?: (props: SvgIconProps) => JSX.Element;
}

interface CheckboxListProps {
    options: StringIdentifiedOptionCollection<ICheckboxListOption>;
    error?: string;
}

const CheckboxList = observer((props: CheckboxListProps) => {

    const classes = useStyles();

    return (
        <List>
            <FormControl
                component="fieldset"
                error={props.error ? true : false}
                className={props.error ? undefined : classes.hidden}
            >
                <FormHelperText>{props.error}</FormHelperText>
            </FormControl>
            <FormGroup>
                {props.options.options.map((option, index) => {
                    return (
                        <ListItem
                            key={`checkbox-list-${index}`}
                            button={true}
                            onClick={(event) => option.toggleSelection()}
                            disableGutters
                            className={option.selected ? classes.selected : undefined}
                            classes={{
                                secondaryAction: classes.secondaryAction
                            }}
                        >
                            {option.object.icon
                                ? <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        {React.createElement(option.object.icon)}
                                    </Avatar>
                                </ListItemAvatar>
                                : null}
                            <ListItemText
                                primary={option.object.primaryText}
                                secondary={option.object.secondaryText}
                                className={classes.instructions}
                            />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    value={option.object.value}
                                    checked={option.selected}
                                    color="primary"
                                    onClick={() => option.toggleSelection()}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </FormGroup>
        </List>
    )
});

export default CheckboxList;