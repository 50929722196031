import { ChangeEvent } from "react";
import { Typography, TextField, TextFieldProps, TypographyProps } from "@material-ui/core";
import TextFieldWithCharacterLimit from "./TextFieldWithCharacterLimit";
import useFormattedContent from "../../shared/modules/content/hooks/useFormattedContent";

export interface EditableFieldProps {
    value: string;
    fieldName: string;
    editMode: boolean;
    characterLimit?: number;
    placeholderText?: string;
    TextFieldProps?: TextFieldProps;
    TypographyProps?: TypographyProps;
    disableLinks?: boolean;
    onFieldEdited: (fieldName: string,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

// TODO: Add errors

const EditableField = (props: EditableFieldProps) => {

    const formattedContent = useFormattedContent({ content: props.value });

    return (
        <div>
            {props.editMode
                ? props.characterLimit
                    ? <TextFieldWithCharacterLimit
                        characterLimit={props.characterLimit}
                        TextFieldProps={{
                            onChange: (event) => props.onFieldEdited(props.fieldName, event),
                            value: props.value,
                            ...props.TextFieldProps
                        }}
                    />
                    : <TextField
                        onChange={(event) => props.onFieldEdited(props.fieldName, event)}
                        value={props.value}
                        {...props.TextFieldProps}
                    />
                : <Typography {...props.TypographyProps}>
                    {props.value
                        ? props.disableLinks ? props.value : formattedContent
                        : props.placeholderText
                            ? props.placeholderText
                            : 'Not Provided'
                    }
                </Typography>
            }
        </div>
    );
};

export default EditableField;