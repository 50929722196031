import { useContext, useState } from "react";
import { Typography, makeStyles, Theme, createStyles, Container, useMediaQuery, Button, Grid, Avatar } from "@material-ui/core";
import { AccountSearch, Hand, Resistor, LightningBolt, BatteryHigh, Magnify } from "mdi-material-ui";
import { getSearchLink, getOrganizationSignUpLink } from "../Navigation/Links";
import { RootContext } from "../../stores";
import Footer from "../Shared/Footer";
import MobileSearchPrompt, { SearchPromptSteps } from "../Search/Prompts/MobileSearchPrompt";
import { DialogState } from "../../stores/models/DialogState";
import HeroImage from './volunteers-with-outlet.png';
import clsx from 'clsx';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import useResetScrollPosition from "../Shared/Hooks/ResetScrollPosition";
import { useNavigateInternally } from "../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            color: theme.palette.action.active + ' !important',
            textAlign: 'left',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        title: {
            marginBottom: theme.spacing(1),
            '& > h1': {
                lineHeight: 1
            }
        },
        subtitle: {
            marginTop: theme.spacing(2)
        },
        emphasizedText: {
            color: theme.palette.primary.main,
            fontWeight: 500
        },
        bold: {
            fontWeight: 400,
            fontFamily: ['Play', 'Arial'].join(',')
        },
        listGrid: {
            '& > $gridItem': {
                '&:first-child': {
                    marginTop: theme.spacing(12),
                    [theme.breakpoints.down('xs')]: {
                        marginTop: theme.spacing(6),
                    }
                },
                '&:not(:last-child)': {
                    marginBottom: theme.spacing(16),
                    [theme.breakpoints.down('xs')]: {
                        marginBottom: theme.spacing(10)
                    }
                },
                '&:last-child': {
                    marginBottom: theme.spacing(12),
                    [theme.breakpoints.down('xs')]: {
                        marginBottom: theme.spacing(6)
                    }
                },
            }
        },
        flex: {
            display: 'flex',
            alignItems: 'center'
        },
        gridItemText: {
            maxWidth: '450px',
        },
        avatar: {
            width: '100px',
            height: '100px',
            [theme.breakpoints.down('sm')]: {
                width: '75px',
                height: '75px',
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(2)
            },
            background: theme.palette.primary.main,
            '& > svg': {
                fontSize: '2.75rem'
            }
        },
        left: {
            '& > $gridItemText': {
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: '50px'
                },
                [theme.breakpoints.up('md')]: {
                    paddingLeft: '100px'
                }
            },
        },
        right: {
            '& > $gridItemText': {
                [theme.breakpoints.up('sm')]: {
                    paddingRight: '50px'
                },
                [theme.breakpoints.up('md')]: {
                    paddingRight: '100px'
                }
            }
        },
        secondaryCallToAction: {
            marginTop: theme.spacing(2)
        },
        gridItem: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexGrow: 1,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        grow: {
            flexGrow: 1
        },
        paper: {
            background: theme.palette.common.white,
            padding: theme.spacing(5, 8),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            }
        },
        buttonWrapper: {
            marginTop: theme.spacing(3),
            display: 'flex',
        },
        button: {
            [theme.breakpoints.down('xs')]: {
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
            },
        },
        leftButton: {
            paddingRight: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2)
            },
            justifyContent: 'flex-end'
        },
        rightButton: {
        },
        image: {
            width: '450px',
            [theme.breakpoints.only('xs')]: {
                maxWidth: '225px',
                marginBottom: theme.spacing(2)
            },
            [theme.breakpoints.only('sm')]: {
                width: '250px',
            },
            [theme.breakpoints.only('md')]: {
                width: '350px',
            },
        },
    })
);

const Home = () => {

    useResetScrollPosition();

    const classes = useStyles();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const navigate = useNavigateInternally();
    const rootStore = useContext(RootContext);
    const navigationStore = rootStore.navigationStore;

    const [searchDialogState] = useState(new DialogState());

    const onVolunteerButtonClicked = () => {
        // searchDialogState.setOpen(true);
        navigateToSearchPage();
    }

    const navigateToSearchPage = () => {
        navigate(getSearchLink());
    }

    const onRecruitButtonClicked = () => {
        if (rootStore.userStore.user.isVolunteer) {
            rootStore.navigationStore.appwideDialogStates.logoutOfVolunteerAccountDialog.setOpen(true);
        } else {
            navigate(getOrganizationSignUpLink());
        }
    }

    const onVolunteerSignUpButtonClicked = () => {
        navigationStore.appwideDialogStates.signUpDialog.setOpen(true);
    }

    const homePageImage = (
        <div className={classes.flex}>
            <img src={HeroImage} alt="Volunteers lifting plug" className={classes.image} />
        </div>
    );

    const lightningBoltAvatar = (
        <Avatar className={classes.avatar}>
            <LightningBolt fontSize="large" />
        </Avatar>
    );

    return (
        <div id="home-page" className={classes.root}>
            <div className={classes.paper}>
                <Container disableGutters={smDown}>
                    <div className={classes.gridItem}>
                        {/* Mobile main image */}
                        {xsDown ? homePageImage : null}
                        {/* Main header */}
                        <div className={classes.title}>
                            <Typography variant={xsDown ? "h4" : smDown ? "h3" : mdDown ? "h2" : "h1"} className={classes.bold}>Volunteers</Typography>
                            <Typography variant={xsDown ? "h4" : smDown ? "h3" : mdDown ? "h2" : "h1"} className={classes.emphasizedText}>empower</Typography>
                            <Typography variant={xsDown ? "h4" : smDown ? "h3" : mdDown ? "h2" : "h1"} className={classes.bold}>communities.</Typography>
                            <Typography variant={smDown ? "h5" : "h4"} className={classes.subtitle}>
                                Find an outlet for good.
                            </Typography>
                            {/* Calls to Action */}
                            <div className={classes.buttonWrapper}>
                                <div className={classes.leftButton}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={onVolunteerButtonClicked}
                                        startIcon={<Hand />}
                                        size="large"
                                    >
                                        Volunteer
                                </Button>
                                </div>
                                <div className={classes.rightButton}>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        className={classes.button}
                                        onClick={onRecruitButtonClicked}
                                        startIcon={<AccountSearch />}
                                        size="large"
                                    >
                                        Recruit
                                </Button>
                                </div>
                            </div>
                        </div>
                        {/* Main image */}
                        {xsDown ? null : homePageImage}
                    </div>
                </Container>
            </div>
            {/* List of additional calls to action */}
            <Container>
                <Grid container className={classes.listGrid}>
                    <Grid item xs={12} className={clsx(classes.gridItem, classes.left)}>
                        <div className={classes.grow} />
                        <Avatar className={classes.avatar}>
                            <Resistor fontSize="large" />
                        </Avatar>
                        <div className={classes.gridItemText}>
                            <Typography variant={xsDown ? "h5" : smDown || mdDown ? "h4" : "h3"} className={classes.bold}>
                                {`Less `}
                                <span className={classes.emphasizedText}>
                                    resistance.
                                </span>
                            </Typography>
                            <Typography variant={xsDown ? "h5" : smDown || mdDown ? "h4" : "h3"} className={classes.bold}>
                                {`More assistance.`}
                            </Typography>
                            <Typography className={classes.subtitle}>
                                {`Voltage Volunteering makes it easy to find ways to get involved—`}
                                {`quickly connecting volunteers with organizations, allowing volunteers to put `}
                                {`their valuable time and energy to productive use.`}
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.secondaryCallToAction}
                                onClick={navigateToSearchPage}
                                startIcon={<Magnify />}
                                size="large"
                            >
                                Browse Opportunities
                            </Button>
                        </div>
                        <div className={classes.grow} />
                    </Grid>
                    <Grid item xs={12} className={clsx(classes.gridItem, classes.right)}>
                        <div className={classes.grow} />
                        {/* Mobile avatar */}
                        {xsDown ? lightningBoltAvatar : null}
                        <div className={classes.gridItemText}>
                            <Typography variant={xsDown ? "h5" : smDown || mdDown ? "h4" : "h3"} className={classes.bold}>
                                {`Take `}
                                <span className={classes.emphasizedText}>
                                    charge
                                </span>
                                {` of your`}
                            </Typography>
                            <Typography variant={xsDown ? "h5" : smDown || mdDown ? "h4" : "h3"} className={classes.bold}>
                                {`social impact.`}
                            </Typography>
                            <Typography className={classes.subtitle}>
                                {`Use your unique interests and experience to find an opportunity that electrifies you. `}
                                {`Sign up for a volunteer account to save any opportunities or organizations that spark your interest. `}
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.secondaryCallToAction}
                                startIcon={<EmojiPeopleIcon />}
                                onClick={onVolunteerSignUpButtonClicked}
                                size="large"
                            >
                                Sign Up as a Volunteer
                            </Button>
                        </div>
                        {xsDown ? null : lightningBoltAvatar}
                        <div className={classes.grow} />
                    </Grid>
                    <Grid item xs={12} className={clsx(classes.gridItem, classes.left)}>
                        <div className={classes.grow} />
                        <Avatar className={classes.avatar}>
                            <BatteryHigh fontSize="large" />
                        </Avatar>
                        <div className={classes.gridItemText}>
                            <Typography variant={xsDown ? "h5" : smDown || mdDown ? "h4" : "h3"} className={classes.bold}>
                                {`Turn `}
                                <span className={classes.emphasizedText}>
                                    potential
                                </span>
                            </Typography>
                            <Typography variant={xsDown ? "h5" : smDown || mdDown ? "h4" : "h3"} className={classes.bold}>
                                {`into possible.`}
                            </Typography>
                            <Typography className={classes.subtitle}>
                                {`With a battery of energized volunteers to recruit from, organizations can more easily `}
                                {`carry out their missions, transforming the ideal into real.`}
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onRecruitButtonClicked}
                                startIcon={<AccountSearch />}
                                className={classes.secondaryCallToAction}
                                size="large"
                            >
                                Recruit Volunteers
                            </Button>
                        </div>
                        <div className={classes.grow} />
                    </Grid>
                </Grid>
            </Container >
            {/* Dialog */}
            < MobileSearchPrompt state={searchDialogState} onCompletedAllSteps={navigateToSearchPage} />
            <Footer />
        </div >
    )
}

export default Home;