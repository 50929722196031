import React, { useContext, useState, useEffect } from "react";
import { Typography, makeStyles, Theme, createStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Switch, useTheme, useMediaQuery } from "@material-ui/core";
import { RootContext, SearchCriteria } from "../../../stores";
import { MapMarkerRadius } from "mdi-material-ui";
import { DialogState } from "../../../stores/models/DialogState";
import CheckboxList from "../../Shared/CheckboxList";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../../Shared/Dialogs/ThemedDialogWithSpinner";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filters: {
            marginTop: theme.spacing(1)
        },
        sectionTitle: {
            fontWeight: 550
        },
        avatar: {
            color: theme.palette.action.disabled,
            backgroundColor: theme.palette.primary.contrastText
        },
        switchListItem: {
            paddingRight: `${theme.spacing(8) - 2}px`
        },
        switchListItemContainer: {
            marginRight: theme.spacing(1)
        },
    }),
);

interface FilterPickerProps {
    state: DialogState;
}

const FilterPicker = observer((props: FilterPickerProps) => {

    /***** React hooks *****/

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const searchStore = useContext(RootContext).searchStore;

    /***** State *****/

    const [draftFilters, setDraftFilters] = useState(new SearchCriteria(searchStore.searchCriteria));

    /***** Effects *****/

    useEffect(() => {
        draftFilters.setAllFieldsDirty();
    }, [draftFilters]);

    useEffect(() => {
        if (props.state.open) {
            resetFilters();
        }
    }, [props.state.open]);

    /***** Event handlers *****/

    const onClose = () => {
        props.state.setOpen(false);
    }

    const onApplyFilters = () => {
        searchStore.setSearchCriteria(draftFilters);
        onClose();
    }

    /***** Helper method *****/

    const resetFilters = () => {
        setDraftFilters(new SearchCriteria(searchStore.searchCriteria));
    }

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            DialogProps={{ fullScreen: smDown }}
            title={'Filters'}
            primaryButtonProps={{ children: 'Filter', disabled: !draftFilters.validated }}
            onSubmit={onApplyFilters}
        >
            <div className={classes.filters}>
                <div>
                    <Typography className={classes.sectionTitle}>How do you want to volunteer?</Typography>
                    <CheckboxList options={draftFilters.locationOptions} error={draftFilters.errors.locationOptions} />
                </div>
                <div>
                    <Typography className={classes.sectionTitle}>Where do you want to volunteer?</Typography>
                    <List>
                        <ListItem
                            button={true}
                            disableGutters
                            ContainerProps={{ className: classes.switchListItemContainer }}
                            classes={{ secondaryAction: classes.switchListItem }}
                        >
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <MapMarkerRadius color={draftFilters.localOnly ? 'primary' : 'disabled'} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={'Locally'}
                                secondary={'Limit results for volunteering remotely to those located in my community.'}
                            />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    color="primary"
                                    onChange={() => draftFilters.setLocalOnly(!draftFilters.localOnly)}
                                    checked={draftFilters.localOnly}
                                    inputProps={{ 'aria-labelledby': 'switch-list-label-local-only' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>
                <div>
                    <Typography className={classes.sectionTitle}>What kind of schedule are you looking for?</Typography>
                    {/* <CheckboxList options={draftFilters.schedulingOptions} error={draftFilters.errors.schedulingOptions} /> */}
                </div>
                <div>
                    <Typography className={classes.sectionTitle}>What types of organizations are you interested in volunteering for?</Typography>
                    <CheckboxList options={draftFilters.organizationOptions} error={draftFilters.errors.organizationOptions} />
                </div>
                <div>
                    <Typography className={classes.sectionTitle}>What sort of opportunity are you looking for?</Typography>
                    <CheckboxList options={draftFilters.opportunityTypeOptions} error={draftFilters.errors.opportunityTypeOptions} />
                </div>
                <div>
                    <Typography className={classes.sectionTitle}>What level of commitment can you make?</Typography>
                    <CheckboxList options={draftFilters.commitmentOptions} error={draftFilters.errors.commitmentOptions} />
                </div>
            </div>
        </ThemedDialogWithSpinner>
    )
});

export default FilterPicker;