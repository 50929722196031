import { observable, flow, action, computed, autorun, makeObservable, override } from 'mobx';
import { RootStore } from './RootStore';
import { User, IUser } from './models';
import { ServerUser } from './models/server/ServerUser';
import { LoginData } from './models/LoginData';
import { PasswordResetData } from './models/PasswordResetData';
import { ForgotPasswordData } from './models/ForgotPasswordData';
import { DataStore } from './DataStore';
import { EmailCategories } from './models/EmailCategory';
import { IAPIError } from './models/APIError';

enum UserRequestType {
    Login = 'login',
    Logout = 'logout',
    LoadAuthenticatedUser = 'load-authenticated-user',
    ForgotPassword = 'forgot-password',
    ResetPassword = 'reset-password',
    GetEmailPreferences = 'get-email-preferences',
    UpdateEmailPreferences = 'update-email-preferences',
    VerifyEmailAddress = 'verify-email-address'
}

export enum UserState {
    Undetermined,
    CheckingForAuthenticatedUser,
    Authenticated,
    NotAuthenticated
}

export class UserStore extends DataStore<UserRequestType> {
    private userStateDisposer = () => { return; }; // TODO: Figure out when to dispose

    @observable user = new User();
    @observable userState = UserState.Undetermined; // TODO: Is this needed or is the isAuthenticated property enough?

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);

        this.userStateDisposer = this.getUserStateChangeAutorunner();
    }

    /********* Constructor helper methods *********/

    private getUserStateChangeAutorunner() {
        return autorun(() => {
            if (this.user.id > 0) {
                this.setUserState(UserState.Authenticated);
            } else if (this.userState === UserState.Authenticated) {
                // User logged out
                this.setUserState(UserState.NotAuthenticated);
            }
        })
    }

    @action private setUserState(userState: UserState) {
        this.userState = userState;
    }

    /********* Request methods *********/

    @action login = flow(function* (this: UserStore, loginData: LoginData) {
        try {
            loginData.setAllFieldsDirty();
            if (loginData.validated) {
                const { user } = yield this.makeRequest(
                    UserRequestType.Login,
                    loginData.serialize()
                );
                if (user) {
                    this.user = new ServerUser(user).deserialize();
                } else {
                    const error = 'Uh oh! It looks like that email and password combination was incorrect.';
                    loginData.setError(error);
                    return error;
                }
            } else {
                return loginData.validationErrors;
            }
        } catch (error) {
            console.log(error);
        }
    });

    @action checkForAuthenticatedUser = flow(function* (this: UserStore) {
        try {
            this.userState = UserState.CheckingForAuthenticatedUser;
            const { user } = yield this.makeRequest(
                UserRequestType.LoadAuthenticatedUser,
                this.user.id
            );
            if (user) {
                this.user = new ServerUser(user).deserialize();
            } else {
                this.userState = UserState.NotAuthenticated;
            }
        } catch (error) {
            console.log(error);
            this.userState = UserState.NotAuthenticated;
        }
    })

    @action logout = flow(function* (this: UserStore) {
        try {
            yield this.makeRequest(UserRequestType.Logout, this.user.id);
            this.user = new User();
        } catch (error) {
            console.log(error);
        }
    })

    forgotPassword = async (data: ForgotPasswordData) => {
        try {
            const response = await this.makeRequest<{ warning?: { message: string }, success: boolean }>(UserRequestType.ForgotPassword, data.serialize());
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    resetPassword = flow(function* (this: UserStore, passwordResetData: PasswordResetData) {
        try {
            const response = yield this.makeRequest<{ warning: IAPIError } | { success: boolean }>(
                UserRequestType.ResetPassword,
                {
                    currentPassword: passwordResetData.currentPassword,
                    password: passwordResetData.newPassword,
                    token: passwordResetData.token,
                    userId: passwordResetData.userId
                }
            );
            return response as { warning: IAPIError } | { success: boolean };
        } catch (error) {
            console.log(error);
        }
    });

    getEmailPreferences = async (token: string) => {
        try {
            const response = await this.makeRequest<{ emailPreferences: { id: number, category: EmailCategories, subscribed: boolean }[] }>(
                UserRequestType.GetEmailPreferences,
                {
                    token: token,
                }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    updateEmailPreferences = async (token: string, subscribedCategoryIds: number[]) => {
        try {
            const response = await this.makeRequest<{ successful: boolean }>(
                UserRequestType.UpdateEmailPreferences,
                {
                    token: token,
                    subscribedCategoryIds: subscribedCategoryIds
                }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    submitEmailVerification = async (token: string) => {
        try {
            const response = await this.makeRequest<{ successful: boolean } | { error: { code: number, message: string } }>(
                UserRequestType.VerifyEmailAddress,
                {
                    token: token,
                }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    /********* Public properties and methods *********/

    @action setAuthenticatedUser(user?: IUser) {
        this.user = new User(user);
    }

    @computed get isAuthenticated() {
        return this.user && this.user.id > 0;
    }

    isAuthenticatedOrganization(organizationId: number) {
        return this.isAuthenticated
            && this.user.organization
            && this.user.organization.id === organizationId;
    }

    /*************** Override data ***************/

    @override get getRequests() {
        return [
            UserRequestType.Logout,
            UserRequestType.LoadAuthenticatedUser,
            UserRequestType.GetEmailPreferences
        ];
    }

    @override get postRequests() {
        return [
            UserRequestType.Login
        ];
    }

    @override get putRequests() {
        return [
            UserRequestType.ForgotPassword,
            UserRequestType.ResetPassword,
            UserRequestType.UpdateEmailPreferences,
            UserRequestType.VerifyEmailAddress
        ];
    }

    @override get requestURLs() {
        const baseURL = '/api/users'
        return {
            [UserRequestType.Login]: `${baseURL}/login`,
            [UserRequestType.Logout]: `${baseURL}/logout`,
            [UserRequestType.LoadAuthenticatedUser]: `${baseURL}/load-authenticated-user`,
            [UserRequestType.ForgotPassword]: `${baseURL}/forgot-password`,
            [UserRequestType.ResetPassword]: `${baseURL}/reset-password`,
            [UserRequestType.GetEmailPreferences]: `${baseURL}/email-preferences`,
            [UserRequestType.UpdateEmailPreferences]: `${baseURL}/email-preferences`,
            [UserRequestType.VerifyEmailAddress]: `${baseURL}/verify-email-address`,
        };
    }
}

