

import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { FormImageItem } from "../../../../stores/models/FormSection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageWrapper: {
            textAlign: 'center',
        },
        image: {
            width: '100%'
        }
    }),
);

interface ImageItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    item: FormImageItem;
}

const ImageItem = observer((props: ImageItemProps) => {

    const classes = useStyles();

    return (
        <div className={classes.imageWrapper} {...props}>
            <img src={props.item.url} className={classes.image} />
        </div>
    )
});

export default ImageItem;