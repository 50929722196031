import { observable, action, override, makeObservable } from "mobx";
import { Fields } from "./Fields";
import { validateOpportunityApplication } from "../../logic/ValidationChecks/OpportunityApplicationValidation";

export interface IOpportunityApplication {
    opportunityId: number
    email: string;
    phone: string;
    message: string;
}

export class OpportunityApplication extends Fields<IOpportunityApplication, OpportunityApplication> implements IOpportunityApplication {
    opportunityId: number;
    email = '';
    phone = '';
    message = '';

    constructor(opportunityId: number, email?: string, phone?: string, message?: string) {
        super();

        makeObservable(this, {
            opportunityId: observable,
            email: observable,
            phone: observable,
            message: observable,
            setEmail: action,
            setPhone: action,
            setMessage: action,
            validationErrors: override
        });

        this.opportunityId = opportunityId;
        this.email = email || '';
        this.phone = phone || '';
        this.message = message || '';
    }

    setEmail(email: string) {
        this.email = email;
    }

    setPhone(phone: string) {
        this.phone = phone;
    }

    setMessage(message: string) {
        this.message = message;
    }

    get validationErrors() {
        return validateOpportunityApplication(this);
    }

    serialize() {
        return this.trimmed;
    }
}