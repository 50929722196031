import React, { useContext, useState, useEffect } from "react";
import { Container, Paper, makeStyles, Theme, createStyles, Typography, Button, useMediaQuery, Divider } from "@material-ui/core";
import { RootContext, UserType } from "../../../../stores";
import useOrganizationSignUpSteps from "./OrganizationSignUpStepsHook";
import { observer } from "mobx-react";
import { getAccountProfileLink } from "../../../Navigation/Links/UrlConstructors";
import FormLoadingSpinner from "../../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import { StubUser } from "../../../../stores/models/StubUser";
import clsx from 'clsx';
import { ArrowLeft } from "mdi-material-ui";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useNavigateInternally } from "../../../Navigation/Hooks";
import { StylizedInternalLink } from "../../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            flexGrow: 1
        },
        content: {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2)
            },
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        form: {
            position: 'relative'
        },
        titleWrapper: {
            textAlign: 'center',
            marginBottom: theme.spacing(3)
        },
        title: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(1),
            '& $emphasized': {
                color: theme.palette.primary.main,
            }
        },
        emphasized: {
            fontWeight: 'bold'
        },
        button: {
            marginTop: theme.spacing(2),
        },
        backButton: {
            color: theme.palette.action.active,
        },
        buttons: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-evenly',
            maxWidth: '400px'
        },
        flex: {
            display: 'flex',
            justifyContent: 'center'
        },
        loginLine: {
            marginTop: theme.spacing(3)
        },
        alert: {
            marginBottom: theme.spacing(2),
            boxSizing: 'border-box',
            maxWidth: '500px',
            textAlign: 'left',
            flexGrow: 1
        },
        divider: {
            marginTop: theme.spacing(3),
            margin: 'auto',
            maxWidth: 400
        },
        demo: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(2)
        },
    })
);

const OrganizationSignUp = observer(() => {

    /***** State *****/

    const [stubUser, setStubUser] = useState(new StubUser(UserType.Organization));
    const [step, setStep] = useState(0);
    const [registrationError, setRegistrationError] = useState<string>();

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const registrationStore = rootStore.registrationStore;
    const registrationSteps = useOrganizationSignUpSteps(stubUser);
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /***** State *****/

    const [currentRegistrationStep, setCurrentRegistrationStep] = useState(registrationSteps[step]);

    /***** Helper constant *****/

    const isFinalStep = step === registrationSteps.length - 1;

    /***** Effects *****/

    useEffect(() => {
        setStubUser(new StubUser(UserType.Organization));
    }, []);

    useEffect(() => {
        if (!isFinalStep) {
            setRegistrationError(undefined);
        }
        setCurrentRegistrationStep(registrationSteps[step]);
    }, [step, registrationSteps]);

    useEffect(() => {
        currentRegistrationStep.state.setValidationRun(false);
        currentRegistrationStep.state.setCompleted(false);
    }, [currentRegistrationStep]);

    useEffect(() => {
        if (userStore.isAuthenticated) {
            navigateToProfile();
        }
    }, [userStore.isAuthenticated]);

    /***** Event handlers *****/

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        advanceStep();
    }

    const advanceStep = async () => {
        currentRegistrationStep.state.setLoading(true);
        const validated = currentRegistrationStep.validated();
        currentRegistrationStep.state.setValidationRun(true);
        if (validated) {
            currentRegistrationStep.state.setCompleted(true);
            if (!isFinalStep) {
                setStep(step + 1);
            } else {
                const response = await registrationStore.register(stubUser);
                if (response && typeof response === 'string') {
                    setRegistrationError(response);
                }
            }
        }
        currentRegistrationStep.state.setLoading(false);
    }

    const handleBackClicked = () => {
        setStep(step - 1);
    }

    const navigateToProfile = () => {
        navigate(getAccountProfileLink());
    }

    const openLoginDialog = () => {
        rootStore.navigationStore.appwideDialogStates.loginDialog.setOpen(true);
    }

    const handleDemoClicked = () => {
        rootStore.navigationStore.appwideDialogStates.contactUsDialog.setOpen(true);
        rootStore.helpStore.setHelpMessageOverrides({
            subject: 'Demo Request',
            message: `Hello,\n\nI would like to schedule a demo of the Voltage Volunteering platform.`
        });
    };

    /***** Render *****/

    return (
        <div id="organization-sign-up" className={classes.root}>
            <Container>
                <Paper>
                    <div className={classes.content}>
                        <div className={classes.titleWrapper}>
                            <Typography variant={xsDown ? 'h4' : 'h3'} className={classes.title}>
                                Recruit volunteers to <span className={classes.emphasized}>power</span> your mission.
                            </Typography>
                        </div>
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
                            {registrationError
                                ? <div className={classes.flex}>
                                    <Alert severity="error" className={classes.alert}>
                                        <AlertTitle>Registration Error</AlertTitle>
                                        {registrationError}
                                    </Alert>
                                </div>
                                : null
                            }
                            {React.createElement(currentRegistrationStep.content, { stepState: currentRegistrationStep.state })}
                            <div className={classes.flex}>
                                <div className={classes.buttons}>
                                    {step > 0
                                        ? <Button
                                            onClick={handleBackClicked}
                                            disabled={currentRegistrationStep.state.loading}
                                            className={clsx(classes.button, classes.backButton)}
                                            startIcon={<ArrowLeft />}
                                        >
                                            Back
                                        </Button>
                                        : null
                                    }
                                    {currentRegistrationStep.submitButtonEmbedded
                                        ? null
                                        : <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            className={classes.button}
                                            disabled={currentRegistrationStep.state.loading}
                                        // disabled={!currentRegistrationStep.admissable || currentRegistrationStep.state.loading}
                                        >
                                            {isFinalStep ? 'Sign Up' : 'Continue'}
                                        </Button>
                                    }
                                </div>
                            </div>
                            {step === 0
                                ? <React.Fragment>
                                    {/* <Divider className={classes.divider} /> */}
                                    <div className={classes.loginLine}>
                                        <Typography color="textSecondary">Already registered? Welcome back!</Typography>
                                        <StylizedInternalLink onClick={openLoginDialog}>Login</StylizedInternalLink>
                                    </div>
                                    <Divider className={classes.divider} />
                                    <Typography className={classes.demo}>
                                        Interested in learning more about using Voltage Volunteering for your volunteer program?
                                    </Typography>
                                    <StylizedInternalLink onClick={handleDemoClicked}>Request a demo</StylizedInternalLink>
                                </React.Fragment>
                                : null
                            }
                            {currentRegistrationStep.state.loading ? <FormLoadingSpinner /> : undefined}
                        </form>
                    </div>
                </Paper>
            </Container>
        </div>
    )
});

export default OrganizationSignUp;