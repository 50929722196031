import React, { useContext, useState, useEffect } from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Breadcrumbs, useTheme, useMediaQuery, IconButton } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { RootContext } from "../../stores";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { getOrganizationPath, getOrgOpportunitiesPath, routeWithDescendants } from "../Navigation/Links/UrlConstructors";
import useOrgOpportunitiesLink from "../Navigation/Hooks/OrganizationProfile/OrgOpportunitiesLink";
import { BreadcrumbPath } from "../../stores/models/BreadcrumbPath";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import clsx from 'clsx';
import { getSearchLink } from "../Navigation/Links/UrlConstructors";
import { Magnify } from "mdi-material-ui";
import { useMatchCurrentPath, useNavigateInternally } from "../Navigation/Hooks";
import REMChip from "../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        breadcrumbs: {
            marginBottom: theme.spacing(2),
            '& > ol': {
                flexWrap: 'nowrap',
                '& > .MuiBreadcrumbs-li': {
                    overflowX: 'hidden',
                }
            }
        },
        mobileBreadcrumbs: {
            marginBottom: theme.spacing(2),
            display: 'flex'
        },
        active: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:focus, &:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText
            }
        },
        chip: {
            marginBottom: '2px',
            display: 'flex',
            overflowX: 'hidden'
        },
        iconOnlyChip: {
            '& > .MuiChip-icon': {
                marginRight: '5px'
            },
            '& > .MuiChip-label': {
                padding: 0
            }
        },
        backButton: {
            padding: 0
        }
    }));

interface OrganizationProfileBreadcrumbsProps {
    breadcrumbs: BreadcrumbPath;
}

const OrganizationProfileBreadcrumbs = observer((props: OrganizationProfileBreadcrumbsProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const organizationStore = rootStore.organizationStore;
    const navigate = useNavigateInternally();
    const matchPath = useMatchCurrentPath();
    const location = useLocation();
    const opportunitiesLink = useOrgOpportunitiesLink();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [linkedFromSearch] = useState((location.state as { linkedFromSearch?: boolean })?.linkedFromSearch === true);

    /********* State *********/

    const addBreadcrumbs = () => {
        props.breadcrumbs.reset();
        if (linkedFromSearch) {
            props.breadcrumbs.pushBreadcrumb({ displayText: '', link: getSearchLink(), icon: Magnify });
        }
        // Organization tab
        const isOrganizationTab = matchPath({ path: getOrganizationPath(), end: true });
        if (isOrganizationTab && linkedFromSearch) {
            props.breadcrumbs.pushBreadcrumb({ displayText: organizationStore.organization.name, link: organizationStore.organization.organizationPage });
        }

        const isOpportunitiesTab = matchPath({ path: routeWithDescendants(getOrgOpportunitiesPath()) });
        if (isOpportunitiesTab) {
            props.breadcrumbs.pushBreadcrumb({
                displayText: `${organizationStore.organization.name}'s Opportunities`,
                link: opportunitiesLink
            });
        }
    }

    /********* Effect *********/

    useEffect(() => {
        addBreadcrumbs();
    }, [location]);

    /********* Event Handlers *********/

    const onClickedBreadcrumb = (link: string) => {
        navigate(link);
    }

    /********* Helper constants *********/

    const postsFromSameOrganizationBreadcrumb = (
        <React.Fragment>
            <IconButton className={classes.backButton} onClick={() => onClickedBreadcrumb(opportunitiesLink)}>
                <NavigateBeforeIcon />
            </IconButton>
            <REMChip
                label={'Posts from this organization'}
                onClick={() => onClickedBreadcrumb(opportunitiesLink)}
                className={classes.chip}
            />
        </React.Fragment>
    )

    /********* Render *********/

    return (
        <div id="breadcrumbs" className={classes.root}>
            {smDown
                ? linkedFromSearch
                    ? <div className={classes.mobileBreadcrumbs}>
                        <IconButton className={classes.backButton} onClick={() => onClickedBreadcrumb(getSearchLink())}>
                            <NavigateBeforeIcon />
                        </IconButton>
                        <REMChip
                            icon={<Magnify />}
                            label={props.breadcrumbs.pathSegments.length === 2 ? 'Back to Search Results' : undefined}
                            onClick={() => onClickedBreadcrumb(getSearchLink())}
                            className={clsx(
                                classes.chip,
                                props.breadcrumbs.pathSegments.length === 2 ? undefined : classes.iconOnlyChip
                            )}
                        />
                        {props.breadcrumbs.pathSegments.length > 2
                            ? postsFromSameOrganizationBreadcrumb
                            : null
                        }
                    </div>
                    : props.breadcrumbs.pathSegments.length > 1
                        ? <div className={classes.mobileBreadcrumbs}>
                            {postsFromSameOrganizationBreadcrumb}
                        </div>
                        : null
                : props.breadcrumbs.pathSegments.length > 0
                    ? <Breadcrumbs className={classes.breadcrumbs} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        {props.breadcrumbs.pathSegments.map((breadcrumb, index) => {
                            if (breadcrumb.displayText.length > 0 || breadcrumb.icon) {
                                return (
                                    <REMChip
                                        icon={breadcrumb.icon ? React.createElement(breadcrumb.icon) : undefined}
                                        label={breadcrumb.icon ? undefined : breadcrumb.displayText}
                                        className={clsx(
                                            breadcrumb === props.breadcrumbs.activeSegment ? classes.active : undefined,
                                            classes.chip,
                                            breadcrumb.icon ? classes.iconOnlyChip : undefined
                                        )}
                                        onClick={() => onClickedBreadcrumb(breadcrumb.link)}
                                        key={`breadcrumb-${index}`}
                                    />
                                )
                            } else {
                                return null;
                            }
                        })}
                    </Breadcrumbs>
                    : null
            }
        </div >
    );
});

export default OrganizationProfileBreadcrumbs;