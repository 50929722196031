import { Dictionary } from "../../logic/Dictionaries";

interface ISubscriptionPlan<T extends string> {
    name: string;
    price: string;
    features: Dictionary<T, React.ReactNode | boolean>;
    featureText: Dictionary<T, React.ReactNode>;
}

export default class SubscriptionPlan<T extends string> implements ISubscriptionPlan<T> {
    public name: string;
    public price: string;
    public features: Dictionary<T, React.ReactNode | boolean>;
    public featureText: Dictionary<T, React.ReactNode>;

    constructor(name: string, price: string, features: Dictionary<T, React.ReactNode | boolean>, featureText: Dictionary<T, React.ReactNode>) {
        this.name = name;
        this.price = price;
        this.features = features;
        this.featureText = featureText;
    }
}