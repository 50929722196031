import React from "react";
import { Shift } from "../../../stores/models/Shift";
import { List, ListItem, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import ShiftCard, { ShiftAction } from "./ShiftCard";
import { ShiftDetails } from "../../../stores/models/ShiftDetails";
import { getOpportunityLink } from "../../Navigation/Links/UrlConstructors";
import { useNavigateInternally } from "../../Navigation/Hooks";

interface ShiftListProps {
    records: Shift[] | ShiftDetails[];
    editable?: boolean;
    showSignUpOption?: boolean;
    hideExpired?: boolean;
    onShiftActionSelected?: (shift: Shift, action: ShiftAction) => void;
    noResultsText?: string | JSX.Element;
    hideNoResultsText?: boolean;
    permissionNeeded?: boolean;
    assignmentOnly?: boolean;
}

const ShiftList = observer((props: ShiftListProps) => {

    const navigate = useNavigateInternally();

    const getRecordShift = (record: Shift | ShiftDetails) => {
        if ('repetitionPattern' in record) {
            return record;
        } else {
            return record.shift;
        }
    }

    const hasRecordExpired = (record: Shift | ShiftDetails) => {
        const shift = getRecordShift(record);
        return shift.repetitionPattern.hasEnded;
    }

    const hasAnyUnexpiredShifts = () => {
        for (let i = 0; i < props.records.length; i++) {
            const record = props.records[i];
            if (!hasRecordExpired(record)) {
                return true;
            }
        }
        return false;
    }

    const onShiftPositionClicked = (shiftDetails: ShiftDetails) => {
        if (shiftDetails.organizationId === undefined) return;
        navigate(getOpportunityLink(shiftDetails.organizationId, shiftDetails.opportunityId))
    }

    return (
        <React.Fragment>
            {(!props.hideExpired && props.records.length > 0) || hasAnyUnexpiredShifts()
                ? <List>
                    {props.records.map((record, index) => {
                        const expired = hasRecordExpired(record);
                        return (
                            <React.Fragment key={`fragment-${index}`}>
                                {props.hideExpired && expired
                                    ? null
                                    : <ListItem key={`timeline-item-${index}`} disableGutters>
                                        <ShiftCard
                                            shift={getRecordShift(record)}
                                            editable={props.editable}
                                            onShiftActionSelected={props.onShiftActionSelected}
                                            showSignUpOption={props.showSignUpOption}
                                            permissionNeeded={props.permissionNeeded}
                                            assignmentOnly={props.assignmentOnly}
                                            opportunityData={
                                                'shift' in record
                                                    ? {
                                                        id: record.opportunityId,
                                                        position: record.position,
                                                        onClick: () => onShiftPositionClicked(record)
                                                    }
                                                    : undefined
                                            }
                                        />
                                    </ListItem>
                                }
                            </React.Fragment>
                        );
                    })}
                </List>
                : props.hideNoResultsText
                    ? null
                    : props.noResultsText === undefined || typeof props.noResultsText === 'string'
                        ? <Typography>
                            {props.noResultsText ? props.noResultsText : 'No results'}
                        </Typography>
                        : props.noResultsText
            }
        </React.Fragment>
    )
});

export default ShiftList;