import { observable, makeAutoObservable, action, reaction, computed } from "mobx";
import { IDisposer } from "mobx-utils";
import { IAPIGetRequestParameters } from "./APIGetRequestParameters";
import { FilterState } from "./FilterState";
import { ITablePaginationState, TablePaginationState } from "./TablePaginationState";
import { TableSortingState } from "./TableSortingState";

export interface ITableState<ColumnName extends string> {
    sortingState: TableSortingState<ColumnName>;
    paginationState: ITablePaginationState;
    loading: boolean;
    total?: number;
    filters?: FilterState;
}

export class TableState<ColumnName extends string> implements ITableState<ColumnName> {
    @observable sortingState: TableSortingState<ColumnName>;
    @observable paginationState: TablePaginationState;
    @observable loading: boolean;
    @observable total?: number;
    @observable unloadedSelected: boolean = false; 
    @observable filters: FilterState;

    private sortingChangedReaction?: IDisposer;

    constructor(props?: Omit<ITableState<ColumnName>, 'sortingState'>) {
        makeAutoObservable(this);

        this.sortingState = new TableSortingState<ColumnName>();
        this.paginationState = new TablePaginationState(props?.paginationState);
        this.loading = props?.loading || false;
        this.total = props?.total;
        this.filters = props?.filters ? props.filters : new FilterState();
        
        this.setupReactions();
    }

    @action setTotal(total?: number) {
        this.total = total
    }

    @action setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action setUnloadedSelected(unloadedSelected: boolean) {
        this.unloadedSelected = unloadedSelected;
    }

    @action private setupReactions() {
        this.sortingChangedReaction = reaction(
            () => [
                this.sortingState.stringifiedSortConfiguration,
            ],
            () => {
                this.paginationState.setPage(0);
            }
        );
    }

    @computed get loadRequest(): IAPIGetRequestParameters<ColumnName> {
        return {
            limit: this.paginationState.rowsPerPage,
            offset: this.paginationState.offset,
            sort: this.sortingState.sortConfiguration,
            filterState: this.filters
        }
    }
}