import { useContext, useEffect } from 'react';
import { RootContext } from '../../stores';
import { observer } from 'mobx-react';
import LoginDialog from '../Authentication/LoginDialog';
import SignUpDialog from '../Authentication/SignUpDialog';
import { getAccountSettingsLink } from './Links/UrlConstructors';
import ContactUsDialog from '../ContactUs/ContactUsDialog';
import LogoutDialog from '../Authentication/LogoutDialog';
import ForgotPasswordDialog from '../Authentication/ForgotPasswordDialog';
import VolunteerAccountRequiredDialog from '../Authentication/VolunteerAccountRequiredDialog';
import SessionExpiredDialog from '../Authentication/SessionExpiredDialog';
import LogoutOfVolunteerAccountDialog from '../Authentication/LogoutOfVolunteerAccountDialog';
import ActiveCitiesLimitDialog from '../AccountSettings/Pages/Organization/Opportunities/ActiveCitiesLimitDialog';
import RemoteOppsLimitDialog from '../AccountSettings/Pages/Organization/Opportunities/RemoteOppsLimitDialog';
import NewClientVersionDialog from '../General/NewClientVersionDialog';
import APIErrorDialog from '../General/APIErrorDialog';
import { useNavigateInternally } from './Hooks';

export enum AppwideDialog {
    LoginDialog = 'loginDialog',
    SignUpDialog = 'signUpDialog',
    ContactUsDialog = 'contactUsDialog',
    LogoutDialog = 'logoutDialog',
    ForgotPasswordDialog = 'forgotPasswordDialog',
    VolunteerAccountRequiredDialog = 'volunteerAccountRequiredDialog',
    SessionExpiredDialog = 'sessionExpiredDialog',
    LogoutOfVolunteerAccountDialog = 'logoutOfVolunteerAccountDialog',
    ActiveCitiesLimitDialog = 'activeCitiesLimitDialog',
    RemoteOppsLimitDialog = 'remoteOppsLimitDialog',
    ClientVersionOutOfDateDialog = 'clientVersionOutOfDateDialog',
    APIErrorDialog = 'apiErrorDialog'
}

const AppwideDialogs = observer(() => {

    /*****************  React hooks  *****************/
    let navigate = useNavigateInternally();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;

    useEffect(() => {
        if (userStore.isAuthenticated) {
            // Check to make sure that the isAuthenticated property
            // changed because the user logged in or registered 
            // rather than because they refreshed the page after
            // already having an authenticated session.
            const authenticatedFromDialog = navigationStore.authenticationDialogOpen;
            if (authenticatedFromDialog) {
                if (navigationStore.onAuthenticationCallback) {
                    navigationStore.onAuthenticationCallback(userStore.user.id);
                } else {
                    navigateToProfile();
                }
            }
            closeLoginDialog();
            closeSignUpDialog();
        }
    }, [userStore.isAuthenticated]);

    useEffect(() => {
        if (!navigationStore.authenticationDialogOpen) {
            navigationStore.setOnAuthenticationCallback(); // Clear the callback
        }
    }, [navigationStore.authenticationDialogOpen]);

    useEffect(() => {
        if (rootStore.clientHasUpdates) {
            navigationStore.appwideDialogStates.clientVersionOutOfDateDialog.setOpen(true);
        }
    }, [rootStore.clientHasUpdates])

    useEffect(() => {
        if (rootStore.apiError) {
            navigationStore.appwideDialogStates.apiErrorDialog.setOpen(true);
        }
    }, [rootStore.apiError]);

    /*****************  Event handlers  *****************/

    const handleToggleDialogs = () => {
        navigationStore.toggleAuthenticationDialogs();
    }

    /*****************  Helper functions  *****************/

    const closeLoginDialog = () => {
        navigationStore.appwideDialogStates.loginDialog.setOpen(false);
    }

    const closeSignUpDialog = () => {
        navigationStore.appwideDialogStates.signUpDialog.setOpen(false);
    }

    const navigateToProfile = () => {
        navigate(getAccountSettingsLink());
    }

    /*****************  Render  *****************/

    return (
        <div>
            <LoginDialog
                dialogState={navigationStore.appwideDialogStates.loginDialog}
                onSwitchToSignUp={handleToggleDialogs}
            />
            <SignUpDialog
                dialogState={navigationStore.appwideDialogStates.signUpDialog}
                onSwitchToLogin={handleToggleDialogs}
            />
            <ContactUsDialog
                dialogState={navigationStore.appwideDialogStates.contactUsDialog}
            />
            <LogoutDialog />
            <ForgotPasswordDialog />
            <VolunteerAccountRequiredDialog />
            <SessionExpiredDialog />
            <ActiveCitiesLimitDialog />
            <RemoteOppsLimitDialog />
            <LogoutOfVolunteerAccountDialog />
            <NewClientVersionDialog />
            <APIErrorDialog error={rootStore.apiError} />
        </div>
    );
});

export default AppwideDialogs;