import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

const ZipCodeInput = (props: TextFieldProps) => {
    return (
        <TextField
            label="Zip Code"
            inputProps={{ maxLength: 5 }}
            placeholder="XXXXX"
            {...props}
        />
    )
}

export default ZipCodeInput;