import React from "react";
import { Address } from "../../stores/models/Address";
import { Typography } from "@material-ui/core";


interface AddressDisplayProps {
    address: Address;
}

const AddressDisplay = (props: AddressDisplayProps) => {
    return (
        <div>
            {props.address.name ? <Typography>{props.address.name}</Typography> : null}
            <Typography>
                {props.address.lineOne}
            </Typography>
            <Typography>
                {props.address.lineTwo}
            </Typography>
            <Typography>
                {`${props.address.city}, ${props.address.state} ${props.address.zipCode}`}
            </Typography>
        </div>
    );
};

export default AddressDisplay;