import { RootStore } from "./RootStore";
import { action, makeObservable } from "mobx";
import { loadStripe, Stripe, StripeError } from '@stripe/stripe-js';
import { PaymentMethod } from "./models/PaymentMethod";
import { ValidationError } from "./models/ValidationError";
import validatePaymentMethod from "../logic/ValidationChecks/PaymentMethodValidation";
import { Customer, ICustomer } from "./models/Customer";
import { IRequestConfig, RequestQueue } from "./models/RequestQueue";
import { ISubscription, Subscription } from "./models/Subscription";
import { Invoice } from "./models/Invoice";

enum PaymentProcessingRequestType {
    CancelSubscription = 'cancel-subscription',
    GetCustomerData = 'get-customer-data',
    UpdatePaymentMethod = 'update-payment-method',
    RetriveUpcomingInvoice = 'retrieve-upcoming-invoice',
    UpdateSubscription = 'update-subscription'
}

const STRIPE_PUBLISH_KEY = 'pk_test_LORkawYWQ8igMqN8BUnrQ5l600WwDMeRZD';

export class PaymentProcessingStore {

    public stripePromise = {} as Promise<Stripe | null>;

    private rootStore = {} as RootStore;
    private stripe: Stripe | null = null;
    private requestQueue: RequestQueue;

    constructor(rootStore: RootStore) {
        makeObservable<PaymentProcessingStore, "setStripe" | "createPaymentMethod" | "handleStripeError">(this, {
            setStripe: action,
            runClientValidationForPaymentMethod: action,
            setUpPaymentMethodRecord: action,
            createPaymentMethod: action,
            handleStripeError: action
        });

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
        this.stripePromise = loadStripe(STRIPE_PUBLISH_KEY)
            .then((result) => {
                this.setStripe(result)
                return result;
            });
    }

    /*************** Voltage API Requests ***************/

    async cancelSubscription() {
        try {
            const requestConfig = this.generateRequestConfig(PaymentProcessingRequestType.CancelSubscription);
            const { subscription } = await this.requestQueue.makeAPIRequest<{ subscription: ISubscription }>(requestConfig);
            const cancelledSubscription = new Subscription(subscription);
            return cancelledSubscription;
        } catch (error) {
            console.log(error);
        }
    }

    async getCustomerData() {
        try {
            const requestConfig = this.generateRequestConfig(PaymentProcessingRequestType.GetCustomerData);
            const results = await this.requestQueue.makeAPIRequest<{ customer: ICustomer }>(requestConfig);
            const { customer } = results;
            const subscriptionData = new Customer(customer);
            return subscriptionData;
        } catch (error) {
            console.log(error);
        }
    }

    async updatePaymentMethod(paymentMethod: PaymentMethod) {
        try {
            const validationErrors = this.runClientValidationForPaymentMethod(paymentMethod);
            if (validationErrors.length > 0) {
                return validationErrors;
            } else {
                return await this.trySwitchingToNewPaymentMethod(paymentMethod);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async retrieveUpcomingInvoice(planLevel: number) {
        try {
            const requestConfig = this.generateRequestConfig(PaymentProcessingRequestType.RetriveUpcomingInvoice, { planLevel });
            const { invoice } = await this.requestQueue.makeAPIRequest<{ invoice: any }>(requestConfig);
            const clientInvoice = new Invoice(invoice);
            return clientInvoice;
        } catch (error) {
            console.log(error);
        }
    }

    async updateSubscriptionPlanLevel(planLevel: number, prorationDate: number, newPaymentMethod?: PaymentMethod) {
        try {
            if (newPaymentMethod) {
                const customerOrErrors = await this.updatePaymentMethod(newPaymentMethod);
                if (!(customerOrErrors instanceof Customer)) {
                    return customerOrErrors;
                }
            }
            const requestConfig = this.generateRequestConfig(PaymentProcessingRequestType.UpdateSubscription, { planLevel, prorationDate });
            const result = await this.requestQueue.makeAPIRequest<{ customer?: ICustomer, warnings?: Array<{ code: number }> }>(requestConfig);
            const { customer } = result;
            return {
                customer: customer ? new Customer(customer) : undefined,
                warnings: result.warnings
            }
        } catch (error) {
            console.log(error);
        }
    }

    private async trySwitchingToNewPaymentMethod(paymentMethod: PaymentMethod) {
        try {
            const paymentMethodIdOrErrors = await this.setUpPaymentMethodRecord(paymentMethod);
            const paymentMethodSetUpSuccessfully = typeof paymentMethodIdOrErrors === 'string';
            if (paymentMethodSetUpSuccessfully) {
                return await this.switchPaymentMethodForCustomer(paymentMethodIdOrErrors as string);
            } else {
                return paymentMethodIdOrErrors as ValidationError<PaymentMethod>[];
            }
        } catch (err) {
            return 'An error occurred while trying to process your card details.'
        }
    }

    private async switchPaymentMethodForCustomer(paymentMethodId: string) {
        const requestConfig = this.generateRequestConfig(PaymentProcessingRequestType.UpdatePaymentMethod, {
            paymentMethodId: paymentMethodId
        });
        const result = await this.requestQueue.makeAPIRequest<{ customer: ICustomer } | { cardError: string }>(requestConfig);
        if ('customer' in result) {
            return new Customer(result.customer);
        } else {
            return result.cardError;
        }
    }

    private generateRequestConfig(type: PaymentProcessingRequestType, data?: any): IRequestConfig {
        const paymentProcessorAPI = '/api/payment-processor';
        switch (type) {
            case PaymentProcessingRequestType.CancelSubscription:
                return {
                    method: 'get', // TODO: Should this be a put?
                    url: `${paymentProcessorAPI}/cancel-subscription`,
                    forceRefresh: true
                };
            case PaymentProcessingRequestType.GetCustomerData:
                return {
                    method: 'get',
                    url: `${paymentProcessorAPI}/customer-data`,
                    forceRefresh: true
                };
            case PaymentProcessingRequestType.UpdatePaymentMethod:
                return {
                    method: 'post',
                    url: `${paymentProcessorAPI}/update-payment-method`,
                    data: { ...data }
                };
            case PaymentProcessingRequestType.RetriveUpcomingInvoice:
                return {
                    method: 'post',
                    url: `${paymentProcessorAPI}/retrieve-upcoming-invoice`,
                    data: { ...data }
                };
            case PaymentProcessingRequestType.UpdateSubscription:
                return {
                    method: 'post',
                    url: `${paymentProcessorAPI}/update-subscription`,
                    data: { ...data }
                }
        }
    }

    /*************** Private Setters ***************/

    private setStripe(stripe: Stripe | null) {
        this.stripe = stripe;
    }

    /*************** Public validation methods ***************/

    runClientValidationForPaymentMethod(paymentMethod: PaymentMethod) {
        return validatePaymentMethod(paymentMethod);
    }

    async setUpPaymentMethodRecord(paymentMethod: PaymentMethod) {
        try {
            if (paymentMethod === undefined || !paymentMethod.cardElement || !paymentMethod.name || !paymentMethod.zipCode) {
                throw new Error('Payment method is missing data.');
            }
            const result = await this.createPaymentMethod(paymentMethod);
            if (result?.paymentMethod?.id) {
                return result.paymentMethod.id;
            } else if (result?.error) {
                return this.handleStripeError(result.error);
            } else {
                return [this.getGenericError()];
            }
        } catch (error) {
            console.log(error);
            return [this.getGenericError()]; // TODO: Fix
        }
    }

    /*************** Private Stripe API callers ***************/

    // TODO: Test Stripe not loading

    private async createPaymentMethod(paymentMethod: PaymentMethod) {
        await this.stripePromise;
        if (this.stripe) {
            const serializedPaymentMethod = paymentMethod.serialize();
            const result = await this.stripe.createPaymentMethod({
                type: 'card',
                card: serializedPaymentMethod.cardElement || { token: '' },
                billing_details: {
                    name: serializedPaymentMethod.name,
                    address: {
                        postal_code: serializedPaymentMethod.zipCode
                    }
                }
            });
            return result;
        }
    }

    /*************** Private error handling methods ***************/

    private getGenericError() {
        return new ValidationError('Invalid card details entered.', ['cardNumber', 'expirationDate', 'cvc']);
    }

    private getErrorField(error: StripeError) {
        let searchStrings = ['number', 'expiry', 'cvc'];
        let correspondingFields = ['cardNumber', 'expirationDate', 'cvc'] as (keyof PaymentMethod)[];
        for (let i = 0; i < searchStrings.length; i++) {
            const searchStringFound = error.code?.indexOf(searchStrings[i]) !== -1;
            if (searchStringFound) {
                return correspondingFields[i];
            }
        }
    }

    private handleStripeError(error: StripeError) {
        let validationErrors = [] as ValidationError<PaymentMethod>[];
        switch (error.type) {
            case 'validation_error':
                const errorField = this.getErrorField(error);
                if (errorField && error.message) {
                    validationErrors.push(new ValidationError(error.message, [errorField]));
                } else {
                    validationErrors.push(this.getGenericError());
                }
                break;
            case 'invalid_request_error':
                validationErrors.push(this.getGenericError());
                break;
            default:
                validationErrors.push(this.getGenericError());
                break;
        }
        return validationErrors;
    }
}