import { makeStyles, Theme, createStyles, useMediaQuery } from "@material-ui/core";
import React, { useContext } from "react";
import { RootContext } from "../../stores";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        }
    }),
);

const VolunteerAccountRequiredDialog = observer(() => {

    /********** React hooks **********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********** Helper constants **********/

    const dialogState = rootStore.navigationStore.appwideDialogStates.volunteerAccountRequiredDialog;

    /********** Event handlers **********/

    const handleLogout = () => {
        dialogState.setOpen(false);
        rootStore.navigationStore.appwideDialogStates.logoutDialog.setOpen(true);
    }

    /********** Render **********/

    return (
        <ThemedDialogWithSpinner
            title={'Volunteer Account Required'}
            primaryButtonProps={{ children: 'Logout' }}
            state={dialogState}
            DialogProps={{ fullScreen: xsDown, maxWidth: 'xs' }}
            onSubmit={handleLogout}
        >
            <Alert severity="info">
                <AlertTitle>Switch to a volunteer account to continue.</AlertTitle>
                You're logged into your organization's account at the moment. Please logout and then log back in as a volunteer in order to take this action.
            </Alert>
        </ThemedDialogWithSpinner >
    );
});

export default VolunteerAccountRequiredDialog;