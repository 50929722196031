import { observer } from "mobx-react";
import React, { useContext } from "react";
import { makeStyles, Theme, createStyles, Typography} from "@material-ui/core";
import { RootContext } from "../../../stores";
import CommitmentLengthSlider from "./CommitmentLengthSlider";
import CheckboxList from "../../Shared/CheckboxList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexGrow: {
            flexGrow: 1,
            '& > :not(:last-child)': {
                marginBottom: theme.spacing(1)
            }
        },
        slider: {
            padding: theme.spacing(0, 5)
        },
        centered: {
            textAlign: 'center'
        },
    })
);

const OpportunityDetailsFilters = observer(() => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);

    /***** Render *****/

    return (
        <React.Fragment>
            <div className={classes.flexGrow}>
                {/* <div>
                    <Typography id="range-slider" variant="subtitle2" gutterBottom className={classes.centered}>
                        Time Commitment
                    </Typography>
                    <div className={classes.slider}>
                        <CommitmentLengthSlider />
                    </div>
                </div> */}
                <CheckboxList options={rootStore.searchStore.searchCriteria.opportunityDetailsOptions} />
            </div>
        </React.Fragment>
    );
});

export default OpportunityDetailsFilters;