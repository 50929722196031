import { observable, action, computed, when, flow, makeObservable } from 'mobx';
import { RootStore } from './RootStore';
import {
    Organization,
    SearchCriteria,
    Opportunity,
    RequestQueue,
} from './models';
import { ValidationError } from './models/ValidationError';
import { IRequestConfig } from './models/RequestQueue';
import { ServerOpportunity, IServerOpportunity } from './models/server/ServerOpportunity';
import { IServerOrganization, ServerOrganization } from './models/server/ServerOrganization';
import { OptionCategoryCollection } from './models/OptionCategoryCollection';
import { OptionCollection } from './models/OptionCollection';
import { SearchTypeOptions } from '../data/SearchOptions';

enum SearchRequestType {
    GetOpportunities = 'search-opps',
    GetOrganizations = 'search-orgs'
}

export class SearchStore {
    private rootStore = {} as RootStore;
    private requestQueue: RequestQueue;
    private searchRequester?: number;

    organizations = [] as Organization[];
    opportunities = [] as Opportunity[];
    searchCriteria = new SearchCriteria();
    totalOrganizationResults = 0;
    totalOpportunityResults = 0;
    idOfLastSelection?: string;
    initialSearchRequestCompleted = false;

    constructor(rootStore: RootStore) {
        makeObservable<SearchStore, "makeRequest">(this, {
            organizations: observable,
            opportunities: observable,
            searchCriteria: observable,
            totalOrganizationResults: observable,
            totalOpportunityResults: observable,
            idOfLastSelection: observable,
            initialSearchRequestCompleted: observable,
            hasResults: computed,
            totalCount: computed,
            newSparkDataNeeded: computed,
            setSearchCriteria: action,
            resetSearchCriteria: action,
            setIdOfLastSelection: action,
            fetchSearchResults: action,
            makeRequest: action
        });

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
        // Set up cause options
        when(() => rootStore.causeStore.causes.length > 0,
            () => this.searchCriteria.setCauseOptions(
                new OptionCollection('value', this.rootStore.causeStore.causeOptions)
            )
        );
        // Set up skill options
        when(() => rootStore.skillCategoryStore.skillCategories.length > 0,
            () => this.searchCriteria.setSkillOptions(
                new OptionCategoryCollection(this.rootStore.skillCategoryStore.skillCategoryOptions)
            )
        );
    }

    /********** Computed Values **********/

    get hasResults() {
        return this.totalCount > 0;
    }

    get totalCount() {
        if (this.searchCriteria.isOpportunitySearch) {
            return this.totalOpportunityResults;
        } else {
            return this.totalOrganizationResults;
        }
    }

    get newSparkDataNeeded() {
        return (
            this.searchRequester !== undefined
            && this.rootStore.userStore.user.isVolunteer
            && this.searchRequester !== this.rootStore.userStore.user.volunteer!.id
        );
    }

    /********** Setters **********/

    setSearchCriteria(searchCriteria: SearchCriteria) {
        this.searchCriteria = searchCriteria;
    }

    resetSearchCriteria() {
        const currentSearchType = this.searchCriteria.searchOptions.selectedOptions;
        const currentOrgSlug = this.searchCriteria.orgSlug;
        this.searchCriteria = new SearchCriteria();
        this.searchCriteria.setOrgSlug(currentOrgSlug);
        if (currentSearchType.length > 0) {
            this.searchCriteria.searchOptions.setSelections([{ value: currentSearchType[0].value as SearchTypeOptions }]);
        }
        this.searchCriteria.setCauseOptions(new OptionCollection('value', this.rootStore.causeStore.causeOptions));
        this.searchCriteria.setSkillOptions(new OptionCategoryCollection(this.rootStore.skillCategoryStore.skillCategoryOptions));
    }

    setIdOfLastSelection(id?: string) {
        this.idOfLastSelection = id;
    }

    /********** Search Request **********/

    fetchSearchResults = async () => {
        if (this.searchCriteria.isOpportunitySearch) {
            return await this.fetchOpportunities();
        } else {
            return await this.fetchOrganizations();
        }
    };

    /*** Get all organizations matching search criteria ***/

    private fetchOrganizations = flow(function* (this: SearchStore) {
        try {
            const data = yield this.makeRequest(SearchRequestType.GetOrganizations);
            const { results, total, volunteer } = data;
            this.totalOrganizationResults = total;
            this.searchRequester = volunteer;
            this.organizations = results.map((organization: IServerOrganization) => {
                return new ServerOrganization(organization).deserialize();
            });
            this.initialSearchRequestCompleted = true;
        } catch (error) {
            if (!(error instanceof ValidationError)) {
                this.organizations = [];
            }
            console.log(error);
        }
    });

    /*** Get all volunteer opportunities ***/

    private fetchOpportunities = flow(function* (this: SearchStore) {
        try {
            const data = yield this.makeRequest(SearchRequestType.GetOpportunities);
            const { results, total, volunteer } = data;
            this.totalOpportunityResults = total;
            this.searchRequester = volunteer;
            this.opportunities = results.map((opportunity: IServerOpportunity) => {
                return new ServerOpportunity(opportunity).deserialize();
            });
            this.initialSearchRequestCompleted = true;
        } catch (error) {
            if (!(error instanceof ValidationError)) {
                this.opportunities = [];
            }
            console.log(error);
        }
    })

    /*************** Private request handling methods ***************/

    private makeRequest<T>(type: SearchRequestType) {
        return new Promise<T>(async (resolve, reject) => {
            try {
                this.requestQueue.emptyQueue();
                const requestConfig = this.generateRequestConfig(type, this.searchCriteria);

                const response = await this.requestQueue.makeAPIRequest<T>(requestConfig);
                resolve(response);
            } catch (error) {
                reject(error);
            };
        })
    }

    private generateRequestConfig(type: SearchRequestType, searchCriteria: SearchCriteria): IRequestConfig {
        let url = '';
        if (type === SearchRequestType.GetOrganizations) {
            url = `/api/organizations/search`;
        } else {
            url = `/api/opportunities/search`;
        }

        return {
            method: 'get',
            url: url,
            params: {
                searchCriteria: searchCriteria.serializedRequestData
            },
            forceRefresh: true
        };
    }
}

