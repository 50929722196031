import { makeStyles, Theme, createStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { TableHeader } from "../../../../stores/models/TableHeader";
import { IdentifiableObject, OptionCollection } from "../../../../stores/models/OptionCollection";
import { Option } from "../../../../stores/models/Option";
import clsx from "clsx";
import { TableSortingState } from "../../../../stores/models/TableSortingState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            whiteSpace: 'normal',
            wordBreak: 'normal'
        },
        header: {
            '& th': {
                backgroundColor: theme.palette.primaryBackground.main,
                fontWeight: 'bold',
                borderBottom: 'none'
            },
            whiteSpace: 'nowrap',
        },
        noResultsCaption: {
            width: 'auto',
            background: 'whitesmoke',
            color: 'black !important',
        },
        iconButton: {
            padding: 0
        },
        cursor: {
            cursor: 'pointer'
        }
    }),
);

function descendingComparator<T>(a: T, b: T, sortValue: keyof T | ((record: T) => any)) {
    if (typeof sortValue === 'function') {
        if (sortValue(b) < sortValue(a)) {
            return -1;
        }
        if (sortValue(b) > sortValue(a)) {
            return 1;
        }
    } else {
        if (b[sortValue] < a[sortValue]) {
            return -1;
        }
        if (b[sortValue] > a[sortValue]) {
            return 1;
        }
    }
    return 0;
}

function getComparator<T>(order: 'asc' | 'desc', sortValue: keyof T | ((record: T) => any)) {
    return order === 'desc'
        ? (a: T, b: T) => descendingComparator(a, b, sortValue)
        : (a: T, b: T) => -descendingComparator(a, b, sortValue);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: Option<T>[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator((a[0] as Option<T>).object, (b[0] as Option<T>).object);
        if (order !== 0) {
            return order;
        }
        return (a[1] as number) - (b[1] as number);
    });
    return stabilizedThis.map((el) => el[0]);
}

export interface RecordTableProps<IdentityKey extends string, T extends IdentifiableObject<IdentityKey>> {
    records: OptionCollection<IdentityKey, T>;
    tableHeaderCells: TableHeader<T>[];
    orderBy?: string;
    order?: 'asc' | 'desc';
    displayFunctions: ((record: Option<T>, rowIndex: number, cellIndex: number) => React.ReactNode)[];
    serverSorted?: boolean;
    sortState?: TableSortingState<Extract<keyof T, string>>;
    tableRowClassName?: (record: Option<T>) => string | undefined;
    onRowClicked?: (record: Option<T>, rowIndex: number) => void;
}

const RecordTable = observer(<IdentityKey extends string, T extends IdentifiableObject<IdentityKey>>(props: RecordTableProps<IdentityKey, T>) => {

    const classes = useStyles();

    const [order, setOrder] = React.useState<'asc' | 'desc' | undefined>(props.order ? props.order : 'asc');
    const [orderBy, setOrderBy] = useState(props.orderBy);
    const [checkAll, setCheckAll] = useState(false);

    /********* Event handlers *********/

    const handleRequestSort = (event: React.MouseEvent<HTMLSpanElement>, selectedHeadCell: TableHeader<T>) => {
        selectedHeadCell.rotateDirection();

        if (props.serverSorted && props.sortState && typeof selectedHeadCell.sortValue === 'string') {
            props.sortState.updateSortConfiguration(selectedHeadCell.sortValue as Extract<keyof T, string>, selectedHeadCell.direction);
        } else {
            /*** Client sorting - single-column sort ***/
            setOrder(selectedHeadCell.direction);
            setOrderBy(selectedHeadCell.id);
            props.tableHeaderCells.forEach(headCell => {
                if (headCell.id !== selectedHeadCell.id) {
                    headCell.setDirection(undefined);
                }
            });
        }
    };

    const onHeaderCheckboxClicked = () => {
        const newCheckAllValue = !checkAll;
        props.records.options.forEach(option => {
            if (option.selected !== newCheckAllValue) {
                option.toggleSelection();
            }
        });
        setCheckAll(!checkAll);
    }

    const onCheckboxClicked = (event: React.MouseEvent<HTMLButtonElement | HTMLTableHeaderCellElement, MouseEvent>, option: Option<T>) => {
        event.stopPropagation();
        option.toggleSelection();
    }

    const sortValueForOrderByHeader = !props.serverSorted && orderBy && order
        ? props.tableHeaderCells.find(headCell => headCell.id === orderBy)?.sortValue
        : undefined;

    /********* Render *********/

    return (
        <React.Fragment>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    className={classes.table}
                    stickyHeader
                >
                    <TableHead className={classes.header}>
                        <TableRow key="shift-table-header-row">
                            {props.tableHeaderCells.map((headCell) => (
                                headCell.sortable
                                    ? <TableCell
                                        key={headCell.id}
                                        align={headCell.alignment}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={headCell.direction || false}
                                    >
                                        <TableSortLabel
                                            active={headCell.active}
                                            direction={headCell.direction}
                                            onClick={headCell.sortValue
                                                ? (event: React.MouseEvent<HTMLSpanElement>) => handleRequestSort(event, headCell)
                                                : undefined
                                            }
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                    : <TableCell
                                        align={headCell.alignment}
                                        key={headCell.id}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        // sortDirection={headCell.direction || false}
                                    >
                                        {headCell.checkbox
                                            ? <Checkbox
                                                color='primary'
                                                value={checkAll}
                                                onClick={onHeaderCheckboxClicked}
                                                indeterminate={checkAll && props.records.selectedOptions.length < props.records.options.length}
                                            />
                                            // : headCell.direction
                                            //     ? <Tooltip title={<Typography>Not Sortable</Typography>}>
                                            //         <TableSortLabel
                                            //             active={headCell.active}
                                            //             direction={headCell.direction}
                                            //         >
                                            //             {headCell.label}
                                            //         </TableSortLabel>
                                            //     </Tooltip>
                                            : headCell.label
                                        }
                                    </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    {props.records.options.length === 0
                        ? <caption className={classes.noResultsCaption} style={{ textAlign: 'center' }}>
                            No results
                        </caption>
                        : <TableBody>
                            {(sortValueForOrderByHeader && order
                                ? stableSort(props.records.options, getComparator(order, sortValueForOrderByHeader))
                                : props.records.options)
                                .map((record, rowIndex) => {
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={`row-${rowIndex}`}
                                            onClick={props.onRowClicked ? () => props.onRowClicked!(record as Option<T>, rowIndex) : undefined}
                                            className={clsx(
                                                classes.cursor,
                                                props.tableRowClassName
                                                    ? props.tableRowClassName(record as Option<T>)
                                                    : undefined
                                            )}
                                        >
                                            {props.displayFunctions.map((displayFunction, cellIndex) => {
                                                if (cellIndex < props.tableHeaderCells.length && props.tableHeaderCells[cellIndex].checkbox) {
                                                    return (
                                                        <TableCell
                                                            align="center"
                                                            key={`${rowIndex}-${cellIndex}`}
                                                            onClick={(event) => onCheckboxClicked(event, record as Option<T>)}
                                                        >
                                                            <Checkbox
                                                                className={classes.iconButton}
                                                                color='primary'
                                                                onClick={(event) => onCheckboxClicked(event, record as Option<T>)}
                                                                checked={(record as Option<T>).selected}
                                                            />
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return displayFunction(record as Option<T>, rowIndex, cellIndex);
                                                }
                                            })
                                            }
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            {/* <TablePaginationWrapper
                                        total={organizationStore.organization.totalOpportunities}
                                        loadResults={loadOpportunitiesOverview}
                                        reloadNeeded={opportunitiesDirty}
                                    /> */}
        </React.Fragment>
    );
});

export default RecordTable;