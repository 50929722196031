import {
    flow,
    action,
    observable,
    computed,
    makeObservable,
    override,
} from 'mobx';
import { RootStore } from './RootStore';
import { HelpMessage, IHelpMessage } from './models/HelpMessage';
import { DataStore } from './DataStore';

enum HelpRequestTypes {
    ContactUs = 'contact-us'
}

export class HelpStore extends DataStore<HelpRequestTypes> {

    @observable private helpMessageOverrides?: Partial<HelpMessage>;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action setHelpMessageOverrides(helpMessage?: Partial<HelpMessage>) {
        this.helpMessageOverrides = helpMessage;
    }

    getCopyOfDefaultHelpMessage() {
        return new HelpMessage(this.defaultHelpMessage);
    }

    @computed get defaultHelpMessage() {
        let helpMessage = {} as Partial<IHelpMessage>;
        const userStore = this.rootStore.userStore;
        if (userStore.isAuthenticated) {
            helpMessage.email = userStore.user.email;
            if (userStore.user.volunteer) {
                helpMessage.firstName = userStore.user.volunteer.firstName;
                helpMessage.lastName = userStore.user.volunteer.lastName;
            }
        }
        helpMessage = {
            ...helpMessage,
            ...this.helpMessageOverrides
        }
        const message = new HelpMessage(helpMessage);
        return message;
    }

    /********* API Request Methods *********/

    @action submitContactMessage = flow(function* (this: HelpStore, helpMessage: HelpMessage) {
        const result = yield this.makeRequest<{ succeeded: boolean }>(HelpRequestTypes.ContactUs, { contact: helpMessage.serialize() });
        const { succeeded } = result;
        return succeeded;
    });

    /****** Computed Data ******/

    @override get postRequests() {
        return [HelpRequestTypes.ContactUs];
    }

    @override get requestURLs() {
        return {
            [HelpRequestTypes.ContactUs]: '/api/help/contact-us'
        };
    }
}

