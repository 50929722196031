import { useContext } from "react";
import { observer } from 'mobx-react'
import { RootContext } from "../../../../stores";
import AccountSettingsCardGrid from "../../AccountSettingsCardGrid";
import { Container, makeStyles, Theme, createStyles, Typography, Divider, useMediaQuery, Button } from "@material-ui/core";
import { Magnify, Hand } from "mdi-material-ui";
import { useSearchOpportunities } from "../../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        title: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
            },
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        gridWrapper: {
            paddingTop: theme.spacing(3),
            display: 'flex',
            flexGrow: 1
        },
        divider: {
            height: 2
        },
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        button: {
            marginTop: theme.spacing(2)
        },
        emphasizedText: {
            color: theme.palette.primary.main,
            fontWeight: 500
        },
        icon: {
            marginBottom: '-5px',
            marginRight: '2px'
        }
    })
);

const HandsRaised = observer(() => {

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const searchOpportunities = useSearchOpportunities();
    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;

    const onBrowseOpportunitiesClicked = () => {
        searchOpportunities();
    }

    return (
        <Container className={classes.container}>
            <Typography variant="h4" className={classes.title}>Responses</Typography>
            {/* <Typography variant="body1" className={classes.title}>The active volunteer opportunities that you have responded to are listed below.</Typography> */}
            <Divider className={classes.divider} variant={xsDown ? 'middle' : 'fullWidth'} />
            <div className={classes.gridWrapper}>
                <AccountSettingsCardGrid
                    emptyContent={
                        <div>
                            <Typography>
                                {`Opportunities that you `}
                                <span className={classes.emphasizedText}>
                                    <Hand className={classes.icon} />
                                    Volunteer
                                </span>
                                {` for will be saved here.`}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Magnify />}
                                onClick={onBrowseOpportunitiesClicked}
                                className={classes.button}
                            >
                                Browse Opportunities
                            </Button>
                        </div>
                    }
                    requestMethod={volunteerStore.getHandsRaised}
                />
            </div>
        </Container>
    );
});

export default HandsRaised;