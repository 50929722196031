import { action, computed, makeAutoObservable, observable } from "mobx";

export interface IShiftIdentification {
    shiftId?: number,
    parentId?: number,
    defaultDaysFromStartDate?: number
};

export class ShiftIdentification implements IShiftIdentification {
    @observable shiftId: number | undefined;
    @observable parentId: number | undefined;
    @observable defaultDaysFromStartDate: number | undefined;

    constructor(shiftIdentification: IShiftIdentification) {
        makeAutoObservable(this);

        this.shiftId = shiftIdentification.shiftId ? shiftIdentification.shiftId : undefined;
        this.parentId = shiftIdentification.parentId ? shiftIdentification.parentId : undefined;
        this.defaultDaysFromStartDate = shiftIdentification.defaultDaysFromStartDate !== undefined
            ? shiftIdentification.defaultDaysFromStartDate
            : undefined;
    }

    @action setDefaultDaysFromStartDate(defaultDaysFromStartDate: number) {
        this.defaultDaysFromStartDate = defaultDaysFromStartDate;
    }

    @computed get topLevelId() {
        return this.parentId !== undefined ? this.parentId : this.shiftId as number;
    }
}