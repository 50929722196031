import React, { useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, Paper, Container, Typography, TableCell, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext, Volunteer } from "../../../../../stores";
import { getVolunteerDetailsLink } from "../../../../Navigation/Links/UrlConstructors";
import { ShiftDetails } from "../../../../../stores/models/ShiftDetails";
import { AccountRemove } from "mdi-material-ui";
import RecordTable from "../RecordTable";
import { Option } from "../../../../../stores/models/Option";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import { ISortableTableHeader, TableHeader } from "../../../../../stores/models/TableHeader";
import { VolunteerShiftRegistration } from "../../../../../stores/models/VolunteerShiftRegistration";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog";
import UpdateStatusPopper from "../UpdateStatusPopper";
import RegisterVolunteersPopper from "./RegisterVolunteersPopper";
import { IStatus } from "../../../../../stores/models/Status";
import { getInitialDialogStates } from "../../../../../logic/DialogStateDictionary";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import REMToolbar from "../../../../../shared/modules/rem-conversion/components/Toolbar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: '15px',
            paddingBottom: '15px',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word'
        },
        section: {
            paddingTop: '15px'
        },
        toolbar: {
            display: 'flex',
        },
        selectionMenu: {
            '& > button': {
                color: theme.palette.action.active,
            },
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        },
    })
);

const headCells = ([
    { id: 'select', alignment: 'center', disablePadding: true, label: '', sortable: false, checkbox: true },
    { id: 'volunteerName', alignment: 'left', disablePadding: false, label: 'Volunteer', sortable: true, sortValue: 'volunteerName' },
    { id: 'email', alignment: 'left', disablePadding: false, label: 'Email', sortable: true, sortValue: 'email' },
    { id: 'status', alignment: 'left', disablePadding: false, label: 'Status', sortable: true, sortValue: 'status' },
] as ISortableTableHeader<VolunteerShiftRegistration>[]).map(headCell => new TableHeader(headCell));

enum DialogTypes {
    UnregisterConfirmation = 'unregisterConfirmation',
    RegisterVolunteersPopper = 'registerVolunteersPopper',
    StatusPopper = 'statusPopper'
}

interface VolunteerRegistrationsSectionProps {
    shiftDetails: ShiftDetails;
    onShiftUpdated: (newShift: ShiftDetails, oldShift?: ShiftDetails) => void;
}

const VolunteerRegistrationsSection = observer((props: VolunteerRegistrationsSectionProps) => {

    /********* React hooks *********/
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const shiftStore = rootStore.shiftStore;
    const navigate = useNavigateInternally();

    /********* State *********/

    const [dialogStates] = React.useState(getInitialDialogStates(Object.values(DialogTypes)));

    const shiftDetails = props.shiftDetails;

    const getAllVolunteerRegistrations = () => {
        return shiftDetails ? shiftDetails.volunteerRegistrations : [];
    }

    const [volunteerRegistrationsCollection, setVolunteerRegistrationsCollection] = React.useState(new OptionCollection('volunteerId', getAllVolunteerRegistrations()));

    /********* Effects *********/

    useEffect(() => {
        setVolunteerRegistrationsCollection(new OptionCollection('volunteerId', getAllVolunteerRegistrations()));
    }, [shiftDetails?.shift.shiftRegistrations, shiftDetails?.volunteerRegistrations]);

    /********* API Requests *********/

    const updateRegistrationStatuses = async (status?: IStatus) => {
        if (!status || !shiftDetails) return;

        const selections = volunteerRegistrationsCollection.selectedOptions;
        const volunteerIds = selections.map(selection => selection.volunteerId);
        const updatedRegistrations = await shiftStore.updateVolunteerRegistrationStatuses(volunteerIds, shiftDetails.shift.identificationData, status.id);
        return updatedRegistrations;
    }

    /********* Event handlers *********/

    const onVolunteerClicked = (volunteerRegistration: VolunteerShiftRegistration) => {
        navigate(getVolunteerDetailsLink(volunteerRegistration.volunteerId));
    }

    /********* Volunteer Unregistration *********/

    const onUnregisterClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        dialogStates.unregisterConfirmation.setOpen(true);
    }

    const onUnregistrationConfirmed = async () => {
        dialogStates.unregisterConfirmation.setLoading(true);
        await onRecruiterUnregisteredVolunteers();
        dialogStates.unregisterConfirmation.setLoading(false);
    }

    const onRecruiterUnregisteredVolunteers = async () => {
        const unregisteredStatus = shiftStore.volunteerRegistrationStatuses.find(status => status.status === 'Unregistered');
        const updatedRegistrations = await updateRegistrationStatuses(unregisteredStatus);

        if (!updatedRegistrations) return;
        removeUnregisteredVolunteers();
        dialogStates.unregisterConfirmation.setOpen(false);
    }

    const removeUnregisteredVolunteers = () => {
        volunteerRegistrationsCollection.selections.forEach(registration => {
            const volunteerId = registration.object.volunteerId;
            shiftDetails.shift.removeVolunteerRegistration(volunteerId);
            volunteerRegistrationsCollection.removeOption(registration.object);
        });
        volunteerRegistrationsCollection.setSelections([]);
    }

    /********* Volunteer Registration Status Changes *********/

    const onStatusUpdate = async (status: IStatus) => {
        if (!shiftDetails || !shiftDetails.topLevelShift.shiftRegistrations) return;

        dialogStates.statusPopper.setLoading(true);
        const updatedRegistrations = await updateRegistrationStatuses(status);
        dialogStates.statusPopper.setLoading(false);

        if (updatedRegistrations && status) {
            applyVolunteerStatusUpdate(status);
            dialogStates.statusPopper.setOpen(false);
        }
    }

    const applyVolunteerStatusUpdate = (status: IStatus) => {
        if (!shiftDetails) return;

        volunteerRegistrationsCollection.selections.forEach(registration => {
            const volunteerId = registration.object.volunteerId;
            const matchingVolunteerRegistration = shiftDetails.volunteerRegistrations.find(volunteerRegistration => volunteerRegistration.volunteerId === volunteerId);
            if (matchingVolunteerRegistration) {
                matchingVolunteerRegistration.setStatus(status.status);
            }
        });
    }

    /********* Volunteer Registration *********/

    const onVolunteersRegistered = async (volunteers: Volunteer[]) => {
        const volunteerIds = volunteers.map(volunteer => volunteer.id);
        const shiftIdentificationData = shiftDetails?.shift.identificationData;
        if (shiftIdentificationData && volunteerIds.length > 0) {
            dialogStates.registerVolunteersPopper.setLoading(true);
            try {
                const response = await shiftStore.registerVolunteersForShift(volunteerIds, shiftIdentificationData);
                if (!response) return;
                if (shiftDetails) {
                    if (response.isOneOffShift) {
                        shiftDetails.setShift(response);
                    } else {
                        const parentShift = response;
                        shiftDetails.setParentShift(parentShift);
                        const currentInstance = parentShift.getInstanceByIdentificationData(shiftIdentificationData);
                        if (currentInstance) {
                            shiftDetails.setShift(currentInstance);
                        }
                    }
                    props.onShiftUpdated(shiftDetails);
                }
                dialogStates.registerVolunteersPopper.setOpen(false);
            } finally {
                dialogStates.registerVolunteersPopper.setLoading(false);
            }
        }
    }

    /********* Render *********/

    return (
        <React.Fragment>
            {/* Registrations */}
            <Paper className={classes.paper}>
                <Container>
                    <Typography variant="h5">
                        Registrations
                    </Typography>
                    {/* Toolbar Buttons */}
                    <REMToolbar
                        className={classes.toolbar}
                        disableGutters
                    >
                        {volunteerRegistrationsCollection.selections.length > 0
                            ? <div className={classes.selectionMenu}>
                                <Button
                                    startIcon={<AccountRemove />}
                                    variant="outlined"
                                    size="large"
                                    onClick={onUnregisterClicked}
                                >
                                    Unregister
                                </Button>
                                <UpdateStatusPopper
                                    statuses={shiftStore.filteredRegistrationStatuses}
                                    popperState={dialogStates.statusPopper}
                                    onConfirm={onStatusUpdate}
                                />
                                {/* <Button
                                                                startIcon={<Email />}
                                                                variant="outlined"
                                                                size="large"
                                                            // TODO: implement onClick
                                                            >
                                                                Email
                                                            </Button> */}
                            </div>
                            : <RegisterVolunteersPopper
                                registeredVolunteerIds={volunteerRegistrationsCollection.optionObjects.map(registration => registration.volunteerId)}
                                popperState={dialogStates.registerVolunteersPopper}
                                onConfirm={onVolunteersRegistered}
                                maxVolunteers={shiftDetails.shift.unlimitedSlots ? undefined : shiftDetails.shift.slotsLeft}
                            />
                        }
                    </REMToolbar>
                    {/* Registrations Table */}
                    <RecordTable
                        records={volunteerRegistrationsCollection}
                        tableHeaderCells={headCells}
                        orderBy={'volunteerName'}
                        order={'asc'}
                        displayFunctions={[
                            (serviceEntry: Option<VolunteerShiftRegistration>, rowIndex: number, cellIndex: number) => {
                                return null;
                            },
                            (option: Option<VolunteerShiftRegistration>, rowIndex: number, cellIndex: number) => {
                                return (
                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                        {option.object.volunteerName}
                                    </TableCell>
                                );
                            },
                            (option: Option<VolunteerShiftRegistration>, rowIndex: number, cellIndex: number) => {
                                return (
                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                        {option.object.email}
                                    </TableCell>
                                );
                            },
                            (option: Option<VolunteerShiftRegistration>, rowIndex: number, cellIndex: number) => {
                                return (
                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                        {option.object.status}
                                    </TableCell>
                                );
                            },
                        ]}
                        onRowClicked={(option: Option<VolunteerShiftRegistration>, rowIndex: number) => {
                            onVolunteerClicked(option.object);
                        }}
                    />
                </Container>
            </Paper>
            <ConfirmationDialog
                state={dialogStates.unregisterConfirmation}
                title={volunteerRegistrationsCollection.selectedOptions.length > 1
                    ? "Unregister Volunteers"
                    : "Unregister Volunteer"
                }
                content={volunteerRegistrationsCollection.selectedOptions.length > 1
                    ? <Typography>Are you sure you want to unregister these volunteers?</Typography>
                    : <Typography>Are you sure you want to unregister this volunteer?</Typography>
                }
                confirmText={'Unregister'}
                onConfirm={onUnregistrationConfirmed}
                fullScreen={false}
            />
        </React.Fragment>
    );
});

export default VolunteerRegistrationsSection;