import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Container, Typography, Paper, Grid, IconButton, Tooltip, AppBar, Toolbar, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, Card, Backdrop, CircularProgress, useMediaQuery, useTheme, Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { RootContext } from "../../../stores";
import LoadingIndicator from "../../Shared/LoadingIndicator";
import useOrganizationLink from "../../Navigation/Hooks/OrganizationProfile/OrganizationLink";
import LocationCard from "../LocationCard";
import { OpportunityScheduling, OpportunityNeed, TimeCommitment, OpportunityLocation, Opportunity, OpportunityPermissionType } from "../../../stores/models/Opportunity";
import { Hand, ClipboardCheck, Airballoon, Autorenew, CalendarClock, CalendarMultiselect, Gesture, Ring, MapMarker, LightningBolt, Email } from "mdi-material-ui";
import SparkButton from "../../Shared/SparkButton";
import VolunteerApplicationDialog from "./VolunteerApplicationDialog";
import { BreadcrumbPath } from "../../../stores/models/BreadcrumbPath";
import useCurrentWidth from "../../Shared/Hooks/WindowResize";
import { getOrgOpportunitiesLink, getOpportunityEditorLink } from "../../Navigation/Links/UrlConstructors";
import InactiveOpportunityDialog from "./InactiveOpportunityDialog";
import EditButton from "../../Shared/EditButton";
import useReloadOnUserChange from "../../Shared/Hooks/ReloadOnUserChange";
import { useHover } from '@react-aria/interactions'
import ScheduleViewer from "../../AccountSettings/Shared/ScheduleViewer";
import SendMessageDialog from "./SendMessageDialog";
import { NAVIGATION_BAR_ID } from "../../Navigation/NavigationBar";
import { Alert, AlertTitle } from "@material-ui/lab";
import PageWithFooter from "../../Shared/PageWithFooter";
import FormDialog from "../../Shared/Forms/DialogForm/FormDialog";
import { getInitialDialogStates } from "../../../logic/DialogStateDictionary";
import { InternalLink, NavigateInternally } from "../../Navigation/Components";
import useFormattedContent from "../../../shared/modules/content/hooks/useFormattedContent";
import REMChip from "../../../shared/modules/rem-conversion/components/Chip";
import { DefaultShiftListViewOppIds } from "../../../data/temporary/ShiftLabels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        paper: {
            paddingTop: '15px',
            paddingBottom: '15px',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            marginBottom: theme.spacing(1),
        },
        section: {
            paddingTop: '15px',
            '& h6': {
                fontWeight: 600
            }
        },
        header: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
        },
        title: {
            marginBottom: theme.spacing(1),
            fontWeight: 600
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        locations: {
            marginBottom: theme.spacing(2),
        },
        iconButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            marginLeft: theme.spacing(2),
        },
        handButton: {
            position: 'relative',
            color: theme.palette.action.disabled,
            '& $hovered $handIcon': {
                color: theme.palette.primary.main,
                animation: '$raiseHand 500ms 2',
            }
        },
        hovered: {},
        handIcon: {
            position: 'absolute'
        },
        selected: {
            color: theme.palette.primary.main,
        },
        hidden: {
            opacity: 0
        },
        '@keyframes raiseHand': {
            '0%': {
                marginTop: 0
            },
            '40%': {
                marginTop: '-12px'
            },
            '100%': {
                marginTop: 0
            }
        },
        skills: {
            marginTop: theme.spacing(1)
        },
        chip: {
            marginRight: '5px',
            marginBottom: '5px',
            background: theme.palette.lightBackground.main
        },
        appBar: {
            top: 'auto',
            bottom: 0,
        },
        grow: {
            flexGrow: 1,
        },
        loadedContent: {
            width: '100%',
            marginBottom: theme.spacing(1)
        },
        avatar: {
            color: theme.palette.primary.main,
            background: theme.palette.common.white
        },
        filters: {
            marginTop: theme.spacing(1)
        },
        toolbarSpacer: {
            ...theme.mixins.toolbar,
            background: theme.palette.darkBackground.main
        },
        editButton: {
            color: theme.palette.primary.contrastText
        },
        actionButtonsBox: {
            position: 'sticky',
            top: theme.spacing(9),
        },
        actionButtons: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 3),
            zIndex: theme.zIndex.speedDial,
            '& > :not(:first-child)': {
                marginTop: theme.spacing(1.5),
            }
        },
        actionButton: {
            color: theme.palette.action.active,
            textWrap: 'nowrap'
        },
        upcomingSchedule: {
            marginTop: theme.spacing(2),
            // TODO: Figure out fixed height UI for Upcoming shifts section
            // '& .MuiPaper-root': {
            //     maxHeight: 738,
            //     overflow: 'hidden'
            // },
            // '& .MuiList-root': {
            //     overflowY: 'auto'
            // }
        },
        loadingOverlay: {
            position: 'fixed',
            display: 'flex',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgb(255,255,255,0.7)',
            padding: theme.spacing(1),
            zIndex: theme.zIndex.modal + 1
        },
        spark: {
            background: theme.palette.accent.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.accent.dark
            },
            textWrap: 'nowrap'
        },
        alert: {
            marginBottom: theme.spacing(1)
        },
        alertButtonWrapper: {
            marginTop: theme.spacing(1),
        }
    }));

enum DialogTypes {
    RegistrationForm = 'registrationForm',
    ExpressInterest = 'expressInterest',
    InactiveOpportunity = 'inactiveOpportunity',
    ContactRecruiter = 'contactRecruiter'
}

const UPCOMING_SCHEDULE_SECTION_ID = 'upcoming-schedule-section';

interface OpportunityInformationProps {
    breadcrumbs: BreadcrumbPath;
}

const OpportunityInformation = observer((props: OpportunityInformationProps) => {

    /********* React hooks *********/

    const theme = useTheme();
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;
    const opportunityStore = rootStore.opportunityStore;
    const formStore = rootStore.formStore;
    const { orgId, oppId } = useParams() as { orgId: string, oppId: string };
    const organizationLink = useOrganizationLink();
    const width = useCurrentWidth();
    const { hoverProps, isHovered } = useHover({});
    const shouldCondenseButtonText = useMediaQuery((theme: Theme) => theme.breakpoints.down(1250));

    /********* Helper constants *********/

    const parsedOppId = parseInt(oppId || "");
    const parsedOrgId = parseInt(orgId || "");
    const orgMatchesUser = userStore.isAuthenticatedOrganization(parsedOrgId);

    /********* State *********/

    const [oppToDisplay, setOppToDisplay] = useState(new Opportunity());
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingData, setIsUpdatingData] = useState(false);
    const [dialogStates] = useState(getInitialDialogStates(Object.values(DialogTypes)));
    const [registrationCallback, setRegistrationCallback] = useState<() => void>();
    const [refreshInitialData, setRefreshInitialData] = useState(false);

    const formattedDescription = useFormattedContent({ content: oppToDisplay.description});
    const formattedTraining = useFormattedContent({ content: oppToDisplay.trainingDescription});

    /********* Effects *********/

    useEffect(() => {
        sendGetOpportunityRequest(true);
    }, [parsedOppId, parsedOrgId]);

    useEffect(() => {
        if (oppToDisplay) {
            const breadcrumb = {
                displayText: oppToDisplay.title,
                link: ''
            }
            props.breadcrumbs.pushBreadcrumb(breadcrumb);

            // If the Opportunity Inactivated Warning isn't already open and the opportunity
            // is inactive, display a warning to the user.
            if (navigationStore.appwideDialogStates.activeCitiesLimitDialog.open
                || navigationStore.appwideDialogStates.remoteOppsLimitDialog.open) {
                dialogStates.inactiveOpportunity.setOpen(false);
            } else {
                dialogStates.inactiveOpportunity.setOpen(!oppToDisplay.active);
            }
        }
        return function cleanup() {
            props.breadcrumbs.popBreadcrumb();
        }
    }, [oppToDisplay]);

    useEffect(() => {
        if (!dialogStates.registrationForm.open) {
            oppToDisplay.resetForm();
        }
    }, [dialogStates.registrationForm.open]);

    useEffect(() => {
        if (oppToDisplay.registration?.completed) {
            dialogStates.registrationForm.setOpen(false);
            if (registrationCallback) {
                registrationCallback();
            }
            setRegistrationCallback(undefined);
        }
    }, [oppToDisplay.registration?.completed]);

    /********* Data Fetching *********/

    // Request the opportunity matching the id in the URL
    const sendGetOpportunityRequest = async (initialLoad?: boolean) => {
        toggleLoadingIndicator(true, initialLoad);
        const response = await opportunityStore.getOppInfo(parsedOrgId, parsedOppId);
        if (response) {
            setOppToDisplay(response);
            setRefreshInitialData(true);
        }
        toggleLoadingIndicator(false, initialLoad);
    }

    useReloadOnUserChange({ dataLoadingCallback: sendGetOpportunityRequest });

    /********* Helper methods *********/

    const toggleLoadingIndicator = (on: boolean, initialLoad?: boolean) => {
        if (initialLoad) {
            setIsLoading(on);
        } else {
            setIsUpdatingData(on);
        }
    }

    const submitRegistrationForm = async () => {
        const response = await formStore.submitForm(oppToDisplay.registration?.form!, orgId);
        if (response.succeeded) {
            oppToDisplay.setRegistrationCompleted();
        }
        return response;
    }

    const onRegistrationSubmitClicked = async () => {
        const response = await submitRegistrationForm();
        return response;
    }

    const performActionIfVolunteerRegistered = (callback: () => void) => {
        if (oppToDisplay.registration === undefined || oppToDisplay.registration?.completed || oppToDisplay.registration.form === undefined) {
            callback();
        } else {
            // Prompt the volunteer to fill out the registration form first
            dialogStates.registrationForm.setOpen(true);
            setRegistrationCallback(() => callback);
        }
    }

    const performActionIfVolunteerAuthenticated = (callback: () => void) => {
        if (userStore.isAuthenticated) {
            if (userStore.user.isVolunteer) {
                callback();
            } else {
                navigationStore.appwideDialogStates.volunteerAccountRequiredDialog.setOpen(true);
            }
        } else {
            // Prompt the user to login or sign up first
            navigationStore.appwideDialogStates.loginDialog.setOpen(true);
            navigationStore.setOnAuthenticationCallback(() => performActionIfVolunteerAuthenticated(callback));
        }
    }

    const scrollToShifts = () => {
        const upcomingScheduleSection = document.getElementById(UPCOMING_SCHEDULE_SECTION_ID);
        const header = document.getElementById(NAVIGATION_BAR_ID);
        if (upcomingScheduleSection && header) {
            const topOfElement = upcomingScheduleSection.offsetTop - header.offsetHeight - theme.spacing(1);
            window.scroll({ top: topOfElement, behavior: 'smooth' });
        }
    }

    const oppHasUpcomingShifts = () => {
        return oppToDisplay.scheduling === OpportunityScheduling.Shifts
            && oppToDisplay.shiftCollection.hasUnexpiredShifts;
    }

    const getVolunteerButtonText = () => {
        if (oppToDisplay.permissionType === OpportunityPermissionType.AssignmentOnly || !oppToDisplay.hasPermission) {
            return 'Express Interest';
        } else {
            return oppHasUpcomingShifts() || !oppToDisplay.responseSubmitted ? 'Volunteer' : 'Respond again';
        }
    }

    /********* Event Handlers *********/

    const onHandRaised = () => {
        if (oppToDisplay.hasPermission && oppToDisplay.permissionType !== OpportunityPermissionType.AssignmentOnly && oppHasUpcomingShifts()) {
            scrollToShifts();
        } else {
            performActionIfVolunteerAuthenticated(() => performActionIfVolunteerRegistered(() => dialogStates.expressInterest.setOpen(true)));
        }
    }

    const onSparkButtonClicked = () => {
        const currentSparkValue = oppToDisplay.interestSparked;
        performActionIfVolunteerAuthenticated(() => setSparkValue(!currentSparkValue));
    }

    const onSendMessageClicked = () => {
        performActionIfVolunteerAuthenticated(() => dialogStates.contactRecruiter.setOpen(true));
    }

    const setSparkValue = (sparked: boolean) => {
        oppToDisplay.setInterestSparked(sparked);
        rootStore.volunteerStore.updateOpportunitySpark(oppToDisplay.id, sparked);
    }

    /********* Render *********/

    return (
        <Fragment>
            <PageWithFooter>
                <div id="opportunity-information" className={classes.root}>
                    <Container>
                        {isLoading
                            ? <LoadingIndicator />
                            : oppToDisplay.organizationId !== parsedOrgId
                                ? <NavigateInternally to={getOrgOpportunitiesLink(parsedOrgId)} replace /> // TODO: This should link to the main portal if in an org-specific portal, or maybe this won't even be reachable.
                                : <React.Fragment>
                                    {isUpdatingData &&
                                        <Backdrop open={true} className={classes.loadingOverlay}>
                                            <CircularProgress />
                                        </Backdrop>
                                    }
                                    <div className={classes.loadedContent}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={9}>
                                                <Paper className={classes.paper}>
                                                    <Container>
                                                        {/* Title and Icon Buttons */}
                                                        <div className={classes.header}>
                                                            <Typography variant="h4" className={classes.title}>{oppToDisplay.title}</Typography>
                                                            <Box sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }}>
                                                                {orgMatchesUser
                                                                    ? <div className={classes.iconButtons}>
                                                                        <EditButton link={getOpportunityEditorLink(parsedOppId)} />
                                                                    </div>
                                                                    : <div className={classes.iconButtons}>
                                                                        <SparkButton sparkObject={oppToDisplay} displayHoverText={false} />
                                                                        <Tooltip title={oppToDisplay.responseSubmitted ? "You have responded to this opportunity" : "Volunteer"}>
                                                                            <IconButton className={classes.handButton} onClick={onHandRaised}>
                                                                                {oppToDisplay.responseSubmitted
                                                                                    ? <ClipboardCheck className={classes.selected} />
                                                                                    : <div {...hoverProps} className={isHovered ? classes.hovered : undefined}>
                                                                                        <Hand className={classes.handIcon} />
                                                                                        <Hand className={classes.hidden} />
                                                                                    </div>
                                                                                }
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                }
                                                            </Box>
                                                        </div>
                                                        {/* Posted by Organization */}
                                                        <Typography variant="body2">
                                                            Posted by <InternalLink to={organizationLink} className={classes.link}>
                                                                {oppToDisplay.organizationName}
                                                            </InternalLink>
                                                        </Typography>
                                                        {/* Filters */}
                                                        <div className={classes.filters}>
                                                            <REMChip
                                                                className={classes.chip}
                                                                label={oppToDisplay.need === OpportunityNeed.Ongoing ? 'Ongoing Opportunity' : 'One Time Opportunity'}
                                                                icon={oppToDisplay.need === OpportunityNeed.Ongoing ? <Autorenew /> : <CalendarClock />}
                                                            />
                                                            {oppToDisplay.timeCommitment === TimeCommitment.LongTerm &&
                                                                <REMChip
                                                                    className={classes.chip}
                                                                    label={'Long Term Commitment'}
                                                                    icon={<Ring />}
                                                                />
                                                            }
                                                            <REMChip
                                                                className={classes.chip}
                                                                label={oppToDisplay.scheduling === OpportunityScheduling.Flexible ? 'Flexible Scheduling' : 'Shift Scheduling'}
                                                                icon={oppToDisplay.scheduling === OpportunityScheduling.Flexible ? <Gesture /> : <CalendarMultiselect />}
                                                            />
                                                        </div>
                                                        {/* Role */}
                                                        <div className={classes.section}>
                                                            <Typography variant="h6">Role</Typography>
                                                            <Typography>{oppToDisplay.position}</Typography>
                                                        </div>
                                                        {/* Description */}
                                                        <div className={classes.section}>
                                                            <Typography variant="h6">Description</Typography>
                                                            <Typography>{formattedDescription}</Typography>
                                                        </div>
                                                        {/* Onboarding Process */}
                                                        {oppToDisplay.trainingDescription
                                                            ? <div className={classes.section}>
                                                                <Typography variant="h6">Onboarding Process</Typography>
                                                                <Typography>{formattedTraining}</Typography>
                                                                {/* TODO: Rename trainingDescription property? */}
                                                            </div>
                                                            : null}
                                                        {/* Skills */}
                                                        {oppToDisplay.skills.length > 0
                                                            ? <div className={classes.section}>
                                                                <Typography variant="h6">Skills</Typography>
                                                                <div className={classes.skills}>
                                                                    {oppToDisplay.skills.map((skill, index) => {
                                                                        return <REMChip label={skill.skill} key={`skill-${index}`} className={classes.chip} />
                                                                    })}
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                        {/* Shifts */}
                                                        {/* {oppToDisplay.scheduling !== OpportunityScheduling.Flexible && oppToDisplay.shiftCollection.hasUnexpiredShifts
                                                ? <div className={classes.section}>
                                                    <Typography variant="h6">Shifts</Typography>
                                                    <PublicShiftList
                                                        shifts={oppToDisplay.shiftCollection.getOrderedShiftInstances(10, { timestamp: new Date() })}
                                                        onShiftActionSelected={() => { return; }}
                                                        onSignUpSuccessful={() => { return; }}
                                                    />
                                                </div>
                                                : null} */}
                                                    </Container>
                                                </Paper>
                                                {oppHasUpcomingShifts() &&
                                                    <div className={classes.upcomingSchedule} id={UPCOMING_SCHEDULE_SECTION_ID}>
                                                        <ScheduleViewer
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography variant="h5" className={classes.title}>
                                                                        Upcoming Schedule
                                                                    </Typography>
                                                                    {(oppToDisplay.permissionType === OpportunityPermissionType.AssignmentOnly || !oppToDisplay.hasPermission) &&
                                                                        <Alert severity="warning" className={classes.alert}>
                                                                            {oppToDisplay.permissionType === OpportunityPermissionType.AssignmentOnly
                                                                                ? <AlertTitle>Shifts by Assignment Only</AlertTitle>
                                                                                : <AlertTitle>Permission Needed to Register</AlertTitle>
                                                                            }
                                                                            Let the recruiter know you'd like to volunteer by expressing interest in the opportunity.
                                                                            <div className={classes.alertButtonWrapper}>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    startIcon={<Hand />}
                                                                                    onClick={onHandRaised}
                                                                                    aria-label="volunteer"
                                                                                >
                                                                                    {getVolunteerButtonText()}
                                                                                </Button>
                                                                            </div>
                                                                        </Alert>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            refreshInitialData={refreshInitialData}
                                                            loadInitialData={async (startTimeStamp, endTimestamp) => {
                                                                // TODO: Determine what to display on calendar:
                                                                // let shiftOpportunities: ShiftOpportunities = {};
                                                                // oppToDisplay.shiftCollection.shifts.forEach(shift => {
                                                                //     shiftOpportunities[shift.id] = {
                                                                //         position: `Slots: ${shift.slotsLeft}`, //oppToDisplay.position,
                                                                //         opportunityId: oppToDisplay.id
                                                                //     };
                                                                //     shift.modifiedInstances.forEach(modifiedInstance => {
                                                                //         shiftOpportunities[modifiedInstance.id] = {
                                                                //             position: `Slots: ${modifiedInstance.slotsLeft}`,//oppToDisplay.position,
                                                                //             opportunityId: oppToDisplay.id
                                                                //         };
                                                                //     })
                                                                // });
                                                                setRefreshInitialData(false);
                                                                return {
                                                                    shifts: oppToDisplay.shiftCollection.shifts,
                                                                    shiftOpportunities: {}
                                                                }
                                                            }}
                                                            noSubsequentDataToLoad
                                                            onShiftClicked={() => { return; }}
                                                            listView={DefaultShiftListViewOppIds.includes(oppToDisplay.id) || undefined}
                                                            useCardList
                                                            hidePastShifts
                                                            needsPermission={!oppToDisplay.hasPermission}
                                                            assignmentOnly={oppToDisplay.permissionType === OpportunityPermissionType.AssignmentOnly}
                                                            opportunitySpecific
                                                            registration={oppToDisplay.registration}
                                                            resetForm={oppToDisplay.resetForm}
                                                            onRegistrationSubmitClicked={submitRegistrationForm}
                                                        />
                                                    </div>
                                                }
                                            </Grid>
                                            {/* Right column */}
                                            <Grid item xs={12} md={3}>
                                                {/* Locations */}
                                                <div className={classes.locations}>
                                                    <LocationCard
                                                        title={oppToDisplay.addressCollection.addresses.length > 1 ? 'Locations' : 'Location'}
                                                        addresses={oppToDisplay.addressCollection.addresses}
                                                        noAddressNode={oppToDisplay.virtual === OpportunityLocation.Remote
                                                            ? <List>
                                                                <ListItem disableGutters>
                                                                    <ListItemAvatar>
                                                                        <Avatar className={classes.avatar}>
                                                                            <Airballoon />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary="Remote Opportunity" secondary="Volunteer from anywhere." />
                                                                </ListItem>
                                                            </List>
                                                            : <List>
                                                                <ListItem disableGutters>
                                                                    <ListItemAvatar>
                                                                        <Avatar className={classes.avatar}>
                                                                            <MapMarker />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary="On Site Opportunity" secondary="No address provided." />
                                                                </ListItem>
                                                            </List>
                                                        }
                                                    />
                                                </div>
                                                <Box sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }} className={classes.actionButtonsBox}>
                                                    <Card className={classes.actionButtons}>
                                                        {orgMatchesUser
                                                            ? <EditButton
                                                                link={getOpportunityEditorLink(parsedOppId)}
                                                                ButtonProps={{ variant: 'contained', color: 'primary', className: classes.editButton }}
                                                                showText
                                                            />
                                                            : <React.Fragment>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    startIcon={<Hand />}
                                                                    size="large"
                                                                    onClick={onHandRaised}
                                                                    aria-label="volunteer"
                                                                >
                                                                    {getVolunteerButtonText()}
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    startIcon={<LightningBolt />}
                                                                    size="large"
                                                                    aria-label={oppToDisplay.interestSparked ? 'Remove From Sparks' : 'Save to Sparks'}
                                                                    onClick={onSparkButtonClicked}
                                                                    className={classes.spark}
                                                                >
                                                                    {shouldCondenseButtonText
                                                                        ? oppToDisplay.interestSparked ? 'Unspark' : 'Spark'
                                                                        : oppToDisplay.interestSparked ? 'Remove Spark' : 'Save to Sparks'
                                                                    }
                                                                </Button>
                                                                <Button
                                                                    variant="outlined"
                                                                    startIcon={<Email />}
                                                                    size="large"
                                                                    aria-label={'Contact recruiter'}
                                                                    onClick={onSendMessageClicked}
                                                                    className={classes.actionButton}
                                                                >
                                                                    Contact
                                                                </Button>
                                                            </React.Fragment>
                                                        }
                                                        {/* <Button
                                                    variant="outlined"
                                                    startIcon={<Share />}
                                                    size="large"
                                                    className={classes.actionButton}
                                                // TODO: Add Share onClick handler
                                                >
                                                    Share
                                                </Button> */}
                                                    </Card>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <VolunteerApplicationDialog opportunity={oppToDisplay} state={dialogStates.expressInterest} />
                                        <InactiveOpportunityDialog dialogState={dialogStates.inactiveOpportunity} />
                                        <SendMessageDialog
                                            dialogState={dialogStates.contactRecruiter}
                                            recipient={oppToDisplay.organizationName}
                                            opportunityId={oppToDisplay.id}
                                        />
                                        {oppToDisplay.registration?.form &&
                                            <FormDialog
                                                state={dialogStates.registrationForm}
                                                form={oppToDisplay.registration?.form}
                                                onFormSubmit={onRegistrationSubmitClicked}
                                            />
                                        }
                                    </div>
                                </React.Fragment>
                        }
                    </Container>
                </div>
            </PageWithFooter>
            <Box sx={{ display: { sm: 'block', md: 'none' } }}>
                <div className={classes.toolbarSpacer} />
                <AppBar position="fixed" color="inherit" className={classes.appBar}>
                    <Toolbar>
                        {!orgMatchesUser &&
                            <SparkButton
                                sparkObject={oppToDisplay}
                                displayText={width > 325 || (width > 285 && !oppToDisplay.responseSubmitted)}
                                darker
                            />
                        }
                        <div className={classes.grow} />
                        {orgMatchesUser
                            ? <EditButton
                                link={getOpportunityEditorLink(parsedOppId)}
                                ButtonProps={{ variant: 'contained', color: 'primary', className: classes.editButton }}
                                showText
                            />
                            : <Button startIcon={<Hand />} color="primary" aria-label="volunteer" variant="contained" onClick={onHandRaised}>
                                {getVolunteerButtonText()}
                            </Button>
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        </Fragment>
    );
});

export default OpportunityInformation;