import { useContext, useState, useEffect, Fragment, useMemo } from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Container, Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { RootContext } from "../../../../stores";
import { Step } from "../../../../stores/models/Step";
import { OrganizationStubVolunteerLinking } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import LoadingIndicator from "../../LoadingIndicator";
import Footer from "../../Footer";
import StepSequencer from "../../StepSequencer";
import EmbeddedForm from "../EmbeddedForm/EmbeddedForm";
import CenteredLoadingSpinner from "../../CenteredLoadingSpinner";
import useOrganizationRegistrationForm from "../LoadOrganizationRegistrationForm";
import useStandaloneOrgRegistrationSteps, { VolunteerLinkingSteps } from "./StandaloneFormSteps";
import { RegistrationFormStepObject } from "./Steps/RegistrationFormStepObject";
import { Alert } from "@material-ui/lab";
import SuccessfulRegistrationMessage from "./SuccessfulRegistrationMessage";
import FormSubmittedWarning from "../FormSubmittedWarning";
import InvalidPageErrorMessage from "../../InvalidPageErrorMessage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'relative'
        },
        content: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(4),
            display: 'inline-block',
            position: 'relative', // the loading spinner refers to this element,
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: 'column'
            }
        },
        embeddedForm: {
            display: 'flex',
            justifyContent: 'center'
        },
        formWrapper: {
            display: 'flex',
            flexDirection: 'column',
        },
        formError: {
            marginBottom: theme.spacing(2)
        },
        formInfo: {
            marginTop: theme.spacing(2),
        }
    })
);

const SUBMIT_TEXT = 'Submit';

/********* Helper methods *********/

const getForwardButtonText = (currentStepName?: string) => {
    if (currentStepName === VolunteerLinkingSteps.Registration) {
        return SUBMIT_TEXT;
    } else {
        return 'Next';
    }
}

const isFinalStep = (currentStepName?: string) => {
    return getForwardButtonText(currentStepName) === SUBMIT_TEXT || currentStepName === VolunteerLinkingSteps.VerifyCorrectAccount;
}

const setLastStepIncomplete = (steps: Step<any>[]) => {
    if (steps.length > 0) {
        steps[steps.length - 1].state.setCompleted(false);
    }
}

/********* Component *********/

const StandaloneForm = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const registrationStore = rootStore.registrationStore;
    const formStore = rootStore.formStore;
    const { orgId } = useParams() as { orgId: string };
    const organizationId = parseInt(orgId);

    /********* State *********/

    const [currentStep, setCurrentStep] = useState<Step<OrganizationStubVolunteerLinking>>();
    const [stepsCompleted, setStepsCompleted] = useState(false);

    const [error, setError] = useState<string>();
    const [orgRegistrationFormCompleted, setOrgRegistrationFormCompleted] = useState(false);
    const [orgRegistrationFormLoading, setOrgRegistrationFormLoading] = useState(false);

    const stepObject = useMemo(() => new RegistrationFormStepObject(), []);
    const steps = useStandaloneOrgRegistrationSteps(stepObject);
    const [registrationForm, registrationFormLoaded, formSubmitted] = useOrganizationRegistrationForm(organizationId, true);

    /********* Effect *********/

    useEffect(() => {
        if (currentStep?.name !== VolunteerLinkingSteps.Registration) {
            setError(undefined);
        }
    }, [currentStep]);

    /********* Authentication Step Event handlers *********/

    const onAllStepsCompleted = async (stepObject: OrganizationStubVolunteerLinking) => {
        try {
            if (isFinalStep(currentStep?.name)) {
                await onSubmit();
                return { success: true };
            }
        } catch (err) {
            return { success: false, error: err as string };
        }
    }

    const onSubmit = async () => {
        if (stepObject.creatingNewUser) {
            await attemptUserRegistration();
        } else {
            setStepsCompleted(true);
        }
    }

    const attemptUserRegistration = async () => {
        if (!stepObject.validated) {
            stepObject.setAllFieldsDirty();
            return;
        }

        registerNewVolunteer();
    }

    const registerNewVolunteer = async () => {
        try {
            currentStep?.state.setLoading(true);
            const error = await registrationStore.register(stepObject.stubUser);
            if (error) {
                setError(error);
                setLastStepIncomplete(steps);
            } else {
                setStepsCompleted(true);
            }
        } catch (err) {
            throw err;
        } finally {
            currentStep?.state.setLoading(false);
        }
    }

    /********* Organization Registration Form Event handlers *********/

    const onOrgRegistrationFormSubmit = async () => {
        if (!registrationForm) return { succeeded: false };
        setOrgRegistrationFormLoading(true);
        const response = await formStore.submitForm(registrationForm, organizationId.toString());
        setOrgRegistrationFormLoading(false);
        if (response.succeeded) {
            setOrgRegistrationFormCompleted(true);
        }
        return response;
    };

    const orgHasNoRegistrationForm = registrationFormLoaded && (registrationForm === undefined || (registrationForm && registrationForm.version < 0));

    /********* Render *********/

    return (
        <Fragment>
            <div className={classes.root}>
                {orgHasNoRegistrationForm
                    ? <Paper className={classes.content}>
                        <InvalidPageErrorMessage />
                    </Paper>
                    : !registrationFormLoaded
                        ? <LoadingIndicator />
                        : <Container>
                            {!stepsCompleted
                                ? <Paper className={classes.content}>
                                    {error
                                        ? <Alert severity="error" className={classes.formError}>
                                            {error}
                                        </Alert>
                                        : null
                                    }
                                    <StepSequencer
                                        steps={steps}
                                        onAllStepsCompleted={onAllStepsCompleted}
                                        onCurrentStepChanged={(step: Step<OrganizationStubVolunteerLinking>) => setCurrentStep(step)}
                                        getForwardButtonText={() => getForwardButtonText(currentStep?.name)}
                                    />
                                </Paper>
                                : (registrationForm && !orgRegistrationFormCompleted)
                                    ? <div className={classes.embeddedForm}>
                                        <div className={classes.formWrapper}>
                                            {formSubmitted
                                                ? <FormSubmittedWarning className={classes.formInfo} />
                                                : null
                                            }
                                            <EmbeddedForm formStructure={registrationForm} onFormSubmit={onOrgRegistrationFormSubmit} />
                                        </div>
                                        {orgRegistrationFormLoading && <CenteredLoadingSpinner />}
                                    </div>
                                    : <Paper className={classes.content}>
                                        <SuccessfulRegistrationMessage organizationId={organizationId} />
                                    </Paper>
                            }
                        </Container>
                }
            </div>
            <Footer />
        </Fragment >
    );
});

export default StandaloneForm;