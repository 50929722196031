import React, { useRef, useEffect } from "react";
import { Typography, makeStyles, Theme, createStyles, Fade } from "@material-ui/core";
import CityInput from "./CityInput";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        emphasizedText: {
            color: theme.palette.primary.main,
            fontWeight: 900
        },
        text: {
            color: theme.palette.action.active
        },
        input: {
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center'
        },
        locationIcon: {
            color: theme.palette.primary.main,
        }
    }),
);

interface CityPickerProps {
    city: string;
    cityInputAnimateIn?: boolean;
    focusCityInput?: boolean;
    onCityInputAnimatedIn?: () => void;
    onCityChanged: (cityText: string) => void;
}

const CityPicker = observer((props: CityPickerProps) => {

    /****** React hooks ******/

    const classes = useStyles();

    /****** State ******/

    const cityInputRef = useRef<any>();

    useEffect(() => {
        // Assign focus to the city input
        if (props.focusCityInput) {
            setTimeout(() => {
                if (cityInputRef.current) {
                    cityInputRef.current.focus();
                }
            }, 500);
        }
    }, [props.focusCityInput]);

    return (
        <div className={classes.text}>
            <Typography variant="h4">You have the</Typography>
            <Typography variant="h4" className={classes.emphasizedText}>potential</Typography>
            <Typography variant="h4">to make</Typography>
            <Typography variant="h4">a difference.</Typography>
            {/* <Fade
                in={props.cityInputAnimateIn !== undefined ? props.cityInputAnimateIn : true}
                timeout={1000}
                onEntered={props.onCityInputAnimatedIn}
            > */}
            <div className={classes.input}>
                <CityInput
                    city={props.city}
                    onChange={props.onCityChanged}
                    placeholder="Wherever you are."
                    ref={cityInputRef}
                    iconClass={classes.locationIcon}
                />
            </div>
            {/* </Fade> */}
        </div>
    )
});

export default CityPicker;