import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, isValidFutureDate } from "./Validation";
import { RepetitionEnding, RepetitionEndType } from "../../stores/models/RepetitionEnding";
import { isValid, startOfDay } from "date-fns";

const repetitionEndingChecks: ValidationCheck<RepetitionEnding>[] = [{
    name: 'repetition ending on date required',
    condition: (ending: RepetitionEnding) => {
        return ending.endType !== RepetitionEndType.EndDate || (ending.endDate !== null && isValid(ending.endDate));
    },
    error: new ValidationError('Invalid end date.', ['endDate'])
}, {
    name: 'ending date after minimum end date',
    condition: (ending: RepetitionEnding) => {
        return ending.endType !== RepetitionEndType.EndDate
            || ending.endDate === null
            || !isValid(ending.endDate)
            || !isValidFutureDate(ending.endDate)
            || startOfDay(ending.minimumEndDate) <= ending.endDate;
    },
    error: new ValidationError(`End date can't be before the first occurrence.`, ['endDate'])
}, {
    name: 'number of occurrences greater than zero',
    condition: (ending: RepetitionEnding) => {
        return ending.endType !== RepetitionEndType.AfterOccurrences || ending.numOccurrences > 0;
    },
    error: new ValidationError('The number of occurrences must be greater than zero.', ['numOccurrences'])
}];

export function validateRepetitionEnding(ending: RepetitionEnding) {
    return getValidationErrors(repetitionEndingChecks, ending);
}