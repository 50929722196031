import { override } from "mobx";
import { APIService } from "../../../../shared/general/services/APIService";
import { APIGetRequestParameters, IAPIGetRequestParameters } from "../../../../stores/models/APIGetRequestParameters";
import { IServiceEntry, ServiceEntry } from "../entities/ServiceEntry";
import { IServerServiceEntry } from "../../../../stores/models/server/ServerServiceEntry";
import { IGetRecordsAPIResponse } from "../../../../shared/modules/record-collection/data-access/interfaces/RecordListAPIResponse";
import { getSupplementalDataRequest } from "../../../../stores/models/SupplementalOrganizationData";
import { IAppError } from "../../../../stores/models/AppError";
import { IServiceEntryTag } from "../../../../stores/models/ServiceEntryTag";
import { getCategoryListRequest } from "../../../../stores/models/CategoryLists";
import { IServiceEntryBulkEdit } from "../interfaces/ServiceEntryBulkEdit";
import { ServiceEntryIdentification } from "../interfaces";
import { VolunteerHoursRequestTypes } from "../entities/VolunteerHoursRequestTypes";

export class VolunteerHoursServices extends APIService<VolunteerHoursRequestTypes> {

    /*************** Get service history for the current volunteer ***************/

    getVolunteerServiceHistory = async (parameters: IAPIGetRequestParameters<keyof IServiceEntry>) => {
        const serializedParameters = new APIGetRequestParameters(parameters).serialize();
        const response = await this.makeRequest<IGetRecordsAPIResponse<IServerServiceEntry>>(
            VolunteerHoursRequestTypes.GetVolunteerServiceData,
            {
                categoryLists: getCategoryListRequest(['serviceEntryStatuses']),
                ...serializedParameters
            }
        );
        return response;
    };

    /*************** Get the service data for a particular volunteer at the current organization ***************/

    async getServiceEntryDataForOrganizationVolunteer(volunteerId: number, parameters: IAPIGetRequestParameters<keyof IServiceEntry>) {
        const serializedParameters = new APIGetRequestParameters(parameters).serialize();

        const response = await this.makeRequest<IGetRecordsAPIResponse<IServerServiceEntry>>(
            VolunteerHoursRequestTypes.GetOrganizationVolunteerServiceData,
            {
                volunteerId: volunteerId,
                ...serializedParameters
            }
        );
        return response;
    };

    /*************** Get the service data for the current organization ***************/

    getServiceEntryDataForOrganization = async (parameters: IAPIGetRequestParameters<keyof IServiceEntry>) => {
        const supplementalData = getSupplementalDataRequest(
            ['volunteers', 'serviceEntryStatuses', 'serviceEntryTags', 'roles']
        );

        const serializedParameters = new APIGetRequestParameters(parameters).serialize();

        const response = await this.makeRequest<{ serviceEntries: IServerServiceEntry[], total: number }>(
            VolunteerHoursRequestTypes.GetOrganizationServiceData,
            { supplementalData: supplementalData, ...serializedParameters }
        );
        if (!response) return;
        return {
            ...response,
            results: response.serviceEntries // TODO: Eventually update this property from 'serviceEntries' to 'results' on the API
        };
    };

    /*************** Get all of the organization's tags ***************/

    async getAllTagsForOrganization() {
        await this.makeRequest<{ serviceEntryTags: IServiceEntryTag[], total: number }>(
            VolunteerHoursRequestTypes.GetTags
        );
        // Applying data is handled by ResponseMiddleware, at least at the moment
    };

    /*************** Update a service entry ***************/

    async upsertServiceEntries(serviceEntry: ServiceEntry) {
        const response = await this.makeRequest<IServerServiceEntry[] | { error: IAppError }>(
            VolunteerHoursRequestTypes.UpsertServiceEntries,
            {
                id: serviceEntry.id,
                organizationId: serviceEntry.organizationId,
                opportunityId: serviceEntry.opportunityId,
                volunteerIds: serviceEntry.volunteerIds,
                shiftId: serviceEntry.shiftId,
                defaultDaysFromStartDate: serviceEntry.defaultDaysFromStartDate,
                statusId: serviceEntry.statusId,
                notes: serviceEntry.notes,
                tags: serviceEntry.tags,
                updatedDate: serviceEntry.date,
                updatedDuration: serviceEntry.duration,
                lastUpdateId: serviceEntry.lastUpdateId,
                archived: serviceEntry.archived
            }
        );
        if (response && 'error' in response) {
            throw response.error;
        }
        return response;
    };

    /*************** Update multiple service entries at once ***************/

    async bulkEdit(recordIds: ServiceEntryIdentification[], update: IServiceEntryBulkEdit) {
        const response = await this.makeRequest<IServerServiceEntry[]>(
            VolunteerHoursRequestTypes.BulkEdit,
            {
                serviceEntries: recordIds,
                update: update
            }
        );
        return response;
    }

    /*************** Request Types ***************/

    @override get getRequests() {
        return [
            VolunteerHoursRequestTypes.GetTags,
            VolunteerHoursRequestTypes.GetOrganizationVolunteerServiceData,
            VolunteerHoursRequestTypes.GetOrganizationServiceData,
            VolunteerHoursRequestTypes.GetVolunteerServiceData
        ];
    }

    @override get putRequests() {
        return [
            VolunteerHoursRequestTypes.UpsertServiceEntries,
            VolunteerHoursRequestTypes.BulkEdit
        ];
    }

    @override get requestURLs() {
        const baseURL = '/api/service-data'
        return {
            [VolunteerHoursRequestTypes.GetTags]: `${baseURL}/get-service-entry-tags`,
            [VolunteerHoursRequestTypes.GetOrganizationVolunteerServiceData]: (data: { volunteerId: number }) => `${baseURL}/organization-volunteer/${data.volunteerId}`,
            [VolunteerHoursRequestTypes.GetOrganizationServiceData]: `${baseURL}/organization`,
            [VolunteerHoursRequestTypes.GetVolunteerServiceData]: `${baseURL}/volunteer`,
            [VolunteerHoursRequestTypes.UpsertServiceEntries]: `${baseURL}/service-entry`,
            [VolunteerHoursRequestTypes.BulkEdit]: `${baseURL}/bulk-edit`,
        };
    }
}