import { makeStyles, Theme, createStyles, useMediaQuery } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Delete } from "mdi-material-ui";
import { ServiceEntry } from "../data-access/entities/ServiceEntry";
import { Option } from "../../../stores/models/Option";
import { ServiceEntryTag } from "../../../stores/models/ServiceEntryTag";
import { IStatus } from "../../../stores/models/Status";
import MdiIcon from "../../../components/Shared/MdiIcon";
import { mdiClockEditOutline } from "@mdi/js";
import { ServiceDataViewModel } from "../data-access/view-models/ServiceDataViewModel";
import { IServiceEntryBulkEdit } from "../data-access/interfaces/ServiceEntryBulkEdit";
import { RecordTableViewModel } from "../../../shared/modules/record-collection/data-access/view-models/RecordTableViewModel";
import { useRootStore } from "../../../shared/general/hooks";
import AddTagsPopper from "./AddTagsPopper";
import UpdateStatusPopper from "../../../components/AccountSettings/Pages/Organization/UpdateStatusPopper";
import TableToolbar from "../../../components/Shared/Table/TableToolbar";
import { Dictionary } from "../../../logic/Dictionaries";
import { DialogState } from "../../../stores/models/DialogState";
import { ToggleButtonState } from "../../../shared/modules/buttons/entities/ToggleButtonState";
import { VolunteerHoursViewModeOptions } from "../data-access/entities/VolunteerHoursViewModes";
import StatefulToggleButtonGroup from "../../../shared/modules/buttons/components/StatefulToggleButtonGroup";
import REMButton from "../../../shared/modules/rem-conversion/components/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectionMenu: {
            '& > button': {
                color: theme.palette.action.active,
                flexShrink: 0,
                margin: theme.spacing(.5)
            },
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(0, 1, .5, -.5)
        },
        button: {
            marginRight: theme.spacing(1),
            textWrap: 'nowrap'
        }
    }),
);

/*** Enums ***/

export enum PopperTypes {
    TagPopper = 'tagPopper',
    StatusPopper = 'statusPopper',
}

/*** Component ***/

interface ServiceDataTableProps {
    viewModel: ServiceDataViewModel;
    viewModeState: ToggleButtonState<VolunteerHoursViewModeOptions>;
    popperStates: Dictionary<PopperTypes, DialogState>;
    onNewServiceEntryClicked: () => void;
    onServiceEntryClicked: (record: Option<ServiceEntry>, rowIndex: number) => void;
    onBulkDeleteClicked: () => void;
    onBulkEdit: (edit: IServiceEntryBulkEdit) => void;
}

const VolunteerHoursToolbar = observer((props: ServiceDataTableProps) => {

    const classes = useStyles();
    const { userStore, volunteerHoursStore } = useRootStore();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const shortenButtonText = useMediaQuery((theme: Theme) => theme.breakpoints.down(340));

    const isVolunteer = userStore.user.isVolunteer;
    const viewModel = props.viewModel;

    const isTableView = props.viewModeState.selectedButton === VolunteerHoursViewModeOptions.TableViewMode;
    const recordsSelected = viewModel.collection.selections.length > 0;

    /*** State ***/

    const [tableViewModel] = useState(new RecordTableViewModel(volunteerHoursStore));

    /*** Effects ***/

    useEffect(() => {
        const noEntriesSelected = viewModel.collection.selections.length === 0;
        if (noEntriesSelected) {
            closePoppers();
        }
    }, [viewModel.collection.selections.length]);

    const closePoppers = () => {
        props.popperStates.tagPopper.setOpen(false);
        props.popperStates.statusPopper.setOpen(false);
    }

    /********* Bulk Edit *********/

    const onAddTags = (tagsToAdd: ServiceEntryTag[]) => {
        props.onBulkEdit({ tags: tagsToAdd });
    }

    const onStatusUpdate = (status: IStatus) => {
        if (status) {
            props.onBulkEdit({ statusId: status.id });
        }
    }

    /********* Render *********/

    return (
        <Fragment>
            {(isTableView && recordsSelected)
                ? <TableToolbar
                    leftButtons={
                        <div className={classes.selectionMenu}>
                            <REMButton
                                startIcon={<Delete />}
                                variant="outlined"
                                size={smDown ? 'medium' : 'large'}
                                onClick={props.onBulkDeleteClicked}
                            >
                                Delete
                            </REMButton>
                            {!isVolunteer &&
                                <Fragment>
                                    <AddTagsPopper
                                        popperState={props.popperStates.tagPopper}
                                        onConfirm={onAddTags}
                                        size={smDown ? 'medium' : 'large'}
                                    />
                                    <UpdateStatusPopper
                                        statuses={volunteerHoursStore.serviceEntryStatuses}
                                        popperState={props.popperStates.statusPopper}
                                        onConfirm={onStatusUpdate}
                                        size={smDown ? 'medium' : 'large'}
                                    />
                                </Fragment>
                            }
                        </div>
                    }
                    SelectionIndicatorProps={{
                        numSelected: viewModel.numSelectedIncludingUnloaded,
                        onClearSelections: () => tableViewModel.clearSelections()
                    }}
                />
                : <TableToolbar
                    leftButtons={
                        <REMButton
                            startIcon={<MdiIcon iconPath={mdiClockEditOutline} />}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={props.onNewServiceEntryClicked}
                            className={classes.button}
                        >
                            {shortenButtonText ? 'Log' : smDown ? 'Log Hours' : 'Log Volunteer Hours'}
                        </REMButton>
                    }
                    rightButtons={
                        <StatefulToggleButtonGroup
                            state={props.viewModeState}
                        />
                    }
                />
            }
        </Fragment >
    );
});

export default VolunteerHoursToolbar;