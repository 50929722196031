import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";
import { ShiftDetails } from "../../stores/models/ShiftDetails";

const shiftDetailsChecks: ValidationCheck<ShiftDetails>[] = [{
    name: 'opportunity id is valid',
    condition: (shiftDetails: ShiftDetails) => {
        return shiftDetails.opportunityId > 0;
    },
    error: new ValidationError('Please select an opportunity.', ['opportunityId'])
}];

export function validateShiftDetails(shiftDetails: ShiftDetails) {
    return getValidationErrors(shiftDetailsChecks, shiftDetails);
}