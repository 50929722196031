import { Alert, AlertProps, AlertTitle } from "@material-ui/lab";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { ErrorDisplay } from "../../../../data/ErrorCodes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            marginBottom: theme.spacing(2),
        }
    })
);

interface ErrorAlertProps extends AlertProps {
    error: ErrorDisplay;
}

const ErrorAlert = (props: ErrorAlertProps) => {

    const classes = useStyles();

    return (
        <Alert severity="error" {...props} className={clsx(classes.alert, props.className)}>
            {props.error.title ? <AlertTitle>{props.error.title}</AlertTitle> : null}
            {props.error.message}
        </Alert>
    );
}

export default ErrorAlert;