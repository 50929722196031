import { useState } from 'react';

export const DEFAULT_BROWSER_FONT_SIZE_PX = 16; //px

export default function useBrowserFontSize() {

    const getDefaultFontSize = () => {
        const element = document.createElement('div');
        element.style.width = '1rem';
        element.style.display = 'none';
        document.body.append(element);
    
        const widthMatch = window
            .getComputedStyle(element)
            .getPropertyValue('width')
            .match(/\d+/);
    
        element.remove();
    
        if (!widthMatch || widthMatch.length < 1) {
            return null;
        }
    
        const result = Number(widthMatch[0]);
        return !isNaN(result) ? result : null;
    };

    const [fontSize] = useState(getDefaultFontSize())

    return fontSize;
}