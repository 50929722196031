import { Address } from "../Address";

export interface IServerAddress {
    id: number;
    lineOne: string;
    lineTwo?: string;
    city: string;
    state: string;
    zipCode: number;
    name?: string;
    wheelchairAccessible: boolean;
    publicTransportation: boolean;
}

export class ServerAddress implements IServerAddress {
    id: number;
    lineOne: string;
    lineTwo?: string;
    city: string;
    state: string;
    zipCode: number;
    name?: string;
    wheelchairAccessible: boolean;
    publicTransportation: boolean;

    constructor(clientOrServerObject: Address | IServerAddress) {
        this.id = clientOrServerObject.id;
        this.lineOne = clientOrServerObject.lineOne;
        this.lineTwo = clientOrServerObject.lineTwo;
        this.city = clientOrServerObject.city;
        this.state = clientOrServerObject.state;
        this.zipCode = typeof clientOrServerObject.zipCode === 'string' ? parseInt(clientOrServerObject.zipCode) : clientOrServerObject.zipCode;
        this.name = clientOrServerObject.name;
        this.wheelchairAccessible = clientOrServerObject.wheelchairAccessible;
        this.publicTransportation = clientOrServerObject.publicTransportation;
    }

    deserialize(): Address {
        return new Address(this);
    }
}