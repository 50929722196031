import { RadioListOption } from "./RadioList";
import { LinkOff, Ring, CalendarClock, Autorenew, CalendarMultiselect, Gesture, MapMarker, Airballoon, Domain, ClipboardTextOutline, Lock, AccountMultiple } from "mdi-material-ui";
import { OpportunityNeed, TimeCommitment, OpportunityScheduling, OpportunityLocation, OpportunityPermissionType } from "../../../stores/models/Opportunity";

// Options

export const needOptions: RadioListOption[] = [{
    primaryText: OpportunityNeed.OneTime,
    secondaryText: 'This is a temporary need for a single event or one-off task.',
    value: OpportunityNeed.OneTime,
    icon: CalendarClock
}, {
    primaryText: OpportunityNeed.Ongoing,
    secondaryText: 'This type of volunteer help is needed on a recurring basis.',
    value: OpportunityNeed.Ongoing,
    icon: Autorenew
}];

export const commitmentOptions: RadioListOption[] = [{
    primaryText: TimeCommitment.ShortTerm,
    secondaryText: 'The volunteer only needs to be able to commit to one day at a time. Another volunteer could easily pick up where they left off.',
    value: TimeCommitment.ShortTerm,
    icon: LinkOff
}, {
    primaryText: TimeCommitment.LongTerm,
    secondaryText: 'Consistency is key. You need the same volunteer to fill the position for a length of time.',
    value: TimeCommitment.LongTerm,
    icon: Ring
}];

export const locationOptions: RadioListOption[] = [{
    primaryText: OpportunityLocation.Remote,
    secondaryText: 'The individual can volunteer from anywhere.',
    value: OpportunityLocation.Remote,
    icon: Airballoon
}, {
    primaryText: OpportunityLocation.InPerson,
    secondaryText: 'The individual must travel to a specific location to volunteer.',
    value: OpportunityLocation.InPerson,
    icon: MapMarker
}];

export const schedulingOptions: RadioListOption[] = [{
    primaryText: OpportunityScheduling.Flexible,
    secondaryText: "Volunteers set their own schedules.",
    value: OpportunityScheduling.Flexible,
    icon: Gesture
}, {
    primaryText: OpportunityScheduling.Shifts,
    secondaryText: 'Volunteers sign up for particular timeslots.',
    value: OpportunityScheduling.Shifts,
    icon: CalendarMultiselect
}];

export const permissionLevelOptions: RadioListOption[] = [{
    primaryText: OpportunityPermissionType.Open,
    secondaryText: 'Anyone can sign up for this opportunity.',
    value: OpportunityPermissionType.Open,
    icon: AccountMultiple
}, {
    primaryText: OpportunityPermissionType.Organization,
    secondaryText: 'Any volunteer with organization level permission can sign up for this opportunity.',
    value: OpportunityPermissionType.Organization,
    icon: Domain
}, {
    primaryText: OpportunityPermissionType.Opportunity,
    secondaryText: 'Only volunteers with specific permission for this opportunity can sign up.',
    value: OpportunityPermissionType.Opportunity,
    icon: ClipboardTextOutline
}, {
    primaryText: OpportunityPermissionType.AssignmentOnly,
    secondaryText: 'Volunteers cannot self-register. Instead they are assigned to shifts.',
    value: OpportunityPermissionType.AssignmentOnly,
    icon: Lock
}];