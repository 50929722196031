export const VALIDATION_ERROR_CODE = 1;
export const INVALID_TOKEN_ERROR_CODE = 4000;
export const USED_TOKEN_ERROR_CODE = 4010;
export const EXPIRED_TOKEN_ERROR_CODE = 4020;
export const INVALID_CODE_ERROR_CODE = 5000;
export const USED_CODE_ERROR_CODE = 5010;
export const EXPIRED_CODE_ERROR_CODE = 5020;

export enum ErrorTypes {
    ValidationError = 1,
    RecordOutOfDate = 7000
}

export type ErrorDisplay = { code: number, title?: string, message: string };

export const isErrorDisplay = (x: any): x is ErrorDisplay => {
    if (typeof x === 'object') {
        return (x as { code: number }).code !== undefined
            && (typeof x.code === 'number')
            && (x as { message: string }).message !== undefined
            && (typeof x.message === 'string');
    } else {
        return false;
    }
}

export const ErrorCodes = new Map<ErrorTypes, ErrorDisplay>([
    [ErrorTypes.ValidationError, { code: ErrorTypes.ValidationError, title: 'Oops! There was an error with your submission.', message: 'Please scroll down to make corrections.' }],
    [ErrorTypes.RecordOutOfDate, { code: ErrorTypes.RecordOutOfDate, title: 'Record is out of date.', message: 'Please refresh the page to get the latest updates to this record before making updates.' }]
]);