import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import { useState } from "react";
import { Address } from "../../stores/models/Address";
import LocationSelectionTable from "../Shared/Locations/LocationSelectionTable";
import AddressEditor from "../Shared/AddressEditor";
import { DialogState } from "../../stores/models/DialogState";
import { observer } from "mobx-react";
import { NumericallyIdentifiedOptionCollection } from "../../stores/models/OptionCollection";
import { MapMarkerPlus } from "mdi-material-ui";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
        }
    })
);

interface LocationEditorProps {
    addressOptions: NumericallyIdentifiedOptionCollection<Address>;
    onAddressConfirmed?: (address: Address) => void;
    addressRemovable: boolean | ((address: Address) => boolean);
    onRemoveAddressConfirmed?: (address: Address) => void;
    hideCheckboxColumn?: boolean;
    keepVisibleOnNoResults?: boolean;
}

const LocationEditor = observer((props: LocationEditorProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* State *********/

    const [dialogState] = useState(new DialogState());
    const [selectedAddress, setSelectedAddress] = useState(new Address());

    /********* Event handlers *********/

    const onAddButtonClicked = () => {
        setSelectedAddress(new Address());
        dialogState.setOpen(true);
    }

    const onEditButtonClicked = (address: Address) => {
        setSelectedAddress(address);
        dialogState.setOpen(true);
    }

    const handleConfirmAddress = async (address: Address) => {
        if (props.onAddressConfirmed) {
            dialogState.setLoading(true);
            await props.onAddressConfirmed(address);
            dialogState.setLoading(false);
        } else {
            props.addressOptions.addOrUpdateOption(address, true);
        }
        dialogState.setOpen(false);
    };

    const handleRemoveAddress = async (address: Address) => {
        if (props.onRemoveAddressConfirmed) {
            dialogState.setLoading(true);
            await props.onRemoveAddressConfirmed(address);
            dialogState.setLoading(false);
        } else {
            props.addressOptions.removeOption(address);
        }
        dialogState.setOpen(false);
    };

    /********* Render *********/

    return (
        <div>
            <Button
                className={classes.button}
                onClick={onAddButtonClicked}
                variant="contained"
                color="primary"
                size='large'
                startIcon={<MapMarkerPlus />}
            >
                New Location
            </Button>
            {(props.addressOptions.options.length > 0 || props.keepVisibleOnNoResults)
                &&
                <LocationSelectionTable
                    locationOptions={props.addressOptions}
                    onEditButtonClicked={onEditButtonClicked}
                    hideCheckboxColumn={props.hideCheckboxColumn}
                />
            }
            <AddressEditor
                state={dialogState}
                address={selectedAddress}
                addressRemovable={typeof props.addressRemovable === 'function'
                    ? props.addressRemovable(selectedAddress)
                    : props.addressRemovable}
                onAddressUpdated={handleConfirmAddress}
                onAddressRemoved={handleRemoveAddress}
            />
        </div>
    );
});

export default LocationEditor;