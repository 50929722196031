import { makeStyles, Theme, createStyles, Typography, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../../../../Shared/Dialogs/ThemedDialogWithSpinner";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getSubscriptionLink } from "../../../../Navigation/Links/UrlConstructors";
import { DialogState } from "../../../../../stores/models/DialogState";
import { useNavigateInternally } from "../../../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        },
        alert: {
            marginBottom: theme.spacing(2)
        }
    }));

interface InactivationWarningDialogProps {
    state: DialogState;
    title: string;
    details: string;
}

const InactivationWarningDialog = observer((props: InactivationWarningDialogProps) => {

    /********* React hooks *********/
    const classes = useStyles();
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Helper methods *********/

    const navigateToSubscriptionDetails = () => {
        navigate(getSubscriptionLink());
        props.state.setOpen(false);
    }

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            title="Uh Oh!"
            primaryButtonProps={{ children: 'View Subscription' }}
            onSubmit={navigateToSubscriptionDetails}
            DialogProps={{ fullScreen: xsDown }}
        >
            <Alert severity="error" className={classes.alert}>
                <AlertTitle>{props.title}</AlertTitle>
                {`Your opportunity has been saved, but `}
                <span className={classes.bold}>it has been marked as inactive.</span>
            </Alert>
            <Typography>
                {props.details}
            </Typography>
        </ThemedDialogWithSpinner>
    );
});

export default InactivationWarningDialog;