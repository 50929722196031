import { makeStyles, Theme, createStyles, Box } from "@material-ui/core";
import { observer } from "mobx-react";
import { ResponsivePie } from '@nivo/pie';
import { roundToNumDecimalPlaces } from "../../../../../logic/UtilityFunctions";
import { useStandardStyles } from "../../../../../shared/general/hooks/useStandardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
        legendDot: {
            borderRadius: '10px',
            width: '20px',
            height: '20px',
            marginRight: '4px'
        },
        legendItem: {
            margin: theme.spacing(0.5, 1),
        },
        legendLabel: {
            fontSize: '0.6875rem',
            fontFamily: 'sans-serif',
            dominantBaseline: 'central',
            pointerEvents: 'none',
            color: 'rgb(51, 51, 51)'
        }
    }),
);

const CenteredMetric = ({ dataWithArc, centerX, centerY }: { dataWithArc: readonly { value: number }[], centerX: number, centerY: number }) => {
    let total = 0
    dataWithArc.forEach(datum => {
        total += datum.value
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            fill='black'
            style={{
                fontSize: '24px',
            }}
        >
            {total.toFixed(1)}
        </text>
    )
}

type DonutGraphFieldMapping<InputData> = {
    id: keyof InputData;
    label: keyof InputData;
    value: keyof InputData;
    colorPosition?: keyof InputData;
};

interface DonutGraphWidgetProps<InputData> {
    data: InputData[];
    fieldMapping: DonutGraphFieldMapping<InputData>;
    colors: string[];
}

const DonutGraphWidget = observer(<InputData extends object>(props: DonutGraphWidgetProps<InputData>) => {

    const classes = useStyles();
    const baseClasses = useStandardStyles();

    const getMappedData = () => {
        return props.data.map(datum => {
            let mappedDatum = {} as { id: string, label: string, value: number, color: string };
            ((Object.keys(props.fieldMapping)) as unknown as (keyof DonutGraphFieldMapping<InputData>)[]).forEach(field => {
                const fieldFromInputData = props.fieldMapping[field]!;
                const mappedField = datum[fieldFromInputData];
                if (field === 'colorPosition') {
                    const colorPosition = mappedField;
                    if (typeof colorPosition === 'number') {
                        mappedDatum.color = getColor(colorPosition);
                    }
                } else if ((field === 'id' || field === 'label') && typeof mappedField === 'string') {
                    mappedDatum[field] = mappedField;
                } else if (field === 'value' && typeof mappedField === 'number') {
                    mappedDatum[field] = mappedField;
                }
            });
            return mappedDatum;
        });
    }

    const getColor = (colorPosition: number) => {
        const adjustedColorPosition = colorPosition % props.colors.length;
        return props.colors[adjustedColorPosition];
    }

    const mappedData = getMappedData();

    /********* Render *********/

    return (
        <div className={classes.root}>
            <Box style={{ width: '100%', height: 250 }}>
                <ResponsivePie
                    data={mappedData}
                    value={(datum) => { return roundToNumDecimalPlaces(datum.value, 1) }}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    innerRadius={0.6}
                    padAngle={2.5}
                    cornerRadius={3}
                    colors={({ id, data }) => data.color}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}
                    layers={['arcs', 'arcLabels', CenteredMetric]}
                />
            </Box>
            {/* Legend */}
            <div className={clsx(baseClasses.flex, baseClasses.flexWrap, baseClasses.justifyCenter)}>
                {mappedData.map(datum => {
                    return (
                        <div className={clsx(baseClasses.flex, classes.legendItem, baseClasses.alignCenter, baseClasses.overflowHidden)}>
                            <div className={clsx(baseClasses.noShrink, classes.legendDot)} style={{ backgroundColor: datum.color }}></div>
                            <div className={clsx(baseClasses.truncateText, classes.legendLabel)}>{datum.label}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
});

export default DonutGraphWidget;