import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { Fragment, useContext } from 'react';
import { RootContext } from '../../../stores';
import PopperMenu from '../../../shared/general/components/PopperMenu';
import { Delete, Pencil } from 'mdi-material-ui';
import { ServiceDataViewModel } from '../data-access/view-models/ServiceDataViewModel';
import { ServiceEntry } from "../data-access/entities/ServiceEntry";
import { Option } from "../../../stores/models/Option";
import ServiceEntryCard from './ServiceEntryCard';
import ErrorMessage from '../../../components/Shared/ErrorMessage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.action.active,
            marginRight: theme.spacing(1)
        },
    })
);

interface ServiceEntryCardsProps {
    model: ServiceDataViewModel;
    onEntryEdit: (entry: Option<ServiceEntry>) => void;
    onEntryDelete: (entry: Option<ServiceEntry>) => void;
}

const ServiceEntryCards = observer((props: ServiceEntryCardsProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const { userStore } = useContext(RootContext);
    const isVolunteer = userStore.user.isVolunteer;

    /********* Helper constants *********/

    const volunteerMenuItems = (entry: Option<ServiceEntry>) => [{
        label: (
            <Fragment>
                <Pencil className={classes.icon} />
                <Typography>Edit</Typography>
            </Fragment>
        ),
        onClick: () => { props.onEntryEdit(entry); }
    }, {
        label: (
            <Fragment>
                <Delete className={classes.icon} />
                <Typography>Delete</Typography>
            </Fragment>
        ),
        onClick: () => { props.onEntryDelete(entry); }
    }];

    // TODO: Eventually add Select as a menu option
    const organizationMenuItems = (entry: Option<ServiceEntry>) => [{
        label: (
            <Fragment>
                <Pencil className={classes.icon} />
                <Typography>Edit</Typography>
            </Fragment>
        ),
        onClick: () => { props.onEntryEdit(entry); }
    }, {
        label: (
            <Fragment>
                <Delete className={classes.icon} />
                <Typography>Delete</Typography>
            </Fragment>
        ),
        onClick: () => { props.onEntryDelete(entry); }
    }];

    /********* Render *********/

    return (
        <div>
            {props.model.records.length > 0
                ? props.model.records.map((serviceEntry) => {
                    let displayableServiceEntry = props.model.getDisplayableServiceEntry(serviceEntry.object);
                    return (
                        <ServiceEntryCard
                            serviceEntry={displayableServiceEntry}
                            contextMenu={(menuProps) => (
                                <PopperMenu
                                    {...menuProps}
                                    menuItems={
                                        isVolunteer
                                            ? volunteerMenuItems(serviceEntry)
                                            : organizationMenuItems(serviceEntry)
                                    }
                                    placement='bottom-end'
                                />
                            )}
                            onClick={() => props.onEntryEdit(serviceEntry)}
                        />
                    )
                })
                : <ErrorMessage
                    title="No results"
                    details="Log volunteer hours to view them here."
                />
            }
        </div>
    );
});

export default ServiceEntryCards;