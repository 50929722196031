import { useContext, useEffect, useState } from 'react';
import { RootContext, Volunteer } from '../../../../stores';

export default function useRegisterableVolunteers(organizationId?: number) {

    const rootStore = useContext(RootContext);
    const { userStore, supplementalDataStore } = rootStore;
    const user = userStore.user;

    const [volunteers, setVolunteers] = useState<Volunteer[]>([]);

    useEffect(() => {
        refreshRegisterableVolunteers();
    }, [organizationId, user.id]);

    // API call to load proxied volunteers for current user/organization
    const fetchProxiedVolunteers = async () => {
        if (organizationId === undefined || !user.isVolunteer) return;
        const proxiedVolunteers = await supplementalDataStore.getSupplementalDataForProxiedVolunteers(organizationId);
        return proxiedVolunteers;
    }

    const refreshRegisterableVolunteers = async () => {
        if (!user.volunteer) return;
        const proxiedVolunteers = await fetchProxiedVolunteers();
        let allVolunteers = [user.volunteer];
        if (proxiedVolunteers !== undefined) {
            allVolunteers = allVolunteers.concat(proxiedVolunteers);
        }
        setVolunteers(allVolunteers);
    }

    return volunteers;
}