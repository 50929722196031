import { observable, computed, makeObservable } from "mobx";
import { format } from "date-fns";

export interface ICreditCard {
    brand: string;
    expMonth: number;
    expYear: number;
    last4: string;
}

export class CreditCard implements ICreditCard {
    brand: string;
    expMonth: number;
    expYear: number;
    last4: string;

    constructor(card: ICreditCard) {
        makeObservable(this, {
            brand: observable,
            expMonth: observable,
            expYear: observable,
            last4: observable,
            cardExpiration: computed
        });

        this.brand = card.brand;
        this.expMonth = card.expMonth;
        this.expYear = card.expYear;
        this.last4 = card.last4;
    }

    get cardExpiration() {
        return format(new Date(this.expYear, this.expMonth - 1), "MM/yy");
    }
}