import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList } from "@material-ui/core";
import { MenuState } from '../../../stores/models/MenuState';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: theme.zIndex.appBar - 1
        }
    })
);

interface IMenuItem {
    label: string | JSX.Element;
    onClick: () => void;
}

type PopperPlacement = 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

interface PopperMenuProps {
    menuItems: IMenuItem[];
    state: MenuState;
    anchor: null | React.RefObject<HTMLButtonElement>;
    placement?: PopperPlacement;
}

const PopperMenu = observer((props: PopperMenuProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    const onClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, menuItem: IMenuItem) => {
        event.stopPropagation();
        props.state.setOpen(false);
        menuItem.onClick();
    }

    /********* Event handlers *********/

    const onEditMenuClose = (event: React.MouseEvent<Document | HTMLLIElement, MouseEvent>) => {
        if (props.anchor && props.anchor.current && props.anchor.current.contains(event.target as Node)) {
            return;
        }

        props.state.setOpen(false);
    };

    const onListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            props.state.setOpen(false);
        }
    }

    /********* Render *********/

    return (
        <Popper
            open={props.state.open}
            anchorEl={props.anchor?.current}
            role={undefined}
            transition
            disablePortal
            className={classes.popper}
            placement={props.placement}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={onEditMenuClose}>
                            <MenuList autoFocusItem={props.state.open} id="menu-list-grow" onKeyDown={onListKeyDown}>
                                {props.menuItems.map((menuItem, index) => {
                                    return (
                                        <MenuItem key={'context-menu-item-' + index} onClick={(event) => onClick(event, menuItem)}>
                                            {menuItem.label}
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
});

export default PopperMenu;