import { makeAutoObservable } from "mobx";

/*** Option Object ***/

export interface IOptionObject {
    value: string;
}

export interface INumericalIdOptionObject {
    id: number;
}

/*** Option ***/

export interface IOption<T> {
    selected: boolean;
    object: T;
}

export class Option<T = {}> implements IOption<T> {
    selected: boolean;
    object: T;

    constructor(object: T, selected: boolean) {
        makeAutoObservable(this);

        this.object = object;
        this.selected = selected;
    }

    toggleSelection() {
        this.selected = !this.selected;
    }
}