import { observable, makeAutoObservable, computed, action, reaction } from "mobx";
import { IDisposer, deepObserve } from "mobx-utils";
import { IdentifiableObject, ObjectContainingIdentifier, OptionCollection } from "./OptionCollection";
import { TableState } from "./TableState";
import { Option } from "./Option";
import { IAPIGetRequestParameters } from "./APIGetRequestParameters";

export interface ITableController<
    IdentityKey extends string,
    Record extends IdentifiableObject<IdentityKey>,
> {
    tableState: TableState<Extract<keyof Record, string>>;
    records: OptionCollection<IdentityKey, Record>;
}

type RecordId<IdentityKey extends string, Record extends IdentifiableObject<IdentityKey>> = ReturnType<(object: Record, idKey: IdentityKey) => Record[IdentityKey]>;
type RecordIds<IdentityKey extends string, Record extends IdentifiableObject<IdentityKey>> = ReturnType<(object: Record, idKey: IdentityKey) => Record[IdentityKey][]>;

type LoadRecordsMethod<Record> = (request: IAPIGetRequestParameters<keyof Record>) => Promise<{ total: number, results: Record[] } | undefined>;

type AddRecordMethod<Record, StubRecord> = (newRecord: StubRecord) => Promise<Record>;

type DeleteRecordsMethod<IdentityKey extends string, Record extends IdentifiableObject<IdentityKey>> = (ids: RecordIds<IdentityKey, Record>) => Promise<{ success: boolean }>;

type MergeRecordsMethod<
    IdentityKey extends string,
    Record extends IdentifiableObject<IdentityKey>,
    MergedRecord extends object = Record
> = (
    id1: RecordId<IdentityKey, Record>,
    id2: RecordId<IdentityKey, Record>,
    mergedRecord: MergedRecord
) => Promise<Record | undefined>;

type TableControllerProps<
    IdentityKey extends string,
    Record extends IdentifiableObject<IdentityKey> = ObjectContainingIdentifier<IdentityKey>,
    StubRecord extends IdentifiableObject<IdentityKey> = Record,
    MergedRecord extends object = Record
> = Omit<ITableController<IdentityKey, Record>, 'tableState'> & {
    loadRecords?: LoadRecordsMethod<Record>;
    addRecord?: AddRecordMethod<Record, StubRecord>;
    deleteRecords?: DeleteRecordsMethod<IdentityKey, Record>;
    mergeRecords?: MergeRecordsMethod<IdentityKey, Record, MergedRecord>;
}

export class TableController<
    IdentityKey extends string,
    Record extends IdentifiableObject<IdentityKey> = ObjectContainingIdentifier<IdentityKey>,
    StubRecord extends IdentifiableObject<IdentityKey> = Record,
    MergedRecord extends object = Record
> implements ITableController<IdentityKey, Record> {

    @observable tableState: TableState<Extract<keyof Record, string>>;
    @observable records: OptionCollection<IdentityKey, Record>;
    @observable recordsToDisplay: Option<Record>[] = [];

    private loadRecords?: LoadRecordsMethod<Record>;
    private addRecord?: AddRecordMethod<Record, StubRecord>;
    private deleteRecords?: DeleteRecordsMethod<IdentityKey, Record>;
    private mergeRecords?: MergeRecordsMethod<IdentityKey, Record, MergedRecord>;

    private optionsChangedReaction?: IDisposer;
    private pageChangedReaction?: IDisposer;

    constructor(props: TableControllerProps<IdentityKey, Record, StubRecord, MergedRecord>) {
        makeAutoObservable(this);

        this.tableState = new TableState();
        this.records = props.records;
        this.loadRecords = props.loadRecords;
        this.addRecord = props.addRecord;
        this.deleteRecords = props.deleteRecords;
        this.mergeRecords = props.mergeRecords;

        this.setupReactions();

        if (!this.loadRecords) {
            this.tableState.setTotal(this.records.options.length);
            this.updateRecordsToDisplay();
        }
    }

    @action private setupReactions() {
        this.optionsChangedReaction = deepObserve(this.records, () => {
            this.updateRecordsToDisplay();
        });

        this.pageChangedReaction = reaction(
            () => [
                this.tableState.paginationState.page,
                this.tableState.paginationState.rowsPerPage
            ],
            () => {
                this.updateRecordsToDisplay();
            }
        );
    }

    @action private updateRecordsToDisplay() {
        const page = this.tableState.paginationState.page;
        const recordsPerPage = this.tableState.paginationState.rowsPerPage;
        this.recordsToDisplay = this.records.options.slice(page * recordsPerPage, page * recordsPerPage + recordsPerPage);
    }

    /*** Loading Records ***/

    @action async load(resetRecords?: boolean) {
        if (!this.loadRecords) return;

        this.tableState.setLoading(true);
        const loadRequest = this.tableState.loadRequest;
        if (resetRecords) {
            loadRequest.offset = 0;
        }
        const response = await this.loadRecords(loadRequest);
        if (resetRecords) {
            this.tableState.paginationState.setPage(0);
            this.records.clearOptions();
            this.tableState.setUnloadedSelected(false);
        }
        this.records.push(response && response.results ? response.results : [], this.tableState.unloadedSelected);
        this.tableState.setTotal(response ? response.total : 0);
        this.tableState.setLoading(false);
    }

    /*** Adding New Record ***/

    @action async add(stubRecord: StubRecord) {
        if (!this.addRecord) return;

        const response = await this.addRecord(stubRecord);
        if (response) {
            this.load(true);
        }
        return response;
    }

    /*** Merging Records ***/

    @action async mergeSelectedRecords(mergedRecord: MergedRecord) {
        if (this.records.selectedOptions.length !== 2 || this.mergeRecords === undefined) return;

        const id1 = this.records.selectedOptions[0][this.records.identifier];
        const id2 = this.records.selectedOptions[1][this.records.identifier];
        const response = await this.mergeRecords(id1, id2, mergedRecord);
        if (response) {
            this.removeSelectedRecords();
        }
        return response;
    }

    /*** Deleting Records ***/

    @action async deleteSelectedRecords() {
        if (!this.deleteRecords) return;

        const idsToDelete = this.records.selectedOptions.map(selectedOption => selectedOption[this.records.identifier]);
        const response = await this.deleteRecords(idsToDelete);
        if (response.success) {
            this.removeSelectedRecords();
        }
        return response;
    }

    private removeSelectedRecords() {
        // When there are records to remove that are positioned before the current viewing window, reset the page to page 0 
        // and reload. Otherwise, splice all records starting from the beginning of the current viewing window, and then reload 
        // maintaining the current viewing window, unless the removed record was the only record in the viewing window. In that 
        // case, move to the previous page.

        if (this.indexOfFirstSelectedOption === -1) return;
        if (this.indexOfFirstSelectedOption < this.tableState.paginationState.offset) {
            this.resetRecords();
        } else {
            this.removeRecordsFromCurrentWindowToEnd();
            this.load();
        }
    }

    @computed private get indexOfFirstSelectedOption() {
        return this.records.options.findIndex((option) => option.selected);
    }

    @action private resetRecords() {
        this.records.clearOptions();
        this.tableState.paginationState.setPage(0);
    }

    // Assumes that no selections have been made in the records prior to the current window
    @action private removeRecordsFromCurrentWindowToEnd() {
        const currentOffset = this.tableState.paginationState.offset;
        if (this.tableState.total !== undefined && currentOffset + this.numSelectedIncludingUnloaded === this.tableState.total) {
            const newPage = Math.max(this.tableState.paginationState.page - 1, 0);
            this.tableState.paginationState.setPage(newPage);
        }
        this.records.splice(currentOffset, this.records.options.length - currentOffset);
    }

    /*** ~ ***/

    @computed get numUnloadedRecords() {
        if (this.tableState.total === undefined) return;
        return this.tableState.total - this.records.options.length;
    }

    @computed get numSelectedIncludingUnloaded() {
        if (this.tableState.unloadedSelected) {
            const unloadedRecords = this.numUnloadedRecords || 0;
            return this.records.selectedOptions.length + unloadedRecords;
        } else {
            return this.records.selectedOptions.length;
        }
    }

    @computed private get selectionType(): 'exclude' | 'include' {
        return (this.tableState.unloadedSelected || this.records.selections.length === 0) ? 'exclude' : 'include';
    }

    @computed get selectionCriteria() {
        if (this.selectionType === 'exclude') {
            return {
                ids: this.tableState.unloadedSelected
                    ? this.records.options.filter(option => !option.selected).map(option => option.object[this.records.identifier])
                    : [],
                type: this.selectionType,
                filters: this.tableState.filters
            }
        } else {
            return {
                ids: this.records.selectedOptions.map(selection => selection[this.records.identifier]),
                type: this.selectionType
            }
        }
    }

}