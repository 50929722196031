import React, { useState, useEffect } from "react";
import PaymentEntry from "./PaymentEntry";
import ConfirmationDialog from "./ConfirmationDialog";
import { PaymentMethod } from "../../stores/models/PaymentMethod";
import { DialogState } from "../../stores/models/DialogState";
import { observer } from "mobx-react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            marginBottom: theme.spacing(2)
        }
    })
);

interface PaymentEntryDialogProps {
    state: DialogState;
    title: string;
    confirmText?: string;
    cancelText?: string;
    error?: string;
    onConfirm: (paymentMethod: PaymentMethod) => void;
}

const PaymentEntryDialog = observer((props: PaymentEntryDialogProps) => {

    /********* React hook *********/

    const classes = useStyles();

    /********* State *********/

    const [paymentMethod, setPaymentMethod] = useState(new PaymentMethod());
    const [displayErrors, setDisplayErrors] = useState(false);

    /********* Effects *********/

    useEffect(() => {
        if (props.state.open) {
            resetPaymentEntry();
        }
    }, [props.state.open]);

    /********* Event handlers *********/

    const handleConfirm = () => {
        setDisplayErrors(true);
        paymentMethod.setAllFieldsDirty();
        props.onConfirm(paymentMethod);
    }

    /********* Helper constants and methods *********/

    const resetPaymentEntry = () => {
        setPaymentMethod(new PaymentMethod());
        setDisplayErrors(false);
    }

    /********* Render *********/

    return (
        <ConfirmationDialog
            state={props.state}
            title={props.title}
            content={
                <div>
                    {props.error
                        ? <Alert severity="error" className={classes.alert}>
                            <AlertTitle>Card Error</AlertTitle>
                            <Typography>{props.error}</Typography>
                        </Alert>
                        : null
                    }
                    <PaymentEntry
                        paymentMethod={paymentMethod}
                        displayErrors={displayErrors}
                        isLoading={props.state.loading}
                    />
                </div>
            }
            confirmText={props.confirmText ? props.confirmText : 'Confirm'}
            cancelText={props.cancelText}
            onConfirm={handleConfirm}
        />
    )
});

export default PaymentEntryDialog;