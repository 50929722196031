import { observable, action, makeAutoObservable, computed } from "mobx";
import { OrderByPair, SortDirection } from "./OrderByPair";

export class TableSortingState<ColumnName extends string> {

    @observable sortConfiguration: OrderByPair<ColumnName>[] = []; 

    constructor() {
        makeAutoObservable(this);
    }

    @action updateSortConfiguration(column: ColumnName, direction?: SortDirection) {
        if (direction) {
            const indexOfColumnConfiguration = this.sortConfiguration.findIndex(configuration => configuration.column === column);
            const newConfiguration = new OrderByPair({ column: column, direction: direction });
            if (indexOfColumnConfiguration === -1) {
                this.insertNewFirstConfigurationEntry(newConfiguration);
            } else if (indexOfColumnConfiguration > 0) {
                this.sortConfiguration.splice(indexOfColumnConfiguration, 1); // Remove the existing configuration entry for the column
                this.insertNewFirstConfigurationEntry(newConfiguration);
            } else {
                this.sortConfiguration[indexOfColumnConfiguration] = newConfiguration;
            }
        } else {
            this.sortConfiguration = this.sortConfiguration.filter(sortConfiguration => sortConfiguration.column !== column);
        }
    }

    @action insertNewFirstConfigurationEntry(configurationEntry: OrderByPair<ColumnName>) {
        this.sortConfiguration.splice(0, 0, configurationEntry); // Insert the new configuration entry for the column at the front of the array
    }

    @computed get stringifiedSortConfiguration() {
        return JSON.stringify(this.sortConfiguration);
    }
}