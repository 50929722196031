import { Fragment, RefObject, useRef } from "react";
import { observer } from "mobx-react";
import ClickAwayPopper, { ClickAwayPopperProps } from "./ClickAwayPopper";
import PopperArrow from "./PopperArrow";

interface ButtonWithPopperProps extends ClickAwayPopperProps {
    hideArrow?: boolean;
    Button: (ref: RefObject<HTMLButtonElement>) => React.ReactNode;
}

const ButtonWithPopper = observer((props: ButtonWithPopperProps) => {

    const anchorRef = useRef<HTMLButtonElement>(null);

    /********* Render *********/

    return (
        <Fragment>
            {props.Button(anchorRef)}
            <ClickAwayPopper
                anchorEl={anchorRef}
                {...props}
                PopperProps={{ placement: 'bottom' }}
            >
                {!props.hideArrow && <PopperArrow />}
                {props.children}
            </ClickAwayPopper>
        </Fragment>
    );
});

export default ButtonWithPopper;