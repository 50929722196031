import { TextField, makeStyles, Theme, createStyles, OutlinedTextFieldProps } from "@material-ui/core";
import React, { useState, useRef, useLayoutEffect, FunctionComponent } from "react";
import { observer } from "mobx-react";
import { useElements, ElementProps } from '@stripe/react-stripe-js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            display: 'flex',
            transition: theme.transitions.create('width'),
            [theme.breakpoints.down('xs')]: {
                width: 'auto'
            }
        },
        stripeFieldWrapper: {
            position: 'relative'
        },
        stripeField: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3)
        },
        unfocusedRoot: {
            '&$focused $notchedOutline, & $notchedOutline': {
                borderWidth: '1px',
                borderColor: 'rgba(0, 0, 0, 0.23)'
            },
            '&$focused$error $notchedOutline, &$error $notchedOutline': {
                borderColor: theme.palette.error.main
            }
        },
        focusedRoot: {
            '& $notchedOutline': {
                borderColor: theme.palette.primary.main,
                borderWidth: '2px'
            },
            '&$error $notchedOutline': {
                borderColor: theme.palette.error.main
            }
        },
        notchedOutline: {},
        focused: {},
        error: {}
    }),
);

export interface PaymentFieldChangedEvent {
    empty: boolean;
    errorMessage?: string;
}

interface StripeFieldWrapperProps {
    stripeField: JSX.Element;
    stripeElementType: FunctionComponent<ElementProps>;
    label: string;
    TextFieldProps?: Partial<OutlinedTextFieldProps>;
    onFieldChanged?: (event: PaymentFieldChangedEvent) => void;
}

const StripeFieldWrapper = observer((props: StripeFieldWrapperProps) => {

    /********* React Hooks *********/

    const classes = useStyles();
    const wrapperFieldRef = useRef(null as unknown as HTMLInputElement);
    const elements = useElements();

    /********* State *********/

    const [stripeElement, setStripeElement] = useState(elements?.getElement(props.stripeElementType));
    const [focused, setFocused] = useState(false);
    const [empty, setEmpty] = useState(true);

    /********* Effects *********/

    useLayoutEffect(() => {
        if (!stripeElement) {
            const element = elements?.getElement(props.stripeElementType);
            setStripeElement(element);

            element?.on('focus', () => handleStripeFieldGainedFocus());
            element?.on('blur', () => handleStripeFieldLostFocus());
            element?.on('change', (event) => handleStripeFieldChange(event));
        }
        // return function cleanup() {
        // TODO: clean up event handlers? If enter is pressed quickly, test what happens.
        // }
    }, []);

    /********* Event handlers *********/

    const handleWrapperFieldGainedFocus = () => {
        // Transfer the focus to the Stripe element
        wrapperFieldRef.current.blur();
        if (!focused) {
            stripeElement?.focus();
        }
        setFocused(true);
    };

    const handleStripeFieldGainedFocus = () => {
        setFocused(true);
    }

    const handleStripeFieldLostFocus = () => {
        setFocused(false);
    };

    const handleStripeFieldChange = (event: { empty: boolean, error?: { message: string } }) => {
        setEmpty(event.empty);
        if (props.onFieldChanged) {
            props.onFieldChanged({ empty: event.empty, errorMessage: event.error?.message });
        }
    };

    /********* Helper constants *********/

    const error = props.TextFieldProps?.helperText;
    
    // To avoid overlap, the Stripe field should be hidden 
    // if the standard text field's label isn't raised
    const shouldDisplayStripeField = !empty || focused; 

    /********* Render *********/

    return (
        <div className={classes.stripeFieldWrapper}>
            <TextField
                {...props.TextFieldProps}
                className={classes.textField}
                label={props.label}
                variant="outlined"
                error={error !== null && error !== undefined && error !== ""}
                helperText={error}
                onFocus={handleWrapperFieldGainedFocus}
                ref={wrapperFieldRef}
                inputProps={{ tabIndex: -1 }}
                InputProps={{
                    classes: {
                        root: focused ? classes.focusedRoot : classes.unfocusedRoot,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline,
                        error: classes.error
                    }
                }}
                InputLabelProps={
                    {
                        focused: focused,
                        shrink: shouldDisplayStripeField
                    }
                }
            />
            <div className={classes.stripeField} style={{ zIndex: shouldDisplayStripeField ? 10 : -1 }}>
                {props.stripeField}
            </div>
        </div>
    );
});

export default StripeFieldWrapper;