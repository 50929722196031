import { ChangeEvent, Fragment, HTMLAttributes, useContext, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles, MenuItem, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Radio, Checkbox, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext } from "../../../../../stores";
import TextFieldWithCharacterLimit from "../../../../Shared/TextFieldWithCharacterLimit";
import { VOLUNTEER_NAME_MAX_LENGTH } from "../../../../../logic/ValidationChecks/FieldLengths";
import FormError from "../../../../Organization/VolunteerOpportunities/FormError";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import EmailInUseWarningField from "./EmailInUseWarningField";
import { VolunteerDetails } from "../../../../../stores/models/VolunteerDetails";
import { Alert, AlertTitle, Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
import { Permission } from "../../../../../stores/models/Permission";
import { Role } from "../../../../../stores/models/Role";
import REMSelect from "../../../../../shared/modules/rem-conversion/components/Select";
import REMChip from "../../../../../shared/modules/rem-conversion/components/Chip";
import REMKeyboardDatePicker from "../../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            '& .MuiTableRow-root .MuiTableCell-root:first-child': {
                fontWeight: 'bold',
                whiteSpace: 'nowrap'
            },
            '& .MuiTableRow-root:last-child td': {
                borderBottom: 'none',
            }
        },
        header: {
            '& th': {
                backgroundColor: theme.palette.primaryBackground.main,
                borderBottom: 'none'
            },
            whiteSpace: 'nowrap',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        chip: {
            background: theme.palette.primaryBackground.main,
            marginBottom: theme.spacing(.5),
            '&:not(:last-child)': {
                marginRight: theme.spacing(.5)
            }
        },
        controlWithoutLabel: {
            marginLeft: -11
        },
        selectField: {
            minWidth: 205
        },
        required: {
            color: 'red'
        },
        bold: {
            fontWeight: 'bold'
        },
        flex: {
            display: 'flex'
        },
        flexGrow: {
            flexGrow: 1
        },
        instructions: {
            marginBottom: theme.spacing(2)
        },
        columnFlex: {
            flexDirection: 'column',
            display: 'flex'
        }
    })
);

enum SelectionType {
    Radio,
    Checkbox
}

type FieldIdentifiers = 'firstName' | 'lastName' | 'status' | 'email' | 'birthdate' | 'permissions' | 'roles';
type Fields = {
    [key in FieldIdentifiers]: {
        label: string,
        required?: boolean,
        path: string[],
        selectionType: SelectionType
    }
};

const fields: Fields = {
    firstName: {
        label: 'First Name',
        required: true,
        path: ['volunteer', 'firstName'],
        selectionType: SelectionType.Radio
    },
    lastName: {
        label: 'Last Name',
        required: true,
        path: ['volunteer', 'lastName'],
        selectionType: SelectionType.Radio
    },
    status: {
        label: 'Status',
        required: true,
        path: ['status'],
        selectionType: SelectionType.Radio
    },
    email: {
        label: 'Email',
        path: ['email'],
        selectionType: SelectionType.Radio
    },
    birthdate: {
        label: 'Birthdate',
        path: ['volunteer', 'birthdate'],
        selectionType: SelectionType.Radio
    },
    permissions: {
        label: 'Permissions',
        path: ['permissions'],
        selectionType: SelectionType.Checkbox
    },
    roles: {
        label: 'Roles',
        path: ['roles'],
        selectionType: SelectionType.Checkbox
    }
}

enum RecordOptions {
    Record1 = '1',
    Record2 = '2',
    OverrideRecord = 'override',
    Unselected = 'unselected'
}

interface MergeRecordsFormProps extends HTMLAttributes<HTMLDivElement> {
    volunteerOne: VolunteerDetails;
    volunteerTwo: VolunteerDetails;
    mergedRecord: VolunteerDetails;
    error?: boolean;
}

const MergeRecordsForm = observer((props: MergeRecordsFormProps) => {

    const mergedRecord = props.mergedRecord;
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const organization = rootStore.userStore.user.organization;
    const organizationVolunteerStatuses = organization?.volunteerStatuses || [];
    const organizationPermissions = organization?.permissions || [];
    const organizationRoles = organization?.roles || [];

    /********** Helper method *********/

    const getInitialFieldSelections = () => {
        let initialFieldSelections = {} as { [key: string]: RecordOptions | RecordOptions[] };
        (Object.keys(fields) as FieldIdentifiers[]).forEach(key => {
            if (fields[key]?.selectionType === SelectionType.Radio) {
                initialFieldSelections[key] = RecordOptions.Unselected;
            } else {
                const recordOneHasValue = props.volunteerOne[key as 'permissions' | 'roles'].length > 0;
                const recordTwoHasValue = props.volunteerTwo[key as 'permissions' | 'roles'].length > 0;
                initialFieldSelections[key] = [];
                if (recordOneHasValue) {
                    (initialFieldSelections[key] as RecordOptions[]).push(RecordOptions.Record1);
                }
                if (recordTwoHasValue) {
                    (initialFieldSelections[key] as RecordOptions[]).push(RecordOptions.Record2);
                }
            }
        });
        return initialFieldSelections;
    }

    /********** State *********/

    const [fieldSelections, setFieldSelections] = useState(getInitialFieldSelections());
    const [overrideRecord] = useState(new VolunteerDetails());

    /********** Effects *********/

    useEffect(() => {
        overrideRecord.setEmail(''); // Email starts off undefined otherwise
    }, [])

    useEffect(() => {
        updateMergedRecord();
    }, [fieldSelections, overrideRecord, overrideRecord.volunteer.birthdate, overrideRecord.permissions.length, overrideRecord.roles.length]);

    /********** Helper methods *********/

    const updateMergedRecord = () => {
        (Object.keys(fieldSelections) as FieldIdentifiers[]).forEach(key => {
            const recordsToReference = ([] as RecordOptions[]).concat(fieldSelections[key]);
            const valuesToMerge = recordsToReference.map(record => {
                return getValueForRecordField(key, record);
            });
            let mergedValue: any;
            if (valuesToMerge.length === 1) {
                mergedValue = valuesToMerge[0];
            } else {
                mergedValue = valuesToMerge.flat();
                mergedValue = getValuesWithUniqueIds(mergedValue);
            }
            setMergedRecordValue(key, mergedValue);
        });
    }

    const getValuesWithUniqueIds = (valueArray: { id: number }[]) => {
        return valueArray.filter((value, index, self) => {
            return self.findIndex(object => object.id === value.id) === index;
        })
    }

    const setMergedRecordValue = (field: FieldIdentifiers, value: any) => {
        if (value) {
            switch (field) {
                case 'firstName':
                    mergedRecord.volunteer.setFirstName(value);
                    break;
                case 'lastName':
                    mergedRecord.volunteer.setLastName(value);
                    break;
                case 'birthdate':
                    mergedRecord.volunteer.setBirthdate(value);
                    break;
                case 'status':
                    mergedRecord.setStatus(value);
                    break;
                case 'email':
                    mergedRecord.setEmail(value);
                    break;
                case 'permissions':
                    mergedRecord.setPermissions(value);
                    break;
                case 'roles':
                    mergedRecord.setRoles(value);
                    break;
            }
        }
    }

    const getRecord = (record: RecordOptions) => {
        switch (record) {
            case RecordOptions.Record1:
                return props.volunteerOne;
            case RecordOptions.Record2:
                return props.volunteerTwo;
            case RecordOptions.OverrideRecord:
                return overrideRecord;
            default:
                break;
        }
    }

    const getValueForRecordField = (field: FieldIdentifiers, record: RecordOptions) => {
        const propertiesPath = fields[field].path;
        let currentPathValue = getRecord(record);
        propertiesPath.forEach(property => {
            if (currentPathValue) {
                currentPathValue = (currentPathValue as any)[property];
            }
        });
        return currentPathValue;
    }

    const isRadioButtonSelected = (field: FieldIdentifiers, record: RecordOptions) => {
        const currentSelectionForField = fieldSelections[field];
        return (currentSelectionForField === record);
    }

    const isCheckboxSelected = (field: FieldIdentifiers, record: RecordOptions) => {
        const currentSelectionForField = fieldSelections[field];
        return (currentSelectionForField.indexOf(record) !== -1);
    }

    /********** Event Handlers *********/

    const onRadioButtonChanged = (event: ChangeEvent<{}>, checked: boolean, field: FieldIdentifiers, record: RecordOptions) => {
        if (checked) {
            const newFieldSelections = { ...fieldSelections, [field]: record };
            setFieldSelections(newFieldSelections);
        }
    }

    const onCheckboxChanged = (event: ChangeEvent<HTMLInputElement>, checked: boolean, field: FieldIdentifiers, record: RecordOptions) => {
        let currentSelectionForField = fieldSelections[field];
        let newFieldSelections: { [key: string]: RecordOptions | RecordOptions[] };
        if (checked) {
            (currentSelectionForField as RecordOptions[]).push(record);
        } else {
            currentSelectionForField = (currentSelectionForField as RecordOptions[]).filter(selectedRecord => selectedRecord !== record);
        }
        newFieldSelections = { ...fieldSelections, [field]: currentSelectionForField };
        setFieldSelections(newFieldSelections);
    }

    const onOverrideTextFieldValueChanged = (field: FieldIdentifiers, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        if (newValue !== undefined) {
            switch (field) {
                case 'firstName':
                    overrideRecord.volunteer.setFirstName(newValue);
                    break;
                case 'lastName':
                    overrideRecord.volunteer.setLastName(newValue);
                    break;
                case 'email':
                    overrideRecord.setEmail(newValue);
                    break;
            }
        }
    }

    const onSelectedStatusChanged = (event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        overrideRecord.setStatus(event.target.value as string);
    }

    const onBirthdateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        overrideRecord.volunteer.setBirthdate(date ? date.toUTCString() : undefined);
    }

    const onPermissionsChanged = (event: ChangeEvent<{}>, value: Permission[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<Permission> | undefined) => {
        overrideRecord.setPermissions(value);
    }

    const onRolesChanged = (event: ChangeEvent<{}>, value: Role[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<Role> | undefined) => {
        overrideRecord.setRoles(value);
    }

    /********** Render *********/

    return (
        <div className={props.className}>
            {props.error &&
                <div>
                    <FormError />
                </div>
            }
            <Alert className={classes.instructions} severity="info">
                <AlertTitle>Merge Instructions</AlertTitle>
                For each of the following rows, select the value to carry forward to the merged record or enter a new value.
                All shift registrations and service entries from the two existing records will be transferred to the new merged record.
            </Alert>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    className={classes.table}
                    stickyHeader
                >
                    {/* Table Header */}
                    <TableHead className={classes.header}>
                        <TableRow key="record-merge-table-header-row">
                            <TableCell />
                            <TableCell>
                                <div className={classes.bold}>Record 1</div>
                                {props.volunteerOne.volunteer.fullName}
                            </TableCell>
                            <TableCell>
                                <div className={classes.bold}>Record 2</div>
                                {props.volunteerTwo.volunteer.fullName}
                            </TableCell>
                            <TableCell className={classes.bold}>
                                Override Values
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    <TableBody>
                        {(Object.keys(fields) as FieldIdentifiers[]).map((key, index) => {
                            const field = fields[key];
                            return (
                                <TableRow tabIndex={-1} key={`table-row-${index}`}>
                                    <TableCell>
                                        {field.label}{field.required && <span className={classes.required}> *</span>}
                                    </TableCell>
                                    {field.selectionType === SelectionType.Radio
                                        // ************ Radio Buttons ******************
                                        ? <Fragment>
                                            <TableCell>
                                                <FormControlLabel
                                                    value={RecordOptions.Record1}
                                                    control={<Radio color="primary" />}
                                                    label={(getValueForRecordField(key, RecordOptions.Record1) as unknown as string)}
                                                    checked={isRadioButtonSelected(key, RecordOptions.Record1)}
                                                    onChange={(event, checked) => onRadioButtonChanged(event, checked, key, RecordOptions.Record1)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    value={RecordOptions.Record2}
                                                    control={<Radio color="primary" />}
                                                    label={getValueForRecordField(key, RecordOptions.Record2) as unknown as string}
                                                    checked={isRadioButtonSelected(key, RecordOptions.Record2)}
                                                    onChange={(event, checked) => onRadioButtonChanged(event, checked, key, RecordOptions.Record2)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.flex}>
                                                    <Radio
                                                        value={RecordOptions.OverrideRecord}
                                                        color="primary"
                                                        className={classes.controlWithoutLabel}
                                                        checked={isRadioButtonSelected(key, RecordOptions.OverrideRecord)}
                                                        onChange={(event, checked) => onRadioButtonChanged(event, checked, key, RecordOptions.OverrideRecord)}
                                                    />
                                                    {key === 'status'
                                                        ? <REMSelect
                                                            value={overrideRecord.status}
                                                            variant={'outlined'}
                                                            onChange={onSelectedStatusChanged}
                                                            className={classes.selectField}
                                                        >
                                                            {organizationVolunteerStatuses.map(status => {
                                                                return (
                                                                    <MenuItem value={status.status} key={status.id}>{status.status}</MenuItem>
                                                                )
                                                            })}
                                                        </REMSelect>
                                                        : key === 'birthdate'
                                                            ? <REMKeyboardDatePicker
                                                                id="birthdate-picker"
                                                                format="MM/dd/yyyy"
                                                                minDate={new Date(1900, 1, 1)}
                                                                value={overrideRecord.volunteer.birthdate ? overrideRecord.volunteer.birthdate : null}
                                                                onChange={onBirthdateChange}
                                                                inputVariant="outlined"
                                                                required
                                                                disableFuture
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                error={overrideRecord.volunteer.isFieldInvalid('birthdate')}
                                                                helperText={overrideRecord.volunteer.getErrorForField('birthdate')}
                                                            />
                                                            : key === 'email'
                                                                ? <div className={classes.columnFlex}>
                                                                    <EmailInUseWarningField
                                                                        email={overrideRecord.email}
                                                                        error={overrideRecord.getErrorForField('email')}
                                                                        onEmailChanged={(event) => onOverrideTextFieldValueChanged(key, event)}
                                                                    />
                                                                </div>
                                                                : <TextFieldWithCharacterLimit
                                                                    characterLimit={VOLUNTEER_NAME_MAX_LENGTH}
                                                                    TextFieldProps={{
                                                                        onChange: (event) => onOverrideTextFieldValueChanged(key, event),
                                                                        value: getValueForRecordField(key, RecordOptions.OverrideRecord),
                                                                        error: (key === 'firstName' || key === 'lastName')
                                                                            && overrideRecord.volunteer.isFieldInvalid(key),
                                                                        helperText: (key === 'firstName' || key === 'lastName')
                                                                            && overrideRecord.volunteer.getErrorForField(key),
                                                                        variant: 'outlined',
                                                                    }}
                                                                />
                                                    }
                                                </div>
                                            </TableCell>
                                        </Fragment>
                                        : <Fragment>
                                            {/************* Checkbox Rows ***************/}
                                            <TableCell>
                                                <div className={classes.flex}>
                                                    <Checkbox
                                                        color="primary"
                                                        className={classes.controlWithoutLabel}
                                                        checked={isCheckboxSelected(key, RecordOptions.Record1)}
                                                        onChange={(event, checked) => onCheckboxChanged(event, checked, key, RecordOptions.Record1)}
                                                    />
                                                    <div className={classes.chips}>
                                                        {(props.volunteerOne[key as 'permissions' | 'roles'] as (Permission | Role)[]).map((value, index) => {
                                                            return (
                                                                <REMChip
                                                                    label={key === 'permissions' ? (value as Permission).label : (value as Role).position}
                                                                    className={classes.chip}
                                                                    key={`${key}-chip-${index}`}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.flex}>
                                                    <Checkbox
                                                        color="primary"
                                                        className={classes.controlWithoutLabel}
                                                        checked={isCheckboxSelected(key, RecordOptions.Record2)}
                                                        onChange={(event, checked) => onCheckboxChanged(event, checked, key, RecordOptions.Record2)}
                                                    />
                                                    <div className={classes.chips}>
                                                        {(props.volunteerTwo[key as 'permissions' | 'roles'] as (Permission | Role)[]).map((value, index) => {
                                                            return (
                                                                <REMChip
                                                                    label={key === 'permissions' ? (value as Permission).label : (value as Role).position}
                                                                    className={classes.chip}
                                                                    key={`${key}-chip-${index}`}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.flex}>
                                                    <Checkbox
                                                        color="primary"
                                                        className={classes.controlWithoutLabel}
                                                        checked={isCheckboxSelected(key, RecordOptions.OverrideRecord)}
                                                        onChange={(event, checked) => onCheckboxChanged(event, checked, key, RecordOptions.OverrideRecord)}
                                                    />
                                                    {key === 'permissions'
                                                        ? <Autocomplete
                                                            className={classes.flexGrow}
                                                            multiple
                                                            // id={ADD_TAGS_AUTOCOMPLETE_ID}
                                                            value={overrideRecord[key]}
                                                            options={organizationPermissions}
                                                            getOptionLabel={(option) => option.label}
                                                            // freeSolo
                                                            filterSelectedOptions
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <REMChip
                                                                        variant="outlined"
                                                                        label={option.label}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                // id={ADD_TAGS_TEXT_FIELD_ID}
                                                                />
                                                            )}
                                                            onChange={onPermissionsChanged}
                                                        />
                                                        : <Autocomplete
                                                            className={classes.flexGrow}
                                                            // className={classes.autocomplete}
                                                            multiple
                                                            // id={ADD_TAGS_AUTOCOMPLETE_ID}
                                                            value={overrideRecord.roles}
                                                            options={organizationRoles}
                                                            getOptionLabel={(option) => option.position}
                                                            // freeSolo
                                                            filterSelectedOptions
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <REMChip
                                                                        variant="outlined"
                                                                        label={option.position}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                // id={ADD_TAGS_TEXT_FIELD_ID}
                                                                />
                                                            )}
                                                            onChange={onRolesChanged}
                                                        />
                                                    }
                                                </div>
                                            </TableCell>
                                        </Fragment>
                                    }
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
});

export default MergeRecordsForm;