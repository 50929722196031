import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";
import { FormInputItem } from "../../stores/models/FormInputItem";
import { FormItemTypes } from "../../stores/models/FormSection";

const isValidFieldValue = (fieldValue?: string | string[]) => {
    return fieldValue !== undefined &&
        fieldValue.length !== 0 &&
        (typeof fieldValue !== 'string' || (fieldValue as string).trim().length !== 0);
}

const isCheckboxOptionSelected = (checkboxItem: FormInputItem) => {
    return (checkboxItem.value !== undefined && checkboxItem.value.length !== 0)
        || checkboxItem.otherSelected === true;
}

const isValidCheckboxValue = (checkboxItem: FormInputItem) => {
    return (checkboxItem.value !== undefined && checkboxItem.value.length !== 0)
        || (checkboxItem.otherSelected === true && checkboxItem.otherValue !== undefined && checkboxItem.otherValue.trim().length !== 0);
}

const formInputItemChecks: ValidationCheck<FormInputItem>[] = [{
    name: 'Field required',
    condition: (formInputItem: FormInputItem) => {
        return formInputItem.type === FormItemTypes.Checkbox || !formInputItem.required || isValidFieldValue(formInputItem.value);
    },
    error: new ValidationError('This is a required field.', ['value'])
}, {
    name: 'Checkbox field selection required',
    condition: (formInputItem: FormInputItem) => {
        return formInputItem.type !== FormItemTypes.Checkbox || !formInputItem.required || isCheckboxOptionSelected(formInputItem);
    },
    error: new ValidationError('This is a required field.', ['value'])
}, {
    name: 'Other field required',
    condition: (formInputItem: FormInputItem) => {
        return formInputItem.type !== FormItemTypes.Checkbox
            || !formInputItem.required
            || !isCheckboxOptionSelected(formInputItem)
            || isValidCheckboxValue(formInputItem);
    },
    error: new ValidationError('Please enter a value in the Other field.', ['value'])
}];

export function validateFormInputItem(formInputItem: FormInputItem) {
    return getValidationErrors(formInputItemChecks, formInputItem);
}