import { useContext, useEffect, useState } from "react";
import { RootContext } from "../../../../stores";
import { useOrgPortalLogoSrc } from "./OrgPortalLogoSrc";

// Checks if the specified or current custom portal has a valid logo, and if so, returns its URL 
export const useValidatedOrgPortalLogoSrc = (slug?: string) => {

    const rootStore = useContext(RootContext);
    const { imageStore } = rootStore;

    const portalLogoSrc = useOrgPortalLogoSrc(slug);

    const [validatedPortalLogoSrc, setValidatedPortalLogoSrc] = useState<string | null>(); // null = no logo exists, undefined = still checking
    const [loading, setLoading] = useState(true);

    const testImageUrl = async () => {
        if (portalLogoSrc) {
            setLoading(true);
            const validated = await imageStore.validateImageUrl(portalLogoSrc);
            if (validated) {
                setValidatedPortalLogoSrc(portalLogoSrc);
            } else {
                setValidatedPortalLogoSrc(null);
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        testImageUrl();
    }, [portalLogoSrc]);

    return { portalLogoSrc: validatedPortalLogoSrc, loading };
}