import { observer } from "mobx-react";
import React, { Fragment, useContext, useState } from "react";
import { makeStyles, Theme, createStyles, IconButton, useMediaQuery, Card, List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from "@material-ui/core";
import { RootContext } from "../../../../stores";
import { Plus } from "mdi-material-ui";
import SearchInput from "../SearchInput";
import { DialogState } from "../../../../stores/models/DialogState";
import MobileSearchPrompt, { SearchPromptSteps } from "../../Prompts/MobileSearchPrompt";
import FilterPicker from "../../Prompts/FilterPicker";
import clsx from 'clsx';
import SimpleCityPicker from "../../Prompts/SimpleCityPicker";
import { City } from "../../../../stores/models/City";
import LocationAccordion from "../LocationAccordion";
import OpportunityDetailsAccordion from "../OpportunityDetailsAccordion";
import { AccordionState } from "../../../../stores/models/AcccordionState";
import SearchTypeSelection from "../SearchTypeSelection";
import ButtonSelect from "../ButtonSelect";
import { useIsOrgPortal } from "../../../Navigation/Hooks";
import REMChip from "../../../../shared/modules/rem-conversion/components/Chip";

// TODO: Clean up

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchInput: {
            display: 'flex',
            flexGrow: 1,
            // flexShrink: 1,
            // marginBottom: theme.spacing(.5),
            alignItems: 'center',
            justifyContent: 'center'
            // maxWidth: '400px'
        },
        searchTypeSelector: {
            marginLeft: theme.spacing(1),
            display: 'flex'
        },
        flex: {
            display: 'flex'
        },
        bottomMargin: {
            marginBottom: theme.spacing(1)
        },
        card: {
            padding: theme.spacing(3, 3, 2, 3),
            color: theme.palette.action.active,
            position: 'sticky',
            top: theme.spacing(10),
        },
        // spaceUnder: {
        //     marginBottom: theme.spacing(1)
        // },
        // filterHeader: {
        //     // background: theme.palette.primary.main,
        //     // color: theme.palette.primary.contrastText,
        //     fontFamily: 'Open Sans',
        //     color: theme.palette.common.black,
        //     display: 'flex',
        //     // margin: theme.spacing(-2, -3, 1.5, -3),
        //     // padding: theme.spacing(1.5, 3, 0, 3),
        //     '& > :first-child': {
        //         marginRight: theme.spacing(1)
        //     }
        // },
        list: {
            paddingTop: 0
        },
        accordions: {
            '& .MuiAccordionSummary-root': {
                padding: 0
            },
            '& .MuiAccordionDetails-root': {
                padding: theme.spacing(0, 0, 1)
            }
        },
        sectionChips: {
            marginBottom: theme.spacing(1),
            '& > *': {
                marginBottom: 4,
                '&:not(:last-child)': {
                    marginRight: 4,
                }
            },
        },
        secondaryAction: {
            right: 0
        },
        defaultText: {
            color: theme.palette.common.black
        },
        locationFilter: {
            minHeight: theme.spacing(5),
            marginBottom: theme.spacing(1)
        }
    })
);

// TODO: Clean up

const AbridgedDesktopSearchFilters = observer(() => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const searchStore = rootStore.searchStore;
    const searchCriteria = searchStore.searchCriteria;
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const shortenSearchPlaceholder = useMediaQuery((theme: Theme) => theme.breakpoints.down(380));
    const isOrgPortal = useIsOrgPortal();

    /***** State *****/

    const [searchDialogState] = useState(new DialogState(false));
    const [filterSelectionDialogState] = useState(new DialogState());
    const [activeFilterStep, setActiveFilterStep] = useState<SearchPromptSteps>();
    const [city, setCity] = useState<string>('');
    const [simpleCityPickerState] = useState(new DialogState());
    const [locationAccordionState] = useState(new AccordionState(true));
    const [oppDetailsAccordionState] = useState(new AccordionState());

    /***** Event handler *****/

    const onAccordionStateChanged = (accordion: string, expanded: boolean) => {
        if (expanded) {
            if (accordion === 'location') {
                oppDetailsAccordionState.setExpanded(false);
            } else {
                locationAccordionState.setExpanded(false);
            }
        }
    }

    const onCityChanged = (newCity?: City) => {
        const newCityText = newCity?.cityAndState || '';
        setCity(newCityText);
        searchCriteria.setCityText(newCityText);
        simpleCityPickerState.setOpen(false);
    };

    const handleOpenDialog = (step: SearchPromptSteps) => {
        setActiveFilterStep(step);
        searchDialogState.setOpen(true);
    }

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        searchCriteria.setSearchText(event.target.value);
    };

    /***** Helper constant *****/

    const keywordFilter = (
        <Fragment>
            <div id="search-input-wrapper" className={classes.searchInput}>
                <SearchInput
                    onChange={handleSearchInputChange}
                    searchText={searchCriteria.searchText}
                    placeholder={shortenSearchPlaceholder ? "Search…" : undefined}
                    outlined
                />
            </div>
            {!isOrgPortal
                ? <div className={classes.searchTypeSelector}>
                    <SearchTypeSelection outlined />
                </div>
                : null
            }
        </Fragment>
    );

    /***** Render *****/

    return (
        <Card id="search-filters" className={classes.card}>
            {/* <div className={classes.filterHeader}>
                <TuneVerticalVariant />
                <Typography variant='h5' className={classes.spaceUnder}>Filters</Typography>
            </div> */}
            {true
                ? <div className={clsx(classes.flex, classes.bottomMargin)}>
                    {keywordFilter}
                </div>
                : null
            }
            <ButtonSelect options={searchCriteria.locationOptions} className={classes.locationFilter} />
            <div className={classes.accordions}>
                {/* <LocationAccordion
                    state={locationAccordionState}
                    onExpandedChanged={(expanded) => onAccordionStateChanged('location', expanded)}
                /> */}
                {/* <Divider /> */}
                <OpportunityDetailsAccordion
                    state={oppDetailsAccordionState}
                    onExpandedChanged={(expanded) => onAccordionStateChanged('oppDetails', expanded)}
                />
            </div>
            <List dense={false} className={classes.list}>
                {/* {generate( */}
                <Divider component='li' />
                <ListItem disableGutters>
                    <ListItemText
                        primary="Causes"
                        className={classes.defaultText}
                    // secondary={secondary ? 'Secondary text' : null}
                    />
                    <ListItemSecondaryAction className={classes.secondaryAction}>
                        <IconButton edge="end" aria-label="add causes" onClick={() => handleOpenDialog(SearchPromptSteps.CauseSelection)}>
                            <Plus />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {searchCriteria.causeOptions.selections.length > 0 &&
                    <div className={classes.sectionChips}>
                        {searchCriteria.causeOptions.selections.map(option => {
                            return (
                                <REMChip
                                    variant='outlined'
                                    key={`cause-${option.object.id}`}
                                    label={option.object.cause}
                                    onDelete={() => option.toggleSelection()}
                                // icon={React.createElement(CauseIconDictionary[option.object.id])}
                                />
                            )
                        })}
                    </div>
                }
                <Divider component='li' />
                <ListItem disableGutters>
                    <ListItemText
                        primary="Experience & Interests"
                        className={classes.defaultText}
                    // secondary={secondary ? 'Secondary text' : null}
                    />
                    <ListItemSecondaryAction className={classes.secondaryAction}>
                        <IconButton edge="end" aria-label="add interests and experience" onClick={() => handleOpenDialog(SearchPromptSteps.SkillsSelection)}>
                            <Plus />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {searchCriteria.skillOptions.selections.length > 0 &&
                    <div className={classes.sectionChips}>
                        {searchCriteria.skillOptions.selections.map(option => {
                            return (
                                <REMChip
                                    variant='outlined'
                                    key={`skill-${option.object.id}`}
                                    label={option.object.skill}
                                    onDelete={() => option.toggleSelection()}
                                />
                            )
                        })}
                    </div>
                }
                {/* )} */}
            </List>
            {/****** Location ******/}
            {/* {!displayCityInput
                        ? <Chip
                        icon={<MapMarker className={classes.icon} />}
                        variant="outlined"
                        label={searchCriteria.locationFilterText}
                        onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                        onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                        />
                        : null
                    } */}
            {/* <Chip
                    variant="outlined"
                    // icon={<MapMarker />}
                    label={'Organization Type'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                    /> */}
            {/****** Filter Dialogs ******/}
            <MobileSearchPrompt state={searchDialogState} stepToDisplay={activeFilterStep} />
            <SimpleCityPicker city={city} onCityChanged={onCityChanged} state={simpleCityPickerState} />
            <FilterPicker state={filterSelectionDialogState} />
        </Card>
    );
});

export default AbridgedDesktopSearchFilters;