import { useEffect, useState, useContext } from 'react';
import { RootContext } from '../../stores';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { MenuItem, IMenuItem, MenuOption } from '../../stores/models/MenuItem';
import useAccountSettingsMenuItems from '../AccountSettings/AccountSettingsMenuItemsHook';
import { SearchMenuItem, AccountMenuItem, LoginMenuItem, SignUpMenuItem, AuthenticationMenuItem, SupportMenuItem, PublicPageMenuItem, ContactUsMenuItem, MobileRecruitMenuItem, LogHoursItem, AboutOrganizationMenuItem } from './MenuItems';
import { getAccountSettingsLink, getOrganizationPathPrefix } from './Links';
import { MOBILE_MENU_BREAKPOINT } from './NavigationBar';
import { Domain, InformationOutline } from 'mdi-material-ui';
import { OrganizationMenuItem, UpdateMenuItem } from '../AccountSettings/AccountSettingsMenuItems';
import { useIsOrgPortal, useOrgPublicPageLink } from './Hooks';

// Main Navigation Menu

const getSearchMenuItemMatchRegex = (currentOrgLink: string) => {
    const negativeLookahead = currentOrgLink.replace(getOrganizationPathPrefix(), '');
    if (negativeLookahead) {
        return new RegExp(getOrganizationPathPrefix() + `(?!${negativeLookahead}/)(?!${negativeLookahead}$)` + '/.*');
    } else {
        return new RegExp(getOrganizationPathPrefix() + '/.*');
    }
}

export default function useMenuItems() {

    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;
    const [menuItems, setMenuItems] = useState([] as MenuItem[]);
    const theme = useTheme();
    const desktopMenu = useMediaQuery(theme.breakpoints.up(MOBILE_MENU_BREAKPOINT));
    const userSettingsMenuItems = useAccountSettingsMenuItems();
    const isOrgPortal = useIsOrgPortal();
    const orgPublicPageLink = useOrgPublicPageLink();

    useEffect(() => {
        let items: MenuItem[] = [];

        const updateItem = UpdateMenuItem({
            onClick: () => navigationStore.appwideDialogStates.clientVersionOutOfDateDialog.setOpen(true)
        });

        if (rootStore.clientHasUpdates && desktopMenu) {
            items.push(updateItem);
        }

        const currentOrgLink = userStore.user.organization?.organizationPage || '';
        const searchRegex = getSearchMenuItemMatchRegex(currentOrgLink);
        const searchItem = SearchMenuItem({ urlRegex: searchRegex });
        items.push(searchItem);

        const logHoursItem = LogHoursItem();
        const aboutItem = AboutOrganizationMenuItem();

        if (userStore.isAuthenticated) {
            /*****  Logged In User Menu *****/

            let publicPageItemOverrides = isOrgPortal
                ? { linkPath: orgPublicPageLink }
                : {
                    linkPath: orgPublicPageLink,
                    urlRegex: new RegExp(userStore.user.organization?.organizationPage + '/.*')
                };
            const publicPageItem = PublicPageMenuItem(publicPageItemOverrides);

            if (desktopMenu) {
                /*****  Logged In Desktop Menu *****/
                if (userStore.user.isOrganization) {
                    /*****  Organization User Desktop Menu *****/
                    if (isOrgPortal) {
                        const aboutItemForOrganization = AboutOrganizationMenuItem({ icon: InformationOutline });
                        items.push(aboutItemForOrganization);
                    } else {
                        items.push(publicPageItem);
                    }
                } else {
                    /*****  Volunteer User Desktop Menu *****/
                    items.push(logHoursItem);
                    if (isOrgPortal) {
                        items.push(aboutItem);
                    }
                }
                const accountItem = userStore.user.isVolunteer ? AccountMenuItem({ linkPath: getAccountSettingsLink() }) : AccountMenuItem({ text: 'Account', icon: Domain });
                items.push(accountItem);
            } else {
                /*****  Logged In Mobile Menu - Additions to the Account Settings Menu *****/
                let drawerMenuItems = userSettingsMenuItems;
                if (userStore.user.isOrganization) {
                    /*****  Organization User Mobile Menu *****/
                    drawerMenuItems = userSettingsMenuItems.map(menuItem => {
                        if (menuItem.option === MenuOption.Organization && menuItem.subItems) {
                            const publicPageItemNotYetAdded = menuItem.subItems.findIndex(subItem => subItem.option === publicPageItem.option) === -1;
                            if (publicPageItemNotYetAdded) {
                                menuItem.subItems.splice(1, 0, publicPageItem)
                            }
                        }
                        return menuItem;
                    });
                } else if (isOrgPortal) {
                    /*****  Volunteer User Mobile Menu *****/
                    const organizationItemNotAdded = userSettingsMenuItems.findIndex(menuItem => menuItem.option === MenuOption.Organization) === -1;
                    if (organizationItemNotAdded) {
                        userSettingsMenuItems.splice(1, 0, OrganizationMenuItem({ subItems: [aboutItem] }));
                    }
                    drawerMenuItems = userSettingsMenuItems;
                }
                items = items.concat(drawerMenuItems);
            }
        } else {
            /*****  Logged Out User Menu *****/
            let loginItemProps: Partial<IMenuItem> = { onClick: () => navigationStore.appwideDialogStates.loginDialog.setOpen(true) };
            if (!desktopMenu) {
                loginItemProps = {
                    ...loginItemProps,
                    text: 'Login'
                }
            }
            const loginItem = LoginMenuItem(loginItemProps);
            // const recruitItem = RecruitMenuItem();

            if (desktopMenu) {
                /*****  Logged Out Desktop Menu *****/
                items.push(logHoursItem);
                if (isOrgPortal) {
                    items.push(aboutItem);
                }
                items.push(loginItem);
                // items.push(recruitItem, loginItem);
            } else {
                /*****  Logged Out Mobile Menu *****/
                const contactUsItem = ContactUsMenuItem({
                    linkPath: undefined,
                    onClick: () => navigationStore.appwideDialogStates.contactUsDialog.setOpen(true)
                });
                const signUpItem = SignUpMenuItem({ onClick: () => navigationStore.appwideDialogStates.signUpDialog.setOpen(true) });
                const authenticationItem = AuthenticationMenuItem({ subItems: [loginItem, signUpItem] });

                let supportItem = SupportMenuItem({ subItems: [contactUsItem] });
                if (rootStore.clientHasUpdates) {
                    supportItem.subItems?.push(updateItem);
                }

                if (isOrgPortal) {
                    const organizationItem = OrganizationMenuItem({ subItems: [aboutItem] });
                    items.push(authenticationItem, organizationItem, supportItem);
                } else {
                    const recruitmentItem = MobileRecruitMenuItem();
                    items.push(authenticationItem, recruitmentItem, supportItem);
                }
            }
        }

        setMenuItems(items);

    }, [userStore.isAuthenticated, desktopMenu, userStore.user.name, userSettingsMenuItems, rootStore.clientHasUpdates, orgPublicPageLink]);

    return menuItems;
}