import { useEffect } from 'react';

interface DebouncedEffectProps {
    trigger: any;
    effect: () => void;
    timeout?: number;
}

const DEFAULT_TIMEOUT = 1000;

export default function useDebouncedEffect(props: DebouncedEffectProps) {

    useEffect(() => {
        // timeoutId for debouncing
        let timeoutId: NodeJS.Timeout | undefined = undefined;
        // prevent execution of previous setTimeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // update the map after timeout
        timeoutId = setTimeout(() => {
            props.effect();
        }, props.timeout ? props.timeout : DEFAULT_TIMEOUT);

        return function cleanUp() {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [props.trigger]);
}