import { observable, action, override, makeObservable } from "mobx";
import { ValidationError } from "./ValidationError";
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { Fields } from "./Fields";
import validatePaymentMethod from "../../logic/ValidationChecks/PaymentMethodValidation";

interface ICardElements {
    cardNumber?: string;
    expirationDate?: string;
    cvc?: string;
}

type CardDetailErrors = {
    [cardField in keyof ICardElements]?: ValidationError<PaymentMethod> | undefined;
};

type CardDetailEmpty = {
    [cardField in keyof ICardElements]: boolean;
}

export interface IPaymentMethod {
    id: string;
    name: string;
    zipCode: string;
    cardElement: StripeCardNumberElement | null;
    cardDetailErrors: CardDetailErrors;
    cardDetailEmpty: CardDetailEmpty;
}

interface IPaymentMethodFields {
    name: string;
    cardNumber: string;
    cvc: string;
    expirationDate: string;
    zipCode: string;
}

export class PaymentMethod extends Fields<IPaymentMethodFields, PaymentMethod> implements IPaymentMethod, ICardElements {
    name = "";
    zipCode = "";
    id = "";
    cardElement: StripeCardNumberElement | null = null;
    cardDetailErrors: CardDetailErrors = {};
    cardDetailEmpty: CardDetailEmpty = { cardNumber: true, expirationDate: true, cvc: true };

    // The following properties are never stored, but they are used 
    // to reference the appropriate field to display errors for.
    public cardNumber = '';
    public expirationDate = ''; 
    public cvc = ''; 

    constructor(paymentMethod?: IPaymentMethod) {
        super();

        makeObservable(this, {
            name: observable,
            zipCode: observable,
            id: observable,
            cardElement: observable,
            cardDetailErrors: observable,
            cardDetailEmpty: observable,
            setPaymentMethodField: action,
            setCardElement: action,
            setCardDetailErrors: action,
            setCardDetailEmpty: action,
            validationErrors: override
        });

        this.id = paymentMethod?.id || "";
        this.name = paymentMethod?.name || "";
        this.zipCode = paymentMethod?.zipCode || "";
        this.cardElement = paymentMethod?.cardElement || null;
        this.cardDetailErrors = paymentMethod?.cardDetailErrors || {};
    }

    setPaymentMethodField(field: 'name' | 'zipCode', value: string) {
        this[field] = value;
    }

    setCardElement(cardElement: StripeCardNumberElement) {
        this.cardElement = cardElement;
    }

    setCardDetailErrors(cardDetailErrors: CardDetailErrors) {
        this.cardDetailErrors = cardDetailErrors;
    }

    setCardDetailEmpty(cardDetailEmpty: CardDetailEmpty) {
        this.cardDetailEmpty = cardDetailEmpty;
    }

    get validationErrors() {
        return validatePaymentMethod(this);
    }

    serialize() {
        const serializedValue: Partial<PaymentMethod> = { ...this.trimmed };
        delete serializedValue.cardNumber;
        delete serializedValue.expirationDate;
        delete serializedValue.cvc;
        return serializedValue;
    }
}