import { makeStyles, Theme, createStyles, Box } from "@material-ui/core";
import { observer } from "mobx-react";
import { ResponsiveBar } from '@nivo/bar';
import { getUniqueElements } from "../../../../../logic/UtilityFunctions";
import { useStandardStyles } from "../../../../../shared/general/hooks/useStandardStyles";
import clsx from "clsx";
import { DASHBOARD_COLOR_POSITION_SUFFIX, DASHBOARD_OPPORTUNITY_ID_SUFFIX } from "../../../../../stores/models/constants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        legend: {
            marginLeft: 40,
            [theme.breakpoints.down(400)]: {
                marginLeft: 0
            }
        },
        legendIcon: {
            // borderRadius: '10px',
            width: '20px',
            height: '20px',
            marginRight: '4px'
        },
        legendItem: {
            margin: theme.spacing(0.5, .5),
            [theme.breakpoints.up('lg')]: {
                flexBasis: 'calc(33% - 8px)'
            },
            [theme.breakpoints.down('sm')]: {
                flexBasis: 'calc(33% - 8px)'
            },
            [theme.breakpoints.down('xs')]: {
                flexBasis: 'calc(50% - 8px)'
            },
            [theme.breakpoints.down(200)]: {
                flexBasis: 'calc(100% - 8px)'
            },
            flexBasis: 'calc(25% - 8px)'
        },
        legendLabel: {
            fontSize: '0.6875rem',
            fontFamily: 'sans-serif',
            dominantBaseline: 'central',
            pointerEvents: 'none',
            color: 'rgb(51, 51, 51)',
            // opacity: '0.85'
        }
    }),
);

interface BarGraphWidgetProps {
    colors: string[];
    data: { month: string }[];
}

type LegendItem = { id: string, label: string, color: string };

const INDEXING_PROPERTY = "month";
const COLOR_KEY_SUFFIX = "Color";
const colorPositionRegex = new RegExp(DASHBOARD_COLOR_POSITION_SUFFIX, "g");
const opportunityIdRegex = new RegExp(DASHBOARD_OPPORTUNITY_ID_SUFFIX, "g");

const BarGraphWidget = observer((props: BarGraphWidgetProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const baseClasses = useStandardStyles();

    /********* Helper methods *********/

    const getKeys = () => {
        if (props.data.length === 0) return [];
        return getUniqueElements(props.data.flatMap(dataElement => Object.keys(dataElement).filter(key => key !== INDEXING_PROPERTY && !colorPositionRegex.test(key) && !opportunityIdRegex.test(key))));
    }

    const keys = getKeys();

    const getColor = (colorPosition: number) => {
        const adjustedColorPosition = colorPosition % props.colors.length;
        return props.colors[adjustedColorPosition];
    }

    const getData = () => {
        return props.data.map((dataElement: Record<string, any>) => {
            let mappedElement: Record<string, any> = {};
            Object.keys(dataElement).forEach(key => {
                if (colorPositionRegex.test(key)) {
                    const renamedKey = key.replace(colorPositionRegex, COLOR_KEY_SUFFIX);
                    const color = getColor(dataElement[key]);
                    mappedElement[renamedKey] = color;
                } else {
                    mappedElement[key] = dataElement[key];
                }
            })
            return mappedElement;
        })
    }

    const getLegendData = () => {

        let legendData: LegendItem[] = [];
        props.data.forEach((dataElement: Record<string, any>) => {
            Object.keys(dataElement).forEach(key => {
                if (keys.includes(key)) {
                    legendData.push({
                        id: dataElement[key + DASHBOARD_OPPORTUNITY_ID_SUFFIX],
                        label: key,
                        color: getColor(dataElement[key + DASHBOARD_COLOR_POSITION_SUFFIX])
                    })
                }
            })
        });

        return legendData;
    }

    const getUniqueLegendItems = () => {
        const legendData = getLegendData();
        const uniqueLegendItems = legendData.reduce((accumulator: LegendItem[], current: LegendItem) => {
            if (!accumulator.some(item => item.id === current.id)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        return uniqueLegendItems;
    }

    const legendData = getUniqueLegendItems();

    /********* Render *********/

    return (
        <div className={classes.root}>
            <Box style={{ height: 250 }}>
                <ResponsiveBar
                    data={getData()}
                    keys={keys}
                    indexBy={INDEXING_PROPERTY}
                    margin={{ top: 30, right: 16, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    valueFormat={value =>
                        `${Number(value).toLocaleString('en-US', {
                            maximumFractionDigits: 2
                        })}`
                    }
                    indexScale={{ type: 'band', round: true }}
                    colors={({ id, data }) => (data as any)[id + COLOR_KEY_SUFFIX]}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Month',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Hours',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    role="application"
                    ariaLabel="Monthly volunteer hours"
                    barAriaLabel={(e: any) => { return e.id + ": " + e.formattedValue + " in month: " + e.indexValue }}
                    animate={false}
                />
            </Box>
            {/* Legend - Centered if only 1 item, otherwise arranged in equal-sized columns */}
            <div className={legendData.length === 1 ? clsx(baseClasses.flex, baseClasses.justifyCenter) : clsx(baseClasses.flex, baseClasses.flexWrap, classes.legend)}>
                {legendData.map(datum => {
                    return (
                        <div className={clsx(baseClasses.flex, legendData.length !== 1 ? classes.legendItem : undefined, baseClasses.alignCenter, baseClasses.overflowHidden)}>
                            <div className={clsx(baseClasses.noShrink, classes.legendIcon)} style={{ backgroundColor: datum.color }}></div>
                            <div className={clsx(baseClasses.truncateText, classes.legendLabel)}>{datum.label}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
});

export default BarGraphWidget;