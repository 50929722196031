import { observable, action, computed, makeObservable } from "mobx";
import { UserType } from ".";
import { Fields } from "./Fields";
import { StubOrganizationVolunteer } from "./StubOrganizationVolunteer";
import { StubUser } from "./StubUser";

export enum VolunteerRelation {
    Volunteer = 'volunteer',
    Contact = 'contact',
    Neither = 'neither',
    Unselected = ''
}

export enum EmailPreference {
    EmailMe = 'Yes please',
    RemoveMe = `No thank you`,
    Unselected = ''
}

interface LinkDetails {
    volunteer: StubOrganizationVolunteer;
    organizationId: number;
    organizationName: string;
}

export interface IOrganizationStubVolunteerLinking {
    token: string;
    linkDetails: LinkDetails;
    relation: VolunteerRelation;
    emailPreference: EmailPreference;
    agreedToTerms: boolean;
    creatingNewUser: boolean;
}

export class OrganizationStubVolunteerLinking extends Fields<IOrganizationStubVolunteerLinking, OrganizationStubVolunteerLinking> implements IOrganizationStubVolunteerLinking {
    @observable token: string;
    @observable relation = VolunteerRelation.Unselected;
    @observable emailPreference = EmailPreference.Unselected;
    @observable agreedToTerms = false;
    @observable creatingNewUser = false;
    @observable linkDetails: LinkDetails = {
        volunteer: new StubOrganizationVolunteer(),
        organizationName: '',
        organizationId: -1
    };

    constructor(token: string) {
        super();

        makeObservable(this);

        this.token = token;
    }

    /***** Setters *****/

    @action setAgreedToTerms(agreedToTerms: boolean) {
        this.agreedToTerms = agreedToTerms;
    }

    @action setRelation(relation: VolunteerRelation) {
        this.relation = relation;
    }

    @action setEmailPreference(emailPreference: EmailPreference) {
        this.emailPreference = emailPreference;
    }

    @action setLinkDetails(linkDetails: LinkDetails) {
        this.linkDetails = linkDetails;
    }

    @action setCreatingNewUser(creatingNewUser: boolean) {
        this.creatingNewUser = creatingNewUser;
    }

    /***** Computed properties *****/

    @computed get stubUser() {
        if (this.creatingNewUser) {
            const user = new StubUser(UserType.Volunteer);
            user.stubVolunteer?.setFirstName(this.linkDetails.volunteer.firstName);
            user.stubVolunteer?.setLastName(this.linkDetails.volunteer.lastName);
            if (this.linkDetails.volunteer.email) {
                user.credentials.setEmail(this.linkDetails.volunteer.email);
            }
            return user;
        }
    }

    @computed get emailOwnerIsVolunteer() {
        return this.relation === VolunteerRelation.Volunteer;
    }

    @computed get emailOwnerIsContact() {
        return this.relation === VolunteerRelation.Contact;
    }

    @computed get validationErrors() {
        return []; // TODO: implement if needed
    }

    @computed get emailVerified() {
        return this.emailOwnerIsContact || this.emailOwnerIsVolunteer;
    }

    @computed get notificationsAccepted() {
        return this.emailOwnerIsContact && this.emailPreference === EmailPreference.EmailMe;
    }

    @computed get hasExistingAccount() {
        return this.emailOwnerIsVolunteer && !this.creatingNewUser;
    }

    /***** Serialization *****/

    serialize() {
        return {
            token: this.token,
            emailVerified: this.emailVerified,
            notificationsAccepted: this.notificationsAccepted,
            hasExistingAccount: this.hasExistingAccount,
            stubUser: this.stubUser?.serialize()
        }
    }
}