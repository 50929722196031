import { observable, flow, action, computed, makeObservable, override } from 'mobx';
import { RootStore } from './RootStore';
import { Cause, ICause } from './models';
import { IOptionObject } from './models/Option';
import { IIconTileSelectorOption } from '../components/Shared/IconTileSelector';
import { DataStore } from './DataStore';

export type ICauseOption = IOptionObject & ICause & IIconTileSelectorOption;

enum CausesRequestTypes {
    LoadCauses = 'load-causes'
}

export class CauseStore extends DataStore<CausesRequestTypes> {

    @observable causes = [] as Cause[];

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);

        this.fetchCauses();
    }

    /********* API Request Methods *********/

    @action fetchCauses = flow(function* (this: CauseStore) {
        this.causes = [];
        const results = yield this.makeRequest(CausesRequestTypes.LoadCauses);
        const { causes } = results;
        this.causes = causes.map((cause: ICause) => { return new Cause(cause); });
    });

    /****** Computed Data ******/

    @override get getRequests() {
        return [CausesRequestTypes.LoadCauses];
    }

    @override get requestURLs() {
        return {
            [CausesRequestTypes.LoadCauses]: '/api/causes/all'
        };
    }

    @computed get causeOptions(): ICauseOption[] {
        const options = this.causes.map(cause => {
            const option = {
                ...cause,
                primaryText: cause.cause,
                value: cause.cause,
                icon: cause.icon
            };
            return option;
        })
        return options;
    }
}

