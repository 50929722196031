import { Method } from "axios";
import { computed, makeObservable } from "mobx";
import { Dictionary } from "../logic/Dictionaries";
import { IRequestConfig } from "./models/RequestQueue";
import { RootStore } from "./RootStore";

export class DataStore<RequestTypes extends string> {
    protected rootStore = {} as RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @computed private get requestQueue() {
        return this.rootStore.requestQueue;
    }

    @computed protected get getRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get patchRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get putRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get postRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get deleteRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get requestURLs(): Dictionary<string, (string | ((data: any, params: any) => string))> {
        return {};
    }

    private getRequestMethod(type: RequestTypes): Method {
        if (this.getRequests.findIndex(getRequest => getRequest === type) !== -1) {
            return 'get';
        } else if (this.patchRequests.findIndex(patchRequest => patchRequest === type) !== -1) {
            return 'patch';
        } else if (this.putRequests.findIndex(putRequest => putRequest === type) !== -1) {
            return 'put';
        } else if (this.postRequests.findIndex(postRequest => postRequest === type) !== -1) {
            return 'post';
        } else if (this.deleteRequests.findIndex(deleteRequest => deleteRequest === type) !== -1) {
            return 'delete';
        } else {
            throw new Error('Invalid request type.');
        }
    }

    private generateRequestConfig(type: RequestTypes, data?: any, params?: any): IRequestConfig {
        const method = this.getRequestMethod(type);
        const requestURL = this.requestURLs[type];
        const url = typeof requestURL === 'string' ? requestURL : requestURL(data, params);
        let requestConfig = {
            type: type,
            method: method,
            url: url,
            forceRefresh: method === 'get' ? true : undefined
        }
        const dataKey = data === undefined ? undefined : method === 'get' ? 'params' : 'data';
        if (dataKey !== undefined) {
            requestConfig = {
                ...requestConfig,
                [dataKey]: data
            }
        }
        return requestConfig;
    }

    protected makeRequest<T>(type: RequestTypes, data?: any, params?: any, bubbleError?: boolean) {
        try {
            return new Promise<T>(async (resolve, reject) => {
                try {
                    const requestConfig = this.generateRequestConfig(type, data, params);
                    let responseData;
                    if (this.isAPIRequest(requestConfig)) {
                        responseData = await this.requestQueue.makeAPIRequest<T>(requestConfig);
                    } else {
                        responseData = await this.requestQueue.makeExternalRequest<T>(requestConfig);
                    }
                    resolve(responseData);
                } catch (error) {
                    reject(error);
                }
            });
        } catch (error) {
            if (bubbleError) {
                throw error;
            } else {
                console.log(error);
            }
        }
    }

    protected cancelInFlightRequests(type?: string) {
        this.requestQueue.emptyQueue(type);
    }

    private isAPIRequest(requestConfig: IRequestConfig) {
        const match = requestConfig.url?.match(/^\/api\//);
        return match !== undefined && match !== null;
    }

}