import { Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import PlaceIcon from '@material-ui/icons/Place';
import React from "react";
import { Address } from "../../stores/models/Address";
import { getLinkToGoogleMap } from "../../logic";
import AddressDisplay from "./AddressDisplay";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            textAlign: 'left',
            alignItems: 'flex-start',
            textTransform: 'none',
            '&:hover .MuiSvgIcon-root': {
                color: theme.palette.primary.main
            }
        },
        icon: {
            marginRight: theme.spacing(1)
        },
    }),
);


interface AddressButtonProps {
    address: Address;
    selected: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function AddressButton(props: AddressButtonProps) {

    const classes = useStyles();

    const shouldLinkToGoogleMap = props.onClick === undefined;
    const buttonProps = shouldLinkToGoogleMap ? { target: '_blank' } : {};

    return (
        <Button
            className={classes.button}
            startIcon={<PlaceIcon fontSize='small' color={props.selected ? "primary" : "disabled"} className={classes.icon} />}
            onClick={props.onClick}
            href={shouldLinkToGoogleMap ? getLinkToGoogleMap(props.address) : undefined}
            {...buttonProps}
        >
            <AddressDisplay address={props.address} />
        </Button>
    )
}