import { observer } from "mobx-react";
import { useContext } from "react";
import { RootContext, Opportunity, Organization } from "../../stores";
import { Tooltip, Button, makeStyles, IconButton } from "@material-ui/core";
import React from "react";
import { LightningBolt } from "mdi-material-ui";
import { useHover } from '@react-aria/interactions'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    inactive: {
        justifyContent: 'flex-start',
        '&$hovered': {
            color: theme.palette.accent.main,
            '& $hoverText': {
                display: 'inline'
            }
        }
    },
    hovered: {},
    active: {
        color: theme.palette.accent.main + ' !important',
        '& $hoverText': {
            display: 'inline'
        }
    },
    hoverText: {
        display: 'none'
    },
    light: {
        color: theme.palette.action.disabled,
    },
    dark: {
        color: theme.palette.action.active
    }
}));

export interface SparkObject {
    interestSparked: boolean;
    setInterestSparked: (interestSparked: boolean) => void;
}

interface SparkButtonProps {
    sparkObject: SparkObject;
    text?: string;
    displayText?: boolean;
    displayHoverText?: boolean;
    darker?: boolean;
}

const SparkButton = observer((props: SparkButtonProps) => {

    const classes = useStyles();
    const { hoverProps, isHovered } = useHover({});

    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;

    const sparked = props.sparkObject.interestSparked && userStore.isAuthenticated;

    const handleSparkToggled: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        if (userStore.isAuthenticated) {
            if (userStore.user.isVolunteer) {
                toggleSparkValue();
            } else {
                navigationStore.appwideDialogStates.volunteerAccountRequiredDialog.setOpen(true);
            }
        } else {
            // Prompt the user to login or sign up first
            navigationStore.appwideDialogStates.loginDialog.setOpen(true);
            navigationStore.setOnAuthenticationCallback(() => handleSparkToggled(event));
        }
    }

    const toggleSparkValue = () => {
        const newValue = !sparked;
        props.sparkObject.setInterestSparked(newValue);
        if (props.sparkObject instanceof Opportunity) {
            volunteerStore.updateOpportunitySpark(props.sparkObject.id, newValue);
        } else if (props.sparkObject instanceof Organization) {
            volunteerStore.updateOrganizationSpark(props.sparkObject.id, newValue);
        }
    }

    return (
        <Tooltip title={sparked ? "Saved to Sparks" : "Save to Sparks"}>
            <div {...hoverProps}>
                {props.displayHoverText || props.displayText
                    ? <Button
                        className={clsx(
                            sparked ? classes.active : classes.inactive,
                            props.darker ? classes.dark : classes.light,
                            isHovered ? classes.hovered : undefined
                        )}
                        startIcon={<LightningBolt />}
                        onClick={handleSparkToggled}

                    >
                        <span className={props.displayText ? undefined : classes.hoverText}>
                            {props.text ? props.text : sparked ? 'Sparked!' : 'Spark'}
                        </span>
                    </Button>
                    : <IconButton
                        onClick={handleSparkToggled}
                        className={clsx(
                            sparked ? classes.active : classes.inactive,
                            props.darker ? classes.dark : classes.light,
                            isHovered ? classes.hovered : undefined
                        )}
                    >
                        <LightningBolt />
                    </IconButton>
                }
            </div>
        </Tooltip >
    )
})

export default SparkButton;