import React, { useState, useContext, useEffect } from "react";
import { Grid, Typography, makeStyles, Theme, createStyles, Container, Paper, Button } from "@material-ui/core";
import { RootContext } from "../../../../../stores";
import PaymentIcon from '@material-ui/icons/Payment';
import LoadingIndicator from "../../../../Shared/LoadingIndicator";
import { Restart, CheckBold, AlertCircle } from "mdi-material-ui";
import { Customer } from "../../../../../stores/models/Customer";
import { Subscription } from "../../../../../stores/models/Subscription";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog";
import { PaymentMethod } from "../../../../../stores/models/PaymentMethod";
import PaymentEntryDialog from "../../../../Shared/PaymentEntryDialog";
import { DialogState } from "../../../../../stores/models/DialogState";
import { observer } from "mobx-react";
import PaymentMethodDetails from "./PaymentMethodDetails";
import clsx from 'clsx';
import SubscriptionPlanOptionsDialog from "./SubscriptionPlanOptionsDialog";
import { Alert, AlertTitle } from "@material-ui/lab";
import FieldHeader from "../../../../Shared/FieldHeader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subsection: {
            marginTop: theme.spacing(2),
        },
        detail: {
            marginBottom: theme.spacing(2)
        },
        bold: {
            fontWeight: 'bold'
        },
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line'
        },
        cancel: {
            backgroundColor: theme.palette.grey[800],
            '&:hover': {
                backgroundColor: theme.palette.grey[800],
            }
        },
        statusWrapper: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1)
        },
        statusIcon: {
            marginRight: theme.spacing(1),
            paddingBottom: '2px'
        },
        check: {
            color: theme.palette.success.main,
            paddingBottom: '4px'
        },
        error: {
            color: theme.palette.error.main,
        },
        button: {
            marginTop: theme.spacing(1),
        },
        paymentDetailsButton: {
            display: 'flex',
            marginBottom: theme.spacing(1)
        },
        cancelButton: {
            color: theme.palette.action.active,
            borderColor: theme.palette.action.active,
            '&:hover': {
                borderColor: theme.palette.action.active
            }
        }
    }),
);

const SubscriptionDetails = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const paymentProcessingStore = rootStore.paymentProcessingStore;

    /********* State *********/

    const [isLoading, setIsLoading] = useState(true);
    const [customer, setCustomer] = useState<Customer>();
    const [confirmationDialogState] = useState(new DialogState());
    const [updateCardDialogState] = useState(new DialogState());
    const [subscriptionPlansDialogState] = useState(new DialogState());
    const [cardError, setCardError] = useState<string>();

    /********* Helper constants *********/

    const card = customer?.card;
    const subscription = customer?.subscription;
    const subscriptionActive = subscription?.active && !subscription.cancelled;

    /********* Effects *********/

    useEffect(() => {
        loadCustomerData();
    }, []);

    useEffect(() => {
        if (updateCardDialogState.open) {
            setCardError(undefined);
        }
    }, [updateCardDialogState.open]);

    /********* Event handlers *********/

    const handleStatusButtonClicked = () => {
        if (subscriptionActive) {
            handleCancelSubscription();
        } else {
            subscriptionPlansDialogState.setOpen(true);
        }
    }

    // Open a confirmation dialog before actually cancelling
    const handleCancelSubscription = () => {
        confirmationDialogState.setOpen(true);
    }

    const handleConfirmCancellation = async () => {
        confirmationDialogState.setLoading(true);
        const subscription = await paymentProcessingStore.cancelSubscription();
        if (subscription) {
            setSubscription(subscription);
            confirmationDialogState.setOpen(false);
        }
        confirmationDialogState.setLoading(false);
    }

    const openUpdateCardDialog = () => {
        updateCardDialogState.setOpen(true);
    }

    const closeUpdateCardDialog = () => {
        updateCardDialogState.setOpen(false);
    }

    const handleUpdateCard = async (paymentMethod: PaymentMethod) => {
        updateCardDialogState.setLoading(true);
        const customerDataOrErrors = await paymentProcessingStore.updatePaymentMethod(paymentMethod);
        if (customerDataOrErrors instanceof Customer) {
            customer?.setCard(customerDataOrErrors.card);
            closeUpdateCardDialog();
        }
        if (typeof customerDataOrErrors === 'string') {
            setCardError(customerDataOrErrors);
        } else if (Array.isArray(customerDataOrErrors) && customerDataOrErrors.length ===1) {
            setCardError(customerDataOrErrors[0].message);
        } else {
            setCardError(undefined);
        }
        // TODO: Handle card errors
        updateCardDialogState.setLoading(false);
    }

    /********* Helper constants and methods *********/

    const loadCustomerData = async () => {
        setIsLoading(true);
        const customerData = await paymentProcessingStore.getCustomerData();
        if (customerData) {
            setCustomer(customerData);
        }
        setIsLoading(false);
    }

    const setSubscription = (subscription: Subscription) => {
        customer?.setSubscription(subscription);
    };

    const getStatusText = () => {
        if (subscriptionActive) {
            if (subscription?.errorWithCard) {
                return 'Inactive - Card Error'
            }
            return 'Active';
        } else if (subscription?.cancelled) {
            return (
                <span>
                    Canceled - Expires <span className={classes.bold}>{subscription.paymentDate}</span>
                </span>
            );
        } else {
            return 'Inactive';
        }
    }

    const getStatusButtonText = () => {
        if (subscriptionActive) {
            return 'Cancel Subscription';
        } else {
            return subscription?.active ? 'Resume Subscription' : 'Renew Subscription';
        }
    }

    /********* Render *********/

    if (isLoading) {
        return <LoadingIndicator />;
    } else {
        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Container>
                                <div>
                                    <Typography variant="h4">
                                        Subscription
                                    </Typography>
                                    <div className={classes.subsection}>
                                        <Grid container spacing={2}>
                                            {!subscription?.active
                                                ? null
                                                : <Grid item xs={12} sm={6}>
                                                    <FieldHeader title="Subscription Plan" />
                                                    <div className={classes.detail}>
                                                        {subscription
                                                            ? <div>
                                                                {subscription?.upcomingPlanLevel
                                                                    ? < Typography className={classes.bold}>
                                                                        Until {subscription.paymentDate}:
                                                                    </Typography>
                                                                    : null
                                                                }
                                                                <Typography>
                                                                    {subscription.planName}
                                                                </Typography>
                                                                <Typography color="textSecondary">
                                                                    {`Active recruitment cities: `}
                                                                    {subscription.cityLimitation}
                                                                </Typography>
                                                                <Typography color="textSecondary">
                                                                    {`Remote opportunities: `}
                                                                    {subscription.remoteOpportunitiesLimitation}
                                                                </Typography>
                                                            </div>
                                                            : null
                                                        }
                                                        {subscription?.upcomingPlanLevel
                                                            ? <div className={classes.subsection}>
                                                                < Typography className={classes.bold}>
                                                                    Beginning {subscription.paymentDate}:
                                                                </Typography>
                                                                < Typography >
                                                                    {subscription.upcomingPlanName}
                                                                </Typography>
                                                                <Typography color="textSecondary">
                                                                    {`Active recruitment cities: `}
                                                                    {subscription.upcomingCityLimitation}
                                                                </Typography>
                                                                <Typography color="textSecondary">
                                                                    {`Remote opportunities: `}
                                                                    {subscription.upcomingRemoteOpportunitiesLimitation}
                                                                </Typography>
                                                            </div>
                                                            : null
                                                        }
                                                        {subscription.cancelled || subscription.errorWithCard
                                                            ? null
                                                            : <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.button}
                                                                onClick={() => subscriptionPlansDialogState.setOpen(true)}
                                                            >
                                                                Switch Plans
                                                            </Button>
                                                        }
                                                    </div>
                                                    {subscription.errorWithCard && card
                                                        ? <PaymentMethodDetails card={card} />
                                                        : null
                                                    }
                                                </Grid>
                                            }
                                            <Grid item xs={12} sm={6}>
                                                <FieldHeader title="Status" />
                                                <div className={classes.detail}>
                                                    <Typography>
                                                        <div className={clsx(classes.statusWrapper, subscription?.errorWithCard ? classes.error : undefined)}>
                                                            {subscription?.active && !subscription?.cancelled
                                                                ? subscription.errorWithCard
                                                                    ? <AlertCircle className={clsx(classes.statusIcon, classes.error)} />
                                                                    : <CheckBold className={clsx(classes.statusIcon, classes.check)} />
                                                                : null
                                                            }
                                                            {getStatusText()}
                                                        </div>
                                                    </Typography>
                                                    {subscription?.errorWithCard
                                                        ? <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={clsx(classes.button, classes.paymentDetailsButton)}
                                                            onClick={() => subscriptionPlansDialogState.setOpen(true)}
                                                            startIcon={<PaymentIcon />}
                                                        >
                                                            Update Payment Details
                                                        </Button>
                                                        : null
                                                    }
                                                    <Button
                                                        variant={subscriptionActive ? "outlined" : "contained"}
                                                        color={subscriptionActive ? "inherit" : "primary"}
                                                        className={clsx(
                                                            classes.button,
                                                            subscriptionActive ? classes.cancelButton : undefined
                                                        )}
                                                        onClick={handleStatusButtonClicked}
                                                        startIcon={subscriptionActive ? undefined : <Restart />}
                                                    >
                                                        {getStatusButtonText()}
                                                    </Button>
                                                </div>
                                            </Grid>
                                            {subscription?.cancelled || !subscription?.active || subscription.errorWithCard
                                                ? null
                                                : <Grid item xs={12} sm={6}>
                                                    {!card
                                                        ? null
                                                        : <div className={classes.detail}>
                                                            <PaymentMethodDetails card={card} />
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.button}
                                                                startIcon={<PaymentIcon />}
                                                                onClick={openUpdateCardDialog}
                                                            >
                                                                Update Card
                                                            </Button>
                                                        </div>
                                                    }
                                                </Grid>
                                            }
                                            {subscription?.cancelled || !subscription?.active || subscription.errorWithCard
                                                ? null
                                                : <Grid item xs={12} sm={6}>
                                                    <FieldHeader title="Next Payment" />
                                                    {subscription?.currentPeriodEnd
                                                        ? <div className={classes.detail}>
                                                            <Typography>
                                                                {`Your next bill is for `}
                                                                <span className={classes.bold}>
                                                                    ${subscription ? subscription.paymentAmount / 100 : ''}
                                                                </span>
                                                                {` on `}
                                                                <span className={classes.bold}>
                                                                    {subscription.paymentDate}
                                                                </span>
                                                            </Typography>
                                                        </div>
                                                        : null
                                                    }
                                                </Grid>
                                            }
                                        </Grid>
                                    </div>
                                </div>
                                <PaymentEntryDialog
                                    state={updateCardDialogState}
                                    title={'Update Card'}
                                    confirmText={'Update'}
                                    onConfirm={handleUpdateCard}
                                    error={cardError}
                                />
                                <ConfirmationDialog
                                    state={confirmationDialogState}
                                    title={`We're Sorry to See You Go`}
                                    content={
                                        <div>
                                            <Alert severity="info">
                                                <AlertTitle>Your subscription will remain active until {subscription?.paymentDate}.</AlertTitle>
                                                <Typography>
                                                    {`At that point your volunteer opportunity posts will automatically be marked inactive. `}
                                                    {`No further charges will be made on your card unless you renew your subscription, `}
                                                    {`which you can do at any time.`}
                                                </Typography>
                                            </Alert>
                                        </div>
                                    }
                                    confirmText='Confirm'
                                    cancelText='Close'
                                    onConfirm={handleConfirmCancellation}
                                />
                                {customer
                                    ? <SubscriptionPlanOptionsDialog
                                        state={subscriptionPlansDialogState}
                                        customer={customer}
                                        onCustomerUpdated={(customer) => setCustomer(customer)}
                                    />
                                    : null
                                }
                            </Container>
                        </Paper>
                    </Grid>
                </Grid >
            </Container >
        )
    }
});

export default SubscriptionDetails;