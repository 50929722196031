import { useEffect } from 'react';

const getWidth = () => {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

const getHeight = () => {
    return window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

export default function useWindowResizeEvent(callback: (width: number, height: number) => void) {

    useEffect(() => {
        // timeoutId for debouncing
        let timeoutId: NodeJS.Timeout;
        function resizeListener() {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => callback(getWidth(), getHeight()), 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        return function cleanUp() {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
}