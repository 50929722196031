import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, isValidFutureTimestamp } from "./Validation";
import { RepetitionPattern, RepetitionFrequency } from "../../stores/models/RepetitionPattern";
import { isValid } from "date-fns";

const repetitionPatternChecks: ValidationCheck<RepetitionPattern>[] = [{
    name: 'start date required',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.startDate !== null;
    },
    error: new ValidationError('Start date required.', ['startDate'])
}, {
    name: 'start date invalid',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.startDate === null || isValid(repetitionPattern.startDate);
    },
    error: new ValidationError('Invalid date.', ['startDate'])
}, {
    name: 'start time required',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.startTime !== null;
    },
    error: new ValidationError('Start time required.', ['startTime'])
}, {
    name: 'start time invalid',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.startTime === null || isValid(repetitionPattern.startTime);
    },
    error: new ValidationError('Invalid time.', ['startTime'])
}, {
    name: 'end date required',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.endDate !== null;
    },
    error: new ValidationError('End date required.', ['endDate'])
}, {
    name: 'end date invalid',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.endDate === null || isValid(repetitionPattern.endDate);
    },
    error: new ValidationError('Invalid date.', ['endDate'])
}, {
    name: 'end time required',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.endTime !== null;
    },
    error: new ValidationError('End time required.', ['endTime'])
}, {
    name: 'end time invalid',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.endTime === null || isValid(repetitionPattern.endTime);
    },
    error: new ValidationError('Invalid time.', ['endTime'])
}, {
    name: 'end comes after start',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.startTimestamp === null
            || repetitionPattern.endTimestamp === null
            || !isValid(repetitionPattern.startTimestamp)
            || !isValid(repetitionPattern.endTimestamp)
            || repetitionPattern.startTimestamp <= repetitionPattern.endTimestamp;
    },
    error: new ValidationError('The end of the shift is earlier than the start.', ['endTime', 'endDate'])
}, {
    name: 'repetition interval greater than zero',
    condition: (repetitionPattern: RepetitionPattern) => {
        return repetitionPattern.interval > 0 || repetitionPattern.frequency === RepetitionFrequency.Once;
    },
    error: new ValidationError('Frequency must be greater than zero for a repeating shift.', ['interval'])
}];

export default function validateRepetitionPattern(repetitionPattern: RepetitionPattern) {
    return getValidationErrors(repetitionPatternChecks, repetitionPattern);
}