import { getHandsRaisedLink, getAccountProfileLink, getSettingsPageLink, getLocationsLink, getSubscriptionLink, getOpportunitiesTableLink, getSparksLink, getVolunteersLink, getSchedulingLink, getServiceDataLink, getVolunteerShiftsLink, getDashboardLink, getVolunteerServiceHistoryLink, getCustomPortalLink, getReportingLink } from "../Navigation/Links/UrlConstructors";
import { AccountCircle, AccountSearch, Hand, LightningBolt, ClipboardTextMultipleOutline, Power, AccountGroup, CalendarMonth, ChartBar, CalendarClock, Home, Update, Web, ClockOutline } from "mdi-material-ui";
import PaymentIcon from '@material-ui/icons/Payment';
import DomainIcon from '@material-ui/icons/Domain';
import PlaceIcon from '@material-ui/icons/Place';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MenuItem, MenuOption, IMenuItem } from "../../stores/models/MenuItem";
import { Settings } from "@material-ui/icons";
import React from "react";

/** Account Settings menu items shared by volunteers and organizations **/

export const InformationMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Information',
        option: MenuOption.ProfileInformation,
        icon: InfoOutlinedIcon,
        linkPath: getAccountProfileLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const SettingsMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Settings',
        option: MenuOption.Settings,
        icon: Settings,
        linkPath: getSettingsPageLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const LogoutMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Logout',
        option: MenuOption.Logout,
        icon: Power,
    };

    return new MenuItem({ ...defaultItem, ...override });
};

/** Account Settings menu items for volunteers only **/

export const VolunteerAccountMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Account',
        option: MenuOption.VolunteerAccount,
        icon: AccountCircle,
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const VolunteerDashboardMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Dashboard',
        option: MenuOption.Dashboard,
        icon: Home,
        linkPath: getDashboardLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const ShiftsItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Schedule',
        option: MenuOption.VolunteerShifts,
        icon: CalendarClock,
        linkPath: getVolunteerShiftsLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const VolunteerHoursItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Volunteer Hours',
        option: MenuOption.VolunteerServiceHistory,
        icon: ChartBar,
        linkPath: getVolunteerServiceHistoryLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const ResponsesMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Responses',
        option: MenuOption.HandsRaised,
        icon: Hand,
        linkPath: getHandsRaisedLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const SparksMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Sparks',
        option: MenuOption.Sparks,
        icon: LightningBolt,
        linkPath: getSparksLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const VolunteeringMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Volunteering',
        option: MenuOption.Volunteering,
        subItems: [VolunteerDashboardMenuItem(), ShiftsItem(), VolunteerHoursItem(), SparksMenuItem(), ResponsesMenuItem()]
    };

    return new MenuItem({ ...defaultItem, ...override });
};

/** Account Settings menu items for organizations only **/

export const DashboardMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Dashboard',
        option: MenuOption.Dashboard,
        icon: Home,
        linkPath: getDashboardLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const LocationsMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Locations',
        option: MenuOption.Locations,
        icon: PlaceIcon,
        linkPath: getLocationsLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const CustomPortalMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Portal',
        option: MenuOption.CustomPortal,
        icon: Web,
        linkPath: getCustomPortalLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const OrganizationMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Organization',
        option: MenuOption.Organization,
        icon: DomainIcon,
        subItems: [DashboardMenuItem(), InformationMenuItem(), LocationsMenuItem(), CustomPortalMenuItem()]
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const SubscriptionMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Subscription',
        option: MenuOption.Subscription,
        icon: PaymentIcon,
        linkPath: getSubscriptionLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const OrganizationAccountMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Account',
        option: MenuOption.OrganizationAccount,
        icon: AccountCircle
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const OrganizationOpportunitiesMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Opportunities',
        option: MenuOption.OrganizationOpportunities,
        icon: ClipboardTextMultipleOutline,
        linkPath: getOpportunitiesTableLink(),
        urlRegex: new RegExp(getOpportunitiesTableLink() + '/.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const SchedulingMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Scheduling',
        option: MenuOption.Scheduling,
        icon: CalendarMonth,
        linkPath: getSchedulingLink(),
        urlRegex: new RegExp(getSchedulingLink() + '/.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const VolunteersMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Volunteers',
        option: MenuOption.Volunteers,
        icon: AccountGroup,
        linkPath: getVolunteersLink(),
        urlRegex: new RegExp(getVolunteersLink() + '/.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const RecruitmentMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Recruitment',
        option: MenuOption.Recruitment,
        icon: AccountSearch,
        subItems: [OrganizationOpportunitiesMenuItem(), SchedulingMenuItem(), VolunteersMenuItem()]
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const ServiceDataMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Volunteer Hours',
        option: MenuOption.ServiceData,
        icon: ClockOutline,
        linkPath: getServiceDataLink(),
        urlRegex: new RegExp(getServiceDataLink() + '/.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const ReportingMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Reporting',
        option: MenuOption.Reporting,
        icon: ChartBar,
        linkPath: getReportingLink(),
        urlRegex: new RegExp(getReportingLink() + '/.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const DataMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Data',
        option: MenuOption.Data,
        icon: AccountSearch,
        subItems: [ServiceDataMenuItem(), ReportingMenuItem()]
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const UpdateMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Update',
        option: MenuOption.UpdateClient,
        icon: Update,
        badge: React.createElement('div', undefined, '1')
    };

    return new MenuItem({ ...defaultItem, ...override });
};