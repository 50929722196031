import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, getMaxLengthValidationCheck, getFieldRequiredValidationCheck } from "./Validation";
import { Address } from "../../stores/models/Address";
import { zipCodeIsValid, stateAbbreviationIsValid } from "../RegexValidation";
import { ADDRESS_LINE_ONE_MAX_LENGTH, ADDRESS_LINE_TWO_MAX_LENGTH, CITY_MAX_LENGTH, STATE_MAX_LENGTH } from "./FieldLengths";

const addressChecks: (address: Address) => ValidationCheck<Address>[] = (address: Address) => [
    getFieldRequiredValidationCheck(address.lineOne, 'lineOne', 'Please enter the first line of the address.'),
    getMaxLengthValidationCheck(address.lineOne, 'lineOne', ADDRESS_LINE_ONE_MAX_LENGTH),
    getMaxLengthValidationCheck(address.lineTwo, 'lineTwo', ADDRESS_LINE_TWO_MAX_LENGTH),
    getFieldRequiredValidationCheck(address.city, 'city', 'Please enter a city.'),
    getMaxLengthValidationCheck(address.city, 'city', CITY_MAX_LENGTH),
    getFieldRequiredValidationCheck(address.state, 'state', 'Please enter a state.'),
    getMaxLengthValidationCheck(address.state, 'state', STATE_MAX_LENGTH),
    {
        name: 'valid state',
        condition: (address: Address) => {
            return address.state.trim().length === 0 || stateAbbreviationIsValid(address.state);
        },
        error: new ValidationError('Invalid US state abbreviation.', ['state'])
    }, {
        name: 'valid zip code',
        condition: (address: Address) => {
            return zipCodeIsValid(address.zipCode);
        },
        error: new ValidationError('Please enter a valid zip code.', ['zipCode'])
    }];

export default function validateAddress(address: Address) {
    return getValidationErrors(addressChecks, address);
}