import { makeStyles, Theme, createStyles, Typography, Button, Divider } from "@material-ui/core";
import { observer } from "mobx-react";
import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            position: 'relative'
        },
        linkWrapper: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            color: theme.palette.text.secondary,
            textAlign: 'center'
        },
        signUpButton: {
            marginTop: theme.spacing(1),
            color: theme.palette.common.white,
            background: theme.palette.success.main,
            '&:hover': {
                background: theme.palette.success.main,
            }
        }
    }),
);

interface LoginOrSignUpFormProps {
    onSwitchToSignUp: () => void;
    onLoadingStateChanged: (isLoading: boolean) => void;
}

const LoginOrSignUpForm = observer((props: LoginOrSignUpFormProps) => {

    /********** React hooks **********/

    const classes = useStyles();

    /********** Render **********/

    return (
        <div className={classes.form}>
            <LoginForm onLoadingStateChanged={props.onLoadingStateChanged} />
            <Divider />
            <div className={classes.linkWrapper}>
                <Typography variant="body2">
                    {`Don't have an account yet? `}
                </Typography>
                <Button
                    color="inherit"
                    variant="contained"
                    fullWidth
                    className={classes.signUpButton}
                    size="large"
                    onClick={props.onSwitchToSignUp}
                >
                    Sign up
                </Button>
            </div>
        </div>
    );
});

export default LoginOrSignUpForm;