import { useEffect, useContext, useState } from 'react';
import { RootContext } from '../../../stores';

interface ReloadOnUserChangeProps {
    dataLoadingCallback: () => Promise<any>;
}

export default function useReloadOnUserChange(props: ReloadOnUserChangeProps) {

    const userStore = useContext(RootContext).userStore;

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
        } else {
            props.dataLoadingCallback();
        }
    }, [userStore.user.id]);
}