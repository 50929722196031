import { ServiceEntry } from "../../modules/volunteer-hours/data-access/entities/ServiceEntry";
import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { SERVICE_ENTRY_NOTES_MAX_LENGTH } from "./FieldLengths";
import { getMaxLengthValidationCheck, getValidationErrors } from "./Validation";

const serviceEntryChecks: (serviceEntry: ServiceEntry) => ValidationCheck<ServiceEntry>[] = (serviceEntry: ServiceEntry) => [
    getMaxLengthValidationCheck(serviceEntry.notes, 'notes', SERVICE_ENTRY_NOTES_MAX_LENGTH),
    {
        name: 'at least one volunteer',
        condition: (serviceEntry: ServiceEntry) => {
            return serviceEntry.volunteerEntry || serviceEntry.volunteers.length > 0;
        },
        error: new ValidationError('At least one volunteer must be selected.', ['volunteers'])
    }, {
        name: 'duration greater than zero',
        condition: (serviceEntry: ServiceEntry) => {
            return serviceEntry.duration > 0 || serviceEntry.archived === true;
        },
        error: new ValidationError('The duration must be greater than zero.', ['duration'])
    }, {
        name: 'date required',
        condition: (serviceEntry: ServiceEntry) => {
            return serviceEntry.date !== undefined;
        },
        error: new ValidationError('Please enter a date.', ['date'])
    }, {
        name: 'date not in the future',
        condition: (serviceEntry: ServiceEntry) => {
            return serviceEntry.date === undefined || new Date(serviceEntry.date) < new Date();
        },
        error: new ValidationError('The date cannot be in the future.', ['date'])
    }, {
        name: 'organization required for volunteer entry',
        condition: (serviceEntry: ServiceEntry) => {
            return serviceEntry.volunteerEntry === false || serviceEntry.organizationId !== undefined;
        },
        error: new ValidationError('Please select an organization.', ['organizationId'])
    }];

export function validateServiceEntry(serviceEntry: ServiceEntry) {
    return getValidationErrors(serviceEntryChecks, serviceEntry);
}