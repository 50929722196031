import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { OrganizationStubVolunteerLinking, VolunteerRelation } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { StepProps } from "../../../../stores/models/Step";
import VolunteerRelationSelection from "../VolunteerRelationSelection";

const VolunteerRelationStep = observer((props: StepProps<OrganizationStubVolunteerLinking>) => {

    const linkData = props.stepState.stepObject;

    /***** Effects *****/

    useEffect(() => {
        props.stepState.setValidationMethod(validateVolunteerRelationSelected);
    }, []);

    /***** Event handlers *****/

    const onRelationChanged = (relation: VolunteerRelation) => {
        linkData.setRelation(relation);
    }

    /***** Helper methods *****/

    const validateVolunteerRelationSelected = () => {
        return linkData.relation !== VolunteerRelation.Unselected;
    }

    /***** Render *****/

    return (
        <VolunteerRelationSelection
            relation={linkData.relation}
            onRelationChanged={onRelationChanged}
        />
    );
});

export default VolunteerRelationStep;