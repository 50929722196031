import { action, computed, makeObservable, observable } from "mobx";
import { Option, IOptionObject, INumericalIdOptionObject } from "./Option";
import { UniqueIdentification } from "../../logic/UniqueIdentification";

export type Identity = string | number;
export type IdentityKey<T> = {
    [K in keyof T]: T[K] extends Identity ? K : never;
}[keyof T];

type RequireOne<T> = T extends infer U
    ? { [K in keyof U]: Pick<U, K> }[keyof U]
    : never;

type FilteredObject<T> = {
    [K in keyof T as T[K] extends Identity ? K : never]: T[K];
};

type RequireOneIdentifier<T> = RequireOne<FilteredObject<T>>
export type ObjectWithIdentifier<T> = RequireOneIdentifier<T> & Partial<T>

// export type StringIdentifiedOptionCollection<T extends IOptionObject> = NewOptionCollection<'value', IOptionObject, T>;
// export type NumericallyIdentifiedOptionCollection<T extends IdentifiableObject<T, 'id'>> = NewOptionCollection<'id', T>;//'id', INumericalIdOptionObject, T>;

/*** Option Collection ***/

interface IOptionCollection<T> {
    options: Option<T>[];
    identifier: IdentityKey<T>;
}

export class NewOptionCollection<
    OptionObject extends ObjectWithIdentifier<OptionObject>,
    MinimallyIdentifiableObject extends ObjectWithIdentifier<OptionObject> = ObjectWithIdentifier<OptionObject>
>
    implements IOptionCollection<OptionObject>
{
    @observable options: Option<OptionObject>[];
    @observable identifier: IdentityKey<OptionObject>;

    constructor(identifierOrOptionCollection: IdentityKey<OptionObject>, optionObjects: OptionObject[], selectedOptions?: MinimallyIdentifiableObject[]);
    constructor(identifierOrOptionCollection: NewOptionCollection<OptionObject, MinimallyIdentifiableObject>);

    constructor(identifierOrOptionCollection: IdentityKey<OptionObject> | NewOptionCollection<OptionObject, MinimallyIdentifiableObject>, optionObjects?: OptionObject[], selectedOptions?: MinimallyIdentifiableObject[]) {
        makeObservable(this);

        if (typeof identifierOrOptionCollection === 'string') {
            this.identifier = identifierOrOptionCollection;
            this.options = optionObjects
                ? optionObjects.map(object => {
                    const selected = selectedOptions
                        ? selectedOptions.findIndex(selection => selection[this.identifier] === object[this.identifier]) !== -1
                        : false;
                    return new Option(object, selected);
                })
                : [];
        } else {
            identifierOrOptionCollection = identifierOrOptionCollection as NewOptionCollection<OptionObject, MinimallyIdentifiableObject>;
            this.identifier = identifierOrOptionCollection.identifier;
            this.options = identifierOrOptionCollection.options.map(option => new Option(option.object, option.selected));
        }
    }

    @action setSelections(selections: MinimallyIdentifiableObject[]) {
        this.options.forEach(option => {
            const selected = selections.findIndex(selection => selection[this.identifier] === option.object[this.identifier]) !== -1;
            if (option.selected !== selected) {
                option.toggleSelection();
            }
        })
    }

    @action push(toAdd: OptionObject | OptionObject[], selected?: boolean) {
        if (Array.isArray(toAdd)) {
            toAdd.forEach(optionObject => {
                this.addOption(optionObject, selected);
            });
        } else {
            this.addOption(toAdd, selected);
        }
    }

    @action addOrUpdateOption(optionObject: OptionObject, selected?: boolean) {
        const optionIndex = this.getOptionIndex(optionObject);
        if (optionIndex === -1) {
            this.addOption(optionObject, selected);
        } else {
            this.options[optionIndex] = new Option(optionObject, selected !== undefined ? selected : false);
        }
    }

    @action private addOption(optionObject: OptionObject, selected?: boolean) {
        if (this.optionNeedsClientId(optionObject)) {
            this.assignUniqueClientId(optionObject);
        }
        const newOption = new Option(optionObject, selected !== undefined ? selected : false);
        this.options.push(newOption);
    }

    private optionNeedsClientId = (optionObject: OptionObject) => {
        const identity = optionObject[this.identifier];
        switch (typeof identity) {
            case 'number':
                return (identity < 0);
            default:
                return false;
        }
    }

    @action toggleSelection(optionObject: MinimallyIdentifiableObject) {
        const optionIndex = this.getOptionIndex(optionObject);
        if (optionIndex !== -1) {
            this.options[optionIndex].selected = !this.options[optionIndex].selected;
        }
    }

    @action clearOptions() {
        this.options = [];
    }

    @action removeOption(optionObject: OptionObject) {
        const optionIndex = this.getOptionIndex(optionObject);
        if (optionIndex !== -1) {
            this.options.splice(optionIndex, 1);
        }
    }

    @action removeOptionById(identifierValue: number | string) {
        const option = this.getOptionByIdentifier(identifierValue);
        if (!option) return;
        this.removeOption(option.object);
    }

    @action removeSelectedOptions() {
        for (let index = this.options.length - 1; index >= 0; index--) {
            if (this.options[index].selected) {
                this.options.splice(index, 1);
            }
        }
    }

    @action splice(start: number, deleteCount: number, ...items: Option<OptionObject>[]) {
        this.options.splice(start, deleteCount, ...items);
    }

    @action replaceOption(oldOption: OptionObject, replacement: OptionObject) {
        const optionIndex = this.getOptionIndex(oldOption);
        if (optionIndex !== -1) {
            this.options.splice(optionIndex, 1, new Option(replacement, false));
        }
    }

    getOptionByIdentifier(identifierValue: number | string) {
        return this.options.find(option => option.object[this.identifier] === identifierValue);
    }

    isValidOption(optionObject: OptionObject) {
        return this.getOptionIndex(optionObject) !== -1;
    }

    private getOptionIndex(optionObject: MinimallyIdentifiableObject) {
        return this.options.findIndex(existingOption => existingOption.object[this.identifier] === optionObject[this.identifier]);
    }

    @action private assignUniqueClientId(optionObject: OptionObject) {
        if (typeof optionObject[this.identifier] === 'number') {
            (optionObject[this.identifier] as number) = UniqueIdentification.getClientId();
        }
    }

    @computed get selections(): Option<OptionObject>[] {
        return this.options.filter(option => option.selected);
    }

    @computed get optionObjects(): OptionObject[] {
        return this.options.map(option => option.object);
    }

    @computed get selectedOptions(): OptionObject[] {
        return this.selections.map(selection => selection.object);
    }

    isOptionSelected(option: Identity) {
        return this.selectedOptions.findIndex(selection => selection[this.identifier] === option) !== -1;
    }

    isOptionWithIdentifierSelected(identifierValue: number | string) {
        return this.selectedOptions.findIndex(selection => selection[this.identifier] === identifierValue) !== -1;
    }
}