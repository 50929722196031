import { useEffect, useState } from 'react';
import { IdentifiableObject } from '../../../stores/models/OptionCollection';
import { TableController } from '../../../stores/models/TableController';
import useDebouncedEffect from './DebouncedEffect';

export default function useLoadTableData<
    IdentityKey extends string,
    Record extends IdentifiableObject<IdentityKey>,
    StubRecord extends IdentifiableObject<IdentityKey> = Record,
    MergedRecord extends object = Record
>(
    tableController: TableController<IdentityKey, Record, StubRecord, MergedRecord>
) {

    const offset = tableController.tableState.paginationState.offset;
    const limit = tableController.tableState.paginationState.rowsPerPage;

    const [initialLoad, setInitialLoad] = useState(true);

    const hasRecordsToLoad = () => {
        const totalRecords = tableController.tableState.total;
        return totalRecords === undefined || tableController.records.options.length < totalRecords;
    }

    const loadData = async (resetRecords?: boolean) => {
        await tableController.load(resetRecords);
    }

    const reload = () => {
        loadData(true);
    }

    useEffect(() => {
        if (tableController.records.options.length === 0) {
            tableController.tableState.setUnloadedSelected(false);
        }
    }, [tableController.records.options.length]);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        tableController.records.clearOptions();
        if (offset === 0) {
            loadData();
        }
    }, [tableController.tableState.sortingState.stringifiedSortConfiguration]);

    useEffect(() => {
        tableController.records.clearOptions();
    }, [limit]);

    useEffect(() => {
        if (offset + limit > tableController.records.options.length && hasRecordsToLoad()) {
            loadData();
        }
    }, [tableController.tableState.paginationState.offset, limit]);

    useEffect(() => {
        reload();
    }, [JSON.stringify(tableController.tableState.loadRequest.filterState?.filters)])

    useDebouncedEffect({
        trigger: tableController.tableState.loadRequest.filterState?.searchQuery,
        effect: () => {
            if (initialLoad) {
                setInitialLoad(false);
            } else {
                reload();
            }
        }
    });
}