import { makeStyles, Theme, createStyles, TableContainer, Table, TableRow, TableCell, TableBody, TableHead, Typography, Button } from "@material-ui/core";
import React from "react";
import { Dictionary } from "../../../../logic/Dictionaries";
import { CheckBold } from "mdi-material-ui";
import SubscriptionPlan from "../../../../stores/models/SubscriptionPlan";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.lightBackground.main,
            },
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.lightBackground.light
            }
        },
        cell: {
            textAlign: 'center',
        },
        check: {
            color: theme.palette.success.main,
            margin: -5
        },
        tableBody: {
            '& $cell': {
                border: '1px solid',
                borderColor: 'rgba(0,0,0,0.12)',
            }
        },
        lastRow: {
            '& $cell': {
                border: 'none'
            }
        },
        button: {
            textTransform: 'none'
        },
        planName: {
            fontWeight: 700,
            marginBottom: '4px',
        },
        price: {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
        tableContainer: {
            overflowX: 'inherit'
        }
    }),
);

interface PricingGridProps<T extends string> {
    features: Dictionary<T, React.ReactNode>;
    plans: SubscriptionPlan<T>[];
    onPlanSelected: (planName: string) => void;
}

export default function PricingGrid<T extends string>(props: PricingGridProps<T>) {

    // React hooks
    const classes = useStyles();

    return (
        <div>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    {/* Plan Names */}
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {props.plans.map((plan, index) => {
                                return (
                                    <TableCell className={classes.cell} key={`plan-name-${index}`}>
                                        <Typography variant="h5" className={classes.planName}>{plan.name}</Typography>
                                        <Typography variant="h4" className={classes.price}>{plan.price}</Typography>
                                        <Typography variant="body2">/month</Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    {/* Plan Features */}
                    <TableBody className={classes.tableBody}>
                        {Object.keys(props.features).map((featureKey: string, index: number) => {
                            const feature = props.features[featureKey as T];
                            return (
                                <TableRow key={`${featureKey}-${index}`} className={classes.row}>
                                    <TableCell className={classes.cell} variant="head">
                                        {feature as React.ReactNode}
                                    </TableCell>
                                    {props.plans.map((plan, planIndex) => {
                                        const planFeature = plan.features[featureKey as T];
                                        return (
                                            <TableCell className={classes.cell} key={`plan-${planIndex}-${featureKey}`}>
                                                {typeof planFeature === 'boolean'
                                                    ? planFeature
                                                        ? <CheckBold className={classes.check} />
                                                        : '-'
                                                    : planFeature as React.ReactNode}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    {/* Select Plan Buttons */}
                    <TableRow className={classes.lastRow}>
                        <TableCell className={classes.cell} />
                        {props.plans.map((plan, index) => {
                            return (
                                <TableCell className={classes.cell} key={`select-plan-${index}`}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => props.onPlanSelected(plan.name as T)}
                                        type="submit"
                                    >
                                        Select Plan
                                    </Button>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </Table>
            </TableContainer>
        </div>
    );
};