import React from "react";
import { DialogState } from "../../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../../Shared/Dialogs/ThemedDialogWithSpinner";
import TermsOfServiceOrganizationUser from "./TermsOfServiceOrganizationUser";
import TermsOfServiceVolunteerUser from "./TermsOfServiceVolunteerUser";

interface TermsOfServiceDialogProps {
    state: DialogState;
    organizationTerms?: boolean;
}

const TermsOfServiceDialog = (props: TermsOfServiceDialogProps) => {

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            title={'Terms of Service'}
            cancelText={'Close'}
        >
            {props.organizationTerms ?
                <TermsOfServiceOrganizationUser />
                : <TermsOfServiceVolunteerUser />
            }
        </ThemedDialogWithSpinner>
    )
}

export default TermsOfServiceDialog;