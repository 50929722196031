import React, { useContext } from "react";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { RootContext } from "../../stores";

const LogoutDialog = () => {

    const rootStore = useContext(RootContext);
    const navigationStore = rootStore.navigationStore;
    const userStore = rootStore.userStore;
    const state = navigationStore.appwideDialogStates.logoutDialog;

    const logout = async () => {
        state.setLoading(true);
        await userStore.logout();
        state.setOpen(false);
        state.setLoading(false);
    }

    return (
        <ConfirmationDialog
            state={state}
            title={'Logout'}
            confirmText={'Logout'}
            onConfirm={logout}
            content={'Are you sure you want to logout?'}
            fullScreen={false}
        />
    )
}

export default LogoutDialog;