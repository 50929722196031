import React, { useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, Container } from "@material-ui/core";
import { useParams, Route, Routes } from "react-router-dom";
import OrganizationInformation from "./OrganizationInformation";
import { RootContext } from "../../stores/";
import { observer } from "mobx-react";
import LoadingIndicator from "../Shared/LoadingIndicator";
import OrganizationHeader from "./Header/OrganizationHeader";
import { opportunitiesPathSegment, routeWithDescendants } from "../Navigation/Links/UrlConstructors";
import OrganizationOpportunites from "./VolunteerOpportunities/OrganizationOpportunities";
import OrganizationProfileBreadcrumbs from "./OrganizationProfileBreadcrumbs";
import { BreadcrumbPath } from "../../stores/models/BreadcrumbPath";
import { useIsOrgPortal, useOrgPortalSlug } from "../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        content: {
            paddingTop: '15px',
            textAlign: 'left',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column'
        },
        toolbarSpacer: theme.mixins.toolbar,
    })
);

const OrganizationProfile = observer(() => {

    /********* State *********/

    const [isLoading, setIsLoading] = React.useState(true);
    const [breadcrumbs] = React.useState(new BreadcrumbPath());

    /********* React hooks *********/

    const { orgId } = useParams() as { orgId: string };
    const rootStore = useContext(RootContext);
    const organizationStore = rootStore.organizationStore;
    const classes = useStyles();
    const isOrgPortal = useIsOrgPortal();
    const portalSlug = useOrgPortalSlug();

    useEffect(() => {
        requestOrganization();
    }, [orgId, portalSlug])

    /********* Helper methods *********/

    // Request the organization matching the id in the URL
    const requestOrganization = async () => {
        const organizationId = parseInt(orgId || "");

        if (!isNaN(organizationId) || portalSlug !== undefined) {
            setIsLoading(true);
            const orgIdentifier = portalSlug ? portalSlug : organizationId;
            await organizationStore.loadOrgInfo(orgIdentifier);
            setIsLoading(false);
        }
    }

    /********* Render *********/

    return (
        isLoading
            ? <LoadingIndicator />
            : <div className={classes.pageWrapper}>
                {!isOrgPortal ? <OrganizationHeader /> : null}
                <div className={classes.content}>
                    {!isOrgPortal ?
                        <Container>
                            <OrganizationProfileBreadcrumbs breadcrumbs={breadcrumbs} />
                        </Container>
                        : null
                    }
                    <Routes>
                        <Route path={routeWithDescendants(opportunitiesPathSegment)} element={<OrganizationOpportunites breadcrumbs={breadcrumbs} />} />
                        <Route path={"/"} element={<OrganizationInformation />} />
                    </Routes>
                </div>
            </div>
    );
});

export default OrganizationProfile;