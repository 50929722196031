import React from "react";
import { observer } from "mobx-react";
import { TuneVerticalVariant } from "mdi-material-ui";
import { IStatus } from "../../../../../stores/models/Status";
import { DialogState } from "../../../../../stores/models/DialogState";
import ButtonWithPopper from "../ButtonWithPopper";
import NestedMenu from "../../../../Navigation/NestedMenu";
import { IRole } from "../../../../../stores/models/Role";
import { ObjectContainingIdentifier, OptionCollection } from "../../../../../stores/models/OptionCollection";
import useVolunteerFilters from "./VolunteerFiltersHook";
import { ButtonProps, IconButton, Theme, useMediaQuery } from "@material-ui/core";
import REMButton from "../../../../../shared/modules/rem-conversion/components/Button";

export interface VolunteerFilterOptionCollections {
    roleOptions: OptionCollection<"id", ObjectContainingIdentifier<"id">, IRole>;
    statusOptions: OptionCollection<"id", ObjectContainingIdentifier<"id">, IStatus>
}

interface VolunteersFilterPopperProps extends VolunteerFilterOptionCollections, ButtonProps {
    state: DialogState;
    size?: "small" | "large" | "medium";
}

const VolunteersFilterPopper = observer((props: VolunteersFilterPopperProps) => {

    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));

    const filterMenuItems = useVolunteerFilters({ roleOptions: props.roleOptions, statusOptions: props.statusOptions });

    /********* Event Handlers *********/

    const onFilterButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.state.setOpen(!props.state.open);
    }

    // TODO: Improve keyboard usage

    /********* Render *********/

    return (
        <ButtonWithPopper
            Button={xsDown
                ? (ref) => <IconButton ref={ref} onClick={onFilterButtonClicked} className={props.className}>
                    <TuneVerticalVariant />
                </IconButton>
                : (ref) =>
                    <REMButton
                        startIcon={<TuneVerticalVariant />}
                        variant={"outlined"}
                        size={props.size ? props.size : "large"}
                        onClick={onFilterButtonClicked}
                        ref={ref}
                        {...props}
                    >
                        Filter
                    </REMButton>
            }
            state={props.state}
        >
            <NestedMenu
                menuItems={filterMenuItems}
                state={props.state}
            />
        </ButtonWithPopper >
    );
});

export default VolunteersFilterPopper;