import { observer } from 'mobx-react'
import InternalLink, { InternalLinkProps } from './InternalLink';
import { useLinkStyles } from './LinkStyles';
import clsx from "clsx";
import { Typography } from '@material-ui/core';

type StylizedInternalLinkProps = InternalLinkProps;

const StylizedInternalLink = observer((props: StylizedInternalLinkProps) => {
    const { link, inline } = useLinkStyles();

    const innerContent = typeof props.children === 'string'
            ? <Typography className={inline}>
                {props.children}
            </Typography>
            : props.children;

    return (
        <InternalLink {...props} children={innerContent} className={clsx(link, props.className)} />
    );
});

export default StylizedInternalLink;