import { makeObservable } from "mobx"
import { VolunteerHoursServices } from "../services/VolunteerHoursServices"
import { VolunteerHoursStore } from "../stores/VolunteerHoursStore"
import { LoadRecordListAction } from "../../../../shared/modules/record-collection/data-access/actions/LoadRecordListAction"
import { IAPIGetRequestParameters } from "../../../../stores/models/APIGetRequestParameters"
import { IServiceEntry, ServiceEntry } from "../entities/ServiceEntry"
import { IServerServiceEntry, ServerServiceEntry } from "../../../../stores/models/server/ServerServiceEntry"

export class LoadHoursAction extends LoadRecordListAction<ServiceEntry, keyof IServiceEntry, IServerServiceEntry> {
    constructor(store: VolunteerHoursStore, private services: VolunteerHoursServices) {
        super(store);
        makeObservable(this)
    }

    async fetchData(loadRequest: IAPIGetRequestParameters<keyof IServiceEntry>, param: unknown) {
        let response;
        if (this.store.rootStore.userStore.user.isOrganization) {
            const volunteerId: number | undefined = this.parseParam(param, 'volunteerId', 'number');
            if (volunteerId !== undefined) {
                response = await this.services.getServiceEntryDataForOrganizationVolunteer(volunteerId, loadRequest);
            } else {
                response = await this.services.getServiceEntryDataForOrganization(loadRequest);
            }
        } else {
            response = await this.services.getVolunteerServiceHistory(loadRequest);
        }
        return response;
    }

    protected transformData(data: IServerServiceEntry[]): ServiceEntry[] {
        return data.map(element => new ServerServiceEntry(element).deserialize());
    }
}