import { useEffect, useState } from 'react';
import { OrganizationServiceDataViewModel } from '../data-access/view-models/OrganizationServiceDataViewModel';
import { useRootStore } from '../../../shared/general/hooks';
import { Volunteer } from '../../../stores';
import { VolunteerServiceDataViewModel } from '../data-access/view-models/VolunteerServiceDataViewModel';
import useVolunteerOrganizations from './useVolunteerOrganizations';
import { ServiceDataViewModel } from '../data-access/view-models/ServiceDataViewModel';

interface VolunteerHoursViewModelProps {
    volunteer?: Volunteer;
}

export default function useVolunteerHoursViewModel(props: VolunteerHoursViewModelProps) {
    const { userStore, volunteerHoursStore } = useRootStore();
    const isOrganization = userStore.user.isOrganization;

    const { volunteerOrganizations } = useVolunteerOrganizations();

    const getViewModel = () => {
        const model = isOrganization
            ? new OrganizationServiceDataViewModel({
                store: volunteerHoursStore,
                volunteer: props.volunteer
            })
            : new VolunteerServiceDataViewModel(volunteerHoursStore);
        return model;
    }

    const [viewModel, setViewModel] = useState(() => getViewModel());

    useEffect(() => {
        setViewModel(getViewModel());
    }, [isOrganization]);

    useEffect(() => {
        if (volunteerOrganizations) {
            volunteerHoursStore.setOrganizationRoles(volunteerOrganizations);
        }
    }, [volunteerOrganizations])

    return viewModel as ServiceDataViewModel;
}