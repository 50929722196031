import { observable, action, computed, makeObservable } from "mobx";
import { Fields } from "./Fields";
import { validateOrganizationType, validateOrganizationIdentity, validateOrganizationDetails } from "../../logic/ValidationChecks";
import { SubscriptionPlanOptions, PlanLevelDictionary, isPlanLevel } from "../../components/Authentication/RegistrationSteps/Organization/Plans";
import { validateSubscriptionPlan } from "../../logic/ValidationChecks/StubOrganizationValidation";

export enum OrganizationType {
    None = 'none',
    Nonprofit = 'nonprofit',
    Forprofit = 'forprofit'
}

export enum StubOrganizationValidationChecks {
    OrganizationType,
    SubscriptionPlan,
    OrganizationIdentity,
    OrganizationDetails
}

export interface IStubOrganizationFields {
    name: string;
    ein: string;
    signUpCode: string;
    mission: string;
    description: string;
    website: string;
    type: OrganizationType;
    subscriptionPlanType: SubscriptionPlanOptions;
}

export class StubOrganization extends Fields<IStubOrganizationFields, StubOrganization> implements IStubOrganizationFields {
    @observable name = "";
    @observable ein = "";
    @observable signUpCode = "";
    @observable mission = "";
    @observable description = "";
    @observable website = "";
    @observable type = OrganizationType.Nonprofit; // TODO: Change back after demo
    @observable validationChecks = StubOrganizationValidationChecks.OrganizationType;
    @observable subscriptionPlanType = SubscriptionPlanOptions.Unselected;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    @action setName(name: string) {
        this.name = name;
    }

    @action setEIN(ein: string) {
        this.ein = ein;
    }

    @action setSignUpCode(signUpCode: string) {
        this.signUpCode = signUpCode;
    }

    @action setMission(mission: string) {
        this.mission = mission;
    }

    @action setDescription(description: string) {
        this.description = description;
    }

    @action setWebsite(website: string) {
        this.website = website;
    }

    @action setOrganizationType(type: OrganizationType) {
        this.type = type;
    }

    @action setValidationChecks(validationChecks: StubOrganizationValidationChecks) {
        this.validationChecks = validationChecks;
    }

    @action setSubscriptionPlanType(subscriptionPlanType: SubscriptionPlanOptions) {
        this.subscriptionPlanType = subscriptionPlanType;
    }

    @computed get subscriptionPlanLevel() {
        switch (this.subscriptionPlanType) {
            case SubscriptionPlanOptions.Generator:
                return 1;
            case SubscriptionPlanOptions.Transformer:
                return 2;
            case SubscriptionPlanOptions.Powerhouse:
                return 3;
            default:
                return -1;
        }
    }

    @computed get subscriptionPlan() {
        if (isPlanLevel(this.subscriptionPlanLevel)) {
            return PlanLevelDictionary[this.subscriptionPlanLevel];
        }
    }

    @computed get isForProfit() {
        return this.type === OrganizationType.Forprofit;
    }

    @computed get validationErrors() {
        if (this.validationChecks === StubOrganizationValidationChecks.OrganizationType) {
            return validateOrganizationType(this);
        } else if (this.validationChecks === StubOrganizationValidationChecks.SubscriptionPlan) {
            return validateSubscriptionPlan(this);
        } else if (this.validationChecks === StubOrganizationValidationChecks.OrganizationIdentity) {
            return validateOrganizationIdentity(this);
        } else {
            return validateOrganizationDetails(this);
        }
    }

    @computed get serializedData() {
        return {
            name: this.trimmed.name,
            ein: this.trimmed.ein,
            signUpCode: this.trimmed.signUpCode,
            mission: this.trimmed.mission,
            description: this.trimmed.description,
            website: this.trimmed.website,
            subscriptionPlan: this.subscriptionPlanType,
            isForProfit: this.isForProfit
        }
    }
}