import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useMediaQuery, Theme } from "@material-ui/core";
import { RootContext } from "../../stores";
import { ForgotPasswordData } from "../../stores/models/ForgotPasswordData";


const ForgotPasswordDialog = observer(() => {

    /********** State **********/

    const [forgotPasswordData, setForgotPasswordData] = useState(new ForgotPasswordData());
    const [validated, setValidated] = useState(false);
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState(false);

    /********** React hooks **********/

    const rootStore = useContext(RootContext);
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********** Helper constants **********/

    const dialogState = rootStore.navigationStore.appwideDialogStates.forgotPasswordDialog;

    /********** Effects **********/

    // Reset the dialog when it is first opened
    useEffect(() => {
        if (dialogState.open) {
            resetDialog();
        }
    }, [dialogState.open]);

    /********** Helper functions **********/

    const sendResetLinkRequest = async () => {
        if (forgotPasswordData.validated) {
            dialogState.setLoading(true);
            const response = await rootStore.userStore.forgotPassword(forgotPasswordData);
            if (response) {
                const { warning, success } = response;
                setSuccess(success);
                setWarning(warning ? warning.message : undefined);
            }
            dialogState.setLoading(false);
        } else {
            forgotPasswordData.setAllFieldsDirty();
            setValidated(true);
        }
    }

    const resetDialog = () => {
        setForgotPasswordData(new ForgotPasswordData());
        setValidated(false);
        setSuccess(false);
        setWarning(undefined);
    }

    const closeDialog = () => {
        dialogState.setOpen(false);
    }

    /********** Render **********/

    return (
        <ThemedDialogWithSpinner
            title={'Forgot Password'}
            primaryButtonProps={{ children: success ? 'Close' : 'Get Link' }}
            state={dialogState}
            DialogProps={{ fullScreen: xsDown, maxWidth: 'xs' }}
            onSubmit={success ? closeDialog : sendResetLinkRequest}
            hideCancelButton={success}
        >
            <ForgotPasswordForm
                forgotPasswordData={forgotPasswordData}
                validated={validated}
                success={success}
                warning={warning}
            />
        </ThemedDialogWithSpinner >
    );
});

export default ForgotPasswordDialog;