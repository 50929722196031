import { makeAutoObservable } from "mobx";

export interface IComponentState {
    open?: boolean;
    loading?: boolean;
    editing?: boolean;
}

export class ComponentState implements IComponentState {
    open?: boolean;
    loading?: boolean;
    editing?: boolean;

    constructor(state: IComponentState) {
        makeAutoObservable(this);

        this.open = state.open;
        this.loading = state.loading;
        this.editing = state.editing;
    }

    setOpen(open: boolean) {
        this.open = open;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setEditing(editing: boolean) {
        this.editing = editing;
    }
}