import { Address } from "./Address";
import { INonRepeatingPattern, IRepetitionPattern, RepetitionPattern } from "./RepetitionPattern";
import { IReactionDisposer, reaction, makeObservable, observable, action, computed } from "mobx";
import { UniqueIdentification } from "../../logic/UniqueIdentification";
import { ServerShift } from "./server/ServerShift";
import { Dictionary } from "../../logic/Dictionaries";
import { Sorter } from "../../logic/Sorter";
import { addDays } from "date-fns";
import { DateFormatter } from "../../logic/DateFormatter";
import { deepObserve, IDisposer } from "mobx-utils";
import { Fields } from "./Fields";
import { validateShift } from "../../logic/ValidationChecks/ShiftValidation";
import { ShiftRegistration } from ".";
import { IArchivable } from "./Archivable";
import { ShiftIdentification } from "./ShiftIdentification";

export interface IInstanceData {
    defaultDaysFromStartDate: number;
    deleted: boolean;
    parentId: number;
}

interface IShiftFields {
    slots: number;
}

export interface IShift extends IShiftFields, IArchivable {
    id?: number;
    address?: Address;
    addressId?: number;
    repetitionPattern: IRepetitionPattern | INonRepeatingPattern;
    instances?: IShift[];
    modifiedInstances?: IShift[];
    instanceData?: IInstanceData;
    shiftRegistrations?: ShiftRegistration[];
    registered?: boolean;
}

export class Shift extends Fields<IShiftFields, Shift> implements IShift {
    @observable address?: Address;
    @observable addressId?: number;
    @observable repetitionPattern: RepetitionPattern;
    @observable shiftInstanceOffset?: Shift;
    @observable shiftInstanceCount = 15;
    @observable instanceData?: IInstanceData;
    @observable instances: Shift[] = [];
    @observable slots = 1;
    @observable shiftRegistrations?: ShiftRegistration[];
    @observable registered = false;
    @observable archived: boolean | undefined;

    @observable private instanceModifications: Dictionary<number, Shift> = {};
    private static now = new Date();

    // Using the current date as a client identifier. 
    // It's unique enough for our purposes at this point.
    private clientId: number;
    private databaseId?: number;

    private instancesChangedReaction?: IReactionDisposer;
    private repetitionPatternChangedReaction?: IDisposer;

    constructor(shift?: IShift) {
        super();
        makeObservable(this);

        if (shift) {
            if (shift.id === undefined || shift.id >= 0) {
                this.databaseId = shift.id;
                this.clientId = UniqueIdentification.getClientId();
            } else {
                this.clientId = shift.id;
            }
            this.address = shift.address; // TODO: Create new Address
            this.addressId = shift.addressId;
            this.repetitionPattern = new RepetitionPattern(shift.repetitionPattern);
            this.instanceData = shift.instanceData;
            this.shiftRegistrations = shift.shiftRegistrations ? shift.shiftRegistrations.map(registration => new ShiftRegistration(registration)) : undefined;
            if (shift.modifiedInstances) {
                this.assignShiftRegistrationsToModifiedInstances(shift.modifiedInstances);
                this.initializeInstanceModificationsDictionary(shift.modifiedInstances);
            }
            this.slots = shift.slots;
            if (shift.registered) {
                this.registered = shift.registered;
            } else if (!this.isShiftSeries) {
                this.registered = this.shiftRegistrations ? this.shiftRegistrations.findIndex(registration => registration.currentVolunteerRegistered) !== -1 : false;
            }
            this.archived = shift.archived;
        } else {
            this.clientId = UniqueIdentification.getClientId();
            this.repetitionPattern = new RepetitionPattern();
        }

        this.setupReactions();
    }

    @action private assignShiftRegistrationsToModifiedInstances(modifiedInstances: IShift[]) {
        if (!this.shiftRegistrations) return;
        modifiedInstances.forEach(instance => {
            const matchingRegistrations = this.shiftRegistrations?.filter(registration => {
                if (!instance.instanceData) return false;
                return Shift.identificationDataMatches(
                    registration.shiftIdentification,
                    new ShiftIdentification({
                        defaultDaysFromStartDate: instance.instanceData.defaultDaysFromStartDate,
                        parentId: this.databaseId
                    })
                );
            });
            instance.shiftRegistrations = matchingRegistrations;
            instance.registered = matchingRegistrations?.findIndex(registration => registration.currentVolunteerRegistered) !== -1 || false;
        });
    }

    @action private setupReactions() {
        if (this.instanceData === undefined) {
            this.repetitionPatternChangedReaction = deepObserve(this.repetitionPattern, () => {
                this.updateInstances();
                // this.updateModifiedInstancesRepetitionPattern();
            });
            this.instancesChangedReaction = reaction(
                () => [
                    this.shiftInstanceCount,
                    this.shiftInstanceOffset,
                    this.sortedUpcomingModifiedInstances,
                    this.instanceModifications,
                    this.address
                ],
                () => {
                    this.updateInstances();
                },
                { fireImmediately: true }
            );
        }
    }

    /***** Shift Instances *****/

    getInstanceByIdentificationData(shiftIdentification: ShiftIdentification) {
        const defaultDaysFromStartDate = shiftIdentification.defaultDaysFromStartDate;
        if (shiftIdentification.parentId && defaultDaysFromStartDate !== undefined) {
            const modifiedInstanceMatchingDefaultDaysFromStartDate = this.findModifiedInstanceWithDefaultDaysFromStartDate(defaultDaysFromStartDate);
            if (modifiedInstanceMatchingDefaultDaysFromStartDate) {
                return modifiedInstanceMatchingDefaultDaysFromStartDate;
            } else if (this.verifyValidUnmodifiedInstance(defaultDaysFromStartDate)) {
                return this.createShiftInstanceWithDefaultDaysFromStartDate(defaultDaysFromStartDate);
            }
        } else {
            return this;
        }
    }

    isValidInstanceOffset(defaultDaysFromStartDate: number) {
        return this.isModifiedInstance(defaultDaysFromStartDate) ||
            this.repetitionPattern.verifyValidDaysFromStartDate(defaultDaysFromStartDate);
    }

    private isModifiedInstance(defaultDaysFromStartDate: number) {
        return this.findModifiedInstanceWithDefaultDaysFromStartDate(defaultDaysFromStartDate) !== undefined;
    }

    private findModifiedInstanceWithDefaultDaysFromStartDate(defaultDaysFromStartDate: number) {
        return this.instanceModifications[defaultDaysFromStartDate];
    }

    private verifyValidUnmodifiedInstance(defaultDaysFromStartDate: number) {
        if (this.isModifiedInstance(defaultDaysFromStartDate)) {
            return false;
        } else {
            return this.repetitionPattern.verifyValidDaysFromStartDate(defaultDaysFromStartDate);
        }
    }

    @action private updateInstances() {
        this.instances = this.getInstances();
    }

    private getInstances() {
        let instances = [] as Shift[];
        let currentShift: Shift | undefined = this.shiftInstanceOffset || this.defaultShiftInstanceOffset();
        while (currentShift && instances.length < this.shiftInstanceCount) {
            currentShift = this.getFollowingInstance(currentShift);
            if (currentShift) {
                instances.push(currentShift);
            }
        }
        return instances;
    }

    getFollowingInstance(currentShift: Shift) {
        if (this.repetitionPattern.repeats) {
            const nextDefaultInstance = this.getNextUnmodifiedInstance(currentShift);
            let nextModifiedInstance = this.getNextModifiedInstance(currentShift);
            while (nextModifiedInstance && nextModifiedInstance.instanceData?.deleted) {
                nextModifiedInstance = this.getNextModifiedInstance(nextModifiedInstance);
            }
            return this.getFirstOfTwoInstances(nextDefaultInstance, nextModifiedInstance);
        }
    }

    private getNextUnmodifiedInstance(offsetShift: Shift) {
        let currentShift: Shift | undefined = offsetShift;
        while (currentShift && currentShift.repetitionPattern.startTimestamp) {
            const nextOccurrence = this.repetitionPattern.getNextOccurrenceAfterTimestamp(currentShift.repetitionPattern.startTimestamp);
            if (!nextOccurrence) {
                break;
            }
            currentShift = this.createShiftInstanceWithStartTime(nextOccurrence);
            if (!this.instanceHasBeenModified(currentShift)) {
                return currentShift;
            }
        }
    }

    getNextInstanceAfterTimestamp(timestamp: Date, inclusive?: boolean) {
        if (inclusive) {
            timestamp.setMilliseconds(timestamp.getMilliseconds() - 1);
        }
        const shift = new Shift({
            repetitionPattern: {
                startTimestamp: timestamp,
                endTimestamp: timestamp
            },
            slots: 0
        });
        if (this.repetitionPattern.repeats) {
            return this.getFollowingInstance(shift);
        } else {
            const thisShiftFollowsTimestamp = Sorter.compareShifts(shift, this) !== 1;
            if (thisShiftFollowsTimestamp) {
                return this;
            }
        }
    }

    private getNextModifiedInstance(offsetShift: Shift) {
        return Sorter.findFirstShiftAfterShift(this.modifiedInstances, offsetShift);
    }

    private getFirstOfTwoInstances(instance1?: Shift, instance2?: Shift) {
        if (!instance1 || !instance2) {
            return instance1 || instance2;
        } else {
            let instances: Shift[] = [];
            instances.push(instance1, instance2);
            instances = Sorter.orderShiftsByStartTimestamp(instances) as Shift[];
            return instances[0];
        }
    }

    private createShiftInstanceWithStartTime(startTime: Date) {
        const defaultDaysFromStartDate = this.repetitionPattern.getDaysFromStartDate(startTime);
        const endTimestamp = this.repetitionPattern.addDurationToTimestamp(startTime);
        const shiftRegistrations = this.shiftRegistrations
            ? this.shiftRegistrations.filter(registration => registration.shiftIdentification.defaultDaysFromStartDate === defaultDaysFromStartDate)
            : undefined;
        return new Shift({
            instanceData: {
                defaultDaysFromStartDate: defaultDaysFromStartDate,
                deleted: false,
                parentId: this.id
            },
            address: this.address,
            addressId: this.addressId,
            repetitionPattern: new RepetitionPattern({
                startTimestamp: startTime,
                endTimestamp: endTimestamp,
            }),
            instances: [],
            slots: this.slots,
            shiftRegistrations: shiftRegistrations,
        });
    }

    createShiftInstanceWithDefaultDaysFromStartDate(defaultDaysFromStartDate: number) {
        if (this.repetitionPattern.startTimestamp) {
            const startTimestamp = addDays(this.repetitionPattern.startTimestamp, defaultDaysFromStartDate);
            return this.createShiftInstanceWithStartTime(startTimestamp);
        }
    }

    private defaultShiftInstanceOffset() {
        return new Shift({
            instanceData: {
                parentId: -1,
                deleted: false,
                defaultDaysFromStartDate: this.repetitionPattern.getDaysFromStartDate(Shift.now)
            },
            repetitionPattern: new RepetitionPattern({
                startTimestamp: Shift.now,
                endTimestamp: Shift.now
            }),
            slots: 1
        });
    }

    @action private initializeInstanceModificationsDictionary(instances: IShift[]) {
        instances.forEach(instanceProps => {
            if (instanceProps.instanceData) {
                instanceProps.instanceData.parentId = this.id;
            }
            const instance = new Shift(instanceProps);
            const key = this.getInstanceModificiationsKey(instance);
            if (key !== undefined) {
                this.instanceModifications[key] = instance;
            }
        });
    }

    private getInstanceModificiationsKey(instance: Shift) {
        return instance.instanceData?.defaultDaysFromStartDate;
    }

    private instanceHasBeenModified(instance: Shift) {
        const key = this.getInstanceModificiationsKey(instance);
        return key !== undefined && this.instanceModifications[key] !== undefined;
    }

    @computed private get sortedUpcomingModifiedInstances(): Shift[] {
        return Sorter.orderShiftsByStartTimestamp(this.upcomingModifiedInstances) as Shift[];
    }

    // Upcoming modified instances that haven't been deleted
    @computed private get upcomingModifiedInstances(): Shift[] {
        return this.futureModifiedInstances.filter(instance => {
            if (!instance.instanceData?.deleted) {
                return instance;
            }
        })
    }

    // All future modified instances, even those that have been deleted
    @computed private get futureModifiedInstances(): Shift[] {
        return Object.values(this.instanceModifications).filter(instance => {
            if (instance.repetitionPattern.startTimestamp
                && instance.repetitionPattern.startTimestamp > new Date()) {
                return instance;
            }
        })
    }

    // Modified instances that take place in the future and have been deleted
    @computed private get futureDeletedOccurrences(): Shift[] {
        return this.futureModifiedInstances.filter(instance => {
            if (instance.instanceData?.deleted) {
                return instance;
            }
        });
    }

    @computed get occurrencesLeft() {
        const numLeft = this.repetitionPattern.unmodifiedOccurrencesLeft - this.futureDeletedOccurrences.length;
        return numLeft >= 0 ? numLeft : 0;
    }

    @computed get nextInstance(): Shift | undefined {
        return this.getFollowingInstance(this.defaultShiftInstanceOffset());
    }

    // private updateModifiedInstancesRepetitionPattern() {
    // Check if repetition pattern has been altered, if not, update it
    // (Object.keys(this.instanceModifications) as unknown as number[]).forEach(key => {
    //     const instance = this.instanceModifications[key];
    //     const instanceData = instance.instanceData;
    //     if (instanceData && !instanceData.timingModified) {
    //         this.instanceModifications[key] = 
    //     }
    // })
    // }

    /***** Setters *****/

    @action setAddress(address?: Address) {
        this.address = address;
        this.addressId = address?.id;
        this.modifiedInstances.forEach(instance => {
            if (!instance.address && !instance.addressId) {
                instance.setAddress(address);
            }
        })
    }

    @action setSlots(slots: number) {
        const updatedSlots = isNaN(slots) ? 0 : slots;
        this.slots = updatedSlots;
    }

    @action setRegistered(registered: boolean) {
        this.registered = registered;
    }

    @action setArchived(archived: boolean) {
        this.archived = archived;
    }

    @action setDeleted(deleted: boolean) {
        if (this.isShiftInstance) {
            this.instanceData!.deleted = true;
        }
    }

    /***** Public Actions and Methods *****/

    @action modifyShiftInstance(shiftInstance: Shift) {
        const key = this.getInstanceModificiationsKey(shiftInstance);
        if (key !== undefined) {
            this.instanceModifications[key] = shiftInstance;
        }
    }

    @action deleteShiftInstance(shiftInstance: Shift) {
        const key = this.getInstanceModificiationsKey(shiftInstance);
        if (key !== undefined) {
            shiftInstance.instanceData!.deleted = true;
            this.instanceModifications[key] = shiftInstance;
        }
    }

    @action removeVolunteerRegistration(volunteerId: number) {
        if (!this.shiftRegistrations) return;
        for (let i = 0; i < this.shiftRegistrations.length; i++) {
            const index = this.shiftRegistrations[i].volunteerRegistrations.findIndex(registration => registration.volunteerId === volunteerId);
            if (index !== -1) {
                this.shiftRegistrations[i].volunteerRegistrations.splice(index, 1);
                return;
            }
        }
    }

    static identificationDataMatches(identificationData1: ShiftIdentification, identificationData2: ShiftIdentification) {
        if (
            'shiftId' in identificationData1 &&
            'shiftId' in identificationData2 &&
            identificationData1.shiftId !== undefined &&
            identificationData2.shiftId !== undefined &&
            identificationData1.shiftId > 0 &&
            identificationData2.shiftId > 0
        ) {
            return identificationData1.shiftId === identificationData2.shiftId;
        } else if ('parentId' in identificationData1 && 'parentId' in identificationData2) {
            return identificationData1.parentId === identificationData2.parentId &&
                identificationData1.defaultDaysFromStartDate === identificationData2.defaultDaysFromStartDate;
        } else {
            return false;
        }
    }

    /***** Computed Values *****/

    @computed get id() {
        if (this.databaseId !== undefined) {
            return this.databaseId;
        } else {
            return this.clientId;
        }
    }

    @computed get isTopLevelShift() {
        return this.isShiftSeries || this.isOneOffShift;
    }

    @computed get isShiftSeries() {
        return this.repetitionPattern.repeats;
    }

    @computed get isOneOffShift() {
        return !this.repetitionPattern.repeats && !this.isShiftInstance;
    }

    @computed get isShiftInstance() {
        return this.instanceData !== undefined;
    }

    @computed get modifiedInstances() {
        return Object.values(this.instanceModifications);
    }

    @computed get formattedNextOccurrenceDate() {
        return this.nextInstance ? DateFormatter.formatDate(this.nextInstance.repetitionPattern.startDate!) : '';
    }

    @computed get unlimitedSlots() {
        return this.slots === -1;
    }

    @computed get numRegistrations() {
        let numVolunteerRegistrations = 0;
        if (this.isShiftSeries) return numVolunteerRegistrations;
        this.shiftRegistrations?.forEach(shiftRegistration => {
            numVolunteerRegistrations += shiftRegistration.volunteerRegistrations.length;
        });
        return numVolunteerRegistrations;
    }

    @computed get registeredProxyVolunteerIds() {
        let ids = [] as number[];
        this.shiftRegistrations?.forEach(registration => {
            ids = ids.concat(registration.registeredProxies);
        });
        return ids;
    }

    @computed get currentVolunteerRegistered() {
        if (!this.shiftRegistrations) return false;
        for (let i = 0; i < this.shiftRegistrations.length; i++) {
            if (this.shiftRegistrations[i].currentVolunteerRegistered) {
                return true;
            }
        }
        return false;
    }

    @computed get userOrProxyRegistered() {
        return this.currentVolunteerRegistered || this.registeredProxyVolunteerIds.length > 0;
    }

    @computed get slotsLeft() {
        return this.slots - this.numRegistrations;
    }

    @computed get identificationData(): ShiftIdentification {
        return new ShiftIdentification({
            shiftId: this.id,
            parentId: this.instanceData ? this.instanceData.parentId : undefined,
            defaultDaysFromStartDate: this.instanceData ? this.instanceData.defaultDaysFromStartDate : undefined
        });
    }

    @computed get validationErrors() {
        return validateShift(this);
    }

    @computed get validated(): boolean {
        return this.validationErrors.length === 0 && this.repetitionPattern.validated;
    }

    /***** Serialization *****/

    serialize() {
        return new ServerShift(this);
    }
}