import { flow, makeObservable, observable } from "mobx";
import { IAction } from "../../../actions/interfaces/Action";
import { RecordListStore } from "../stores/RecordListStore";
import { ObjectWithIdentifier } from "../../../../../stores/models/NewOptionCollection";
import { ErrorCodes } from "../../../../../data/ErrorCodes";

export abstract class APIAction<RecordType extends ObjectWithIdentifier<RecordType>, SortableKeys extends string, ServerRecordType = RecordType, Param = unknown> implements IAction<Param> {

    @observable protected store: RecordListStore<RecordType, SortableKeys>;

    constructor(store: RecordListStore<RecordType, SortableKeys>) {
        makeObservable(this);
        this.store = store;
    }

    abstract makeAPICall(param: Param): Promise<void>;

    protected transformData(data: ServerRecordType[]): RecordType[] {
        return data as unknown as RecordType[];
    };

    execute = flow(function* (this: APIAction<RecordType, SortableKeys, ServerRecordType, Param>, param: Param) {
        try {
            yield this.makeAPICall(param);
        } catch (error) {
            if (typeof error === 'object' && error !== null && 'code' in error) {
                const displayableError = ErrorCodes.get((error as { code: any }).code);
                throw displayableError;
            }
        }
    });

    protected parseParam(param: Param, property: string, type: string) {
        if (typeof param !== 'object' || param === null) return;
        if (!(property in param)) return;
        if (typeof (param as any)[property] !== type) return;
        return (param as any)[property];
    }
}
