import { useState, useEffect } from "react";
import { SvgIconProps, makeStyles, Theme, createStyles, Container } from "@material-ui/core";
import ExtendedFab from "../Shared/ExtendedFloatingActionButton";
import clsx from 'clsx';
import { observer } from "mobx-react";
import { EditorState } from "../../stores/models/EditorState";
import FormLoadingSpinner from "../Organization/VolunteerOpportunities/FormLoadingSpinner";
import { NavigateAwayPrompt } from "../Navigation/Components";
// import { Prompt } from "react-router-dom"; // TODO: Use this again once react router v6 re-implements it

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        floatingButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '0.5rem',
            '& > *': {
                marginLeft: '0.5rem'
            }
        }
    }),
);

type Icon = (props: SvgIconProps) => JSX.Element;
const isIcon = (x: any): x is Icon => {
    if (typeof x === 'object') {
        return (x as { muiName: string }).muiName !== undefined
            && (x as { muiName: string }).muiName === 'SvgIcon';
    } else {
        return false;
    }
}

type ActionIcon = Icon | ((editing: boolean) => Icon);

export interface Action {
    label: string | ((editing: boolean) => string);
    togglesEditMode?: boolean;
    icon: Icon | ((editing: boolean) => Icon);
    onClick?: (editorState?: EditorState) => void;
    className?: string;
}

interface EditorProps {
    children: (editorState: EditorState) => JSX.Element;
    actions?: (editorState: EditorState) => Action[];
    className?: string;
    editing?: boolean;
    editsMade: boolean;
}

const Editor = observer((props: EditorProps) => {
    const classes = useStyles();
    const [editorState] = useState(new EditorState(props.editing));

    useEffect(() => {
        if (props.editsMade === true) {
            // Enable navigation prompt
            window.onbeforeunload = function () {
                if (shouldWarnAboutUnsavedChanges()) {
                    return true;
                }
            };
            // Remove navigation prompt
            return function cleanup() {
                window.onbeforeunload = null;
            }
        }
    }, [props.editsMade]);

    const handleButtonClicked = (action: Action) => {
        if (action.togglesEditMode) {
            editorState.setEditing(!editorState.editing);
        }
        if (action.onClick) {
            action.onClick(editorState);
        }
    };

    const getIcon = (icon: ActionIcon): Icon => {
        return isIcon(icon) ? (icon as Icon) : (icon(editorState.editing) as Icon);
    }

    const shouldWarnAboutUnsavedChanges = () => {
        return props.editsMade === true && editorState.editing;
    }

    return (
        <div id={'editor'} className={clsx(classes.root, props.className)}>
            {props.children(editorState)}
            {editorState.loading ? <FormLoadingSpinner /> : null}
            <NavigateAwayPrompt
                when={shouldWarnAboutUnsavedChanges()}
                message={'You might have unsaved changes. Are you sure you want to leave this page?'}
            />
            <Container>
                {(props.actions !== undefined && props.actions.length > 0) &&
                    <div className={classes.floatingButtons}>
                        {props.actions(editorState).map((action, index) => (
                            <ExtendedFab
                                key={`extended-fab-${index}`}
                                text={(typeof action.label === 'string') ? action.label : action.label(editorState.editing)}
                                onClick={() => { handleButtonClicked(action) }}
                                icon={getIcon(action.icon)}
                                className={action.className}
                            />
                        ))}
                    </div>
                }
            </Container>
        </div>
    );
});

export default Editor;