import { isValid } from "date-fns";
import { Shift } from "../stores/models/Shift";
import { ShiftDetails } from "../stores/models/ShiftDetails";

export class Sorter {

    public static orderShiftsByStartTimestamp = (shifts: Shift[]) => {
        const sortedShifts = shifts.slice().sort(Sorter.compareShifts);
        return sortedShifts;
    }

    public static orderShiftDetailsByStartTimestamp = (shiftDetails: ShiftDetails[]) => {
        const sortedShiftDetails = shiftDetails.slice().sort(Sorter.compareShiftDetails);
        return sortedShiftDetails;
    }

    public static findFirstShiftAfterShift = (shifts: Shift[], shiftOffset: Shift) => {
        const sortedShifts = shifts.slice().sort(Sorter.compareShifts);
        const firstShift = sortedShifts.find(shift => Sorter.compareShifts(shift, shiftOffset) === 1);
        return firstShift;
    }

    public static compareShiftDetails = (shiftDetails1: ShiftDetails, shiftDetails2: ShiftDetails) => {
        return this.compareShifts(shiftDetails1.shift, shiftDetails2.shift);
    }

    // Compares shifts based on their start and end times
    public static compareShifts = (shift1: Shift, shift2: Shift) => {
        const startTimestamp1 = shift1.repetitionPattern.sortOrderStartTimestamp;
        const startTimestamp2 = shift2.repetitionPattern.sortOrderStartTimestamp;
        if (!startTimestamp1 || !startTimestamp2) {
            return 0;
        }

        if (startTimestamp1 > startTimestamp2) {
            return 1;
        }

        if (startTimestamp1 < startTimestamp2) {
            return -1;
        }

        const endTimestamp1 = shift1.repetitionPattern.sortOrderEndTimestamp;
        const endTimestamp2 = shift2.repetitionPattern.sortOrderEndTimestamp;

        if (!endTimestamp1 || !endTimestamp2) {
            return 0;
        }

        if (endTimestamp1 > endTimestamp2) {
            return 1;
        }

        if (endTimestamp1 < endTimestamp2) {
            return -1;
        }

        // Differentiate shift instances that fall at the same time.
        if (shift1.isShiftInstance && shift2.isShiftInstance) {

            const offset1 = shift1.instanceData!.defaultDaysFromStartDate;
            const offset2 = shift2.instanceData!.defaultDaysFromStartDate;

            if (offset1 > offset2) {
                return 1;
            }

            if (offset2 > offset1) {
                return -1
            }
        }

        return 0;
    };

    private static getSortTypeForElements(elements: any[]) {
        let sortType = 'number';
        for (let j = 0; j < elements.length; j++) {
            const element = elements[j];
            if (element && typeof element === 'string' && isNaN(Number(element))) {
                if (isValid(new Date(element))) {
                    sortType = 'date';
                } else {
                    sortType = 'string';
                    break;
                }
            }
        }
        return sortType;
    }

    // Sorts an array of objects based on properties of those objects
    public static getSortedObjects<T extends object>(objectArray: T[], sorting: { column: keyof T, direction: 'asc' | 'desc' }[]) {
        let mutableObjectArray = JSON.parse(JSON.stringify(objectArray)) as T[];
        if (mutableObjectArray.length === 0) return mutableObjectArray;

        for (let i = sorting.length - 1; i >= 0; i--) {
            const sortDirection = sorting[i].direction;
            const sortColumn = sorting[i].column;
            const columnValues = mutableObjectArray.map(element => element[sortColumn]);
            const sortType = Sorter.getSortTypeForElements(columnValues);

            switch (sortType) {
                case 'number':
                    mutableObjectArray.sort((a, b) => {
                        if (sortDirection === 'asc') {
                            return (a[sortColumn] as unknown as number) - (b[sortColumn] as unknown as number);
                        } else {
                            return (b[sortColumn] as unknown as number) - (a[sortColumn] as unknown as number);
                        }
                    })
                    break;
                case 'string':
                    mutableObjectArray.sort((a, b) => {
                        if (sortDirection === 'asc') {
                            return (a[sortColumn] as unknown as string || "").localeCompare((b[sortColumn] as unknown as string) || "");
                        } else {
                            return (b[sortColumn] as unknown as string || "").localeCompare((a[sortColumn] as unknown as string) || "");
                        }
                    })
                    break;
                case 'date':
                    mutableObjectArray.sort((a, b) => {
                        if (sortDirection === 'asc') {
                            return new Date((a[sortColumn] as unknown as string)).getTime() - new Date((b[sortColumn] as unknown as string)).getTime();
                        } else {
                            return new Date((b[sortColumn] as unknown as string)).getTime() - new Date((a[sortColumn] as unknown as string)).getTime();
                        }
                    })
                    break;

            }
        }
        return mutableObjectArray;
    }
}