import { useContext, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { RootContext, SearchCriteria } from "../../../stores";
import { DialogState } from "../../../stores/models/DialogState";
import CheckboxList from "../../Shared/CheckboxList";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../../Shared/Dialogs/ThemedDialogWithSpinner";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filters: {
            // marginTop: theme.spacing(1),
            marginTop: theme.spacing(-2),
            '& > :not(:last-child)': {
                marginBottom: theme.spacing(2)
            }
        },
        sectionTitle: {
            fontWeight: 550
        },
    }),
);

interface MoreFiltersPromptProps {
    state: DialogState;
}

const MoreFiltersPrompt = observer((props: MoreFiltersPromptProps) => {

    /***** React hooks *****/

    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();
    const searchStore = useContext(RootContext).searchStore;

    /***** State *****/

    const [draftFilters, setDraftFilters] = useState(new SearchCriteria(searchStore.searchCriteria));

    /***** Effects *****/

    useEffect(() => {
        draftFilters.setAllFieldsDirty();
    }, [draftFilters]);

    useEffect(() => {
        if (props.state.open) {
            resetFilters();
        }
    }, [props.state.open]);

    /***** Event handlers *****/

    const onClose = () => {
        props.state.setOpen(false);
    }

    const onApplyFilters = () => {
        searchStore.setSearchCriteria(draftFilters);
        onClose();
    }

    /***** Helper method *****/

    const resetFilters = () => {
        setDraftFilters(new SearchCriteria(searchStore.searchCriteria));
    }

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            DialogProps={{ fullScreen: xsDown }}
            title={'Filters'}
            primaryButtonProps={{ children: 'Filter' }}
            onSubmit={onApplyFilters}
        >
            <div className={classes.filters}>
                {/* <div>
                    <Typography className={classes.sectionTitle}>Location</Typography>
                    <CheckboxList options={draftFilters.locationCheckboxOptions} />
                </div>
                <Divider /> */}
                {/* <Typography className={classes.sectionTitle}>Opportunity Details</Typography> */}
                {/* <OpportunityDetailsFilters /> */}
                <div>
                    {/* <Typography className={classes.sectionTitle}>Location</Typography> */}
                    <CheckboxList options={draftFilters.opportunityDetailsOptions} />
                </div>
            </div>
        </ThemedDialogWithSpinner>
    )
});

export default MoreFiltersPrompt;