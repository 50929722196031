import { HTMLAttributes } from "react";
import { observer } from "mobx-react";
import { StubOrganizationVolunteer } from "../../../../../../stores/models/StubOrganizationVolunteer";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import REMKeyboardDatePicker from "../../../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

interface BirthdateFieldProps extends HTMLAttributes<HTMLDivElement> {
    volunteer: StubOrganizationVolunteer;
}

const BirthdateField = observer((props: BirthdateFieldProps) => {

    /********** Event Handlers *********/

    const onBirthdateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        props.volunteer.setBirthdate(date ? date.toUTCString() : undefined);
    }

    /********** Render *********/

    return (
        <REMKeyboardDatePicker
            id="birthdate-picker"
            format="MM/dd/yyyy"
            minDate={new Date(1900, 1, 1)}
            value={props.volunteer.birthdate ? props.volunteer.birthdate : null}
            onChange={(date, value) => onBirthdateChange(date, value)}
            inputVariant="outlined"
            required
            disableFuture
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            error={props.volunteer.isFieldInvalid('birthdate')}
            helperText={props.volunteer.getErrorForField('birthdate')}
        />
    )
});

export default BirthdateField;