export type Dictionary<T extends string | number, U> = { [key in T]: U };

export function initializeObjectDictionary<Keys extends string, ObjectType extends object>(keys: Keys[], objectCreator: () => ObjectType) {
    let state = {} as Dictionary<Keys, ObjectType>;
    Object.values(keys).forEach(key => { state[key as Keys] = objectCreator(); });
    return state;
}

export function initializeDictionaryWithValue<Keys extends string, T>(keys: Keys[], value: T) {
    let state = {} as Dictionary<Keys, T>;
    Object.values(keys).forEach(key => { state[key as Keys] = value });
    return state;
}

export function pruneDictionary<key extends number | string, value>(dictionary: Dictionary<key, value>): Dictionary<key, Exclude<value, undefined>> {
    (Object.keys(dictionary) as key[]).map(key => {
        if (dictionary[key] === undefined) {
            delete dictionary[key];
        }
    });
    return dictionary as Dictionary<key, Exclude<value, undefined>>;
}

export function sortKeysBasedOnComparison<key extends number | string, value>(dictionary: Dictionary<key, value>, compareFn: (((a: value, b: value) => number))) {
    let sortedValues = (Object.values(dictionary) as value[]).sort(compareFn);
    return sortedValues.map(value => {
        return (Object.keys(dictionary) as key[]).find(key => dictionary[key] === value);
    }) as key[];
}