import { makeStyles, Theme, createStyles, Typography, Button, ButtonProps } from "@material-ui/core";
import { observer } from "mobx-react";
import ErrorAvatar from "./ErrorAvatar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            padding: theme.spacing(6),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(4)
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            '& > *:not(:last-child)': {
                marginBottom: theme.spacing(2)
            }
        },
        errorTitle: {
            marginTop: theme.spacing(1)
        }
    }));

interface ErrorMessageProps {
    title: string;
    details: string | JSX.Element;
    ButtonProps?: ButtonProps;
    footerDetails?: JSX.Element;
}

const ErrorMessage = observer((props: ErrorMessageProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <div id='error-message' className={classes.root}>
            <ErrorAvatar />
            <Typography variant="h4" className={classes.errorTitle}>{props.title}</Typography>
            {typeof props.details === 'string'
                ? <Typography>{props.details}</Typography>
                : props.details
            }
            {props.ButtonProps && <Button variant="contained" color="primary" {...props.ButtonProps} />}
            {props.footerDetails}
        </div>
    );
});

export default ErrorMessage;