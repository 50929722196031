import { makeAutoObservable } from "mobx";

export interface IPermission {
    id: number;
    label: string;
    type: string;
}

export class Permission implements IPermission {
    id: number;
    label: string;
    type: string;

    constructor(permission: IPermission) {
        makeAutoObservable(this);

        this.id = permission.id;
        this.label = permission.label;
        this.type = permission.type;
    }
}