import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography, TableCell, Button } from "@material-ui/core";
import { Fragment, useContext, useEffect, useState } from 'react';
import RegisterAdditionalVolunteersInstructions from './RegisterAdditionalVolunteersInstructions';
import { RootContext, Volunteer } from '../../../../stores';
import RecordTable from '../../../AccountSettings/Pages/Organization/RecordTable';
import { OptionCollection } from '../../../../stores/models/OptionCollection';
import { Option } from "../../../../stores/models/Option";
import { ISortableTableHeader, TableHeader } from '../../../../stores/models/TableHeader';
import FormDialog from '../../../Shared/Forms/DialogForm/FormDialog';
import useOrganizationRegistrationForm from '../../../Shared/Forms/LoadOrganizationRegistrationForm';
import { getInitialDialogStates } from '../../../../logic/DialogStateDictionary';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        },
        spacer: {
            height: theme.spacing(2)
        },
        // icon: {
        //     color: theme.palette.action.active
        // }
    })
);

enum DialogTypes {
    RegistrationForm = 'registrationForm',
    // RemoveVolunteerConfirmation = 'removalConfirmation'
}

const headCells = ([
    { id: 'select', alignment: 'center', disablePadding: true, label: '', sortable: false, checkbox: true },
    { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name', sortable: true },
    { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name', sortable: true },
    // { id: 'remove', alignment: 'center', disablePadding: true, label: 'Remove', sortable: false }
] as ISortableTableHeader<Volunteer>[]).map(headCell => new TableHeader(headCell));

interface RegisterAdditionalVolunteersProps {
    volunteerCollection: OptionCollection<'id', Volunteer>;
    organizationId?: number;
}

const RegisterAdditionalVolunteers = observer((props: RegisterAdditionalVolunteersProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const formStore = rootStore.formStore;

    /********* State *********/

    const [registrationForm, registrationFormLoaded, formSubmitted, resetForm] = useOrganizationRegistrationForm(props.organizationId, true);
    const [dialogStates] = useState(getInitialDialogStates(Object.values(DialogTypes)));

    /********* Effects *********/

    useEffect(() => {
        if (!dialogStates.registrationForm.open) {
            resetForm();
        }
    }, [dialogStates.registrationForm.open]);

    /********* Helper methods *********/

    const onRegistrationSubmitClicked = async () => {
        if (!registrationForm || props.organizationId === undefined) return { succeeded: false };
        const response = await formStore.submitForm(registrationForm, props.organizationId.toString(), true);
        if (response.succeeded && (response as unknown as { orgVolunteerId: number }).orgVolunteerId !== undefined) {
            const { firstName } = registrationForm.formData;
            const { lastName } = registrationForm.formData;
            if (firstName !== undefined && lastName !== undefined && typeof firstName === 'string' && typeof lastName === 'string') {
                props.volunteerCollection.addOrUpdateOption(
                    new Volunteer({
                        id: (response as unknown as { orgVolunteerId: number }).orgVolunteerId,
                        firstName: firstName,
                        lastName: lastName,
                        birthdate: undefined,
                    }))
            }
        }
        return response;
    }

    // const onRemoveAdditionalVolunteer = (option: Option<Volunteer>) => {
    //     setOptionToRemove(option);

    // }

    // const onConfirmedVolunteerRemoval = () => {
    //     if (!optionToRemove) return;
    //     props.volunteerCollection.removeOptionById(optionToRemove.object.id);
    // }

    /********* Render *********/

    return (
        <Fragment>
            <Typography className={classes.bold}>Select All Participating Volunteers</Typography>
            <RegisterAdditionalVolunteersInstructions />
            <div className={classes.spacer} />
            <Button variant='contained' color="primary" onClick={() => dialogStates.registrationForm.setOpen(true)}>
                Add Volunteer
            </Button>
            <div className={classes.spacer} />
            {props.volunteerCollection.options.length === 0
                ? null
                : <RecordTable
                    records={props.volunteerCollection}
                    tableHeaderCells={headCells}
                    displayFunctions={[
                        (option: Option<Volunteer>, rowIndex: number, cellIndex: number) => {
                            return null;
                        },
                        (option: Option<Volunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align={headCells[cellIndex].alignment} key={`${rowIndex}-${cellIndex}`}>
                                    {option.object.firstName}
                                </TableCell>
                            );
                        },
                        (option: Option<Volunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align={headCells[cellIndex].alignment} key={`${rowIndex}-${cellIndex}`}>
                                    {option.object.lastName}
                                </TableCell>
                            );
                        },
                        // (option: Option<Volunteer>, rowIndex: number, cellIndex: number) => {
                        //     return (
                        //         <TableCell align={headCells[cellIndex].alignment} key={`${rowIndex}-${cellIndex}`}>
                        //             <IconButton onClick={() => onRemoveAdditionalVolunteer(option)}>
                        //                 <Delete className={classes.icon} />
                        //             </IconButton>
                        //         </TableCell>
                        //     );
                        // },
                    ]}
                    onRowClicked={(record: Option<Volunteer>, rowIndex: number) => { record.toggleSelection(); }}
                />
            }
            {registrationForm !== undefined
                ? <FormDialog
                    state={dialogStates.registrationForm}
                    submitted={formSubmitted}
                    hideSubmittedWarning
                    form={registrationForm}
                    onFormSubmit={onRegistrationSubmitClicked}
                />
                : null
            }
            {/* <ConfirmationDialog
                state={dialogStates.removalConfirmation}
                title={"Remove Volunteer"}
                content={<Typography>Are you sure you want to remove this volunteer from this shift registration?</Typography>}
                confirmText={'Remove'}
                onConfirm={onConfirmedVolunteerRemoval}
                fullScreen={false}
            /> */}
        </Fragment>
    );
});

export default RegisterAdditionalVolunteers;