import { observer } from "mobx-react";
import { makeStyles, Grid, TextField, Typography, useMediaQuery, Theme } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { DialogState } from "../../../stores/models/DialogState";
import { Opportunity, RootContext } from "../../../stores";
import { Hand } from "mdi-material-ui";
import { OpportunityApplication } from "../../../stores/models/OpportunityApplication";
import ThemedDialogWithSpinner from "../../Shared/Dialogs/ThemedDialogWithSpinner";
import { VOLUNTEER_RESPONSE_PHONE_MAX_LENGTH, VOLUNTEER_RESPONSE_MESSAGE_MAX_LENGTH } from "../../../logic/ValidationChecks/FieldLengths";
import TextFieldWithCharacterLimit from "../../Shared/TextFieldWithCharacterLimit";
import { Alert, AlertTitle } from "@material-ui/lab";
import SuccessMessage from "../../Shared/SuccessMessage";

const useStyles = makeStyles(theme => ({
    instructions: {
        marginBottom: theme.spacing(3)
    },
    gridContainer: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        '& > div:not(:first-child) > $label': {
            [theme.breakpoints.only('xs')]: {
                marginTop: theme.spacing(2)
            }
        }
    },
    label: {
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right'
        },
    },
    required: {
        color: 'red'
    },
    emphasizedText: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
}));

interface VolunteerApplicationDialogProps {
    state: DialogState;
    opportunity: Opportunity;
}

const VolunteerApplicationDialog = observer((props: VolunteerApplicationDialogProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const volunteerStore = rootStore.volunteerStore;
    const volunteer = userStore.user.volunteer;

    /***** Helper method *****/

    const getNewOpportunityApplication = () => {
        return new OpportunityApplication(props.opportunity.id, userStore.user.email);
    }

    /***** State *****/

    const [opportunityApplication, setOpportunityApplication] = useState(getNewOpportunityApplication());
    const [applicationSubmitted, setApplicationSubmitted] = useState(false);

    /***** Effects *****/

    useEffect(() => {
        if (props.state.open) {
            setOpportunityApplication(getNewOpportunityApplication());
            setApplicationSubmitted(false);
        }
    }, [props.state.open]);

    /***** Event handlers *****/

    const onConfirm = async () => {
        props.state.setLoading(true);
        try {
            const response = await volunteerStore.respondToOpportunity(opportunityApplication);
            if (response && response.responseSubmitted) {
                props.opportunity.setResponseSubmitted();
                setApplicationSubmitted(true);
            }
        } catch {
            props.state.setOpen(false); // Handle user session expiring between opening and submitting form
        }
        props.state.setLoading(false);
    }

    /***** Helper constants *****/

    const emailError = opportunityApplication.errors.email;
    const phoneError = opportunityApplication.errors.phone;

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            DialogProps={{ maxWidth: 'sm', fullScreen: xsDown }}
            state={props.state}
            title={'Submit a Response'}
            onSubmit={onConfirm}
            primaryButtonProps={!applicationSubmitted ? { children: 'Volunteer', startIcon: <Hand /> } : undefined}
            cancelText={!applicationSubmitted ? undefined : 'Close'}
        >
            {!applicationSubmitted
                ? <React.Fragment>
                    <Alert severity="info" className={classes.instructions}>
                        <AlertTitle>Share Your Contact Information</AlertTitle>
                        {`Fill out this form to let ${props.opportunity.organizationName} know how to contact you about volunteering.`}
                    </Alert>
                    <Grid container spacing={xsDown ? 0 : 2} classes={{ container: classes.gridContainer }}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" className={classes.label}>Opportunity:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{props.opportunity.title}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" className={classes.label}>Name:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{volunteer?.firstName + " " + volunteer?.lastName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {xsDown
                                ? <Typography variant="subtitle1" className={classes.label}>
                                    Email: <span className={classes.required}>*</span>
                                </Typography>
                                : <Typography variant="subtitle1" className={classes.label}>
                                    <span className={classes.required}>*</span> Email:
                        </Typography>
                            }
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField
                                variant="outlined"
                                value={opportunityApplication.email}
                                onChange={event => opportunityApplication.setEmail(event.target.value)}
                                error={emailError.length > 0}
                                helperText={emailError.length > 0 ? emailError : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" className={classes.label}>Phone:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField
                                variant="outlined"
                                value={opportunityApplication.phone}
                                onChange={event => opportunityApplication.setPhone(event.target.value)}
                                error={phoneError.length > 0}
                                helperText={phoneError.length > 0 ? phoneError : undefined}
                                inputProps={{ maxLength: VOLUNTEER_RESPONSE_PHONE_MAX_LENGTH }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" className={classes.label}>Message:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextFieldWithCharacterLimit
                                characterLimit={VOLUNTEER_RESPONSE_MESSAGE_MAX_LENGTH}
                                TextFieldProps={{
                                    variant: "outlined",
                                    multiline: true,
                                    fullWidth: true,
                                    rows: 2,
                                    rowsMax: 10,
                                    value: opportunityApplication.message,
                                    onChange: event => opportunityApplication.setMessage(event.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
                : <SuccessMessage
                    title={'Thank you for volunteering!'}
                    details={(
                        <React.Fragment>
                            {`${props.opportunity.organizationName} will `}
                            <span className={classes.emphasizedText}>connect</span>
                            {` with you soon.`}
                        </React.Fragment>
                    )}
                />
            }
        </ThemedDialogWithSpinner >
    )
})

export default VolunteerApplicationDialog;