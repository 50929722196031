import { useMatch } from "react-router-dom"
import { ORG_PORTAL_PATH_PATTERN } from ".";

// Gets the organization-specific portal slug if there is one.
export const useOrgPortalSlug = () => {

    const match = useMatch(ORG_PORTAL_PATH_PATTERN);
    
    if (match) {
        return match.params.organization;
    }
}