import { observable, action, makeObservable } from "mobx";

export interface IEditorState {
    editing: boolean;
    loading: boolean;
}

export class EditorState implements IEditorState {
    editing = false;
    loading = false;

    constructor(editing?: boolean) {
        makeObservable(this, {
            editing: observable,
            loading: observable,
            setEditing: action,
            setLoading: action
        });

        if (editing) {
            this.editing = editing;
        }
    }

    setEditing(editing: boolean) {
        this.editing = editing;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }
}