import { useEffect } from "react";
import { observer } from "mobx-react";
import { OrganizationStubVolunteerLinking } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { StepProps } from "../../../../stores/models/Step";
import AuthenticateToLinkAccount from "../AuthenticateToLinkAccount";

const AuthenticateToLinkAccountStep = observer((props: StepProps<OrganizationStubVolunteerLinking>) => {

    const linkData = props.stepState.stepObject;

    /***** Effects *****/

    useEffect(() => {
        if (!props.stepState.completed) {
            linkData.setCreatingNewUser(false);
        }

        return function cleanup() {
            resetCreatingNewUser();
        }
    }, []);

    /***** Helper methods *****/

    const resetCreatingNewUser = () => {
        if (!props.stepState.completed) {
            linkData.setCreatingNewUser(false);
        }
    }

    /***** Event handlers *****/

    const onSwitchToSignUp = () => {
        linkData.setCreatingNewUser(true);
        setTimeout(() => { // Give the sign up step a chance to be added
            props.stepState.setCompleted(true);
        }, 0);
    }

    const onLoadingStateChanged = (isLoading: boolean) => {
        props.stepState.setLoading(isLoading);
    }

    /***** Render *****/

    return (
        <AuthenticateToLinkAccount
            title={`Hi ${linkData.linkDetails.volunteer.firstName}!`}
            prompt={`Please login or sign up to link your account to ${linkData.linkDetails.organizationName}.`}
            onSwitchToSignUp={onSwitchToSignUp}
            onLoadingStateChanged={onLoadingStateChanged}
        />
    );
});

export default AuthenticateToLinkAccountStep;