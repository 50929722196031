import { observable, action, makeAutoObservable, computed, reaction } from "mobx";
import { IDisposer } from "mobx-utils";

export interface ITablePaginationState {
    page?: number;
    rowsPerPage?: number;
    offset?: number;
    reloadNeeded?: boolean;
}

export class TablePaginationState implements ITablePaginationState {
    @observable page: number;
    @observable rowsPerPage: number;
    @observable isInitialLoad: boolean;
    @observable reloadNeeded: boolean;

    @observable private providedOffset?: number;
    private rowsPerPageChanged: IDisposer;

    constructor(state?: ITablePaginationState) {
        makeAutoObservable(this);

        this.rowsPerPage = (state && state.rowsPerPage !== undefined) ? state.rowsPerPage : 25;
        this.providedOffset = state?.offset;
        this.page = (state && state.page !== undefined) ? state.page : this.pageBasedOnOffset;
        this.isInitialLoad = true;
        this.reloadNeeded = false;

        this.rowsPerPageChanged = reaction(
            () => [this.rowsPerPage],
            () => {
                if (this.page !== 0) {
                    this.setPage(0);
                }
            }
        );
    }

    @action setPage(page: number) {
        this.page = page;
    }

    @action setRowsPerPage(rowsPerPage: number) {
        this.rowsPerPage = rowsPerPage;
    }

    @action setIsInitialLoad(isInitialLoad: boolean) {
        this.isInitialLoad = isInitialLoad;
    }

    @action setReloadNeeded(reloadNeeded: boolean) {
        this.reloadNeeded = reloadNeeded;
    }

    @computed get offset() {
        return this.rowsPerPage * this.page;
    }

    @computed get loadingParameters(): [number, number] {
        return [this.rowsPerPage, this.offset];
    }

    @computed private get pageBasedOnOffset() {
        if (this.providedOffset) {
            return this.providedOffset / this.rowsPerPage;
        } else {
            return 0;
        }
    }
}