import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Alert, AlertProps, AlertTitle } from "@material-ui/lab";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            textAlign: 'left'
        }
    })
);

interface FormSubmittedWarningProps extends AlertProps { }

const FormSubmittedWarning = observer((props: FormSubmittedWarningProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <Alert severity="warning"  {...props} className={clsx(classes.alert, props.className)}>
            <AlertTitle>You've already submitted this form.</AlertTitle>
            Please ignore this notice and proceed if you have updates to make.
        </Alert>
    );
});

export default FormSubmittedWarning;