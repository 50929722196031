import { differenceInWeeks, format, startOfMonth } from "date-fns";

export interface UTCDateComponents {
    year: number;
    month: number;
    date: number;
}

export class DateFormatter {

    public static getBeginningOfToday() {
        return new Date(new Date().setHours(0, 0, 0, 0));
    }

    public static occursThisYear(date: Date) {
        return date.getFullYear() === new Date().getFullYear();
    }

    public static getTimestampFromDateAndTime(date: Date, time: Date) {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes()
        );
    }

    public static getDurationBetweenTimestamps(start: Date, end: Date) {
        return end.valueOf() - start.valueOf();
    }

    public static getDuration(startDate: Date | null, startTime: Date | null, endDate: Date | null, endTime: Date | null) {
        if (startDate && startTime && endDate && endTime) {
            const startTimestamp = this.getTimestampFromDateAndTime(startDate, startTime);
            const endTimestamp = this.getTimestampFromDateAndTime(endDate, endTime);
            return this.getDurationBetweenTimestamps(startTimestamp, endTimestamp);
        } else {
            return 0;
        }
    }

    public static getUTCDate(date: Date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
    }

    public static getUTCDateComponents(date: Date): UTCDateComponents {
        return {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth(),
            date: date.getUTCDate()
        }
    }

    public static getDateInUTC(inputDate: string | Date, fullYear?: boolean): string {
        let date;
        if (typeof inputDate === 'string') {
            date = new Date(inputDate);
        } else {
            date = inputDate;
        }
        const utcDate = date.getUTCDate();
        const utcMonth = date.getUTCMonth() + 1;
        const utcYear = date.getUTCFullYear();
        return `${utcMonth}/${utcDate}/${utcYear.toString().substring(fullYear ? 0 : 2)}`;
    }

    public static getDateStringWithoutTimestamp(date: Date, fullYear?: boolean) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${month}/${day}/${year.toString().substring(fullYear ? 0 : 2)}`;
    }

    // Returns a number 1-5, indicating the position of the input date
    // in relation to other days in the same month that fall on the same
    // day of the week. (ex: 2 for the second Monday of the month)
    public static getDayOfWeekPositionInMonth(date: Date, exact?: boolean) {
        const firstDayOfMonth = startOfMonth(date);
        const diffInWeeks = differenceInWeeks(date, firstDayOfMonth);
        return diffInWeeks + 1;
    };

    // Returns the string representation of the week of
    // the month that the input date falls in.
    public static getDayOfWeekPositionInMonthString(date: Date) {
        const weekOfMonth = this.getDayOfWeekPositionInMonth(date);
        switch (weekOfMonth) {
            case 1:
                return 'first';
            case 2:
                return 'second';
            case 3:
                return 'third';
            case 4:
                return 'fourth';
            // case 5:
            //     return 'fifth';
            default:
                return 'last';
        }
    }

    public static formatDate(date: Date) {
        if (DateFormatter.occursThisYear(date)) {
            return format(date, 'MMMM d');
        } else {
            return format(date, 'MMMM d, yyyy');
        }
    }

    public static formatDateForFileName(date: Date) {
        return format(date, 'yyyy-MM-dd_HH-mm-ss');
    }

    public static formatDateTimeForDisplay(date: Date) {
        return date.toLocaleString('en-us');
    }
}