import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Paper, Typography } from "@material-ui/core";
import { Fragment, useState } from 'react';
import LoginOrSignUpForm from './LoginOrSignUpForm';
import VolunteerSignUpForm from './VolunteerSignUpForm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(0, 2)
        },
        paper: {
            margin: `${theme.spacing(2)}px auto`,
            padding: theme.spacing(4),
            maxWidth: `calc(40ch + ${theme.spacing(8)}px)`
        },
        title: {
            marginBottom: theme.spacing(4),
            color: theme.palette.action.active
        },

    })
);

const AuthenticationForms = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* State *********/

    const [signingUp, setSigningUp] = useState(false);

    /********* Render *********/

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {signingUp
                    ? <VolunteerSignUpForm
                        onSwitchToLogin={() => setSigningUp(false)}
                    />
                    : <Fragment>
                        <Typography variant={'h5'} className={classes.title}>Please login to continue.</Typography>
                        <LoginOrSignUpForm
                            onSwitchToSignUp={() => setSigningUp(true)}
                            onLoadingStateChanged={() => { return; }}
                        />
                    </Fragment>
                }
            </Paper >
        </div>
    );
});

export default AuthenticationForms;