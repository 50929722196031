import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { EmailPreference, OrganizationStubVolunteerLinking } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { StepProps } from "../../../../stores/models/Step";
import EmailPreferenceSelection from "../EmailPreferenceSelection";

const EmailPreferenceStep = observer((props: StepProps<OrganizationStubVolunteerLinking>) => {

    const linkData = props.stepState.stepObject;

    /***** Effects *****/

    useEffect(() => {
        props.stepState.setValidationMethod(validateEmailPreferenceSelected);
    }, []);

    /***** Helper methods *****/

    const validateEmailPreferenceSelected = () => {
        return linkData.emailPreference !== EmailPreference.Unselected;
    }

    /***** Event handlers *****/

    const onEmailPreferenceChanged = (emailPreference: EmailPreference) => {
        linkData.setEmailPreference(emailPreference);
    }

    /***** Render *****/

    return (
        <EmailPreferenceSelection
            organizationName={linkData.linkDetails.organizationName}
            emailPreference={linkData.emailPreference}
            onEmailPreferenceChanged={onEmailPreferenceChanged}
        />
    );
});

export default EmailPreferenceStep;