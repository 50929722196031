import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Chip as MUIChip, ChipProps as MUIChipProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '2rem',
            minWidth: '3rem',
            borderRadius: '1rem'
        },
        label: {
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem'
        },
        deleteIcon: {
            width: '1.375rem',
            height: '1.375rem',
            margin: '0 0.3125rem 0 -0.375rem'
        },
        icon: {
            marginLeft: '0.3125rem',
            marginRight: '-0.375rem'
        }
    })
);

interface ChipProps extends MUIChipProps {

}

const REMChip = observer((props: ChipProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIChip
            {...props}
            classes={{
                ...props.classes,
                root: clsx(classes.root, props.classes?.root),
                label: clsx(classes.label, props.classes?.label),
                deleteIcon: clsx(classes.deleteIcon, props.classes?.deleteIcon),
                icon: clsx(classes.icon, props.classes?.icon)
            }}
        >
            {props.children}
        </MUIChip>
    );
});

export default REMChip;