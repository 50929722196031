import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, isValidEmail, isValidPhoneNumber, getMaxLengthValidationCheck } from "./Validation";
import { OpportunityApplication } from "../../stores/models/OpportunityApplication";
import { VOLUNTEER_RESPONSE_MESSAGE_MAX_LENGTH } from "./FieldLengths";

const opportunityApplicationChecks: (opportunityApplication: OpportunityApplication) => ValidationCheck<OpportunityApplication>[] =
    (opportunityApplication: OpportunityApplication) => [
        {
            name: 'valid email',
            condition: (opportunityApplication: OpportunityApplication) => {
                return isValidEmail(opportunityApplication.email);
            },
            error: new ValidationError('You must enter a valid email.', ['email'])
        }, {
            name: 'valid phone',
            condition: (opportunityApplication: OpportunityApplication) => {
                return opportunityApplication.phone.trim().length === 0
                    || isValidPhoneNumber(opportunityApplication.phone);
            },
            error: new ValidationError('Please enter a valid 10-digit phone number.', ['phone'])
        },
        getMaxLengthValidationCheck(opportunityApplication.message, 'message', VOLUNTEER_RESPONSE_MESSAGE_MAX_LENGTH),
    ];

export function validateOpportunityApplication(opportunityApplication: OpportunityApplication) {
    return getValidationErrors(opportunityApplicationChecks, opportunityApplication);
}