import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, getMaxLengthValidationCheck, getFieldRequiredValidationCheck } from "./Validation";
import { Opportunity } from "../../stores";
import { OpportunityNeed, OpportunityScheduling, TimeCommitment, OpportunityLocation, OpportunityPermissionType } from "../../stores/models/Opportunity";
import { DateFormatter } from "../DateFormatter";
import { isValid } from "date-fns";
import { OPPORTUNITY_TITLE_MAX_LENGTH, OPPORTUNITY_POSITION_MAX_LENGTH, OPPORTUNITY_DESCRIPTION_MAX_LENGTH, OPPORTUNITY_TRAINING_DESCRIPTION_MAX_LENGTH } from "./FieldLengths";

const opportunityChecks: (opportunity: Opportunity) => ValidationCheck<Opportunity>[] =
    (opportunity: Opportunity) => [
        getFieldRequiredValidationCheck(opportunity.title, 'title', 'Please enter a search title.'),
        getMaxLengthValidationCheck(opportunity.title, 'title', OPPORTUNITY_TITLE_MAX_LENGTH),
        getFieldRequiredValidationCheck(opportunity.position, 'position', 'Please enter a role.'),
        getMaxLengthValidationCheck(opportunity.position, 'position', OPPORTUNITY_POSITION_MAX_LENGTH),
        getFieldRequiredValidationCheck(opportunity.description, 'description', 'Please enter a description for this opportunity.'),
        getMaxLengthValidationCheck(opportunity.description, 'description', OPPORTUNITY_DESCRIPTION_MAX_LENGTH),
        getMaxLengthValidationCheck(opportunity.trainingDescription, 'trainingDescription', OPPORTUNITY_TRAINING_DESCRIPTION_MAX_LENGTH),
        {
            name: 'valid need',
            condition: (opportunity: Opportunity) => {
                return opportunity.need !== OpportunityNeed.Unspecified;
            },
            error: new ValidationError('Please indicate whether this is a one time or ongoing need.', ['need'])
        }, {
            name: 'valid time commitment selection',
            condition: (opportunity: Opportunity) => {
                return opportunity.need !== OpportunityNeed.Ongoing
                    || opportunity.timeCommitment !== TimeCommitment.Unspecified;
            },
            error: new ValidationError('Please indicate whether this is a short term or long term commitment.', ['timeCommitment'])
        }, {
            name: 'valid location selection',
            condition: (opportunity: Opportunity) => {
                return opportunity.virtual !== OpportunityLocation.Unspecified;
            },
            error: new ValidationError('Please indicate whether this is a virtual or in person opportunity.', ['virtual'])
        }, {
            name: 'on site location selected',
            condition: (opportunity: Opportunity) => {
                return opportunity.virtual !== OpportunityLocation.InPerson || opportunity.addressCollection.addresses.length > 0;
            },
            error: new ValidationError('Please select at least one address.', ['addressCollection'])
        }, {
            name: 'valid scheduling selection',
            condition: (opportunity: Opportunity) => {
                return opportunity.scheduling !== OpportunityScheduling.Unspecified;
            },
            error: new ValidationError('Please indicate whether scheduling for this opportunity is flexible or shift-based.', ['scheduling'])
        }, {
            name: 'valid permissions selection',
            condition: (opportunity: Opportunity) => {
                return opportunity.scheduling === OpportunityScheduling.Flexible || opportunity.permissionType !== OpportunityPermissionType.Unspecified;
            },
            error: new ValidationError('Please select a permission level.', ['permissionType'])
        }, {
            name: 'expiration date required',
            condition: (opportunity: Opportunity) => {
                return !opportunity.active || opportunity.expirationTimestamp !== null;
            },
            error: new ValidationError('Expiration date is required.', ['expirationTimestamp'])
        }, {
            name: 'expiration date invalid',
            condition: (opportunity: Opportunity) => {
                return !opportunity.active || opportunity.expirationTimestamp === null || isValid(opportunity.expirationTimestamp);
            },
            error: new ValidationError('Expiration date is invalid.', ['expirationTimestamp'])
        }, {
            name: 'expiration date in future',
            condition: (opportunity: Opportunity) => {
                return !opportunity.active
                    || opportunity.expirationTimestamp === null
                    || !isValid(opportunity.expirationTimestamp)
                    || DateFormatter.getUTCDate(opportunity.expirationTimestamp) >= DateFormatter.getBeginningOfToday();
            },
            error: new ValidationError('The expiration date cannot be in the past.', ['expirationTimestamp'])
        }
    ];

export default function validateOpportunity(opportunity: Opportunity) {
    return getValidationErrors(opportunityChecks, opportunity);
}