import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import './App.css';
import { observer } from 'mobx-react';
import { configure } from 'mobx';
import { ThemeProvider, makeStyles, Theme, createStyles } from '@material-ui/core';
import BaseTheme from './styles/BaseTheme';
import clsx from 'clsx';
import { NavigationBar } from './components/';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Elements } from '@stripe/react-stripe-js';
import { RootContext } from './stores';
import LoadingIndicator from './components/Shared/LoadingIndicator';
import AppwideDialogs from './components/Navigation/AppwideDialogs';
import BaseRoutes from './components/Navigation/Routes/BaseRoutes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    app: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      background: '#ebebeb',
      minHeight: '100vh'
    },
    content: {
      background: '#ebebeb',
      width: '100%',
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column'
    },
    toolbar: theme.mixins.toolbar,
  })
);

const FONT_LINK = 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Play:wght@400;700&display=swap';

configure({ enforceActions: "observed" });

const App: React.FC = observer(() => {

  /********* React hooks *********/

  const classes = useStyles();
  const rootStore = useContext(RootContext);
  const paymentProcessingStore = rootStore.paymentProcessingStore;
  const userStore = rootStore.userStore;

  /********* State *********/

  const [isLoading, setIsLoading] = useState(true);

  /********* Helper method *********/

  const tryLoadingUser = useCallback(async () => {
    setIsLoading(true);
    await userStore.checkForAuthenticatedUser();
    setIsLoading(false);
  }, []);

  /********* Effect *********/

  useEffect(() => {
    tryLoadingUser();
  }, [tryLoadingUser]);

  /********* Render *********/

  return (
    <ThemeProvider theme={BaseTheme}>
      <div className={clsx("App", classes.app)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Elements stripe={paymentProcessingStore.stripePromise} options={{ fonts: [{ cssSrc: FONT_LINK }] }}>
            {isLoading
              ? <LoadingIndicator />
              : <Fragment>
                <NavigationBar />
                <div className={classes.content}  >
                  <div id="spacer" className={classes.toolbar} />
                  <BaseRoutes />
                </div>
              </Fragment>
            }
            <AppwideDialogs />
          </Elements>
        </MuiPickersUtilsProvider>
      </div>
    </ThemeProvider >
  );
})

export default App;
