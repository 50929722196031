import { useEffect } from 'react';
import { IdentifiableObject, ObjectContainingIdentifier, OptionCollection } from '../../../stores/models/OptionCollection';
import { TableController } from '../../../stores/models/TableController';

export default function useUpdateFilterSelections<
    IdentityKey extends string,
    Record extends IdentifiableObject<IdentityKey>,
    FilterIdentityKey extends string,
    FilterRecord extends IdentifiableObject<FilterIdentityKey>,
    StubRecord extends IdentifiableObject<IdentityKey> = Record,
    MergedRecord extends object = Record,
>(
    tableController: TableController<IdentityKey, Record, StubRecord, MergedRecord>,
    filter: string,
    filterCollection: OptionCollection<FilterIdentityKey, ObjectContainingIdentifier<FilterIdentityKey>, FilterRecord>
) {

    useEffect(() => {
        const selectedIds = filterCollection.selections.map(selection => selection.object[filterCollection.identifier]);
        tableController.tableState.filters.setFilter(filter, selectedIds);
    }, [filterCollection.selections]);

}