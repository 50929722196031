import { action, computed, makeObservable, observable } from "mobx";
import { ObjectContainingIdentifier } from "../../../../../stores/models/OptionCollection";
import { RecordListStore } from "../stores/RecordListStore";
import { ObjectWithIdentifier } from "../../../../../stores/models/NewOptionCollection";

interface IRecordListViewDataModel<ObjectInterface extends ObjectContainingIdentifier<'id'>, ObjectClass extends ObjectInterface> {
    editableRecord: ObjectClass | null;
    draft: ObjectClass;
    getNewDraftObject: () => void;
    setEditableRecord: (selected: ObjectClass) => void;
    resetDraft: () => void;
}

export abstract class RecordListViewDataModel<ObjectInterface extends ObjectWithIdentifier<ObjectInterface>, ObjectClass extends ObjectInterface> implements IRecordListViewDataModel<ObjectInterface, ObjectClass> {

    @observable editableRecord: ObjectClass | null = null;
    @observable draft: ObjectClass = this.getNewDraftObject();

    @observable protected store: RecordListStore<ObjectClass, Extract<keyof ObjectInterface, string>>;

    constructor(store: RecordListStore<ObjectClass, Extract<keyof ObjectInterface, string>>) {
        this.store = store;
        makeObservable(this);
    }

    @computed get collection() {
        return this.store.collection;
    }

    @computed get records() {
        return this.store.recordsToDisplay;
    }

    @computed get total() {
        return this.store.total;
    }

    @computed get paginationState() {
        return this.store.paginationState;
    }

    @computed get sortingState() {
        return this.store.sortingState;
    }

    @computed get numSelectedIncludingUnloaded() {
        return this.store.numSelectedIncludingUnloaded;
    }

    abstract getNewDraftObject(): ObjectClass;

    @action setEditableRecord(editableRecord: ObjectClass) {
        this.editableRecord = editableRecord;
    }

    @action resetDraft() {
        this.draft = this.getNewDraftObject();
    }
}
