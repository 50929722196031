import React, { useState, useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, Button, Fade, Dialog, DialogActions, DialogContent, useTheme, useMediaQuery, DialogTitle, Divider, Container } from "@material-ui/core";
import { observer } from "mobx-react";
import { DialogState } from "../../../stores/models/DialogState";
import { isValidCityState } from "../../../stores/models/City";
import { RootContext } from "../../../stores";
import { OptionCollection } from "../../../stores/models/OptionCollection";
import { OptionCategoryCollection } from "../../../stores/models/OptionCategoryCollection";
import CityPicker from "./CityPicker";
import CausePickerTitle from "./CausePickerTitle";
import SkillPickerTitle from "./SkillPickerTitle";
import ChipSelectionGrid from "../../Shared/ChipSelectionGrid";
import IconTileSelector from "../../Shared/IconTileSelector";
import { DialogTransition } from "../../Shared/Dialogs/DialogTransition";
import SkillCategoryIconDictionary from "../../../data/SkillCategoryIcons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: 0,
            background: theme.palette.primary.main,
        },
        titleText: {
            marginBottom: theme.spacing(2)
        },
        content: {
            paddingLeft: 0,
            paddingRight: 0,
            color: theme.palette.action.active,
        },
        centered: {
            textAlign: 'center'
        },
        dialogActions: {
            display: 'flex',
            justifyContent: 'space-between',
            color: theme.palette.action.active,
        },
        notDisplayed: {
            display: 'none'
        },
        hidden: {
            visibility: 'hidden'
        },
        cityPickerWrapper: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5),
        },
        causes: {
            marginTop: theme.spacing(1)
        },
    }),
);

export enum SearchPromptSteps {
    CitySelection = 1,
    CauseSelection = 2,
    SkillsSelection = 3
}

const numSteps = 3;

enum Animations {
    CitySelectionText = "citySelectionText",
    CityInput = "cityInput",
    BottomBar = "bottomBar",
}

const getInitialAnimationStates = () => {
    let state = {} as { [key in Animations]: boolean };
    Object.values(Animations).forEach(animation => {
        state[animation as Animations] = false;
    });
    return state;
}

const SEARCH_DIALOG_CONTENT_ID = "search-dialog-content";

interface MobileSearchPromptProps {
    state: DialogState;
    stepToDisplay?: SearchPromptSteps; // If no step is specified, all steps will be displayed in order
    onCompletedAllSteps?: () => void;
}

const MobileSearchPrompt = observer((props: MobileSearchPromptProps) => {

    /****** React hooks ******/

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const searchStore = rootStore.searchStore;
    const searchCriteria = searchStore.searchCriteria;

    /****** Helper methods ******/

    const getCauseOptions = () => {
        return new OptionCollection(searchCriteria.causeOptions);
    };

    const getSkillOptions = () => {
        return new OptionCategoryCollection(searchCriteria.skillOptions);
    };

    /****** State ******/

    const [causeOptions, setCauseOptions] = useState(getCauseOptions());
    const [skillOptions, setSkillOptions] = useState(getSkillOptions());
    const [city, setCity] = useState(searchCriteria.cityText);
    const [step, setStep] = useState(props.stepToDisplay ? props.stepToDisplay : SearchPromptSteps.CitySelection);
    const [animations, setAnimations] = useState(getInitialAnimationStates());

    /****** Helper constant ******/

    const displayAllSteps = props.stepToDisplay === undefined;

    /****** Effects ******/

    /** Reset options after they're loaded **/
    useEffect(() => {
        setCauseOptions(getCauseOptions());
    }, [searchCriteria.causeOptions]);

    useEffect(() => {
        setSkillOptions(getSkillOptions());
    }, [searchCriteria.skillOptions]);

    /** Reset options when the dialog is opened **/
    useEffect(() => {
        if (props.state.open) {
            setCity(searchCriteria.cityText);
            setCauseOptions(getCauseOptions());
            setSkillOptions(getSkillOptions());
        }
    }, [props.state.open]);

    /** Set the steps to display **/
    useEffect(() => {
        if (props.stepToDisplay) {
            setStep(props.stepToDisplay);
        }
    }, [props.stepToDisplay]);

    /****** Event handlers ******/

    const onCityChanged = (cityText: string) => {
        setCity(cityText);
    };

    const onSkip = () => {
        clearData();
        advance();
    }

    const onCancel = () => {
        closeDialog();
    }

    const onContinue = () => {
        if (displayAllSteps) {
            advance();
        } else {
            saveData();
            closeDialog();
        }
    }

    const onClear = () => {
        clearData();
    }

    const onBack = () => {
        if (step === 2) {
            // Reset animations to re-trigger
            // focus on autocomplete field
            setAnimations({
                ...animations,
                citySelectionText: false
            });
        }
        if (step !== 1) {
            setStep(step - 1);
            scrollToTopOfDialog();
        }
    }

    const onFinishedAnimation = (animation: Animations) => {
        setAnimations({ ...animations, [animation]: true });
    }

    /****** Helper methods ******/

    const closeDialog = () => {
        props.state.setOpen(false);
    }

    const advance = () => {
        if (step < numSteps) {
            setStep(step + 1);
            scrollToTopOfDialog();
        } else {
            saveData();
            closeDialog();
            if (displayAllSteps && props.onCompletedAllSteps) {
                props.onCompletedAllSteps();
            }
        }
    }

    const scrollToTopOfDialog = () => {
        document.querySelector(`#${SEARCH_DIALOG_CONTENT_ID}`)?.scrollTo(0, 0);
    }

    const saveData = () => {
        if (!displayAllSteps) {
            if (step === SearchPromptSteps.CitySelection) {
                searchStore.searchCriteria.setCityText(city);
            } else if (step === SearchPromptSteps.CauseSelection) {
                searchStore.searchCriteria.setCauseOptions(causeOptions);
            } else {
                searchStore.searchCriteria.setSkillOptions(skillOptions);
            }
        } else if (step === numSteps) {
            searchStore.searchCriteria.setCityText(city);
            searchStore.searchCriteria.setCauseOptions(causeOptions);
            searchStore.searchCriteria.setSkillOptions(skillOptions);
        }
    }

    const clearData = () => {
        if (step === SearchPromptSteps.CitySelection) {
            setCity('');
        } else if (step === SearchPromptSteps.CauseSelection) {
            causeOptions.setSelections([]);
        } else {
            skillOptions.setSelections([]);
        }
    }

    const isContinueDisabledForCitySelection = () => {
        return city.trim().length === 0 || !isValidCityState(city);
    }

    const isContinueDisabled = () => {
        if (displayAllSteps) {
            switch (step) {
                case SearchPromptSteps.CitySelection:
                    return isContinueDisabledForCitySelection();
                case SearchPromptSteps.CauseSelection:
                    return causeOptions.selections.length === 0;
                case SearchPromptSteps.SkillsSelection:
                    return skillOptions.selections.length === 0;
            }
        } else {
            if (step === SearchPromptSteps.CitySelection) {
                return isContinueDisabledForCitySelection();
            } else {
                return false;
            }
        }
    }

    /****** Render ******/

    return (
        <Dialog
            open={props.state.open}
            fullScreen={smDown}
            onClose={closeDialog}
            fullWidth
            maxWidth={step === SearchPromptSteps.SkillsSelection ? "sm" : "sm"}
            className={classes.centered}
            TransitionComponent={DialogTransition}
        >
            {step !== SearchPromptSteps.CitySelection
                ? <DialogTitle className={classes.title}>
                    {step === SearchPromptSteps.CauseSelection
                        ? 
                        // <Fade
                        //     in={step === SearchPromptSteps.CauseSelection}
                        //     timeout={step === SearchPromptSteps.CauseSelection ? 1800 : 0}
                        // >
                            <div>
                                <div className={classes.titleText}>
                                    <CausePickerTitle />
                                </div>
                            </div>
                        // </Fade>
                        : null
                    }
                    {step === SearchPromptSteps.SkillsSelection
                        ? 
                        // <Fade
                        //     in={step === SearchPromptSteps.SkillsSelection}
                        //     timeout={step === SearchPromptSteps.SkillsSelection ? 1800 : 0}
                        // >
                            <div>
                                <div className={classes.titleText}>
                                    <SkillPickerTitle />
                                </div>
                            </div>
                        // </Fade>
                        : null
                    }
                </DialogTitle>
                : null
            }
            <DialogContent className={classes.content} id={SEARCH_DIALOG_CONTENT_ID}>
                {/******* City Selection *******/}
                {/* <Fade
                    in={props.state.open && step === SearchPromptSteps.CitySelection}
                    timeout={step === SearchPromptSteps.CitySelection ? 1800 : 0}
                    onEntered={() => onFinishedAnimation(Animations.CitySelectionText)}
                > */}
                    <div className={step !== SearchPromptSteps.CitySelection ? classes.notDisplayed : classes.centered}>
                        <div className={!smDown ? classes.cityPickerWrapper : undefined}>
                            <CityPicker
                                city={city}
                                onCityChanged={onCityChanged}
                                cityInputAnimateIn={
                                    (animations.citySelectionText || animations.cityInput)
                                    && step === SearchPromptSteps.CitySelection
                                }
                                onCityInputAnimatedIn={() => onFinishedAnimation(Animations.CityInput)}
                                focusCityInput={true}
                                    // animations.citySelectionText}
                            />
                        </div>
                    </div>
                {/* </Fade> */}

                {/******* Cause Selection *******/}
                {/* <Fade
                    in={step === SearchPromptSteps.CauseSelection}
                    timeout={step === SearchPromptSteps.CauseSelection ? 1800 : 0}
                > */}
                    <div className={step === SearchPromptSteps.CauseSelection ? classes.causes : classes.notDisplayed}>
                        <Container>
                            <IconTileSelector optionCollection={causeOptions} />
                        </Container>
                    </div>
                {/* </Fade> */}

                {/******* Skill Selection *******/}
                {/* <Fade
                    in={step === SearchPromptSteps.SkillsSelection}
                    timeout={step === SearchPromptSteps.SkillsSelection ? 1800 : 0}
                > */}
                    <div className={step === SearchPromptSteps.SkillsSelection ? undefined : classes.notDisplayed}>
                        <Container>
                            <ChipSelectionGrid optionCollection={skillOptions} iconDictionary={SkillCategoryIconDictionary} />
                        </Container>
                    </div>
                {/* </Fade> */}
            </DialogContent>

            {/******* Bottom Bar *******/}
            {/* <Fade
                in={animations.cityInput || step > 1 || animations.bottomBar}
                timeout={step > 1 ? 1800 : 1000}
                onEntered={() => onFinishedAnimation(Animations.BottomBar)}
            > */}
                <div>
                    <Divider />
                    <DialogActions className={classes.dialogActions}>
                        <Fade in={step > 1 || !displayAllSteps} timeout={displayAllSteps ? 1000 : 0}>
                            <Button
                                onClick={displayAllSteps ? onBack : onClear}
                                className={step === 1 && displayAllSteps ? classes.hidden : undefined}
                                color="inherit"
                            >
                                {displayAllSteps ? 'Back' : 'Reset'}
                            </Button>
                        </Fade>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isContinueDisabled()}
                            onClick={onContinue}
                        >
                            {displayAllSteps && step < numSteps ? 'Continue' : 'Search'}
                        </Button>
                        {displayAllSteps
                            ? <Button onClick={onSkip} color="inherit">Skip</Button>
                            : <Button onClick={onCancel} color="inherit">Close</Button>
                        }
                    </DialogActions>
                </div>
            {/* </Fade> */}
        </Dialog >
    )
});

export default MobileSearchPrompt;