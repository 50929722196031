import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            marginBottom: theme.spacing(2)
        }
    })
);

interface FieldSectionProps extends React.HTMLAttributes<HTMLDivElement> {

}

const FieldSection = observer((props: FieldSectionProps) => {
    
    /********* React hooks *********/
    
    const classes = useStyles();
    
    /********* Render *********/
    
    return (
        <div className={classes.section}>
            {props.children}
        </div>
    );
});

export default FieldSection;