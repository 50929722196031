import { Container, Paper, makeStyles, Theme, createStyles, Typography, useMediaQuery, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import clsx from 'clsx';
import { Fragment, useContext } from "react";
import useResetScrollPosition from "../../../../Shared/Hooks/ResetScrollPosition";
import { getSearchLink } from "../../../../Navigation/Links/UrlConstructors";
import { RootContext } from "../../../../../stores";
import { Account, AccountEdit, Calendar, Domain } from "mdi-material-ui";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Dictionary } from "../../../../../logic/Dictionaries";
import SearchFilters from "../../../../HelpPage/images/search-filters.png";
import SearchPage from "../../../../HelpPage/images/search-page.png";
import OrganizationSearch from "../../../../HelpPage/images/organization-search.png";
import OpportunityPage from "../../../../HelpPage/images/opportunity-page.png";
import OrganizationPage from "../../../../HelpPage/images/organization-page.png";
import ShiftRegistration from "../../../../HelpPage/images/shift-registration-popup.png";
import PermissionNeeded from "../../../../HelpPage/images/permission-needed.png";
import OrganizationTab from "../../../../HelpPage/images/organization-tab.png";
import OpportunitiesTab from "../../../../HelpPage/images/opportunities-tab.png";
import UpcomingSchedule from "../../../../HelpPage/images/upcoming-schedule.png";
import SettingsPage from "./images/settings-edit-mode.png";
import DashboardPage from "./images/dashboard.png";
import LocationsPage from "./images/locations.png";
import PortalPage from "./images/portal-page.png";
import PortalExample from "./images/portal-example.png";
import SchedulingPage from "./images/scheduling.png";
import ServiceDataPage from "./images/service-data.png";
import VolunteersPage from "./images/volunteers.png";
import OpportunitiesPage from "./images/opportunities.png";
import AddLocation from "./images/add-location.png";
import AddOpportunity from "./images/add-opportunity.png";
import AddOpportunityButton from "./images/add-opportunity-button.png";
import AddShift from "./images/add-shift.png";
import AddServiceEntry from "./images/add-service-entry.png";
import AddVolunteer from "./images/add-volunteer.png";
import Causes from "./images/causes.png";
import DeleteOpportunity from "./images/delete-opportunity.png";
import EditLocation from "./images/edit-location.png";
import EditOpportunityIcon from "./images/edit-opportunity-icon.png";
import EditServiceEntry from "./images/edit-service-entry.png";
import MergeVolunteers from "./images/merge-volunteers-select.png";
import MergeVolunteersDialog from "./images/merge-volunteers-values-selected.png";
import ImportVolunteers from "./images/import-volunteers.png";
import InformationPage from "./images/information.png";
import OpportunityPermissions from "./images/opportunity-permissions.png";
import OpportunityTags from "./images/opportunity-tags.png";
import OpportunityStatus from "./images/opportunity-status.png";
import ServiceEntryAddTags from "./images/service-entry-add-tags.png";
import ServiceEntryDelete from "./images/service-entry-delete.png";
import ServiceEntryUpdateStatuses from "./images/service-entry-update-statuses.png";
import ServiceEntryVolunteerEdits from "./images/service-entry-volunteer-edits.png";
import ShiftDetailsWithRegistrations from "./images/shift-details-with-registrations.png";
import ShiftDetailsRegistration from "./images/shift-registration.png";
import ShiftDetailsRegistrationUpdateStatus from "./images/shift-registration-update-status.png";
import ShiftDetailsVolunteerRegistrations from "./images/shift-registrations.png";
import ViewOpportunityIcon from "./images/view-opportunity-icon.png";
import ViewOrganization from "./images/view-organization.png";
import VolunteerDetails from "./images/volunteer-details.png";
import VolunteerEmail from "./images/volunteer-email.png";
import VolunteerPermissions from "./images/volunteer-permissions.png";
import VolunteerRoles from "./images/volunteer-roles.png";
import VolunteerStatus from "./images/volunteer-status.png";
import TableOfContents, { TableOfContentsItem } from "../../../../Navigation/TableOfContents";
import { useLocation } from "react-router-dom";
import AnchorLink from "../../../../Navigation/Components/AnchorLink";
import { StylizedExternalLink, StylizedInternalLink } from "../../../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'left',
            flexGrow: 1
        },
        wrapperContainer: {
            display: 'flex'
        },
        tableOfContents: {
            position: 'sticky',
            top: theme.spacing(10),
        },
        content: {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2)
            },
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3),
            '& h3': {
                fontWeight: 600,
                marginBottom: theme.spacing(2)
            },
            '& h4, h5, h6': {
                scrollMarginTop: `${theme.spacing(10)}px`,
            },
            '& h4': {
                marginBottom: theme.spacing(2),
                fontWeight: 600
            },
            '& p': {
                marginBottom: theme.spacing(2)
            },
            '& h4:not(:first-child)': {
                marginTop: theme.spacing(4)
            },
            '& h5': {
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(2),
                fontWeight: 300
            },
            '& img': {
                marginBottom: theme.spacing(3)
            },
        },
        image: {
            maxWidth: '350px',
        },
        fullWidth: {
            width: '100%',
            marginBottom: theme.spacing(3)
        },
        flex: {
            display: 'flex',
            '& > :first-child': {
                paddingRight: theme.spacing(2)
            }
        },
        subsection: {
            marginTop: theme.spacing(2),
            '& h5': {
                marginTop: 0
            }
        },
        icon: {
            marginBottom: '-6px'
        },
    })
);

enum HelpSections {
    YourProfile = 'your-profile',
    DashboardPage = 'dashboard-page',
    InformationPage = 'information-page',
    LocationsPage = 'locations-page',
    AddingLocation = 'adding-location',
    EditingLocation = 'editing-or-deleting-location',
    PortalPage = 'portal-page',
    OpportunitiesPage = 'opportunities-page',
    AddingOpportunity = 'adding-opportunity',
    EditingOpportunity = 'editing-opportunity',
    ViewingOpportunity = 'viewing-opportunity',
    DeletingOpportunity = 'deleting-opportunity',
    OpportunityEditorPage = 'opportunity-editor',
    OpportunityPermissions = 'opportunity-permissions',
    OpportunityTags = 'opportunity-tags',
    OpportunityStatus = 'opportunity-status',
    SchedulingPage = 'scheduling-page',
    AddingShift = 'adding-shift',
    EditingShift = 'editing-shift',
    RegisteringVolunteers = 'volunteer-registrations',
    VolunteersPage = 'volunteers-page',
    AddingVolunteer = 'adding-volunteer',
    ImportingVolunteers = 'importing-volunteers',
    MergingVolunteers = 'merging-volunteers',
    EditingVolunteers = 'editing-volunteers',
    VolunteerDetailsPage = 'volunteer-details-page',
    VolunteerEmailField = 'volunteer-email',
    VolunteerStatusField = 'volunteer-status',
    VolunteerPermissionsField = 'volunteer-permissions',
    VolunteerRolesField = 'volunteer-roles',
    ServiceDataPage = 'service-data-page',
    AddingServiceEntry = 'adding-volunteer-hours',
    EditingServiceEntry = 'editing-volunteer-hours',
    BulkEditingServiceEntries = 'bulk-editing-volunteer-hours',
    DeletingServiceEntries = 'deleting-volunteer-hours',
    ReviewingServiceEntryVolunteerEdits = 'reviewing-volunteer-hours',
    SettingsPage = 'settings-page',
    PublicPages = 'public-pages',
    PublicOrganizationPage = 'public-organization-page',
    PublicOpportunityPage = 'public-opportunity-page',
    SearchPage = 'search-page',
    SearchOpportunities = 'finding-an-opportunity',
    SearchOrganizations = 'finding-an-organization',
    PublicScheduling = 'scheduling',
    PublicPermissionsRequired = 'permissions-required',
    PublicShiftRegistration = 'shift-registration',
}

const headers: Dictionary<HelpSections, string> = {
    [HelpSections.YourProfile]: 'Your Account',
    [HelpSections.DashboardPage]: 'Dashboard',
    [HelpSections.InformationPage]: 'Information',
    [HelpSections.LocationsPage]: 'Locations',
    [HelpSections.AddingLocation]: 'Adding a Location',
    [HelpSections.EditingLocation]: 'Editing or Deleting a Location',
    [HelpSections.PortalPage]: 'Portal',
    [HelpSections.OpportunitiesPage]: 'Opportunities',
    [HelpSections.AddingOpportunity]: 'Adding an Opportunity',
    [HelpSections.EditingOpportunity]: 'Editing an Opportunity',
    [HelpSections.ViewingOpportunity]: 'Viewing an Opportunity',
    [HelpSections.DeletingOpportunity]: 'Deleting an Opportunity',
    [HelpSections.OpportunityEditorPage]: 'Opportunity Editor',
    [HelpSections.OpportunityStatus]: 'Status',
    [HelpSections.OpportunityTags]: 'Hour Tracking Tags',
    [HelpSections.OpportunityPermissions]: 'Permission Level',
    [HelpSections.SchedulingPage]: 'Scheduling',
    [HelpSections.AddingShift]: 'Adding a Shift',
    [HelpSections.EditingShift]: 'Editing a Shift',
    [HelpSections.RegisteringVolunteers]: 'Volunteer Registrations',
    [HelpSections.VolunteersPage]: 'Volunteers',
    [HelpSections.AddingVolunteer]: 'Adding a Volunteer',
    [HelpSections.ImportingVolunteers]: 'Importing Volunteers',
    [HelpSections.MergingVolunteers]: 'Merging Volunteers',
    [HelpSections.EditingVolunteers]: 'Editing Volunteers',
    [HelpSections.VolunteerDetailsPage]: 'Volunteer Details',
    [HelpSections.VolunteerEmailField]: 'Volunteer Email',
    [HelpSections.VolunteerStatusField]: 'Volunteer Status',
    [HelpSections.VolunteerPermissionsField]: 'Permissions',
    [HelpSections.VolunteerRolesField]: 'Roles',
    [HelpSections.ServiceDataPage]: 'Service Data',
    [HelpSections.AddingServiceEntry]: 'Adding Volunteer Hours',
    [HelpSections.EditingServiceEntry]: 'Editing Volunteer Hours',
    [HelpSections.BulkEditingServiceEntries]: 'Editing Multiple Service Entries',
    [HelpSections.DeletingServiceEntries]: 'Deleting Volunteer Hours',
    [HelpSections.ReviewingServiceEntryVolunteerEdits]: 'Reviewing Volunteer Entries and Modifications',
    [HelpSections.SettingsPage]: 'Settings',
    [HelpSections.PublicPages]: 'Public Pages',
    [HelpSections.PublicOrganizationPage]: 'Public Organization Profile',
    [HelpSections.PublicOpportunityPage]: 'Public Opportunity Pages',
    [HelpSections.SearchPage]: 'Search Page',
    [HelpSections.SearchOpportunities]: 'Finding a Volunteer Opportunity',
    [HelpSections.SearchOrganizations]: 'Finding an Organization',
    [HelpSections.PublicScheduling]: 'Scheduling',
    [HelpSections.PublicPermissionsRequired]: 'Opportunities Requiring Permission',
    [HelpSections.PublicShiftRegistration]: 'Registering for a Shift'
}

const sectionHierarchy = [
    [HelpSections.YourProfile, [
        HelpSections.DashboardPage,
        HelpSections.InformationPage,
        [HelpSections.LocationsPage, [
            HelpSections.AddingLocation,
            HelpSections.EditingLocation
        ]],
        HelpSections.PortalPage,
        [HelpSections.OpportunitiesPage, [
            HelpSections.AddingOpportunity,
            HelpSections.EditingOpportunity,
            HelpSections.ViewingOpportunity,
            HelpSections.DeletingOpportunity
        ]],
        [HelpSections.OpportunityEditorPage, [
            HelpSections.OpportunityPermissions,
            HelpSections.OpportunityStatus,
            HelpSections.OpportunityTags
        ]],
        [HelpSections.SchedulingPage, [
            HelpSections.AddingShift,
            HelpSections.EditingShift,
            HelpSections.RegisteringVolunteers
        ]],
        [HelpSections.VolunteersPage, [
            HelpSections.AddingVolunteer,
            HelpSections.ImportingVolunteers,
            HelpSections.MergingVolunteers,
            HelpSections.EditingVolunteers
        ]],
        [HelpSections.VolunteerDetailsPage, [
            HelpSections.VolunteerEmailField,
            HelpSections.VolunteerStatusField,
            HelpSections.VolunteerPermissionsField,
            HelpSections.VolunteerRolesField
        ]],
        [HelpSections.ServiceDataPage, [
            HelpSections.AddingServiceEntry,
            HelpSections.EditingServiceEntry,
            HelpSections.BulkEditingServiceEntries,
            HelpSections.DeletingServiceEntries,
            HelpSections.ReviewingServiceEntryVolunteerEdits
        ]],
        HelpSections.SettingsPage
    ]],
    [HelpSections.PublicPages, [
        HelpSections.PublicOrganizationPage,
        [HelpSections.PublicOpportunityPage, [
            HelpSections.PublicScheduling,
            HelpSections.PublicPermissionsRequired,
            HelpSections.PublicShiftRegistration
        ]],
        [HelpSections.SearchPage, [
            HelpSections.SearchOpportunities,
            HelpSections.SearchOrganizations
        ]]
    ]]
];

const getTableOfContentsItem = (section: any): TableOfContentsItem => {
    if (Array.isArray(section) && section.length === 2 && Array.isArray(section[1])) {
        return {
            label: headers[section[0] as HelpSections],
            anchorId: section[0],
            subItems: section[1].map(subsection => getTableOfContentsItem(subsection))
        }
    } else {
        return {
            label: headers[section as HelpSections],
            anchorId: section
        }
    }
}

const tableOfContentsItems = sectionHierarchy.map(section => getTableOfContentsItem(section));

const AdministratorInstructions = observer(() => {

    const rootStore = useContext(RootContext);
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    /***** React hooks *****/

    const classes = useStyles();
    useResetScrollPosition();
    const nineHundredDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(900));

    const flexSection = (text: JSX.Element, imageSrc: string, altText: string, textSide: 'left' | 'right') => {
        return nineHundredDown
            ? <Fragment>
                {text}
                <img src={imageSrc} alt={altText} className={classes.fullWidth} />
            </Fragment>
            : (
                <div className={clsx(classes.flex, classes.subsection)}>
                    {textSide === 'left'
                        ? <Fragment>
                            <div>
                                {text}
                            </div>
                            <div>
                                <img src={imageSrc} alt={altText} className={classes.image} />
                            </div>
                        </Fragment>
                        : <Fragment>
                            <div>
                                <img src={imageSrc} alt={altText} className={classes.image} />
                            </div>
                            <div>
                                {text}
                            </div>
                        </Fragment>
                    }
                </div>
            )
    }

    const onContactUsClicked = () => {
        rootStore.navigationStore.appwideDialogStates.contactUsDialog.setOpen(true);
    }

    const header = (section: HelpSections, variant?: Variant) => {
        return <Typography variant={variant} id={section}>{headers[section]}</Typography>
    }

    const anchorLink = (text: string, anchorId: string) => {
        return <AnchorLink anchorId={anchorId}>{text}</AnchorLink>
    }

    /***** Render *****/

    return (
        <div id="organization-instructions" className={classes.root}>
            <Container className={classes.wrapperContainer}>
                <Grid container spacing={2}>
                    {/* Table of Contents */}
                    {smDown
                        ? null
                        : <Grid item xs={3} md={3}>
                            <div className={classes.tableOfContents}>
                                <TableOfContents tableOfContentsItems={tableOfContentsItems} />
                            </div>
                        </Grid>
                    }
                    {/* Instructions */}
                    <Grid item xs={12} sm={12} md={9}>
                        <Paper>
                            <Container>
                                <div className={classes.content}>
                                    <Typography variant="h3">Help Center</Typography>
                                    <Typography>
                                        The instructions below cover how to navigate and use the site. If you're still left with any questions, you can always <StylizedInternalLink onClick={onContactUsClicked}>Contact Us</StylizedInternalLink>.
                                    </Typography>
                                    {header(HelpSections.YourProfile, 'h4')}

                                    {/* Dashboard */}
                                    {header(HelpSections.DashboardPage, 'h5')}
                                    <Typography>
                                        Your Dashboard gives you an overview of your volunteer program at a glance. Track trends in volunteer hours served over the past 6 months, view a breakdown of volunteer hours served per opportunity, identify upcoming dates with shifts that are yet to be filled, and more.
                                    </Typography>
                                    <img src={DashboardPage} alt="Dashboard page" className={classes.fullWidth} />

                                    {/* Information */}
                                    {header(HelpSections.InformationPage, 'h5')}
                                    <Typography>
                                        From the Information Page, you can edit your organization's public facing details by clicking the pencil icon in the top right corner or by clicking the <b>Edit Profile</b> button at the bottom of the page.
                                    </Typography>
                                    <img src={InformationPage} alt="Information page" className={classes.fullWidth} />
                                    <Typography>
                                        Adding the causes that your organization is focused on will help volunteers find your recruitment posts when they are {anchorLink('searching for volunteer opportunities', HelpSections.SearchOpportunities)}.
                                    </Typography>
                                    <img src={Causes} alt="Add cause areas" className={classes.fullWidth} />
                                    <Typography>
                                        To view your {anchorLink(`organization's public facing page`, HelpSections.PublicOrganizationPage)}, you can either click the View icon in the top right corner or you can click on the <b>Public Page</b> option from the navigation bar at the top of the screen.
                                    </Typography>
                                    <img src={ViewOrganization} alt="View public organization page" className={classes.fullWidth} />

                                    {/* Locations */}
                                    {header(HelpSections.LocationsPage, 'h5')}
                                    <Typography>
                                        The Locations Page gives you a one-stop place to manage the addresses where your organization is located and the addresses where your organization's volunteer opportunities take place.
                                    </Typography>
                                    <img src={LocationsPage} alt="Locations page" className={classes.fullWidth} />
                                    {header(HelpSections.AddingLocation, 'h6')}
                                    <Typography>
                                        To add a new address, click the <b>New Location</b> button at the top of the table. In the dialog that appears, enter the location's details, and then click the <b>Add</b> button.
                                    </Typography>
                                    <img src={AddLocation} alt="Add location" className={classes.fullWidth} />
                                    {header(HelpSections.EditingLocation, 'h6')}
                                    <Typography>
                                        To modify an address, click on its row in the table, and in the dialog that appears, make any necessary changes and click the <b>Save</b> button or click the <b>Remove</b> button to delete the address. Be aware that any changes made will be applied everywhere that the address is used.
                                    </Typography>
                                    <img src={EditLocation} alt="Edit location" className={classes.fullWidth} />

                                    {/* Portal */}
                                    {header(HelpSections.PortalPage, 'h5')}
                                    <Typography>
                                        The Portal Page allows you to configure an organization-specific portal that will display only your organization's information and volunteer opportunities. Configure the portal with a URL of your choice and upload your organization's logo to have it displayed in the navigation bar of your portal.
                                    </Typography>
                                    <Typography>
                                        Once you select a URL for your portal, you can link directly to it from your organization's website to give your volunteers easy access.
                                    </Typography>
                                    <img src={PortalPage} alt="Portal page" className={classes.fullWidth} />
                                    <Typography>
                                        Your volunteer opportunities will continue to appear in the main Voltage Volunteering portal in addition to your organization-specific portal.
                                    </Typography>
                                    <img src={PortalExample} alt="Portal example" className={classes.fullWidth} />

                                    {/* Opportunities */}
                                    {header(HelpSections.OpportunitiesPage, 'h5')}
                                    <Typography>
                                        From the Opportunities Page you can create a new recruitment post or view, edit, or delete an existing post.
                                    </Typography>
                                    <img src={OpportunitiesPage} alt="Opportunities page" className={classes.fullWidth} />
                                    {header(HelpSections.AddingOpportunity, 'h6')}
                                    <Typography>
                                        To create an opportunity post click the <b>New Opportunity</b> button at the top of the table. This will open up the {anchorLink('Opportunity Editor', HelpSections.OpportunityEditorPage)} where you can fill out the details of the recruitment post you wish to make.
                                    </Typography>
                                    <img src={AddOpportunityButton} alt="New Opportunity button" className={classes.fullWidth} />
                                    {header(HelpSections.EditingOpportunity, 'h6')}
                                    <Typography>
                                        To edit an existing opportunity, click on its row within the table or click on the pencil icon in the first column. This will open the {anchorLink('Opportunity Editor', HelpSections.OpportunityEditorPage)} populated with the selected opportunity's details so that you can make and save any necessary changes.
                                    </Typography>
                                    <img src={EditOpportunityIcon} alt="Edit existing opportunity" className={classes.fullWidth} />
                                    {header(HelpSections.ViewingOpportunity, 'h6')}
                                    <Typography>
                                        To view the public facing version of an existing opportunity post, you can click on the view icon in the second column of the table.
                                    </Typography>
                                    <img src={ViewOpportunityIcon} alt="View existing opportunity" className={classes.fullWidth} />
                                    {header(HelpSections.DeletingOpportunity, 'h6')}
                                    <Typography>
                                        To permanently delete an opportunity, click on the delete icon in the last column of the table. It's important to note that deleting an opportunity will delete all of its associated shifts and service data. If you'd like to maintain this data, you can instead {anchorLink(`change the opportunity's status`, HelpSections.OpportunityStatus)} to inactive.
                                    </Typography>
                                    <img src={DeleteOpportunity} alt="Delete existing opportunity" className={classes.fullWidth} />

                                    {/* Opportunity Editor */}
                                    {header(HelpSections.OpportunityEditorPage, 'h5')}
                                    <Typography>
                                        The Opportunity Editor allows you to outline the details of a particular volunteer role for which you are recruiting.
                                    </Typography>
                                    <img src={AddOpportunity} alt="Post a new opportunity" className={classes.fullWidth} />
                                    {header(HelpSections.OpportunityPermissions, 'h6')}
                                    <Typography>
                                        Within the Scheduling section of the Opportunity Editor, if you specify "shifts" as the scheduling type, you'll then be asked to set a permission level to determine which volunteers are eligible to register for the opportunity's shifts. Once you create an opportunity with either Organization Level or Opportunity Level permissions, you'll then be able to {anchorLink('assign that permission to a volunteer', HelpSections.VolunteerPermissionsField)} from the {anchorLink('Volunteer Details Page', HelpSections.VolunteerDetailsPage)}.
                                    </Typography>
                                    <img src={OpportunityPermissions} alt="Set opportunity permissions level" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.OpportunityStatus, 'h6')}
                                            <Typography>
                                                Under the Administrative Details section of the Opportunity Editor, you can specify whether or not the opportunity is currently active. Inactive opportunities remain accessible to you but are not visible to volunteers.
                                            </Typography>
                                        </Fragment>,
                                        OpportunityStatus,
                                        'Opportunity status toggle',
                                        'left'
                                    )}
                                    {header(HelpSections.OpportunityTags, 'h6')}
                                    <Typography>
                                        In the Administrative Details section of the Opportunity Editor, you can add hour tracking tags. Any text can be used as a tag. Tags that you add will be applied to any {anchorLink('volunteer hours that are automatically generated', HelpSections.ServiceDataPage)} for the opportunity based on shift registrations, and they can also be added to {anchorLink('manually entered service data', HelpSections.AddingServiceEntry)}. Tags help categorize volunteer hours so that you can run detailed reports on the data later on.
                                    </Typography>
                                    <Typography>
                                        As an example, you may want to track hours volunteers spend on administrative or capacity-building work and compare it to the number of volunteer hours that went toward direct service work. To do so, you could add an “Admin” tag to opportunities that focus on office support, data entry, fundraising, etc., and you could add a “Direct Service” tag to opportunities where volunteers perform work that is tied more closely to your organization's specific mission and impact.
                                    </Typography>
                                    <img src={OpportunityTags} alt="Hour tracking tags" className={classes.fullWidth} />

                                    {/* Scheduling */}
                                    {header(HelpSections.SchedulingPage, 'h5')}
                                    <Typography>
                                        The Scheduling Page displays all of the volunteer shifts you have scheduled. You can navigate through the calendar to access shifts that take place in the future as well as shifts that took place in the past.
                                    </Typography>
                                    <img src={SchedulingPage} alt="Scheduling page" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.AddingShift, 'h6')}
                                            <Typography>
                                                To create a new shift either click the <b>New Shift</b> button at the top of the calendar or click on the calendar date that the shift will take place on. This will bring up a dialog for you to input the rest of the shift's details. In order to add a shift, you must have an opportunity to attach it to. Once all of the shift details are entered, click <b>Add</b>.
                                            </Typography>
                                            <Typography>
                                                The new shift should now appear in the calendar. If the newly added shift is in the future, it will now also appear on the {anchorLink('public-facing Opportunity Page', HelpSections.PublicOpportunityPage)}.
                                            </Typography>
                                        </Fragment>,
                                        AddShift,
                                        'Add a shift',
                                        'left'
                                    )}
                                    {header(HelpSections.EditingShift, 'h6')}
                                    <Typography>
                                        To modify a shift, click on its listing in the calendar. This will open the Shift Details page where you can adjust the shift's timing, location, and number of slots by clicking on the <b>Edit</b> button or you can remove the shift altogether by clicking on the <b>Delete</b> button. Any volunteers who are registered for the shift will be notified by email of the modification or cancellation.
                                    </Typography>
                                    <img src={ShiftDetailsWithRegistrations} alt="Shift Details page" className={classes.fullWidth} />
                                    {header(HelpSections.RegisteringVolunteers, 'h6')}
                                    <Typography>
                                        From the Shift Details page you can also register volunteers for the shift or update a volunteer's registration status. Volunteers will be notified by email if they are registered or unregistered for a shift.
                                    </Typography>
                                    <Typography>
                                        To register volunteers, click the <b>Register Volunteers</b> button at the top of the Registrations section and either type the names of the volunteers you'd like to add or select them from the dropdown list.
                                    </Typography>
                                    <img src={ShiftDetailsRegistration} alt="Register volunteers for a shift" className={classes.fullWidth} />
                                    <Typography>
                                        To unregister a volunteer or update a volunteer's registration status, click the checkbox in the first column and click either the <b>Unregister</b> button or the <b>Update Status</b> button at the top of the table.
                                    </Typography>
                                    <img src={ShiftDetailsRegistrationUpdateStatus} alt="Update a volunteer's shift registration status" className={classes.fullWidth} />
                                    <Typography>
                                        Clicking on a row within the registrations table allows you to quickly navigate to the {anchorLink('Volunteer Details Page', HelpSections.VolunteerDetailsPage)} for that volunteer if needed.
                                    </Typography>
                                    <img src={ShiftDetailsVolunteerRegistrations} alt="Click on a row in the table to navigate to that volunteer" className={classes.fullWidth} />

                                    {/* Volunteers */}
                                    {header(HelpSections.VolunteersPage, 'h5')}
                                    <Typography>
                                        The Volunteers Page lists all of the volunteers linked to your organization. You can {anchorLink('manually add', HelpSections.AddingVolunteer)} or {anchorLink('import volunteers', HelpSections.ImportingVolunteers)}, and volunteers are automatically added when they take one of the following actions:
                                        <ol>
                                            <li>Register for one of your shifts</li>
                                            <li>Respond to one of your opportunities</li>
                                            <li>Send you a contact message through the platform</li>
                                        </ol>
                                    </Typography>
                                    <Typography>
                                        If a volunteer registers for one of your shifts, they are linked to your organization with a status of Active. Otherwise, they are linked with a status of Inquiry. You can {anchorLink(`update a volunteer's status`, HelpSections.VolunteerStatusField)} and other details by {anchorLink('editing their record', HelpSections.EditingVolunteers)}.
                                    </Typography>
                                    <img src={VolunteersPage} alt="Volunteers page" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.AddingVolunteer, 'h6')}
                                            <Typography>
                                                To add a volunteer, click the <b>New Volunteer</b> button at the top of the table. A form will pop up prompting you to enter the volunteer's basic information and status.
                                            </Typography>
                                            <Typography>
                                                If you enter an email address for the volunteer, they will automatically be sent an invitation via email to connect with your organization on the platform.
                                            </Typography>
                                            <Typography>
                                                Once you have entered all of the appropriate details, click the <b>Add</b> button, and a new row will be added to the table for the volunteer. By clicking on the row, you can access the {anchorLink('Volunteer Details', HelpSections.VolunteerDetailsPage)} page where you can add additional information for the volunteer.
                                            </Typography>
                                        </Fragment>,
                                        AddVolunteer,
                                        'Add a volunteer',
                                        'right'
                                    )}
                                    {header(HelpSections.ImportingVolunteers, 'h6')}
                                    <Typography>
                                        To import multiple volunteers at the same time, follow the steps below:
                                        <ol>
                                            <li>Make a copy of the <StylizedExternalLink to={'https://docs.google.com/spreadsheets/d/1mI3JylHw0YdjZFQ07bqY1SGyJoyE9A5TN0N3oBqj41Q/copy'}>template for importing volunteers.</StylizedExternalLink></li>
                                            <li>Enter your volunteer data, and select the appropriate status for each volunteer from the dropdown options. <b>Note:</b> Anyone who you add an email for will receive an email invitation to link to your organization on the platform, unless they have a status of "Banned".</li>
                                            <li>After entering your volunteer data, save or download a copy of the document as a .csv file.</li>
                                            <li>From the Volunteers page of your user account, click the <b>Import</b> button.</li>
                                            <li>In the file selection prompt that pops up, select your .csv file.</li>
                                            <li>You'll be able to see the data you're importing and modify it if needed.</li>
                                            <li>Once there are no changes left to make or errors left to address, click the <b>Import</b> button.</li>
                                        </ol>
                                    </Typography>
                                    <img src={ImportVolunteers} alt="Importing volunteers" className={classes.fullWidth} />
                                    {header(HelpSections.MergingVolunteers, 'h6')}
                                    <Typography>
                                        In some cases, it may become necessary to merge two separate volunteer records into one. To do so, click on the checkbox in the first column for the two volunteer rows that you want to combine. Then click the <b>Merge Records</b> button at the top of the table.
                                    </Typography>
                                    <img src={MergeVolunteers} alt="Select two volunteers to merge" className={classes.fullWidth} />
                                    <Typography>
                                        A popup will appear prompting you to choose the information that should be applied to the merged record. If neither of the two records being merged contain the correct data for a particular field, you can enter a new value in the third column to override or add to the existing values. Once you've selected all the appropriate data for the new merged record, click the <b>Merge</b> button.
                                    </Typography>
                                    <img src={MergeVolunteersDialog} alt="Select values to carry forward to new merged volunteer record" className={classes.fullWidth} />
                                    <Typography>
                                        All shift registrations and service entries from the two existing records will automatically be transferred to the new merged record. You can verify that the record merged as expected by clicking on the new row in the table for the volunteer and reviewing the {anchorLink('Volunteer Details', HelpSections.VolunteerDetailsPage)}.
                                    </Typography>
                                    {header(HelpSections.EditingVolunteers, 'h6')}
                                    <Typography>
                                        To update a volunteer's status or any other details, click on the volunteer's row within the table. This will open the {anchorLink('Volunteer Details', HelpSections.VolunteerDetailsPage)} page.
                                    </Typography>

                                    {/* Volunteer Details */}
                                    {header(HelpSections.VolunteerDetailsPage, 'h5')}
                                    <Typography>
                                        The Volunteer Details page displays a volunteer's basic information as well as a list of their upcoming shifts and their service history. By clicking on the pencil icon in the top right corner, you can edit the volunteer's details.
                                    </Typography>
                                    <img src={VolunteerDetails} alt="Volunteer details page" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.VolunteerEmailField, 'h6')}
                                            <Typography>
                                                A volunteer record may or may not be linked to a user account. If the record is not already linked to a user account, adding an email address or changing the entered email address will send an email invitation to the volunteer, prompting them to create an account and connect with your organization.
                                            </Typography>
                                        </Fragment>,
                                        VolunteerEmail,
                                        'Volunteer email field',
                                        'left'
                                    )}
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.VolunteerStatusField, 'h6')}
                                            <Typography>
                                                You can update the volunteer's status to another option within the dropdown list. Statuses are for the most part intended to help you categorize volunteers and don't have any functional ramifications aside from the Banned and the Inactive statuses.
                                            </Typography>
                                            <Typography>
                                                Marking a volunteer as banned means that they won't be able to sign up for any of your organization's shifts even if no permission is required to register. Instead, the volunteer will only be able to send you a message to indicate their interest, which you can choose to act on however you see fit. Marking a volunteer as Inactive will prevent them from showing up in places where there are dropdowns to select a volunteer.
                                            </Typography>
                                        </Fragment>,
                                        VolunteerStatus,
                                        'Volunteer status field',
                                        'right'
                                    )}
                                    {header(HelpSections.VolunteerPermissionsField, 'h6')}
                                    <Typography>
                                        The Permissions field dictates which opportunities the volunteer has permission to sign up for. If any of your organization's posted opportunities use shift-based scheduling and {anchorLink('require organization level permission or opportunity level permission', HelpSections.OpportunityPermissions)} in order to register, the permissions field dropdown will display those as options that you can assign to the volunteer if appropriate. The organization level permission will match your organization's name, and the opportunity-specific permissions will match the respective opportunity's role.
                                    </Typography>
                                    <Typography>
                                        Volunteers with the appropriate permission will be able to self-register for an opportunity's shifts while volunteers who don't have permission will instead be prompted to express their interest so that you can inform them of any steps they need to take before being granted permission.
                                    </Typography>
                                    <img src={VolunteerPermissions} alt="Volunteer permissions field" className={classes.fullWidth} />
                                    {header(HelpSections.VolunteerRolesField, 'h6')}
                                    <Typography>
                                        The Roles field helps you keep track of the different capacities in which a particular volunteer serves. You can assign the volunteer any number of roles from the opportunities you've posted. If a volunteer needs to manually enter volunteer hours, they will be able to categorize the hours under any of the roles they have been assigned.
                                    </Typography>
                                    <img src={VolunteerRoles} alt="Volunteer roles field" className={classes.fullWidth} />

                                    {/* Service Data */}
                                    {header(HelpSections.ServiceDataPage, 'h5')}
                                    <Typography>
                                        The Service Data Page shows all of the hours that volunteers have served with your organization. The data associated with each individual service entry is shared between you and the volunteer who served the hours.
                                    </Typography>
                                    <Typography>
                                        There are several different ways an entry could end up in your service data:
                                        <ol>
                                            <li>After a volunteer shift that you have created ends, hours are automatically logged for any volunteers who were registered and haven't been marked as a no-show.</li>
                                            <li>You can {anchorLink('enter', HelpSections.AddingServiceEntry)} or {anchorLink('edit volunteer hours manually', HelpSections.EditingServiceEntry)} for a volunteer.</li>
                                            <li>Volunteers who are linked to your organization can manually enter or adjust their own volunteer hours.</li>
                                        </ol>
                                    </Typography>
                                    <img src={ServiceDataPage} alt="Service Data page" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.AddingServiceEntry, 'h6')}
                                            <Typography>
                                                When a volunteer is signed up for a shift, a new service entry is automatically generated at the end of the shift to track the hours served. However, there may be cases when an individual volunteers on an ad hoc basis rather than signing up for a specific shift. To capture those volunteer hours, either you or the volunteer can manually log that service.
                                            </Typography>
                                            <Typography>
                                                To log volunteer hours, click the Log Volunteer Hours button toward the top left. In the popup that opens, select one or more volunteers to log hours for. An identical service entry record will be created for each volunteer that you select. You can optionally select a role to categorize the hours under, and you can also add tags to further categorize the service data to help with any reporting needs you may have. Select the date and length of time spent volunteering, adjust the status if needed, and enter any notes you want to keep track of. Notes and tags are not shared with volunteers. Click the <b>Add</b> button once you've entered all the appropriate data, and you'll see a new service entry row added to the table for each volunteer that you listed in the Volunteers field.
                                            </Typography>
                                        </Fragment>,
                                        AddServiceEntry,
                                        'Add service entry',
                                        'left'
                                    )}
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.EditingServiceEntry, 'h6')}
                                            <Typography>
                                                If you need to make adjustments to a service entry, click on the entry's row in the table. In the popup that opens, adjust the details as needed.
                                            </Typography>
                                            <Typography>
                                                If the service entry was automatically generated based on a shift the volunteer was registered for, you won't be able to change the role, but you can still edit the other details or {anchorLink('delete the service entry', HelpSections.DeletingServiceEntries)} if needed.
                                            </Typography>
                                        </Fragment>,
                                        EditServiceEntry,
                                        'Edit service entry',
                                        'right'
                                    )}
                                    {header(HelpSections.BulkEditingServiceEntries, 'h6')}
                                    <Typography>
                                        If you want to change the status of multiple service entries all to the same status, you can do so by clicking on the checkbox in the first column of each of the service entries to be updated. Then click the <b>Update Status</b> button at the top of the table, select the new status, and click the <b>Update</b> button.
                                    </Typography>
                                    <img src={ServiceEntryUpdateStatuses} alt="Update the status of multiple service entries" className={classes.fullWidth} />
                                    <Typography>
                                        Following a similar set of steps, you can add a set of tags to multiple different service entries all at once. After selecting the service entries to be updated, click on the <b>Add Tags</b> button. Enter or select the tags to add, and then click the <b>Add</b> button.
                                    </Typography>
                                    <img src={ServiceEntryAddTags} alt="Add tags to multiple service entries" className={classes.fullWidth} />
                                    {header(HelpSections.DeletingServiceEntries, 'h6')}
                                    <Typography>
                                        To delete volunteer service entries, click on the checkbox in the first column of any rows you'd like to delete. Then click the <b>Delete</b> button at the top of the table.
                                    </Typography>
                                    <img src={ServiceEntryDelete} alt="Delete one or more service entries" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.ReviewingServiceEntryVolunteerEdits, 'h6')}
                                            <Typography>
                                                When a volunteer enters, edits, or deletes a service entry, you'll be able to tell by the icon in the Source column. These icons help indicate where a particular service entry originated from or who last modified it:
                                                <ul>
                                                    <li>A calendar icon ( <Calendar className={classes.icon} /> ) indicates that the service entry was automatically generated from a shift and hasn't been edited by anyone.</li>
                                                    <li>A person icon ( <Account className={classes.icon} /> ) means the service entry was added by the volunteer.</li>
                                                    <li>A person icon with a pencil ( <AccountEdit className={classes.icon} /> ) means the service entry was last modified by a volunteer.</li>
                                                    <li>An organization icon ( <Domain className={classes.icon} /> ) indicates that the service entry was last modified by you or another staff member at your organization.</li>
                                                </ul>
                                            </Typography>
                                            <Typography>
                                                Once you've identified a service entry that has been added, edited, or deleted by a volunteer, you can review the addition or change by clicking on the service entry's row in the table. In the popup that appears, you'll be able to view any changes that were made to the record and make adjustments or completely revert the changes if needed.
                                            </Typography>
                                        </Fragment>,
                                        ServiceEntryVolunteerEdits,
                                        'A service entry with volunteer edits to review',
                                        'left'
                                    )}

                                    {/* Settings */}
                                    {header(HelpSections.SettingsPage, 'h5')}
                                    <Typography>
                                        Your Settings Page displays your account information and allows you to update your password.
                                    </Typography>
                                    <img src={SettingsPage} alt="Settings page" className={classes.fullWidth} />


                                    {header(HelpSections.PublicPages, 'h4')}
                                    <Typography>
                                    </Typography>

                                    {/* Public Organization Page */}
                                    {header(HelpSections.PublicOrganizationPage, 'h5')}
                                    <Typography>
                                        Your public Organization Page displays a description of your organization along with its mission, its cause areas, and any physical addresses. If your organization sparks a volunteer's interest, they can save it by clicking the lightning bolt icon in the top right corner.
                                    </Typography>
                                    <img src={OrganizationPage} alt="A public-facing organization page" className={classes.fullWidth} />
                                    {flexSection(
                                        <Typography>
                                            Clicking the <b>Opportunities</b> tab at the top of the page will take you to either the most recent {anchorLink('Opportunity Page', HelpSections.PublicOpportunityPage)} you were viewing or, if you didn't have a particular volunteer opportunity page open, it will take you to the complete list of the organization's opportunities.
                                        </Typography>,
                                        OpportunitiesTab,
                                        'Opportunities tab',
                                        'left'
                                    )}

                                    {/* Public Opportunity Page */}
                                    {header(HelpSections.PublicOpportunityPage, 'h5')}
                                    <Typography>
                                        The Opportunity Page displays details about a specific volunteer recruitment post. Volunteers can find a description of the role as well as information about the onboarding process. They can click the <b>Contact Recruiter</b> button on the right side of the page to send a message or question to you about the post. If the opportunity sparks their interest, they can save it by clicking the <b>Save to Sparks</b> button.
                                    </Typography>
                                    <img src={OpportunityPage} alt="A public-facing opportunity page" className={classes.fullWidth} />
                                    {flexSection(
                                        <Typography>
                                            Clicking on the <b>Organization</b> tab at the top of the page will take volunteers to the recruiter's {anchorLink('Organization Page', HelpSections.PublicOrganizationPage)} where they can find additional details about the overarching mission that the volunteer opportunity aids in advancing.
                                        </Typography>,
                                        OrganizationTab,
                                        'Organization tab',
                                        'left'
                                    )}
                                    {header(HelpSections.PublicScheduling, 'h6')}
                                    <Typography>
                                        Some volunteer opportunities take place at scheduled times while others use a more flexible approach to scheduling. If an opportunity has upcoming shifts, volunteers will be able to view details of those shifts in the Upcoming Schedule section toward the bottom of the page.
                                    </Typography>
                                    <img src={UpcomingSchedule} alt="Upcoming schedule section of opportunity page" className={classes.fullWidth} />
                                    {header(HelpSections.PublicPermissionsRequired, 'h6')}
                                    <Typography>
                                        Some opportunities require special {anchorLink('permission', HelpSections.OpportunityPermissions)} in order to register for a shift. If that's the case, until you {anchorLink('assign volunteers the appropriate permission', HelpSections.VolunteerPermissionsField)}, they will see an <b>Express Interest</b> button at the top of the Upcoming Schedule section. When a volunteer lets you know they're interested in an opportunity that requires additional permissions, you can share with them any additional steps they may need to take in order to be given permission to register.
                                    </Typography>
                                    <img src={PermissionNeeded} alt="Permission needed to register warning message" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.PublicShiftRegistration, 'h6')}
                                            <Typography>
                                                If the opportunity doesn't require any special permission to register for a shift or if the volunteer has already been given the appropriate permission, they can use the calendar navigation within the Upcoming Schedule section to find a shift with at least one slot remaining that fits their schedule. Then they can click the <b>Sign Up</b> button to register. At this point, if they are not already logged in, they will be prompted to do so before continuing with their registration.
                                            </Typography>
                                        </Fragment>,
                                        ShiftRegistration,
                                        'Registering for a shift as a volunteer',
                                        'left'
                                    )}

                                    {/* Search */}
                                    {header(HelpSections.SearchPage, 'h5')}
                                    <Typography>
                                        To <StylizedInternalLink to={getSearchLink()}>navigate to the Search Page</StylizedInternalLink>, click the <b>Find Opportunities</b> option in the main menu at the top of the screen. On smaller screens, this menu item appears as a magnifying glass icon without any text.
                                    </Typography>
                                    <Typography>
                                        From the search page, volunteers can browse or filter through the assortment of recruitment posts. Clicking on a recruitment post will open up its {anchorLink('Opportunity Page', HelpSections.PublicOpportunityPage)} where the volunteer will be able to see additional details, including a full description of the role, where it takes place, and the timing of any upcoming shifts.
                                    </Typography>
                                    <img src={SearchPage} alt="Search page" className={classes.fullWidth} />
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.SearchOpportunities, 'h6')}
                                            <Typography>
                                                There are a number of ways to search and filter through opportunity posts. Volunteers can type in the search field to search by keyword. They can filter through opportunity posts based on whether they take place in person or remotely. They can also filter based on causes they're passionate about or based on experience, interests, or skills they have to share.
                                            </Typography>
                                        </Fragment>,
                                        SearchFilters,
                                        "Search filters",
                                        'left'
                                    )}
                                    {flexSection(
                                        <Fragment>
                                            {header(HelpSections.SearchOrganizations, 'h6')}
                                            <Typography>
                                                Rather than searching for a particular opportunity post, volunteers can instead browse through or search for organizations by clicking the drop down menu next to the search field and selecting <b>Organizations</b>. Clicking on an organization search result will open up that organization's {anchorLink('Public Profile', HelpSections.PublicOrganizationPage)}.
                                            </Typography>
                                        </Fragment>,
                                        OrganizationSearch,
                                        "Search by organizations",
                                        'right'
                                    )}
                                </div>
                            </Container>
                        </Paper>
                    </Grid>
                </Grid>
            </Container >
        </div >
    )
});

export default AdministratorInstructions;