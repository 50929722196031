import { useContext } from "react";
import { observer } from 'mobx-react'
import { RootContext } from "../../../../stores";
import AccountSettingsCardGrid from "../../AccountSettingsCardGrid";
import { Typography, Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import { Magnify, LightningBolt } from "mdi-material-ui";
import { useSearchOpportunities } from "../../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(2)
        },
        emphasizedText: {
            color: theme.palette.accent.main
        },
        icon: {
            marginBottom: '-5px'
        }
    }),
);

const OpportunitySparks = observer(() => {

    const classes = useStyles();
    const searchOpportunities = useSearchOpportunities();
    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;

    const onBrowseOpportunitiesClicked = () => {
        searchOpportunities();
    }

    return (
        <AccountSettingsCardGrid
            emptyContent={
                <div>
                    <Typography>
                        {`Opportunities that `}
                        <span className={classes.emphasizedText}>
                            <LightningBolt className={classes.icon} />
                            Spark
                        </span>
                        {` your interest will be saved here.`}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Magnify />}
                        onClick={onBrowseOpportunitiesClicked}
                        className={classes.button}
                    >
                        Browse Opportunities
                    </Button>
                </div>
            }
            requestMethod={volunteerStore.getOpportunitySparks}
        />
    );
});

export default OpportunitySparks;