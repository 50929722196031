import { useContext } from "react";
import { observer } from 'mobx-react'
import { RootContext } from "../../../../stores";
import AccountSettingsCardGrid from "../../AccountSettingsCardGrid";
import { Typography, Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import { Magnify, LightningBolt } from "mdi-material-ui";
import { useIsOrgPortal, useSearchOrganizations } from "../../../Navigation/Hooks";
import { getAbsoluteLink, getSearchLink } from "../../../Navigation/Links";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(2)
        },
        emphasizedText: {
            color: theme.palette.accent.main
        },
        icon: {
            marginBottom: '-5px'
        }
    }),
);

const OrganizationSparks = observer(() => {

    const classes = useStyles();
    const isOrgPortal = useIsOrgPortal();
    const searchOrganizations = useSearchOrganizations();
    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;

    const onBrowseOrganizationsClicked = () => {
        if (isOrgPortal) {
            window.open(getAbsoluteLink(getSearchLink(true)), "_blank");
        } else {
            searchOrganizations();
        }
    }

    return (
        <AccountSettingsCardGrid
            emptyContent={
                <div>
                    <Typography>
                        {`Organizations that `}
                        <span className={classes.emphasizedText}>
                            <LightningBolt className={classes.icon} />
                            Spark
                        </span>
                        {` your interest will be saved here.`}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Magnify />}
                        onClick={onBrowseOrganizationsClicked} className={classes.button}
                    >
                        Browse Organizations
                    </Button>
                </div>
            }
            requestMethod={volunteerStore.getOrganizationSparks}
        />
    );
});

export default OrganizationSparks;