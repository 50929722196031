import { useState, useContext, useEffect, Fragment } from "react";
import { Typography, Paper, Container, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import ExtendedFab from "../Shared/ExtendedFloatingActionButton";
import SendIcon from '@material-ui/icons/Send';
import PageWithFooter from "../Shared/PageWithFooter";
import ContactForm from "./ContactForm";
import { RootContext } from "../../stores";
import FormLoadingSpinner from "../Organization/VolunteerOpportunities/FormLoadingSpinner";
import SuccessMessage from "../Shared/SuccessMessage";
import { useNavigateInternally } from "../Navigation/Hooks";
import { getSearchLink } from "../Navigation/Links";

// TODO: Refactor this and ContactUsDialog.tsx

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '700px'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            whiteSpace: 'pre-line'
        },
        topMargin: {
            marginTop: theme.spacing(2),
        },
        floatingButton: {
            marginTop: theme.spacing(1),
            alignSelf: 'flex-end'
        },
        emphasizedText: {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
    }),
);

const ContactUs = () => {

    /********* Hooks *********/

    const rootStore = useContext(RootContext);
    const { helpStore } = rootStore;
    const classes = useStyles();
    const navigate = useNavigateInternally();

    /********* State *********/

    const [loading, setLoading] = useState(false);
    const [messageSubmitted, setMessageSubmitted] = useState(false);
    const [validationRun, setValidationRun] = useState(false);
    const [helpMessage, setHelpMessage] = useState(helpStore.getCopyOfDefaultHelpMessage());

    /********* Effects *********/

    useEffect(() => {
        resetHelpMessage();
    }, [helpStore.defaultHelpMessage]);

    /********* Helper methods *********/

    const resetHelpMessage = () => {
        setHelpMessage(helpStore.getCopyOfDefaultHelpMessage());
    }

    const submitContactMessage = async () => {
        setLoading(true);
        helpMessage.setAllFieldsDirty();
        setValidationRun(true);
        if (helpMessage.validated) {
            const succeeded = await helpStore.submitContactMessage(helpMessage);
            if (succeeded) {
                setMessageSubmitted(true);
            }
        }
        setLoading(false);
    }

    const onBrowseOpportunitiesClicked = () => {
        navigate(getSearchLink());
    }

    /********* Render *********/

    return (
        <PageWithFooter>
            <Container className={classes.content}>
                {!messageSubmitted
                    ? <Fragment>
                        <Paper className={classes.paper}>
                            <Typography variant="h4">
                                Hello there. How can we help?
                            </Typography>
                            <div className={classes.topMargin}>
                                <ContactForm
                                    helpMessage={helpMessage}
                                    validationRun={validationRun}
                                    fieldsDisabled={loading}
                                />
                            </div>
                        </Paper>
                        <ExtendedFab
                            icon={SendIcon}
                            text={'Submit'}
                            onClick={submitContactMessage}
                            className={classes.floatingButton}
                        />
                        {loading ? <FormLoadingSpinner /> : null}
                    </Fragment>
                    : <Paper className={classes.paper}>
                        <SuccessMessage
                            title={'Thank you for contacting us.'}
                            details={(
                                <Fragment>
                                    <div>We'll be in touch in a <span className={classes.emphasizedText}>flash!</span></div>
                                    <Typography className={classes.topMargin}>In the meantime, you can check out the available volunteer opportunities.</Typography>
                                    <Button
                                        variant='contained'
                                        color="primary"
                                        onClick={onBrowseOpportunitiesClicked}
                                        className={classes.topMargin}
                                    >
                                        Browse Opportunities
                                    </Button>
                                </Fragment>
                            )}
                        />
                    </Paper>
                }
            </Container>
        </PageWithFooter>
    )
};

export default ContactUs;