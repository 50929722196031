// TODO: Remove if not using

import { action, makeObservable } from 'mobx';
import { AxiosRequestConfig } from 'axios';
import { RootStore } from './RootStore';
import { RequestQueue } from './models';
import { Address } from './models/Address';

enum MapRequestType {
    GetMap = 'get-map'
}

export class MapStore {
    private rootStore = {} as RootStore;
    private requestQueue: RequestQueue;

    constructor(rootStore: RootStore) {
        makeObservable<MapStore, "makeRequest">(this, {
            makeRequest: action
        });

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
    }

    /*************** Get Map ***************/

    async getMap(id: string, address: Address) {
        const params = {
            mapParameters: address.addressString
        };
        const { map } = await this.makeRequest(MapRequestType.GetMap, id, params);
        return map;
    };

    /*************** Private request handling methods ***************/

    private makeRequest<T>(type: MapRequestType, mapId: string, params?: any) {
        return new Promise<T>(async (resolve, reject) => {
            try {
                const requestConfig = this.generateRequestConfig(type, params);

                const responseData = await this.requestQueue.makeAPIRequest<T>(requestConfig);
                // const { map } = response.data;
                resolve(responseData);
            } catch (error) {
                reject(error);
            };
        })
    }

    private generateRequestConfig(type: MapRequestType, params?: any): AxiosRequestConfig {
        return {
            method: 'get',
            url: '/api/maps/',
            params: params,
        };
    }
}

