import { observable, computed, makeObservable } from "mobx";
import { stateAbbreviations, stateAbbreviationIsValid } from "../../logic/RegexValidation";
import { StateAbbreviationDictionary } from "../../data/StateAbbreviations";

export interface ICity {
    city: string;
    state: string;
    zipCode?: string;
}

// Case insensitive validation check
export function isValidCityState(cityState: string) {
    const validText = new RegExp('\^\.\+, \(' + stateAbbreviations + '\)\$', "i");
    return validText.test(cityState.trim());
}

export class City implements ICity {
    city: string;
    state: string;
    zipCode?: string;

    constructor(city: string, state: string, zipCode?: string) {
        makeObservable(this, {
            city: observable,
            state: observable,
            zipCode: observable,
            stateAbbreviation: computed,
            isValidCity: computed,
            cityAndState: computed
        });

        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
    }

    get stateAbbreviation() {
        const isStateAbbreviation = stateAbbreviationIsValid(this.state);
        return isStateAbbreviation ? this.state : StateAbbreviationDictionary[this.state];
    }

    get isValidCity() {
        return this.city !== undefined && this.stateAbbreviation !== undefined;
    }

    get cityAndState() {
        if (this.stateAbbreviation) {
            return `${this.city}, ${this.stateAbbreviation.toUpperCase()}`;
        } else {
            return '';
        }
    }
}