import { useContext, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import { volunteerIdParam } from "../../../../Navigation/Links/UrlConstructors";
import VolunteerDetailsPage from "./VolunteerDetailsPage";
import VolunteersOverview from "./VolunteersOverview";
import { IVolunteerOverview } from "../../../../../stores/models/VolunteerOverview";
import { TableController } from "../../../../../stores/models/TableController";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import { RootContext } from "../../../../../stores";
import { StubOrganizationVolunteer } from "../../../../../stores/models/StubOrganizationVolunteer";
import { VolunteerDetails } from "../../../../../stores/models/VolunteerDetails";
import useUpdateFilterSelections from "../../../../Shared/Hooks/FilterSelections";

enum FilterType {
    Status = 'status',
    Role = 'role'
}

const VolunteersSection = observer(() => {

    /********* React hooks *********/

    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;
    const tableController = useMemo(() => new TableController<'id', IVolunteerOverview, StubOrganizationVolunteer, VolunteerDetails>({
        records: new OptionCollection('id', [] as IVolunteerOverview[]),
        loadRecords: volunteerStore.getVolunteersForOrganization,
        addRecord: volunteerStore.addStubOrganizationVolunteer,
        deleteRecords: volunteerStore.deleteVolunteer,
        mergeRecords: volunteerStore.mergeOrganizationVolunteers
    }), []);

    const organizationVolunteerStatuses = useMemo(() => rootStore.userStore.user.organization?.volunteerStatuses || [], [rootStore.userStore.user.organization?.volunteerStatuses]);
    const organizationVolunteerRoles = useMemo(() => rootStore.userStore.user.organization?.roles || [], [rootStore.userStore.user.organization?.roles]);

    const roleOptions = useMemo(() => new OptionCollection('id', organizationVolunteerRoles), [organizationVolunteerRoles.length]);
    const statusOptions = useMemo(() => new OptionCollection('id', organizationVolunteerStatuses), [organizationVolunteerStatuses.length]);

    useUpdateFilterSelections(tableController, FilterType.Status, statusOptions);
    useUpdateFilterSelections(tableController, FilterType.Role, roleOptions);

    /********* Render *********/

    return (
        <Routes>
            <Route path={volunteerIdParam} element={<VolunteerDetailsPage />} />
            <Route
                path="/"
                element={
                    <VolunteersOverview
                        tableController={tableController}
                        roleOptions={roleOptions}
                        statusOptions={statusOptions}
                    />
                }
            />
        </Routes>
    );
});

export default VolunteersSection;