import { computed, makeObservable } from "mobx";
import { ISortableTableHeader, TableHeader } from "../../../../stores/models/TableHeader";
import { ServiceEntry } from "../entities/ServiceEntry";
import { ServiceDataViewModel } from "./ServiceDataViewModel";
import { VolunteerHoursStore } from "../stores/VolunteerHoursStore";
import { IOrganizationRoles } from "../interfaces/OrganizationRoles";

enum VolunteerSpecificServiceDataHeaderCells {
    Organization = 'organization'
}

const organizationHeaderCell: ISortableTableHeader<ServiceEntry> = {
    id: VolunteerSpecificServiceDataHeaderCells.Organization,
    sortValue: 'organizationId', // On the server this will be translated to the organization's name
    alignment: 'left',
    disablePadding: false,
    label: 'Organization',
    sortable: true
};

export interface IVolunteerServiceDataViewModel {
    organizationRoles?: IOrganizationRoles[];
}

export class VolunteerServiceDataViewModel extends ServiceDataViewModel implements IVolunteerServiceDataViewModel {

    constructor(store: VolunteerHoursStore) {
        super(store);
        makeObservable(this);
        
        let headerCells = super.getTableHeaderCells();
        headerCells.splice(1, 0, new TableHeader(organizationHeaderCell));
        this.setTableHeaderCells(headerCells);
    }

    @computed get organizationRoles() {
        return (this.store as VolunteerHoursStore).organizationRoles;
    }

    getDisplayableServiceEntry(serviceEntry: ServiceEntry) {
        const shared = super.getDisplayableServiceEntry(serviceEntry);
        return {
            ...shared,
            organization: this.getOrganizationText(serviceEntry)
        }
    }

    private getOrganizationText(serviceEntry: ServiceEntry) {
        return this.organizationRoles?.find(organizationRolesData => organizationRolesData.id === serviceEntry.organizationId)?.organization || '';
    }
}