import { startOfMonth, startOfWeek, startOfDay, endOfMonth, endOfWeek, endOfDay, format, addMonths, addWeeks, addDays } from "date-fns";

export enum CalendarViewMode {
    Month,
    Week,
    Day
}

export const getStartViewDateForViewModeUnit = (viewMode: CalendarViewMode, viewDate: Date) => {
    switch (viewMode) {
        case CalendarViewMode.Month:
            return startOfMonth(viewDate);
        case CalendarViewMode.Week:
            return startOfWeek(viewDate);
        case CalendarViewMode.Day:
            return startOfDay(viewDate);
    }
}

export const getEndViewDateForViewModeUnit = (viewMode: CalendarViewMode, viewDate: Date) => {
    switch (viewMode) {
        case CalendarViewMode.Month:
            return endOfMonth(viewDate);
        case CalendarViewMode.Week:
            return endOfWeek(viewDate);
        case CalendarViewMode.Day:
            return endOfDay(viewDate);
    }
}

export const getTextDisplay = (date: Date, viewMode: CalendarViewMode, abbreviation?: boolean) => {
    switch (viewMode) {
        case CalendarViewMode.Month:
            if (abbreviation) {
                return format(date, 'MMM yyy');
            } else {
                return format(date, 'MMMM yyyy');
            }
        case CalendarViewMode.Week:
            return 'not implemented week'; // TODO: implement
        case CalendarViewMode.Day:
            if (abbreviation) {
                return format(date, 'MMM d, yyyy');
            } else {
                return format(date, 'MMMM d, yyyy');
            }
    }
}

export const adjustViewDate = (date: Date, viewMode: CalendarViewMode, direction: 1 | -1) => {
    switch (viewMode) {
        case CalendarViewMode.Month:
            return addMonths(date, 1 * direction);
        case CalendarViewMode.Week:
            return addWeeks(date, 1 * direction);
        case CalendarViewMode.Day:
            return addDays(date, 1 * direction);
    }
}