import { Shift } from "../../../stores/models/Shift";
import { observer } from "mobx-react";
import ShiftList from "./ShiftList";
import { ShiftAction } from "./ShiftCard";

interface PublicShiftListProps {
    records: Shift[];
    onShiftActionSelected: (shift: Shift, action: ShiftAction) => void;
    noResultsText?: string | JSX.Element;
    permissionNeeded?: boolean;
    assignmentOnly?: boolean;
    showExpired?: boolean;
}

const PublicShiftList = observer((props: PublicShiftListProps) => {

    return (
        <ShiftList
            hideExpired={!props.showExpired}
            editable={false}
            showSignUpOption={props.permissionNeeded !== true && props.assignmentOnly !== true}
            {...props}
        />
    )
});

export default PublicShiftList;