import React, { useEffect } from "react";
import { observer } from 'mobx-react'
import { TablePagination, useMediaQuery, Theme, makeStyles, createStyles, Typography } from "@material-ui/core";
import { TablePaginationState } from "../../../../stores/models/TablePaginationState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hidden: {
            display: 'none'
        },
        arrows: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 0
        },
        selectRoot: {
            marginRight: 0,
        },
        select: {
            paddingRight: '1.5rem !important'
        },
        selectIcon: {
            top: 'calc(50% - 0.75rem)'
        },
        paginationToolbar: {
            paddingLeft: 0
        }
    })
);

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

interface TablePaginationWrapperProps {
    total?: number;
    state: TablePaginationState;
    loadResults: (limit: number, offset: number) => void;
    rowsPerPageOptions?: number[];
    skipInitialLoad?: boolean;
}

const TablePaginationWrapper = observer((props: TablePaginationWrapperProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Effects *********/

    useEffect(() => {
        if (props.state.reloadNeeded) {
            loadResults();
        }
    }, [props.state.reloadNeeded]);

    useEffect(() => {
        if (!props.skipInitialLoad || !props.state.isInitialLoad) {
            loadResults();
        }
    }, [props.state.loadingParameters]);

    // This effect must come last
    useEffect(() => {
        props.state.setIsInitialLoad(false);
    }, []);

    /********* Helper method *********/

    const loadResults = () => {
        props.loadResults(...props.state.loadingParameters);
    }

    const getDisplayedRowsLabel = () => {
        let from = props.state.offset + 1;
        let to = from + props.state.rowsPerPage - 1;
        if (props.total !== undefined && props.total !== null && props.total >= 0) {
            to = Math.min(props.total, to);
            if (props.total === 0) {
                from = 0;
            }
            return `${from}-${to} of ${props.total}`;
        } else {
            return `${from}-${to}`;
        }
    }

    /********* Event handlers *********/

    const handleChangePage = (event: unknown, newPage: number) => {
        props.state.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.state.setRowsPerPage(parseInt(event.target.value, 10));
    };

    /********* Render *********/

    return (
        <div>
            <TablePagination
                rowsPerPageOptions={props.rowsPerPageOptions ? props.rowsPerPageOptions : DEFAULT_ROWS_PER_PAGE_OPTIONS}
                component="div"
                count={props.total !== undefined ? props.total : -1}
                labelDisplayedRows={xsDown ? () => '' : () => getDisplayedRowsLabel()}
                rowsPerPage={props.state.rowsPerPage}
                page={props.state.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={
                    xsDown
                        ? {
                            toolbar: classes.paginationToolbar,
                            spacer: classes.hidden,
                            actions: classes.arrows,
                            selectRoot: classes.selectRoot,
                            select: classes.select,
                            selectIcon: classes.selectIcon
                        }
                        : {
                            select: classes.select,
                            selectIcon: classes.selectIcon
                        }
                }
            />
            {xsDown
                ? <Typography variant="body2">{getDisplayedRowsLabel()}</Typography>
                : null
            }
        </div>
    );
});

export default TablePaginationWrapper;