import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Toolbar as MUIToolbar, ToolbarProps as MUIToolbarProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '3.5rem',
            [theme.breakpoints.up('sm')]: {
                minHeight: '4rem'
            }
        },
    })
);

interface ToolbarProps extends MUIToolbarProps {

}

const REMToolbar = observer((props: ToolbarProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIToolbar
            {...props}
            classes={{
                ...props.classes,
                root: clsx(classes.root, props.classes?.root),
            }}
        >
            {props.children}
        </MUIToolbar>
    );
});

export default REMToolbar;