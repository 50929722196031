import React, { useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, Grid, Paper, Container, Typography, FormControlLabel, Switch, useMediaQuery, IconButton, Breadcrumbs, FormHelperText, Toolbar, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext, Opportunity, Skill } from "../../../../../stores";
import { useParams } from "react-router-dom";
import { ValidationError } from "../../../../../stores/models/ValidationError";
import { OpportunityNeed, TimeCommitment, OpportunityScheduling, OpportunityLocation, OpportunityPermissionType } from "../../../../../stores/models/Opportunity";
import { getOpportunityLink, getOrgOpportunitiesLink, getOpportunitiesTableLink } from "../../../../Navigation/Links/UrlConstructors";
import LoadingIndicator from "../../../../Shared/LoadingIndicator";
import FormError from "../../../../Organization/VolunteerOpportunities/FormError";
import FieldHeader from "../../../../Shared/FieldHeader";
import EditableField from "../../../../Shared/EditableField";
import RadioList from "../../../../Organization/VolunteerOpportunities/RadioList";
import { needOptions, commitmentOptions, schedulingOptions, locationOptions, permissionLevelOptions } from "../../../../Organization/VolunteerOpportunities/RadioListData";
import LocationEditor from "../../../../Organization/LocationEditor";
import ShiftListEditor from "../../../../Organization/VolunteerOpportunities/ShiftListEditor";
import { DateFormatter } from "../../../../../logic/DateFormatter";
import SkillsDialog from "../../../../Organization/VolunteerOpportunities/SkillsDialog";
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Address } from "../../../../../stores/models/Address";
import clsx from 'clsx';
import Editor from "../../../Editor";
import ChipEditor from "../../../../Shared/ChipEditor";
import { DialogState } from "../../../../../stores/models/DialogState";
import { INumericalIdOptionObject } from "../../../../../stores/models/Option";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import { Eye, MapMarker, Pin } from "mdi-material-ui";
import AddressDisplay from "../../../../Shared/AddressDisplay";
import { EditorState } from "../../../../../stores/models/EditorState";
import { getOpportunityEditorFields } from "./OpportunityEditorFields";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import InactiveSubscriptionMessage from "./../Subscription/InactiveSubscriptionMessage";
import ZeroState from "../../../../Shared/ZeroState";
import ShiftList from "../../../../Organization/VolunteerOpportunities/ShiftList";
import CheckboxList from "../../../../Shared/CheckboxList";
import ServiceEntryTagAutocomplete, { IServiceEntryTagOption } from "../../../../../modules/volunteer-hours/components/ServiceEntryTagAutocomplete";
import { SIDEBAR_DRAWER_WIDTH, SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT, SIDEBAR_ICON_ONLY_DRAWER_WIDTH } from "../../../../Shared/MenuSidebar/MenuSidebar";
import { ServiceEntryTag } from "../../../../../stores/models/ServiceEntryTag";
import { getUniqueArrayObjectsByProperty } from "../../../../../logic/UtilityFunctions";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import { NavigateInternally } from "../../../../Navigation/Components";
import REMFab from "../../../../../shared/modules/rem-conversion/components/Fab";
import REMChip from "../../../../../shared/modules/rem-conversion/components/Chip";
import REMKeyboardDatePicker from "../../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%'
        },
        contentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        paper: {
            paddingTop: '15px',
            paddingBottom: '15px',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            overflow: 'hidden'
        },
        formError: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(-2)
        },
        section: {
            paddingTop: theme.spacing(2)
        },
        sectionTitle: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: theme.spacing(-2, -3, 1, -3),
            padding: theme.spacing(1.5, 3)
        },
        indentedSection: {
            marginLeft: theme.spacing(9),
        },
        skillsSection: {
            marginBottom: theme.spacing(2)
        },
        addressesSection: {
            marginBottom: theme.spacing(2)
        },
        shiftsSection: {
            marginBottom: theme.spacing(2)
        },
        subsection: {
            paddingTop: theme.spacing(1)
        },
        skillsButton: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1)
        },
        floatingButtonWrapper: {
            paddingBottom: theme.spacing(2)
        },
        floatingButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        floatingButton: {
            marginRight: theme.spacing(2),
            background: theme.palette.darkBackground.main,
            color: theme.palette.darkBackground.contrastText,
            '&:hover': {
                background: theme.palette.darkBackground.light,
            },
            '& svg': {
                marginRight: theme.spacing(1),
            }
        },
        discardButton: {
            marginRight: theme.spacing(1)
        },
        linkWrapper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        locations: {
            '& $address:last-child': {
                marginBottom: 0
            }
        },
        address: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },
        icon: {
            marginRight: theme.spacing(1)
        },
        breadcrumbs: {
            marginBottom: theme.spacing(2),
        },
        mobileBreadcrumbs: {
            marginBottom: theme.spacing(2),
            display: 'flex'
        },
        backButton: {
            padding: 0
        },
        active: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:focus, &:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText
            }
        },
        chips: {
            '& > *': {
                marginBottom: '4px',
                marginRight: '4px'
            }
        },
        chip: {
            marginBottom: '2px'
        },
        checkboxList: {
            maxWidth: '26.5625rem'
        },
        bottomFixedElement: {
            transition: theme.transitions.create('margin-left'),
            [theme.breakpoints.up(SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT)]: {
                marginLeft: SIDEBAR_DRAWER_WIDTH, // TODO: Once TypeScript supports it, consider using ResizeObserver instead
            },
            [theme.breakpoints.only(SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT)]: {
                marginLeft: SIDEBAR_ICON_ONLY_DRAWER_WIDTH
            },
            position: 'fixed',
            top: 'auto',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: theme.zIndex.drawer - 1
        },
        toolbar: {
            padding: 0,
            background: 'white',
            borderTop: '1px solid lightgray',
        },
        toolbarContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        toolbarSpacer: theme.mixins.toolbar,
        actionButtons: {
            '& > :not(:last-child)': {
                marginRight: theme.spacing(1)
            }
        },
        actionButton: {
            color: theme.palette.action.active,
        }
    })
);

enum DialogType {
    Skills = "skills",
}

const MAX_SKILL_SELECTIONS = 10;
const OPPORTUNITY_EDITOR_ID = 'opportunity-editor';

const OpportunityEditor = observer(() => {

    /********* React hooks *********/
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const opportunityStore = rootStore.opportunityStore;
    const locationStore = rootStore.locationStore;
    const userStore = rootStore.userStore;
    const organization = userStore.user.organization;
    const organizationTags = userStore.user.organization?.tags || [];
    const { oppId } = useParams() as { oppId: string };
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* State *********/
    const parsedOppId = parseInt(oppId || "");
    const parsedOrgId = rootStore.userStore.user.organization?.id || -1;
    const creatingNewOpp = isNaN(parsedOppId);

    const [isLoading, setIsLoading] = React.useState(!creatingNewOpp);
    const [locationsLoaded, setLocationsLoaded] = React.useState(false);
    const [errors, setErrors] = React.useState([] as ValidationError<Opportunity>[]);
    const [initialEditingState, setInitialEditingState] = React.useState(true);
    const [baseOpportunity, setBaseOpportunity] = React.useState(new Opportunity());
    const [oppDraft, setOppDraft] = React.useState(new Opportunity());

    const getLocationCollection = () => {
        return new OptionCollection<'id', INumericalIdOptionObject, Address>(
            'id',
            organization?.locations.addresses || [],
            oppDraft.addressCollection.addresses
        );
    }

    const [locationCollection, setLocationCollection] = React.useState(getLocationCollection());

    const getTagCollection = () => {
        let roleTagIncluded = false;
        let options: IServiceEntryTagOption[] = organizationTags.map((tag) => {
            if (tag.tag === oppDraft.position) {
                roleTagIncluded = true;
                return {
                    ...tag,
                    roleTag: true
                }
            } else {
                return {
                    ...tag,
                    roleTag: false
                }
            }
        });
        if (!roleTagIncluded && oppDraft.position.length > 0) {
            options.concat({
                id: -1,
                tag: oppDraft.position,
                roleTag: true
            });
        }
        return new OptionCollection<'id', INumericalIdOptionObject, IServiceEntryTagOption>(
            'id',
            options,
            oppDraft.serviceEntryTags
        );
    }

    const [tagCollection, setTagCollection] = React.useState(getTagCollection());

    // Set up the state for each of the dialogs
    let state = {} as { [key in DialogType]: DialogState };
    Object.values(DialogType).forEach(dialog => { state[dialog as DialogType] = new DialogState() });
    const [dialogState] = React.useState(state);

    /********* Effects *********/

    useEffect(() => {
        if (creatingNewOpp) {
            setInitialEditingState(true);
            rootStore.supplementalDataStore.getSupplementalDataForNewOpportunity();
        }
    }, [creatingNewOpp]);

    useEffect(() => {
        sendGetOpportunityRequest();
    }, [parsedOppId, parsedOrgId]);

    useEffect(() => {
        resetDraft();
    }, [baseOpportunity]);

    // Make sure the form is scrolled to the top when it is first loaded.
    // Fixes scroll issue when linking from the bottom of the Opportunities Table.
    useEffect(() => {
        scrollToTopOfForm();
    }, []);

    useEffect(() => {
        setErrors([]);
    }, [oppDraft]);

    useEffect(() => {
        if (!isLoading) {
            loadLocations();
        }
    }, [oppDraft.addressCollection, isLoading]);

    useEffect(() => {
        setLocationCollection(getLocationCollection());
    }, [oppDraft.addressCollection, organization?.locations, oppDraft]);

    useEffect(() => {
        oppDraft.addressCollection.setAddresses(locationCollection.selectedOptions);
    }, [locationCollection.selections]);

    useEffect(() => {
        const roleOption = tagCollection.options.find(option => option.object.roleTag === true);
        if (oppDraft.position.length > 0) {
            if (roleOption) {
                tagCollection.addOrUpdateOption({ ...roleOption.object, tag: oppDraft.position }, roleOption.selected);
            } else {
                tagCollection.addOrUpdateOption({ id: -1, tag: oppDraft.position, roleTag: true });
            }
        } else if (roleOption) {
            tagCollection.removeOption(roleOption.object);
        }
    }, [oppDraft.position]);

    useEffect(() => {
        setTagCollection(getTagCollection());
    }, [oppDraft.serviceEntryTags, organizationTags]);

    /********* Event handlers *********/

    const saveEdits = async (editorState: EditorState) => {
        if (editorState.editing) {
            editorState.setLoading(true);
            await confirmOpportunityEditsRequest(editorState);
            scrollToTopOfForm();
            editorState.setLoading(false);
        }
    };

    const handleDiscardClicked = (editorState: EditorState) => {
        editorState.setEditing(false);
        setErrors([]);
        resetDraft();
    }

    /* --- Skill changes --- */

    const handleConfirmSkills = (skills: Skill[]) => {
        oppDraft.setSkills(skills);
        handleCloseDialog(DialogType.Skills);
    };

    const handleSkillRemoved = (skillId: number) => {
        oppDraft.removeSkill(skillId);
        handleCloseDialog(DialogType.Skills);
    }

    /* --- Dialog state changes --- */

    const handleOpenDialog = (dialog: DialogType) => { dialogState[dialog].setOpen(true); };
    const handleCloseDialog = (dialog: DialogType) => { dialogState[dialog].setOpen(false); };

    /********* API Requests *********/

    // Request the opportunity matching the id in the URL
    const sendGetOpportunityRequest = async () => {
        setIsLoading(true);
        const opportunity = await opportunityStore.getOppInfo(parsedOrgId, parsedOppId);
        if (opportunity) {
            setBaseOpportunity(opportunity);
        }
        setIsLoading(false);
    }

    const loadLocations = async () => {
        await locationStore.getAllLocationsForOrganization();
        setLocationsLoaded(true);
    };

    const confirmOpportunityEditsRequest = async (editorState: EditorState) => {
        oppDraft.setServiceEntryTags(tagCollection.selectedOptions);
        const responseOrErrors = await opportunityStore.confirmEdits(parsedOrgId, oppDraft);
        if (responseOrErrors instanceof Array) {
            setErrors(responseOrErrors);
        } else {
            editorState.setEditing(false);
            setErrors([]);
            if (wasAutomaticallyInactivated(responseOrErrors)) {
                if (oppDraft.virtual === OpportunityLocation.Remote) {
                    openRemoteOppsLimitWarning();
                } else {
                    openActiveCitiesLimitWarning();
                }
            }
            addNewTagsToOrganizationTags(responseOrErrors.opportunity.serviceEntryTags);
            setBaseOpportunity(responseOrErrors.opportunity);
            if (creatingNewOpp) {
                navigateToOpportunity(responseOrErrors.opportunity.id);
            }
        }
    }

    const openActiveCitiesLimitWarning = () => {
        rootStore.navigationStore.appwideDialogStates.activeCitiesLimitDialog.setOpen(true);
    }

    const openRemoteOppsLimitWarning = () => {
        rootStore.navigationStore.appwideDialogStates.remoteOppsLimitDialog.setOpen(true);
    }

    // Checks if the opportunity was automatically marked as inactive due to the user exceeding 
    // their active recruitment cities limit.
    const wasAutomaticallyInactivated = (updateResponse: { warnings: { code: number }[] }) => {
        const indexOfWarning = updateResponse.warnings.findIndex(warning => warning.code === 1000);
        return indexOfWarning !== -1;
    }

    /********* Helper methods *********/

    const addNewTagsToOrganizationTags = (serviceEntryTags: ServiceEntryTag[]) => {
        if (!organization) return;
        const mergedTags = organizationTags.concat(serviceEntryTags);
        const uniqueTags = getUniqueArrayObjectsByProperty(mergedTags, 'id');
        organization.setTags(uniqueTags);
    }

    const resetDraft = () => {
        setOppDraft(new Opportunity(baseOpportunity));
    }

    const scrollToTopOfForm = () => {
        window.scroll({ top: 0 });
    }

    const navigateToOpportunity = (opportunityId: number) => {
        navigate(getOpportunityLink(parsedOrgId, opportunityId));
    }

    const returnToOpportunitiesTable = () => {
        navigate(getOpportunitiesTableLink());
    }

    /********* Helper constants *********/

    const canEdit = rootStore.organizationStore.isAuthorizedToUpdate(parsedOrgId);
    const shouldRedirect = creatingNewOpp && !canEdit;

    const needError = oppDraft.errors.need;
    const commitmentError = oppDraft.errors.timeCommitment;
    const locationError = oppDraft.errors.virtual;
    const addressError = oppDraft.errors.addressCollection;
    const schedulingError = oppDraft.errors.scheduling;
    const permissionsError = oppDraft.errors.permissionType;
    const expirationDateError = oppDraft.errors.expirationTimestamp || "";

    const fields = getOpportunityEditorFields();

    /********* Render *********/

    if (isLoading) {
        return <LoadingIndicator />;
    } else if (shouldRedirect) {
        return <NavigateInternally to={getOrgOpportunitiesLink(parsedOrgId)} replace />;
    }

    return (
        <div className={classes.root} id={OPPORTUNITY_EDITOR_ID}>
            {!userStore.user.active
                ? <ZeroState content={
                    <Container>
                        <InactiveSubscriptionMessage content={
                            creatingNewOpp
                                ? `Plug back in to post a new opportunity.`
                                : `Plug back in to edit an opportunity.`
                        }
                        />
                    </Container>
                }
                />
                : <div className={classes.contentWrapper}>
                    <Container>
                        {xsDown
                            ? <div className={classes.mobileBreadcrumbs}>
                                <IconButton className={classes.backButton} onClick={returnToOpportunitiesTable}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <REMChip label={`Opportunities`} onClick={returnToOpportunitiesTable} />
                            </div>
                            : <Breadcrumbs className={classes.breadcrumbs} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <REMChip
                                    label={'Opportunities'}
                                    className={classes.chip}
                                    onClick={returnToOpportunitiesTable}
                                    key={`breadcrumb-opportunities-table`}
                                />
                                <REMChip
                                    label={'Opportunity Editor'}
                                    onClick={() => { return; }}
                                    className={clsx(classes.active, classes.chip)}
                                    key={`breadcrumb-opportunity-editor`}
                                />
                            </Breadcrumbs>
                        }
                    </Container>
                    <Editor editing={initialEditingState} editsMade={oppDraft.isDirty}>
                        {(editorState) => {
                            const editMode = editorState.editing;
                            const oppToDisplay = editMode ? oppDraft : baseOpportunity;
                            return (
                                <React.Fragment>
                                    <Container>
                                        <div id="opportunity-information">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        <Container>
                                                            <div className={classes.sectionTitle}>
                                                                <Typography variant="h4">
                                                                    {creatingNewOpp ? 'Post New Volunteer Opportunity' : oppToDisplay.position}
                                                                </Typography>
                                                            </div>
                                                            {editMode && errors.length > 0
                                                                && <div className={classes.formError}>
                                                                    <FormError />
                                                                </div>
                                                            }
                                                            {/* Text Fields */}
                                                            {fields.map((field, index) => {
                                                                return (
                                                                    <div className={classes.section} key={`text-field-${index}`}>
                                                                        <FieldHeader
                                                                            title={field.title}
                                                                            instructions={editMode ? field.instructions : undefined}
                                                                            example={editMode ? field.secondaryText : undefined}
                                                                            required={editMode && field.required}
                                                                        />
                                                                        <Grid container>
                                                                            <Grid item {...field.sizing} xs={field.sizing?.xs ? field.sizing.xs : 12}>
                                                                                <EditableField
                                                                                    fieldName={field.fieldName}
                                                                                    value={oppDraft[field.fieldName] as string}
                                                                                    editMode={editMode}
                                                                                    characterLimit={field.maxLength}
                                                                                    onFieldEdited={(fieldName, event) => field.onFieldEdited(oppDraft, event.target.value)}
                                                                                    TextFieldProps={{
                                                                                        variant: 'outlined',
                                                                                        fullWidth: true,
                                                                                        multiline: true,
                                                                                        rows: field.minRows,
                                                                                        rowsMax: field.maxRows,
                                                                                        error: oppDraft.isFieldInvalid(field.fieldName),
                                                                                        helperText: oppDraft.getErrorForField(field.fieldName),
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Container>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={4} className={classes.section}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        <Container>
                                                            {/* Locations */}
                                                            <div className={classes.sectionTitle}>
                                                                <Typography variant="h5">
                                                                    Location
                                                                </Typography>
                                                            </div>
                                                            <FieldHeader
                                                                title="Remote or In Person"
                                                                instructions={editMode ? "Where does this opportunity take place?" : undefined}
                                                                required={editMode}
                                                            />
                                                            {editMode
                                                                ? <div>
                                                                    <RadioList
                                                                        selectedValue={oppDraft.virtual}
                                                                        options={locationOptions}
                                                                        onValueChanged={(value) => { oppDraft.setVirtual(value as OpportunityLocation); }}
                                                                        error={locationError}
                                                                    />
                                                                </div>
                                                                : <Typography>
                                                                    {oppToDisplay.virtual === OpportunityLocation.Remote
                                                                        ? OpportunityLocation.Remote
                                                                        : OpportunityLocation.InPerson
                                                                    }
                                                                </Typography>
                                                            }
                                                            {oppDraft.virtual === OpportunityLocation.InPerson &&
                                                                <div className={clsx(classes.addressesSection, classes.section)}>
                                                                    <FieldHeader
                                                                        title="Addresses"
                                                                        required={editMode}
                                                                        instructions={editMode
                                                                            ? (organization && organization.locations.addresses.length > 0)
                                                                                ? 'Select or add any addresses where this opportunity takes place.'
                                                                                : 'Add any addresses where this opportunity takes place.'
                                                                            : undefined
                                                                        }
                                                                    />
                                                                    {editMode
                                                                        ? <React.Fragment>
                                                                            {addressError
                                                                                ? <FormHelperText error={true}>{addressError}</FormHelperText>
                                                                                : undefined
                                                                            }
                                                                            <LocationEditor
                                                                                addressOptions={locationCollection}
                                                                                addressRemovable={(selectedAddress: Address) => selectedAddress.id < -1}
                                                                            />
                                                                        </React.Fragment>
                                                                        : <div className={classes.locations}>
                                                                            {oppToDisplay.addressCollection.addresses.length > 0
                                                                                ? oppToDisplay.addressCollection.addresses.map((address, index) => {
                                                                                    return (
                                                                                        <div className={classes.address} key={`address-${index}`}>
                                                                                            <MapMarker fontSize='small' color={"primary"} className={classes.icon} />
                                                                                            <AddressDisplay address={address} />
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                                : <Typography>None Listed</Typography>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </Container>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} className={classes.section}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        <Container>
                                                            {/* Scheduling */}
                                                            <div className={classes.sectionTitle}>
                                                                <Typography variant="h5">
                                                                    Scheduling
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                <FieldHeader
                                                                    title='Scheduling Type'
                                                                    instructions={editMode ? "How does an individual schedule time to volunteer?" : undefined}
                                                                    required={editMode}
                                                                />
                                                                {editMode
                                                                    ? <RadioList
                                                                        selectedValue={oppToDisplay.scheduling}
                                                                        options={schedulingOptions}
                                                                        onValueChanged={(value) => { oppDraft.setScheduling(value as OpportunityScheduling) }}
                                                                        error={schedulingError}
                                                                    />
                                                                    : <Typography>
                                                                        {oppToDisplay.scheduling}
                                                                    </Typography>
                                                                }
                                                            </div>
                                                            {/* Permissions */}
                                                            {oppDraft.scheduling === OpportunityScheduling.Shifts &&
                                                                <div className={classes.section}>
                                                                    <FieldHeader
                                                                        title="Permission Level"
                                                                        instructions={editMode ? "What permission level is required to sign up for a shift?" : undefined}
                                                                        required={editMode}
                                                                    />
                                                                    {editMode
                                                                        ? <RadioList
                                                                            selectedValue={oppToDisplay.permissionType}
                                                                            options={permissionLevelOptions}
                                                                            onValueChanged={(value) => { oppDraft.setPermissionType(value as OpportunityPermissionType) }}
                                                                            error={permissionsError}
                                                                        />
                                                                        : <Typography>
                                                                            {oppToDisplay.permissionType}
                                                                        </Typography>
                                                                    }
                                                                </div>
                                                            }
                                                            {/* Shifts */}
                                                            {oppDraft.scheduling === OpportunityScheduling.Flexible || oppDraft.scheduling === OpportunityScheduling.Unspecified
                                                                ? null
                                                                : <div className={clsx(classes.shiftsSection, classes.section)}>
                                                                    <FieldHeader
                                                                        title="Shifts"
                                                                        instructions={editMode ? "Add shifts to let volunteers know when this opportunity takes place." : undefined}
                                                                    />
                                                                    {editMode
                                                                        ? <div className={classes.subsection}>
                                                                            <ShiftListEditor
                                                                                shiftCollection={oppToDisplay.shiftCollection}
                                                                                locationCollection={locationCollection}
                                                                                locationsLoaded={locationsLoaded}
                                                                            />
                                                                        </div>
                                                                        : oppToDisplay.shiftCollection.shifts.length > 0
                                                                            ? <ShiftList
                                                                                records={oppToDisplay.shiftCollection.orderedShifts}
                                                                                editable={false}
                                                                                onShiftActionSelected={() => { return; }}
                                                                            />
                                                                            : <Typography>None Listed</Typography>
                                                                    }
                                                                </div>
                                                            }
                                                        </Container>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} className={classes.section}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        <Container>
                                                            <div className={classes.sectionTitle}>
                                                                <Typography variant="h5">
                                                                    Opportunity Details
                                                                </Typography>
                                                            </div>
                                                            {/* Need */}
                                                            <div>
                                                                <FieldHeader
                                                                    title="Need"
                                                                    instructions={editMode ? "Is this a one time or ongoing need for your organization?" : undefined}
                                                                    required={editMode}
                                                                />
                                                                {editMode
                                                                    ? <RadioList
                                                                        selectedValue={oppToDisplay.need}
                                                                        options={needOptions}
                                                                        onValueChanged={(value) => { oppDraft.setNeed(value as OpportunityNeed); }}
                                                                        error={needError}
                                                                    />
                                                                    : <Typography>{oppToDisplay.need}</Typography>
                                                                }
                                                            </div>
                                                            {/* Commitment Level */}
                                                            {oppDraft.need === OpportunityNeed.Ongoing &&
                                                                <div className={classes.section}>
                                                                    <FieldHeader
                                                                        title="Commitment Level"
                                                                        instructions={editMode
                                                                            ? "What sort of commitment would a volunteer be making to your organization?"
                                                                            : undefined
                                                                        }
                                                                        required={editMode}
                                                                    />
                                                                    {editMode
                                                                        ? <RadioList
                                                                            selectedValue={oppToDisplay.timeCommitment}
                                                                            options={commitmentOptions}
                                                                            onValueChanged={(value) => { oppDraft.setTimeCommitment(value as TimeCommitment) }}
                                                                            error={commitmentError}
                                                                        />
                                                                        : <Typography>{oppToDisplay.timeCommitment}</Typography>
                                                                    }
                                                                </div>
                                                            }
                                                            {/* Skills */}
                                                            <div className={classes.section}>
                                                                <FieldHeader
                                                                    title="Skills"
                                                                    instructions={editMode ?
                                                                        `What skills are you looking for in a volunteer? (Select up to ${MAX_SKILL_SELECTIONS})`
                                                                        : undefined
                                                                    }
                                                                />
                                                                {editMode || creatingNewOpp
                                                                    ? <div className={clsx(classes.subsection, classes.skillsSection)}>
                                                                        <ChipEditor
                                                                            options={oppToDisplay.skills}
                                                                            labelProperty={'skill'}
                                                                            buttonText={'Add Skills'}
                                                                            onAddButtonClicked={() => handleOpenDialog(DialogType.Skills)}
                                                                            onChipRemoved={handleSkillRemoved}
                                                                        />
                                                                        <SkillsDialog
                                                                            state={dialogState.skills}
                                                                            selectedSkills={oppToDisplay.skills}
                                                                            onSelectionsChanged={handleConfirmSkills}
                                                                            maxSelections={MAX_SKILL_SELECTIONS}
                                                                        />
                                                                    </div>
                                                                    : <Typography>
                                                                        {oppToDisplay.skills.length > 0
                                                                            ? oppToDisplay.skillsList
                                                                            : 'None Listed'
                                                                        }
                                                                    </Typography>
                                                                }
                                                            </div>
                                                            {/* Additional Details */}
                                                            {!editMode && oppToDisplay.filterOptions.selectedOptions.length === 0
                                                                ? null
                                                                : <div className={classes.section}>
                                                                    <FieldHeader
                                                                        title="Additional Details"
                                                                        instructions={editMode ?
                                                                            `Help volunteers determine if this opportunity is a good fit for them.`
                                                                            : undefined
                                                                        }
                                                                    />
                                                                    {editMode
                                                                        ? <div className={classes.checkboxList}>
                                                                            <CheckboxList options={oppToDisplay.filterOptions} />
                                                                        </div>
                                                                        : <div className={classes.chips}>
                                                                            {oppToDisplay.filterOptions.selectedOptions.map((selection, index) => {
                                                                                return (
                                                                                    <REMChip
                                                                                        key={`filter-${index}`}
                                                                                        label={selection.chipText ? selection.chipText : selection.primaryText}
                                                                                        icon={selection.icon ? React.createElement(selection.icon) : undefined}
                                                                                    />
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </Container>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} className={classes.section}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        <Container>
                                                            <div className={classes.sectionTitle}>
                                                                <Typography variant="h5">
                                                                    Administrative Details
                                                                </Typography>
                                                            </div>
                                                            {/* Status */}
                                                            <div>
                                                                <FieldHeader
                                                                    title={'Status'}
                                                                    instructions={editMode ? 'Is this opportunity currently active?' : undefined}
                                                                />
                                                                {editMode
                                                                    ? <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={oppToDisplay.active}
                                                                                onChange={(event, checked) => oppDraft.setActive(checked)}
                                                                                name="active"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        labelPlacement="start"
                                                                        label="Active"
                                                                    />
                                                                    : oppToDisplay.status
                                                                }
                                                            </div>
                                                            {/* Expiration Date */}
                                                            {oppDraft.active &&
                                                                <div className={classes.section}>
                                                                    <FieldHeader
                                                                        title="Expiration Date"
                                                                        instructions={editMode ? "When should this opportunity be deactivated?" : undefined}
                                                                        required={editMode}
                                                                    />
                                                                    {editMode
                                                                        ? <div>
                                                                            <REMKeyboardDatePicker
                                                                                id="birthdate-picker"
                                                                                format="MM/dd/yy"
                                                                                minDate={new Date()}
                                                                                value={oppToDisplay.expirationTimestamp === null
                                                                                    ? ''
                                                                                    : DateFormatter.getDateInUTC(oppToDisplay.expirationTimestamp.toString())
                                                                                }
                                                                                onChange={(date, value) => oppDraft.setExpirationDate(date)}
                                                                                variant="inline"
                                                                                inputVariant="outlined"
                                                                                required
                                                                                autoOk
                                                                                disableToolbar
                                                                                KeyboardButtonProps={{
                                                                                    'aria-label': 'change date',
                                                                                }}
                                                                                error={expirationDateError.length > 0}
                                                                                helperText={expirationDateError ? expirationDateError : ""}
                                                                            />
                                                                        </div>
                                                                        : <Typography>
                                                                            {oppToDisplay.expirationTimestamp
                                                                                ? DateFormatter.getDateInUTC(oppToDisplay.expirationTimestamp.toString())
                                                                                : 'None Selected'
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                </div>
                                                            }
                                                            {/* Service Data Tags */}
                                                            <div className={classes.section}>
                                                                <FieldHeader
                                                                    title="Hour Tracking Tags"
                                                                    instructions={editMode
                                                                        ? "Add custom tags to help categorize volunteer hours associated with this opportunity. These tags will not be visible to volunteers."
                                                                        : undefined}
                                                                />
                                                                {editMode
                                                                    ? <ServiceEntryTagAutocomplete
                                                                        optionCollection={tagCollection}
                                                                    />
                                                                    : <div className={classes.chips}>
                                                                        {tagCollection.selectedOptions.map((tagOption, index) => {
                                                                            return (
                                                                                <REMChip
                                                                                    key={`tag-${index}`}
                                                                                    label={tagOption.tag}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Container>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </div >
                                    </Container>
                                    <div className={classes.toolbarSpacer} />
                                    {creatingNewOpp
                                        ? <div className={clsx(classes.bottomFixedElement, classes.floatingButtonWrapper)}>
                                            <Container className={classes.floatingButtonContainer}>
                                                {/* Floating Action Button */}
                                                <REMFab
                                                    variant='extended'
                                                    className={classes.floatingButton}
                                                    size="large"
                                                    onClick={() => saveEdits(editorState!)}
                                                >
                                                    <Pin />
                                                    Post Opportunity
                                                </REMFab>
                                            </Container>
                                        </div>
                                        : <Toolbar className={clsx(classes.bottomFixedElement, classes.toolbar)}>
                                            {/* Action Toolbar */}
                                            <Container className={classes.toolbarContainer}>
                                                <Button
                                                    startIcon={<Eye />}
                                                    className={classes.actionButton}
                                                    size={xsDown ? 'medium' : 'large'}
                                                    onClick={() => navigate(getOpportunityLink(parsedOrgId, parsedOppId))}
                                                >
                                                    {xsDown ? 'View' : 'View Opportunity'}
                                                </Button>
                                                {editMode
                                                    ? <div className={classes.actionButtons}>
                                                        <Button
                                                            startIcon={<SaveIcon />}
                                                            color='primary'
                                                            variant='contained'
                                                            size={xsDown ? 'medium' : 'large'}
                                                            onClick={() => saveEdits(editorState!)}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button size='large' onClick={() => handleDiscardClicked(editorState!)} className={classes.actionButton}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    : <Button
                                                        startIcon={<EditIcon />}
                                                        color='primary'
                                                        variant='contained'
                                                        size={xsDown ? 'medium' : 'large'}
                                                        onClick={() => editorState.setEditing(true)}
                                                    >
                                                        Edit
                                                    </Button>
                                                }
                                            </Container>
                                        </Toolbar>
                                    }
                                </React.Fragment>
                            );
                        }}
                    </Editor>
                </div>
            }
        </div >
    );
});

export default OpportunityEditor;