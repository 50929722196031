import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { getSearchLink, getAccountSettingsLink, getContactUsLink, getOrganizationSignUpLink, getOrganizationHelpLink, getVolunteerHelpLink, getVolunteerServiceHistoryLink, buildQueryString, getAboutPagePath, HOURS_LOGGING_PARAMETER } from './Links/UrlConstructors';
import { EmailOutline, Earth, AccountSearch, HelpCircleOutline, Domain } from 'mdi-material-ui';
import { MenuItem, MenuOption, IMenuItem } from '../../stores/models/MenuItem';
import { getOrganizationPathPrefix } from './Links/UrlConstructors';
import { mdiClockEditOutline } from '@mdi/js';

// Main Navigation Menu

export const LogHoursItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Log Hours',
        option: MenuOption.LogHours,
        icon: mdiClockEditOutline,
        linkPath: getVolunteerServiceHistoryLink(),
        queryParameters: buildQueryString({ [HOURS_LOGGING_PARAMETER]: true }),
        urlRegex: new RegExp(getVolunteerServiceHistoryLink() + '/.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const AboutOrganizationMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'About',
        option: MenuOption.AboutOrganization,
        icon: Domain,
        linkPath: getAboutPagePath(),
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const SearchMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Find Opportunities',
        shortenedText: 'Search',
        option: MenuOption.Search,
        icon: SearchIcon,
        linkPath: getSearchLink(),
        urlRegex: new RegExp(getOrganizationPathPrefix() + '/.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const RecruitMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Recruit',
        option: MenuOption.RecruiterSignUp,
        icon: AccountSearch,
        linkPath: getOrganizationSignUpLink(),
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const MobileRecruitMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Recruitment',
        option: MenuOption.Recruitment,
        subItems: [RecruitMenuItem({ text: 'Find Volunteers' })]
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const PublicPageMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Public Page',
        option: MenuOption.PublicPage,
        icon: Earth,
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const AccountMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Profile',
        option: MenuOption.Account,
        icon: AccountCircle,
        linkPath: getAccountSettingsLink(),
        urlRegex: new RegExp(getAccountSettingsLink() + '.*')
    };

    return new MenuItem({ ...defaultItem, ...override });
};

/***** Authentication *****/

export const LoginMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Login / Register',
        option: MenuOption.Login,
        icon: AccountCircle,
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const SignUpMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Sign Up',
        option: MenuOption.SignUp,
        icon: CreateIcon,
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const AuthenticationMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Account',
        option: MenuOption.Authentication,
    };

    return new MenuItem({ ...defaultItem, ...override });
};

/***** Support *****/

export const ContactUsMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Contact Us',
        option: MenuOption.ContactUs,
        icon: EmailOutline,
        linkPath: getContactUsLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const OrganizationHelpMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Help Center',
        option: MenuOption.OrganizationHelp,
        icon: HelpCircleOutline,
        linkPath: getOrganizationHelpLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const VolunteerHelpMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Help Center',
        option: MenuOption.VolunteerHelp,
        icon: HelpCircleOutline,
        linkPath: getVolunteerHelpLink()
    };

    return new MenuItem({ ...defaultItem, ...override });
};

export const SupportMenuItem = (override?: Partial<IMenuItem>) => {
    const defaultItem = {
        text: 'Support',
        option: MenuOption.Support,
        subItems: [ContactUsMenuItem()]
    };

    return new MenuItem({ ...defaultItem, ...override });
};