import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { Grid, Typography, Icon } from '@material-ui/core';
import clsx from 'clsx';
import { OptionCategoryCollection } from '../../stores/models/OptionCategoryCollection';
import { IOptionObject } from '../../stores/models/Option';
import { observer } from 'mobx-react';
import { IconName } from '../../stores/models/Icon';
import REMChip from '../../shared/modules/rem-conversion/components/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryHeader: {
            display: 'inline-flex',
        },
        categoryIcon: {
            marginRight: theme.spacing(1.5),
            marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(.25)
        },
        categoryName: {
            marginBottom: theme.spacing(1)
        },
        chip: {
            margin: '3px',
            padding: '5px'
        },
        unselectedChip: {
            background: theme.palette.lightBackground.main
        }
    }),
);

export interface ChipSelectionGridOption extends IOptionObject {
    text: string;
}

interface ChipSelectionGridProps {
    optionCollection: OptionCategoryCollection<ChipSelectionGridOption>;
    iconDictionary?: Record<string, IconName>;
}

const ChipSelectionGrid = observer((props: ChipSelectionGridProps) => {

    const classes = useStyles();

    return (
        <FormControl component="fieldset">
            <FormGroup>
                <Grid id="grid" container spacing={3}>
                    {props.optionCollection.categories.map((category, index) => {
                        let iconName: IconName | undefined;
                        if (props.iconDictionary) {
                            iconName = props.iconDictionary[category.name];
                        }
                        return (
                            <Grid item xs={12} key={`category-${index}`}>
                                <div>
                                    <div className={classes.categoryHeader}>
                                        {iconName !== undefined &&
                                            <Icon className={classes.categoryIcon}>
                                                {React.createElement(iconName)}
                                            </Icon>
                                        }
                                        <Typography variant="h6" className={classes.categoryName}>
                                            {category.name}
                                        </Typography>
                                    </div>
                                </div>
                                {
                                    category.options.map((option, optionIndex) => {
                                        return (
                                            <REMChip
                                                key={`option-${optionIndex}`}
                                                label={option.object.text}
                                                color={option.selected ? "primary" : undefined}
                                                onClick={() => option.toggleSelection()}
                                                className={clsx(classes.chip, option.selected ? undefined : classes.unselectedChip)}
                                            />
                                        )
                                    })
                                }
                            </Grid>
                        )
                    })}
                </Grid>
            </FormGroup>
        </FormControl>
    );
});

export default ChipSelectionGrid;