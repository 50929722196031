import { useEffect, useState } from "react";
import { OrganizationIdentification, OrganizationDetails, SubscriptionPayment } from "..";
// import OrganizationTypeSelector from "./OrganizationType";
import { Step } from "../../../../stores/models/Step";
import SubscriptionPlans from "./SubscriptionPlans";
import { StubUser } from "../../../../stores/models/StubUser";
import CredentialsInputWrapper from "../Shared/CredentialsInputWrapper";

enum OrganizationSignUpSteps {
    OrganizationTypeSelection = 'type-selection',
    SubscriptionSelection = 'subscription-selection',
    OrganizationIdentification = 'organization-identification',
    Credentials = 'credentials',
    OrganizationDetails = 'organization-details',
    PaymentDetails = 'payment-details'
}

const useOrganizationSignUpSteps = (stubUser: StubUser) => {

    const getSteps = () => {

        if (stubUser.stubOrganization) {

            // const organizationTypeSelectionStep = new Step(OrganizationSignUpSteps.OrganizationTypeSelection, OrganizationTypeSelector, stubUser.stubOrganization, true);
            const planSelectionStep = new Step(OrganizationSignUpSteps.SubscriptionSelection, SubscriptionPlans, stubUser.stubOrganization, true);
            const organizationIdentificationStep = new Step(OrganizationSignUpSteps.OrganizationIdentification, OrganizationIdentification, stubUser.stubOrganization);
            const credentialsStep = new Step(OrganizationSignUpSteps.Credentials, CredentialsInputWrapper, stubUser.credentials);
            const organizationDetailsStep = new Step(OrganizationSignUpSteps.OrganizationDetails, OrganizationDetails, stubUser);
            const paymentStep = new Step(OrganizationSignUpSteps.PaymentDetails, SubscriptionPayment, stubUser, true);

            let steps: Step<any>[] = [];

            if (stubUser.stubOrganization.isForProfit) {
                steps = [
                    // organizationTypeSelectionStep, // TODO: Add back after demo
                    planSelectionStep,
                    organizationIdentificationStep,
                    credentialsStep,
                    organizationDetailsStep,
                    paymentStep
                ];
            } else {
                steps = [
                    // organizationTypeSelectionStep, // TODO: Add back after demo
                    organizationIdentificationStep,
                    credentialsStep,
                    organizationDetailsStep,
                ];
            }
            return steps;
        } else {
            return [];
        }

    }

    const [steps, setSteps] = useState(getSteps());

    useEffect(() => {
        setSteps(getSteps());
    }, [stubUser.stubOrganization, stubUser.stubOrganization?.isForProfit]);

    return steps;
}

export default useOrganizationSignUpSteps;