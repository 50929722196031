import { Avatar, makeStyles, Theme, createStyles, Grid, Typography, Button, ButtonBase } from "@material-ui/core";
import React from "react";
import clsx from 'clsx';
import { observer } from "mobx-react";
import { StringIdentifiedOptionCollection } from "../../stores/models/OptionCollection";
import { IconName } from "../../stores/models/Icon";
import { Dictionary } from "../../logic/Dictionaries";
import { IOptionObject } from "../../stores/models/Option";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
            '&:not($selected):hover': {
                backgroundColor: theme.palette.action.disabled,
            },
            width: '75px',
            height: '75px',
            cursor: 'pointer'
        },
        grid: {
            justifyContent: 'center'
        },
        gridItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        },
        selected: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light
        },
        button: {
            marginBottom: theme.spacing(1),
            boxSizing: 'border-box',
            borderRadius: theme.shape.borderRadius,
            border: `2px solid transparent`,
            '& > span': {
                display: 'flex',
                flexDirection: 'column',
                textTransform: 'none'
            },
            '&:focus': {
                boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
            }
        }
    }),
);

enum OverridableClasses {
    Grid = "grid",
    GridItem = "gridItem",
    Selected = "selected"
}

export interface IIconTileSelectorOption extends IOptionObject {
    primaryText: string;
    secondaryText?: string;
    icon: IconName;
}

interface IconTileSelectorProps {
    optionCollection: StringIdentifiedOptionCollection<IIconTileSelectorOption>;
    classes?: Partial<Dictionary<OverridableClasses, string>>;
}

const IconTileSelector = observer((props: IconTileSelectorProps) => {

    const classes = useStyles();

    return (
        <Grid container spacing={2} className={clsx(classes.grid, props.classes ? props.classes.grid : undefined)}>
            {props.optionCollection.options.map((option, index) => {
                return (
                    <Grid
                        item
                        key={`icon-tile-${index}`}
                        xs={4}
                        className={clsx(classes.gridItem, props.classes ? props.classes.gridItem : undefined)}
                    >
                        <ButtonBase className={classes.button} onClick={event => option.toggleSelection()}>
                            <Avatar
                                variant="rounded"
                                className={clsx(
                                    classes.avatar,
                                    option.selected ? classes.selected : undefined,
                                    option.selected && props.classes ? props.classes.selected : undefined
                                )}
                            >
                                {React.createElement(option.object.icon, { fontSize: "large" })}
                            </Avatar>
                        </ButtonBase>
                        {option.object.secondaryText
                            ? <div>
                                <Typography variant="h6">{option.object.primaryText}</Typography>
                                <Typography variant="subtitle1">{option.object.secondaryText}</Typography>
                            </div>
                            : <Typography variant="caption">{option.object.primaryText}</Typography>
                        }
                    </Grid>
                )
            })}
        </Grid>
    )
});

export default IconTileSelector;