import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { observer } from 'mobx-react';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import { useScrollTrigger, Slide } from '@material-ui/core';
import { useNavigateInternally } from './Hooks';
import useNavigationBarLogoSrc from './NavigationBarLogo';

export const MOBILE_MENU_BREAKPOINT = 1006;
export const MAIN_SEARCH_BREAKPOINT = 650;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.appBar,
      background: theme.palette.common.white,
      boxShadow: 'none',
      borderBottom: '1px solid lightgray',
      left: 0,
      width: '100vw',
      borderTop: 'white'
    },
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
        display: 'flex',
      },
    },
    search: {
      display: 'none',
      [theme.breakpoints.up(MAIN_SEARCH_BREAKPOINT)]: {
        display: 'flex',
        marginRight: theme.spacing(2)
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
        display: 'none',
      },
    },
    link: {
      textDecoration: 'none',
      color: 'inherit'
    },
    flex: {
      display: 'flex',
      alignItems: 'center'
    },
    image: {
      [theme.breakpoints.up(600)]: {
        maxWidth: '200px',
        maxHeight: '50px'
      },
      [theme.breakpoints.between(330, 600)]: {
        maxWidth: '175px',
        maxHeight: '42px'

      },
      [theme.breakpoints.down(330)]: {
        maxWidth: '150px',
        maxHeight: '42px'
      }
    }
  }));

export const NAVIGATION_BAR_ID = 'app-navigation-bar';

interface HideOnScrollProps {
  scrollTarget: Node;
  children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children, scrollTarget } = props;
  // Set the scroll trigger's target to the HTML node that scrolls
  const trigger = useScrollTrigger();//{ target: scrollTarget ? scrollTarget : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface NavigationBarProps {
  refToScrollDiv?: React.MutableRefObject<any>;
}

const NavigationBar = observer((props: NavigationBarProps) => {

  const classes = useStyles();
  const navigate = useNavigateInternally();
  const logoSrc = useNavigationBarLogoSrc();
  // const [scrollTarget, setScrollTarget] = useState<Node>();

  // useEffect(() => {
  //   // Trigger refresh of component
  //   setScrollTarget(props.refToScrollDiv?.current);
  // }, [props.refToScrollDiv?.current]);

  const logo = (
    <div className={classes.flex} onClick={() => navigate("/")}>
      <img src={logoSrc} alt="Voltage Volunteering Logo" className={classes.image} />
    </div>
  );

  return (
    // scrollTarget
    //   ? 
    // <HideOnScroll scrollTarget={scrollTarget}>
    <AppBar id={NAVIGATION_BAR_ID} className={classes.appBar}>
      <Toolbar>
        {logo}
        <div className={classes.grow} />
        <div className={classes.search}>
          {/* <MainSearch /> */}
        </div>
        <div className={classes.sectionDesktop}>
          <DesktopMenu />
        </div>
        <div className={classes.sectionMobile}>
          <MobileMenu />
        </div>
      </Toolbar>
    </AppBar>
    // </HideOnScroll>
    // : null
  );
});

export default NavigationBar;