import { useContext } from "react";
import { RootContext } from "../../../../../stores";
import { observer } from "mobx-react";
import { getSchedulingLink, getShiftDetailsLink, getShiftDetailsPath } from "../../../../Navigation/Links/UrlConstructors";
import { Shift } from "../../../../../stores/models/Shift";
import ScheduleViewer from "../../../Shared/ScheduleViewer";
import { Container } from "@material-ui/core";
import { useNavigateInternally } from "../../../../Navigation/Hooks";

const SchedulingPage = observer(() => {

    const navigate = useNavigateInternally();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const shiftStore = rootStore.shiftStore;

    /********* Helper methods *********/

    const loadShiftsForOrganization = async () => {
        const organization = userStore.user.organization;
        if (organization) {
            const shifts = await shiftStore.getAllShiftsForOrganization(organization.id);
            if (shifts) {
                return {
                    shifts: shifts,
                    shiftOpportunities: organization.shiftOpportunities
                }
            }
        }
    }

    /********* Event handler *********/

    const onShiftClicked = (shift: Shift) => {
        navigate(getShiftDetailsLink(shift.identificationData));
    }

    /********* Render *********/

    return (
        <Container>
            <ScheduleViewer
                title="Scheduling"
                scheduleLink={getSchedulingLink()}
                shiftDetailsPath={getShiftDetailsPath()}
                loadInitialData={async (startTimestamp, endTimestamp) => { return await loadShiftsForOrganization(); }}
                noSubsequentDataToLoad
                onShiftClicked={onShiftClicked}
                editable
                fullScreen
            />
        </Container>
    );
});

export default SchedulingPage;