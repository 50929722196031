import { observable, computed, action, makeObservable } from "mobx";
import { IconName } from "./Icon";

export interface IPathSegment {
    displayText: string;
    link: string;
    icon?: IconName;
}

export class PathSegment implements IPathSegment {
    displayText: string;
    icon?: IconName;
    link: string;

    constructor(displayText: string, link: string, icon?: IconName) {
        makeObservable(this, {
            displayText: observable,
            icon: observable,
            link: observable
        });

        this.displayText = displayText;
        this.link = link;
        this.icon = icon;
    }
}

export interface IBreadcrumbPath {
    pathSegments: IPathSegment[];
}

export class BreadcrumbPath implements IBreadcrumbPath {
    pathSegments = [] as IPathSegment[];

    constructor(pathSegments?: IPathSegment | IPathSegment[]) {
        makeObservable(this, {
            pathSegments: observable,
            pushBreadcrumb: action,
            popBreadcrumb: action,
            reset: action,
            activeSegment: computed
        });

        if (pathSegments) {
            if (Array.isArray(pathSegments)) {
                this.pathSegments = pathSegments.map(pathSegment => {
                    return new PathSegment(pathSegment.displayText, pathSegment.link, pathSegment.icon);
                });
            } else {
                this.pathSegments = [new PathSegment(pathSegments.displayText, pathSegments.link, pathSegments.icon)];
            }
        }
    }

    pushBreadcrumb(breadcrumb: IPathSegment) {
        this.pathSegments.push(new PathSegment(breadcrumb.displayText, breadcrumb.link, breadcrumb.icon));
    }

    popBreadcrumb() {
        this.pathSegments.pop();
    }

    reset() {
        this.pathSegments = [];
    }
    
    get activeSegment() {
        if (this.pathSegments.length > 0) {
            return this.pathSegments[this.pathSegments.length - 1];
        }
    }
}