import { action, computed, makeAutoObservable, observable } from "mobx";
import { Shift, IShift } from "./Shift";
import { Sorter } from "../../logic/Sorter";
import { Dictionary, pruneDictionary, sortKeysBasedOnComparison } from "../../logic/Dictionaries";
import { endOfDay, startOfDay } from "date-fns";
import { ShiftIdentification } from "./ShiftIdentification";

export interface IShiftCollection {
    shifts: Shift[];
    orderedShifts: Shift[];
    addOrUpdateShift: (shift: Shift) => void;
    deleteShift: (shift: Shift) => void;
}

export class ShiftCollection implements IShiftCollection {
    @observable shifts = [] as Shift[];

    constructor(shifts?: IShift[]) {
        makeAutoObservable(this);

        if (shifts) {
            this.shifts = shifts.map(shift => new Shift(shift)) || [];
        }
    }

    /******* Shift update methods *******/

    @action deleteShift(shift: Shift) {
        if (shift.isShiftInstance) {
            this.updateShiftInstance(shift, true);
        } else {
            const index = this.getShiftIndex(shift);
            if (index >= 0 && index < this.shifts.length) {
                this.shifts.splice(index, 1);
            }
        }
    }

    addOrUpdateShift(shift: Shift) {
        if (shift.isShiftInstance) {
            const shiftIndexByParentId = this.getShiftIndexByParentId(shift);
            if (shiftIndexByParentId !== -1) {
                this.updateShift(shiftIndexByParentId, shift)
            } else {
                this.updateShiftInstance(shift);
            }
        } else {
            const shiftIndex = this.getShiftIndex(shift);
            if (shiftIndex === -1) {
                this.addShift(shift);
            } else {
                this.updateShift(shiftIndex, shift);
            }
        }
    }

    private updateShiftInstance(instance: Shift, deleting?: boolean) {
        const parentShift = this.getParentShiftForInstance(instance);
        if (parentShift) {
            if (deleting) {
                parentShift.deleteShiftInstance(instance);
            } else {
                parentShift.modifyShiftInstance(instance);
            }
        }
    }

    /******* Private methods *******/

    private addShift(shift: Shift) {
        this.shifts.push(shift);
    }

    private updateShift(index: number, shift: Shift) {
        if (index >= 0 && index < this.shifts.length) {
            this.shifts[index] = shift;
        }
    }

    /************ Shift Instances *************/

    // Order the occurrences of each of the shift collection's shifts
    getOrderedShiftInstances(count: number, offsetData: { timestamp: Date } | { minShift: Shift, inclusive?: boolean }) {
        let instances: Shift[] = [];
        let nextInstances: Dictionary<number, Shift | undefined> = {};
        if ('timestamp' in offsetData) {
            nextInstances = this.getAllNextInstancesAfterTimestamp(offsetData.timestamp);
        } else {
            const minShift = offsetData.minShift;
            nextInstances = this.getAllNextInstancesAfterShift(minShift);
            if (offsetData.inclusive) {
                const shiftOrParentId = minShift.instanceData?.parentId ? minShift.instanceData?.parentId : minShift.id;
                nextInstances[shiftOrParentId] = minShift;
            }
        }
        let prunedDictionary = pruneDictionary(nextInstances);
        while (instances.length < count && Object.keys(prunedDictionary).length > 0) {
            let sortedKeys = sortKeysBasedOnComparison(prunedDictionary, Sorter.compareShifts);
            const keyOfEarliest = sortedKeys[0];
            const nextInstance = prunedDictionary[keyOfEarliest];
            instances.push(nextInstance);
            const shift = this.shifts.find(shift => shift.id === parseInt(keyOfEarliest.toString()));
            const replacementInstance = shift?.getFollowingInstance(nextInstance);
            if (replacementInstance) {
                prunedDictionary[keyOfEarliest] = replacementInstance;
            } else {
                delete prunedDictionary[keyOfEarliest];
            }
        }
        return instances;
    }

    getShiftInstancesBetweenDates(startDate: Date, endDate: Date) {
        const start = startOfDay(startDate);
        const end = endOfDay(endDate);
        const instances: Dictionary<number, Shift[]> = {};
        // Get next instance for each shift 
        this.shifts.forEach((shift) => {
            let nextInstance = shift.getNextInstanceAfterTimestamp(start, true);
            while (nextInstance && nextInstance.repetitionPattern.startTimestamp! < end) {
                const dictionaryKey = startOfDay(nextInstance.repetitionPattern.startTimestamp!).getTime();
                const dictionaryEntry = instances[dictionaryKey];
                if (dictionaryEntry) {
                    instances[dictionaryKey].push(nextInstance);
                } else {
                    instances[dictionaryKey] = [nextInstance];
                }
                nextInstance = shift.getFollowingInstance(nextInstance);
            }
        });
        // Order the shifts for each date by their start times
        (Object.keys(instances) as unknown as number[]).forEach((date: number) => {
            instances[date] = Sorter.orderShiftsByStartTimestamp(instances[date]);
        })
        return instances;
    }

    getShiftByIdentificationData(shiftIdentification: ShiftIdentification) {
        for (let i = 0; i < this.shifts.length; i++) {
            const topLevelShift = this.shifts[i];
            if (topLevelShift.id !== shiftIdentification.topLevelId) continue;
            const shiftInstanceOrOneOff = topLevelShift.getInstanceByIdentificationData(shiftIdentification);
            if (shiftInstanceOrOneOff) {
                return shiftInstanceOrOneOff;
            }
        }
    }

    /************ Computed properties *************/

    @computed get orderedShifts() {
        return Sorter.orderShiftsByStartTimestamp(this.shifts);
    }

    @computed get addressDictionary(): Dictionary<number, Shift[]> {
        let dictionary = {} as Dictionary<number, Shift[]>;
        this.shifts.forEach(shift => {
            this.mapShiftToAddressId(shift, dictionary);
            shift.instances.map(shiftInstance => {
                this.mapShiftToAddressId(shiftInstance, dictionary);
            });
        });
        return dictionary;
    }

    private mapShiftToAddressId(shift: Shift, dictionary: Dictionary<number, Shift[]>) {
        if (shift.address) {
            const addressId = shift.address.id;
            if (dictionary[addressId]) {
                dictionary[addressId] = dictionary[addressId].concat(shift);
            } else {
                dictionary[addressId] = [shift];
            }
        }
    }

    @computed get hasUnexpiredShifts() {
        return this.shifts.findIndex(shift => shift.repetitionPattern.hasEnded === false) !== -1;
    }

    /************ Helper methods *************/

    private getShiftIndex(shift: Shift) {
        return this.shifts.findIndex((unorderedShift: Shift) => {
            return unorderedShift.id === shift.id;
        });
    }

    private getShiftIndexByParentId(shift: Shift) {
        return this.shifts.findIndex((unorderedShift: Shift) => {
            return unorderedShift.identificationData.topLevelId === shift.identificationData.topLevelId
                && unorderedShift.identificationData.defaultDaysFromStartDate === shift.identificationData.defaultDaysFromStartDate;
        });
    }

    private getParentShiftForInstance(instance: Shift) {
        return this.shifts.find(shift => shift.id === instance.instanceData?.parentId);
    }

    private getAllNextInstancesAfterTimestamp(timestamp: Date, inclusive?: boolean) {
        let nextInstances: Dictionary<number, Shift | undefined> = {};
        this.shifts.forEach(shift => {
            nextInstances[shift.id] = shift.getNextInstanceAfterTimestamp(timestamp, inclusive);
        });
        return nextInstances;
    }

    private getAllNextInstancesAfterShift(offsetShift: Shift) {
        let nextInstances: Dictionary<number, Shift | undefined> = {};
        this.shifts.forEach(shift => {
            if (shift.repetitionPattern.repeats) {
                nextInstances[shift.id] = shift.getFollowingInstance(offsetShift);
            } else {
                const currentShiftFollowsOffset = Sorter.compareShifts(offsetShift, shift) !== 1;
                if (currentShiftFollowsOffset) {
                    nextInstances[shift.id] = shift;
                }
            }
        });
        return nextInstances;
    }
}