

import { makeStyles, Theme, createStyles, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { ChangeEvent, DetailedHTMLProps, HTMLAttributes } from "react";
import { FormInputItem } from "../../../../stores/models/FormInputItem";
import ElementDescription from "./ElementDescription";
import ElementTitle from "./ElementTitle";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex'
        },
        textField: {
            flexGrow: 1,
            maxWidth: '400px'
        },
    }),
);

interface ShortAnswerItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    item: FormInputItem;
}

const ShortAnswerItem = observer((props: ShortAnswerItemProps) => {

    const classes = useStyles();

    const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        props.item.setValue(newValue);
    }

    return (
        <div  {...props}>
            <ElementTitle item={props.item} />
            <ElementDescription item={props.item} />
            <div className={classes.flex}>
                <TextField
                    value={props.item.value}
                    variant='outlined'
                    className={classes.textField}
                    onChange={onChange}
                    error={props.item.errors.value ? true : false}
                    helperText={props.item.errors.value}
                />
            </div>
        </div>
    )
});

export default ShortAnswerItem;