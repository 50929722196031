import QueryString from "qs";
import { ShiftIdentification } from "../../../stores/models/ShiftIdentification";
import { SearchTypeOptions } from "../../../data/SearchOptions";

export const routeWithDescendants = (route: string) => {
    return `${route}/*`;
}

/*** Path segments ***/

export enum BasePathSegments {
    OrganizationSpecificPortal = 'portal'
}

export enum TopLevelPathSegments {
    Search = 'search',
    OrganizationPage = 'organization',
    About = 'about',
    AccountSettings = 'account-settings',
    PasswordReset = 'reset',
    LinkVolunteerToOrg = 'link-to-organization',
    OrganizationAccountSignUp = 'organization-sign-up',
    PrivacyPolicy = 'privacy',
    TermsOfService = 'terms-of-service',
    VolunteerHelp = 'help',
    EmailPreferences = 'email-preferences',
    EmailVerification = 'email-verification',
    Redirecter = 'redirect',
    ContactUs = 'contact-us'
}

export enum URITokens {
    Organization = ':organization',
    OrganizationId = ':orgId',
    ResetToken = ':token',
    StubVolunteerToken = ':token'
}

export enum OrgPagePathSegments {
    Registration = 'registration'
}

enum RedirectPathSegments {
    Portal = 'portal'
}

export const buildPath = (segments: string | string[]) => {
    if (typeof segments === 'string') {
        return "/" + segments;
    }
    return "/" + segments.join("/");
}

export const searchPathSegment = 'search';
export const organizationProfilePathSegment = 'organization';

/*** Custom Portal ***/

export const getCustomPortalPath = (organizationSlug: string) => {
    return `${BasePathSegments.OrganizationSpecificPortal}/${organizationSlug}`;
}

export const getCustomPortalUrl = (organizationSlug: string) => {
    return `${getProtocolAndHost()}/${getCustomPortalPath(organizationSlug)}`;
}

/*** Main Portal ***/

export const getAbsoluteLink = (path: string) => `${getProtocolAndHost()}${path}`;

const getProtocolAndHost = () => `${window.location.protocol}//${window.location.host}`;

/*** Redirect Paths ***/

export const redirectToPortalPath = buildPath([TopLevelPathSegments.Redirecter, RedirectPathSegments.Portal, URITokens.OrganizationId, '*']);

type RedirectToPortalURITokens = URITokens.OrganizationId;

export const getPortalRedirectLink = (params: Record<RedirectToPortalURITokens, string>, link?: string) => {
    let redirectToPortalPath = buildPath([TopLevelPathSegments.Redirecter, RedirectPathSegments.Portal, URITokens.OrganizationId]);
    (Object.keys(params) as RedirectToPortalURITokens[]).forEach(key => {
        const token = params[key];
        redirectToPortalPath = redirectToPortalPath.replace(key, token);
    });
    return redirectToPortalPath + (link ? `${link}` : '');
}

// About Organization page

export const getAboutPagePath = () => {
    return `/${TopLevelPathSegments.About}`;
}

/*** Public pages ***/

// Organization page

export function getOrganizationPathPrefix() {
    return '/organization';
}

export function getOrganizationPath() {
    return `${getOrganizationPathPrefix()}/:orgId`;
}

export function getOrganizationLink(organizationId: number) {
    return `${getOrganizationPathPrefix()}/${organizationId}`;
}

export const opportunitiesPathSegment = "opportunities";

export function getOrgOpportunitiesPath() {
    return `${getOrganizationPath()}/${opportunitiesPathSegment}`;
}

export const organizationOpportunityToken = ":oppId"

export function getOpportunityPath() {
    return `${getOrgOpportunitiesPath()}/${organizationOpportunityToken}`;
}


export function getOrgOpportunitiesLink(organizationId: number) {
    const orgLink = getOrganizationLink(organizationId);
    return `${orgLink}/${opportunitiesPathSegment}`;
}

export function getOrganizationRegistrationPath() {
    return `${getOrganizationPath()}/registration`;
}

// Opportunity page

export function getOpportunityLink(organizationId: number, opportunityId: number) {
    const orgOpportunitiesLink = getOrgOpportunitiesLink(organizationId);
    return `${orgOpportunitiesLink}/${opportunityId}`;
}

// Home page

export function getHomeLink() {
    return `/`;
}

// Search page

export const SEARCH_TYPE_PARAMETER = 'type';

export function getSearchLink(orgSearch?: boolean) {
    return `/search${orgSearch === undefined ? '' : `?${SEARCH_TYPE_PARAMETER}=${orgSearch ? SearchTypeOptions.Organizations : SearchTypeOptions.Opportunities}`}`;
}

// Contact Us page

export function getContactUsLink() {
    return `/contact-us`;
}

// Privacy Policy page

export function getPrivacyPolicyLink() {
    return `/privacy`;
}

// Terms of Service page

export function getTermsOfServiceLink() {
    return `/terms-of-service`;
}

// Help page for volunteers

export const getVolunteerInstructionsLink = () => {
    return `/help`;
}

// Email Preferences page

export function getEmailPreferencesPath() {
    return `/email-preferences`;
}

// Email verification page

export function getEmailVerificationPath() {
    return `/email-verification`
}

// Password reset page

export function getResetPasswordPath() {
    return `/reset/:token`;
}

// Volunteer connect with organization page

export function getLinkToStubVolunteerPath() {
    return `/link-to-organization/:token`;
}

// Organization sign up page

export function getOrganizationSignUpLink() {
    return `/organization-sign-up`;
}

/*** User Administration Pages */

// Account Settings Base URL

export function getAccountSettingsPath() {
    return `account-settings`;
}

export function getAccountSettingsLink() {
    return `/${getAccountSettingsPath()}`;
}

// Dashboard page

export function getDashboardPath() {
    return `dashboard`;
}

export function getDashboardLink() {
    return `${getAccountSettingsLink()}/${getDashboardPath()}`;
}

// Information page

export function getAccountProfilePath() {
    return `profile`;
}

export function getAccountProfileLink() {
    return `${getAccountSettingsLink()}/${getAccountProfilePath()}`;
}

// Portal Configuration page

export function getCustomPortalConfigurationPath() {
    return `portal`;
}

export function getCustomPortalLink() {
    return `${getAccountSettingsLink()}/${getCustomPortalConfigurationPath()}`;
}

// Subscription page

export function getSubscriptionPath() {
    return `subscription`;
}

export function getSubscriptionLink() {
    return `${getAccountSettingsLink()}/${getSubscriptionPath()}`;
}

// Credentials page

export function getSettingsPagePath() {
    return `settings`;
}

export function getSettingsPageLink() {
    return `${getAccountSettingsLink()}/${getSettingsPagePath()}`;
}

// Recruitment subsection

function getRecruitmentPath() {
    return `recruitment`;
}

// Opportunities Table page

export function getOpportunitiesTablePath() {
    return `${getRecruitmentPath()}/opportunities`;
}

export function getOpportunitiesTableLink() {
    return `${getAccountSettingsLink()}/${getOpportunitiesTablePath()}`;
}

// Opportunity Editor page

const opportunityIdParam = ':oppId';

export function getOpportunityEditorPathSegment() {
    return `edit/${opportunityIdParam}`;
}

export function getOpportunityEditorPath() {
    return `${getOpportunitiesTablePath()}/${getOpportunityEditorPathSegment()}`;
}

export function getOpportunityEditorLink(opportunityId: number | string) {
    return `${getOpportunitiesTableLink()}/${getOpportunityEditorPathSegment()}`.replace(opportunityIdParam, opportunityId.toString());
}

// New Opportunity page

export function getNewOpportunityLink() {
    return getOpportunityEditorLink('new-opportunity');
}

// Scheduling page

export function getSchedulingPath() {
    return `${getRecruitmentPath()}/scheduling`;
}

export function getSchedulingLink() {
    return `${getAccountSettingsLink()}/${getSchedulingPath()}`;
}

// Shift Editor page

export const getShiftDetailsPath = () => {
    return 'shift-details';
}

function getShiftDetailsFullPath() {
    return `${getSchedulingLink()}/${getShiftDetailsPath()}`;
}

export const SHIFT_ID_QUERY_PARAMETER = 'shift';
export const PARENT_ID_QUERY_PARAMETER = 'parent';
export const DEFAULT_DAYS_FROM_START_DATE_QUERY_PARAMETER = 'offset';

export function getShiftDetailsLink(queryData: number | ShiftIdentification) {
    return getShiftDetailsLinkWithPrefixPath(getShiftDetailsFullPath(), queryData);
}

function getShiftDetailsLinkWithPrefixPath(prefixPath: string, queryData: number | ShiftIdentification) {
    return `${prefixPath}?${getShiftDetailsQueryString(queryData)}`;
}

function getShiftDetailsQueryString(queryData: number | ShiftIdentification) {
    if (typeof queryData === 'number') {
        return `${SHIFT_ID_QUERY_PARAMETER}=${queryData}`;
    } else if (queryData.parentId) {
        return `${PARENT_ID_QUERY_PARAMETER}=${queryData.parentId}&${DEFAULT_DAYS_FROM_START_DATE_QUERY_PARAMETER}=${queryData.defaultDaysFromStartDate}`;
    } else {
        return `${SHIFT_ID_QUERY_PARAMETER}=${queryData.shiftId}`;
    }
}

// Volunteers page

export function getVolunteersPath() {
    return `${getRecruitmentPath()}/volunteers`;
}

export function getVolunteersLink() {
    return `${getAccountSettingsLink()}/${getVolunteersPath()}`;
}

// Volunteer Details page

export const volunteerIdParam = ':volId';

export function getVolunteerDetailsPath() {
    return `${getVolunteersLink()}/${volunteerIdParam}`;
}

export function getVolunteerDetailsLink(volunteerId: number) {
    return getVolunteerDetailsPath().replace(volunteerIdParam, volunteerId.toString());
}

// Data subsection

function getDataPath() {
    return `data`;
}

// Service Data page

export function getServiceDataPath() {
    return `${getDataPath()}/volunteer-hours`;
}

export function getServiceDataLink() {
    return `${getAccountSettingsLink()}/${getServiceDataPath()}`;
}

// Reporting Summary page

export function getReportingPath() {
    return `${getDataPath()}/reports`;
}

export function getReportingLink() {
    return `${getAccountSettingsLink()}/${getReportingPath()}`;
}

// Report page

export const reportNameParam = ':reportName';

export function getReportPagePath() {
    return `${getReportingPath()}/${reportNameParam}`;
}

export enum ReportNameSlugs {
    VolunteersByStatus = 'volunteers-by-status',
    VolunteerHours = 'volunteer-hours',
    VolunteersWithHoursLogged = 'volunteers-with-hours-logged',
    // TopVolunteers = 'top-volunteers'
}

export function getReportPageLink(reportName: ReportNameSlugs) {
    return `${getAccountSettingsLink()}/${getReportPagePath().replace(reportNameParam, reportName)}`;
}

// Organization Help page

export function getOrganizationHelpPath() {
    return `help`;
}

export function getOrganizationHelpLink() {
    return `${getAccountSettingsLink()}/${getOrganizationHelpPath()}`;
}

// Volunteer Help page

export function getVolunteerHelpPath() {
    return `help`;
}

export function getVolunteerHelpLink() {
    return `${getAccountSettingsLink()}/${getVolunteerHelpPath()}`;
}

// Locations page

export function getLocationsPath() {
    return `locations`;
}

export function getLocationsLink() {
    return `${getAccountSettingsLink()}/${getLocationsPath()}`;
}

// Hands Raised page

export function getHandsRaisedPath() {
    return `hands-raised`;
}

export function getHandsRaisedLink() {
    return `${getAccountSettingsLink()}/${getHandsRaisedPath()}`;
}

// Sparks section

export function getSparksPath() {
    return 'sparks';
}

export function getSparksLink() {
    return `${getAccountSettingsLink()}/${getSparksPath()}`;
}

export function getOpportunitySparksPath() {
    return `${getSparksPath()}/opportunities`;
}

export function getOpportunitySparksLink() {
    return `${getAccountSettingsLink()}/${getOpportunitySparksPath()}`;
}

export function getOrganizationSparksPath() {
    return `${getSparksPath()}/organizations`;
}

export function getOrganizationSparksLink() {
    return `${getAccountSettingsLink()}/${getOrganizationSparksPath()}`;
}

// Volunteer Shifts page

export function getVolunteerShiftsPath() {
    return 'schedule';
}

export function getVolunteerShiftsLink() {
    return `${getAccountSettingsLink()}/${getVolunteerShiftsPath()}`;
}

// Volunteer Shift Details page

export const volunteerShiftDetailsPathSegment = 'shift-details';

export function getVolunteerShiftDetailsPath() {
    return `${getVolunteerShiftsLink()}/${volunteerShiftDetailsPathSegment}`;
}

export function getVolunteerShiftDetailsLink(queryData: number | ShiftIdentification) {
    return getShiftDetailsLinkWithPrefixPath(getVolunteerShiftDetailsPath(), queryData);
}

// Volunteer Service History page

export const HOURS_LOGGING_PARAMETER = 'logging';

export function getVolunteerServiceHistoryPath() {
    return 'service-history';
}

export function getVolunteerServiceHistoryLink() {
    return `${getAccountSettingsLink()}/${getVolunteerServiceHistoryPath()}`;
}

// URL Parameters
export function buildQueryString(params?: object) {
    if (params) {
        return '?' + QueryString.stringify(params);
    } else {
        return '';
    }
}