import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { AccountMultiplePlus, DotsVertical, Email } from "mdi-material-ui";
import { DialogState } from "../../../../../stores/models/DialogState";
import ButtonWithPopper from "../ButtonWithPopper";
import NestedMenu from "../../../../Navigation/NestedMenu";
import { ButtonProps, IconButton, ListItemIcon, ListItemText, MenuItem, MenuItemProps, SvgIconProps } from "@material-ui/core";

const IconMenuItem = (icon: (props: SvgIconProps) => JSX.Element) => (props: MenuItemProps & { button?: true }) => {
    return (
        <MenuItem {...props}>
            <ListItemIcon style={{ minWidth: 'unset', paddingRight: '12px' }}>
                {React.createElement(icon)}
            </ListItemIcon>
            <ListItemText primary={props.children} />
        </MenuItem>
    )
};

const EmailMenuItem = IconMenuItem(Email);
const ImportMenuItem = IconMenuItem(AccountMultiplePlus);


interface VolunteersOverflowMenuProps extends ButtonProps {
    onEmailsClicked: () => void;
    onImportClicked: () => void;
}

const VolunteersOverflowMenu = observer((props: VolunteersOverflowMenuProps) => {

    const state = useMemo(() => new DialogState(), []);

    const menuItems = useMemo(() => {
        return [{
            label: 'Emails',
            MenuItem: EmailMenuItem,
            onClick: props.onEmailsClicked
        }, {
            label: 'Import',
            MenuItem: ImportMenuItem,
            onClick: props.onImportClicked
        }];
    }, [props.onEmailsClicked, props.onImportClicked]);

    /********* Event Handlers *********/

    const onMenuButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        state.setOpen(!state.open);
    }

    // TODO: Improve keyboard usage 
    
    /********* Render *********/

    return (
        <ButtonWithPopper
            Button={(ref) => <IconButton ref={ref} onClick={onMenuButtonClicked}>
                <DotsVertical />
            </IconButton>
            }
            state={state}
        >
            <NestedMenu
                menuItems={menuItems}
                state={state}
            />
        </ButtonWithPopper>
    );
});

export default VolunteersOverflowMenu;