import { differenceInCalendarDays, subDays } from "date-fns";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { Dictionary } from "../../logic/Dictionaries";
import { getUniqueElements } from "../../logic/UtilityFunctions";
import { RepetitionEndType } from "./RepetitionEnding";
import { Shift } from "./Shift";
import { IShiftDetails, ShiftDetails } from "./ShiftDetails";

export enum ShiftEditingOptions {
    ThisShift = 'this-shift',
    ThisAndFollowing = 'this-and-following',
    EntireSeries = 'entire-series'
}

export class ShiftDetailsModification {

    @observable originalTopLevelShift?: ShiftDetails;
    @observable displayedShiftDetails?: ShiftDetails;
    @observable selectedEditingOption?: ShiftEditingOptions;
    @observable oldShiftDetails?: ShiftDetails;
    @observable newShiftDetails?: ShiftDetails;

    constructor(newShiftDetails?: IShiftDetails) {
        makeAutoObservable(this);

        if (newShiftDetails) {
            this.newShiftDetails = new ShiftDetails(newShiftDetails);
        }
    }

    @action setOriginalTopLevelShift(originalTopLevelShift: ShiftDetails) {
        this.originalTopLevelShift = originalTopLevelShift;
    }

    @action setDisplayedShiftDetails(shiftDetails: ShiftDetails) {
        this.displayedShiftDetails = shiftDetails;
    }

    @action updateSelectedShifts(newShiftDetails: ShiftDetails) {
        this.oldShiftDetails = this.shiftDetailsDraft;
        this.newShiftDetails = newShiftDetails;
        if (this.selectedEditingOption === ShiftEditingOptions.ThisAndFollowing) {
            const oldParentShift = this.parentShiftAdjustedToEndBeforeThisShift;
            if (oldParentShift) {
                this.newShiftDetails.setOldParentShift(oldParentShift);
            }
        }
    }

    @action setSelectedEditingOption(editingOption: ShiftEditingOptions) {
        this.selectedEditingOption = editingOption;
    }

    @action deleteSelectedShifts() {
        switch (this.selectedEditingOption) {
            case ShiftEditingOptions.ThisAndFollowing:
                this.deleteThisAndFollowingShifts();
                break;
            case ShiftEditingOptions.EntireSeries:
                this.deleteEntireSeries();
                break;
            case ShiftEditingOptions.ThisShift:
            default:
                this.deleteThisShift();
                break;

        }
    }

    @action private deleteThisShift() {
        this.selectedEditingOption = ShiftEditingOptions.ThisShift;
        this.oldShiftDetails = this.shiftDetailsDraft;
        let deletedShift = new Shift(this.oldShiftDetails?.shift);
        if (this.shiftDetailsDraft?.shift.isTopLevelShift) {
            deletedShift.setArchived(true);
        } else {
            deletedShift.setDeleted(true);
        }
        this.newShiftDetails = this.getShiftDetailsForShift(deletedShift);
    }

    @action private deleteThisAndFollowingShifts() {
        if (!this.parentShiftAdjustedToEndBeforeThisShift) return;
        this.selectedEditingOption = ShiftEditingOptions.EntireSeries; // Edit the entire series to be shorter
        this.oldShiftDetails = this.shiftDetailsDraft;
        this.newShiftDetails = this.getShiftDetailsForShift(this.parentShiftAdjustedToEndBeforeThisShift);
    }

    // When Editing option "This and all following shifts" is chosen,
    // the initial parent shift is updated to end the day before the 
    // current shift instance, and a new parent shift (aka recurrence)
    // is created to describe the repetition for the current shift 
    // instance and any following instances.
    @computed private get parentShiftAdjustedToEndBeforeThisShift() {
        const currentParentShift = this.displayedShiftDetails?.parentShift;
        if (currentParentShift === undefined) return;
        if (!this.displayedShiftDetails?.shift.repetitionPattern.startTimestamp) return;

        const adjustedParentShift = new Shift(currentParentShift);
        adjustedParentShift.repetitionPattern.ending.setEndType(RepetitionEndType.EndDate);
        adjustedParentShift.repetitionPattern.ending.setEndDate(subDays(this.displayedShiftDetails.shift.repetitionPattern.startTimestamp, 1));
        return adjustedParentShift;
    }

    @action private deleteEntireSeries() {
        this.selectedEditingOption = ShiftEditingOptions.EntireSeries;
        this.oldShiftDetails = this.shiftDetailsDraft;
        let deletedShift = new Shift(this.oldShiftDetails?.shift);
        deletedShift.setArchived(true);
        this.newShiftDetails = this.getShiftDetailsForShift(deletedShift);
    }

    @computed private get shiftToEdit() {
        if (this.displayedShiftDetails === undefined) return;
        if (this.displayedShiftDetails.parentShift) {
            switch (this.selectedEditingOption) {
                case ShiftEditingOptions.ThisShift:
                    return this.displayedShiftDetails.shift;
                case ShiftEditingOptions.ThisAndFollowing:
                    return this.displayedShiftDetails.thisAndFollowingShifts;
                case ShiftEditingOptions.EntireSeries:
                    return this.displayedShiftDetails.parentShift;
                default:
                    return;
            }
        } else {
            return this.displayedShiftDetails.shift;
        }
    }

    @computed get shiftDetailsDraft() {
        if (this.displayedShiftDetails === undefined) return;
        if (this.shiftToEdit === undefined) return;
        return this.getShiftDetailsForShift(this.shiftToEdit);
    }

    private getShiftDetailsForShift(shift: Shift) {
        if (!this.displayedShiftDetails) return;
        return new ShiftDetails({
            ...this.displayedShiftDetails,
            shift: shift,
            parentShift: undefined,
            oldParentShift: this.selectedEditingOption == ShiftEditingOptions.ThisAndFollowing ? this.parentShiftAdjustedToEndBeforeThisShift : undefined
        });
    }

    @computed private get originalStartTimestamp() {
        return this.newShiftDetails?.oldParentShift
            ? this.newShiftDetails.oldParentShift.repetitionPattern.startTimestamp
            : this.oldShiftDetails?.topLevelShift.repetitionPattern.startTimestamp;
    }

    /********* Determining Validity of Original Shift/Shift Instances After Updates *********/

    getOffsetMapping() {
        const modifiedInstancesValidity = this.getModifiedInstancesValidity();
        const volunteerRegistrationValidity = this.getVolunteerRegistrationValidity();
        const currentInstanceValidity = this.getCurrentInstanceValidity();
        if (!modifiedInstancesValidity || !volunteerRegistrationValidity || !currentInstanceValidity) return {};
        const offsetMapping = {} as Dictionary<number, number>;
        const concatenatedValidityData = modifiedInstancesValidity.valid.concat(volunteerRegistrationValidity.valid).concat(currentInstanceValidity.valid);
        concatenatedValidityData.forEach(validInstance => {
            if (validInstance.offsetMapping) {
                offsetMapping[validInstance.offsetMapping.oldOffset] = validInstance.offsetMapping.newOffset;
            }
        })
        return offsetMapping;
    }

    private getCurrentInstanceValidity() {
        if (!this.displayedShiftDetails?.shift) return;
        return this.getShiftInstanceValidity([this.displayedShiftDetails.shift]);
    }

    private getModifiedInstancesValidity() {
        if (!this.oldShiftDetails) return;
        return this.getShiftInstanceValidity(this.oldShiftDetails.shift.modifiedInstances);
    }

    private getVolunteerRegistrationValidity() {
        const shiftInstancesWithRegistrations = this.getShiftInstancesWithRegistrations();
        if (!shiftInstancesWithRegistrations) return;
        return this.getShiftInstanceValidity(shiftInstancesWithRegistrations);
    }

    private getShiftInstanceValidity(instances: Shift[]) {
        const dictionary = { valid: [], invalid: [] } as Dictionary<'valid' | 'invalid', { shift: Shift, offsetMapping?: { oldOffset: number, newOffset: number } }[]>;
        if (!this.newShiftDetails || !this.originalStartTimestamp || this.newShiftOffsetFromOriginal === undefined) return dictionary;
        instances.forEach(instance => {
            const oldOffset = instance.identificationData.defaultDaysFromStartDate;
            if (instance.repetitionPattern.startTimestamp && oldOffset !== undefined && this.newShiftOffsetFromOriginal !== undefined) {
                if (oldOffset >= this.newShiftOffsetFromOriginal) {
                    const actualDaysFromOldStart = differenceInCalendarDays(instance.repetitionPattern.startTimestamp, this.originalStartTimestamp!);
                    const instanceOffset = actualDaysFromOldStart - this.newShiftOffsetFromOriginal!;
                    if (this.newShiftDetails!.shift.repetitionPattern.verifyValidDaysFromStartDate(instanceOffset)) {
                        dictionary.valid.push({ shift: instance, offsetMapping: { oldOffset: oldOffset, newOffset: instanceOffset } });
                    } else {
                        dictionary.invalid.push({ shift: instance });
                    }
                } else {
                    dictionary.invalid.push({ shift: instance });
                }
            }
        })
        return dictionary;
    }

    @computed private get newShiftOffsetFromOriginal() {
        if (!this.newShiftDetails) return;
        return this.getShiftOffsetFromOriginal(this.newShiftDetails?.shift);
    }

    private getShiftOffsetFromOriginal(shift: Shift) {
        if (!this.originalStartTimestamp || !shift.repetitionPattern.startTimestamp) return;
        return differenceInCalendarDays(shift.repetitionPattern.startTimestamp, this.originalStartTimestamp);
    }

    private getShiftInstancesWithRegistrations() {
        if (!this.originalTopLevelShift) return;
        const instances = [] as Shift[];
        this.originalTopLevelShift.shift.shiftRegistrations?.forEach(registration => {
            const instance = this.originalTopLevelShift!.shift!.getInstanceByIdentificationData(registration.shiftIdentification);
            if (instance) {
                instances.push(instance);
            }
        });
        return instances;
    }

    /***** Invalid Instances *****/

    @computed private get allInvalidInstanceOffsets() {
        if (!this.invalidModifiedInstanceOffsets || !this.invalidVolunteerRegistrationOffsets) return;
        let invalidOffsets = this.invalidModifiedInstanceOffsets?.concat(this.invalidVolunteerRegistrationOffsets).concat(this.getInvalidInstanceOffsetsForNewParentShift());
        if (this.isCurrentInstanceInvalid() && this.currentInstanceOffset !== undefined) {
            invalidOffsets = invalidOffsets.concat(this.currentInstanceOffset);
        }
        return getUniqueElements(invalidOffsets);
    }

    isCurrentInstanceInvalid() {
        const currentInstanceValidity = this.getCurrentInstanceValidity();
        return currentInstanceValidity && currentInstanceValidity.invalid.length > 0;
    }

    @computed private get currentInstanceOffset() {
        return this.displayedShiftDetails?.shift.identificationData.defaultDaysFromStartDate;
    }

    @computed private get invalidModifiedInstanceOffsets() {
        const offsets = [] as number[];
        this.invalidModifiedInstances.forEach(instance => {
            const offset = instance.identificationData.defaultDaysFromStartDate;
            if (offset !== undefined) {
                offsets.push(offset);
            }
        });
        return offsets;
    }

    @computed private get invalidModifiedInstances() {
        return this.getModifiedInstancesValidity()?.invalid.map(invalidInstance => invalidInstance.shift) || [];
    }

    @computed private get invalidVolunteerRegistrationOffsets() {
        const offsets = [] as number[];
        this.getVolunteerRegistrationValidity()?.invalid.forEach(invalidInstance => {
            const offset = invalidInstance.shift.identificationData.defaultDaysFromStartDate;
            if (offset !== undefined) {
                offsets.push(offset);
            }
        });
        return offsets;
    }

    // @computed
    private getInvalidInstanceOffsetsForNewParentShift() {
        if (!this.newShiftDetails?.oldParentShift || this.newShiftOffsetFromOriginal === undefined) return [];
        const invalidInstances = this.newShiftDetails.modifiedInstancesAfterMaxOffset.filter(instance => {
            const newInstanceOffset = this.getNewInstanceOffset(instance);
            if (newInstanceOffset === undefined) return false;
            return !this.newShiftDetails?.shift.isValidInstanceOffset(newInstanceOffset);
        });
        return invalidInstances.map(instance => instance.identificationData.defaultDaysFromStartDate!);
    }

    private getNewInstanceOffset(instance: Shift) {
        const originalInstanceOffset = this.getShiftOffsetFromOriginal(instance);
        if (originalInstanceOffset === undefined || this.newShiftOffsetFromOriginal === undefined) return;
        return originalInstanceOffset - this.newShiftOffsetFromOriginal;
    }

    /***** Is the original shift still valid after a change to the repetition pattern? *****/

    @computed private get originalShiftInvalidForNewRepetitionPattern() {
        if (!this.newShiftDetails || this.oldShiftDetails?.topLevelShift.isShiftSeries || this.newShiftOffsetFromOriginal === undefined) return false;
        return !this.newShiftDetails.shift.isValidInstanceOffset(0 - this.newShiftOffsetFromOriginal);
    }

    /******************/

    @computed private get hasNewLocation() {
        return this.newShiftDetails?.shift.address && this.newShiftDetails.shift.address.id < 0;
    }

    serialize() {
        return {
            identificationData: this.oldShiftDetails
                ? this.oldShiftDetails.identificationData
                : this.newShiftDetails
                    ? this.newShiftDetails.identificationData
                    : undefined,
            shiftDetails: this.newShiftDetails?.serialize(),
            address: this.hasNewLocation
                ? this.newShiftDetails?.shift.address?.serialize()
                : undefined,
            newShiftOffsetFromOriginal: this.newShiftOffsetFromOriginal,
            originalShiftInvalidForNewRepetitionPattern: this.originalShiftInvalidForNewRepetitionPattern,
            invalidInstanceOffsets: this.allInvalidInstanceOffsets,
            offsetMapping: this.getOffsetMapping()
        }
    }
}