import { Fragment } from "react";
import { observer } from "mobx-react";
import { Avatar, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { CheckBold } from "mdi-material-ui";
import { getOrganizationLink } from "../../../Navigation/Links/UrlConstructors";
import { useNavigateInternally } from "../../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginBottom: theme.spacing(1),
            maxWidth: 800,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
        button: {
            marginTop: theme.spacing(2)
        }
    })
);

interface InstructionsForAuthenticatedUserProps {
    organizationId: number;
}

const SuccessfulRegistrationMessage = observer((props: InstructionsForAuthenticatedUserProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const navigate = useNavigateInternally();

    /********* Event handler *********/

    const onViewOrganizationPageClicked = () => {
        navigate(getOrganizationLink(props.organizationId));
    }

    /***** Render *****/

    return (
        <Fragment>
            <Avatar className={classes.avatar}>
                <CheckBold fontSize='large' />
            </Avatar>
            <Typography variant="h4" className={classes.prompt}>
                Thank you for registering!
            </Typography>
            <Typography>
                Check out the organization's page to find volunteer opportunities that might spark your interest.
            </Typography>
            <Button
                variant="contained"
                color='primary'
                onClick={onViewOrganizationPageClicked}
                className={classes.button}
            >
                View Organization Page
            </Button>
        </Fragment>
    );
});

export default SuccessfulRegistrationMessage;