import React, { Fragment } from "react";
import { makeStyles, Theme, createStyles, TableCell, IconButton } from "@material-ui/core";
import clsx from 'clsx';
import { observer } from "mobx-react";
import { StubOrganizationVolunteer } from "../../../../../stores/models/StubOrganizationVolunteer";
import RecordTable from "../RecordTable";
import { ISortableTableHeader, TableHeader } from "../../../../../stores/models/TableHeader";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import { Option } from "../../../../../stores/models/Option";
import EmailField from "./InputFields/EmailField";
import NameField from "./InputFields/NameField";
import BirthdateField from "./InputFields/BirthdateField";
import StatusField from "./InputFields/StatusField";
import { Delete } from "mdi-material-ui";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formFields: {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                '&:not(:last-child)': {
                    marginBottom: theme.spacing(2),
                },
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    flexGrow: 1
                }
            }
        },
        iconButton: {
            padding: 0
        },
        alert: {
            marginBottom: theme.spacing(2)
        }
    })
);

const headCells = ([
    { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name*', sortable: false },
    { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name*', sortable: false },
    { id: 'email', alignment: 'left', disablePadding: false, label: 'Email', sortable: false },
    { id: 'birthdate', alignment: 'left', disablePadding: false, label: 'Birthdate', sortable: false },
    { id: 'status', alignment: 'left', disablePadding: false, label: 'Status*', sortable: false },
    { id: 'delete', alignment: 'center', disablePadding: false, label: 'Delete', sortable: false }
] as ISortableTableHeader<StubOrganizationVolunteer>[]).map(headCell => new TableHeader(headCell));

interface ImportVolunteersFormProps extends React.HTMLAttributes<HTMLDivElement> {
    volunteerCollection: OptionCollection<'id', StubOrganizationVolunteer>;
    error?: boolean;
}

const ImportVolunteersForm = observer((props: ImportVolunteersFormProps) => {

    const classes = useStyles();

    /********** Event Handler *********/

    const deleteRow = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, volunteer: StubOrganizationVolunteer) => {
        props.volunteerCollection.removeOptionById(volunteer.id);
    }

    /********** Render *********/

    return (
        <Fragment>
            <Alert severity="info" className={classes.alert}>
                <AlertTitle>Email Invitations</AlertTitle>
                All volunteers imported with an email address, except those with a "Banned" status, will be sent an invitation to link to your organization.
            </Alert>
            <div className={clsx(classes.formFields, props.className)}>
                <RecordTable
                    records={props.volunteerCollection}
                    tableHeaderCells={headCells}
                    displayFunctions={[
                        (option: Option<StubOrganizationVolunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                    <NameField volunteer={option.object} nameType='firstName' />
                                </TableCell>
                            );
                        },
                        (option: Option<StubOrganizationVolunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                    <NameField volunteer={option.object} nameType='lastName' />
                                </TableCell>
                            );
                        },
                        (option: Option<StubOrganizationVolunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                    <EmailField volunteer={option.object} />
                                </TableCell>
                            );
                        },
                        (option: Option<StubOrganizationVolunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                    <BirthdateField volunteer={option.object} />
                                </TableCell>
                            );
                        },
                        (option: Option<StubOrganizationVolunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                    <StatusField volunteer={option.object} />
                                </TableCell>
                            );
                        },
                        (option: Option<StubOrganizationVolunteer>, rowIndex: number, cellIndex: number) => {
                            return (
                                <TableCell align="center" key={`${rowIndex}-${cellIndex}`}>
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={(event) => deleteRow(event, option.object)}
                                    >
                                        <Delete color="action" />
                                    </IconButton>
                                </TableCell>
                            );
                        }
                    ]}
                    onRowClicked={(option: Option<StubOrganizationVolunteer>, rowIndex: number) => {
                        // No action
                    }}
                />
            </div>
        </Fragment>
    )
});

export default ImportVolunteersForm;