import { computed, makeObservable, observable } from "mobx";
import { ISortableTableHeader, TableHeader } from "../../../../stores/models/TableHeader";
import { ServiceEntry } from "../entities/ServiceEntry";
import { ServiceEntryTag } from "../../../../stores/models/ServiceEntryTag";
import { Volunteer } from "../../../../stores";
import { ServiceDataViewModel } from "./ServiceDataViewModel";
import { VolunteerHoursStore } from "../stores/VolunteerHoursStore";

export enum OrganizationSpecificServiceDataHeaderCells {
    Volunteer = 'volunteer',
    Source = 'source'
}

const volunteerHeaderCell: ISortableTableHeader<ServiceEntry> = {
    id: OrganizationSpecificServiceDataHeaderCells.Volunteer,
    sortValue: 'volunteerName',
    alignment: 'left',
    disablePadding: false,
    label: 'Volunteer',
    sortable: true
};

const sourceHeaderCell: ISortableTableHeader<ServiceEntry> = {
    id: OrganizationSpecificServiceDataHeaderCells.Source,
    sortValue: 'hasVolunteerEdits',
    alignment: 'center',
    disablePadding: true,
    label: 'Source',
    sortable: false
};

interface IOrganizationServiceDataViewModel {
    volunteer?: Volunteer;
}

interface ConstructorParams extends IOrganizationServiceDataViewModel {
    store: VolunteerHoursStore;
}

export class OrganizationServiceDataViewModel extends ServiceDataViewModel implements IOrganizationServiceDataViewModel {

    @observable volunteer?: Volunteer | undefined;
    @observable newStatusId: number | null = null;
    @observable newTags: ServiceEntryTag[] | null = null;

    constructor(viewModel: ConstructorParams) {
        super(viewModel.store);
        makeObservable(this);

        this.volunteer = viewModel.volunteer;
        let headerCells = super.getTableHeaderCells();
        if (this.displayVolunteer) {
            headerCells.splice(1, 0, new TableHeader(volunteerHeaderCell));
        }
        headerCells.push(new TableHeader(sourceHeaderCell));
        this.setTableHeaderCells(headerCells);
    }

    @computed private get displayVolunteer() {
        return this.volunteer === undefined || false;
    }

    getDisplayableServiceEntry(serviceEntry: ServiceEntry) {
        const shared = super.getDisplayableServiceEntry(serviceEntry);
        return {
            ...shared,
            volunteer: this.displayVolunteer ? serviceEntry.volunteerName || null : null,
            sourceIcon: serviceEntry.sourceIcon,
            sourceDescriptor: serviceEntry.sourceDescriptor
        }
    }

}