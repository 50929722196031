import React from "react";
import { makeStyles, Theme, createStyles, Container } from "@material-ui/core";
import OrganizationTabBar from "./OrganizationTabBar";

// TODO: Fix styling

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            height: '150px',
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-end',
            color: 'white',
            flexShrink: 0,
            overflow: 'hidden',
            textAlign: 'left',
            zIndex: 0,
            background: theme.palette.darkBackground.main
        },
        headerBackground: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            height: '100%',
            zIndex: -5
            // boxShadow: 'inset 0 0 0 200px rgba(255, 255, 255, 0.05)',
            // filter: 'blur(1px)',
            // filter: 'opacity(60%)',
        },
        blurredBox: {
            [theme.breakpoints.up('sm')]: {
                display: 'inline-block',
            },
            backdropFilter: 'blur(4px)',
            // background: 'rgba(40,40,40,1)',
            boxShadow: '0 0 20px 20px rgba(40,40,40,1)',
            borderRadius: '4px',
            // filter: 'drop-shadow(9px -9px 9px black)',
            // boxShadow: 'inset 0 0 8px 10px rgba(0, 0, 0, 0.05)',
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1)
            // boxShadow: 'inset 0 0 0 10px rgba(0, 0, 0, 0.25)',
        }
    })
);

const OrganizationHeader = () => {

    // React hooks
    const classes = useStyles();

    return (
        <div id='organization-header' className={classes.header}>
            <div className={classes.headerBackground} />
            <Container>
                <OrganizationTabBar />
            </Container>
        </div>
    );
};

export default OrganizationHeader;