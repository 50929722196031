import { Fragment } from "react";
import { makeStyles, Theme, createStyles, CircularProgress, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center',
            flexGrow: 1,
            '& :first-child': {
                marginBottom: theme.spacing(2)
            },
        },
        loadingIndicator: {
            width: '30px !important',
            height: '30px !important'
        },
        leftAligned: {
            display: 'flex',
            alignItems: 'center',
            '& :not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        }
    }));

interface LoadingIndicatorProps {
    text?: string;
    leftAligned?: boolean;
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {

    // React hooks
    const classes = useStyles();

    const visualElements = (
        <Fragment>
            <CircularProgress className={props.leftAligned ? classes.loadingIndicator : undefined} />
            <Typography>{props.text ? props.text : 'Loading...'}</Typography>
        </Fragment>
    )

    return (
        props.leftAligned
            ? <div className={classes.leftAligned}>{visualElements}</div>
            : <div className={classes.loadingWrapper}>{visualElements}</div>
    );
};

export default LoadingIndicator;