import { observable } from "mobx";

export type SortDirection = 'asc' | 'desc';

export interface IOrderByPair<ColumnName> {
    column: ColumnName;
    direction: SortDirection;
}

export class OrderByPair<ColumnName> implements IOrderByPair<ColumnName> {
    @observable column: ColumnName;
    @observable direction: SortDirection;
    
    constructor(orderByPair: IOrderByPair<ColumnName>) {
        this.column = orderByPair.column;
        this.direction = orderByPair.direction;
    }

    serialize() {
        return `${this.column}:${this.direction}`;
    }
}