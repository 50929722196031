import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Tooltip, Typography } from "@material-ui/core";
import { createElement } from 'react';
import { ServiceEntry } from '../data-access/entities/ServiceEntry';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.action.active
        },
    })
);

interface VolunteerHoursSourceIconProps {
    serviceEntry: ServiceEntry;
}

const VolunteerHoursSourceIcon = observer((props: VolunteerHoursSourceIconProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <Tooltip title={<Typography>{props.serviceEntry.sourceDescriptor}</Typography>}>
            {createElement(props.serviceEntry.sourceIcon, { className: classes.icon })}
        </Tooltip>
    );
});

export default VolunteerHoursSourceIcon;