import { useContext, useEffect, useState } from "react";
import { RootContext } from "../../../../stores";
import { EmailPreference, OrganizationStubVolunteerLinking, VolunteerRelation } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { Step, StepProps } from "../../../../stores/models/Step";
import AuthenticateToLinkAccountStep from "./AuthenticateToLinkAccountStep";
import CreateVolunteerAccountStep from "./CreateVolunteerAccountStep";
import EmailPreferenceStep from "./EmailPreferenceStep";
import EmailsAcceptedStep from "./EmailsAcceptedStep";
import InvalidEmailApologyStep from "./InvalidEmailApologyStep";
import InvalidUserTypeStep from "./InvalidUserTypeStep";
import PreferenceSavedStep from "./PreferenceSavedStep";
import TermsOfServiceStep from "./TermsOfServiceStep";
import VerifyCorrectAccountStep from "./VerifyCorrectAccountStep";
import VolunteerRelationStep from "./VolunteerRelationStep";

export enum VolunteerLinkingSteps {
    RelationSelection = 'relationSelection',
    EmailPreferenceSelection = 'emailPreferenceSelection',
    TermsOfService = 'termsOfService',
    LoginOrSignUp = 'loginOrSignUp',
    InvalidEmail = 'invalidEmail',
    Registration = 'registration',
    EmailPreferenceSaved = 'emailPreferenceSaved',
    EmailsAccepted = 'emailsAccepted',
    InvalidUserType = 'invalidUserType',
    VerifyCorrectAccount = 'verifyCorrectAccount'
}

type VolunteerLinkingStepComponent = (props: StepProps<OrganizationStubVolunteerLinking>) => JSX.Element;

const stepComponentDictionary: { [key in VolunteerLinkingSteps]: VolunteerLinkingStepComponent } = {
    [VolunteerLinkingSteps.RelationSelection]: VolunteerRelationStep,
    [VolunteerLinkingSteps.EmailPreferenceSelection]: EmailPreferenceStep,
    [VolunteerLinkingSteps.TermsOfService]: TermsOfServiceStep,
    [VolunteerLinkingSteps.LoginOrSignUp]: AuthenticateToLinkAccountStep,
    [VolunteerLinkingSteps.InvalidEmail]: InvalidEmailApologyStep,
    [VolunteerLinkingSteps.Registration]: CreateVolunteerAccountStep,
    [VolunteerLinkingSteps.EmailPreferenceSaved]: PreferenceSavedStep,
    [VolunteerLinkingSteps.EmailsAccepted]: EmailsAcceptedStep,
    [VolunteerLinkingSteps.InvalidUserType]: InvalidUserTypeStep,
    [VolunteerLinkingSteps.VerifyCorrectAccount]: VerifyCorrectAccountStep
}

const getStep = (
    name: VolunteerLinkingSteps,
    content: (props: StepProps<OrganizationStubVolunteerLinking>) => JSX.Element,
    link?: OrganizationStubVolunteerLinking,
    options?: {
        submitButtonEmbedded?: boolean,
        disableSubmitUntilValid?: boolean,
        hideBackButton?: boolean
    }
) => {
    if (link) {
        return new Step(name, content, link, options?.submitButtonEmbedded, options?.disableSubmitUntilValid, options?.hideBackButton);
    }
}

const getEndMessageStep = (
    name: VolunteerLinkingSteps,
    content: (props: StepProps<OrganizationStubVolunteerLinking>) => JSX.Element,
    link?: OrganizationStubVolunteerLinking
) => {
    if (link) {
        return getStep(name, content, link, { submitButtonEmbedded: true, disableSubmitUntilValid: false, hideBackButton: true });
    }
}

const getStepFromType = (stepType: VolunteerLinkingSteps, link?: OrganizationStubVolunteerLinking) => {
    const stepComponent = stepComponentDictionary[stepType];
    switch (stepType) {
        // Steps that disable the submit button until valid:
        case VolunteerLinkingSteps.RelationSelection:
        case VolunteerLinkingSteps.EmailPreferenceSelection:
        case VolunteerLinkingSteps.TermsOfService:
            return getStep(stepType, stepComponent, link, { disableSubmitUntilValid: true });
        // Steps that display an end message:
        case VolunteerLinkingSteps.InvalidEmail:
        case VolunteerLinkingSteps.EmailPreferenceSaved:
        case VolunteerLinkingSteps.EmailsAccepted:
            return getEndMessageStep(stepType, stepComponent, link);
        // Steps that have an embedded submit button:
        case VolunteerLinkingSteps.LoginOrSignUp:
        case VolunteerLinkingSteps.InvalidUserType:
        case VolunteerLinkingSteps.VerifyCorrectAccount:
            return getStep(stepType, stepComponent, link, { submitButtonEmbedded: true });
        // Steps that don't have an embedded submit button and 
        // that don't disable the submit button until valid:
        default:
            return getStep(stepType, stepComponent, link);
    }
}

const useLinkVolToOrgSteps = (link?: OrganizationStubVolunteerLinking) => {

    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;

    const getStepOptions = () => {
        let allSteps = {} as { [key in VolunteerLinkingSteps]: Step<OrganizationStubVolunteerLinking> | undefined };
        Object.values(VolunteerLinkingSteps).forEach(stepType => { allSteps[stepType as VolunteerLinkingSteps] = getStepFromType(stepType, link); });
        return allSteps;
    }

    const [stepOptions, setStepOptions] = useState(getStepOptions());

    useEffect(() => {
        if (link) {
            setStepOptions(getStepOptions());
        }
    }, [link]);

    const getSteps = () => {

        if (link) {

            let steps: Step<any>[] = [stepOptions.relationSelection!];

            switch (link.relation) {
                case VolunteerRelation.Volunteer:
                    if (userStore.isAuthenticated) {
                        if (!userStore.user.isVolunteer) {
                            steps.push(stepOptions.invalidUserType!);
                        } else {
                            steps.push(stepOptions.verifyCorrectAccount!);
                        }
                    } else {
                        steps.push(stepOptions.loginOrSignUp!);
                        if (link.creatingNewUser) {
                            steps.push(stepOptions.registration!);
                        }
                    }
                    break;
                case VolunteerRelation.Contact:
                    steps.push(stepOptions.emailPreferenceSelection!);
                    if (link.emailPreference === EmailPreference.EmailMe) {
                        steps.push(stepOptions.termsOfService!, stepOptions.emailsAccepted!);
                    } else if (link.emailPreference === EmailPreference.RemoveMe) {
                        steps.push(stepOptions.emailPreferenceSaved!);
                    }
                    break;
                case VolunteerRelation.Neither:
                    steps.push(stepOptions.invalidEmail!);
                    break;
                default:
                    break;
            }
            return steps;
        } else {
            return [];
        }

    }

    const [steps, setSteps] = useState(getSteps());

    useEffect(() => {
        setSteps(getSteps());
    }, [
        link,
        link?.relation,
        link?.emailPreference,
        link?.creatingNewUser,
        stepOptions,
        userStore.user
    ]);

    return steps;
}

export default useLinkVolToOrgSteps;