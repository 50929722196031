import React from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography, Avatar, Divider, Button } from "@material-ui/core";
import { Send } from "mdi-material-ui";
import { getSearchLink } from "../../Navigation/Links/UrlConstructors";
import { useNavigateInternally } from "../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginBottom: theme.spacing(1),
            maxWidth: 800,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
        divider: {
            width: '90%',
            margin: '30px auto',
        },
        title: {
            maxWidth: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: theme.spacing(2),
        },
    })
);

const NewLinkRequested = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const navigate = useNavigateInternally();

    /********* Event handler *********/

    const onButtonClicked = () => {
        navigate(getSearchLink());
    }

    /********* Render *********/

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <Send fontSize='large' />
            </Avatar>
            <Typography variant="h4" className={classes.prompt}>
                A new link is on its way!
            </Typography>
            <Typography>
                Please allow the email a few minutes to arrive. Try checking your spam folder if you don't see it in your inbox.
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="h5" className={classes.title}>
                Want to browse volunteer opportunities in the meantime?
            </Typography>
            <Button
                variant="contained"
                color='primary'
                onClick={onButtonClicked}
            >
                Browse Opportunities
            </Button>
        </React.Fragment>
    );
});

export default NewLinkRequested;