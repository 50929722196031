import { observable, makeObservable } from 'mobx';
import { IVolunteer, Volunteer } from '../Volunteer';
import { DateFormatter } from '../../../logic/DateFormatter';

export const isServerVolunteer = (x: any): x is IServerVolunteer => {
    if (typeof x === 'object' && 'birthdate' in x) {
        return (typeof x.birthdate === 'object');
    } else {
        return false;
    }
}

export interface IServerVolunteer {
    id: number;
    firstName: string;
    lastName: string;
    birthdate?: Date;
}

export class ServerVolunteer implements IServerVolunteer {
    id: number;
    firstName: string;
    lastName: string;
    birthdate?: Date;

    constructor(clientOrServerObject: IVolunteer | IServerVolunteer) {
        makeObservable(this, {
            id: observable,
            firstName: observable,
            lastName: observable,
            birthdate: observable
        });

        this.id = clientOrServerObject.id;
        this.firstName = clientOrServerObject.firstName;
        this.lastName = clientOrServerObject.lastName;
        if (isServerVolunteer(clientOrServerObject)) {
            this.birthdate = clientOrServerObject.birthdate;
        } else {
            this.birthdate = clientOrServerObject.birthdate ? new Date(clientOrServerObject.birthdate) : undefined;
        }
    }

    deserialize(): Volunteer {
        const volunteerData: IVolunteer = {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            birthdate: this.birthdate ? DateFormatter.getDateInUTC(this.birthdate, true) : undefined
        };
        return new Volunteer(volunteerData);
    }
}