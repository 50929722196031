

import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { observer } from "mobx-react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { Form } from "../../../../stores/models/Form";
import FormError from "../../../Organization/VolunteerOpportunities/FormError";
import DialogFormSection from "./DialogFormSection";
import { APIError, isAPIError } from "../../../../stores/models/APIError";
import FormSubmittedWarning from "../FormSubmittedWarning";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            textAlign: 'left',
        },
        formError: {
            marginBottom: theme.spacing(2)
        },
    }),
);

interface DialogFormProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    formStructure: Form;
    submitted?: boolean;
    hideSubmittedWarning?: boolean;
    displayError?: boolean;
    error?: string | APIError;
}

const DialogForm = observer((props: DialogFormProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <div className={classes.form} {...props}>
            {props.error !== undefined
                ? isAPIError(props.error)
                    ? <Alert severity={props.error.severity} className={classes.formError}>
                        {props.error.errorTitle && <AlertTitle>{props.error.errorTitle}</AlertTitle>}
                        {props.error.message}
                    </Alert>
                    : <Alert severity="error" className={classes.formError}>
                        {props.error}
                    </Alert>
                : props.displayError
                    ? <FormError className={classes.formError} />
                    : null
            }
            {props.submitted && props.hideSubmittedWarning !== true
                ? <FormSubmittedWarning className={classes.formError} />
                : null
            }
            {props.formStructure.description !== undefined
                ? <Typography>{props.formStructure.description}</Typography>
                : null
            }
            {props.formStructure.sections.map((section, index) => {
                return (
                    <DialogFormSection section={section} />
                )
            })}
        </div>
    );
});

export default DialogForm;