import { ICause, IOrganization, Organization } from "..";
import { Address } from "../Address";
import { observable, action, makeAutoObservable } from "mobx";
import { ServerOpportunity } from "./ServerOpportunity";
import { Opportunity } from "../Opportunity";
import { AddressCollection } from "../Addressable";
import { Deserializer } from "../../../logic/Deserialization";
import { Cause } from "../Cause";
import { ServerAddress } from "./ServerAddress";
import { UniqueIdentification } from "../../../logic/UniqueIdentification";
import { PortalMode } from "../../../data/CategoryOptions";

export const isClientOrganization = (x: any): x is IOrganization => {
    if (typeof x === 'object') {
        return 'addressCollection' in x;
    } else {
        return false;
    }
}

export interface IServerOrganization {
    id?: number;
    name?: string;
    description?: string;
    mission?: string;
    website?: string;
    causes?: ICause[];
    opportunities?: ServerOpportunity[];
    totalOpportunities?: number;
    addresses?: ServerAddress[];
    interestSparked?: boolean;
    isForProfit?: boolean;
    portalSlug?: string;
    portalMode?: PortalMode;
}

export class ServerOrganization implements IServerOrganization {
    @observable id?: number;
    @observable name?: string;
    @observable description?: string;
    @observable mission?: string;
    @observable website?: string;
    @observable causes?: ICause[];
    @observable opportunities?: ServerOpportunity[];
    @observable totalOpportunities?: number;
    @observable addresses?: ServerAddress[];
    @observable interestSparked?: boolean;
    @observable isForProfit?: boolean;
    @observable portalSlug?: string;
    @observable portalMode?: PortalMode;

    constructor(clientOrServerObject: IOrganization | Partial<IServerOrganization>) {
        makeAutoObservable(this);

        this.id = clientOrServerObject.id;
        this.name = clientOrServerObject.name;
        this.description = clientOrServerObject.description;
        this.mission = clientOrServerObject.mission;
        this.website = clientOrServerObject.website;
        this.causes = clientOrServerObject.causes;
        this.interestSparked = clientOrServerObject.interestSparked;
        this.totalOpportunities = clientOrServerObject.totalOpportunities;
        this.isForProfit = clientOrServerObject.isForProfit;
        this.portalSlug = clientOrServerObject.portalSlug;
        this.portalMode = clientOrServerObject.portalMode;
        if (clientOrServerObject.opportunities) {
            this.opportunities = (clientOrServerObject.opportunities as Array<Opportunity | ServerOpportunity>)
                .map((opportunity: Opportunity | ServerOpportunity) => {
                    return new ServerOpportunity(opportunity);
                });
        }
        if (isClientOrganization(clientOrServerObject)) {
            this.addresses = clientOrServerObject.addressCollection.addresses.map(address => {
                return new ServerAddress(address);
            });
            this.mapClientAddressIdsToServerIds();
        } else {
            this.addresses = clientOrServerObject.addresses
                ? clientOrServerObject.addresses.map(address => {
                    return new ServerAddress(address);
                })
                : undefined;
        }
    }

    @action private mapClientAddressIdsToServerIds() {
        let serverId = -1;
        if (this.addresses) {
            this.addresses.forEach((address, index) => {
                if (UniqueIdentification.isClientId(address.id)) {
                    this.addresses![index].id = serverId;
                    serverId--;
                }
            });
        }
    }

    deserialize(organizationToUpdate?: Organization): Organization {
        let deserializedData: Partial<IOrganization> = {
            id: this.id,
            name: this.name,
            description: this.description,
            mission: this.mission,
            website: this.website,
            causes: this.causes ? this.causes.map(cause => { return new Cause(cause); }) : undefined,
            opportunities: this.opportunities?.map(opportunity => { return opportunity.deserialize(); }),
            totalOpportunities: this.totalOpportunities,
            addressCollection: this.addresses ? new AddressCollection(this.addresses.map(address => { return new Address(address); })) : undefined,
            interestSparked: this.interestSparked,
            isForProfit: this.isForProfit,
            portalSlug: this.portalSlug,
            portalMode: this.portalMode
        }

        // Delete keys with undefined values
        Object.keys(deserializedData).forEach(key => {
            if (deserializedData[key as keyof IOrganization] === undefined) {
                delete deserializedData[key as keyof IOrganization];
            }
        });

        // Apply the deserialized data to an Organization object.
        if (organizationToUpdate === undefined) {
            organizationToUpdate = new Organization(-1);
        }
        Deserializer.applyDeserializedData(organizationToUpdate, deserializedData);

        return organizationToUpdate;
    }

}