import { mdiCardMultipleOutline, mdiTable } from "@mdi/js";
import MdiIcon from "../../../../components/Shared/MdiIcon";
import { IToggleButtonProps } from "../../../../shared/modules/buttons/entities/ToggleButtonState";

export enum VolunteerHoursViewModeOptions {
    CardViewMode = 'card',
    TableViewMode = 'table'
}

export const VolunteerHoursViewModes: IToggleButtonProps<VolunteerHoursViewModeOptions>[] = [{
    value: VolunteerHoursViewModeOptions.CardViewMode,
    icon: <MdiIcon iconPath={mdiCardMultipleOutline} />
}, {
    value: VolunteerHoursViewModeOptions.TableViewMode,
    icon: <MdiIcon iconPath={mdiTable} />
}]