import { action, makeObservable, observable } from "mobx";
import { IAction } from "../../../actions/interfaces/Action";
import { RecordListStore } from "../stores/RecordListStore";
import { ObjectWithIdentifier } from "../../../../../stores/models/NewOptionCollection";

export class SelectAllRecordsAction<RecordType extends ObjectWithIdentifier<RecordType>> implements IAction {

    @observable protected store: RecordListStore<RecordType>;

    constructor(store: RecordListStore<RecordType>) {
        makeObservable(this);
        this.store = store;
    }

    @action async execute() {
        this.store.setUnloadedSelected(true);
        this.store.collection.options.forEach(option => {
            if (!option.selected) {
                option.toggleSelection();
            }
        });
    }

}
