import { Fragment, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { getInitialDialogStates } from "../../../logic/DialogStateDictionary";
import { Volunteer } from "../../../stores";
import ServiceEntryCards from "./ServiceEntryCards";
import useVolunteerHoursActions from "../hooks/useVolunteerHoursActions";
import useVolunteerHoursViewModel from "../hooks/useVolunteerHoursViewModel";
import { ServiceEntry } from "../data-access/entities/ServiceEntry";
import VolunteerHoursTable from "./VolunteerHoursTable";
import { Option } from "../../../stores/models/Option";
import { IServiceEntryBulkEdit, isServiceEntryBulkDeletion, isServiceEntryBulkStatusUpdate } from "../data-access/interfaces";
import { useRootStore } from "../../../shared/general/hooks";
import useReactiveLoad from "../../../shared/modules/actions/hooks/useReactiveLoad";
import { VolunteerServiceDataViewModel } from "../data-access/view-models/VolunteerServiceDataViewModel";
import NewConfirmationDialog from "../../../shared/modules/dialogs/components/NewConfirmationDialog";
import { Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ConfirmationDialogState } from "../../../shared/modules/dialogs/entities/ConfirmationDialogState";
import UnreviewedEditsDialog from "./UnreviewedEditsDialog";
import { isErrorDisplay } from "../../../data/ErrorCodes";
import { DialogState } from "../../../stores/models/DialogState";
import useQueryParamDialogTrigger from "../../../shared/modules/dialogs/hooks/useQueryParamDialogTrigger";
import ServiceEntryDialog from "./ServiceEntryDialog";
import FormLoadingSpinner from "../../../components/Organization/VolunteerOpportunities/FormLoadingSpinner";
import TablePaginationWrapper from "../../../components/AccountSettings/Pages/Organization/TablePaginationWrapper";
import { ToggleButtonState } from "../../../shared/modules/buttons/entities/ToggleButtonState";
import { VolunteerHoursViewModeOptions, VolunteerHoursViewModes } from "../data-access/entities/VolunteerHoursViewModes";
import VolunteerHoursToolbar, { PopperTypes } from "./VolunteerHoursToolbar";
import { HOURS_LOGGING_PARAMETER } from "../../../components/Navigation/Links";

enum DialogTypes {
    RecordEditor = 'recordEditor',
    VolunteerEditsWarning = 'volunteerEditsWarning',
}

interface ServiceDataPresenterProps {
    volunteer?: Volunteer;
}

const ServiceDataPresenter = observer((props: ServiceDataPresenterProps) => {

    /********* Hooks *********/

    const { userStore } = useRootStore();
    const isCardViewByDefault = useMediaQuery((theme: Theme) => theme.breakpoints.down(1000));

    /********* Constants *********/

    const isVolunteer = userStore.user.isVolunteer;

    /********* View Model and Actions *********/

    const viewModel = useVolunteerHoursViewModel({ volunteer: props.volunteer });
    const { load: loadHours, upsert: upsertHours, bulkEdit } = useVolunteerHoursActions();

    /********* Local State *********/

    const [dialogStates] = useState(getInitialDialogStates(Object.values(DialogTypes)));
    const [popperStates] = useState(getInitialDialogStates(Object.values(PopperTypes)));
    const [deletionConfirmationState] = useState(new ConfirmationDialogState());
    const [volunteerEditsWarningCallback, setVolunteerEditsWarningCallback] = useState<(overrideWarning: boolean) => Promise<void>>();
    const [viewModeState] = useState(new ToggleButtonState<VolunteerHoursViewModeOptions>({
        toggleButtons: VolunteerHoursViewModes,
        selectedButton: isCardViewByDefault ? VolunteerHoursViewModeOptions.CardViewMode : VolunteerHoursViewModeOptions.TableViewMode
    }));

    const isCardView = viewModeState.selectedButton === VolunteerHoursViewModeOptions.CardViewMode;

    /********* Data Loading *********/

    const loadParam = useMemo(() => { return { volunteerId: props.volunteer?.id } }, [props.volunteer?.id]);

    const isLoading = useReactiveLoad({
        action: loadHours,
        param: loadParam,
        reloadNeeded: viewModel.reloadNeeded,
        setReloadNeeded: viewModel.setReloadNeeded
    });

    /********* Handle "logging" Query Parameter *********/

    useQueryParamDialogTrigger({
        param: HOURS_LOGGING_PARAMETER,
        triggerValue: "true",
        dialog: dialogStates.recordEditor,
        delayInitialOpening: true
    });

    /********* Effects *********/

    useEffect(() => {
        viewModeState.setSelectedButton(
            isCardViewByDefault
                ? VolunteerHoursViewModeOptions.CardViewMode
                : VolunteerHoursViewModeOptions.TableViewMode
        );
    }, [isCardViewByDefault]);

    useEffect(() => {
        if (isCardView) {
            // Unselect all records after switching to card view
            viewModel.collection.setSelections([]);
        }
    }, [viewModeState.selectedButton])

    /********* Event handlers *********/

    // ----- Create -----

    const onNewServiceEntryClicked = () => {
        viewModel.setEditableRecord(viewModel.draft);
        dialogStates.recordEditor.setOpen(true);
    }

    const onServiceEntryAddedOrEdited = async (record: ServiceEntry) => {
        if (viewModel.editableRecord?.lastOfficialEntry) {
            const updatedEntry = new ServiceEntry({...record, id: viewModel.editableRecord.id });
            await attemptRequest(() => upsertHours.execute(updatedEntry), dialogStates.recordEditor);
        } else {
            await attemptRequest(() => upsertHours.execute(record), dialogStates.recordEditor);
        }
    }

    // ----- Update -----

    const onServiceEntryClicked = (record: Option<ServiceEntry>, rowIndex?: number) => {
        viewModel.setEditableRecord(record.object);
        dialogStates.recordEditor.setOpen(true);
    }

    // ----- Delete -----

    const onServiceDeleteClicked = (record: Option<ServiceEntry>, rowIndex?: number) => {
        confirmDeletionOfSingleRecord(record);
    }

    const confirmDeletionOfSingleRecord = (record: Option<ServiceEntry>) => {
        viewModel.collection.setSelections([]);
        record.toggleSelection();
        confirmDeletion();
    }

    const confirmDeletion = () => {
        deletionConfirmationState.setOpen(true);
    }

    const onDeletionConfirmed = async () => {
        if (dialogStates.recordEditor.open) {
            if (viewModel.editableRecord) {
                const archivedServiceEntry = new ServiceEntry({ ...viewModel.editableRecord, archived: true });
                await onServiceEntryAddedOrEdited(archivedServiceEntry);
                deletionConfirmationState.setOpen(false);
            }
        } else {
            await onBulkEdit({ archived: true });
        }
    }

    const onDeletionCancelled = () => {
        if (isCardView) {
            viewModel.collection.setSelections([]);
        }
    }

    // ----- Bulk Update/Deletion -----

    const onBulkEdit = async (edits: IServiceEntryBulkEdit) => {
        const dialog = getDialogMatchingEditType(edits)
        await makeBulkEdit(() => bulkEdit.execute(edits), dialog);
    }

    const getDialogMatchingEditType = (edits: IServiceEntryBulkEdit) => {
        if (isServiceEntryBulkDeletion(edits)) {
            return deletionConfirmationState;
        } else if (isServiceEntryBulkStatusUpdate(edits)) {
            return popperStates.statusPopper;
        } else {
            return popperStates.tagPopper;
        }
    }

    const makeBulkEdit = async (actionCallback: () => Promise<void>, dialog: DialogState, overrideWarning?: boolean) => {
        warnAboutVolunteerEdits(actionCallback, dialog, overrideWarning);
        if (dialogStates.volunteerEditsWarning.open) return;

        // Make edits
        await attemptRequest(() => actionCallback(), dialog);
    }

    const warnAboutVolunteerEdits = (actionCallback: () => Promise<void>, dialog: DialogState, overrideWarning?: boolean) => {
        if (viewModel.selectionsIncludeVolunteerEdits && !overrideWarning) {
            const callback = () => async (override: boolean) => await makeBulkEdit(actionCallback, dialog, override);
            setVolunteerEditsWarningCallback(callback);
            dialogStates.volunteerEditsWarning.setOpen(true);
            return;
        } else {
            dialogStates.volunteerEditsWarning.setOpen(false);
        }
    }

    /********* Helper Methods *********/

    const attemptRequest = async (request: () => Promise<void>, dialog?: DialogState): Promise<void> => {
        try {
            dialog?.setLoading(true);
            await request();
            dialog?.setOpen(false);
        } catch (error) {
            if (isErrorDisplay(error)) {
                dialog?.setError(error);
            }
        } finally {
            dialog?.setLoading(false);
        }
    }

    /********* Render *********/

    return (
        <Fragment>
            {/* Toolbar */}
            <VolunteerHoursToolbar
                viewModel={viewModel}
                viewModeState={viewModeState}
                onNewServiceEntryClicked={onNewServiceEntryClicked}
                onServiceEntryClicked={onServiceEntryClicked}
                onBulkEdit={onBulkEdit}
                onBulkDeleteClicked={confirmDeletion}
                popperStates={popperStates}
            />
            {isCardView
                ? <Fragment>
                    <ServiceEntryCards
                        model={viewModel}
                        onEntryEdit={onServiceEntryClicked}
                        onEntryDelete={onServiceDeleteClicked}
                    />
                    <TablePaginationWrapper
                        state={viewModel.paginationState}
                        total={viewModel.total}
                        loadResults={() => { }}
                        skipInitialLoad
                    />
                </Fragment>
                : <VolunteerHoursTable
                    viewModel={viewModel}
                    onServiceEntryClicked={onServiceEntryClicked}
                />
            }
            {isLoading ? <FormLoadingSpinner /> : null}
            {/* Dialogs */}
            <NewConfirmationDialog
                state={deletionConfirmationState}
                onConfirmed={onDeletionConfirmed}
                onCancelled={onDeletionCancelled}
                ConfirmationDialogProps={{
                    title: "Delete Volunteer Hours",
                    content: <Typography>Are you sure you want to delete these volunteer hours?</Typography>,
                    confirmText: 'Delete',
                    fullScreen: false
                }}
            />
            <UnreviewedEditsDialog
                state={dialogStates.volunteerEditsWarning}
                onOverride={volunteerEditsWarningCallback}
            />
            <ServiceEntryDialog
                record={viewModel.recordToEdit}
                serviceEntry={viewModel.editableRecord ? viewModel.editableRecord : viewModel.draft}
                volunteer={props.volunteer}
                copyRecord={record => new ServiceEntry(record)}
                state={dialogStates.recordEditor}
                title={viewModel.editableRecord?.volunteerId === undefined ? 'Log Volunteer Hours' : 'Edit Volunteer Hours'}
                onConfirm={onServiceEntryAddedOrEdited}
                confirmText={viewModel.getEditConfirmationText}
                confirmDisabled={(serviceEntry) => (isVolunteer === true && viewModel.editableRecord !== null && ServiceEntry.volunteerFieldsMatch(viewModel.editableRecord, serviceEntry))}
                volunteerOrganizations={viewModel instanceof VolunteerServiceDataViewModel ? viewModel.organizationRoles : undefined}
                volunteerEntry={isVolunteer}
                removeText={viewModel.editableRecord?.duration === 0 ? 'Delete' : undefined}
                onRemove={viewModel.editableRecord?.duration === 0 ? confirmDeletion : undefined}
            />
        </Fragment>
    );
});

export default ServiceDataPresenter;