import { List, ListItem, ListItemText, makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { useContext, useEffect } from "react";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import BusinessIcon from '@material-ui/icons/Business';
import { RootContext, UserType } from "../../../../stores";
import { StepProps } from "../../../../stores/models/Step";
import clsx from 'clsx';
import { observer } from "mobx-react";
import { StubUser } from "../../../../stores/models/StubUser";
import REMAvatar from "../../../../shared/modules/rem-conversion/components/Avatar";
import REMListItemAvatar from "../../../../shared/modules/rem-conversion/components/ListItemAvatar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
        },
        title: {
            color: theme.palette.action.active
        },
        listItem: {
            '&:hover $avatar': {
                backgroundColor: theme.palette.action.disabled
            },
            '&:hover $selected, $selected': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            }
        },
        avatar: {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
        selected: {},
        footer: {
            marginBottom: theme.spacing(1),
            color: theme.palette.text.secondary
        },
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
    }),
);

enum ListItemOption {
    Unselected,
    Volunteer,
    Organization,
}

const AccountTypeSelection = observer((props: StepProps<StubUser>) => {

    const stubUser = props.stepState.stepObject;

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const navigationStore = rootStore.navigationStore;

    /***** Effect *****/

    useEffect(() => {
        props.stepState.setValidationMethod(validateUserTypeSelected);
    }, []);

    /***** Helper methods *****/

    const validateUserTypeSelected = () => {
        return stubUser.type !== UserType.None;
    }

    /***** Event handlers *****/

    const handleListItemClick = (option: ListItemOption) => {
        if (option === ListItemOption.Volunteer) {
            stubUser.setType(UserType.Volunteer);
        } else {
            stubUser.setType(UserType.Organization);
        }
    }

    const handleSwitchToLogin = () => {
        navigationStore.toggleAuthenticationDialogs();
    }

    /***** Render *****/

    return (
        <div className={classes.root}>
            <Typography variant="h5" className={classes.title}>Select Your Account Type</Typography>
            <List>
                <ListItem button onClick={() => handleListItemClick(ListItemOption.Volunteer)} className={classes.listItem}>
                    <REMListItemAvatar>
                        <REMAvatar className={clsx(classes.avatar, stubUser.type === UserType.Volunteer ? classes.selected : undefined)}>
                            <EmojiPeopleIcon />
                        </REMAvatar>
                    </REMListItemAvatar>
                    <ListItemText primary="Volunteer" secondary="Find ways to get involved." />
                </ListItem>
                <ListItem button onClick={() => handleListItemClick(ListItemOption.Organization)} className={classes.listItem}>
                    <REMListItemAvatar>
                        <REMAvatar className={clsx(classes.avatar, stubUser.type === UserType.Organization ? classes.selected : undefined)}>
                            <BusinessIcon />
                        </REMAvatar>
                    </REMListItemAvatar>
                    <ListItemText primary="Organization" secondary="Post opportunities and recruit volunteers." />
                </ListItem>
            </List>
            <div className={classes.footer}>
                <Typography variant="body2">Already have an account? Welcome back!</Typography>
                <Typography variant="body2"><span className={classes.link} onClick={handleSwitchToLogin}>Let's get you logged in.</span></Typography>
            </div>
        </div>
    );
});

export default AccountTypeSelection;