import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Button, Divider, Typography } from "@material-ui/core";
import { Fragment, useContext, useState } from 'react';
import FullVolunteerSignUp from './RegistrationSteps/Volunteer/FullVolunteerSignUp';
import { StubUser } from '../../stores/models/StubUser';
import { RootContext, UserType } from '../../stores';
import { Alert } from '@material-ui/lab';
import FormLoadingSpinner from '../Organization/VolunteerOpportunities/FormLoadingSpinner';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            marginBottom: theme.spacing(2)
        },
        linkWrapper: {
            marginTop: theme.spacing(2),
            color: theme.palette.text.secondary,
            textAlign: 'center'
        },
        signUpButton: {
            marginTop: theme.spacing(1),
            color: theme.palette.common.white,
            background: theme.palette.success.main,
            '&:hover': {
                background: theme.palette.success.main,
            }
        },
        loginButton: {
            marginTop: theme.spacing(1),
        },
        divider: {
            marginTop: theme.spacing(3)
        }
    })
);

interface VolunteerSignUpFormProps {
    onSwitchToLogin: () => void;
    stubUser?: StubUser;
}

const VolunteerSignUpForm = observer((props: VolunteerSignUpFormProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const registrationStore = rootStore.registrationStore;

    /********* State *********/

    const [stubUser] = useState(props.stubUser ? props.stubUser : new StubUser(UserType.Volunteer));
    const [validationRun, setValidationRun] = useState(false);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);

    /********* Helper methods *********/

    const onLoginClicked = () => {
        props.onSwitchToLogin();
    }

    const onSignUpClicked = async () => {
        stubUser.setAllFieldsDirty();
        stubUser.stubVolunteer?.setAllFieldsDirty();
        stubUser.credentials.setAllFieldsDirty();
        setValidationRun(true);
        if (stubUser.validated) {
            setLoading(true);
            const error = await registrationStore.register(stubUser);
            setLoading(false);
            setError(error);
        } else {
            setError('Please resolve the errors below to finish registering.')
        }
    }

    /********* Render *********/

    return (
        <Fragment>
            {error !== undefined
                ? <Alert severity="error" className={classes.alert}>{error}</Alert>
                : null
            }
            <FullVolunteerSignUp
                stubUser={stubUser}
                validationRun={validationRun}
            />
            <Button
                color="primary"
                variant="contained"
                fullWidth
                className={classes.signUpButton}
                size="large"
                onClick={onSignUpClicked}
                disabled={loading}
            >
                Sign Up
            </Button>
            <Divider className={classes.divider} />
            <div className={classes.linkWrapper}>
                <Typography variant="body2">
                    {`Already have an account? Welcome back!`}
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    className={classes.loginButton}
                    size="large"
                    onClick={onLoginClicked}
                    disabled={loading}
                >
                    Login
                </Button>
            </div>
            {loading ? <FormLoadingSpinner /> : null}
        </Fragment>
    );
});

export default VolunteerSignUpForm;