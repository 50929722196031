import { IOpportunityFields, Opportunity } from "../../../../../stores/models/Opportunity";
import { OPPORTUNITY_POSITION_MAX_LENGTH, OPPORTUNITY_TITLE_MAX_LENGTH, OPPORTUNITY_DESCRIPTION_MAX_LENGTH, OPPORTUNITY_TRAINING_DESCRIPTION_MAX_LENGTH } from "../../../../../logic/ValidationChecks/FieldLengths";

type Sizing = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface OpportunityEditorField {
    title: string;
    instructions: string;
    secondaryText?: string;
    required?: boolean;
    fieldName: keyof IOpportunityFields;
    sizing?: { xs?: Sizing, sm?: Sizing, md?: Sizing, lg?: Sizing, xl?: Sizing },
    minRows?: number;
    maxRows?: number;
    maxLength: number;
    onFieldEdited: (opportunity: Opportunity, newValue: string) => void;
}

export const getOpportunityEditorFields: () => OpportunityEditorField[] = () => [{
    title: 'Volunteer Role',
    instructions: 'The name of the role the volunteer will fill.',
    secondaryText: 'Example: Event Photographer',
    required: true,
    fieldName: 'position',
    sizing: { sm: 6 },
    onFieldEdited: (opportunity: Opportunity, newValue: string) => opportunity.setPosition(newValue),
    maxLength: OPPORTUNITY_POSITION_MAX_LENGTH
}, {
    title: 'Search Title',
    instructions: 'An attention grabbing title to attract volunteers.',
    secondaryText: 'Example: Share your traditions! Host an exchange student!',
    required: true,
    fieldName: 'title',
    sizing: { sm: 9 },
    onFieldEdited: (opportunity: Opportunity, newValue: string) => opportunity.setTitle(newValue),
    maxLength: OPPORTUNITY_TITLE_MAX_LENGTH
}, {
    title: 'Description',
    instructions: 'Describe the purpose and responsibilities of the role, any benefits the volunteer will receive, any restrictions, etc.',
    required: true,
    fieldName: 'description',
    minRows: 3,
    maxRows: 30,
    onFieldEdited: (opportunity: Opportunity, newValue: string) => opportunity.setDescription(newValue),
    maxLength: OPPORTUNITY_DESCRIPTION_MAX_LENGTH
}, {
    title: 'Onboarding Process',
    instructions: 'What steps need to be completed before an individual is eligible to volunteer? What sort of training will be provided?',
    fieldName: 'trainingDescription',
    minRows: 2,
    maxRows: 30,
    onFieldEdited: (opportunity: Opportunity, newValue: string) => opportunity.setTrainingDescription(newValue),
    maxLength: OPPORTUNITY_TRAINING_DESCRIPTION_MAX_LENGTH
}];