export const CLIENT_VERSION_HEADER_KEY = 'voltage-client-version';
export const VOLTAGE_CLIENT_VERSION = '1.9.0';

export const DOLLAR_VALUE_OF_VOLUNTEER_HOUR: Record<number, number> = {
    2022: 31.80, // Value reported in April 2023 based on 2022 data
    2021: 29.95, // Value reported in April 2022 based on 2021 data
    2020: 28.54, // Value reported in April 2021 based on 2020 data
    2019: 27.20, 
}

export const LATEST_DOLLAR_VALUE_OF_VOLUNTEER_HOUR = DOLLAR_VALUE_OF_VOLUNTEER_HOUR[Math.max(...(Object.keys(DOLLAR_VALUE_OF_VOLUNTEER_HOUR).map(Number)))];