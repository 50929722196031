import { format, startOfMonth, subMonths } from "date-fns";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { Dictionary } from "../../logic/Dictionaries";
import { Shift } from "./Shift";
import { IShiftIdentification, ShiftIdentification } from "./ShiftIdentification";
import { IShiftInstanceDetails, ShiftInstanceDetails } from "./ShiftInstanceDetails";
import { Sorter } from "../../logic/Sorter";
import { IShiftDetails, ShiftDetails } from "./ShiftDetails";
import { DASHBOARD_COLOR_POSITION_SUFFIX, DASHBOARD_OPPORTUNITY_ID_SUFFIX } from "./constants";
import { IServerVolunteerDashboardData } from "./server/ServerVolunteerDashboardData";
import { ICategorizedMonthlyServiceHours } from "./interfaces/ICategorizedMonthlyServiceHours";

export interface TopOrganization {
    id: number;
    organization: string;
    hours: number;
}

interface HoursByOpportunity {
    hours: number;
    position: string;
    opportunityId: number;
    organizationId: number;
}

export interface IVolunteerDashboardData {
    numConfirmedHours: number;
    numUnconfirmedHours: number;
    numShifts: number;
    numSparks: number;
    topOrganizations: TopOrganization[];
    monthlyServiceHours: ICategorizedMonthlyServiceHours[];
    hoursByOpportunity: HoursByOpportunity[];
    shifts: IShiftInstanceDetails[];
}

export class VolunteerDashboardData implements IVolunteerDashboardData {
    @observable numSparks: number;
    @observable monthlyServiceHours: ICategorizedMonthlyServiceHours[];
    @observable shifts: IShiftInstanceDetails[] = [];

    @observable private numMonthsDataToDisplay: number = 6;
    private today = new Date();

    constructor(dashboardData: IServerVolunteerDashboardData) {
        makeAutoObservable(this);

        this.numSparks = dashboardData.numSparks;
        this.monthlyServiceHours = dashboardData.monthlyServiceHours;
        if (dashboardData.shifts.length > 0) {
            this.shifts = dashboardData.shifts.map(shift => new ShiftInstanceDetails(shift));
        }
    }

    @action setNumMonthsToDisplay(numMonths: number) {
        this.numMonthsDataToDisplay = numMonths;
    }

    @computed private get hoursGroupedByStatus() {
        let statusMapping: Record<string, ICategorizedMonthlyServiceHours[]> = {};
        this.monthlyServiceHours.forEach(record => {
            if (record.status in statusMapping) {
                statusMapping[record.status].push({ ...record });
            } else {
                statusMapping[record.status] = [{ ...record }];
            }
        })
        return statusMapping;
    }

    @computed get numConfirmedHours() {
        return this.hoursGroupedByStatus['Confirmed']?.reduce((accumulator, currentValue) => accumulator += currentValue.hours, 0) || 0;
    }

    @computed get numUnconfirmedHours() {
        return this.hoursGroupedByStatus['Unconfirmed']?.reduce((accumulator, currentValue) => accumulator += currentValue.hours, 0) || 0;
    }

    @computed get numShifts() {
        return this.shifts.length;
    }

    @computed get hasServiceHoursData() {
        return this.monthlyServiceHours.length > 0;
    }

    @computed get hoursByOpportunity() {
        let hoursMapping: Record<number, HoursByOpportunity> = {};
        this.monthlyServiceHours.forEach(record => {
            const key = record.opportunityId === null ? -1 : record.opportunityId;
            if (key in hoursMapping) {
                hoursMapping[key].hours += record.hours;
            } else {
                hoursMapping[key] = { ...record };
            }
        })

        return Object.values(hoursMapping);
    }

    @computed get topOrganizations() {
        let hoursMapping: Record<number, TopOrganization> = {};
        this.monthlyServiceHours.forEach(record => {
            if (record.organizationId in hoursMapping) {
                hoursMapping[record.organizationId].hours += record.hours;
            } else {
                hoursMapping[record.organizationId] = { id: record.organizationId, organization: record.organization, hours: record.hours };
            }
        })

        const sortedOrganizations = Object.values(hoursMapping).sort((a, b) => a.hours > b.hours ? -1 : a.hours < b.hours ? 1 : 0);
        return sortedOrganizations.slice(0, 5);
    }

    @computed get totalsByOpportunity() {
        const opportunityTotalsDictionary = {} as Dictionary<number, { id: string | number, position: string, total: number, colorPosition: number }>;
        this.hoursByOpportunity.forEach(record => {
            if (opportunityTotalsDictionary[record.opportunityId] !== undefined) {
                opportunityTotalsDictionary[record.opportunityId].total += record.hours;
            } else {
                opportunityTotalsDictionary[record.opportunityId] = {
                    id: record.opportunityId ? record.opportunityId : 'Other',
                    position: record.position ? record.position : 'Other',
                    total: record.hours,
                    colorPosition: this.opportunityColorNumbering[record.opportunityId]
                }
            }
        });
        return Object.values(opportunityTotalsDictionary);
    }

    @computed private get lastXMonths() {
        const lastXMonths = [] as number[];
        for (let index = 0; index < this.numMonthsDataToDisplay; index++) {
            const numMonthsPrior = this.numMonthsDataToDisplay - index;
            lastXMonths.push(((this.currentMonth + 1) - (numMonthsPrior) + 12) % 12);
        }
        return lastXMonths;
    }

    @computed private get lastXMonthsDictionary() {
        const dictionary = {} as Dictionary<number, { month: string }>;
        this.lastXMonths.forEach((month, index) => {
            const date = new Date().setMonth(month, 1);
            const monthAbbrev = format(date, 'MMM');
            dictionary[index] = {
                month: monthAbbrev
            }
        });
        return dictionary;
    }

    @computed get hasServiceHoursDataInLastXMonths() {
        return this.monthlyServiceHoursBarGraphData.findIndex(monthlyData => Object.keys(monthlyData).length > 1) !== -1;
    }

    @computed private get opportunityColorNumbering() {
        let colorIndex = 0;
        const opportunityColorNumbering = {} as Dictionary<number, number>;
        this.monthlyServiceHours.forEach(monthlyData => {
            if (opportunityColorNumbering[monthlyData.opportunityId] === undefined) {
                opportunityColorNumbering[monthlyData.opportunityId] = colorIndex;
                colorIndex++;
            }
        });
        return opportunityColorNumbering;
    }

    @computed private get currentMonth() {
        return this.today.getMonth();
    }

    @computed private get serviceHoursForRequestedNumMonths() {
        return this.getMonthlyServiceDataForLastXMonths(this.numMonthsDataToDisplay);
    }

    @computed private getMonthlyServiceDataForLastXMonths(numMonths: number) {
        const earliestDate = subMonths(startOfMonth(new Date()), this.numMonthsDataToDisplay - 1);
        return this.monthlyServiceHours.filter(monthlyData => earliestDate <= new Date(monthlyData.year, monthlyData.month - 1, 1));
    }

    @computed get monthlyServiceHoursBarGraphData() {
        const lastXMonths = this.lastXMonths;
        const monthDictionary = this.lastXMonthsDictionary;
        this.serviceHoursForRequestedNumMonths.forEach(monthlyData => {
            const month = monthlyData.month - 1;
            const index = lastXMonths.findIndex(monthNum => monthNum === month);
            if (index !== -1) {
                const key = monthlyData.position ? monthlyData.position : 'Other';
                if (key in monthDictionary[index]) {
                    monthDictionary[index] = {
                        ...monthDictionary[index],
                        [key]: ((monthDictionary[index] as Record<string, string | number>)[key] as number) + monthlyData.hours,
                    }
                } else {
                    monthDictionary[index] = {
                        ...monthDictionary[index],
                        [key]: monthlyData.hours,
                        [key + DASHBOARD_COLOR_POSITION_SUFFIX]: this.opportunityColorNumbering[monthlyData.opportunityId],
                        [key + DASHBOARD_OPPORTUNITY_ID_SUFFIX]: monthlyData.opportunityId
                    };
                }
            }
        });
        return Object.values(monthDictionary);
    }

    @computed private get shiftInstances() {
        const shiftInstances = this.shifts.map(shiftInstanceDetails => {
            let identificationData: IShiftIdentification;
            if (shiftInstanceDetails.offset !== undefined && shiftInstanceDetails.offset !== null) {
                identificationData = {
                    defaultDaysFromStartDate: shiftInstanceDetails.offset,
                    parentId: shiftInstanceDetails.shift.id
                }
            } else {
                identificationData = {
                    shiftId: shiftInstanceDetails.shift.id,
                }
            }
            const shift = new Shift(shiftInstanceDetails.shift).getInstanceByIdentificationData(new ShiftIdentification(identificationData));
            return new ShiftDetails({ ...shiftInstanceDetails, shift: shift } as IShiftDetails)
        }).filter(element => element !== undefined) as ShiftDetails[];
        return shiftInstances;
    }

    @computed get sortedShiftInstances() {
        return Sorter.orderShiftDetailsByStartTimestamp(this.shiftInstances);
    }

}