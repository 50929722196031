import { useContext, useEffect, useState } from 'react';
import { RootContext } from '../../../stores';
import { Form } from '../../../stores/models/Form';

export default function useOrganizationRegistrationForm(organizationId?: number, standalone?: boolean) {

    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const formStore = rootStore.formStore;

    const [registrationForm, setRegistrationForm] = useState<Form>();
    const [registrationFormRequested, setRegistrationFormRequested] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const requestForm = async () => {
        if (organizationId !== undefined && ((userStore.isAuthenticated && userStore.user.isVolunteer) || standalone)) {
            const { form, submitted } = await formStore.getForm(organizationId, standalone);
            setRegistrationForm(form);
            setFormSubmitted(submitted);
            setRegistrationFormRequested(true);
        }
    }

    const resetForm = () => {
        if (!registrationForm) return;
        const newForm = new Form(registrationForm);
        setRegistrationForm(newForm);
    }

    useEffect(() => {
        requestForm();
    }, [organizationId, userStore.isAuthenticated]);

    return [registrationForm, registrationFormRequested, formSubmitted, resetForm] as [Form | undefined, boolean, boolean, () => void];
}