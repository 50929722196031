import React, { useState, useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, useMediaQuery, TextField } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import { observer } from "mobx-react";
import ContactForm from "../../ContactUs/ContactForm";
import { RootContext } from "../../../stores";
import { DialogState } from "../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../Shared/Dialogs/ThemedDialogWithSpinner";
import SuccessMessage from "../../Shared/SuccessMessage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        recipient: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },
        emphasizedText: {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
    }),
);

interface SendMessageDialogProps {
    dialogState: DialogState;
    recipient: string;
    opportunityId?: number;
}

const SendMessageDialog = observer((props: SendMessageDialogProps) => {

    /********* React hooks *********/

    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;
    const helpStore = rootStore.helpStore;
    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* State *********/

    const [messageSubmitted, setMessageSubmitted] = useState(false);
    const [validationRun, setValidationRun] = useState(false);
    const [helpMessage, setHelpMessage] = useState(helpStore.getCopyOfDefaultHelpMessage());

    /********* Effects *********/

    useEffect(() => {
        if (props.dialogState.open) {
            reset();
        } else {
            // Reset the message on close rather than open in order
            // to allow fields of the help message to be set from
            // another component.
            resetHelpMessage();
        }
    }, [props.dialogState.open]);

    useEffect(() => {
        resetHelpMessage();
    }, [helpStore.defaultHelpMessage]);

    /********* Helper methods *********/

    const reset = () => {
        setMessageSubmitted(false);
        setValidationRun(false);
    }

    const resetHelpMessage = () => {
        setHelpMessage(helpStore.getCopyOfDefaultHelpMessage());
    }

    const submitContactMessage = async () => {
        props.dialogState.setLoading(true);
        helpMessage.setAllFieldsDirty();
        setValidationRun(true);
        if (helpMessage.validated) {
            const response = await volunteerStore.contactRecruiter(helpMessage, props.opportunityId);
            if (response && response.succeeded) {
                setMessageSubmitted(true);
            }
        }
        props.dialogState.setLoading(false);
    }

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            title={!messageSubmitted ? 'Contact Recruiter' : undefined}
            state={props.dialogState}
            DialogProps={{ fullScreen: xsDown }}
            primaryButtonProps={!messageSubmitted ? { children: 'Submit', startIcon: <SendIcon /> } : undefined}
            cancelText={!messageSubmitted ? undefined : 'Close'}
            onSubmit={submitContactMessage}
        >
            <div>
                {!messageSubmitted
                    ? <React.Fragment>
                        <TextField
                            label="Recruiter"
                            value={props.recipient}
                            variant="outlined"
                            required
                            className={classes.recipient}
                            disabled={true}
                        />
                        <ContactForm
                            helpMessage={helpMessage}
                            fieldsDisabled={props.dialogState.loading}
                            validationRun={validationRun}
                        />
                    </React.Fragment>
                    : <SuccessMessage
                        details={(
                            <React.Fragment>
                                Your message has been <span className={classes.emphasizedText}>relayed!</span>
                            </React.Fragment>
                        )}
                    />
                }
            </div>
        </ThemedDialogWithSpinner>
    )
});

export default SendMessageDialog;