import { TextField, makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import { ChangeEvent, useEffect } from "react";
import { observer } from "mobx-react";
import { StepProps } from "../../../../stores/models/Step";
import TermsOfServiceAcceptance from "../Shared/TermsOfServiceAcceptance";
import { StubUser } from "../../../../stores/models/StubUser";
import { VOLUNTEER_NAME_MAX_LENGTH } from "../../../../logic/ValidationChecks/FieldLengths";
import REMKeyboardDatePicker from "../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
            width: '40ch',
            [theme.breakpoints.down('xs')]: {
                flexGrow: 1,
                width: 'auto'
            },
        },
        checkboxWrapper: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            color: theme.palette.text.secondary,
            textAlign: 'center'
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                alignItems: 'center'
            }
        },
        textFieldWrapper: {
            display: 'flex',
            flexGrow: 1
        }
    }),
);

const DemographicsInput = observer((props: StepProps<StubUser>) => {

    const stubUser = props.stepState.stepObject;
    const stubVolunteer = stubUser.stubVolunteer!;

    /***** React hooks *****/

    const classes = useStyles();

    /***** Effect *****/

    useEffect(() => {
        if (props.stepState.validationRun) {
            stubVolunteer.setAllFieldsDirty();
            stubUser.setFieldsDirty(['termsOfServiceAccepted']);
        }
    }, [props.stepState.validationRun]);

    /***** Event handlers *****/

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        stubVolunteer.setFirstName(event.target.value);
    }

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        stubVolunteer.setLastName(event.target.value);
    }

    const handleBirthdateChange = (date: Date | null) => {
        stubVolunteer.setBirthdate(date);
    }

    /***** Helper constants *****/

    const validationRun = props.stepState.validationRun;
    const firstNameError = validationRun ? stubVolunteer.errors.firstName : '';
    const lastNameError = validationRun ? stubVolunteer.errors.lastName : '';
    const dateOfBirthError = validationRun ? stubVolunteer.errors.birthdate : '';
    const termsOfServiceErrorExists = validationRun && stubUser.isFieldInvalid('termsOfServiceAccepted');

    /***** Render *****/

    return (
        <Grid spacing={2}>
            <Grid item>
                <div className={classes.textFieldWrapper}>
                    <TextField
                        id="standard-first-name-input"
                        label="First Name"
                        variant="outlined"
                        required
                        value={stubVolunteer.firstName}
                        onChange={handleFirstNameChange}
                        autoFocus
                        className={classes.textField}
                        error={firstNameError.length > 0}
                        helperText={firstNameError.length > 0 ? firstNameError : undefined}
                        inputProps={{ maxLength: VOLUNTEER_NAME_MAX_LENGTH }}
                    />
                </div>
                <div className={classes.textFieldWrapper}>
                    <TextField
                        id="standard-last-name-input"
                        label="Last Name"
                        variant="outlined"
                        required
                        value={stubVolunteer.lastName}
                        onChange={handleLastNameChange}
                        className={classes.textField}
                        error={lastNameError.length > 0}
                        helperText={lastNameError.length > 0 ? lastNameError : undefined}
                        inputProps={{ maxLength: VOLUNTEER_NAME_MAX_LENGTH }}
                    />
                </div>
                <div className={classes.textFieldWrapper}>
                    <REMKeyboardDatePicker
                        margin="normal"
                        id="birthdate-picker"
                        label="Date of Birth"
                        format="MM/dd/yyyy"
                        minDate={new Date(1900, 1, 1)}
                        value={stubVolunteer.birthdate}
                        onChange={handleBirthdateChange}
                        inputVariant="outlined"
                        required
                        className={classes.textField}
                        disableFuture
                        error={dateOfBirthError.length > 0}
                        helperText={dateOfBirthError.length > 0 ? dateOfBirthError : undefined}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
                <div className={classes.checkboxWrapper}>
                    <TermsOfServiceAcceptance
                        checked={stubUser.termsOfServiceAccepted}
                        onChange={(accepted) => stubUser.setTermsOfServiceAccepted(accepted)}
                        error={termsOfServiceErrorExists ? stubUser.getErrorForField('termsOfServiceAccepted') : undefined}
                    />
                </div>
            </Grid>
        </Grid>
    );
});

export default DemographicsInput;