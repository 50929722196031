import { observable, computed, makeObservable } from "mobx";
import { stateAbbreviations } from "../../logic/RegexValidation";
import { City } from "./City";

export interface ICitySuggestion {
    text: string;
}

// Case insensitive validation check
export function isValidCityState(cityState: string) {
    const validText = new RegExp('\^\.\+, \(' + stateAbbreviations + '\)\$', "i");
    return validText.test(cityState.trim());
}

export class CitySuggestion implements ICitySuggestion {
    text = "";
    private textPieces = [] as string[];

    constructor(text: string) {
        makeObservable(this, {
            text: observable,
            cityName: computed,
            cityAndState: computed,
            stateAndCountry: computed,
            state: computed,
            cityState: computed,
            cityObject: computed
        });

        this.text = text;
        const splittingRegex = new RegExp(', (?=' + stateAbbreviations + ', USA)');
        this.textPieces = text.split(splittingRegex);
    }

    get cityName() {
        return this.textPieces.length > 0 ? this.textPieces[0] : "";
    }

    get cityAndState() {
        return `${this.cityName}, ${this.state}`;
    }

    get stateAndCountry() {
        return this.textPieces.length > 1 ? this.textPieces[1] : "";
    }

    get state() {
        return this.stateAndCountry.replace(/, USA.*/, '');
    }

    get cityState() {
        return `${this.cityName}, ${this.state}`;
    }

    get cityObject() {
        return new City(this.cityName, this.state);
    }
}