import { TextField, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FilterOptionsState, createFilterOptions } from "@material-ui/lab";
import { TextBoxCheckOutline } from "mdi-material-ui";
import ButtonWithPopper from "./ButtonWithPopper";
import { IStatus } from "../../../../stores/models/Status";
import { DialogState } from "../../../../stores/models/DialogState";
import PopperCard from "./PopperCard";
import REMAutocomplete from "../../../../shared/modules/rem-conversion/components/Autocomplete";
import REMButton from "../../../../shared/modules/rem-conversion/components/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectDropdown: {
            width: 230
        },
    })
);

const STATUS_SELECT_ID = 'status-select';

const filterOptions: (options: IStatus[], state: FilterOptionsState<IStatus>) => IStatus[] = createFilterOptions({
    matchFrom: 'start',
    // stringify: (option) => option.title,
});

interface UpdateStatusPopperProps {
    statuses: IStatus[];
    popperState: DialogState;
    size?: "small" | "large" | "medium";
    onConfirm: (status: IStatus) => void;
}

const UpdateStatusPopper = observer((props: UpdateStatusPopperProps) => {

    const classes = useStyles();

    const [status, setStatus] = React.useState<IStatus>();

    useEffect(() => {
        if (!props.popperState.open) {
            setStatus(undefined);
        }
    }, [props.popperState.open]);

    /********* Event Handlers *********/

    const onUpdateStatusButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.popperState.setOpen(!props.popperState.open);
        setTimeout(() => {
            document.getElementById(STATUS_SELECT_ID)?.focus();
        }, 0)
    }

    const onStatusChanged = (value: IStatus | null) => {
        setStatus(value ? value : undefined);
    }

    /********* Render *********/

    return (
        <ButtonWithPopper
            Button={(ref) =>
                <REMButton
                    startIcon={<TextBoxCheckOutline />}
                    variant="outlined"
                    size={props.size ? props.size : "large"}
                    onClick={onUpdateStatusButtonClicked}
                    ref={ref}
                >
                    Update Status
                </REMButton>
            }
            state={props.popperState}
        >
            <PopperCard
                state={props.popperState}
                actionButtonText={"Update"}
                ActionButtonProps={{
                    disabled: status === undefined,
                    onClick: () => props.onConfirm(status!)
                }}
            >
                <REMAutocomplete
                    id={STATUS_SELECT_ID}
                    className={classes.selectDropdown}
                    options={props.statuses}
                    getOptionLabel={(option: IStatus) => option.status}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                        />
                    )}
                    onChange={(event, value) => onStatusChanged(value as IStatus | null)}
                />
            </PopperCard>
        </ButtonWithPopper>
    );
});

export default UpdateStatusPopper;