import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DialogState } from "../../../../../stores/models/DialogState";
import { AccountPlus } from "mdi-material-ui";
import ButtonWithPopper from "../ButtonWithPopper";
import { Volunteer } from "../../../../../stores";
import VolunteersAutocomplete from "../VolunteersAutocomplete";
import PopperCard from "../PopperCard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocomplete: {
            width: 300
        },
    })
);

const VOLUNTEER_AUTOCOMPLETE_ID = 'register-volunteers-autocomplete';

interface RegisterVolunteersPopperProps {
    popperState: DialogState;
    registeredVolunteerIds: number[];
    maxVolunteers?: number;
    onConfirm: (volunteers: Volunteer[]) => void;
}

const RegisterVolunteersPopper = observer((props: RegisterVolunteersPopperProps) => {

    const classes = useStyles();

    const [volunteers, setVolunteers] = React.useState<Volunteer[]>([]);

    useEffect(() => {
        if (!props.popperState.open) {
            setVolunteers([]);
        }
    }, [props.popperState.open]);

    /********* Helper constants *********/

    const singluarError = `There is only 1 open slot left.`;
    const pluralError = `There are only ${props.maxVolunteers} open slots left.`;
    const zeroError = `There are no open slots left.`;
    const error = props.maxVolunteers === undefined
        ? undefined
        : volunteers.length > props.maxVolunteers
            ? props.maxVolunteers === 0
                ? zeroError
                : props.maxVolunteers === 1
                    ? singluarError
                    : pluralError
            : undefined;

    /********* Event Handlers *********/

    const onRegisterVolunteersButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.popperState.setOpen(!props.popperState.open);
        setTimeout(() => {
            document.getElementById(VOLUNTEER_AUTOCOMPLETE_ID)?.focus();
        }, 0)
    }

    const onConfirmRegistrationClicked = () => {
        if (error === undefined) {
            props.onConfirm(volunteers);
        }
    }

    const onVolunteersChanged = (volunteers: Volunteer[]) => {
        setVolunteers(volunteers);
    }

    /********* Render *********/

    return (
        <ButtonWithPopper
            Button={(ref) =>
                <Button
                    startIcon={<AccountPlus />}
                    variant={"contained"}
                    size={"large"}
                    color={'primary'}
                    onClick={onRegisterVolunteersButtonClicked}
                    ref={ref}
                >
                    Register Volunteers
                </Button>
            }
            state={props.popperState}
        >
            <PopperCard
                state={props.popperState}
                actionButtonText={"Register"}
                ActionButtonProps={{
                    disabled: volunteers.length === 0 || error !== undefined,
                    onClick: onConfirmRegistrationClicked
                }}
            >
                <VolunteersAutocomplete
                    disabledOptions={props.registeredVolunteerIds}
                    onVolunteersChanged={onVolunteersChanged}
                    autocompleteClassName={classes.autocomplete}
                    autocompleteId={VOLUNTEER_AUTOCOMPLETE_ID}
                    error={error}
                />
            </PopperCard>
        </ButtonWithPopper>
    );
});

export default RegisterVolunteersPopper;