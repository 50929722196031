import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from 'mobx-react'
import { RootContext } from "../../stores";
import { makeStyles, Theme, createStyles, Container, Typography, Paper, Grid, Button, AppBar, Toolbar, Box, Card, useMediaQuery, Backdrop } from "@material-ui/core";
import { getExternalUrl } from "../../logic";
import LocationCard from "./LocationCard";
import SparkButton from "../Shared/SparkButton";
import { getAccountProfileLink } from "../Navigation/Links/UrlConstructors";
import EditButton from "../Shared/EditButton";
import useOrgOpportunitiesLink from "../Navigation/Hooks/OrganizationProfile/OrgOpportunitiesLink";
import useCurrentWidth from "../Shared/Hooks/WindowResize";
import { ClipboardTextMultipleOutline, DotsVertical, FileEditOutline, LightningBolt, Web } from "mdi-material-ui";
import FieldHeader from "../Shared/FieldHeader";
import PageWithFooter from "../Shared/PageWithFooter";
import { getInitialDialogStates } from "../../logic/DialogStateDictionary";
// import SendMessageDialog from "./VolunteerOpportunities/SendMessageDialog";
import FormDialog from "../Shared/Forms/DialogForm/FormDialog";
import useOrganizationRegistrationForm from "../Shared/Forms/LoadOrganizationRegistrationForm";
import SuccessfulRegistrationDialog from "../Shared/Forms/DialogForm/SuccessfulRegistrationDialog";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import { useNavigateInternally } from "../Navigation/Hooks";
import useFormattedContent from "../../shared/modules/content/hooks/useFormattedContent";
import REMChip from "../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            overflow: 'hidden'
        },
        paper: {
            paddingTop: '15px',
            paddingBottom: '15px',
            whiteSpace: 'pre-line',
            marginBottom: theme.spacing(2)
        },
        address: {
            marginBottom: theme.spacing(2)
        },
        header: {
            display: 'flex',
            alignItems: 'flex-start'
        },
        title: {
            flexGrow: 1,
            marginBottom: theme.spacing(1),
            fontWeight: 600
        },
        section: {
            paddingTop: '15px'
        },
        websiteLink: {
            textTransform: 'none'
        },
        container: {
            wordBreak: 'break-word'
        },
        appBar: {
            top: 'auto',
            bottom: 0,
        },
        grow: {
            flexGrow: 1,
        },
        chip: {
            marginRight: '5px',
            marginBottom: '5px',
            background: theme.palette.lightBackground.main
        },
        toolbarSpacer: theme.mixins.toolbar,
        actionButtonsBox: {
            position: 'sticky',
            top: theme.spacing(9),
        },
        actionButtons: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 3),
            zIndex: theme.zIndex.speedDial,
            '& > :not(:first-child)': {
                marginTop: theme.spacing(1.5),
            }
        },
        // actionButton: {
        //     color: theme.palette.action.active
        // },
        spark: {
            background: theme.palette.accent.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.accent.dark
            },
            textWrap: 'nowrap'
        },
        editButton: {
            color: theme.palette.primary.contrastText
        },
        speedDial: {
            position: 'fixed',
            bottom: theme.spacing(3),
            right: theme.spacing(4),
            zIndex: theme.zIndex.appBar + 1
        },
        noWrap: {
            whiteSpace: 'nowrap'
        }
    })
);

enum DialogTypes {
    RegistrationForm = 'registrationForm',
    SuccessfulRegistration = 'successfulRegistration',
    ContactRecruiter = 'contactRecruiter',
    SpeedDial = 'speedDial'
}

const OrganizationInformation = observer(() => {

    /********* React hooks *********/
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const volunteerStore = rootStore.volunteerStore;
    const organizationStore = rootStore.organizationStore;
    const navigationStore = rootStore.navigationStore;
    const formStore = rootStore.formStore;
    const organization = organizationStore.organization;
    const navigate = useNavigateInternally();
    const opportunitiesLink = useOrgOpportunitiesLink();
    const width = useCurrentWidth();
    const shouldCondenseButtonText = useMediaQuery((theme: Theme) => theme.breakpoints.down(1235));
    const formattedMission = useFormattedContent({ content: organization.mission });
    const formattedDescription = useFormattedContent({ content: organization.description });

    /********* State *********/

    const [dialogStates] = useState(getInitialDialogStates(Object.values(DialogTypes)));
    const [registrationForm, registrationFormLoaded, formSubmitted, resetForm] = useOrganizationRegistrationForm(organization.id, true);

    /********* Effects *********/

    useEffect(() => {
        if (!dialogStates.registrationForm.open) {
            resetForm();
        }
    }, [dialogStates.registrationForm.open]);

    /********* Helper constants *********/

    const navigateToOpportunities = () => {
        navigate(opportunitiesLink);
    }

    /********* Helper constants *********/

    const noAddressGiven = (organization.addressCollection.addresses.length === 0);

    const orgMatchesUser = userStore.isAuthenticatedOrganization(organization.id);

    /********* Helper methods *********/

    const onRegisterClicked = () => {
        performActionIfVolunteerAuthenticated(() => dialogStates.registrationForm.setOpen(true));
    }

    const onSparkButtonClicked = () => {
        const currentSparkValue = organization.interestSparked;
        performActionIfVolunteerAuthenticated(() => setSparkValue(!currentSparkValue));
    }

    const setSparkValue = (sparked: boolean) => {
        organization.setInterestSparked(sparked);
        volunteerStore.updateOrganizationSpark(organization.id, sparked);
    }

    // TODO: Add ability to message the recruiter from the organization page
    // const onSendMessageClicked = () => {
    //     performActionIfVolunteerAuthenticated(() => dialogStates.contactRecruiter.setOpen(true));
    // }

    const onRegistrationSubmitClicked = async () => {
        if (!registrationForm) return { succeeded: false };
        const response = await formStore.submitForm(registrationForm, organization.id.toString());
        if (response.succeeded) {
            dialogStates.successfulRegistration.setOpen(true);
        }
        return response;
    }

    const performActionIfVolunteerAuthenticated = (callback: () => void) => {
        if (userStore.isAuthenticated) {
            if (userStore.user.isVolunteer) {
                callback();
            } else {
                navigationStore.appwideDialogStates.volunteerAccountRequiredDialog.setOpen(true);
            }
        } else {
            // Prompt the user to login or sign up first
            navigationStore.appwideDialogStates.loginDialog.setOpen(true);
            navigationStore.setOnAuthenticationCallback(() => performActionIfVolunteerAuthenticated(callback));
        }
    }

    const hasCustomRegistrationForm = registrationForm !== undefined && registrationForm.version > 0;
    const actions = registrationFormLoaded && hasCustomRegistrationForm
        ? [
            { icon: <ClipboardTextMultipleOutline />, name: 'View Opportunities', onClick: navigateToOpportunities },
            // { icon: <Email />, name: 'Contact Recruiter', onClick: onSendMessageClicked },
            { icon: <FileEditOutline />, name: 'Register', onClick: onRegisterClicked },
        ]
        : [
            { icon: <ClipboardTextMultipleOutline />, name: 'View Opportunities', onClick: navigateToOpportunities },
            // { icon: <Email />, name: 'Contact Recruiter', onClick: onSendMessageClicked },
        ];

    /********* Render *********/

    return (
        <Fragment>
            <PageWithFooter>
                <Container>
                    <div id="organization-information" className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={noAddressGiven ? 12 : 9}>
                                <Paper className={classes.paper}>
                                    <Container className={classes.container}>
                                        <div className={classes.header}>
                                            <Typography variant="h4" className={classes.title}>
                                                {organization.name}
                                            </Typography>
                                            <Box sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }}>
                                                {orgMatchesUser
                                                    ? <EditButton link={getAccountProfileLink()} />
                                                    : <SparkButton sparkObject={organization} displayHoverText={false} />
                                                }
                                            </Box>
                                        </div>
                                        {organization.causes.length > 0
                                            ? <div>
                                                {organization.causes.map((cause, index) => {
                                                    return (
                                                        <REMChip
                                                            label={cause.cause}
                                                            icon={React.createElement(cause.icon)}
                                                            className={classes.chip}
                                                            key={`cause-${index}`}
                                                        />
                                                    )
                                                })}
                                            </div>
                                            : null}
                                        {organization?.website
                                            ? <Button
                                                aria-label="visit website"
                                                href={getExternalUrl(organization?.website)}
                                                target='_blank'
                                                color="primary"
                                                startIcon={<Web />}
                                                className={classes.websiteLink}
                                            >
                                                <Typography noWrap>Visit website</Typography>
                                            </Button>
                                            : null
                                        }
                                        {organization.isForProfit
                                            ? null
                                            : <div className={classes.section}>
                                                <FieldHeader title="Mission" />
                                                <Typography>{formattedMission}</Typography>
                                            </div>
                                        }
                                        <div className={classes.section}>
                                            <FieldHeader title="Description" />
                                            <Typography>{formattedDescription}</Typography>
                                        </div>
                                    </Container>
                                </Paper>
                            </Grid>
                            {noAddressGiven
                                ? null
                                : <Grid item xs={12} md={3}>
                                    <div className={classes.address}>
                                        <LocationCard
                                            title={organization.addressCollection.addresses.length > 1 ? 'Addresses' : 'Address'}
                                            addresses={organization.addressCollection.addresses}
                                        />
                                    </div>
                                    <Box sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }} className={classes.actionButtonsBox}>
                                        {orgMatchesUser || registrationFormLoaded
                                            ? <Card className={classes.actionButtons}>
                                                {orgMatchesUser
                                                    ? <EditButton
                                                        link={getAccountProfileLink()}
                                                        ButtonProps={{ variant: 'contained', color: 'primary', className: classes.editButton }}
                                                        showText
                                                    />
                                                    : <Fragment>
                                                        {hasCustomRegistrationForm
                                                            ? <Button
                                                                variant="contained"
                                                                color="primary"
                                                                startIcon={<FileEditOutline />}
                                                                size="large"
                                                                onClick={onRegisterClicked}
                                                                aria-label="volunteer"
                                                                className={classes.noWrap}
                                                            >
                                                                Register
                                                            </Button>
                                                            : null
                                                        }
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<LightningBolt />}
                                                            size="large"
                                                            aria-label={organization.interestSparked ? 'Remove From Sparks' : 'Save to Sparks'}
                                                            onClick={onSparkButtonClicked}
                                                            className={classes.spark}
                                                        >
                                                            {shouldCondenseButtonText
                                                                ? organization.interestSparked ? 'Unspark' : 'Spark'
                                                                : organization.interestSparked ? 'Remove Spark' : 'Save to Sparks'
                                                            }
                                                        </Button>
                                                        {/* <Button
                                                            variant="outlined"
                                                            startIcon={<Email />}
                                                            size="large"
                                                            aria-label={'Contact recruiter'}
                                                            onClick={onSendMessageClicked}
                                                            className={classes.actionButton}
                                                        >
                                                            {shouldCondenseButtonText ? 'Contact' : 'Contact Recruiter'}
                                                        </Button> */}
                                                    </Fragment>
                                                }
                                                {/* <Button
                                                    variant="outlined"
                                                    startIcon={<Share />}
                                                    size="large"
                                                    className={classes.actionButton}
                                                    // TODO: Add Share onClick handler
                                                    >
                                                    Share
                                                </Button> */}
                                            </Card>
                                            : null
                                        }
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </Container>
            </PageWithFooter>
            <Box sx={{ display: { sm: 'block', md: 'none' } }}>
                <div className={classes.speedDial}>
                    <Backdrop open={dialogStates.speedDial.open} />
                    <SpeedDial
                        ariaLabel="Organization actions"
                        open={dialogStates.speedDial.open}
                        icon={<DotsVertical />}
                        onClose={() => dialogStates.speedDial.setOpen(false)}
                        onOpen={() => dialogStates.speedDial.setOpen(true)}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                classes={{ staticTooltipLabel: classes.noWrap }}
                                onClick={() => {
                                    dialogStates.speedDial.setOpen(false);
                                    action.onClick();
                                }}
                            />
                        ))}
                    </SpeedDial>
                </div>
                <div className={classes.toolbarSpacer} />
                <AppBar position="fixed" color="inherit" className={classes.appBar}>
                    <Toolbar>
                        {orgMatchesUser
                            ? <EditButton link={getAccountProfileLink()} showText />
                            : <SparkButton sparkObject={organization} displayText={width > 335} darker />
                        }
                    </Toolbar>
                </AppBar>
            </Box>
            {/* <SendMessageDialog
                dialogState={dialogStates.contactRecruiter}
                recipient={organization.name}
            /> */}
            {hasCustomRegistrationForm
                ? <FormDialog
                    state={dialogStates.registrationForm}
                    submitted={formSubmitted}
                    form={registrationForm}
                    onFormSubmit={onRegistrationSubmitClicked}
                />
                : null
            }
            <SuccessfulRegistrationDialog
                state={dialogStates.successfulRegistration}
                organizationName={organization.name}
            />
        </Fragment>
    );
});

export default OrganizationInformation;