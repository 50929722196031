import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, ChipProps } from "@material-ui/core";
import { Autocomplete as MUIAutocomplete, AutocompleteProps as MUIAutocompleteProps } from "@material-ui/lab";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        endAdornment: {
            top: 'calc(50% - 0.875rem)',
            right: '0.5625rem',
        },
        input: {
            minWidth: '1.875rem !important'
        },
        inputRoot: {
            minWidth: '1.875rem',
            paddingRight: '4.0625rem !important'
        },
        tag: {
            height: '2rem',
            borderRadius: '1rem'
        },
        root: {
            height: '2rem',
            // padding: '0 1rem',
            minWidth: '3rem',
            borderRadius: '1rem'
        },
        label: {
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem'
        },
        deleteIcon: {
            width: '1.375rem',
            height: '1.375rem',
            margin: '0 0.3125rem 0 -0.375rem'
        },
        icon: {
            marginLeft: '0.3125rem',
            marginRight: '-0.375rem'
        }
    })
);

interface AutocompleteProps<T, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined> extends MUIAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {

}

const REMAutocomplete = observer(
    <T, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined>(
        props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
    ) => {

        // const chipProps = props.ChipProps as ChipProps | undefined;

        /********* React hooks *********/

        const classes = useStyles();

        /********* Render *********/

        return (
            <MUIAutocomplete
                {...props}
                classes={{
                    ...props.classes,
                    endAdornment: clsx(classes.endAdornment, props.classes?.endAdornment),
                    input: clsx(classes.input, props.classes?.input),
                    inputRoot: clsx(classes.inputRoot, props.classes?.inputRoot),
                    // tag: clsx(classes.tag, props.classes?.tag),
                }}
                // TODO: The following seems to be broken MUI functionality
                // ChipProps={{
                //     ...props.ChipProps,
                //     classes: {
                //         root: clsx(classes.root, (chipProps as ChipProps)?.classes?.root),
                //         deletable: clsx(classes.root, (chipProps as ChipProps)?.classes?.root),
                //         label: clsx(classes.label, (chipProps as ChipProps)?.classes?.label),
                //         deleteIcon: clsx(classes.deleteIcon, (chipProps as ChipProps)?.classes?.deleteIcon),
                //         icon: clsx(classes.icon, (chipProps as ChipProps)?.classes?.icon)
                //     }
                // }}
            />
        );
    });

export default REMAutocomplete;