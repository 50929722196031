import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";
import { SearchCriteria } from "../../stores";

const searchCriteriaChecks: ValidationCheck<SearchCriteria>[] = [{
    name: 'location option selected',
    condition: (searchCriteria: SearchCriteria) => {
        return searchCriteria.locationOptions.selections.length > 0;
    },
    error: new ValidationError('Please select at least one option.', ['locationOptions'])
}, {
//     name: 'schedule option selected',
//     condition: (searchCriteria: SearchCriteria) => {
//         return searchCriteria.schedulingOptions.selections.length > 0;
//     },
//     error: new ValidationError('Please select at least one option.', ['schedulingOptions'])
// }, {
    name: 'commitment option selected',
    condition: (searchCriteria: SearchCriteria) => {
        return searchCriteria.commitmentOptions.selections.length > 0;
    },
    error: new ValidationError('Please select at least one option.', ['commitmentOptions'])
}, {
    name: 'opportunity type option selected',
    condition: (searchCriteria: SearchCriteria) => {
        return searchCriteria.opportunityTypeOptions.selections.length > 0;
    },
    error: new ValidationError('Please select at least one option.', ['opportunityTypeOptions'])
}, {
    name: 'organization type option selected',
    condition: (searchCriteria: SearchCriteria) => {
        return searchCriteria.organizationOptions.selections.length > 0;
    },
    error: new ValidationError('Please select at least one option.', ['organizationOptions'])
}];

export default function validateSearchCriteria(searchCriteria: SearchCriteria) {
    return getValidationErrors(searchCriteriaChecks, searchCriteria);
}