import {
    Grid,
    FormControlLabel,
    makeStyles,
    Theme,
    createStyles,
    FormControl,
    MenuItem,
    Typography,
    InputAdornment,
    RadioGroup,
    Radio,
    TextField,
    useMediaQuery,
    Button,
    GridSize,
} from "@material-ui/core";
import React, { useState, ChangeEvent, useEffect, Fragment } from "react";
import { ClockOutline, Autorenew, MapMarker, MapMarkerPlus, ClipboardText } from "mdi-material-ui";
import { Shift } from "../../../stores/models/Shift";
import WeekdayButtons from "./WeekdayButtons";
import { RepetitionFrequency, MonthlyRepetitionType, DaysOfTheWeek } from "../../../stores/models/RepetitionPattern";
import { observer } from "mobx-react";
import { SingularFrequencies, PluralFrequencies, } from "../../../logic/RepetitionPatternLogic";
import clsx from 'clsx';
import { RepetitionEndType } from "../../../stores/models/RepetitionEnding";
import { Address } from "../../../stores/models/Address";
import { Role } from "../../../stores/models/Role";
import AddressDialog from "../../Shared/AddressDialog";
import { DialogState } from "../../../stores/models/DialogState";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getNewOpportunityLink } from "../../Navigation/Links";
import { useNavigateInternally } from "../../Navigation/Hooks";
import REMSelect from "../../../shared/modules/rem-conversion/components/Select";
import REMKeyboardDatePicker from "../../../shared/modules/rem-conversion/components/KeyboardDatePicker";
import REMKeyboardTimePicker from "../../../shared/modules/rem-conversion/components/KeyboardTimePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(2)
        },
        subtitle: {
            paddingTop: '5px',
            textAlign: 'right',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
                fontWeight: 'bold'
            }
        },
        endingSubtitle: {
            paddingTop: '9px',
            textAlign: 'right',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
                fontWeight: 'bold'
            }
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        icon: {
            padding: theme.spacing(1),
            color: 'rgba(0, 0, 0, 0.54)'
        },
        container: {
            marginBottom: theme.spacing(1)
        },
        numberField: {
            width: '1.875rem',
            marginRight: theme.spacing(2)
        },
        radioButton: {
            marginLeft: -11
        },
        radioField: {
            paddingTop: '4px',
        },
        xOverflowHidden: {
            overflowX: 'hidden'
        },
        fullWidth: {
            maxWidth: '100%'
        },
        pointer: {
            cursor: 'pointer'
        },
        addLocationButton: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
            textTransform: 'none'
        },
        alert: {
            marginBottom: theme.spacing(2),
            '& > .MuiAlert-message': {
                width: '100%'
            }
        },
        bold: {
            fontWeight: 'bold'
        },
        errorButton: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1)
        }
    })
);

type ColumnWidths = {
    labels: {
        md: GridSize;
        sm: GridSize;
        xs: GridSize;
    };
    inputs: {
        md: GridSize;
        sm: GridSize;
        xs: GridSize;
    };
}

const columnWidths: ColumnWidths = {
    labels: {
        md: 3,
        sm: 3,
        xs: 12
    },
    inputs: {
        md: 9,
        sm: 9,
        xs: 12
    }
}

interface ShiftEntryProps {
    shift: Shift;
    locationOptions: Address[];
    inlineLocationAdding?: boolean;
    opportunityId?: number;
    opportunityError?: string;
    opportunityOptions?: Role[];
    onOpportunityChanged?: (opportunityId: number) => void;
}

interface DateTimeEntryFieldData {
    label: string;
    date: Date | null;
    time: Date | null;
    dateError: string;
    timeError: string;
    onDateChange: (newValue: Date | null) => void;
    onTimeChange: (newValue: Date | null) => void;
}

const ShiftEntry = observer((props: ShiftEntryProps) => {

    /********* React Hooks *********/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const navigate = useNavigateInternally();

    /********* Helper methods *********/

    const getIndexOfAddress = (addressId: number) => {
        return props.locationOptions.findIndex(address => address.id === addressId);
    }

    const getSelectedAddress = () => {
        if (props.shift.address) {
            const index = getIndexOfAddress(props.shift.address.id);
            if (index !== -1) {
                return props.shift.address.id;
            }
        }
        return 0;
    }

    /********* State *********/

    const [initialFocusedDate, setInitialFocusedDate] = useState(new Date());

    const repetitionPattern = props.shift.repetitionPattern;
    const ending = repetitionPattern.ending;
    const defaultEndingDate = ending.endType === RepetitionEndType.EndDate ? ending.endDate : repetitionPattern.defaultRepetitionEndDate;
    const [endingDate, setEndingDate] = useState(defaultEndingDate);

    const defaultOccurrences = ending.endType === RepetitionEndType.AfterOccurrences ? ending.numOccurrences : 2;
    const [occurrences, setOccurrences] = useState(defaultOccurrences);

    const [locationOptions, setLocationOptions] = useState(props.locationOptions);
    const [location, setLocation] = useState(getSelectedAddress());
    const [opportunityId, setOpportunityId] = useState(props.opportunityId);

    const [slots, setSlots] = useState(props.shift.unlimitedSlots ? '1' : props.shift.slots.toString());

    const [dialogState] = useState(new DialogState());
    const [newAddress] = useState(new Address());

    /********* Effects *********/

    useEffect(() => {
        setEndingDate(ending.endDate);
    }, [ending.endDate]);

    useEffect(() => {
        setOccurrences(ending.numOccurrences);
    }, [ending.numOccurrences]);

    /********* Event Handlers *********/

    const onFrequencyChanged =
        (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode) => {
            const newValue = event.target.value as RepetitionFrequency;
            repetitionPattern.setFrequency(newValue);
        }

    const onRepetitionIntervalChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = !Number.isNaN(parsedValue) ? parsedValue : 0;
        repetitionPattern.setInterval(newValue);
    }

    const onCustomFrequencyChanged =
        (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode) => {
            const descriptor = event.target.value as SingularFrequencies | PluralFrequencies;
            repetitionPattern.setCustomFrequencyDescriptor(descriptor);
        }

    const onDaysOfWeekChanged = (selectedDays: DaysOfTheWeek[]) => {
        if (selectedDays.length > 0) {
            repetitionPattern.setDaysOfTheWeek(selectedDays);
        }
    }

    const onMonthlyRepetitionPatternChanged = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        const newPattern = event.target.value as MonthlyRepetitionType;
        repetitionPattern.setMonthlyRepetitionPattern(newPattern);
    }

    const onEndTypeChanged = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const endType = value as unknown as RepetitionEndType;
        ending.setEndType(endType);
    }

    const onOccurrencesChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = parseInt(event.target.value);
        ending.setNumOccurrences(newValue);
    }

    const onLocationChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode) => {
        const newValue = event.target.value as number;
        const addressIndex = getIndexOfAddress(newValue);
        if (addressIndex !== -1) {
            props.shift.setAddress(locationOptions[addressIndex]);
        } else {
            props.shift.setAddress();
        }
        setLocation(newValue);
    }

    const onNewAddressAdded = (address: Address) => {
        const index = locationOptions.findIndex(location => location.id === -1);
        const newAddressAlreadyAdded = index !== -1;
        let locationOptionsCopy = locationOptions.slice();
        if (newAddressAlreadyAdded) {
            locationOptionsCopy[index] = address; // Replace the address with the newest one
        } else {
            locationOptionsCopy.push(address);
        }
        setLocationOptions(locationOptionsCopy);
        setLocation(-1);
        props.shift.setAddress(address);
        dialogState.setOpen(false);
    }

    const onOpportunityChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode) => {
        const newValue = event.target.value as number;
        setOpportunityId(newValue);
        if (props.onOpportunityChanged) {
            props.onOpportunityChanged(newValue);
        }
    }

    const onSlotsChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = !Number.isNaN(parsedValue) ? parsedValue : 0;
        props.shift.setSlots(newValue);
        setSlots(newValue.toString());
    }

    const onSlotsRadioSelectionChanged = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value === 'unlimited') {
            props.shift.setSlots(-1);
        } else {
            onLimitedSlotsSelected();
        }
    }

    const onLimitedSlotsSelected = () => {
        props.shift.setSlots(parseInt(slots));
    }

    const linkToNewOpportunity = () => {
        navigate(getNewOpportunityLink());
    }

    /********* Helper constants *********/

    const dateTimeEntryFieldData: DateTimeEntryFieldData[] = [{
        label: "Starting:",
        date: repetitionPattern.startDate,
        time: repetitionPattern.startTime,
        dateError: repetitionPattern.errors.startDate,
        timeError: repetitionPattern.errors.startTime,
        onDateChange: (date: Date | null) => repetitionPattern.setStartDate(date),
        onTimeChange: (time: Date | null) => repetitionPattern.setStartTime(time)
    }, {
        label: "Ending:",
        date: repetitionPattern.endDate,
        time: repetitionPattern.endTime,
        dateError: repetitionPattern.errors.endDate,
        timeError: repetitionPattern.errors.endTime,
        onDateChange: (date: Date | null) => repetitionPattern.setEndDate(date),
        onTimeChange: (time: Date | null) => repetitionPattern.setEndTime(time)
    }];

    // Dropdown Options
    const dropdownOptions = repetitionPattern.frequencyDropdownOptions;
    const monthlyDropdownOptions = repetitionPattern.monthlyDropdownOptions;

    /********* Render *********/

    return (
        <div>
            {/******* Opportunity selection *******/}
            {props.opportunityId && props.opportunityOptions &&
                <Fragment>
                    {props.opportunityOptions.length === 0 &&
                        < Alert severity="error" className={classes.alert}>
                            <AlertTitle className={classes.bold}>Opportunity Required</AlertTitle>
                            <Typography>Create a volunteer opportunity in order to schedule shifts.</Typography>
                            <div className={classes.errorButton}>
                                <Button variant='contained' color='primary' onClick={() => linkToNewOpportunity()}>Create an opportunity</Button>
                            </div>
                        </Alert>
                    }
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                            <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>Opportunity:</Typography>
                        </Grid>
                        <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md} className={classes.xOverflowHidden}>
                            <FormControl variant="standard" className={classes.fullWidth}>
                                <REMSelect
                                    id="opportunity-dropdown"
                                    value={opportunityId}
                                    onChange={onOpportunityChanged}
                                    startAdornment={
                                        <InputAdornment position="start" className={classes.icon}>
                                            <ClipboardText />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value={-1}>
                                        Select an Opportunity
                                    </MenuItem>
                                    {props.opportunityOptions.map((role, index) => {
                                        return (
                                            <MenuItem key={`role-${index}`} value={role.id}>
                                                {role.position}
                                            </MenuItem>
                                        );
                                    })}
                                </REMSelect>
                                {props.opportunityError &&
                                    <Typography color='error' variant='caption'>
                                        {props.opportunityError}
                                    </Typography>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </Fragment>
            }

            {/******* Starting and ending dates and times *******/}
            {dateTimeEntryFieldData.map((data, index) => {
                return (
                    <Grid container spacing={2} className={classes.container} key={`date-time-entry-field-${index}`}>
                        {/* Label */}
                        <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                            <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>{data.label}</Typography>
                        </Grid>
                        {/* Date field */}
                        <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <REMKeyboardDatePicker
                                        id={`date-picker-${index}`}
                                        format="MM/dd/yy"
                                        // minDate={new Date()}
                                        value={data.date}
                                        inputVariant="standard"
                                        disableToolbar
                                        variant="inline"
                                        required
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputAdornmentProps={{ position: "start" }}
                                        autoOk
                                        initialFocusedDate={initialFocusedDate}
                                        onChange={(date, value) => { data.onDateChange(date); }}
                                        helperText={data.dateError.length > 0 ? data.dateError : ""}
                                        error={data.dateError.length > 0}
                                    />
                                </Grid>
                                {/* Time field */}
                                <Grid item md={6}>
                                    <REMKeyboardTimePicker
                                        id={`time-picker-${index}`}
                                        format="h:mm a"
                                        mask="__:__ _M"
                                        minutesStep={5}
                                        value={data.time}
                                        variant="inline"
                                        inputVariant="standard"
                                        required
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        InputAdornmentProps={{ position: "start" }}
                                        keyboardIcon={<ClockOutline />}
                                        onChange={(time, value) => { data.onTimeChange(time); }}
                                        error={data.timeError.length > 0}
                                        helperText={data.timeError.length > 0 ? data.timeError : ""}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                );
            })}

            {/******* Repeats (Repetition pattern selection) *******/}
            {!props.shift.isShiftInstance
                ? <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                        <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>Repeats:</Typography>
                    </Grid>
                    <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md} className={classes.xOverflowHidden}>
                        <FormControl variant="standard" className={classes.fullWidth}>
                            <REMSelect
                                id="repetition-pattern-dropdown"
                                value={repetitionPattern.frequency}
                                onChange={onFrequencyChanged}
                                startAdornment={
                                    <InputAdornment position="start" className={classes.icon}>
                                        <Autorenew />
                                    </InputAdornment>
                                }
                            >
                                {Object.values(RepetitionFrequency).map((frequency, index) => {
                                    return (
                                        <MenuItem key={`menu-item-${index}`} value={frequency}>
                                            {dropdownOptions[frequency]}
                                        </MenuItem>
                                    );
                                })}
                            </REMSelect>
                        </FormControl>
                    </Grid>
                </Grid>
                : null
            }

            {/* Repetition Pattern Details */}
            {!repetitionPattern.repeats
                ? null
                : <div>

                    {/******* Frequency *******/}
                    < Grid container spacing={2} className={classes.container}>
                        <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                            <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>Frequency:</Typography>
                        </Grid>
                        <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md}>
                            <div className={classes.row}>
                                <Typography className={classes.text}>Every</Typography>
                                <TextField
                                    value={repetitionPattern.interval}
                                    className={classes.numberField}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    onChange={onRepetitionIntervalChanged}
                                    error={repetitionPattern.errors.interval.length > 0}
                                />
                                {repetitionPattern.isCustomPattern
                                    ? <REMSelect value={repetitionPattern.frequencyDescriptor} onChange={onCustomFrequencyChanged}>
                                        {repetitionPattern.customFrequencyDescriptors.map((frequency, index) => {
                                            return (
                                                <MenuItem key={`menu-item-${index}`} value={frequency}>
                                                    {frequency}
                                                </MenuItem>
                                            );
                                        })}
                                    </REMSelect>
                                    : <Typography className={classes.text}>{repetitionPattern.frequencyDescriptor}</Typography>
                                }
                            </div>
                            {repetitionPattern.errors.interval.length > 0
                                ? <Typography color='error' variant='caption'>
                                    {repetitionPattern.errors.interval}
                                </Typography>
                                : null}
                        </Grid>
                    </Grid>

                    {/******* Repeats on *******/}

                    {/* Days of the week selection */}
                    {repetitionPattern.isWeeklyPattern
                        ? <Grid container spacing={2} className={classes.container}>
                            <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                                <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>Repeats on:</Typography>
                            </Grid>
                            <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md}>
                                <WeekdayButtons
                                    selectedDays={repetitionPattern.daysOfTheWeek}
                                    onSelectedDaysChanged={onDaysOfWeekChanged}
                                />
                            </Grid>
                        </Grid>
                        : null
                    }

                    {/* Monthly repetition type selection */}
                    {repetitionPattern.isCustomMonthlyPattern
                        ? <Grid container spacing={2} className={classes.container}>
                            <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                                <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>Repeats on:</Typography>
                            </Grid>
                            <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md} className={classes.xOverflowHidden}>
                                <FormControl variant="standard" className={classes.fullWidth}>
                                    <REMSelect value={repetitionPattern.monthlyRepetitionPattern} onChange={onMonthlyRepetitionPatternChanged}>
                                        {Object.keys(monthlyDropdownOptions).map((key: string, index: number) => {
                                            return (
                                                <MenuItem key={`menu-item-${index}`} value={key}>
                                                    {monthlyDropdownOptions[key as MonthlyRepetitionType]}
                                                </MenuItem>
                                            );
                                        })}
                                    </REMSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {/******* Ending *******/}
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                            <Typography className={classes.endingSubtitle} variant={xsDown ? "body1" : "subtitle2"}>
                                Ending:
                            </Typography>
                        </Grid>
                        <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md} className={classes.xOverflowHidden}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="ending" value={ending.endType} onChange={onEndTypeChanged} >
                                    {/* Never Option */}
                                    <FormControlLabel value={RepetitionEndType.Never} control={<Radio color="primary" />} label="Never" />
                                    {/* On Date Option */}
                                    <div className={classes.row}>
                                        <FormControlLabel
                                            value={RepetitionEndType.EndDate}
                                            control={<Radio color="primary" />}
                                            label="On"
                                        />
                                        <REMKeyboardDatePicker
                                            id="birthdate-picker"
                                            format="MM/dd/yy"
                                            // minDate={new Date()}
                                            value={endingDate}
                                            onChange={(date, value) => { ending.setEndDate(date); }}
                                            inputVariant="standard"
                                            variant="inline"
                                            required
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            className={classes.radioField}
                                            style={{ width: '8.125rem' }}
                                            disabled={ending.endType !== RepetitionEndType.EndDate}
                                            disableToolbar
                                            error={ending.errors.endDate.length > 0}
                                            helperText={ending.errors.endDate.length > 0 ? ending.errors.endDate : undefined}
                                            onClick={() => ending.setEndType(RepetitionEndType.EndDate)}
                                        />
                                    </div>
                                    {/* After Number of Occurrences Option */}
                                    <div className={classes.row}>
                                        <FormControlLabel value={RepetitionEndType.AfterOccurrences} control={<Radio color="primary" />} label="After" />
                                        <TextField
                                            inputProps={{ style: { textAlign: 'center' } }}
                                            className={clsx(classes.numberField, classes.radioField)}
                                            disabled={ending.endType !== RepetitionEndType.AfterOccurrences}
                                            value={occurrences}
                                            onChange={onOccurrencesChanged}
                                            error={ending.errors.numOccurrences.length > 0}
                                            onClick={() => ending.setEndType(RepetitionEndType.AfterOccurrences)}
                                        />
                                        <Typography
                                            className={clsx(classes.text, classes.pointer)}
                                            color={ending.endType !== RepetitionEndType.AfterOccurrences
                                                ? 'textSecondary'
                                                : 'inherit'}
                                            onClick={() => ending.setEndType(RepetitionEndType.AfterOccurrences)}
                                        >
                                            occurrences
                                        </Typography>
                                    </div>
                                    {ending.errors.numOccurrences.length > 0
                                        ? <Typography color='error' variant='caption'>
                                            {ending.errors.numOccurrences}
                                        </Typography>
                                        : null}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            }

            {/******* Slots *******/}
            < Grid container spacing={2} className={classes.container}>
                <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                    <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>Slots:</Typography>
                </Grid>
                <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md} className={classes.xOverflowHidden}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="slots" value={props.shift.unlimitedSlots ? 'unlimited' : 'limited'} onChange={onSlotsRadioSelectionChanged} >
                            {/* Limited Slots Option */}
                            <div className={classes.row}>
                                <Radio color="primary" value={'limited'} className={classes.radioButton} />
                                <TextField
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    className={clsx(classes.numberField, classes.radioField)}
                                    disabled={props.shift.unlimitedSlots}
                                    value={slots}
                                    onChange={onSlotsChanged}
                                    error={props.shift.errors.slots.length > 0}
                                    onClick={onLimitedSlotsSelected}
                                />
                                <Typography
                                    className={clsx(classes.text, classes.pointer)}
                                    color={props.shift.unlimitedSlots
                                        ? 'textSecondary'
                                        : 'inherit'}
                                    onClick={onLimitedSlotsSelected}
                                >
                                    slots
                                </Typography>
                            </div>
                            {props.shift.errors.slots.length > 0
                                ? <Typography color='error' variant='caption'>
                                    {props.shift.errors.slots}
                                </Typography>
                                : null}
                            {/* Unlimited Option */}
                            <FormControlLabel value={'unlimited'} control={<Radio color="primary" />} label="Unlimited" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>

            {/******* Location selection *******/}
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={columnWidths.labels.xs} sm={columnWidths.labels.sm} md={columnWidths.labels.md}>
                    <Typography className={classes.subtitle} variant={xsDown ? "body1" : "subtitle2"}>Location:</Typography>
                </Grid>
                <Grid item xs={columnWidths.inputs.xs} sm={columnWidths.inputs.sm} md={columnWidths.inputs.md} className={classes.xOverflowHidden}>
                    {locationOptions.length > 0
                        ? <FormControl variant="standard" className={classes.fullWidth}>
                            <REMSelect
                                id="location-dropdown"
                                value={location}
                                onChange={onLocationChanged}
                                startAdornment={
                                    <InputAdornment position="start" className={classes.icon}>
                                        <MapMarker />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value={0}>
                                    Select a Location
                                </MenuItem>
                                {locationOptions.map((address, index) => {
                                    return (
                                        <MenuItem key={`address-${index}`} value={address.id}>
                                            {address.addressString}
                                        </MenuItem>
                                    );
                                })}
                            </REMSelect>
                        </FormControl>
                        : <Typography>To link this shift to an address, you must first add or select the address from the Locations section.</Typography>
                    }
                    {props.inlineLocationAdding &&
                        <div>
                            <Button
                                color={'primary'}
                                variant={'text'}
                                startIcon={<MapMarkerPlus />}
                                className={classes.addLocationButton}
                                onClick={() => dialogState.setOpen(true)}
                            >
                                Add Location
                            </Button>
                            <AddressDialog
                                state={dialogState}
                                title={"Add Address"}
                                address={newAddress}
                                confirmText={"Add"}
                                confirmIcon={MapMarkerPlus}
                                displayRemoveButton={false}
                                onConfirm={onNewAddressAdded}
                            />
                        </div>
                    }
                </Grid>
            </Grid>
        </div >
    )
});

export default ShiftEntry;