import { observable, action, override, makeObservable } from "mobx";
import { Fields } from "./Fields";
import validateForgotPasswordData from "../../logic/ValidationChecks/ForgotPasswordDataValidation";

export interface IForgotPasswordDataFields {
    email: string;
}

export class ForgotPasswordData extends Fields<IForgotPasswordDataFields, ForgotPasswordData> implements IForgotPasswordDataFields {
    email = "";

    constructor() {
        super();

        makeObservable(this, {
            email: observable,
            setEmail: action,
            validationErrors: override
        });
    }

    /***** Setters *****/

    setEmail(email: string) {
        this.email = email;
    }

    /***** Computed properties *****/

    get validationErrors() {
        return validateForgotPasswordData(this);
    }

    /***** Serialization *****/

    serialize() {
        return this.trimmed;
    }
}