import { Address } from "../stores/models/Address";

export function getExternalUrl(url: string) {
    const regex = /^https?:\/\//;
    const alreadyExternal = regex.test(url);
    if (alreadyExternal) {
        return url;
    } else {
        return `http://${url}`;
    }
}

export function getLinkToGoogleMap(address: Address) {
    const parameters = getEncodedParameters(address);
    return `https://www.google.com/maps/search/?api=1&query=${parameters}`;
}

export function getEmbeddedGoogleMapLink(address: Address) {
    const parameters = getEncodedParameters(address);
    const mode = 'place';
    const apiKey = 'AIzaSyAh3w36kyija3J_yl8MSQI8dk-dFwiynLA';
    const zoom = 11;
    const link = `https://www.google.com/maps/embed/v1/${mode}?key=${apiKey}&q=${parameters}&zoom=${zoom}`;
    return link;
}

function getEncodedParameters(address: Address) {
    const addressObj = (address instanceof Address ? address : new Address(address));
    const parameters = encodeURI(addressObj.addressString);
    return parameters;
}