import { observable, action, override, makeObservable } from "mobx";
import { Fields } from "./Fields";
import validateLoginData from "../../logic/ValidationChecks/LoginDataValidation";
import { ValidationError } from "./ValidationError";

export interface ILoginFields {
    username: string;
    password: string;
}

export class LoginData extends Fields<ILoginFields, LoginData> implements ILoginFields {
    username = "";
    password = "";
    error?: string;

    constructor() {
        super();

        makeObservable(this, {
            username: observable,
            password: observable,
            error: observable,
            setUsername: action,
            setPassword: action,
            setError: action,
            validationErrors: override
        });
    }

    /***** Setters *****/

    setUsername(username: string) {
        this.username = username;
    }

    setPassword(password: string) {
        this.password = password;
    }

    setError(error: string | undefined) {
        this.error = error;
    }

    /***** Computed properties *****/

    get validationErrors(): ValidationError<LoginData>[] {
        return validateLoginData({
            ...this,
            username: this.username.trim()
        });
    }

    serialize() {
        return {
            username: this.username.trim(),
            password: this.password
        }
    }
}