import { action, computed, observable, makeObservable, IReactionDisposer, reaction } from "mobx";
import { format, add, startOfDay, addDays } from "date-fns";
import { validateRepetitionEnding } from "../../logic/ValidationChecks/RepetitionEndingValidation";
import { Fields } from "./Fields";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

export const DEFAULT_NUM_OCCURRENCES = 2;

export enum RepetitionEndType {
    Never = 'Never',
    EndDate = 'EndDate',
    AfterOccurrences = 'AfterOccurrences'
}

export interface IRepetitionEndingFields {
    endType: RepetitionEndType;
    endDate: Date | null;
    numOccurrences: number;
}

export interface IRepetitionEnding {
    endType: RepetitionEndType;
    minimumEndDate: Date;
    endDateDaysFromMinimumEndDate?: number;
    numOccurrences: number;
}

export class RepetitionEnding extends Fields<IRepetitionEndingFields, RepetitionEnding> implements IRepetitionEnding, IRepetitionEndingFields {
    @observable endType = RepetitionEndType.Never;
    @observable endDate: Date | null = add(new Date(), { months: 3 });
    @observable minimumEndDate: Date = startOfDay(new Date());
    @observable numOccurrences = DEFAULT_NUM_OCCURRENCES;

    private instancesChangedReaction?: IReactionDisposer;

    constructor(repetitionEnding?: IRepetitionEnding) {
        super();

        makeObservable(this);

        if (repetitionEnding) {
            this.endType = repetitionEnding.endType;
            this.minimumEndDate = repetitionEnding.minimumEndDate;
            if (this.endType === RepetitionEndType.EndDate && repetitionEnding.endDateDaysFromMinimumEndDate !== undefined) {
                this.endDate = addDays(repetitionEnding.minimumEndDate, repetitionEnding.endDateDaysFromMinimumEndDate);
            } else if (this.endType === RepetitionEndType.AfterOccurrences) {
                this.numOccurrences = repetitionEnding.numOccurrences;
            }
        }

        this.instancesChangedReaction = reaction(
            () => [ this.endType ],
            () => {
                if (this.endType === RepetitionEndType.EndDate) return;
                if ((this.endDate === null) || (this.endDate < this.minimumEndDate)) {
                    this.setEndDate(this.defaultEndDate);
                }
            },
            { fireImmediately: true }
        );
    }

    /***** Setters *****/

    @action setEndType(endType: RepetitionEndType) {
        this.endType = endType;
    }

    @action setEndDate(endDate: Date | null) {
        this.endDate = endDate;
    }

    @action setMinimumEndDate(minimumEndDate: Date) {
        this.minimumEndDate = minimumEndDate;
    }

    @action setNumOccurrences(numOccurrences: number) {
        const updatedOccurrencesCount = isNaN(numOccurrences) ? 0 : numOccurrences;
        this.numOccurrences = updatedOccurrencesCount;
    }

    /***** Computed properties *****/

    @computed private get defaultEndDate() {
        return add(this.minimumEndDate, { months: 3 });
    }

    @computed get description() {
        switch (this.endType) {
            case RepetitionEndType.Never:
                return "";
            case RepetitionEndType.EndDate:
                return this.endDate ? `until ${format(this.endDate, 'MMM d, yyyy')}` : '';
            case RepetitionEndType.AfterOccurrences:
                return `${this.numOccurrences} times`;
        }
    }

    @computed get endDateDaysFromMinimumEndDate() {
        if (this.endDate) {
            return differenceInCalendarDays(this.endDate, this.minimumEndDate);
        }
    }

    @computed get validationErrors() {
        return validateRepetitionEnding(this);
    }
}