import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { StubOrganizationVolunteer } from "../../../../../../stores/models/StubOrganizationVolunteer";

interface EmailFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    volunteer: StubOrganizationVolunteer;
}

const EmailField = observer((props: EmailFieldProps) => {

    /********** Event Handlers *********/

    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value !== undefined) {
            props.volunteer.setEmail(event.target.value);
        }
    }

    /********** Render *********/

    return (
        <TextField
            id="standard-email-input"
            variant="outlined"
            value={props.volunteer.email}
            onChange={onEmailChanged}
            error={props.volunteer.isFieldInvalid('email')}
            helperText={props.volunteer.getErrorForField('email')}
        />
    )
});

export default EmailField;