export function getUniqueElements<T>(array: Array<T>) {
    return array.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
}

export function getUniqueArrayObjectsByProperty<T>(array: Array<T>, key: keyof T) {
    return Array.from(new Map(array.map(item => [item[key], item])).values());
}

export function roundToNumDecimalPlaces(valueToRound: number, numDecimalPlaces: number) {
    let multiplier = 1;
    while (numDecimalPlaces > 0) {
        numDecimalPlaces--;
        multiplier = multiplier * 10;
    }
    return Math.round((valueToRound + Number.EPSILON) * multiplier) / multiplier;
}

export const getSingularOrPluralDescriptor = (count: number, singular: string, plural: string) => {
    return (count === 1) ? singular : plural;
}