import { useCallback } from 'react'
import { IAction } from '../interfaces/Action';
// import { container, InjectionToken } from 'tsyringe' // TODO: Consider using tsyringe

export const useAction = <Param>(action: IAction<Param>) => {
    // export const useAction = <Param>(action: InjectionToken<IAction<Param>>) => {
//   const actionInstance = container.resolve(action)
  const actionInstance = action;

  const handleExecute = useCallback(
    async (param: Param) => {
      await actionInstance.execute(param)
    },
    [actionInstance]
  )

  return handleExecute
}