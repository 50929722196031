

import { makeStyles, Theme, createStyles, TextField, FormControl, FormGroup, FormHelperText } from "@material-ui/core";
import { observer } from "mobx-react";
import { ChangeEvent, DetailedHTMLProps, HTMLAttributes } from "react";
import ElementTitle from "./ElementTitle";
import ElementDescription from "./ElementDescription";
import CheckboxOption from "./CheckboxOption";
import { FormInputItem } from "../../../../stores/models/FormInputItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex'
        },
        itemWithOptions: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(3)
            }
        }
    }),
);

const OTHER_LABEL = 'Other';

interface FormSectionProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    item: FormInputItem;
}

const CheckboxItem = observer((props: FormSectionProps) => {

    const classes = useStyles();

    const onOtherSelectionChanged = (option: string, checked: boolean) => {
        props.item.setOtherSelected(checked);
    }

    const onOtherValueChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        props.item.setOtherValue(value);
        props.item.setOtherSelected(true);
    }

    const onSelectionChanged = (option: string, checked: boolean) => {
        if (checked) {
            props.item.addValue(option);
        } else {
            props.item.removeValue(option);
        }
    }

    /********* Render *********/

    return (
        <div className={classes.itemWithOptions} {...props}>
            <ElementTitle item={props.item} />
            <ElementDescription item={props.item} />
            <FormControl component="fieldset" error={props.item.errors.value ? true : false}>
                <FormHelperText>{props.item.errors.value}</FormHelperText>
                <FormGroup>
                    {(props.item.options as string[])?.map((option, index) => {
                        return (
                            <CheckboxOption
                                key={`item-${props.item.title}-option-${index}`}
                                option={option}
                                onCheckboxClicked={(event, checked) => onSelectionChanged(option, checked)}
                            />
                        )
                    })}
                    {props.item.includeOtherOption &&
                        <div className={classes.flex}>
                            <CheckboxOption
                                option={OTHER_LABEL}
                                selected={props.item.otherSelected}
                                onCheckboxClicked={onOtherSelectionChanged}
                            />
                            <TextField value={props.item.otherValue} onChange={onOtherValueChanged} />
                        </div>
                    }
                </FormGroup>
            </FormControl>
        </div>
    );
});

export default CheckboxItem;