import { useEffect, useState } from 'react';
import { useActionLoadable } from './useActionLoadable';
import { ObjectWithIdentifier } from '../../../../stores/models/NewOptionCollection';
import { LoadRecordListAction } from '../../record-collection/data-access/actions/LoadRecordListAction';

interface ReactiveLoadProps<RecordType extends ObjectWithIdentifier<RecordType>, SortableKeys extends string, ServerRecordType = RecordType, Param = unknown> {
    action: LoadRecordListAction<RecordType, SortableKeys, ServerRecordType, Param>;
    param: Param;
    reloadNeeded: boolean;
    setReloadNeeded: (reloadNeeded: boolean) => void;
}

export default function useReactiveLoad<Param extends object, RecordType extends ObjectWithIdentifier<RecordType>, SortableKeys extends string, ServerRecordType = RecordType>(props: ReactiveLoadProps<RecordType, SortableKeys, ServerRecordType, Param>) {
    const [load, isLoading] = useActionLoadable(props.action);
    const [lastLoadParam, setLastLoadParam] = useState<string>();

    const offset = props.action.loadRequest.offset;
    const limit = props.action.loadRequest.limit;

    const loadParamChanged = () => {
        return (lastLoadParam !== JSON.stringify(props.param));
    }

    const loadRecords = (resetRecords?: boolean) => {
        load({
            ...props.param,
            resetRecords: resetRecords !== undefined ? resetRecords : loadParamChanged()
        });
    }

    useEffect(() => {
        if (props.action.requestingUnloadedRecords || loadParamChanged()) {
            loadRecords()
        }
        setLastLoadParam(JSON.stringify(props.param));
    }, [offset, limit, JSON.stringify(props.action.loadRequest.sort), props.param]);


    // From LoadTableData.ts, not needed yet:

    // useEffect(() => {
    //     reload();
    // }, [JSON.stringify(tableController.tableState.loadRequest.filterState?.filters)])

    // useDebouncedEffect({
    //     trigger: tableController.tableState.loadRequest.filterState?.searchQuery,
    //     effect: () => {
    //         if (initialLoad) {
    //             setInitialLoad(false);
    //         } else {
    //             reload();
    //         }
    //     }
    // });

    useEffect(() => {
        if (props.reloadNeeded) {
            props.setReloadNeeded(false);
            loadRecords(true);
        }
    }, [props.reloadNeeded]);

    return isLoading;
}