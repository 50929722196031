import React, { useEffect } from "react";
import { observer } from "mobx-react";
import PaymentEntry from "../../../Shared/PaymentEntry";
import { Button, makeStyles, Theme, createStyles, Typography, Card } from "@material-ui/core";
import { StepProps } from "../../../../stores/models/Step";
import { StubUser } from "../../../../stores/models/StubUser";
import TermsOfServiceAcceptance from "../Shared/TermsOfServiceAcceptance";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        title: {
            marginBottom: theme.spacing(3)
        },
        content: {
            maxWidth: '500px'
        },
        payButton: {
            textTransform: 'none',
            margin: 'auto',
            marginTop: theme.spacing(1),
            fontSize: theme.typography.subtitle1.fontSize
        },
        flex: {
            display: 'flex'
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 1,
            textAlign: 'left',
            '&:not(:last-child)': {
                marginBottom: theme.spacing(2)
            }
        },
        bold: {
            fontWeight: 'bold'
        },
        card: {
            marginBottom: theme.spacing(3),
            background: 'rgba(0,0,0,0.04)',
            border: `1px solid ${theme.palette.action.disabledBackground}`
        },
        orderSummary: {
            padding: theme.spacing(3),
        },
        termsOfService: {
            color: theme.palette.text.secondary
        }
    }),
);

const SubscriptionPayment = observer((props: StepProps<StubUser>) => {

    /***** Helper constant *****/

    const stubUser = props.stepState.stepObject;
    const stubOrganization = stubUser.stubOrganization!;
    const subscriptionPlan = stubOrganization.subscriptionPlan!;

    /***** React hooks *****/

    const classes = useStyles();

    /***** Effects *****/

    useEffect(() => {
        return function cleanup() {
            stubUser.refreshPaymentMethod();
        }
    }, []);

    useEffect(() => {
        if (props.stepState.validationRun) {
            // After validation is run, mark the fields as dirty so that errors will be displayed
            stubUser.paymentMethod.setAllFieldsDirty();
            stubUser.setFieldsDirty(['termsOfServiceAccepted']);
        }
    }, [props.stepState.validationRun]);

    /***** Render *****/

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Card elevation={0} className={classes.card}>
                    <div>
                        <div className={classes.orderSummary}>
                            <Typography variant="h5" className={classes.title}>Order Summary</Typography>
                            <div className={classes.row}>
                                <div>
                                    <Typography className={classes.bold}>{`${subscriptionPlan.name} Subscription`}</Typography>
                                    <Typography color="textSecondary">
                                        {`Active recruitment cities: ${subscriptionPlan.features.cityLimitation}`}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        {`Remote opportunities: ${subscriptionPlan.features.remotePosts}`}
                                    </Typography>
                                </div>
                                <Typography>{`${subscriptionPlan.price}/month`}</Typography>
                            </div>
                            <div className={classes.row}>
                                <Typography className={classes.bold}>Due Today</Typography>
                                <Typography className={classes.bold}>{subscriptionPlan.price}</Typography>
                            </div>
                        </div>
                    </div>
                </Card>
                <PaymentEntry
                    paymentMethod={stubUser.paymentMethod}
                    displayErrors={props.stepState.validationRun}
                    isLoading={props.stepState.loading}
                />
                <div className={classes.termsOfService}>
                    <TermsOfServiceAcceptance
                        checked={stubUser.termsOfServiceAccepted}
                        onChange={(accepted) => stubUser.setTermsOfServiceAccepted(accepted)}
                        error={props.stepState.validationRun ? stubUser.getErrorForField('termsOfServiceAccepted') : undefined}
                        organizationTerms
                    />
                </div>
                <div className={classes.flex}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.payButton}
                        disabled={props.stepState.loading}
                        type="submit"
                    >
                        Subscribe
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default SubscriptionPayment;