import { Fragment } from "react";
import { Typography, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import FieldHeader from "../../../../Shared/FieldHeader";
import { ReportData, ReportNames } from "../../../../../stores/models/ReportData";
import { Download } from "mdi-material-ui";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import { getReportPageLink } from "../../../../Navigation/Links";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        viewButton: {
            marginRight: theme.spacing(1)
        },
        bold: {
            fontWeight: 'bold'
        },
        spacer: {
            height: theme.spacing(1)
        }
    }),
);

interface ReportingSummarySectionProps {
    report: ReportNames;
    reportData: ReportData | undefined;
    onDownload: (report: ReportNames) => void;
}

const ReportSummarySection = observer((props: ReportingSummarySectionProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const navigateInternally = useNavigateInternally();

    /********* State *********/

    const { report, reportData, onDownload } = props;

    /********* Render *********/

    return (
        <Fragment>
            <FieldHeader title={ReportData.reportTitleByType[report]} />
            {reportData?.summaryStatsByReport[report].map((summaryStats, index) => {
                return <Typography key={`label-${index}`}><span className={classes.bold}>{summaryStats.label}:</span> {summaryStats.data || "-"}</Typography>
            })}
            <div className={classes.spacer} />
            <div className={classes.flex}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigateInternally(getReportPageLink(ReportData.reportUrlSlugByName[report]))}
                    className={classes.viewButton}
                >
                    View
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => reportData ? onDownload(report) : undefined}
                    startIcon={<Download />}
                >
                    Download
                </Button>
            </div>
        </Fragment>
    )
});

export default ReportSummarySection;