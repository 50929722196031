import React from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography, Avatar } from "@material-ui/core";
import { Check, Close, Email } from "mdi-material-ui";
import RadioList, { RadioListOption } from "../../Organization/VolunteerOpportunities/RadioList";
import { EmailPreference } from "../../../stores/models/OrganizationStubVolunteerLinking";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginBottom: theme.spacing(1),
            maxWidth: 700,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        radioListWrapper: {
            '& > ul': {
                display: 'flex',
                justifyContent: 'center'
            }
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
    })
);

const emailPreferenceOptions: RadioListOption[] = [{
    primaryText: 'Yes please',
    value: EmailPreference.EmailMe,
    icon: Check
}, {
    primaryText: 'No thank you',
    value: EmailPreference.RemoveMe,
    icon: Close
}];

interface EmailPreferenceSelectionProps {
    organizationName: string;
    emailPreference: EmailPreference;
    onEmailPreferenceChanged: (emailPreference: EmailPreference) => void;
}

const EmailPreferenceSelection = observer((props: EmailPreferenceSelectionProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <Email fontSize='large' />
            </Avatar>
            <Typography variant="h5" className={classes.prompt}>
                Would you like to receive email notifications relevant to this volunteer's involvement at {props.organizationName}?
            </Typography>
            <div className={classes.radioListWrapper}>
                <RadioList
                    selectedValue={props.emailPreference}
                    options={emailPreferenceOptions}
                    onValueChanged={(value) => props.onEmailPreferenceChanged(value as EmailPreference)}
                />
            </div>
        </React.Fragment>
    );
});

export default EmailPreferenceSelection;