import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Theme, createStyles, SwipeableDrawer, IconButton, Typography, useMediaQuery, Badge } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { MenuItem, MenuOption } from "../../stores/models/MenuItem";
import MenuSidebarList from "../Shared/MenuSidebar/MenuSidebarList";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { RootContext } from "../../stores";
import { Domain } from "mdi-material-ui";
import useMenuItems from "./MainMenuItemsHook";
import { getAccountSettingsLink } from "./Links/UrlConstructors";
import { MenuState } from "../../stores/models/MenuState";
import { MOBILE_MENU_BREAKPOINT } from "./NavigationBar";
import { useNavigateInternally, useOrgPublicPageLink } from "./Hooks";
import REMAvatar from "../../shared/modules/rem-conversion/components/Avatar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobileMenu: {
            color: '#474747'
        },
        drawerPaper: {
            background: theme.palette.darkBackground.main,
            display: 'flex'
        },
        header: {
            color: theme.palette.darkBackground.main,
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(2),
            position: 'relative',
            maxWidth: '250px'
        },
        avatar: {
            borderWidth: '2px',
            border: 'solid',
            borderColor: theme.palette.lightBackground.main,
            color: theme.palette.lightBackground.main,
            background: theme.palette.darkBackground.main,
            zIndex: 10,
            position: 'absolute',
        },
        nameBackground: {
            marginLeft: '1.5rem',
            marginRight: theme.spacing(2),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(3),
            background: theme.palette.lightBackground.main,
            borderRadius: '1rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        nameTypography: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    })
);

const MobileMenu = observer(() => {

    /*****************  React hooks  *****************/

    const classes = useStyles();
    const navigate = useNavigateInternally();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const menuItems = useMenuItems();
    const mobileMenuEnabled = useMediaQuery((theme: Theme) => theme.breakpoints.down(MOBILE_MENU_BREAKPOINT - 1));
    const location = useLocation();
    const orgPublicPageLink = useOrgPublicPageLink();

    /*****************  State  *****************/

    const [drawerState] = useState(new MenuState());
    const [searchMenuItem, setSearchMenuItem] = useState<MenuItem>();
    const [sidebarMenuItems, setSidebarMenuItems] = useState<MenuItem[]>([]);

    /*****************  Effects  *****************/

    useEffect(() => {
        // Close the drawer if the desktop menu can be displayed
        if (!mobileMenuEnabled) {
            drawerState.setOpen(false);
        }
    }, [mobileMenuEnabled]);

    useEffect(() => {
        // Close the drawer when the user or current path changes
        drawerState.setOpen(false);
    }, [location.pathname, userStore.user]);

    useEffect(() => {
        // Update the menu items
        let sidebarItems = [] as MenuItem[];
        let searchItem = undefined;
        for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i].option === MenuOption.Search) {
                searchItem = menuItems[i];
            } else {
                sidebarItems.push(menuItems[i]);
            }
        }
        setSearchMenuItem(searchItem);
        setSidebarMenuItems(sidebarItems);
    }, [menuItems]);

    /*****************  Event Handlers  *****************/

    const handleHeaderClicked = () => {
        if (userStore.user.isOrganization) {
            navigate(orgPublicPageLink);
        } else {
            navigate(getAccountSettingsLink());
        }
    }

    const handleMenuButtonClicked = () => {
        drawerState.setOpen(!drawerState.open);
    }

    const handleMenuItemClicked = (menuItem: MenuItem) => {
        if (menuItem.onClick) {
            menuItem.onClick();
        }
        if (menuItem.fullLink) {
            navigate(menuItem.fullLink);
        }
    }

    /*****************  Render  *****************/

    return (
        <div className={classes.mobileMenu}>
            {/* Search Button */}
            {searchMenuItem && searchMenuItem.icon
                ? <IconButton color="inherit" onClick={() => handleMenuItemClicked(searchMenuItem)}>
                    {React.createElement(searchMenuItem.icon)}
                </IconButton>
                : null
            }
            {/* Menu Button */}
            <IconButton onClick={handleMenuButtonClicked} color="inherit">
                <Badge badgeContent={rootStore.clientHasUpdates ? 1 : undefined} color='error'>
                    <MenuIcon />
                </Badge>
            </IconButton>
            {/* Menu Drawer */}
            <SwipeableDrawer
                anchor={'right'}
                open={drawerState.open}
                onClose={() => drawerState.setOpen(false)}
                onOpen={() => drawerState.setOpen(true)}
                classes={{ paper: classes.drawerPaper }}
            >
                {/* Header */}
                {userStore.isAuthenticated
                    ? <div className={classes.header} onClick={handleHeaderClicked}>
                        <REMAvatar className={classes.avatar}>
                            {userStore.user.isOrganization ? <Domain /> : null}
                        </REMAvatar>
                        <div className={classes.nameBackground}>
                            <Typography variant="h6" className={classes.nameTypography}>
                                {userStore.user.name}
                            </Typography>
                        </div>
                    </div>
                    : null
                }
                {/* Menu */}
                <MenuSidebarList menuItems={sidebarMenuItems} />
            </SwipeableDrawer>
        </div >
    )
});

export default MobileMenu;