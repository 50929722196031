import { Theme, Typography, useMediaQuery, ButtonProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { Alert, AlertTitle } from "@material-ui/lab";
import ThemedDialogWithSpinner from "./ThemedDialogWithSpinner";
import { DialogState } from "../../../stores/models/DialogState";

interface ErrorDialogProps {
    state: DialogState;
    dialogTitle: string;
    errorTitle?: string;
    errorDetails: string | { message: string } | JSX.Element;
    onAcknowledge?: () => void;
}

const ErrorDialog = observer((props: ErrorDialogProps) => {

    /********* React hooks *********/
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            title={props.dialogTitle}
            onCancel={props.onAcknowledge}
            DialogProps={{ fullScreen: xsDown }}
            cancelText={'Okay'}
        >
            <Alert severity="error">
                {props.errorTitle && <AlertTitle>{props.errorTitle}</AlertTitle>}
                {typeof props.errorDetails === 'string'
                    ? <Typography>
                        {props.errorDetails}
                    </Typography>
                    : 'message' in props.errorDetails
                        ? <Typography>
                            {props.errorDetails.message}
                        </Typography>
                        : props.errorDetails
                }
            </Alert>
        </ThemedDialogWithSpinner>
    );
});

export default ErrorDialog;