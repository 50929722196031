import { Alert, AlertProps, AlertTitle } from "@material-ui/lab";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formError: {
            marginBottom: theme.spacing(2),
        }
    }));

type FormErrorProps = Partial<AlertProps>;

const FormError = (props: FormErrorProps) => {

    const classes = useStyles();

    return (
        <Alert severity="error" {...props} className={clsx(classes.formError, props.className)}>
            <AlertTitle>Oops! There was an error with your submission.</AlertTitle>
            Please scroll down to make corrections.
        </Alert>
    );
}

export default FormError;