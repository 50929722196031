import { ObjectWithIdentifier } from "../../../../../stores/models/NewOptionCollection";
import { RecordListStore } from "../stores/RecordListStore";
import { APIAction } from "./APIAction";

export abstract class UpsertRecordListAction<
    RecordType extends ObjectWithIdentifier<RecordType>,
    ServerRecordType = RecordType,
    Param = unknown
> extends APIAction<RecordType, Extract<keyof RecordType, string>, ServerRecordType, Param> {

    constructor(store: RecordListStore<RecordType>) {
        super(store);
    }

    abstract upsertRecord(param: Param): Promise<ServerRecordType | ServerRecordType[] | undefined>;

    async makeAPICall(param: Param) {
        let response = await this.upsertRecord(param);
        if (response) {
            const records = Array.isArray(response) ? this.transformData(response) : this.transformData([response]);
            records.forEach(record => {
                this.store.collection.addOrUpdateOption(record);
            });
            this.store.setReloadNeeded(true);
        }
    }

}
