import { addDays, endOfDay, endOfMonth, endOfWeek, getDay, getWeeksInMonth, startOfDay, startOfMonth, startOfWeek, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { Dictionary } from "../../../logic/Dictionaries";
import { CalendarViewMode } from "./CalendarLogicHelpers";

export const DAYS_OF_THE_WEEK = [{
    full: 'Sunday',
    abbreviation: 'Sun'
}, {
    full: 'Monday',
    abbreviation: 'Mon'
}, {
    full: 'Tuesday',
    abbreviation: 'Tue'
}, {
    full: 'Wednesday',
    abbreviation: 'Wed'
}, {
    full: 'Thursday',
    abbreviation: 'Thu'
}, {
    full: 'Friday',
    abbreviation: 'Fri'
}, {
    full: 'Saturday',
    abbreviation: 'Sat'
}];

export const getFirstDateToDisplayOnCalendar = (date: Date, viewMode: CalendarViewMode) => {
    switch (viewMode) {
        case CalendarViewMode.Month:
            const startOfCurrentMonth = startOfMonth(date);
            const dayOfWeekOfStartOfMonth = getDay(startOfCurrentMonth);
            return dayOfWeekOfStartOfMonth === 0 ? startOfCurrentMonth : subDays(startOfCurrentMonth, dayOfWeekOfStartOfMonth);
        case CalendarViewMode.Week:
            return startOfWeek(date);
        case CalendarViewMode.Day:
            return startOfDay(date);
    }
}

export const getLastDateToDisplayOnCalendar = (date: Date, viewMode: CalendarViewMode) => {
    switch (viewMode) {
        case CalendarViewMode.Month:
            const endOfCurrentMonth = endOfMonth(date);
            const dayOfWeekOfEndOfMonth = getDay(endOfCurrentMonth);
            return dayOfWeekOfEndOfMonth === 6 ? endOfCurrentMonth : addDays(endOfCurrentMonth, 6 - dayOfWeekOfEndOfMonth);
        case CalendarViewMode.Week:
            return endOfWeek(date);
        case CalendarViewMode.Day:
            return endOfDay(date);
    }
}

const getDatesForCalendarMonth = (date: Date) => {
    const numWeeks = getWeeksInMonth(date);
    let datesToDisplay: Dictionary<number, Date[]> = {};
    let nextDateToDisplay = getFirstDateToDisplayOnCalendar(date, CalendarViewMode.Month);
    for (let week = 1; week <= numWeeks; week++) {
        let days = [] as Date[];
        DAYS_OF_THE_WEEK.forEach((day) => {
            days.push(nextDateToDisplay);
            nextDateToDisplay = addDays(nextDateToDisplay, 1);
        });
        datesToDisplay[week] = days;
    }
    return datesToDisplay;
}

const getDatesToDisplayForViewMode = (date: Date, viewMode: CalendarViewMode) => {
    switch (viewMode) {
        case CalendarViewMode.Month:
            return getDatesForCalendarMonth(date);
        default:
            return {};
    }
}

export default function useDatesToDisplay(date: Date, viewMode: CalendarViewMode) {

    const [datesToDisplay, setDatesToDisplay] = useState<Dictionary<number, Date[]>>({});

    useEffect(() => {
        setDatesToDisplay(getDatesToDisplayForViewMode(date, viewMode));
    }, [date]);

    return datesToDisplay;
}