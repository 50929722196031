import { TextField, makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { StepProps } from "../../../../stores/models/Step";
import { StubOrganization, StubOrganizationValidationChecks } from "../../../../stores/models/StubOrganization";
import TextFieldWithCharacterLimit from "../../../Shared/TextFieldWithCharacterLimit";
import { RootContext } from "../../../../stores";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
            width: '40ch',
            [theme.breakpoints.down('xs')]: {
                flexGrow: 1,
                width: 'auto'
            }
        },
        title: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(3)
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                alignItems: 'center'
            }
        },
        link: {
            color: theme.palette.primary.main,
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        },
    }),
);

const OrganizationIdentification = observer((props: StepProps<StubOrganization>) => {

    const stubOrganization = props.stepState.stepObject;

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);

    /***** Effects *****/

    useEffect(() => {
        // Update which validation checks should be run for the stub organization
        stubOrganization.setValidationChecks(StubOrganizationValidationChecks.OrganizationIdentity);
    }, []);

    useEffect(() => {
        if (props.stepState.validationRun) {
            // After validation is run, mark the fields as dirty so that errors will be displayed
            stubOrganization.setFieldsDirty(['name', 'ein', 'signUpCode']);
        }
    }, [props.stepState.validationRun]);

    /***** Event handlers *****/

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        stubOrganization.setName(event.target.value);
    }

    const handleEINChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        stubOrganization.setEIN(event.target.value);
    }

    const handleSignUpCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        stubOrganization.setSignUpCode(event.target.value);
    }

    const handleSignUpCodeRequest = () => {
        rootStore.navigationStore.appwideDialogStates.contactUsDialog.setOpen(true);
        rootStore.helpStore.setHelpMessageOverrides({
            subject: 'Sign Up Code Request',
        });
    };

    /***** Helper constants *****/

    const validationRun = props.stepState.validationRun;
    const nameError = validationRun ? stubOrganization.errors.name : '';
    const einError = validationRun ? stubOrganization.errors.ein : '';
    const signUpCodeError = validationRun ? stubOrganization.errors.signUpCode : '';

    /***** Render *****/

    return (
        <div>
            <Typography variant="h5" className={classes.title}>Enter your organization's details to get started.</Typography> {/* // TODO: Adjust wording after demo */}
            <div className={classes.flex}>
                <TextFieldWithCharacterLimit
                    characterLimit={150}
                    TextFieldProps={{
                        id: "standard-name-input",
                        label: "Organization Name",
                        variant: "outlined",
                        required: true,
                        value: stubOrganization.name,
                        onChange: handleNameChange,
                        autoFocus: true,
                        className: classes.textField,
                        error: nameError.length > 0,
                        helperText: nameError.length > 0 ? nameError : undefined
                    }}
                />
                {stubOrganization.isForProfit
                    ? null
                    : <TextField
                        id="standard-ein-input"
                        label="EIN"
                        variant="outlined"
                        required
                        value={stubOrganization.ein}
                        onChange={handleEINChange}
                        className={classes.textField}
                        error={einError.length > 0}
                        helperText={einError.length > 0 ? einError : undefined}
                        inputProps={{ maxLength: 9 }}
                    />
                }
                <TextField
                    id="sign-up-code"
                    label="Sign Up Code"
                    variant="outlined"
                    required
                    value={stubOrganization.signUpCode}
                    onChange={handleSignUpCodeChange}
                    className={classes.textField}
                    error={signUpCodeError.length > 0}
                    helperText={signUpCodeError.length > 0
                        ? signUpCodeError
                        : <Typography variant="caption">
                            Contact us to <span onClick={handleSignUpCodeRequest} className={classes.link}>request a sign up code</span> if you don't already have one.
                        </Typography>}
                />
            </div>
        </div>
    );
});

export default OrganizationIdentification;