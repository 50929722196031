import { Dialog, DialogProps } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { DialogState } from "../../../stores/models/DialogState";
import FormLoadingSpinner from "../../Organization/VolunteerOpportunities/FormLoadingSpinner";

interface DialogWithLoadingSpinnerProps {
    state: DialogState;
    onClose?: () => void;
    DialogProps?: Partial<DialogProps>;
    children?: React.ReactNode;
}

const DialogWithLoadingSpinner: FunctionComponent<DialogWithLoadingSpinnerProps> = observer((props) => {

    const handleClose = () => {
        if (!props.state.loading) {
            if (props.onClose !== undefined) {
                props.onClose();
            }
            props.state.setOpen(false);
        }
    }

    return (
        <Dialog open={props.state.open} onClose={handleClose} {...props.DialogProps}>
            {props.children}
            {props.state.loading ? <FormLoadingSpinner /> : null}
        </Dialog>
    );
});

export default DialogWithLoadingSpinner;