import { observable, computed, makeObservable } from "mobx";
import CauseIconDictionary from "../../data/CauseIcons";

export interface ICause {
    id: number;
    cause: string;
}

export class Cause implements ICause {
    id: number;
    cause: string;

    constructor(idOrCause: number | ICause, cause?: string) {
        makeObservable(this, {
            id: observable,
            cause: observable,
            icon: computed
        });

        if (typeof idOrCause === 'number') {
            this.id = idOrCause;
            this.cause = cause || '';
        } else {
            this.id = idOrCause.id;
            this.cause = idOrCause.cause;
        }
    }

    get icon() {
        return CauseIconDictionary[this.id];
    }
}