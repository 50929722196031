import { observer } from 'mobx-react'
import { Route, Routes } from 'react-router-dom';
import EmailVerification from '../../Authentication/EmailVerification';
import LinkVolToOrgStepSequencer from '../../Authentication/LinkVolunteerToOrganizationSteps/LinkVolToOrgStepSequencer';
import PasswordReset from '../../Authentication/PasswordReset';
import OrganizationSignUp from '../../Authentication/RegistrationSteps/Organization/OrganizationSignUp';
import PrivacyPolicyPage from '../../Authentication/RegistrationSteps/Shared/PrivacyPolicyPage';
import TermsOfServicePage from '../../Authentication/RegistrationSteps/Shared/TermsOfServicePage';
import EmailPreferencesPage from '../../EmailPreferences/EmailPreferencesPage';
import VolunteerHelpPage from '../../HelpPage/VolunteerHelpPage';
import OrganizationProfile from '../../Organization/OrganizationProfile';
import StandaloneForm from '../../Shared/Forms/StandaloneForm/StandaloneForm';
import { getOrganizationRegistrationPath, buildPath, TopLevelPathSegments, URITokens, OrgPagePathSegments } from '../Links/UrlConstructors';
import AccountSettings from '../../AccountSettings/AccountSettings';
import Home from '../../HomePage/Home';
import Search from '../../Search/Search';
import { NavigateInternally } from '../Components';
import LinkRedirecter from '../LinkRedirecter';
import ContactUs from '../../ContactUs/ContactUs';

const TopLevelRoutes = observer(() => {

    /********* Render *********/

    return (
        <Routes>
            <Route path={buildPath(TopLevelPathSegments.Search)} element={<Search />} />
            <Route path={buildPath([TopLevelPathSegments.OrganizationPage, URITokens.OrganizationId, "*"])} element={<OrganizationProfile />} />
            <Route path={buildPath([TopLevelPathSegments.OrganizationPage, URITokens.OrganizationId, OrgPagePathSegments.Registration])} element={<StandaloneForm />} /> 
            <Route path={buildPath([TopLevelPathSegments.AccountSettings, '*'])} element={<AccountSettings />} />
            <Route path={buildPath([TopLevelPathSegments.PasswordReset, URITokens.ResetToken])} element={<PasswordReset />} />
            <Route path={buildPath([TopLevelPathSegments.LinkVolunteerToOrg, URITokens.StubVolunteerToken])} element={<LinkVolToOrgStepSequencer />} />
            <Route path={buildPath(TopLevelPathSegments.OrganizationAccountSignUp)} element={<OrganizationSignUp />} />
            <Route path={buildPath(TopLevelPathSegments.PrivacyPolicy)} element={<PrivacyPolicyPage />} />
            <Route path={buildPath(TopLevelPathSegments.TermsOfService)} element={<TermsOfServicePage />} />
            <Route path={buildPath(TopLevelPathSegments.VolunteerHelp)} element={<VolunteerHelpPage />} />
            <Route path={buildPath(TopLevelPathSegments.EmailPreferences)} element={<EmailPreferencesPage />} />
            <Route path={buildPath(TopLevelPathSegments.EmailVerification)} element={<EmailVerification />} />
            <Route path={buildPath([TopLevelPathSegments.Redirecter, '*'])} element={<LinkRedirecter />} />
            <Route path={buildPath([TopLevelPathSegments.ContactUs])} element={<ContactUs />} />
            <Route path={"/"} element={<Home />} />
            <Route path={"/*"} element={<NavigateInternally to={"/"} replace />} />
        </Routes>
    );
});

export default TopLevelRoutes;