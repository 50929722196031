

import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import FormElement from "../FormElement";
import { FormSection as FormSectionType } from "../../../../stores/models/FormSection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            marginTop: theme.spacing(3),
            '& > :not(:first-child)': {
                marginTop: theme.spacing(1)
            }
        },
        sectionTitle: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: theme.spacing(-3, -3, 1, -3),
            padding: theme.spacing(1.5, 3)
        },
    }),
);

interface FormSectionProps {
    section: FormSectionType;
}

const FormSection = observer((props: FormSectionProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <div className={classes.section}>
            <Typography variant="h5" className={classes.sectionTitle}>{props.section.title}</Typography>
            {props.section.items.map((item, index) => {
                return (
                    <FormElement
                        item={item}
                        key={`section-${props.section.title}-item-${index}`}
                    />
                )
            })}
        </div>
    );
});

export default FormSection;