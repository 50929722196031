import { makeStyles, Theme, createStyles } from "@material-ui/core";
import FormLoadingSpinner from "../Organization/VolunteerOpportunities/FormLoadingSpinner";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 15
        }
    }),
);

const CenteredLoadingSpinner = () => {

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <FormLoadingSpinner />
        </div>
    );
}

export default CenteredLoadingSpinner;