import React from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography, Avatar } from "@material-ui/core";
import { ScriptText } from "mdi-material-ui";
import TermsOfServiceAcceptance from "../RegistrationSteps/Shared/TermsOfServiceAcceptance";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginBottom: theme.spacing(1),
            maxWidth: 700,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
        agreement: {
            display: 'flex',
            flexDirection: 'column',
            '& > ${center}': {
                justifyContent: 'start'
            }
        },
    })
);

interface TermsOfServicePromptProps {
    agreedToTerms: boolean;
    onAcceptanceChanged: (agreedToTerms: boolean) => void;
}

const TermsOfServicePrompt = observer((props: TermsOfServicePromptProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <ScriptText fontSize='large' />
            </Avatar>
            <Typography variant="h5" className={classes.prompt}>
                Great! Just one last step. Please review the Terms of Service, and we'll keep you connected!
            </Typography>
            <div className={classes.agreement}>
                <TermsOfServiceAcceptance
                    checked={props.agreedToTerms}
                    onChange={props.onAcceptanceChanged}
                />
            </div>
        </React.Fragment>
    );
});

export default TermsOfServicePrompt;