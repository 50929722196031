import {
    RadioGroup,
    List,
    ListItem,
    ListItemText,
    SvgIconProps,
    makeStyles,
    Theme,
    createStyles,
    FormHelperText,
    FormControl
} from "@material-ui/core";
import React, { useState } from "react";
import clsx from 'clsx';
import REMAvatar from "../../../shared/modules/rem-conversion/components/Avatar";
import REMListItemAvatar from "../../../shared/modules/rem-conversion/components/ListItemAvatar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            '&:hover $avatar': {
                backgroundColor: theme.palette.action.disabled
            },
            '&:hover $selected, $selected': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            }
        },
        selected: { },
        avatar: {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
    }),
);

export interface RadioListOption {
    primaryText: string;
    secondaryText?: string;
    value: string;
    icon: (props: SvgIconProps) => JSX.Element;
}

interface RadioListProps {
    selectedValue: any;
    options: RadioListOption[];
    error?: string;
    onValueChanged: (newValue: string) => void;
}

const RadioList = (props: RadioListProps) => {

    const classes = useStyles();

    const [selectedValue, setSelectedValue] = useState(props.selectedValue);

    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        updateSelectedValue(value);
    }

    const onListItemClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
        updateSelectedValue(value);
    }

    const updateSelectedValue = (value: string) => {
        setSelectedValue(value);
        props.onValueChanged(value);
    }

    return (
        <List>
            <RadioGroup value={selectedValue} onChange={onValueChanged}>
                {props.options.map((option, index) => {
                    return (
                        <ListItem
                            key={`radio-list-${index}`}
                            button={true}
                            onClick={(event) => onListItemClicked(event, option.value)}
                            className={classes.listItem}
                        >
                            <REMListItemAvatar>
                                <REMAvatar className={clsx(classes.avatar, option.value === selectedValue ? classes.selected : undefined)}>
                                    {React.createElement(option.icon)}
                                </REMAvatar>
                            </REMListItemAvatar>
                            <ListItemText primary={option.primaryText} secondary={option.secondaryText} />
                        </ListItem>
                    );
                })}
            </RadioGroup>
            <FormControl component="fieldset" error={props.error ? true : false}>
                <FormHelperText>{props.error}</FormHelperText>
            </FormControl>
        </List>
    )
}

export default RadioList;