import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { KeyboardTimePicker as MUIKeyboardTimePicker, KeyboardTimePickerProps as MUIKeyboardTimePickerProps } from "@material-ui/pickers";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            '& .MuiToolbar-gutters': {
                paddingRight: '1.5rem'
            }
        },
    })
);

interface KeyboardTimePickerProps extends MUIKeyboardTimePickerProps {

}

const REMKeyboardTimePicker = observer((props: KeyboardTimePickerProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIKeyboardTimePicker
            {...props}
            PopoverProps={{
                className: classes.popover
            }}
        >
            {props.children}
        </MUIKeyboardTimePicker>
    );
});

export default REMKeyboardTimePicker;