import React, { useContext, useState } from "react";
import { Address } from "../../stores/models/Address";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { RootContext } from "../../stores";
import clsx from 'clsx';
import { getEmbeddedGoogleMapLink } from "../../logic";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        map: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        iframe: {
            border: 0,
            height: '100%'
        }
    })
);

interface MapProps extends React.HTMLAttributes<HTMLDivElement> {
    address: Address;
}

const Map = (props: MapProps) => {

    const classes = useStyles();
    // TODO: Regenerate API key and proxy this request to avoid exposing it
    // const mapsEmbedUrl = `http://127.0.0.1:3001/api/maps?parameters=${encodedParams}`;
    // const mode = 'place';
    // const encodedParams = encodeURI(parameters);
    // return 
    const mapsEmbedUrl = getEmbeddedGoogleMapLink(props.address);
    // const mapsEmbedUrl = `https://www.google.com/maps/embed/v1/${mode}?key=AIzaSyAh3w36kyija3J_yl8MSQI8dk-dFwiynLA&q=${encodedParams}&zoom=11`;
    // const mapsEmbedUrl = 'https://www.google.com/maps/embed?pb=!1m4!1m3!1i15!2i8250!3i12024!1m4!1m3!1i15!2i8251!3i12024!2m3!1e0!2sm!3i512226652!2m31!1e2!2sspotlight!5i1!8m27!1m2!12m1!20e1!2m6!1s0x88065486b3c17361%3A0x8883ae32de78187c!2sCanopy+Center+2445+Darwin+Road+Suite+15+Madison%2C+WI+53704!4m2!3d43.1284185!4d-89.34933339999999!5e0!11e11!13m12!2sa!14b1!18m5!5b0!6b0!9b1!12b1!16b0!22m3!6e2!7e3!8e2!14b1!19u14!19u29!3m12!2sen-US!3sUS!5e289!12m4!1e68!2m2!1sset!2sRoadmap!12m3!1e37!2m1!1ssmartmaps!4e3!12m1!5b1&client=google-maps-embed&token=102051';
    // const mapsEmbedUrl = `https://www.google.com/maps/search/?api=1&query=${encodedParams}&query_place_id=${4}`;
    // const mapsEmbedUrl = `https://maps.google.com/maps?output=embed&amp;width=100%&amp;height=600&amp;hl=en&amp;q=${encodedParams}&amp;ie=UTF8&amp;t=&amp;z=11&amp;iwloc=B`

    const [map, setMap] = useState(undefined);

    const mapStore = useContext(RootContext).mapStore;

    // useEffect(() => {

    // mapStore.getMap(mapsEmbedUrl, props.address)
    //     .then((result) => {
    //         console.log('result');
    //         console.log(result);
    //         if (result) {
    //             setMap(result);
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // }
    // }, [addresses]);

    return (
        <div
            className={clsx(classes.map, props.className)}
            // dangerouslySetInnerHTML={map ? map : undefined}
        >
            {/* {map} */}
            <iframe
                className={classes.iframe}
                frameBorder="0"
                // style={{ border: '0' }}
                src={mapsEmbedUrl}
                allowFullScreen
                // srcDoc={map}
                // referrerPolicy={'origin'}
            >
            </iframe>
        </div>
    )
};

export default Map;