import { Method } from "axios";
import { computed, makeObservable } from "mobx";
import { RequestQueue } from "../../../stores";
import { Dictionary } from "../../../logic/Dictionaries";
import { IRequestConfig } from "../../../stores/models/RequestQueue";

export abstract class APIService<RequestTypes extends string> {
    private requestQueue: RequestQueue;

    constructor(requestQueue: RequestQueue) {
        makeObservable(this);

        this.requestQueue = requestQueue;
    }

    @computed protected get getRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get putRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get postRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get deleteRequests(): RequestTypes[] {
        return [];
    }

    @computed protected get requestURLs(): Dictionary<string, (string | ((data: any) => string))> {
        return {};
    }

    private getRequestMethod(type: RequestTypes): Method {
        if (this.getRequests.findIndex(getRequest => getRequest === type) !== -1) {
            return 'get';
        } else if (this.putRequests.findIndex(putRequest => putRequest === type) !== -1) {
            return 'put';
        } else if (this.postRequests.findIndex(postRequest => postRequest === type) !== -1) {
            return 'post';
        } else if (this.deleteRequests.findIndex(deleteRequest => deleteRequest === type) !== -1) {
            return 'delete';
        } else {
            throw new Error('Invalid request type.');
        }
    }

    private generateRequestConfig(type: RequestTypes, data?: any): IRequestConfig {
        const method = this.getRequestMethod(type);
        const requestURL = this.requestURLs[type];
        const url = typeof requestURL === 'string' ? requestURL : requestURL(data);
        let requestConfig = {
            type: type,
            method: method,
            url: url,
            forceRefresh: method === 'get' ? true : undefined
        }
        const dataKey = data === undefined ? undefined : method === 'get' ? 'params' : 'data';
        if (dataKey !== undefined) {
            requestConfig = {
                ...requestConfig,
                [dataKey]: data
            }
        }
        return requestConfig;
    }

    protected makeRequest<T>(type: RequestTypes, data?: any, bubbleError?: boolean) {
        try {
            return new Promise<T>(async (resolve, reject) => {
                try {
                    const requestConfig = this.generateRequestConfig(type, data);
                    const responseData = await this.requestQueue.makeAPIRequest<T>(requestConfig);
                    resolve(responseData);
                } catch (error) {
                    reject(error);
                }
            });
        } catch (error) {
            if (bubbleError) {
                throw error;
            } else {
                console.log(error);
            }
        }
    }

    protected cancelInFlightRequests(type?: string) {
        this.requestQueue.emptyQueue(type);
    }

}