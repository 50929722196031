

import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { FormTextItem } from "../../../../stores/models/FormSection";
import ElementDescription from "./ElementDescription";
import ElementTitle from "./ElementTitle";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textItem: {
            marginBottom: theme.spacing(2)
        },
    }),
);

interface TextItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    item: FormTextItem;
}

const TextItem = observer((props: TextItemProps) => {

    const classes = useStyles();

    return (
        <div className={classes.textItem}>
            <ElementTitle variant="h6" item={props.item} />
            <ElementDescription item={props.item} />
        </div>
    )

});

export default TextItem;