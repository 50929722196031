import React, { useContext, useState, useEffect, ChangeEvent, Ref, useRef, useImperativeHandle, forwardRef } from "react";
import { observer } from 'mobx-react'
import {
    makeStyles,
    createStyles,
    Theme,
    Grid,
    Typography,
    TextField,
    TextFieldProps,
} from "@material-ui/core";
import { MapMarker } from "mdi-material-ui";
import { Autocomplete, AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails, createFilterOptions } from "@material-ui/lab";
import clsx from 'clsx';
import { RootContext } from "../../stores";
import { AddressSuggestion } from "../../stores/models/AddressSuggestion";
import { Address } from "../../stores/models/Address";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        optionIcon: {
            paddingRight: theme.spacing(2),
            color: theme.palette.action.active
        },
        noPadding: {
            paddingRight: '0px !important'
        },
        search: {
            display: 'flex',
            alignItems: 'center',
        },
        textFieldRoot: {
            padding: theme.spacing(1, 1, 1, 0),
            width: '225px'
        },
        optionLineOne: {
            fontWeight: 500
        },
        hidden: {
            display: 'none'
        }
    }),
);

// TODO: Generalize to work for CityInput as well

const filterOptions = createFilterOptions<AddressSuggestion>({
    trim: true,
});

const MIN_LENGTH_TO_AUTOCOMPLETE = 3;

interface AddressAutocompleteProps {
    address: Address;
    placeholder?: string;
    onChange: (address: string | AddressSuggestion) => void;
    TextFieldProps?: TextFieldProps;
}

const AddressAutocomplete = observer(forwardRef((props: AddressAutocompleteProps, ref: Ref<any>) => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const autocompleteStore = rootStore.autocompleteStore;

    /***** State *****/

    const [open, setOpen] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState<AddressSuggestion[]>([]);
    const [selectedOption, setSelectedOption] = useState<AddressSuggestion>();

    /***** Helper constant *****/

    const searchText = props.address.lineOne;

    /***** Autocomplete Element Reference *****/

    const inputRef = useRef<any>();
    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus();
        }
    }));

    /***** Effects *****/

    useEffect(() => {
        if (textIsMinLengthToAutocomplete(searchText) && !selectedOptionMatchesAddress()) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [searchText])

    /***** Event handlers *****/

    const handleInputChange = (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
        const text = value;
        if (reason !== 'reset') {
            setSelectedOption(undefined);
            props.onChange(text);
            getAutocompleteOptions(text);
        }
    }

    const handleOptionSelected = (
        event: React.ChangeEvent<{}>,
        value: string | AddressSuggestion | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<AddressSuggestion> | undefined
    ) => {
        if (value) {
            setOpen(false);
            if (typeof value === 'string') {
                props.onChange(value);
            } else {
                setSelectedOption(value);
                props.onChange(value);
            }
        }
    }

    /***** Helper methods *****/

    const selectedOptionMatchesAddress = () => {
        return selectedOption
            && props.address.lineOne === selectedOption.lineOne
            && props.address.city === selectedOption.city
            && props.address.state === selectedOption.state
            && props.address.zipCode === selectedOption.zip;
    }

    const textIsMinLengthToAutocomplete = (text: string) => {
        const trimmedText = text.trim();
        return trimmedText.length >= MIN_LENGTH_TO_AUTOCOMPLETE;
    }

    const getAutocompleteOptions = async (textToMatch: string) => {
        if (textIsMinLengthToAutocomplete(textToMatch)) {
            const trimmedText = textToMatch.trim();
            const newOptions = await autocompleteStore.fetchAddressAutocomplete(trimmedText);
            if (newOptions) {
                setAutocompleteOptions(newOptions);
            }
        }
    }

    /***** Render *****/

    return (
        <Autocomplete
            inputValue={searchText.length === 0 ? '' : searchText}
            open={open}
            onClose={() => setOpen(false)}
            onInputChange={handleInputChange}
            onChange={handleOptionSelected}
            options={autocompleteOptions}
            filterOptions={filterOptions}
            autoHighlight
            freeSolo={true}
            popupIcon={null}
            classes={{
                inputRoot: searchText.length > 0 ? undefined : classes.noPadding,
                endAdornment: searchText.length > 0 ? undefined : classes.hidden,
                input: clsx(
                    searchText.length > 0 ? undefined : classes.noPadding
                )
            }}
            renderInput={(params) => (
                <div className={classes.search}>
                    <TextField
                        classes={{
                            root: classes.textFieldRoot,
                        }}
                        inputRef={inputRef}
                        {...params}
                        {...props.TextFieldProps}
                        inputProps={{ ...params.inputProps, ...props.TextFieldProps?.inputProps, 'aria-label': 'city' }}
                    />
                </div>
            )}
            getOptionLabel={(option) => option.text || searchText}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <MapMarker className={classes.optionIcon} />
                        </Grid>
                        <Grid item xs>
                            <span className={classes.optionLineOne}>
                                {option.lineOne}
                            </span>
                            <Typography variant="body2" color="textSecondary">
                                {option.secondaryString}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}));

export default AddressAutocomplete;