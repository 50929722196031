import { useContext } from "react";
import { observer } from "mobx-react";
import { RegistrationFormStepObject } from "./RegistrationFormStepObject";
import { StepProps } from "../../../../../stores/models/Step";
import { RootContext } from "../../../../../stores";
import VerifyCorrectAccount from "../../../../Authentication/LinkVolunteerToOrganizationSteps/VerifyCorrectAccount";

const VerifyCorrectAccountStep = observer((props: StepProps<RegistrationFormStepObject>) => {

    const rootStore = useContext(RootContext);
    const user = rootStore.userStore.user;

    /***** Event handlers *****/

    const onLogoutClicked = async () => {
        props.stepState.setLoading(true);
        await rootStore.userStore.logout();
        props.stepState.setLoading(false);
    }

    const onConfirmed = async () => {
        props.stepState.setCompleted(true);
    }

    /***** Render *****/

    return (
        <VerifyCorrectAccount
            email={user.email}
            usersName={user.volunteer?.fullName || ''}
            prompt={`Would you like to continue with this account?`}
            onLogoutClicked={onLogoutClicked}
            onConfirmed={onConfirmed}
        />
    );
});

export default VerifyCorrectAccountStep;