import { observer } from 'mobx-react'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import { Fragment } from 'react';

interface NavigateAwayPromptProps {
    when: boolean;
    message?: string; 
}

const NavigateAwayPrompt = observer((props: NavigateAwayPromptProps) => {

    const block = props.when
    
    /********* Effects *********/

    useBlocker(() => {
        if (block) {
            return !window.confirm(props.message)
        }
        return false
    })

    /********* Render *********/

    return (
        <Fragment />
    );
});

export default NavigateAwayPrompt;