import { makeStyles, Theme, createStyles, Container, Grid, Paper, Typography, TableCell, IconButton } from "@material-ui/core";
import { useMemo } from "react";
import { observer } from "mobx-react";
import { Pencil } from "mdi-material-ui";
import { ISortableTableHeader, TableHeader } from "../../../../../stores/models/TableHeader";
import { Shift } from "../../../../../stores/models/Shift";
import { Option } from "../../../../../stores/models/Option";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import RecordTable from "./../RecordTable";
import { ShiftOpportunityData } from "../../../../../stores/models/VolunteerDetails";
import { getShiftDetailsLink } from "../../../../Navigation/Links/UrlConstructors";
import TablePaginationWrapper from "../TablePaginationWrapper";
import { TablePaginationState } from "../../../../../stores/models/TablePaginationState";
import { useNavigateInternally } from "../../../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line'
        },
        iconButton: {
            padding: 0
        },
        title: {
            marginBottom: theme.spacing(2)
        }
    }),
);

interface VolunteerUpcomingShiftsTableProps {
    shifts: Shift[];
    shiftOpportunityMapping: ShiftOpportunityData;
    total: number;
    loadResults: (limit: number, offset: number) => void;
}

const VolunteerUpcomingShiftsTable = observer((props: VolunteerUpcomingShiftsTableProps) => {

    const classes = useStyles();
    const navigate = useNavigateInternally();

    const tablePaginationState = useMemo(() => {
        return new TablePaginationState();
    }, []);

    const shiftHeaderCells = ([
        { id: 'edit', alignment: 'center', disablePadding: true, label: 'Edit', sortable: false },
        { id: 'role', alignment: 'left', disablePadding: false, label: 'Volunteer Role', sortable: true, sortValue: (shift: Shift) => props.shiftOpportunityMapping[shift.id].position },
        { id: 'date', alignment: 'left', disablePadding: false, label: 'Date', sortable: true, sortValue: (shift: Shift) => shift.repetitionPattern.startTimestamp },
        { id: 'time', alignment: 'left', disablePadding: false, label: 'Time', sortable: false },
    ] as ISortableTableHeader<Shift>[]).map(headCell => new TableHeader(headCell));

    /********* Render *********/

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Container>
                            <Typography variant="h5" className={classes.title}>
                                Upcoming Shifts
                            </Typography>
                            <RecordTable
                                records={new OptionCollection('id', props.shifts)}
                                orderBy={'date'}
                                tableHeaderCells={shiftHeaderCells}
                                displayFunctions={[
                                    (shift: Option<Shift>, rowIndex: number, cellIndex: number) => {
                                        return (
                                            <TableCell align="center" key={`${shift.object.id}-${rowIndex}-${cellIndex}`}>
                                                <IconButton
                                                    className={classes.iconButton}
                                                >
                                                    <Pencil color="action" />
                                                </IconButton>
                                            </TableCell>
                                        )
                                    },
                                    (shift: Option<Shift>, rowIndex: number, cellIndex: number) => {
                                        const topLevelId = shift.object.identificationData.topLevelId;
                                        const position = props.shiftOpportunityMapping[topLevelId].position;
                                        return (
                                            <TableCell align="left" key={`${shift.object.id}-${rowIndex}-${cellIndex}`}>{position}</TableCell>
                                        )
                                    },
                                    (shift: Option<Shift>, rowIndex: number, cellIndex: number) => {
                                        return (
                                            <TableCell align="left" key={`${shift.object.id}-${rowIndex}-${cellIndex}`}>{shift.object.repetitionPattern.formattedStartDate}</TableCell>
                                        )
                                    },
                                    (shift: Option<Shift>, rowIndex: number, cellIndex: number) => {
                                        return (
                                            <TableCell align="left" key={`${shift.object.id}-${rowIndex}-${cellIndex}`}>{shift.object.repetitionPattern.formattedTime}</TableCell>
                                        )
                                    }
                                ]}
                                onRowClicked={(option: Option<Shift>) => {
                                    const link = getShiftDetailsLink(option.object.identificationData);
                                    navigate(link);
                                }}
                            />
                            <TablePaginationWrapper
                                state={tablePaginationState}
                                total={props.total}
                                loadResults={props.loadResults}
                                skipInitialLoad
                            />
                        </Container>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
});

export default VolunteerUpcomingShiftsTable;