import { observable, action, makeAutoObservable } from "mobx";
import axios, { CancelTokenSource, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { RootStore } from "../RootStore";
import { ResponseMiddleware } from "./ResponseMiddleware";
import qs from "qs";
import { CLIENT_VERSION_HEADER_KEY, VOLTAGE_CLIENT_VERSION } from "../../data/config";

enum RequestStatus {
    NotRequested,
    InFlight,
    Successful,
    Error,
    Canceled
}

class RequestQueueError extends Error {
    // New error class
}

export interface IRequestConfig extends AxiosRequestConfig {
    type?: string;
    forceRefresh?: boolean;
    noHeaders?: boolean;
}

class Request {
    @observable id: string | number = "";
    @observable status = RequestStatus.NotRequested;
    @observable cancelTokenSource: CancelTokenSource | null = null;
    @observable config: IRequestConfig;

    constructor(id: string | number, config: IRequestConfig) {
        makeAutoObservable(this);

        this.id = id;
        this.status = RequestStatus.InFlight;
        this.config = config;
        const cancelTokenSource = axios.CancelToken.source();
        this.cancelTokenSource = cancelTokenSource;
        this.config.cancelToken = cancelTokenSource.token;
        if (!this.config.noHeaders) {
            this.config.headers = { 'Cache-Control': 'no-cache', [CLIENT_VERSION_HEADER_KEY]: VOLTAGE_CLIENT_VERSION };
        }
    }
}

export class RequestQueue {
    @observable queue = [] as Request[];

    private rootStore: RootStore;
    private responseMiddleware: ResponseMiddleware;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
        this.responseMiddleware = new ResponseMiddleware(rootStore);
    }

    makeExternalRequest<T>(requestConfig: IRequestConfig) {
        return this.makeRequest<T>(requestConfig);
    }
    
    makeAPIRequest<T>(requestConfig: IRequestConfig) {
        return this.makeRequest<T>(requestConfig, this.apiRequestMiddleware);
    }
    
    private apiRequestMiddleware = (response: AxiosResponse<any, any>) => {
        this.responseMiddleware.checkForClientVersionUpdate(response.headers);
        this.responseMiddleware.checkForAPIError(response.data);
        this.responseMiddleware.parseCategoryLists(response.data);
        this.responseMiddleware.applyMiddleware(response.data);
    }

    private makeRequest<T>(requestConfig: IRequestConfig, middleware?: (response: AxiosResponse<any, any>) => void) {
        return new Promise<T /*| { error: APIError }*/>(async (resolve, reject) => {
            try {
                const requestId = this.generateRequestId(requestConfig);
                requestConfig = this.addParamSerializerIfNeeded(requestConfig);
                this.verifyRequestNotQueued(requestId);
                if (process.env.NODE_ENV === 'development') {
                    console.log('Request:');
                    console.log(requestConfig);
                }
                const response = await this.addRequest(requestId, requestConfig);

                if (process.env.NODE_ENV === 'development') {
                    console.log(`Response for ${requestId}:`);
                    console.log(response.data);
                }

                // setTimeout(() => {
                if (middleware) {
                    middleware(response);
                }
                resolve(response.data);
                // }
                // }, 3000);
            } catch (error) {
                if (error instanceof RequestQueueError) {
                    console.log(error);
                } else {
                    reject(error);
                }
            }
        });
    }

    /************* Add a new request to the queue *************/

    @action private addRequest(id: string | number, config: IRequestConfig) {
        return new Promise<AxiosResponse>(async (resolve, reject) => {
            const request = new Request(id, config);
            this.queue.push(request);
            await axios(request.config).then(response => {
                request.status = RequestStatus.Successful;
                resolve(response);
            }).catch((reason: AxiosError) => {
                if (reason.response?.status === 401) {
                    // Handle the user's session expiring
                    this.rootStore.userStore.setAuthenticatedUser();
                    this.rootStore.navigationStore.appwideDialogStates.sessionExpiredDialog.setOpen(true);
                }
                request.status = RequestStatus.Error;
                reject(reason);
            });
        })
    }

    verifyRequestNotQueued(id: string | number) {
        if (this.queue.some(request => request.id === id)) {
            throw new RequestQueueError(`Request ${id} has already been queued.`);
        }
    }

    /************* Empty the queue, canceling any in flight requests *************/

    @action emptyQueue(type?: string) {
        if (type === undefined) {
            this.cancelQueuedRequests('All in flight requests canceled.');
            this.queue = [] as Request[];
        } else {
            this.cancelQueuedRequestsWithType(type);
        }
    }

    @action private cancelQueuedRequestsWithType(type: string) {
        this.queue.filter(request => request.config.type === type).forEach(request => {
            this.cancelQueuedRequest(request, `Cancelling all requests of type '${type}'.`)
        });
    }

    private cancelQueuedRequests(message: string) {
        this.queue.forEach(request => this.cancelQueuedRequest(request, message));
    }

    private cancelQueuedRequest(request: Request, message: string) {
        if (request.status !== RequestStatus.InFlight) return;
        request.cancelTokenSource?.cancel(message + " " + request.id);
        request.status = RequestStatus.Canceled;
    }

    /************* Private helper methods *************/

    private generateRequestId(config: IRequestConfig) {
        // Generates a unique id for each put, post, delete request.
        // Must be unique since the request queue ignores requests with
        // ids matching an existing request.
        const uniqueIdNeeded = config.method?.toLowerCase() !== 'get';
        const id = `${config.url}${config.data ? JSON.stringify(config.data) : ''}`;
        if (uniqueIdNeeded || config.forceRefresh) {
            return `${id}-${Date.now()}`;
        } else {
            return `${id}`;
        }
    }

    private addParamSerializerIfNeeded(config: IRequestConfig): IRequestConfig {
        if (config.method?.toLowerCase() === 'get' && config.params !== undefined) {
            return {
                ...config,
                paramsSerializer: function (params) {
                    const stringifiedParams = qs.stringify(params);
                    return stringifiedParams;
                }
            }
        }
        return config;
    }
}