import { useContext, useEffect, useState } from "react";
import { Organization, RootContext } from "../../../../stores";
import { useIsOrgPortal } from "./IsOrgPortal";
import { useOrgPortalSlug } from "./OrgPortalSlug";

export const useOrganization = (identifier?: number | string) => {

    const rootStore = useContext(RootContext);
    const { organizationStore } = rootStore;

    const isOrgPortal = useIsOrgPortal();
    const portalSlug = useOrgPortalSlug();

    const orgToLoad = identifier !== undefined ? identifier : portalSlug;
    const [loading, setLoading] = useState(isOrgPortal || identifier !== undefined);
    const [organization, setOrganization] = useState<Organization>();

    useEffect(() => {
        requestOrganization();
    }, [orgToLoad]);

    const requestOrganization = async () => {
        if (orgToLoad !== undefined) {
            setLoading(true);
            const response = await organizationStore.getOrgInfo(orgToLoad);
            setOrganization(response);
            setLoading(false);
        }
    }

    return { organization, loading };
}