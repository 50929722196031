import { TableCell, } from "@material-ui/core";
import { useMemo, useState } from "react";
import { observer } from "mobx-react";
import { ServiceEntry } from "../data-access/entities/ServiceEntry";
import { Option } from "../../../stores/models/Option";
import { ServiceDataHeaderCells, ServiceDataViewModel } from "../data-access/view-models/ServiceDataViewModel";
import NewRecordTable from "../../../shared/modules/record-collection/components/NewRecordTable";
import { RecordTableViewModel } from "../../../shared/modules/record-collection/data-access/view-models/RecordTableViewModel";
import { useRootStore } from "../../../shared/general/hooks";
import VolunteerHoursSourceIcon from "./VolunteerHoursSourceIcon";
import { OrganizationSpecificServiceDataHeaderCells } from "../data-access/view-models/OrganizationServiceDataViewModel";

/*** Component ***/

interface ServiceDataTableProps {
    viewModel: ServiceDataViewModel;
    onServiceEntryClicked: (record: Option<ServiceEntry>, rowIndex: number) => void;
}

const VolunteerHoursTable = observer((props: ServiceDataTableProps) => {

    const { volunteerHoursStore } = useRootStore();
    const viewModel = props.viewModel;

    /*** Memoized Values ***/

    const displayFunctions = useMemo(() => {
        let functions = viewModel.tableHeaderCells.map(headCell => {
            return (serviceEntry: Option<ServiceEntry>, rowIndex: number, cellIndex: number) => {
                return (
                    <TableCell align={headCell.alignment} key={`${rowIndex}-${cellIndex}`}>
                        {headCell.id === OrganizationSpecificServiceDataHeaderCells.Source
                            ? <VolunteerHoursSourceIcon serviceEntry={serviceEntry.object} />
                            : viewModel.getDisplayableServiceEntry(serviceEntry.object)[headCell.id as ServiceDataHeaderCells]
                        }
                    </TableCell>
                )
            }
        });
        return functions;
    }, []);

    /*** State ***/

    const [tableViewModel] = useState(new RecordTableViewModel(volunteerHoursStore));

    /********* Render *********/

    return (
        <NewRecordTable
            viewModel={tableViewModel}
            hideSelectAllRow
            onRowClicked={props.onServiceEntryClicked}
            tableHeaderCells={viewModel.tableHeaderCells}
            displayFunctions={displayFunctions}
        />
    );
});

export default VolunteerHoursTable;