import { WheelchairAccessibility, MapMarkerRadius, Bus, Baby, HumanMaleChild, AccountGroup, WeightLifter, SeatLegroomNormal, Gavel } from "mdi-material-ui";
import { IconName } from "../../../stores/models/Icon";
import { IOptionObject } from "../../../stores/models/Option";

export interface SearchOption<T> extends IOptionObject {
    // filterText: string; // The text to be displayed in the filter chip on the search page
    primaryText: T; // The top line of text to be displayed in the filter selection dialog
    // secondaryText?: string; // Text to describe the filter option
    chipText?: string; // Text to display in a filter chip
    icon?: IconName;
}

export enum VolunteerLocationOptions {
    OnSite = "In Person",
    Remote = "Remote",
    All = "All"
}

export const volunteerLocationFilterOptions: SearchOption<VolunteerLocationOptions>[] = [{
    primaryText: VolunteerLocationOptions.All,
    value: VolunteerLocationOptions.All,
}, {
    // filterText: VolunteerLocationOptions.OnSite,
    primaryText: VolunteerLocationOptions.OnSite,
    // secondaryText: 'Volunteer on site.',
    value: VolunteerLocationOptions.OnSite,
}, {
    // filterText: 'Remote',
    primaryText: VolunteerLocationOptions.Remote,
    // secondaryText: 'Volunteer from wherever the wind takes you.',
    value: VolunteerLocationOptions.Remote,
}];

export enum SearchTypeOptions {
    Opportunities = "Opportunities",
    Organizations = "Organizations"
}

export const searchTypeOptions: SearchOption<SearchTypeOptions>[] = [{
    // filterText: SearchTypeOptions.Opportunities,
    primaryText: SearchTypeOptions.Opportunities,
    value: SearchTypeOptions.Opportunities,
    // icon: ClipboardTextMultipleOutline
}, {
    // filterText: SearchTypeOptions.Organizations,
    primaryText: SearchTypeOptions.Organizations,
    value: SearchTypeOptions.Organizations,
    // icon: Domain
}];


export enum SchedulingOptions {
    Flexible = 'Flexible',
    Shifts = 'Shifts',
    Either = 'Either'
}

export const schedulingFilterOptions: SearchOption<SchedulingOptions>[] = [{
    // filterText: SchedulingOptions.Flexible,
    primaryText: SchedulingOptions.Flexible,
    value: SchedulingOptions.Flexible,
    // icon: Gesture
}, {
    // filterText: SchedulingOptions.Shifts,
    primaryText: SchedulingOptions.Shifts,
    value: SchedulingOptions.Shifts,
    // icon: CalendarMultiselect
}, {
    // filterText: SchedulingOptions.Shifts,
    primaryText: SchedulingOptions.Either,
    value: SchedulingOptions.Either,
    // icon: CalendarMultiselect
}];

export enum OrganizationTypeOptions {
    Nonprofit = 'Nonprofit',
    ForProfit = 'For-Profit',
}

export const organizationTypeOptions: SearchOption<OrganizationTypeOptions>[] = [{
    // filterText: OrganizationTypeOptions.Nonprofit,
    primaryText: OrganizationTypeOptions.Nonprofit,
    value: OrganizationTypeOptions.Nonprofit,
    // icon: HomeHeart
}, {
    // filterText: OrganizationTypeOptions.ForProfit,
    primaryText: OrganizationTypeOptions.ForProfit,
    value: OrganizationTypeOptions.ForProfit,
    // icon: Store
}];

export enum OpportunityTypeOptions {
    OneTime = 'One Time',
    Ongoing = "Ongoing",
}

export const opportunityTypeOptions: SearchOption<OpportunityTypeOptions>[] = [{
    // filterText: OpportunityTypeOptions.OneTime,
    primaryText: OpportunityTypeOptions.OneTime,
    value: OpportunityTypeOptions.OneTime,
    // icon: CalendarClock
}, {
    // filterText: OpportunityTypeOptions.Ongoing,
    primaryText: OpportunityTypeOptions.Ongoing,
    value: OpportunityTypeOptions.Ongoing,
    // icon: Autorenew
}];

export enum CommitmentLevelOptions {
    ShortTerm = "Short Term",
    LongTerm = "Long Term",
    All = 'All'
}

export const commitmentFilterOptions: SearchOption<CommitmentLevelOptions>[] = [{
    //     // filterText: SchedulingOptions.Shifts,
    //     primaryText: CommitmentLevelOptions.All,
    //     value: CommitmentLevelOptions.All,
    //     // icon: CalendarMultiselect
    // }, {
    // filterText: CommitmentLevelOptions.ShortTerm,
    primaryText: CommitmentLevelOptions.ShortTerm,
    // secondaryText: `You're able to commit to one day at a time.`,
    value: CommitmentLevelOptions.ShortTerm,
    // icon: LinkOff
}, {
    // filterText: CommitmentLevelOptions.LongTerm,
    primaryText: CommitmentLevelOptions.LongTerm,
    // secondaryText: `You're looking for somewhere to volunteer consistently.`,
    value: CommitmentLevelOptions.LongTerm,
    // icon: Ring
}];

export enum LocationCheckboxOptions {
    // LocalRemoteResults = 'Show only local remote results',
    WheelchairAccessible = 'Wheelchair accessible',
    PublicTransportation = 'Accessible by public transportation'
}

const wheelchairAccessibilityOption: SearchOption<LocationCheckboxOptions> = {
    primaryText: LocationCheckboxOptions.WheelchairAccessible,
    value: LocationCheckboxOptions.WheelchairAccessible,
    icon: WheelchairAccessibility
};

const publicTransportationAccessibilityOption: SearchOption<LocationCheckboxOptions> = {
    primaryText: LocationCheckboxOptions.PublicTransportation,
    chipText: 'Public transportation',
    value: LocationCheckboxOptions.PublicTransportation,
    icon: Bus
};

export const locationCheckboxFilterOptions: SearchOption<LocationCheckboxOptions>[] = [
//     {
//     primaryText: LocationCheckboxOptions.LocalRemoteResults,
//     chipText: 'Volunteer locally',
//     value: LocationCheckboxOptions.LocalRemoteResults,
//     icon: MapMarkerRadius
// },
    wheelchairAccessibilityOption,
    publicTransportationAccessibilityOption
];

export const locationAccessibilityOptions: SearchOption<LocationCheckboxOptions>[] = [
    wheelchairAccessibilityOption,
    publicTransportationAccessibilityOption
]

export enum OpportunityDetailsOptions {
    Childcare = 'Childcare available',
    Families = 'Good for families',
    Groups = 'Good for groups',
    ShortStanding = 'Minimal time spent standing',
    NoHeavyLifting = 'Heavy lifting not required',
    CourtMandated = 'Court-ordered volunteers eligible'
}

export const opportunityDetailsFilterOptions: SearchOption<OpportunityDetailsOptions>[] = [{
    primaryText: OpportunityDetailsOptions.Childcare,
    value: OpportunityDetailsOptions.Childcare,
    icon: Baby
}, {
    primaryText: OpportunityDetailsOptions.Families,
    value: OpportunityDetailsOptions.Families,
    icon: HumanMaleChild
}, {
    primaryText: OpportunityDetailsOptions.Groups,
    value: OpportunityDetailsOptions.Groups,
    icon: AccountGroup
}, {
    primaryText: OpportunityDetailsOptions.ShortStanding,
    value: OpportunityDetailsOptions.ShortStanding,
    icon: SeatLegroomNormal
}, {
    primaryText: OpportunityDetailsOptions.NoHeavyLifting,
    value: OpportunityDetailsOptions.NoHeavyLifting,
    icon: WeightLifter
}, {
    primaryText: OpportunityDetailsOptions.CourtMandated,
    chipText: 'Court-ordered service',
    value: OpportunityDetailsOptions.CourtMandated,
    icon: Gavel
}];

// export const volunteerLocationFilterOptions = [{
//     primaryText: 'In Person',
//     value: 'In Person',
// }, {
//     primaryText: 'Remote',
//     value: 'Remote',
// }, {
//     primaryText: 'Either',
//     value: 'Either'
// }];

// export const opportunityFilterOptions = [{
//     primaryText: 'One Time',
//     value: 'One Time',
// }, {
//     primaryText: 'Ongoing',
//     value: 'Ongoing',
// }, {
//     primaryText: 'Either',
//     value: 'Either'
// }];