import { ServiceEntryTag } from "../../../../stores/models/ServiceEntryTag";

type IServiceEntryBulkDelete = { archived: true };
type IServiceEntryBulkTagUpdate = { tags: ServiceEntryTag[] };
type IServiceEntryBulkStatusUpdate = { statusId: number };

export type IServiceEntryBulkEdit = IServiceEntryBulkTagUpdate | IServiceEntryBulkStatusUpdate | IServiceEntryBulkDelete;

export const isServiceEntryBulkDeletion = (x: any): x is IServiceEntryBulkDelete => {
    if (typeof x === 'object' && 'archived' in x) {
        return true;
    }
    return false;
}

export const isServiceEntryBulkStatusUpdate = (x: any): x is IServiceEntryBulkStatusUpdate => {
    if (typeof x === 'object' && 'statusId' in x) {
        return true;
    }
    return false;
}