import { makeStyles, Theme, createStyles, ClickAwayListener, PopperProps, Popper, ClickAwayListenerProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { DialogState } from "../../../../stores/models/DialogState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: theme.zIndex.modal
        }
    })
);

export interface ClickAwayPopperProps extends Omit<ClickAwayListenerProps, 'onClickAway'> {
    state: DialogState;
    anchorEl?: React.RefObject<any>;
    PopperProps?: Partial<PopperProps>;
}

const ClickAwayPopper: FunctionComponent<ClickAwayPopperProps> = observer((props) => { 

    const classes = useStyles();

    /********* Event handler *********/

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (props.anchorEl?.current.contains(event.target as Node)) {
            return;
        }

        props.state.setOpen(false);
    };

    /********* Render *********/

    return (
        <Popper {...props.PopperProps} disablePortal open={props.state.open} anchorEl={props.anchorEl?.current} className={classes.popper}>
            <ClickAwayListener onClickAway={handleClose}>
                <div>
                    {props.children}
                </div>
            </ClickAwayListener>
        </Popper>
    );
});

export default ClickAwayPopper;