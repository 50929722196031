import { makeAutoObservable } from "mobx";
import { Dictionary, pruneDictionary } from "../../logic/Dictionaries";
import { Identity } from "./OptionCollection";

export interface IFilterState {
    filters: Dictionary<string, Identity[]>;
    searchQuery?: string;
}

export class FilterState implements IFilterState {

    filters: Dictionary<string, Identity[]> = {};
    searchQuery?: string;

    constructor(filterState?: IFilterState) {
        makeAutoObservable(this);

        if (filterState !== undefined) {
            this.filters = filterState.filters;
            this.searchQuery = filterState.searchQuery;
        }
    }

    setSearchQuery(searchQuery?: string) {
        this.searchQuery = searchQuery;
    }

    setFilter(filter: string, selected: Identity[]) {
        this.filters[filter] = selected;
    }

    addFilter(filter: string, selected: number[]) {
        if (this.filters[filter]) {
            this.addUniqueFilters(filter, selected);
        } else {
            this.filters[filter] = selected;
        }
    }

    removeFilter(filter: string, valueToRemove: number) {
        const indexOfValue = this.filters[filter].findIndex(filterValue => filterValue === valueToRemove);
        if (indexOfValue !== undefined && indexOfValue !== -1) {
            delete this.filters[filter][indexOfValue];
        }
    }

    clearFilters() {
        pruneDictionary(this.filters);
    }

    private addUniqueFilters(filter: string, selected: number[]) {
        selected.forEach(selection => {
            if (this.filters[filter].findIndex(filterValue => filterValue === selection) === -1) {
                this.filters[filter].push(selection);
            }
        });
    }

    serialize() {
        return {
            ...this,
            searchQuery: this.searchQuery?.trim(),
            filters: JSON.stringify(this.filters)
        }
    }
}