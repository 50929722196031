import React, { useState, useEffect, useMemo, Fragment } from "react";
import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import clsx from 'clsx';
import { ArrowLeft } from "mdi-material-ui";
import { Step } from "../../stores/models/Step";
import { Fields } from "../../stores/models/Fields";
import FormLoadingSpinner from "../Organization/VolunteerOpportunities/FormLoadingSpinner";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            position: 'relative'
        },
        button: {
            marginTop: theme.spacing(2),
        },
        backButton: {
            color: theme.palette.action.active,
        },
        buttons: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-evenly',
            maxWidth: '400px'
        },
        flex: {
            display: 'flex',
            justifyContent: 'center'
        },
    })
);

interface StepSequencerProps<T extends Fields<Partial<T>, T>> {
    steps: Step<T>[];
    onAllStepsCompleted: (stepObject: T) => void;
    onForwardButtonClicked?: () => Promise<void | { success: boolean, error?: string }>;
    onCurrentStepChanged?: (currentStep: Step<T>) => void;
    getForwardButtonText?: (step: Step<T>) => string;
}

const StepSequencer = observer(<T extends Fields<Partial<T>, T>>(props: StepSequencerProps<T>) => {

    /***** Helper method *****/

    const getFormId = () => {
        return `form-step-sequencer-${Date.now()}-${Math.random()}`;
    }

    /***** React hooks *****/

    const classes = useStyles();
    const formId = useMemo(() => getFormId(), []);

    /***** State *****/

    const [stepIndex, setStepIndex] = useState(0);
    const [previousStepIndex, setPreviousStepIndex] = useState(0);
    const [currentStep, setCurrentStep] = useState(props.steps[stepIndex]);

    /***** Helper constant *****/

    const isFinalStep = stepIndex === props.steps.length - 1;

    /***** Effects *****/

    useEffect(() => {
        handleStepIndexChanged();
    }, [stepIndex]);

    useEffect(() => {
        if (stepIndex < props.steps.length) {
            setCurrentStep(props.steps[stepIndex]);
        }
    }, [props.steps]);

    useEffect(() => {
        currentStep.state.setValidationRun(false);
        currentStep.state.setCompleted(false);
        if (props.onCurrentStepChanged) {
            props.onCurrentStepChanged(currentStep);
        }
    }, [currentStep]);

    useEffect(() => {
        if (currentStep.state.completed) {
            advanceStep();
        }
    }, [currentStep, currentStep.state.completed]);

    /***** Event handlers *****/

    const handleStepIndexChanged = async () => {
        if (stepIndex < props.steps.length) {
            const movedForward = stepIndex > previousStepIndex;
            let result: { success: boolean, error?: string } = { success: true };
            if (props.onForwardButtonClicked && movedForward) {
                let callbackResult = await props.onForwardButtonClicked();
                if (callbackResult) {
                    result = callbackResult;
                }
            }
            if (result.success) {
                setCurrentStep(props.steps[stepIndex]);
            }
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        checkForStepCompletion();
    }

    const checkForStepCompletion = () => {
        currentStep.state.setLoading(true);
        const validated = currentStep.validated();
        currentStep.state.setValidationRun(true);
        if (validated) {
            currentStep.state.setCompleted(true);
        }
        currentStep.state.setLoading(false);
    }

    const advanceStep = () => {
        if (!isFinalStep) {
            setPreviousStepIndex(stepIndex);
            setStepIndex(stepIndex + 1);
        } else {
            props.onAllStepsCompleted(currentStep.state.stepObject);
        }
    }

    const handleBackClicked = () => {
        if (stepIndex > 0) {
            setPreviousStepIndex(stepIndex);
            setStepIndex(stepIndex - 1);
        }
    }

    /***** Render *****/

    return (
        <Fragment>
            {/* TODO: If input fields in the currentStep.content should be associated with this form, then the formId should be passed to them. 
                Don't wrap the form around the step content. The step content might contain a form and nested forms cause issues.
            */}
            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form} id={formId} />
            {React.createElement(currentStep.content, { stepState: currentStep.state })}
            <div className={classes.flex}>
                <div className={classes.buttons}>
                    {stepIndex > 0 && !currentStep.hideBackButton
                        ? <Button
                            onClick={handleBackClicked}
                            disabled={currentStep.state.loading}
                            className={clsx(classes.button, classes.backButton)}
                            startIcon={<ArrowLeft />}
                        >
                            Back
                        </Button>
                        : null
                    }
                    {currentStep.submitButtonEmbedded
                        ? null
                        : <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            form={formId}
                            className={classes.button}
                            disabled={(currentStep.disableSubmitUntilValid && !currentStep.admissable) || currentStep.state.loading}
                        >
                            {props.getForwardButtonText
                                ? props.getForwardButtonText(currentStep)
                                : isFinalStep
                                    ? 'Submit'
                                    : 'Next'
                            }
                        </Button>
                    }
                </div>
            </div>
            {currentStep.state.loading ? <FormLoadingSpinner /> : undefined}
        </Fragment>
    )
});

export default StepSequencer;