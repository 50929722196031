import React, { useContext } from "react";
import { makeStyles, Theme, createStyles, MenuItem } from "@material-ui/core";
import { observer } from "mobx-react";
import { StubOrganizationVolunteer } from "../../../../../../stores/models/StubOrganizationVolunteer";
import { RootContext } from "../../../../../../stores";
import REMSelect from "../../../../../../shared/modules/rem-conversion/components/Select";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dropdown: {
            display: 'flex'
        }
    })
);

interface StatusFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    volunteer: StubOrganizationVolunteer;
}

const StatusField = observer((props: StatusFieldProps) => {

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const organizationVolunteerStatuses = rootStore.userStore.user.organization?.volunteerStatuses || [];

    /********** Event Handlers *********/

    const onSelectedStatusChanged = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        props.volunteer.setStatusId(event.target.value as number);
    }

    /********** Render *********/

    return (
        <REMSelect
            value={props.volunteer.statusId}
            variant={'outlined'}
            onChange={(event, child) => onSelectedStatusChanged(event, child)}
            className={classes.dropdown}
        >
            {organizationVolunteerStatuses.map(status => {
                return (
                    <MenuItem value={status.id} key={status.id}>{status.status}</MenuItem>
                )
            })}
        </REMSelect>
    )
});

export default StatusField;