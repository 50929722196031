import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { getPrivacyPolicyLink, getTermsOfServiceLink } from "../../../Navigation/Links/UrlConstructors";
import { StylizedInternalLink } from "../../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            textDecoration: 'underline',
            fontWeight: 500,
        },
        centered: {
            textAlign: 'center',
            margin: theme.spacing(1, 0)
        }
    })
);

const TermsOfServiceOrganizationUser = () => {

    const classes = useStyles();

    return (
        <div>
            <p>
                THESE TERMS CREATE A BINDING CONTRACT
            </p>
            <p>
                Please read these Terms of Service (“Terms”) carefully because they form a contract between any person or entity who uses our site (“You” and “Your”) and Voltage Volunteering, LLC, our partners, employees, officers, directors, sponsors, and affiliated companies (“We”, “Us”, and “Our”).
            </p>
            <p>
                Our service enables organizations (“Organization Users”) accessing voltagevolunteering.com (“Website”) to post information about themselves and volunteer opportunities they have available as well as the ability to reach out to volunteers who have expressed interest in their events or organization (“Services”).
            </p>
            <p>
                These Terms govern the use of our site by Organization Users to access and share information with other users of the Website. These Terms also incorporate <StylizedInternalLink to={getPrivacyPolicyLink()} target="_blank" className={classes.link}>Our Privacy Policy</StylizedInternalLink>. Please read that document carefully because it also governs Your use of the Services and includes important provisions of the contract between You and Us.
            </p>
            <p>
                By using the Services, You affirm you have read and understood the Terms and You agree to be bound by these Terms. If You are using the Services on behalf of an organization, you are agreeing to these Terms for that organization and promising that You have the authority to bind that organization to these terms. If You are using the Services to deliver or provide access to content owned by a third-party, You represent that you have the right to make the access available. By agreeing to these Terms, you are representing to Us that You are over 13 years old.
            </p>
            <p>
                You may use the Services only in compliance with these Terms and only if you have the power to form a contract with Us and are not barred under any applicable laws from doing so.
            </p>
            <div className={classes.centered}>
                <div>
                    IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS,
                </div>
                <div>
                    YOU MUST NOT USE THE SERVICE
                </div>
            </div>
            <p>
                1. ORGANIZATION USERS
            </p>
            <p>
                We allow volunteers to access and view the information You make available on the Website. As a condition of making content available through Our site, You represent that You have the right to grant Us, and do grant Us, a non-exclusive license to use, copy, reproduce, process, adapt, publish, transmit, host and display that content for the purpose of (i) providing the Services and associated support; and (ii) analyzing and improving the operation of the Services. We reserve the right to remove content that We reasonably believe may create liability for Us.
            </p>
            <p>
                We do not have control over and do not guarantee the quality or quantity of volunteers that may show interest or volunteer for Your events. We do not guarantee that any volunteer connected to You though Our Service will attend the event or perform the tasks asked of them.
            </p>
            <p>
                You are solely responsible for any claims referring or relating to the existence, quality, safety or legality of events or content, the truth or accuracy of descriptions of events or content and cancellation, postponement or changes to events or unavailability of content.
            </p>
            <p>
                Organization Users may post a link that takes prospective volunteers to their organization's site but may not solicit for donations on the Website.
            </p>
            <p>
                By posting opportunities on our website, You represent that You have met the requirements to accept the assistance that You are requesting.
            </p>
            <p>
                2. FEES
            </p>
            <p>
                Organization Users are charged a fee for their continual use of our Services based on a tiered subscription model. Payment of fees will automatically renew. All Organization Users agree to pay fees. We reserve the right and sole discretion to change Our fees at any time as We deem appropriate.
            </p>
            <p>
                3. SUSPENSION AND TERMINATION OF USE
            </p>
            <p>
                The Terms will continue to apply until your use of the Website is terminated by either You or Voltage Volunteering as follows. (1) You may stop using the Service at any time. (2) We reserve the right to suspend or terminate Your access to the Service at any time, in Our sole discretion, with or without cause, and with or without notice, without incurring liability of any kind. For example, We may suspend or terminate Your access to or use of the Service for: (a) the actual or suspected violation of these Terms; (b) the use of the Services in a manner that may cause Us to have legal liability or disrupt others' use of the Services; (c) scheduled downtime and recurring downtime; or (d) unplanned technical problems and outages.
            </p>
            <p>
                4. ACCESS TO THE SERVICES
            </p>
            <p>
                Organization Users are responsible for safeguarding the password that You use to access the Website and for any activities or actions under Your password. Voltage Volunteering cannot and will not be liable for any loss or damage arising from Your failure to comply with the above.
            </p>
            <p>
                We allow Organization Users a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software that is provided to You by Voltage Volunteering as part of Your use of the Services. This license is for the sole purpose of enabling You to use and enjoy the benefit of the Services as provided by Voltage Volunteering, in the manner permitted by these Terms.
            </p>
            <p>
                5. CONSENT TO ELECTRONIC COMMUNICATIONS AND SOLICITATION.
            </p>
            <p>
                By registering with Us, You understand that We may send You communications or data regarding the Services, including but not limited to (a) notices about Your use of the Services, and (b) information and materials regarding potential volunteers that have expressed interest in
            </p>
            <p>
                Your events, via electronic mail. We give You the opportunity to opt-out of receiving such non-mandatory electronic mail from Us by following the opt-out instructions provided in the message.
            </p>
            <p>
                We may also send You, in electronic form, mandatory notices about the Service and information the law requires Us to provide. We may provide mandatory or required information to You by email at the address You specified when You signed up for the Service. Mandatory or required notices emailed to You will be deemed given and received when the email is sent.
            </p>
            <p>
                You cannot opt-out of receiving mandatory or required notices. If You do not consent to receive mandatory or required notices electronically, You must stop using the Service.
            </p>
            <p>
                6. DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY
            </p>
            <p>
                PLEASE READ THIS SECTION CAREFULLY.
            </p>
            <p>
                THE SERVICES ARE PROVIDED “AS IS”, AT YOUR OWN RISK. VOLTAGE VOLUNTEERING DISCLAIMS ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
            </p>
            <p>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VOLTAGE VOLUNTEERING SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE WEBSITE; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE WEBSITE, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE WEBSITE; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR INFORMATION.
            </p>
            <p>
                THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY.
            </p>
            <p>
                7. THE USE OF LINKS ON OUR WEBSITE
            </p>
            <p>
                Our Service contains links to other web sites. These websites that You may visit from these links have their own privacy policy. Voltage Volunteering is not responsible for the privacy practices of these other websites. We encourage You to read their privacy policy before providing them with any of Your personally identifiable information.
            </p>
            <p>
                8. WAIVER AND SEVERABILITY
            </p>
            <p>
                In the event that any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect. Our failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.
            </p>
            <p>
                9. CONTROLLING LAW AND JURISDICTION
            </p>
            <p>
                This agreement and the validity thereof shall be governed by and construed in accordance with the substantive laws of the State of Wisconsin, USA, excluding its conflicts of laws principles. Any controversy or claim arising out of or relating to this agreement, or the breach thereof, shall be finally settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The seat of arbitration shall be the City of Madison, Wisconsin, USA.
            </p>
            <p>
                10. CHANGES IN SERVICE
            </p>
            <p>
                We may revise these Terms from time to time, the most current version will always be available <StylizedInternalLink to={getTermsOfServiceLink()} target="_blank" className={classes.link}>Here</StylizedInternalLink>. We will do Our best to notify Users of material changes. By continuing to use the Service following revisions, You agree to be bound by the new revised Terms.
            </p>
            <p>
                11. ENTIRE AGREEMENT
            </p>
            <p>
                These Terms and the Privacy Policy are the entire and exclusive agreement between Voltage Volunteering and You regarding the use of Our Service (excluding any services for which You have a separate agreement that is explicitly in addition to or in place of these Terms).
            </p>
            <p>
                12. INDEMNIFICATION
            </p>
            <p>
                You agree to indemnify Us hold Our subsidiaries, affiliates and their respective officers, directors, attorneys, agents, employees, licensors and suppliers (the “Indemnitees”) harmless against any claim or demand and all liabilities, costs and expenses (including reasonable attorneys' fees) incurred by Us and (if applicable) any Indemnitee resulting from or arising out of Your violation of any law, breach of any representation or warranty in this agreement, or infringement of the rights of a third party, including intellectual property rights.
            </p>
            <p>
                13. INTELLECTUAL PROPERTY
            </p>
            <p>
                All rights, titles, and interests in and to the Website (excluding Information provided by Users) are and will remain the exclusive property of Voltage Volunteering. Nothing in these Terms gives You the right to use the name Voltage Volunteering. Any feedback, comments, or suggestions You may provide regarding the Service is entirely voluntary and We will be free to use such feedback, comments, or suggestions as We see fit and without any obligation to You.
            </p>
        </div>
    )
}

export default TermsOfServiceOrganizationUser;