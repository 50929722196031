import React, { ChangeEvent, useContext } from "react";
import { Grid, TextField, makeStyles, Theme, createStyles, useMediaQuery } from "@material-ui/core";
import { IHelpMessage, HelpMessage } from "../../stores/models/HelpMessage";
import { RootContext } from "../../stores";
import { observer } from "mobx-react";
import { CONTACT_US_SUBJECT_MAX_LENGTH, CONTACT_US_MESSAGE_MAX_LENGTH, VOLUNTEER_NAME_MAX_LENGTH } from "../../logic/ValidationChecks/FieldLengths";
import TextFieldWithCharacterLimit from "../Shared/TextFieldWithCharacterLimit";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            display: 'flex',
            flexGrow: 1,
        }
    }),
);

interface ContactFormProps {
    helpMessage: HelpMessage;
    fieldsDisabled?: boolean;
    validationRun?: boolean;
}

const ContactForm = observer((props: ContactFormProps) => {

    /********* React hooks *********/

    const rootStore = useContext(RootContext);
    const helpMessage = props.helpMessage;
    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Event handlers *********/

    const onTextFieldChanged = (property: keyof IHelpMessage, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        helpMessage.setField(property, newValue);
    };

    /********* Helper constants *********/

    const firstNameError = props.validationRun ? helpMessage.errors.firstName : '';
    const lastNameError = props.validationRun ? helpMessage.errors.lastName : '';
    const emailError = props.validationRun ? helpMessage.errors.email : '';
    const subjectError = props.validationRun ? helpMessage.errors.subject : '';
    const messageError = props.validationRun ? helpMessage.errors.message : '';

    /********* Render *********/

    return (
        <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
                <TextField
                    label="First Name"
                    value={helpMessage.firstName}
                    variant="outlined"
                    onChange={event => onTextFieldChanged('firstName', event)}
                    required
                    className={classes.field}
                    inputProps={{ maxLength: VOLUNTEER_NAME_MAX_LENGTH }}
                    disabled={props.fieldsDisabled}
                    error={firstNameError.length > 0}
                    helperText={firstNameError.length > 0 ? firstNameError : undefined}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    label="Last Name"
                    value={helpMessage.lastName}
                    variant="outlined"
                    onChange={event => onTextFieldChanged('lastName', event)}
                    required
                    className={classes.field}
                    inputProps={{ maxLength: VOLUNTEER_NAME_MAX_LENGTH }}
                    disabled={props.fieldsDisabled}
                    error={lastNameError.length > 0}
                    helperText={lastNameError.length > 0 ? lastNameError : undefined}
                />
            </Grid>
            <Grid item sm={8} xs={12}>
                <TextField
                    label="Email Address"
                    value={helpMessage.email}
                    variant="outlined"
                    onChange={event => onTextFieldChanged('email', event)}
                    required
                    className={classes.field}
                    disabled={props.fieldsDisabled}
                    error={emailError.length > 0}
                    helperText={emailError.length > 0 ? emailError : undefined}
                />
            </Grid>
            <Grid item xs={12} sm={9}>
                <TextFieldWithCharacterLimit
                    characterLimit={CONTACT_US_SUBJECT_MAX_LENGTH}
                    TextFieldProps={{
                        label: "Subject",
                        value: helpMessage.subject,
                        variant: "outlined",
                        onChange: (event) => onTextFieldChanged('subject', event),
                        required: true,
                        className: classes.field,
                        disabled: props.fieldsDisabled,
                        error: subjectError.length > 0,
                        helperText: subjectError.length > 0 ? subjectError : undefined
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldWithCharacterLimit
                    characterLimit={CONTACT_US_MESSAGE_MAX_LENGTH}
                    TextFieldProps={{
                        label: "Message",
                        value: helpMessage.message,
                        variant: "outlined",
                        onChange: event => onTextFieldChanged('message', event),
                        required: true,
                        multiline: true,
                        rows: xsDown ? 3 : 2,
                        rowsMax: 20,
                        className: classes.field,
                        disabled: props.fieldsDisabled,
                        error: messageError.length > 0,
                        helperText: messageError.length > 0 ? messageError : undefined
                    }}
                />
            </Grid>
        </Grid>
    )
});

export default ContactForm;