import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, useMediaQuery, useTheme } from "@material-ui/core";
import MobileSearchFilters from './FilterBar/MobileSearchFilters';
import AbridgedDesktopSearchFilters from './FilterBar/LocalizedFilters/AbridgedDesktopSearchFilters';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filters: {
            // margin: theme.spacing(2, 0),
            // maxWidth: 600,
            [theme.breakpoints.up('md')]: {
                width: 450,
                paddingLeft: theme.spacing(2),
            },
            color: theme.palette.action.active,
            textAlign: 'left',
            flexShrink: 0,
        },
        mobileFilters: {
            padding: theme.spacing(0, 2, 2),
            maxWidth: 650,
            margin: 'auto'
        },
    })
);

interface SearchFiltersProps { }

const SearchFilters = observer((props: SearchFiltersProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    /********* Render *********/

    return (
        smDown
            ? <div className={classes.filters}>
                <div className={classes.mobileFilters}>
                    <MobileSearchFilters />
                </div>
            </div>
            : <div className={classes.filters}>
                {/* // : <DesktopSearchFilters /> */}
                <AbridgedDesktopSearchFilters />
            </div>
    );
});

export default SearchFilters;