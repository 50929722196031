import { format, isSameDay } from "date-fns";
import { computed, observable } from "mobx";

interface ICalendarEvent<T> {
    startTime: Date;
    endTime: Date;
    recurring?: boolean;
    label: string;
    dataObject?: T;
    icon?: React.ReactNode;
}

export class CalendarEvent<T> implements ICalendarEvent<T> {
    @observable startTime: Date;
    @observable endTime: Date;
    @observable recurring?: boolean;
    @observable label: string;
    @observable dataObject?: T;
    @observable icon?: React.ReactNode;

    constructor(event: ICalendarEvent<T>) {
        this.startTime = event.startTime;
        this.endTime = event.endTime;
        this.recurring = event.recurring;
        this.label = event.label;
        this.dataObject = event.dataObject;
        this.icon = event.icon;
    }

    @computed get formattedStartTime() {
        const minutes = format(this.startTime, 'mm');
        if (minutes === '00') {
            return format(this.startTime, 'haaa').toLowerCase();
        } else {
            return format(this.startTime, 'h:mmaaa').toLowerCase();
        }
    }

    @computed get multiDayEvent() {
        return !isSameDay(this.startTime, this.endTime);
    }

}