import ConfirmationDialog from "./ConfirmationDialog";
import React from "react";
import { useTheme } from "@material-ui/core";
import AddressDisplay from "./AddressDisplay";
import { Address } from "../../stores/models/Address";
import { DialogState } from "../../stores/models/DialogState";
import { observer } from "mobx-react";
import { Alert } from "@material-ui/lab";
import { MapMarker } from "mdi-material-ui";

interface AddressRemovalConfirmationProps {
    dialogState: DialogState;
    address: Address;
    onConfirm: (address: Address) => void;
}

const AddressRemovalConfirmation = observer((props: AddressRemovalConfirmationProps) => {

    const theme = useTheme();

    return (
        <ConfirmationDialog
            state={props.dialogState}
            title={'Delete Address?'}
            content={(
                <div>
                    {props.address.id < 0
                        ? null
                        : <Alert severity="warning" style={{ marginBottom: theme.spacing(2) }}>
                            You will be removing this address <strong>across your entire organization, </strong>
                                including for any opportunities it's listed on.
                        </Alert>
                    }
                    <div style={{ display: 'flex' }}>
                        <MapMarker color="primary" style={{ marginRight: theme.spacing(1) }} />
                        <AddressDisplay address={props.address} />
                    </div>
                </div>
            )}
            confirmText={'Delete'}
            onConfirm={() => { props.onConfirm(props.address); }}
            fullScreen={false}
        />
    );
});

export default AddressRemovalConfirmation;