import { flow, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { Volunteer, RequestQueue, Opportunity, Organization } from './models';
import { OpportunityApplication } from './models/OpportunityApplication';
import { ServerOpportunity, IServerOpportunity } from './models/server/ServerOpportunity';
import { IRequestConfig } from './models/RequestQueue';
import { IServerOrganization, ServerOrganization } from './models/server/ServerOrganization';
import { IVolunteerOverview, VolunteerOverview } from './models/VolunteerOverview';
import { IServerVolunteerDetails, VolunteerDetails } from './models/VolunteerDetails';
import { IStubOrganizationVolunteer, StubOrganizationVolunteer } from './models/StubOrganizationVolunteer';
import { IServerShift, ServerShift } from './models/server/ServerShift';
import { Dictionary } from '../logic/Dictionaries';
import { HelpMessage } from './models/HelpMessage';
import { OrganizationStubVolunteerLinking } from './models/OrganizationStubVolunteerLinking';
import { IAppError } from './models/AppError';
import { IServerUser, ServerUser } from './models/server/ServerUser';
import { Shift } from './models/Shift';
import { ShiftIdentification } from './models/ShiftIdentification';
import { getSupplementalDataRequest } from './models/SupplementalOrganizationData';
import { Role } from './models/Role';
import { APIGetRequestParameters, IAPIGetRequestParameters } from './models/APIGetRequestParameters';
import { FilterState } from './models/FilterState';
import { getUniqueElements } from '../logic/UtilityFunctions';
import { VolunteerDashboardData } from './models/VolunteerDashboardData';
import { IHttpResponse } from './interfaces/IHttpResponse';
import { IServerVolunteerDashboardData } from './models/server/ServerVolunteerDashboardData';

enum VolunteerRequestType {
    UpdateVolunteer = 'update-volunteer',
    UpdateOpportunitySpark = 'update-opp-spark',
    UpdateOrganizationSpark = 'update-org-spark',
    RespondToOpportunity = 'respond-to-opportunity',
    GetDashboard = 'get-dashboard',
    GetHandsRaised = 'get-hands-raised',
    GetOrganizationSparks = 'get-organization-sparks',
    GetOpportunitySparks = 'get-opportunity-sparks',
    GetVolunteersForOrganization = 'get-volunteers-for-org',
    GetVolunteerEmailsForOrganization = 'get-volunteer-emails-for-org',
    GetOrganizationVolunteersToMerge = 'get-org-volunteers-to-merge',
    GetVolunteerDetailsForOrganization = 'get-volunteer-details-for-org',
    MergeOrganizationVolunteers = 'merge-org-volunteers',
    UpdateVolunteerDetailsForOrganization = 'update-volunteer-details-for-org',
    AddStubVolunteer = 'add-stub-volunteer',
    ImportStubVolunteers = 'import-stub-volunteers',
    GetShifts = 'get-shifts',
    ContactRecruiter = 'contact-recruiter',
    LoadStubVolunteer = 'load-organization-stub-volunteer',
    RegisterAndLinkStubVolunteer = 'register-and-link-stub-volunteer',
    VerifyAndLinkStubVolunteer = 'verify-and-link-stub-volunteer',
    RequestNewLink = 'request-new-link',
    DeleteVolunteer = 'delete-volunteer',
    GetVolunteerOrganizations = 'get-volunteer-organizations'
}

type SavedSearchResultsFetch = VolunteerRequestType.GetHandsRaised
    | VolunteerRequestType.GetOrganizationSparks
    | VolunteerRequestType.GetOpportunitySparks;

export type ShiftOpportunities = Dictionary<number, { position: string, opportunityId: number, organizationId?: number }>;

export class VolunteerStore {
    private rootStore = {} as RootStore;
    private requestQueue: RequestQueue;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {}, { autoBind: true });

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
    }

    private get volunteer() {
        return this.rootStore.userStore.user.volunteer;
    }

    /*************** API Request methods ***************/

    // Get Volunteer Dashboard

    async getDashboard(parameters: Record<'id', number>) {
        this.requestQueue.emptyQueue();
        const response = await this.makeRequest<IHttpResponse<IServerVolunteerDashboardData>>(
            VolunteerRequestType.GetDashboard,
            {
                id: parameters.id
            }
        );
        if (response.body) {
            return new VolunteerDashboardData(response.body);
        }
    }
    
    // Get opportunities that the volunteer has responded to

    getHandsRaised = (limit: number, offset: number) => {
        return this.getResults<Opportunity>(VolunteerRequestType.GetHandsRaised, limit, offset);
    };

    // Get organizations that the volunteer has marked as sparks

    getOrganizationSparks = (limit: number, offset: number) => {
        return this.getResults<Organization>(VolunteerRequestType.GetOrganizationSparks, limit, offset);
    };

    // Get opportunities that the volunteer has marked as sparks

    getOpportunitySparks = (limit: number, offset: number) => {
        return this.getResults<Opportunity>(VolunteerRequestType.GetOpportunitySparks, limit, offset);
    };

    /*************** Private helper method ***************/

    private getResults = async <T extends Organization | Opportunity>(requestType: SavedSearchResultsFetch, limit: number, offset: number):
        Promise<{ results: T[], total: number } | undefined> => {
        try {
            if (this.volunteer) {
                const response = await this.makeRequest<{ total: number, results: Array<IServerOrganization | IServerOpportunity> }>(
                    requestType,
                    { limit, offset }
                );
                const { results } = response;
                if (requestType === VolunteerRequestType.GetOrganizationSparks) {
                    return {
                        ...response,
                        results: results.map((organization: IServerOrganization) => {
                            return new ServerOrganization(organization).deserialize() as T;
                        })
                    };
                } else {
                    return {
                        ...response,
                        results: (results as IServerOpportunity[]).map((opportunity: IServerOpportunity) => {
                            return new ServerOpportunity(opportunity).deserialize() as T;
                        })
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /*************** Update the volunteer's demographic information ***************/

    updateInformation = flow(function* (this: VolunteerStore, volunteer: Volunteer) {
        try {
            const responseData = yield this.makeRequest(
                VolunteerRequestType.UpdateVolunteer,
                { volunteer: volunteer.serialize() }
            );
            const updatedVolunteer = new Volunteer(responseData.volunteer);
            this.updateUserProfileDataIfNeeded(updatedVolunteer);
            return updatedVolunteer;
        } catch (error) {
            console.log(error);
        }
    });

    /*************** Update opportunity spark ***************/

    updateOpportunitySpark(oppId: number, interestSparked: boolean) {
        const volunteer = this.rootStore.userStore.user.volunteer;
        if (volunteer?.id) {
            return this.makeRequest(
                VolunteerRequestType.UpdateOpportunitySpark,
                {
                    opportunityId: oppId,
                    volunteer: {
                        id: volunteer.id
                    },
                    interestSparked: interestSparked
                }
            );
        }
    }

    /*************** Update organization spark ***************/

    updateOrganizationSpark(orgId: number, interestSparked: boolean) {
        const volunteer = this.rootStore.userStore.user.volunteer;
        if (volunteer?.id) {
            return this.makeRequest(
                VolunteerRequestType.UpdateOrganizationSpark,
                {
                    organizationId: orgId,
                    volunteer: {
                        id: volunteer.id
                    },
                    interestSparked: interestSparked
                }
            );
        }
    }

    /*************** Submit a response to an opportunity ***************/

    respondToOpportunity(opportunityApplication: OpportunityApplication) {
        const volunteer = this.rootStore.userStore.user.volunteer;
        if (volunteer === undefined) {
            return;
        }

        opportunityApplication.setAllFieldsDirty();
        if (opportunityApplication.validated) {
            return this.makeRequest<{ responseSubmitted: boolean }>(
                VolunteerRequestType.RespondToOpportunity,
                {
                    opportunityApplication: opportunityApplication.serialize(),
                    volunteer: {
                        id: volunteer.id
                    }
                }
            )
        }
    }

    /*************** Contact the recruiter for an opportunity ***************/

    contactRecruiter(message: HelpMessage, opportunityId?: number) {
        message.setAllFieldsDirty();
        if (message.validated) {
            return this.makeRequest<{ succeeded: boolean }>(
                VolunteerRequestType.ContactRecruiter,
                {
                    opportunityId: opportunityId,
                    message: message.serialize()
                }
            )
        }
    }

    /*************** Get volunteers for a particular organization ***************/

    async getVolunteersForOrganization(parameters: IAPIGetRequestParameters<keyof IVolunteerOverview>) {
        const organization = this.rootStore.userStore.user.organization;
        if (organization === undefined) {
            return;
        }

        const supplementalData = getSupplementalDataRequest(
            ['volunteerStatuses', 'roles'],
        );

        const serializedParameters = new APIGetRequestParameters(parameters).serialize();

        this.requestQueue.emptyQueue();
        return this.makeRequest<{ results: IVolunteerOverview[], total: number }>(
            VolunteerRequestType.GetVolunteersForOrganization,
            {
                id: organization.id,
                supplementalData: supplementalData,
                ...serializedParameters
            }
        );
    }

    async getVolunteerEmailsForOrganization(request?: { ids: number[], type: 'include' | 'exclude', filters?: FilterState }) {
        const organization = this.rootStore.userStore.user.organization;
        if (organization === undefined) {
            return;
        }

        const response = await this.makeRequest<{ emails: string[] }>(
            VolunteerRequestType.GetVolunteerEmailsForOrganization,
            {
                id: organization.id,
                request: {
                    ids: request?.ids.join(','),
                    type: request?.type
                },
                ...request?.filters?.serialize()
            }
        );
        return { emails: response.emails ? getUniqueElements(response.emails) : [] };
    }

    async getOrganizationVolunteerRecordsToMerge(volunteerIds: number[]) {
        const organization = this.rootStore.userStore.user.organization;
        if (organization === undefined) {
            return;
        }

        const supplementalData = getSupplementalDataRequest(
            ['organizationPermissions', 'roles']
        );

        const response = await this.makeRequest<{ volunteerOne: IServerVolunteerDetails, volunteerTwo: IServerVolunteerDetails }>(
            VolunteerRequestType.GetOrganizationVolunteersToMerge,
            {
                organizationId: organization.id,
                volunteerIds: volunteerIds,
                supplementalData: supplementalData
            }
        )
        return {
            volunteerOne: new VolunteerDetails(response.volunteerOne),
            volunteerTwo: new VolunteerDetails(response.volunteerTwo)
        }
    }

    async mergeOrganizationVolunteers(volunteerOneId: number, volunteerTwoId: number, mergedRecord: VolunteerDetails) {
        const organization = this.rootStore.userStore.user.organization;
        if (organization === undefined) {
            return;
        }

        const response = await this.makeRequest<{ mergedVolunteer: IVolunteerOverview }>(
            VolunteerRequestType.MergeOrganizationVolunteers,
            {
                volunteerOneId: volunteerOneId,
                volunteerTwoId: volunteerTwoId,
                mergedRecord: mergedRecord.serialize()
            }
        );

        return new VolunteerOverview(response.mergedVolunteer);
    }

    /*************** Get details for a particular volunteer for a particular organization ***************/

    async getOrganizationVolunteerInfo(volunteerId: number) {
        const organization = this.rootStore.userStore.user.organization;
        if (organization === undefined) {
            return;
        }

        const supplementalData = getSupplementalDataRequest(
            ['volunteerStatuses', 'roles', 'serviceEntryStatuses', 'serviceEntryTags', 'organizationPermissions']
        );

        const response = await this.makeRequest<{ shiftTotal: number, serviceEntriesTotal: number } & IServerVolunteerDetails>(
            VolunteerRequestType.GetVolunteerDetailsForOrganization,
            {
                id: organization.id,
                volunteerId: volunteerId,
                supplementalData: supplementalData
            }
        )
        return {
            volunteerDetails: new VolunteerDetails(response),
            shiftTotal: response.shiftTotal,
            serviceEntriesTotal: response.serviceEntriesTotal
        };
    }

    /*************** Update details for a particular volunteer for a particular organization ***************/

    async updateOrganizationVolunteerDetails(volunteerDetails: VolunteerDetails) {
        const organization = this.rootStore.userStore.user.organization;
        if (organization === undefined) {
            return;
        }

        const response = await this.makeRequest<IServerVolunteerDetails>(
            VolunteerRequestType.UpdateVolunteerDetailsForOrganization,
            {
                volunteerDetails: volunteerDetails.serialize()
            }
        )
        if (!('apiError' in response)) {
            return new VolunteerDetails(response);
        }
    }

    /*************** Add a new stub volunteer for a particular organization ***************/

    async addStubOrganizationVolunteer(stubVolunteer: StubOrganizationVolunteer) {
        const response = await this.makeRequest<IVolunteerOverview>(
            VolunteerRequestType.AddStubVolunteer,
            {
                stubVolunteer: stubVolunteer.serializedData
            }
        );
        return new VolunteerOverview(response);
    }

    /*************** Add a new stub volunteer for a particular organization ***************/

    async importStubOrganizationVolunteers(stubVolunteers: StubOrganizationVolunteer[]) {
        const response = await this.makeRequest<IVolunteerOverview[]>(
            VolunteerRequestType.ImportStubVolunteers,
            {
                stubVolunteers: stubVolunteers.map(stubVolunteer => stubVolunteer.serializedData)
            }
        );
        return response.map(volunteerData => new VolunteerOverview(volunteerData));
    }

    /*************** Update email verification status and/or link a stub volunteer to a user account ***************/

    async loadOrganizationStubVolunteer(token: string) {
        const response = await this.makeRequest<{ organizationId: number, organizationName: string, volunteer: IStubOrganizationVolunteer } | { error: IAppError }>(
            VolunteerRequestType.LoadStubVolunteer,
            { token }
        );
        if ('volunteer' in response) {
            return {
                ...response,
                volunteer: new StubOrganizationVolunteer(response.volunteer)
            };
        } else {
            return response;
        }
    }

    /*************** Request a new link for a stub volunteer record ***************/

    async requestNewLinkForStubVolunteer(token: string) {
        const response = await this.makeRequest<{ error?: IAppError }>(
            VolunteerRequestType.RequestNewLink,
            { token }
        );
        return response;
    }

    /*************** Update email verification status and/or link a stub volunteer to a user account ***************/

    async verifyAndLinkStubVolunteer(linkData: OrganizationStubVolunteerLinking) {
        const requestType = linkData.creatingNewUser ? VolunteerRequestType.RegisterAndLinkStubVolunteer : VolunteerRequestType.VerifyAndLinkStubVolunteer;
        const response = await this.makeRequest<{ error?: IAppError, user?: IServerUser }>(
            requestType,
            requestType === VolunteerRequestType.RegisterAndLinkStubVolunteer
                ? {
                    ...linkData.serialize(),
                    ...linkData.serialize().stubUser
                }
                : linkData.serialize()
        );
        if (response.user) {
            const user = new ServerUser(response.user).deserialize();
            this.rootStore.userStore.setAuthenticatedUser(user);
        }

        return response;
    }

    /*************** Get the current volunteer's shifts ***************/

    async getVolunteerShifts(startDate: Date, endDate: Date) {
        const volunteer = this.rootStore.userStore.user.volunteer;
        if (volunteer === undefined) return;

        const response = await this.makeRequest<{
            shifts: IServerShift[],
            shiftOpportunities: ShiftOpportunities,
            currentVolunteerRegistrations: ShiftIdentification[]
        }>(
            VolunteerRequestType.GetShifts,
            {
                startDate: startDate,
                endDate: endDate
            }
        )
        const topLevelShifts = response.shifts.map(serverShift => (new ServerShift(serverShift)).deserialize());
        const volunteerRegistrations = response.currentVolunteerRegistrations.map(registration => new ShiftIdentification(registration));
        const shiftInstances = volunteerRegistrations.map(registrationIdentificationData => {
            const topLevelShift = topLevelShifts.find(shift => registrationIdentificationData.parentId === shift.id || registrationIdentificationData.shiftId === shift.id);
            if (topLevelShift) {
                return topLevelShift.getInstanceByIdentificationData(registrationIdentificationData);
            }
        }).filter(element => element !== undefined) as Shift[];
        return { shiftOpportunities: response.shiftOpportunities, shifts: shiftInstances };
    }

    /*************** Delete volunteer ***************/

    async deleteVolunteer(volunteerIds: number[]) {
        const response = await this.makeRequest<{ success: boolean }>(
            VolunteerRequestType.DeleteVolunteer,
            {
                volunteerIds: volunteerIds
            }
        );
        return response;
    }

    /*************** Get organizations linked to volunteer ***************/

    async getVolunteerOrganizations() {
        if (!this.rootStore.userStore.user.isVolunteer) return;
        const response = await this.makeRequest<{ id: number, organization: string, roles: { id: number, role: string }[] }[]>(
            VolunteerRequestType.GetVolunteerOrganizations
        );
        if (response && Array.isArray(response)) {
            return response.map(arrayElement => {
                return {
                    ...arrayElement,
                    roles: arrayElement.roles.map(role => {
                        return new Role({
                            id: role.id,
                            position: role.role,
                            tags: []
                        })
                    })
                }
            })
        }
        return response;
    }

    /*************** Private methods ***************/

    private updateUserProfileDataIfNeeded(newVolunteer: Volunteer) {
        const userStore = this.rootStore.userStore;
        if (userStore.user.isVolunteer) {
            const isLoggedInUser = newVolunteer.id === userStore.user.volunteer?.id;
            if (isLoggedInUser) {
                userStore.user.setProfileData(newVolunteer);
            }
        }
    }

    /*************** Private request handling methods ***************/

    private makeRequest<T>(type: VolunteerRequestType, data?: any) {
        return new Promise<T>(async (resolve, reject) => {
            try {
                const requestConfig = this.generateRequestConfig(type, data);
                const responseData = await this.requestQueue.makeAPIRequest<T>(requestConfig);
                resolve(responseData);
            } catch (error) {
                reject(error);
            };
        })
    }

    private generateRequestConfig(type: VolunteerRequestType, data: any): IRequestConfig {
        const volunteerAPIEndpoint = '/api/volunteers';
        switch (type) {
            case VolunteerRequestType.UpdateVolunteer:
                return {
                    method: 'put',
                    url: `${volunteerAPIEndpoint}/update`,
                    data: data
                };
            case VolunteerRequestType.UpdateOpportunitySpark:
                return {
                    method: 'put',
                    url: `${volunteerAPIEndpoint}/update-opportunity-spark`,
                    data: data
                }
            case VolunteerRequestType.UpdateOrganizationSpark:
                return {
                    method: 'put',
                    url: `${volunteerAPIEndpoint}/update-organization-spark`,
                    data: data
                }
            case VolunteerRequestType.RespondToOpportunity:
                return {
                    method: 'post',
                    url: `${volunteerAPIEndpoint}/respond-to-opportunity`,
                    data: data
                }
            case VolunteerRequestType.ContactRecruiter:
                return {
                    method: 'post',
                    url: `${volunteerAPIEndpoint}/contact-recruiter`,
                    data: data
                }
            case VolunteerRequestType.GetHandsRaised:
            case VolunteerRequestType.GetOrganizationSparks:
            case VolunteerRequestType.GetOpportunitySparks:
                const route = type === VolunteerRequestType.GetOrganizationSparks
                    ? 'get-organization-sparks'
                    : type === VolunteerRequestType.GetOpportunitySparks
                        ? 'get-opportunity-sparks'
                        : 'get-hands-raised';
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/${route}`,
                    forceRefresh: true,
                    params: data
                }
            case VolunteerRequestType.GetDashboard:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/${data.id}/dashboard`,
                    forceRefresh: true,
                }
            case VolunteerRequestType.GetVolunteersForOrganization:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/organization/${data.id}`,
                    forceRefresh: true,
                    params: data
                }
            case VolunteerRequestType.GetVolunteerEmailsForOrganization:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/organization/${data.id}/emails`,
                    forceRefresh: true,
                    params: data
                }
            case VolunteerRequestType.GetOrganizationVolunteersToMerge:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/organization/${data.organizationId}/merge`,
                    forceRefresh: true,
                    params: data
                }
            case VolunteerRequestType.MergeOrganizationVolunteers:
                return {
                    method: 'post',
                    url: `${volunteerAPIEndpoint}/merge-volunteers`,
                    data: data
                }
            case VolunteerRequestType.GetVolunteerDetailsForOrganization:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/organization/${data.id}/volunteer/${data.volunteerId}`,
                    forceRefresh: true,
                    params: data
                }
            case VolunteerRequestType.UpdateVolunteerDetailsForOrganization:
                return {
                    method: 'put',
                    url: `${volunteerAPIEndpoint}/update-organization-volunteer-details`,
                    data: data
                }
            case VolunteerRequestType.AddStubVolunteer:
                return {
                    method: 'post',
                    url: `${volunteerAPIEndpoint}/add-stub-volunteer`,
                    data: data
                }
            case VolunteerRequestType.ImportStubVolunteers:
                return {
                    method: 'post',
                    url: `${volunteerAPIEndpoint}/import-stub-volunteers`,
                    data: data
                }
            case VolunteerRequestType.GetShifts:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/shifts`,
                    forceRefresh: true,
                    params: data
                }
            case VolunteerRequestType.LoadStubVolunteer:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/load-stub-volunteer`,
                    forceRefresh: true,
                    params: data
                }
            case VolunteerRequestType.RegisterAndLinkStubVolunteer:
                return {
                    method: 'post',
                    url: `${volunteerAPIEndpoint}/register-and-link-stub-volunteer`,
                    data: data
                }
            case VolunteerRequestType.VerifyAndLinkStubVolunteer:
                return {
                    method: 'put',
                    url: `${volunteerAPIEndpoint}/verify-and-link-stub-volunteer`,
                    data: data
                }
            case VolunteerRequestType.RequestNewLink:
                return {
                    method: 'post',
                    url: `${volunteerAPIEndpoint}/new-stub-volunteer-token`,
                    data: data
                }
            case VolunteerRequestType.DeleteVolunteer:
                return {
                    method: 'delete',
                    url: `${volunteerAPIEndpoint}/delete`,
                    data: data
                }
            case VolunteerRequestType.GetVolunteerOrganizations:
                return {
                    method: 'get',
                    url: `${volunteerAPIEndpoint}/organizations`,
                    forceRefresh: true
                }
        }
    }
}

