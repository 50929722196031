import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, getMaxLengthValidationCheck, getFieldRequiredValidationCheck } from "./Validation";
import { Organization } from "../../stores";
import { websiteIsValid } from "../RegexValidation";
import { ORGANIZATION_NAME_MAX_LENGTH, ORGANIZATION_MISSION_MAX_LENGTH, ORGANIZATION_DESCRIPTION_MAX_LENGTH } from "./FieldLengths";

// Organization details validation checks
const organizationChecks: (organization: Organization) => ValidationCheck<Organization>[] =
    (organization: Organization) => {
        let checks: ValidationCheck<Organization>[] = [
            getFieldRequiredValidationCheck(organization.name, 'name', `Please enter your organization's name.`),
            getMaxLengthValidationCheck(organization.name, 'name', ORGANIZATION_NAME_MAX_LENGTH),
            getFieldRequiredValidationCheck(organization.description, 'description', `Please enter a description for your organization.`),
            getMaxLengthValidationCheck(organization.description, 'description', ORGANIZATION_DESCRIPTION_MAX_LENGTH),
            {
                name: 'valid website',
                condition: (organization: Organization) => websiteIsValid(organization.website),
                error: new ValidationError(`Please enter a valid website.`, ['website'])
            }];

        if (!organization.isForProfit) {
            checks.push(
                getFieldRequiredValidationCheck(organization.mission, 'mission', `Please enter your organization's mission.`),
                getMaxLengthValidationCheck(organization.mission, 'mission', ORGANIZATION_MISSION_MAX_LENGTH),
            )
        }
        return checks;
    };

export function validateOrganization(organization: Organization) {
    return getValidationErrors(organizationChecks, organization);
} 