import { useContext, useState, useEffect, Fragment } from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Container, Paper, Typography, Divider } from "@material-ui/core";
import { useParams } from "react-router-dom";
import StepSequencer from "../../Shared/StepSequencer";
import useLinkVolToOrgSteps, { VolunteerLinkingSteps } from "./Steps/LinkVolToOrgSteps";
import { EmailPreference, OrganizationStubVolunteerLinking, VolunteerRelation } from "../../../stores/models/OrganizationStubVolunteerLinking";
import { Step } from "../../../stores/models/Step";
import Footer from "../../Shared/Footer";
import { RootContext } from "../../../stores";
import { StubOrganizationVolunteer } from "../../../stores/models/StubOrganizationVolunteer";
import ErrorMessage from "../../Shared/ErrorMessage";
import LoadingIndicator from "../../Shared/LoadingIndicator";
import NewLinkRequested from "./NewLinkRequested";
import { EXPIRED_TOKEN_ERROR_CODE, INVALID_TOKEN_ERROR_CODE, USED_TOKEN_ERROR_CODE } from "../../../data/ErrorCodes";
import { getSearchLink } from "../../Navigation/Links/UrlConstructors";
import useOrganizationRegistrationForm from "../../Shared/Forms/LoadOrganizationRegistrationForm";
import InstructionsForAuthenticatedUser from "./InstructionsForAuthenticatedUser";
import EmbeddedForm from "../../Shared/Forms/EmbeddedForm/EmbeddedForm";
import CenteredLoadingSpinner from "../../Shared/CenteredLoadingSpinner";
import { useNavigateInternally } from "../../Navigation/Hooks";
import { NavigateInternally } from "../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'relative'
        },
        content: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(4),
            display: 'inline-block',
            position: 'relative' // the loading spinner refers to this element
        },
        title: {
            marginBottom: theme.spacing(2),
            color: theme.palette.action.active
        },
        alert: {
            marginBottom: theme.spacing(1),
            textAlign: 'left',
            maxWidth: '400px'
        },
        divider: {
            width: '90%',
            margin: '30px auto',
        },
        embeddedForm: {
            display: 'flex',
            justifyContent: 'center'
        }
    })
);

const SUBMIT_TEXT = 'Submit';
const LINK_EXPIRATION_PERIOD = '24 hours';

const LinkVolToOrgStepSequencer = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;
    const formStore = rootStore.formStore;
    const navigate = useNavigateInternally();
    const { token } = useParams() as { token: string };

    /********* Helper method *********/

    const getOrganizationStubVolunteerLinkingData = () => {
        return token ? new OrganizationStubVolunteerLinking(token) : undefined;
    }

    /********* State *********/

    const [stubVolunteerData, setStubVolunteerData] = useState<{ organizationId: number, organizationName: string, volunteer: StubOrganizationVolunteer }>();
    const [linkData, setLinkData] = useState(getOrganizationStubVolunteerLinkingData());
    const [currentStep, setCurrentStep] = useState<Step<OrganizationStubVolunteerLinking>>();
    const [stepsCompleted, setStepsCompleted] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [warningCode, setWarningCode] = useState<number>();
    const [newLinkRequested, setNewLinkRequested] = useState(false);
    const [orgRegistrationFormCompleted, setOrgRegistrationFormCompleted] = useState(false);
    const [orgRegistrationFormLoading, setOrgRegistrationFormLoading] = useState(false);

    const steps = useLinkVolToOrgSteps(linkData);
    const [registrationForm, registrationFormLoaded] = useOrganizationRegistrationForm(stubVolunteerData?.organizationId);

    /********* Effect *********/

    useEffect(() => {
        loadStubVolunteerData();
        setLinkData(getOrganizationStubVolunteerLinkingData());
    }, [token])

    useEffect(() => {
        if (linkData && stubVolunteerData) {
            linkData.setLinkDetails(stubVolunteerData);
        }
    }, [stubVolunteerData])

    /********* API Requests *********/

    const loadStubVolunteerData = async () => {
        try {
            if (token) {
                setIsLoading(true);
                // Send the token and the email as parameters
                const response = await volunteerStore.loadOrganizationStubVolunteer(token);
                if (response) {
                    if ('error' in response) {
                        setWarningCode(response.error.code);
                    } else {
                        setStubVolunteerData(response);
                    }
                }
                setIsLoading(false);
            }
        } catch (err) {
            throw err;
        } finally {
            setIsLoading(false);
        }
    }

    /********* Event handlers *********/

    const onAllStepsCompleted = async (stepObject: OrganizationStubVolunteerLinking) => {
        try {
            if (getForwardButtonText() === SUBMIT_TEXT || currentStep?.name === VolunteerLinkingSteps.VerifyCorrectAccount) {
                const submission = await onSubmit();
                return { success: true };
            }
        } catch (err) {
            return { success: false, error: err as string };
        }
    }

    const onCurrentStepChanged = (step: Step<OrganizationStubVolunteerLinking>) => {
        setCurrentStep(step);
    }

    const setLastStepIncomplete = () => {
        if (steps.length > 0) {
            steps[steps.length - 1].state.setCompleted(false);
        }
    }

    const onSubmit = async () => {
        try {
            if (linkData) {
                if (linkData.validated) {
                    currentStep?.state.setLoading(true);
                    const response = await volunteerStore.verifyAndLinkStubVolunteer(linkData);
                    if (response.error) {
                        setWarningCode(response.error.code);
                    } else if (response === '' || !('apiError' in response)) { // TODO: Could probably be cleaned up
                        setStepsCompleted(true);
                    } else {
                        setLastStepIncomplete();
                    }
                    currentStep?.state.setLoading(false);
                } else {
                    linkData.setAllFieldsDirty();
                }
            }
        } catch (err) {
            throw err;
        } finally {
            currentStep?.state.setLoading(false);
        }
    }

    const onOrgRegistrationFormSubmit = async () => {
        if (!registrationForm || stubVolunteerData === undefined) return { succeeded: false };
        setOrgRegistrationFormLoading(true);
        const response = await formStore.submitForm(registrationForm, stubVolunteerData.organizationId.toString());
        setOrgRegistrationFormLoading(false);
        if (response.succeeded) {
            setOrgRegistrationFormCompleted(true);
        }
        return response;
    };

    const onInvalidLinkButtonClicked = async () => {
        switch (warningCode) {
            case USED_TOKEN_ERROR_CODE:
                // TODO: Implement, link to notification preferences section of profile if logged in.
                // Otherwise prompt login.
                onBrowseOpportunitiesClicked();
                break;
            case EXPIRED_TOKEN_ERROR_CODE:
                setIsLoading(true);
                const response = await volunteerStore.requestNewLinkForStubVolunteer(token);
                if (response.error) {
                    setWarningCode(response.error.code);
                } else {
                    setNewLinkRequested(true);
                }
                setIsLoading(false);
                break;
            default:
                break;
        }
    }

    // TODO: Remove after notification preferences section is implemented.
    const onBrowseOpportunitiesClicked = () => {
        navigate(getSearchLink());
    }

    /********* Helper methods *********/

    const shouldDisplayHeader = () => {
        if (currentStep?.name === VolunteerLinkingSteps.RelationSelection ||
            (linkData?.relation === VolunteerRelation.Contact && currentStep?.name !== VolunteerLinkingSteps.EmailPreferenceSaved && currentStep?.name !== VolunteerLinkingSteps.EmailsAccepted)) {
            return true;
        }
    }

    const getForwardButtonText = () => {
        if (currentStep?.name === VolunteerLinkingSteps.TermsOfService ||
            currentStep?.name === VolunteerLinkingSteps.Registration ||
            (currentStep?.name === VolunteerLinkingSteps.RelationSelection && linkData?.relation === VolunteerRelation.Neither) ||
            (currentStep?.name === VolunteerLinkingSteps.EmailPreferenceSelection && linkData?.emailPreference === EmailPreference.RemoveMe)) {
            return SUBMIT_TEXT;
        } else {
            return 'Next';
        }
    }

    /********* Render *********/

    if (token === undefined) {
        return <NavigateInternally to="/" replace />
    }

    return (
        <Fragment>
            <div className={classes.root}>
                {isLoading || (stepsCompleted && !registrationFormLoaded)
                    ? <LoadingIndicator />
                    : <Container>
                        {(stubVolunteerData && warningCode === undefined)
                            ? !stepsCompleted
                                ? <Paper className={classes.content}>
                                    {shouldDisplayHeader() &&
                                        <div>
                                            <Typography variant="h3" className={classes.title}>{`Hello there!`}</Typography>
                                            <Typography variant="h4" className={classes.title}>
                                                Welcome to Voltage Volunteering! It looks like you're here because {stubVolunteerData?.organizationName} is trying to connect with
                                            </Typography>
                                            <Typography variant="h4" color="primary">{stubVolunteerData?.volunteer.firstName} {stubVolunteerData?.volunteer.lastName}</Typography>
                                            <Divider className={classes.divider} />
                                        </div>
                                    }
                                    <StepSequencer
                                        steps={steps}
                                        onAllStepsCompleted={onAllStepsCompleted}
                                        onCurrentStepChanged={onCurrentStepChanged}
                                        getForwardButtonText={getForwardButtonText}
                                    />
                                </Paper>
                                : (registrationForm && registrationForm.version > 0 && !orgRegistrationFormCompleted)
                                    ? <div className={classes.embeddedForm}>
                                        <EmbeddedForm formStructure={registrationForm} onFormSubmit={onOrgRegistrationFormSubmit} />
                                        {orgRegistrationFormLoading && <CenteredLoadingSpinner />}
                                    </div>
                                    : <Paper className={classes.content}>
                                        <InstructionsForAuthenticatedUser
                                            organizationId={stubVolunteerData.organizationId}
                                            organizationName={stubVolunteerData.organizationName}
                                        />
                                    </Paper>
                            : <Paper className={classes.content}>
                                {!newLinkRequested
                                    ? warningCode === INVALID_TOKEN_ERROR_CODE
                                        ? <ErrorMessage
                                            title={'Oops! This link is invalid.'}
                                            details={"Please double check that you've entered the link exactly as it appears in your email."}
                                        />
                                        : warningCode === USED_TOKEN_ERROR_CODE
                                            ? <ErrorMessage
                                                title={'Oops! This link is no longer valid.'}
                                                details={"You've already been linked to this organization."}
                                                // details={"Please check your account profile to adjust your preferences for any linked organizations."}
                                                ButtonProps={{
                                                    children: 'Browse Opportunities',
                                                    onClick: onInvalidLinkButtonClicked
                                                    // children: "Adjust Preferences", // TODO: Should prompt the user to login if they aren't already logged in
                                                    // onClick: onInvalidLinkButtonClicked
                                                }}
                                            />
                                            : <ErrorMessage
                                                title={'Oops! This link is no longer valid.'}
                                                details={`For security purposes, links expire after ${LINK_EXPIRATION_PERIOD}. Please request a new link to continue.`}
                                                ButtonProps={{
                                                    children: "Request New Link",
                                                    onClick: onInvalidLinkButtonClicked
                                                }}
                                            />
                                    : <NewLinkRequested />
                                }
                            </Paper>
                        }
                    </Container>
                }
            </div>
            <Footer />
        </Fragment >
    );
});

export default LinkVolToOrgStepSequencer;