import { Color } from "@material-ui/lab";

const API_ERROR_NAME = "APIError";

export const isAPIError = (x: any): x is APIError => {
    if (typeof x === 'object') {
        return (x as { name: string }).name !== undefined
            && (x as { name: string }).name === API_ERROR_NAME;
    } else {
        return false;
    }
}

export interface IAPIError {
    code: number;
    message: string;
    dialogTitle?: string;
    errorTitle?: string;
    severity?: Color;
}

export class APIError extends Error {
    code: number;
    dialogTitle?: string;
    errorTitle?: string;
    severity?: Color;

    constructor(error: IAPIError) {
        super(error.message);
        this.name = API_ERROR_NAME;
        this.code = error.code;
        this.dialogTitle = error.dialogTitle;
        this.errorTitle = error.errorTitle;
        this.severity = error.severity;
    }
}