import { observable, action, computed, makeObservable } from "mobx";
import { Fields } from "./Fields";
import validatePasswordResetData from "../../logic/ValidationChecks/PasswordResetDataValidation";

export interface IPasswordResetDataFields {
    token?: string;
    userId?: number;
    currentPassword?: string;
    newPassword: string;
    confirmedPassword: string;
}

export class PasswordResetData extends Fields<IPasswordResetDataFields, PasswordResetData> implements IPasswordResetDataFields {
    @observable token?: string;
    @observable userId?: number;
    @observable currentPasswordRequired: boolean = false;
    @observable currentPassword?: string;
    @observable newPassword = "";
    @observable confirmedPassword = "";

    constructor(tokenOrUserId: string | number, currentPasswordRequired?: boolean) {
        super();

        makeObservable(this);

        if (typeof tokenOrUserId === 'string') {
            this.token = tokenOrUserId;
        } else {
            this.userId = tokenOrUserId;
        }

        this.currentPasswordRequired = currentPasswordRequired || false;
    }

    /***** Setters *****/

    @action setCurrentPassword(currentPassword: string) {
        this.currentPassword = currentPassword;
    }
    
    @action setNewPassword(newPassword: string) {
        this.newPassword = newPassword;
    }

    @action setConfirmedPassword(confirmedPassword: string) {
        this.confirmedPassword = confirmedPassword;
    }

    /***** Computed properties *****/

    @computed get validationErrors() {
        return validatePasswordResetData(this);
    }

    @computed get passwordsMatch() {
        return this.newPassword === this.confirmedPassword;
    }
}