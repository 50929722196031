import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, isValidEmail } from "./Validation";
import { LoginData } from "../../stores/models/LoginData";

const loginDataChecks: ValidationCheck<LoginData>[] = [{
    name: 'email length',
    condition: (loginData: LoginData) => {
        return loginData.username.length > 0;
    },
    error: new ValidationError('Please enter your email.', ['username'])
}, {
    name: 'valid email',
    condition: (loginData: LoginData) => {
        return loginData.username.length === 0 || isValidEmail(loginData.username);
    },
    error: new ValidationError('Please enter a valid email address.', ['username'])
}, {
    name: 'password length',
    condition: (loginData: LoginData) => {
        return loginData.password.length > 0;
    },
    error: new ValidationError('Please enter your password.', ['password'])
}];

export default function validateLoginData(loginData: LoginData) {
    return getValidationErrors(loginDataChecks, loginData);
}