import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import Logo from '../Navigation/VV-transparent-bkg-450x450.png'
import ExternalLink from '../Navigation/Components/ExternalLink';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            marginTop: theme.spacing(4),
            background: 'rgba(0, 0, 0, 0.06)',
            padding: theme.spacing(2),
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        voltageLink: {
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        subheader: {
            marginRight: theme.spacing(1.5),
            color: '#4e4e4e',
            fontSize: '.85em',
            textTransform: 'uppercase',
            '&:not(:first-child)': {
                fontWeight: 'bold',
                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        },
        imageWrapper: {
            cursor: 'pointer'
        },
        image: {
            width: '45px',
            marginRight: theme.spacing(1)
        },
        copyright: {
            textAlign: 'right'
        }
    })
);

interface OrganizationPortalFooterProps {
    copyright: JSX.Element;
}

const OrganizationPortalFooter = observer((props: OrganizationPortalFooterProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <div className={classes.footer}>
            <ExternalLink to="https://www.voltagevolunteering.com" target='_blank' className={classes.voltageLink}>
                <div className={classes.imageWrapper}>
                    <img src={Logo} alt="Voltage Volunteering Logo" className={classes.image} />
                </div>
                <div>
                    <Typography className={classes.subheader}>Powered by</Typography>
                    <Typography className={classes.subheader}>Voltage Volunteering</Typography>
                </div>
            </ExternalLink>
            <div className={classes.copyright}>
                {props.copyright}
            </div>
        </div>
    );
});

export default OrganizationPortalFooter;