import { observable } from "mobx";
import { IRole } from "./Role";

export interface IVolunteerOverview {
    id: number;
    firstName: string;
    lastName: string;
    status: string;
    roles: IRole[];
}

export class VolunteerOverview implements IVolunteerOverview {
    @observable id: number;
    @observable firstName: string;
    @observable lastName: string;
    @observable status: string;
    @observable roles: IRole[];

    constructor(volunteerOverview: IVolunteerOverview) {
        this.id = volunteerOverview.id;
        this.firstName = volunteerOverview.firstName;
        this.lastName = volunteerOverview.lastName;
        this.status = volunteerOverview.status;
        this.roles = volunteerOverview.roles || [];
    }
}