import { observable, makeObservable } from "mobx";

export interface IFilterData {
    childcareAvailable: boolean;
    familyOpportunity: boolean;
    groupOpportunity: boolean;
    shortStandingTime: boolean;
    noHeavyLifting: boolean;
    courtOrdered: boolean;
}

export class FilterData implements IFilterData {
    childcareAvailable: boolean;
    familyOpportunity: boolean;
    groupOpportunity: boolean;
    shortStandingTime: boolean;
    noHeavyLifting: boolean;
    courtOrdered: boolean;

    constructor(filterData?: IFilterData) {
        makeObservable(this, {
            childcareAvailable: observable,
            familyOpportunity: observable,
            groupOpportunity: observable,
            shortStandingTime: observable,
            noHeavyLifting: observable,
            courtOrdered: observable
        });

        this.childcareAvailable = filterData?.childcareAvailable || false;
        this.familyOpportunity = filterData?.familyOpportunity || false;
        this.groupOpportunity = filterData?.groupOpportunity || false;
        this.shortStandingTime = filterData?.shortStandingTime || false;
        this.noHeavyLifting = filterData?.noHeavyLifting || false;
        this.courtOrdered = filterData?.courtOrdered || false;
    }
}