import { observer } from "mobx-react";
import { useMediaQuery, Theme, TextField, Typography, makeStyles, createStyles } from "@material-ui/core";
import { DialogState } from "../../../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../../../Shared/Dialogs/ThemedDialogWithSpinner";
import { Fragment, useState } from "react";
import SuccessSnackbar from "../../../../Shared/SuccessSnackbar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            textAlign: 'right',
            color: theme.palette.action.active,
            marginBottom: theme.spacing(1)
        }
    }),
);

interface VolunteerEmailsDialogProps {
    state: DialogState;
    emails: string[];
    numSelected: number;
}

const VolunteerEmailsDialog = observer((props: VolunteerEmailsDialogProps) => {

    /*********************** React hooks ***********************/

    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const styles = useStyles();

    /*********************** State ***********************/

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    /*********************** Helper methods ***********************/

    const getFormattedEmails = () => {
        let formattedEmails = '';
        props.emails.forEach((email, index) => {
            formattedEmails = formattedEmails.concat(email);
            if (index < props.emails.length - 1) {
                formattedEmails = formattedEmails.concat(', ');
            }
        });
        return formattedEmails;
    }

    const formattedEmails = getFormattedEmails();

    const copyTextToClipboard = () => {
        navigator.clipboard.writeText(formattedEmails);
        setSnackbarOpen(true);
    }

    /*********************** Render ***********************/

    return (
        <Fragment>
            <ThemedDialogWithSpinner
                state={props.state}
                title="Volunteer Emails"
                DialogProps={{ fullScreen: xsDown, maxWidth: 'sm' }}
                primaryButtonProps={{ children: 'Copy to Clipboard', disabled: formattedEmails.length === 0 }}
                onSubmit={copyTextToClipboard}
            >
                {formattedEmails.length > 0
                    ? <Fragment>
                        <Typography
                            className={styles.label}
                            style={{ width: xsDown ? undefined : '500px' }}
                        >
                            {props.emails.length} unique {props.emails.length === 1 ? 'email' : 'emails'} available for {props.numSelected} selected {props.numSelected === 1 ? 'record' : 'records'}.
                        </Typography>
                        <TextField
                            value={formattedEmails}
                            multiline
                            maxRows={xsDown ? undefined : 15}
                            variant="outlined"
                            fullWidth
                            style={{ width: xsDown ? undefined : '500px' }}
                        />
                    </Fragment>
                    : <Typography>
                        No emails available for selected volunteers.
                    </Typography>
                }
            </ThemedDialogWithSpinner>
            <SuccessSnackbar
                open={snackbarOpen}
                message={'Emails copied!'}
                onClose={() => setSnackbarOpen(false)}
            />
        </Fragment>
    );
});

export default VolunteerEmailsDialog;