const semanticVersionRegex = /(0|[1-9]\d*)(?=(\.|$))/g;

export const parseSemanticVersion = (version: string) => {
    const regexMatches = version.match(semanticVersionRegex);
    if (regexMatches?.length === 3) {
        return {
            majorVersion: regexMatches[0],
            minorVersion: regexMatches[1],
            patchVersion: regexMatches[2]
        }
    }
}