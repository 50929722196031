import React, { useContext, useState, useEffect } from "react";
import { observer } from 'mobx-react'
import { RootContext, ICause } from "../../stores";
import { DialogState } from "../../stores/models/DialogState";
import IconTileSelector from "../Shared/IconTileSelector";
import { OptionCollection } from "../../stores/models/OptionCollection";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import { makeStyles, Theme, createStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        },
        alert: {
            marginBottom: theme.spacing(2)
        }
    })
);

const CAUSES_DIALOG_ID = 'causes-dialog';

interface CausesDialogProps {
    state: DialogState;
    selectedCauses: ICause[];
    onConfirm: (causes: ICause[]) => void;
    maxSelections?: number;
}

const CausesDialog = observer((props: CausesDialogProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const rootStore = useContext(RootContext);
    const causeStore = rootStore.causeStore;

    /***** State *****/

    const getOptionCollection = () => {
        const selections = props.selectedCauses.map(selection => { return { value: selection.cause } });
        return new OptionCollection('value', causeStore.causeOptions, selections);
    };

    const [draftOptionCollection, setDraftOptionCollection] = useState(getOptionCollection());

    /***** Effects *****/

    useEffect(() => {
        if (props.state.open) {
            setDraftOptionCollection(getOptionCollection());
        }
    }, [props.state.open])

    /***** Event handlers *****/

    const handleConfirmCauses = () => {
        if (!hasError()) {
            props.onConfirm(draftOptionCollection.selectedOptions);
        } else {
            scrollToTop();
        }
    };

    /***** Helper methods *****/

    const scrollToTop = () => {
        document.querySelector(`#${CAUSES_DIALOG_ID}`)?.scrollTo(0, 0);
    }

    const hasError = () => {
        return props.maxSelections && draftOptionCollection.selections.length > props.maxSelections;
    }

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            title={props.selectedCauses.length > 0 ? 'Edit Causes' : 'Add Causes'}
            primaryButtonProps={{ children: 'Save' }}
            onSubmit={handleConfirmCauses}
            contentId={CAUSES_DIALOG_ID}
            DialogProps={{ fullScreen: xsDown }}
        >
            {hasError()
                ? <Alert severity="error" className={classes.alert}>
                    <Typography className={classes.bold}>Too many causes selected.</Typography>
                    <Typography>
                        {`You currently have ${draftOptionCollection.selections.length} causes selected.`}
                    </Typography>
                    <Typography>
                        {`You may select a `}
                        <span className={classes.bold}>maximum of {props.maxSelections} causes.</span>
                    </Typography>
                </Alert>
                : null
            }
            <IconTileSelector optionCollection={draftOptionCollection} />
        </ThemedDialogWithSpinner>
    );
});

export default CausesDialog;