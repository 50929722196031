import { observer } from "mobx-react";
import { makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import React, { useContext, useState, useEffect, Fragment } from "react";
import { DialogState } from "../../../stores/models/DialogState";
import { RootContext, ShiftRegistration, Volunteer } from "../../../stores";
import { ClipboardEditOutline } from "mdi-material-ui";
import ThemedDialogWithSpinner from "../../Shared/Dialogs/ThemedDialogWithSpinner";
import { Alert } from "@material-ui/lab";
import SuccessMessage from "../../Shared/SuccessMessage";
import { Shift } from "../../../stores/models/Shift";
import { VolunteerShiftRegistration } from "../../../stores/models/VolunteerShiftRegistration";
import ShiftCard from "./ShiftCard";
import RegisterAdditionalVolunteers from "./ShiftRegistration/RegisterAdditionalVolunteers";
import { OptionCollection } from "../../../stores/models/OptionCollection";
import useRegisterableVolunteers from "./ShiftRegistration/RegisterableVolunteersHook";

const useStyles = makeStyles(theme => ({
    spacer: {
        height: theme.spacing(2),
    },
    // emphasizedText: {
    //     color: theme.palette.primary.main,
    //     fontWeight: 600
    // },
}));

const getNonOverlappingArrayElements = <T extends any>(arrayWithExtra: T[], elementsToRemove: T[]) => {
    let nonOverlappingElements = [] as T[];
    arrayWithExtra.forEach(elementToCheck => {
        if (elementsToRemove.findIndex(elementToRemove => elementToRemove === elementToCheck) === -1) {
            nonOverlappingElements.push(elementToCheck);
        }
    })
    return nonOverlappingElements;
}

interface ShiftSignUpDialogProps {
    state: DialogState;
    shift: Shift;
    organizationId?: number;
    onRegistrationsChanged: (shift: Shift, registered: boolean) => void;
}

const ShiftSignUpDialog = observer((props: ShiftSignUpDialogProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const rootStore = useContext(RootContext);
    const { userStore, shiftStore } = rootStore;
    const user = userStore.user;
    const volunteer = user.volunteer;

    /***** Helper methods *****/

    const getNewShiftRegistration = () => {
        if (!volunteer) return;
        return new ShiftRegistration({
            registeringUserId: user.id,
            shiftIdentification: props.shift.identificationData,
            volunteerRegistrations: []
        });
    }

    const getAllRegisteredVolunteerIds = () => {
        let idsToSelect = props.shift.registeredProxyVolunteerIds;
        if (props.shift.currentVolunteerRegistered && volunteer) {
            idsToSelect = idsToSelect.concat(volunteer.id);
        }
        return idsToSelect;
    }

    /***** State *****/

    const registerableVolunteers = useRegisterableVolunteers(props.organizationId);

    const [shiftRegistration, setShiftRegistration] = useState(getNewShiftRegistration());
    const [shiftRegistrationsSubmitted, setShiftRegistrationsSubmitted] = useState(false);
    const [volunteerCollection, setVolunteerCollection] = useState(new OptionCollection<'id', Volunteer>('id', registerableVolunteers));
    const [startingRegisteredVolunteerIds, setStartingRegisteredVolunteerIds] = useState(getAllRegisteredVolunteerIds());
    const [error, setError] = useState<string>();

    /***** Effects *****/

    useEffect(() => {
        const newVolunteerCollection = new OptionCollection<'id', Volunteer>('id', registerableVolunteers);
        newVolunteerCollection.setSelectionsById(getAllRegisteredVolunteerIds());
        setVolunteerCollection(newVolunteerCollection);
    }, [JSON.stringify(registerableVolunteers)]);

    useEffect(() => {
        if (props.state.open) {
            resetDialog();
        }
    }, [props.state.open]);

    /***** API methods *****/

    const registerSelectedVolunteers = async () => {
        if (shiftRegistration && volunteersToRegister().length > 0) {
            let newVolunteerRegistrations = [] as VolunteerShiftRegistration[];
            volunteerCollection.selectedOptions.forEach((volunteer) => {
                if (volunteersToRegister().findIndex(volIdToReg => volIdToReg === volunteer.id) !== -1) {
                    newVolunteerRegistrations.push(
                        new VolunteerShiftRegistration({
                            volunteerId: volunteer.id,
                            firstName: volunteer.firstName,
                            lastName: volunteer.lastName,
                            email: user.email // use the current user's email
                        })
                    );
                }
            });
            shiftRegistration.setVolunteerRegistrations(newVolunteerRegistrations);
            const response = await shiftStore.signUpForShift(shiftRegistration);
            return response;
        }
    }

    const unregisterRemovedVolunteers = async () => {
        let volsToUnregister = volunteersToUnregister();
        if (volsToUnregister.length > 0) {
            const response = await shiftStore.unregisterForShifts([props.shift.identificationData], volsToUnregister);
            return response;
        }
    }

    /***** Event handlers *****/

    const resetDialog = () => {
        setStartingRegisteredVolunteerIds(getAllRegisteredVolunteerIds());
        volunteerCollection.setSelectionsById(getAllRegisteredVolunteerIds());
        setShiftRegistration(getNewShiftRegistration());
        setShiftRegistrationsSubmitted(false);
        setError(undefined);
    }

    const onConfirmRegistrationChanges = async () => {
        props.state.setLoading(true);
        try {
            const unregisterResponse = await unregisterRemovedVolunteers();
            const registerResponse = await registerSelectedVolunteers();
            const currentUserOrProxyRegistered = volunteerCollection.selectedOptions.length > 0;

            if (unregisterResponse) {
                unregisterResponse.shifts.forEach(shift => {
                    props.onRegistrationsChanged(shift, currentUserOrProxyRegistered);
                })
            }
            if (registerResponse && 'error' in (registerResponse)) {
                setError(registerResponse.error);
            } else {
                if (registerResponse) {
                    props.onRegistrationsChanged(registerResponse, currentUserOrProxyRegistered);
                }
                setShiftRegistrationsSubmitted(true);
            }
        } catch {
            props.state.setOpen(false); // Handle user session expiring between opening and submitting form
        }
        props.state.setLoading(false);
    }

    /***** Helper methods *****/

    const volunteersToUnregister = () => {
        const selectedVolunteerIds = volunteerCollection.selectedOptions.map(volunteer => volunteer.id);
        return getNonOverlappingArrayElements(startingRegisteredVolunteerIds, selectedVolunteerIds);
    }

    const volunteersToRegister = () => {
        const selectedVolunteerIds = volunteerCollection.selectedOptions.map(volunteer => volunteer.id);
        return getNonOverlappingArrayElements(selectedVolunteerIds, startingRegisteredVolunteerIds);
    }

    const registrationChangesMade = () => {
        return volunteersToRegister().length > 0 || volunteersToUnregister().length > 0;
    }

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            DialogProps={{ maxWidth: 'sm', fullScreen: xsDown }}
            state={props.state}
            title={startingRegisteredVolunteerIds.length > 0 ? 'Edit Shift Registration' : 'Shift Sign Up'}
            onSubmit={onConfirmRegistrationChanges}
            primaryButtonProps={!shiftRegistrationsSubmitted
                ? startingRegisteredVolunteerIds.length > 0
                    ? { children: 'Update Registration', startIcon: <ClipboardEditOutline />, disabled: !registrationChangesMade() }
                    : { children: 'Sign Up', startIcon: <ClipboardEditOutline />, disabled: volunteerCollection.selections.length === 0 }
                : undefined
            }
            cancelText={!shiftRegistrationsSubmitted ? undefined : 'Close'}
        >
            {!shiftRegistrationsSubmitted
                ? <React.Fragment>
                    {error
                        ? <Fragment>
                            <Alert severity={'error'}>
                                {error}
                            </Alert>
                            <div className={classes.spacer} />
                        </Fragment>
                        : null
                    }
                    <Alert severity="info">
                        {/* <AlertTitle>Share Your Contact Information</AlertTitle> */}
                        {'When you sign up for a shift, your contact information is shared with the shift organizer.'}
                        {/* {`Fill out this form to let the shift organizer know how to contact you if needed.`} */}
                        {/* TODO: Replace text with 'how to contact you and those volunteering with you if needed'
                            once a volunteer can sign up multiple people at a time.
                        */}
                    </Alert>
                    <div className={classes.spacer} />
                    <ShiftCard shift={props.shift} />
                    <div className={classes.spacer} />
                    <RegisterAdditionalVolunteers volunteerCollection={volunteerCollection} organizationId={props.organizationId} />
                </React.Fragment>
                : startingRegisteredVolunteerIds.length > 0
                    ? <SuccessMessage
                        title={'All set!'}
                        details={(
                            <React.Fragment>
                                Your registration has been updated.
                            </React.Fragment>
                        )}
                    />
                    : <SuccessMessage
                        title={'Thank you for volunteering!'}
                        details={(
                            <React.Fragment>
                                You're signed up!
                                {/* {`${props.opportunity.organizationName} will `}
                            <span className={classes.emphasizedText}>connect</span>
                            {` with you soon.`} */}
                            </React.Fragment>
                        )}
                    />
            }
        </ThemedDialogWithSpinner >
    )
})

export default ShiftSignUpDialog;