import { TextField, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { DialogState } from "../../../stores/models/DialogState";
import { AutocompleteChangeReason, AutocompleteChangeDetails } from "@material-ui/lab";
import { TagPlus } from "mdi-material-ui";
import ButtonWithPopper from "../../../components/AccountSettings/Pages/Organization/ButtonWithPopper";
import { RootContext } from "../../../stores";
import { ServiceEntryTag } from "../../../stores/models/ServiceEntryTag";
import PopperCard from "../../../components/AccountSettings/Pages/Organization/PopperCard";
import REMAutocomplete from "../../../shared/modules/rem-conversion/components/Autocomplete";
import REMChip from "../../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocomplete: {
            width: 300
        },
    })
);

const ADD_TAGS_AUTOCOMPLETE_ID = 'add-tags-autocomplete';
const ADD_TAGS_TEXT_FIELD_ID = 'add-tags-text-field';

interface AddTagsPopperProps {
    popperState: DialogState;
    onConfirm: (tags: ServiceEntryTag[]) => void;
    size?: "small" | "large" | "medium";
}

const AddTagsPopper = observer((props: AddTagsPopperProps) => {

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const tags = rootStore.userStore.user.organization?.tags || [];

    const [tagsToAdd, setTagsToAdd] = React.useState<ServiceEntryTag[]>([]);

    useEffect(() => {
        if (!props.popperState.open) {
            setTagsToAdd([]);
        }
    }, [props.popperState.open]);

    /********* Event Handlers *********/

    const onAddTagsButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.popperState.setOpen(!props.popperState.open);
        setTimeout(() => {
            document.getElementById(ADD_TAGS_AUTOCOMPLETE_ID)?.focus();
        }, 0)
    }

    const onTagsChanged = (
        event: React.ChangeEvent<{}>,
        value: (string | ServiceEntryTag)[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<ServiceEntryTag> | undefined
    ) => {
        const tags = value.map(tag => {
            if (typeof tag === 'string') {
                return new ServiceEntryTag(-1, tag);
            } else {
                return tag;
            }
        })
        setTagsToAdd(tags);
    }

    /********* Render *********/

    return (
        <ButtonWithPopper
            Button={(ref) =>
                <Button
                    startIcon={<TagPlus />}
                    variant={"outlined"}
                    size={props.size ? props.size : "large"}
                    onClick={onAddTagsButtonClicked}
                    ref={ref}
                >
                    Add Tags
                </Button>
            }
            state={props.popperState}
        >
            <PopperCard
                state={props.popperState}
                actionButtonText={"Add"}
                ActionButtonProps={{
                    disabled: tagsToAdd.length === 0,
                    onClick: () => props.onConfirm(tagsToAdd)
                }}
            >
                <REMAutocomplete
                    className={classes.autocomplete}
                    multiple
                    id={ADD_TAGS_AUTOCOMPLETE_ID}
                    options={tags}
                    getOptionLabel={(option) => option.tag}
                    freeSolo
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <REMChip
                                variant="outlined"
                                label={option.tag ? option.tag : ''}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            id={ADD_TAGS_TEXT_FIELD_ID}
                        />
                    )}
                    onChange={onTagsChanged}
                />
            </PopperCard>
        </ButtonWithPopper>
    );
});

export default AddTagsPopper;