import { Subscription } from "./Subscription";
import { CreditCard } from "./CreditCard";
import { observable, action, makeObservable } from "mobx";

export interface ICustomer {
    subscription?: Subscription;
    card?: CreditCard;
}

export class Customer implements ICustomer {
    subscription?: Subscription;
    card?: CreditCard;

    constructor(customer: ICustomer) {
        makeObservable(this, {
            subscription: observable,
            card: observable,
            setSubscription: action,
            setCard: action
        });

        this.subscription = customer.subscription ? new Subscription(customer.subscription) : undefined;
        this.card = customer.card ? new CreditCard(customer.card) : undefined;
    }

    setSubscription(subscription: Subscription) {
        this.subscription = subscription;
    }

    setCard(card?: CreditCard) {
        this.card = card;
    }
}