import { observer } from "mobx-react";
import ServiceEntryForm from "./ServiceEntryForm";
import { ServiceEntry } from "../data-access/entities/ServiceEntry";
import RecordEditorDialog, { RecordEditorDialogProps } from "../../../components/AccountSettings/Pages/Organization/RecordEditorDialog";
import { Volunteer } from "../../../stores";
import { IOrganizationRoles } from "../data-access/interfaces/OrganizationRoles";
import useBrowserFontSize, { DEFAULT_BROWSER_FONT_SIZE_PX } from "../../../shared/general/hooks/useBrowserFontSize";

interface ServiceEntryDialogProps extends Omit<RecordEditorDialogProps<ServiceEntry>, 'children'> {
    volunteer?: Volunteer;
    volunteerEntry?: boolean;
    volunteerOrganizations?: IOrganizationRoles[];
    serviceEntry: ServiceEntry;
}

const ServiceEntryDialog = observer((props: ServiceEntryDialogProps) => {

    const browserFontSize = useBrowserFontSize() || DEFAULT_BROWSER_FONT_SIZE_PX;

    /********* Render *********/

    return (
        <RecordEditorDialog {...props} DialogProps={{ maxWidth: browserFontSize > DEFAULT_BROWSER_FONT_SIZE_PX ? 'sm' : 'xs' }}>
            {(record: ServiceEntry) => {
                return (
                    <ServiceEntryForm
                        volunteerOrganizations={props.volunteerOrganizations}
                        entryToEdit={record}
                        serviceEntry={props.serviceEntry}
                        volunteer={props.volunteer}
                        volunteerEntry={props.volunteerEntry}
                    />
                );
            }}
        </RecordEditorDialog>
    )
});

export default ServiceEntryDialog;