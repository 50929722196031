import useOrgOpportunitiesLink from "./OrgOpportunitiesLink";
import { useState, useEffect } from "react";
import { getOrgOpportunitiesPath, routeWithDescendants } from "../../Links/UrlConstructors";
import useMatchCurrentPath from "../MatchCurrentPath";

// Returns the last visited link in an organization's opportunities tab
export default function useCurrentOpportunitiesTabLink(): string {
    const initialLink = useOrgOpportunitiesLink();
    const matchPath = useMatchCurrentPath();
    const routeMatch = matchPath(routeWithDescendants(getOrgOpportunitiesPath()));

    const [link, setLink] = useState(initialLink);
    const [lastOpportunitiesUrl, setLastOpportunitiesUrl] = useState(initialLink);

    useEffect(() => {
        if (routeMatch) {
            // The path is within the Opportunities Tab
            setLastOpportunitiesUrl(routeMatch.pathname);
            setLink(initialLink); // The tab links to the organization's Opportunities page
        } else {
            // The path is not within the Opportunities Tab
            setLink(lastOpportunitiesUrl); // The tab links to the last location within the Opportunities tab
        }
    }, [routeMatch]);

    return link;
}
