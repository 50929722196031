import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { Credentials } from "../../stores/models/Credentials";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, isValidEmail } from "./Validation";
import { hasLeadingOrTrailingSpace } from "../RegexValidation";

export const MIN_PASSWORD_LENGTH = 6;

const credentialsChecks: ValidationCheck<Credentials>[] = [{
    name: 'valid email',
    condition: (credentials: Credentials) => {
        return isValidEmail(credentials.email);
    },
    error: new ValidationError('You must enter a valid email.', ['email'])
}, {
    name: 'unique email',
    condition: (credentials: Credentials) => !isValidEmail(credentials.email) || credentials.unique !== undefined,
    error: new ValidationError('Verifying email is unique.', ['email'])
}, {
    name: 'unique email',
    condition: (credentials: Credentials) => {
        return !isValidEmail(credentials.email) || credentials.unique === undefined || credentials.unique === true;
    },
    error: new ValidationError('That email is already associated with an account. Did you mean to login?', ['email'])
}, {
    name: 'password no leading or trailing spaces',
    condition: (credentials: Credentials) => {
        return !hasLeadingOrTrailingSpace(credentials.password);
    },
    error: new ValidationError('Your password should not have any leading or trailing spaces.', ['password'])
}, {
    name: 'password length',
    condition: (credentials: Credentials) => {
        return credentials.password.length >= MIN_PASSWORD_LENGTH;
    },
    error: new ValidationError('Your password must be at least 6 characters long.', ['password'])
}, {
    name: 'password confirmed',
    condition: (credentials: Credentials) => {
        return credentials.passwordsMatch;
    },
    error: new ValidationError('Your passwords must match.', ['password', 'confirmedPassword'], 'confirmedPassword')
}];

export default function validateCredentials(credentials: Credentials) {
    return getValidationErrors(credentialsChecks, credentials);
}