import { observable, action, makeObservable } from "mobx";

export interface IAccordionState {
    expanded: boolean;
}

export class AccordionState implements IAccordionState {
    expanded = false;

    constructor(expanded?: boolean) {
        makeObservable(this, {
            expanded: observable,
            setExpanded: action
        });

        if (expanded) {
            this.expanded = expanded;
        }
    }

    setExpanded(expanded: boolean) {
        this.expanded = expanded;
    }
}