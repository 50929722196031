import { createMuiTheme } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    accent: Palette['primary'];
    darkBackground: Palette['primary'];
    lightBackground: Palette['primary'];
    primaryBackground: Palette['primary'];
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary'];
    darkBackground: PaletteOptions['primary'];
    lightBackground: PaletteOptions['primary'];
    primaryBackground: PaletteOptions['primary'];
  }
}

// https://coolors.co/f0386b-03b5aa-222b4e-b4e33d-f18f01-f8f32b

const BaseTheme = createMuiTheme({
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiCard: {
      elevation: 0
    }
  },
  overrides: {
    MuiCard: {
      root: {
        border: '1px solid lightgray'
      }
    },
    MuiPaper: {
      root: {
        border: '1px solid lightgray'
      }
    },
    MuiDialog: {
      paper: {
        border: 'none'
      }
    },
    MuiButton: {
      contained: {
        boxShadow: 'none'
      }
    },
    MuiDrawer: {
      paper: {
        border: 'none'
      }
    }
  },
  typography: {
    h1: {
      fontFamily: [
        '"Play"',
        'Arial',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    fontFamily: [
      'Open Sans',
      'sans-serif',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#08c4d0', //'#30E7ed', //'#0066ff', //'#08c4d0',
      main: '#08c4d0', //'#2d3142', //'#00a9a5',//'#00a9a5', //'#0fa3b1', //'#0091ad', //'#03b5aa', //'#28c2b0',  
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#ffeb00', //'#fff700', //'#f8f32b', //'#08c4d0',//'#00a9a5',//'rgb(255, 255, 255)', //'#f8f32b',//'#f8f32b' //'#28c2b0' //'#cddc39'
      contrastText: '#222b4e'
      // contrastText: '#30343F'
    },
    accent: {
      main: '#f0386b',
      dark: '#D11046',
      contrastText: '#ffffff'
    },
    success: {
      main: '#84c318'//'#b4e33d'
    },
    darkBackground: {
      light: '#505C68',
      main: '#373F47',//'#222b4e', //'#08c4d0', //'#30343F', //'gainsboro', //'#222b4e',
      contrastText: '#ffffff'
    },
    lightBackground: {
      light: '#f5f5f5',
      main: '#ebebeb'
    },
    primaryBackground: {
      main: '#d6f2f4'
    }
  },
  zIndex: {
    drawer: zIndex.appBar - 5
  }
  //   status: {
  //     danger: 'orange',
  //   },
});

export default BaseTheme;