import { makeStyles, Theme, createStyles, TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import React, { Fragment } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { TableHeader } from "../../../../stores/models/TableHeader";
import { Option } from "../../../../stores/models/Option";
import TableHeaderCell from "../../../../components/Shared/Table/TableHeaderCell";
import CheckboxTableCell from "../../../../components/Shared/Table/CheckboxTableCell";
import SelectAllTableRow from "../../../../components/Shared/Table/SelectAllTableRow";
import { RecordTableViewModel } from "../data-access/view-models/RecordTableViewModel";
import useRecordListActions from "../hooks/useRecordListActions";
import { IAction } from "../../actions/interfaces/Action";
import { CheckboxState } from "../../../general/entities/CheckboxState";
import TablePaginationWrapper from "../../../../components/AccountSettings/Pages/Organization/TablePaginationWrapper";
import { ObjectWithIdentifier } from "../../../../stores/models/NewOptionCollection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            whiteSpace: 'normal',
            wordBreak: 'normal'
        },
        header: {
            '& th': {
                backgroundColor: theme.palette.primaryBackground.main,
                fontWeight: 'bold',
                borderBottom: 'none'
            },
            whiteSpace: 'nowrap',
        },
        noResultsCaption: {
            width: 'auto',
            background: 'whitesmoke',
            color: 'black !important',
        },
        cursor: {
            cursor: 'pointer'
        },
    }),
);

interface RecordTableProps<
    RecordType extends ObjectWithIdentifier<RecordType>,
    MinimallyIdentifiableObject extends ObjectWithIdentifier<RecordType> = ObjectWithIdentifier<RecordType>
> {
    viewModel: RecordTableViewModel<RecordType>;
    hideSelectAllRow?: boolean;
    onRowClicked: (record: Option<RecordType>, rowIndex: number) => void;
    onSelectAll?: () => void;
    onCheckboxClicked?: (rowObject: Option<MinimallyIdentifiableObject>) => void; // TODO: fix param

    tableHeaderCells: TableHeader<RecordType>[];
    displayFunctions: ((record: Option<RecordType>, rowIndex: number, cellIndex: number) => React.ReactNode)[];
    tableRowClassName?: (record: Option<RecordType>) => string | undefined;
}

const RecordTable = observer(<
    RecordType extends ObjectWithIdentifier<RecordType>,
    MinimallyIdentifiableObject extends ObjectWithIdentifier<RecordType> = ObjectWithIdentifier<RecordType>
>(props: RecordTableProps<RecordType>) => {

    const classes = useStyles();
    const viewModel = props.viewModel;
    const { selectAll } = viewModel.injectStore<{ selectAll: IAction }>((store) => useRecordListActions<RecordType>({ store }));

    /********* Event handlers *********/

    const handleRequestSort = (
        event: React.MouseEvent<HTMLSpanElement>,
        selectedHeadCell: TableHeader<MinimallyIdentifiableObject>
    ) => {
        if (typeof selectedHeadCell.sortValue === 'string') {
            viewModel.sortingState.updateSortConfiguration(
                selectedHeadCell.sortValue as Extract<keyof MinimallyIdentifiableObject, string>,
                selectedHeadCell.direction
            );
        }
    };

    const onHeaderCheckboxClicked = () => {
        viewModel.toggleHeaderCheckbox();
    }

    const onCheckboxClicked = (
        event: React.MouseEvent<HTMLButtonElement | HTMLTableHeaderCellElement, MouseEvent>,
        option: Option<MinimallyIdentifiableObject>
    ) => {
        event.stopPropagation();
        viewModel.toggleCheckbox(option);
        if (props.onCheckboxClicked) {
            props.onCheckboxClicked(option);
        }
    }

    const onSelectAllRecordsClicked = () => {
        selectAll.execute(undefined); // TODO: update execute params so that no parameter is required.
        if (props.onSelectAll) {
            props.onSelectAll();
        }
    }

    /********* Render *********/

    return (
        <Fragment>
            <TableContainer>
                <Table className={classes.table} stickyHeader>
                    <TableHead className={classes.header}>
                        <TableRow key="table-header-row">
                            {props.tableHeaderCells.map((headCell) => (
                                headCell.checkbox
                                    ? <CheckboxTableCell
                                        onCheckboxClicked={onHeaderCheckboxClicked}
                                        CheckboxProps={{
                                            checked: viewModel.headerCheckboxState === CheckboxState.Checked,
                                            indeterminate: viewModel.headerCheckboxState === CheckboxState.Indeterminate
                                        }}
                                    />
                                    : <TableHeaderCell
                                        tableHeader={headCell}
                                        onHeaderClicked={handleRequestSort}
                                    />
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    {viewModel.total === 0
                        ? <caption className={classes.noResultsCaption} style={{ textAlign: 'center' }}>
                            No results
                        </caption>
                        : <TableBody>
                            {(viewModel.displaySelectAllRow && !props.hideSelectAllRow) &&
                                <SelectAllTableRow
                                    TableCellProps={{ colSpan: props.tableHeaderCells.length }}
                                    rowsOnPage={viewModel.recordsToDisplay.length}
                                    totalRows={viewModel.total || 0}
                                    onSelectAllClicked={onSelectAllRecordsClicked}
                                />
                            }
                            {viewModel.recordsToDisplay.map((record, rowIndex) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={`row-${rowIndex}`}
                                        onClick={() => props.onRowClicked(record as Option<RecordType>, rowIndex)}
                                        className={clsx(
                                            classes.cursor,
                                            props.tableRowClassName
                                                ? props.tableRowClassName(record as Option<RecordType>)
                                                : undefined
                                        )}
                                    >
                                        {props.displayFunctions.map((displayFunction, cellIndex) => {
                                            if (cellIndex < props.tableHeaderCells.length && props.tableHeaderCells[cellIndex].checkbox) {
                                                return (
                                                    <CheckboxTableCell
                                                        key={`${rowIndex}-${cellIndex}`}
                                                        CheckboxProps={{
                                                            checked: (record as Option<RecordType>).selected
                                                        }}
                                                        onCheckboxClicked={(event) => onCheckboxClicked(event, record as Option<RecordType>)}
                                                    />
                                                );
                                            } else {
                                                return displayFunction(record as Option<RecordType>, rowIndex, cellIndex);
                                            }
                                        })
                                        }
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePaginationWrapper
                state={viewModel.paginationState}
                total={viewModel.total}
                loadResults={() => { return; }}
                skipInitialLoad
            />
        </Fragment>
    );
});

export default RecordTable;