import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { observer } from "mobx-react";
import React from "react";
import { ToggleButtonState } from "../entities/ToggleButtonState";

interface StatefulToggleButtonGroupProps<T extends string> {
    state: ToggleButtonState<T>;
}

const StatefulToggleButtonGroup = observer(<T extends string>(props: StatefulToggleButtonGroupProps<T>) => {

    /********* Event Handlers *********/

    const onValueChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
        if (value) {
            props.state.setSelectedButton(value);
        }
    }

    /********* Render *********/

    return (
        <ToggleButtonGroup
            value={props.state.selectedButton}
            onChange={onValueChanged}
            exclusive
        >
            {props.state.toggleButtons.map(button => {
                return (
                    <ToggleButton value={button.value}>
                        {button.icon}
                    </ToggleButton>
                )
            })}
        </ToggleButtonGroup>
    )
});

export default StatefulToggleButtonGroup;