import { DialogProps, makeStyles, Theme, createStyles, DialogTitle, DialogContent, DialogActions, Button, ButtonProps, DialogContentProps, IconButton } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState, useEffect } from "react";
import { DialogState } from "../../../stores/models/DialogState";
import DialogWithLoadingSpinner from "./DialogWithLoadingSpinner";
import { Close } from "mdi-material-ui";
import ErrorAlert from "../../../shared/modules/errors/components/ErrorAlert";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        secondaryButton: {
            color: theme.palette.action.active
        },
        spaceBetween: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between'
        },
        rightButtons: {
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        },
        flex: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        xButton: {
            color: theme.palette.primary.contrastText,
            padding: 0
        }
    }),
);

export interface ThemedDialogWithSpinnerProps {
    state: DialogState;
    DialogProps?: Partial<DialogProps>;
    DialogContentProps?: Partial<DialogContentProps>;
    title?: React.ReactNode;
    primaryButtonProps?: ButtonProps;
    leftButtonProps?: ButtonProps;
    extraButtonProps?: ButtonProps[];
    onSubmit?: () => void;
    onCancel?: () => void;
    cancelText?: string;
    contentId?: string;
    hideCancelButton?: boolean;
    showXButton?: boolean;
    children?: React.ReactNode;
}

const ThemedDialogWithSpinner: FunctionComponent<ThemedDialogWithSpinnerProps> = observer((props) => {

    const classes = useStyles();

    const getFormId = () => {
        return `form-${props.title}-${Date.now()}-${Math.random()}`;
    }

    const [formId, setFormId] = useState(getFormId());

    useEffect(() => {
        if (props.state.open) {
            setFormId(getFormId());
        }
    }, [props.state.open]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent page reload
        event.stopPropagation();
        if (props.onSubmit) {
            props.onSubmit();
        }
    }
    
    const handleCancel = () => {
        if (props.onCancel) props.onCancel();
        props.state.setOpen(false);
    }

    const displayButtons = props.primaryButtonProps || !props.hideCancelButton || props.leftButtonProps || props.extraButtonProps;

    return (
        <DialogWithLoadingSpinner state={props.state} onClose={handleCancel} DialogProps={{ scroll: 'paper', ...props.DialogProps }}>
            {props.title
                ? <DialogTitle className={classes.title}>
                    <div className={classes.flex}>
                        {props.title}
                        {props.showXButton
                            ? <IconButton onClick={() => props.state.setOpen(false)} className={classes.xButton}>
                                <Close />
                            </IconButton>
                            : null
                        }
                    </div>
                </DialogTitle>
                : null
            }
            <DialogContent dividers {...props.DialogContentProps} id={props.contentId}>
                <form onSubmit={handleSubmit} noValidate autoComplete="off" id={formId}>
                    {props.state.error
                        ? <ErrorAlert error={props.state.error} />
                        : null
                    }
                    {props.children}
                </form>
            </DialogContent>
            {displayButtons
                ? <DialogActions>
                    <div className={props.leftButtonProps ? classes.spaceBetween : undefined}>
                        {props.leftButtonProps
                            ? <div>
                                <Button
                                    className={classes.secondaryButton}
                                    disabled={props.state.loading}
                                    {...props.leftButtonProps}
                                >
                                    {props.leftButtonProps.children ? props.leftButtonProps.children : 'Back'}
                                </Button>
                            </div>
                            : null
                        }
                        <div className={classes.rightButtons}>
                            {props.primaryButtonProps
                                ? <Button
                                    variant='contained'
                                    color='primary'
                                    type="submit"
                                    form={formId}
                                    disabled={props.state.loading}
                                    {...props.primaryButtonProps}
                                />
                                : null
                            }
                            {props.extraButtonProps?.map((button, index) => {
                                return <Button key={`dialog-button-${index}`} disabled={props.state.loading} {...button} />;
                            })}
                            {props.hideCancelButton !== true
                                ? <Button className={classes.secondaryButton} onClick={handleCancel} disabled={props.state.loading}>
                                    {props.cancelText ? props.cancelText : 'Cancel'}
                                </Button>
                                : null
                            }
                        </div>
                    </div>
                </DialogActions>
                : null
            }
        </DialogWithLoadingSpinner>
    );
});

export default ThemedDialogWithSpinner;