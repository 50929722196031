import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import REMChip from "../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(2),
        },
        chip: {
            margin: theme.spacing(0.5),
            maxWidth: '100%'
        },
        listItem: {
            maxWidth: '100%'
        },
        list: {
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap'
        }
    })
);

interface IChipOption {
    id: number;
}

interface ChipEditorProps<T extends IChipOption> {
    options: T[];
    labelProperty: keyof T;
    buttonText: string;
    onChipRemoved: (chipId: number) => void;
    onAddButtonClicked: () => void;
}

const ChipEditor = <T extends IChipOption,>(props: ChipEditorProps<T>) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <div>
            <div className={classes.list}>
                {props.options.map((option: T) => {
                    return (
                        <li key={`option-${option.id}`} className={classes.listItem}>
                            <REMChip
                                label={option[props.labelProperty] as unknown as string}
                                onDelete={() => props.onChipRemoved(option.id)}
                                className={classes.chip}
                            />
                        </li>
                    );
                })}
            </div>
            <Button
                className={props.options.length > 0 ? classes.button : undefined}
                onClick={props.onAddButtonClicked}
                variant="contained"
                color="primary"
                size="large"
            >
                {props.buttonText}
            </Button>
        </div>
    );
};

export default ChipEditor;