import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { getValidationErrors, getFieldRequiredValidationCheck, getMaxLengthValidationCheck, getBirthdateValidationCheck, isValidEmail } from "./Validation";
import { VOLUNTEER_NAME_MAX_LENGTH } from "./FieldLengths";
import { StubOrganizationVolunteer } from "../../stores/models/StubOrganizationVolunteer";
import { ValidationError } from "../../stores/models/ValidationError";

const demographicChecks: (stubOrganizationVolunteer: StubOrganizationVolunteer) => ValidationCheck<StubOrganizationVolunteer>[] =
    (stubOrganizationVolunteer: StubOrganizationVolunteer) => {
        let checks: ValidationCheck<StubOrganizationVolunteer>[] = [
            getFieldRequiredValidationCheck(stubOrganizationVolunteer.firstName, 'firstName', `The volunteer's first name is required.`),
            getMaxLengthValidationCheck(stubOrganizationVolunteer.firstName, 'firstName', VOLUNTEER_NAME_MAX_LENGTH),
            getFieldRequiredValidationCheck(stubOrganizationVolunteer.lastName, 'lastName', `The volunteer's last name is required.`),
            getMaxLengthValidationCheck(stubOrganizationVolunteer.lastName, 'lastName', VOLUNTEER_NAME_MAX_LENGTH),
            {
                name: 'valid email',
                condition: (stubOrganizationVolunteer: StubOrganizationVolunteer) => {
                    return !stubOrganizationVolunteer.email || isValidEmail(stubOrganizationVolunteer.email);
                },
                error: new ValidationError('Invalid email.', ['email'])
            }
        ];
        if (stubOrganizationVolunteer.birthdate) {
            const birthdate = stubOrganizationVolunteer.birthdate.trim().length > 0
                ? new Date(stubOrganizationVolunteer.birthdate)
                : null;
            checks.push(getBirthdateValidationCheck(birthdate, 'birthdate'));
        }
        return checks;
    };

export default function validateStubOrganizationVolunteer(stubOrganizationVolunteer: StubOrganizationVolunteer) {
    return getValidationErrors(demographicChecks, stubOrganizationVolunteer);
}