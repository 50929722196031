import { observable, action, computed, makeObservable } from "mobx";
import { Fields } from "./Fields";
import validateStubOrganizationVolunteer from "../../logic/ValidationChecks/StubOrganizationVolunteerValidation";
import { isValidEmail } from "../../logic/ValidationChecks/Validation";
import { UniqueIdentification } from "../../logic/UniqueIdentification";

export interface IStubOrganizationVolunteer {
    firstName: string;
    lastName: string;
    statusId: number;
    email?: string;
    birthdate?: string;
}

export class StubOrganizationVolunteer extends Fields<IStubOrganizationVolunteer, StubOrganizationVolunteer> implements IStubOrganizationVolunteer {
    @observable firstName = "";
    @observable lastName = "";
    @observable statusId = 1;
    @observable email?: string;
    @observable birthdate?: string;
    @observable id: number;

    constructor(stubOrganizationVolunteer?: IStubOrganizationVolunteer) {
        super();

        makeObservable(this);

        this.id = UniqueIdentification.getClientId();

        if (stubOrganizationVolunteer) {
            this.firstName = stubOrganizationVolunteer.firstName;
            this.lastName = stubOrganizationVolunteer.lastName;
            this.statusId = stubOrganizationVolunteer.statusId;
            this.email = stubOrganizationVolunteer.email;
            this.birthdate = stubOrganizationVolunteer.birthdate
        }
    }

    @action setFirstName(firstName: string) {
        this.firstName = firstName;
    }

    @action setLastName(lastName: string) {
        this.lastName = lastName;
    }
    
    @action setEmail(email: string) {
        this.email = email === "" ? undefined : email;
    }

    @action setBirthdate(date?: string) {
        this.birthdate = date;
    }

    @action setStatusId(statusId: number) {
        this.statusId = statusId;
    }

    @computed get validationErrors() {
        return validateStubOrganizationVolunteer(this);
    }

    @computed get emailIsValid() {
        if (this.email) {
            return isValidEmail(this.email);
        } else {
            return false;
        }
    }

    @computed get serializedData() {
        return {
            firstName: this.trimmed.firstName,
            lastName: this.trimmed.lastName,
            birthdate: this.birthdate,
            email: this.email ? this.trimmed.email : undefined,
            statusId: this.statusId
        }
    }
}