export const zipCodePattern = `\\d{5}`;
export const zipCodeRegex = new RegExp(zipCodePattern);
const onlyZipCodeRegex = new RegExp('^(' + zipCodePattern + ')$');

export function zipCodeIsValid(zipCode: string) {
    return onlyZipCodeRegex.test(zipCode);
}

// 50 US States and DC
export const stateAbbreviations = 'A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY]';
export const containsStateAbbreviationRegex = new RegExp('(' + stateAbbreviations + ')', "i");
export const stateAbbreviationRegex = new RegExp('^(' + stateAbbreviations + ')$');

export function stateAbbreviationIsValid(state: string) {
    return stateAbbreviationRegex.test(state);
}

const websiteRegex = /^[^\.\s]+(\.[^\.\s]+)+$/

export function websiteIsValid(website: string) {
    const noWebsiteEntered = website.trim().length === 0;
    return noWebsiteEntered || websiteRegex.test(website);
}

const einRegex = /^\d{2}-?\d{7}$/

export function einIsValid(ein: string) {
    return einRegex.test(ein);
}

const leadingOrTrailingSpaceRegex = /^\s|\s$/

export function hasLeadingOrTrailingSpace(value: string) {
    return leadingOrTrailingSpaceRegex.test(value);
}