import { MapMarker, Airballoon, Gesture, CalendarMultiselect, Store, HomeHeart, CalendarClock, Autorenew, LinkOff, Ring, Domain, ClipboardTextMultipleOutline } from "mdi-material-ui";
import { IconName } from "../stores/models/Icon";
import { IOptionObject } from "../stores/models/Option";

export interface SearchOption<T> extends IOptionObject {
    filterText: string; // The text to be displayed in the filter chip on the search page
    primaryText: T; // The top line of text to be displayed in the filter selection dialog
    secondaryText?: string; // Text to describe the filter option
    icon: IconName;
}

export enum VolunteerLocationOptions {
    OnSite = "In Person",
    Remote = "Remotely"
}

export const volunteerLocationFilterOptions: SearchOption<VolunteerLocationOptions>[] = [{
    filterText: VolunteerLocationOptions.OnSite,
    primaryText: VolunteerLocationOptions.OnSite,
    secondaryText: 'Volunteer on site.',
    value: VolunteerLocationOptions.OnSite,
    icon: MapMarker
}, {
    filterText: 'Remote',
    primaryText: VolunteerLocationOptions.Remote,
    secondaryText: 'Volunteer from wherever the wind takes you.',
    value: VolunteerLocationOptions.Remote,
    icon: Airballoon
}];

export enum SearchTypeOptions {
    Opportunities = "Opportunities",
    Organizations = "Organizations"
}

export const searchTypeOptions: SearchOption<SearchTypeOptions>[] = [{
    filterText: SearchTypeOptions.Opportunities,
    primaryText: SearchTypeOptions.Opportunities,
    value: SearchTypeOptions.Opportunities,
    icon: ClipboardTextMultipleOutline
}, {
    filterText: SearchTypeOptions.Organizations,
    primaryText: SearchTypeOptions.Organizations,
    value: SearchTypeOptions.Organizations,
    icon: Domain
}];


export enum SchedulingOptions {
    Flexible = 'Flexible',
    Shifts = 'Shifts'
}

export const schedulingFilterOptions: SearchOption<SchedulingOptions>[] = [{
    filterText: SchedulingOptions.Flexible,
    primaryText: SchedulingOptions.Flexible,
    value: SchedulingOptions.Flexible,
    icon: Gesture
}, {
    filterText: SchedulingOptions.Shifts,
    primaryText: SchedulingOptions.Shifts,
    value: SchedulingOptions.Shifts,
    icon: CalendarMultiselect
}];

export enum OrganizationTypeOptions {
    Nonprofit = 'Nonprofit',
    ForProfit = 'For-Profit'
}

export const organizationTypeOptions: SearchOption<OrganizationTypeOptions>[] = [{
    filterText: OrganizationTypeOptions.Nonprofit,
    primaryText: OrganizationTypeOptions.Nonprofit,
    value: OrganizationTypeOptions.Nonprofit,
    icon: HomeHeart
}, {
    filterText: OrganizationTypeOptions.ForProfit,
    primaryText: OrganizationTypeOptions.ForProfit,
    value: OrganizationTypeOptions.ForProfit,
    icon: Store
}];

export enum OpportunityTypeOptions {
    OneTime = 'One Time',
    Ongoing = "Ongoing"
}

export const opportunityTypeOptions: SearchOption<OpportunityTypeOptions>[] = [{
    filterText: OpportunityTypeOptions.OneTime,
    primaryText: OpportunityTypeOptions.OneTime,
    value: OpportunityTypeOptions.OneTime,
    icon: CalendarClock
}, {
    filterText: OpportunityTypeOptions.Ongoing,
    primaryText: OpportunityTypeOptions.Ongoing,
    value: OpportunityTypeOptions.Ongoing,
    icon: Autorenew
}];

export enum CommitmentLevelOptions {
    ShortTerm = "No Strings Attached",
    LongTerm = "In It for the Long Haul"
}

export const commitmentFilterOptions: SearchOption<CommitmentLevelOptions>[] = [{
    filterText: CommitmentLevelOptions.ShortTerm,
    primaryText: CommitmentLevelOptions.ShortTerm,
    secondaryText: `You're able to commit to one day at a time.`,
    value: CommitmentLevelOptions.ShortTerm,
    icon: LinkOff
}, {
    filterText: CommitmentLevelOptions.LongTerm,
    primaryText: CommitmentLevelOptions.LongTerm,
    secondaryText: `You're looking for somewhere to volunteer consistently.`,
    value: CommitmentLevelOptions.LongTerm,
    icon: Ring
}];