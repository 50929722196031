import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, createStyles, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "mdi-material-ui";
import { adjustViewDate, CalendarViewMode, getStartViewDateForViewModeUnit, getTextDisplay } from "./CalendarLogicHelpers";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center'
        },
        buttons: {
            display: 'flex',
            marginRight: theme.spacing(1),
            '& > :first-child': {
                marginRight: theme.spacing(1),
            }
        },
        iconButton: {
            padding: theme.spacing(1.5, 1)
        }
    })
);

interface CalendarNavigatorProps {
    date: Date;
    viewMode: CalendarViewMode;
    onDateChanged: (date: Date) => void;
    limitToFuture?: boolean;
    abbreviateText?: boolean;
}

const CalendarNavigator = observer((props: CalendarNavigatorProps) => {

    const classes = useStyles();
    const [now] = useState(new Date());

    const onTodayClicked = () => {
        props.onDateChanged(now);
    }

    const onDateChanged = (direction: -1 | 1) => {
        const newDate = adjustViewDate(props.date, props.viewMode, direction);
        props.onDateChanged(newDate);
    }

    const shouldDisablePreviousButton = () => {
        if (props.limitToFuture) {
            const newDate = adjustViewDate(props.date, props.viewMode, -1);
            return newDate < getStartViewDateForViewModeUnit(props.viewMode, now);
        }
        return false;
    }

    /********* Render *********/

    return (
        <div id="calendar-navigator" className={classes.root}>
            <div className={classes.buttons}>
                <Button variant='outlined' onClick={onTodayClicked} size='large'>
                    Today
                </Button>
                <IconButton onClick={() => onDateChanged(-1)} className={classes.iconButton} disabled={shouldDisablePreviousButton()}>
                    <ChevronLeft />
                </IconButton>
                <IconButton onClick={() => onDateChanged(1)} className={classes.iconButton}>
                    <ChevronRight />
                </IconButton>
            </div>
            <Typography variant="h6">
                {getTextDisplay(props.date, props.viewMode, props.abbreviateText)}
            </Typography>
        </div>
    )
});

export default CalendarNavigator;