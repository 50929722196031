import React, { useState, useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, useMediaQuery } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import { DialogState } from "../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import { RootContext } from "../../stores";
import { observer } from "mobx-react";
import SuccessMessage from "../Shared/SuccessMessage";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        emphasizedText: {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
    }),
);

interface ContactUsDialogProps {
    dialogState: DialogState;
}

const ContactUsDialog = observer((props: ContactUsDialogProps) => {

    /********* React hooks *********/

    const rootStore = useContext(RootContext);
    const helpStore = rootStore.helpStore;
    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* State *********/

    const [messageSubmitted, setMessageSubmitted] = useState(false);
    const [validationRun, setValidationRun] = useState(false);
    const [helpMessage, setHelpMessage] = useState(helpStore.getCopyOfDefaultHelpMessage());

    /********* Effects *********/

    useEffect(() => {
        if (props.dialogState.open) {
            reset();
        } else {
            // Reset the message on close rather than open in order
            // to allow fields of the help message to be set from
            // another component.
            helpStore.setHelpMessageOverrides();
            resetHelpMessage();
        }
    }, [props.dialogState.open]);

    useEffect(() => {
        resetHelpMessage();
    }, [helpStore.defaultHelpMessage]);

    /********* Helper methods *********/

    const reset = () => {
        setMessageSubmitted(false);
        setValidationRun(false);
    }

    const resetHelpMessage = () => {
        setHelpMessage(helpStore.getCopyOfDefaultHelpMessage());
    }

    const submitContactMessage = async () => {
        props.dialogState.setLoading(true);
        helpMessage.setAllFieldsDirty();
        setValidationRun(true);
        if (helpMessage.validated) {
            const succeeded = await helpStore.submitContactMessage(helpMessage);
            if (succeeded) {
                setMessageSubmitted(true);
            }
        }
        props.dialogState.setLoading(false);
    }

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            title={!messageSubmitted ? 'Contact Us' : undefined}
            state={props.dialogState}
            DialogProps={{ fullScreen: xsDown }}
            primaryButtonProps={!messageSubmitted ? { children: 'Submit', startIcon: <SendIcon /> } : undefined}
            cancelText={!messageSubmitted ? undefined : 'Close'}
            onSubmit={submitContactMessage}
        >
            {!messageSubmitted
                ? <ContactForm
                    helpMessage={helpMessage}
                    fieldsDisabled={props.dialogState.loading}
                    validationRun={validationRun}
                />
                : <SuccessMessage
                    title={'Thank you for contacting us.'}
                    details={(
                        <React.Fragment>
                            We'll be in touch in a <span className={classes.emphasizedText}>flash!</span>
                        </React.Fragment>
                    )}
                />
            }
        </ThemedDialogWithSpinner>
    )
});

export default ContactUsDialog;