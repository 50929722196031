import { makeStyles, Theme, createStyles, Container, Paper, Typography, Button, useMediaQuery } from "@material-ui/core";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Route, Routes, useParams } from "react-router-dom";
import { CalendarPlus, ClipboardCheck } from "mdi-material-ui";
import clsx from 'clsx';
import { Dictionary } from "../../../logic/Dictionaries";
import { RootContext } from "../../../stores";
import { CalendarEvent } from "../../../stores/models/CalendarEvent";
import { Shift } from "../../../stores/models/Shift";
import { ShiftDetails } from "../../../stores/models/ShiftDetails";
import FormLoadingSpinner from "../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import ShiftListDisplayMode, { ShiftListDisplayModeState } from "../../Organization/VolunteerOpportunities/ShiftListDisplayMode";
import CalendarNavigator from "../../Shared/Calendar/CalendarNavigator";
import useDatesToDisplay, { getFirstDateToDisplayOnCalendar, getLastDateToDisplayOnCalendar } from "../../Shared/Calendar/DatesToDisplay";
import useShiftsToDisplay from "../../Shared/Calendar/ShiftsToDisplay";
import Editor from "../Editor";
import ShiftDetailsPage from "../Pages/Organization/Scheduling/ShiftDetailsPage";
import ShiftEntryDialog from "../Pages/Organization/Scheduling/ShiftEntryDialog";
import Calendar from "../../Shared/Calendar/Calendar";
import { ShiftCollection } from "../../../stores/models/ShiftCollection";
import ShiftTable from "./ShiftTable";
import { ShiftOpportunities } from "../../../stores/VolunteerStore";
import PublicShiftList from "../../Organization/VolunteerOpportunities/PublicShiftList";
import LoadingIndicator from "../../Shared/LoadingIndicator";
import ErrorMessage from "../../Shared/ErrorMessage";
import { CalendarViewMode, getEndViewDateForViewModeUnit, getStartViewDateForViewModeUnit } from "../../Shared/Calendar/CalendarLogicHelpers";
import ShiftCard, { ShiftAction } from "../../Organization/VolunteerOpportunities/ShiftCard";
import ShiftSignUpDialog from "../../Organization/VolunteerOpportunities/ShiftSignUpDialog";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import { ShiftIdentification } from "../../../stores/models/ShiftIdentification";
import { ShiftDetailsModification } from "../../../stores/models/ShiftDetailsModification";
import ShiftList from "../../Organization/VolunteerOpportunities/ShiftList";
import { getSearchLink } from "../../Navigation/Links/UrlConstructors";
import { Form } from "../../../stores/models/Form";
import { getInitialDialogStates } from "../../../logic/DialogStateDictionary";
import FormDialog from "../../Shared/Forms/DialogForm/FormDialog";
import { APIError } from "../../../stores/models/APIError";
import { useNavigateInternally } from "../../Navigation/Hooks";
import { ShiftLabels } from "../../../data/temporary/ShiftLabels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: '0.5rem'
        },
        toolbar: {
            display: 'flex',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(2)
            }
        },
        flex: {
            display: 'flex'
        },
        actionButtons: {
            flexWrap: 'wrap',
            margin: theme.spacing(-.5),
            marginBottom: theme.spacing(1),
            '& > :not(:last-child)': {
                marginRight: theme.spacing(2)
            },
            '& > *': {
                margin: theme.spacing(.5)
            }
        },
        columnFlex: {
            display: 'flex',
            flexDirection: 'column'
        },
        maxHeight: {
            height: '100%'
        },
        flexGrow: {
            flexGrow: 1
        },
        flexSpaced: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between'
        },
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line',
            width: '100%'
        },
        calendarNavigator: {
            marginRight: theme.spacing(2),
        },
        relative: {
            position: 'relative'
        },
        loadingIndicator: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        popoutCard: {
            [theme.breakpoints.up('sm')]: {
                minWidth: 420,
            },
            '& .MuiCard-root': {
                background: 'white',
                border: 'none',
                padding: theme.spacing(1, 2),
                width: 'auto'
            }
        },
        registeredIcon: {
            width: theme.spacing(2),
            marginLeft: -5,
            marginRight: 2,
            color: theme.palette.primary.main
        }
    }),
);

enum DialogTypes {
    NewShift = 'newShift',
    RegistrationForm = 'registrationForm',
    ShiftSignUp = 'shiftSignUp',
}

/***** Helper functions for List View *****/

type ShiftCalendarData = {
    shifts: Shift[],
    shiftOpportunities: ShiftOpportunities
};

interface ScheduleViewerProps {
    title: string | JSX.Element;
    loadInitialData: (startTimestamp: Date, endTimestamp: Date) => Promise<ShiftCalendarData | undefined>;
    refreshInitialData?: boolean;
    onShiftClicked: (shift: Shift) => void;
    scheduleLink?: string;
    shiftDetailsPath?: string;
    noSubsequentDataToLoad?: boolean;
    editable?: boolean;
    fullScreen?: boolean;
    useCardList?: boolean;
    listView?: boolean;
    hidePastShifts?: boolean;
    needsPermission?: boolean;
    assignmentOnly?: boolean;
    opportunitySpecific?: boolean;
    registration?: { completed: boolean, form?: Form };
    resetForm?: () => void;
    onRegistrationSubmitClicked?: () => Promise<{ succeeded: boolean, error?: string | APIError }>;
}

const ScheduleViewer = observer((props: ScheduleViewerProps) => {

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const rootStore = useContext(RootContext);
    const shiftStore = rootStore.shiftStore;
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;
    const navigate = useNavigateInternally();

    const { orgId } = useParams() as { orgId?: string };

    /********* State *********/

    const [shiftCollection, setShiftCollection] = useState(new ShiftCollection());
    const [shiftOpportunities, setShiftOpportunities] = useState<ShiftOpportunities>({});
    const [viewDate, setViewDate] = useState(new Date());
    const [viewMode] = useState(CalendarViewMode.Month);
    const [displayMode] = useState(new ShiftListDisplayModeState(props.listView || xsDown ? true : false));
    const [refreshNeeded, setRefreshNeeded] = React.useState(false);
    const [draftShiftDetails, setDraftShiftDetails] = React.useState(new ShiftDetails());
    const [shiftsForList, setShiftsForList] = React.useState<Shift[]>([]);
    const [shiftDetailsForList, setShiftDetailsForList] = React.useState<ShiftDetails[]>([]);
    const [isInitialLoadForList, setIsInitialLoadForList] = React.useState(true);
    const [shiftsForCalendarLoaded, setShiftsForCalendarLoaded] = useState(false);
    const [shiftsForListLoaded, setShiftsForListLoaded] = React.useState(false);
    const [initialDataRequested, setInitialDataRequested] = React.useState(false);
    const [initialDataLoaded, setInitialDataLoaded] = React.useState(false);
    const [eventsToDisplay, setEventsToDisplay] = React.useState<Dictionary<number, CalendarEvent<Shift>[]>>({});
    const [selectedShift, setSelectedShift] = useState<Shift>(new Shift());
    const [dialogStates] = useState(getInitialDialogStates(Object.values(DialogTypes)));
    const [registrationCallback, setRegistrationCallback] = useState<() => void>();

    /********* React Hooks *********/

    const datesToDisplay = useDatesToDisplay(viewDate, viewMode);
    const shiftsToDisplay = useShiftsToDisplay(
        getFirstDateToDisplayOnCalendar(viewDate, viewMode),
        getLastDateToDisplayOnCalendar(viewDate, viewMode),
        shiftCollection,
        refreshNeeded
    );

    /********* Effects *********/

    useEffect(() => {
        if (props.refreshInitialData !== false && initialDataLoaded) {
            loadInitialData();
        }
    }, [userStore.user.id, props.refreshInitialData]);

    useEffect(() => {
        if (selectedShift) {
            const reloadedSelectedShift = shiftCollection.getShiftByIdentificationData(selectedShift.identificationData);
            if (!reloadedSelectedShift) return;
            setSelectedShift(reloadedSelectedShift);
            refreshShiftSignUpDialog();
        }
    }, [shiftCollection]);

    const refreshShiftSignUpDialog = () => {
        if (dialogStates.shiftSignUp.open) {
            dialogStates.shiftSignUp.setOpen(false);
            setTimeout(() => {
                dialogStates.shiftSignUp.setOpen(true);
            }, 0)
        }
    }

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if (!displayMode.listView && xsDown) {
            displayMode.setListView(xsDown);
        }
    }, [xsDown]);

    useEffect(() => {
        // Request shift data when the date to view changes:

        if (timeoutId) { // timeoutId for debouncing
            clearTimeout(timeoutId); // prevent execution of previous setTimeout
        }

        setTimeoutId(setTimeout(() => {
            if (!props.noSubsequentDataToLoad || !initialDataRequested) {
                setInitialDataRequested(true);
                loadInitialData();
            }
        }, 150));
    }, [viewDate]);

    useEffect(() => {
        if (refreshNeeded) {
            setRefreshNeeded(false);
        }
    }, [refreshNeeded]);

    useEffect(() => {
        // Reset the New Shift dialog:
        if (!dialogStates.newShift.open) {
            setDraftShiftDetails(new ShiftDetails());
        }
    }, [dialogStates.newShift.open]);

    useEffect(() => {
        // Update the events to display in the calendar view:
        setEventsToDisplay(getEventsForCalendarView());
    }, [shiftsToDisplay]);

    useEffect(() => {
        if (initialDataLoaded) {
            setShiftsForCalendarLoaded(true);
        }
    }, [eventsToDisplay]);

    useEffect(() => {
        // Update the shifts to display in the list view:
        const newEventsForListView = getEventsForListView();
        setShiftsForList(newEventsForListView);
        updateShiftDetailsForList(newEventsForListView);
        if (!isInitialLoadForList) {
            setShiftsForListLoaded(true);
        } else {
            setIsInitialLoadForList(false);
        }
    }, [shiftsToDisplay]);

    useEffect(() => {
        if (!dialogStates.registrationForm.open && props.resetForm) {
            props.resetForm();
        }
    }, [dialogStates.registrationForm.open]);

    useEffect(() => {
        if (props.registration?.completed) {
            dialogStates.registrationForm.setOpen(false);
            if (registrationCallback) {
                registrationCallback();
            }
            setRegistrationCallback(undefined);
        }
    }, [props.registration?.completed]);

    /********* Helper methods *********/

    const getEventsForCalendarView = () => {
        let newEventsToDisplay = {} as Dictionary<number, CalendarEvent<Shift>[]>;
        (Object.keys(shiftsToDisplay) as unknown as number[]).forEach(date => {
            newEventsToDisplay[date] = [];
            shiftsToDisplay[date].forEach(shift => {
                const expired = shift.repetitionPattern.hasEnded;
                if (!expired || !props.hidePastShifts) {
                    const position = getOpportunityDataForShift(shift)?.position || ShiftLabels[shift.id] || "";
                    newEventsToDisplay[date].push(new CalendarEvent({
                        startTime: shift.repetitionPattern.startTimestamp!,
                        endTime: shift.repetitionPattern.endTimestamp!,
                        label: position || '',
                        recurring: shift.isShiftInstance,
                        dataObject: shift,
                        icon: shift.userOrProxyRegistered ? <ClipboardCheck className={classes.registeredIcon} /> : undefined
                    }));
                }
            })
        });
        return newEventsToDisplay;
    }

    const getEventsForListView = () => {
        const startDate = getStartViewDateForViewModeUnit(viewMode, viewDate).getTime();
        const endDate = getEndViewDateForViewModeUnit(viewMode, viewDate).getTime();
        let shiftsWithinRange = [] as Shift[];
        const sortedKeys = (Object.keys(shiftsToDisplay) as unknown as number[]).sort();
        sortedKeys.forEach(date => {
            if (date >= startDate && date <= endDate) {
                shiftsWithinRange.push(...shiftsToDisplay[date]);
            }
        });
        return shiftsWithinRange;
    }

    const updateShiftDetailsForList = (newShiftsForListView: Shift[]) => {
        const newShiftDetailsForListView = newShiftsForListView.map(shift => {
            return new ShiftDetails({
                shift: shift,
                ...getOpportunityDataForShift(shift)
            });
        });
        setShiftDetailsForList(newShiftDetailsForListView);
    }

    const getTopLevelIdForShift = (shift: Shift) => {
        return shift.identificationData.topLevelId;
    }

    const getOpportunityDataForShift = (shift: Shift) => {
        const topLevelShiftId = getTopLevelIdForShift(shift);
        return shiftOpportunities[topLevelShiftId];
    }

    const loadInitialData = async () => {
        let data = await props.loadInitialData(
            getFirstDateToDisplayOnCalendar(viewDate, viewMode),
            getLastDateToDisplayOnCalendar(viewDate, viewMode)
        );
        if (data) {
            const newShiftCollection = new ShiftCollection(data.shifts);
            setShiftCollection(newShiftCollection);
            setShiftOpportunities(data.shiftOpportunities);
            setInitialDataLoaded(true);
            setRefreshNeeded(true);
        }
    }

    /********* Event handler *********/

    const onConfirmNewShift = async (shiftDetails: ShiftDetails) => {
        if (shiftDetails) {
            shiftDetails.setAllFieldsDirty();
            shiftDetails.shift.setAllFieldsDirty();
            shiftDetails.shift.repetitionPattern.setAllFieldsDirty();
            shiftDetails.shift.repetitionPattern.ending.setAllFieldsDirty();
            if (shiftDetails.validated) {
                const response = await shiftStore.upsertShiftDetails(new ShiftDetailsModification(shiftDetails));
                if (response) {
                    shiftCollection.addOrUpdateShift(response.details.shift);
                    if (response.shiftOpportunities) {
                        setShiftOpportunities(response.shiftOpportunities);
                    }
                    dialogStates.newShift.setOpen(false);
                }
            }
        }
    }

    const onShiftUpdated = (newShiftDetails: ShiftDetails, oldShiftDetails?: ShiftDetails) => {
        // Shift opportunity changed:
        if (oldShiftDetails && oldShiftDetails.opportunityId !== newShiftDetails.opportunityId) {
            shiftCollection.deleteShift(oldShiftDetails.shift);
            delete shiftOpportunities[oldShiftDetails.shift.id];
        }

        if (newShiftDetails.shift.archived) {
            shiftCollection.deleteShift(newShiftDetails.shift);
        } else {
            if (newShiftDetails.oldParentShift) {
                shiftCollection.addOrUpdateShift(newShiftDetails.oldParentShift);
            }
            if (newShiftDetails.parentShift) {
                shiftCollection.addOrUpdateShift(newShiftDetails.parentShift);
                shiftOpportunities[newShiftDetails.parentShift.id] = { position: newShiftDetails.position, opportunityId: newShiftDetails.opportunityId, organizationId: newShiftDetails.organizationId };
            } else {
                shiftCollection.addOrUpdateShift(newShiftDetails.shift);
                shiftOpportunities[newShiftDetails.shift.id] = { position: newShiftDetails.position, opportunityId: newShiftDetails.opportunityId, organizationId: newShiftDetails.organizationId };
            }
        }

        setRefreshNeeded(true);
    }

    const onDateClicked = (date: Date) => {
        if (props.editable) {
            draftShiftDetails.shift.repetitionPattern.setStartDate(date);
            dialogStates.newShift.setOpen(true);
        }
    }

    const onShiftCalendarEventClicked = (calendarEvent: CalendarEvent<Shift>) => {
        const shift = calendarEvent.dataObject;
        if (shift) {
            props.onShiftClicked(shift);
        }
    }

    const onShiftRowClicked = (shift: Shift) => {
        props.onShiftClicked(shift);
    }

    const onShiftActionSelected = (shift: Shift, action: ShiftAction) => {
        switch (action) {
            case ShiftAction.SignUp:
            case ShiftAction.EditRegistration:
                onShiftSignUp(shift);
                break;
        }
    };

    const onRegistrationSubmitted = async () => {
        if (props.onRegistrationSubmitClicked === undefined) return { succeeded: true };
        const response = await props.onRegistrationSubmitClicked();
        return response;
    }

    const performActionIfVolunteerRegistered = (callback: () => void) => {
        if (props.registration === undefined || props.registration?.completed || props.registration.form === undefined) {
            callback();
        } else {
            // Prompt the volunteer to fill out the registration form first
            dialogStates.registrationForm.setOpen(true); // TODO: Prevent this from opening briefly when it shouldn't (prior to a shift registration when the user had been logged out)
            setRegistrationCallback(() => callback);
        }
    }

    const onShiftSignUp = (shift: Shift) => {
        setSelectedShift(shift);
        if (userStore.isAuthenticated) {
            if (userStore.user.isVolunteer) {
                performActionIfVolunteerRegistered(() => dialogStates.shiftSignUp.setOpen(true));
            } else {
                navigationStore.appwideDialogStates.volunteerAccountRequiredDialog.setOpen(true);
            }
        } else {
            navigationStore.appwideDialogStates.loginDialog.setOpen(true);
            navigationStore.setOnAuthenticationCallback(() => onShiftSignUp(shift));
        }
    }

    const onRegistrationsChanged = (shift: Shift, registered: boolean) => {
        applyRegistrationChange(shift, registered);
        setSelectedShift(new Shift());
    }

    // TODO: Can probably clean this up. The fact that the shift collection is a collection of
    // shift instances rather than top level shifts is making it harder to use ShiftCollection
    // methods that add and remove shifts.
    const onShiftDetailsRegistrationsChanged = (shiftInstance: Shift, registered: boolean) => {
        const instanceInCollection = shiftCollection.shifts.find(instance => Shift.identificationDataMatches(instance.identificationData, shiftInstance.identificationData));
        if (registered) {
            if (instanceInCollection) return;
            const newShiftCollection = shiftCollection.shifts.slice();
            newShiftCollection.push(shiftInstance);
            setShiftCollection(new ShiftCollection(newShiftCollection));
        } else if (instanceInCollection) {
            const newShiftCollection = shiftCollection.shifts.filter(collectionShift => !Shift.identificationDataMatches(collectionShift.identificationData, shiftInstance.identificationData));
            setShiftCollection(new ShiftCollection(newShiftCollection));
        }
        setRefreshNeeded(true);
    }

    const onFindAShiftClicked = () => {
        navigate(getSearchLink());
    }

    const getAddressMatchingId = (addressId: number) => {
        const shiftWithMatchingAddress = shiftCollection.shifts.find(shift => shift.addressId === addressId);
        return shiftWithMatchingAddress?.address;
    }

    const applyRegistrationChange = (shift: Shift, registered: boolean) => {
        const shiftInstance = shift.getInstanceByIdentificationData(selectedShift.identificationData);
        if (shiftInstance?.addressId) {
            shiftInstance?.setAddress(getAddressMatchingId(shiftInstance.addressId));
        }
        if (!shiftInstance) return;
        shiftInstance.setRegistered(registered);
        shiftCollection.addOrUpdateShift(shiftInstance);
        setRefreshNeeded(true);
    }

    const getReplacementForSelectedEventOnCalendar = (previouslySelectedEvent: CalendarEvent<Shift>) => {
        const shiftIdentificationData = previouslySelectedEvent.dataObject?.identificationData;
        if (!shiftIdentificationData) return;

        const keys = Object.keys(eventsToDisplay);
        for (let i = 0; i < keys.length; i++) {
            const key = parseInt(keys[i]);
            for (let j = 0; j < eventsToDisplay[key].length; j++) {
                const shift = eventsToDisplay[key][j].dataObject;
                if (!shift) continue;

                if (Shift.identificationDataMatches(shift.identificationData, shiftIdentificationData)
                    || (shift.instanceData && Shift.identificationDataMatches(
                        new ShiftIdentification({
                            parentId: shift.instanceData.parentId,
                            defaultDaysFromStartDate: shift.instanceData.defaultDaysFromStartDate
                        }),
                        shiftIdentificationData
                    ))
                ) {
                    return eventsToDisplay[key][j];
                }
            }
        }
    }

    /********* Render *********/

    return (
        <Fragment>
            <Routes>
                {props.shiftDetailsPath &&
                    <Route
                        path={props.shiftDetailsPath}
                        element={
                            <ShiftDetailsPage
                                onShiftUpdated={onShiftUpdated}
                                onRegistrationsChanged={onShiftDetailsRegistrationsChanged}
                                editable={props.editable}
                                redirectLink={props.scheduleLink}
                            />
                        }
                    />
                }
                <Route
                    path="/"
                    element={
                        <Editor editsMade={false} className={clsx(classes.columnFlex, !displayMode.listView && props.fullScreen ? classes.maxHeight : undefined)}>
                            {(editorState) => (
                                <Fragment>
                                    <Paper className={clsx(classes.paper, classes.flex, classes.flexGrow)}>
                                        <Container className={classes.columnFlex}>
                                            {typeof props.title === 'string'
                                                ? <Typography variant="h4" className={classes.title}>
                                                    {props.title}
                                                </Typography>
                                                : props.title
                                            }
                                            {/* {serviceEntryCollection.selections.length > 0
                                            ? <div className={classes.selectionMenu}>
                                            <Button
                                            startIcon={<Delete />}
                                            variant="outlined"
                                            size="large"
                                            onClick={confirmDeletion}
                                            >
                                            Delete
                                            </Button>
                                            </div>
                                        :  */}
                                            <div className={clsx(classes.flex, classes.actionButtons)}>
                                                {props.editable &&
                                                    <Button
                                                        startIcon={<CalendarPlus />}
                                                        color="primary"
                                                        variant="contained"
                                                        size="large"
                                                        onClick={() => dialogStates.newShift.setOpen(true)}
                                                    >
                                                        New Shift
                                                    </Button>
                                                }
                                                <div className={classes.flexSpaced}>
                                                    <div className={classes.calendarNavigator}>
                                                        <CalendarNavigator
                                                            date={viewDate}
                                                            viewMode={viewMode}
                                                            onDateChanged={(date: Date) => { setViewDate(date) }}
                                                            limitToFuture={props.hidePastShifts}
                                                            abbreviateText={smDown}
                                                        />
                                                    </div>
                                                    {!xsDown &&
                                                        <ShiftListDisplayMode
                                                            displayModeState={displayMode}
                                                            hideExpandShiftsButton
                                                            calendarButtonFirst
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            {!displayMode.listView
                                                ? <div className={clsx(classes.columnFlex, classes.relative, classes.maxHeight)}>
                                                    <Calendar
                                                        date={viewDate}
                                                        viewMode={viewMode}
                                                        datesToDisplay={datesToDisplay}
                                                        eventsToDisplay={eventsToDisplay}
                                                        onDateClicked={onDateClicked}
                                                        onEventClicked={onShiftCalendarEventClicked}
                                                        eventPopout={!props.editable
                                                            ? (event) => {
                                                                return event.dataObject
                                                                    ? <div className={classes.popoutCard}>
                                                                        <ShiftCard
                                                                            shift={event.dataObject}
                                                                            onShiftActionSelected={onShiftActionSelected}
                                                                            showSignUpOption={!props.assignmentOnly && !props.needsPermission}
                                                                            displaySignUpAtBottom
                                                                            registered={event.dataObject.registered}
                                                                            assignmentOnly={props.assignmentOnly}
                                                                            permissionNeeded={props.needsPermission}
                                                                        />
                                                                    </div>
                                                                    : <div />
                                                            }
                                                            : undefined
                                                        }
                                                        replaceSelectedEvent={getReplacementForSelectedEventOnCalendar}
                                                        fullScreen={props.fullScreen}
                                                    />
                                                    {!shiftsForCalendarLoaded && <FormLoadingSpinner />}
                                                </div>
                                                : shiftsForListLoaded
                                                    ? props.useCardList
                                                        ? !props.opportunitySpecific
                                                            ? <ShiftList
                                                                records={shiftDetailsForList}
                                                                hideExpired={props.hidePastShifts}
                                                                editable={false}
                                                                showSignUpOption
                                                                onShiftActionSelected={onShiftActionSelected}
                                                                noResultsText={
                                                                    <Fragment>
                                                                        <ErrorMessage
                                                                            title="No shifts to display."
                                                                            details="Try adjusting the selected time period or look for a shift to add."
                                                                        />
                                                                        <Button variant='contained' color='primary' onClick={onFindAShiftClicked}>
                                                                            Find a Shift
                                                                        </Button>
                                                                    </Fragment>
                                                                }
                                                            />
                                                            : <PublicShiftList
                                                                records={shiftsForList}
                                                                onShiftActionSelected={onShiftActionSelected}
                                                                noResultsText={
                                                                    <ErrorMessage
                                                                        title="No shifts to display."
                                                                        details="Try adjusting the selected time period."
                                                                    />
                                                                }
                                                                permissionNeeded={props.needsPermission}
                                                                assignmentOnly={props.assignmentOnly}
                                                                showExpired={!props.hidePastShifts}
                                                            />
                                                        : <ShiftTable
                                                            shifts={shiftsForList}
                                                            onShiftRowClicked={onShiftRowClicked}
                                                            getPositionForShift={(shift) => getOpportunityDataForShift(shift)?.position || ""}
                                                        />
                                                    : <div className={classes.loadingIndicator}>
                                                        <LoadingIndicator />
                                                    </div>
                                            }
                                        </Container>
                                    </Paper>

                                    {/* Shift Entry and Shift Sign Up / Unregister Dialogs */}
                                    {props.editable
                                        ? <ShiftEntryDialog
                                            record={draftShiftDetails}
                                            copyRecord={record => new ShiftDetails(record)}
                                            state={dialogStates.newShift}
                                            title={'Add Shift'}
                                            onConfirm={onConfirmNewShift}
                                            confirmText={'Add'}
                                        />
                                        : <Fragment>
                                            <ShiftSignUpDialog
                                                state={dialogStates.shiftSignUp}
                                                shift={selectedShift}
                                                organizationId={orgId ? parseInt(orgId) : getOpportunityDataForShift(selectedShift)?.organizationId}
                                                onRegistrationsChanged={onRegistrationsChanged}
                                            />
                                            {props.registration?.form &&
                                                <FormDialog
                                                    state={dialogStates.registrationForm}
                                                    form={props.registration.form}
                                                    onFormSubmit={onRegistrationSubmitted}
                                                />
                                            }
                                        </Fragment>
                                    }
                                </Fragment>
                            )
                            }
                        </Editor >
                    }
                />
            </Routes>
        </Fragment>
    );
});

export default ScheduleViewer;