import { useContext, useEffect, useState } from "react";
import { RootContext } from "../../../../stores";
import { useOrgPortalSlug } from "./OrgPortalSlug";
import { Environments, getEnvironment } from "../../../General";

// TODO: Refactor to separate out the image caching logic

// Returns the URL for loading a particular organization's custom portal logo

const getCloudfrontDistribution = () => {
    const environment = getEnvironment();
    switch (environment) {
        case Environments.Demo:
            return process.env.REACT_APP_DEMO_CUSTOMER_UPLOADS_CLOUDFRONT_DIST;
        default:
            return process.env.REACT_APP_CUSTOMER_UPLOADS_CLOUDFRONT_DIST;
    }
}

const portalLogoPath = 'logos/portal';

export const useOrgPortalLogoSrc = (overrideSlug?: string) => {

    const rootStore = useContext(RootContext);
    const { imageStore } = rootStore;

    const urlPortalSlug = useOrgPortalSlug();
    const [cloudfrontDistribution] = useState(getCloudfrontDistribution());

    const portalSlug = (overrideSlug ? overrideSlug : urlPortalSlug);
    let logoUrl = `${cloudfrontDistribution}/${portalLogoPath}/${portalSlug}`;
    let cacheDate = imageStore.imageCacheDates.get(logoUrl);

    const getPortalLogoUrl = () => {
        if (portalSlug) {
            if (!cacheDate) {
                cacheDate = new Date();
                imageStore.imageCacheDates.set(logoUrl, cacheDate);
            }
            logoUrl = logoUrl + '?' + cacheDate.getTime();
            return logoUrl;
        }
    }

    const [portalLogoSrc, setPortalLogoSrc] = useState<string | undefined>(getPortalLogoUrl());

    useEffect(() => {
        setPortalLogoSrc(getPortalLogoUrl());
    }, [portalSlug, cacheDate]);

    return portalLogoSrc;
}