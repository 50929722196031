import { useContext, Fragment } from "react";
import { makeStyles, Theme, createStyles, Container, Typography, Button } from "@material-ui/core";
// import { Facebook, Instagram, Twitter } from "mdi-material-ui";
import { getSearchLink, getAccountProfileLink, getSparksLink, getHomeLink, getVolunteerInstructionsLink, getAccountSettingsLink } from "../Navigation/Links/UrlConstructors";
import { RootContext } from "../../stores";
import { observer } from "mobx-react";
import { useIsOrgPortal, useNavigateInternally } from "../Navigation/Hooks";
import OrganizationPortalFooter from "./OrganizationPortalFooter";
import FooterCopyright from "./FooterCopyright";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            marginTop: theme.spacing(4),
            background: theme.palette.darkBackground.main,
            color: theme.palette.darkBackground.contrastText,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            textAlign: 'center',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(4)
        },
        navigationButtons: {
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(4),
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                '& > button:not(:first-child)': {
                    marginLeft: theme.spacing(2)
                },
            },
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        socialMediaLinks: {
            display: 'flex',
            justifyContent: 'center'
        },
        copyright: {
            marginTop: theme.spacing(2),
        },
        subheader: {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            '&:not(:first-child)': {
                marginTop: theme.spacing(3)
            }
        },
        avatar: {
            color: theme.palette.common.white,
            background: theme.palette.action.disabledBackground
        },
        customPortalFooterlinks: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
        }
    })
);

const Footer = observer(() => {

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;
    const navigate = useNavigateInternally();
    const isOrgPortal = useIsOrgPortal();

    const onClickedInternalLink = (link: string) => {
        navigate(link);
    }

    const onClickedProfileLink = () => {
        onClickedInternalLink(getAccountSettingsLink());
    }

    return (
        isOrgPortal
            ? <OrganizationPortalFooter copyright={<FooterCopyright linksClassName={classes.customPortalFooterlinks} />} />
            : <div className={classes.footer}>
                <Container>
                    {/* Social Media Links */}
                    {/* <Typography className={classes.subheader}>Stay Plugged In</Typography>
                <div className={classes.socialMediaLinks}>
                    <IconButton href={'https://www.facebook.com/voltagevolunteeering'} target={'_blank'} component="a">
                        <Avatar className={classes.avatar}><Facebook /></Avatar>
                    </IconButton>
                    <IconButton href={'https://twitter.com/VoltageVols'} target={'_blank'} component="a">
                        <Avatar className={classes.avatar}><Twitter /></Avatar>
                    </IconButton>
                    <IconButton href={'https://www.instagram.com/voltage_volunteering/'} target={'_blank'} component="a">
                        <Avatar className={classes.avatar}><Instagram /></Avatar>
                    </IconButton>
                </div> */}
                    {/* Navigation Links */}
                    <Typography className={classes.subheader}>Navigation</Typography>
                    <div className={classes.navigationButtons}>
                        <Button color="inherit" onClick={() => onClickedInternalLink(getHomeLink())}>Home</Button>
                        <Button color="inherit" onClick={() => onClickedInternalLink(getSearchLink())}>Browse</Button>
                        {userStore.isAuthenticated
                            ? <Button color="inherit" onClick={() => onClickedProfileLink()}>Profile</Button>
                            : <Fragment>
                                <Button color="inherit" onClick={() => navigationStore.appwideDialogStates.signUpDialog.setOpen(true)}>Sign Up</Button>
                                <Button color="inherit" onClick={() => navigationStore.appwideDialogStates.loginDialog.setOpen(true)}>Login</Button>
                            </Fragment>
                        }
                        <Button color="inherit" onClick={() => onClickedInternalLink(getVolunteerInstructionsLink())}>Help</Button>
                        <Button color="inherit" onClick={() => navigationStore.appwideDialogStates.contactUsDialog.setOpen(true)}>Contact Us</Button>
                    </div>
                    {/* Copyright */}
                    <div className={classes.copyright}>
                        <FooterCopyright />
                    </div>
                </Container>
            </div>
    )
});

export default Footer;