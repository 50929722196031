import React from "react";
import { observer } from "mobx-react";
import { Avatar, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { getOrganizationLink } from "../../Navigation/Links/UrlConstructors";
import { LinkVariant } from "mdi-material-ui";
import { useNavigateInternally } from "../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginBottom: theme.spacing(1),
            maxWidth: 800,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
        button: {
            marginTop: theme.spacing(2)
        }
    })
);

interface InstructionsForAuthenticatedUserProps {
    organizationId: number;
    organizationName: string;
}

const InstructionsForAuthenticatedUser = observer((props: InstructionsForAuthenticatedUserProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const navigate = useNavigateInternally();

    /********* Event handler *********/

    const onNotificationSettingsButtonClicked = () => {
        // navigate(getSearchLink());
        // TODO: Implement
    }

    const onViewOrganizationPageClicked = () => {
        navigate(getOrganizationLink(props.organizationId));
    }

    /***** Render *****/

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <LinkVariant fontSize='large' />
            </Avatar>
            <Typography variant="h4" className={classes.prompt}>
                You've been linked to {props.organizationName}!
            </Typography>
            <Typography>
                Check out their page to find volunteer opportunities that might spark your interest.
                {/* You can adjust your notification settings from the Notifications section of your profile. */}
            </Typography>
            <Button
                variant="contained"
                color='primary'
                onClick={onViewOrganizationPageClicked}
                className={classes.button}
            >
                View Organization Page
                {/* Notifications Settings */}
            </Button>
        </React.Fragment>
    );
});

export default InstructionsForAuthenticatedUser;