import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ServiceEntryStatuses } from '../data-access/entities/ServiceEntryStatuses';
import { CheckCircleOutline, HelpCircleOutline, MinusCircleOutline } from 'mdi-material-ui';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        confirmed: {
            color: `${theme.palette.success.main} !important`
        },
        unconfirmed: {
            color: `${theme.palette.warning.main} !important`
        },
        voided: {
            color: `${theme.palette.error.main} !important`
        },
    })
);

interface ServiceEntryStatusIconProps {
    statusId: number;
    className?: string;
}

const ServiceEntryStatusIcon = observer((props: ServiceEntryStatusIconProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    switch (props.statusId) {
        case ServiceEntryStatuses.Unconfirmed:
            return <HelpCircleOutline className={clsx(props.className, classes.unconfirmed)} />;
        case ServiceEntryStatuses.Confirmed:
            return <CheckCircleOutline className={clsx(props.className, classes.confirmed)} />;
        default:
            return <MinusCircleOutline className={clsx(props.className, classes.voided)} />;
    }
});

export default ServiceEntryStatusIcon;