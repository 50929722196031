import { useMediaQuery, useTheme } from '@material-ui/core';

// Determines the number of grid columns cards should occupy on Account Settings pages

export default function useAccountSettingsGridColumns(): 1 | 2 | 3 {

    const theme = useTheme();
    let oneColumn = useMediaQuery(theme.breakpoints.between('md', 970));
    oneColumn = useMediaQuery(theme.breakpoints.down(720)) || oneColumn;
    let threeColumn = useMediaQuery(theme.breakpoints.up(1300));

    
    return oneColumn ? 1 : threeColumn ? 3 : 2;
}

