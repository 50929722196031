import React, { useRef, useEffect, useState } from "react";
import { Typography, makeStyles, Theme, createStyles, Fade, Dialog, Divider, Button, IconButton, Popper } from "@material-ui/core";
import CityInput from "./CityInput";
import { observer } from "mobx-react";
import { DialogState } from "../../../stores/models/DialogState";
import { City } from "../../../stores/models/City";
import { ArrowLeft } from "mdi-material-ui";

// TODO: Clean up

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        emphasizedText: {
            color: theme.palette.primary.main,
            fontWeight: 900
        },
        text: {
        },
        input: {
            flexGrow: 1
            // display: 'flex',
            // justifyContent: 'center'
        },
        locationIcon: {
            color: theme.palette.primary.main,
        },
        flex: {
            color: theme.palette.action.active,
            padding: theme.spacing(1.5, 1),
            display: 'flex',
            alignItems: 'center'
        },
        popper: {
            zIndex: theme.zIndex.modal + 1,
            width: '100% !important',
            top: `${theme.spacing(9)}px !important`,
            transform: 'none !important',
            willChange: 'none !important',
            left: 0,
            bottom: 0
        },
        paper: {
            boxShadow: 'none',
            height: '100%',
            overflowY: 'auto'
        },
        listbox: {
            maxHeight: 'none'
        }
    }),
);

interface SimpleCityPickerProps {
    state: DialogState;
    city: string;
    cityInputAnimateIn?: boolean;
    focusCityInput?: boolean;
    onCityInputAnimatedIn?: () => void;
    onCityChanged: (city?: City) => void;
}

const SimpleCityPicker = observer((props: SimpleCityPickerProps) => {

    /****** React hooks ******/

    const classes = useStyles();

    /****** State ******/

    const [city, setCity] = useState<string>(props.city);
    const cityInputRef = useRef<any>();
    // const suggestionsContainerRef = useRef<HTMLDivElement>(null);

    const suggestionsContainer = document.getElementById('auto-complete-suggestions');

    useEffect(() => {
        setCity(props.city);
    }, [props.city]);

    useEffect(() => {
        // Assign focus to the city input
        if (props.state.open) {
            setTimeout(() => {
                if (cityInputRef.current) {
                    cityInputRef.current.focus();
                }
            }, 0);
        }
    }, [props.state.open]);

    const onSearchTextChanged = (text: string) => {
        setCity(text);
    }

    return (
        <Dialog open={props.state.open} fullScreen>
            <div className={classes.flex}>
                <IconButton onClick={() => props.state.setOpen(false)}>
                    <ArrowLeft />
                </IconButton>
                <div className={classes.input}>
                    <CityInput
                        city={city}
                        onChange={onSearchTextChanged}
                        onSelectionChanged={props.onCityChanged}
                        placeholder="Enter a city or zip code."
                        ref={cityInputRef}
                        classes={{ paper: classes.paper, listbox: classes.listbox }}
                        iconClass={classes.locationIcon}
                        forNavBar
                        noFreeSolo
                        PopperComponent={(props) =>
                            <Popper {...props} anchorEl={suggestionsContainer} className={classes.popper} placement='bottom-start'></Popper>
                        }
                    />
                </div>
            </div>
            <Divider />
            <div id="auto-complete-suggestions" />
        </Dialog>
    )
});

export default SimpleCityPicker;