import { observer } from "mobx-react";
import { makeStyles, Theme, createStyles, MenuItem, ListItemIcon } from "@material-ui/core";
import React, { useContext } from "react";
import { RootContext } from "../../../stores";
import { StyledSelect } from "./StyledSelect";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        interalSelect: {
            '& $listItemIcon': {
                display: 'none'
            }
        },
        listItemIcon: {
            // Referenced from another css rule
        },
        select: {
            padding: theme.spacing(0, 1),
            overflow: 'visible'
        }
    })
);

interface SearchTypeSelectionProps {
    outlined?: boolean;
}

const SearchTypeSelection = observer((props: SearchTypeSelectionProps) => {

    /*** React hooks ***/

    const classes = useStyles();
    const searchStore = useContext(RootContext).searchStore;
    const searchCriteria = searchStore.searchCriteria;

    /*** Event handler ***/

    const onSearchTypeChanged = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        searchCriteria.searchOptions.setSelections([{ value: event.target.value + "" }])
    };

    /*** Render ***/

    return (
        <StyledSelect
            value={searchCriteria.searchType}
            classes={{ root: classes.interalSelect, select: classes.select }}
            onChange={onSearchTypeChanged}
            variant={props.outlined ? 'outlined' : 'standard'}
        >
            {searchCriteria.searchOptions.options.map((option, index) => {
                return (
                    <MenuItem value={option.object.value} key={`search-type-option-${index}`}>
                        <ListItemIcon className={classes.listItemIcon}>
                            {React.createElement(option.object.icon)}
                        </ListItemIcon>
                        {option.object.primaryText}
                    </MenuItem>);
            })}
        </StyledSelect>
    )
});

export default SearchTypeSelection;