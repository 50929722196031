import { useContext } from "react";
import { RootContext } from "../../../stores";
import { SearchTypeOptions } from "../../../data/SearchOptions";
import { getSearchLink } from "../Links/UrlConstructors";
import { useNavigateInternally } from ".";

export const useSearchOrganizations = () => {
    const searchStore = useContext(RootContext).searchStore;
    const navigate = useNavigateInternally();

    return () => {
        searchStore.searchCriteria.setSearchType(SearchTypeOptions.Organizations);
        navigate(getSearchLink());
    }
}

export const useSearchOpportunities = () => {
    const searchStore = useContext(RootContext).searchStore;
    const navigate = useNavigateInternally();

    return () => {
        searchStore.searchCriteria.setSearchType(SearchTypeOptions.Opportunities);
        navigate(getSearchLink());
    }
}