import { TextField, makeStyles, Theme, createStyles, Typography, Grid, Button } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { RootContext } from "../../stores";
import { observer } from "mobx-react";
import { LoginData } from "../../stores/models/LoginData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            position: 'relative'
        },
        forgottenPassword: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            textAlign: 'center'
        },
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        field: {
            display: 'flex',
            flexGrow: 1,
        },
        loginButton: {
            marginTop: theme.spacing(2)
        },
    }),
);

interface LoginFormProps {
    onLoadingStateChanged?: (isLoading: boolean) => void;
}

const LoginForm = observer((props: LoginFormProps) => {

    /********** State **********/

    const [loginData] = useState(new LoginData());
    const [validationRun, setValidationRun] = useState(false);

    const getFormId = () => {
        return `form-login-${Date.now()}-${Math.random()}`;
    }

    const formId = useMemo(() => getFormId(), []);

    /********** React hooks **********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;

    /********** Event handlers **********/

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        loginData.setUsername(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        loginData.setPassword(event.target.value);
    }

    const onForgotPassword = () => {
        rootStore.navigationStore.appwideDialogStates.forgotPasswordDialog.setOpen(true);
    }

    /********** Helper functions **********/

    const onSubmitLoginForm = useMemo(() => (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent page reload
        event.stopPropagation();
        attemptLogin();
        // if (props.onSubmit) {
        //     props.onSubmit();
        // }
    }, []);

    const attemptLogin = async () => {
        if (props.onLoadingStateChanged) props.onLoadingStateChanged(true);
        await userStore.login(loginData);
        setValidationRun(true);
        if (props.onLoadingStateChanged) props.onLoadingStateChanged(false);
    }

    const emailFieldError = validationRun ? loginData.errors.username : undefined;
    const passwordFieldError = validationRun ? loginData.errors.password || loginData.error : undefined;

    /********** Render **********/

    return (
        <form onSubmit={onSubmitLoginForm} noValidate autoComplete="off" id={formId} className={classes.form}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="standard-username-input"
                        label="Email"
                        autoComplete="current-username"
                        variant="outlined"
                        required
                        value={loginData.username}
                        error={loginData.error !== undefined || (emailFieldError !== undefined && emailFieldError.length > 0)}
                        helperText={emailFieldError}
                        className={classes.field}
                        onChange={handleUsernameChange}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="standard-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        className={classes.field}
                        required
                        value={loginData.password}
                        error={passwordFieldError !== undefined && passwordFieldError.length > 0}
                        helperText={passwordFieldError}
                        onChange={handlePasswordChange}
                    />
                </Grid>
            </Grid>
            <Button color="primary" variant="contained" type="submit" form={formId} fullWidth className={classes.loginButton} size="large">
                Login
            </Button>
            <div className={classes.forgottenPassword}>
                <Typography variant="body2" className={classes.link} onClick={onForgotPassword}>
                    Forgot your password?
                </Typography>
            </div>
        </form>
    );
});

export default LoginForm;