import { observer } from "mobx-react";
import { DialogState } from "../../../stores/models/DialogState";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import { Alert } from "@material-ui/lab";
import React from "react";
import { Typography } from "@material-ui/core";

interface InactiveOpportunityDialogProps {
    dialogState: DialogState;
}

const InactiveOpportunityDialog = observer((props: InactiveOpportunityDialogProps) => {
    return (
        <ConfirmationDialog
            state={props.dialogState}
            title={'Inactive Opportunity'}
            content={
                <Alert severity="warning">
                    <Typography>
                        This opportunity has either expired or has been marked as inactive. You are currently the only one with access to view it.
                    </Typography>
                </Alert>
            }
            confirmText={'Okay'}
            onConfirm={() => props.dialogState.setOpen(false)}
            hideCancelButton
        />
    )
});

export default InactiveOpportunityDialog;