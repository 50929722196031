import { Route, Routes, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import {
    getOpportunityEditorPath,
    getServiceDataLink,
    getDashboardLink,
    routeWithDescendants,
    getDashboardPath,
    getSettingsPagePath,
    getLocationsPath,
    getSubscriptionPath,
    getOpportunitiesTablePath,
    getVolunteersPath,
    getSchedulingPath,
    getServiceDataPath,
    getVolunteerServiceHistoryPath,
    getOrganizationHelpPath,
    getAccountProfilePath,
    getReportingPath,
    getCustomPortalConfigurationPath,
} from "../../Navigation/Links/UrlConstructors";
import Dashboard from "../../AccountSettings/Pages/Organization/Dashboard/Dashboard";
import { ProfileSettings, CredentialsSettings, SubscriptionSettings } from "../../AccountSettings/Pages";
import LocationsTable from "../../AccountSettings/Pages/Organization/Locations/LocationsTable";
import OpportunitiesTable from "../../AccountSettings/Pages/Organization/Opportunities/OpportunitiesTable";
import OpportunityEditor from "../../AccountSettings/Pages/Organization/Opportunities/OpportunityEditor";
import SchedulingPage from "../../AccountSettings/Pages/Organization/Scheduling/SchedulingPage";
import OrganizationServiceDataPage from "../../../modules/volunteer-hours/components/OrganizationServiceDataPage";
import AdministratorInstructions from "../../AccountSettings/Pages/Organization/Support/AdministratorInstructions";
import VolunteersSection from "../../AccountSettings/Pages/Organization/Volunteers/VolunteersSection";
import { NavigateInternally } from "../Components";
import ReportingSection from "../../AccountSettings/Pages/Organization/Reporting/ReportingSection";
import CustomPortalConfiguration from "../../AccountSettings/Pages/Organization/CustomPortal/CustomPortalConfiguration";

const OrganizationAccountSettingsRoutes = observer(() => {

    /********* React hooks *********/

    const location = useLocation();
    const searchQuery = location.search || '';

    /********* Render *********/

    return (
        <Routes>
            {/* Organization Section */}
            <Route path={getDashboardPath()} element={<Dashboard />} />
            <Route path={getAccountProfilePath()} element={<ProfileSettings />} />
            <Route path={getLocationsPath()} element={<LocationsTable />} />
            <Route path={getCustomPortalConfigurationPath()} element={<CustomPortalConfiguration />} />
            {/* Recruitment Section */}
            <Route path={routeWithDescendants(getOpportunitiesTablePath())} element={<OpportunitiesTable />} />
            <Route path={getOpportunityEditorPath()} element={<OpportunityEditor />} />
            <Route path={routeWithDescendants(getSchedulingPath())} element={<SchedulingPage />} />
            <Route path={routeWithDescendants(getVolunteersPath())} element={<VolunteersSection />} />
            {/* Data Section */}
            <Route path={getServiceDataPath()} element={<OrganizationServiceDataPage />} />
            {/* If a recruiter clicked the Log Hours button, 
                redirect them to the recruiter's Service Data page */}
            <Route path={getVolunteerServiceHistoryPath()} element={<NavigateInternally to={getServiceDataLink() + searchQuery} replace />} />
            <Route path={routeWithDescendants(getReportingPath())} element={<ReportingSection />} />
            {/* Account Section */}
            <Route path={getSettingsPagePath()} element={<CredentialsSettings />} />
            <Route path={getSubscriptionPath()} element={<SubscriptionSettings />} />
            {/* Support Section */}
            <Route path={getOrganizationHelpPath()} element={<AdministratorInstructions />} />
            {/* Catch All */}
            <Route path={"/*"} element={<NavigateInternally to={getDashboardLink()} replace />} />
        </Routes>
    );
});

export default OrganizationAccountSettingsRoutes;