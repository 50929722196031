import { useEffect, useState } from "react";
import { DemographicsInput, CredentialsInput, AccountTypeSelection } from "./RegistrationSteps";
import { Step } from "../../stores/models/Step";
import { StubUser } from "../../stores/models/StubUser";

enum RegistrationSteps {
    AccountTypeSelection = 'account-type',
    Credentials = 'credentials',
    Demographics = 'demographics'
}

const useRegistrationSteps = (stubUser: StubUser, skipAccountTypeSelection?: boolean) => {

    /********* Helper method *********/

    const getSteps = () => {

        let registrationSteps: Step<any>[] = [];

        const accountTypeSelectionStep = new Step(RegistrationSteps.AccountTypeSelection, AccountTypeSelection, stubUser);
        const volunteerCredentialsStep = new Step(RegistrationSteps.Credentials, CredentialsInput, stubUser.credentials);
        const volunteerDemographicsStep = new Step(RegistrationSteps.Demographics, DemographicsInput, stubUser);

        if (skipAccountTypeSelection) {
            registrationSteps = [volunteerCredentialsStep, volunteerDemographicsStep];
        } else if (stubUser.stubVolunteer) {
            registrationSteps = [accountTypeSelectionStep, volunteerCredentialsStep, volunteerDemographicsStep];
        } else {
            registrationSteps = [accountTypeSelectionStep, volunteerCredentialsStep];
        }

        return registrationSteps;
    }

    /********* State *********/

    const [steps, setSteps] = useState(getSteps());

    /********* Effects *********/

    useEffect(() => {
        setSteps(getSteps());
    }, [stubUser, stubUser.stubVolunteer]);

    return steps;
}

export default useRegistrationSteps;