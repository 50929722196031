import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, getFieldRequiredValidationCheck } from "./Validation";
import { PaymentMethod } from "../../stores/models/PaymentMethod";
import { zipCodeIsValid } from "../RegexValidation";

const paymentMethodChecks: (paymentMethod: PaymentMethod) => ValidationCheck<PaymentMethod>[] = (paymentMethod: PaymentMethod) => {
    return [
        getFieldRequiredValidationCheck(paymentMethod.name, 'name', `Please enter the cardholder's name.`),
        {
            name: 'valid zip code',
            condition: (paymentMethod: PaymentMethod) => zipCodeIsValid(paymentMethod.zipCode),
            error: paymentMethod.zipCode.length === 0
                ? new ValidationError('Zip code required.', ['zipCode'])
                : new ValidationError('Invalid zip code.', ['zipCode'])
        }, {
            name: 'card number required',
            condition: (paymentMethod: PaymentMethod) => !paymentMethod.cardDetailEmpty.cardNumber,
            error: new ValidationError('Please enter your card number.', ['cardNumber'])
        }, {
            name: 'card expiration required',
            condition: (paymentMethod: PaymentMethod) => !paymentMethod.cardDetailEmpty.expirationDate,
            error: new ValidationError('Expiration date required.', ['expirationDate'])
        }, {
            name: 'cvc required',
            condition: (paymentMethod: PaymentMethod) => !paymentMethod.cardDetailEmpty.cvc,
            error: new ValidationError('CVC required.', ['cvc'])
        }, {
            name: 'valid card number',
            condition: (paymentMethod: PaymentMethod) => paymentMethod.cardDetailErrors.cardNumber === undefined,
            error: paymentMethod.cardDetailErrors.cardNumber!
        }, {
            name: 'valid card expiration',
            condition: (paymentMethod: PaymentMethod) => paymentMethod.cardDetailErrors.expirationDate === undefined,
            error: paymentMethod.cardDetailErrors.expirationDate!
        }, {
            name: 'valid card cvc',
            condition: (paymentMethod: PaymentMethod) => paymentMethod.cardDetailErrors.cvc === undefined,
            error: paymentMethod.cardDetailErrors.cvc!
        }];
};

export default function validatePaymentMethod(paymentMethod: PaymentMethod) {
    const validationErrors = getValidationErrors(paymentMethodChecks, paymentMethod);
    return validationErrors;
}