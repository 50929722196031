import React from "react";
import { Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { CreditCard } from "../../../../../stores/models/CreditCard";
import FieldHeader from "../../../../Shared/FieldHeader";

interface PaymentMethodDetailsProps {
    card: CreditCard;
}

const PaymentMethodDetails = observer((props: PaymentMethodDetailsProps) => {

    /********* Render *********/

    return (
        <div>
            <FieldHeader title="Payment Method" />
            <Typography>
                Your card ending in {props.card.last4}
            </Typography>
            <Typography color="textSecondary">
                Expires: {props.card.cardExpiration}
            </Typography>
        </div>
    )
});

export default PaymentMethodDetails;