import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";
import { Shift } from "../../stores/models/Shift";

const shiftChecks: ValidationCheck<Shift>[] = [{
    name: 'slots unlimited or greater than zero',
    condition: (shift: Shift) => {
        return shift.unlimitedSlots || shift.slots > 0;
    },
    error: new ValidationError('The number of slots must be greater than zero.', ['slots'])
}];

export function validateShift(shift: Shift) {
    return getValidationErrors(shiftChecks, shift);
}