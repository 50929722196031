import { RootStore } from "./RootStore";
import { observable, action, computed, makeAutoObservable } from "mobx";
import { initializeObjectDictionary, Dictionary } from "../logic/Dictionaries";
import { AppwideDialog } from "../components/Navigation/AppwideDialogs";
import { DialogState } from "./models/DialogState";

export class NavigationStore {
    private rootStore = {} as RootStore;

    @observable appwideDialogStates: Dictionary<AppwideDialog, DialogState>;
    @observable onAuthenticationCallback?: (userId: number) => void;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
        this.appwideDialogStates = initializeObjectDictionary(Object.values(AppwideDialog), () => new DialogState());
    }

    @action toggleAuthenticationDialogs() {
        this.appwideDialogStates.loginDialog.setOpen(!this.appwideDialogStates.loginDialog.open);
        this.appwideDialogStates.signUpDialog.setOpen(!this.appwideDialogStates.signUpDialog.open);
    }

    @action setOnAuthenticationCallback(callback?: (userId: number) => void) {
        this.onAuthenticationCallback = callback;
    }

    @computed get authenticationDialogOpen() {
        return this.appwideDialogStates.loginDialog.open || this.appwideDialogStates.signUpDialog.open;
    }
}