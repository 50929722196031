import { flow, observable, makeObservable, override, action } from 'mobx';
import { RootStore } from './RootStore';
import { City } from './models/City';
import { AddressSuggestion } from './models/AddressSuggestion';
import { CitySuggestion } from './models/CitySuggestion';
import { DataStore } from './DataStore';

const AUTOCOMPLETE_API_ROUTE = '/api/autocomplete';

const usCityRegex = /, (?=[A-Z]{2}, USA)/;
const usAddressRegex = /, (?=[A-Z]{2}, [0-9]{5}, USA)/;

enum AutocompleteRequestType {
    City = 'autocomplete-city',
    Address = 'autocomplete-address',
    Geolocation = 'geolocation',
}

export class AutocompleteStore extends DataStore<AutocompleteRequestType> {

    @observable ipAddress?: string;
    @observable geolocationSuggestion?: City;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);

        // this.fetchGeolocationBasedSuggestion(); // TODO: Add back later on
    }

    /****** API Rquests ******/

    @action fetchGeolocationBasedSuggestion = flow(function* (this: AutocompleteStore) {
        const data = yield this.makeRequest<{ suggestion: { city: string, state: string } }>(AutocompleteRequestType.Geolocation, { text: this.ipAddress });
        const { suggestion } = data;
        const { city, state } = suggestion;
        const citySuggestion = new City(city, state);
        this.geolocationSuggestion = citySuggestion.isValidCity ? citySuggestion : undefined;
    });

    async fetchCityAutocomplete(searchText: string) {
        const suggestions = await this.getAutocompleteSuggestions(AutocompleteRequestType.City, searchText);
        let options = [] as City[];
        suggestions?.forEach((suggestion: { text: string }) => {
            if (usCityRegex.test(suggestion.text)) {
                options.push(new CitySuggestion(suggestion.text).cityObject);
            }
        });
        return options;
    }

    async fetchAddressAutocomplete(searchText: string) {
        const suggestions = await this.getAutocompleteSuggestions(AutocompleteRequestType.Address, searchText);
        let options = [] as AddressSuggestion[];
        suggestions?.forEach((suggestion: { text: string }) => {
            if (usAddressRegex.test(suggestion.text)) {
                options.push(new AddressSuggestion(suggestion.text));
            }
        });
        return options;
    }

    /****** Helper Method ******/

    private async getAutocompleteSuggestions(requestType: AutocompleteRequestType, searchText: string) {
        this.cancelInFlightRequests(requestType); // Prevent other autocomplete requests from being returned out of order
        const data = await this.makeRequest<{ suggestions: { text: string }[] }>(requestType, { text: searchText });
        return data?.suggestions;
    }

    /****** Computed Data ******/

    @override get getRequests() {
        return [AutocompleteRequestType.City, AutocompleteRequestType.Address, AutocompleteRequestType.Geolocation];
    }

    @override get requestURLs() {
        return {
            [AutocompleteRequestType.City]: `${AUTOCOMPLETE_API_ROUTE}/city`,
            [AutocompleteRequestType.Address]: `${AUTOCOMPLETE_API_ROUTE}/address`,
            [AutocompleteRequestType.Geolocation]: `${AUTOCOMPLETE_API_ROUTE}/geolocation-suggestion`
        };
    }
}

