import { observable, computed, makeObservable } from "mobx";
import { PlanLevels, PlanLevelDictionary, isPlanLevel } from "../../components/Authentication/RegistrationSteps/Organization/Plans";
import { format } from "date-fns";

export interface ISubscription {
    active: boolean;
    cancelled: boolean;
    currentPeriodEnd?: number;
    paymentAmount: number;
    planLevel?: number;
    upcomingPlanLevel?: PlanLevels;
    errorWithCard: boolean;
}

export class Subscription implements ISubscription {
    active = false;
    cancelled = false;
    currentPeriodEnd?: number;
    paymentAmount = 0;
    planLevel?: PlanLevels;
    upcomingPlanLevel?: PlanLevels;
    errorWithCard = false;

    constructor(subscription?: ISubscription) {
        makeObservable(this, {
            active: observable,
            cancelled: observable,
            currentPeriodEnd: observable,
            paymentAmount: observable,
            planLevel: observable,
            upcomingPlanLevel: observable,
            errorWithCard: observable,
            planName: computed,
            upcomingPlanName: computed,
            cityLimitation: computed,
            upcomingCityLimitation: computed,
            remoteOpportunitiesLimitation: computed,
            upcomingRemoteOpportunitiesLimitation: computed,
            paymentDate: computed,
            nextPlanLevel: computed
        });

        if (subscription) {
            this.active = subscription.active;
            this.cancelled = subscription.cancelled;
            this.currentPeriodEnd = subscription.currentPeriodEnd;
            this.paymentAmount = subscription.paymentAmount;
            this.planLevel = isPlanLevel(subscription.planLevel) ? subscription.planLevel : undefined;
            this.upcomingPlanLevel = isPlanLevel(subscription.upcomingPlanLevel) ? subscription.upcomingPlanLevel : undefined;
            this.errorWithCard = subscription.errorWithCard;
        }
    }

    get planName() {
        return this.planLevel ? PlanLevelDictionary[this.planLevel].name : '';
    }

    get upcomingPlanName() {
        return this.upcomingPlanLevel ? PlanLevelDictionary[this.upcomingPlanLevel].name : '';
    }

    get cityLimitation() {
        return this.planLevel ? PlanLevelDictionary[this.planLevel].features.cityLimitation : 0;
    }

    get upcomingCityLimitation() {
        return this.upcomingPlanLevel ? PlanLevelDictionary[this.upcomingPlanLevel].features.cityLimitation : 0;
    }

    get remoteOpportunitiesLimitation() {
        return this.planLevel ? PlanLevelDictionary[this.planLevel].features.remotePosts : 0;
    }

    get upcomingRemoteOpportunitiesLimitation() {
        return this.upcomingPlanLevel ? PlanLevelDictionary[this.upcomingPlanLevel].features.remotePosts : 0;
    }

    get paymentDate() {
        return this.currentPeriodEnd ? format(new Date(this.currentPeriodEnd * 1000), "M/dd/yy") : "";
    }

    get nextPlanLevel() {
        return this.upcomingPlanLevel ? this.upcomingPlanLevel : this.planLevel;
    }
}