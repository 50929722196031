import { makeStyles, Theme, createStyles, Paper, Container } from "@material-ui/core";
import { observer } from "mobx-react";
import { ComponentState } from "../../stores/models/ComponentState";
import FormLoadingSpinner from "../Organization/VolunteerOpportunities/FormLoadingSpinner";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
        },
        loadingWrapper: {
            display: 'flex',
            flexGrow: 1,
            margin: theme.spacing(15),
        },
        container: {
            minHeight: '100px',
            position: 'relative'
        }
    }),
);

interface PaperSectionProps {
    state?: Omit<ComponentState, 'open' | 'editing'>;
    children: JSX.Element | null;
}

const PaperSection = observer((props: PaperSectionProps) => {

    /********** React hooks **********/

    const classes = useStyles();

    /********** Render **********/

    return (
        <Paper className={classes.paper}>
            <Container className={classes.container}>
                {(props.state?.loading || props.children === null) &&
                    <FormLoadingSpinner />
                }
                {props.children}
            </Container>
        </Paper>
    );
});

export default PaperSection;