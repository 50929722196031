import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { HelpMessage } from "../../stores/models/HelpMessage";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, isValidEmail, getMaxLengthValidationCheck, getFieldRequiredValidationCheck } from "./Validation";
import { VOLUNTEER_NAME_MAX_LENGTH, CONTACT_US_SUBJECT_MAX_LENGTH, CONTACT_US_MESSAGE_MAX_LENGTH } from "./FieldLengths";

const helpMessageChecks: (helpMessage: HelpMessage) => ValidationCheck<HelpMessage>[] =
    (helpMessage: HelpMessage) => [
        getFieldRequiredValidationCheck(helpMessage.firstName, 'firstName', 'Please enter your first name.'),
        getMaxLengthValidationCheck(helpMessage.firstName, 'firstName', VOLUNTEER_NAME_MAX_LENGTH),
        getFieldRequiredValidationCheck(helpMessage.lastName, 'lastName', 'Please enter your last name.'),
        getMaxLengthValidationCheck(helpMessage.lastName, 'lastName', VOLUNTEER_NAME_MAX_LENGTH),
        {
            name: 'valid email required',
            condition: (helpMessage: HelpMessage) => {
                return isValidEmail(helpMessage.email);
            },
            error: new ValidationError('Please enter a valid email address.', ['email'])
        },
        getFieldRequiredValidationCheck(helpMessage.subject, 'subject', 'Please enter a subject.'),
        getMaxLengthValidationCheck(helpMessage.subject, 'subject', CONTACT_US_SUBJECT_MAX_LENGTH),
        getFieldRequiredValidationCheck(helpMessage.message, 'message', 'Please enter a message.'),
        getMaxLengthValidationCheck(helpMessage.message, 'message', CONTACT_US_MESSAGE_MAX_LENGTH)
    ];

export default function validateHelpMessage(helpMessage: HelpMessage) {
    return getValidationErrors(helpMessageChecks, helpMessage);
}