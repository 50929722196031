export enum EmailCategories {
    GeneralNews = 'General News',
    NewRecruitmentPosts = 'New Recruitment Posts',
    UrgentNeeds = 'Urgent Needs'
}

export interface IEmailCategory {
    id: number;
    category: EmailCategories;
}

export class EmailCategory implements IEmailCategory {
    id: number;
    category: EmailCategories;

    constructor(emailCategory: IEmailCategory) {
        this.id = emailCategory.id;
        this.category = emailCategory.category;
    }
}