import { makeStyles, Theme, createStyles, TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import React, { Fragment } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { TableHeader } from "../../../stores/models/TableHeader";
import { IdentifiableObject, ObjectContainingIdentifier } from "../../../stores/models/OptionCollection";
import { Option } from "../../../stores/models/Option";
import TablePaginationWrapper from "../../AccountSettings/Pages/Organization/TablePaginationWrapper";
import CheckboxTableCell from "./CheckboxTableCell";
import TableHeaderCell from "./TableHeaderCell";
import { TableController } from "../../../stores/models/TableController";
import useLoadTableData from "../Hooks/LoadTableData";
import SelectAllTableRow from "./SelectAllTableRow";
import { CheckboxState } from "../../../shared/general/entities/CheckboxState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            whiteSpace: 'normal',
            wordBreak: 'normal'
        },
        header: {
            '& th': {
                backgroundColor: theme.palette.primaryBackground.main,
                fontWeight: 'bold',
                borderBottom: 'none'
            },
            whiteSpace: 'nowrap',
        },
        noResultsCaption: {
            width: 'auto',
            background: 'whitesmoke',
            color: 'black !important',
        },
        cursor: {
            cursor: 'pointer'
        },
    }),
);

interface RecordTableProps<
    IdentityKey extends string,
    Record extends IdentifiableObject<IdentityKey>,
    StubRecord extends IdentifiableObject<IdentityKey> = Record,
    MergedRecord extends object = Record
    > {
    controller: TableController<IdentityKey, Record, StubRecord, MergedRecord>;
    tableHeaderCells: TableHeader<Record>[];
    displayFunctions: ((record: Option<Record>, rowIndex: number, cellIndex: number) => React.ReactNode)[];
    tableRowClassName?: (record: Option<Record>) => string | undefined;
    onRowClicked: (record: Option<Record>, rowIndex: number) => void;
}

const RecordTable = observer(<
    IdentityKey extends string,
    MinimallyIdentifiableObject extends IdentifiableObject<IdentityKey> = ObjectContainingIdentifier<IdentityKey>,
    StubRecord extends IdentifiableObject<IdentityKey> = MinimallyIdentifiableObject,
    MergedRecord extends object = MinimallyIdentifiableObject
>(props: RecordTableProps<IdentityKey, MinimallyIdentifiableObject, StubRecord, MergedRecord>) => {

    const classes = useStyles();
    const numSelectedRecords = props.controller.records.selectedOptions.length;

    useLoadTableData(props.controller);

    /********* Helper methods *********/

    const getHeaderCheckboxState = () => {
        let allDisplayedRecordsSelected = true;
        let allDisplayedRecordsNotSelected = true;
        for (let i = 0; i < props.controller.recordsToDisplay.length; i++) {
            const record = props.controller.recordsToDisplay[i];
            if (!record.selected) {
                allDisplayedRecordsSelected = false;
            } else {
                allDisplayedRecordsNotSelected = false;
            }
            if (!allDisplayedRecordsNotSelected && !allDisplayedRecordsSelected) {
                return CheckboxState.Indeterminate;
            }
        }
        return allDisplayedRecordsNotSelected ? CheckboxState.NotChecked : CheckboxState.Checked;
    }

    const headerCheckboxState = getHeaderCheckboxState();

    /********* Event handlers *********/

    const handleRequestSort = (event: React.MouseEvent<HTMLSpanElement>, selectedHeadCell: TableHeader<MinimallyIdentifiableObject>) => {
        if (typeof selectedHeadCell.sortValue === 'string') {
            props.controller.tableState.sortingState.updateSortConfiguration(
                selectedHeadCell.sortValue as Extract<keyof MinimallyIdentifiableObject, string>,
                selectedHeadCell.direction
            );
        }
    };

    const onHeaderCheckboxClicked = () => {
        const indeterminateState = headerCheckboxState === CheckboxState.Indeterminate;
        props.controller.recordsToDisplay.forEach(option => {
            if ((indeterminateState && option.selected) || !indeterminateState) {
                option.toggleSelection();
            }
        });
    }

    const onCheckboxClicked = (event: React.MouseEvent<HTMLButtonElement | HTMLTableHeaderCellElement, MouseEvent>, option: Option<MinimallyIdentifiableObject>) => {
        event.stopPropagation();
        option.toggleSelection();
    }

    const onSelectAllRecordsClicked = () => {
        props.controller.tableState.setUnloadedSelected(true);
        props.controller.records.options.forEach(option => {
            if (!option.selected) {
                option.toggleSelection();
            }
        });
    }

    const displaySelectAllRow = (headerCheckboxState === CheckboxState.Checked)
        && (props.controller.tableState.total !== undefined)
        && (props.controller.numSelectedIncludingUnloaded < props.controller.tableState.total);

    /********* Render *********/

    return (
        <Fragment>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    className={classes.table}
                    stickyHeader
                >
                    <TableHead className={classes.header}>
                        <TableRow key="shift-table-header-row">
                            {props.tableHeaderCells.map((headCell) => (
                                headCell.checkbox
                                    ? <CheckboxTableCell
                                        onCheckboxClicked={onHeaderCheckboxClicked}
                                        CheckboxProps={{
                                            checked: headerCheckboxState === CheckboxState.Checked,
                                            indeterminate: headerCheckboxState === CheckboxState.Indeterminate
                                        }}
                                    />
                                    : <TableHeaderCell
                                        tableHeader={headCell}
                                        onHeaderClicked={handleRequestSort}
                                    />
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    {props.controller.records.options.length === 0
                        ? <caption className={classes.noResultsCaption} style={{ textAlign: 'center' }}>
                            No results
                        </caption>
                        : <TableBody>
                            {displaySelectAllRow &&
                                <SelectAllTableRow
                                    TableCellProps={{ colSpan: props.tableHeaderCells.length }}
                                    rowsOnPage={props.controller.recordsToDisplay.length}
                                    totalRows={props.controller.tableState.total || 0}
                                    onSelectAllClicked={onSelectAllRecordsClicked}
                                />
                            }
                            {props.controller.recordsToDisplay.map((record, rowIndex) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={`row-${rowIndex}`}
                                        onClick={() => props.onRowClicked(record as Option<MinimallyIdentifiableObject>, rowIndex)}
                                        className={clsx(
                                            classes.cursor,
                                            props.tableRowClassName
                                                ? props.tableRowClassName(record as Option<MinimallyIdentifiableObject>)
                                                : undefined
                                        )}
                                    >
                                        {props.displayFunctions.map((displayFunction, cellIndex) => {
                                            if (cellIndex < props.tableHeaderCells.length && props.tableHeaderCells[cellIndex].checkbox) {
                                                return (
                                                    <CheckboxTableCell
                                                        key={`${rowIndex}-${cellIndex}`}
                                                        CheckboxProps={{
                                                            checked: (record as Option<MinimallyIdentifiableObject>).selected
                                                        }}
                                                        onCheckboxClicked={(event) => onCheckboxClicked(event, record as Option<MinimallyIdentifiableObject>)}
                                                    />
                                                );
                                            } else {
                                                return displayFunction(record as Option<MinimallyIdentifiableObject>, rowIndex, cellIndex);
                                            }
                                        })
                                        }
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePaginationWrapper
                state={props.controller.tableState.paginationState}
                total={props.controller.tableState.total}
                loadResults={() => { return; }}
                skipInitialLoad
            />
        </Fragment>
    );
});

export default RecordTable;