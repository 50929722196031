export enum Environments {
    Development = 'development',
    Demo = 'demo',
    Production = 'production'
}

const PRODUCTION_HOST = 'voltagevolunteering';

export const getEnvironment = () => {
    if (process.env.NODE_ENV === 'production') {
        if (window.location.hostname.includes(PRODUCTION_HOST)) {
            return Environments.Production;
        } else {
            return Environments.Demo;
        }
    } else {
        return Environments.Development;
    }
}