import { differenceInCalendarDays } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import { validateShiftDetails } from "../../logic/ValidationChecks/ShiftDetailsValidation";
import { Fields } from "./Fields";
import { RepetitionEndType } from "./RepetitionEnding";
import { IRepetitionPattern } from "./RepetitionPattern";
import { IServerShift, isServerShift, ServerShift } from "./server/ServerShift";
import { IShift, Shift } from "./Shift";
import { IShiftIdentification, ShiftIdentification } from "./ShiftIdentification";
import { VolunteerShiftRegistration } from "./VolunteerShiftRegistration";

export type ShiftDetailsIdentificationData = IShiftIdentification & { opportunityId: number };

export interface IServerShiftDetails {
    opportunityId: number;
    organizationId: number;
    position: string;
    shift: IServerShift;
    parentShift?: IServerShift;
    oldParentShift?: IServerShift;
}

interface IShiftDetailsFields {
    opportunityId: number;
}

export interface IShiftDetails extends IShiftDetailsFields {
    position: string;
    shift: IShift;
    organizationId?: number;
    parentShift?: IShift;
    oldParentShift?: IShift;
}

export class ShiftDetails extends Fields<IShiftDetailsFields, ShiftDetails> implements IShiftDetails {

    @observable opportunityId: number = -1;
    @observable organizationId?: number | undefined;
    @observable position: string = '';
    @observable shift: Shift = new Shift();
    @observable parentShift: Shift | undefined;
    @observable oldParentShift: Shift | undefined;

    constructor(shiftDetails?: IShiftDetails | IServerShiftDetails) {
        super();

        makeObservable(this);

        if (shiftDetails) {
            this.opportunityId = shiftDetails.opportunityId;
            this.position = shiftDetails.position;
            this.organizationId = shiftDetails.organizationId;
            if (isServerShift(shiftDetails.shift)) {
                shiftDetails = shiftDetails as IServerShiftDetails;
                this.shift = new ServerShift(shiftDetails.shift).deserialize();
                this.parentShift = shiftDetails.parentShift ? new ServerShift(shiftDetails.parentShift).deserialize() : undefined;
                this.oldParentShift = shiftDetails.oldParentShift ? new ServerShift(shiftDetails.oldParentShift).deserialize() : undefined;
            } else {
                shiftDetails = shiftDetails as IShiftDetails;
                this.shift = new Shift(shiftDetails.shift);
                this.parentShift = shiftDetails.parentShift ? new Shift(shiftDetails.parentShift) : undefined;
                this.oldParentShift = shiftDetails.oldParentShift ? new Shift(shiftDetails.oldParentShift) : undefined;
            }
        }
    }

    @action setShift(shift: Shift) {
        this.shift = shift;
    }

    @action setParentShift(parentShift: Shift) {
        this.parentShift = parentShift;
    }

    @action setOldParentShift(oldParentShift?: Shift) {
        this.oldParentShift = oldParentShift;
    }

    @action setOpportunityId(opportunityId: number) {
        this.opportunityId = opportunityId;
    }

    public getInstanceShiftDetailsFromParentShiftDetails(defaultDaysFromStartDate: number) {
        if (!this.shift.isShiftSeries) return;

        const shiftDetails = new ShiftDetails(this);
        shiftDetails.setParentShift(this.shift);

        const shiftIdentification = new ShiftIdentification({ parentId: shiftDetails.parentShift?.id, defaultDaysFromStartDate: defaultDaysFromStartDate });
        const shiftInstance = this.shift.getInstanceByIdentificationData(shiftIdentification);
        if (!shiftInstance) throw new Error('Invalid default days from start date.');
        shiftDetails.setShift(shiftInstance);
        return shiftDetails;
    }

    @computed get identificationData(): ShiftDetailsIdentificationData {
        return {
            opportunityId: this.opportunityId,
            ...this.shift.identificationData
        }
    }

    @computed private get oldParentMaximumOffset() {
        if (!this.oldParentShift || !this.oldParentShift.repetitionPattern.startTimestamp || !this.oldParentShift.repetitionPattern.ending.endDate) return;
        return differenceInCalendarDays(this.oldParentShift.repetitionPattern.ending.endDate, this.oldParentShift.repetitionPattern.startTimestamp);
    }

    @computed get modifiedInstancesAfterMaxOffset() {
        if (!this.oldParentShift || this.oldParentMaximumOffset === undefined) return [];
        return this.oldParentShift.modifiedInstances.filter(instance =>
            (instance.identificationData.defaultDaysFromStartDate !== undefined) &&
            (instance.identificationData.defaultDaysFromStartDate > this.oldParentMaximumOffset!));
    }

    @computed get topLevelShift() {
        return this.parentShift ? this.parentShift : this.shift;
    }

    @computed get volunteerRegistrations() {
        let volunteerRegistrations = [] as VolunteerShiftRegistration[];
        this.topLevelShift.shiftRegistrations?.forEach((shiftRegistration) => {
            if (Shift.identificationDataMatches(shiftRegistration.shiftIdentification, this.shift.identificationData)) {
                volunteerRegistrations.push(...shiftRegistration.volunteerRegistrations);
            }
        });
        return volunteerRegistrations;
    }

    @computed get thisAndFollowingShifts() {
        if (this.parentShift && this.parentShiftRepetitionStartingFromCurrentShift) {
            switch (this.parentShift.repetitionPattern.ending.endType) {
                case RepetitionEndType.AfterOccurrences:
                    if (!this.shift.repetitionPattern.startTimestamp || this.shift.identificationData.defaultDaysFromStartDate === undefined) break;
                    const defaultDaysFromStartDate = this.shift.identificationData.defaultDaysFromStartDate;
                    return new Shift({
                        ...this.parentShiftRepetitionStartingFromCurrentShift,
                        repetitionPattern: {
                            ...this.parentShiftRepetitionStartingFromCurrentShift?.repetitionPattern,
                            ending: {
                                ...(this.parentShiftRepetitionStartingFromCurrentShift.repetitionPattern as IRepetitionPattern).ending,
                                numOccurrences: this.parentShift.repetitionPattern.getRemainingOccurrencesFromInstance(defaultDaysFromStartDate || 0)
                            }
                        }
                    });
                case RepetitionEndType.EndDate:
                case RepetitionEndType.Never:
                    return new Shift(this.parentShiftRepetitionStartingFromCurrentShift);
            }
        }
        return new Shift();
    }

    @computed private get parentShiftRepetitionStartingFromCurrentShift(): IShift | undefined {
        if (this.parentShift) {
            return {
                ...this.shift,
                modifiedInstances: this.parentShift.modifiedInstances, // Offsets will be adjusted via ShiftDetailsModification offsetMapping
                instanceData: undefined,
                repetitionPattern: {
                    ...this.parentShift.repetitionPattern,
                    startTimestamp: this.shift.repetitionPattern.startTimestamp,
                    endTimestamp: this.shift.repetitionPattern.endTimestamp
                }
            }
        }
    }

    @computed get validationErrors() {
        return validateShiftDetails(this);
    }

    @computed get validated() {
        return this.validationErrors.length === 0 && this.shift.validated;
    }

    serialize() {
        return {
            opportunityId: this.opportunityId,
            shift: this.shift.serialize(),
            oldParentShift: this.oldParentShift?.serialize(),
            oldParentMaximumOffset: this.oldParentMaximumOffset,
        }
    }

}
