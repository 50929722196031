import { makeStyles, Theme, createStyles, useMediaQuery, ToolbarProps } from "@material-ui/core";
import { Fragment } from "react";
import { observer } from "mobx-react";
import { Delete } from "mdi-material-ui";
import TableToolbar from "../../../../Shared/Table/TableToolbar";
import { Edit, MergeType } from "@material-ui/icons";
import REMButton from "../../../../../shared/modules/rem-conversion/components/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButton: {
            color: theme.palette.action.active
        },
        rightMargin: {
            marginRight: theme.spacing(1)
        },
    }),
);

interface VolunteersOverviewSelectionMenuProps extends ToolbarProps {
    numSelections: number;
    onEditClicked: () => void;
    onDeleteClicked: () => void;
    onMergeRecordsClicked: () => void;
    onClearSelections: () => void;
}

const VolunteersOverviewSelectionMenu = observer((props: VolunteersOverviewSelectionMenuProps) => {

    const classes = useStyles();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    /********* Render *********/

    return (
        <TableToolbar
            leftButtons={
                <Fragment>
                    {props.numSelections === 1
                        ? <REMButton
                            startIcon={<Edit />}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={props.onEditClicked}
                            className={classes.rightMargin}
                        >
                            Edit
                        </REMButton>
                        : props.numSelections === 2
                            ? <REMButton
                                startIcon={<MergeType />}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={props.onMergeRecordsClicked}
                                className={classes.rightMargin}
                            >
                                {smDown ? 'Merge' : 'Merge Records'}
                            </REMButton>
                            : null
                    }
                    {props.numSelections > 0 &&
                        <REMButton
                            startIcon={<Delete />}
                            variant="outlined"
                            size="large"
                            onClick={props.onDeleteClicked}
                            className={classes.actionButton}
                        >
                            Delete
                        </REMButton>
                    }
                </Fragment>
            }
            SelectionIndicatorProps={{
                numSelected: props.numSelections,
                onClearSelections: props.onClearSelections
            }}
            {...props}
        />
    );
});

export default VolunteersOverviewSelectionMenu;