import { makeStyles, Theme, createStyles, Container, Grid, Paper, Typography, TableCell, IconButton, Button, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { RootContext, Opportunity } from "../../../../../stores";
import { observer } from "mobx-react";
import { Pencil, Eye, Delete, Pin } from "mdi-material-ui";
import { DateFormatter } from "../../../../../logic/DateFormatter";
import { getOpportunityLink, getOpportunityEditorLink, getNewOpportunityLink } from "../../../../Navigation/Links/UrlConstructors";
import Editor from "../../../Editor";
import OpportunityDeletionConfirmation from "./OpportunityDeletionConfirmation";
import { DialogState } from "../../../../../stores/models/DialogState";
import TablePaginationWrapper from "../TablePaginationWrapper";
import FormLoadingSpinner from "../../../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import RecordTable from "../RecordTable";
import { Option } from "../../../../../stores/models/Option";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import { ISortableTableHeader, TableHeader } from "../../../../../stores/models/TableHeader";
import { TablePaginationState } from "../../../../../stores/models/TablePaginationState";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import { InternalLink } from "../../../../Navigation/Components";
import REMToolbar from "../../../../../shared/modules/rem-conversion/components/Toolbar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line'
        },
        title: {
            marginBottom: theme.spacing(1),
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        toolbar: {
            display: 'flex',
        },
        error: {
            color: theme.palette.error.main,
            opacity: 0.6
        },
        iconButton: {
            padding: 0
        },
        disabled: {
            color: theme.palette.text.disabled,
            '& .MuiTableCell-body': {
                color: 'inherit'
            }
        },
        button: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textWrap: 'nowrap'
        }
    }),
);

const headCells = ([
    { id: 'edit', alignment: 'center', disablePadding: true, label: 'Edit', sortable: false },
    { id: 'view', alignment: 'center', disablePadding: true, label: 'View', sortable: false },
    { id: 'role', alignment: 'left', disablePadding: false, label: 'Volunteer Role', sortable: true, sortValue: 'position' },
    { id: 'status', alignment: 'left', disablePadding: false, label: 'Status', sortable: true, sortValue: 'active' },
    { id: 'expiration', alignment: 'left', disablePadding: false, label: 'Expiration Date', sortable: true, sortValue: 'expirationTimestamp' },
    { id: 'delete', alignment: 'center', disablePadding: true, label: 'Delete', sortable: false }
] as ISortableTableHeader<Opportunity>[]).map(headCell => new TableHeader(headCell));

const OpportunitiesTable = observer(() => {

    const classes = useStyles();
    const navigate = useNavigateInternally();
    const theme = useTheme();
    const shortenButtonText = useMediaQuery(theme.breakpoints.down(350));
    const rootStore = useContext(RootContext);
    const organizationStore = rootStore.organizationStore;
    const userStore = rootStore.userStore;
    const opportunityStore = rootStore.opportunityStore;

    const [isLoading, setIsLoading] = useState(false);
    const [deletionConfirmationDialogState] = useState(new DialogState());
    const [oppToDelete, setOppToDelete] = useState<Opportunity>();
    const records = useMemo(() => {
        return new OptionCollection('id', organizationStore.organization.opportunities);
    }, [organizationStore.organization.opportunities]);
    const tablePaginationState = useMemo(() => {
        return new TablePaginationState();
    }, []);

    /********* Helper methods *********/

    const loadOpportunitiesOverview = async (limit: number, offset: number) => {
        const organizationId = userStore.user.organization?.id;
        if (organizationId) {
            setIsLoading(true);
            tablePaginationState.setReloadNeeded(false);
            await organizationStore.getOrganizationOpportunitiesOverview(organizationId, offset, limit);
            setIsLoading(false);
        }
    }

    const openOpportunityEditor = (opportunityId: number) => {
        navigate(getOpportunityEditorLink(opportunityId));
    }

    /********* Event handler *********/

    const confirmDeletion = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, opportunity: Opportunity) => {
        event.stopPropagation();
        setOppToDelete(opportunity);
        deletionConfirmationDialogState.setOpen(true);
    }

    const onDeletionConfirmed = async (opportunity: Opportunity) => {
        deletionConfirmationDialogState.setLoading(true);
        const response = await opportunityStore.deleteOpportunity(opportunity.id);
        if (response?.success) {
            records.removeOptionById(opportunity.id);
        }
        setOppToDelete(undefined);
        deletionConfirmationDialogState.setOpen(false);
        deletionConfirmationDialogState.setLoading(false);
        tablePaginationState.setReloadNeeded(true);
    }

    /********* Render *********/

    return (
        <Editor editsMade={false}>
            {(editorState) => (
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Container>
                                    <Typography variant="h4" className={classes.title}>
                                        Opportunities
                                    </Typography>
                                    {/* Toolbar Buttons */}
                                    <REMToolbar
                                        className={classes.toolbar}
                                        disableGutters
                                    >
                                        <Button
                                            startIcon={<Pin />}
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            onClick={() => navigate(getNewOpportunityLink())}
                                            className={classes.button}
                                        >
                                            {shortenButtonText ? 'New Post' : 'New Opportunity'}
                                        </Button>
                                    </REMToolbar>
                                    <RecordTable
                                        records={records}
                                        tableHeaderCells={headCells}
                                        orderBy={'role'}
                                        order={'asc'}
                                        tableRowClassName={(option) => (option.object.expired || !option.object.active) ? classes.disabled : undefined}
                                        displayFunctions={[
                                            (option: Option<Opportunity>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="center" key={`${rowIndex}-${cellIndex}`}>
                                                        <IconButton
                                                            className={classes.iconButton}
                                                            onClick={() => openOpportunityEditor(option.object.id)}
                                                        >
                                                            <Pencil color="action" />
                                                        </IconButton>
                                                    </TableCell>
                                                );
                                            },
                                            (option: Option<Opportunity>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="center" key={`${rowIndex}-${cellIndex}`}>
                                                        <InternalLink
                                                            target="_blank"
                                                            to={getOpportunityLink(organizationStore.organization.id, option.object.id)}
                                                        >
                                                            <IconButton
                                                                className={classes.iconButton}
                                                                onClick={(event) => event.stopPropagation()}
                                                            >
                                                                <Eye color="action" />
                                                            </IconButton>
                                                        </InternalLink>
                                                    </TableCell>
                                                );
                                            },
                                            (option: Option<Opportunity>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                                        {option.object.position}
                                                    </TableCell>
                                                );
                                            },
                                            (option: Option<Opportunity>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                                        {option.object.status}
                                                    </TableCell>
                                                );
                                            },
                                            (option: Option<Opportunity>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                                        {option.object.expirationTimestamp
                                                            ? option.object.expired
                                                                ? <span className={classes.error}>
                                                                    {DateFormatter.getDateInUTC(option.object.expirationTimestamp)}
                                                                </span>
                                                                : DateFormatter.getDateInUTC(option.object.expirationTimestamp)
                                                            : '-'
                                                        }
                                                    </TableCell>
                                                );
                                            },
                                            (option: Option<Opportunity>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="center" key={`${rowIndex}-${cellIndex}`}>
                                                        <IconButton
                                                            className={classes.iconButton}
                                                            onClick={(event) => confirmDeletion(event, option.object)}
                                                        >
                                                            <Delete color="action" />
                                                        </IconButton>
                                                    </TableCell>
                                                );
                                            },
                                        ]}
                                        onRowClicked={(option: Option<Opportunity>, rowIndex: number) => {
                                            openOpportunityEditor(option.object.id)
                                        }}
                                    />
                                    <TablePaginationWrapper
                                        state={tablePaginationState}
                                        total={organizationStore.organization.totalOpportunities}
                                        loadResults={loadOpportunitiesOverview}
                                    />
                                </Container>
                            </Paper>
                        </Grid>
                    </Grid>
                    {oppToDelete
                        ? <OpportunityDeletionConfirmation
                            state={deletionConfirmationDialogState}
                            opportunity={oppToDelete}
                            onConfirm={onDeletionConfirmed}
                        />
                        : null
                    }
                    {isLoading ? <FormLoadingSpinner /> : null}
                </Container>
            )}
        </Editor>
    );
});

export default OpportunitiesTable;