import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
import { IServiceEntryTag } from "../../../stores/models/ServiceEntryTag";
import { OptionCollection } from "../../../stores/models/OptionCollection";
import { INumericalIdOptionObject } from "../../../stores/models/Option";
import REMAutocomplete from "../../../shared/modules/rem-conversion/components/Autocomplete";
import REMChip from "../../../shared/modules/rem-conversion/components/Chip";

export interface IServiceEntryTagOption extends IServiceEntryTag {
    roleTag: boolean;
}

interface ServiceEntryTagAutocompleteProps {
    optionCollection: OptionCollection<"id", INumericalIdOptionObject, IServiceEntryTagOption>;
}

const ServiceEntryTagAutocomplete = observer((props: ServiceEntryTagAutocompleteProps) => {

    /********* State *********/

    const [roleOptionDeleted, setRoleOptionDeleted] = React.useState(false);

    /********* Effects *********/

    useEffect(() => {
        const roleOption = props.optionCollection.options.find(option => option.object.roleTag === true);
        if (roleOption) {
            if (roleOptionDeleted && roleOption.selected) {
                props.optionCollection.toggleSelection(roleOption.object);
            } else if (!roleOptionDeleted && !roleOption.selected) {
                props.optionCollection.toggleSelection(roleOption.object);
            }
        }
    }, [props.optionCollection.optionObjects]);

    /********* Event handlers *********/

    const getOptionByTag = (tag: string) => {
        return props.optionCollection.optionObjects.find(option => option.tag === tag);
    }

    const onSelectionsChanged = (
        event: React.ChangeEvent<{}>,
        value: string[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined
    ) => {
        switch (reason) {
            case "create-option":
                if (details) {
                    const existingTag = props.optionCollection.optionObjects.find(option => option.tag.trim() === details.option.trim());
                    if (!existingTag) {
                        props.optionCollection.addOrUpdateOption({ id: -1, tag: details.option, roleTag: false }, true);
                    } else {
                        if (existingTag.roleTag) {
                            props.optionCollection.toggleSelection(existingTag);
                            setRoleOptionDeleted(!roleOptionDeleted);
                        } else {
                            removeOption(existingTag);
                        }
                    }
                }
                break;
            case "select-option":
                if (details) {
                    const selectedOption = getOptionByTag(details.option);
                    if (selectedOption) {
                        props.optionCollection.toggleSelection(selectedOption);
                        if (selectedOption.roleTag) {
                            setRoleOptionDeleted(!roleOptionDeleted);
                        }
                    }
                }
                break;
            case "remove-option":
                if (details) {
                    const selectedOption = getOptionByTag(details.option);
                    if (selectedOption) {
                        removeOption(selectedOption);
                    }
                }
                break;
            case "clear":
                props.optionCollection.selectedOptions.forEach(selectedOption => {
                    removeOption(selectedOption);
                });
                break;
            default:
                break;
        }
    };

    const removeOption = (selectedOption: IServiceEntryTagOption) => {
        if (selectedOption.id < 0) {
            if (selectedOption.roleTag) {
                props.optionCollection.toggleSelection(selectedOption);
            } else {
                props.optionCollection.removeOption(selectedOption);
            }
        } else {
            props.optionCollection.toggleSelection(selectedOption);
        }

        if (selectedOption.roleTag) {
            setRoleOptionDeleted(true);
        }
    }

    /********* Render *********/

    return (
        <REMAutocomplete
            multiple
            id="tags-outlined"
            options={props.optionCollection.optionObjects.map((option) => option.tag)}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <REMChip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
            }
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type a tag and press enter"
                />
            )}
            value={props.optionCollection.selections.map(selection => selection.object.tag)}
            onChange={onSelectionsChanged}
        />
    );
});

export default ServiceEntryTagAutocomplete;