import { useState, useEffect } from 'react';

const getWidth = () => {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

export default function useCurrentWidth() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
        // timeoutId for debouncing
        let timeoutId: NodeJS.Timeout;
        function resizeListener() {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        return function cleanUp() {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return width;
}