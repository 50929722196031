import { observable, makeAutoObservable } from "mobx";

export interface IServiceEntryTag {
    id: number;
    tag: string;
}

export class ServiceEntryTag implements IServiceEntryTag {
    @observable id: number;
    @observable tag: string;

    constructor(idOrTag: number | IServiceEntryTag, tag?: string) {
        makeAutoObservable(this);

        if (typeof idOrTag === 'number') {
            this.id = idOrTag;
            this.tag = tag || '';
        } else {
            this.id = idOrTag.id;
            this.tag = idOrTag.tag;
        }
    }
}