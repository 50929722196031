import { ScaleBalance, TelevisionClassic, CommentTextMultipleOutline, Handshake, ChartBar, BookOpenPageVariant, FireTruck, PiggyBank, Food, Ambulance, Translate, Forklift, DramaMasks, Sprout, Microscope, HandHeart, WeightLifter, CellphoneLink, Brush } from "mdi-material-ui";
import { IconName } from "../stores/models/Icon";

const SkillCategoryIconDictionary: Record<string, IconName> = {
    'Advertising and Media': TelevisionClassic,
    'Business and Nonprofit Development': Handshake,
    'Communication': CommentTextMultipleOutline,
    'Data': ChartBar,
    'Education': BookOpenPageVariant,
    'Emergencies': FireTruck,
    'Finance': PiggyBank,
    'Food': Food,
    'Health and Medicine': Ambulance,
    'Humanities': ScaleBalance,
    'Languages': Translate,
    'Mechanical and Manual Labor': Forklift,
    'Performing Arts': DramaMasks,
    'Plants and Animals': Sprout,
    'Science and Engineering': Microscope,
    'Social Services': HandHeart,
    'Sports and Exercise': WeightLifter,
    'Technology': CellphoneLink,
    'Visual Arts': Brush
};

export default SkillCategoryIconDictionary;