import { makeStyles, Theme, createStyles, Typography, TypographyProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { Fragment } from "react";
import { StylizedInternalLink } from "../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'right'
        },
        selections: {
            fontWeight: 'bold',
            color: theme.palette.action.active
        },
        link: {
            color: theme.palette.primary.main
        }
    }),
);

export interface SelectionIndicatorProps extends TypographyProps {
    numSelected: number;
    onClearSelections: () => void;
}

const SelectionIndicator = observer((props: SelectionIndicatorProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <Fragment>
            <div className={classes.root}>
                <Typography className={classes.selections} variant="body2" {...props}>
                    {props.numSelected} rows selected
                </Typography>
                <StylizedInternalLink
                    onClick={props.onClearSelections}
                    children={<Typography className={classes.link} variant="body2">Clear</Typography>}
                />
            </div>
        </Fragment>
    );
});

export default SelectionIndicator;