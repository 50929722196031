import Button from '@material-ui/core/Button';
import ButtonGroup, { ButtonGroupProps } from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { OptionCollection } from '../../../stores/models/OptionCollection';
import { Option, IOptionObject } from '../../../stores/models/Option';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        textWrap: 'nowrap'
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.main,
        }
    }
}));

interface ButtonSelectOption extends IOptionObject {
    primaryText: string;
}

interface ButtonSelectProps {
    options: OptionCollection<'value', IOptionObject, ButtonSelectOption>;
    ButtonGroupProps?: ButtonGroupProps;
    className?: string;
}

const ButtonSelect = observer((props: ButtonSelectProps) => {

    // Event handlers
    const handleChange = (selectedOption: Option<ButtonSelectOption>) => () => {
        props.options.options.forEach(option => {
            const selected = option.object.value === selectedOption.object.value;
            if (selected !== option.selected) {
                option.toggleSelection();
            }
        });
    };

    // React hooks
    const classes = useStyles();

    return (
        <ButtonGroup
            className={clsx(classes.root, props.className)}
            {...props.ButtonGroupProps}
        // aria-label="vertical outlined primary button group"
        >
            {props.options.options.map((option, index) => {
                return (
                    <Button
                        key={index}
                        onClick={handleChange(option)}
                        variant={'outlined'}
                        className={option.selected ? classes.selected : undefined}
                        style={{ flexBasis: 100 / props.options.options.length + '%' }}
                    >
                        {option.object.primaryText}
                    </Button>
                )
            })}
        </ButtonGroup>
    );
});

export default ButtonSelect;
