import { makeStyles, Theme, createStyles, TableCell, Checkbox, TableCellProps, CheckboxProps } from "@material-ui/core";
import React from "react";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            padding: 0
        },
    }),
);

interface CheckboxTableCellProps extends TableCellProps {
    onCheckboxClicked: (event: React.MouseEvent<HTMLButtonElement | HTMLTableHeaderCellElement, MouseEvent>) => void;
    CheckboxProps?: CheckboxProps;
}

const CheckboxTableCell = observer((props: CheckboxTableCellProps) => {

    const classes = useStyles();

    /********* Event handlers *********/

    const onCheckboxClicked = (event: React.MouseEvent<HTMLButtonElement | HTMLTableHeaderCellElement, MouseEvent>) => {
        event.stopPropagation();
        props.onCheckboxClicked(event);
    }

    /********* Render *********/

    return (
        <TableCell
            align="center"
            onClick={onCheckboxClicked}
        >
            <Checkbox
                className={classes.iconButton}
                color='primary'
                onClick={onCheckboxClicked}
                {...props.CheckboxProps}
            />
        </TableCell>
    );
});

export default CheckboxTableCell;