import { flow, makeObservable, override } from 'mobx';
import { RootStore } from './RootStore';
import { Organization } from './models';
import { DashboardData, IDashboardData } from './models/DashboardData';
import { DataStore } from './DataStore';
import { IServerOrganization, ServerOrganization } from './models/server/ServerOrganization';
import { IRequestConfig } from './models/RequestQueue';
import { IPortalForm } from '../components/AccountSettings/Pages/Organization/CustomPortal';
import { IServerReportData, ReportData } from './models/ReportData';
import { DateFormatter } from '../logic/DateFormatter';

enum OrganizationRequestType {
    GetDashboardData = 'get-dashboard-data',
    GetInformation = 'get-org-information',
    GetOpportunities = 'get-org-opportunities',
    GetOpportunitiesOverview = 'get-org-opportunities-overview',
    GetReportData = 'get-report-data',
    UpdateInformation = 'update-org-information',
    FindVolunteersWithEmail = 'find-volunteers-with-email',
    PatchOrganization = 'patch-organization',
    PatchPortal = 'patch-portal'
}

/*** Maintains the data for a single organization ***/

export class OrganizationStore extends DataStore<OrganizationRequestType> {

    organization = new Organization(-1);

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    /*************** API Methods ***************/

    /*  ------------ Request URLs ------------  */

    @override get requestURLs() {
        const opportunitiesAPI = '/api/opportunities';
        const organizationsAPI = '/api/organizations'
        return {
            [OrganizationRequestType.GetInformation]: (data: { idOrSlug: number | string }) => `${organizationsAPI}/organization/${data.idOrSlug}`,
            [OrganizationRequestType.GetOpportunities]: (data: { id: number }) => `${opportunitiesAPI}/organization/${data.id}`,
            [OrganizationRequestType.GetOpportunitiesOverview]: (data: { id: number }) => `${opportunitiesAPI}/overview/${data.id}`,
            [OrganizationRequestType.GetDashboardData]: `${organizationsAPI}/dashboard`,
            [OrganizationRequestType.GetReportData]: (data: { id: number }) => `${organizationsAPI}/${data.id}/reports`,
            [OrganizationRequestType.FindVolunteersWithEmail]: `${organizationsAPI}/find-volunteers-with-email`,
            [OrganizationRequestType.UpdateInformation]: `${organizationsAPI}/update`,
            [OrganizationRequestType.PatchOrganization]: (data: any, params: { organizationId: number }) => `${organizationsAPI}/${params.organizationId}`,
            [OrganizationRequestType.PatchPortal]: (data: any, params: { organizationId: number }) => `${organizationsAPI}/${params.organizationId}/portal`
        };
    }

    /*  ------------ GET Methods ------------  */

    @override get getRequests() {
        return [
            OrganizationRequestType.GetInformation,
            OrganizationRequestType.GetOpportunities,
            OrganizationRequestType.GetOpportunitiesOverview,
            OrganizationRequestType.GetDashboardData,
            OrganizationRequestType.FindVolunteersWithEmail,
            OrganizationRequestType.GetReportData
        ];
    }

    async getDashboardData(id: number) {
        const response = await this.makeRequest<IDashboardData>(OrganizationRequestType.GetDashboardData, { id });
        if (!response) return;
        return new DashboardData(response);
    };

    async loadOrgInfo(idOrSlug: number | string) {
        const response = await this.requestOrgInfo(idOrSlug);
        if (!response) return;
        this.organization.onDataLoaded(response);
    };

    async getOrgInfo(idOrSlug: number | string) {
        const response = await this.requestOrgInfo(idOrSlug);
        if (!response || !('organization' in response)) return;
        return new ServerOrganization(response.organization).deserialize();
    };

    isPortalSlugUnique = flow(function* (this: OrganizationStore, slug: string) {
        return yield this.getOrgInfo(slug).then((orgUsingSlug) => {
            const currentOrg = this.rootStore.userStore.user.organization;
            if (!orgUsingSlug) return true;
            return orgUsingSlug.id === currentOrg?.id;
        })
    });

    async requestOrgInfo(idOrSlug: number | string) {
        return await this.makeRequest<{ organization: IServerOrganization }>(
            OrganizationRequestType.GetInformation,
            { idOrSlug }
        );
    }

    async getOpportunitiesForOrganization(id: number, limit: number, offset: number) {
        const response = await this.makeRequest<{ organization: IServerOrganization }>(
            OrganizationRequestType.GetOpportunities,
            { id, limit, offset }
        );
        if (!response) return;
        this.organization.onDataLoaded(response);
    };

    async getOrganizationOpportunitiesOverview(id: number, offset: number, limit: number) {
        const response = await this.makeRequest<{ organization: IServerOrganization }>(
            OrganizationRequestType.GetOpportunitiesOverview,
            { id, limit, offset }
        );
        if (!response) return;
        this.organization.onDataLoaded(response);
    };

    findVolunteersWithEmail = flow(function* (this: OrganizationStore, email: string) {
        const orgId = this.rootStore.userStore.user.organization?.id;
        if (orgId) {
            return yield this.makeRequest<boolean>(OrganizationRequestType.FindVolunteersWithEmail, { id: orgId, email: email.trim() });
        }
    });

    async getReportData(id: number, startDate: Date, endDate: Date) {
        const start = DateFormatter.getDateStringWithoutTimestamp(startDate);
        const end = DateFormatter.getDateStringWithoutTimestamp(endDate);
        const response = await this.makeRequest<IServerReportData>(OrganizationRequestType.GetReportData, { id, startDate: start, endDate: end });
        if (!response) return;
        return new ReportData(response);
    };

    /*  ------------ PUT Methods ------------  */

    @override get putRequests() {
        return [OrganizationRequestType.UpdateInformation];
    }

    async updateInformation(organization: Organization) {
        const serializedOrg = organization.serialize();
        const userOrg = this.rootStore.userStore.user.organization;
        const response = await this.makeRequest<{ organization: IServerOrganization }>(
            OrganizationRequestType.UpdateInformation,
            { id: organization.id, organization: serializedOrg }
        );
        if (!response) return;
        userOrg?.onDataLoaded(response);
    };


    /*  ------------ PATCH Methods ------------  */

    @override get patchRequests() {
        return [OrganizationRequestType.PatchPortal, OrganizationRequestType.PatchOrganization];
    }

    async patchOrganization(organizationPatch: Pick<Partial<Organization>, 'portalMode'>) {//organization: Partial<Organization>) {
        // const serializedOrg = organization.serialize();
        const serializedData = organizationPatch;
        const userOrg = this.rootStore.userStore.user.organization;
        if (!userOrg) return;
        const response = await this.makeRequest<{ body: IServerOrganization }>(
            OrganizationRequestType.PatchOrganization,
            serializedData,
            { organizationId: userOrg.id }
        );
        if (!response) return;
        userOrg?.onDataLoaded({ organization: response.body });
    };

    async patchPortal(organization: Organization, portalData: Partial<IPortalForm>) {
        const formData = new FormData();
        (Object.keys(portalData) as (keyof IPortalForm)[]).forEach(key => {
            if (key !== 'logo' && portalData[key]) {
                formData.set(key, portalData[key]!);
            }
        })
        // Add the logo last
        if (portalData.logo) {
            formData.set('file', portalData.logo);
        }

        const response = await this.makeRequest<{ organization: IServerOrganization }>(
            OrganizationRequestType.PatchPortal,
            formData,
            {
                organizationId: organization.id
            }
        );
        if (!response) return;
        organization.onDataLoaded(response);
    };

    /*************** Validation Checks ***************/

    verifyRequestForOrganization(orgId: number, config: IRequestConfig) {
        this.verifyValidOrganizationId(orgId);
        const isGetRequest = (config.method === 'get');
        if (!isGetRequest && !this.isAuthorizedToUpdate(orgId)) {
            throw new Error('User is not authorized to update this organization.');
        }
    }

    verifyValidOrganizationId(orgId: number) {
        if (orgId < 1) {
            throw new Error('Invalid organization ID.');
        }
    }

    isAuthorizedToUpdate(organizationId: number) {
        const userStore = this.rootStore.userStore;
        return userStore.isAuthenticatedOrganization(organizationId);
    }
}


