import { observable, flow, computed, action, makeObservable } from 'mobx';
import { RootStore } from './RootStore';
import { SkillCategory, RequestQueue } from './models';
import { OptionCategory } from './models/OptionCategoryCollection';
import { ISkillCategory, ISkill } from './models/Skill';
import { IOptionObject } from './models/Option';
import { ChipSelectionGridOption } from '../components/Shared/ChipSelectionGrid';
import { IRequestConfig } from './models/RequestQueue';

export type ISkillOption = IOptionObject & ISkill & ChipSelectionGridOption;

export class SkillCategoryStore {
    private rootStore = {} as RootStore;
    private requestQueue: RequestQueue;

    skillCategories = [] as SkillCategory[];
    state = "pending";

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            skillCategories: observable,
            state: observable,
            fetchSkillCategories: action,
            skillCategoryOptions: computed
        });

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
        this.fetchSkillCategories();
    }

    fetchSkillCategories = flow(function* (this: SkillCategoryStore) {
        this.skillCategories = [];
        this.state = "pending";
        try {
            const results = yield this.requestQueue.makeAPIRequest(this.generateRequestConfig());
            const { skillCategories } = results;
            this.skillCategories = skillCategories.map((category: ISkillCategory) => { return new SkillCategory(category); });
            this.state = "done";
        } catch (error) {
            this.state = "error";
        }
    });

    private generateRequestConfig(): IRequestConfig {
        return {
            method: 'get',
            url: '/api/skillcategories/all'
        };
    }

    get skillCategoryOptions() {
        const categories = this.skillCategories.map(skillCategory => {
            const options: ISkillOption[] = skillCategory.skills.map(skill => {
                return {
                    ...skill,
                    value: skill.skill,
                    text: skill.skill
                };
            });
            return new OptionCategory(skillCategory.category, options);
        })
        return categories;
    }
}

