import { makeStyles, Theme, createStyles, useMediaQuery } from "@material-ui/core";
import React, { Fragment, useMemo } from "react";
import { observer } from "mobx-react";
import { AccountMultiplePlus, AccountPlus, Email } from "mdi-material-ui";
import clsx from "clsx";
import SearchInput from "../../../../Search/FilterBar/SearchInput";
import TableToolbar from "../../../../Shared/Table/TableToolbar";
import VolunteersFilterPopper, { VolunteerFilterOptionCollections } from "./VolunteersFilterPopper";
import { DialogState } from "../../../../../stores/models/DialogState";
import VolunteersOverflowMenu from "./VolunteersOverflowMenu";
import REMButton from "../../../../../shared/modules/rem-conversion/components/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButton: {
            color: theme.palette.action.active
        },
        rightMargin: {
            marginRight: theme.spacing(1)
        },
        leftMargin: {
            marginLeft: theme.spacing(1)
        },
        searchInput: {
            maxWidth: '300px',
            marginRight: theme.spacing(1)
        },
        noWrap: {
            textWrap: 'nowrap'
        }
    }),
);

interface VolunteersOverviewMainMenuProps extends VolunteerFilterOptionCollections {
    searchQuery?: string;
    onSearchInputChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onEmailsClicked: () => void;
    onImportClicked: () => void;
    onNewVolunteerClicked: () => void;
}

const VolunteersOverviewMainMenu = observer((props: VolunteersOverviewMainMenuProps) => {

    const classes = useStyles();

    const state = useMemo(() => new DialogState(), []);
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    /********* Render *********/

    return (
        <TableToolbar
            leftButtons={
                <Fragment>
                    <SearchInput
                        searchText={props.searchQuery || ''}
                        placeholder={"Search"}
                        outlined
                        onChange={props.onSearchInputChanged}
                        className={clsx(classes.actionButton, classes.searchInput)}
                    />
                    <VolunteersFilterPopper
                        state={state}
                        roleOptions={props.roleOptions}
                        statusOptions={props.statusOptions}
                        className={classes.actionButton}
                    />
                </Fragment>
            }
            rightButtons={
                mdDown
                    ? <Fragment>
                        <VolunteersOverflowMenu
                            onEmailsClicked={props.onEmailsClicked}
                            onImportClicked={props.onImportClicked}
                        />
                        <REMButton
                            startIcon={<AccountPlus />}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={props.onNewVolunteerClicked}
                        >
                            Add
                        </REMButton>
                    </Fragment>
                    : <Fragment>
                        <REMButton
                            startIcon={<Email />}
                            variant="outlined"
                            size="large"
                            onClick={props.onEmailsClicked}
                            className={clsx(classes.actionButton, classes.rightMargin, classes.leftMargin)}
                        >
                            Emails
                        </REMButton>
                        <REMButton
                            startIcon={<AccountMultiplePlus />}
                            variant="outlined"
                            size="large"
                            onClick={props.onImportClicked}
                            className={clsx(classes.actionButton, classes.rightMargin)}
                        >
                            Import
                        </REMButton>
                        <REMButton
                            startIcon={<AccountPlus />}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={props.onNewVolunteerClicked}
                            className={classes.noWrap}
                        >
                            New Volunteer
                        </REMButton>
                    </Fragment>
            }
        />
    );
});

export default VolunteersOverviewMainMenu;