import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { isValid } from "date-fns";
import { DateFormatter } from "../DateFormatter";

export function getValidationErrors<T>(checks: ValidationCheck<T>[] | ((valueToValidate: T) => ValidationCheck<T>[]), valueToValidate: T) {
    let errors = [] as ValidationError<T>[];
    let validationChecks;
    if (typeof checks === 'function') {
        validationChecks = checks(valueToValidate);
    } else {
        validationChecks = checks;
    }
    for (let i = 0; i < validationChecks.length; i++) {
        const check = validationChecks[i];
        if (!check.condition(valueToValidate)) {
            errors.push(check.error);
        }
    }
    return errors;
}

export function isValidDate(date: any) {
    return date && isValid(date);
}

export function isValidFutureDate(date: any) {
    return isValidDate(date) && date >= DateFormatter.getBeginningOfToday();
}

export function isValidFutureTimestamp(date: any) {
    return isValidDate(date) && date >= new Date();
}

function isValidBirthdate(date: any, required?: boolean, isCurrentUser?: boolean): string | true {
    if (date === null) {
        if (required) {
            return isCurrentUser ? 'Your date of birth is required.' : 'Date of birth is required.';
        } else {
            return true;
        }
    } else if (!isValidDate(date)) {
        return 'Invalid date of birth.';
    } else if (date < new Date(1900, 1)) {
        return isCurrentUser ? `You don't look that old!` : `Surely the volunteer isn't that old!`;
    } else if (date >= new Date()) {
        return `Support not currently available for time travelers.`;
    } else {
        return true;
    }
}

export function getBirthdateValidationCheck<ObjectToValidate, KeyOfObject extends keyof ObjectToValidate>(birthdate: any, key: KeyOfObject, required?: boolean, isCurrentUser?: boolean) {
    const result = isValidBirthdate(birthdate, required, isCurrentUser);
    return {
        name: 'birthdate valid',
        condition: (object: ObjectToValidate) => result === true,
        error: new ValidationError<ObjectToValidate>(`${result}`, [key])
    }
}

const emailRegex = /^([^@\s])+@([^@\.\s])+(\.[^@\.\s]+)+$/;

export function isValidEmail(email: string) {
    return emailRegex.test(email.trim());
}

const phoneRegex = /^[2-9](?!11)\d{9}$/;

export function isValidPhoneNumber(phone: string) {
    return phoneRegex.test(phone);
}

export function getMaxLengthValidationCheck<ObjectToValidate, KeyOfObject extends keyof ObjectToValidate>(
    property: string,
    key: KeyOfObject,
    maxLength: number) {
    return {
        name: `${key.toString()} max length`,
        condition: (object: ObjectToValidate) => property.length <= maxLength,
        error: new ValidationError<ObjectToValidate>(`${property.length}/${maxLength}`, [key])
    }
}

export function getFieldRequiredValidationCheck<ObjectToValidate, KeyOfObject extends keyof ObjectToValidate>(
    property: any,
    key: KeyOfObject,
    error: string
) {
    return {
        name: `${key.toString()} required`,
        condition: (object: ObjectToValidate) => {
            if (typeof property === 'string') {
                return property.trim().length > 0;
            } else {
                return property !== null && property !== undefined;
            }
        },
        error: new ValidationError<ObjectToValidate>(error, [key])
    }
}