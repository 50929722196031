import { useContext, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { RootContext } from "../../stores/";
import { observer } from "mobx-react";
import LoadingIndicator from "../Shared/LoadingIndicator";
import MenuSidebar, { SIDEBAR_DRAWER_WIDTH, SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT, SIDEBAR_ICON_ONLY_DRAWER_WIDTH } from "../Shared/MenuSidebar/MenuSidebar";
import useAccountSettingsMenuItems from './AccountSettingsMenuItemsHook';
import { UserState } from "../../stores/UserStore";
import AuthenticationForms from "../Authentication/AuthenticationForms";
import NavigateInternally from "../Navigation/Components/NavigateInternally";
import VolunteerAccountSettingsRoutes from "../Navigation/Routes/VolunteerAccountSettingsRoutes";
import OrganizationAccountSettingsRoutes from "../Navigation/Routes/OrganizationAccountSettingsRoutes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1,
            // overflowX: 'hidden'
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            position: 'relative', // Used to position loading spinner
            width: `calc(100% - ${SIDEBAR_DRAWER_WIDTH}px)`,
            transition: theme.transitions.create('margin-left'),
            marginLeft: SIDEBAR_DRAWER_WIDTH, // TODO: Once TypeScript supports it, consider using ResizeObserver instead
            [theme.breakpoints.only(SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT)]: {
                paddingLeft: SIDEBAR_ICON_ONLY_DRAWER_WIDTH,
                marginLeft: 0
            },
            boxSizing: 'border-box',
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0
            },
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            textAlign: 'left',
        },
    })
);

export const ACCOUNT_SETTINGS_CONTENT_ID = 'account-settings-content';

const AccountSettings = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const menuItems = useAccountSettingsMenuItems();

    /********* State *********/

    const [isLoading, setIsLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    /********* Effects *********/

    useEffect(() => {
        // Verify the user has been loaded
        if (isUserLoaded()) {
            setIsLoading(false);
        }
    }, [userStore.user, userStore.userState]);

    useEffect(() => {
        if (userStore.user.id > 0) {
            setLoggedIn(true);
        }
        if (loggedIn && userStore.user.id < 0) {
            setShouldRedirect(true);
        }
    }, [userStore.user.id]);

    /********* Helper constants *********/

    const userState = userStore.userState;
    const isUserLoaded = () => userState === UserState.Authenticated || userState === UserState.NotAuthenticated;
    const userLoading = userState === UserState.CheckingForAuthenticatedUser;

    if (!isUserLoaded() && !userLoading) {
        userStore.checkForAuthenticatedUser();
    }

    /********* Render *********/

    if (isLoading) {
        return <LoadingIndicator />;
    } else if (shouldRedirect) {
        return <NavigateInternally to="/search" replace />;
    } else if (userStore.user.id < 0) {
        return (
            <AuthenticationForms />
        )
    } else {
        return (
            <div id="account-settings" className={classes.root}>
                <MenuSidebar menuItems={menuItems} />
                <div className={classes.content}>
                    {userStore.user.isVolunteer
                        ? <VolunteerAccountSettingsRoutes />
                        : <OrganizationAccountSettingsRoutes />
                    }
                </div>
            </div >
        );
    }
});

export default AccountSettings;