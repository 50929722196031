import { observable, action, computed, makeObservable, makeAutoObservable } from "mobx";
import { Fields } from "./Fields";

interface IStepState<T extends Fields<Partial<T>, T>> {
    loading: boolean;
    validationRun: boolean;
    completed: boolean; // Indicates whether or not the step has been advanced past
    stepObject: T;
}

export class StepState<T extends Fields<Partial<T>, T>> implements IStepState<T> {
    @observable loading = false;
    @observable validationRun = false;
    @observable completed = false;
    @observable stepObject: T;
    @observable validationMethod?: () => boolean;

    constructor(stepObject: T) {
        makeAutoObservable(this);

        this.stepObject = stepObject;
    }

    @action setValidationMethod(validationMethod: () => boolean) {
        this.validationMethod = validationMethod;
    }

    @action setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action setValidationRun(validationRun: boolean) {
        this.validationRun = validationRun;
    }

    @action setCompleted(completed: boolean) {
        this.completed = completed;
    }
}

export type StepProps<T extends Fields<Partial<T>, T>> = { stepState: StepState<T> };

interface IStep<T extends Fields<Partial<T>, T>> {
    name: string;
    content: (props: StepProps<T>) => JSX.Element;
    state: StepState<T>;
    submitButtonEmbedded: boolean;
    admissable: boolean; // Indicates whether or not the step can be submitted
    disableSubmitUntilValid: boolean;
    hideBackButton: boolean;
}

export class Step<T extends Fields<Partial<T>, T>> implements IStep<T> {
    @observable name: string;
    @observable content: (props: StepProps<T>) => JSX.Element;
    @observable state: StepState<T>;
    @observable submitButtonEmbedded: boolean;
    @observable disableSubmitUntilValid: boolean;
    @observable hideBackButton: boolean;

    constructor(
        name: string,
        content: (props: StepProps<T>) => JSX.Element,
        stepObject: T,
        submitButtonEmbedded?: boolean,
        disableSubmitUntilValid?: boolean,
        hideBackButton?: boolean
    ) {
        makeAutoObservable(this);

        this.name = name;
        this.content = content;
        this.state = new StepState<T>(stepObject);
        this.submitButtonEmbedded = submitButtonEmbedded || false;
        this.disableSubmitUntilValid = disableSubmitUntilValid || false;
        this.hideBackButton = hideBackButton || false;
    }

    validated() {
        if (this.state.validationMethod) {
            return this.state.validationMethod();
        } else {
            return this.state.stepObject.validationErrors.length === 0;
        }
    }

    @computed get admissable() {
        return this.validated();
    }
}
