import { observable, action, makeObservable, computed } from "mobx";
import { DateFormatter } from "../../logic/DateFormatter";
import { Fields } from "./Fields";
import { ServerVolunteer } from "./server/ServerVolunteer";
import { ValidationError } from "./ValidationError";
import validateVolunteerAsCurrentUser from "../../logic/ValidationChecks/VolunteerValidation";

interface IVolunteerFields {
    firstName: string;
    lastName: string;
    birthdate: string | undefined;
}

export interface IVolunteer extends IVolunteerFields {
    id: number;
}

export const isVolunteer = (x: any): x is Volunteer => {
    if (typeof x === 'object') {
        return ('firstName' in x);
    } else {
        return false;
    }
}

export class Volunteer extends Fields<IVolunteerFields, Volunteer> implements IVolunteer {
    @observable id = -1;
    @observable firstName = "";
    @observable lastName = "";
    @observable birthdate: string | undefined = undefined;

    private validationChecks = validateVolunteerAsCurrentUser;

    constructor(volunteer?: IVolunteer) {
        super();

        makeObservable(this);

        if (volunteer) {
            this.id = volunteer.id;
            this.firstName = volunteer.firstName;
            this.lastName = volunteer.lastName;
            this.birthdate = volunteer.birthdate ? DateFormatter.getDateInUTC(volunteer.birthdate, true) : undefined;
        }
    }

    @action setFirstName(firstName: string) {
        this.firstName = firstName;
    }

    @action setLastName(lastName: string) {
        this.lastName = lastName;
    }

    @action setBirthdate(birthdate?: string) {
        this.birthdate = birthdate;
    }

    @action setValidationChecks(validationChecks: (volunteer: Volunteer) => ValidationError<Volunteer>[]) {
        this.validationChecks = validationChecks;
    }

    @computed get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    @computed get validationErrors() {
        return this.validationChecks(this);
    }

    serialize() {
        return new ServerVolunteer(this.trimmed);
    }
}