import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { OrganizationStubVolunteerLinking } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { StepProps } from "../../../../stores/models/Step";
import TermsOfServicePrompt from "../TermsOfServicePrompt";

const TermsOfServiceStep = observer((props: StepProps<OrganizationStubVolunteerLinking>) => {

    const linkData = props.stepState.stepObject;

    /***** Effects *****/

    useEffect(() => {
        props.stepState.setValidationMethod(validateAgreedToTerms);
    }, []);

    /***** Event handlers *****/

    const onAcceptanceChanged = (agreedToTerms: boolean) => {
        linkData.setAgreedToTerms(agreedToTerms);
    }

    /***** Helper methods *****/

    const validateAgreedToTerms = () => {
        return linkData.agreedToTerms;
    }

    /***** Render *****/

    return (
        <TermsOfServicePrompt
            agreedToTerms={linkData.agreedToTerms}
            onAcceptanceChanged={onAcceptanceChanged}
        />
    );
});

export default TermsOfServiceStep;