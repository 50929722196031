import { useEffect, useState } from 'react';
import { DialogState } from '../../../../stores/models/DialogState';
import { useQuery } from '../../../../components/Shared/Hooks/URLQuery';
import { useLocation } from 'react-router-dom';
import { useNavigateInternally } from '../../../../components/Navigation/Hooks';

interface QueryParamDialogTriggerProps {
    param: string;
    triggerValue: string | number;
    dialog: DialogState;
    delayInitialOpening?: boolean
}

export default function useQueryParamDialogTrigger(props: QueryParamDialogTriggerProps) {

    const location = useLocation();
    const navigate = useNavigateInternally();
    const query = useQuery();
    const paramValue = query.get(props.param);

    const [delayDialogOpening, setDelayDialogOpening] = useState(props.delayInitialOpening || false);

    useEffect(() => {
        onParameterValueChanged();
    }, [paramValue]);

    useEffect(() => {
        if (!props.dialog.open) {
            clearQueryParameter();
        }
    }, [props.dialog.open]);

    const onParameterValueChanged = () => {
        if (paramValue === props.triggerValue) {
            if (delayDialogOpening) {
                setTimeout(() => {
                    props.dialog.setOpen(true);
                }, 700);
            } else {
                props.dialog.setOpen(true);
            }
        }
        setDelayDialogOpening(false);
    }

    const clearQueryParameter = () => {
        // Remove the query parameter so that it can be 
        // set again to retrigger an effect if needed
        let newLink = location.pathname + location.search;
        const regex = new RegExp(`${props.param}=${paramValue}`);
        newLink = newLink.replace(regex, '');
        navigate(newLink, { replace: true });
    }
}