import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import { DialogState } from '../../../stores/models/DialogState';
import { AlertOutline } from 'mdi-material-ui';
import VolunteerHoursSourceIcon from './VolunteerHoursSourceIcon';
import ThemedDialogWithSpinner from '../../../components/Shared/Dialogs/ThemedDialogWithSpinner';
import { ServiceEntry } from '../data-access/entities/ServiceEntry';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inlineIcon: {
            '& *': {
                marginBottom: -6
            }
        },
        warning: {
            background: 'rgb(255, 244, 229)',
            color: 'rgb(102, 60, 0)'
        },
        warningIcon: {
            color: theme.palette.warning.main,
        }
    })
);

interface UnreviewedEditsDialogProps {
    state: DialogState;
    onOverride?: (override: boolean) => void;
}

const UnreviewedEditsDialog = observer((props: UnreviewedEditsDialogProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            title={"Unreviewed Edits"}
            primaryButtonProps={{ children: 'Stop and Review' }}
            onSubmit={() => props.state.setOpen(false)}
            DialogProps={{ maxWidth: 'sm', fullScreen: xsDown }}
            extraButtonProps={[{
                children: 'Continue',
                startIcon: <AlertOutline className={classes.warningIcon} />,
                variant: 'outlined',
                onClick: () => props.onOverride ? props.onOverride(true) : {},
                className: classes.warning
            }]}
            hideCancelButton
        >
            <Alert severity="warning">
                <AlertTitle>Proceed Cautiously</AlertTitle>
                <Typography>
                    At least one of the selected service entries has unreviewed volunteer edits. <b>By continuing, you will automatically accept all edits and deletions and won't be able to review the volunteer changes side-by-side with the last verified version of the service entry.</b>
                </Typography>
                <br />
                <Typography>
                    It is recommended that prior to making this update, you <b>Stop and Review</b> each selected service entry with volunteer changes. Service entries with edits to review can be identified by looking for the <span className={classes.inlineIcon}><VolunteerHoursSourceIcon serviceEntry={new ServiceEntry({ ...(new ServiceEntry()), hasVolunteerEdits: true, lastOfficialEntry: new ServiceEntry() })} /></span> icon in the Source column.
                </Typography>
            </Alert>
        </ThemedDialogWithSpinner>
    );
});

export default UnreviewedEditsDialog;