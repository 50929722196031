import { MonthlyRepetitionType, RepetitionPattern, IRepetitionPatternFields, RepetitionFrequency, DaysOfTheWeek } from '../RepetitionPattern';
import { RepetitionEndType, RepetitionEnding, DEFAULT_NUM_OCCURRENCES, IRepetitionEnding } from '../RepetitionEnding';
import { format, startOfDay } from 'date-fns';
import RepetitionPatternMapper from '../../../logic/RepetitionPatternLogic';

export const isServerRepetitionPattern = (x: any): x is IServerRepetitionPattern => {
    if (typeof x === 'object') {
        return (x as { monthlyRepetitionPattern: MonthlyRepetitionType }).monthlyRepetitionPattern === undefined;
    } else {
        return false;
    }
}

export interface IServerRepetitionPattern {
    startTimestamp: Date;
    endTimestamp: Date;
    frequency: string;
    interval: number;
    daysOfTheWeek: string[];
    endType: string;
    repetitionEndDaysFromStart?: number;
    occurrences?: number;
}

export class ServerRepetitionPattern implements IServerRepetitionPattern {
    startTimestamp: Date;
    endTimestamp: Date;
    frequency: string;
    interval: number;
    daysOfTheWeek: string[];
    endType: string;
    repetitionEndDaysFromStart?: number;
    occurrences?: number;

    constructor(clientOrServerObject: RepetitionPattern | IServerRepetitionPattern) {
        if (clientOrServerObject.startTimestamp && clientOrServerObject.endTimestamp) {
            this.interval = clientOrServerObject.interval;
            if (isServerRepetitionPattern(clientOrServerObject)) {
                this.startTimestamp = new Date(clientOrServerObject.startTimestamp);
                this.endTimestamp = new Date(clientOrServerObject.endTimestamp);
                this.endType = clientOrServerObject.endType;
                this.repetitionEndDaysFromStart = clientOrServerObject.repetitionEndDaysFromStart;
                this.occurrences = clientOrServerObject.occurrences;
                this.frequency = clientOrServerObject.frequency;
                this.daysOfTheWeek = clientOrServerObject.daysOfTheWeek;
            } else {
                this.startTimestamp = clientOrServerObject.startTimestamp!;
                this.endTimestamp = clientOrServerObject.endTimestamp!;
                const ending = clientOrServerObject.ending;
                this.endType = ending.endType;
                this.repetitionEndDaysFromStart = ending.endType === RepetitionEndType.EndDate && ending.endDateDaysFromMinimumEndDate !== undefined
                    ? ending.endDateDaysFromMinimumEndDate
                    : undefined;
                this.occurrences = ending.endType === RepetitionEndType.AfterOccurrences ? clientOrServerObject.ending.numOccurrences : undefined;
                this.frequency = clientOrServerObject.isCustomPattern ? clientOrServerObject.customFrequency : clientOrServerObject.frequency;
                this.daysOfTheWeek = clientOrServerObject.isWeeklyPattern ? clientOrServerObject.daysOfTheWeek : [];
            }
        } else {
            throw (new Error('Invalid start or end to repetition pattern.'));
        }
    }

    setStartTimestamp(timestamp: Date) {
        this.startTimestamp = new Date(timestamp);
    }

    setEndTimestamp(timestamp: Date) {
        this.endTimestamp = new Date(timestamp);
    }

    deserialize(): RepetitionPattern {
        const repetitionEndingData: IRepetitionEnding = {
            endType: RepetitionEndType[this.endType as RepetitionEndType],
            endDateDaysFromMinimumEndDate: this.repetitionEndDaysFromStart,
            numOccurrences: this.occurrences || DEFAULT_NUM_OCCURRENCES,
            minimumEndDate: startOfDay(this.startTimestamp)
        }
        const repetitionPatternData: IRepetitionPatternFields = {
            startDate: this.startTimestamp,
            startTime: this.startTimestamp,
            endDate: this.endTimestamp,
            endTime: this.endTimestamp,
            frequency: this.determineClientFrequency(),
            interval: this.interval,
            customFrequencyDescriptor: this.getCustomFrequencyDescriptor(),
            daysOfTheWeek: this.daysOfTheWeek as DaysOfTheWeek[],
            monthlyRepetitionPattern: this.isMonthlyRepetitionPattern() ? this.frequency as MonthlyRepetitionType : RepetitionFrequency.MonthlyOnDayOfWeek,
            ending: new RepetitionEnding(repetitionEndingData),
        }
        return new RepetitionPattern(repetitionPatternData);
    }

    private isMonthlyRepetitionPattern() {
        return this.frequency === RepetitionFrequency.MonthlyOnDate
            || this.frequency === RepetitionFrequency.MonthlyOnDayOfWeek;
    }

    private getCustomFrequencyDescriptor() {
        const frequency = this.frequency as RepetitionFrequency;
        if (this.interval === 1) {
            return RepetitionPatternMapper.getSingularFrequencyDescriptor(frequency);
        } else {
            return RepetitionPatternMapper.getPluralFrequencyDescriptor(frequency);
        }
    }

    private determineClientFrequency(): RepetitionFrequency {
        if (this.isCustomFrequency()) {
            return RepetitionFrequency.Custom;
        } else {
            return RepetitionFrequency[this.frequency as RepetitionFrequency];
        }
    }

    private isCustomFrequency() {
        return this.interval !== 1
            || (this.frequency === RepetitionFrequency.Weekly &&
                (this.daysOfTheWeek.length > 1 || !this.daysOfTheWeekContainsStartDay()));
    }

    private daysOfTheWeekContainsStartDay() {
        return this.daysOfTheWeek.indexOf(this.startDayOfWeek()) !== -1;
    }

    private startDayOfWeek() {
        return format(this.startTimestamp, "EEEE");
    }
}