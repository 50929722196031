import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { IRequestConfig, RequestQueue } from './models/RequestQueue';
import { ShiftRegistration } from '.';
import { IServerShiftDetails, ShiftDetails } from './models/ShiftDetails';
import { Shift } from './models/Shift';
import { IServerShift, ServerShift } from './models/server/ServerShift';
import { getSupplementalDataRequest, ISupplementalOrganizationData, SupplementalOrganizationDataRequest } from './models/SupplementalOrganizationData';
import { IStatus } from './models/Status';
import { ShiftIdentification } from './models/ShiftIdentification';
import { ShiftDetailsModification } from './models/ShiftDetailsModification';
import { APIError, IAPIError } from './models/APIError';

enum ShiftRequestType {
    SignUpForShift = 'post-shift-registration',
    UnregisterForShifts = 'unregister-for-shifts',
    RegisterVolunteersForShift = 'post-volunteer-registrations',
    GetAllShiftsForOrganization = 'get-all-for-org',
    GetOrganizationVolunteerShifts = 'get-org-vol-shifts',
    GetShiftDetails = 'get-shift-details',
    UpdateVolunteerRegistrationStatuses = 'update-volunteer-registration-statuses',
    UpsertShiftDetails = 'upsert-shift-details'
}

export class ShiftStore {
    rootStore = {} as RootStore;

    volunteerRegistrationStatuses: IStatus[] = [];

    private requestQueue: RequestQueue;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
    }

    get filteredRegistrationStatuses() {
        return this.volunteerRegistrationStatuses.filter(status => status.status !== 'Unregistered');
    }

    /*************** Sign up for shift ***************/

    async signUpForShift(shiftRegistration: ShiftRegistration) {
        try {
            let allRegistrationsValidated = true;
            shiftRegistration.volunteerRegistrations.forEach(volunteerRegistration => {
                volunteerRegistration.setAllFieldsDirty();
                if (!volunteerRegistration.validated) {
                    allRegistrationsValidated = false;
                }
            });
            if (allRegistrationsValidated) {
                const response = await this.makeRequest<IServerShift | { error: string }>(ShiftRequestType.SignUpForShift, shiftRegistration.serialize());
                if ('error' in response) {
                    return response;
                }
                return new ServerShift(response).deserialize();
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Unregister for shift ***************/

    async unregisterForShifts(shiftIds: ShiftIdentification[], volunteerIds: number[]) {
        try {
            if (this.rootStore.userStore.user.isVolunteer) {
                const response = await this.makeRequest<{ shifts: IServerShift[] }>(ShiftRequestType.UnregisterForShifts, { shiftIds: shiftIds, volunteerIds: volunteerIds });
                return { shifts: response.shifts.map(shift => new ServerShift(shift).deserialize()) };
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Register volunteers for shift ***************/

    async registerVolunteersForShift(volunteerIds: number[], shiftIdentificationData: ShiftIdentification) {
        try {
            if (this.rootStore.userStore.user.isOrganization) {
                const data = {
                    volunteerIds: volunteerIds,
                    identificationData: shiftIdentificationData
                }
                const response = await this.makeRequest<{ shift: IServerShift }>(
                    ShiftRequestType.RegisterVolunteersForShift,
                    data
                );
                const shift = new ServerShift(response.shift).deserialize();
                this.assignAddressToShift(shift, response.shift.addressId);
                return shift;
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Update the status of volunteer registrations for a shift ***************/

    async updateVolunteerRegistrationStatuses(volunteerIds: number[], shiftIdentification: ShiftIdentification, statusId: number) {
        try {
            if (this.rootStore.userStore.user.isOrganization) {
                const data = {
                    volunteerIds: volunteerIds,
                    shiftIdentification: shiftIdentification,
                    statusId: statusId
                }
                const response = await this.makeRequest<{ volunteerId: number, statusId: number }[]>(
                    ShiftRequestType.UpdateVolunteerRegistrationStatuses,
                    data
                );
                return response;
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Get all shifts for a particular organization ***************/

    async getAllShiftsForOrganization(orgId?: number) {
        try {
            let organizationId = orgId ? orgId : this.rootStore.userStore.user.organization?.id;
            if (organizationId) {
                const supplementalData = getSupplementalDataRequest(
                    ['locations', 'shiftOpportunities', 'roles']
                );

                let response = await this.makeRequest<{ shifts: IServerShift[] }>(
                    ShiftRequestType.GetAllShiftsForOrganization,
                    {
                        id: organizationId,
                        supplementalData: {
                            ...supplementalData,
                            shiftOpportunities: true
                        }
                    });
                return response.shifts.map(shift => new ServerShift(shift).deserialize());
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Get all shifts for a particular volunteer at an organization ***************/

    async getShiftsForOrganizationVolunteer(volunteerId: number, requestData: { limit: number, offset: number }) {
        try {
            if (this.rootStore.organizationStore.organization.id) {
                const requestConfig = this.generateRequestConfig(
                    ShiftRequestType.GetOrganizationVolunteerShifts,
                    {
                        volunteerId: volunteerId,
                        ...requestData
                    }
                );
                const response = await this.requestQueue.makeAPIRequest<{ results: IServerShift[], total: number }>(requestConfig);
                return {
                    ...response,
                    results: response.results.map(result => new ServerShift(result).deserialize())
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Get a particular shift ***************/

    async getShiftDetails(shiftOrParentId: number, offset?: number) {
        try {
            let supplementalData: SupplementalOrganizationDataRequest = {};
            if (this.rootStore.userStore.user.isOrganization) {
                supplementalData = getSupplementalDataRequest(
                    ['volunteers', 'locations', 'volunteerRegistrationStatuses', 'roles']
                );
            }

            const data = await this.makeRequest<IServerShiftDetails | { error: IAPIError }>(
                ShiftRequestType.GetShiftDetails,
                { id: shiftOrParentId, supplementalData: supplementalData, offset: offset }
            );

            if ('error' in data) throw new APIError(data.error);

            const shiftDetails = new ShiftDetails(data);
            this.assignAddressToShift(shiftDetails.shift, data.shift.addressId);
            return shiftDetails;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    /*************** Update a particular shift ***************/

    async upsertShiftDetails(shiftDetailsModification: ShiftDetailsModification) {
        try {
            let data = await this.makeRequest<IServerShiftDetails & ISupplementalOrganizationData>(
                ShiftRequestType.UpsertShiftDetails,
                shiftDetailsModification.serialize()
            );

            const newDetails = new ShiftDetails(data);
            this.assignAddressToShift(newDetails.shift, data.shift.addressId);
            return { details: newDetails, shiftOpportunities: data.supplementalOrganizationData.shiftOpportunities };
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Private response handling methods ***************/

    private assignAddressToShift(shift: Shift, addressId?: number) {
        if (addressId === undefined || shift.address) return;
        const shiftAddress = this.rootStore.userStore.user.organization?.locations.addresses.find(address => address.id === addressId);
        shift.setAddress(shiftAddress);
        shift.modifiedInstances.forEach(modifiedInstance => {
            this.assignAddressToShift(modifiedInstance, modifiedInstance.addressId);
        })
    }

    /*************** Private request handling methods ***************/

    private makeRequest<T>(type: ShiftRequestType, data?: any) {
        return new Promise<T>(async (resolve, reject) => {
            try {
                const requestConfig = this.generateRequestConfig(type, data);
                const responseData = await this.requestQueue.makeAPIRequest<T>(requestConfig);
                resolve(responseData);
            } catch (error) {
                reject(error);
            };
        })
    }

    private generateRequestConfig(type: ShiftRequestType, data?: any): IRequestConfig {
        const shiftsAPI = '/api/shifts'
        switch (type) {
            case ShiftRequestType.SignUpForShift:
                return {
                    method: 'post',
                    url: `${shiftsAPI}/post-shift-registration`,
                    data: data
                };
            case ShiftRequestType.UnregisterForShifts:
                return {
                    method: 'put',
                    url: `${shiftsAPI}/unregister`,
                    data: data
                }
            case ShiftRequestType.RegisterVolunteersForShift:
                return {
                    method: 'post',
                    url: `${shiftsAPI}/post-volunteer-registrations`,
                    data: data
                }
            case ShiftRequestType.UpdateVolunteerRegistrationStatuses:
                return {
                    method: 'put',
                    url: `${shiftsAPI}/update-volunteer-registration-statuses`,
                    data: data
                }
            case ShiftRequestType.GetAllShiftsForOrganization:
                return {
                    method: 'get',
                    url: `${shiftsAPI}/get-all-shifts/${data.id}`,
                    forceRefresh: true,
                    params: data
                }
            case ShiftRequestType.GetOrganizationVolunteerShifts:
                return {
                    method: 'get',
                    url: `${shiftsAPI}/organization-volunteer-shifts/${data.volunteerId}`,
                    forceRefresh: true,
                    params: data
                }
            case ShiftRequestType.GetShiftDetails:
                return {
                    method: 'get',
                    url: `${shiftsAPI}/shift-details/`,
                    forceRefresh: true,
                    params: data
                }
            case ShiftRequestType.UpsertShiftDetails:
                return {
                    method: 'put',
                    url: `${shiftsAPI}/shift-details`,
                    data: data
                }
        }
    }
}

