import { observable } from "mobx";
import { DateFormatter, UTCDateComponents } from "../../../logic/DateFormatter";
import { ServiceEntry } from "../../../modules/volunteer-hours/data-access/entities/ServiceEntry";
import { IServiceEntryTag, ServiceEntryTag } from "../ServiceEntryTag";
import { Volunteer } from "../Volunteer";

export interface IServerServiceEntry {
    id: number;
    organization?: string;
    organizationId: number;
    volunteerId: number;
    firstName?: string;
    lastName?: string;
    opportunityId: number;
    position: string;
    shiftId: number;
    defaultDaysFromStartDate?: number;
    tags: IServiceEntryTag[];
    date: Date | null;
    duration: number;
    statusId: number;
    notes: string;
    archived: boolean;
    lastOfficialEntry?: Omit<IServerServiceEntry, 'lastOfficialEntry'>;
    hasVolunteerEdits?: boolean;
    lastUpdateId?: number;
}

export class ServerServiceEntry implements IServerServiceEntry {
    @observable id: number;
    @observable organization?: string;
    @observable organizationId: number;
    @observable volunteerId: number;
    @observable firstName?: string;
    @observable lastName?: string;
    @observable opportunityId: number;
    @observable position: string;
    @observable shiftId: number;
    @observable defaultDaysFromStartDate?: number;
    @observable tags: ServiceEntryTag[];
    @observable date: Date | null = null;
    @observable utcDateComponents: UTCDateComponents | undefined = undefined;
    @observable duration: number;
    @observable statusId: number;
    @observable notes: string;
    @observable archived: boolean;
    @observable lastOfficialEntry?: ServerServiceEntry | undefined;
    @observable hasVolunteerEdits?: boolean;
    @observable lastUpdateId?: number;

    constructor(serviceEntry: IServerServiceEntry) {
        this.id = serviceEntry.id;
        this.organization = serviceEntry.organization;
        this.organizationId = serviceEntry.organizationId;
        this.volunteerId = serviceEntry.volunteerId;
        this.opportunityId = serviceEntry.opportunityId;
        this.position = serviceEntry.position;
        this.shiftId = serviceEntry.shiftId;
        this.defaultDaysFromStartDate = serviceEntry.defaultDaysFromStartDate;
        this.tags = serviceEntry.tags?.map(tag => new ServiceEntryTag(tag)) || [];
        if (serviceEntry.date) {
            const serviceEntryDate = new Date(serviceEntry.date);
            this.date = DateFormatter.getUTCDate(serviceEntryDate); // Keep the date the same as in the database, regardless of the client's timezone
            this.utcDateComponents = DateFormatter.getUTCDateComponents(serviceEntryDate);
        }
        this.duration = serviceEntry.duration;
        this.statusId = serviceEntry.statusId;
        this.notes = serviceEntry.notes || '';
        this.firstName = serviceEntry.firstName;
        this.lastName = serviceEntry.lastName;
        this.archived = serviceEntry.archived;
        this.lastOfficialEntry = serviceEntry.lastOfficialEntry
            ? new ServerServiceEntry(serviceEntry.lastOfficialEntry)
            : undefined;
        this.hasVolunteerEdits = serviceEntry.hasVolunteerEdits;
        this.lastUpdateId = serviceEntry.lastUpdateId;
    }

    deserialize(): ServiceEntry {
        let serviceEntry = {
            ...this,
            date: this.date!.toLocaleDateString(undefined, {
                month: 'numeric',
                day: 'numeric',
                year: '2-digit'
            }),
            volunteers: [new Volunteer({
                id: this.volunteerId,
                firstName: this.firstName || '',
                lastName: this.lastName || '',
                birthdate: undefined
            })],
            lastOfficialEntry: this.lastOfficialEntry
                ? this.lastOfficialEntry.deserialize()
                : undefined
        };
        return new ServiceEntry(serviceEntry);
    }
}