import { makeStyles, Theme, createStyles, FormControlLabel, Checkbox, Typography, FormControl, FormHelperText } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../../../stores";
import TermsOfServiceDialog from "./TermsOfServiceDialog";
import { DialogState } from "../../../../stores/models/DialogState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        center: {
            justifyContent: 'center'
        }
    }),
);

interface TermsOfServiceAcceptanceProps {
    checked: boolean;
    onChange: (accepted: boolean) => void;
    error?: string;
    organizationTerms?: boolean;
}

const TermsOfServiceAcceptance = observer((props: TermsOfServiceAcceptanceProps) => {

    /***** React hooks *****/

    const classes = useStyles();

    /***** State *****/

    const [dialogState] = useState(new DialogState());

    /***** Event handler *****/

    const onClickedTermsOfServiceLink = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.preventDefault();
        dialogState.setOpen(true);
    }

    /***** Render *****/

    return (
        <React.Fragment>
            <FormControl error={props.error !== undefined}>
                <FormControlLabel
                    classes={{ root: classes.center }}
                    control={
                        <Checkbox
                            checked={props.checked}
                            onChange={(event, checked) => props.onChange(checked)}
                            name="terms-of-service"
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            I agree to the <span className={classes.link} onClick={onClickedTermsOfServiceLink}>Terms of Service</span>.
                        </Typography>
                    }
                />
                {props.error !== undefined
                    ? <FormHelperText>{props.error}</FormHelperText>
                    : null
                }
            </FormControl>
            <TermsOfServiceDialog state={dialogState} organizationTerms={props.organizationTerms} />
        </React.Fragment>
    );
});

export default TermsOfServiceAcceptance;