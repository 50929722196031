import React from "react";
import { makeStyles, Theme, createStyles, SvgIconProps } from "@material-ui/core";
import clsx from 'clsx';
import REMFab from "../../shared/modules/rem-conversion/components/Fab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        fab: {
            background: theme.palette.primary.light,
        }
    })
);

interface ExtendedFabProps {
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    link?: string;
    icon: (props: SvgIconProps) => JSX.Element;
    text: string;
    className?: string;
}

function ExtendedFab(props: ExtendedFabProps) {

    // React hooks
    const classes = useStyles();

    return (
        <REMFab
            color="primary"
            variant='extended'
            onClick={props.onClick}
            href={props.link}
            className={clsx(props.className, classes.fab)}
        >
            {React.createElement(props.icon, { className: classes.extendedIcon })}
            {props.text}
        </REMFab>
    );
};

export default ExtendedFab;