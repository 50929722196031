import React, { ReactNode } from "react";
import SubscriptionPlan from "../../../../stores/models/SubscriptionPlan";
import { initializeDictionaryWithValue, Dictionary } from "../../../../logic/Dictionaries";
import { Typography } from "@material-ui/core";
import InformationTooltip from "./InformationTooltip";

export enum SubscriptionPlanOptions {
    Unselected = 'Unselected',
    Nonprofit = 'Nonprofit',
    Generator = 'Generator',
    Transformer = 'Transformer',
    Powerhouse = 'Powerhouse'
}

export function getSubscriptionCost(plan: SubscriptionPlanOptions) {
    switch (plan) {
        case SubscriptionPlanOptions.Generator:
            return '$9.99';
        case SubscriptionPlanOptions.Transformer:
            return '$49.99';
        case SubscriptionPlanOptions.Powerhouse:
            return '$89.99';
        default:
            return '';
    }
}

function getRemoteOpportunitiesLimit(plan: SubscriptionPlanOptions) {
    switch (plan) {
        case SubscriptionPlanOptions.Generator:
            return 2;
        case SubscriptionPlanOptions.Transformer:
            return 10;
        case SubscriptionPlanOptions.Powerhouse:
            return 'Unlimited';
        default:
            return '';
    }
}

function getCitiesLimit(plan: SubscriptionPlanOptions) {
    switch (plan) {
        case SubscriptionPlanOptions.Generator:
            return 2;
        case SubscriptionPlanOptions.Transformer:
            return 10;
        case SubscriptionPlanOptions.Powerhouse:
            return 'Unlimited';
        default:
            return '';
    }
}

/***** Subscription Plan Features *****/

export const REMOTE_OPPORTUNITY_TOOLTIP = `Remote opportunities are opportunities that do not require an individual to travel to a particular location to volunteer.`;
export const ON_SITE_OPPORTUNITY_TOOLTIP = `On site opportunities require an individual to travel to a particular location to volunteer.`;
const ACTIVE_RECRUITMENT_CITIES_TOOLTIP = `The number of different cities an organization can have active volunteer opportunity posts in at a single time.`;

export const FeatureHeaders: Dictionary<Feature, React.ReactNode> = {
    cityLimitation: (
        <React.Fragment>
            Active Recruitment Cities
            <InformationTooltip title={ACTIVE_RECRUITMENT_CITIES_TOOLTIP} />
        </React.Fragment>
    ),
    remotePosts: (
        <React.Fragment>
            Remote Opportunity Posts
            <InformationTooltip title={REMOTE_OPPORTUNITY_TOOLTIP} />
        </React.Fragment>
    ),
    posts: (
        <React.Fragment>
            On Site Opportunity Posts
            <InformationTooltip title={ON_SITE_OPPORTUNITY_TOOLTIP} />
        </React.Fragment>
    ),
    profile: 'Personalized Organization Profile',
    opportunities: 'Opportunities Hub',
    support: 'Phone and Email Support',
    community: 'Access to the Voltage Volunteer Community',
}

/***** Private helper method and constants *****/

export enum Feature {
    CityLimitation = 'cityLimitation',
    RemotePosts = 'remotePosts',
    Posts = 'posts',
    Profile = 'profile',
    OpportunitiesHub = 'opportunities',
    Support = 'support',
    Community = 'community',
}

const getFeatureDictionary = (override?: Partial<Dictionary<Feature, any>>) => {
    let dictionary = initializeDictionaryWithValue(Object.values(Feature), true);
    if (override) {
        dictionary = { ...dictionary, ...override };
    }
    return dictionary;
}

const levelOneFeatures = getFeatureDictionary({
    remotePosts: `${getRemoteOpportunitiesLimit(SubscriptionPlanOptions.Generator)} remote posts`,
    posts: 'Unlimited',
    cityLimitation: `${getCitiesLimit(SubscriptionPlanOptions.Generator)} cities`,
});

const levelTwoFeatures = getFeatureDictionary({
    remotePosts: `${getRemoteOpportunitiesLimit(SubscriptionPlanOptions.Transformer)} remote posts`,
    posts: 'Unlimited',
    cityLimitation: `${getCitiesLimit(SubscriptionPlanOptions.Transformer)} cities`,
});

const levelThreeFeatures = getFeatureDictionary({
    remotePosts: getRemoteOpportunitiesLimit(SubscriptionPlanOptions.Powerhouse),
    posts: 'Unlimited',
    cityLimitation: getCitiesLimit(SubscriptionPlanOptions.Powerhouse),
});

const getFeatureTextDictionary = (override?: Partial<Dictionary<Feature, ReactNode>>) => {
    let dictionary = FeatureHeaders;
    if (override) {
        dictionary = { ...dictionary, ...override };
    }
    return dictionary;
}

const levelOneFeatureText = getFeatureTextDictionary({
    posts: 'Unlimited On Site Opportunity Posts',
    remotePosts: `${getRemoteOpportunitiesLimit(SubscriptionPlanOptions.Generator)} remote opportunities`,
    cityLimitation: (
        <Typography>
            {`Recruit in `}
            <span>up to {getCitiesLimit(SubscriptionPlanOptions.Generator)} cities</span>
            {' simultaneously'}
        </Typography>
    )
});

const levelTwoFeatureText = getFeatureTextDictionary({
    posts: 'Unlimited On Site Opportunity Posts',
    remotePosts: `${getRemoteOpportunitiesLimit(SubscriptionPlanOptions.Transformer)} remote opportunities`,
    cityLimitation: (
        <Typography>
            {`Recruit in `}
            <span>up to {getCitiesLimit(SubscriptionPlanOptions.Transformer)} cities</span>
            {' simultaneously'}
        </Typography>
    )
});

const levelThreeFeatureText = getFeatureTextDictionary({
    posts: 'Unlimited On Site Opportunity Posts',
    remotePosts: (
        <React.Fragment>
            <span>{getRemoteOpportunitiesLimit(SubscriptionPlanOptions.Powerhouse)}</span>
            {` remote opportunities`}
        </React.Fragment>
    ),
    cityLimitation: (
        <Typography>
            {`Recruit in `}
            <span>{getCitiesLimit(SubscriptionPlanOptions.Powerhouse).toString().toLowerCase()} cities</span>
            {' simultaneously'}
        </Typography>
    )
});


/***** For-profit Subscription Plan Levels *****/

export const LevelOnePlan = new SubscriptionPlan(
    SubscriptionPlanOptions.Generator,
    getSubscriptionCost(SubscriptionPlanOptions.Generator),
    levelOneFeatures,
    levelOneFeatureText
);

export const LevelTwoPlan = new SubscriptionPlan(
    SubscriptionPlanOptions.Transformer,
    getSubscriptionCost(SubscriptionPlanOptions.Transformer),
    levelTwoFeatures,
    levelTwoFeatureText
);

export const LevelThreePlan = new SubscriptionPlan(
    SubscriptionPlanOptions.Powerhouse,
    getSubscriptionCost(SubscriptionPlanOptions.Powerhouse),
    levelThreeFeatures,
    levelThreeFeatureText
);

export type PlanLevels = 1 | 2 | 3;

export const isPlanLevel = (x: any): x is PlanLevels => {
    if (typeof x === 'number') {
        return (x === 1 || x === 2 || x === 3);
    } else {
        return false;
    }
}

export const PlanLevelDictionary: { [key in PlanLevels]: SubscriptionPlan<Feature> } = {
    1: LevelOnePlan,
    2: LevelTwoPlan,
    3: LevelThreePlan
}