import { observable, computed, makeObservable } from "mobx";

interface IAddressSuggestion {
    text: string;
}

export class AddressSuggestion implements IAddressSuggestion {
    text: string;

    private textPieces: string[] = [];

    constructor(text: string) {
        makeObservable<AddressSuggestion, "cityStateZipCountry" | "cityStateZipCountryPieces">(this, {
            text: observable,
            lineOne: computed,
            cityStateZipCountry: computed,
            cityStateZipCountryPieces: computed,
            city: computed,
            state: computed,
            zip: computed,
            secondaryString: computed
        });

        this.text = text;
        this.textPieces = text.split(/,(?= [^,]+, [A-Z]{2}, [0-9]{5}, USA)/);
    }

    get lineOne() {
        return this.textPieces.length > 0 ? this.textPieces[0] : '';
    }

    private get cityStateZipCountry() {
        return this.textPieces.length > 1 ? this.textPieces[1] : '';
    }

    private get cityStateZipCountryPieces() {
        return this.cityStateZipCountry.split(', ');
    }

    get city() {
        return this.cityStateZipCountryPieces.length > 0 ? this.cityStateZipCountryPieces[0].trim() : '';
    }

    get state() {
        return this.cityStateZipCountryPieces.length > 1 ? this.cityStateZipCountryPieces[1] : '';
    }

    get zip() {
        return this.cityStateZipCountryPieces.length > 2 ? this.cityStateZipCountryPieces[2] : '';
    }

    get secondaryString() {
        return `${this.city}, ${this.state} ${this.zip}`;
    }
}