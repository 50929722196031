import { Container, Paper, makeStyles, Theme, createStyles, Typography, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react";
import useResetScrollPosition from "../Shared/Hooks/ResetScrollPosition";
import { getSearchLink } from "../Navigation/Links/UrlConstructors";
import OrganizationSearch from "./images/organization-search.png";
import SearchFilters from "./images/search-filters.png";
import SearchPage from "./images/search-page.png";
import OpportunityPage from "./images/opportunity-page.png";
import OrganizationPage from "./images/organization-page.png";
import SchedulePage from "./images/schedule-page.png";
import ScheduleTable from "./images/shift-table.png";
import ShiftDetails from "./images/shift-details.png";
import SparksPage from "./images/sparks-page.png";
import SparkIcon from "./images/spark-icon.png";
import ResponsesPage from "./images/responses-page.png";
import VolunteerHoursPage from "./images/volunteer-hours-page.png";
import InformationPage from "./images/information-page.png";
import SettingsPage from "./images/settings-page.png";
import ShiftRegistration from "./images/shift-registration-popup.png";
import PermissionNeeded from "./images/permission-needed.png";
import OrganizationTab from "./images/organization-tab.png";
import OpportunitiesTab from "./images/opportunities-tab.png";
import NewServiceEntryDialog from "./images/service-entry.png";
import EditServiceEntryDialog from "./images/edit-service-entry.png";
import DeleteServiceEntryDialog from "./images/delete-service-entry.png";
import UpcomingSchedule from "./images/upcoming-schedule.png";
import clsx from 'clsx';
import { Fragment } from "react";
import { StylizedInternalLink } from "../Navigation/Components";
import AnchorLink from "../Navigation/Components/AnchorLink";
import { useIsOrgPortal } from "../Navigation/Hooks";
import { PortalAboutPage, PortalSearchPage, PortalSearchFilters, PortalOpportunityPage } from "./images/portal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'left',
            flexGrow: 1
        },
        wrapperContainer: {
            maxWidth: 900
        },
        content: {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2)
            },
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3),
            '& h3': {
                fontWeight: 600,
                marginBottom: theme.spacing(2)
            },
            '& h4, h5, h6': {
                scrollMarginTop: `${theme.spacing(9)}px`,
            },
            '& h4': {
                marginBottom: theme.spacing(2),
                fontWeight: 600
            },
            '& p': {
                marginBottom: theme.spacing(2)
            },
            '& h4:not(:first-child)': {
                marginTop: theme.spacing(4)
            },
            '& h5': {
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(2),
                fontWeight: 300
            },
            '& img': {
                marginBottom: theme.spacing(3)
            },
            '& img$floatLeft, img$floatRight': {
                marginBottom: theme.spacing(2)
            }
        },
        image: {
            maxWidth: '350px',
        },
        fullWidth: {
            width: '100%',
            marginBottom: theme.spacing(3)
        },
        flex: {
            display: 'flex',
            '& > :first-child': {
                paddingRight: theme.spacing(2)
            }
        },
        subsection: {
            marginTop: theme.spacing(2),
            '& h5': {
                marginTop: 0
            }
        },
        floatRight: {
            float: 'right',
            marginLeft: theme.spacing(2),
        },
        floatLeft: {
            float: 'left',
            marginRight: theme.spacing(2),
        },
        halfWidth: {
            width: '50%',
        },
        clear: {
            clear: 'both'
        }
    })
);

/*** Anchor tags ***/
const OPPORTUNITY_PAGES_SECTION_ID = 'opportunity-pages-section';
const ORGANIZATION_PAGES_SECTION_ID = 'organization-pages-section';
const SPARKS_PAGE_SECTION_ID = 'sparks-page-section';
const SERVICE_HISTORY_SECTION_ID = 'service-history-section';
const LINKING_TO_ORGANIZATION_SECTION_ID = 'linking-to-organization-section';
const ADD_VOLUNTEER_HOURS_SECTION_ID = 'add-volunteer-hours-section';
const DELETE_VOLUNTEER_HOURS_SECTION_ID = 'delete-volunteer-hours-section';

interface VolunteerInstructionsProps {
    title?: string;
}

const VolunteerInstructions = observer((props: VolunteerInstructionsProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    useResetScrollPosition();
    const nineHundredDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(900));
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const isOrgPortal = useIsOrgPortal();

    const flexSection = (text: JSX.Element, imageSrc: string, altText: string, textSide: 'left' | 'right') => {
        return nineHundredDown
            ? <Fragment>
                {text}
                <img src={imageSrc} alt={altText} className={classes.fullWidth} />
            </Fragment>
            : (
                <div className={clsx(classes.flex, classes.subsection)}>
                    {textSide === 'left'
                        ? <Fragment>
                            <div>
                                {text}
                            </div>
                            <div>
                                <img src={imageSrc} alt={altText} className={classes.image} />
                            </div>
                        </Fragment>
                        : <Fragment>
                            <div>
                                <img src={imageSrc} alt={altText} className={classes.image} />
                            </div>
                            <div>
                                {text}
                            </div>
                        </Fragment>
                    }
                </div>
            )
    }

    /***** Render *****/

    return (
        <div id="volunteer-instructions" className={classes.root}>
            <Container className={classes.wrapperContainer}>
                <Paper>
                    <Container>
                        <div className={classes.content}>
                            <Typography variant="h3">{props.title ? props.title : 'Instructions for Volunteers'}</Typography>
                            <Typography>
                                Need some help finding your way around? Check out the instructions below for information on navigating and using the site.
                            </Typography>
                            <Typography variant="h4">Search Page</Typography>
                            <Typography>
                                To navigate to the <StylizedInternalLink to={getSearchLink()}>Search Page</StylizedInternalLink>, click the <b>Find Opportunities</b> option in the main menu at the top of the screen. On smaller screens, this menu item appears as a magnifying glass icon without any text.
                            </Typography>
                            <Typography>
                                From the search page, you can browse or filter through the assortment of recruitment posts. Clicking on a recruitment post will open up its <AnchorLink anchorId={OPPORTUNITY_PAGES_SECTION_ID}>Opportunity Page</AnchorLink> where you'll be able to see additional details, including a full description of the role, where it takes place, and the timing of any upcoming shifts.
                            </Typography>
                            <img src={isOrgPortal ? PortalSearchPage : SearchPage} alt="Search page" className={classes.fullWidth} />
                            {flexSection(
                                <Fragment>
                                    <Typography variant="h5">
                                        Finding a Volunteer Opportunity
                                    </Typography>
                                    <Typography>
                                        There are a number of ways to search and filter through opportunity posts. You can type in the search field to search by keyword. You can filter through opportunity posts based on whether they take place in person or remotely. You can also filter based on causes you're passionate about or based on experience, interests, or skills you have to share.
                                    </Typography>
                                </Fragment>,
                                isOrgPortal ? PortalSearchFilters : SearchFilters,
                                "Search filters",
                                'left'
                            )}
                            {isOrgPortal
                                ? null
                                : flexSection(
                                    <Fragment>
                                        <Typography variant="h5">
                                            Finding an Organization
                                        </Typography>
                                        <Typography>
                                            Rather than searching for a particular opportunity post, you can instead browse through or search for organizations. In order to do so, click the drop down menu next to the search field and select <b>Organizations</b>. Clicking on an organization search result will open up that organization's <AnchorLink anchorId={ORGANIZATION_PAGES_SECTION_ID}>Organization Page</AnchorLink>.
                                        </Typography>
                                    </Fragment>,
                                    OrganizationSearch,
                                    "Search by organizations",
                                    'right'
                                )}
                            <Typography variant="h4" id={OPPORTUNITY_PAGES_SECTION_ID}>
                                Opportunity Pages
                            </Typography>
                            <Typography>
                                The Opportunity Page displays details about a specific volunteer recruitment post. You'll find a description of the role as well as information about the onboarding process. You can click the <b>Contact Recruiter</b> button on the right side of the page to send a message or question to the recruiter who made the post. If the opportunity sparks your interest, you can save it by clicking the <b>Save to Sparks</b> button. The opportunity will then appear on the <AnchorLink anchorId={SPARKS_PAGE_SECTION_ID}>Sparks Page</AnchorLink> of your profile.
                            </Typography>
                            <img src={isOrgPortal ? PortalOpportunityPage : OpportunityPage} alt="Opportunity page" className={classes.fullWidth} />
                            {isOrgPortal
                                ? null
                                : flexSection(
                                    <Typography>
                                        Clicking on the <b>Organization</b> tab at the top of the page will take you to the recruiter's <AnchorLink anchorId={ORGANIZATION_PAGES_SECTION_ID}>Organization Page</AnchorLink> where you can find additional details about the overarching mission that the volunteer opportunity aids in advancing.
                                    </Typography>,
                                    OrganizationTab,
                                    'Organization tab',
                                    'left'
                                )
                            }
                            <Typography variant="h5">
                                Scheduling
                            </Typography>
                            <Typography>
                                Some volunteer opportunities take place at scheduled times while others use a more flexible approach to scheduling. If an opportunity has upcoming shifts, you will be able to view details of those shifts in the Upcoming Schedule section toward the bottom of the page.
                            </Typography>
                            <img src={UpcomingSchedule} alt="Upcoming schedule" className={classes.fullWidth} />
                            <Typography variant="h5">
                                Opportunities Requiring Permission
                            </Typography>
                            <Typography>
                                Some opportunities require special permission in order to register for a shift. If that's the case, you'll see an <b>Express Interest</b> button at the top of the Upcoming Schedule section. By letting the recruiter know that you're interested, they can share with you any additional steps you may need to take in order to be given permission to register.
                            </Typography>
                            <img src={PermissionNeeded} alt="Permission needed warning" className={classes.fullWidth} />
                            {flexSection(
                                <Fragment>
                                    <Typography variant="h5">
                                        Registering for a Shift
                                    </Typography>
                                    <Typography>
                                        If the opportunity doesn't require any special permission to register for a shift or if you've already been given the appropriate permission, use the calendar navigation within the Upcoming Schedule section to find a shift with at least one slot remaining that fits your schedule, and click the <b>Sign Up</b> button to register. At this point, if you are not already logged in, you will be prompted to do so before continuing with your registration.
                                    </Typography>
                                </Fragment>,
                                ShiftRegistration,
                                'Registering for a shift',
                                'left'
                            )}
                            <Typography variant="h4" id={ORGANIZATION_PAGES_SECTION_ID}>
                                {isOrgPortal ? 'About Page' : 'Organization Pages'}
                            </Typography>
                            <Typography>
                                The {isOrgPortal ? 'About Page' : 'Organization Page'} displays a description of {isOrgPortal ? 'the' : 'an'} organization along with its mission, its cause areas, and any addresses where the organization is located. If the organization sparks your interest, you can save it by clicking the lightning bolt icon in the top right corner. The organization will then appear on the <AnchorLink anchorId={SPARKS_PAGE_SECTION_ID}>Sparks Page</AnchorLink> of your profile.
                            </Typography>
                            <img src={isOrgPortal ? PortalAboutPage : OrganizationPage} alt="Organization page" className={classes.fullWidth} />
                            {isOrgPortal
                                ? null
                                : flexSection(
                                    <Typography>
                                        Clicking the <b>Opportunities</b> tab at the top of the page will take you to either the most recent <AnchorLink anchorId={OPPORTUNITY_PAGES_SECTION_ID}>Opportunity Page</AnchorLink> you were viewing for the organization or, if you didn't have a particular volunteer opportunity page open, it will take you to the complete list of opportunities posted by the organization.
                                    </Typography>,
                                    OpportunitiesTab,
                                    'Opportunities tab',
                                    'left'
                                )
                            }
                            <Typography variant="h4">
                                Your Profile
                            </Typography>
                            <Typography variant="h5">
                                Schedule Page
                            </Typography>
                            <Typography>
                                Your Schedule Page lists all of the volunteer shifts that you've signed up for or that you've been registered for by a recruiter.
                            </Typography>
                            <img src={SchedulePage} alt="Schedule page" className={classes.fullWidth} />
                            <Typography>
                                On larger screens you'll have the ability to toggle between a calendar view and a table view.
                            </Typography>
                            <img src={ScheduleTable} alt="Schedule table" className={classes.fullWidth} />
                            <Typography>
                                Clicking on any individual shift from the calendar or table will bring up that shift's details. If you are no longer able to attend the shift, you can click the <b>Unregister</b> button.
                            </Typography>
                            <img src={ShiftDetails} alt="Shift details" className={classes.fullWidth} />
                            <Typography variant="h5" id={SERVICE_HISTORY_SECTION_ID}>
                                Volunteer Hours Page
                            </Typography>
                            <Typography>
                                {`Your Volunteer Hours Page helps you keep a record of all of your past volunteer 
                                    service across all organizations at which you've volunteered. The data associated 
                                    with each individual entry is shared between you and the organization at 
                                    which you served.`}
                            </Typography>
                            <img src={VolunteerHoursPage} alt="Volunteer Hours page" className={classes.fullWidth} />
                            <Typography>
                                There are several different ways an entry could end up in your volunteer hours:
                                <ol>
                                    <li>
                                        After a volunteer shift that you're registered for ends, hours are automatically logged for you.
                                    </li>
                                    <li>
                                        A staff member at an organization that you volunteer with can add volunteer hours for you.
                                    </li>
                                    <li>
                                        You can <AnchorLink anchorId={ADD_VOLUNTEER_HOURS_SECTION_ID}>add volunteer hours manually</AnchorLink> for organizations to which you're linked.
                                    </li>
                                </ol>
                            </Typography>
                            {!xsDown && <img src={NewServiceEntryDialog} alt="Log Volunteer Hours Dialog" className={clsx(classes.halfWidth, classes.floatRight)} />}
                            <Typography variant="subtitle1" id={ADD_VOLUNTEER_HOURS_SECTION_ID}>
                                <b>Adding Volunteer Hours</b>
                            </Typography>
                            <Typography>
                                To log volunteer hours, click the <b>Log Volunteer Hours</b> button toward the top left. In the popup that opens, select the organization where you served, the role you served in, and the date and length of the service.
                            </Typography>
                            {xsDown && <img src={NewServiceEntryDialog} alt="Log Volunteer Hours Dialog" className={classes.fullWidth} />}
                            <Typography>
                                You do not need to log hours for volunteer shifts that you registered for and completed. Those hours will be automatically logged for you.
                            </Typography>
                            <Typography>
                                If you don't see the correct organization listed as an option, it means your account is not yet linked to that organization. See the section below on <AnchorLink anchorId={LINKING_TO_ORGANIZATION_SECTION_ID}>Linking to an Organization</AnchorLink>. If you don't see the correct role listed as an option, you can reach out to the organization's volunteer coordinator to let them know to assign the role to you so that you can log hours under it going forward.
                            </Typography>
                            <Typography>
                                After clicking the <b>Add</b> button, you will see the volunteer hours added to the table with a status of Unconfirmed. Once a staff member at the organization has a chance to review your entry, they will be able to update the status.
                            </Typography>
                            {!xsDown && <img src={EditServiceEntryDialog} alt="Edit Volunteer Hours Dialog" className={clsx(classes.halfWidth, classes.floatLeft)} />}
                            <Typography variant="subtitle1">
                                <b>Modifying Volunteer Hours</b>
                            </Typography>
                            <Typography>
                                If you need to make adjustments to a volunteer hours entry, click on the entry's row in the table. In the popup that opens, adjust the details as needed. If the entry was automatically generated based on a shift you were registered for, you won't be able to change the organization or the role, but you can still edit the date and length of the entry or <AnchorLink anchorId={DELETE_VOLUNTEER_HOURS_SECTION_ID}>delete the entry</AnchorLink> if needed. Please note that if the volunteer hours entry had a status other than Unconfirmed, making changes to the entry will reset its status back to Unconfirmed.
                            </Typography>
                            {xsDown && <img src={EditServiceEntryDialog} alt="Edit Volunteer Hours Dialog" className={classes.fullWidth} />}
                            <Typography variant="subtitle1" className={classes.clear} id={DELETE_VOLUNTEER_HOURS_SECTION_ID}>
                                <b>Deleting Volunteer Hours</b>
                            </Typography>
                            <Typography>
                                To delete a volunteer hours entry, click on the checkbox in the leftmost column of the entry's row. Then click the <b>Delete</b> button at the top of the table.
                            </Typography>
                            <img src={DeleteServiceEntryDialog} alt="Delete Volunteer Hours Dialog" className={classes.fullWidth} />
                            <Typography variant="subtitle1" id={LINKING_TO_ORGANIZATION_SECTION_ID}>
                                <b>Linking to an Organization</b>
                            </Typography>
                            <Typography>
                                There are a number of ways to link to an organization:
                                <ol>
                                    <li>Register for one of the organization's volunteer shifts.</li>
                                    <li>Respond to an opportunity posted by the organization.</li>
                                    <li>Contact the organization's recruiter via the <b>Contact Recruiter</b> button on an <AnchorLink anchorId={OPPORTUNITY_PAGES_SECTION_ID}>Opportunity Page</AnchorLink>.</li>
                                    <li>Receive an email invitation from the organization and follow the embedded link to connect.</li>
                                </ol>
                            </Typography>
                            <Typography variant="h5" id={SPARKS_PAGE_SECTION_ID}>
                                Sparks Page
                            </Typography>
                            <Typography>
                                Throughout the platform, you can indicate when an opportunity or organization sparks your interest by clicking on the lightning bolt icons.
                            </Typography>
                            <img src={SparkIcon} alt="Spark icon" className={classes.fullWidth} />
                            <Typography>
                                Sparking an opportunity or organization saves it to your Sparks Page so that you can easily revisit it later on.
                            </Typography>
                            <img src={SparksPage} alt="Sparks page" className={classes.fullWidth} />
                            <Typography variant="h5">
                                Responses Page
                            </Typography>
                            <Typography>
                                Your Responses Page helps you keep track of any opportunities that you've expressed interest in to the recruiter. From this page, you'll be able to easily revisit opportunities if you want to review any of their details.
                            </Typography>
                            <img src={ResponsesPage} alt="Responses page" className={classes.fullWidth} />
                            <Typography variant="h5">
                                Information Page
                            </Typography>
                            <Typography>
                                Your Information Page allows you to update or correct your personal details.
                            </Typography>
                            <img src={InformationPage} alt="Information page" className={classes.fullWidth} />
                            <Typography variant="h5">
                                Settings Page
                            </Typography>
                            <Typography>
                                Your Settings Page displays your account information and allows you to update your password.
                            </Typography>
                            <img src={SettingsPage} alt="Settings page" className={classes.fullWidth} />
                        </div>
                    </Container>
                </Paper>
            </Container>
        </div>
    )
});

export default VolunteerInstructions;