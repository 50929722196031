export class UniqueIdentification {
   
    private static idIterator = -1;

    // Negative to indicate that it's a client ID.
    public static getClientId() {
        UniqueIdentification.idIterator--;
        return this.idIterator;
    }

    public static isClientId(id?: number) {
        return id !== undefined && id < 0;
    }    
}
