import { observer } from 'mobx-react'
import { Navigate, NavigateProps } from 'react-router-dom';
import { useOrgPortalPrefix } from '../Hooks';

const NavigateInternally = observer((props: NavigateProps) => {
    
    /********* React hooks *********/
    
    const internalLink = useOrgPortalPrefix(props.to);

    /********* Render *********/
    
    return (
        <Navigate {...props} to={internalLink} />
    );
});

export default NavigateInternally;