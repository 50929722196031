import React, { Fragment, useState } from "react";
import { Container, Paper, makeStyles, Theme, createStyles, useMediaQuery, Tabs, Tab, Divider } from "@material-ui/core";
import { observer } from "mobx-react";
import { TabContext, TabPanel } from "@material-ui/lab";
import TermsOfServiceVolunteerUser from "./TermsOfServiceVolunteerUser";
import TermsOfServiceOrganizationUser from "./TermsOfServiceOrganizationUser";
import { Account, Domain } from "mdi-material-ui";
import Footer from "../../../Shared/Footer";
import useResetScrollPosition from "../../../Shared/Hooks/ResetScrollPosition";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 0, 1),
            textAlign: 'left'
        },
        wrapperContainer: {
            maxWidth: 900
        },
        content: {
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2)
            },
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        title: {
            marginBottom: theme.spacing(3)
        },
        tabPanel: {
            flexGrow: 1,
            display: 'flex',
            padding: `${theme.spacing(3)}px 0`
        },
        divider: {
            marginTop: '-2px',
            height: 2
        },
        indicator: {
            height: '3.5px',
        },
        tabRoot: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: theme.spacing(1),
                paddingLeft: theme.spacing(1)
            }
        },
        tabWrapper: {
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row'
            }
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        labelIcon: {
            [theme.breakpoints.up('sm')]: {
                minHeight: 0
            }
        },
    })
);

enum TermsOfServiceTabs {
    VolunteerUser = 'Volunteer Users',
    OrganizationUser = 'Organization Users'
}

const TermsOfServicePage = observer(() => {

    /***** React hooks *****/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    useResetScrollPosition();

    /***** State *****/

    const [selectedTab, setSelectedTab] = useState(TermsOfServiceTabs.VolunteerUser);

    /***** Event handlers *****/

    const handleTabSelectionChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedTab(newValue as TermsOfServiceTabs);
    }

    /***** Render *****/

    return (
        <Fragment>
            <div id="privacy-policy" className={classes.root}>
                <Container className={classes.wrapperContainer}>
                    <Paper>
                        <Container>
                            <div className={classes.content}>
                                <h1 className={classes.title}>
                                    Terms of Service
                                </h1>
                                <TabContext value={selectedTab}>
                                    {/* Header */}
                                    <Container>
                                        <Tabs
                                            centered={xsDown}
                                            value={selectedTab}
                                            onChange={handleTabSelectionChange}
                                            classes={{ indicator: classes.indicator }}
                                        >
                                            <Tab
                                                value={TermsOfServiceTabs.VolunteerUser}
                                                label={TermsOfServiceTabs.VolunteerUser}
                                                icon={<Account className={classes.icon} />}
                                                classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, labelIcon: classes.labelIcon }}
                                            />
                                            <Tab
                                                value={TermsOfServiceTabs.OrganizationUser}
                                                label={TermsOfServiceTabs.OrganizationUser}
                                                icon={<Domain className={classes.icon} />}
                                                classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, labelIcon: classes.labelIcon }}
                                            />
                                        </Tabs>
                                        <Divider className={classes.divider} variant={xsDown ? 'middle' : 'fullWidth'} />
                                        {/* Tab Content */}
                                        <TabPanel
                                            value={TermsOfServiceTabs.VolunteerUser}
                                            className={selectedTab === TermsOfServiceTabs.VolunteerUser ? classes.tabPanel : undefined}
                                        >
                                            <TermsOfServiceVolunteerUser />
                                        </TabPanel>
                                        <TabPanel
                                            value={TermsOfServiceTabs.OrganizationUser}
                                            className={selectedTab === TermsOfServiceTabs.OrganizationUser ? classes.tabPanel : undefined}
                                        >
                                            <TermsOfServiceOrganizationUser />
                                        </TabPanel>
                                    </Container>
                                </TabContext>
                            </div>
                        </Container>
                    </Paper>
                </Container>
            </div>
            <Footer />
        </Fragment>
    )
});

export default TermsOfServicePage;