import { observer } from "mobx-react";
import React, { useContext, useState, useRef } from "react";
import { makeStyles, Theme, createStyles, alpha, useMediaQuery } from "@material-ui/core";
import { RootContext } from "../../../stores";
// import { MapMarker, Close } from "mdi-material-ui";
import SearchInput from "./SearchInput";
import { DialogState } from "../../../stores/models/DialogState";
import MobileSearchPrompt, { SearchPromptSteps } from "../Prompts/MobileSearchPrompt";
import FilterPicker from "../Prompts/FilterPicker";
import clsx from 'clsx';
import { MAIN_SEARCH_BREAKPOINT } from "../../Navigation/NavigationBar";
import SimpleCityPicker from "../Prompts/SimpleCityPicker";
import { City } from "../../../stores/models/City";
import SearchTypeSelection from "./SearchTypeSelection";
import LocationTypeSelection from "./LocationTypeSelection";
import MoreFiltersPrompt from "../Prompts/MoreFiltersPrompt";
import ButtonSelect from "./ButtonSelect";
import REMChip from "../../../shared/modules/rem-conversion/components/Chip";
import { useIsOrgPortal } from "../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchSettings: {
            marginTop: theme.spacing(2),
            margin: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                alignItems: 'center'
            },
            maxWidth: '1000px'
        },
        searchBarLine: {
            display: 'flex',
            flexShrink: 1,
            flexGrow: 1,
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(1)
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(1)
            }
        },
        locationLine: {
            display: 'flex',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        },
        searchInput: {
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            // marginBottom: theme.spacing(.5),
            marginRight: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center'
            // maxWidth: '400px'
        },
        noRightMargin: {
            marginRight: 0
        },
        filterLine: {
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'space-between',
            background: alpha(theme.palette.common.black, 0.07),
            borderRadius: theme.shape.borderRadius,
            '&:first-child': {
                marginLeft: theme.spacing(1)
            },
            '& > :not(:last-child)': {
                marginLeft: theme.spacing(1)
            },
            [theme.breakpoints.up('sm')]: {
                '& > :last-child': {
                    marginRight: theme.spacing(1)
                }
            }
        },
        flex: {
            display: 'flex'
        },
        grow: {
            flexGrow: 1
        },
        divider: {
            height: '60%',
            alignSelf: 'center'
        },
        filterButton: {
            padding: theme.spacing(1),
            color: theme.palette.action.active,
            flexShrink: 0
        },
        filters: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            margin: 0,
            '& > *': {
                margin: '3px'
            },
            '&:first-child': {
                // marginBottom: theme.spacing(.5)
                // marginRight: theme.spacing(1)
            }
        },
        appliedFilters: {
            marginTop: theme.spacing(0.5),
            '& .MuiChip-root': {
                border: `1px solid ${theme.palette.action.disabled}`
                // backgroundColor: '#057077',
                // backgroundColor: theme.palette.primary.dark,
                // color: theme.palette.common.white
            },
            '& .MuiChip-deleteIcon': {
                // color: 'inherit'
            }
        },
        icon: {
            color: '#057077'
        },
        textFieldIcon: {
            padding: theme.spacing(0, 1)
        },
        bottomMargin: {
            marginBottom: theme.spacing(1)
        },
        cityInputWrapper: {
            height: '40px',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
            }
        },
        popper: {
            marginLeft: theme.spacing(-6)
        },
        textFieldButton: {
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            color: theme.palette.text.primary,
            padding: 0
        },
        locationTextField: {
            flexGrow: 1,
        },
        selectedLocation: {
            color: theme.palette.text.primary
        },
        lessVerticalPadding: {
            paddingTop: 2,
            paddingBottom: 2
        },
        locationFilter: {
            minHeight: theme.spacing(5),
            marginBottom: theme.spacing(1)
        }
    })
);

// TODO: Clean up

const MobileSearchFilters = observer(() => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const searchStore = rootStore.searchStore;
    const searchCriteria = searchStore.searchCriteria;
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const displayCityInput = useMediaQuery((theme: Theme) => theme.breakpoints.down(MAIN_SEARCH_BREAKPOINT));
    const shortenSearchPlaceholder = useMediaQuery((theme: Theme) => theme.breakpoints.down(435));
    const isOrgPortal = useIsOrgPortal();

    /***** State *****/

    const [searchDialogState] = useState(new DialogState(false));
    const [filterSelectionDialogState] = useState(new DialogState());
    const [activeFilterStep, setActiveFilterStep] = useState<SearchPromptSteps>();
    const [city, setCity] = useState<string>('');
    const [simpleCityPickerState] = useState(new DialogState());
    const locationFieldRef = useRef<HTMLDivElement>(null);

    /***** Event handler *****/

    const onCityChanged = (newCity?: City) => {
        const newCityText = newCity?.cityAndState || '';
        setCity(newCityText);
        searchCriteria.setCityText(newCityText);
        simpleCityPickerState.setOpen(false);
    };

    const openCitySelection = () => {
        simpleCityPickerState.setOpen(true);
    }

    const handleOpenDialog = (step: SearchPromptSteps) => {
        setActiveFilterStep(step);
        searchDialogState.setOpen(true);
    }

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        searchCriteria.setSearchText(event.target.value);
    };

    const removeCity = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setCity('');
        searchCriteria.setCityText('');
        event.stopPropagation();
    }

    /***** Helper constant *****/

    const keywordFilter = (
        <React.Fragment>
            {!smDown ? <div className={classes.grow} /> : null}
            <div id="search-input-wrapper" className={clsx(classes.searchInput, isOrgPortal ? classes.noRightMargin : undefined)}>
                <SearchInput
                    onChange={handleSearchInputChange}
                    searchText={searchCriteria.searchText}
                    placeholder={shortenSearchPlaceholder ? "Search…" : undefined}
                />
            </div>
            {!isOrgPortal
                ? <SearchTypeSelection />
                : null
            }
        </React.Fragment>
    );

    /***** Render *****/

    return (
        <div id="search-filters">
            <div className={clsx(classes.flex, classes.bottomMargin)}>
                {keywordFilter}
            </div>
            <div className={classes.locationLine}>
                {/* <ButtonBase
                    className={clsx(classes.cityInputWrapper, searchCriteria.cityText ? classes.lessVerticalPadding : undefined)}
                    onClick={openCitySelection}
                >
                    <MapMarker className={classes.textFieldIcon} />
                    <TextField
                        placeholder="City or zip code"
                        value={searchCriteria.cityText}
                        InputProps={{ disableUnderline: true, autoComplete: 'off', autoCapitalize: 'off', autoCorrect: 'off', classes: { input: classes.textFieldButton } }}
                        ref={locationFieldRef}
                        disabled
                        className={clsx(classes.locationTextField, searchCriteria.cityText.length > 0 ? classes.selectedLocation : undefined)}
                    />
                    {searchCriteria.cityText
                        ? <IconButton onClick={removeCity}>
                            <Close fontSize="small" />
                        </IconButton>
                        : null
                    } */}
                {/* </div> */}
                {/* <CityInput city={city}
                        onChange={onCityChanged}
                        forNavBar
                        PopperProps={{ placement: 'bottom-start', className: classes.popper }}
                    /> */}
                {/* </ButtonBase> */}
                <ButtonSelect options={searchCriteria.locationOptions} className={classes.locationFilter} />
                {/* <LocationTypeSelection /> */}
            </div>
            <div className={classes.flex}>
                <ul className={classes.filters}>
                    {/****** Location ******/}
                    {/* {!displayCityInput
                        ? <Chip
                            icon={<MapMarker className={classes.icon} />}
                            variant="outlined"
                            label={searchCriteria.locationFilterText}
                            onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                            onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                        />
                        : null
                    } */}
                    {/* <Chip
                    variant="outlined"
                    // icon={<MapMarker />}
                    label={'Volunteering Site'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                /> */}
                    <REMChip
                        variant="outlined"
                        // icon={<MapMarker />}
                        label={'Causes'}
                        onClick={() => handleOpenDialog(SearchPromptSteps.CauseSelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                    />
                    <REMChip
                        // icon={<MapMarker />}
                        variant="outlined"
                        label={'Experience'}
                        onClick={() => handleOpenDialog(SearchPromptSteps.SkillsSelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                    />
                    {/* <Chip
                        variant="outlined"
                        // icon={<MapMarker />}
                        label={'Type of Results'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                    /> */}
                    <REMChip
                        // icon={<MapMarker />}
                        variant="outlined"
                        label={'More Filters'}
                        onClick={() => filterSelectionDialogState.setOpen(true)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                    />
                    {/* <Chip
                    variant="outlined"
                    // icon={<MapMarker />}
                    label={'Organization Type'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                />
                <Chip
                    variant="outlined"
                    // icon={<MapMarker />}
                    label={'Scheduling'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                />
                <Chip
                    variant="outlined"
                    // icon={<MapMarker />}
                    label={'Volunteer Need'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                />
                <Chip
                    variant="outlined"
                    // icon={<MapMarker />}
                    label={'Commitment'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                />
                <Chip
                    variant="outlined"
                    // icon={<MapMarker />}
                    label={'Results'}
                    // onClick={() => handleOpenDialog(SearchPromptSteps.CitySelection)}
                    // onDelete={searchCriteria.locationEntered ? () => searchCriteria.setCityText('') : undefined}
                /> */}
                </ul>
            </div>

            <ul className={clsx(classes.filters, classes.appliedFilters)}>
                {/****** Local Only ******/}
                {/* {searchCriteria.localOnly
                    ? <Chip
                        label={'Volunteer Locally'}
                        onDelete={() => searchCriteria.setLocalOnly(false)}
                    />
                    : null
                } */}
                {/****** Causes ******/}
                {searchCriteria.causeOptions.selections.map(option => {
                    return (
                        <REMChip
                            key={`cause-${option.object.id}`}
                            label={option.object.cause}
                            onDelete={() => option.toggleSelection()}
                        // icon={React.createElement(CauseIconDictionary[option.object.id])}
                        />
                    )
                })}
                {/****** Skills ******/}
                {searchCriteria.skillOptions.selections.map(option => {
                    return (
                        <REMChip
                            key={`skill-${option.object.id}`}
                            label={option.object.skill}
                            onDelete={() => option.toggleSelection()}
                        />
                    )
                })}
                {/****** Additional Filters ******/}
                {searchCriteria.filters?.map((filter, index) => {
                    return (
                        <REMChip
                            key={`filter-${index}`}
                            label={filter.text}
                            onDelete={filter.onDelete ? () => filter.onDelete!() : undefined}
                        // icon={React.createElement(filter.icon)}
                        />
                    )
                })}
            </ul>
            {/****** Filter Dialogs ******/}
            <MobileSearchPrompt state={searchDialogState} stepToDisplay={activeFilterStep} />
            <SimpleCityPicker city={city} onCityChanged={onCityChanged} state={simpleCityPickerState} />
            {/* <FilterPicker state={filterSelectionDialogState} /> */}
            <MoreFiltersPrompt state={filterSelectionDialogState} />
        </div>
    );
});

export default MobileSearchFilters;