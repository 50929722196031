import { observable, flow, action, makeObservable } from 'mobx';
import { RootStore } from './RootStore';
import { RequestQueue, IUser } from './models';
import { ValidationError } from './models/ValidationError';
import { IRequestConfig } from './models/RequestQueue';
import { StubUser } from './models/StubUser';
import { PaymentMethod } from './models/PaymentMethod';
import { AxiosError } from "axios";
import { APIError, IAPIError, isAPIError } from './models/APIError';
import { EXPIRED_CODE_ERROR_CODE, INVALID_CODE_ERROR_CODE, USED_CODE_ERROR_CODE } from '../data/ErrorCodes';

enum RegistrationRequestType {
    Register = 'register',
    UniqueUsernameCheck = 'unique-username'
}

export class RegistrationStore {
    private rootStore = {} as RootStore;
    private requestQueue: RequestQueue;

    errors: ValidationError<any>[] = [];

    constructor(rootStore: RootStore) {
        makeObservable<RegistrationStore, "makeRegistrationRequest" | "preparePaymentMethod">(this, {
            errors: observable,
            checkUsernameUniqueness: action,
            register: action,
            makeRegistrationRequest: action,
            preparePaymentMethod: action
        });

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
    }

    checkUsernameUniqueness = flow(function* (this: RegistrationStore, username: string) {
        try {
            const requestConfig = this.generateRequestConfig(RegistrationRequestType.UniqueUsernameCheck, { username: username.trim() });
            const response = yield this.requestQueue.makeAPIRequest<{ exists: boolean }>(requestConfig);
            const { exists } = response;
            return exists;
        } catch (error) {
            console.log(error);
        }
    });

    private getErrorDetails(errorData: string) {
        const errorRegex = /Error:.+?(?=<br>)/;
        const result = errorRegex.exec(errorData);
        if (result && result.length > 0) {
            return result[0]
        }
    }

    private isAxiosError = (x: any): x is AxiosError => {
        if (typeof x === 'object' && 'isAxiosError' in x) {
            return true;
        }
        return false;
    }

    register = flow(function* (this: RegistrationStore, stubUser: StubUser) {
        try {
            if (stubUser.stubOrganization && stubUser.stubOrganization.isForProfit) {
                yield this.preparePaymentMethod(stubUser.paymentMethod);
            }
            const user = yield this.makeRegistrationRequest(stubUser);
            this.rootStore.userStore.setAuthenticatedUser(user);
        } catch (error) {
            if (this.isAxiosError(error)) {
                if (error.response?.status === 402 || error.response?.status === 400) {
                    return `Please verify that the payment information you've entered is correct.`
                }
                if (typeof error.response?.data === 'string') {
                    const errorMessage = this.getErrorDetails(error.response?.data);
                    return errorMessage ? errorMessage : error.message;
                }
            } else if (isAPIError(error)) {
                if (error.code === INVALID_CODE_ERROR_CODE
                    || error.code === EXPIRED_CODE_ERROR_CODE
                    || error.code === USED_CODE_ERROR_CODE) {
                    return `Invalid or expired sign up code.`;
                }
            }
        }
    });

    private async makeRegistrationRequest(stubUser: StubUser) {
        const requestConfig = this.generateRequestConfig(
            RegistrationRequestType.Register,
            stubUser.serialize()
        );
        const response = await this.requestQueue.makeAPIRequest<{ user: IUser } | { error: IAPIError }>(requestConfig);
        if ('user' in response) {
            return response.user;
        } else {
            throw new APIError(response.error);
        }
    }

    private generateRequestConfig(type: RegistrationRequestType, data: any): IRequestConfig {
        const apiEndpoint = '/api/users';
        switch (type) {
            case RegistrationRequestType.Register:
                return {
                    method: 'post',
                    url: `${apiEndpoint}/register`,
                    data: data
                };
            case RegistrationRequestType.UniqueUsernameCheck:
                return {
                    method: 'get',
                    url: `${apiEndpoint}/username-exists`,
                    forceRefresh: true,
                    params: data
                }
        }
    }

    private preparePaymentMethod = flow(function* (this: RegistrationStore, paymentMethod: PaymentMethod) {
        const paymentMethodIdOrErrors = yield this.rootStore.paymentProcessingStore.setUpPaymentMethodRecord(paymentMethod);
        const paymentMethodId = typeof paymentMethodIdOrErrors === 'string' ? paymentMethodIdOrErrors : undefined;
        if (paymentMethodId) {
            paymentMethod.id = paymentMethodId;
        } else {
            this.errors = paymentMethodIdOrErrors as ValidationError<PaymentMethod>[];
        }
        return paymentMethodId;
    });
}

