import React, { useContext, useState, useEffect, ChangeEvent, Ref, useRef, useImperativeHandle, forwardRef } from "react";
import { observer } from 'mobx-react'
import {
    makeStyles,
    createStyles,
    Theme,
    Grid,
    TextField,
    Popper,
    PopperProps,
    useMediaQuery,
} from "@material-ui/core";
import { MapMarker, MapMarkerRadius } from "mdi-material-ui";
import { RootContext } from "../../../stores";
import { Autocomplete, AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails, createFilterOptions, AutocompleteClassKey } from "@material-ui/lab";
import { City } from "../../../stores/models/City";
import clsx from 'clsx';

// TODO: Clean up

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocompleteRoot: {
            width: 'auto'
        },
        autocompleteInput: {
            fontSize: '1.75rem',
            lineHeight: 'normal'
        },
        optionIcon: {
            paddingRight: theme.spacing(2),
            color: theme.palette.action.active
        },
        noPadding: {
            paddingRight: '0px !important'
        },
        locationIcon: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            display: 'flex',
        },
        navBarLocationIcon: {
            color: '#057077',
        },
        search: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        textFieldRoot: {
            padding: theme.spacing(1, 1, 1, 0),
            width: '225px'
        },
        navBarTextFieldRoot: {
            // width: '135px',
            paddingRight: theme.spacing(1)
        },
        navBarInputRoot: {
            // color: 'inherit'
        },
        cityName: {
            // fontWeight: 500
        },
        hidden: {
            display: 'none'
        },
        popper: {
            width: 'auto !important',
        }
    }),
);

const filterOptions = createFilterOptions<City>({
    trim: true,
});

const MIN_LENGTH_TO_AUTOCOMPLETE = 1;

interface CityInputProps {
    city: string;
    placeholder?: string;
    onChange?: (searchText: string) => void;
    onSelectionChanged?: (selection?: City) => void;
    forNavBar?: boolean;
    noFreeSolo?: boolean;
    classes?: Partial<Record<AutocompleteClassKey, string>>;
    iconClass: string;
    PopperProps?: Partial<PopperProps>;
    PopperComponent?: React.FunctionComponent<PopperProps>;
}

const CityInput = observer(forwardRef((props: CityInputProps, ref: Ref<any>) => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const autocompleteStore = rootStore.autocompleteStore;
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /***** State *****/

    const [open, setOpen] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState([] as City[]);
    const [selectedCity, setSelectedCity] = useState<City>();
    const [focused, setFocused] = useState(false);

    /***** Autocomplete Element Reference *****/

    const inputRef = useRef<any>();
    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus();
        }
    }));

    /***** Effects *****/

    useEffect(() => {
        return function cleanup() {
            setOpen(false);
        }
    }, [])

    useEffect(() => {
        if (focused && textIsMinLengthToAutocomplete(props.city) && (selectedCity === undefined || selectedCity?.cityAndState !== props.city)) {
            setOpen(true);
        } else if (focused && shouldDisplayDefaultSuggestions(props.city)) {
            setOpen(true);
            setAutocompleteOptions(getDefaultAutocompleteOptions());
        } else {
            setOpen(false);
        }
    }, [props.city, focused]);

    const getDefaultAutocompleteOptions = () => {
        let defaultOptions = [] as City[];
        if (autocompleteStore.geolocationSuggestion) {
            defaultOptions.push(autocompleteStore.geolocationSuggestion);
        }
        return defaultOptions;
    }

    /***** Event handlers *****/

    const sendSelectionChangedNotification = (newCity: City) => {
        if (props.onSelectionChanged) {
            props.onSelectionChanged(newCity);
        }
    }

    const handleInputChange = (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
        const text = value;
        if (reason === 'clear') {
            setFocused(true);
        }
        if (reason !== 'reset') {
            setSelectedCity(undefined);
            if (props.onChange) {
                props.onChange(text);
            }
            getAutocompleteOptions(text);
        }
    }

    const handleOptionSelected = (
        event: React.ChangeEvent<{}>,
        value: string | City | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<City> | undefined
    ) => {
        if (value) {
            setFocused(false);
            setOpen(false);
            if (typeof value === 'string') {
                if (props.onChange) {
                    props.onChange(value);
                }
            } else {
                setSelectedCity(value);
                sendSelectionChangedNotification(value);
                if (props.onChange) {
                    props.onChange(value.cityAndState);
                }
            }
        }
    }

    /***** Helper methods *****/

    const textIsMinLengthToAutocomplete = (text: string) => {
        const trimmedText = text.trim();
        return trimmedText.length >= MIN_LENGTH_TO_AUTOCOMPLETE;
    }

    const shouldDisplayDefaultSuggestions = (text: string) => {
        if (autocompleteStore.geolocationSuggestion) {
            const trimmedText = text.trim();
            const uppercase = trimmedText.toUpperCase();
            const uppercaseSuggestion = autocompleteStore.geolocationSuggestion.cityAndState.toUpperCase();
            if (uppercase !== uppercaseSuggestion) {
                const regex = new RegExp(`^${uppercase}`);
                return regex.test(uppercaseSuggestion);
            }
        }
        return false;
    }

    const getAutocompleteOptions = async (textToMatch: string) => {
        if (textIsMinLengthToAutocomplete(textToMatch)) {
            const trimmedText = textToMatch.trim();
            const newOptions = await autocompleteStore.fetchCityAutocomplete(trimmedText);
            if (newOptions) {
                setAutocompleteOptions(newOptions);
            }
        } else if (autocompleteStore.geolocationSuggestion) {
            setAutocompleteOptions([autocompleteStore.geolocationSuggestion]);
        }
    }

    /***** Render *****/

    return (
        <Autocomplete
            inputValue={props.city.length === 0 ? '' : props.city}
            open={open}
            onClose={() => setOpen(false)}
            onInputChange={handleInputChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={handleOptionSelected}
            options={autocompleteOptions}
            filterOptions={filterOptions}
            autoHighlight={!xsDown}
            freeSolo={!props.noFreeSolo}
            popupIcon={null}
            className={classes.autocompleteRoot}
            classes={{
                inputRoot: props.city.length > 0 ? undefined : classes.noPadding,
                endAdornment: props.city.length > 0 ? undefined : classes.hidden,
                input: clsx(props.forNavBar ? undefined : classes.autocompleteInput, props.city.length > 0 ? undefined : classes.noPadding),
                ...props.classes
            }}
            renderInput={(params) => (
                <div className={classes.search}>
                    <div id="location-icon" className={clsx(classes.locationIcon, props.iconClass)}>
                        <MapMarker />
                    </div>
                    <TextField
                        placeholder={props.placeholder ? props.placeholder : "City"}
                        classes={{
                            root: props.forNavBar ? classes.navBarTextFieldRoot : classes.textFieldRoot,
                        }}
                        inputRef={inputRef}
                        // inputProps={{ 'aria-label': 'city' }}
                        {...params}
                        InputProps={props.forNavBar ? { ...params.InputProps, disableUnderline: true, classes: { root: classes.navBarInputRoot } } : params.InputProps}
                    />
                </div>
            )}
            getOptionLabel={(option) => option.cityAndState || props.city}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            {option === autocompleteStore.geolocationSuggestion
                                ? <MapMarkerRadius className={classes.optionIcon} />
                                : <MapMarker className={classes.optionIcon} />
                            }
                        </Grid>
                        <Grid item xs>
                            <span className={classes.cityName}>
                                {option.cityAndState}
                            </span>
                        </Grid>
                    </Grid>
                );
            }}
            PopperComponent={
                props.PopperComponent
                    ? props.PopperComponent
                    : (popperProps) => {
                        return <Popper {...popperProps} {...props.PopperProps}></Popper>
                    }
            }
        />
    );
}));

export default CityInput;