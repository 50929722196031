import { useEffect } from 'react';
import { ConfirmationDialogState } from '../entities/ConfirmationDialogState';

interface ConfirmationDialogResultProps {
    state: ConfirmationDialogState;
    onConfirmed?: () => void;
    onCancelled?: () => void;
}

export default function useConfirmationDialogResult(props: ConfirmationDialogResultProps) {
  
    useEffect(() => {
        if (props.state.confirmed) {
            if (props.onConfirmed) {
                props.onConfirmed();
            }
        } else if (props.state.confirmed === false) {
            if (props.onCancelled) {
                props.onCancelled();
            }
        }
        props.state.reset();
    }, [props.state.confirmed]);
    
}