import React, { useContext, useEffect } from "react";
import { makeStyles, Theme, createStyles, Paper, Container, Typography, useMediaQuery, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext } from "../../../../../stores";
import { getOpportunityEditorLink, getOpportunityLink, getShiftDetailsLink, DEFAULT_DAYS_FROM_START_DATE_QUERY_PARAMETER, PARENT_ID_QUERY_PARAMETER, SHIFT_ID_QUERY_PARAMETER } from "../../../../Navigation/Links/UrlConstructors";
import LoadingIndicator from "../../../../Shared/LoadingIndicator";
import InactiveSubscriptionMessage from "./../Subscription/InactiveSubscriptionMessage";
import ZeroState from "../../../../Shared/ZeroState";
import ShiftCard, { ShiftAction } from "../../../../Organization/VolunteerOpportunities/ShiftCard";
import { ShiftDetails } from "../../../../../stores/models/ShiftDetails";
import { Eye, Pencil, TrashCan } from "mdi-material-ui";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog";
import ThemedDialogWithSpinner from "../../../../Shared/Dialogs/ThemedDialogWithSpinner";
import ShiftEntry from "../../../../Organization/VolunteerOpportunities/ShiftEntry";
import { Shift } from "../../../../../stores/models/Shift";
import ShiftSignUpDialog from "../../../../Organization/VolunteerOpportunities/ShiftSignUpDialog";
import { ShiftIdentification } from "../../../../../stores/models/ShiftIdentification";
import { differenceInCalendarDays } from "date-fns/esm";
import { getInitialDialogStates } from "../../../../../logic/DialogStateDictionary";
import { ShiftDetailsModification, ShiftEditingOptions } from "../../../../../stores/models/ShiftDetailsModification";
import VolunteerRegistrationsSection from "./VolunteerRegistrationsSection";
import ShiftSeriesDropdown from "./ShiftSeriesDropdown";
import ShiftDetailsBreadcrumbs from "./ShiftDetailsBreadcrumbs";
import { useQuery } from "../../../../Shared/Hooks/URLQuery";
import { APIError, isAPIError } from "../../../../../stores/models/APIError";
import ErrorDialog from "../../../../Shared/Dialogs/ErrorDialog";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import { NavigateInternally } from "../../../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%'
        },
        contentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        paper: {
            paddingTop: '15px',
            paddingBottom: '15px',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word'
        },
        title: {
            marginBottom: theme.spacing(2)
        },
        subtitle: {
            marginBottom: theme.spacing(1)
        },
        icon: {
            color: theme.palette.darkBackground.main
        },
        opportunityRow: {
            display: 'flex',
            alignItems: 'center',
            '& > :not(:last-child)': {
                marginRight: theme.spacing(1)
            }
        },
        buttonRow: {
            marginTop: theme.spacing(1),
            '& > :not(:last-child)': {
                marginRight: theme.spacing(2)
            }
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            wordBreak: 'break-word'
        },
        toolbar: {
            display: 'flex',
        },
        selectionMenu: {
            '& > button': {
                color: theme.palette.action.active,
            },
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        },
        shiftButtons: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1),
            '& > button': {
                color: theme.palette.action.active,
            },
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(1)
            }
        },
        section: {
            marginTop: theme.spacing(2)
        }
    })
);

enum DialogTypes {
    ShiftUpdater = 'shiftUpdater',
    DeleteConfirmation = 'deleteConfirmation',
    VolunteerSignUp = 'volunteerSignUp',
    ShiftSeriesDropdownPopper = 'shiftSeriesDropdownPopper',
    ShiftLoadingError = 'shiftLoadingError'
}

interface ShiftDetailsPageProps {
    onShiftUpdated: (newShift: ShiftDetails, oldShift?: ShiftDetails) => void;
    onRegistrationsChanged: (shift: Shift, registered: boolean) => void;
    redirectLink?: string;
    editable?: boolean;
}

const ShiftDetailsPage = observer((props: ShiftDetailsPageProps) => {

    /********* React hooks *********/
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const shiftStore = rootStore.shiftStore;
    const userStore = rootStore.userStore;
    const query = useQuery();
    const shiftId = parseInt(query.get(SHIFT_ID_QUERY_PARAMETER) || '') || undefined;
    const parentId = parseInt(query.get(PARENT_ID_QUERY_PARAMETER) || '') || undefined;
    const defaultDaysFromStartDate = parseInt(query.get(DEFAULT_DAYS_FROM_START_DATE_QUERY_PARAMETER) || '');
    const requestedShiftInstance = (defaultDaysFromStartDate !== undefined) && (!isNaN(defaultDaysFromStartDate));
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const editAnchorRef = React.useRef<HTMLButtonElement>(null);
    const deleteAnchorRef = React.useRef<HTMLButtonElement>(null);

    /********* State *********/

    const [isLoading, setIsLoading] = React.useState(false);
    const [loadingError, setLoadingError] = React.useState<APIError>();
    const [shiftDetailsDraft, setShiftDetailsDraft] = React.useState<ShiftDetails>();
    const [dialogStates] = React.useState(getInitialDialogStates(Object.values(DialogTypes)));
    const [shouldRedirect, setShouldRedirect] = React.useState(false);
    const [currentPopperAnchor, setCurrentPopperAnchor] = React.useState<React.RefObject<HTMLButtonElement>>(editAnchorRef);
    const [shiftDetailsModification] = React.useState(new ShiftDetailsModification());

    const shiftDetails = shiftDetailsModification.displayedShiftDetails;
    const organizationId = shiftDetails?.organizationId || -1;

    /********* Effects *********/

    useEffect(() => {
        sendGetShiftRequest();
    }, []);

    useEffect(() => {
        if (dialogStates.shiftUpdater.open && shiftDetailsModification.shiftDetailsDraft) {
            setShiftDetailsDraft(shiftDetailsModification.shiftDetailsDraft);
        }
    }, [dialogStates.shiftUpdater.open]);

    useEffect(() => {
        if (loadingError) {
            dialogStates.shiftLoadingError.setOpen(true);
        }
    }, [loadingError]);

    /********* API Requests *********/

    const sendGetShiftRequest = async () => {
        try {
            setIsLoading(true);
            const shiftToRequest = shiftId ? shiftId : parentId;
            if (!shiftToRequest) return;
            const response = await shiftStore.getShiftDetails(shiftToRequest, defaultDaysFromStartDate);

            if (response === undefined) throw new Error('Request error');
            shiftDetailsModification.setOriginalTopLevelShift(response);
            if (requestedShiftInstance) {
                const childInstanceDetails = getChildInstanceShiftDetails(response, defaultDaysFromStartDate);
                if (!childInstanceDetails) throw new Error('Invalid child instance');
                shiftDetailsModification.setDisplayedShiftDetails(childInstanceDetails);
            } else {
                shiftDetailsModification.setDisplayedShiftDetails(response);
            }
        } catch (err) {
            if (isAPIError(err)) {
                setLoadingError(err);
            } else {
                // Invalid default days from start date or shift id from URL
                setShouldRedirect(true);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const upsertShift = async () => {
        if (shiftDetailsDraft === undefined) return;

        setAllShiftFieldsDirty(shiftDetailsDraft.shift);
        if (!shiftDetailsDraft.shift.validated) return;

        shiftDetailsModification.updateSelectedShifts(shiftDetailsDraft);
        if (!shiftDetailsModification.oldShiftDetails || !shiftDetailsModification.newShiftDetails) return;

        const response = await shiftStore.upsertShiftDetails(shiftDetailsModification);
        return response;
    }

    const deleteShift = async () => {
        shiftDetailsModification.deleteSelectedShifts();
        if (!shiftDetailsModification.newShiftDetails) return;

        const response = await shiftStore.upsertShiftDetails(shiftDetailsModification);
        if (response) {
            props.onShiftUpdated(response.details);
            shiftDetailsModification.setDisplayedShiftDetails(response.details);
        }
    }

    /********* Helper methods *********/

    const getChildInstanceShiftDetails = (parentShiftDetails: ShiftDetails, offset?: number) => {
        if (offset === undefined || isNaN(offset)) return;
        try {
            return parentShiftDetails.getInstanceShiftDetailsFromParentShiftDetails(offset);
        } catch {
            setShouldRedirect(true);
        }
    }

    /********* Event handlers *********/

    const onEditOpportunityButtonClicked = () => {
        if (shiftDetails) {
            navigate(getOpportunityEditorLink(shiftDetails.opportunityId));
        }
    }

    const onViewOpportunityClicked = () => {
        if (shiftDetails) {
            const link = getOpportunityLink(organizationId, shiftDetails.opportunityId); // TODO: Don't link to expired opportunities?
            navigate(link);
        }
    }

    const onEditShiftClicked = () => {
        onShiftActionClicked('edit');
    }

    const onDeleteShiftClicked = () => {
        onShiftActionClicked('delete');
    }

    const onShiftActionClicked = (action: 'edit' | 'delete') => {
        if (shiftDetails?.parentShift) {
            setCurrentPopperAnchor(action === 'edit' ? editAnchorRef : deleteAnchorRef);
            dialogStates.shiftSeriesDropdownPopper.setOpen(true);
        } else {
            const dialogState = action === 'edit' ? dialogStates.shiftUpdater : dialogStates.deleteConfirmation;
            dialogState.setOpen(true);
        }
    }

    const onEditOptionSelected = (option: ShiftEditingOptions) => {
        shiftDetailsModification.setSelectedEditingOption(option);
        if (currentPopperAnchor === editAnchorRef) {
            dialogStates.shiftUpdater.setOpen(true);
        } else {
            dialogStates.deleteConfirmation.setOpen(true);
        }
    }

    const onDeleteShiftConfirmed = async () => {
        dialogStates.deleteConfirmation.setLoading(true);
        await deleteShift();
        dialogStates.deleteConfirmation.setLoading(false);
        dialogStates.deleteConfirmation.setOpen(false);
    }

    /********* Volunteer Registration/Unregistration *********/

    const onRegistrationsChanged = (shift: Shift, registered: boolean) => {
        if (shiftDetails) {
            const shiftInstance = shift.getInstanceByIdentificationData(shiftDetails.shift.identificationData);
            if (shiftInstance) {
                shiftInstance.setRegistered(registered);
                props.onRegistrationsChanged(shiftInstance, registered);
            }
        }
        applyRegistrationChange(shift);
    }

    const applyRegistrationChange = (shift: Shift) => {
        if (!shiftDetails?.shift.identificationData) return;
        const shiftInstance = shift.getInstanceByIdentificationData(shiftDetails.shift.identificationData);
        if (!shiftInstance) return;
        shiftDetailsModification.displayedShiftDetails?.setShift(shiftInstance);
    }

    /********* Update Shift *********/

    const onConfirmShift = async () => {
        const response = await upsertShift();
        if (response === undefined || shiftDetails === undefined) return;

        // Update Calendar View
        props.onShiftUpdated(response.details, shiftDetailsModification.oldShiftDetails);
        shiftDetailsModification.setOriginalTopLevelShift(response.details);

        if (response.details.topLevelShift.isShiftSeries) {
            // Save the startTimestamp of the currently displayed shift
            const currentStartTimestamp = shiftDetails.shift.repetitionPattern.startTimestamp;

            // Calculate the offset of the shift to display and get the associated child instance
            if (!currentStartTimestamp) return;
            let newOffset;
            if (shiftDetailsModification.selectedEditingOption === ShiftEditingOptions.ThisShift) {
                newOffset = defaultDaysFromStartDate; // Keep the offset the same when only the current shift was edited.
            } else {
                newOffset = recalculateOffset(currentStartTimestamp, response.details.topLevelShift);
            }
            const newShiftInstanceDetails = getChildInstanceShiftDetails(response.details, newOffset);

            // Set the displayed shift to the new instance
            if (!newShiftInstanceDetails) return;
            newShiftInstanceDetails.setOldParentShift(undefined); // Probably a cleaner way to handle this. oldParentShift should be reset after edits are made to This and Following Shifts. It shouldn't be passed along with a subsequent delete request or it will causes issues. 
            shiftDetailsModification.setDisplayedShiftDetails(newShiftInstanceDetails);

            // Update the URL if needed
            const urlQueryData = new ShiftIdentification({ parentId: response.details.shift.identificationData.topLevelId, defaultDaysFromStartDate: newOffset });
            navigate(getShiftDetailsLink(urlQueryData), { replace: true });
        } else {
            // New one-off shift due to role change:
            const urlQueryData = new ShiftIdentification({ shiftId: response.details.identificationData.shiftId });
            navigate(getShiftDetailsLink(urlQueryData), { replace: true });
            shiftDetailsModification.setDisplayedShiftDetails(response.details);
        }

        dialogStates.shiftUpdater.setOpen(false);
    }

    const recalculateOffset = (currentStartTimestamp: Date, series: Shift) => {
        if (!series.isShiftSeries) return;
        const seriesStartTimestamp = series.repetitionPattern.startTimestamp;
        if (!seriesStartTimestamp) return;
        // Get the difference between the offset of the currently displayed shift from the new series's startTimestamp
        const numDaysFromStartDate = differenceInCalendarDays(currentStartTimestamp, seriesStartTimestamp);
        const newOffset = numDaysFromStartDate >= 0 ? numDaysFromStartDate : 0;
        // If the offset is valid for the new series, return it. Otherwise, use 0 (the first occurrence).
        return series.isValidInstanceOffset(newOffset) ? newOffset : 0;
    }

    const setAllShiftFieldsDirty = (shift: Shift) => {
        shift.setAllFieldsDirty();
        shift.repetitionPattern.setAllFieldsDirty();
        shift.repetitionPattern.ending.setAllFieldsDirty();
    }

    const onShiftActionSelected = async (shift: Shift, action: ShiftAction) => {
        switch (action) {
            case ShiftAction.EditRegistration:
            case ShiftAction.SignUp:
                dialogStates.volunteerSignUp.setOpen(true);
                break;
            default:
                break;
        }
    }

    const onErrorClosed = () => {
        setShouldRedirect(true);
    }

    /********* Helper constant *********/

    const shiftDeleted = shiftDetailsModification.displayedShiftDetails?.shift.archived || shiftDetailsModification.displayedShiftDetails?.shift.instanceData?.deleted;
    // shiftDetails?.shift.instanceData?.deleted || shiftDetails?.shift.archived;
    const invalidShiftDetails = shiftDetails?.shift.isShiftSeries;

    /********* Render *********/

    if (isLoading) {
        return <LoadingIndicator />;
    } else if (props.redirectLink && (shiftDeleted || shouldRedirect || invalidShiftDetails)) {
        // Redirect when the shift is deleted
        return <NavigateInternally to={props.redirectLink} replace />; 
    } else if (shiftDetails && shiftDetails.parentShift && shiftDetails.parentShift.id !== parentId) {
        return (
            <NavigateInternally
                to={getShiftDetailsLink(new ShiftIdentification({
                    parentId: shiftDetails.parentShift.id,
                    defaultDaysFromStartDate: 0
                }))}
                replace
            />
        );
    }

    return (
        <div className={classes.root}>
            {!userStore.user.active
                ? <ZeroState
                    content={
                        <Container>
                            <InactiveSubscriptionMessage content={`Plug back in to edit a shift.`} />
                        </Container>
                    }
                />
                : <div className={classes.contentWrapper}>
                    {/* Breadcrumbs */}
                    <ShiftDetailsBreadcrumbs />
                    {shiftDetails === undefined
                        ? null
                        : <React.Fragment>
                            {/* Shift Details */}
                            <div id="shift-information">
                                <Paper className={classes.paper}>
                                    <Container>
                                        <div className={classes.header}>
                                            <Typography variant="h4" className={classes.title}>
                                                Shift Details
                                            </Typography>
                                        </div>
                                        <ShiftCard
                                            shift={shiftDetails.shift}
                                            opportunityData={{
                                                id: shiftDetails.opportunityId,
                                                position: shiftDetails.position,
                                                onClick: props.editable ? onEditOpportunityButtonClicked : onViewOpportunityClicked
                                            }}
                                            showSignUpOption={!props.editable}
                                            onShiftActionSelected={onShiftActionSelected}
                                            registered={!props.editable && shiftDetails.shift.registered}
                                        />
                                        {props.editable &&
                                            <div className={classes.shiftButtons}>
                                                <Button
                                                    startIcon={<Pencil />}
                                                    size={xsDown ? 'medium' : 'large'}
                                                    onClick={onEditShiftClicked}
                                                    ref={editAnchorRef}
                                                >
                                                    Edit
                                                </Button>
                                                {shiftDetails.parentShift &&
                                                    <ShiftSeriesDropdown
                                                        state={dialogStates.shiftSeriesDropdownPopper}
                                                        anchor={currentPopperAnchor}
                                                        onOptionSelected={onEditOptionSelected}
                                                    />
                                                }
                                                <Button
                                                    startIcon={<TrashCan />}
                                                    size={xsDown ? 'medium' : 'large'}
                                                    onClick={onDeleteShiftClicked}
                                                    ref={deleteAnchorRef}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    startIcon={<Eye />}
                                                    size={xsDown ? 'medium' : 'large'}
                                                    onClick={onViewOpportunityClicked}
                                                >
                                                    View
                                                </Button>
                                            </div>
                                        }
                                    </Container>
                                </Paper>
                            </div>
                            {/* Shift Entry Dialog */}
                            {props.editable &&
                                <React.Fragment>
                                    <ThemedDialogWithSpinner
                                        state={dialogStates.shiftUpdater}
                                        title={'Update Shift'}
                                        primaryButtonProps={{ children: 'Update' }}
                                        onSubmit={onConfirmShift}
                                        DialogProps={{ fullScreen: xsDown }}
                                    >
                                        {shiftDetailsDraft &&
                                            <ShiftEntry
                                                shift={shiftDetailsDraft.shift}
                                                locationOptions={rootStore.userStore.user.organization?.locations.addresses || []}
                                                inlineLocationAdding
                                                opportunityId={shiftDetails.opportunityId}
                                                opportunityError={shiftDetailsDraft.getErrorForField('opportunityId')}
                                                opportunityOptions={rootStore.userStore.user.organization?.roles}
                                                onOpportunityChanged={(opportunityId: number) => shiftDetailsDraft.setOpportunityId(opportunityId)}
                                            />
                                        }
                                    </ThemedDialogWithSpinner>
                                    {/* Registrations */}
                                    <div className={classes.section}>
                                        <VolunteerRegistrationsSection
                                            onShiftUpdated={props.onShiftUpdated}
                                            shiftDetails={shiftDetails}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    {/* Confirmation Dialog for Deletion */}
                    {props.editable &&
                        <ConfirmationDialog
                            state={dialogStates.deleteConfirmation}
                            title="Delete Shift"
                            content={(
                                <Typography>Are you sure you want to remove this shift?</Typography>
                            )}
                            confirmText="Remove"
                            onConfirm={onDeleteShiftConfirmed}
                            fullScreen={false}
                        />
                    }
                    {!props.editable && shiftDetails &&
                        <ShiftSignUpDialog
                            state={dialogStates.volunteerSignUp}
                            shift={shiftDetails.shift}
                            organizationId={shiftDetails.organizationId}
                            onRegistrationsChanged={onRegistrationsChanged}
                        />
                    }
                    {/* Error Dialog */}
                    <ErrorDialog
                        state={dialogStates.shiftLoadingError}
                        dialogTitle={'Error Loading Shift'}
                        errorDetails={loadingError ? loadingError : ''}
                        onAcknowledge={() => onErrorClosed()}
                    />
                </div >
            }
        </div>
    );
});

export default ShiftDetailsPage;