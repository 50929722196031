import { observable, action, computed, override, makeObservable } from "mobx";
import { Fields } from "./Fields";
import { validateVolunteerDemographics } from "../../logic/ValidationChecks";

interface IStubVolunteerFields {
    firstName: string;
    lastName: string;
    birthdate: Date | null;
}

export class StubVolunteer extends Fields<IStubVolunteerFields, StubVolunteer> implements IStubVolunteerFields {
    firstName = "";
    lastName = "";
    birthdate: Date | null = null;

    constructor() {
        super();

        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            birthdate: observable,
            setFirstName: action,
            setLastName: action,
            setBirthdate: action,
            serializedData: computed,
            validationErrors: override
        });
    }

    setFirstName(firstName: string) {
        this.firstName = firstName;
    }

    setLastName(lastName: string) {
        this.lastName = lastName;
    }

    setBirthdate(date: Date | null) {
        this.birthdate = date;
    }

    get validationErrors() {
        return validateVolunteerDemographics(this);
    }

    get serializedData() {
        return {
            firstName: this.trimmed.firstName,
            lastName: this.trimmed.lastName,
            birthdate: this.birthdate
        }
    }
}