import { makeStyles, Theme, createStyles, Typography, Card, Stepper, Step, StepLabel, StepContent } from "@material-ui/core";
import { observer } from "mobx-react";
import { DateFormatter } from "../../../logic/DateFormatter";
import { ServiceEntry } from "../data-access/entities/ServiceEntry";
import { CalendarClock, ClipboardText, TagOutline } from "mdi-material-ui";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { Fragment } from "react";
import ServiceEntryStatusIcon from "./ServiceEntryStatusIcon";
import { useRootStore } from "../../../shared/general/hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            '& .MuiPaper-root': {
                backgroundColor: 'rgb(255, 244, 229)',
            },
            '& .MuiStepIcon-root': {
                color: theme.palette.warning.main
            },
            '& .MuiStepLabel-label': {
                color: 'black',
                fontWeight: 600
            },
            color: 'rgb(102, 60, 0)'
        },
        row: {
            display: 'flex'
        },
        icon: {
            marginRight: theme.spacing(1),
            color: 'rgb(102 60 0 / 85%)'
        },
        stepper: {
            border: 'none'
        }
    })
);

interface ServiceEntryAuditTrailProps extends React.HTMLAttributes<HTMLDivElement> {
    serviceEntry: ServiceEntry;
}

const ServiceEntryAuditTrail = observer((props: ServiceEntryAuditTrailProps) => {

    const classes = useStyles();
    const { volunteerHoursStore } = useRootStore();

    /********** Helper method *********/

    const getStatusText = (serviceEntry: ServiceEntry) => {
        return volunteerHoursStore.serviceEntryStatuses.find(status => status.id === serviceEntry.statusId)?.status;
    }

    const getTagsText = (serviceEntry: ServiceEntry) => {
        return serviceEntry.tags.map(tag => tag.tag).join(', ');
    }

    const getStepContent = (serviceEntry: ServiceEntry) => {
        return (
            <Fragment>
                {serviceEntry.position &&
                    <div className={classes.row}>
                        <ClipboardText className={classes.icon} />
                        <Typography variant="body2">
                            {`Role: `}
                            {serviceEntry.position}
                        </Typography>
                    </div>
                }
                {serviceEntry.tags.length > 0 &&
                    <div className={classes.row}>
                    <TagOutline className={classes.icon} />
                    <Typography variant="body2">
                        {`Tags: `}
                        {getTagsText(serviceEntry)}
                    </Typography>
                </div>
                }
                <div className={classes.row}>
                    <CalendarClock className={classes.icon} />
                    <Typography variant="body2">
                        {`Date: ${serviceEntry.date ? DateFormatter.getDateInUTC(serviceEntry.date) : ''}`}
                    </Typography>
                </div>
                <div className={classes.row}>
                    <HourglassEmptyIcon className={classes.icon} />
                    <Typography variant="body2">
                        {`Duration: ${serviceEntry.formattedDuration}`}
                    </Typography>
                </div>
                <div className={classes.row}>
                    <ServiceEntryStatusIcon statusId={serviceEntry.statusId} className={classes.icon} />
                    <Typography variant="body2">
                        {`Status: ${getStatusText(serviceEntry)}`}
                    </Typography>
                </div>
            </Fragment>
        )
    }

    /********** Render *********/

    return (
        <Card className={classes.card}>
            <Stepper orientation="vertical" activeStep={3} className={classes.stepper} nonLinear>
                {props.serviceEntry.lastOfficialEntry &&
                    <Step expanded>
                        <StepLabel>Original Entry</StepLabel>
                        <StepContent>
                            {getStepContent(props.serviceEntry.lastOfficialEntry)}
                        </StepContent>
                    </Step>
                }
                <Step expanded>
                    <StepLabel>{props.serviceEntry.duration === 0 ? 'Deleted by volunteer' : 'Volunteer Edits'}</StepLabel>
                    {props.serviceEntry.duration !== 0 &&
                        <StepContent>
                            {getStepContent(props.serviceEntry)}
                        </StepContent>
                    }
                </Step>
            </Stepper>
        </Card>
    )
});

export default ServiceEntryAuditTrail;