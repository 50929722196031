import { observable, makeObservable } from "mobx";

export interface ISkill {
    id: number;
    skill: string;
}

export class Skill implements ISkill {
    id = -1;
    skill = "";

    constructor(skill: ISkill) {
        makeObservable(this, {
            id: observable,
            skill: observable
        });

        this.id = skill.id;
        this.skill = skill.skill;
    }
}

export interface ISkillCategory {
    id: number;
    category: string;
    skills: Skill[];
}

export class SkillCategory implements ISkillCategory {
    id = -1;
    category = "";
    skills = [] as Skill[];

    constructor(skillCategory: ISkillCategory) {
        makeObservable(this, {
            id: observable,
            category: observable,
            skills: observable
        });

        this.id = skillCategory.id;
        this.category = skillCategory.category;
        this.skills = skillCategory.skills.map(skill => {
            return new Skill(skill);
        });
    }
}