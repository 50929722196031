import { action, computed, makeAutoObservable } from "mobx";
import { CheckboxState } from "../../../../general/entities/CheckboxState";
import { RecordListStore } from "../stores/RecordListStore";
import { Option } from "../../../../../stores/models/Option";
import { ObjectWithIdentifier } from "../../../../../stores/models/NewOptionCollection";

// TODO: Should the constructor accept a RecordListViewDataModel object rather than RecordListStore?

export class RecordTableViewModel<RecordType extends ObjectWithIdentifier<RecordType>> {

    protected store: RecordListStore<RecordType>;

    constructor(store: RecordListStore<RecordType>) {
        makeAutoObservable(this);
        this.store = store;
    }

    @action toggleCheckbox(option: Option<RecordType>) {
        option.toggleSelection();
    }

    @action toggleHeaderCheckbox() {
        const indeterminateState = this.headerCheckboxState === CheckboxState.Indeterminate;
        this.store.recordsToDisplay.forEach(option => {
            if ((indeterminateState && option.selected) || !indeterminateState) {
                option.toggleSelection();
            }
        });
    }

    @action clearSelections() {
        this.store.collection.setSelections([]);
        this.store.setUnloadedSelected(false);
    }

    @computed get recordsToDisplay() {
        return this.store.recordsToDisplay;
    }

    @computed get total() {
        return this.store.total;
    }

    @computed get paginationState() {
        return this.store.paginationState;
    }

    @computed get sortingState() {
        return this.store.sortingState;
    }

    @computed get displaySelectAllRow() {
        return (this.headerCheckboxState === CheckboxState.Checked)
            && (this.store.total !== undefined)
            && (this.store.numSelectedIncludingUnloaded < this.store.total);
    }

    @computed get headerCheckboxState() {
        let allDisplayedRecordsSelected = true;
        let allDisplayedRecordsNotSelected = true;
        for (let i = 0; i < this.store.recordsToDisplay.length; i++) {
            const record = this.store.recordsToDisplay[i];
            if (!record.selected) {
                allDisplayedRecordsSelected = false;
            } else {
                allDisplayedRecordsNotSelected = false;
            }
            if (!allDisplayedRecordsNotSelected && !allDisplayedRecordsSelected) {
                return CheckboxState.Indeterminate;
            }
        }
        return allDisplayedRecordsNotSelected ? CheckboxState.NotChecked : CheckboxState.Checked;
    }

    injectStore<T>(method: <T>(store: RecordListStore<RecordType>) => any): T {
        return method(this.store);
    }

}
