import { Theme, createStyles, withStyles, SelectProps, makeStyles, alpha } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import clsx from 'clsx';
import REMSelect from "../../../shared/modules/rem-conversion/components/Select";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderRadius: theme.shape.borderRadius,
    },
    outlinedRoot: {
        backgroundColor: 'transparent',
        '&.MuiOutlinedInput-root:hover': {
            backgroundColor: theme.palette.grey[100],
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.action.disabled,
                backgroundColor: 'transparent'
            }
        },
        '&:focus-within': {
            backgroundColor: 'rgba(0, 0, 0, 0.07)'
        }
    },
    standardRoot: {
        backgroundColor: alpha(theme.palette.common.black, 0.07),
        '&:before': {
            borderBottom: 'none !important'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        },
        '&:focus-within': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
    },
}));

const styles = (theme: Theme) => createStyles({
    root: {
        paddingLeft: theme.spacing(1),
    },
    select: {
        '&:focus': {
            backgroundColor: 'transparent'
        }
    },
});

const PartiallyStyledSelect = withStyles(styles)(REMSelect);

export const StyledSelect = observer((props: SelectProps) => {

    const classes = useStyles();

    /*** Anchor element for placement of the dropdown popover ***/

    const ref = useRef<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        setAnchorEl(ref.current);
    }, []);

    /*** Render ***/

    return (
        <PartiallyStyledSelect
            {...props}
            ref={ref}
            MenuProps={{
                keepMounted: true,
                elevation: 3,
                anchorEl: anchorEl,
                getContentAnchorEl: null,
                anchorOrigin: { horizontal: 'center', vertical: 'bottom' }, 
                transformOrigin: { horizontal: 'center', vertical: 'top' }
            }}
            className={clsx(
                classes.root,
                props.variant === 'outlined' ? classes.outlinedRoot : classes.standardRoot,
                props.className
            )}
        />
    )
});