import { computed } from "mobx";
import { IAPIGetRequestParameters } from "../../../../../stores/models/APIGetRequestParameters";
import { RecordListStore } from "../stores/RecordListStore";
import { IGetRecordsAPIResponse } from "../../../../../shared/modules/record-collection/data-access/interfaces/RecordListAPIResponse";
import { ObjectWithIdentifier } from "../../../../../stores/models/NewOptionCollection";
import { APIAction } from "./APIAction";

export abstract class LoadRecordListAction<
    RecordType extends ObjectWithIdentifier<RecordType>,
    SortableKeys extends string,
    ServerRecordType = RecordType,
    Param = unknown
> extends APIAction<RecordType, SortableKeys, ServerRecordType, Param> {

    constructor(store: RecordListStore<RecordType, SortableKeys>) {
        super(store);
    }

    abstract fetchData(loadRequest: IAPIGetRequestParameters<SortableKeys>, param: Param): Promise<IGetRecordsAPIResponse<ServerRecordType> | undefined>;

    async makeAPICall(param: Param) {
        let resetRecords = this.parseParam(param, 'resetRecords', 'boolean') || false;

        try {
            const loadRequest = this.getAdjustedLoadRequest(resetRecords);
            let response = await this.fetchData(loadRequest, param);
            if (response) {
                response.results = this.transformData(response.results);
            }

            if (resetRecords) {
                this.store.resetRecords(true);
            }
            this.store.collection.push(response && response.results ? response.results as unknown as RecordType[] : [], this.store.unloadedSelected);
            this.store.setTotal(response ? response.total : 0);
        } catch (error) {
            if (resetRecords) {
                this.store.setTotal(0);
                this.store.resetRecords();
            }
            throw error;
        }
    }

    getAdjustedLoadRequest(resetRecords: boolean) {
        const loadRequest = { ...this.loadRequest };
        if (resetRecords) {
            loadRequest.limit = loadRequest.offset + loadRequest.limit;
            loadRequest.offset = 0;
        } else {
            // Adjust load request to avoid reloading records
            const numLoadedRecords = this.store.collection.options.length;
            const offset = Math.max(loadRequest.offset, numLoadedRecords);
            const difference = numLoadedRecords - loadRequest.offset;
            loadRequest.limit = loadRequest.limit - difference;
            loadRequest.offset = offset;
        }
        return loadRequest;
    }

    @computed get loadRequest(): IAPIGetRequestParameters<SortableKeys> {
        return this.store.loadRequest;
    }

    @computed get requestingUnloadedRecords() {
        return this.store.requestingUnloadedRecords;
    }
}
