import {
    flow,
    action,
    makeObservable,
    override,
} from 'mobx';
import { RootStore } from './RootStore';
import { DataStore } from './DataStore';
import { Form, IForm } from './models/Form';
import qs from 'qs';
import { APIError } from './models/APIError';

enum FormRequestTypes {
    GetOrgRegistrationForm = 'getOrgRegistrationForm',
    Submit = 'submit'
}

export class FormStore extends DataStore<FormRequestTypes> {

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    /********* API Request Methods *********/

    @action getForm = flow(function* (this: FormStore, organizationId: number, standalone?: boolean) {
        const result = yield this.makeRequest<{ form?: IForm, submitted: boolean }>(FormRequestTypes.GetOrgRegistrationForm, { organizationId, standalone: standalone });
        return {
            form: result.form ? new Form(result.form) : undefined,
            submitted: result.submitted
        };
    });

    @action submitForm = flow(function* (this: FormStore, form: Form, orgId: string, proxy?: boolean) {
        const data = {
            form: form.formData,
            organizationId: orgId,
            proxy: proxy // Indicates whether or not the form is being filled out on behalf of someone else
        };
        const formattedData = qs.stringify(data, { encodeValuesOnly: true, arrayFormat: 'repeat' });
        const response = yield this.makeRequest<{ succeeded: boolean, error?: string | APIError }>(FormRequestTypes.Submit, formattedData);
        return response as { succeeded: boolean, error?: string | APIError };
    });

    /****** Computed Data ******/

    @override get getRequests() {
        return [FormRequestTypes.GetOrgRegistrationForm];
    }

    @override get postRequests() {
        return [FormRequestTypes.Submit];
    }

    @override get requestURLs() {
        return {
            [FormRequestTypes.GetOrgRegistrationForm]: (data: { organizationId: number }) => `/api/proxy/form/${data.organizationId}`,
            [FormRequestTypes.Submit]: '/api/proxy/form'
        };
    }
}