import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors, isValidEmail } from "./Validation";
import { ForgotPasswordData } from "../../stores/models/ForgotPasswordData";

const forgotPasswordDataChecks: ValidationCheck<ForgotPasswordData>[] = [{
    name: 'valid email',
    condition: (forgotPasswordData: ForgotPasswordData) => {
        return isValidEmail(forgotPasswordData.email);
    },
    error: new ValidationError('Please enter a valid email address.', ['email'])
}];

export default function validateForgotPasswordData(forgotPasswordData: ForgotPasswordData) {
    return getValidationErrors(forgotPasswordDataChecks, forgotPasswordData);
}