

import { observer } from "mobx-react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { FormItem, FormItemTypes } from "../../../stores/models/FormSection";
import CheckboxItem from "./Elements/CheckboxItem";
import ImageItem from "./Elements/ImageItem";
import MultipleChoiceItem from "./Elements/MultipleChoiceItem";
import ShortAnswerItem from "./Elements/ShortAnswerItem";
import TextItem from "./Elements/TextItem";

interface FormSectionProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    item: FormItem;
}

const FormElement = observer((props: FormSectionProps) => {

    const getItem = (item: FormItem) => {
        switch (item.type) {
            case FormItemTypes.ShortAnswer:
                return <ShortAnswerItem item={item} />;
            case FormItemTypes.MultipleChoice:
                return <MultipleChoiceItem item={item} />;
            case FormItemTypes.Checkbox:
                return <CheckboxItem item={item} />
            case FormItemTypes.Text:
                return <TextItem item={item} />
            case FormItemTypes.Image:
                return <ImageItem item={item} />
        }
    }

    /********* Render *********/

    return (
        getItem(props.item)
    );
});

export default FormElement;