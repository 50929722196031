import { useContext, useEffect, useState } from "react";
import { RootContext } from "../../../../stores";
import { Fields } from "../../../../stores/models/Fields";
import { Step, StepProps } from "../../../../stores/models/Step";
import CreateVolunteerAccountStep from "../../../Authentication/LinkVolunteerToOrganizationSteps/Steps/CreateVolunteerAccountStep";
import InvalidUserTypeStep from "../../../Authentication/LinkVolunteerToOrganizationSteps/Steps/InvalidUserTypeStep";
import AuthenticateToLinkAccountStep from "./Steps/AuthenticateToLinkAccountStep";
import { RegistrationFormStepObject } from "./Steps/RegistrationFormStepObject";
import VerifyCorrectAccountStep from "./Steps/VerifyCorrectAccountStep";

export enum VolunteerLinkingSteps {
    LoginOrSignUp = 'loginOrSignUp',
    Registration = 'registration',
    InvalidUserType = 'invalidUserType',
    VerifyCorrectAccount = 'verifyCorrectAccount'
}

type VolunteerLinkingStepComponent = (props: StepProps<RegistrationFormStepObject>) => JSX.Element;

const stepComponentDictionary: { [key in VolunteerLinkingSteps]: VolunteerLinkingStepComponent } = {
    // TODO: Refactor AuthenticateToLinkAccountStep and VerifyCorrectAccountStep to use the same steps that
    // are used as part of the organization volunteer linking step sequence
    [VolunteerLinkingSteps.LoginOrSignUp]: AuthenticateToLinkAccountStep,
    [VolunteerLinkingSteps.Registration]: CreateVolunteerAccountStep,
    [VolunteerLinkingSteps.InvalidUserType]: InvalidUserTypeStep,
    [VolunteerLinkingSteps.VerifyCorrectAccount]: VerifyCorrectAccountStep
}

const getStep = <StepObject extends Fields<Partial<StepObject>, StepObject>>(
    name: VolunteerLinkingSteps,
    content: (props: StepProps<StepObject>) => JSX.Element,
    stepObject: StepObject,
    options?: {
        submitButtonEmbedded?: boolean,
        disableSubmitUntilValid?: boolean,
        hideBackButton?: boolean
    }
) => {
    return new Step(
        name,
        content,
        stepObject,
        options?.submitButtonEmbedded,
        options?.disableSubmitUntilValid,
        options?.hideBackButton
    );
}

const getStepFromType = <StepObject extends Fields<Partial<StepObject>, StepObject>>(stepType: VolunteerLinkingSteps, stepObject: RegistrationFormStepObject) => {
    const stepComponent = stepComponentDictionary[stepType];
    switch (stepType) {
        // Steps that have an embedded submit button:
        case VolunteerLinkingSteps.LoginOrSignUp:
        case VolunteerLinkingSteps.InvalidUserType:
        case VolunteerLinkingSteps.VerifyCorrectAccount:
            return getStep(stepType, stepComponent, stepObject, { submitButtonEmbedded: true });
        // Steps that don't have an embedded submit button and 
        // that don't disable the submit button until valid:
        default:
            return getStep(stepType, stepComponent, stepObject);
    }
}

const getStepOptions = (stepObject: RegistrationFormStepObject) => {
    let allSteps = {} as { [key in VolunteerLinkingSteps]: Step<RegistrationFormStepObject> | undefined };
    Object.values(VolunteerLinkingSteps).forEach(stepType => { allSteps[stepType as VolunteerLinkingSteps] = getStepFromType(stepType, stepObject); });
    return allSteps;
}

const useStandaloneOrgRegistrationSteps = (stepObject: RegistrationFormStepObject) => {

    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;

    const [stepOptions, setStepOptions] = useState(getStepOptions(stepObject));

    useEffect(() => {
        setStepOptions(getStepOptions(stepObject));
    }, [stepObject]);

    const getSteps = () => {

        let steps: Step<any>[] = [];

        if (userStore.isAuthenticated) {
            if (!userStore.user.isVolunteer) {
                steps.push(stepOptions.invalidUserType!);
            } else {
                steps.push(stepOptions.verifyCorrectAccount!);
            }
        } else {
            steps.push(stepOptions.loginOrSignUp!);
            if (stepObject.creatingNewUser) {
                steps.push(stepOptions.registration!);
            }
        }

        return steps;
    }

    const [steps, setSteps] = useState(getSteps());

    useEffect(() => {
        setSteps(getSteps());
    }, [
        stepObject.creatingNewUser,
        stepOptions,
        userStore.user
    ]);

    return steps;
}

export default useStandaloneOrgRegistrationSteps;