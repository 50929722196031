import { Typography, makeStyles, Theme, createStyles, Card, CardContent, IconButton, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react";
import { ArrowTopRight } from "mdi-material-ui";
import clsx from "clsx";
import { useNavigateInternally } from "../../../components/Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'relative',
        },
        cardTitle: {
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        linkIcon: {
            marginLeft: '2.5rem',
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: theme.palette.action.active
        },
        flex: {
            display: 'flex',
        },
        columnFlex: {
            flexDirection: 'column'
        },
        center: {
            textAlign: 'center'
        }
    }),
);

interface ActionCardWithFooterProps {
    title: string;
    content: JSX.Element;
    footer: JSX.Element;
    arrowLink?: string;
}

const ActionCardWithFooter = observer((props: ActionCardWithFooterProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const navigate = useNavigateInternally();

    /********* Event handlers *********/

    const followLink = (link: string) => {
        navigate(link);
    }

    /********* Render *********/

    const linkIcon = (link?: string) => (
        <IconButton className={classes.linkIcon} onClick={link ? () => followLink(link) : undefined}>
            <ArrowTopRight fontSize="small" />
        </IconButton>
    )

    return (
        <Card className={classes.card}>
            <CardContent className={clsx(classes.flex, classes.columnFlex)}>
                <Typography variant="h6" className={clsx(xsDown && props.arrowLink === undefined ? classes.center : classes.cardTitle)}>
                    {props.title}
                </Typography>
                {props.arrowLink ? linkIcon(props.arrowLink) : null}
                {props.content}
                {props.footer}
            </CardContent>
        </Card>
    )
});

export default ActionCardWithFooter;