import React, { useContext, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles, Container, Paper, Grid, Typography, Button } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { RootContext } from "../../../stores";
import { observer } from "mobx-react";
import LoadingIndicator from "../../Shared/LoadingIndicator";
import { KeyVariant } from "mdi-material-ui";
import { format } from "date-fns";
import PasswordResetForm from "../../Authentication/PasswordResetForm";
import { PasswordResetData } from "../../../stores/models/PasswordResetData";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line'
        },
        subsection: {
            marginTop: theme.spacing(2),
        },
        detail: {
            marginBottom: theme.spacing(2)
        },
        field: {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        buttons: {
            margin: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-end',
            color: theme.palette.action.active,
            '& > :first-child': {
                marginRight: theme.spacing(1)
            },
        },
        topSpacing: {
            marginTop: theme.spacing(1)
        },
        flex: {
            display: 'flex',
            flexGrow: 1
        },
        formWrapper: {
            marginLeft: theme.spacing(1) * -1,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxWidth: '40ch'
        },
        success: {
            maxWidth: '40ch',
            marginTop: theme.spacing(1)
        },
        alert: {
            margin: theme.spacing(1),
            flexGrow: 1,
        },
        bold: {
            fontWeight: 'bold'
        }
    })
);

const Credentials = observer(() => {

    /********* React hooks *********/
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;

    /********* Helper method *********/

    const getPasswordResetData = () => {
        const userId = userStore.user.id;
        return new PasswordResetData(userId, true);
    }

    /********* State *********/
    const [isLoading, setIsLoading] = useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [passwordResetData, setPasswordResetData] = useState(getPasswordResetData());
    const [validationRun, setValidationRun] = useState(false);
    const [error, setError] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    /********* Effects *********/

    useEffect(() => {
        if (!editMode) {
            reset();
        } else {
            setSuccessMessage(undefined);
        }
    }, [editMode]);

    /********* Event handlers *********/

    const reset = () => {
        setIsLoading(false);
        setPasswordResetData(getPasswordResetData());
        setValidationRun(false);
    }

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const onSaveClicked = async () => {
        if (passwordResetData.validated) {
            setIsLoading(true);
            setSuccessMessage(undefined);
            setError(undefined);
            const response = await userStore.resetPassword(passwordResetData);
            if (response) {
                if ('warning' in response) {
                    setError(response.warning.message);
                } else {
                    setSuccessMessage('Your password has been updated.');
                    setEditMode(false);
                }
            } else {
                setError('An error occurred while processing your request. Please try again.');
            }
            setIsLoading(false);
        } else {
            passwordResetData.setAllFieldsDirty();
            setValidationRun(true);
        }
    }

    /********* Helper constants *********/

    const user = userStore.user;

    /********* Render *********/

    if (isLoading) {
        return <LoadingIndicator />;
    } else {
        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Container>
                                <div id="user-profile" className={classes.root}>
                                    <Typography variant="h4">Settings</Typography>
                                    <div className={classes.subsection}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div>
                                                    <Typography>
                                                        <span className={classes.bold}>Account Created: </span>
                                                        {userStore.user.registrationDate
                                                            ? format(userStore.user.registrationDate, 'MM/dd/yy')
                                                            : ''
                                                        }
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography className={classes.field}>
                                                        <span className={classes.bold}>Email: </span>{user.email}
                                                    </Typography>
                                                </div>
                                                <div className={classes.detail}>
                                                    {successMessage &&
                                                        <Alert severity="success" className={classes.success}>{successMessage}</Alert>
                                                    }
                                                    {!editMode
                                                        ? <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={toggleEditMode}
                                                            className={classes.topSpacing}
                                                            startIcon={<KeyVariant />}
                                                        >
                                                            Change Password
                                                        </Button>
                                                        : <div>
                                                            <Typography><span className={classes.bold}>Password:</span></Typography>
                                                            <div className={classes.flex}>
                                                                <div className={classes.formWrapper}>
                                                                    {error &&
                                                                        <Alert severity="error" className={classes.alert}>{error}</Alert>
                                                                    }
                                                                    <PasswordResetForm
                                                                        passwordResetData={passwordResetData}
                                                                        validated={validationRun}
                                                                    />
                                                                    <div className={classes.buttons}>
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                            onClick={onSaveClicked}
                                                                            startIcon={<SaveIcon />}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                        <Button color="inherit" onClick={toggleEditMode}>
                                                                            Cancel
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div >
                            </Container>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }
});

export default Credentials;