import Checkbox from "@material-ui/core/Checkbox";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import { useMemo } from "react";
import { ObjectContainingIdentifier, OptionCollection } from "../../../../../stores/models/OptionCollection";
import { IRole } from "../../../../../stores/models/Role";
import { IStatus } from "../../../../../stores/models/Status";
import { NestedMenuItems } from "../../../../Navigation/NestedMenuItem";

interface FiltersProps {
    roleOptions: OptionCollection<'id', ObjectContainingIdentifier<"id">, IRole>;
    statusOptions: OptionCollection<'id', ObjectContainingIdentifier<"id">, IStatus>;
}

const StandardMenuItem = (props: MenuItemProps & { button?: true }) => { return (<MenuItem {...props} />) };
const CheckboxMenuItem = (props: MenuItemProps & { button?: true }, menuItem: NestedMenuItems<any>) => {
    return (
        <MenuItem {...props}>
            <Checkbox
                size="small"
                color="primary"
                checked={menuItem.option?.selected}
                style={{ padding: '0px 8px 0px 0px' }}
            />
            {props.children}
        </MenuItem>
    )
};

export default function useVolunteerFilters(props: FiltersProps) {

    const filterMenuItems = useMemo(() => {
        return [{
            label: 'Status',
            MenuItem: StandardMenuItem,
            subItems: props.statusOptions.options.map((option) => {
                return {
                    label: option.object.status,
                    option: option,
                    MenuItem: CheckboxMenuItem
                }
            })
        }, {
            label: 'Role',
            MenuItem: StandardMenuItem,
            subItems: props.roleOptions.options.map((option) => {
                return {
                    label: option.object.position,
                    option: option,
                    MenuItem: CheckboxMenuItem
                }
            })
        }];
    }, [props.roleOptions, props.statusOptions]);

    return filterMenuItems;

}