import { useEffect } from "react";
import { observer } from "mobx-react";
import { StepProps } from "../../../../stores/models/Step";
import FullVolunteerSignUp from "../../RegistrationSteps/Volunteer/FullVolunteerSignUp";
import { StubUser } from "../../../../stores/models/StubUser";
import { UserType } from "../../../../stores";
import { Fields } from "../../../../stores/models/Fields";

const CreateVolunteerAccountStep = observer(<T extends Fields<Partial<T>, T> & { stubUser?: StubUser }>(props: StepProps<T>) => {

    const stepObject = props.stepState.stepObject;

    /***** Effects *****/

    useEffect(() => {
        props.stepState.setValidationMethod(validateNewVolunteer);
    }, []);

    /***** Helper methods *****/

    const validateNewVolunteer = () => {
        if (stepObject.stubUser && stepObject.stubUser.stubVolunteer) {
            stepObject.stubUser.setAllFieldsDirty();
            stepObject.stubUser.credentials.setAllFieldsDirty();
            stepObject.stubUser.stubVolunteer.setAllFieldsDirty();
            return stepObject.stubUser.validated;
        }
        return false;
    }

    /***** Render *****/

    return (
        <FullVolunteerSignUp
            stubUser={stepObject.stubUser || new StubUser(UserType.Volunteer)}
            validationRun={props.stepState.validationRun}
        />
    );
});

export default CreateVolunteerAccountStep;