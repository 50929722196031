import { TextField, makeStyles, Theme, createStyles, Typography, useMediaQuery, Grid, Button, Divider } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { RootContext } from "../../stores";
import { DialogState } from "../../stores/models/DialogState";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import { LoginData } from "../../stores/models/LoginData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkWrapper: {
            marginTop: theme.spacing(2),
            color: theme.palette.text.secondary,
            textAlign: 'center'
        },
        forgottenPassword: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            textAlign: 'center'
        },
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        field: {
            display: 'flex',
            flexGrow: 1,
        },
        loginButton: {
            marginTop: theme.spacing(2)
        },
        signUpButton: {
            marginTop: theme.spacing(1),
            color: theme.palette.common.white,
            background: theme.palette.success.main,
            '&:hover': {
                background: theme.palette.success.main,
            }
        }
    }),
);

interface LoginDialogProps {
    dialogState: DialogState;
    onSwitchToSignUp: () => void;
}

const LoginDialog = observer((props: LoginDialogProps) => {

    /********** State **********/

    const [loginData, setLoginData] = useState(new LoginData());
    const [validationRun, setValidationRun] = useState(false);

    /********** React hooks **********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********** Effects **********/

    // Reset the dialog when it is first opened
    useEffect(() => {
        if (props.dialogState.open) {
            resetDialog();
        }
    }, [props.dialogState.open]);

    /********** Event handlers **********/

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        loginData.setUsername(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        loginData.setPassword(event.target.value);
    }

    const handleLogin = () => {
        attemptLogin();
    }

    const onForgotPassword = () => {
        props.dialogState.setOpen(false);
        rootStore.navigationStore.appwideDialogStates.forgotPasswordDialog.setOpen(true);
    }

    /********** Helper functions **********/

    const attemptLogin = async () => {
        props.dialogState.setLoading(true);
        await userStore.login(loginData);
        setValidationRun(true);
        props.dialogState.setLoading(false);
    }

    const resetDialog = () => {
        setLoginData(new LoginData());
        setValidationRun(false);
    }

    const emailFieldError = validationRun ? loginData.errors.username : undefined;
    const passwordFieldError = validationRun ? loginData.errors.password || loginData.error : undefined;

    /********** Render **********/

    return (
        <ThemedDialogWithSpinner
            title={'Login'}
            hideCancelButton
            showXButton
            state={props.dialogState}
            DialogProps={{ fullScreen: xsDown, maxWidth: 'xs' }}
            onSubmit={handleLogin}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="standard-username-input"
                        label="Email"
                        autoComplete="current-username"
                        variant="outlined"
                        required
                        value={loginData.username}
                        error={loginData.error !== undefined || (emailFieldError !== undefined && emailFieldError.length > 0)}
                        helperText={emailFieldError}
                        className={classes.field}
                        onChange={handleUsernameChange}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="standard-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        className={classes.field}
                        required
                        value={loginData.password}
                        error={passwordFieldError !== undefined && passwordFieldError.length > 0}
                        helperText={passwordFieldError}
                        onChange={handlePasswordChange}
                    />
                </Grid>
            </Grid>
            <Button color="primary" variant="contained" type="submit" fullWidth className={classes.loginButton} size="large">
                Login
            </Button>
            <div className={classes.forgottenPassword}>
                <Typography variant="body2" className={classes.link} onClick={onForgotPassword}>
                    Forgot your password?
                </Typography>
            </div>
            <Divider />
            <div className={classes.linkWrapper}>
                <Typography variant="body2">
                    {`Don't have an account yet? `}
                </Typography>
                <Button
                    color="inherit"
                    variant="contained"
                    fullWidth
                    className={classes.signUpButton}
                    size="large"
                    onClick={props.onSwitchToSignUp}
                >
                    Sign up
                </Button>
            </div>
        </ThemedDialogWithSpinner >
    );
});

export default LoginDialog;