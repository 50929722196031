import { List, makeStyles, Theme, createStyles, Divider } from "@material-ui/core";
import React from "react";
import MenuSidebarListItem from "./MenuSidebarListItem";
import { MenuItem } from "../../../stores/models/MenuItem";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            padding: 0,
        },
        link: {
            textDecoration: 'none'
        },
        divider: {
            background: theme.palette.darkBackground.contrastText,
            opacity: 0.3
        }
    }),
);

interface MenuSidebarListProps {
    menuItems: MenuItem[];
    nestLevel?: number;
    iconsOnly?: boolean;
}

const MenuSidebarList = observer((props: MenuSidebarListProps) => {

    const classes = useStyles();

    return (
        <List className={props.nestLevel && props.nestLevel > 0 ? classes.list : undefined}>
            {props.menuItems.map((menuItem, index) => {
                return (
                    <div key={`menu-item-${index}`}>
                        {props.iconsOnly && index !== 0 && menuItem.subItems && menuItem.subItems.length > 0
                            ? <Divider variant="middle" className={classes.divider} />
                            : null
                        }
                        <MenuSidebarListItem
                            menuItem={menuItem}
                            nestLevel={props.nestLevel}
                            iconOnly={props.iconsOnly}
                        />
                    </div>
                );
            })}
        </List>
    );
});

export default MenuSidebarList;