
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@material-ui/core";
import clsx from "clsx";
import { ForwardedRef, forwardRef } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outlinedSizeLarge: {
            padding: '0.4375rem 1.3125rem'
        },
        containedSizeLarge: {
            padding: '0.4375rem 1.3125rem'
        }
    })
);

interface ButtonProps extends MUIButtonProps {

}

const REMButton = observer(forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIButton
            {...props}
            ref={ref}
            classes={{
                ...props.classes,
                outlinedSizeLarge: clsx(classes.outlinedSizeLarge, props.classes?.outlinedSizeLarge),
                containedSizeLarge: clsx(classes.containedSizeLarge, props.classes?.containedSizeLarge),
            }}
        >
            {props.children}
        </MUIButton>
    );
}));

export default REMButton;