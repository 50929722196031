import {
    observable,
    action,
    computed,
    IReactionDisposer,
    reaction,
    makeObservable,
} from "mobx";
import { Skill } from ".";
import { AddressCollection } from "./Addressable";
import validateOpportunity from "../../logic/ValidationChecks/OpportunityValidation";
import { add } from "date-fns";
import { ShiftCollection } from "./ShiftCollection";
import { DateFormatter } from "../../logic/DateFormatter";
import { Fields } from "./Fields";
import { ServerOpportunity } from './server/ServerOpportunity';
import { OptionCollection } from "./OptionCollection";
import { opportunityDetailsFilterOptions, OpportunityDetailsOptions } from "../../components/Search/FilterBar/FilterOptions";
import { FilterData } from "./FilterData";
import { IOptionObject } from "./Option";
import { ServiceEntryTag } from "./ServiceEntryTag";
import { Form } from "./Form";

export enum TimeCommitment {
    Unspecified = "unspecified",
    ShortTerm = "No Strings Attached",
    LongTerm = "In It for the Long Haul"
}

export enum OpportunityNeed {
    Unspecified = "unspecified",
    OneTime = "One Time",
    Ongoing = "Ongoing"
}

export enum OpportunityLocation {
    Unspecified = 'unspecified',
    Remote = 'Remote',
    InPerson = 'In Person'
}

export enum OpportunityScheduling {
    Unspecified = "unspecified",
    Flexible = "Flexible Scheduling",
    Shifts = "Shifts"
}

export enum OpportunityPermissionType {
    Unspecified = 'unspecified',
    Open = 'None',
    Organization = 'Organization Level',
    Opportunity = 'Opportunity Level',
    AssignmentOnly = 'Assignment Only'
}

export interface IOpportunityFields {
    active: boolean;
    title: string;
    position: string;
    description: string;
    trainingDescription: string;
    need: OpportunityNeed;
    timeCommitment: TimeCommitment;
    scheduling: OpportunityScheduling;
    permissionType: OpportunityPermissionType;
    virtual: OpportunityLocation;
    addressCollection: AddressCollection;
    expirationTimestamp: Date | null;
    filterData: FilterData;
    serviceEntryTags: ServiceEntryTag[];
}

export interface IOpportunity extends IOpportunityFields {
    id: number;
    organizationId: number;
    organizationName: string;
    skills: Skill[];
    shiftCollection: ShiftCollection;
    interestSparked: boolean;
    responseSubmitted: boolean;
    hasPermission: boolean;
    registration?: { completed: boolean, form?: Form };
}

export class Opportunity extends Fields<IOpportunityFields, Opportunity> implements IOpportunity {
    @observable id = -1;
    @observable organizationId = -1;
    @observable organizationName = "";
    @observable active = true;
    @observable title: string;
    @observable position = "";
    @observable description = "";
    @observable trainingDescription = "";
    @observable expirationTimestamp: Date | null = new Date(add(new Date(), { months: 3 })); // Default is 3 months from now
    @observable need = OpportunityNeed.Unspecified;
    @observable timeCommitment = TimeCommitment.Unspecified;
    @observable scheduling = OpportunityScheduling.Unspecified;
    @observable permissionType = OpportunityPermissionType.Unspecified;
    @observable virtual = OpportunityLocation.Unspecified;
    @observable skills = [] as Skill[];
    @observable shiftCollection: ShiftCollection;
    @observable addressCollection: AddressCollection;
    @observable interestSparked: boolean = false;
    @observable responseSubmitted: boolean = false;
    @observable hasPermission: boolean = false;
    @observable filterData = new FilterData();
    @observable serviceEntryTags = [] as ServiceEntryTag[];
    @observable registration?: { completed: boolean, form?: Form };

    private filtersChangedReaction: IReactionDisposer | undefined;

    constructor(id?: number);
    constructor(opportunity?: IOpportunity);

    constructor(idOrOpportunity?: number | IOpportunity) {
        super();

        makeObservable(this);

        if (idOrOpportunity === undefined || typeof idOrOpportunity === 'number') {
            this.id = idOrOpportunity !== undefined ? idOrOpportunity : -1;
            this.title = "";
            this.shiftCollection = new ShiftCollection();
            this.addressCollection = new AddressCollection();
        } else {
            this.id = idOrOpportunity.id;
            this.organizationId = idOrOpportunity.organizationId;
            this.organizationName = idOrOpportunity.organizationName;
            this.active = idOrOpportunity.active;
            this.title = idOrOpportunity.title;
            this.position = idOrOpportunity.position;
            this.description = idOrOpportunity.description;
            this.trainingDescription = idOrOpportunity.trainingDescription;
            this.expirationTimestamp = idOrOpportunity.expirationTimestamp;
            this.need = idOrOpportunity.need;
            this.scheduling = idOrOpportunity.scheduling;
            this.permissionType = idOrOpportunity.permissionType;
            this.timeCommitment = idOrOpportunity.timeCommitment;
            this.virtual = idOrOpportunity.virtual;
            this.skills = idOrOpportunity.skills || [];
            this.shiftCollection = new ShiftCollection(idOrOpportunity.shiftCollection.shifts) || new ShiftCollection();
            this.addressCollection = new AddressCollection(idOrOpportunity.addressCollection.addresses) || new AddressCollection();
            this.interestSparked = idOrOpportunity.interestSparked || false;
            this.responseSubmitted = idOrOpportunity.responseSubmitted || false;
            this.hasPermission = idOrOpportunity.hasPermission || false;
            this.filterData = idOrOpportunity.filterData || new FilterData();
            this.filtersChangedReaction = reaction(
                () => [
                    this.filterOptions.selections
                ],
                () => {
                    this.setFieldsDirty(['filterData']);
                    this.updateFilterData();
                }
            );
            this.serviceEntryTags = idOrOpportunity.serviceEntryTags;
            this.registration = idOrOpportunity.registration;
        }
    }

    /***** Setters *****/

    @action setActive(active: boolean) {
        this.active = active;
    }

    @action setTitle(title: string) {
        this.title = title;
    }

    @action setPosition(position: string) {
        this.position = position;
    }

    @action setDescription(description: string) {
        this.description = description;
    }

    @action setTrainingDescription(trainingDescription: string) {
        this.trainingDescription = trainingDescription;
    }

    @action setNeed(need: OpportunityNeed) {
        this.need = need;
    }

    @action setTimeCommitment(timeCommitment: TimeCommitment) {
        this.timeCommitment = timeCommitment;
    }

    @action setVirtual(virtual: OpportunityLocation) {
        this.virtual = virtual;
    }

    @action setScheduling(scheduling: OpportunityScheduling) {
        this.scheduling = scheduling;
    }

    @action setPermissionType(permissionType: OpportunityPermissionType) {
        this.permissionType = permissionType;
    }

    @action setSkills(skills: Skill[]) {
        this.skills = skills;
    }

    @action setServiceEntryTags(serviceEntryTags: ServiceEntryTag[]) {
        this.serviceEntryTags = serviceEntryTags;
    }

    @action setExpirationDate(expirationTimestamp: Date | null) {
        this.expirationTimestamp = expirationTimestamp;
    }

    @action setInterestSparked(interestSparked: boolean) {
        this.interestSparked = interestSparked;
    }

    @action setResponseSubmitted() {
        this.responseSubmitted = true;
    }

    @action setRegistrationCompleted() {
        if (this.registration) {
            this.registration.completed = true;
        }
    }

    /***** Public methods *****/

    removeSkill(skillId: number) {
        const remainingSkills = this.skills.filter((skill) => {
            return skill.id !== skillId;
        });
        this.setSkills(remainingSkills);
    }

    @action resetForm() {
        if (this.registration && this.registration.form) {
            this.registration.form = new Form(this.registration.form);
        }
    }

    serialize() {
        return new ServerOpportunity(this.trimmed);
    }

    /***** Private methods *****/

    @action.bound updateFilterData() {
        this.filterData.childcareAvailable = this.filterOptions.isOptionSelected(OpportunityDetailsOptions.Childcare);
        this.filterData.familyOpportunity = this.filterOptions.isOptionSelected(OpportunityDetailsOptions.Families);
        this.filterData.groupOpportunity = this.filterOptions.isOptionSelected(OpportunityDetailsOptions.Groups);
        this.filterData.shortStandingTime = this.filterOptions.isOptionSelected(OpportunityDetailsOptions.ShortStanding);
        this.filterData.noHeavyLifting = this.filterOptions.isOptionSelected(OpportunityDetailsOptions.NoHeavyLifting);
        this.filterData.courtOrdered = this.filterOptions.isOptionSelected(OpportunityDetailsOptions.CourtMandated);
    }

    /***** Computed properties *****/

    @computed get validationErrors() {
        return validateOpportunity(this);
    }

    @computed get expired() {
        return this.expirationTimestamp
            && this.expirationTimestamp < DateFormatter.getBeginningOfToday();
    }

    @computed get status() {
        if (!this.active) {
            return 'Inactive';
        } else if (this.expired) {
            return 'Expired';
        } else {
            return 'Active';
        }
    }

    @computed get skillsList() {
        let list = [] as string[];
        this.skills.forEach(skill => {
            list.push(skill.skill);
        });
        return list.join(', ');
    }

    @computed get selectedFilters(): IOptionObject[] {
        let selected = [] as IOptionObject[];
        if (this.filterData.childcareAvailable) {
            selected.push({ value: OpportunityDetailsOptions.Childcare });
        }
        if (this.filterData.familyOpportunity) {
            selected.push({ value: OpportunityDetailsOptions.Families });
        }
        if (this.filterData.groupOpportunity) {
            selected.push({ value: OpportunityDetailsOptions.Groups });
        }
        if (this.filterData.shortStandingTime) {
            selected.push({ value: OpportunityDetailsOptions.ShortStanding });
        }
        if (this.filterData.noHeavyLifting) {
            selected.push({ value: OpportunityDetailsOptions.NoHeavyLifting });
        }
        if (this.filterData.courtOrdered) {
            selected.push({ value: OpportunityDetailsOptions.CourtMandated });
        }
        return selected;
    }

    @computed get filterOptions() {
        return new OptionCollection('value', opportunityDetailsFilterOptions, this.selectedFilters);
    }
}