import React, { useState } from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography, Avatar, Button } from "@material-ui/core";
import { AccountCheck, Check } from "mdi-material-ui";
import RadioList, { RadioListOption } from "../../Organization/VolunteerOpportunities/RadioList";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginBottom: theme.spacing(1),
            maxWidth: 800,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
        accountInformation: {
            margin: theme.spacing(2, 0, 3)
        },
        radioListWrapper: {
            '& > ul': {
                display: 'flex',
                justifyContent: 'center'
            }
        },
    })
);


enum YesNoUnselected {
    Yes = 'Yes',
    No = 'No',
    Unselected = ''
}

const options: RadioListOption[] = [{
    primaryText: `Yes, this is the correct account.`,
    value: YesNoUnselected.Yes,
    icon: Check
}, {
    primaryText: `No, I need to switch accounts.`,
    value: YesNoUnselected.No,
    icon: Close
}];

interface VerifyCorrectAccountProps {
    email: string;
    usersName: string;
    prompt: string;
    onConfirmed: () => void;
    onLogoutClicked: () => void;
}

const VerifyCorrectAccount = observer((props: VerifyCorrectAccountProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* State *********/

    const [selectedOption, setSelectedOption] = useState(YesNoUnselected.Unselected);

    const onOptionChanged = (value: YesNoUnselected) => {
        setSelectedOption(value);
    }

    /********* Render *********/

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <AccountCheck fontSize='large' />
            </Avatar>
            <Typography variant="h4">
                You're currently logged in as:
            </Typography>
            <div className={classes.accountInformation}>
                <Typography variant="h6">
                    {props.usersName}
                </Typography>
                <Typography variant="h6">
                    {props.email}
                </Typography>
            </div>
            <Typography variant="h5" className={classes.prompt}>
                {props.prompt}
            </Typography>
            <div className={classes.radioListWrapper}>
                <RadioList
                    selectedValue={selectedOption}
                    options={options}
                    onValueChanged={(value) => onOptionChanged(value as YesNoUnselected)}
                />
            </div>
            {selectedOption !== YesNoUnselected.No
                ? <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onConfirmed}
                    disabled={selectedOption !== YesNoUnselected.Yes}
                >
                    Confirm
                </Button>
                : <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onLogoutClicked}
                >
                    Logout
                </Button>
            }
        </React.Fragment>
    );
});

export default VerifyCorrectAccount;