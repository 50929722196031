import { action, computed, makeObservable, observable } from "mobx";
import React from "react";

export interface ITableHeaderCell {
    id: string;
    label: string;
    cellContent?: React.ReactNode;
    alignment: 'left' | 'center' | 'right';
    disablePadding: boolean;
    sortable: boolean;
    direction?: 'asc' | 'desc';
}

export interface ISortableTableHeader<RecordType> {
    id: string;
    sortValue?: keyof RecordType | ((record: RecordType) => any);
    label: string;
    checkbox?: boolean;
    alignment: 'left' | 'center' | 'right';
    disablePadding: boolean;
    sortable: boolean;
    direction?: 'asc' | 'desc';
}

export class TableHeader<RecordType> implements ISortableTableHeader<RecordType> {

    @observable id: string;
    @observable sortValue?: keyof RecordType | ((record: RecordType) => any);
    @observable label: string;
    @observable checkbox?: boolean;
    @observable alignment: 'left' | 'center' | 'right';
    @observable disablePadding: boolean;
    @observable sortable: boolean;
    @observable direction?: 'asc' | 'desc';

    constructor(tableHeaderCell: ISortableTableHeader<RecordType>) {
        makeObservable(this);

        this.id = tableHeaderCell.id;
        this.sortValue = tableHeaderCell.sortValue;
        this.label = tableHeaderCell.label;
        this.checkbox = tableHeaderCell.checkbox;
        this.alignment = tableHeaderCell.alignment;
        this.disablePadding = tableHeaderCell.disablePadding;
        this.sortable = tableHeaderCell.sortable;
        this.direction = tableHeaderCell.direction;
    }

    @computed get active() {
        return this.direction !== undefined;
    }

    @action rotateDirection() {
        switch (this.direction) {
            case 'asc':
                this.direction = 'desc';
                break;
            case 'desc':
                this.direction = undefined;
                break;
            default:
                this.direction = 'asc';
                break;
        }
    }

    @action setDirection(direction?: 'asc' | 'desc') {
        this.direction = direction;
    }
}
