import { observable, action, computed, makeObservable } from "mobx";
import { UserType } from "../../../../../stores";
import { Fields } from "../../../../../stores/models/Fields";
import { StubUser } from "../../../../../stores/models/StubUser";
import { ValidationError } from "../../../../../stores/models/ValidationError";

export interface IRegistrationFormStepObject {
    creatingNewUser: boolean;
    stubUser?: StubUser;
}

export class RegistrationFormStepObject extends Fields<IRegistrationFormStepObject, RegistrationFormStepObject> implements IRegistrationFormStepObject {
    @observable creatingNewUser = false;
    @observable stubUser = new StubUser(UserType.Volunteer);

    constructor() {
        super();
        makeObservable(this);
    }

    /***** Setters *****/

    @action setCreatingNewUser(creatingNewUser: boolean) {
        this.creatingNewUser = creatingNewUser;
    }

    /***** Computed properties *****/

    @computed get validationErrors() {
        return (this.stubUser?.validationErrors || []) as unknown as ValidationError<RegistrationFormStepObject>[];
    }

}