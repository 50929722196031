import { Fragment, useContext, useEffect, useState } from "react";
import { Typography, Paper, Container, makeStyles, Theme, createStyles, InputAdornment, CircularProgress } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from "mobx-react";
import { RootContext } from "../../../../../stores";
import { EditorState } from "../../../../../stores/models/EditorState";
import { getCustomPortalPath, getCustomPortalUrl } from "../../../../Navigation/Links";
import FormError from "../../../../Organization/VolunteerOpportunities/FormError";
import LoadingIndicator from "../../../../Shared/LoadingIndicator";
import Editor, { Action } from "../../../Editor";
import EditorHeaderButtons from "../EditorHeaderButtons";
import FieldHeader from "../../../../Shared/FieldHeader";
import FieldSection from "../FieldSection";
import ImageUploader from "./ImageUploader";
import { useIsOrgPortal, useMatchCurrentPath, useNavigateInternally, useOrgPortalSlug, useValidatedOrgPortalLogoSrc } from "../../../../Navigation/Hooks";
import { PortalForm } from "./PortalFormFields";
import { CUSTOM_PORTAL_SLUG_MAX_LENGTH } from "../../../../../logic/ValidationChecks/FieldLengths";
import { Alert, AlertTitle } from "@material-ui/lab";
import DebouncedInputField from "../../../../Shared/DebouncedInputField";
import { CancellablePromise } from "mobx/dist/internal";
import { Error } from "@material-ui/icons";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            overflow: 'hidden'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        title: {
            wordBreak: 'normal',
            textOverflow: 'ellipsis',
            overflowX: 'hidden'
        },
        floatingButton: {
            background: theme.palette.darkBackground.main,
            color: theme.palette.darkBackground.contrastText,
            '&:hover': {
                background: theme.palette.darkBackground.light,
            },
            '& svg': {
                marginRight: theme.spacing(1),
            }
        },
        flex: {
            display: 'flex',
        },
        logo: {
            maxHeight: '100px',
            maxWidth: '100%',
        },
        logoWrapper: {
            marginTop: theme.spacing(1)
        },
        textField: {
            marginLeft: theme.spacing(1)
        },
        urlFrontEditing: {
            marginTop: '19px'
        },
        urlFrontNotEditing: {
            marginTop: '3px'
        },
        warning: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        error: {
            color: theme.palette.error.main
        },
        checkIcon: {
            color: theme.palette.primary.main
        },
    }),
);

const CustomPortalConfiguration = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const { userStore, organizationStore, imageStore } = rootStore;
    const isOrgPortal = useIsOrgPortal();
    const urlPortalSlug = useOrgPortalSlug();
    const { portalLogoSrc, loading: logoUrlLoading } = useValidatedOrgPortalLogoSrc(userStore.user.organization?.portalSlug);
    const navigate = useNavigateInternally();
    const routeMatch = useMatchCurrentPath();

    /********* State *********/

    const [formHasErrors, setFormHasErrors] = useState(false);
    const [draft, setDraft] = useState(new PortalForm({ portalSlug: userStore.user.organization?.portalSlug }))
    const [slugUniquenessPromise, setSlugUniquenessPromise] = useState<CancellablePromise<boolean>>();
    const [slugFieldLoading, setSlugFieldLoading] = useState(false);
    const [slugFieldUnique, setSlugFieldUnique] = useState<boolean>();
    const [lastCheckedSlug, setLastCheckedSlug] = useState<string>();

    /********* Effects *********/

    useEffect(() => {
        if (draft.portalSlug) {
            setSlugFieldUnique(true);
            setLastCheckedSlug(draft.portalSlug);
        }
    }, []);

    useEffect(() => {
        if (portalLogoSrc) {
            draft.setLogoExists(true);
        }
    }, [portalLogoSrc, draft]);

    useEffect(() => {
        resetFields();
    }, [userStore.user.profileData, userStore.user.organization?.portalSlug]);

    useEffect(() => {
        const organizationSlug = userStore.user.organization?.portalSlug;
        if (isOrgPortal && urlPortalSlug && organizationSlug && urlPortalSlug !== organizationSlug) {
            onPortalSlugChanged(urlPortalSlug, organizationSlug);
        }
    }, [userStore.user.organization?.portalSlug]);

    /********* Event handlers *********/

    const slugFieldInValidState = !slugFieldLoading && slugFieldUnique && lastCheckedSlug === draft.portalSlug;

    const handleSave = async (editorState?: EditorState) => {
        if (draft.validated && slugFieldInValidState) {
            setFormHasErrors(false);
            editorState?.setLoading(true);
            await saveDraft();
            reloadPortalLogo();
            resetFields();
            scrollToTopOfForm();
            editorState?.setLoading(false);
            editorState?.setEditing(false);
        } else {
            setFormHasErrors(true);
            scrollToTopOfForm();
            draft.setAllFieldsDirty();
        }
    }

    const onLogoSelected = (logo: File) => {
        draft.setLogo(logo);
    }

    const onPortalSlugChanged = (oldSlug: string, newSlug: string) => {
        // Give the Editor a chance to leave edit mode before redirecting 
        // to avoid triggering the unsaved changes warning
        setTimeout(() => {
            redirectToNewPortal(newSlug);
        }, 0);
    }

    /********* Helper methods *********/

    const reloadPortalLogo = () => {
        if (portalLogoSrc) {
            imageStore.resetCacheDateForImage(portalLogoSrc);
        }
    }

    const redirectToNewPortal = (newPortalSlug: string) => {
        const path = routeMatch('/*')?.pathname;
        if (path) {
            navigate(path, { replace: true }, newPortalSlug);
        }
    }

    const resetFields = () => {
        setFormHasErrors(false);
        if (userStore.user.organization) {
            setDraft(new PortalForm({ portalSlug: userStore.user.organization?.portalSlug }));
        }
    }

    const saveDraft = async () => {
        if (!userStore.user.organization) return;
        await organizationStore.patchPortal(userStore.user.organization, draft.trimmed);
    }

    const shouldDisplayFormError = (editorState: EditorState) => {
        return editorState.editing && formHasErrors;
    }

    const scrollToTopOfForm = () => {
        window.scroll({ top: 0 });
    }

    const hasDraftBeenEdited = () => {
        return draft.isDirty;
    }

    const verifyUniqueSlug = () => {
        const slugToVerify = draft.portalSlug;
        if (!slugToVerify) return;
        cancelCurrentUniquenessCheck();
        if (draft.isFieldInvalid('portalSlug')) return;
        setSlugFieldLoading(true);
        const promise = organizationStore.isPortalSlugUnique(slugToVerify);
        setSlugUniquenessPromise(promise);
        return promise.then(unique => {
            setSlugFieldUnique(unique);
            setLastCheckedSlug(slugToVerify);
            setSlugFieldLoading(false);
        });
    }

    const cancelCurrentUniquenessCheck = () => {
        if (slugUniquenessPromise) {
            slugUniquenessPromise.cancel();
        }
    }

    /********* Actions *********/

    const getActions = (editorState: EditorState): Action[] => {
        if (editorState.editing) {
            return [{
                label: 'Save',
                onClick: (editorState) => handleSave(editorState),
                icon: SaveIcon,
                className: classes.floatingButton
            }, {
                label: 'Cancel',
                togglesEditMode: true,
                icon: CloseIcon,
                onClick: resetFields,
                className: classes.floatingButton
            }];
        } else {
            return [{
                label: 'Edit',
                togglesEditMode: true,
                icon: EditIcon,
                className: classes.floatingButton
            }];
        }
    };

    const linkToViewOrgPortal = isOrgPortal
        ? undefined
        : userStore.user.organization?.portalSlug
            ? "/" + getCustomPortalPath(userStore.user.organization!.portalSlug)
            : undefined;

    const startInEditMode = draft.portalSlug === undefined || draft.portalSlug.length === 0;

    /********* Render *********/

    return (
        <Editor actions={getActions} editsMade={hasDraftBeenEdited()} editing={startInEditMode}>
            {(editorState: EditorState) => (
                <Container>
                    <Paper className={classes.paper}>
                        <Container>
                            {shouldDisplayFormError(editorState) ? <FormError /> : null}
                            <FieldSection>
                                <div className={classes.header}>
                                    <Typography variant="h4" className={classes.title}>
                                        Portal
                                    </Typography>
                                    <EditorHeaderButtons
                                        editing={editorState.editing}
                                        viewLink={linkToViewOrgPortal}
                                        viewTooltip={'View Custom Portal'}
                                        onEdit={() => editorState.setEditing(true)}
                                        onSave={() => handleSave(editorState)}
                                    />
                                </div>
                                <Typography>Customize your organization-specific portal.</Typography>
                            </FieldSection>
                            {/* Portal URL */}
                            <FieldSection>
                                <FieldHeader title="URL" required={editorState.editing} />
                                {editorState.editing && userStore.user.organization?.portalSlug !== null && userStore.user.organization?.portalSlug !== undefined
                                    ? <Alert severity="warning" className={classes.warning}>
                                        <AlertTitle>Proceed with Caution</AlertTitle>
                                        Changing your portal's URL will break any existing links you or others have pointing to it.
                                    </Alert>
                                    : null
                                }
                                {editorState.editing && slugFieldUnique === false
                                    ? <Alert severity="error" className={classes.warning}>
                                        <AlertTitle>This URL is already in use.</AlertTitle>
                                        Please enter a different value.
                                    </Alert>
                                    : null
                                }
                                <div className={classes.flex}>
                                    {editorState.editing || draft?.portalSlug
                                        ? <span className={editorState.editing ? classes.urlFrontEditing : classes.urlFrontNotEditing}>
                                            {getCustomPortalUrl('')}
                                        </span>
                                        : null
                                    }
                                    <DebouncedInputField
                                        fieldName="slug"
                                        value={draft.portalSlug || ''}
                                        loading={slugFieldLoading}
                                        editMode={editorState.editing}
                                        characterLimit={CUSTOM_PORTAL_SLUG_MAX_LENGTH}
                                        onFieldEdited={(fieldName, event) => draft.setPortalSlug(event.target.value)}
                                        validityCheck={() => !draft.isFieldInvalid('portalSlug')}
                                        debouncedRequest={verifyUniqueSlug}
                                        TextFieldProps={{
                                            className: classes.textField,
                                            variant: 'outlined',
                                            error: draft.isFieldInvalid('portalSlug'),
                                            helperText: draft.getErrorForField('portalSlug') || null,
                                            InputProps: {
                                                endAdornment:
                                                    draft.portalSlug || lastCheckedSlug || draft.isFieldInvalid('portalSlug')
                                                        ? <InputAdornment position="end">
                                                            {draft.isFieldInvalid('portalSlug')
                                                                ? <Error className={classes.error} />
                                                                : slugFieldLoading || (draft.portalSlug && lastCheckedSlug !== draft.portalSlug)
                                                                    ? <CircularProgress size={20} />
                                                                    : slugFieldUnique === false
                                                                        ? <Error className={classes.error} />
                                                                        : <CheckCircleIcon className={classes.checkIcon} />
                                                            }
                                                        </InputAdornment>
                                                        : null
                                            }
                                        }}
                                        placeholderText="Edit to setup a custom URL"
                                    />
                                </div>
                            </FieldSection>
                            {/* Portal Logo */}
                            <FieldSection>
                                <FieldHeader title="Navigation Bar Logo" required={editorState.editing} />
                                {editorState.editing
                                    ? <Fragment>
                                        <ImageUploader
                                            imageClassname={classes.logo}
                                            onImageSelected={onLogoSelected}
                                            initialImageSrc={portalLogoSrc}
                                        >
                                            Upload Logo
                                        </ImageUploader>
                                        <Typography
                                            variant="caption"
                                            className={classes.error}
                                        >
                                            {draft.getErrorForField('logo')}
                                        </Typography>
                                    </Fragment>
                                    : portalLogoSrc
                                        ? <div className={classes.logoWrapper}>
                                            <img id="uploaded-logo" src={portalLogoSrc} className={classes.logo} />
                                        </div>
                                        : logoUrlLoading
                                            ? <LoadingIndicator leftAligned />
                                            : <Typography>Not Provided</Typography>
                                }
                            </FieldSection>
                        </Container>
                    </Paper>
                </Container >
            )}
        </Editor >
    )
});

export default CustomPortalConfiguration;