import React, { useContext, useState, useEffect } from "react";
import { observer } from 'mobx-react'
import { RootContext } from "../../stores";
import { makeStyles, Theme, createStyles, Container, Paper, Typography, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import PasswordResetForm from "./PasswordResetForm";
import { PasswordResetData } from "../../stores/models/PasswordResetData";
import FormLoadingSpinner from "../Organization/VolunteerOpportunities/FormLoadingSpinner";
import { Alert } from "@material-ui/lab";
import { NavigateInternally } from "../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        content: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(4),
            display: 'inline-block',
            position: 'relative' // the loading spinner refers to this element
        },
        title: {
            marginBottom: theme.spacing(2),
            color: theme.palette.action.active
        },
        button: {
            marginTop: theme.spacing(2)
        },
        alert: {
            marginBottom: theme.spacing(1),
            textAlign: 'left',
            maxWidth: '400px'
        },
        bold: {
            fontWeight: 'bold'
        },
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    })
);

const PasswordReset = observer(() => {

    /********* React hooks *********/
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const { token } = useParams() as { token: string };

    /********* Helper method *********/

    const getPasswordResetData = () => {
        return token ? new PasswordResetData(token) : undefined;
    }

    /********* State *********/

    const [passwordResetData, setPasswordResetData] = useState(getPasswordResetData());
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [warningCode, setWarningCode] = useState<number>();

    /********* Effect *********/

    useEffect(() => {
        setPasswordResetData(getPasswordResetData());
        setValidated(false);
        setIsLoading(false);
        setSuccess(false);
        setWarningCode(undefined);
    }, [])

    /********* Event handlers *********/

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (passwordResetData) {
            if (passwordResetData.validated) {
                setIsLoading(true);
                const response = await userStore.resetPassword(passwordResetData);
                if (response) {
                    if ('warning' in response) {
                        setSuccess(false);
                        setWarningCode(response.warning.code);
                    } else {
                        setSuccess(response.success);
                        setWarningCode(undefined);
                    }
                }
                setIsLoading(false);
            } else {
                passwordResetData.setAllFieldsDirty();
                setValidated(true);
            }
        }
    }

    const onClickLogin = () => {
        rootStore.navigationStore.appwideDialogStates.loginDialog.setOpen(true);
    }

    const onClickRequestNewResetLink = () => {
        rootStore.navigationStore.appwideDialogStates.forgotPasswordDialog.setOpen(true);
    }

    /********* Render *********/

    if (passwordResetData === undefined) {
        return <NavigateInternally to="/" replace />
    }

    return (
        <div id="password-reset" className={classes.root}>
            <Container>
                <Paper className={classes.content}>
                    <Typography variant="h4" className={classes.title}>Reset Your Password</Typography>
                    {success
                        ? <Alert severity="success" className={classes.alert}>
                            <div className={classes.bold}>Password reset</div>
                            {`Your password has successfully been reset. Please `}
                            <span className={classes.link} onClick={onClickLogin}>login</span>
                            {` to continue.`}
                        </Alert>
                        : warningCode
                            ? <Alert severity="error" className={classes.alert}>
                                {warningCode === 3020
                                    ? <div>
                                        <div className={classes.bold}>Invalid password reset link</div>
                                        {`Please verify that you've entered the URL exactly as it appears in the email you were sent. `}
                                        {`If it does, you may need to `}
                                        <span className={classes.link} onClick={onClickRequestNewResetLink}>
                                            request a new password reset link
                                        </span>.
                                    </div>
                                    : warningCode === 3030
                                        ? <div>
                                            <div className={classes.bold}>Expired password reset link</div>
                                            {`Please `}
                                            <span className={classes.link} onClick={onClickRequestNewResetLink}>
                                                request a new password reset link
                                            </span>
                                            {` in order to continue.`}
                                        </div>
                                        : null
                                }
                            </Alert>
                            : null
                    }
                    {!success && warningCode === undefined
                        ? <form onSubmit={onSubmit} noValidate autoComplete="off">
                            <PasswordResetForm passwordResetData={passwordResetData} validated={validated} />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                            >
                                Reset Password
                            </Button>
                        </form>
                        : null
                    }
                    {isLoading ? <FormLoadingSpinner /> : null}
                </Paper>
            </Container>
        </div>
    );
});

export default PasswordReset;