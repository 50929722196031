import { Fragment, createElement, useEffect, useState } from "react";
import { Grid, Typography, makeStyles, Theme, createStyles, Paper, Container, TableCell, IconButton, Button, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react";
import { ArrowTopRight, CalendarClock, ChartDonut, ChartLine, Domain, FormatListText, LightningBolt, Magnify, Poll } from "mdi-material-ui";
import clsx from "clsx";
import { TopOrganization, VolunteerDashboardData } from "../../../stores/models/VolunteerDashboardData";
import useCurrentWidth from "../../../components/Shared/Hooks/WindowResize";
import { useIsOrgPortal, useNavigateInternally } from "../../../components/Navigation/Hooks";
import { OptionCollection } from "../../../stores/models/OptionCollection";
import { HOURS_LOGGING_PARAMETER, buildQueryString, getOrganizationLink, getPortalRedirectLink, getSearchLink, getSparksLink, getVolunteerServiceHistoryLink, getVolunteerShiftsLink } from "../../../components/Navigation/Links";
import BarGraphWidget from "../../../components/AccountSettings/Pages/Organization/Dashboard/BarGraphWidget";
import DonutGraphWidget from "../../../components/AccountSettings/Pages/Organization/Dashboard/DonutGraphWidget";
import LoadingIndicator from "../../../components/Shared/LoadingIndicator";
import { Option } from "../../../stores/models/Option";
import { ISortableTableHeader, TableHeader } from "../../../stores/models/TableHeader";
import { roundToNumDecimalPlaces } from "../../../logic/UtilityFunctions";
import RecordTable from "../../../components/AccountSettings/Pages/Organization/RecordTable";
import { IconName } from "../../../stores/models/Icon";
import MdiIcon from "../../../components/Shared/MdiIcon";
import { mdiTimerSandComplete } from "@mdi/js";
import ActionCardWithFooter from "./ActionCardWithFooter";
import ShiftList from "../../../components/Organization/VolunteerOpportunities/ShiftList";
import { useStandardStyles } from "../../../shared/general/hooks/useStandardStyles";
import { useRootStore } from "../../../shared/general/hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            position: 'relative',
            overflow: 'hidden'
        },
        cardTitle: {
            marginRight: '2.5rem'
        },
        cardUnits: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap'
        },
        number: {
            color: theme.palette.primary.main
        },
        multiItemGridCell: {
            '& > :not(:first-child)': {
                marginTop: theme.spacing(2)
            }
        },
        linkIcon: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: theme.palette.action.active
        },
        sectionTitle: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: theme.spacing(-2, -3, 2, -3),
            padding: theme.spacing(1.5, 3)
        },
        statsWrapper: {
            width: '100%',
            justifyContent: 'space-evenly',
            '& > *': {
                padding: '0px 6px',
                textAlign: 'center'
            }
        },
        alignCenter: {
            alignItems: 'center'
        },
        rightMargin: {
            marginRight: theme.spacing(.5)
        },
        doubleTopMargin: {
            marginTop: theme.spacing(2)
        },
        topMargin: {
            marginTop: theme.spacing(1)
        },
        bottomMargin: {
            marginBottom: theme.spacing(1)
        },
        icon: {
            fontSize: '1.75rem',
            color: theme.palette.darkBackground.main
        },
        defaultIcon: {
            color: "#ebebeb",
            fontSize: 230,
            [theme.breakpoints.down('sm')]: {
                fontSize: 180
            },
            alignSelf: 'center'
        },
        alignFlexStart: {
            alignSelf: 'flex-start'
        },
        shiftIcons: {
            margin: theme.spacing(1, 0),
            display: 'flex',
            flexDirection: 'column'
        },
        shiftIcon: {
            margin: '-20px 0px'
        },
        donutGraph: {
            marginTop: theme.spacing(-2)
        },
        shiftListWrapper: {
            marginBottom: theme.spacing(-2)
        }
    }),
);

const emptyVolunteerTestData = new VolunteerDashboardData({
    numSparks: 0,
    monthlyServiceHours: [],
    shifts: [],
});

enum NumericalDataCards {
    ConfirmedHours = 'Confirmed Hours',
    UnconfirmedHours = 'Unconfirmed Hours',
    Sparks = 'Sparks',
    UpcomingShifts = 'Upcoming Shifts'
}

type CardData = { title: string, field: keyof VolunteerDashboardData, link?: string } //, zeroStateButtonText: string };

const cardData: CardData[] = [{
    title: NumericalDataCards.ConfirmedHours,
    field: 'numConfirmedHours',
    link: getVolunteerServiceHistoryLink()
}, {
    title: NumericalDataCards.UnconfirmedHours,
    field: 'numUnconfirmedHours',
    link: getVolunteerServiceHistoryLink()
}, {
    title: NumericalDataCards.Sparks,
    field: 'numSparks',
    link: getSparksLink()
}, {
    title: NumericalDataCards.UpcomingShifts,
    field: 'numShifts',
    link: getVolunteerShiftsLink()
}];

const headCells = ([
    { id: 'name', alignment: 'left', disablePadding: false, label: 'Organization', sortable: false },
    { id: 'hours', alignment: 'right', disablePadding: false, label: 'Hours', sortable: true, sortValue: 'hours' }
] as ISortableTableHeader<TopOrganization>[]).map(headCell => new TableHeader(headCell));

const graphColors = [/*"#824c71 ",*/ /*"#d6f2f4",*/ "#cbdf90", "#EBD184", "#ffc9b5", "#08C4D0", "#f7b1ab", "#97BDC4"];

const TWO_MONTHS_WIDTH = 300;
const THREE_MONTHS_WIDTH = 330;
const FOUR_MONTHS_WIDTH = 355;
const FIVE_MONTHS_WIDTH = 385;
const SIX_MONTHS_WIDTH = 415;

const MAX_UPCOMING_SHIFTS_TO_DISPLAY = 5;

const toFixedIfNecessary = (value: string | number, maxDecimalPlaces: number) => {
    const input = typeof value === 'number' ? value.toString() : value;
    return +parseFloat(input).toFixed(maxDecimalPlaces);
}

const VolunteerDashboard = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const baseClasses = useStandardStyles();
    const rootStore = useRootStore();
    const userStore = rootStore.userStore;
    const volunteerStore = rootStore.volunteerStore;
    const width = useCurrentWidth();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const down800 = useMediaQuery((theme: Theme) => theme.breakpoints.down(800));
    const navigate = useNavigateInternally();
    const isOrgPortal = useIsOrgPortal();

    /********* State *********/

    const [topOrganizations, setTopOrganizations] = useState<OptionCollection<'id', TopOrganization>>(new OptionCollection('id', []));
    const [dashboardData, setDashboardData] = useState<VolunteerDashboardData>();

    /********* Effects *********/

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (dashboardData) {
            let numMonthsToDisplay: number;
            if (width < TWO_MONTHS_WIDTH) {
                numMonthsToDisplay = 1;
            } else if (width < THREE_MONTHS_WIDTH) {
                numMonthsToDisplay = 2;
            } else if (width < FOUR_MONTHS_WIDTH) {
                numMonthsToDisplay = 3;
            } else if (width < FIVE_MONTHS_WIDTH) {
                numMonthsToDisplay = 4;
            } else if (width < SIX_MONTHS_WIDTH) {
                numMonthsToDisplay = 5;
            } else {
                numMonthsToDisplay = 6;
            }
            dashboardData.setNumMonthsToDisplay(numMonthsToDisplay);
        }
    }, [width, dashboardData])

    useEffect(() => {
        if (dashboardData) {
            setTopOrganizations(new OptionCollection('id', dashboardData.topOrganizations));
        }
    }, [dashboardData]);

    /********* Helper methods *********/

    const loadData = async () => {
        if (userStore.user.volunteer?.id) {
            const response = await volunteerStore.getDashboard({ "id": userStore.user.volunteer.id });
            if (response) {
                setDashboardData(response);
            } else {
                setDashboardData(emptyVolunteerTestData)
            }
        }
    }

    /********* Event handlers *********/

    const onOrganizationClicked = (organizationId: number) => {
        const organizationLink = getOrganizationLink(organizationId);
        if (isOrgPortal) {
            navigate(getPortalRedirectLink({ ":orgId": organizationId.toString() }, organizationLink), undefined, false);
        } else {
            followLink(organizationLink);
        }
    }

    const followLink = (link: string) => {
        navigate(link);
    }

    /********* Render *********/

    const linkIcon = (link?: string) => (
        <IconButton className={classes.linkIcon} onClick={link ? () => followLink(link) : undefined}>
            <ArrowTopRight fontSize="small" />
        </IconButton>
    )

    const barGraph = (dashboardData && dashboardData.hasServiceHoursDataInLastXMonths)
        ? (
            <BarGraphWidget
                data={dashboardData?.monthlyServiceHoursBarGraphData || []}
                colors={graphColors}
            />
        )
        : null;

    const donutGraph = (dashboardData && dashboardData.totalsByOpportunity.length > 0)
        ? (
            <DonutGraphWidget
                data={dashboardData.totalsByOpportunity}
                fieldMapping={{ id: 'position', label: 'position', value: 'total', colorPosition: 'colorPosition' }}
                colors={graphColors}
            />
        )
        : null;

    const orgHoursTable = (dashboardData && dashboardData.topOrganizations.length > 0)
        ? <RecordTable
            records={topOrganizations}
            tableHeaderCells={headCells}
            orderBy={'hours'}
            order={'desc'}
            displayFunctions={[
                (option: Option<TopOrganization>, rowIndex: number, cellIndex: number) => {
                    return (
                        <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                            {option.object.organization}
                        </TableCell>
                    );
                },
                (option: Option<TopOrganization>, rowIndex: number, cellIndex: number) => {
                    return (
                        <TableCell align="right" key={`${rowIndex}-${cellIndex}`}>
                            {roundToNumDecimalPlaces(option.object.hours, 2)}
                        </TableCell>
                    );
                },
            ]}
            onRowClicked={(option: Option<TopOrganization>, rowIndex: number) => {
                onOrganizationClicked(option.object.id);
            }}
        />
        : null;

    const statsDisplay = (stat: number, label: string, options: { iconName?: IconName, icon?: JSX.Element }) => {
        return (
            <div className={clsx(baseClasses.flex, baseClasses.columnFlex)}>
                <div className={clsx(baseClasses.flex, baseClasses.columnFlex, classes.alignCenter)}>
                    <div className={classes.rightMargin}>{options.icon ? options.icon : options.iconName ? createElement(options.iconName, { className: classes.icon }) : null}</div>
                    <div className={classes.topMargin}>{stat} {label}</div>
                </div>
            </div>
        )
    }

    const actionButton = (label: string, icon: JSX.Element, onClick: () => void) => {
        return (
            <Button variant="contained" color="primary" startIcon={icon} onClick={onClick}>
                {label}
            </Button>
        )
    }

    /********* Data Components *********/

    const welcomeCard = (
        <Paper className={clsx(classes.paper)}>
            <div className={classes.sectionTitle}>
                <Typography variant="h5">
                    {xsDown ? "Welcome!" : "Welcome to your Dashboard!"}
                </Typography>
            </div>
            <Typography>
                As you sign up for volunteer shifts and log your service hours, you'll be able to track your impact here.
            </Typography>
        </Paper>
    )

    const mobileStatsDisplay = (
        <Paper className={classes.paper}>
            <div className={clsx(baseClasses.flex, classes.statsWrapper)}>
                {statsDisplay(
                    toFixedIfNecessary((dashboardData?.numConfirmedHours || 0) + (dashboardData?.numUnconfirmedHours || 0), 2),
                    "Hours",
                    { icon: <MdiIcon iconPath={mdiTimerSandComplete} className={classes.icon} /> }
                )}
                {statsDisplay(dashboardData?.numShifts || 0, "Future Shifts", { iconName: CalendarClock })}
                {statsDisplay(dashboardData?.numSparks || 0, "Sparks", { iconName: LightningBolt })}
            </div>
        </Paper>
    )

    const desktopStatsDisplay = (dataElement: CardData) => {
        if (!dashboardData) {
            return null;
        } else {
            return (
                <Paper className={classes.paper}>
                    <Typography variant="h4" className={classes.cardTitle}>
                        <span className={classes.number}>{toFixedIfNecessary(dashboardData[dataElement.field] as number, 2)}</span>
                    </Typography>
                    {linkIcon(dataElement.link)}
                    <Typography variant="h6" className={classes.cardUnits}>
                        {dataElement.title}
                    </Typography>
                </Paper>
            )
        }
    }

    const hoursByOpportunityCard = (
        <ActionCardWithFooter
            title="Impact by Opportunity"
            content={
                donutGraph
                    ? <div className={classes.donutGraph}>
                        {donutGraph}
                    </div>
                    : <ChartDonut className={classes.defaultIcon} />
            }
            footer={dashboardData !== undefined && dashboardData.hoursByOpportunity.length > 0
                ? <Fragment />
                : actionButton("Find Opportunities", <Magnify />, () => followLink(getSearchLink(false)))
            }
        />
    );

    const monthlyVolunteerHoursCard = (
        <ActionCardWithFooter
            title="Monthly Volunteer Hours"
            content={
                barGraph
                    ? <div className={classes.donutGraph}>
                        {barGraph}
                    </div>
                    : <ChartLine className={classes.defaultIcon} />
            }
            footer={barGraph
                ? <Fragment />
                : actionButton("Log Hours", <MdiIcon iconPath={mdiTimerSandComplete} />, () => followLink(getVolunteerServiceHistoryLink() + buildQueryString({ [HOURS_LOGGING_PARAMETER]: true })))
            }
        />
    )

    const impactByOrganizationCard = (
        <ActionCardWithFooter
            title="Impact by Organization"
            content={
                orgHoursTable
                    ? <div className={clsx(classes.topMargin, classes.bottomMargin)}>
                        {orgHoursTable}
                    </div>
                    : <Poll className={classes.defaultIcon} />
            }
            footer={dashboardData !== undefined && dashboardData.topOrganizations.length > 0
                ? <Fragment />
                : actionButton("Find Organizations", <Domain />, () => followLink(getSearchLink(true)))
            }
        />
    )

    const upcomingShiftsCard = (
        <ActionCardWithFooter
            title="Upcoming Shifts"
            content={
                (dashboardData && dashboardData.shifts.length > 0)
                    ? <div className={classes.shiftListWrapper}>
                        <ShiftList
                            records={dashboardData.sortedShiftInstances.slice(0, MAX_UPCOMING_SHIFTS_TO_DISPLAY)}
                            editable={false}
                            onShiftActionSelected={() => { return; }}
                        />
                    </div>
                    : <div className={classes.shiftIcons}>
                        <FormatListText className={clsx(classes.defaultIcon, !xsDown ? classes.alignFlexStart : undefined, classes.shiftIcon)} />
                        {!xsDown && !dashboardData?.hasServiceHoursData
                            ? <FormatListText className={clsx(classes.defaultIcon, !xsDown ? classes.alignFlexStart : undefined, classes.shiftIcon)} />
                            : null
                        }
                    </div>
            }
            footer={dashboardData && dashboardData.shifts.length > 0
                ? <div className={clsx(classes.doubleTopMargin, baseClasses.flex, baseClasses.columnFlex)}>
                    {actionButton("View Schedule", <CalendarClock />, () => followLink(getVolunteerShiftsLink()))}
                </div>
                : actionButton("Find Shifts", <CalendarClock />, () => followLink(getSearchLink(false)))
            }
        />
    )

    /********* Grid Layouts *********/

    const emptyStateLayout = (
        <Fragment>
            {/* Hours by Opportunity and Upcoming Shifts */}
            <Grid item xs={12} sm={6} md={5} lg={4} className={classes.multiItemGridCell}>
                {mdDown ? hoursByOpportunityCard : null}
                {!xsDown ? upcomingShiftsCard : null}
            </Grid>
            {/* Monthly Volunteer Hours and Impact by Organization */}
            <Grid item xs={12} sm={6} md={7} lg={8}>
                <Grid container spacing={2}>
                    {!mdDown
                        ? <Grid item xs={12} lg={6} className={classes.multiItemGridCell}>
                            {hoursByOpportunityCard}
                        </Grid>
                        : null
                    }
                    {!xsDown
                        ? <Fragment>
                            <Grid item xs={12} lg={6} className={classes.multiItemGridCell}>
                                {impactByOrganizationCard}
                            </Grid>
                            <Grid item xs={12} className={classes.multiItemGridCell}>
                                {monthlyVolunteerHoursCard}
                            </Grid>
                        </Fragment>
                        : <Grid item xs={12} className={classes.multiItemGridCell}>
                            {impactByOrganizationCard}
                            {monthlyVolunteerHoursCard}
                            {upcomingShiftsCard}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Fragment>
    )

    const hoursLoggedLayout = (
        xsDown
            ? <Grid item xs={12} className={classes.multiItemGridCell}>
                {hoursByOpportunityCard}
                {monthlyVolunteerHoursCard}
                {impactByOrganizationCard}
                {upcomingShiftsCard}
            </Grid>
            : smDown
                ? <Fragment>
                    <Grid item xs={12} className={classes.multiItemGridCell}>
                        {hoursByOpportunityCard}
                        {monthlyVolunteerHoursCard}
                    </Grid>
                    {down800 || (dashboardData && dashboardData.shifts.length > 0)
                        ? <Grid item xs={12} className={classes.multiItemGridCell}>
                            {impactByOrganizationCard}
                            {upcomingShiftsCard}
                        </Grid>
                        : <Fragment>
                            <Grid item xs={7} className={classes.multiItemGridCell}>
                                {impactByOrganizationCard}
                            </Grid>
                            <Grid item xs={5} className={classes.multiItemGridCell}>
                                {upcomingShiftsCard}
                            </Grid>
                        </Fragment>
                    }
                </Fragment>
                : mdDown
                    ? <Fragment>
                        <Grid item md={12} className={classes.multiItemGridCell}>
                            {hoursByOpportunityCard}
                            {monthlyVolunteerHoursCard}
                        </Grid>
                        <Grid item md={(dashboardData && dashboardData.shifts.length > 0) ? 5 : 7} className={classes.multiItemGridCell}>
                            {impactByOrganizationCard}
                        </Grid>
                        <Grid item md={(dashboardData && dashboardData.shifts.length > 0) ? 7 : 5} className={classes.multiItemGridCell}>
                            {upcomingShiftsCard}
                        </Grid>
                    </Fragment>
                    : <Fragment>
                        <Grid item md={5} className={classes.multiItemGridCell}>
                            {hoursByOpportunityCard}
                            {impactByOrganizationCard}
                        </Grid>
                        <Grid item md={7} className={classes.multiItemGridCell}>
                            {monthlyVolunteerHoursCard}
                            {upcomingShiftsCard}
                        </Grid>
                    </Fragment>
    )


    /********* Render *********/

    if (!dashboardData) {
        return <LoadingIndicator />;
    }
    return (
        <div className={baseClasses.fullWidth}>
            <Container>
                <Grid container spacing={2}>
                    {/* Welcome Message */}
                    {/* If there's no service hour data, display the welcome message: */}
                    {!dashboardData.hasServiceHoursData &&
                        <Grid item xs={12}>
                            {welcomeCard}
                        </Grid>
                    }

                    {/* Quick Stats */}
                    {xsDown
                        ? <Grid item xs={12}>
                            {mobileStatsDisplay}
                        </Grid>
                        : cardData.map((dataElement, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={6} lg={3} key={`grid-item-${index}`}>
                                    {desktopStatsDisplay(dataElement)}
                                </Grid>
                            )
                        })
                    }

                    {dashboardData.hasServiceHoursData
                        ? hoursLoggedLayout
                        : emptyStateLayout
                    }

                </Grid>
            </Container >
        </div >
    )
});

export default VolunteerDashboard;