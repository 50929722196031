import { observer } from "mobx-react";
import { useMediaQuery, Theme } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { DialogState } from "../../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../Dialogs/ThemedDialogWithSpinner";
import { Form } from "../../../../stores/models/Form";
import DialogForm from "./DialogForm";
import { APIError } from "../../../../stores/models/APIError";

interface FormDialogProps {
    form: Form;
    state: DialogState;
    submitted?: boolean;
    hideSubmittedWarning?: boolean;
    onFormSubmit: (form: Form) => Promise<{ succeeded: boolean, error?: string | APIError }>;
}

const FormDialog = observer((props: FormDialogProps) => {

    /***** React hooks *****/

    const ref = useRef<HTMLDivElement>(null);
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /***** State *****/

    const [displayError, setDisplayError] = useState(false);
    const [responseError, setResponseError] = useState<string | APIError>();

    /***** Effects *****/

    useEffect(() => {
        if (!props.state.open) {
            setDisplayError(false);
            setResponseError(undefined);
        }
    }, [props.state.open]);

    /***** Helper method *****/

    const scrollToTopOfForm = () => {
        if (ref.current) {
            ref.current.scrollIntoView({ block: 'center' });
        }
    }

    /***** Event handlers *****/

    const onSubmitClicked = async () => {
        props.form.setAllFieldsDirty();
        if (props.form.validated) {
            setDisplayError(false);
            props.state.setLoading(true);
            const response = await props.onFormSubmit(props.form);
            if (response.succeeded) {
                props.state.setOpen(false);
            }
            props.state.setLoading(false);
            if (response.error !== undefined) {
                setResponseError(response.error);
                scrollToTopOfForm();
            }
        } else {
            setDisplayError(true);
            scrollToTopOfForm();
        }
    }

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            DialogProps={{ maxWidth: 'sm', fullScreen: xsDown }}
            state={props.state}
            title={props.form.title}
            onSubmit={onSubmitClicked}
            primaryButtonProps={{ children: 'Submit' }}
        >
            <div ref={ref} />
            <DialogForm
                formStructure={props.form}
                submitted={props.submitted}
                hideSubmittedWarning={props.hideSubmittedWarning}
                displayError={displayError}
                error={responseError}
            />
        </ThemedDialogWithSpinner >
    )
})

export default FormDialog;