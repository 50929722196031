import Logo from './VV-logo-2000x500.svg';
import { useIsOrgPortal } from './Hooks';
import { useEffect, useState } from 'react';
import { useOrgPortalLogoSrc } from './Hooks/OrganizationPortal/OrgPortalLogoSrc';

export default function useNavigationBarLogoSrc() {

    const isOrgPortal = useIsOrgPortal();
    const orgPortalLogoSrc = useOrgPortalLogoSrc();

    const getSrc = () => {
        if (isOrgPortal) {
            return orgPortalLogoSrc;
        } else {
            return Logo;
        }
    }

    const [src, setSrc] = useState(getSrc());

    useEffect(() => {
        setSrc(getSrc());
    }, [orgPortalLogoSrc]);

    return src;
}