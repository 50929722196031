import { makeObservable } from "mobx"
import { VolunteerHoursServices } from "../services/VolunteerHoursServices"
import { VolunteerHoursStore } from "../stores/VolunteerHoursStore"
import { ServiceEntry } from "../entities/ServiceEntry"
import { IServerServiceEntry, ServerServiceEntry } from "../../../../stores/models/server/ServerServiceEntry"
import { BulkEditRecordListAction } from "../../../../shared/modules/record-collection/data-access/actions/BulkEditRecordListAction"
import { IServiceEntryBulkEdit, isServiceEntryBulkDeletion, isServiceEntryBulkStatusUpdate } from "../interfaces/ServiceEntryBulkEdit"

export class BulkEditHoursAction extends BulkEditRecordListAction<ServiceEntry, IServerServiceEntry, IServiceEntryBulkEdit> {
    constructor(store: VolunteerHoursStore, private services: VolunteerHoursServices) {
        super(store);
        makeObservable(this)
    }

    protected transformData(data: IServerServiceEntry[]): ServiceEntry[] {
        return data.map(element => new ServerServiceEntry(element).deserialize());
    }

    async editData(update: IServiceEntryBulkEdit) {
        const isBulkDeletion = isServiceEntryBulkDeletion(update);
        if (!this.store.rootStore.userStore.user.isOrganization && !isBulkDeletion) return;

        const idsOfSelections = (this.store as VolunteerHoursStore).idsOfSelections;
        const response = await this.services.bulkEdit(idsOfSelections, update);
        if (response) {
            const selections = this.store.collection.selectedOptions;
            const autoGeneratedEntryEdited = selections.findIndex(selection => selection.id < 0) !== -1; // Checks for edits to any automatically-generated service entries
            const statusUpdated = isServiceEntryBulkStatusUpdate(update); // A status update could affect sort order, so reload
            const reloadNeeded = isBulkDeletion || autoGeneratedEntryEdited || statusUpdated;
            if (reloadNeeded) {
                this.store.setReloadNeeded(true);
            } else {
                selections.forEach(selection => {
                    if (selection.id < 0 || selection.duration === 0 || isBulkDeletion) {
                        this.store.collection.removeOption(selection);
                    }
                });
                return response.filter(entry => entry.duration > 0 && !entry.archived);
            }
        }
    }
}