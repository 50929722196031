import { TextField, makeStyles, Theme, createStyles, InputAdornment, CircularProgress, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { observer } from "mobx-react";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { StepProps } from "../../../../stores/models/Step";
import { Credentials } from "../../../../stores/models/Credentials";
import { RootContext } from "../../../../stores";
import { CancellablePromise } from "mobx/dist/internal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
            width: '40ch',
            flexGrow: 1,
            [theme.breakpoints.down('xs')]: {
                width: 'auto'
            },
            '& > .MuiFormHelperText-root': {
                whiteSpace: 'pre-line'
            }
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        textFieldWrapper: {
            display: 'flex',
            flexGrow: 1
        },
        errorIcon: {
            color: theme.palette.accent.main
        },
        matchIcon: {
            color: theme.palette.primary.main
        },
        title: {
            color: theme.palette.action.active,
            marginBottom: theme.spacing(3),
            textAlign: 'center'
        },
    }),
);

const CredentialsInput = observer((props: StepProps<Credentials>) => {

    const credentials = props.stepState.stepObject;

    /***** React hooks *****/

    const classes = useStyles();
    const registrationStore = useContext(RootContext).registrationStore;

    /***** State *****/

    const [uniqueEmailCheck, setUniqueEmailCheck] = useState<CancellablePromise<boolean>>();

    /***** Effects *****/

    useEffect(() => {
        if (props.stepState.validationRun) {
            credentials.setAllFieldsDirty();
        }
    }, [props.stepState.validationRun]);

    useEffect(() => {
        // timeoutId for debouncing
        let timeoutId: NodeJS.Timeout | undefined = undefined;
        // prevent execution of previous setTimeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // update the map after timeout
        timeoutId = setTimeout(() => {
            if (credentials.emailIsValid) {
                verifyEmailIsUnique();
            }
        }, 1000);

        return function cleanUp() {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [credentials.email]);

    /***** Helper methods *****/

    const verifyEmailIsUnique = async () => {
        if (uniqueEmailCheck) {
            uniqueEmailCheck.cancel();
        }
        const promise = registrationStore.checkUsernameUniqueness(credentials.email);
        setUniqueEmailCheck(promise);
        const usernameAlreadyExists = await promise;
        credentials.setUnique(!usernameAlreadyExists);
    }

    /***** Event handlers *****/

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        credentials.setEmail(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        credentials.setPassword(event.target.value);
    }

    const handleConfirmedPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        credentials.setConfirmedPassword(event.target.value);
    }

    /***** Helper constants *****/

    const validationRun = props.stepState.validationRun;
    const emailError = validationRun || credentials.emailIsValid ? credentials.errors.email : '';
    const passwordError = validationRun ? credentials.errors.password : '';
    const confirmedPasswordError = validationRun ? credentials.errors.confirmedPassword : '';

    /***** Render *****/

    return (
        <Fragment>
            <div className={classes.title}>
                <Typography variant="h5">Create your account.</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.textFieldWrapper}>
                        <TextField
                            id="standard-email-input"
                            label="Email"
                            autoComplete="current-email"
                            variant="outlined"
                            required
                            value={credentials.email}
                            onChange={handleEmailChange}
                            autoFocus
                            className={classes.textField}
                            error={emailError.length > 0 && (!credentials.emailIsValid || credentials.unique === false)}
                            helperText={emailError.length > 0 ? emailError : undefined}
                            InputProps={{
                                endAdornment: credentials.emailIsValid
                                    ? <InputAdornment position="end">
                                        {credentials.unique === undefined
                                            ? <CircularProgress size={20} />
                                            : credentials.unique
                                                ? <CheckCircleIcon className={classes.matchIcon} />
                                                : <ErrorIcon className={classes.errorIcon} />
                                        }
                                    </InputAdornment>
                                    : null
                            }}
                        />
                    </div>
                    <div className={classes.textFieldWrapper}>
                        <TextField
                            id="standard-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            required
                            value={credentials.password}
                            onChange={handlePasswordChange}
                            className={classes.textField}
                            error={passwordError.length > 0}
                            helperText={passwordError.length > 0 ? passwordError : undefined}
                        />
                    </div>
                    <div className={classes.textFieldWrapper}>
                        <TextField
                            id="confim-password-input"
                            label="Confirm Password"
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            required
                            value={credentials.confirmedPassword}
                            onChange={handleConfirmedPasswordChange}
                            className={classes.textField}
                            error={confirmedPasswordError.length > 0}
                            helperText={confirmedPasswordError.length > 0 ? confirmedPasswordError : undefined}
                            InputProps={{
                                endAdornment: credentials.confirmedPassword.length > 0
                                    ? <InputAdornment position="end">
                                        {credentials.passwordsMatch
                                            ? <CheckCircleIcon className={classes.matchIcon} />
                                            : <ErrorIcon className={classes.errorIcon} />
                                        }
                                    </InputAdornment>
                                    : null
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    );
});

export default CredentialsInput;