import { action, computed, makeObservable, observable } from "mobx";
import { Fields } from "../../../../../stores/models/Fields";
import validatePortalForm from "./PortalFormValidation";

export interface IPortalForm {
    portalSlug?: string;
    logo?: File;
}

export class PortalForm extends Fields<IPortalForm, PortalForm> implements IPortalForm {

    @observable portalSlug?: string;
    @observable logo?: File;
    @observable logoExists: boolean = false;

    constructor(portalForm: IPortalForm) {
        super();
        makeObservable(this);
        this.portalSlug = portalForm.portalSlug || "";
        this.logo = portalForm.logo;
    }

    @action setPortalSlug(portalSlug: string) {
        this.portalSlug = portalSlug;
    }

    @action setLogo(logo: File) {
        this.logo = logo;
    }

    @action setLogoExists(logoExists: boolean) {
        this.logoExists = logoExists;
    }

    @computed get validationErrors() {
        return validatePortalForm(this);
    }
}