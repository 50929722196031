import React from "react";
import {
    useMediaQuery,
    Theme
} from "@material-ui/core";
import ThemedDialogWithSpinner from "./Dialogs/ThemedDialogWithSpinner";
import { DialogState } from "../../stores/models/DialogState";

export interface ConfirmationDialogProps {
    state: DialogState;
    title?: string;
    content: React.ReactNode; // TODO: Switch to using children instead of content
    cancelText?: string;
    hideCancelButton?: boolean;
    confirmText: string;
    onConfirm: () => void;
    fullScreen?: boolean;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {

    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            DialogProps={{ maxWidth: 'sm', fullScreen: props.fullScreen !== undefined ? props.fullScreen : xsDown }}
            title={props.title}
            primaryButtonProps={{ children: props.confirmText }}
            onSubmit={props.onConfirm}
            cancelText={props.cancelText}
            hideCancelButton={props.hideCancelButton}
        >
            {props.content}
        </ThemedDialogWithSpinner >
    )
};

export default ConfirmationDialog;