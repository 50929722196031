import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Fab as MUIFab, FabProps as MUIFabProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        extended: {
            height: '3rem',
            padding: '0 1rem',
            minWidth: '3rem',
            borderRadius: '1.5rem'
        },
    })
);

interface FabProps extends MUIFabProps {

}

const REMFab = observer((props: FabProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <MUIFab
            {...props}
            classes={{
                ...props.classes,
                extended: clsx(classes.extended, props.classes?.extended),
            }}
        >
            {props.children}
        </MUIFab>
    );
});

export default REMFab;