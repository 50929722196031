import { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { OptionCollection } from "../../../../stores/models/OptionCollection";
import RecordTable, { RecordTableProps } from "./RecordTable";
import TablePaginationWrapper from "./TablePaginationWrapper";
import { TableController } from "../../../../stores/models/TableController";

type IdentityKey = '_id';
type IdentifiableObject = Record<IdentityKey, number>;

interface DisplayTableProps<T extends object> extends Omit<RecordTableProps<IdentityKey, T & IdentifiableObject>, 'records'> {
    records: T[];
    // loadRecords?: (limit: number, offset: number) => Promise<{ records: T[], total: number }>;
}

const getOptionCollection = <T extends object>(array: T[]) => {
    const arrayWithIds = array.map((element, index) => { return { ...element, _id: index } });
    return new OptionCollection('_id', arrayWithIds);
}

const DisplayTable = observer(<T extends object>(props: DisplayTableProps<T>) => {

    const [tableController, setTableController] = useState(new TableController({ records: getOptionCollection(props.records) }));

    useEffect(() => {
        setTableController(new TableController({ records: getOptionCollection(props.records) }))
    }, [props.records])

    /********* Render *********/

    return (
        <Fragment>
            <RecordTable
                {...props}
                records={
                    getOptionCollection(
                        tableController.recordsToDisplay.map(option => option.object)
                    ) as OptionCollection<"_id", T & IdentifiableObject, T & IdentifiableObject>
                }
            />
            <TablePaginationWrapper
                state={tableController.tableState.paginationState}
                total={tableController.tableState.total}
                loadResults={() => { }}
                skipInitialLoad
            />
        </Fragment>
    );
});

export default DisplayTable;