import { IShiftDetails, ShiftDetails } from "./ShiftDetails";
import { makeObservable, observable } from "mobx";

export interface IShiftInstanceDetails extends IShiftDetails { 
    offset: number 
};

export class ShiftInstanceDetails extends ShiftDetails implements IShiftInstanceDetails {

    @observable offset: number = -1;

    constructor(shiftInstanceDetails?: IShiftInstanceDetails) {
        super(shiftInstanceDetails);

        makeObservable(this);

        if (shiftInstanceDetails) {
            this.offset = shiftInstanceDetails.offset;
        }
    }

}