import { observable, action, computed, makeObservable } from "mobx";
import { Opportunity, ICause, Cause, Volunteer } from ".";
import { AddressCollection } from "./Addressable";
import { getOrganizationLink } from "../../components/Navigation/Links/UrlConstructors";
import { IServerOrganization, ServerOrganization } from "./server/ServerOrganization";
import { Fields } from "./Fields";
import { validateOrganization } from "../../logic/ValidationChecks/OrganizationValidation";
import { ServiceEntryTag } from "./ServiceEntryTag";
import { Role } from "./Role";
import { IStatus } from "./Status";
import { ShiftOpportunities } from "../VolunteerStore";
import { Permission } from "./Permission";
import { PortalMode } from "../../data/CategoryOptions";

export interface IOrganizationFields {
    name: string;
    description: string;
    mission: string;
    website: string;
    causes: ICause[];
    portalSlug: string;
}

export interface IOrganization extends IOrganizationFields {
    id: number;
    opportunities: Opportunity[];
    totalOpportunities: number;
    addressCollection: AddressCollection;
    interestSparked: boolean;
    isForProfit: boolean;
    tags?: ServiceEntryTag[];
    volunteers?: Volunteer[];
    locations?: AddressCollection;
    totalLocations: number;
    roles?: Role[];
    permissions?: Permission[];
    volunteerStatuses?: IStatus[];
    portalMode?: PortalMode;
}

export class Organization extends Fields<IOrganizationFields, Organization> implements IOrganization {
    @observable id = -1;
    @observable name = "";
    @observable description = "";
    @observable mission = "";
    @observable website = "";
    @observable portalSlug = "";
    @observable portalMode?: PortalMode;
    @observable causes = [] as Cause[];
    @observable opportunities = [] as Opportunity[];
    @observable totalOpportunities = 0;
    @observable addressCollection: AddressCollection;
    @observable interestSparked: boolean = false;
    @observable isForProfit: boolean = false;
    @observable volunteers: Volunteer[] = [];
    @observable locations = new AddressCollection();
    @observable totalLocations = 0;
    @observable tags: ServiceEntryTag[] = [];
    @observable roles: Role[] = [];
    @observable permissions: Permission[] = [];
    @observable volunteerStatuses: IStatus[] = [];
    @observable shiftOpportunities: ShiftOpportunities = {};

    constructor(idOrOrganization: number | IOrganization, name?: string) {
        super();

        makeObservable(this);

        if (typeof idOrOrganization === 'number') {
            this.id = idOrOrganization;
            this.name = name || '';
            this.addressCollection = new AddressCollection();
        } else {
            this.id = idOrOrganization.id;
            this.name = idOrOrganization.name;
            this.description = idOrOrganization.description;
            this.mission = idOrOrganization.mission;
            this.website = idOrOrganization.website;
            this.portalSlug = idOrOrganization.portalSlug;
            this.portalMode = idOrOrganization.portalMode;
            this.causes = idOrOrganization.causes.map(cause => { return new Cause(cause); }) || [];
            this.opportunities = idOrOrganization.opportunities || [];
            this.totalOpportunities = idOrOrganization.totalOpportunities || 0;
            this.addressCollection = new AddressCollection(idOrOrganization.addressCollection?.addresses);
            this.interestSparked = idOrOrganization.interestSparked || false;
            this.isForProfit = idOrOrganization.isForProfit;
            this.volunteers = idOrOrganization.volunteers || [];
            this.tags = idOrOrganization.tags || [];
            this.roles = idOrOrganization.roles || [];
            this.permissions = idOrOrganization.permissions || [];
            this.volunteerStatuses = idOrOrganization.volunteerStatuses || [];
        }
    }

    @action setName(name: string) {
        this.name = name;
    }

    @action setDescription(description: string) {
        this.description = description;
    }

    @action setMission(mission: string) {
        this.mission = mission;
    }

    @action setWebsite(website: string) {
        this.website = website;
    }

    @action setPortalSlug(portalSlug: string) {
        this.portalSlug = portalSlug;
    }

    @action setAddressCollection(addressCollection: AddressCollection) {
        this.addressCollection = addressCollection;
    }

    @action setCauses(causes: ICause[]) {
        this.causes = causes.map(cause => { return new Cause(cause); });
    }

    @action setInterestSparked(interestSparked: boolean) {
        this.interestSparked = interestSparked;
    }

    @action setVolunteers(volunteers: Volunteer[]) {
        this.volunteers = volunteers;
    }

    @action setTags(tags: ServiceEntryTag[]) {
        this.tags = tags;
    }

    @action setRoles(roles: Role[]) {
        this.roles = roles;
    }

    @action setPermissions(permissions: Permission[]) {
        this.permissions = permissions;
    }

    @computed get organizationPage() {
        return getOrganizationLink(this.id);
    }

    @computed get validationErrors() {
        return validateOrganization(this);
    }

    serialize() {
        return new ServerOrganization(this.trimmed);
    }

    @action.bound onDataLoaded(data: { organization: IServerOrganization }) {
        try {
            const { organization } = data;
            if (organization) {
                if (organization.id === undefined) {
                    throw new Error(`The organization's ID is missing.`);
                }
                // Apply the deserialized data to this organization.
                new ServerOrganization(organization).deserialize(this);
            }
        } catch (error) {
            console.log(error);
        }
    }
}