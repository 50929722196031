import React, { useContext } from "react";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { RootContext } from "../../stores";
import { Alert, AlertTitle } from "@material-ui/lab";

const SessionExpiredDialog = () => {

    const rootStore = useContext(RootContext);
    const navigationStore = rootStore.navigationStore;
    const state = navigationStore.appwideDialogStates.sessionExpiredDialog;

    return (
        <ConfirmationDialog
            state={state}
            title={'Session Expired'}
            confirmText={'Okay'}
            hideCancelButton
            onConfirm={() => state.setOpen(false)}
            content={
                <Alert severity="error">
                    <AlertTitle>Your session has expired.</AlertTitle>
                    The action you attempted to take could not be completed. Please log back in in order to try again.
                </Alert>
            }
        />
    )
}

export default SessionExpiredDialog;