import { getOrgOpportunitiesLink, getOrganizationPath, routeWithDescendants } from '../../Links/UrlConstructors';
import useMatchCurrentPath from '../MatchCurrentPath';

export default function useOrgOpportunitiesLink(organizationId?: number) {

    const matchPath = useMatchCurrentPath();
    const match = matchPath(routeWithDescendants(getOrganizationPath()));

    // Org ID provided:
    if (organizationId) {
        return getOrgOpportunitiesLink(organizationId);
    }

    if (match) {
        // Org ID in route:
        const { orgId } = match.params as unknown as { orgId: number };
        if (orgId) {
            return getOrgOpportunitiesLink(orgId);
        }
    }

    // No Org ID:
    return '#';
}