import { TableCell } from "@material-ui/core";
import { observer } from "mobx-react";
import { OptionCollection } from "../../../stores/models/OptionCollection";
import { Option } from "../../../stores/models/Option";
import { Shift } from "../../../stores/models/Shift";
import { ISortableTableHeader, TableHeader } from "../../../stores/models/TableHeader";
import RecordTable from "../Pages/Organization/RecordTable";

interface ShiftTableProps {
    shifts: Shift[];
    getPositionForShift: (shift: Shift) => string;
    onShiftRowClicked?: (shift: Shift) => void;
}

const ShiftTable = observer((props: ShiftTableProps) => {

    /********* Event handler *********/

    const onShiftRowClicked = (shift: Shift) => {
        if (props.onShiftRowClicked) {
            props.onShiftRowClicked(shift);
        }
    }

    /********* Header cells *********/

    const headCells = ([
        // { id: 'edit', alignment: 'center', disablePadding: true, label: '', sortable: false },
        { id: 'role', alignment: 'left', disablePadding: false, label: 'Volunteer Role', sortable: true, sortValue: (shift: Shift) => props.getPositionForShift(shift) },
        { id: 'date', alignment: 'left', disablePadding: false, label: 'Date', sortable: true, sortValue: (shift: Shift) => shift.repetitionPattern.startTimestamp },
        { id: 'time', alignment: 'left', disablePadding: false, label: 'Time', sortable: false },
        { id: 'status', alignment: 'left', disablePadding: false, label: 'Slots Filled', sortable: true, sortValue: (shift: Shift) => shift.slots - shift.slotsLeft },
    ] as ISortableTableHeader<Shift>[]).map(headCell => new TableHeader(headCell));

    /********* Render *********/

    return (
        <RecordTable
            records={new OptionCollection('id', props.shifts)}
            tableHeaderCells={headCells}
            orderBy={'date'}
            order={'asc'}
            // TODO: Figure out a plan for handling ongoing shifts for expired opportunities.
            displayFunctions={[
                (option: Option<Shift>, rowIndex: number, cellIndex: number) => {
                    return (
                        <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                            {props.getPositionForShift(option.object)}
                        </TableCell>
                    );
                },
                (option: Option<Shift>, rowIndex: number, cellIndex: number) => {
                    return (
                        <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                            {option.object.repetitionPattern.formattedStartDate}
                        </TableCell>
                    );
                },
                (option: Option<Shift>, rowIndex: number, cellIndex: number) => {
                    return (
                        <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                            {option.object.repetitionPattern.formattedTime}
                        </TableCell>
                    );
                },
                (option: Option<Shift>, rowIndex: number, cellIndex: number) => {
                    const slotsFilled = option.object.numRegistrations;
                    const unlimitedSlots = option.object.unlimitedSlots;
                    return (
                        <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                            {`${slotsFilled}/${unlimitedSlots ? 'Unlimited' : option.object.slots}`}
                        </TableCell>
                    );
                },
            ]}
            onRowClicked={(option: Option<Shift>, rowIndex: number) => {
                const shift = option.object;
                onShiftRowClicked(shift)
            }}
        />
    );
});

export default ShiftTable;