import React from "react";
import { observer } from "mobx-react";
import CalendarMonth from "./CalendarMonth";
import { CalendarViewMode } from "./CalendarLogicHelpers";
import { Dictionary } from "../../../logic/Dictionaries";
import { CalendarEvent } from "../../../stores/models/CalendarEvent";

interface CalendarProps<T> {
    date: Date;
    viewMode: CalendarViewMode;
    datesToDisplay: Dictionary<number, Date[]>;
    eventsToDisplay: Dictionary<number, CalendarEvent<T>[]>; // Key: startOfDay(Date).getTime()
    fullScreen?: boolean;
    onDateClicked: (date: Date) => void;
    onEventClicked: (event: CalendarEvent<T>) => void;
    eventPopout?: (event: CalendarEvent<T>) => JSX.Element;
    replaceSelectedEvent?: (currentEvent: CalendarEvent<T>) => CalendarEvent<T> | undefined;
}

const Calendar = observer(<T extends any>(props: CalendarProps<T>) => {

    // TODO: be able to take date from URL

    /********* Render *********/

    return (
        // <div> height: 100%
        <React.Fragment>
            {/* View label */}
            {/* View switcher */}
            <CalendarMonth
                date={props.date}
                datesToDisplay={props.datesToDisplay}
                eventsToDisplay={props.eventsToDisplay}
                onDateClicked={props.onDateClicked}
                onEventClicked={props.onEventClicked}
                fullScreen={props.fullScreen}
                eventPopout={props.eventPopout}
                replaceSelectedEvent={props.replaceSelectedEvent}
            />
        </React.Fragment>
        // </div>
    )
});

export default Calendar;