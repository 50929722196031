import { action, computed, makeObservable, observable } from "mobx";
import { IServiceEntry, ServiceEntry } from "../entities/ServiceEntry";
import { ISortableTableHeader, TableHeader } from "../../../../stores/models/TableHeader";
import { IServiceEntryDisplayData } from "../interfaces/ServiceEntryDisplayData";
import { VolunteerHoursStore } from "../stores/VolunteerHoursStore";
import { RecordListViewDataModel } from "../../../../shared/modules/record-collection/data-access/view-models/RecordListViewDataModel";
import { ServiceEntrySourceTypes } from "../entities/ServiceEntrySourceTypes";

export enum ServiceDataHeaderCells {
    Select = 'select',
    Role = 'role',
    Date = 'date',
    Hours = 'hours',
    Status = 'status',
}

export abstract class ServiceDataViewModel extends RecordListViewDataModel<IServiceEntry, ServiceEntry> {

    @observable public tableHeaderCells = ([
        { id: ServiceDataHeaderCells.Select, alignment: 'center', disablePadding: true, label: '', sortable: false, checkbox: true },
        { id: ServiceDataHeaderCells.Role, sortValue: 'position', alignment: 'left', disablePadding: false, label: 'Role', sortable: true },
        { id: ServiceDataHeaderCells.Date, sortValue: 'date', alignment: 'left', disablePadding: false, label: 'Date', sortable: true },
        { id: ServiceDataHeaderCells.Hours, sortValue: 'duration', alignment: 'left', disablePadding: false, label: 'Hours', sortable: true },
        { id: ServiceDataHeaderCells.Status, sortValue: 'status', alignment: 'left', disablePadding: false, label: 'Status', sortable: true },
    ] as ISortableTableHeader<ServiceEntry>[]).map(headCell => new TableHeader(headCell));

    constructor(store: VolunteerHoursStore) {
        super(store);
        makeObservable(this);
        this.store.reset();
    }

    @computed get recordToEdit() {
        if (this.editableRecord) {
            if (this.editableRecord.entryDeletedByVolunteer) {
                return this.editableRecord.lastOfficialEntry!;
            } else {
                return this.editableRecord;
            }
        } else {
            return this.draft;
        }
    }

    @computed get reloadNeeded() {
        return this.store.reloadNeeded;
    }

    setReloadNeeded = (reloadNeeded: boolean) => {
        this.store.setReloadNeeded(reloadNeeded);
    }

    @action setTableHeaderCells(tableHeaderCells: TableHeader<ServiceEntry>[]) {
        this.tableHeaderCells = tableHeaderCells;
    }

    getNewDraftObject(): ServiceEntry {
        return new ServiceEntry();
    }

    getEditConfirmationText = (serviceEntry: ServiceEntry) => {
        if (!this.editableRecord) return 'Save';
        return this.editableRecord.duration === 0
            && this.editableRecord.lastOfficialEntry
            && ServiceEntry.recruiterFieldsMatch(this.editableRecord.lastOfficialEntry, serviceEntry)
            ? 'Revert'
            : this.editableRecord.volunteerId === undefined
                ? 'Add'
                : 'Save';
    }

    getDisplayableServiceEntry(serviceEntry: ServiceEntry): IServiceEntryDisplayData {
        const entryDeletedByVolunteer = serviceEntry.entryDeletedByVolunteer;
        const entryToDisplay = entryDeletedByVolunteer ? serviceEntry.lastOfficialEntry! : serviceEntry;
        return {
            select: undefined, // Checkbox
            role: serviceEntry.position,
            // tags: {serviceEntry.object.tags.map(tag => {
            //                 return <Chip label={tag.tag} key={`tag-${tag.id}`} size='small' />
            //             })}
            date: serviceEntry.date || '',
            dateComponents: serviceEntry.utcDateComponents,
            hours: entryDeletedByVolunteer ? '-' : parseFloat((entryToDisplay.duration / (60 * 60)).toFixed(2)).toString(),
            status: serviceEntry.getStatusText((this.store as VolunteerHoursStore).serviceEntryStatuses),
            statusId: serviceEntry.statusId
        }
    }

    protected getTableHeaderCells() {
        return this.tableHeaderCells;
    }

    @computed get selectionsIncludeVolunteerEdits() {
        const selections = this.store.collection.selectedOptions;
        for (let i = 0; i < selections.length; i++) {
            if (selections[i].source === ServiceEntrySourceTypes.VolunteerEdits) {
                return true;
            }
        }
        return false;
    }
}
