import { useContext, useEffect, useState } from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Container, useTheme, useMediaQuery } from "@material-ui/core";
import { RootContext, Opportunity, Organization } from "../../stores/";
import { reaction } from "mobx";
import LoadingIndicator from "../Shared/LoadingIndicator";
import ZeroState from "../Shared/ZeroState";
import OpportunityGrid from "../Shared/OpportunityGrid";
import OrganizationGrid from "../Shared/OrganizationGrid";
import useGridColumns from "../Shared/GridColumnsHook";
import { Pagination } from "@material-ui/lab";
import ErrorMessage from "../Shared/ErrorMessage";
import { NAVIGATION_BAR_ID } from "../Navigation/NavigationBar";
import useResetScrollPosition from "../Shared/Hooks/ResetScrollPosition";
import { SEARCH_TYPE_PARAMETER, getOpportunityLink, getOrganizationLink } from "../Navigation/Links/UrlConstructors";
import PageWithFooter from "../Shared/PageWithFooter";
import { useIsOrgPortal, useNavigateInternally, useOrgPortalSlug } from "../Navigation/Hooks";
import SearchFilters from "./SearchFilters";
import { useQuery } from "../Shared/Hooks/URLQuery";
import { SearchTypeOptions } from "../../data/SearchOptions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchWrapper: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            },
            paddingTop: theme.spacing(2),
            flexGrow: 1
        },
        results: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        pagination: {
            marginTop: theme.spacing(4),
        },
        flex: {
            display: 'flex',
            justifyContent: 'center'
        },
        loadingWrapper: {
            display: 'flex',
            flexGrow: 1,
            margin: theme.spacing(15),
        },
    })
);

const Search = observer(() => {

    /********* React hooks *********/

    useResetScrollPosition();

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const { searchStore } = rootStore;
    const query = useQuery();
    const numColumns = useGridColumns();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigateInternally();
    const isOrgPortal = useIsOrgPortal();
    const portalSlug = useOrgPortalSlug();

    /********* Helper methods *********/

    const getPageForOffset = () => {
        const criteria = searchStore.searchCriteria;
        const page = criteria.offset / criteria.numResultsRequested + 1;
        return page;
    }

    const getOffsetForPage = () => {
        const offset = searchStore.searchCriteria.numResultsRequested * (page - 1);
        return offset;
    }

    /********* State *********/

    const [isLoading, setIsLoading] = useState(!searchStore.hasResults);
    const [page, setPage] = useState(getPageForOffset());

    /********* Effects *********/

    useEffect(() => {
        const searchType = query.get(SEARCH_TYPE_PARAMETER);
        if (searchType && searchType.toLowerCase() === SearchTypeOptions.Organizations.toLowerCase()) {
            searchStore.searchCriteria.setSearchType(SearchTypeOptions.Organizations);
        } else if (searchType && searchType.toLowerCase() === SearchTypeOptions.Opportunities.toLowerCase()) {
            searchStore.searchCriteria.setSearchType(SearchTypeOptions.Opportunities);
        }
    }, [query])

    useEffect(() => {
        if (searchStore.searchCriteria.orgSlug !== portalSlug) {
            searchStore.searchCriteria.setOrgSlug(portalSlug);
            searchStore.searchCriteria.setRefreshNeeded(true); // Needed for when the portal slug changes after initial results are loaded
        }
    }, [isOrgPortal, portalSlug]);

    useEffect(() => {
        // Fetch search results based on the search type
        const disposer = reaction(
            () => [searchStore.searchCriteria.serializedRequestData, searchStore.searchCriteria.searchType],
            () => {
                fetchSearchResults();
            }, {
            fireImmediately: !searchStore.hasResults || searchStore.newSparkDataNeeded || searchStore.searchCriteria.refreshNeeded
        });
        return function cleanup() {
            disposer();
        }
    }, []); // Empty array to avoid unnecessary disposal of reaction

    useEffect(() => {
        // Update the search offset when the page changes
        searchStore.searchCriteria.setOffset(getOffsetForPage());
    }, [page]);

    useEffect(() => {
        // Reset the page if the offset is reset
        if (searchStore.searchCriteria.offset === 0) {
            setPage(1);
        }
    }, [searchStore.searchCriteria.offset]);

    useEffect(() => {
        setTimeout(() => {
            scrollToSearchResult();
        }, 0); // Delay scrolling until the search results have rendered
    }, [searchStore.opportunities, searchStore.organizations]);

    /********* API Request *********/

    const fetchSearchResults = async () => {
        scrollToTop();
        setIsLoading(true);
        await searchStore.fetchSearchResults();
        setIsLoading(false);
    }

    /********* Event handlers *********/

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const resetFilters = () => {
        searchStore.resetSearchCriteria();
    }

    const handleOpportunitySelected = (cardId: string, opportunity: Opportunity) => {
        searchStore.setIdOfLastSelection(cardId);
        const link = getOpportunityLink(opportunity.organizationId, opportunity.id);
        navigate(
            link,
            { state: { linkedFromSearch: true } }
        );
    }

    const handleOrganizationSelected = (cardId: string, organization: Organization) => {
        searchStore.setIdOfLastSelection(cardId);
        const link = getOrganizationLink(organization.id);
        navigate(
            link,
            { state: { linkedFromSearch: true } }
        );
    }

    /********* Helper methods *********/

    const scrollToSearchResult = () => {
        if (searchStore.idOfLastSelection) {
            const lastSelectedResult = document.getElementById(searchStore.idOfLastSelection);
            const header = document.getElementById(NAVIGATION_BAR_ID);
            if (lastSelectedResult && header) {
                const topOfElement = lastSelectedResult.offsetTop - header.offsetHeight - theme.spacing(1);
                window.scroll({ top: topOfElement, behavior: 'smooth' });
                searchStore.setIdOfLastSelection(); // Clear last selection
            }
        }
    }

    const scrollToTop = () => {
        window.scroll({ top: 0 });
    }

    /********* Rendered Components *********/

    const searchResultContent = isLoading || !searchStore.initialSearchRequestCompleted
        ? <div className={classes.loadingWrapper}><LoadingIndicator /></div>
        : searchStore.hasResults
            ? <Container>
                <div id="search-results" className={classes.results}>
                    {searchStore.searchCriteria.isOpportunitySearch
                        ? <OpportunityGrid
                            opportunities={searchStore.opportunities}
                            idPrefix={'search-opportunities'}
                            columns={numColumns}
                            onCardClicked={handleOpportunitySelected}
                        />
                        : <OrganizationGrid
                            organizations={searchStore.organizations}
                            idPrefix={'search-organizations'}
                            columns={numColumns}
                            onCardClicked={handleOrganizationSelected}
                        />
                    }
                    <div className={classes.flex}>
                        <Pagination
                            count={Math.ceil(searchStore.totalCount / searchStore.searchCriteria.numResultsRequested)}
                            page={page}
                            color="primary"
                            className={classes.pagination}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </Container>
            : <ZeroState
                content={
                    <ErrorMessage
                        title={'No results found.'}
                        details={'Try clearing some or all of your filters.'}
                        ButtonProps={{ children: 'Reset Filters', onClick: resetFilters }}
                    />
                }
            />;

    /********* Render *********/

    return (
        <PageWithFooter>
            {smDown
                ? <div className={classes.searchWrapper}>
                    <SearchFilters />
                    {searchResultContent}
                </div>
                : <Container className={classes.searchWrapper}>
                    <SearchFilters />
                    {searchResultContent}
                </Container>
            }
        </PageWithFooter>
    );
});

export default Search;