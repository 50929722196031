
import React, { useContext, useState, useEffect } from "react";
import { observer } from 'mobx-react';
import { RootContext, Skill } from "../../../stores";
import { OptionCategoryCollection } from "../../../stores/models/OptionCategoryCollection";
import { DialogState } from "../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../Shared/Dialogs/ThemedDialogWithSpinner";
import ChipSelectionGrid from "../../Shared/ChipSelectionGrid";
import { useMediaQuery, Theme, Typography, makeStyles, createStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SkillCategoryIconDictionary from "../../../data/SkillCategoryIcons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 'bold'
        },
        alert: {
            marginBottom: theme.spacing(1)
        },
        content: {
            color: theme.palette.action.active
        }
    })
);

interface SkillsDialogProps {
    state: DialogState;
    selectedSkills: Skill[];
    onSelectionsChanged: (skills: Skill[]) => void;
    maxSelections?: number;
}

const SKILLS_DIALOG_ID = 'skills-dialog';

const SkillsDialog = observer((props: SkillsDialogProps) => {

    /***** React hooks *****/
    const classes = useStyles();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const rootStore = useContext(RootContext);
    const skillCategoryStore = rootStore.skillCategoryStore;

    /***** Helper methods *****/

    const getSelectedSkillValues = () => {
        return props.selectedSkills.map(selectedSkill => {
            return {
                value: selectedSkill.skill
            };
        });
    }

    const getSkillOptions = () => {
        const categories = skillCategoryStore.skillCategoryOptions;
        return new OptionCategoryCollection(categories, getSelectedSkillValues());
    };

    /***** State *****/

    const [skillOptions, setSkillOptions] = useState(getSkillOptions());

    /***** Effects *****/

    useEffect(() => {
        if (props.state.open) {
            setSkillOptions(getSkillOptions());
        }
    }, [props.state.open]);

    /***** Event handlers *****/

    const handleConfirmSkills = () => {
        if (!hasError()) {
            const selectedSkills = skillOptions.selectedObjects;
            props.onSelectionsChanged(selectedSkills);
            props.state.setOpen(false);
        } else {
            scrollToTop();
        }
    };

    /***** Helper methods and constants *****/

    const scrollToTop = () => {
        document.querySelector(`#${SKILLS_DIALOG_ID}`)?.scrollTo(0, 0);
    }

    const hasError = () => {
        return props.maxSelections && skillOptions.selections.length > props.maxSelections;
    }

    const hasSkillsListed = (props.selectedSkills.length > 0);

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            title={hasSkillsListed ? 'Edit Skills' : 'Add Skills'}
            primaryButtonProps={{ children: 'Save' }}
            onSubmit={handleConfirmSkills}
            DialogProps={{ fullScreen: smDown }}
            contentId={SKILLS_DIALOG_ID}
        >
            {hasError()
                ? <Alert severity="error" className={classes.alert}>
                    <Typography className={classes.bold}>Too many skills selected.</Typography>
                    <Typography>
                        {`You currently have ${skillOptions.selections.length} skills selected.`}
                    </Typography>
                    <Typography>
                        {`You may select a `}
                        <span className={classes.bold}>maximum of {props.maxSelections} skills.</span>
                    </Typography>
                </Alert>
                : null
            }
            <div className={classes.content}>
                <ChipSelectionGrid optionCollection={skillOptions} iconDictionary={SkillCategoryIconDictionary} />
            </div>
        </ThemedDialogWithSpinner>
    );
});

export default SkillsDialog;