import { useState, useEffect } from 'react';
import { getOrganizationLink, getOrganizationPath, routeWithDescendants } from '../../Links/UrlConstructors';
import useMatchCurrentPath from '../MatchCurrentPath';

export default function useOrganizationLink(organizationId?: number) {

    const matchPath = useMatchCurrentPath();
    const match = matchPath(routeWithDescendants(getOrganizationPath()));
    const [organizationLink, setOrganizationLink] = useState('#');

    useEffect(() => {
        if (organizationId) {
            setOrganizationLink(getOrganizationLink(organizationId));
        } else if (match) {
            setOrganizationLink(match.pathnameBase);
        }
    }, []);

    return organizationLink;
}