import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { StylizedExternalLink } from "../../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            textDecoration: 'underline',
            fontWeight: 500,
        },
        centered: {
            textAlign: 'center',
            margin: theme.spacing(1, 0)
        },
        sectionTitle: {
            fontWeight: 'bold',
            textDecoration: 'underline'
        },
        bold: {
            fontWeight: 'bold'
        },
        indented: {
            marginLeft: theme.spacing(2)
        }
    })
);

const STRIPE_PRIVACY_POLICY_URL = 'https://stripe.com/privacy';
const ESRI_PRIVACY_POLICY_URL = 'https://www.esri.com/en-us/privacy/overview';
const GOOGLE_PRIVACY_POLICY_URL = 'https://policies.google.com/privacy';
const GEOLOCATION_PRIVACY_POLICY_URL = 'https://geolocation-db.com/privacy';
const PROPUBLICA_PRIVACY_POLICY_URL = 'https://www.propublica.org/legal';

const SUPPORT_EMAIL_ADDRESS = 'support@voltagevolunteering.com';

const PrivacyPolicy = () => {

    const classes = useStyles();

    return (
        <div>
            <p className={classes.sectionTitle}>
                SUMMARY
            </p>
            <div>
                <div className={classes.bold}>Section 1: Definitions</div>
                <div className={classes.bold}>Section 2: Nature and Purpose of Information We Collect</div>
                <div className={classes.bold}>Section 3: How We Share Your Information</div>
                <div className={classes.bold}>Section 4: How We Secure Your Information</div>
                <div className={classes.bold}>Section 5: How We Respond to Compelled Disclosure</div>
                <div className={classes.bold}>Section 6: Updates to Our Privacy Policy</div>
                <div className={classes.bold}>Section 7: Contact Us</div>
            </div>
            <p className={classes.sectionTitle}>
                Section 1: Definitions
            </p>
            <p>
                1) “API” refers to a third-party application programming interface.
            </p>
            <p>
                2) “Us”, “We”, “Our” and “Company” refers to Voltage Volunteering, LLC
            </p>
            <p>
                3) “Cookies” are small pieces of data that Our Website stores on a device. Cookies are used
                to improve Users' Website experience and to track analytics and use of the Website, as
                well as to ensure secure use of the Website.
            </p>
            <p>
                4) “Information” is any data, personal or otherwise, that is
                <p className={classes.indented}>
                    a) Transferred from User to Company; and
                </p>
                <p className={classes.indented}>
                    b) Recorded in any form.
                </p>
            </p>
            <p>
                5) “Sensitive Personal Information” means Information that reveals race, ethnic origin,
                sexual orientation, political opinions, religious or philosophical beliefs, trade union
                membership or that concerns an individual's health.
            </p>
            <p>
                6) The “Website” includes all pages, uses, and content found or accessed under the
                voltagevolunteering.com domain name.
            </p>
            <p>
                7) “Organizational User” means any individual or organization accessing the Website for
                the purposes of displaying volunteer opportunities and finding volunteers to participate in
                those opportunities.
            </p>
            <p>
                8) “Volunteer User” means anyone accessing the Website, for the purposes of identifying
                opportunities and participating as a volunteer.
            </p>
            <p>
                9) “User” collectively refers to both Organizational Users and Volunteer Users.
            </p>
            <p className={classes.sectionTitle}>
                Section 2: Nature and Purpose of Information We Collect.
            </p>
            <p>
                1) Any time a User accesses the Website they receive a Cookie from the Company Website.
                The Company uses Cookies to improve the Website and to protect both the User and the
                Company. These Cookies give the Company Information about the Users' use of the
                Website. You can remove or block Cookies using the settings in your browser, but in
                some cases that may impact your ability to use the Website.
            </p>
            <p>
                2) The Website requires Volunteer Users to provide their name, email address, and date of
                birth. The Company saves Volunteer User Information for the purpose of creating an
                account for the Volunteer User and to use that account to sign up for volunteer
                opportunities.
            </p>
            <p>
                3) The Website requires Organizational Users to provide their business name, email,
                employment identification number, and a brief description of the purpose of the business.
                The Company saves Organizational Users information for the purposes of creating an
                account for the Organizational User and to post volunteer opportunities and find willing
                volunteers.
            </p>
            <p>
                4) This Website uses the following APIs, which are subject to the respective providers
                privacy policies:
                <p className={classes.indented}>
                    {`a) Your payment information is sent by you directly to Stripe. We do not access or
                    store any payment information. Information transmitted to Stripe is subject to the `}
                    <StylizedExternalLink to={STRIPE_PRIVACY_POLICY_URL} target="_blank" className={classes.link}>Stripe Privacy Policy</StylizedExternalLink>.
                </p>
                <p className={classes.indented}>
                    {`b) Esri ArcGIS allows the Website to auto-suggest complete addresses for Users.
                    ArcGIS collects address data from Users to properly suggest addresses.
                    Information transmitted to Esri ArcGIS is subject to the `}
                    <StylizedExternalLink to={ESRI_PRIVACY_POLICY_URL} target="_blank" className={classes.link}>Esri ArcGIS Privacy Policy</StylizedExternalLink>.
                </p>
                <p className={classes.indented}>
                    {`c) Google Maps API allows for the embedding of Google Maps on the Website.
                    Information transmitted to Google Maps is subject to the `}
                    <StylizedExternalLink to={GOOGLE_PRIVACY_POLICY_URL} target="_blank" className={classes.link}>Google Privacy Policy</StylizedExternalLink>.
                </p>
                <p className={classes.indented}>
                    {`d) Some Organizational Users may require Volunteer Users to fill out and submit a registration form prior to signing
                    up for a volunteer opportunity. Information submitted through these registration forms is sent to Google Forms and is subject to the `}
                    <StylizedExternalLink to={GOOGLE_PRIVACY_POLICY_URL} target="_blank" className={classes.link}>Google Privacy Policy</StylizedExternalLink>.
                </p>
                <p className={classes.indented}>
                    {`e) Geolocation-db collects Volunteer Users IP address to identify their geolocation
                    and suggest nearby areas where volunteer opportunities exist. Information
                    transmitted to Geolocation-db is subject to the `}
                    <StylizedExternalLink to={GEOLOCATION_PRIVACY_POLICY_URL} target="_blank" className={classes.link}>Geolocation-db Privacy Policy</StylizedExternalLink>.
                </p>
                <p className={classes.indented}>
                    {`f) ProPublica Explorer collects Non-profit Organizational Users employment
                    identification number in order to verify the non-profit status of the organization.
                    Information transmitted to ProPublica is subject to the `}
                    <StylizedExternalLink to={PROPUBLICA_PRIVACY_POLICY_URL} target="_blank" className={classes.link}>ProPublica Privacy Policy</StylizedExternalLink>.
                </p>
            </p>
            <p>
                5) Company does not collect Sensitive Personal Information.
            </p>
            <p className={classes.sectionTitle}>
                Section 3: How We Share Your Information
            </p>
            <p>
                1) Company uses Information provided through Cookies in order to provide and operate the
                Website, to help track use of the Website, and for analytics and performance purposes.
                We share this information with Google Analytics to learn about Website traffic and activity.
            </p>
            <p>
                2) The sharing and use of Information transmitted to the APIs listed in Section 2 is subject
                to those providers respective privacy policies.
            </p>
            <p>
                3) Company uses the Information provided to it by the Users to upload volunteer
                opportunity listings and to connect Organizational and interested Volunteer Users. The
                information provided to the Company by Users will be shared with other Users of the
                Website.
            </p>
            <p>
                4) Company does not sell or rent Information provided to the Company by Users.
            </p>
            <p className={classes.sectionTitle}>
                Section 4: How We Secure Your Information
            </p>
            All parts of the Website are protected by 2048-bit SSL encryption.
            <p className={classes.sectionTitle}>
                Section 5: How We Respond to Compelled Disclosure
            </p>
            Company will not sell or otherwise transfer your Information except where essential for
            providing the Website. However, Company will give out User's Information to comply
            with applicable law or a court order, to prevent harm or injury, or to prevent fraud or
            other illegal activity. Company may be required to share Information with government
            entities from a foreign country. You cannot opt out of this provision or otherwise force
            Company to hold Information confidential in such a way as to cause Company to violate
            any law.
            <p className={classes.sectionTitle}>
                Section 6: Updates to Our Privacy Policy
            </p>
            We may update our privacy policy at any time. Changes to the Privacy Policy will be
            posted on the Website within 30 days of such change.
            <p className={classes.sectionTitle}>
                Section 7: Contact Us
            </p>
            {`Questions, comments or complaints regarding Company's privacy policy or data
            collection and processing practices should be directed to `}
            {SUPPORT_EMAIL_ADDRESS}
        </div>
    )
}

export default PrivacyPolicy;