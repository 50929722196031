import { TextField, makeStyles, Theme, createStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import { observer } from "mobx-react";
import { Alert } from "@material-ui/lab";
import { ForgotPasswordData } from "../../stores/models/ForgotPasswordData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        field: {
            display: 'flex',
            flexGrow: 1,
        },
        instructions: {
            marginBottom: theme.spacing(2)
        },
        alert: {
            marginBottom: theme.spacing(1)
        },
        bold: {
            fontWeight: 'bold'
        }
    }),
);

interface ForgotPasswordFormProps {
    forgotPasswordData: ForgotPasswordData;
    validated: boolean;
    success: boolean;
    warning?: string;
}

const ForgotPasswordForm = observer((props: ForgotPasswordFormProps) => {

    /********** React hooks **********/

    const classes = useStyles();

    /********** Event handlers **********/

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.forgotPasswordData.setEmail(event.target.value);
    }

    /********** Helper functions **********/

    const emailFieldError = props.validated ? props.forgotPasswordData.errors.email : '';

    /********** Render **********/

    return (
        <div>
            {props.success
                ? <Alert severity="success" className={classes.alert}>
                    <div className={classes.bold}>Email sent</div>
                    {`An email with instructions on how to reset your password has been sent to `}
                    <span className={classes.bold}>{props.forgotPasswordData.email}</span>.
                    {` If you don't see the email in your inbox, check your spam or junk folder.`}
                </Alert>
                : props.warning
                    ? <Alert severity="error" className={classes.alert}>
                        {props.warning}
                    </Alert>
                    : null
            }
            {!props.success
                ? <div>
                    <Typography className={classes.instructions}>
                        Enter your email address, and we'll send you a link to reset your password.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="forgot-password-username-input"
                                label="Email"
                                autoComplete="current-username"
                                variant="outlined"
                                required
                                value={props.forgotPasswordData.email}
                                error={emailFieldError.length > 0}
                                helperText={emailFieldError}
                                className={classes.field}
                                onChange={handleEmailChange}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                </div>
                : null
            }
        </div>
    );
});

export default ForgotPasswordForm;