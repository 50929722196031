import { PathPattern, matchPath } from 'react-router-dom';
import { useOrgPortalAgnosticPath } from './OrganizationPortal/OrgPortalAgnosticPath';

// useMatchCurrentPath should be preferred over react-router-dom's useMatch hook or matchPath 
// function since it helps account for prefixes for organization-specific portals.
export default function useMatchCurrentPath() {

    const unprefixedPath = useOrgPortalAgnosticPath();
    const match = (pattern: string | PathPattern<string>) => matchPath(pattern, unprefixedPath);

    return match;
}