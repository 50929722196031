

import { FormControlLabel, Checkbox, FormControlLabelProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { useState } from "react";

interface CheckboxOptionProps extends Partial<FormControlLabelProps> {
    option: string;
    selected?: boolean;
    onCheckboxClicked: (option: string, checked: boolean) => void;
}

const CheckboxOption = observer((props: CheckboxOptionProps) => {

    const [nativeSelected, setNativeSelected] = useState(false);

    const onCheckboxClicked = () => {
        const newValue = props.selected !== undefined ? !props.selected : !nativeSelected;
        setNativeSelected(newValue);
        props.onCheckboxClicked(props.option, newValue);
    }

    /********* Render *********/

    return (
        <FormControlLabel
            value={props.option}
            label={props.option}
            control={
                <Checkbox
                    color='primary'
                    checked={props.selected !== undefined ? props.selected : nativeSelected}
                    onChange={onCheckboxClicked}
                />
            }
            {...props}
        />
    );
});

export default CheckboxOption;