import { TableCell } from "@material-ui/core";
import { useState } from "react";
import { Address } from "../../../stores/models/Address";
import { observer } from "mobx-react";
import { ISortableTableHeader, TableHeader } from "../../../stores/models/TableHeader";
import { NumericallyIdentifiedOptionCollection } from "../../../stores/models/OptionCollection";
import { Option } from "../../../stores/models/Option";
import RecordTable from "../../AccountSettings/Pages/Organization/RecordTable";

enum LocationHeaderCells {
    Select = 'select',
    LineOne = 'lineOne',
    LineTwo = 'lineTwo',
    City = 'city',
    State = 'state',
    ZipCode = 'zipCode',
    Name = 'name'
}

type DisplayFunctionMapping = { [key in LocationHeaderCells]: string | null | undefined };

const getHeadCells = (hideCheckboxColumn?: boolean) => {
    let headCells: ISortableTableHeader<Address>[] = [];
    if (!hideCheckboxColumn) {
        headCells.push({ id: LocationHeaderCells.Select, alignment: 'center', disablePadding: true, label: '', sortable: false, checkbox: true })
    }
    headCells.push(
        { id: LocationHeaderCells.LineOne, alignment: 'left', disablePadding: false, label: 'Line 1', sortable: true, sortValue: 'lineOne' },
        { id: LocationHeaderCells.LineTwo, alignment: 'left', disablePadding: false, label: 'Line 2', sortable: true, sortValue: 'lineTwo' },
        { id: LocationHeaderCells.City, alignment: 'left', disablePadding: false, label: 'City', sortable: true, sortValue: 'city' },
        { id: LocationHeaderCells.State, alignment: 'left', disablePadding: false, label: 'State', sortable: true, sortValue: 'state' },
        { id: LocationHeaderCells.ZipCode, alignment: 'left', disablePadding: false, label: 'Zip Code', sortable: true, sortValue: 'zipCode' },
        { id: LocationHeaderCells.Name, alignment: 'left', disablePadding: false, label: 'Name', sortable: true, sortValue: 'name' }
    );
    return headCells.map(headCell => new TableHeader(headCell));
}

interface LocationSelectionTableProps {
    locationOptions: NumericallyIdentifiedOptionCollection<Address>;
    onEditButtonClicked?: (address: Address) => void;
    hideCheckboxColumn?: boolean;
}

const LocationSelectionTable = observer((props: LocationSelectionTableProps) => {

    const [headCells] = useState(getHeadCells(props.hideCheckboxColumn));

    const getDisplayFunctions = () => {
        let displayText: (address: Address) => DisplayFunctionMapping = (address: Address) => {
            return {
                select: null, // Checkbox
                lineOne: address.lineOne,
                lineTwo: address.lineTwo,
                city: address.city,
                state: address.state,
                zipCode: address.zipCode,
                name: address.name
            }
        }
        let functions = headCells.map(headCell => {
            return (address: Option<Address>, rowIndex: number, cellIndex: number) => {
                return (
                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                        {displayText(address.object)[headCell.id as LocationHeaderCells]}
                    </TableCell>
                )
            }
        });
        return functions;
    }

    const [displayFunctions] = useState(getDisplayFunctions());

    const handleEditButtonClicked = (address: Address) => {
        if (props.onEditButtonClicked) {
            props.onEditButtonClicked(address);
        }
    }

    return (
        <RecordTable
            records={props.locationOptions}
            orderBy={'lineOne'}
            order={'asc'}
            tableHeaderCells={headCells}
            displayFunctions={displayFunctions}
            onRowClicked={(option: Option<Address>, rowIndex: number) => {
                handleEditButtonClicked(option.object)
            }}
        />
    );
});

export default LocationSelectionTable;