import { AxiosResponseHeaders } from "axios";
import { makeAutoObservable } from "mobx";
import { CLIENT_VERSION_HEADER_KEY, VOLTAGE_CLIENT_VERSION } from "../../data/config";
import { parseSemanticVersion } from "../../logic/SemanticVersioning";
import { RootStore } from "../RootStore";
import { APIError, isAPIError } from "./APIError";
import { isCategoryListData } from "./CategoryLists";
import { IServerRole, Role } from "./Role";
import { IServerAddress, ServerAddress } from "./server/ServerAddress";
import { IServerVolunteer, ServerVolunteer } from "./server/ServerVolunteer";
import { IServiceEntryTag, ServiceEntryTag } from "./ServiceEntryTag";
import { isSupplementalOrganizationData } from "./SupplementalOrganizationData";

export class ResponseMiddleware {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    checkForClientVersionUpdate(headers: AxiosResponseHeaders) {
        const latestVersion = headers[CLIENT_VERSION_HEADER_KEY];
        const clientHasUpdates = latestVersion !== VOLTAGE_CLIENT_VERSION;
        this.rootStore.setClientHasUpdates(clientHasUpdates);

        const loadedVersion = parseSemanticVersion(VOLTAGE_CLIENT_VERSION);
        const updatedVersion = parseSemanticVersion(latestVersion);
        return {
            majorVersionUpdate: loadedVersion?.majorVersion !== updatedVersion?.majorVersion,
            minorVersionUpdate: loadedVersion?.minorVersion !== updatedVersion?.minorVersion,
            patchVersionUpdate: loadedVersion?.patchVersion !== updatedVersion?.patchVersion
        }
    }

    checkForAPIError(data: any) {
        const { apiError } = data;
        if (apiError && isAPIError(apiError)) {
            this.rootStore.setAPIError(new APIError(apiError)); 
        }
    }

    parseCategoryLists(data: any) {
        if (!isCategoryListData(data)) return;

        const { categoryLists } = data;
        const { serviceEntryStatuses } = categoryLists;

        // Service entry statuses
        if (serviceEntryStatuses) {
            this.rootStore.volunteerHoursStore.setServiceEntryStatuses(serviceEntryStatuses);
        }
    }

    applyMiddleware(data: any) {

        const organization = this.rootStore.userStore.user.organization;
        if (!isSupplementalOrganizationData(data) || organization === undefined) {
            return;
        }

        const { supplementalOrganizationData } = data;

        const { locations, volunteers, serviceEntryTags, roles, serviceEntryStatuses } = supplementalOrganizationData;
        const { volunteerStatuses, volunteerRegistrationStatuses, shiftOpportunities, organizationPermissions } = supplementalOrganizationData;

        // Locations
        const locationCollection = this.rootStore.userStore.user.organization?.locations;
        if (locations) {
            locationCollection?.setAddresses(locations.map((location: IServerAddress) => new ServerAddress(location).deserialize()));
        }
        // Volunteers
        if (volunteers) {
            organization.setVolunteers(volunteers.map((volunteer: IServerVolunteer) => new ServerVolunteer(volunteer).deserialize()));
        }
        // Organization service tags
        if (serviceEntryTags) {
            organization.setTags(serviceEntryTags.map((tag: IServiceEntryTag) => new ServiceEntryTag(tag)));
        }
        // Roles
        if (roles) {
            organization.setRoles(roles.map((role: IServerRole) => new Role(role)));
        }

        // Service entry statuses
        if (serviceEntryStatuses) {
            this.rootStore.volunteerHoursStore.setServiceEntryStatuses(serviceEntryStatuses);
        }

        // Volunteer registration statuses
        const shiftStore = this.rootStore.shiftStore;
        if (volunteerRegistrationStatuses) {
            shiftStore.volunteerRegistrationStatuses = volunteerRegistrationStatuses;
        }

        // Volunteer statuses
        if (volunteerStatuses) {
            organization.volunteerStatuses = volunteerStatuses;
        }

        // Opportunity shifts
        if (shiftOpportunities) {
            organization.shiftOpportunities = shiftOpportunities;
        }

        // Permissions
        if (organizationPermissions) {
            organization.permissions = organizationPermissions;
        }
    }
}