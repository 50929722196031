import { makeStyles, createStyles, Snackbar, Theme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noBorder: {
            border: 'none'
        },
    })
);

interface SuccessMessageProps {
    open: boolean;
    message: string;
    onClose?: () => void;
}

const SuccessSnackbar = observer((props: SuccessMessageProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={2000}
            onClose={props.onClose}
        >
            <Alert severity="success" variant={'filled'} elevation={6} classes={{ root: classes.noBorder }}>
                {props.message}
            </Alert>
        </Snackbar>
    );
});

export default SuccessSnackbar;