import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import PageWithFooter from "../Shared/PageWithFooter";
import VolunteerInstructions from "./VolunteerInstructions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 0, 1),
        },
    })
);

const VolunteerHelpPage = observer(() => {

    /***** React hooks *****/

    const classes = useStyles();

    /***** Render *****/

    return (
        <PageWithFooter>
            <div className={classes.root}>
                <VolunteerInstructions />
            </div>
        </PageWithFooter>
    )
});

export default VolunteerHelpPage;