import { Option } from "./Option";
import { OptionCollection, IdentifiableObject } from "./OptionCollection";
import { action, observable, autorun, IReactionDisposer, override, makeObservable } from "mobx";

interface ISingleSelectionOptionCollection<T> {
    selection?: Option<T>;
}

export class SingleSelectionOptionCollection<IdentityKey extends string, T extends IdentifiableObject<IdentityKey>, U extends T>
    extends OptionCollection<IdentityKey, T, U>
    implements ISingleSelectionOptionCollection<U>
{
    selection?: Option<U>;

    private reactionDisposer: IReactionDisposer;

    constructor(identifierOrOptionCollection: IdentityKey, optionObjects: U[], selectedOptions?: T[]);
    constructor(identifierOrOptionCollection: OptionCollection<IdentityKey, T, U>);

    constructor(identifierOrOptionCollection: IdentityKey | OptionCollection<IdentityKey, T, U>, optionObjects?: U[], selectedOptions?: T[]) {
        if (typeof identifierOrOptionCollection === 'string') {
            super(identifierOrOptionCollection, optionObjects!, selectedOptions);
        } else {
            super(identifierOrOptionCollection);
        }

        makeObservable(this, {
            selection: observable,
            setSelection: action,
            setSelections: override
        });
        
        this.reactionDisposer = autorun(() => {
            // Automatically reset the selection when too many are selected
            if (this.selections.length === 0) {
                this.setSelection();
            } else if (this.selections.length === 1) {
                this.setSelection(this.selections[0]);
            } else if (this.selections.length > 1 && this.selection) {
                this.selection.toggleSelection();
            }
        });
    }

    setSelection(selection?: Option<U>) {
        this.selection = selection;
    }

    setSelections(selections: T[]) {
        if (selections.length > 1) {
            throw new Error('Only one option can be selected.');
        }
        this.options.forEach(option => {
            const selected = selections.findIndex(selection => selection[this.identifier] === option.object[this.identifier]) !== -1;
            if (option.selected !== selected) {
                option.toggleSelection();
                if (selected) {
                    this.selection = option;
                }
            }
        })
    }
}
