import { To, useMatch } from "react-router-dom"
import { BasePathSegments, URITokens, buildPath } from "../../Links";

export const ORG_PORTAL_PATH_PATTERN = buildPath([BasePathSegments.OrganizationSpecificPortal, URITokens.Organization, '*']);

// Gets the organization-specific portal URI prefix if there is one.
export const useOrgPortalPrefix = (to?: To) => {

    const match = useMatch(ORG_PORTAL_PATH_PATTERN);

    let link: To = to || "";
    
    if (match) {
        if (to === undefined) {
            link = match.pathnameBase;
        } else {
            link = match.pathnameBase + to.toString();
        }
    } else {
        link = to?.toString() || "";
    }

    return link;
}