import clsx from 'clsx';
import { observer } from 'mobx-react'
import {
    makeStyles,
    Drawer,
    createStyles,
    Theme,
    useMediaQuery,
    Box,
} from "@material-ui/core";
import MenuSidebarList from "./MenuSidebarList";
import { MenuItem } from "../../../stores/models/MenuItem";

export const SIDEBAR_DRAWER_WIDTH = 250;
export const SIDEBAR_ICON_ONLY_DRAWER_WIDTH = 56;
export const SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT = 'sm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hide: {
            display: 'none',
        },
        drawerWrapper: {
            position: 'fixed',
            bottom: 0,
            top: 0,
            display: 'flex',
            zIndex: theme.zIndex.drawer
        },
        drawer: {
            transition: theme.transitions.create('width'),
            [theme.breakpoints.only(SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT)]: {
                width: SIDEBAR_ICON_ONLY_DRAWER_WIDTH
            },
            width: SIDEBAR_DRAWER_WIDTH,
            height: '100%',
        },
        drawerPaper: {
            transition: theme.transitions.create('width'),
            [theme.breakpoints.only(SIDEBAR_ICON_ONLY_DRAWER_BREAKPOINT)]: {
                width: SIDEBAR_ICON_ONLY_DRAWER_WIDTH
            },
            width: SIDEBAR_DRAWER_WIDTH,
            position: 'static',
            background: theme.palette.darkBackground.main,
            color: 'white',
            overflowX: 'hidden'
        },
        menu: {
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        toolbar: theme.mixins.toolbar
    }),
);

export const SIDEBAR_MENU_ID = 'menu-sidebar';

interface MenuSidebarProps {
    menuItems: MenuItem[];
}

const MenuSidebar = observer((props: MenuSidebarProps) => {

    const classes = useStyles();
    const smOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));

    return (
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div id={SIDEBAR_MENU_ID} className={classes.drawerWrapper}>
                <Drawer
                    variant="permanent"
                    anchor="left"
                    className={classes.drawer}
                    classes={{
                        paper: clsx(classes.drawerPaper),
                    }}
                >
                    <div className={classes.toolbar} />
                    <div className={classes.menu}>
                        <MenuSidebarList menuItems={props.menuItems} iconsOnly={smOnly} />
                    </div>
                </Drawer>
            </div >
        </Box>
    );
});

export default MenuSidebar;