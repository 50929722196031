import React from "react";
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        zeroState: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center'
        }
    }));

interface ZeroStateProps {
    content: string | React.ReactNode;
}

const ZeroState = (props: ZeroStateProps) => {

    // React hooks
    const classes = useStyles();

    return (
        <div className={classes.zeroState}>
            {typeof props.content === 'string'
                ? <Typography>{props.content}</Typography>
                : props.content
            }
        </div>
    );
};

export default ZeroState;