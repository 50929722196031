import { formatDuration } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import { UniqueIdentification } from "../../../../logic/UniqueIdentification";
import { validateServiceEntry } from "../../../../logic/ValidationChecks/ServiceEntryValidation";
import { IArchivable } from "../../../../stores/models/Archivable";
import { Fields } from "../../../../stores/models/Fields";
import { ServiceEntryTag } from "../../../../stores/models/ServiceEntryTag";
import { Volunteer } from "../../../../stores/models/Volunteer";
import { Account, AccountEdit, Calendar, Domain } from "mdi-material-ui";
import { IStatus } from "../../../../stores/models/Status";
import { ServiceEntrySourceTypes } from "./ServiceEntrySourceTypes";
import { UTCDateComponents } from "../../../../logic/DateFormatter";

export const isClientServiceEntry = (x: any): x is IServiceEntry => {
    if (typeof x === 'object') {
        return (x as { volunteers: Volunteer[] }).volunteers !== undefined;
    } else {
        return false;
    }
}

interface IServiceEntryFields {
    organizationId?: number;
    volunteers: Volunteer[];
    tags: ServiceEntryTag[];
    date: string | undefined;
    duration: number;
    statusId: number;
    notes: string;
}

export interface IServiceEntry extends IServiceEntryFields, IArchivable {
    id: number;
    opportunityId: number;
    position: string;
    shiftId?: number;
    defaultDaysFromStartDate?: number;
    lastOfficialEntry?: ServiceEntry;
    hasVolunteerEdits?: boolean;
    lastUpdateId?: number;
    utcDateComponents?: UTCDateComponents;
}

export class ServiceEntry extends Fields<IServiceEntryFields, ServiceEntry> implements IServiceEntry {
    @observable id: number = -1;
    @observable organizationId: number | undefined;
    @observable volunteers: Volunteer[] = [];
    @observable opportunityId: number = -1;
    @observable position: string = '';
    @observable shiftId?: number;
    @observable defaultDaysFromStartDate?: number;
    @observable tags: ServiceEntryTag[] = [];
    @observable date: string | undefined = undefined;
    @observable utcDateComponents: UTCDateComponents | undefined = undefined;
    @observable duration: number = 3600;
    @observable statusId: number = 2;
    @observable notes: string = '';
    @observable archived?: boolean;
    @observable lastOfficialEntry?: ServiceEntry;
    @observable hasVolunteerEdits?: boolean;
    @observable lastUpdateId?: number;

    @observable volunteerEntry: boolean = false;

    constructor(serviceEntry?: IServiceEntry) {
        super();

        makeObservable(this);

        if (serviceEntry) {
            this.id = serviceEntry.id === -1 ? UniqueIdentification.getClientId() : serviceEntry.id;
            this.organizationId = serviceEntry.organizationId;
            this.volunteers = serviceEntry.volunteers;
            this.opportunityId = serviceEntry.opportunityId;
            this.position = serviceEntry.position;
            this.shiftId = serviceEntry.shiftId;
            this.defaultDaysFromStartDate = serviceEntry.defaultDaysFromStartDate;
            this.tags = serviceEntry.tags;
            this.date = serviceEntry.date;
            this.utcDateComponents = serviceEntry.utcDateComponents;
            this.duration = serviceEntry.duration;
            this.statusId = serviceEntry.statusId;
            this.notes = serviceEntry.notes;
            this.archived = serviceEntry.archived;
            this.lastOfficialEntry = serviceEntry.lastOfficialEntry ? new ServiceEntry(serviceEntry.lastOfficialEntry) : undefined;
            this.hasVolunteerEdits = serviceEntry.hasVolunteerEdits;
            this.lastUpdateId = serviceEntry.lastUpdateId;
        }
    }

    @computed get validationErrors() {
        return validateServiceEntry(this);
    }

    @computed get volunteerName() {
        if (this.volunteers.length === 1) {
            const volunteer = this.volunteers[0];
            if (typeof volunteer !== 'number') {
                return `${volunteer.firstName} ${volunteer.lastName}`;
            }
        }
    }

    @computed get volunteerId() {
        if (this.volunteers.length === 1) {
            const volunteer = this.volunteers[0];
            if (typeof volunteer === 'number') {
                return volunteer;
            } else {
                return volunteer.id;
            }
        }
    }

    @computed get volunteerIds() {
        return this.volunteers.map(volunteer => volunteer.id);
    }

    getStatusText(serviceEntryStatuses: IStatus[]) {
        return this.duration === 0 ? 'Deleted by volunteer' : serviceEntryStatuses.find(status => status.id === this.statusId)?.status;
    }

    @computed get source() {
        if (this.hasVolunteerEdits) {
            return this.lastOfficialEntry ? ServiceEntrySourceTypes.VolunteerEdits : ServiceEntrySourceTypes.Volunteer;
        } else {
            return this.id < 0 ? ServiceEntrySourceTypes.Shift : ServiceEntrySourceTypes.Organization;
        }
    }

    @computed get sourceDescriptor() {
        switch (this.source) {
            case ServiceEntrySourceTypes.Organization:
                return 'Last modified by staff';
            case ServiceEntrySourceTypes.Shift:
                return 'Automatically generated from shift';
            case ServiceEntrySourceTypes.Volunteer:
                return 'Added by volunteer';
            case ServiceEntrySourceTypes.VolunteerEdits:
                return 'Last modified by volunteer';
        }
    }

    @computed get sourceIcon() {
        switch (this.source) {
            case ServiceEntrySourceTypes.Organization:
                return Domain;
            case ServiceEntrySourceTypes.Shift:
                return Calendar;
            case ServiceEntrySourceTypes.Volunteer:
                return Account;
            case ServiceEntrySourceTypes.VolunteerEdits:
                return AccountEdit;
        }
    }

    @computed get entryDeletedByVolunteer() {
        return this.duration === 0 && this.lastOfficialEntry !== undefined;
    }

    @action setId(id: number) {
        this.id = id;
    }

    @action setVolunteerEntry(volunteerEntry: boolean) {
        this.volunteerEntry = volunteerEntry;
    }

    @action setDate(date: string | undefined) {
        this.date = date;
    }

    @action setDuration(hours: number) {
        this.duration = hours * 3600;
    }

    @action setVolunteers(volunteers: Volunteer[]) {
        this.volunteers = volunteers;
    }

    @action setStatusId(statusId: number) {
        this.statusId = statusId;
    }

    @action setNotes(notes: string) {
        this.notes = notes;
    }

    @action setOrganizationId(organizationId?: number) {
        this.organizationId = organizationId;
    }

    @action setOpportunityId(opportunityId?: number) {
        this.opportunityId = opportunityId || -1;
    }

    @action setArchived(archived: boolean) {
        this.archived = archived;
    }

    @action addTags(newTags: ServiceEntryTag[]) {
        let tagsToAdd = [] as ServiceEntryTag[];
        newTags.forEach(newTag => {
            if (this.tags.findIndex(tag => tag.id === newTag.id) === -1) {
                tagsToAdd.push(newTag);
            }
        });
        this.tags = this.tags.concat(tagsToAdd);
    }

    @action clearTags() {
        this.tags = [];
    }

    @action removeTag(tagToRemove: ServiceEntryTag) {
        this.tags = this.tags.filter(tag => tag.id !== tagToRemove.id);
    }

    @action createNewTag(tag: string) {
        this.tags = this.tags.concat({ id: -1, tag: tag });
    }

    @computed get formattedDuration() {
        const durationInHours = parseFloat((this.duration / (60 * 60)).toFixed(2));
        return formatDuration(
            { hours: durationInHours },
            { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ', ' }
        );
    }

    static volunteerFieldsMatch(serviceEntry1: ServiceEntry, serviceEntry2: ServiceEntry): boolean {
        return (
            serviceEntry1.organizationId === serviceEntry2.organizationId &&
            serviceEntry1.opportunityId === serviceEntry2.opportunityId &&
            serviceEntry1.date === serviceEntry2.date &&
            serviceEntry1.duration === serviceEntry2.duration &&
            serviceEntry1.archived === serviceEntry2.archived
        );
    }

    private static entryTagsMatch(tags1: ServiceEntryTag[], tags2: ServiceEntryTag[]): boolean {
        if (tags1.length !== tags2.length) return false;
        for (let i = 0; i < tags1.length; i++) {
            if (tags2.findIndex(tag => tag.id === tags1[i].id) === -1) {
                return false;
            }
        }
        return true;
    }

    static recruiterFieldsMatch(serviceEntry1: ServiceEntry, serviceEntry2: ServiceEntry): boolean {
        return (
            ((serviceEntry1.opportunityId === serviceEntry2.opportunityId
                || ((serviceEntry1.opportunityId === null || serviceEntry1.opportunityId < 0) &&
                    (serviceEntry2.opportunityId === null || serviceEntry2.opportunityId < 0))) &&
                ServiceEntry.entryTagsMatch(serviceEntry1.tags, serviceEntry2.tags) &&
                serviceEntry1.date === serviceEntry2.date &&
                serviceEntry1.duration === serviceEntry2.duration &&
                serviceEntry1.statusId === serviceEntry2.statusId &&
                serviceEntry1.notes === serviceEntry2.notes
            )
        );
    }
}