import { observer } from 'mobx-react'
import { Route, Routes } from 'react-router-dom';
import TopLevelRoutes from './TopLevelRoutes';
import OrgSpecificRoutes from './OrgSpecificRoutes';
import { ORG_PORTAL_PATH_PATTERN } from '../Hooks';
import { TopLevelPathSegments, buildPath } from '../Links';
import LinkRedirecter from '../LinkRedirecter';

const BaseRoutes = observer(() => {

    /********* Render *********/

    return (
        <Routes>
            <Route path={buildPath([TopLevelPathSegments.Redirecter, '*'])} element={<LinkRedirecter />} />
            <Route path={ORG_PORTAL_PATH_PATTERN} element={<OrgSpecificRoutes />} />
            <Route path={"/*"} element={<TopLevelRoutes />} />
        </Routes>
    );
});

export default BaseRoutes;