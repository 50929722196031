import React from "react";
import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Typography, Avatar } from "@material-ui/core";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { AccountRemove, HumanMaleGirl } from "mdi-material-ui";
import { mdiConnection } from "@mdi/js";
import MdiIcon from "../../Shared/MdiIcon";
import RadioList, { RadioListOption } from "../../Organization/VolunteerOpportunities/RadioList";
import { VolunteerRelation } from "../../../stores/models/OrganizationStubVolunteerLinking";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginBottom: theme.spacing(1),
            maxWidth: 700,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        radioListWrapper: {
            '& > ul': {
                display: 'flex',
                justifyContent: 'center'
            }
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
    })
);

const volunteerRelationOptions: RadioListOption[] = [{
    primaryText: `That's me!`,
    value: VolunteerRelation.Volunteer,
    icon: EmojiPeopleIcon
}, {
    primaryText: `I'm a contact for the volunteer`,
    value: VolunteerRelation.Contact,
    icon: HumanMaleGirl
}, {
    primaryText: `I'm neither the volunteer nor a contact for the volunteer`,
    value: VolunteerRelation.Neither,
    icon: AccountRemove
}];

interface VolunteerRelationSelectionProps {
    relation: VolunteerRelation;
    onRelationChanged: (relation: VolunteerRelation) => void;
}

const VolunteerRelationSelection = observer((props: VolunteerRelationSelectionProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <MdiIcon iconPath={mdiConnection} fontSize='large' />
            </Avatar>
            <Typography variant="h5" className={classes.prompt}>What is your connection to this volunteer?</Typography>
            <div className={classes.radioListWrapper}>
                <RadioList
                    selectedValue={props.relation}
                    options={volunteerRelationOptions}
                    onValueChanged={(value) => props.onRelationChanged(value as VolunteerRelation)}
                />
            </div>
        </React.Fragment>
    );
});

export default VolunteerRelationSelection;