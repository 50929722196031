import { action, computed, makeObservable, observable } from "mobx";
import { Volunteer } from ".";
import { Dictionary } from "../../logic/Dictionaries";
import validateVolunteerDetails from "../../logic/ValidationChecks/VolunteerDetailsValidation";
import { validateVolunteerAsOrganizationUser } from "../../logic/ValidationChecks/VolunteerValidation";
import { Fields } from "./Fields";
import { IPermission, Permission } from "./Permission";
import { IRole, Role } from "./Role";
import { IServerServiceEntry, ServerServiceEntry } from "./server/ServerServiceEntry";
import { IServerShift, isServerShift, ServerShift } from "./server/ServerShift";
import { ServerVolunteer } from "./server/ServerVolunteer";
import { isClientServiceEntry, IServiceEntry, ServiceEntry } from "../../modules/volunteer-hours/data-access/entities/ServiceEntry";
import { Shift } from "./Shift";
import { ValidationError } from "./ValidationError";

export type ShiftOpportunityData = Dictionary<number, { position: string, opportunityId: number }>;

interface IVolunteerDetailsFields {
    email?: string;
}

export interface IVolunteerDetails extends IVolunteerDetailsFields {
    volunteer: Volunteer;
    status: string;
    registrationFormStatus?: string;
    permissions: IPermission[];
    roles: IRole[];
    serviceEntries: IServiceEntry[];
    shiftOpportunityMapping: ShiftOpportunityData;
    shifts: Shift[];
}

export interface IServerVolunteerDetails {
    id: number;
    firstName: string;
    lastName: string;
    birthdate: Date;
    email?: string;
    status: string;
    registrationFormStatus?: string;
    permissions: IPermission[];
    roles: IRole[];
    serviceEntries: IServerServiceEntry[];
    shiftOpportunityMapping: ShiftOpportunityData;
    shifts: IServerShift[];
}

export class VolunteerDetails extends Fields<IVolunteerDetailsFields, VolunteerDetails> implements IVolunteerDetails {
    @observable volunteer: Volunteer;
    @observable email?: string;
    @observable status: string = '';
    @observable registrationFormStatus?: string = '';
    @observable permissions: Permission[] = [];
    @observable roles: Role[] = [];
    @observable serviceEntries: ServiceEntry[] = [];
    @observable shiftOpportunityMapping: ShiftOpportunityData = {}; // TODO: Does this need to be part of this object?
    @observable shifts: Shift[] = [];

    constructor(volunteerDetails?: IVolunteerDetails | IServerVolunteerDetails) {
        super();

        makeObservable(this);

        if (volunteerDetails === undefined) {
            this.volunteer = new Volunteer();
        } else {
            if ('volunteer' in volunteerDetails) {
                this.volunteer = new Volunteer(volunteerDetails.volunteer);
            } else {
                this.volunteer = new ServerVolunteer(volunteerDetails).deserialize();
            }
            this.volunteer.setValidationChecks(validateVolunteerAsOrganizationUser);
            this.email = volunteerDetails.email;
            this.status = volunteerDetails.status;
            this.registrationFormStatus = volunteerDetails.registrationFormStatus;
            this.shiftOpportunityMapping = volunteerDetails.shiftOpportunityMapping;
            this.shifts = (volunteerDetails.shifts as (IServerShift | Shift)[])?.map(shift => {
                if (isServerShift(shift)) {
                    return new ServerShift(shift).deserialize();
                } else {
                    return new Shift(shift);
                }
            });
            this.permissions = volunteerDetails.permissions?.map(permission => new Permission(permission));
            this.roles = volunteerDetails.roles?.map(role => new Role(role));
            if (volunteerDetails.serviceEntries?.length > 0) {
                if (isClientServiceEntry(volunteerDetails.serviceEntries[0])) {
                    this.serviceEntries = (volunteerDetails.serviceEntries as IServiceEntry[])?.map(serviceEntry => new ServiceEntry(serviceEntry));
                } else {
                    this.serviceEntries = (volunteerDetails.serviceEntries as IServerServiceEntry[])?.map(serviceEntry => new ServerServiceEntry(serviceEntry).deserialize());
                }
            } else {
                this.serviceEntries = [];
            }
        }
    }

    @action setEmail(email: string) {
        this.email = email;
    }

    @action setStatus(status: string) {
        this.status = status;
    }

    @action setRegistrationFormStatus(registrationFormStatus: string) {
        this.registrationFormStatus = registrationFormStatus;
    }

    @action setPermissions(permissions: Permission[]) {
        this.permissions = permissions;
    }

    @action setRoles(roles: Role[]) {
        this.roles = roles;
    }

    @action setShifts(shifts: Shift[]) {
        this.shifts = shifts;
    }

    @computed get upcomingShifts() {
        const registeredShifts = this.shifts.flatMap(shift => {
            return shift.shiftRegistrations?.map(shiftRegistration => {
                if (shift.identificationData.topLevelId === shiftRegistration.shiftIdentification.topLevelId) {
                    const registrationContainsVolunteer = shiftRegistration?.volunteerRegistrations.findIndex(volunteerRegistration => volunteerRegistration.volunteerId === this.volunteer.id) !== -1;
                    if (registrationContainsVolunteer) {
                        const shiftInstance = shift.getInstanceByIdentificationData(shiftRegistration.shiftIdentification);
                        if (!shift.archived && !shiftInstance?.instanceData?.deleted && !shiftInstance?.repetitionPattern.hasEnded) {
                            return shiftInstance;
                        }
                    }
                }
            })
        }).filter(element => element !== undefined) as Shift[];
        return registeredShifts;
    }

    @computed get validationErrors(): ValidationError<VolunteerDetails>[] {
        return validateVolunteerDetails(this);
    }

    @computed get validated(): boolean {
        return this.validationErrors.length === 0 && this.volunteer.validated;
    }

    serialize() {
        return {
            volunteer: this.volunteer.serialize(),
            email: this.email && this.email.trim().length > 0 ? this.trimmed.email : undefined,
            status: this.status,
            registrationFormStatus: this.registrationFormStatus,
            permissionIds: this.permissions.map(permission => permission.id),
            roleIds: this.roles.map(role => role.id)
        }
    }
}