import { observable, action, makeObservable, computed } from "mobx";
import { Fields } from "./Fields";
import { validateVolunteerShiftRegistration } from "../../logic/ValidationChecks/VolunteerShiftRegistrationValidation";

export interface IVolunteerShiftRegistration {
    volunteerId: number
    firstName?: string;
    lastName?: string;
    email?: string;
    status?: string;
}

export class VolunteerShiftRegistration extends Fields<IVolunteerShiftRegistration, VolunteerShiftRegistration> implements IVolunteerShiftRegistration {
    @observable volunteerId: number;
    @observable firstName? = '';
    @observable lastName? = '';
    @observable email? = '';
    @observable status?: string;

    constructor(volunteerShiftRegistration: IVolunteerShiftRegistration) {
        super();

        makeObservable(this);

        this.volunteerId = volunteerShiftRegistration.volunteerId;
        this.firstName = volunteerShiftRegistration.firstName;
        this.lastName = volunteerShiftRegistration.lastName;
        this.email = volunteerShiftRegistration.email;
        this.status = volunteerShiftRegistration.status;
    }

    @action setEmail(email: string) {
        this.email = email;
    }

    @action setFirstName(firstName: string) {
        this.firstName = firstName;
    }

    @action setLastName(lastName: string) {
        this.lastName = lastName;
    }

    @action setStatus(status: string) {
        this.status = status;
    }

    @computed get volunteerName() {
        return `${this.firstName} ${this.lastName}`;
    }

    @computed get validationErrors() {
        return validateVolunteerShiftRegistration(this);
    }

    serialize() {
        let trimmed: VolunteerShiftRegistration = this.trimmed;
        return {
            volunteerId: trimmed.volunteerId,
            email: trimmed.email,
            firstName: trimmed.firstName,
            lastName: trimmed.lastName,
            status: trimmed.status
        }
    }
}