import { TextField, makeStyles, Theme, createStyles } from "@material-ui/core";
import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements
} from '@stripe/react-stripe-js';
import StripeFieldWrapper, { PaymentFieldChangedEvent } from "./StripeFieldWrapper";
import { PaymentMethod } from "../../stores/models/PaymentMethod";
import { ValidationError } from "../../stores/models/ValidationError";
import ZipCodeInput from "./ZipCodeInput";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            display: 'flex',
            transition: theme.transitions.create('width'),
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
            }
        },
        wrapper: {
            flexGrow: 1,
            flexShrink: 1,
            minWidth: '160px',
            padding: theme.spacing(1),
            boxSizing: 'border-box'
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        row: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > $wrapper': {
                flexGrow: 1,
                flexBasis: '33.33%'
            }
        },
        zipCodeField: {
            display: 'flex'
        }
    }),
);

const stripeFieldStyle = {
    base: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        letterSpacing: '0.00938em',
        textTransform: 'capitalize'
    }
};

interface PaymentEntryProps {
    paymentMethod: PaymentMethod;
    displayErrors: boolean;
    isLoading: boolean;
}

// TODO: When the user types in a Stripe field and hits enter, that field doesn't validate until after
// the user leaves it. Maybe unfocusing the field before validation and then refocusing on it will fix the issue.

const PaymentEntry = observer((props: PaymentEntryProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const elements = useElements();

    /********* Effects *********/

    // Get and save a reference to the Stripe card number element
    useLayoutEffect(() => {
        if (!props.paymentMethod.cardElement) {
            const element = elements?.getElement('cardNumber');
            if (element) {
                props.paymentMethod.setCardElement(element);
            }
        }
    }, []);

    /********* Event handlers *********/

    // Handles a change to a Stripe field containing credit card details
    const handleCardFieldChange = (field: keyof PaymentMethod, event: PaymentFieldChangedEvent) => {
        const error = event.errorMessage && event.errorMessage.length > 0
            ? new ValidationError(event.errorMessage, [field])
            : undefined;
        const empty = event.empty;
        props.paymentMethod.setCardDetailErrors({ ...props.paymentMethod.cardDetailErrors, [field]: error });
        props.paymentMethod.setCardDetailEmpty({ ...props.paymentMethod.cardDetailEmpty, [field]: empty });
    }

    /********* Helper constants *********/

    let nameError = '';
    let cardNumberError = '';
    let expirationDateError = '';
    let cvcError = '';
    let zipCodeError = '';

    if (props.displayErrors) {
        nameError = props.paymentMethod.errors.name;
        cardNumberError = props.paymentMethod.cardDetailErrors['cardNumber']?.message
            ? props.paymentMethod.cardDetailErrors['cardNumber'].message
            : props.paymentMethod.errors.cardNumber;
        expirationDateError = props.paymentMethod.cardDetailErrors['expirationDate']?.message
            ? props.paymentMethod.cardDetailErrors['expirationDate'].message
            : props.paymentMethod.errors.expirationDate;
        cvcError = props.paymentMethod.cardDetailErrors['cvc']?.message
            ? props.paymentMethod.cardDetailErrors['cvc'].message
            : props.paymentMethod.errors.cvc;
        zipCodeError = props.paymentMethod.errors.zipCode;
    }

    /********* Render *********/

    return (
        <div className={classes.flex}>
            <div className={classes.wrapper}>
                <TextField
                    id="standard-cardholder-name-input"
                    label="Name on Card"
                    variant="outlined"
                    value={props.paymentMethod.name}
                    onChange={(event) => props.paymentMethod.setPaymentMethodField('name', event.target.value)}
                    className={classes.textField}
                    error={nameError.length > 0}
                    helperText={nameError}
                    disabled={props.isLoading}
                    required
                    autoFocus
                />
            </div>
            <div className={classes.wrapper}>
                <StripeFieldWrapper
                    label="Card Number"
                    stripeElementType={CardNumberElement}
                    stripeField={
                        <CardNumberElement
                            options={{
                                showIcon: true,
                                style: stripeFieldStyle,
                                disabled: props.isLoading
                            }}
                        />}
                    TextFieldProps={{
                        error: cardNumberError.length > 0,
                        helperText: cardNumberError,
                        disabled: props.isLoading,
                        required: true
                    }}
                    onFieldChanged={(event) => handleCardFieldChange('cardNumber', event)}
                />
            </div>
            <div className={classes.row}>
                <div className={classes.wrapper}>
                    <StripeFieldWrapper
                        label="Expiration Date"
                        stripeElementType={CardExpiryElement}
                        stripeField={
                            <CardExpiryElement
                                options={{
                                    style: stripeFieldStyle,
                                    disabled: props.isLoading
                                }}
                            />}
                        TextFieldProps={{
                            error: expirationDateError.length > 0,
                            helperText: expirationDateError,
                            disabled: props.isLoading,
                            required: true
                        }}
                        onFieldChanged={(event) => handleCardFieldChange('expirationDate', event)}
                    />
                </div>
                <div className={classes.wrapper}>
                    <StripeFieldWrapper
                        label="CVC"
                        stripeElementType={CardCvcElement}
                        stripeField={
                            <CardCvcElement
                                options={{
                                    placeholder: 'XXX',
                                    style: stripeFieldStyle,
                                    disabled: props.isLoading
                                }}
                            />}
                        TextFieldProps={{
                            error: cvcError.length > 0,
                            helperText: cvcError,
                            disabled: props.isLoading,
                            required: true
                        }}
                        onFieldChanged={(event) => handleCardFieldChange('cvc', event)}
                    />
                </div>
                <div className={classes.wrapper}>
                    <ZipCodeInput
                        id="standard-zip-code-input"
                        variant="outlined"
                        value={props.paymentMethod.zipCode}
                        onChange={(event) => props.paymentMethod.setPaymentMethodField('zipCode', event.target.value)}
                        error={zipCodeError.length > 0}
                        helperText={zipCodeError}
                        disabled={props.isLoading}
                        required
                        className={classes.zipCodeField}
                    />
                </div>
            </div>
        </div>
    );
});

export default PaymentEntry;