import { makeStyles, Theme, createStyles, Typography, Tooltip, IconButton } from "@material-ui/core";
import { observer } from "mobx-react";
import SaveIcon from '@material-ui/icons/Save';
import { Eye, Pencil } from "mdi-material-ui";
import { InternalLink } from "../../../Navigation/Components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerButtons: {
            flexShrink: 0
        },
    })
);

interface EditorHeaderButtonsProps {
    editing: boolean;
    viewTooltip?: string;
    viewLink?: string;
    onEdit: () => void;
    onSave: () => void;
}

const EditorHeaderButtons = observer((props: EditorHeaderButtonsProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    return (
        <div className={classes.headerButtons}>
            {props.viewLink && props.viewTooltip
                ? <Tooltip title={<Typography>{props.viewTooltip}</Typography>} arrow>
                    <InternalLink
                        target="_blank"
                        to={props.viewLink}
                    >
                        <IconButton>
                            <Eye />
                        </IconButton>
                    </InternalLink>
                </Tooltip>
                : null
            }
            {props.editing
                ? <Tooltip title={<Typography>Save</Typography>} arrow>
                    <IconButton onClick={props.onSave}>
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                : <Tooltip title={<Typography>Edit</Typography>} arrow>
                    <IconButton onClick={props.onEdit}>
                        <Pencil />
                    </IconButton>
                </Tooltip>
            }
        </div>
    );
});

export default EditorHeaderButtons;