import React, { useState } from "react";
import { observer } from 'mobx-react'
import { Tabs, Tab, makeStyles, Theme, createStyles, Divider, Container, Typography, useMediaQuery } from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";
import OrganizationSparks from "./OrganizationSparks";
import OpportunitySparks from "./OpportunitySparks";
import { Domain, ClipboardMultipleOutline } from "mdi-material-ui";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        title: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
            },
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        indicator: {
            height: '2px',
            background: theme.palette.accent.main
        },
        tabRoot: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: theme.spacing(1),
                paddingLeft: theme.spacing(1)
            }
        },
        tabWrapper: {
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row'
            }
        },
        selectedTab: {
            color: theme.palette.accent.main
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        labelIcon: {
            [theme.breakpoints.up('sm')]: {
                minHeight: 0
            }
        },
        divider: {
            marginTop: '-2px',
            height: 2
        },
        tabPanel: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: `${theme.spacing(3)}px 0`
        },
        sparksWrapper: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column'
        }
    })
);

enum SparkTabs {
    Organizations = 'Organizations',
    Opportunities = 'Opportunities'
}

const Sparks = observer(() => {

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const [selectedTab, setSelectedTab] = useState(SparkTabs.Opportunities);

    const handleTabSelectionChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedTab(newValue as SparkTabs);
    }

    return (
        <div className={classes.root}>
            <TabContext value={selectedTab}>
                {/* Header */}
                <Container className={classes.sparksWrapper}>
                    <Typography variant="h4" className={classes.title}>Sparks</Typography>
                    <Tabs
                        centered={xsDown}
                        value={selectedTab}
                        onChange={handleTabSelectionChange}
                        classes={{ indicator: classes.indicator }}
                    >
                        <Tab
                            value={SparkTabs.Opportunities}
                            label={SparkTabs.Opportunities}
                            icon={<ClipboardMultipleOutline className={classes.icon} />}
                            classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, labelIcon: classes.labelIcon, selected: classes.selectedTab }}
                        />
                        <Tab
                            value={SparkTabs.Organizations}
                            label={SparkTabs.Organizations}
                            icon={<Domain className={classes.icon} />}
                            classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, labelIcon: classes.labelIcon, selected: classes.selectedTab }}
                        />
                    </Tabs>
                    <Divider className={classes.divider} variant={xsDown ? 'middle' : 'fullWidth'} />
                    {/* Tab Content */}
                    <TabPanel value={SparkTabs.Opportunities} className={selectedTab === SparkTabs.Opportunities ? classes.tabPanel : undefined}>
                        <div className={classes.sparksWrapper}>
                            <OpportunitySparks />
                        </div>
                    </TabPanel>
                    <TabPanel value={SparkTabs.Organizations} className={selectedTab === SparkTabs.Organizations ? classes.tabPanel : undefined}>
                        <div className={classes.sparksWrapper}>
                            <OrganizationSparks />
                        </div>
                    </TabPanel>
                </Container>
            </TabContext>
        </div >
    );
});

export default Sparks;