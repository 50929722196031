import { Opportunity } from "../../stores";
import CardGridItem from "./CardGridItem";
import { ReactNode } from "react";
import { getOpportunityLink } from "../Navigation/Links/UrlConstructors";
import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { CalendarOutline, CalendarMultiselect, MapMarkerRadius, Airballoon, Autorenew, Gesture, Ring } from "mdi-material-ui";
import { OpportunityLocation, OpportunityNeed, OpportunityScheduling, TimeCommitment } from "../../stores/models/Opportunity";
import { useNavigateInternally } from "../Navigation/Hooks";
import REMChip from "../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles(theme => ({
    chip: {
        marginBottom: '4px',
        backgroundColor: theme.palette.primaryBackground.main,
        '& .MuiChip-icon, .MuiChip-label': {
            color: theme.palette.darkBackground.main
        }
    },
}));

interface OpportunityGridProps {
    opportunities: Opportunity[];
    idPrefix: string;
    columns: 1 | 2 | 3 | 4;
    onCardClicked?: (cardId: string, opportunity: Opportunity) => void;
}

const OpportunityGrid = observer((props: OpportunityGridProps) => {

    const classes = useStyles();
    const navigate = useNavigateInternally();

    /********* Event handlers *********/

    const handleCardClicked = (cardId: string, opportunity: Opportunity) => {
        if (props.onCardClicked) {
            props.onCardClicked(cardId, opportunity);
        } else {
            navigate(getOpportunityLink(opportunity.organizationId, opportunity.id));
        }
    }

    /********* Helper method *********/

    const getCardId = (opportunityId: number) => {
        return `${props.idPrefix}-${opportunityId}`;
    }

    /********* Render *********/

    return (
        <Grid container spacing={2}>
            {props.opportunities?.map(opportunity => {
                const cardId = getCardId(opportunity.id);
                const onClick = () => handleCardClicked(cardId, opportunity);

                /********* Chips *********/
                let chips = [] as ReactNode[];
                chips.push(
                    <REMChip
                        icon={opportunity.virtual === OpportunityLocation.InPerson ? <MapMarkerRadius /> : <Airballoon />}
                        label={opportunity.virtual}
                        className={classes.chip}
                    />,
                    <REMChip
                        icon={opportunity.need === OpportunityNeed.OneTime ? <CalendarOutline /> : <Autorenew />}
                        label={opportunity.need}// + ' Need'}
                        className={classes.chip}
                    />
                );
                if (opportunity.timeCommitment === TimeCommitment.LongTerm) {
                    chips.push(<REMChip icon={<Ring />} label={'Long Term'} className={classes.chip} />);
                }
                chips.push(
                    <REMChip
                        icon={opportunity.scheduling === OpportunityScheduling.Shifts ? <CalendarMultiselect /> : <Gesture />}
                        label={opportunity.scheduling === OpportunityScheduling.Shifts ? 'Shifts' : 'Flexible'}
                        className={classes.chip}
                    />
                );
                /******************/

                return (
                    <CardGridItem
                        key={cardId}
                        object={opportunity}
                        id={cardId}
                        title={opportunity.title}
                        subtitle={opportunity.organizationName ? 'Posted by ' + opportunity.organizationName : ''}
                        description={opportunity.description}
                        onClick={onClick}
                        columns={1}//props.columns}
                        chips={chips}
                    />
                );
            })}
        </Grid>
    )
});

export default OpportunityGrid;