import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { StepProps } from "../../../../stores/models/Step";
import { Credentials } from "../../../../stores/models/Credentials";
import CredentialsInput from "./CredentialsInput";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            maxWidth: `calc(40ch + ${theme.spacing(2)}px)`,
            margin: 'auto'
        }
    }),
);

const CredentialsInputWrapper = observer((props: StepProps<Credentials>) => {

    const classes = useStyles();

    /***** Render *****/

    return (
        <div className={classes.wrapper}>
            <CredentialsInput {...props} />
        </div>
    );
});

export default CredentialsInputWrapper;