import { observable, action, makeObservable, computed } from "mobx";
import { Fields } from "./Fields";
import validateHelpMessage from "../../logic/ValidationChecks/HelpMessageValidation";

export interface IHelpMessage {
    firstName: string;
    lastName: string;
    email: string;
    subject: string;
    message: string;
}

export class HelpMessage extends Fields<IHelpMessage, HelpMessage> implements IHelpMessage {
    @observable firstName: string = "";
    @observable lastName: string = "";
    @observable email: string = "";
    @observable subject: string = "";
    @observable message: string = "";

    constructor(helpMessage?: Partial<IHelpMessage>) {
        super();

        makeObservable(this);

        if (helpMessage) {
            this.firstName = helpMessage.firstName || "";
            this.lastName = helpMessage.lastName || "";
            this.email = helpMessage.email || "";
            this.subject = helpMessage.subject || "";
            this.message = helpMessage.message || "";
        }
    }

    @action setField(field: keyof IHelpMessage, value: string) {
        this[field] = value;
    }

    @computed get validationErrors() {
        return validateHelpMessage(this);
    }

    serialize() {
        return this.trimmed;
    }
}