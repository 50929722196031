import { observer } from 'mobx-react'
import { Typography } from "@material-ui/core";

const RegisterAdditionalVolunteersInstructions = observer(() => {
    
    /********* Render *********/
    
    return (
        <Typography variant='body2'>
            Sign up additional volunteers for this shift by completing a registration form for each individual.
        </Typography>
    );
});

export default RegisterAdditionalVolunteersInstructions;