import { StubVolunteer } from "../../stores/models/StubVolunteer";
import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { getValidationErrors, getFieldRequiredValidationCheck, getMaxLengthValidationCheck, getBirthdateValidationCheck } from "./Validation";
import { VOLUNTEER_NAME_MAX_LENGTH } from "./FieldLengths";

const demographicChecks: (stubVolunteer: StubVolunteer) => ValidationCheck<StubVolunteer>[] =
    (stubVolunteer: StubVolunteer) => [
        getFieldRequiredValidationCheck(stubVolunteer.firstName, 'firstName', 'Your first name is required.'),
        getMaxLengthValidationCheck(stubVolunteer.firstName, 'firstName', VOLUNTEER_NAME_MAX_LENGTH),
        getFieldRequiredValidationCheck(stubVolunteer.lastName, 'lastName', 'Your last name is required.'),
        getMaxLengthValidationCheck(stubVolunteer.lastName, 'lastName', VOLUNTEER_NAME_MAX_LENGTH),
        getBirthdateValidationCheck(stubVolunteer.birthdate, 'birthdate', true, true)
    ];

export default function validateVolunteerDemographics(stubVolunteer: StubVolunteer) {
    return getValidationErrors(demographicChecks, stubVolunteer);
}