import React, { useContext, useEffect, useMemo, useState } from "react";
import { Typography, Paper, Container, makeStyles, Theme, createStyles } from "@material-ui/core";
import Editor from "../../../Editor";
import { RootContext } from "../../../../../stores";
import { Address } from "../../../../../stores/models/Address";
import { observer } from "mobx-react";
import TablePaginationWrapper from "./../TablePaginationWrapper";
import FormLoadingSpinner from "../../../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import { OptionCollection } from "../../../../../stores/models/OptionCollection";
import LocationEditor from "../../../../Organization/LocationEditor";
import { TablePaginationState } from "../../../../../stores/models/TablePaginationState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line',
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
    }),
);

const LocationsTable = observer(() => {

    /********* React hooks *********/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const locationStore = rootStore.locationStore;
    const organization = rootStore.userStore.user.organization;

    /********* State *********/

    const [isLoading, setIsLoading] = useState(true);
    const [locationsDirty, setLocationsDirty] = useState(false);
    const tablePaginationState = useMemo(() => {
        return new TablePaginationState();
    }, []);

    const getLocationCollection = () => {
        return new OptionCollection(
            'id',
            organization?.locations.addresses || []
        );
    }

    const [locationCollection, setLocationCollection] = React.useState(getLocationCollection());

    /********* Effects *********/

    useEffect(() => {
        setLocationCollection(getLocationCollection());
    }, [organization?.locations.addresses]);

    useEffect(() => {
        if (locationsDirty) {
            setLocationsDirty(false);
            setLocationCollection(getLocationCollection())
        }
    }, [locationsDirty]);

    /********* Helper methods *********/

    const loadOrganizationLocations = async (limit: number, offset: number) => {
        setIsLoading(true);
        await locationStore.getLocationsForOrganization(limit, offset);
        setIsLoading(false);
    }

    /********* Event handlers *********/

    const onConfirmAddressEdits = async (address: Address) => {
        await locationStore.updateOrganizationLocation(address);
        setLocationsDirty(true);
    };

    const onRemoveAddressConfirmed = async (address: Address) => {
        await locationStore.deleteOrganizationLocation(address);
        setLocationsDirty(true);
    }

    /********* Render *********/

    return (
        <Editor editsMade={false}>
            {(editorState) => (
                <Container>
                    <Paper className={classes.paper}>
                        <Container>
                            <div>
                                <Typography variant="h4">
                                    Locations
                                </Typography>
                                <Typography className={classes.instructions}>
                                    Add, edit, or remove addresses where your organization is located or where your organization's opportunities take place.
                                </Typography>
                                <LocationEditor
                                    addressOptions={locationCollection}
                                    onAddressConfirmed={onConfirmAddressEdits}
                                    addressRemovable={true}
                                    onRemoveAddressConfirmed={onRemoveAddressConfirmed}
                                    hideCheckboxColumn
                                    keepVisibleOnNoResults
                                />
                                <TablePaginationWrapper
                                    state={tablePaginationState}
                                    total={organization?.totalLocations}
                                    loadResults={loadOrganizationLocations}
                                />
                            </div>
                        </Container>
                    </Paper>
                    {isLoading && <FormLoadingSpinner />}
                </Container >
            )}
        </Editor >
    )
});

export default LocationsTable;