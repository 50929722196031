import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface mdiIconProps extends SvgIconProps {
    iconPath?: string;
}

const MdiIcon = (props: mdiIconProps) => {
    return (
        <SvgIcon {...props}>
            <path d={props.iconPath} />
        </SvgIcon>
    )
};

export default MdiIcon;