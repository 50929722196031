import { observer } from "mobx-react";
import { useLinkStyles } from "./LinkStyles";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

interface StylizedExternalLinkProps extends LinkProps { }

const StylizedExternalLink = observer((props: StylizedExternalLinkProps) => {

    const { link, inline } = useLinkStyles();

    const innerContent = typeof props.children === 'string'
            ? <Typography className={inline}>
                {props.children}
            </Typography>
            : props.children;

    /********** Render **********/

    return (
        <Link {...props} children={innerContent} className={clsx(link, props.className)} />
    );
});

export default StylizedExternalLink;