import React, { useContext } from "react";
import { RootContext } from "../../../../../stores";
import { observer } from "mobx-react";
import RecordEditorDialog, { RecordEditorDialogProps } from "../RecordEditorDialog";
import ShiftEntry from "../../../../Organization/VolunteerOpportunities/ShiftEntry";
import { ShiftDetails } from "../../../../../stores/models/ShiftDetails";

interface ShiftEntryDialogProps extends Omit<RecordEditorDialogProps<ShiftDetails>, 'children'> {

}

const ShiftEntryDialog = observer((props: ShiftEntryDialogProps) => {

    const rootStore = useContext(RootContext);

    /********* Render *********/

    return (
        <RecordEditorDialog {...props}>
            {(record: ShiftDetails) =>
                <ShiftEntry
                    shift={record.shift}
                    locationOptions={rootStore.userStore.user.organization?.locations.addresses || []}
                    inlineLocationAdding
                    opportunityId={record.opportunityId}
                    opportunityError={record.errors.opportunityId}
                    opportunityOptions={rootStore.userStore.user.organization?.roles}
                    onOpportunityChanged={(opportunityId: number) => record.setOpportunityId(opportunityId)}
                />
            }
        </RecordEditorDialog>
    );
});

export default ShiftEntryDialog;