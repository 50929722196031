import { observer } from 'mobx-react'
import { Link, LinkProps, To } from 'react-router-dom';
import { useOrgPortalPrefix } from '../Hooks';

export type InternalLinkProps = Omit<LinkProps, 'to'> & ({ onClick: () => void } | { to: To });

const InternalLink = observer((props: InternalLinkProps) => {

    /********* React hooks *********/

    const internalLink = useOrgPortalPrefix('to' in props ? props.to : undefined);

    /********* Render *********/

    return (
        'onClick' in props
            ? <a {...props} />
            : <Link {...props} to={internalLink} />
    );
});

export default InternalLink;