import { useMediaQuery, useTheme } from '@material-ui/core';

// Determines the number of grid columns cards should occupy

export default function useGridColumns(): 1 | 2 | 3 | 4 {

    const theme = useTheme();
    const oneColumn = useMediaQuery(theme.breakpoints.down('sm'));
    const twoColumn = useMediaQuery(theme.breakpoints.only('md'));
    
    return oneColumn ? 1 : twoColumn ? 2 : 3;
}

