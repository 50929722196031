import { action, makeObservable, observable } from "mobx";

export interface IArchivable {
    archived?: boolean | undefined;
    setArchived?: (archived: boolean) => void;
}

export class Archivable implements IArchivable {

    @observable archived: boolean | undefined;

    constructor(archivable: IArchivable) {
        makeObservable(this);
        this.archived = archivable.archived;
    }

    @action setArchived(archived: boolean) {
        this.archived = archived;
    }
}