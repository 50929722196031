import { useContext, useState } from 'react';
import { RootContext } from '../../../stores';
import { LoadHoursAction } from '../data-access/actions/LoadHoursActions';
import { VolunteerHoursServices } from '../data-access/services/VolunteerHoursServices';
import { BulkEditHoursAction } from '../data-access/actions/BulkEditHoursAction';
import { UpsertHoursAction } from '../data-access/actions/UpsertHoursAction';

export default function useVolunteerHoursActions() {
    const rootStore = useContext(RootContext);
    const { volunteerHoursStore } = rootStore;
    const [services] = useState(new VolunteerHoursServices(rootStore.requestQueue));
    const [load] = useState(new LoadHoursAction(volunteerHoursStore, services));
    const [bulkEdit] = useState(new BulkEditHoursAction(volunteerHoursStore, services));
    const [upsert] = useState(new UpsertHoursAction(volunteerHoursStore, services));
    return { load, bulkEdit, upsert };
}