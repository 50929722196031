import { observer } from 'mobx-react'
import { useLocation, useMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigateInternally, useOrganization } from './Hooks';
import LoadingIndicator from '../Shared/LoadingIndicator';
import { redirectToPortalPath } from './Links';

// Redirects organization-specific links to the organization's portal
// May have other uses in the future

const LinkRedirecter = observer(() => {

    /********* React hooks *********/

    const match = useMatch(redirectToPortalPath);
    const { organization, loading } = useOrganization(match?.params.orgId);
    const navigate = useNavigateInternally();
    const location = useLocation();

    /********* Effects *********/

    useEffect(() => {
        if (!loading) {
            // Navigates to the correct portal if the organization has one,
            // otherwise, navigates to the main portal
            navigate('/' + match?.params['*'] + location.search, { replace: true }, organization?.portalSlug);
        }
    }, [organization, loading]);

    /********* Render *********/

    return <LoadingIndicator />

});

export default LinkRedirecter;