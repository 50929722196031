import { observer } from "mobx-react";
import React, { useContext, useState, useRef } from "react";
import { makeStyles, Theme, createStyles, useMediaQuery, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { RootContext } from "../../../stores";
import { DialogState } from "../../../stores/models/DialogState";
import { SearchPromptSteps } from "../Prompts/MobileSearchPrompt";
import { MAIN_SEARCH_BREAKPOINT } from "../../Navigation/NavigationBar";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionState } from "../../../stores/models/AcccordionState";
import OpportunityDetailsFilters from "./OpportunityDetailsFilters";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            // fontSize: theme.typography.pxToRem(15),
            // flexBasis: '33.33%',
            flexShrink: 0,
        },
        flexGrow: {
            flexGrow: 1,
            '& > :not(:last-child)': {
                marginBottom: theme.spacing(1)
            }
        },
        accordion: {
            border: 'none',
            '&.MuiAccordion-root.Mui-expanded, .MuiAccordionSummary-content.Mui-expanded': {
                margin: 0,
            },
            '&::before': {
                opacity: 0,
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 0
            },
            '& .MuiList-padding': {
                paddingTop: 0
            },
        },
        slider: {
            padding: theme.spacing(0, 5)
        },
        centered: {
            textAlign: 'center'
        },
        opportunityOptions: {
            marginTop: theme.spacing(2)
        },
    })
);

// TODO: Clean up

interface OpportunityDetailsAccordionProps {
    state: AccordionState;
    onExpandedChanged: (expanded: boolean) => void;
}

const OpportunityDetailsAccordion = observer((props: OpportunityDetailsAccordionProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const searchStore = rootStore.searchStore;
    const searchCriteria = searchStore.searchCriteria;
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const displayCityInput = useMediaQuery((theme: Theme) => theme.breakpoints.down(MAIN_SEARCH_BREAKPOINT));
    const shortenSearchPlaceholder = useMediaQuery((theme: Theme) => theme.breakpoints.down(380));

    /***** State *****/

    const [searchDialogState] = useState(new DialogState(false));
    const [filterSelectionDialogState] = useState(new DialogState());
    const [activeFilterStep, setActiveFilterStep] = useState<SearchPromptSteps>();
    const [city, setCity] = useState<string>('');
    const [simpleCityPickerState] = useState(new DialogState());
    const locationFieldRef = useRef<HTMLDivElement>(null);

    /***** Event handler *****/

    const onExpandedChanged = (event: React.ChangeEvent<{}>, expanded: boolean) => {
        props.state.setExpanded(expanded);
        props.onExpandedChanged(expanded);
    }

    /***** Render *****/

    return (
        <Accordion
            expanded={props.state.expanded} 
            onChange={onExpandedChanged}
            elevation={0}
            className={classes.accordion}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography className={classes.heading}>Opportunity Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <OpportunityDetailsFilters />
            </AccordionDetails>
        </Accordion>
    );
});

export default OpportunityDetailsAccordion;