import { flow, action, computed, observable, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { AddressCollection } from './models/Addressable';
import { IRequestConfig, RequestQueue } from './models/RequestQueue';
import { Address, IAddress } from './models/Address';

enum LocationRequestType {
    GetLocations = 'get-locations',
    GetAllLocations = 'get-all-locations',
    UpdateLocation = 'update-location',
    DeleteLocation = 'delete-location'
}

export class LocationStore {
    @observable rootStore = {} as RootStore;

    private requestQueue: RequestQueue;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
    }

    @computed private get organizationId() {
        return this.rootStore.userStore.user.organization?.id;
    }

    /*************** Get all of the organization's locations ***************/

    async getAllLocationsForOrganization() {
        try {
            if (this.organizationId) {
                const requestConfig = this.generateRequestConfig(LocationRequestType.GetAllLocations);
                await this.getLocations(requestConfig);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Incrementally load organization locations ***************/

    async getLocationsForOrganization(limit: number, offset: number) {
        try {
            if (this.organizationId) {
                const requestConfig = this.generateRequestConfig(LocationRequestType.GetLocations, { limit, offset });
                await this.getLocations(requestConfig);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Helper method ***************/

    @action private getLocations = flow(function* (this: LocationStore, requestConfig: IRequestConfig) {
        const data = yield this.requestQueue.makeAPIRequest<{ results: IAddress[], total: number }>(requestConfig);
        const { results, total } = data;
        if (this.rootStore.userStore.user.organization) {
            this.rootStore.userStore.user.organization.locations = new AddressCollection(results);
            this.rootStore.userStore.user.organization.totalLocations = total;
        }
    });

    /*************** Update organization location ***************/

    @action async updateOrganizationLocation(location: Address) {
        try {
            if (this.organizationId) {
                const requestConfig = this.generateRequestConfig(
                    LocationRequestType.UpdateLocation,
                    {
                        organization: {
                            id: this.organizationId,
                            location: {
                                ...location.serialize(),
                                id: location.id < 0 ? -1 : location.id
                            }
                        }
                    });
                const data = await this.requestQueue.makeAPIRequest<{ address: Address }>(requestConfig);
                this.rootStore.userStore.user.organization?.locations.onAddressUpdated(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Delete organization location ***************/

    @action async deleteOrganizationLocation(location: Address) {
        try {
            if (this.organizationId) {
                const requestConfig = this.generateRequestConfig(
                    LocationRequestType.DeleteLocation,
                    {
                        organization: {
                            id: this.organizationId,
                            location: location
                        }
                    });
                const data = await this.requestQueue.makeAPIRequest<{ address: Address }>(requestConfig);
                this.rootStore.userStore.user.organization?.locations.onAddressDeleted(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Private request handling methods ***************/

    private generateRequestConfig(type: LocationRequestType, data?: any): IRequestConfig {
        const organizationsAPI = '/api/organizations'
        switch (type) {
            case LocationRequestType.GetLocations:
                return {
                    method: 'get',
                    url: `${organizationsAPI}/locations`,
                    forceRefresh: true,
                    params: data,
                };
            case LocationRequestType.GetAllLocations:
                return {
                    method: 'get',
                    url: `${organizationsAPI}/all-locations`,
                    forceRefresh: true,
                }
            case LocationRequestType.UpdateLocation:
                return {
                    method: 'put',
                    url: `${organizationsAPI}/update-location`,
                    data: data
                };
            case LocationRequestType.DeleteLocation:
                return {
                    method: 'delete',
                    url: `${organizationsAPI}/delete-location`,
                    data: data
                };
        }
    }
}

