import { makeAutoObservable, observable } from "mobx";

export interface IServerRole {
    id: number,
    position: string,
    tags: number[];
}

export interface IRole {
    id: number,
    position: string,
    tagIds: number[];
}

export class Role implements IRole {
    @observable id: number;
    @observable position: string;
    @observable tagIds: number[];

    constructor(role: IRole | IServerRole) {
        makeAutoObservable(this);

        this.id = role.id;
        this.position = role.position;
        if ('tags' in role) {
            this.tagIds = role.tags;
        } else {
            this.tagIds = role.tagIds;
        }
    }
}