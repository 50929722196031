import { makeStyles, Theme, createStyles, Typography, useMediaQuery, Divider, FormControl, RadioGroup, FormControlLabel, Radio, Collapse, IconButton, Tooltip } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { RootContext } from "../../../../../stores";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "../../../../Shared/Dialogs/ThemedDialogWithSpinner";
import { DialogState } from "../../../../../stores/models/DialogState";
import { PlanLevels, PlanLevelDictionary } from "../../../../Authentication/RegistrationSteps/Organization/Plans";
import { Information } from "mdi-material-ui";
import { Invoice } from "../../../../../stores/models/Invoice";
import PaymentEntry from "../../../../Shared/PaymentEntry";
import { PaymentMethod } from "../../../../../stores/models/PaymentMethod";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Customer } from "../../../../../stores/models/Customer";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog";
import SubscriptionPlanCards from "./SubscriptionPlanCards";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            position: 'relative',
            overflowY: 'visible'
        },
        root: {
            background: theme.palette.lightBackground.main,
        },
        hidden: {
            visibility: 'hidden'
        },
        bold: {
            fontWeight: 'bold'
        },
        billLine: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 4,
        },
        divider: {
            marginBottom: theme.spacing(1),
            background: theme.palette.text.primary,
            height: 2
        },
        section: {
            marginBottom: theme.spacing(3)
        },
        sectionTitle: {
            marginBottom: theme.spacing(1)
        },
        cardOnFile: {
            marginLeft: theme.spacing(4)
        },
        cardEntry: {
            marginLeft: theme.spacing(3)
        },
        alert: {
            marginBottom: theme.spacing(2)
        },
        flex: {
            display: 'flex'
        },
        infoButton: {
            padding: 0,
            paddingLeft: 4
        },
        tooltip: {
            background: theme.palette.darkBackground.main
        },
        arrow: {
            color: theme.palette.darkBackground.main
        },
    }),
);

enum CardSelectionOptions {
    CurrentCard = 'current-card',
    NewCard = 'new-card'
}

const SUBSCRIPTION_PLAN_OPTIONS_DIALOG_ID = 'subscription-plan-options-dialog';

interface SubscriptionPlanOptionsDialogProps {
    state: DialogState;
    customer: Customer;
    onCustomerUpdated: (customer: Customer) => void;
}

const SubscriptionPlanOptionsDialog = observer((props: SubscriptionPlanOptionsDialogProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const paymentProcessingStore = rootStore.paymentProcessingStore;
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /***** Helper method *****/

    const getStartingPlanLevel = () => {
        const nextPlanLevel = props.customer.subscription?.nextPlanLevel;
        return nextPlanLevel ? nextPlanLevel : -1;
    }

    const getInitialCardSelection = () => {
        if (props.customer.card && !props.customer.subscription?.errorWithCard) {
            return CardSelectionOptions.CurrentCard;
        } else {
            return CardSelectionOptions.NewCard;
        }
    }

    /***** State *****/

    const [planLevel, setPlanLevel] = useState(getStartingPlanLevel());
    const [step, setStep] = useState(0);
    const [invoice, setInvoice] = useState<Invoice>();
    const [cardSelection, setCardSelection] = useState(getInitialCardSelection());
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [displayCardErrors, setDisplayCardErrors] = useState(false);
    const [expiredSessionDialogState] = useState(new DialogState());
    const [prorationRateExpired, setProrationRateExpired] = useState(false);
    const [cardError, setCardError] = useState<string>();

    /***** Effects *****/

    useEffect(() => {
        if (props.state.open) {
            reset();
        }
    }, [props.state.open]);

    useEffect(() => {
        if (step === 1) {
            retrieveInvoice();
            setCardError(undefined);
        }
    }, [step]);

    /***** Event handlers *****/

    const handleCardSelectionChanged = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setDisplayCardErrors(false);
        setCardSelection(value as CardSelectionOptions);
    }

    const advanceStep = () => {
        const isFinalStep = step === 1;
        if (isFinalStep) {
            if (prorationRateExpired) {
                retrieveInvoice();
            } else {
                confirmChanges();
            }
        } else if (step === 0) {
            // Important: The payment method must be set before the PaymentEntry component is rendered
            // and refreshed before each time it's rendered in order for the Stripe elements to be valid.
            setPaymentMethod(new PaymentMethod());
            setStep(step + 1);
        }
    }

    const previousStep = () => {
        setStep(step - 1);
    }

    /***** Helper methods *****/

    const handlePlanLevelChanged = (planLevel: number) => {
        setPlanLevel(planLevel);
    }

    const refreshProrationRate = () => {
        expiredSessionDialogState.setOpen(false);
    }

    const newSelectionMatchesCurrentPlan = () => {
        return props.customer.subscription?.nextPlanLevel === planLevel;
    }

    const getSelectedPlanName = () => {
        return getPlanName(planLevel);
    }

    const getPlanName = (planLevel: number) => {
        return PlanLevelDictionary[planLevel as PlanLevels].name;
    }

    const canReselectCurrentPlan = () => {
        return props.customer.subscription?.cancelled || props.customer.subscription?.errorWithCard;
    }

    const primaryButtonDisabled = () => {
        return step === 0 && newSelectionMatchesCurrentPlan() && !canReselectCurrentPlan();
    }

    const retrieveInvoice = async () => {
        props.state.setLoading(true);
        const invoice = await paymentProcessingStore.retrieveUpcomingInvoice(planLevel);
        setInvoice(invoice);
        setProrationRateExpired(false);
        props.state.setLoading(false);
    }

    const confirmChanges = async () => {
        props.state.setLoading(true);
        let result;
        if (invoice) {
            if (cardSelection === CardSelectionOptions.NewCard) {
                paymentMethod?.setAllFieldsDirty();
                if (paymentMethod?.validated) {
                    result = await paymentProcessingStore.updateSubscriptionPlanLevel(planLevel, invoice.prorationDate, paymentMethod);
                } else {
                    setDisplayCardErrors(true);
                }
            } else {
                result = await paymentProcessingStore.updateSubscriptionPlanLevel(planLevel, invoice.prorationDate);
            }
        }
        if (result && !Array.isArray(result)) {
            if (typeof result === 'string') {
                setCardError(result);
                scrollToTop();
            } else {
                setCardError(undefined);
                if (result.customer) {
                    // Update the user's active status
                    if (result.customer.subscription
                        && result.customer.subscription.active
                        && !result.customer.subscription.errorWithCard) {
                        rootStore.userStore.user.setActive(true);
                    }
                    props.onCustomerUpdated(result.customer);
                    props.state.setOpen(false);
                } else if (result.warnings && result.warnings.length > 0) {
                    // Handle error code 2000
                    setProrationRateExpired(true);
                    expiredSessionDialogState.setOpen(true);
                }
            }
        }
        props.state.setLoading(false);
    }

    const reset = () => {
        expiredSessionDialogState.setOpen(false);
        setPlanLevel(getStartingPlanLevel());
        setStep(0);
        setInvoice(undefined);
        setCardSelection(getInitialCardSelection());
        setPaymentMethod(undefined);
        setDisplayCardErrors(false);
    }

    const scrollToTop = () => {
        document.querySelector(`#${SUBSCRIPTION_PLAN_OPTIONS_DIALOG_ID}`)?.scrollTo(0, 0);
    }

    /***** Render *****/

    return (
        <ThemedDialogWithSpinner
            state={props.state}
            primaryButtonProps={{
                children: step === 0
                    ? 'Select'
                    : prorationRateExpired && !expiredSessionDialogState.open
                        ? 'Refresh'
                        : 'Confirm',
                disabled: primaryButtonDisabled()
            }}
            leftButtonProps={step > 0 ? { children: 'Back', onClick: previousStep } : undefined}
            DialogProps={{ fullScreen: xsDown, classes: { paper: classes.dialogPaper } }}
            DialogContentProps={{ classes: { root: step == 0 ? classes.root : undefined } }}
            title={'Subscription Plans'}
            onSubmit={advanceStep}
            contentId={SUBSCRIPTION_PLAN_OPTIONS_DIALOG_ID}
        >
            {step === 0
                ? <SubscriptionPlanCards
                    currentPlanLevel={getStartingPlanLevel()}
                    planLevel={planLevel}
                    onPlanLevelChanged={(planLevel) => handlePlanLevelChanged(planLevel)}
                    hideSelectButton
                    elevated
                />
                : <div className={invoice === undefined ? classes.hidden : undefined}>
                    {cardError
                        ? <Alert severity="error" className={classes.alert}>
                            <AlertTitle>Card Error</AlertTitle>
                            {cardError}
                        </Alert>
                        : null
                    }
                    {invoice?.dueToday && invoice.dueToday > 0
                        ? <div className={classes.section}>
                            <Typography variant="h6" className={classes.sectionTitle}>Due Today</Typography>
                            <div className={classes.billLine}>
                                <div className={classes.flex}>
                                    <Typography>Prorated Cost</Typography>
                                    <Tooltip arrow title={
                                        <Typography>
                                            The prorated cost of a {getSelectedPlanName()} subscription for the remainder of your current billing period.
                                        </Typography>
                                    }
                                        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                                    >
                                        <IconButton className={classes.infoButton}>
                                            <Information color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <Typography>
                                    {invoice ? invoice.dueImmediatelyText : undefined}
                                </Typography>
                            </div>
                            <div className={classes.billLine}>
                                <div className={classes.flex}>
                                    <Typography>Credit</Typography>
                                    <Tooltip arrow title={
                                        <Typography>
                                            {`A credit for the unused time on your ${props.customer.subscription?.planName || ""} plan.`}
                                        </Typography>
                                    }
                                        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                                    >
                                        <IconButton className={classes.infoButton}>
                                            <Information color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <Typography>
                                    {`-    ${invoice ? invoice.creditText : ''}`}
                                </Typography>
                            </div>
                            <Divider className={classes.divider} />
                            <div className={classes.billLine}>
                                <Typography className={classes.bold}>Total</Typography>
                                <Typography className={classes.bold}>
                                    {invoice ? invoice.dueTodayText : undefined}
                                </Typography>
                            </div>
                        </div>
                        : props.customer.subscription?.planLevel === planLevel
                            ? <Alert severity="info" className={classes.alert}>
                                {`By confirming, your subscription will remain at the `}
                                <span className={classes.bold}>{getSelectedPlanName()}</span>
                                {` level at the end of your current billing period.`}
                            </Alert>
                            : <Alert severity="info" className={classes.alert}>
                                {`By confirming, your `}
                                <span className={classes.bold}>{props.customer.subscription?.planName || ""}</span>
                                {` subscription will continue through the end of your current billing period. `}
                                {`At that point your subscription will be updated to the `}
                                <span className={classes.bold}>{getSelectedPlanName()}</span>
                                {` plan.`}
                            </Alert>
                    }
                    <div className={classes.section}>
                        <Typography variant="h6" className={classes.sectionTitle}>Next Payment</Typography>
                        {invoice
                            ? <Typography>
                                {`Your next monthly bill will be for `}
                                <span className={classes.bold}>
                                    {invoice.nextPaymentText}
                                </span>
                                {` on `}
                                <span className={classes.bold}>
                                    {invoice.formattedNextPaymentDate}
                                </span>
                            </Typography>
                            : null
                        }
                    </div>
                    <div>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Payment Method
                        </Typography>
                        <FormControl component="fieldset">
                            <Typography color="textSecondary">Select a card to keep on file for subscription payments:</Typography>
                            <RadioGroup aria-label="card selection" name="card selection" value={cardSelection} onChange={handleCardSelectionChanged}>
                                {props.customer.card
                                    ? <div>
                                        <FormControlLabel
                                            value={CardSelectionOptions.CurrentCard}
                                            control={<Radio color="primary" />}
                                            label={props.customer.subscription?.errorWithCard ? "Current card - Invalid" : "Current card"}
                                            classes={{ label: classes.bold }}
                                            disabled={props.customer.subscription?.errorWithCard}
                                        />
                                        <div className={classes.cardOnFile}>
                                            <Typography>
                                                Your card ending in {props.customer.card.last4}
                                            </Typography>
                                            <Typography color="textSecondary">
                                                Expires: {props.customer.card.cardExpiration}
                                            </Typography>
                                        </div>
                                    </div>
                                    : null
                                }
                                <FormControlLabel
                                    value={CardSelectionOptions.NewCard}
                                    control={<Radio color="primary" />}
                                    label="New card"
                                    classes={{ label: classes.bold }}
                                />
                            </RadioGroup>
                        </FormControl>
                        <div className={classes.cardEntry}>
                            <Collapse in={cardSelection === CardSelectionOptions.NewCard}>
                                {paymentMethod
                                    ? <PaymentEntry
                                        paymentMethod={paymentMethod}
                                        displayErrors={displayCardErrors}
                                        isLoading={step === 1 && props.state.loading}
                                    />
                                    : null
                                }
                            </Collapse>
                        </div>
                    </div>
                    <ConfirmationDialog
                        title='Session Expired'
                        state={expiredSessionDialogState}
                        onConfirm={refreshProrationRate}
                        confirmText="Refresh"
                        content={
                            <Alert severity="warning">
                                <Typography>
                                    <span className={classes.bold}>Your checkout session has expired.</span>
                                </Typography>
                                <Typography>
                                    Please refresh in order to continue.
                                </Typography>
                            </Alert>
                        }
                    />
                </div>
            }
        </ThemedDialogWithSpinner >
    );
});

export default SubscriptionPlanOptionsDialog;