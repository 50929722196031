import { makeObservable } from "mobx";
import { ServiceEntry } from "../entities/ServiceEntry";
import { IServerServiceEntry, ServerServiceEntry } from "../../../../stores/models/server/ServerServiceEntry";
import { UpsertRecordListAction } from "../../../../shared/modules/record-collection/data-access/actions/UpsertRecordListAction";
import { VolunteerHoursServices } from "../services/VolunteerHoursServices";
import { VolunteerHoursStore } from "../stores/VolunteerHoursStore";
import { ErrorCodes, ErrorTypes } from "../../../../data/ErrorCodes";

export class UpsertHoursAction extends UpsertRecordListAction<ServiceEntry, IServerServiceEntry, ServiceEntry> {

    constructor(store: VolunteerHoursStore, private services: VolunteerHoursServices) {
        super(store);
        makeObservable(this)
    }

    async upsertRecord(record: ServiceEntry) {
        record.setAllFieldsDirty();
        if (record.validated) {
            const response = await this.services.upsertServiceEntries(record);
            return response;
        } else {
            throw ErrorCodes.get(ErrorTypes.ValidationError);
        }
    }

    protected transformData(data: IServerServiceEntry[]): ServiceEntry[] {
        return data.map(element => new ServerServiceEntry(element).deserialize());
    }
}