import { makeObservable, override } from "mobx";
import { DataStore } from "./DataStore";
import { RootStore } from "./RootStore";
import { Volunteer } from "./models";

enum SupplementalDataRequestTypes {
    NewOpportunity = 'new-opportunity',
    ProxiedVolunteerIds = 'proxied-volunteer-ids'
}

export class SupplementalDataStore extends DataStore<SupplementalDataRequestTypes> {

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    /****** API Requests ******/

    async getSupplementalDataForNewOpportunity() {
        try {
            return await this.makeRequest(SupplementalDataRequestTypes.NewOpportunity, { requestType: 'new-opportunity' });
        } catch (err) {
            console.log(err);
        }
    }

    async getSupplementalDataForProxiedVolunteers(organizationId: number) {
        try {
            const result = await this.makeRequest<{ supplementalOrganizationData: { proxiedVolunteers: { id: number, firstName: string, lastName: string }[] } }>(
                SupplementalDataRequestTypes.ProxiedVolunteerIds,
                { requestType: 'proxied-volunteer-ids', organizationId: organizationId }
            );
            if (result) {
                const { proxiedVolunteers } = result?.supplementalOrganizationData;
                return proxiedVolunteers.map(proxiedVolunteer => new Volunteer({ ...proxiedVolunteer, birthdate: undefined }));
            }
        } catch (err) {
            console.log(err);
        }
    }

    /****** Computed Data ******/

    @override get getRequests() {
        return [SupplementalDataRequestTypes.NewOpportunity, SupplementalDataRequestTypes.ProxiedVolunteerIds];
    }

    @override get requestURLs() {
        return {
            [SupplementalDataRequestTypes.NewOpportunity]: `/api/supplemental-data/`,
            [SupplementalDataRequestTypes.ProxiedVolunteerIds]: `/api/supplemental-data/volunteer`
        };
    }
}