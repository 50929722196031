import React, { useState } from "react";
import { observer } from "mobx-react";
import { Typography, Tooltip } from "@material-ui/core";
import { InformationOutline } from "mdi-material-ui";

interface InformationTooltipProps {
    title: string;
}

const InformationTooltip = observer((props: InformationTooltipProps) => {

    /***** State *****/

    const [open, setOpen] = useState(false);

    /***** Event handlers *****/

    const onInfoButtonClicked = () => {
        setOpen(true);
    }

    const onTooltipOpen = () => {
        setOpen(true);
    }

    const onTooltipClose = () => {
        setOpen(false);
    }

    /***** Render *****/

    return (
        <Tooltip
            arrow
            open={open}
            onOpen={onTooltipOpen}
            onClose={onTooltipClose}
            leaveTouchDelay={15000}
            interactive
            title={
                <Typography style={{ textAlign: 'center' }}>
                    {props.title}
                </Typography>
            }>
            <InformationOutline
                color="primary"
                onClick={onInfoButtonClicked}
                style={{ marginTop: -5, marginBottom: -5, marginLeft: 4 }}
            />
        </Tooltip>
    );
});

export default InformationTooltip;