import { observer } from 'mobx-react'
import { makeStyles, Theme, createStyles, Card, Typography, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { DotsVertical } from 'mdi-material-ui';
import { MouseEventHandler, RefObject, createElement, useRef, useState } from 'react';
import MdiIcon from '../../../components/Shared/MdiIcon';
import { mdiTimerSandComplete } from '@mdi/js';
import ServiceEntryStatusIcon from './ServiceEntryStatusIcon';
import { ServiceEntryStatuses } from '../data-access/entities/ServiceEntryStatuses';
import { MenuState } from '../../../stores/models/MenuState';
import { IServiceEntryDisplayData } from '../data-access/interfaces/ServiceEntryDisplayData';
import { useRootStore } from '../../../shared/general/hooks';
import REMChip from '../../../shared/modules/rem-conversion/components/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rows: {
            padding: theme.spacing(2),
            '&:not(:first-child)': {
                paddingTop: 0
            }
        },
        condensedTopRow: {
            paddingBottom: theme.spacing(0)
        },
        condensedRows: {
            paddingTop: theme.spacing(0)
        },
        row: {
            display: 'flex',
            alignItems: 'center'
        },
        alignStart: {
            alignItems: 'flex-start'
        },
        icon: {
            marginRight: theme.spacing(1),
            color: theme.palette.action.active,
        },
        date: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'center',
            padding: theme.spacing(2, 1.5),
            flexShrink: 0
        },
        card: {
            width: '100%',
            display: 'flex',
            marginBottom: theme.spacing(1)
            // background: 'rgba(0,0,0,.04)',
            // border: `1px solid ${theme.palette.action.disabledBackground}`
        },
        leftBar: {
            background: 'gray',
            width: theme.spacing(1),
            marginRight: theme.spacing(1)
            // height: '100%'
        },
        unconfirmed: {
            background: theme.palette.warning.main
        },
        confirmed: {
            background: theme.palette.success.main
        },
        voided: {
            background: theme.palette.error.main
        },
        bold: {
            fontWeight: 'bold'
        },
        hoursChip: {
            background: theme.palette.primaryBackground.main
        },
        flexGrow: {
            flexGrow: 1
        },
        iconButton: {
            marginTop: theme.spacing(-1.5),
            marginRight: theme.spacing(-1.5),
        },
        truncated: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        noMinWidth: {
            minWidth: 0
        }
    })
);

interface ContextMenuProps {
    state: MenuState;
    anchor: null | RefObject<HTMLButtonElement>;
}

interface ServiceEntryCardProps {
    serviceEntry: IServiceEntryDisplayData;
    contextMenu: (props: ContextMenuProps) => JSX.Element;
    onClick?: () => void;
}

const ServiceEntryCard = observer((props: ServiceEntryCardProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const { userStore } = useRootStore();

    const [menuState] = useState(new MenuState());
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [anchor] = useState<RefObject<HTMLButtonElement>>(anchorRef);
    const [currentDate] = useState(new Date());

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        menuState.setOpen(true)
    };

    const statusId = props.serviceEntry.statusId;
    const unconfirmed = statusId === ServiceEntryStatuses.Unconfirmed;
    const confirmed = statusId === ServiceEntryStatuses.Confirmed;

    const dateComponents = props.serviceEntry.dateComponents!;
    const date = new Date(dateComponents.year, dateComponents.month, dateComponents.date);
    const displayYear = date.toLocaleDateString('en-us', { year: 'numeric' });
    const currentYear = currentDate.toLocaleDateString('en-us', { year: 'numeric' });
    const shouldDisplayYear = displayYear !== currentYear;
    const entryDeletedByVolunteer = props.serviceEntry.hours !== undefined && isNaN(parseInt(props.serviceEntry.hours));
    const noOrgOrVol = props.serviceEntry.organization === undefined && !props.serviceEntry.volunteer;

    /********* Render *********/

    return (
        <Card elevation={0} className={clsx(classes.card)} onClick={props.onClick}>
            <div className={clsx(classes.leftBar, entryDeletedByVolunteer ? classes.voided : unconfirmed ? classes.unconfirmed : confirmed ? classes.confirmed : classes.voided)} />
            <div className={classes.date}>
                <Typography variant='body2'>{date.toLocaleDateString('en-us', { month: 'short' })}</Typography>
                <Typography variant='h5'>{date.getDate()}</Typography>
                {shouldDisplayYear ? <Typography variant='body2'>{displayYear}</Typography> : null}
            </div>
            <div className={clsx(classes.flexGrow, classes.noMinWidth)}>
                <div className={clsx(classes.rows, classes.noMinWidth, noOrgOrVol ? classes.condensedTopRow : undefined)}>
                    <div className={clsx(classes.row, classes.noMinWidth, classes.alignStart)}>
                        <div className={clsx(classes.flexGrow, classes.noMinWidth)}>
                            <div className={clsx(classes.row, classes.noMinWidth)}>
                                <Typography variant='body2' className={clsx(classes.bold, classes.truncated)}>
                                    {noOrgOrVol ? props.serviceEntry.role || 'Unspecified Role' : props.serviceEntry.organization !== undefined ? props.serviceEntry.organization : props.serviceEntry.volunteer}
                                </Typography>
                            </div>
                            {!noOrgOrVol
                                ? <div className={clsx(classes.row, classes.noMinWidth)}>
                                    <Typography variant='body2' className={classes.truncated}>
                                        {props.serviceEntry.role}
                                    </Typography>
                                </div>
                                : null
                            }
                        </div>
                        <div>
                            <IconButton className={classes.iconButton} onClick={handleClick} ref={anchorRef}>
                                <DotsVertical />
                            </IconButton>
                            {props.contextMenu({
                                state: menuState,
                                anchor: anchor
                            })}
                        </div>
                    </div>
                </div>
                <div className={clsx(classes.rows, noOrgOrVol ? classes.condensedRows : undefined)}>
                    {!entryDeletedByVolunteer
                        ? <div className={classes.row}>
                            <MdiIcon iconPath={mdiTimerSandComplete} className={classes.icon} />
                            <Typography variant="body2">
                                {`${props.serviceEntry.hours} hours`}
                            </Typography>
                        </div>
                        : null
                    }
                    {userStore.user.isOrganization && props.serviceEntry.sourceIcon
                        ? <div className={classes.row}>
                            {createElement(props.serviceEntry.sourceIcon, { className: classes.icon })}
                            <Typography variant="body2">
                                {props.serviceEntry.sourceDescriptor}
                            </Typography>
                        </div>
                        : null
                    }
                    {!confirmed
                        ? <div className={classes.row}>
                            <REMChip label={`Status: ${props.serviceEntry.status}`}
                                icon={
                                    <ServiceEntryStatusIcon
                                        statusId={
                                            entryDeletedByVolunteer
                                                ? ServiceEntryStatuses.Voided
                                                : props.serviceEntry.statusId
                                        }
                                    />
                                }
                                style={{ marginTop: 4 }}
                                className={classes.hoursChip}
                            />
                        </div>
                        : null
                    }
                </div>
            </div>
        </Card >
    );
});

export default ServiceEntryCard;