import { Fragment } from "react";
import { makeStyles, Theme, createStyles, Paper, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import AnchorLink from "./Components/AnchorLink";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        header: {
            marginBottom: theme.spacing(1)
        },
        treeItemLabel: {
            overflow: 'hidden',
        },
        treeItemGroup: {
            marginLeft: theme.spacing(1)
        },
        link: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block'
        },
        tableOfContentsLevel0: {
            color: 'black',
            fontWeight: 600,
        },
        tableOfContentsLevel1: {
            color: 'black',
            fontWeight: 'normal'
        },
        tableOfContentsLevel2: {
            color: theme.palette.action.active
        },
    })
);

export type TableOfContentsItem = { label: string, anchorId: string, subItems?: TableOfContentsItem[] };

interface TableOfContentsProps {
    tableOfContentsItems: TableOfContentsItem[];
}

const TableOfContents = observer((props: TableOfContentsProps) => {

    /*****************  React hooks  *****************/

    const classes = useStyles();

    /*****************  Helper method  *****************/

    const displayTableOfContentsItem = (item: TableOfContentsItem, indentation: 0 | 1 | 2, index: number) => {
        return (
            <Fragment>
                <TreeItem
                    key={`tree-item-${indentation}-${index}`}
                    nodeId={item.anchorId}
                    classes={{ label: classes.treeItemLabel, group: classes.treeItemGroup }}
                    label={
                        <AnchorLink anchorId={item.anchorId} className={clsx(classes[`tableOfContentsLevel${indentation}`], classes.link)}>
                            {item.label}
                        </AnchorLink>
                    }
                >
                    {item.subItems?.map((subItem, subItemIndex) => {
                        return displayTableOfContentsItem(subItem, Math.min(indentation + 1, 2) as 0 | 1 | 2, subItemIndex);
                    })}
                </TreeItem>
            </Fragment>
        )
    }

    /*****************  Render  *****************/

    return (
        <Paper className={classes.root}>
            <Typography variant='h6' className={classes.header}>Table of Contents</Typography>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={props.tableOfContentsItems.map(topLevelSection => topLevelSection.anchorId)}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                {props.tableOfContentsItems.map((item, index) => {
                    return displayTableOfContentsItem(item, 0, index);
                })}
            </TreeView>
        </Paper>
    )
});

export default TableOfContents;