import { observer } from "mobx-react";
import { DialogState } from "../../../../../stores/models/DialogState";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog";
import { Opportunity } from "../../../../../stores";
import { Alert, AlertTitle } from "@material-ui/lab";

interface OpportunityDeletionConfirmationProps {
    state: DialogState;
    opportunity: Opportunity;
    onConfirm: (opportunity: Opportunity) => void;
}

const OpportunityDeletionConfirmation = observer((props: OpportunityDeletionConfirmationProps) => {

    /********* Helper methods *********/

    const deletionConfirmed = () => {
        props.onConfirm(props.opportunity);
    }

    /********* Render *********/

    return (
        <ConfirmationDialog
            state={props.state}
            title="Delete Opportunity"
            content={
               <Alert severity="warning">
                    <AlertTitle>Please Proceed with Caution</AlertTitle>
                    Deleting an opportunity will delete all associated shifts and service data. If you'd like to maintain this data, you can instead change the opportunity's status to inactive.
               </Alert> 
            }
            confirmText={'Delete'}
            onConfirm={deletionConfirmed}
            fullScreen={false}
        />
    );
});

export default OpportunityDeletionConfirmation;