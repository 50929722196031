import { TableCell, TableSortLabel, TableCellProps } from "@material-ui/core";
import React from "react";
import { observer } from "mobx-react";
import { TableHeader } from "../../../stores/models/TableHeader";

interface TableHeaderCellProps<T> extends TableCellProps {
    tableHeader: TableHeader<T>;
    onHeaderClicked?: (event: React.MouseEvent<HTMLSpanElement>, tableHeader: TableHeader<T>) => void;
}

const TableHeaderCell = observer(<T extends unknown>(props: TableHeaderCellProps<T>) => {

    const onHeaderClicked = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (props.tableHeader.sortValue) {
            props.tableHeader.rotateDirection();
            if (props.onHeaderClicked) {
                props.onHeaderClicked(event, props.tableHeader);
            }
        }
    }

    const tableCell = (tableCellProps: TableCellProps) => {
        return (
            <TableCell
                key={props.tableHeader.id}
                align={props.tableHeader.alignment}
                padding={props.tableHeader.disablePadding ? 'none' : 'normal'}
                {...tableCellProps}
            />
        )
    }

    /********* Render *********/

    return (
        props.tableHeader.sortable
            ? tableCell({
                sortDirection: props.tableHeader.direction || false,
                children: (
                    <TableSortLabel
                        active={props.tableHeader.active}
                        direction={props.tableHeader.direction}
                        onClick={onHeaderClicked}
                    >
                        {props.tableHeader.label}
                    </TableSortLabel>
                )
            })
            : tableCell({ children: props.tableHeader.label })
    )
});

export default TableHeaderCell;