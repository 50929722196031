import { VolunteerDetails } from "../../stores/models/VolunteerDetails";
import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { getFieldRequiredValidationCheck, getValidationErrors, isValidEmail } from "./Validation";
import { ValidationError } from "../../stores/models/ValidationError";

const demographicChecks: (volunteerDetails: VolunteerDetails) => ValidationCheck<VolunteerDetails>[] =
    (volunteerDetails: VolunteerDetails) => [{
        name: 'valid email',
        condition: (volunteerDetails: VolunteerDetails) => {
            return !volunteerDetails.email || isValidEmail(volunteerDetails.email);
        },
        error: new ValidationError('Invalid email.', ['email'])
    },
    getFieldRequiredValidationCheck(volunteerDetails.status, 'status', 'Status is required.')
];

export default function validateVolunteerDetails(volunteerDetails: VolunteerDetails) {
    return getValidationErrors(demographicChecks, volunteerDetails);
}