import { computed, makeAutoObservable, observable } from "mobx";
import { IServiceEntry, ServiceEntry } from "../../modules/volunteer-hours/data-access/entities/ServiceEntry";
import { IServerServiceEntry, ServerServiceEntry } from "./server/ServerServiceEntry";
import { DateFormatter } from "../../logic/DateFormatter";
import { format } from "date-fns";
import { Sorter } from "../../logic/Sorter";
import { getSingularOrPluralDescriptor } from "../../logic/UtilityFunctions";
import { LATEST_DOLLAR_VALUE_OF_VOLUNTEER_HOUR } from "../../data/config";
import { ReportNameSlugs } from "../../components/Navigation/Links";
import { ISortableTableHeader } from "./TableHeader";

interface VolunteerWithStatus {
    id: number;
    firstName: string;
    lastName: string;
    status: string;
}

export interface IReportData {
    volunteers: VolunteerWithStatus[];
    volunteerHours: (IServiceEntry & { status: string })[];
    reportTimestamp: Date;
}

export interface IServerReportData {
    volunteers: VolunteerWithStatus[];
    volunteerHours: (IServerServiceEntry & { status: string })[];
    reportTimestamp: Date;
}

type VolunteerWithLoggedHours = {
    total: number,
    id: number,
    firstName: string,
    lastName: string
}

export enum RangeType {
    RangeSpecific,
    Rangeless,
}

export enum ReportNames {
    VolunteersByStatus = 'volunteersByStatus',
    VolunteerHours = 'volunteerHours',
    VolunteersWithHoursLogged = 'volunteersWithHoursLogged',
    // TopVolunteers = 'topVolunteers'
}

export class ReportData implements IReportData {
    @observable volunteers: VolunteerWithStatus[];
    @observable volunteerHours: (IServiceEntry & { status: string })[];
    @observable reportTimestamp: Date;

    constructor(reportData: IServerReportData) {
        makeAutoObservable(this);

        this.volunteers = reportData.volunteers;
        this.volunteerHours = reportData.volunteerHours.map(entry => {
            return {
                ...new ServerServiceEntry(entry).deserialize(),
                status: entry.status
            }
        });
        this.reportTimestamp = new Date(reportData.reportTimestamp);
    }

    /****** Static Methods ******/

    private static rangeTypeByReport: Record<ReportNames, RangeType> = {
        [ReportNames.VolunteersByStatus]: RangeType.Rangeless,
        [ReportNames.VolunteerHours]: RangeType.RangeSpecific,
        [ReportNames.VolunteersWithHoursLogged]: RangeType.RangeSpecific,
        // [ReportNames.TopVolunteers]: RangeType.RangeSpecific
    }

    static rangelessReports: ReportNames[] = (Object.keys(ReportData.rangeTypeByReport) as ReportNames[]).filter(key => ReportData.rangeTypeByReport[key] === RangeType.Rangeless);
    static rangeSpecificReports: ReportNames[] = (Object.keys(ReportData.rangeTypeByReport) as ReportNames[]).filter(key => ReportData.rangeTypeByReport[key] === RangeType.RangeSpecific);

    static reportNameByUrlSlug: Record<ReportNameSlugs, ReportNames> = {
        [ReportNameSlugs.VolunteersByStatus]: ReportNames.VolunteersByStatus,
        [ReportNameSlugs.VolunteerHours]: ReportNames.VolunteerHours,
        [ReportNameSlugs.VolunteersWithHoursLogged]: ReportNames.VolunteersWithHoursLogged,
        // [ReportNameSlugs.TopVolunteers]: ReportNames.TopVolunteers
    }

    static reportUrlSlugByName: Record<ReportNames, ReportNameSlugs> = Object.entries(ReportData.reportNameByUrlSlug).reduce((acc, [key, value]) => {
        acc[value] = key as ReportNameSlugs;
        return acc;
    }, {} as Record<ReportNames, ReportNameSlugs>);

    static reportTitleByType: Record<ReportNames, string> = {
        [ReportNames.VolunteersByStatus]: 'Current Volunteers By Status',
        [ReportNames.VolunteerHours]: 'Volunteer Hours',
        [ReportNames.VolunteersWithHoursLogged]: 'Volunteers with Hours Logged',
        // [ReportNames.TopVolunteers]: 'Top Volunteers'
    }

    static headerCellsByReportType: Record<ReportNames, ISortableTableHeader<any>[]> = {
        [ReportNames.VolunteersByStatus]: [
            { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name', sortable: true, sortValue: 'firstName' },
            { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name', sortable: true, sortValue: 'lastName' },
            { id: 'status', alignment: 'right', disablePadding: false, label: 'Status', sortable: true, sortValue: 'status' },
        ],
        [ReportNames.VolunteerHours]: [
            { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name', sortable: true, sortValue: 'firstName' },
            { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name', sortable: true, sortValue: 'lastName' },
            { id: 'role', alignment: 'left', disablePadding: false, label: 'Role', sortable: true, sortValue: 'role' },
            { id: 'date', alignment: 'left', disablePadding: false, label: 'Date', sortable: true, sortValue: 'date' },
            { id: 'hours', alignment: 'right', disablePadding: false, label: 'Hours', sortable: true, sortValue: 'hours' },
            { id: 'status', alignment: 'left', disablePadding: false, label: 'Status', sortable: true, sortValue: 'status' },
        ],
        [ReportNames.VolunteersWithHoursLogged]: [
            { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name', sortable: true, sortValue: 'firstName' },
            { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name', sortable: true, sortValue: 'lastName' },
            { id: 'total', alignment: 'right', disablePadding: false, label: 'Total Hours', sortable: true, sortValue: 'total' },
        ],
        // [ReportNames.TopVolunteers]: [
        //     { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name', sortable: true, sortValue: 'firstName' },
        //     { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name', sortable: true, sortValue: 'lastName' },
        //     { id: 'total', alignment: 'right', disablePadding: false, label: 'Total Hours', sortable: false, sortValue: 'total', direction: 'desc' },
        // ]

        // const headCells = ([
        //     { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name', sortable: true, sortValue: 'firstName' },
        //     { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name', sortable: true, sortValue: 'lastName' },
        //     { id: 'total', alignment: 'right', disablePadding: false, label: 'Total Hours', sortable: true, sortValue: 'total' },
        // ] as ISortableTableHeader<{ id: number, firstName: string, lastName: string, total: number }>[]);
    }

    /****** CSV Report ******/

    getCSVReport(report: ReportNames, options?: { startDate?: Date, endDate?: Date, sorting?: { column: string, direction: 'asc' | 'desc' }[] }) {
        return {
            filename: this.getCSVFilenameByReport(options?.startDate || null, options?.endDate || null)[report],
            data: this.getCSVReportData(report, options)
        }
    }

    private getCSVReportData(report: ReportNames, options?: { startDate?: Date, endDate?: Date, sorting?: { column: string, direction: 'asc' | 'desc' }[] }) {
        const defaultData = this.reportDataObjectsDefaultSorting[report];
        const sortedData = options?.sorting ? Sorter.getSortedObjects(defaultData as any[], options.sorting) : defaultData;
        const dataHeaders = ReportData.headerCellsByReportType[report].map(column => column.id);
        const dataColumns = sortedData.map(element => dataHeaders.map(propertyName => element[propertyName]));
        let reportData = this.getReportHeader(report, options?.startDate, options?.endDate) || [];
        reportData = reportData?.concat(dataColumns);
        return reportData;
    }

    private getCSVFilenameByReport(startDate: Date | null, endDate: Date | null): Record<ReportNames, string> {
        const dateRangeString = startDate && endDate ? `between-${format(startDate!, 'MM-dd-yy')}-and-${format(endDate!, 'MM-dd-yy')}` : '';
        const appendPulledDate = (filename: string) => {
            return filename + '_pulled-' + DateFormatter.formatDateForFileName(this.reportTimestamp);
        }
        return {
            [ReportNames.VolunteersByStatus]: appendPulledDate('volunteers-by-status'),
            [ReportNames.VolunteerHours]: appendPulledDate(`volunteers-hours-${dateRangeString}`),
            [ReportNames.VolunteersWithHoursLogged]: appendPulledDate(`volunteers-with-hours-logged-${dateRangeString}`),
            // [ReportNames.TopVolunteers]: appendPulledDate(`top-volunteers-${dateRangeString}`)
        }
    }

    /****** CSV Report Headers ******/

    private getReportHeader(report: ReportNames, startDate?: Date, endDate?: Date) {
        if (startDate && endDate) {
            switch (report) {
                case ReportNames.VolunteerHours:
                    return this.volunteerHoursReportHeader(startDate, endDate);
                case ReportNames.VolunteersWithHoursLogged:
                    return this.volunteersWithLoggedHoursReportHeader(startDate, endDate);
                // case ReportNames.TopVolunteers:
                //     return this.topVolunteersReportHeader(startDate, endDate);
            }
        }

        switch (report) {
            case ReportNames.VolunteersByStatus:
                return this.volunteerStatusReportHeader;
        }
    }

    private volunteerHoursReportHeader(startDate: Date, endDate: Date) {
        return this.rangeSpecificHeader(
            ReportNames.VolunteerHours,
            startDate,
            endDate,
            [
                ['Total Hours:', this.totalVolunteerHours.toFixed(2)],
                ['Confirmed Hours:', this.confirmedVolunteerHours.toFixed(2)]
            ]
        );
    }

    private volunteersWithLoggedHoursReportHeader(startDate: Date, endDate: Date) {
        return this.rangeSpecificHeader(
            ReportNames.VolunteersWithHoursLogged,
            startDate,
            endDate,
            [['Total Volunteers:', this.numUniqueVolunteersWithLoggedHours]]
        );
    }

    // private topVolunteersReportHeader(startDate: Date, endDate: Date) {
    //     return this.rangeSpecificHeader(
    //         ReportNames.TopVolunteers,
    //         startDate,
    //         endDate,
    //         [['Total Volunteers:', this.numUniqueVolunteersWithLoggedHours]]
    //     );
    // }

    @computed private get volunteerStatusReportHeader() {
        return [
            ['Volunteer Statuses'],
            [`Report run:`, this.reportRunFormattedString],
            ['Total Volunteers:', this.volunteers.length],
            [],
            ReportData.headerCellsByReportType[ReportNames.VolunteersByStatus].map(column => column.label)
        ]
    }

    @computed private get reportRunFormattedString() {
        return DateFormatter.formatDateTimeForDisplay(this.reportTimestamp);
    }

    private rangeSpecificHeader(report: ReportNames, startDate: Date, endDate: Date, summaryStats?: (string | number)[][]) {
        const start = format(startDate, 'MM-dd-yy');
        const end = format(endDate, 'MM-dd-yy');
        let header: (string | number)[][] = [
            [`${ReportData.reportTitleByType[report]} between ${start} and ${end}`],
            [`Report run:`, this.reportRunFormattedString],
        ];
        if (summaryStats) {
            header = header.concat(summaryStats);
        }
        header.push([]);
        header = header.concat([ReportData.headerCellsByReportType[report].map(column => column.label)]);
        return header;
    }

    /****** Summary Stats ******/

    @computed get summaryStatsByReport() {
        return {
            [ReportNames.VolunteersByStatus]: [{
                label: 'Active',
                data: this.volunteersByStatus['Active'] !== undefined ? `${this.volunteersByStatus['Active']?.length || 0} volunteers` : "-"
            }, {
                label: 'Inquiry',
                data: this.volunteersByStatus['Inquiry'] !== undefined ? `${this.volunteersByStatus['Inquiry']?.length || 0} volunteers` : "-"
            }, {
                label: 'Inactive',
                data: this.volunteersByStatus['Inactive'] !== undefined ? `${this.volunteersByStatus['Inactive']?.length || 0} volunteers` : "-"
            }, {
                label: 'Never Active',
                data: this.volunteersByStatus['Never Active'] !== undefined ? `${this.volunteersByStatus['Never Active']?.length || 0} volunteers` : "-"
            }, {
                label: 'Banned',
                data: `${this.volunteersByStatus['Banned']?.length || 0} volunteers`
            }],
            [ReportNames.VolunteerHours]: [{
                label: 'Total',
                data: this.volunteerHoursLoggedString
            }, {
                label: 'Value of Volunteer Time',
                data: this.valueOfVolunteerTime
            }],
            [ReportNames.VolunteersWithHoursLogged]: [{
                label: 'Total',
                data: this.numVolunteersWithHours
            }],
            // [ReportNames.TopVolunteers]: [{
            //     label: 'Total',
            //     data: this.numVolunteersWithHours
            // }]
        }
    }

    @computed private get totalVolunteerHoursString() {
        return this.totalVolunteerHours.toFixed(2);
    }

    @computed private get volunteerHoursLoggedString() {
        return `${this.totalVolunteerHoursString} ${getSingularOrPluralDescriptor(this.totalVolunteerHours, 'hour', 'hours')} logged (${this.confirmedVolunteerHours.toFixed(2)} ${getSingularOrPluralDescriptor(this.confirmedVolunteerHours, 'hour', 'hours')} confirmed)`;
    }

    @computed private get valueOfVolunteerTime() {
        return `${this.totalVolunteerHoursString} ${getSingularOrPluralDescriptor(this.totalVolunteerHours, 'hour', 'hours')} * $${LATEST_DOLLAR_VALUE_OF_VOLUNTEER_HOUR.toFixed(2)} per hour = $${Number((this.totalVolunteerHours * LATEST_DOLLAR_VALUE_OF_VOLUNTEER_HOUR)).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`
    }

    @computed private get numVolunteersWithHours() {
        return `${this.numUniqueVolunteersWithLoggedHours} ${getSingularOrPluralDescriptor(this.numUniqueVolunteersWithLoggedHours, 'volunteer', 'volunteers')}`;
    }

    /****** Report Data Objects ******/

    @computed get reportDataObjectsDefaultSorting() {
        return {
            [ReportNames.VolunteersByStatus]: this.volunteers,
            [ReportNames.VolunteerHours]: this.volunteerHoursReportDataObjects,
            [ReportNames.VolunteersWithHoursLogged]: this.volunteersWithHoursLoggedReportDataObjects,
            // [ReportNames.TopVolunteers]: this.topVolunteersReportDataObjects
        }
    }

    @computed private get volunteerHoursReportDataObjects() {
        return this.volunteerHours
            .filter((entry) => entry.volunteers.length > 0)
            .map(entry => {
                const volunteer = entry.volunteers[0];
                const status = entry.duration === 0 ? 'Deleted by Volunteer' : entry.status;
                return {
                    firstName: volunteer.firstName,
                    lastName: volunteer.lastName,
                    role: entry.position,
                    date: entry.date,
                    hours: this.formatDecimalNumber(entry.duration / (60 * 60)),
                    status: status
                }
            });
    }

    @computed private get volunteersWithHoursLoggedReportDataObjects() {
        return Sorter.getSortedObjects(Object.values(this.uniqueVolunteersWithLoggedHours), [{ column: 'lastName', direction: 'asc' }]).map(volunteer => {
            return {
                firstName: volunteer.firstName,
                lastName: volunteer.lastName,
                total: this.formatDecimalNumber(volunteer.total / (60 * 60))
            }
        });
    }

    // @computed private get topVolunteersReportDataObjects() {
    //     return Sorter.getSortedObjects(this.volunteersWithHoursLoggedReportDataObjects, [{ column: 'total', direction: 'desc' }]);
    // }

    /****** Private Computed Values ******/

    @computed private get totalVolunteerHours() {
        return this.volunteerHours.map((serviceEntry) => serviceEntry.duration).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (60 * 60);
    }

    @computed private get confirmedVolunteerHours() {
        return this.volunteerHours
            .filter(serviceEntry => serviceEntry.status === 'Confirmed')
            .map((serviceEntry) => serviceEntry.duration)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / (60 * 60);
    }

    @computed private get uniqueVolunteersWithLoggedHours() {
        let volunteersWithLoggedHours = {} as Record<number, VolunteerWithLoggedHours>;
        this.volunteerHours.forEach(serviceEntry => {
            const entry = new ServiceEntry(serviceEntry);
            if (entry.duration > 0) {
                const volunteerId = entry.volunteerId!;
                const volunteer = serviceEntry.volunteers[0];
                const currentTotal = volunteersWithLoggedHours[volunteerId]?.total;
                volunteersWithLoggedHours[volunteerId] = {
                    total: currentTotal ? currentTotal + serviceEntry.duration : serviceEntry.duration,
                    id: volunteer.id,
                    firstName: volunteer.firstName,
                    lastName: volunteer.lastName
                }
            }
        });
        return volunteersWithLoggedHours;
    }

    @computed private get numUniqueVolunteersWithLoggedHours() {
        return Object.keys(this.uniqueVolunteersWithLoggedHours).length;
    }

    @computed private get volunteersByStatus() {
        const volunteersByStatus = {} as Record<string, VolunteerWithStatus[]>;
        this.volunteers.forEach(volunteer => {
            const status = volunteer.status;
            const currentValueForStatus = volunteersByStatus[status];
            if (currentValueForStatus) {
                volunteersByStatus[status].push(volunteer);
            } else {
                volunteersByStatus[status] = [volunteer];
            }
        });
        return volunteersByStatus;
    }

    /****** Utility ******/

    private formatDecimalNumber(value: number): string {
        const formattedValue = value.toFixed(2);
        return formattedValue.replace(/\.?0+$/, ''); // Remove trailing decimal and zeros
    }
}