import { makeAutoObservable } from "mobx";

export interface IToggleButtonProps<T extends string> {
    value: T;
    icon: JSX.Element;
}

export interface IToggleButtonState<T extends string> {
    toggleButtons: IToggleButtonProps<T>[];
    selectedButton: T;
}

export class ToggleButtonState<T extends string = string> implements IToggleButtonState<T> {
    toggleButtons: IToggleButtonProps<T>[];
    selectedButton: T;

    constructor(viewModeState: IToggleButtonState<T>) {
        makeAutoObservable(this);

        this.toggleButtons = viewModeState.toggleButtons;
        this.selectedButton = viewModeState.selectedButton;
    }

    setSelectedButton(selectedMode: T) {
        this.selectedButton = selectedMode;
    }
}