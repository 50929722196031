import { makeStyles, Theme, createStyles, Box } from "@material-ui/core";
import { observer } from "mobx-react";
import { ResponsiveTimeRange } from '@nivo/calendar';
import { startOfMonth } from "date-fns";
import { addMonths } from "date-fns/esm";
import { ResponsiveWrapper, SvgWrapper, ThemeProvider } from "@nivo/core";
import { BoxLegendSvg } from "@nivo/legends";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
        customLegend: {
            height: 50,
            width: '100%',
            position: 'absolute',
            top: '50%',
            right: 0
        }
    }),
);

interface CalendarHeatMapWidgetProps {
    data: { day: string, value: number }[];
}

const CalendarHeatMapWidget = observer((props: CalendarHeatMapWidgetProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Render *********/

    const monthStart = startOfMonth(new Date());
    const nextMonth = addMonths(monthStart, 1);
    const nextMonthStart = startOfMonth(nextMonth);

    return (
        <div className={classes.root}>
            <Box style={{ width: '100%', height: 180 }}>
                <ResponsiveTimeRange
                    data={props.data}
                    from={monthStart}
                    to={nextMonthStart}
                    direction="vertical"
                    dayRadius={4}
                    weekdayTicks={[]}
                    emptyColor="#eeeeee"
                    colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
                    weekdayLegendOffset={0}
                    theme={{ text: { fill: 'none' }}}
                    monthLegendOffset={-30}
                    margin={{ top: 0, right: 70, bottom: 0, left: 16 }}
                    dayBorderWidth={2}
                    dayBorderColor="#ffffff"
                    legends={[
                        {
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            itemCount: 4,
                            itemWidth: 30,
                            itemHeight: 20,
                            itemsSpacing: 8,
                            itemDirection: 'left-to-right',
                            translateX: -36,
                            translateY: -8,//-60,
                            symbolSize: 20,
                            itemTextColor: 'black'
                        }
                    ]}
                />
            </Box>
            {props.data.length === 0 &&
                <div className={classes.customLegend}>
                    <ThemeProvider>
                        <ResponsiveWrapper>
                            {(dimensions: { width: number, height: number }) =>
                                <SvgWrapper
                                    height={dimensions.height}
                                    width={dimensions.width}
                                    margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
                                >
                                    <BoxLegendSvg
                                        anchor="right"
                                        // TODO: Implement a function that will assign each of the four colors an appropriate range label as opposed to a single value label
                                        // data={props.data.map(((datum, index) => {
                                        //     return {
                                        //         id: index,
                                        //         label: datum.value.toString(),
                                        //         fill: '#bbbbbb'
                                        //     }
                                        // })).concat({
                                        //     id: -1,
                                        //     label: 'N/A',
                                        //     fill: '#eeeeee'
                                        // })}
                                        data={[{
                                            id: -1,
                                            label: 'N/A',
                                            fill: '#eeeeee'
                                        }]}
                                        containerWidth={dimensions.width}
                                        containerHeight={dimensions.height}
                                        direction="column"
                                        itemWidth={30}
                                        itemHeight={20}
                                        itemsSpacing={8}
                                        translateX={-36}
                                        translateY={-10}
                                        symbolSize={20}
                                    />
                                </SvgWrapper>
                            }
                        </ResponsiveWrapper>
                    </ThemeProvider>
                </div>
            }
        </div>
    )
});

export default CalendarHeatMapWidget;