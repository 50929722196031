import { createStyles, makeStyles, Theme, BoxProps } from "@material-ui/core";
import { Fragment } from "react";
import { observer } from "mobx-react";
import { IdentifiableObject } from "../../stores/models/OptionCollection";
import { Option } from "../../stores/models/Option";
import { Dictionary } from "../../logic/Dictionaries";
import clsx from "clsx";
import REMChip from "../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            padding: 0,
            '& > :not(:last-child)': {
                marginRight: theme.spacing(.5)
            }
        },
        listItem: {
            maxWidth: '100%'
        },
        chip: {
            margin: theme.spacing(0.5),
            maxWidth: '100%',
            background: theme.palette.primaryBackground.main,
            '&:focus': {
                background: theme.palette.primaryBackground.main,
            }
        }
    })
);

type FilterCollection<OptionObject extends IdentifiableObject<any>> = {
    getLabel: (optionObject: OptionObject) => string,
    selections: Option<OptionObject>[]
};

/*** Individual Filter Chip ***/

interface FilterChipProps<OptionObject extends IdentifiableObject<any>> extends FilterCollection<OptionObject> {
    filter: string;
}

const FilterChip = observer(<OptionObject extends IdentifiableObject<any>>(props: FilterChipProps<OptionObject>) => {

    const classes = useStyles();

    const label = (
        <Fragment>
            <b>{props.filter}: </b>
            {props.selections.map(selection => props.getLabel(selection.object)).join(', ')}
        </Fragment>
    )

    const onDelete = () => {
        props.selections.forEach(selection => selection.toggleSelection());
    }

    return (
        <REMChip
            label={label}
            onDelete={onDelete}
            className={classes.chip}
        />
    )
});

/*** Group of Filter Chips ***/

interface FilterChipsProps<OptionObject extends IdentifiableObject<any>> extends BoxProps {
    filters: Dictionary<string, FilterCollection<OptionObject>>;
}

const FilterChips = observer(<OptionObject extends IdentifiableObject<any>>(props: FilterChipsProps<OptionObject>) => {

    const classes = useStyles();

    const hasSelections = Object.values(props.filters).flatMap(filter => filter.selections).length > 0;

    /********* Render *********/

    return (
        <Fragment>
            {hasSelections &&
                <ul {...props} className={clsx(classes.list, props.className)} >
                    {Object.keys(props.filters).map((filterName, index) => {
                        const selections = props.filters[filterName].selections;
                        if (selections.length > 0) {
                            return (
                                <li key={`${filterName}-${index}`} className={classes.listItem}>
                                    <FilterChip filter={filterName} {...props.filters[filterName]} />
                                </li>
                            );
                        }
                    })}
                </ul>
            }
        </Fragment>
    );
});

export default FilterChips;