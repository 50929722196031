import { ServerRepetitionPattern, IServerRepetitionPattern, isServerRepetitionPattern } from "./ServerRepetitionPattern";
import { IInstanceData, IShift, Shift } from "../Shift";
import { IServerAddress, ServerAddress } from "./ServerAddress";
import { action, computed, makeObservable, observable } from "mobx";
import { IShiftRegistration } from "../ShiftRegistration";
import { ShiftRegistration } from "..";
import { addMinutes } from "date-fns";
import { Archivable, IArchivable } from "../Archivable";

export const isServerShift = (x: any): x is IServerShift => {
    if (typeof x === 'object') {
        return isServerRepetitionPattern((x as { repetitionPattern: any }).repetitionPattern);
    } else {
        return false;
    }
}

export interface IServerInstanceData extends IInstanceData {
    parentStartDate?: Date;
    timezoneOffsetApplied?: boolean;
}

export interface IServerShift extends IArchivable {
    id?: number;
    addressId?: number;
    address?: IServerAddress;
    repetitionPattern: IServerRepetitionPattern;
    modifiedInstances?: IServerShift[];
    instanceData?: IServerInstanceData;
    slots: number;
    shiftRegistrations?: IShiftRegistration[];
}

export class ServerShift extends Archivable implements IServerShift {
    @observable id?: number;
    @observable addressId?: number;
    @observable address?: ServerAddress;
    @observable repetitionPattern: ServerRepetitionPattern;
    @observable modifiedInstances?: ServerShift[];
    @observable instanceData?: IInstanceData;
    @observable slots: number;
    @observable shiftRegistrations?: ShiftRegistration[];

    private parentStartDate?: Date;

    constructor(clientOrServerObject: Shift | IServerShift) {
        super(clientOrServerObject);
        makeObservable(this);

        this.id = clientOrServerObject.id;
        this.repetitionPattern = new ServerRepetitionPattern(clientOrServerObject.repetitionPattern);
        if ('addressId' in clientOrServerObject && clientOrServerObject.addressId !== null && clientOrServerObject.addressId !== undefined) {
            this.addressId = clientOrServerObject.addressId;
        } else if ('address' in clientOrServerObject) {
            this.addressId = clientOrServerObject.address ? clientOrServerObject.address.id : undefined;
        }
        this.address = clientOrServerObject.address ? new ServerAddress(clientOrServerObject.address) : undefined;
        if (clientOrServerObject.modifiedInstances) {
            this.modifiedInstances = (clientOrServerObject.modifiedInstances as (Shift | IServerShift)[]).map(instance => new ServerShift(instance));
        }
        this.instanceData = clientOrServerObject.instanceData
            ? {
                defaultDaysFromStartDate: clientOrServerObject.instanceData.defaultDaysFromStartDate,
                deleted: clientOrServerObject.instanceData.deleted,
                parentId: clientOrServerObject.instanceData.parentId
            }
            : undefined;
        if (clientOrServerObject.instanceData && 'parentStartDate' in clientOrServerObject.instanceData) {
            this.parentStartDate = clientOrServerObject.instanceData.parentStartDate
                ? new Date(clientOrServerObject.instanceData.parentStartDate)
                : undefined;
            // If the child instance's start and end times are based on a calculation from the 
            // parent instance, make any necessary adjustments based on differences in timezone
            // offset due to daylight saving time.
            if (clientOrServerObject.instanceData.timezoneOffsetApplied === false) {
                this.accountForTimezoneOffset();
            }
        }
        this.slots = clientOrServerObject.slots;
        this.shiftRegistrations = clientOrServerObject.shiftRegistrations
            ? (clientOrServerObject.shiftRegistrations as (IShiftRegistration | ShiftRegistration)[]).map(registration => new ShiftRegistration(registration))
            : undefined;
    }

    @action setAddress(address: ServerAddress) {
        this.address = address;
    }

    @computed private get timezoneOffset() {
        return this.repetitionPattern.startTimestamp.getTimezoneOffset();
    }

    @computed private get parentTimezoneOffset() {
        return this.parentStartDate?.getTimezoneOffset();
    }

    private accountForTimezoneOffset() {
        // Compare the shift's timezone offset to the instance's timezone offset
        // Add hours or minutes as needed
        if (this.parentTimezoneOffset) {
            const differenceInTimezoneOffset = this.timezoneOffset - this.parentTimezoneOffset;
            if (differenceInTimezoneOffset !== 0) {
                const newStartTimestamp = addMinutes(this.repetitionPattern.startTimestamp, differenceInTimezoneOffset);
                const newEndTimestamp = addMinutes(this.repetitionPattern.endTimestamp, differenceInTimezoneOffset);
                this.repetitionPattern.setStartTimestamp(newStartTimestamp);
                this.repetitionPattern.setEndTimestamp(newEndTimestamp);
            }
        }
    }

    deserialize(): Shift {
        const shiftData: IShift = {
            id: this.id,
            address: this.address ? this.address.deserialize() : undefined,
            addressId: this.addressId,
            repetitionPattern: this.repetitionPattern.deserialize(),
            modifiedInstances: this.modifiedInstances?.map(instance => instance.deserialize()),
            instanceData: this.instanceData,
            slots: this.slots,
            shiftRegistrations: this.shiftRegistrations,
            archived: this.archived
        }
        return new Shift(shiftData);
    }
}