import { createStyles, makeStyles, Theme, MenuItemProps } from "@material-ui/core";
import { Fragment, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { DialogState } from "../../stores/models/DialogState";
import ClickAwayPopper from "../AccountSettings/Pages/Organization/ClickAwayPopper";
import NestedMenu from "./NestedMenu";
import { ChevronRight } from "@material-ui/icons";
import { Option } from "../../stores/models/Option";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        chevron: {
            alignSelf: 'center',
            marginLeft: theme.spacing(.5)
        },
        nestedMenu: {
            marginLeft: '3px'
        }
    })
);

export type NestedMenuItems<OptionObject extends any> = {
    label: string,
    MenuItem: (props: MenuItemProps & { button?: true }, menuItem: NestedMenuItems<OptionObject>) => JSX.Element,
    onClick?: () => void,
    option?: Option<OptionObject>;
    subItems?: NestedMenuItems<OptionObject>[];
}

interface NestedMenuItemProps<OptionObject extends any> {
    menuItem: NestedMenuItems<OptionObject>;
}

const NestedMenuItem = observer(<
    OptionObject extends any
>(props: NestedMenuItemProps<OptionObject>) => {

    const classes = useStyles();
    const anchorRef = useRef<HTMLLIElement>(null);

    const subMenuState = useMemo(() => new DialogState(), []);
    const hasSubMenu = props.menuItem.subItems && props.menuItem.subItems.length > 0;

    /********* Event Handlers *********/

    const onMenuItemClicked = () => {
        if (hasSubMenu) {
            subMenuState.setOpen(true);
        } else if (props.menuItem.option) {
            props.menuItem.option.toggleSelection();
        } else if (props.menuItem.onClick) {
            props.menuItem.onClick();
        }
    }

    /********* Render *********/

    return (
        <Fragment>
            {props.menuItem.MenuItem({
                ref: anchorRef,
                onClick: onMenuItemClicked,
                children: (
                    <Fragment>
                        {hasSubMenu
                            ? <div className={classes.flex}>
                                {props.menuItem.label}
                                <ChevronRight className={classes.chevron} fontSize='small' />
                            </div>
                            : props.menuItem.label
                        }
                    </Fragment>
                ),
            }, props.menuItem)}
            {hasSubMenu &&
                <ClickAwayPopper
                    anchorEl={anchorRef}
                    state={subMenuState}
                    PopperProps={{ placement: 'right-start' }}
                >
                    <NestedMenu
                        state={subMenuState}
                        menuItems={props.menuItem.subItems!}
                        className={classes.nestedMenu}
                    />
                </ClickAwayPopper>
            }
        </Fragment>
    );
});

export default NestedMenuItem;