import React, { FunctionComponent, useRef, useState } from "react";
import { makeStyles, Theme, createStyles, TextFieldProps, TextField } from "@material-ui/core";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            background: theme.palette.darkBackground.main,
            zIndex: theme.zIndex.drawer
        },
        alignRight: {
            textAlign: 'right'
        }
    })
);

interface TextFieldWithCharacterLimitProps {
    characterLimit: number;
    TextFieldProps: TextFieldProps;
}

const TextFieldWithCharacterLimit: FunctionComponent<TextFieldWithCharacterLimitProps> = observer((props) => {

    /********* React hooks *********/

    const classes = useStyles();
    const inputEl = useRef<HTMLElement>(null);

    /********* State *********/

    const [hasFocus, setHasFocus] = useState(false);

    /********* Helper methods *********/

    const getValueLength = () => {
        return props.TextFieldProps.value ? (props.TextFieldProps.value as string).length : 0;
    }

    const getCharacterLimitText = () => {
        return `${getValueLength()}/${props.characterLimit}`;
    }

    const fieldContainsText = () => {
        return (props.TextFieldProps.value as string).length > 0;
    }

    const shouldDisplayCharacterLimit = () => {
        return exceedsCharacterLimit() || (hasFocus && !props.TextFieldProps.error && fieldContainsText());
    }

    const getHelperText = () => {
        if (shouldDisplayCharacterLimit()) {
            return getCharacterLimitText();
        } else {
            return props.TextFieldProps.helperText;
        }
    }

    const exceedsCharacterLimit = () => {
        return props.TextFieldProps.value !== undefined
            && (props.TextFieldProps.value as string).length > props.characterLimit;
    }

    const hasError = () => {
        return props.TextFieldProps.error || exceedsCharacterLimit();
    }

    /********* Render *********/

    return (
        <TextField
            {...props.TextFieldProps}
            helperText={getHelperText()}
            error={hasError()}
            inputRef={inputEl}
            onFocus={(event) => setHasFocus(true)}
            onBlur={(event) => setHasFocus(false)}
            FormHelperTextProps={{ classes: { root: shouldDisplayCharacterLimit() ? classes.alignRight : undefined } }}
        />
    )
});

export default TextFieldWithCharacterLimit;