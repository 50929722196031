import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStandardStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
        },
        columnFlex: {
            flexDirection: 'column'
        },
        fullWidth: {
            width: '100%'
        },
        flexWrap: {
            flexWrap: 'wrap'
        },
        justifyCenter: {
            justifyContent: 'center'
        },
        alignCenter: {
            alignItems: 'center'
        },
        noShrink: {
            flexShrink: 0
        },
        overflowHidden: {
            overflow: 'hidden'
        },
        truncateText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }),
);