import { format } from "date-fns";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { Dictionary } from "../../logic/Dictionaries";
import { IServerShift, isServerShift, ServerShift } from "./server/ServerShift";
import { IShift, Shift } from "./Shift";
import { DASHBOARD_COLOR_POSITION_SUFFIX, DASHBOARD_OPPORTUNITY_ID_SUFFIX } from "./constants";

export interface TopVolunteer {
    id: number;
    firstName: string;
    lastName: string;
    hours: number;
}

interface CategorizedMonthlyServiceHours {
    month: number;
    year: number;
    opportunityId: number;
    position: string;
    hours: number;
}

export interface IDashboardData {
    numActivePosts: number;
    numActiveVolunteers: number;
    numInquiries: number;
    numSparks: number;
    topVolunteers: TopVolunteer[];
    unconfirmedHours: number;
    monthlyServiceHours: CategorizedMonthlyServiceHours[];
    shifts: IServerShift[] | IShift[];
}

export class DashboardData implements IDashboardData {
    @observable numActivePosts: number;
    @observable numActiveVolunteers: number;
    @observable numInquiries: number;
    @observable numSparks: number;
    @observable topVolunteers: TopVolunteer[];
    @observable unconfirmedHours: number;
    @observable monthlyServiceHours: CategorizedMonthlyServiceHours[];
    @observable shifts: Shift[] = [];

    @observable private numMonthsDataToDisplay: number = 6;
    private today = new Date();

    constructor(dashboardData: IDashboardData) {
        makeAutoObservable(this);

        this.numActivePosts = dashboardData.numActivePosts;
        this.numActiveVolunteers = dashboardData.numActiveVolunteers;
        this.numInquiries = dashboardData.numInquiries;
        this.numSparks = dashboardData.numSparks;
        this.unconfirmedHours = dashboardData.unconfirmedHours;
        this.topVolunteers = dashboardData.topVolunteers;
        this.monthlyServiceHours = dashboardData.monthlyServiceHours;
        if (dashboardData.shifts.length > 0) {
            if (isServerShift(dashboardData.shifts[0])) {
                this.shifts = (dashboardData.shifts as IServerShift[]).map(shift => new ServerShift(shift).deserialize());
            } else {
                this.shifts = (dashboardData.shifts as IShift[]).map(shift => new Shift(shift));
            }
        }
    }

    @action setNumMonthsToDisplay(numMonths: number) {
        this.numMonthsDataToDisplay = numMonths;
    }

    @computed get totalHoursYTD() {
        let total = 0;
        this.monthlyServiceHoursForCurrentYear.forEach(monthlyData => {
            total += monthlyData.hours;
        })
        return total;
    }

    @computed get hasDataFromCurrentYear() {
        const currentYear = this.today.getFullYear();
        return this.monthlyServiceHours.findIndex(monthlyData => monthlyData.year === currentYear) !== -1;
    }

    @computed private get monthlyServiceHoursForCurrentYear() {
        if (!this.hasDataFromCurrentYear) {
            return [];
        }
        const indexOfFirstDataFromCurrentYear = this.monthlyServiceHours.findIndex((monthlyData, index) => index > 0 && monthlyData.month < this.monthlyServiceHours[index - 1].month);
        const allDataFromCurrentYear = indexOfFirstDataFromCurrentYear === -1;
        if (allDataFromCurrentYear) {
            return this.monthlyServiceHours.slice();
        } else {
            return this.monthlyServiceHours.slice(indexOfFirstDataFromCurrentYear)
        }
    }

    @computed get yearlyTotalsByOpportunity() {
        const opportunityTotalsDictionary = {} as Dictionary<number, { id: string | number, position: string, total: number, colorPosition: number }>;
        this.monthlyServiceHoursForCurrentYear.forEach(monthlyData => {
            if (opportunityTotalsDictionary[monthlyData.opportunityId]) {
                opportunityTotalsDictionary[monthlyData.opportunityId].total += monthlyData.hours;
            } else {
                opportunityTotalsDictionary[monthlyData.opportunityId] = {
                    id: monthlyData.opportunityId ? monthlyData.opportunityId : 'Other',
                    position: monthlyData.position ? monthlyData.position : 'Other',
                    total: monthlyData.hours,
                    colorPosition: this.opportunityColorNumbering[monthlyData.opportunityId]
                }
            }
        });
        return Object.values(opportunityTotalsDictionary);
    }

    @computed private get lastXMonths() {
        const lastXMonths = [] as number[];
        for (let index = 0; index < this.numMonthsDataToDisplay; index++) {
            const numMonthsPrior = this.numMonthsDataToDisplay - index;
            lastXMonths.push(((this.currentMonth + 1) - (numMonthsPrior) + 12) % 12);
        }
        return lastXMonths;
    }

    @computed private get lastXMonthsDictionary() {
        const dictionary = {} as Dictionary<number, { month: string }>;
        this.lastXMonths.forEach((month, index) => {
            const date = new Date().setMonth(month, 1);
            const monthAbbrev = format(date, 'MMM');
            dictionary[index] = {
                month: monthAbbrev
            }
        });
        return dictionary;
    }

    @computed get hasServiceHoursDataInLastXMonths() {
        return this.monthlyServiceHoursBarGraphData.findIndex(monthlyData => Object.keys(monthlyData).length > 1) !== -1;
    }

    @computed private get opportunityColorNumbering() {
        let colorIndex = 0;
        const opportunityColorNumbering = {} as Dictionary<number, number>;
        this.monthlyServiceHours.forEach(monthlyData => {
            if (opportunityColorNumbering[monthlyData.opportunityId] === undefined) {
                opportunityColorNumbering[monthlyData.opportunityId] = colorIndex;
                colorIndex++;
            }
        });
        return opportunityColorNumbering;
    }

    @computed private get currentMonth() {
        return this.today.getMonth();
    }

    @computed private get serviceHoursForRequestedNumMonths() {
        return this.getMonthlyServiceDataForLastXMonths(this.numMonthsDataToDisplay);
    }

    // Only the last six months of data is guaranteed to be available
    @computed private getMonthlyServiceDataForLastXMonths(numMonths: number) {
        return this.monthlyServiceHours.filter(monthlyData => this.lastXMonths.findIndex(month => month === monthlyData.month - 1) !== -1);
    }

    @computed get monthlyServiceHoursBarGraphData() {
        const lastXMonths = this.lastXMonths;
        const monthDictionary = this.lastXMonthsDictionary;
        this.serviceHoursForRequestedNumMonths.forEach(monthlyData => {
            const month = monthlyData.month - 1;
            const index = lastXMonths.findIndex(monthNum => monthNum === month);
            if (index !== -1) {
                const key = monthlyData.position ? monthlyData.position : 'Other';
                monthDictionary[index] = {
                    ...monthDictionary[index],
                    [key]: monthlyData.hours,
                    [key + DASHBOARD_COLOR_POSITION_SUFFIX]: this.opportunityColorNumbering[monthlyData.opportunityId],
                    [key + DASHBOARD_OPPORTUNITY_ID_SUFFIX]: monthlyData.opportunityId
                }
            }
        });
        return Object.values(monthDictionary);
    }
}