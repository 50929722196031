import { IOpportunity, OpportunityNeed, TimeCommitment, OpportunityScheduling, Opportunity, OpportunityLocation, OpportunityPermissionType } from '../Opportunity';
import { ISkill } from '../Skill';
import { ServerShift } from './ServerShift';
import { makeAutoObservable } from 'mobx';
import { ShiftCollection } from '../ShiftCollection';
import { AddressCollection } from '../Addressable';
import { ServerAddress } from './ServerAddress';
import { FilterData } from '../FilterData';
import { IServiceEntryTag, ServiceEntryTag } from '../ServiceEntryTag';
import { Form, IForm } from '../Form';

const clientToDbSchedulingTypeMapping = {
    [OpportunityScheduling.Flexible]: "Flexible",
    [OpportunityScheduling.Shifts]: "Shifts",
    [OpportunityScheduling.Unspecified]: "",
};

const dbToClientSchedulingTypeMapping = {
    [clientToDbSchedulingTypeMapping[OpportunityScheduling.Flexible]]: OpportunityScheduling.Flexible,
    [clientToDbSchedulingTypeMapping[OpportunityScheduling.Shifts]]: OpportunityScheduling.Shifts,
    [clientToDbSchedulingTypeMapping[OpportunityScheduling.Unspecified]]: OpportunityScheduling.Unspecified
}

const clientToDbPermissionTypeMapping = {
    [OpportunityPermissionType.Unspecified]: "",
    [OpportunityPermissionType.Open]: "None",
    [OpportunityPermissionType.Organization]: "Organization Level",
    [OpportunityPermissionType.Opportunity]: "Opportunity Level",
    [OpportunityPermissionType.AssignmentOnly]: "Assignment Only",
};

const dbToClientPermissionTypeMapping = {
    [clientToDbPermissionTypeMapping[OpportunityPermissionType.Unspecified]]: OpportunityPermissionType.Unspecified,
    [clientToDbPermissionTypeMapping[OpportunityPermissionType.Open]]: OpportunityPermissionType.Open,
    [clientToDbPermissionTypeMapping[OpportunityPermissionType.Organization]]: OpportunityPermissionType.Organization,
    [clientToDbPermissionTypeMapping[OpportunityPermissionType.Opportunity]]: OpportunityPermissionType.Opportunity,
    [clientToDbPermissionTypeMapping[OpportunityPermissionType.AssignmentOnly]]: OpportunityPermissionType.AssignmentOnly,
}

export const isClientOpportunity = (x: any): x is IOpportunity => {
    if (typeof x === 'object') {
        return ('addressCollection' in x);
    } else {
        return false;
    }
}

export interface IServerOpportunity {
    id: number;
    organizationId: number;
    organizationName: string;
    active: boolean;
    title: string;
    position: string;
    description: string;
    trainingDescription: string;
    ongoing: boolean;
    longTerm: boolean;
    virtual: boolean;
    schedulingType: string;
    permissionType?: string;
    expirationTimestamp: Date | null;
    skills: ISkill[];
    shifts: ServerShift[];
    addresses: ServerAddress[];
    interestSparked: boolean;
    responseSubmitted: boolean;
    hasPermission: boolean;
    filterData: FilterData;
    serviceEntryTags: IServiceEntryTag[];
    registration?: { completed: boolean, form?: IForm };
}

export class ServerOpportunity implements IServerOpportunity {
    id: number;
    organizationId: number;
    organizationName: string;
    active: boolean;
    title: string;
    position: string;
    description: string;
    trainingDescription: string;
    ongoing: boolean;
    longTerm: boolean;
    virtual: boolean;
    schedulingType: string;
    permissionType?: string;
    expirationTimestamp: Date | null;
    skills: ISkill[];
    shifts = [] as ServerShift[];
    addresses = [] as ServerAddress[];
    interestSparked: boolean;
    responseSubmitted: boolean;
    hasPermission: boolean;
    filterData: FilterData;
    serviceEntryTags = [] as IServiceEntryTag[];
    registration?: { completed: boolean, form?: Form };

    constructor(clientOrServerObject: Opportunity | IServerOpportunity) {
        makeAutoObservable(this);

        this.id = clientOrServerObject.id;
        this.organizationId = clientOrServerObject.organizationId;
        this.organizationName = clientOrServerObject.organizationName;
        this.active = clientOrServerObject.active;
        this.title = clientOrServerObject.title;
        this.position = clientOrServerObject.position;
        this.description = clientOrServerObject.description;
        this.trainingDescription = clientOrServerObject.trainingDescription;
        this.skills = clientOrServerObject.skills;
        this.interestSparked = clientOrServerObject.interestSparked || false;
        this.responseSubmitted = clientOrServerObject.responseSubmitted || false;
        this.hasPermission = clientOrServerObject.hasPermission || false;
        this.serviceEntryTags = clientOrServerObject.serviceEntryTags;
        if (isClientOpportunity(clientOrServerObject)) {
            this.expirationTimestamp = this.active
                ? clientOrServerObject.expirationTimestamp
                : null;
            this.virtual = clientOrServerObject.virtual === OpportunityLocation.Remote;
            this.ongoing = clientOrServerObject.need === OpportunityNeed.Ongoing;
            this.longTerm = clientOrServerObject.timeCommitment === TimeCommitment.LongTerm;
            this.schedulingType = clientToDbSchedulingTypeMapping[clientOrServerObject.scheduling];
            this.permissionType = clientOrServerObject.permissionType === OpportunityPermissionType.Unspecified
                ? undefined
                : clientToDbPermissionTypeMapping[clientOrServerObject.permissionType];

            this.filterData = new FilterData(clientOrServerObject.filterData);
            this.shifts = clientOrServerObject.shiftCollection.shifts.map(shift => { return shift.serialize(); });
            this.addresses = clientOrServerObject.virtual === OpportunityLocation.Remote
                ? []
                : clientOrServerObject.addressCollection.addresses.map(address => {
                    return address.serialize();
                });
        } else {
            this.expirationTimestamp = clientOrServerObject.expirationTimestamp || null;
            this.virtual = clientOrServerObject.virtual;
            this.ongoing = clientOrServerObject.ongoing;
            this.longTerm = clientOrServerObject.longTerm;
            this.schedulingType = clientOrServerObject.schedulingType;
            this.permissionType = clientOrServerObject.permissionType;
            this.filterData = clientOrServerObject.filterData;
            this.addresses = clientOrServerObject.addresses
                ? clientOrServerObject.addresses.map(address => {
                    return new ServerAddress(address);
                })
                : [];
            if (clientOrServerObject.shifts) {
                this.shifts = clientOrServerObject.shifts.map(shift => { return new ServerShift(shift) });
                this.populateShiftAddresses();
            }
            this.registration = clientOrServerObject.registration ? {
                ...clientOrServerObject.registration,
                form: clientOrServerObject.registration.form ? new Form(clientOrServerObject.registration.form) : undefined
            } : undefined;
        }
    }

    private get addressDictionary() {
        let dictionary = {} as { [key: number]: ServerAddress };
        this.addresses.forEach(address => {
            dictionary[address.id] = address;
        });
        return dictionary;
    }

    private populateShiftAddresses() {
        this.shifts.forEach(shift => {
            if (shift.addressId) {
                shift.setAddress(this.addressDictionary[shift.addressId]);
            }
            shift.modifiedInstances?.forEach(shiftInstance => {
                if (shiftInstance.addressId) {
                    shiftInstance.setAddress(this.addressDictionary[shiftInstance.addressId]);
                }
            })
        })
    }

    deserialize(): Opportunity {
        const opportunityData: IOpportunity = {
            id: this.id,
            organizationId: this.organizationId,
            organizationName: this.organizationName,
            active: this.active,
            title: this.title,
            position: this.position,
            description: this.description,
            trainingDescription: this.trainingDescription,
            need: this.ongoing ? OpportunityNeed.Ongoing : OpportunityNeed.OneTime,
            timeCommitment: this.longTerm ? TimeCommitment.LongTerm : TimeCommitment.ShortTerm,
            virtual: this.virtual ? OpportunityLocation.Remote : OpportunityLocation.InPerson,
            scheduling: dbToClientSchedulingTypeMapping[this.schedulingType],
            permissionType: this.permissionType ? dbToClientPermissionTypeMapping[this.permissionType] : dbToClientPermissionTypeMapping[''],
            skills: this.skills,
            shiftCollection: new ShiftCollection(this.shifts.map(shift => shift.deserialize())),
            addressCollection: new AddressCollection(this.addresses.map(address => address.deserialize())),
            expirationTimestamp: this.expirationTimestamp ? new Date(this.expirationTimestamp) : null,
            interestSparked: this.interestSparked,
            responseSubmitted: this.responseSubmitted,
            hasPermission: this.hasPermission,
            filterData: this.filterData,
            serviceEntryTags: this.serviceEntryTags ? this.serviceEntryTags.map(tag => new ServiceEntryTag(tag)) : [],
            registration: this.registration
        };
        return new Opportunity(opportunityData);
    }
}