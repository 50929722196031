import React from "react";
import { makeStyles, Theme, createStyles, Button, Badge, useMediaQuery } from "@material-ui/core";
import { MenuItem, MenuOption } from "../../stores/models/MenuItem";
import useMenuItems from "./MainMenuItemsHook";
import { observer } from "mobx-react";
import MdiIcon from "../Shared/MdiIcon";
import { useNavigateInternally } from "./Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuWrapper: {
            color: theme.palette.common.white,
            display: 'flex',
            alignItems: 'center',
            '& > :not(:last-child)': {
                marginRight: theme.spacing(.5)
            }
        },
        authenticationButton: {
            margin: theme.spacing(1),
            marginLeft: theme.spacing(0),
            padding: theme.spacing(1, 2)
        },
        button: {
            color: "#474747",
            padding: theme.spacing(1, 2),
            '&:hover, &:active': {
                backgroundColor: 'rgba(0, 0, 0, 0.43)'
            },
        },
        iconSizeMedium: {
            '& *:first-child': {
                fontSize: '1.25rem'
            }
        }
    })
);

const DesktopMenu = observer(() => {

    /*****************  React hooks  *****************/

    const classes = useStyles();
    const navigate = useNavigateInternally();
    const menuItems = useMenuItems();
    const shouldShortenMenuItems = useMediaQuery((theme: Theme) => theme.breakpoints.down(1130));

    /*****************  Helper method  *****************/

    const onMenuItemClicked = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
        menuItem: MenuItem
    ) => {
        event.preventDefault();
        if (menuItem.onClick) {
            menuItem.onClick();
        }
        if (menuItem.fullLink) {
            navigate(menuItem.fullLink);
        }
    };

    /*****************  Render  *****************/

    return (
        <div className={classes.menuWrapper}>
            {menuItems.map((menuItem, index) => {
                const isAuthenticationMenuItem = menuItem.option === MenuOption.SignUp || menuItem.option === MenuOption.Login;
                const icon = menuItem.icon ? typeof menuItem.icon === 'string' ? <MdiIcon iconPath={menuItem.icon} fontSize="large" /> : React.createElement(menuItem.icon) : undefined;
                return (
                    <Button
                        key={`main-menu-button-${index}`}
                        variant={isAuthenticationMenuItem ? "contained" : "text"}
                        className={isAuthenticationMenuItem ? classes.authenticationButton : classes.button}
                        startIcon={icon ? menuItem.badge ? <Badge badgeContent={menuItem.badge} color='error'>{icon}</Badge> : icon : undefined}
                        classes={{
                            iconSizeMedium: classes.iconSizeMedium
                        }}
                        color={isAuthenticationMenuItem ? "primary" : 'inherit'}
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            onMenuItemClicked(event, menuItem);
                        }}
                    >
                        {shouldShortenMenuItems && menuItem.shortenedText ? menuItem.shortenedText : menuItem.text}
                    </Button>
                );
            })}
        </div>
    )
});

export default DesktopMenu;