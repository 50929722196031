import { observable, action, computed, makeObservable } from "mobx";
import { Fields } from "./Fields";
import { UserType } from "./User";
import { validateStubUser } from "../../logic/ValidationChecks/StubUserValidation";
import { StubOrganization } from "./StubOrganization";
import { StubVolunteer } from "./StubVolunteer";
import { PaymentMethod } from "./PaymentMethod";
import { Credentials } from "./Credentials";

interface IStubUserFields {
    type: UserType;
    termsOfServiceAccepted: boolean;
}

export class StubUser extends Fields<IStubUserFields, StubUser> implements IStubUserFields {
    @observable type = UserType.None;
    @observable data?: StubOrganization | StubVolunteer;
    @observable credentials = new Credentials();
    @observable paymentMethod = new PaymentMethod();
    @observable termsOfServiceAccepted = false;

    constructor(userType?: UserType) {
        super();

        makeObservable(this);

        if (userType !== undefined) {
            this.type = userType;
            this.setDataForUserType();
        } else {
            this.type = UserType.None;
        }
    }

    @action refreshPaymentMethod() {
        this.paymentMethod = new PaymentMethod();
    }

    @action setType(type: UserType) {
        this.type = type;
        this.setDataForUserType();
    }

    @action setTermsOfServiceAccepted(termsOfServiceAccepted: boolean) {
        this.termsOfServiceAccepted = termsOfServiceAccepted;
    }

    @action private setDataForUserType() {
        if (this.type === UserType.None) {
            this.data = undefined;
        } else if (this.type === UserType.Volunteer) {
            this.data = new StubVolunteer();
        } else {
            this.data = new StubOrganization();
        }
    }

    @computed get isVolunteer() {
        return this.type === UserType.Volunteer;
    }

    @computed get isOrganization() {
        return this.type === UserType.Organization;
    }

    @computed get validated() {
        if (this.validationErrors.length > 0 || this.data === undefined) {
            return false;
        } else {
            const validPaymentMethod = this.isVolunteer || !this.stubOrganization?.isForProfit || this.paymentMethod.validated;
            return this.data.validated && this.credentials.validated && validPaymentMethod;
        }
    }

    @computed get validationErrors() {
        return validateStubUser(this);
    }

    @computed get stubOrganization() {
        return this.isOrganization ? this.data as StubOrganization : undefined;
    }

    @computed get stubVolunteer() {
        return this.isVolunteer ? this.data as StubVolunteer : undefined;
    }

    serialize() {
        return {
            ...this.credentials.serialize(),
            volunteer: this.stubVolunteer?.serializedData,
            organization: this.stubOrganization?.serializedData,
            subscription: {
                planLevel: this.stubOrganization?.subscriptionPlanLevel,
                paymentMethodId: this.paymentMethod.id
            },
            termsOfServiceAccepted: this.termsOfServiceAccepted
        }
    }
}