

import { makeStyles, Theme, createStyles, ToolbarProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { Fragment } from "react";
import SelectionIndicator, { SelectionIndicatorProps } from "./SelectionIndicator";
import REMToolbar from "../../../shared/modules/rem-conversion/components/Toolbar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            display: 'flex',
        },
        flexGrow: {
            flexGrow: 1
        },
    }),
);

interface TableToolbarProps extends ToolbarProps {
    leftButtons?: JSX.Element;
    rightButtons?: JSX.Element;
    SelectionIndicatorProps?: SelectionIndicatorProps;
}

const TableToolbar = observer((props: TableToolbarProps) => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <REMToolbar
            className={classes.toolbar}
            disableGutters
            {...props}
        >
            {props.children
                ? props.children
                : <Fragment>
                    {props.leftButtons !== undefined && props.leftButtons}
                    <div className={classes.flexGrow} />
                    {props.rightButtons !== undefined
                        ? props.rightButtons
                        : (props.SelectionIndicatorProps !== undefined && props.SelectionIndicatorProps.numSelected > 0) &&
                            <SelectionIndicator {...props.SelectionIndicatorProps} />
                    }
                </Fragment>
            }
        </REMToolbar>
    );
});

export default TableToolbar;