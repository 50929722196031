import { TextField, makeStyles, Theme, createStyles, InputAdornment } from "@material-ui/core";
import React from "react";
import { observer } from "mobx-react";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PasswordResetData } from "../../stores/models/PasswordResetData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
            maxWidth: '40ch',
            flexGrow: 1,
            [theme.breakpoints.down('xs')]: {
                width: 'auto'
            },
            '& > .MuiFormHelperText-root': {
                whiteSpace: 'pre-line'
            }
        },
        flex: {
            display: 'flex',
            flexDirection: 'column'
        },
        errorIcon: {
            color: theme.palette.accent.main
        },
        matchIcon: {
            color: theme.palette.primary.main
        }
    }),
);

interface PasswordResetFormProps {
    passwordResetData: PasswordResetData;
    validated: boolean;
}

const PasswordResetForm = observer((props: PasswordResetFormProps) => {

    /***** React hooks *****/

    const classes = useStyles();

    /***** Event handlers *****/

    const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.passwordResetData.setCurrentPassword(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.passwordResetData.setNewPassword(event.target.value);
    }

    const handleConfirmedPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.passwordResetData.setConfirmedPassword(event.target.value);
    }

    /***** Helper constants *****/

    const currentPasswordError = props.validated ? (props.passwordResetData.errors.currentPassword || '') : '';
    const passwordError = props.validated ? props.passwordResetData.errors.newPassword : '';
    const confirmedPasswordError = props.validated ? props.passwordResetData.errors.confirmedPassword : '';

    /***** Render *****/

    return (
        <div className={classes.flex}>
            {props.passwordResetData.currentPasswordRequired &&
                <TextField
                    id="current-password-input"
                    label="Current Password"
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    required
                    value={props.passwordResetData.currentPassword || ""}
                    onChange={handleCurrentPasswordChange}
                    className={classes.textField}
                    error={currentPasswordError.length > 0}
                    helperText={currentPasswordError.length > 0 ? currentPasswordError : undefined}
                />
            }
            <TextField
                id="standard-password-input"
                label="New Password"
                type="password"
                autoComplete="new-password"
                variant="outlined"
                required
                value={props.passwordResetData.newPassword}
                onChange={handlePasswordChange}
                className={classes.textField}
                error={passwordError.length > 0}
                helperText={passwordError.length > 0 ? passwordError : undefined}
            />
            <TextField
                id="confim-password-input"
                label="Confirm Password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
                required
                value={props.passwordResetData.confirmedPassword}
                onChange={handleConfirmedPasswordChange}
                className={classes.textField}
                error={confirmedPasswordError.length > 0}
                helperText={confirmedPasswordError.length > 0 ? confirmedPasswordError : undefined}
                InputProps={{
                    endAdornment: props.passwordResetData.confirmedPassword.length > 0
                        ? <InputAdornment position="end">
                            {props.passwordResetData.passwordsMatch
                                ? <CheckCircleIcon className={classes.matchIcon} />
                                : <ErrorIcon className={classes.errorIcon} />
                            }
                        </InputAdornment>
                        : null
                }}
            />
        </div>
    );
});

export default PasswordResetForm;