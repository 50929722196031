import { observer } from 'mobx-react'
import ErrorMessage from './ErrorMessage';
import { getSearchLink } from '../Navigation/Links/UrlConstructors';
import { useNavigateInternally } from '../Navigation/Hooks';

const InvalidPageErrorMessage = observer(() => {

    /********* React hooks *********/

    const navigate = useNavigateInternally();

    /********* Helper methods *********/

    const onButtonClicked = () => {
        navigate(getSearchLink());
    };

    /********* Render *********/

    return (
        <ErrorMessage
            title="Oops! That link is invalid."
            details={`The page you're trying to access doesn't exist.`}
            ButtonProps={{ children: 'Jump to Search Page', onClick: onButtonClicked }}
        />
    );
});

export default InvalidPageErrorMessage;