import { makeStyles, Theme, createStyles, Paper, Container, Typography, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import AddressButton from "../Shared/AddressButton";
import { Address } from "../../stores/models/Address";
import Map from '../Shared/Map';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: '15px',
            paddingBottom: '15px',
            whiteSpace: 'pre-line'
        },
        map: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            height: '200px'
        },
        title: {
            fontWeight: 600
        }
    })
);

interface LocationCardProps {
    title: string;
    addresses: Address[];
    noAddressNode?: JSX.Element;
}

const LocationCard = (props: LocationCardProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* State *********/

    const [mapAddressIndex, setMapAddressIndex] = React.useState(0); // The address to display on the map
    const [mapAddressId, setMapAddressId] = React.useState(props.addresses.length > 0 ? props.addresses[0].id : -1);

    /********* Effects *********/

    useEffect(() => {
        reconcileMapAddress();
    }, [props.addresses]);

    /********* Event handlers *********/

    const handleMapMarkerChanged = (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMapAddressIndex(index);
        setMapAddressId(props.addresses[index].id);
        event.preventDefault();
    }

    /********* Helper methods and constants *********/

    // Make sure the address used for the map stays the
    // same if possible after updates to the addresses.
    const reconcileMapAddress = () => {
        const indexOfPreviousMapAddress = props.addresses.findIndex(address => address.id === mapAddressId);
        if (indexOfPreviousMapAddress !== -1) {
            setMapAddressIndex(indexOfPreviousMapAddress);
        } else {
            setMapAddressIndex(0);
        }
    }

    const noAddressGiven = (props.addresses.length === 0);

    return (
        <Paper className={classes.paper}>
            <Container>
                <div>
                    <Typography variant="h6" className={classes.title}>{props.title}</Typography>
                    {noAddressGiven ? null : <Map address={props.addresses[mapAddressIndex]} className={classes.map} />}
                    {noAddressGiven
                        ? props.noAddressNode
                            ? props.noAddressNode
                            : 'Not Provided'
                        : <Grid container>
                            {props.addresses.map((address, index) => {
                                return (
                                    <Grid item sm={4} xs={12} md={12} key={`address-list-item-${index}`}>
                                        <AddressButton
                                            key={`address-button-${index}`}
                                            address={address}
                                            selected={mapAddressIndex === index}
                                            onClick={(event) => handleMapMarkerChanged(index, event)}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    }
                </div>
            </Container>
        </Paper>
    );
};

export default LocationCard;