import { makeStyles, Theme, createStyles, Container, Grid, Paper, Typography, TableCell, useMediaQuery } from "@material-ui/core";
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootContext } from "../../../../../stores";
import { observer } from "mobx-react";
import { getVolunteerDetailsLink } from "../../../../Navigation/Links/UrlConstructors";
import Editor from "../../../Editor";
import { ISortableTableHeader, TableHeader } from "../../../../../stores/models/TableHeader";
import FormLoadingSpinner from "../../../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import { IVolunteerOverview } from "../../../../../stores/models/VolunteerOverview";
import { IdentifiableObject, OptionCollection } from "../../../../../stores/models/OptionCollection";
import { Option } from "../../../../../stores/models/Option";
import AddVolunteerForm from "./AddVolunteerForm";
import RecordEditorDialog from "../RecordEditorDialog";
import { IStubOrganizationVolunteer, StubOrganizationVolunteer } from "../../../../../stores/models/StubOrganizationVolunteer";
import { VolunteerDetails } from "../../../../../stores/models/VolunteerDetails";
import MergeRecordsForm from "./MergeRecordsForm";
import SuccessMessage from "../../../../Shared/SuccessMessage";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog";
import { getInitialDialogStates } from "../../../../../logic/DialogStateDictionary";
import Papa from "papaparse";
import ErrorDialog from "../../../../Shared/Dialogs/ErrorDialog";
import ThemedDialogWithSpinner from "../../../../Shared/Dialogs/ThemedDialogWithSpinner";
import ImportVolunteersForm from "./ImportVolunteersForm";
import VolunteerEmailsDialog from "./VolunteerEmailsDialog";
import RecordTable from "../../../../Shared/Table/NewRecordTable";
import { TableController } from "../../../../../stores/models/TableController";
import VolunteersOverviewMainMenu from "./VolunteersOverviewMainMenu";
import VolunteersOverviewSelectionMenu from "./VolunteersOverviewSelectionMenu";
import FilterChips from "../../../../Shared/FilterChips";
import { VolunteerFilterOptionCollections } from "./VolunteersFilterPopper";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import VolunteerImportDirectionsDialog from "./VolunteerImportDirectionsDialog";
import REMChip from "../../../../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line'
        },
        noWrap: {
            display: 'flex',
            flexWrap: 'nowrap',
            margin: '-6px 0px'
        },
        chip: {
            backgroundColor: theme.palette.primaryBackground.main,
            '& .MuiChip-icon, .MuiChip-label': {
                color: theme.palette.darkBackground.main
            },
            '&:not(:last-child)': {
                marginRight: theme.spacing(.5)
            }
        },
        spacer: {
            height: 200
        },
        hidden: {
            display: 'none'
        },
        selectionToolbar: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(-1)
            },
            marginTop: theme.spacing(-1.5)
        },
        filterToolbar: {
            marginTop: theme.spacing(0),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1)
            }
        }
    }),
);

const MAX_ROLE_CHIPS_TO_DISPLAY = 2;

const headCells = ([
    { id: 'checkbox', alignment: 'center', disablePadding: true, sortable: false, checkbox: true },
    { id: 'firstName', alignment: 'left', disablePadding: false, label: 'First Name', sortable: true, sortValue: 'firstName' },
    { id: 'lastName', alignment: 'left', disablePadding: false, label: 'Last Name', sortable: true, sortValue: 'lastName' },
    { id: 'status', alignment: 'left', disablePadding: false, label: 'Status', sortable: true, sortValue: 'status' },
    { id: 'roles', alignment: 'left', disablePadding: false, label: 'Roles', sortable: false },
] as ISortableTableHeader<IVolunteerOverview>[]);

const dataCell = (key: keyof IVolunteerOverview) => {
    return (option: Option<IVolunteerOverview>, rowIndex: number, cellIndex: number) => {
        return (
            <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>{option.object[key]}</TableCell>
        );
    }
};

enum DialogType {
    AddNewVolunteer = "add",
    MergeRecord = "merge",
    DeletionConfirmation = 'delete',
    ImportErrors = 'importErrors',
    ImportVolunteers = 'importVolunteers',
    ImportDirections = 'importDirections',
    VolunteerEmails = 'volunteerEmails'
}

interface VolunteersOverviewProps extends VolunteerFilterOptionCollections {
    tableController: TableController<'id', IVolunteerOverview, StubOrganizationVolunteer, VolunteerDetails>;
}

const VolunteersOverview = observer((props: VolunteersOverviewProps) => {

    const classes = useStyles();
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const rootStore = useContext(RootContext);
    const volunteerStore = rootStore.volunteerStore;
    const volunteerCollection = props.tableController.records;
    const organizationVolunteerStatuses = useMemo(
        () => rootStore.userStore.user.organization?.volunteerStatuses || [],
        [rootStore.userStore.user.organization?.volunteerStatuses]
    );

    /*** State ***/

    const [dialogStates] = useState(getInitialDialogStates(Object.values(DialogType)));
    const [newStubVolunteer] = useState(new StubOrganizationVolunteer());
    const [newMergedRecord] = useState(new VolunteerDetails());
    const [newVolunteerError, setNewVolunteerError] = useState(false);
    const [volunteerOne, setVolunteerOne] = useState<VolunteerDetails>();
    const [volunteerTwo, setVolunteerTwo] = useState<VolunteerDetails>();
    const [mergeError, setMergeError] = useState(false);
    const [mergeSuccessful, setMergeSuccessful] = useState(false);
    const [importErrors, setImportErrors] = useState<string[]>([]);
    const [importedVolunteers, setImportedVolunteers] = useState<StubOrganizationVolunteer[]>([]);
    const [emails, setEmails] = useState<string[]>([]);

    const fileInput = useRef<HTMLInputElement>(null);

    const volunteerTableHeaderCells = useMemo(() => headCells.map(headCell => new TableHeader(headCell)), []);
    const importedVolunteerCollection = useMemo(() => new OptionCollection('id', importedVolunteers), [importedVolunteers]);

    useEffect(() => {
        if (dialogStates[DialogType.AddNewVolunteer].open) {
            setNewVolunteerError(false);
        }
    }, [dialogStates[DialogType.AddNewVolunteer].open]);

    useEffect(() => {
        if (!dialogStates[DialogType.MergeRecord].open) {
            setVolunteerOne(undefined);
            setVolunteerTwo(undefined);
            setMergeError(false);
            setMergeSuccessful(false);
        }
    }, [dialogStates[DialogType.MergeRecord].open]);

    useEffect(() => {
        if (importErrors.length > 0) {
            dialogStates.importErrors.setOpen(true);
        }
    }, [importErrors.length]);

    useEffect(() => {
        if (importedVolunteers.length > 0) {
            dialogStates.importVolunteers.setOpen(true);
            dialogStates.importDirections.setOpen(false);
        }
    }, [importedVolunteers.length]);

    useEffect(() => {
        if (!dialogStates[DialogType.ImportVolunteers].open) {
            setImportedVolunteers([]);
        }
    }, [dialogStates[DialogType.ImportVolunteers].open]);

    /********* Helper methods *********/

    const loadVolunteersToMerge = async () => {
        const volunteerIdsToLoad = volunteerCollection.selectedOptions.map(volunteer => volunteer.id);
        if (volunteerIdsToLoad.length === 2) {
            dialogStates[DialogType.MergeRecord].setLoading(true);
            const response = await volunteerStore.getOrganizationVolunteerRecordsToMerge(volunteerIdsToLoad);
            if (response) {
                setVolunteerOne(response.volunteerOne);
                setVolunteerTwo(response.volunteerTwo);
            }
            dialogStates[DialogType.MergeRecord].setLoading(false);
        }
    }

    /********* Event handler *********/

    const onSearchInputChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target) {
            const searchQuery = event?.target.value;
            props.tableController.tableState.filters.setSearchQuery(searchQuery);
        }
    }

    const onClearSelections = () => {
        props.tableController.tableState.setUnloadedSelected(false);
        props.tableController.records.setSelections([]);
    }

    const onVolunteerRowClicked = (volunteerId: number) => {
        navigate(getVolunteerDetailsLink(volunteerId));
    }

    const onConfirmNewVolunteer = async (stubOrganizationVolunteer: StubOrganizationVolunteer) => {
        stubOrganizationVolunteer.setAllFieldsDirty();
        if (stubOrganizationVolunteer.validated) {
            const addNewVolunteerDialog = dialogStates[DialogType.AddNewVolunteer];
            setNewVolunteerError(false);
            addNewVolunteerDialog.setLoading(true);
            const response = await props.tableController.add(stubOrganizationVolunteer);
            if (response) {
                addNewVolunteerDialog.setOpen(false);
                addNewVolunteerDialog.setLoading(false);
            }
        } else {
            setNewVolunteerError(true);
        }
    }

    const onEditClicked = () => {
        if (volunteerCollection.selections.length === 1) {
            const volunteerId = volunteerCollection.selectedOptions[0].id;
            onVolunteerRowClicked(volunteerId);
        }
    }

    const onMergeRecordsClicked = () => {
        loadVolunteersToMerge();
        dialogStates[DialogType.MergeRecord].setOpen(true);
    }

    const onMergeConfirmed = async (mergedRecord: VolunteerDetails) => {
        if (mergedRecord.validated && volunteerOne && volunteerTwo) {
            setMergeError(false);
            dialogStates[DialogType.MergeRecord].setLoading(true);
            const response = await props.tableController.mergeSelectedRecords(mergedRecord);
            if (response) {
                setMergeSuccessful(true);
            }
            dialogStates[DialogType.MergeRecord].setLoading(false);
        } else {
            setMergeError(true);
        }
    }

    const onDeleteClicked = () => {
        dialogStates.delete.setOpen(true);
    }

    const onDeletionConfirmed = async () => {
        dialogStates.delete.setLoading(true);
        const response = await props.tableController.deleteSelectedRecords();
        dialogStates.delete.setLoading(false);
        if (response?.success) {
            dialogStates.delete.setOpen(false);
        }
    }

    const onImportClicked = () => {
        dialogStates.importDirections.setOpen(true);
    }

    const onUploadClicked = () => {
        fileInput.current?.click();
    }

    const onInputClicked: React.MouseEventHandler<HTMLInputElement> = (event) => {
        // Reset the input file path so that the file selector can successfully select the same path again if needed.
        const element = event.target as HTMLInputElement;
        element.value = '';
    }

    const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        let newFile = null;
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            newFile = event.target.files[0];
        }
        readFile(newFile);
    };

    const readFile = (fileToRead: File | null) => {
        if (!fileToRead) return;

        Papa.parse(fileToRead, {
            header: true,
            skipEmptyLines: true,
            transformHeader: (header: string) => {
                const trimmedHeader = header.trim();
                switch (trimmedHeader) {
                    case 'First Name*':
                    case 'First Name':
                        return 'firstName';
                    case 'Last Name*':
                    case 'Last Name':
                        return 'lastName';
                    case 'Email':
                        return 'email';
                    case 'Birthdate':
                        return 'birthdate';
                    case 'Status*':
                    case 'Status':
                        return 'statusId';
                    default:
                        return trimmedHeader;
                }
            },
            transform: (value, field) => {
                switch (field) {
                    case 'firstName':
                        return value || '';
                    case 'lastName':
                        return value || '';
                    case 'email':
                        return value || '';
                    case 'birthdate':
                        return value ? new Date(value).toUTCString() : '';
                    case 'statusId':
                        return organizationVolunteerStatuses.find(status => status.status === value)?.id;
                    default:
                        return '';
                }
            },
            complete: function (results) {
                if (results.errors && results.errors.length > 0) {
                    setImportErrors(results.errors.map(error => error.message));
                    return;
                }
                try {
                    if (!verifyFields(results.meta.fields)) {
                        setImportErrors(['At least one column is missing from the import file. Please verify you have a column for First Name, Last Name, Email, Birthdate, and Status.']);
                        return;
                    }

                    if (results.data) {
                        let stubVolunteers: StubOrganizationVolunteer[] = [];
                        for (let i = 0; i < results.data.length; i++) {
                            const importedVolunteer = results.data[i];
                            let hasData = false;
                            if (importedVolunteer && typeof importedVolunteer === 'object') {
                                const fieldValues = Object.values(importedVolunteer);
                                for (let j = 0; j < fieldValues.length; j++) {
                                    const fieldValue = fieldValues[j];
                                    if (fieldValue !== undefined && fieldValue !== null) {
                                        if ((typeof fieldValue === 'string' && fieldValue.trim().length > 0) ||
                                            (typeof fieldValue === 'number' && !isNaN(fieldValue))
                                        ) {
                                            hasData = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            if (hasData) {
                                stubVolunteers.push(new StubOrganizationVolunteer(importedVolunteer as IStubOrganizationVolunteer));
                            }
                        }

                        setImportedVolunteers(stubVolunteers);
                    }
                } catch (err) {
                    setImportErrors([`Invalid import: ${err}`]);
                }
            }
        });
    }

    const verifyFields = (fields?: string[]) => {
        if (!fields) return false;
        const expectedFields = ['firstName', 'lastName', 'email', 'birthdate', 'statusId'];
        for (let i = 0; i < expectedFields.length; i++) {
            if (fields.indexOf(expectedFields[i]) === -1) {
                return false;
            }
        }
        return true;
    }

    const onImportErrorsAcknowledged = () => {
        setImportErrors([]);
    }

    const onConfirmImportVolunteers = async () => {
        if (!validateImportedVolunteers()) {
            setImportErrors(['At least one row contains invalid input.']);
            return;
        }
        dialogStates.importVolunteers.setLoading(true);
        const newVolunteers = await volunteerStore.importStubOrganizationVolunteers(importedVolunteerCollection.optionObjects);
        if (newVolunteers) {
            dialogStates.importVolunteers.setOpen(false);
            dialogStates.importVolunteers.setLoading(false);
            props.tableController.load(true);
        }
    }

    const validateImportedVolunteers = () => {
        importedVolunteerCollection.optionObjects.forEach(option => option.setAllFieldsDirty());
        for (let i = 0; i < importedVolunteerCollection.optionObjects.length; i++) {
            const volunteerToImport = importedVolunteerCollection.optionObjects[i];
            if (!volunteerToImport.validated) {
                return false;
            }
        }
        return true;
    }

    /********* Pull Volunteer Emails *********/

    const getVolunteerEmails = async () => {
        const emails = await volunteerStore.getVolunteerEmailsForOrganization(props.tableController.selectionCriteria);
        return emails;
    }

    const displayVolunteerEmails = (emails: string[]) => {
        setEmails(emails);
        dialogStates.volunteerEmails.setOpen(true);
    }

    const onEmailsClicked = async () => {
        const response = await getVolunteerEmails();
        if (!response) return;
        displayVolunteerEmails(response.emails);
    }

    /********* Render *********/

    return (
        <Editor editsMade={false}>
            {(editorState) => (
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Container>
                                    <Typography variant="h4">
                                        Volunteers
                                    </Typography>
                                    {/* Toolbar Buttons */}
                                    <VolunteersOverviewMainMenu
                                        searchQuery={props.tableController.tableState.filters.searchQuery}
                                        roleOptions={props.roleOptions}
                                        statusOptions={props.statusOptions}
                                        onEmailsClicked={onEmailsClicked}
                                        onImportClicked={onImportClicked}
                                        onNewVolunteerClicked={() => dialogStates[DialogType.AddNewVolunteer].setOpen(true)}
                                        onSearchInputChanged={onSearchInputChanged}
                                    />
                                    <FilterChips
                                        filters={{
                                            'Status': {
                                                selections: props.statusOptions.selections as Option<IdentifiableObject<'id'>>[],
                                                getLabel: (optionObject) => (optionObject as unknown as { status: string }).status
                                            },
                                            'Role': {
                                                selections: props.roleOptions.selections,
                                                getLabel: (optionObject) => (optionObject as unknown as { position: string }).position
                                            }
                                        }}
                                        className={classes.filterToolbar}
                                    />
                                    {props.tableController.numSelectedIncludingUnloaded > 0 &&
                                        <VolunteersOverviewSelectionMenu
                                            numSelections={props.tableController.numSelectedIncludingUnloaded}
                                            onDeleteClicked={onDeleteClicked}
                                            onEditClicked={onEditClicked}
                                            onMergeRecordsClicked={onMergeRecordsClicked}
                                            onClearSelections={onClearSelections}
                                            className={classes.selectionToolbar}
                                        />
                                    }
                                    <input ref={fileInput} type="file" accept=".csv" onChange={onFileChange} onClick={onInputClicked} className={classes.hidden} />
                                    <RecordTable
                                        controller={props.tableController}
                                        tableHeaderCells={volunteerTableHeaderCells}
                                        displayFunctions={[
                                            (option: Option<IVolunteerOverview>, rowIndex: number, cellIndex: number) => {
                                                return null;
                                            },
                                            dataCell('firstName'),
                                            dataCell('lastName'),
                                            dataCell('status'),
                                            (option: Option<IVolunteerOverview>, rowIndex: number, cellIndex: number) => {
                                                return (
                                                    <TableCell align="left" key={`${rowIndex}-${cellIndex}`}>
                                                        <div className={classes.noWrap}>
                                                            {option.object.roles.map((role, index) => {
                                                                if (index < MAX_ROLE_CHIPS_TO_DISPLAY) {
                                                                    return (
                                                                        <REMChip
                                                                            label={role.position}
                                                                            className={classes.chip}
                                                                            key={`role-chip-${index}`}
                                                                        />
                                                                    )
                                                                } else if (index === MAX_ROLE_CHIPS_TO_DISPLAY) {
                                                                    return (
                                                                        <REMChip
                                                                            label={`+${option.object.roles.length - MAX_ROLE_CHIPS_TO_DISPLAY}`}
                                                                            className={classes.chip}
                                                                            key={`role-chip-${index}`}
                                                                        />
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    </TableCell>
                                                );
                                            }
                                        ]}
                                        onRowClicked={(option: Option<IVolunteerOverview>, rowIndex: number) => {
                                            onVolunteerRowClicked(option.object.id)
                                        }}
                                    />
                                </Container>
                            </Paper>
                        </Grid>
                    </Grid>
                    {props.tableController.tableState.loading ? <FormLoadingSpinner /> : null}
                    {/* New Volunteer Dialog */}
                    <RecordEditorDialog
                        record={newStubVolunteer}
                        copyRecord={record => new StubOrganizationVolunteer(record)}
                        state={dialogStates[DialogType.AddNewVolunteer]}
                        title={'Add Volunteer'}
                        onConfirm={onConfirmNewVolunteer}
                        confirmText={'Add'}
                        DialogProps={{ maxWidth: 'xs' }}
                    >
                        {(record: StubOrganizationVolunteer) =>
                            <AddVolunteerForm volunteerUser={record} error={newVolunteerError} />
                        }
                    </RecordEditorDialog>
                    {/* Merge Records Dialog */}
                    <RecordEditorDialog
                        record={newMergedRecord}
                        copyRecord={record => new VolunteerDetails(record)}
                        state={dialogStates[DialogType.MergeRecord]}
                        title={'Merge Records'}
                        onConfirm={onMergeConfirmed}
                        cancelText={mergeSuccessful ? 'Okay' : undefined}
                        confirmText={!mergeSuccessful ? 'Merge' : undefined}
                        DialogProps={{ maxWidth: mergeSuccessful ? 'sm' : 'lg' }}
                    >
                        {(record: VolunteerDetails) => {
                            return mergeSuccessful
                                ? <SuccessMessage
                                    details={(
                                        <React.Fragment>
                                            Volunteers successfully merged!
                                        </React.Fragment>
                                    )}
                                />
                                : (volunteerOne && volunteerTwo)
                                    ? <MergeRecordsForm
                                        mergedRecord={record}
                                        volunteerOne={volunteerOne}
                                        volunteerTwo={volunteerTwo}
                                        error={mergeError}
                                    />
                                    : <div className={classes.spacer} />
                        }}
                    </RecordEditorDialog>
                    {/* Delete Volunteer Confirmation */}
                    <ConfirmationDialog
                        state={dialogStates.delete}
                        title={"Delete"}
                        content={<Typography>Are you sure you want to delete {volunteerCollection.selections.length > 1 ? 'these volunteers' : 'this volunteer'}?</Typography>}
                        confirmText={'Delete'}
                        onConfirm={onDeletionConfirmed}
                        fullScreen={false}
                    />
                    <ErrorDialog
                        state={dialogStates.importErrors}
                        dialogTitle={'Import Failed'}
                        onAcknowledge={onImportErrorsAcknowledged}
                        errorDetails={
                            <Fragment>
                                {importErrors.map((importError, index) => {
                                    return (
                                        <Typography key={`import-error-${index}`}>
                                            {importError}
                                        </Typography>
                                    )
                                })}
                            </Fragment>
                        }
                    />
                    <ThemedDialogWithSpinner
                        state={dialogStates.importVolunteers}
                        title={'Import Volunteers'}
                        DialogProps={{ maxWidth: 'lg', fullScreen: xsDown }}
                        onSubmit={onConfirmImportVolunteers}
                        primaryButtonProps={{ children: 'Import' }}
                    >
                        <ImportVolunteersForm
                            volunteerCollection={importedVolunteerCollection}
                        />
                    </ThemedDialogWithSpinner>
                    <VolunteerEmailsDialog
                        state={dialogStates.volunteerEmails}
                        emails={emails}
                        numSelected={props.tableController.records.selectedOptions.length > 0
                            ? props.tableController.numSelectedIncludingUnloaded
                            : props.tableController.tableState.total || 0}
                    />
                    <VolunteerImportDirectionsDialog
                        state={dialogStates.importDirections}
                        onSubmit={onUploadClicked}
                    />
                </Container>
            )}
        </Editor>
    );
});

export default VolunteersOverview;