import { useEffect, useState } from 'react';
import ExternalLink from '../../../../components/Navigation/Components/ExternalLink';
import { useLinkStyles } from '../../../../components/Navigation/Components/LinkStyles';
import clsx from "clsx";

interface FormattedContentProps {
    content: string;
}

export default function useFormattedContent(props: FormattedContentProps) {

    const { link, inline } = useLinkStyles();

    const [formattedContent, setFormattedContent] = useState<(string | JSX.Element)[]>([props.content]);

    const addHypertextLinks = (content: string) => {
        const bareLinkRegex = /((?:[^\.\s]+(?:\.[^?\.,\'\s]+)+)(?:\?){0,1}(?:[^\'\<\>\!\?\,\.\s])*)/g
        const alphabeticalCharRegex = /[a-zA-Z]+/
        const invalidCharactersRegex = /[\,\<\>]/
        const protocolRegex = /^((http\:\/\/)|(https\:\/\/)){1}/
        const dividedContent = content.split(bareLinkRegex);

        const result = dividedContent.map(substring => {
            if (substring.match(bareLinkRegex) && substring.match(alphabeticalCharRegex) && !substring.match(invalidCharactersRegex)) {
                let toLink = substring.match(protocolRegex) ? substring : 'https://' + substring;
                return (
                    <ExternalLink
                        target='_blank'
                        className={clsx(link, inline)}
                        to={toLink}
                    >
                        {substring}
                    </ExternalLink>
                )
            } else {
                return substring;
            }
        })
        return result;
    }

    useEffect(() => {
        setFormattedContent(addHypertextLinks(props.content))
    }, [props.content])

    return formattedContent;
}