import { observable, computed, makeObservable } from "mobx";
import { format } from "date-fns";

interface IInvoice {
    credit: number;
    dueImmediately: number;
    nextPayment: number;
    nextPaymentDate: number;
    prorationDate: number;
}

export class Invoice implements IInvoice {
    credit: number;
    dueImmediately: number;
    nextPayment: number;
    nextPaymentDate: number;
    prorationDate: number;

    constructor(invoice: IInvoice) {
        makeObservable(this, {
            credit: observable,
            dueImmediately: observable,
            nextPayment: observable,
            nextPaymentDate: observable,
            prorationDate: observable,
            dueToday: computed,
            dueImmediatelyText: computed,
            creditText: computed,
            dueTodayText: computed,
            nextPaymentText: computed,
            formattedNextPaymentDate: computed
        });

        this.credit = invoice.credit;
        this.dueImmediately = invoice.dueImmediately;
        this.nextPayment = invoice.nextPayment;
        this.nextPaymentDate = invoice.nextPaymentDate;
        this.prorationDate = invoice.prorationDate;
    }

    get dueToday() {
        return this.dueImmediately + this.credit;
    }

    get dueImmediatelyText() {
        return this.formatPrice(this.dueImmediately);
    }

    get creditText() {
        return this.formatPrice(this.credit * -1);
    }

    get dueTodayText() {
        return this.formatPrice(this.dueToday);
    }

    get nextPaymentText() {
        return this.formatPrice(this.nextPayment);
    }

    get formattedNextPaymentDate() {
        return format(new Date(this.nextPaymentDate * 1000), "M/dd/yy");
    }

    private formatPrice(price: number) {
        return `$${(price / 100).toFixed(2)}`;
    }
}