import { useContext } from "react";
import { observer } from "mobx-react";
import { StepProps } from "../../../../stores/models/Step";
import { RootContext } from "../../../../stores";
import InvalidUserType from "../InvalidUserType";
import { Fields } from "../../../../stores/models/Fields";

const InvalidUserTypeStep = observer(<T extends Fields<Partial<T>, T>>(props: StepProps<T>) => {

    const rootStore = useContext(RootContext);

    /***** Event handlers *****/

    const onLogoutClicked = async () => {
        props.stepState.setLoading(true);
        await rootStore.userStore.logout();
        props.stepState.setLoading(false);
    }

    /***** Render *****/

    return (
        <InvalidUserType onLogoutClicked={onLogoutClicked} />
    );
});

export default InvalidUserTypeStep;