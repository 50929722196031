import React, { useMemo } from "react";
import { makeStyles, Theme, createStyles, Typography, Avatar, SvgIconProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { CheckBold } from "mdi-material-ui";
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thankYou: {
            textAlign: 'center',
            color: theme.palette.action.active,
            padding: theme.spacing(2)
        },
        lineTwo: {
            marginTop: theme.spacing(2),
        },
        avatar: {
            background: 'none',
            margin: 'auto',
            height: 60,
            width: 60,
            marginBottom: theme.spacing(2),
            border: '3px solid',
            borderColor: theme.palette.success.main,
        },
        filled: {
            background: theme.palette.success.main
        },
        icon: {
            color: theme.palette.success.main,
            fontSize: '30px'
        },
        lightIcon: {
            color: theme.palette.common.white,
            fontSize: '35px'
        }
    }));

type Icon = (props: SvgIconProps) => JSX.Element;

interface SuccessMessageProps {
    title?: string;
    icon?: Icon;
    filled?: boolean;
    details: React.ReactNode;
}

const SuccessMessage = observer((props: SuccessMessageProps) => {

    /********* React hooks *********/

    const classes = useStyles();
    const avatarClasses = useMemo(() => clsx(classes.avatar, props.filled ? classes.filled : undefined), [props.filled]);
    const iconClasses = useMemo(() => props.filled ? classes.lightIcon : classes.icon, [props.filled]);

    /********* Render *********/

    return (
        <div id='success-message' className={classes.thankYou}>
            <Avatar className={avatarClasses}>
                {props.icon
                    ? React.createElement(props.icon, { className: iconClasses })
                    : <CheckBold className={iconClasses} fontSize={'large'} />
                }
            </Avatar>
            {props.title && <Typography variant="h4">{props.title}</Typography>}
            <Typography variant="h5" className={classes.lineTwo}>
                {props.details}
            </Typography>
        </div>
    );
});

export default SuccessMessage;