import { TextField, makeStyles, Theme, createStyles } from "@material-ui/core";
import { ChangeEvent } from "react";
import { observer } from "mobx-react";
import TermsOfServiceAcceptance from "../Shared/TermsOfServiceAcceptance";
import { StubUser } from "../../../../stores/models/StubUser";
import { VOLUNTEER_NAME_MAX_LENGTH } from "../../../../logic/ValidationChecks/FieldLengths";
import REMKeyboardDatePicker from "../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

// TODO: Refactor volunteer sign up to use this component.

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
            minWidth: '40ch',
            [theme.breakpoints.down('sm')]: {
                flexGrow: 1,
                minWidth: 'auto'
            },
        },
        checkboxWrapper: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            color: theme.palette.text.secondary,
            textAlign: 'center'
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

interface DemographicsFieldsProps {
    stubUser: StubUser;
    validationRun?: boolean;
    autoFocusFirstField?: boolean;
}

const DemographicsFields = observer((props: DemographicsFieldsProps) => {

    const stubUser = props.stubUser;
    const stubVolunteer = stubUser.stubVolunteer!;

    /***** React hooks *****/

    const classes = useStyles();

    /***** Event handlers *****/

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        stubVolunteer.setFirstName(event.target.value);
    }

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        stubVolunteer.setLastName(event.target.value);
    }

    const handleBirthdateChange = (date: Date | null) => {
        stubVolunteer.setBirthdate(date);
    }

    /***** Helper constants *****/

    const validationRun = props.validationRun;
    const firstNameError = validationRun ? stubVolunteer.errors.firstName : '';
    const lastNameError = validationRun ? stubVolunteer.errors.lastName : '';
    const dateOfBirthError = validationRun ? stubVolunteer.errors.birthdate : '';
    const termsOfServiceErrorExists = validationRun && stubUser.isFieldInvalid('termsOfServiceAccepted');

    /***** Render *****/

    return (
        <div className={classes.flex}>
            <TextField
                id="standard-first-name-input"
                label="First Name"
                variant="outlined"
                required
                value={stubVolunteer.firstName}
                onChange={handleFirstNameChange}
                autoFocus={props.autoFocusFirstField}
                className={classes.textField}
                error={firstNameError.length > 0}
                helperText={firstNameError.length > 0 ? firstNameError : undefined}
                inputProps={{ maxLength: VOLUNTEER_NAME_MAX_LENGTH }}
            />
            <TextField
                id="standard-last-name-input"
                label="Last Name"
                variant="outlined"
                required
                value={stubVolunteer.lastName}
                onChange={handleLastNameChange}
                className={classes.textField}
                error={lastNameError.length > 0}
                helperText={lastNameError.length > 0 ? lastNameError : undefined}
                inputProps={{ maxLength: VOLUNTEER_NAME_MAX_LENGTH }}
            />
            <REMKeyboardDatePicker
                margin="normal"
                id="birthdate-picker"
                label="Date of Birth"
                format="MM/dd/yyyy"
                minDate={new Date(1900, 1, 1)}
                value={stubVolunteer.birthdate}
                onChange={handleBirthdateChange}
                inputVariant="outlined"
                required
                className={classes.textField}
                disableFuture
                error={dateOfBirthError.length > 0}
                helperText={dateOfBirthError.length > 0 ? dateOfBirthError : undefined}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
            <div className={classes.checkboxWrapper}>
                <TermsOfServiceAcceptance
                    checked={stubUser.termsOfServiceAccepted}
                    onChange={(accepted) => stubUser.setTermsOfServiceAccepted(accepted)}
                    error={termsOfServiceErrorExists ? stubUser.getErrorForField('termsOfServiceAccepted') : undefined}
                />
            </div>
        </div >
    );
});

export default DemographicsFields;