import { makeStyles, Theme, createStyles, Container, Grid, Paper, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import Editor from "../../../components/AccountSettings/Editor";
import ServiceDataPresenter from "./ServiceDataPresenter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line'
        }
    }),
);

const VolunteerServiceHistoryPage = observer(() => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <Editor editsMade={false}>
            {(editorState) => (
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Container>
                                    <Typography variant="h4">
                                        Volunteer Hours
                                    </Typography>
                                    <ServiceDataPresenter />
                                </Container>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </Editor>
    );
});

export default VolunteerServiceHistoryPage;