import { makeStyles, Theme, createStyles, ListItem, ListItemIcon, ListItemText, Collapse, useTheme, Tooltip, Typography, Badge } from "@material-ui/core";
import React from "react";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuSidebarList from "./MenuSidebarList";
import { MenuItem } from "../../../stores/models/MenuItem";
import { observer } from "mobx-react";
import clsx from "clsx";
import { useNavigateInternally } from "../../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selected: {
            background: theme.palette.darkBackground.light,
            color: theme.palette.darkBackground.contrastText,
            '&:hover': {
                background: theme.palette.darkBackground.light
            }
        },
        unselected: {
            color: theme.palette.darkBackground.contrastText,
            '&:hover': {
                background: theme.palette.darkBackground.light
            }
        },
        sectionHeader: {
            fontWeight: 500
        },
        text: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        tooltip: {
            background: theme.palette.darkBackground.main,
        },
        tooltipArrow: {
            color: theme.palette.darkBackground.main
        },
        listItem: {
            justifyContent: 'center'
        },
        listItemIcon: {
            minWidth: 'unset'
        }
    }),
);

interface MenuSidebarListItemProps {
    menuItem: MenuItem;
    nestLevel?: number;
    iconOnly?: boolean;
}

const MenuSidebarListItem = observer((props: MenuSidebarListItemProps) => {

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigateInternally();

    const [open, setOpen] = React.useState(true);

    const toggleSubmenu = () => {
        setOpen(!open);
    }

    const handleMenuItemClicked = () => {
        if (props.menuItem.onClick) {
            props.menuItem.onClick();
        }
        if (props.menuItem.fullLink) {
            navigate(props.menuItem.fullLink);
        }
    }

    const hasSubmenu = props.menuItem.subItems && props.menuItem.subItems.length > 0;
    const nestedPadding = props.nestLevel ? theme.spacing(3) * props.nestLevel : 0;
    
    const selected = props.menuItem.active;

    return (
        <div>
            {/* Icon Only */}
            {props.iconOnly
                ? hasSubmenu
                    ? <MenuSidebarList
                        menuItems={props.menuItem.subItems!}
                        iconsOnly={true}
                    />
                    : <Tooltip
                        title={<Typography variant="subtitle2">{props.menuItem.text}</Typography>}
                        placement="right"
                        arrow
                        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                    >
                        <ListItem
                            className={clsx(selected ? classes.selected : classes.unselected, classes.listItem)}
                            button
                            onClick={handleMenuItemClicked}
                        >
                            {props.menuItem.icon &&
                                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                    <Badge badgeContent={props.menuItem.badge} color='error'>
                                        {React.createElement(props.menuItem.icon, {
                                            className: selected ? classes.selected : classes.unselected
                                        })}
                                    </Badge>
                                </ListItemIcon>
                            }
                        </ListItem>
                    </Tooltip>
                : <div>
                    {/* Expanded Menu Item */}
                    <ListItem
                        className={selected ? classes.selected : classes.unselected}
                        style={nestedPadding > 0 ? { paddingLeft: nestedPadding } : undefined}
                        role={undefined}
                        button
                        onClick={hasSubmenu ? toggleSubmenu : handleMenuItemClicked}
                    >
                        {(!hasSubmenu && props.menuItem.icon) &&
                            <ListItemIcon>
                                <Badge badgeContent={props.menuItem.badge} color='error'>
                                    {React.createElement(props.menuItem.icon, {
                                        className: selected ? classes.selected : classes.unselected
                                    })}
                                </Badge>
                            </ListItemIcon>
                        }
                        <ListItemText
                            primary={props.menuItem.text}
                            className={clsx(classes.text, hasSubmenu ? classes.sectionHeader : undefined)}
                            disableTypography
                        />
                        {hasSubmenu
                            ? open ? <ExpandLess /> : <ExpandMore />
                            : null
                        }
                    </ListItem>
                    {/* Collapsible Submenu */}
                    {hasSubmenu &&
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <MenuSidebarList
                                menuItems={props.menuItem.subItems!}
                                nestLevel={props.nestLevel !== undefined ? props.nestLevel + 1 : 1}
                            />
                        </Collapse>
                    }
                </div>
            }
        </div>
    );
});

export default MenuSidebarListItem;