import { observer } from "mobx-react";
import { Tooltip, makeStyles, IconButton, Button, ButtonProps, Typography } from "@material-ui/core";
import { Pencil } from "mdi-material-ui";
import { useNavigateInternally } from "../Navigation/Hooks";

const useStyles = makeStyles(theme => ({
    editButton: {
        color: theme.palette.action.active,
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
}));

interface EditButtonProps {
    link?: string;
    onClick?: () => void;
    showText?: boolean;
    ButtonProps?: ButtonProps;
}

const EditButton = observer((props: EditButtonProps) => {

    const classes = useStyles();
    const navigate = useNavigateInternally();

    const onEditClicked = () => {
        if (props.link) {
            navigate(props.link);
        }
    }

    return (
        <Tooltip open={props.showText ? false : undefined} title={<Typography>Edit</Typography>} arrow>
            {props.showText
                ? <Button
                    className={classes.editButton}
                    startIcon={<Pencil />}
                    onClick={props.onClick ? props.onClick : onEditClicked}
                    {...props.ButtonProps}
                >
                    Edit
                </Button>
                : <IconButton className={classes.editButton} onClick={props.onClick ? props.onClick : onEditClicked}>
                    <Pencil />
                </IconButton>
            }
        </Tooltip >
    )
})

export default EditButton;