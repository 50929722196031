import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles, SvgIconProps, useMediaQuery, DialogProps } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { observer } from "mobx-react";
import { DialogState } from "../../../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../../../Shared/Dialogs/ThemedDialogWithSpinner";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                background: theme.palette.error.main,
                color: theme.palette.error.contrastText,
            }
        },
        paper: {
            flexGrow: 1
        }
    })
);

export interface RecordEditorDialogProps<T> {
    children: (record: T) => JSX.Element;
    state: DialogState;
    title: string;
    record: T;
    copyRecord: (record: T) => T;
    confirmText?: string | ((record: T) => string);
    cancelText?: string;
    confirmIcon?: (props: SvgIconProps) => JSX.Element;
    confirmDisabled?: boolean | ((record: T) => boolean);
    displayRemoveButton?: boolean;
    removeText?: string;
    displayError?: boolean;
    onConfirm: (record: T) => void;
    onRemove?: (record: T) => void;
    DialogProps?: Partial<DialogProps>;
}

const RecordEditorDialog = observer((props: RecordEditorDialogProps<any>) => {

    /********* State *********/

    const [record, setRecord] = useState(props.copyRecord(props.record));
    const [title, setTitle] = useState(props.title);

    /********* React hooks *********/

    const classes = useStyles();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Effects *********/

    // Reset the dialog when it is first opened
    useEffect(() => {
        if (props.state.open) {
            resetDialog();
        }
    }, [props.state.open, props.record]);

    /********* Helper methods *********/

    const resetDialog = () => {
        setRecord(props.copyRecord(props.record));
        setTitle(props.title);
    };

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            DialogProps={{ fullScreen: xsDown, PaperProps: { className: classes.paper }, ...props.DialogProps }}
            state={props.state}
            title={title}
            primaryButtonProps={props.confirmText
                ? {
                    children: typeof props.confirmText === 'string' ? props.confirmText : props.confirmText(record).toString(),
                    startIcon: props.confirmIcon ? React.createElement(props.confirmIcon) : undefined,
                    disabled: props.confirmDisabled
                        ? typeof props.confirmDisabled === 'boolean'
                            ? props.confirmDisabled
                            : props.confirmDisabled(record)
                        : undefined
                }
                : undefined
            }
            cancelText={props.cancelText}
            extraButtonProps={
                props.displayRemoveButton || props.removeText
                    ? [{
                        children: props.removeText ? props.removeText : 'Remove',
                        variant: 'contained',
                        className: classes.removeButton,
                        onClick: () => props.onRemove ? props.onRemove(record) : undefined,
                        startIcon: <DeleteIcon />
                    }]
                    : undefined
            }
            onSubmit={() => props.onConfirm(record)}
        >
            {props.children(record)}
        </ThemedDialogWithSpinner >
    )
});

export default RecordEditorDialog;