import React, { ChangeEvent } from "react";
import { Address, IAddressFields, StringAddressField } from "../../stores/models/Address";
import { TextField, makeStyles, Theme, createStyles, Divider } from "@material-ui/core";
import clsx from 'clsx';
import ZipCodeInput from "./ZipCodeInput";
import { observer } from "mobx-react";
import AddressAutocomplete from "./AddressAutocomplete";
import { AddressSuggestion } from "../../stores/models/AddressSuggestion";
import { ADDRESS_LINE_ONE_MAX_LENGTH, ADDRESS_LINE_TWO_MAX_LENGTH, CITY_MAX_LENGTH, STATE_MAX_LENGTH, LOCATION_NAME_MAX_LENGTH } from "../../logic/ValidationChecks/FieldLengths";
import CheckboxList from "./CheckboxList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formFields: {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                '&:not(:last-child)': {
                    marginBottom: theme.spacing(2),
                },
                '&:last-child': {
                    marginTop: theme.spacing(-2)
                },
                flexGrow: 1,
                display: 'flex',
                '& > *': {
                    flexGrow: 1
                }
            }
        },
        flexGrow: {
            flexGrow: 1
        },
        lastLine: {
            marginBottom: theme.spacing(0)
        },
        stateField: {
            marginRight: theme.spacing(1)
        }
    })
);

interface AddressFormProps extends React.HTMLAttributes<HTMLDivElement> {
    address: Address;
}

const AddressForm = observer((props: AddressFormProps) => {

    const address = props.address;
    const classes = useStyles();

    const handleChange = (field: StringAddressField, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        props.address.setField(field, newValue);
    }

    const handleAutocompleteSuggestionSelected = (address: string | AddressSuggestion) => {
        if (typeof address === 'string') {
            props.address.setField('lineOne', address);
        } else {
            props.address.setField('lineOne', address.lineOne);
            props.address.setField('city', address.city);
            props.address.setField('state', address.state);
            props.address.setField('zipCode', address.zip);
        }
    }

    /********** Helper constants *********/

    const nameError = address.errors.name || '';
    const lineOneError = address.errors.lineOne || '';
    const lineTwoError = address.errors.lineTwo || '';
    const cityError = address.errors.city || '';
    const stateError = address.errors.state || '';
    const zipCodeError = address.errors.zipCode || '';

    /********** Render *********/

    return (
        <div className={clsx(classes.formFields, props.className)}>
            <div>
                <AddressAutocomplete
                    address={address}
                    onChange={handleAutocompleteSuggestionSelected}
                    TextFieldProps={{
                        label: 'Line 1',
                        variant: 'outlined',
                        required: true,
                        autoFocus: true,
                        classes: { root: classes.flexGrow },
                        error: lineOneError.length > 0,
                        helperText: lineOneError.length > 0 ? lineOneError : undefined,
                        inputProps: { maxLength: ADDRESS_LINE_ONE_MAX_LENGTH }
                    }}
                />
            </div>
            <div>
                <TextField
                    label="Line 2"
                    variant="outlined"
                    value={address.lineTwo}
                    onChange={event => handleChange('lineTwo', event)}
                    error={lineTwoError.length > 0}
                    helperText={lineTwoError.length > 0 ? lineTwoError : undefined}
                    inputProps={{ maxLength: ADDRESS_LINE_TWO_MAX_LENGTH }}
                />
            </div>
            <div>
                <TextField
                    label="City"
                    variant="outlined"
                    required
                    value={address.city}
                    onChange={event => handleChange('city', event)}
                    error={cityError.length > 0}
                    helperText={cityError.length > 0 ? cityError : undefined}
                    inputProps={{ maxLength: CITY_MAX_LENGTH }}
                />
            </div>
            <div>
                <TextField
                    label="State"
                    variant="outlined"
                    required
                    value={address.state}
                    className={classes.stateField}
                    onChange={event => handleChange('state', event)}
                    error={stateError.length > 0}
                    helperText={stateError.length > 0 ? stateError : undefined}
                    inputProps={{ maxLength: STATE_MAX_LENGTH }}
                />
                <ZipCodeInput
                    variant="outlined"
                    required
                    value={address.zipCode}
                    onChange={event => handleChange('zipCode', event)}
                    error={zipCodeError.length > 0}
                    helperText={zipCodeError.length > 0 ? zipCodeError : undefined}
                />
            </div>
            <div className={classes.lastLine}>
                <TextField
                    label="Location Name (optional)"
                    variant="outlined"
                    value={address.name}
                    onChange={event => handleChange('name', event)}
                    error={nameError.length > 0}
                    helperText={nameError.length > 0 ? nameError : undefined}
                    inputProps={{ maxLength: LOCATION_NAME_MAX_LENGTH }}
                />
            </div>
            <CheckboxList options={address.accessibilityOptions} />
        </div>
    )
});

export default AddressForm;