import React, { useContext, useState } from "react";
import { TextField, makeStyles, Theme, createStyles, Typography, InputAdornment, CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext } from "../../../../../stores";
import useDebouncedEffect from "../../../../Shared/Hooks/DebouncedEffect";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CancellablePromise } from "mobx/dist/internal";
import { Alert as AlertIcon } from "mdi-material-ui";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isValidEmail } from "../../../../../logic/ValidationChecks/Validation";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warningIcon: {
            color: theme.palette.warning.main
        },
        checkIcon: {
            color: theme.palette.primary.main
        },
        alert: {
            marginBottom: theme.spacing(1)
        },
        bold: {
            fontWeight: 'bold'
        }
    })
);

interface EmailInUseWarningFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    currentVolunteerId?: number;
    email?: string;
    error?: string;
    onEmailChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const EmailInUseWarningField = observer((props: EmailInUseWarningFieldProps) => {

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const organizationStore = rootStore.organizationStore;

    /********** State *********/

    const [volunteersWithEmail, setVolunteersWithEmail] = useState<{ id: number, firstName: string, lastName: string }[]>();
    const [emailCheckPromise, setEmailCheckPromise] = useState<CancellablePromise<boolean>>();

    /********** Effects *********/

    const debouncedFindVolunteersWithEmail = useDebouncedEffect({
        trigger: props.email,
        effect: () => {
            setVolunteersWithEmail(undefined);
            findOtherVolunteersWithEmail();
        }
    });

    /********** Helper methods *********/

    const findOtherVolunteersWithEmail = async () => {
        if (emailCheckPromise) {
            emailCheckPromise.cancel();
        }
        if (isEmailValid()) {
            const promise = organizationStore.findVolunteersWithEmail(props.email!);
            setEmailCheckPromise(promise);
            const volunteersWithSameEmail = await promise;
            setVolunteersWithEmail(volunteersWithSameEmail);
        }
    }

    const isEmailValid = () => {
        return props.email && isValidEmail(props.email);
    }

    const otherVolunteersWithEmail = volunteersWithEmail?.filter(volunteer => volunteer.id !== props.currentVolunteerId);

    /********** Render *********/

    return (
        <React.Fragment>
            {(otherVolunteersWithEmail && otherVolunteersWithEmail.length > 0) &&
                <Alert severity="warning" className={classes.alert}>
                    <AlertTitle>Email Already in Use</AlertTitle>
                    This email is already associated with the following volunteer(s):
                    <ul>
                        {otherVolunteersWithEmail.map((volunteer, index) => {
                            return (
                                <li key={`volunteer-name-${index}`}>
                                    {`${volunteer.firstName} ${volunteer.lastName}`}
                                </li>
                            )
                        })}
                    </ul>
                    <Typography variant="body2" className={classes.bold}>
                        No changes required if this is intentional.
                    </Typography>
                </Alert>
            }
            <TextField
                id="standard-email-input"
                variant="outlined"
                value={props.email}
                onChange={props.onEmailChanged}
                error={props.error !== undefined && props.error.length > 0}
                helperText={props.error}
                InputProps={{
                    endAdornment: isEmailValid()
                        ? <InputAdornment position="end">
                            {otherVolunteersWithEmail === undefined
                                ? <CircularProgress size={20} />
                                : otherVolunteersWithEmail.length === 0
                                    ? <CheckCircleIcon className={classes.checkIcon} />
                                    : <AlertIcon className={classes.warningIcon} />
                            }
                        </InputAdornment>
                        : null
                }}
            />
        </React.Fragment>
    )
});

export default EmailInUseWarningField;