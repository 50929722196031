// Maximum length of Organization Information fields
export const ORGANIZATION_NAME_MAX_LENGTH = 150;
export const ORGANIZATION_MISSION_MAX_LENGTH = 500;
export const ORGANIZATION_DESCRIPTION_MAX_LENGTH = 3000;

// Maximum length of Opportunity Information fields
export const OPPORTUNITY_TITLE_MAX_LENGTH = 100;
export const OPPORTUNITY_POSITION_MAX_LENGTH = 75;
export const OPPORTUNITY_DESCRIPTION_MAX_LENGTH = 5000;
export const OPPORTUNITY_TRAINING_DESCRIPTION_MAX_LENGTH = 3000;

// Maximum length of Volunteer Information fields
export const VOLUNTEER_NAME_MAX_LENGTH = 75;

// Maximum length of Address fields
export const ADDRESS_LINE_ONE_MAX_LENGTH = 200;
export const ADDRESS_LINE_TWO_MAX_LENGTH = 200;
export const CITY_MAX_LENGTH = 90;
export const STATE_MAX_LENGTH = 2;
export const LOCATION_NAME_MAX_LENGTH = 200;

// Maximum length of Contact Us fields
export const CONTACT_US_SUBJECT_MAX_LENGTH = 150;
export const CONTACT_US_MESSAGE_MAX_LENGTH = 4000;

// Maximum length of Volunteer Response fields
export const VOLUNTEER_RESPONSE_PHONE_MAX_LENGTH = 10;
export const VOLUNTEER_RESPONSE_MESSAGE_MAX_LENGTH = 2000;

// Maximum length of Service Entry fields
export const SERVICE_ENTRY_NOTES_MAX_LENGTH = 500;

// Maximum length of Custom Portal slug
export const CUSTOM_PORTAL_SLUG_MAX_LENGTH = 50;
