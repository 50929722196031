import { observer } from 'mobx-react'
import { Theme, useMediaQuery } from "@material-ui/core";
import SuccessMessage from '../../SuccessMessage';
import ThemedDialogWithSpinner, { ThemedDialogWithSpinnerProps } from '../../Dialogs/ThemedDialogWithSpinner';
import { Fragment } from 'react';

interface SuccessfulRegistrationDialogProps extends ThemedDialogWithSpinnerProps {
    organizationName: string;
}

const SuccessfulRegistrationDialog = observer((props: SuccessfulRegistrationDialogProps) => {

    /********* React hooks *********/

    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            {...props}
            DialogProps={{ fullScreen: xsDown }}
            cancelText={'Close'}
        >
            <SuccessMessage
                title={'Thank you!'}
                details={(
                    <Fragment>
                        Your submission has been sent to {props.organizationName}.
                    </Fragment>
                )}
            />
        </ThemedDialogWithSpinner>
    );
});

export default SuccessfulRegistrationDialog;