import React, { StrictMode } from 'react';
import './App.css';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import App from './App';
import { Route } from 'react-router-dom';

const AppRouter: React.FC = () => {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="*" element={<App />} />
        )
    );

    /********* Render *********/

    return (
        <StrictMode>
            <RouterProvider router={router} />
        </StrictMode>
    );
}

export default AppRouter;
