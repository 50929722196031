import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import { ProfileSettings, CredentialsSettings } from "../../AccountSettings/Pages";
import HandsRaised from "../../AccountSettings/Pages/Volunteer/HandsRaised";
import VolunteerServiceHistoryPage from "../../../modules/volunteer-hours/components/VolunteerServiceHistoryPage";
import Sparks from "../../AccountSettings/Pages/Volunteer/Sparks";
import VolunteerShiftsPage from "../../AccountSettings/Pages/Volunteer/VolunteerShiftsPage";
import VolunteerInstructions from "../../HelpPage/VolunteerInstructions";
import { getAccountProfilePath, getSettingsPagePath, getHandsRaisedPath, getSparksPath, getVolunteerShiftsPath, getVolunteerServiceHistoryPath, getVolunteerHelpPath, getDashboardPath, getDashboardLink, routeWithDescendants } from "../Links/UrlConstructors";
import { NavigateInternally } from "../Components";
import VolunteerDashboard from "../../../modules/volunteer-dashboard/components/VolunteerDashboard";

const VolunteerAccountSettingsRoutes = observer(() => {

    return (
        <Routes>
            <Route path={getDashboardPath()} element={<VolunteerDashboard />} />
            <Route path={getAccountProfilePath()} element={<ProfileSettings />} />
            <Route path={getSettingsPagePath()} element={<CredentialsSettings />} />
            <Route path={getHandsRaisedPath()} element={<HandsRaised />} />
            <Route path={getSparksPath()} element={<Sparks />} />
            <Route path={routeWithDescendants(getVolunteerShiftsPath())} element={<VolunteerShiftsPage />} />
            <Route path={getVolunteerServiceHistoryPath()} element={<VolunteerServiceHistoryPage />} />
            <Route path={getVolunteerHelpPath()} element={<VolunteerInstructions title="Help Center" />} />
            <Route path={"/*"} element={<NavigateInternally to={getDashboardLink()} replace />} />
        </Routes>
    );
});

export default VolunteerAccountSettingsRoutes;