import React from "react";
import { observer } from "mobx-react";
import { OrganizationStubVolunteerLinking } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { StepProps } from "../../../../stores/models/Step";
import InvalidEmailApology from "../InvalidEmailApology";

const InvalidEmailApologyStep = observer((props: StepProps<OrganizationStubVolunteerLinking>) => {

    const linkData = props.stepState.stepObject;

    /***** Render *****/

    return (
        <InvalidEmailApology
            organizationName={linkData.linkDetails.organizationName}
        />
    );
});

export default InvalidEmailApologyStep;