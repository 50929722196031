import React from "react";
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1)
            }
        },
        emphasizedText: {
            color: theme.palette.secondary.light,
            fontWeight: 900
        },
    }),
);

const SkillPickerTitle: React.FunctionComponent = observer(() => {

    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Typography variant="h4">Share your <span className={classes.emphasizedText}>superpowers.</span></Typography>
            {/* <Typography variant="h4">Use your experience</Typography>
            <Typography variant="h4">to <span className={classes.emphasizedText}> amplify</span> your impact.</Typography> */}
        </div>
    )
});

export default SkillPickerTitle;