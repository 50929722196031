import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from '../../../../stores/RootStore';
import { IStatus } from '../../../../stores/models/Status';
import { RecordListStore } from '../../../../shared/modules/record-collection/data-access/stores/RecordListStore';
import { IServiceEntry, ServiceEntry } from '../entities/ServiceEntry';
import { IOrganizationRoles } from '../interfaces/OrganizationRoles';
import { IdentityKey } from '../../../../stores/models/NewOptionCollection';
import { ServiceEntryIdentification } from '../interfaces';

export class VolunteerHoursStore extends RecordListStore<ServiceEntry, keyof IServiceEntry> {

    @observable serviceEntryStatuses: IStatus[] = [];
    @observable organizationRoles: IOrganizationRoles[] = [];
    // @observable tags: IServiceEntryTag[] = []; // TODO: Make generic tag class, this data is also stored by middleware, so this property may not be necessary or maybe it should be stored here instead. Check where it's used.

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    /*************** Setters ***************/

    @action setServiceEntryStatuses(serviceEntryStatuses: IStatus[]) {
        this.serviceEntryStatuses = serviceEntryStatuses;
    }

    @action setOrganizationRoles(organizationRoles: IOrganizationRoles[]) {
        this.organizationRoles = organizationRoles;
    }

    /*************** RecordListStore methods ***************/

    getRecordIdentityKey(): IdentityKey<ServiceEntry> {
        return 'id';
    }

    /*************** Computed properties ***************/

    @computed get idsOfSelections(): ServiceEntryIdentification[] {
        return this.collection.selectedOptions.map(serviceEntry => {
            return serviceEntry.id > 0
                ? { id: serviceEntry.id }
                : {
                    shiftId: serviceEntry.shiftId!,
                    defaultDaysFromStartDate: serviceEntry.defaultDaysFromStartDate,
                    volunteerId: serviceEntry.volunteerId!
                };
        });
    }
}

