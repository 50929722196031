import { makeAutoObservable } from "mobx";
import { ShiftIdentification } from "./ShiftIdentification";
import { VolunteerShiftRegistration } from "./VolunteerShiftRegistration";

export interface IShiftRegistration {
    registeringUserId: number;
    shiftIdentification: ShiftIdentification;
    volunteerRegistrations: VolunteerShiftRegistration[];
    currentVolunteerRegistered?: boolean;
    registeredProxies?: number[];
}

interface IPrunedShiftRegistration {
    shiftIdentification: ShiftIdentification;
    numVolunteerRegistrations: number;
    currentVolunteerRegistered: boolean;
    registeredProxies: number[];
}

export class ShiftRegistration implements IShiftRegistration {
    registeringUserId: number;
    shiftIdentification: ShiftIdentification;
    volunteerRegistrations: VolunteerShiftRegistration[];
    currentVolunteerRegistered?: boolean;
    registeredProxies: number[] = [];

    constructor(shiftRegistration: IShiftRegistration | IPrunedShiftRegistration) {
        makeAutoObservable(this);

        this.shiftIdentification = new ShiftIdentification(shiftRegistration.shiftIdentification);
        this.currentVolunteerRegistered = shiftRegistration.currentVolunteerRegistered;
        this.registeredProxies = shiftRegistration.registeredProxies || [];
        if ('registeringUserId' in shiftRegistration) {
            this.registeringUserId = shiftRegistration.registeringUserId;
            this.volunteerRegistrations = shiftRegistration.volunteerRegistrations.map(registration => new VolunteerShiftRegistration(registration));
        } else {
            this.registeringUserId = -1;
            const volunteerRegistrations = [] as VolunteerShiftRegistration[];
            while (volunteerRegistrations.length < shiftRegistration.numVolunteerRegistrations) {
                volunteerRegistrations.push(new VolunteerShiftRegistration({ volunteerId: -1 }));
            }
            this.volunteerRegistrations = volunteerRegistrations;
        }
    }

    setVolunteerRegistrations(volunteerRegistrations: VolunteerShiftRegistration[]) {
        this.volunteerRegistrations = volunteerRegistrations;
    }

    serialize() {
        return {
            shiftIdentification: this.shiftIdentification,
            userId: this.registeringUserId,
            volunteerRegistrations: this.volunteerRegistrations.map(volunteerRegistration => volunteerRegistration.serialize())
        }
    }

}