import { Paw, Palette, HomeGroup, School, PineTree, ScaleBalance, BabyCarriage, HumanMaleChild, HomeRoof, Lifebuoy, SilverwareForkKnife, FireHydrantAlert, Earth, Bullhorn, Laptop, HumanCane, Briefcase, Gavel, Looks, GenderFemale, Medal, GenderMale, WheelchairAccessibility, FlaskOutline, Pharmacy, Bank } from "mdi-material-ui";
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import { IconName } from "../stores/models/Icon";

const CauseIconDictionary: Record<number, IconName> = {
    1: Paw,
    2: Palette,
    3: Bank,
    4: HomeGroup,
    5: School,
    6: PineTree,
    7: FitnessCenterIcon,
    8: ScaleBalance,
    9: BabyCarriage,
    10: HumanMaleChild,
    11: HomeRoof,
    12: Lifebuoy,
    13: SilverwareForkKnife,
    14: FireHydrantAlert,
    15: Earth,
    16: Bullhorn,
    17: GenderMale,
    18: Laptop,
    19: HumanCane,
    20: Briefcase,
    22: Gavel,
    23: Looks,
    24: Pharmacy,
    25: WheelchairAccessibility,
    26: FlaskOutline,
    27: Medal,
    28: GenderFemale,
};

export default CauseIconDictionary;