import React, { Fragment, useContext, useEffect, useState } from "react";
import { RootContext, UserType } from "../../stores";
import { observer } from "mobx-react";
import useRegistrationSteps from "./RegistrationStepsHook";
import { DialogState } from "../../stores/models/DialogState";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import { getOrganizationSignUpLink } from "../Navigation/Links";
import { StubUser } from "../../stores/models/StubUser";
import { useMediaQuery, Theme, Typography, makeStyles, createStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useNavigateInternally } from "../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            marginBottom: theme.spacing(2)
        },
    }),
);

interface SignUpDialogProps {
    dialogState: DialogState;
    onSwitchToLogin: () => void;
}

const SignUpDialog = observer((props: SignUpDialogProps) => {

    /*********************** State ***********************/

    const [stubUser, setStubUser] = useState(new StubUser());

    /*********************** React hooks ***********************/

    const classes = useStyles();
    const registrationSteps = useRegistrationSteps(stubUser);
    const registrationStore = useContext(RootContext).registrationStore;
    const navigate = useNavigateInternally();
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    /*********************** State ***********************/

    const [step, setStep] = useState(0);
    const [currentStep, setCurrentStep] = useState(registrationSteps[step]);
    const [error, setError] = useState<string>();

    /*********************** Effects ***********************/

    // Reset the dialog when it is first opened
    useEffect(() => {
        if (props.dialogState.open) {
            resetDialog();
        }
    }, [props.dialogState.open]);

    useEffect(() => {
        setCurrentStep(registrationSteps[step]);
    }, [step, registrationSteps]);

    useEffect(() => {
        currentStep.state.setValidationRun(false);
        currentStep.state.setCompleted(false);
    }, [currentStep]);

    /*********************** Event handlers ***********************/

    const handleAdvanceClicked = () => {
        advanceStep();
    }

    const handlePreviousClicked = () => {
        returnToPreviousStep();
    }

    /*********************** Helper methods and constants ***********************/

    const resetDialog = () => {
        setStep(0);
        setStubUser(new StubUser());
    }

    const isFinalRegistrationStep = step === registrationSteps.length - 1;

    const advanceStep = async () => {
        props.dialogState.setLoading(true);
        currentStep.state.setLoading(true);
        const validated = await currentStep.validated();
        currentStep.state.setValidationRun(true);
        if (validated) {
            currentStep.state.setCompleted(true);
            if (stubUser.type === UserType.Organization) {
                navigate(getOrganizationSignUpLink());
                props.dialogState.setOpen(false);
            } else if (!isFinalRegistrationStep) {
                setStep(step + 1);
            } else if (stubUser.data) {
                const error = await registrationStore.register(stubUser);
                setError(error);
            }
        }
        currentStep.state.setLoading(false);
        props.dialogState.setLoading(false);
    }

    const returnToPreviousStep = () => {
        setStep(step - 1);
    }

    /*********************** Render ***********************/

    return (
        <ThemedDialogWithSpinner
            state={props.dialogState}
            title="Sign Up"
            DialogProps={{ fullScreen: xsDown, maxWidth: 'xs' }}
            primaryButtonProps={{ children: isFinalRegistrationStep ? 'Sign Up' : 'Next' }}
            leftButtonProps={step > 0 ? { onClick: handlePreviousClicked } : undefined}
            onSubmit={handleAdvanceClicked}
        >
            <Fragment>
                {error &&
                    <Alert severity="error" className={classes.alert}>
                        <Typography>{error}</Typography>
                    </Alert>
                }
                {currentStep &&
                    React.createElement(currentStep.content, { stepState: currentStep.state })
                }
            </Fragment>
        </ThemedDialogWithSpinner>
    );
});

export default SignUpDialog;