import { Grid, Typography, makeStyles, Theme, createStyles } from "@material-ui/core";
import { Volunteer } from "../../../../stores";
import EditableField from "../../../Shared/EditableField";
import { DateFormatter } from "../../../../logic/DateFormatter";
import { observer } from "mobx-react";
import { VOLUNTEER_NAME_MAX_LENGTH } from "../../../../logic/ValidationChecks/FieldLengths";
import REMKeyboardDatePicker from "../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subsection: {
            marginTop: theme.spacing(2),
        },
        detail: {
            marginBottom: theme.spacing(2)
        },
        textField: {
            marginRight: theme.spacing(2)
        }
    }),
);

interface VolunteerProfileProps {
    editing: boolean;
    volunteerDraft: Volunteer;
}

const VolunteerProfile = observer((props: VolunteerProfileProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Event handler *********/

    const handleBirthdateChange = (date: Date | null) => {
        props.volunteerDraft.setBirthdate(date ? date.toUTCString() : undefined);
    }

    /********* Render *********/

    return (
        <div className={classes.subsection}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <div className={classes.detail}>
                        <Typography variant="h6">
                            First Name
                        </Typography>
                        <EditableField
                            fieldName="firstName"
                            value={props.volunteerDraft.firstName}
                            editMode={props.editing}
                            disableLinks
                            onFieldEdited={(fieldName, event) => props.volunteerDraft.setFirstName(event.target.value)}
                            TextFieldProps={{
                                className: classes.textField,
                                variant: 'outlined',
                                error: props.volunteerDraft.isFieldInvalid('firstName'),
                                helperText: props.volunteerDraft.getErrorForField('firstName'),
                                inputProps: { maxLength: VOLUNTEER_NAME_MAX_LENGTH }
                            }}
                        />
                    </div>
                    <Typography variant="h6">
                        Last Name
                    </Typography>
                    <EditableField
                        fieldName="lastName"
                        value={props.volunteerDraft.lastName}
                        editMode={props.editing}
                        disableLinks
                        onFieldEdited={(fieldName, event) => props.volunteerDraft.setLastName(event.target.value)}
                        TextFieldProps={{
                            className: classes.textField,
                            variant: 'outlined',
                            error: props.volunteerDraft.isFieldInvalid('lastName'),
                            helperText: props.volunteerDraft.getErrorForField('lastName'),
                            inputProps: { maxLength: VOLUNTEER_NAME_MAX_LENGTH }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                        Birthday
                    </Typography>
                    {!props.editing
                        ? <Typography>
                            {props.volunteerDraft.birthdate
                                ? DateFormatter.getDateInUTC(props.volunteerDraft.birthdate, true)
                                : ""}
                        </Typography>
                        : <REMKeyboardDatePicker
                            id="birthdate-picker"
                            format="MM/dd/yyyy"
                            minDate={new Date(1900, 1, 1)}
                            value={props.volunteerDraft.birthdate ? props.volunteerDraft.birthdate : null}
                            onChange={handleBirthdateChange}
                            inputVariant="outlined"
                            required
                            disableFuture
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            error={props.volunteerDraft.isFieldInvalid('birthdate')}
                            helperText={props.volunteerDraft.getErrorForField('birthdate')}
                        />
                    }
                </Grid>
            </Grid>
        </div >
    )
});

export default VolunteerProfile;