import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrowWrapper: {
            height: 6,
            position: 'relative',
        },
        popperArrow: {
            zIndex: -1,
            margin: 'auto',
            width: 0,
            height: 0,
            borderLeft: '22px solid transparent',
            borderRight: '22px solid transparent',
            borderBottom: '22px solid lightgray',
            position: 'relative',
        },
        innerPopperArrow: {
            margin: 'auto',
            left: 0,
            right: 0,
            width: 0,
            height: 0,
            borderLeft: '21px solid transparent',
            borderRight: '21px solid transparent',
            borderBottom: '21px solid white',
            position: 'absolute',
            top: '1px',
        },
    }),
);

const PopperArrow = () => {

    const classes = useStyles();

    /********* Render *********/

    return (
        <div className={classes.arrowWrapper}>
            <div className={classes.popperArrow} />
            <div className={classes.innerPopperArrow} />
        </div>
    );
};

export default PopperArrow;