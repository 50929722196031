import { useContext, useState, useEffect } from 'react';
import { RootContext } from '../../stores';
import { MenuItem } from "../../stores/models/MenuItem";
import { LogoutMenuItem, RecruitmentMenuItem, SubscriptionMenuItem, SettingsMenuItem, OrganizationAccountMenuItem, InformationMenuItem, VolunteerAccountMenuItem, OrganizationMenuItem, VolunteeringMenuItem, UpdateMenuItem, DataMenuItem } from "./AccountSettingsMenuItems";
import { SupportMenuItem, ContactUsMenuItem, OrganizationHelpMenuItem, VolunteerHelpMenuItem } from "../Navigation/MenuItems";
import { useMatchCurrentPath } from '../Navigation/Hooks';
import { useLocation } from 'react-router-dom';

export default function useAccountSettingsMenuItems(): MenuItem[] {

    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;
    const matchPath = useMatchCurrentPath();
    const location = useLocation();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    useEffect(() => {

        let menuItems: MenuItem[] = [];

        const logoutItem = LogoutMenuItem({
            onClick: () => navigationStore.appwideDialogStates.logoutDialog.setOpen(true)
        });
        const contactUsItem = ContactUsMenuItem({
            linkPath: undefined,
            onClick: () => navigationStore.appwideDialogStates.contactUsDialog.setOpen(true)
        });
        let supportSubmenuItems = userStore.user.isOrganization ? [OrganizationHelpMenuItem(), contactUsItem] : [VolunteerHelpMenuItem(), contactUsItem];
        if (rootStore.clientHasUpdates) {
            supportSubmenuItems.push(UpdateMenuItem({
                onClick: () => navigationStore.appwideDialogStates.clientVersionOutOfDateDialog.setOpen(true)
            }));
        }
        const supportItem = SupportMenuItem({ subItems: supportSubmenuItems });

        if (userStore.user.isVolunteer) {
            // Volunteer account settings
            const volunteerAccountItem = VolunteerAccountMenuItem({
                subItems: [InformationMenuItem(), SettingsMenuItem(), logoutItem]
            });
            menuItems = [VolunteeringMenuItem(), volunteerAccountItem, supportItem];
        } else if (userStore.user.requiresSubscription) {
            // For-profit account settings
            const organizationAccountItem = OrganizationAccountMenuItem({
                subItems: [SettingsMenuItem(), SubscriptionMenuItem(), logoutItem]
            });
            menuItems = [OrganizationMenuItem(), RecruitmentMenuItem(), DataMenuItem(), organizationAccountItem, supportItem];
        } else {
            // Nonprofit account settings
            const organizationAccountItem = OrganizationAccountMenuItem({ subItems: [SettingsMenuItem(), logoutItem] });
            menuItems = [OrganizationMenuItem(), RecruitmentMenuItem(), DataMenuItem(), organizationAccountItem, supportItem];
        }

        setMenuItems(menuItems);
    }, [userStore.user.profileData, rootStore.clientHasUpdates]);

    /*** If the menu item's link path matches the current path, set the menu item to active. */
    const setActiveMenuItems = (items: MenuItem[]) => {
        items.forEach(menuItem => {
            if (menuItem.linkPath && matchPath(menuItem.linkPath + "/*")) {
                menuItem.setActive(true);
            } else {
                menuItem.setActive(false);
            }
            if (menuItem.subItems) {
                setActiveMenuItems(menuItem.subItems);
            }
        })
    }

    useEffect(() => {
        setActiveMenuItems(menuItems);
    }, [location.pathname, menuItems])

    return menuItems;
}