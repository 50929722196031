import { Fragment } from "react";
import { Typography, Paper, Container, makeStyles, Theme, createStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { EditorState } from "../../../../../stores/models/EditorState";
import Editor from "../../../Editor";
import FieldSection from "../FieldSection";
import { ReportData, ReportNames } from "../../../../../stores/models/ReportData";
import clsx from "clsx";
import FormLoadingSpinner from "../../../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import ReportRangeSelection from "./ReportRangeSelection";
import ReportSummarySection from "./ReportSummarySection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            overflow: 'hidden',
            '&:not(:first-child)': {
                marginTop: theme.spacing(2)
            }
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        title: {
            wordBreak: 'normal',
            textOverflow: 'ellipsis',
            overflowX: 'hidden'
        },
        marginBottom: {
            marginBottom: theme.spacing(1)
        },
        spacer: {
            height: theme.spacing(1)
        }
    }),
);

interface ReportingSummaryPageProps {
    reportData: ReportData | undefined;
    startDate: Date | null;
    endDate: Date | null;
    rangeError: string;
    loading: boolean;
    onStartDateChanged: (date: Date | null) => void;
    onEndDateChanged: (date: Date | null) => void;
    onDownload: (report: ReportNames) => void;
}

const ReportingSummaryPage = observer((props: ReportingSummaryPageProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* State *********/

    const { reportData, startDate, endDate, rangeError, loading, onStartDateChanged, onEndDateChanged, onDownload } = props;

    /********* Render *********/

    return (
        <Editor editsMade={false}>
            {(editorState: EditorState) => (
                <Container>
                    <Paper className={classes.paper}>
                        <Container>
                            {/* {shouldDisplayFormError(editorState) ? <FormError /> : null} */}
                            <FieldSection>
                                <div className={classes.header}>
                                    <Typography variant="h4" className={classes.title}>
                                        Reporting
                                    </Typography>
                                </div>
                                <Typography>Pull reports for your volunteer data.</Typography>
                            </FieldSection>
                            {ReportData.rangelessReports.map((report, index, array) => {
                                return (
                                    <div key={`rangeless-report-${index}`}>
                                        <ReportSummarySection
                                            report={report}
                                            reportData={reportData}
                                            onDownload={onDownload}
                                        />
                                        {index !== array.length - 1
                                            ? <Fragment>
                                                <div className={classes.spacer} />
                                                <div className={classes.spacer} />
                                            </Fragment>
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </Container>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Container>
                            <Typography variant="h5" className={clsx(classes.title, classes.marginBottom)}>
                                Reports By Range
                            </Typography>
                            <ReportRangeSelection
                                startDate={startDate}
                                endDate={endDate}
                                rangeError={rangeError}
                                onStartDateChanged={onStartDateChanged}
                                onEndDateChanged={onEndDateChanged}
                            />
                            {ReportData.rangeSpecificReports.map((report, index, array) => {
                                return (
                                    <div key={`range-specific-report-${index}`}>
                                        <ReportSummarySection
                                            report={report}
                                            reportData={reportData}
                                            onDownload={onDownload}
                                        />
                                        {index !== array.length - 1
                                            ? <Fragment>
                                                <div className={classes.spacer} />
                                                <div className={classes.spacer} />
                                            </Fragment>
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </Container>
                    </Paper>
                    {loading ? <FormLoadingSpinner /> : null}
                </Container >
            )}
        </Editor >
    )
});

export default ReportingSummaryPage;