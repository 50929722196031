import { observable, action, computed, override, makeObservable } from "mobx";
import { isValidEmail } from "../../logic/ValidationChecks/Validation";
import { Fields } from "./Fields";
import { validateCredentials } from "../../logic/ValidationChecks";

interface ICredentialsFields {
    email: string;
    password: string;
    confirmedPassword: string;
}

export class Credentials extends Fields<ICredentialsFields, Credentials> implements ICredentialsFields {
    email = "";
    password = "";
    confirmedPassword = "";
    unique?: boolean;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this, {
            email: observable,
            password: observable,
            confirmedPassword: observable,
            unique: observable,
            setEmail: action,
            setPassword: action,
            setConfirmedPassword: action,
            setUnique: action,
            emailIsValid: computed,
            passwordsMatch: computed,
            validationErrors: override
        });
    }

    setEmail(newEmail: string) {
        this.email = newEmail;
        this.unique = undefined;
    }

    setPassword(newPassword: string) {
        this.password = newPassword;
    }

    setConfirmedPassword(newConfirmedPassword: string) {
        this.confirmedPassword = newConfirmedPassword;
    }

    setUnique(unique: boolean) {
        this.unique = unique;
    }

    get emailIsValid() {
        return isValidEmail(this.email);
    }

    get passwordsMatch() {
        return this.password === this.confirmedPassword;
    }

    get validationErrors() {
        return validateCredentials(this);
    }

    serialize() {
        return {
            username: this.email.trim(),
            password: this.password
        }
    }
}