import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";
import { VolunteerShiftRegistration } from "../../stores/models/VolunteerShiftRegistration";

const volunteerShiftRegistrationChecks: ValidationCheck<VolunteerShiftRegistration>[] = [{
    name: 'valid volunteer id or first name entered',
    condition: (volunteerShiftRegistration: VolunteerShiftRegistration) => {
        const firstName = volunteerShiftRegistration.firstName;
        return volunteerShiftRegistration.volunteerId > 0 ||
            (firstName !== undefined && firstName.length > 0);
    },
    error: new ValidationError('Please enter a first name.', ['firstName'])
}, {
    name: 'valid volunteer id or last name entered',
    condition: (volunteerShiftRegistration: VolunteerShiftRegistration) => {
        const lastName = volunteerShiftRegistration.lastName;
        return volunteerShiftRegistration.volunteerId > 0 ||
            (lastName !== undefined && lastName.length > 0);
    },
    error: new ValidationError('Please enter a last name.', ['lastName'])
}];

export function validateVolunteerShiftRegistration(volunteerShiftRegistration: VolunteerShiftRegistration) {
    return getValidationErrors(volunteerShiftRegistrationChecks, volunteerShiftRegistration);
}