import { Fragment, useContext } from "react";
import { RootContext } from "../../stores";
import ThemedDialogWithSpinner from "../Shared/Dialogs/ThemedDialogWithSpinner";
import SuccessMessage from "../Shared/SuccessMessage";
import { PowerStandby } from "mdi-material-ui";
import { Theme, useMediaQuery } from "@material-ui/core";

const NewClientVersionDialog = () => {

    const rootStore = useContext(RootContext);
    const navigationStore = rootStore.navigationStore;

    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const onRefreshClicked = () => {
        window.location.reload();
    }

    return (
        <ThemedDialogWithSpinner
            state={navigationStore.appwideDialogStates.clientVersionOutOfDateDialog}
            DialogProps={{ fullScreen: xsDown }}
            primaryButtonProps={{ children: 'Refresh' }}
            onSubmit={onRefreshClicked}
        >
            <SuccessMessage
                icon={PowerStandby}
                title={'Time for a power up!'}
                details={(
                    <Fragment>
                        Save your work if needed. Then refresh the page for the latest updates.
                    </Fragment>
                )}
            />
        </ThemedDialogWithSpinner>
    )
}

export default NewClientVersionDialog;