import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";
import { StubUser } from "../../stores/models/StubUser";
import { UserType } from "../../stores";

const stubUserChecks: ValidationCheck<StubUser>[] = [{
    name: 'user type selected',
    condition: (stubUser: StubUser) => {
        return stubUser.type !== UserType.None;
    },
    error: new ValidationError('You must select a user type.')
}, {
    name: 'user type selected',
    condition: (stubUser: StubUser) => {
        return stubUser.termsOfServiceAccepted;
    },
    error: new ValidationError('Please review and accept the Terms of Service to continue.', ['termsOfServiceAccepted'])
}, {
    name: 'stub volunteer valid',
    condition: (stubUser: StubUser) => {
        return !stubUser.isVolunteer || (stubUser.stubVolunteer !== undefined && stubUser.stubVolunteer.validated);
    },
    error: new ValidationError('Volunteer fields are not valid.', ['stubVolunteer'])
}, {
    name: 'stub organization valid',
    condition: (stubUser: StubUser) => {
        return !stubUser.isOrganization || (stubUser.stubOrganization !== undefined && stubUser.stubOrganization.validated);
    },
    error: new ValidationError('Organization fields are not valid.', ['stubOrganization'])
}];

export function validateStubUser(stubUser: StubUser) {
    return getValidationErrors(stubUserChecks, stubUser);
}