import { observer } from 'mobx-react'
import { useEffect, useState } from 'react';
import { ConfirmationDialogState } from '../entities/ConfirmationDialogState';
import ConfirmationDialog, { ConfirmationDialogProps } from '../../../../components/Shared/ConfirmationDialog';
import useConfirmationDialogResult from '../hooks/useConfirmationDialogResult';

interface NewConfirmationDialogProps {
    state: ConfirmationDialogState;
    onConfirmed: () => void;
    onCancelled?: () => void;
    ConfirmationDialogProps: Omit<ConfirmationDialogProps, 'state' | 'onConfirm'>;
}

const NewConfirmationDialog = observer((props: NewConfirmationDialogProps) => {

    const [opened, setOpened] = useState(false);

    useConfirmationDialogResult({
        state: props.state,
        onConfirmed: props.onConfirmed,
        onCancelled: props.onCancelled
    });
    
    useEffect(() => {
        if (props.state.open) {
            setOpened(true);
        }
        if (opened && !props.state.open && props.state.confirmed === undefined) {
            props.state.setConfirmed(false);
        }
    }, [props.state.open]);

    const onConfirm = () => {
        props.state.setConfirmed(true);
    }

    /********* Render *********/

    return (
        <ConfirmationDialog
            {...props.ConfirmationDialogProps}
            state={props.state}
            onConfirm={onConfirm}
        />
    );
});

export default NewConfirmationDialog;