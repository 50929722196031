import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react'
import { Opportunity, Organization } from "../../stores";
import useAccountSettingsGridColumns from "./AccountSettingsGridColumnsHook";
import LoadingIndicator from "../Shared/LoadingIndicator";
import ZeroState from "../Shared/ZeroState";
import OpportunityGrid from "../Shared/OpportunityGrid";
import OrganizationGrid from "../Shared/OrganizationGrid";
import { Pagination } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        pagination: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
        flex: {
            display: 'flex',
            justifyContent: 'center',
        }
    })
);

const NUM_RESULTS_TO_FETCH = 30;

interface AccountSettingsCardGridProps<T extends Opportunity | Organization> {
    emptyContent: string | React.ReactNode;
    requestMethod: (limit: number, offset: number) => Promise<{ results: T[], total: number } | undefined>;
}

const AccountSettingsCardGrid = observer(<T extends Opportunity | Organization>(props: AccountSettingsCardGridProps<T>) => {

    /********* React hooks *********/
    const classes = useStyles();
    const numColumns = useAccountSettingsGridColumns();

    /********* State *********/
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([] as T[]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    /********* Effects *********/

    useEffect(() => {
        requestData();
    }, [page]);

    /********* Helper methods *********/

    const requestData = async () => {
        try {
            setIsLoading(true);
            const offset = (page - 1) * NUM_RESULTS_TO_FETCH;
            const response = await props.requestMethod(NUM_RESULTS_TO_FETCH, offset);
            if (response) {
                const { results, total } = response;
                setData(results);
                setTotal(total);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    /********* Event handler *********/

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    /********* Helper constants *********/

    const hasData = data !== undefined && data.length > 0;

    /********* Render *********/

    if (isLoading) {
        return <LoadingIndicator />
    } else {
        return (
            <div className={classes.root}>
                {!hasData
                    ? <ZeroState content={props.emptyContent} />
                    : data[0] instanceof Opportunity
                        ? <OpportunityGrid opportunities={data as Opportunity[]} idPrefix="opportunity" columns={numColumns} />
                        : <OrganizationGrid organizations={data as Organization[]} idPrefix="organization" columns={numColumns} />
                }
                {hasData
                    ? <div className={classes.flex}>
                        <Pagination
                            count={Math.ceil(total / NUM_RESULTS_TO_FETCH)}
                            page={page}
                            color="primary"
                            className={classes.pagination}
                            onChange={handlePageChange}
                        />
                    </div>
                    : null
                }
            </div>
        )
    }
});

export default AccountSettingsCardGrid;