import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useLinkStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        inline: {
            display: 'inline'
        }
    }),
);