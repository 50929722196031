import { makeStyles, Theme, createStyles, Typography, Button, Container } from "@material-ui/core";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { RootContext } from "../../stores";
import { observer } from "mobx-react";
import { useQuery } from "../Shared/Hooks/URLQuery";
import PaperSection from "../Shared/PaperSection";
import SuccessMessage from "../Shared/SuccessMessage";
import { ComponentState } from "../../stores/models/ComponentState";
import ErrorMessage from "../Shared/ErrorMessage";
import { Dictionary } from "../../logic/Dictionaries";
import PageWithFooter from "../Shared/PageWithFooter";
import { getAccountSettingsLink } from "../Navigation/Links";
import LoginForm from "./LoginForm";
import { EmailCheckOutline } from "mdi-material-ui";
import { useNavigateInternally } from "../Navigation/Hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loginForm: {
            maxWidth: '450px',
            margin: 'auto'
        },
        container: {
            marginTop: theme.spacing(2),
            maxWidth: '1000px'
        },
        loginPrompt: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        button: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2)
        },
        bold: {
            fontWeight: 'bold'
        },
        link: {
            marginTop: theme.spacing(-1),
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
    }),
);

const EMAIL_VERIFICATION_TOKEN_PARAMETER = 'token';

const EmailVerification = observer(() => {

    /********** React hooks **********/

    const query = useQuery();
    const emailVerificationToken = query.get(EMAIL_VERIFICATION_TOKEN_PARAMETER) || undefined;
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const navigationStore = rootStore.navigationStore;
    const navigate = useNavigateInternally();
    const errorCodeData = useMemo<Dictionary<number, { title: string, details: string | JSX.Element }>>(() => {
        return {
            10: {
                title: 'Uh oh! An error occurred during your request.',
                details: `Please refresh the page to try again.`
            },
            4000: {
                title: 'Uh oh! This link is invalid.',
                details: (
                    <Fragment>
                        <Typography>
                            Please make sure the link matches the one sent to your email, and check that you're logged into the account you're trying to verify.
                        </Typography>
                        <Typography>
                            You're currently logged in as: <span className={classes.bold}>{userStore.user.email}</span>
                        </Typography>
                    </Fragment>
                )
            },
        };
    }, [userStore.user.email]);


    /********** State **********/

    const [state] = useState(new ComponentState({ loading: false }));
    const [emailVerified, setEmailVerified] = useState(false);
    const [errorCode, setErrorCode] = useState<number>();

    /********** Effects **********/

    useEffect(() => {
        if (userStore.isAuthenticated) {
            attemptEmailVerification();
        }
    }, [userStore.isAuthenticated]);

    /********** API Requests **********/

    const attemptEmailVerification = async () => {
        if (emailVerificationToken === undefined) return;
        state.setLoading(true);
        const response = await userStore.submitEmailVerification(emailVerificationToken);
        if (response === undefined) {
            setErrorCode(10);
        } else if ('successful' in response) {
            setEmailVerified(true);
        } else {
            setErrorCode(response.error.code);
        }
        state.setLoading(false);
    }

    /********** Helper functions **********/

    const navigateToProfile = () => {
        navigate(getAccountSettingsLink());
    }

    const openContactUsDialog = () => {
        navigationStore.appwideDialogStates.contactUsDialog.setOpen(true);
        rootStore.helpStore.setHelpMessageOverrides({
            subject: 'Email Verification Issue'
        });
    }

    const logout = async () => {
        state.setLoading(true);
        await userStore.logout();
        setErrorCode(undefined);
        state.setLoading(false);
    }

    /********** Render **********/

    return (
        <PageWithFooter>
            <Container className={classes.container}>
                <PaperSection state={state}>
                    {!userStore.isAuthenticated
                        ? <Fragment>
                            <Typography variant='h4' className={classes.loginPrompt}>Please login in order to verify your email.</Typography>
                            <div className={classes.loginForm}>
                                <LoginForm />
                            </div>
                        </Fragment>
                        : emailVerified
                            ? <SuccessMessage
                                icon={EmailCheckOutline}
                                filled
                                details={
                                    <div>
                                        <Typography variant='h4'>Thank you for verifying your email!</Typography>
                                        <Button variant="contained" color='primary' className={classes.button} onClick={navigateToProfile}>
                                            Go to your profile
                                        </Button>
                                    </div>
                                }
                            />
                            : errorCode
                                ? <Fragment>
                                    <ErrorMessage
                                        title={errorCodeData[errorCode].title}
                                        details={errorCodeData[errorCode].details}
                                        ButtonProps={{
                                            children: errorCode === 10 ? "Need Help?" : "Switch Accounts",
                                            onClick: errorCode === 10 ? openContactUsDialog : logout
                                        }}
                                        footerDetails={errorCode === 4000
                                            ? <Typography variant="body2" className={classes.link} onClick={openContactUsDialog}>
                                                Need help?
                                            </Typography>
                                            : undefined
                                        }
                                    />
                                </Fragment>
                                : null
                    }
                </PaperSection>
            </Container>
        </PageWithFooter>
    );
});

export default EmailVerification;