

import { Typography, TypographyProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { FormInputItem } from "../../../../stores/models/FormInputItem";
import { FormTextItem } from "../../../../stores/models/FormSection";

interface ElementDescriptionProps extends TypographyProps {
    item: FormInputItem | FormTextItem;
}

const ElementDescription = observer((props: ElementDescriptionProps) => {
    return props.item.description !== undefined
        ? <Typography {...props}>{props.item.description}</Typography>
        : null;
});

export default ElementDescription;