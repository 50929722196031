import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { TextField, makeStyles, Theme, createStyles, Typography, MenuItem, Button } from "@material-ui/core";
import clsx from 'clsx';
import { observer } from "mobx-react";
import { DateFormatter } from "../../../logic/DateFormatter";
import { Alert, AlertTitle, AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
import { ServiceEntry } from "../data-access/entities/ServiceEntry";
import { Volunteer } from "../../../stores";
import { ServiceEntryTag } from "../../../stores/models/ServiceEntryTag";
import { TagPlus } from "mdi-material-ui";
import TextFieldWithCharacterLimit from "../../../components/Shared/TextFieldWithCharacterLimit";
import { SERVICE_ENTRY_NOTES_MAX_LENGTH } from "../../../logic/ValidationChecks/FieldLengths";
import VolunteersAutocomplete from "../../../components/AccountSettings/Pages/Organization/VolunteersAutocomplete";
import { Role } from "../../../stores/models/Role";
import ServiceEntryAuditTrail from "./ServiceEntryAuditTrail";
import { IOrganizationRoles } from "../data-access/interfaces/OrganizationRoles";
import { useRootStore } from "../../../shared/general/hooks";
import REMSelect from "../../../shared/modules/rem-conversion/components/Select";
import REMAutocomplete from "../../../shared/modules/rem-conversion/components/Autocomplete";
import REMChip from "../../../shared/modules/rem-conversion/components/Chip";
import REMKeyboardDatePicker from "../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formFields: {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                '&:not(:last-child)': {
                    marginBottom: theme.spacing(2),
                },
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    flexGrow: 1
                }
            }
        },
        fieldTitle: {
            fontWeight: 'bold'
        },
        splitRow: {
            display: 'flex',
            alignItems: 'flex-start',
            '& > :first-child': {
                marginRight: theme.spacing(2)
            },
            '& > *': {
                width: '50%'
            }
        },
        required: {
            color: 'red'
        },
        duration: {
            display: 'flex',
            alignItems: 'center',
            '& > :first-child': {
                marginRight: theme.spacing(1)
            },
        },
        roleTagButton: {
            textTransform: 'none',
        },
        volunteerChangesAlert: {
            flexDirection: 'row',
        },
        auditTrail: {
            '& > :not(:last-child)': {
                marginBottom: theme.spacing(2)
            }
        },
    })
);

const nonNumericalRegExp = /[^0-9.]/;

interface ServiceEntryFormProps extends React.HTMLAttributes<HTMLDivElement> {
    entryToEdit: ServiceEntry;
    serviceEntry: ServiceEntry;
    volunteerOrganizations?: IOrganizationRoles[];
    volunteer?: Volunteer;
    volunteerEntry?: boolean; // A volunteer is filling out the form
}

const ServiceEntryForm = observer((props: ServiceEntryFormProps) => {

    const serviceEntry = props.serviceEntry;
    const entryToEdit = props.entryToEdit;
    const classes = useStyles();
    const { volunteerHoursStore, userStore } = useRootStore();
    const organizationRoles = userStore.user.organization?.roles || [];
    const organizationTags = userStore.user.organization?.tags || [];

    /********** State *********/

    const [isNewServiceEntry] = useState(serviceEntry.volunteerId === undefined);
    const [isForParticularVolunteer] = useState(props.volunteer !== undefined);
    const [copyOfServiceEntry] = useState(new ServiceEntry(serviceEntry));

    const getInitialDurationText = () => {
        return (entryToEdit.duration / 3600).toString();
    }
    const [durationText, setDurationText] = useState(getInitialDurationText());

    /********** Memoized values *********/

    const selectedOrganization = useMemo(() => {
        if (entryToEdit.organizationId !== undefined) {
            return props.volunteerOrganizations?.find(volunteerOrganization => volunteerOrganization.id === entryToEdit.organizationId) || null;
        }
        return null;
    }, [entryToEdit.organizationId, props.volunteerOrganizations]);

    const volunteerRoleOptions = useMemo(() => {
        let roleOptions = [] as Role[];
        if (entryToEdit.organizationId) {
            roleOptions = selectedOrganization?.roles || [];
        } else {
            roleOptions = props.volunteerOrganizations?.flatMap(volunteerOrganization => volunteerOrganization.roles) || [];
        }
        if (entryToEdit.organizationId === copyOfServiceEntry.organizationId &&
            copyOfServiceEntry.opportunityId > 0 &&
            roleOptions.findIndex(role => role.id === copyOfServiceEntry.opportunityId) === -1
        ) {
            roleOptions = roleOptions.concat(new Role({ id: copyOfServiceEntry.opportunityId, position: copyOfServiceEntry.position, tagIds: [] }));
        }
        return roleOptions;
    }, [props.volunteerOrganizations, entryToEdit.organizationId, entryToEdit.opportunityId, entryToEdit.id]);

    const role = useMemo(() => {
        if (entryToEdit.opportunityId !== undefined) {
            let roleOptions = props.volunteerEntry ? volunteerRoleOptions : organizationRoles;
            return roleOptions.find(role => role.id === entryToEdit.opportunityId) || null;
        }
        return null;
    }, [organizationRoles, entryToEdit.opportunityId, volunteerRoleOptions]);

    /********** Effects *********/

    useEffect(() => {
        if (props.volunteer) {
            entryToEdit.setVolunteers([props.volunteer]);
        } else if (props.volunteerEntry) {
            entryToEdit.setVolunteerEntry(true);
        }
    }, []);

    useEffect(() => {
        if (props.volunteerEntry && entryToEdit.organizationId === undefined && role) {
            selectOrganizationMatchingSelectedRole();
        }
    }, [role, role?.id]);

    /********** Event Handlers *********/

    const onVolunteersChanged = (
        newVolunteers: Volunteer[],
    ) => {
        entryToEdit.setVolunteers(newVolunteers);
    }

    const onSelectedStatusChanged = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        if (event.target.value) {
            entryToEdit.setStatusId(event.target.value as number);
        }
    }

    const onDurationChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        const newValue = value.replace(nonNumericalRegExp, '');
        setDurationText(newValue);
        const parsedDuration = parseFloat(value);
        const newDuration = isNaN(parsedDuration) ? 0 : parsedDuration;
        entryToEdit.setDuration(newDuration);
    }

    const onNotesChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value !== undefined) {
            entryToEdit.setNotes(event.target.value);
        }
    }

    const onOrganizationChanged = (value: IOrganizationRoles | null) => {
        if (entryToEdit.organizationId !== value?.id) {
            entryToEdit.setOpportunityId(undefined);
        }
        entryToEdit.setOrganizationId(value?.id);
    }

    const onRoleChanged = (value: Role | null) => {
        entryToEdit.setOpportunityId(value?.id);
    }

    const selectOrganizationMatchingSelectedRole = () => {
        if (!role) return;
        const organizationForRole = props.volunteerOrganizations?.find(volunteerOrganization => volunteerOrganization.roles.findIndex(organizationRole => role.id === organizationRole.id) !== -1);
        if (organizationForRole) {
            entryToEdit.setOrganizationId(organizationForRole.id);
        }
    }

    const onAddRoleTags = () => {
        if (role?.tagIds) {
            const roleTags = organizationTags.filter(tag => role.tagIds.findIndex(tagId => tag.id === tagId) !== -1);
            entryToEdit.addTags(roleTags);
        }
    }

    const onTagsChanged = (
        event: React.ChangeEvent<{}>,
        value: (string | ServiceEntryTag)[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<ServiceEntryTag> | undefined
    ) => {
        switch (reason) {
            case 'remove-option':
                if (details) {
                    entryToEdit.removeTag(details.option);
                }
                break;
            case 'clear':
                entryToEdit.clearTags();
                break;
            case 'create-option':
                if (details) {
                    entryToEdit.createNewTag(details.option as unknown as string);
                }
                break;
            case 'select-option':
                if (details) {
                    entryToEdit.addTags([details.option]);
                }
                break;
            default:
                break;
        }
    }

    /********** Helper constants *********/

    const dateError = entryToEdit.errors.date || '';
    const durationError = entryToEdit.errors.duration || '';
    const notesError = entryToEdit.errors.notes || '';
    const volunteersError = entryToEdit.errors.volunteers || '';
    const organizationError = entryToEdit.errors.organizationId || '';

    /********** Render *********/

    return (
        <div className={clsx(classes.formFields, props.className)}>
            {(copyOfServiceEntry.lastOfficialEntry || copyOfServiceEntry.duration === 0) &&
                <div className={classes.auditTrail}>
                    <Alert severity="info" className={classes.volunteerChangesAlert}>
                        <AlertTitle>Entry {copyOfServiceEntry.duration === 0 ? 'deleted' : 'modified'} by volunteer</AlertTitle>
                        The volunteer has {copyOfServiceEntry.duration === 0 ? 'deleted' : 'edited'} this service entry. Review the changes below and make any necessary fixes.
                    </Alert>
                    <ServiceEntryAuditTrail
                        serviceEntry={copyOfServiceEntry}
                    />
                </div>
            }
            {props.volunteerEntry && props.volunteerOrganizations?.length === 0 &&
                <div>
                    <Alert severity="info">
                        <AlertTitle>You're not linked to any organizations yet.</AlertTitle>
                        When you sign up for a volunteer shift, submit a response for an opportunity, or contact the recruiter, you'll be automatically linked to the organization. At that point, you'll be able to log volunteer hours.
                    </Alert>
                </div>
            }
            {/* Volunteer(s) */}
            <div>
                {props.volunteerEntry
                    ? <React.Fragment>
                        <Typography className={classes.fieldTitle}>
                            Organization{entryToEdit.shiftId === null || entryToEdit.shiftId === undefined ? <span className={classes.required}> *</span> : undefined}
                        </Typography>
                        {entryToEdit.shiftId === null || entryToEdit.shiftId === undefined
                            ? <REMAutocomplete
                                id="organization-autocomplete"
                                options={props.volunteerOrganizations || []}
                                value={selectedOrganization}
                                getOptionLabel={(option) => option.organization}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        error={organizationError !== undefined && organizationError.length > 0}
                                        helperText={organizationError ? organizationError : ""}
                                    />
                                )}
                                onChange={(event, value) => onOrganizationChanged(value as IOrganizationRoles | null)}
                            />
                            : <Typography>{selectedOrganization?.organization || ''}</Typography>
                        }
                    </React.Fragment>
                    : !isNewServiceEntry || isForParticularVolunteer
                        ? <React.Fragment>
                            <Typography className={classes.fieldTitle}>Volunteer</Typography>
                            <Typography>{copyOfServiceEntry.volunteerName ? copyOfServiceEntry.volunteerName : props.volunteer?.fullName}</Typography>
                        </React.Fragment>
                        : <VolunteersAutocomplete
                            onVolunteersChanged={onVolunteersChanged}
                            error={volunteersError}
                        />
                }
            </div>
            {/* Role */}
            <div>
                <Typography className={classes.fieldTitle}>Role</Typography>
                {entryToEdit.shiftId === null || entryToEdit.shiftId === undefined
                    ? <REMAutocomplete
                        id="role-autocomplete"
                        options={props.volunteerEntry ? volunteerRoleOptions : organizationRoles}
                        value={role}
                        getOptionLabel={(option) => option.position}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <REMChip variant="outlined" label={option.position} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                            />
                        )}
                        onChange={(event, value) => onRoleChanged(value as Role | null)}
                    />
                    : <Typography>{entryToEdit.position}</Typography>
                }

                {entryToEdit.opportunityId > 0 && !props.volunteerEntry
                    ? <div>
                        <Button
                            onClick={onAddRoleTags}
                            color={'primary'}
                            className={classes.roleTagButton}
                            startIcon={<TagPlus />}
                        >
                            Add tags from role
                        </Button>
                    </div>
                    : null
                }
            </div>
            {/* Tags */}
            {!props.volunteerEntry &&
                <div>
                    <Typography className={classes.fieldTitle}>Tags</Typography>
                    {/* TODO: Consider consolidating with ServiceEntryTagAutocomplete.tsx */}
                    <REMAutocomplete
                        multiple
                        id="tags-filled"
                        options={organizationTags}
                        value={entryToEdit.tags}
                        getOptionLabel={(option) => option.tag}
                        freeSolo
                        filterSelectedOptions
                        getOptionSelected={(option, value) => {
                            return option.id === value.id || (value.id === -1 && option.tag === value.tag);
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <REMChip
                                    variant="outlined"
                                    label={option.tag ? option.tag : ''}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                            />
                        )}
                        onChange={onTagsChanged}
                    />
                </div>
            }
            {/* Date and Duration */}
            <div>
                <div className={classes.splitRow}>
                    <Typography className={classes.fieldTitle}>
                        Date<span className={classes.required}> *</span>
                    </Typography>
                    <Typography className={classes.fieldTitle}>
                        Duration<span className={classes.required}> *</span>
                    </Typography>
                </div>
                <div className={classes.splitRow}>
                    <REMKeyboardDatePicker
                        id="date-picker"
                        format="MM/dd/yy"
                        maxDate={new Date()}
                        value={entryToEdit.date
                            ? entryToEdit.date
                            : null
                        }
                        onChange={(date, value) => entryToEdit.setDate(date ? DateFormatter.getDateStringWithoutTimestamp(date) : undefined)}
                        variant="inline"
                        disableToolbar
                        autoOk
                        inputVariant="outlined"
                        required
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        error={dateError.length > 0}
                        helperText={dateError ? dateError : ""}
                    />
                    <div className={classes.duration}>
                        <TextField
                            variant="outlined"
                            required
                            value={durationText}
                            onChange={onDurationChanged}
                            error={durationError.length > 0}
                            helperText={durationError.length > 0 ? durationError : undefined}
                        />
                        <Typography>hours</Typography>
                    </div>
                </div>
            </div>
            {/* Status */}
            {!props.volunteerEntry &&
                <div>
                    <Typography className={classes.fieldTitle}>
                        Status<span className={classes.required}> *</span>
                    </Typography>
                    <REMSelect
                        value={entryToEdit.statusId}
                        variant={'outlined'}
                        onChange={onSelectedStatusChanged}
                    >
                        {volunteerHoursStore.serviceEntryStatuses.map(status => {
                            return (
                                <MenuItem value={status.id} key={status.id}>{status.status}</MenuItem>
                            )
                        })}
                    </REMSelect>
                </div>
            }
            {/* Notes */}
            {!props.volunteerEntry &&
                <div>
                    <Typography className={classes.fieldTitle}>Notes</Typography>
                    <TextFieldWithCharacterLimit
                        characterLimit={SERVICE_ENTRY_NOTES_MAX_LENGTH}
                        TextFieldProps={{
                            onChange: onNotesChanged,
                            value: entryToEdit.notes,
                            error: notesError.length > 0,
                            helperText: notesError.length > 0 ? notesError : undefined,
                            multiline: true,
                            variant: 'outlined',
                            maxRows: 6
                        }}
                    />
                </div>
            }
        </div>
    )
});

export default ServiceEntryForm;