import { observable, makeObservable } from 'mobx';
import { IServerOrganization, ServerOrganization } from './ServerOrganization';
import { IUser, User } from '../User';
import { ServerVolunteer } from './ServerVolunteer';

export interface IServerUser {
    id: number;
    email: string;
    active: boolean;
    requiresSubscription: boolean;
    registrationTimestamp: number;
    profileData?: ServerVolunteer | ServerOrganization;
}

export class ServerUser implements IServerUser {
    id: number;
    email: string;
    active: boolean;
    requiresSubscription: boolean;
    registrationTimestamp: number;
    profileData?: ServerVolunteer | ServerOrganization;

    constructor(clientOrServerObject: IUser | IServerUser) {
        makeObservable(this, {
            id: observable,
            email: observable,
            active: observable,
            requiresSubscription: observable,
            registrationTimestamp: observable,
            profileData: observable
        });

        this.id = clientOrServerObject.id;
        this.email = clientOrServerObject.email;
        this.active = clientOrServerObject.active;
        this.requiresSubscription = clientOrServerObject.requiresSubscription;
        this.registrationTimestamp = clientOrServerObject.registrationTimestamp;
        if (clientOrServerObject.profileData !== undefined) {
            if ('firstName' in clientOrServerObject.profileData) {
                this.profileData = new ServerVolunteer(clientOrServerObject.profileData);
            } else {
                this.profileData = new ServerOrganization(clientOrServerObject.profileData as IServerOrganization);
            }
        }
    }

    deserialize(): User {
        const userData: IUser = {
            id: this.id,
            email: this.email,
            active: this.active,
            requiresSubscription: this.requiresSubscription,
            registrationTimestamp: this.registrationTimestamp,
            profileData: this.profileData?.deserialize()
        }
        return new User(userData);
    }
}