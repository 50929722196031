import React, { useContext } from "react";
import { TextField, makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { RootContext, Volunteer } from "../../../../stores";
import REMAutocomplete from "../../../../shared/modules/rem-conversion/components/Autocomplete";
import REMChip from "../../../../shared/modules/rem-conversion/components/Chip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fieldTitle: {
            fontWeight: 'bold'
        },
        required: {
            color: 'red'
        },
    })
);

interface VolunteersAutocompleteProps extends React.HTMLAttributes<HTMLDivElement> {
    error?: string;
    autocompleteClassName?: string;
    autocompleteId?: string;
    disabledOptions?: number[];
    onVolunteersChanged: (volunteers: Volunteer[]) => void;
}

const VolunteersAutocomplete = observer((props: VolunteersAutocompleteProps) => {

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const organizationVolunteers = rootStore.userStore.user.organization?.volunteers || [];

    /********** Render *********/

    return (
        <React.Fragment>
            <Typography className={classes.fieldTitle}>
                Volunteer(s)<span className={classes.required}> *</span>
            </Typography>
            <REMAutocomplete
                multiple
                id={props.autocompleteId}
                options={organizationVolunteers}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                getOptionDisabled={(option) => {
                    return props.disabledOptions !== undefined &&
                        props.disabledOptions.findIndex(disableVolunteerId => disableVolunteerId === option.id) !== -1;
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <REMChip
                            variant="outlined"
                            label={`${option.firstName} ${option.lastName}`}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        error={props.error !== undefined && props.error.length > 0}
                        helperText={props.error ? props.error : ""}
                    />
                )}
                onChange={(event, value) => props.onVolunteersChanged(value as Volunteer[])}
                className={props.autocompleteClassName}
            />
        </React.Fragment>
    )
});

export default VolunteersAutocomplete;