import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        required: {
            color: 'red'
        },
        header: {
            marginBottom: 5
        },
        title: {
            fontWeight: 600
        }
    }));

interface OpportunityFieldHeaderProps {
    title: string;
    instructions?: string;
    example?: string;
    required?: boolean;
}

const FieldHeader = (props: OpportunityFieldHeaderProps) => {

    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Typography variant="h6" className={classes.title}>
                {props.title}
                {props.required ? <span className={classes.required}> *</span> : null}
            </Typography>
            {props.instructions
                ? <Typography variant="body2">
                    {props.instructions}
                </Typography>
                : null
            }
            {props.example
                ? <Typography variant="body2" color="textSecondary">
                    {props.example}
                </Typography>
                : null
            }
        </div>
    )
}

export default FieldHeader;