import { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles, Button, Avatar, Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { observer } from "mobx-react";
import useResetScrollPosition from "../Shared/Hooks/ResetScrollPosition";
import { mdiConnection } from '@mdi/js';
import MdiIcon from "../Shared/MdiIcon";
import { useQuery } from "../Shared/Hooks/URLQuery";
import { RootContext } from "../../stores";
import { OptionCollection } from "../../stores/models/OptionCollection";
import { EmailCategory } from "../../stores/models/EmailCategory";
import { Alert, AlertTitle } from "@material-ui/lab";
import LoadingIndicator from "../Shared/LoadingIndicator";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingWrapper: {
            display: 'flex',
            flexGrow: 1,
            margin: theme.spacing(15),
        },
        alert: {
            textAlign: 'left',
            marginBottom: theme.spacing(3)
        },
        title: {
            marginBottom: theme.spacing(3),
        },
        instructions: {
            marginBottom: theme.spacing(2)
        },
        button: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '75px',
            height: '75px',
            margin: '16px auto'
        },
        link: {
            color: theme.palette.primary.dark,
            textDecoration: 'underline',
            cursor: 'pointer'
        },
    })
);

const EMAIL_PREFERENCES_TOKEN_PARAMETER = 'token';

interface EmailPreferencesSelectionProps {
    onPreferencesUpdated: () => void;
    onError: () => void;
    onContactUs: () => void;
}

const EmailPreferencesSelection = observer((props: EmailPreferencesSelectionProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;
    const query = useQuery();
    const token = query.get(EMAIL_PREFERENCES_TOKEN_PARAMETER) || undefined;

    useResetScrollPosition();

    /***** State *****/

    const [emailPreferences, setEmailPreferences] = useState(new OptionCollection('id', [] as EmailCategory[]));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    /***** Effects *****/

    useEffect(() => {
        requestEmailPreferences();
    }, [token]);

    /***** API Requests *****/

    const requestEmailPreferences = async () => {
        if (token) {
            const response = await userStore.getEmailPreferences(token);
            let categories = [] as EmailCategory[];
            let selectedCategories = [] as EmailCategory[];
            if (response?.emailPreferences) {
                response.emailPreferences.forEach(emailPreference => {
                    const category = { id: emailPreference.id, category: emailPreference.category };
                    categories.push(new EmailCategory(category));
                    if (emailPreference.subscribed) {
                        selectedCategories.push(new EmailCategory(category));
                    }
                })
            } else {
                props.onError();
            }
            setEmailPreferences(new OptionCollection('id', categories, selectedCategories));
            setLoading(false);
        }
    }

    const updateEmailPreferences = async () => {
        if (token) {
            const selectedCategoryIds = emailPreferences.selectedOptions.map(selection => selection.id);
            setLoading(true);
            const response = await userStore.updateEmailPreferences(token, selectedCategoryIds);
            if (response?.successful) {
                setError(false);
                props.onPreferencesUpdated();
            } else {
                setError(true);
            }
            setLoading(false);
        }
    }

    /***** Event handlers *****/

    const handleChange = (identifierValue: number) => {
        emailPreferences.getOptionByIdentifier(identifierValue)?.toggleSelection();
    }

    /***** Render *****/

    if (loading) {
        return (
            <div className={classes.loadingWrapper}>
                <LoadingIndicator />
            </div>
        );
    }
    return (
        <Fragment>
            {error &&
                <Alert severity="error" className={classes.alert}>
                    <AlertTitle>Error adjusting your email preferences.</AlertTitle>
                    Please try again. If the error persists, please <a onClick={props.onContactUs} className={classes.link}>Contact Us.</a>
                </Alert>
            }
            <Avatar className={classes.avatar}>
                <MdiIcon iconPath={mdiConnection} fontSize='large' />
            </Avatar>
            <h1 className={classes.title}>
                Need to adjust your connection?
            </h1>
            <Typography variant="h6" className={classes.instructions}>
                Select the types of emails you'd like to receive:
            </Typography>
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={emailPreferences.isOptionWithIdentifierSelected(1)}
                                onChange={() => handleChange(1)}
                                name="General News"
                                color="primary"
                            />
                        }
                        label="General news and updates"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={emailPreferences.isOptionWithIdentifierSelected(2)}
                                onChange={() => handleChange(2)}
                                name="New Posts"
                                color="primary"
                            />
                        }
                        label="New recruitment posts"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={emailPreferences.isOptionWithIdentifierSelected(3)}
                                onChange={() => handleChange(3)}
                                name="Urgent Needs"
                                color="primary"
                            />
                        }
                        label="Urgent volunteer needs"
                    />
                </FormGroup>
            </FormControl>
            <div>
                <Button color="primary" variant="contained" className={classes.button} size="large" onClick={updateEmailPreferences}>
                    Update Preferences
                </Button>
            </div>
        </Fragment>
    )
});

export default EmailPreferencesSelection;