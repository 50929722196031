import {
    observable,
    computed,
    action,
    IReactionDisposer,
    reaction,
    override,
    makeObservable,
} from "mobx";
import { Fields } from "./Fields";
import { validateAddress } from "../../logic/ValidationChecks";
import { IServerAddress, ServerAddress } from "./server/ServerAddress";

import { OptionCollection, StringIdentifiedOptionCollection } from "./OptionCollection";
import { locationAccessibilityOptions, locationCheckboxFilterOptions, LocationCheckboxOptions, SearchOption } from "../../components/Search/FilterBar/FilterOptions";
import { IOptionObject } from "./Option";

export interface IAddressFields {
    lineOne: string;
    lineTwo?: string;
    city: string;
    state: string;
    zipCode: string;
    name?: string;
    wheelchairAccessible: boolean;
    publicTransportation: boolean;
}

type AccessibilityOptionCollection = StringIdentifiedOptionCollection<SearchOption<any>>;

export type StringAddressField = Exclude<keyof IAddressFields, 'wheelchairAccessible' | 'publicTransportation'>;

export interface IAddress extends IAddressFields {
    id?: number;
    accessibilityOptions: AccessibilityOptionCollection;
}

export class Address extends Fields<IAddressFields, Address> implements IAddress {
    id = -1;
    lineOne = "";
    lineTwo = "";
    city = "";
    state = "";
    zipCode = "";
    name = "";
    wheelchairAccessible = false;
    publicTransportation = false;

    private accessibilityOptionsChanged: IReactionDisposer | undefined;

    constructor(idOrAddress?: IAddress | IServerAddress | number) {
        super();

        makeObservable(this, {
            id: observable,
            lineOne: observable,
            lineTwo: observable,
            city: observable,
            state: observable,
            zipCode: observable,
            name: observable,
            wheelchairAccessible: observable,
            publicTransportation: observable,
            setField: action,
            addressString: computed,
            selectedFilters: computed,
            accessibilityOptions: computed,
            updateAccessibilityData: action.bound,
            validationErrors: override
        });

        if (idOrAddress) {
            if (typeof idOrAddress === 'number') {
                this.id = idOrAddress;
            } else {
                this.id = idOrAddress.id || -1;
                this.lineOne = idOrAddress.lineOne;
                this.lineTwo = idOrAddress.lineTwo || "";
                this.city = idOrAddress.city;
                this.state = idOrAddress.state;
                this.zipCode = idOrAddress.zipCode.toString();
                this.name = idOrAddress.name || "";
                this.wheelchairAccessible = idOrAddress.wheelchairAccessible;
                this.publicTransportation = idOrAddress.publicTransportation;
            }
        }
        this.accessibilityOptionsChanged = reaction(
            () => [
                this.accessibilityOptions.selections
            ],
            () => {
                this.updateAccessibilityData();
            }
        );
    }

    setField(field: StringAddressField, newValue: string) {
        this[field] = field === 'state' ? newValue.toUpperCase() : newValue;
    }

    get validationErrors() {
        return validateAddress(this);
    }

    get addressString() {
        const lineTwoText = this.trimmed.lineTwo.length > 0 ? `, ${this.trimmed.lineTwo}` : '';
        return `${this.trimmed.lineOne}${lineTwoText}, ${this.trimmed.city}, ${this.trimmed.state} ${this.trimmed.zipCode}`;
    }

    get selectedFilters(): IOptionObject[] {
        let selected = [] as IOptionObject[];
        if (this.wheelchairAccessible) {
            selected.push({ value: LocationCheckboxOptions.WheelchairAccessible });
        }
        if (this.publicTransportation) {
            selected.push({ value: LocationCheckboxOptions.PublicTransportation });
        }
        return selected;
    }

    get accessibilityOptions() {
        return new OptionCollection('value', locationAccessibilityOptions, this.selectedFilters);
    }
    
    serialize() {
        return new ServerAddress(this.trimmed);
    }

    /***** Private methods *****/

    updateAccessibilityData() {
        this.wheelchairAccessible = this.accessibilityOptions.isOptionSelected(LocationCheckboxOptions.WheelchairAccessible);
        this.publicTransportation = this.accessibilityOptions.isOptionSelected(LocationCheckboxOptions.PublicTransportation);
    }
}