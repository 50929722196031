import React from "react";
import { observer } from "mobx-react";
import { OrganizationStubVolunteerLinking } from "../../../../stores/models/OrganizationStubVolunteerLinking";
import { StepProps } from "../../../../stores/models/Step";
import PreferenceSaved from "../PreferenceSaved";

const PreferenceSavedStep = observer((props: StepProps<OrganizationStubVolunteerLinking>) => {

    /***** Render *****/

    return (
        <PreferenceSaved
            organizationName={props.stepState.stepObject.linkDetails.organizationName}
        />
    );
});

export default PreferenceSavedStep;