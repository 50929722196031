import React from "react";
import { makeStyles, Theme, createStyles, Paper, Popper, Grow, ClickAwayListener, MenuList, MenuItem } from "@material-ui/core";
import { observer } from "mobx-react";
import { ShiftEditingOptions } from "../../../../../stores/models/ShiftDetailsModification";
import { DialogState } from "../../../../../stores/models/DialogState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: theme.zIndex.modal - 1
        }
    })
);

interface ShiftSeriesDropdownProps {
    state: DialogState;
    anchor: React.RefObject<HTMLButtonElement>;
    onOptionSelected: (option: ShiftEditingOptions) => void;
}

const ShiftSeriesDropdown = observer((props: ShiftSeriesDropdownProps) => {

    /********* React hooks *********/

    const classes = useStyles();

    /********* Event handlers *********/

    const onEditMenuClose = (event: React.MouseEvent<Document | HTMLLIElement, MouseEvent>) => {
        if (props.anchor.current && props.anchor.current.contains(event.target as Node)) {
            return;
        }

        props.state.setOpen(false);
    };

    const onEditOptionSelected = (option: ShiftEditingOptions) => {
        props.state.setOpen(false);
        props.onOptionSelected(option);
    }

    const onListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            props.state.setOpen(false);
        }
    }

    /********* Render *********/

    return (
        <Popper
            open={props.state.open}
            anchorEl={props.anchor.current}
            role={undefined}
            transition
            disablePortal
            className={classes.popper}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={onEditMenuClose}>
                            <MenuList autoFocusItem={props.state.open} id="menu-list-grow" onKeyDown={onListKeyDown}>
                                <MenuItem onClick={() => onEditOptionSelected(ShiftEditingOptions.ThisShift)}>This shift</MenuItem>
                                <MenuItem onClick={() => onEditOptionSelected(ShiftEditingOptions.ThisAndFollowing)}>This and all following shifts</MenuItem>
                                <MenuItem onClick={() => onEditOptionSelected(ShiftEditingOptions.EntireSeries)}>All shifts in the series</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
});

export default ShiftSeriesDropdown;