import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles, SvgIconProps, useMediaQuery } from "@material-ui/core";
import AddressForm from "./AddressForm";
import { Address } from "../../stores/models/Address";
import Map from './Map';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertTitle } from "@material-ui/lab";
import { observer } from "mobx-react";
import ThemedDialogWithSpinner from "./Dialogs/ThemedDialogWithSpinner";
import { DialogState } from "../../stores/models/DialogState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        address: {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                flexWrap: 'wrap',
                '& > *:first-child': {
                    marginBottom: theme.spacing(2),
                    flexGrow: 1
                }
            },
            '& > *:last-child': {
                overflowX: 'auto',
                minHeight: '300px'
            }
        },
        addressForm: {
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(2)
            }
        },
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                background: theme.palette.error.main,
                color: theme.palette.error.contrastText,
            }
        },
        alert: {
            marginBottom: theme.spacing(2)
        },
    })
);

interface AddressDialogProps {
    state: DialogState;
    title: string;
    address: Address;
    confirmText: string;
    confirmIcon?: (props: SvgIconProps) => JSX.Element;
    displayRemoveButton: boolean; // TODO: Remove and implement address removal wherever an address dialog appears.
    onConfirm: (address: Address) => void;
    onRemove?: (address: Address) => void;
}

const AddressDialog = observer((props: AddressDialogProps) => {

    /********* State *********/

    const getAddress = () => {
        return new Address(props.address);
    }

    const [address, setAddress] = useState(getAddress());
    const [mapAddress, setMapAddress] = useState(getAddress());
    const [title, setTitle] = useState(props.title);

    /********* React hooks *********/

    const classes = useStyles();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    /********* Event handlers *********/

    const handleConfirm = () => {
        if (address.validated) {
            props.onConfirm(address);
        } else {
            address.setAllFieldsDirty();
        }
    }

    /********* Effects *********/

    // Reset the dialog when it is first opened
    useEffect(() => {
        if (props.state.open) {
            resetDialog();
        }
    }, [props.state.open, props.address]);

    useEffect(() => {
        // timeoutId for debouncing
        let timeoutId: NodeJS.Timeout | undefined = undefined;
        // prevent execution of previous setTimeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // update the map after timeout
        timeoutId = setTimeout(() => {
            setMapAddress(new Address(address))
        }, 2000);

        return function cleanUp() {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [address.lineOne, address.lineTwo, address.city, address.state, address.zipCode]);

    /********* Helper methods *********/

    const resetDialog = () => {
        setAddress(getAddress());
        setMapAddress(getAddress());
        setTitle(props.title);
    };

    /********* Render *********/

    return (
        <ThemedDialogWithSpinner
            DialogProps={{ maxWidth: 'md', fullScreen: smDown }}
            state={props.state}
            title={title}
            primaryButtonProps={{ children: props.confirmText, startIcon: props.confirmIcon ? React.createElement(props.confirmIcon) : undefined }}
            extraButtonProps={
                address.id !== -1 && props.displayRemoveButton
                    ? [{
                        children: 'Remove',
                        variant: 'contained',
                        className: classes.removeButton,
                        onClick: (props.onRemove !== undefined) ? () => props.onRemove!(address) : undefined,
                        startIcon: <DeleteIcon />
                    }]
                    : undefined
            }
            onSubmit={handleConfirm}
        >
            {props.address.id < 0
                ? null
                : <Alert severity="warning" className={classes.alert}>
                    <AlertTitle>Edits are applied globally.</AlertTitle>
                    Any edits to this location will be automatically applied across your entire organization, including any opportunities that list it.
                </Alert>
            }
            <div className={classes.address}>
                <AddressForm
                    address={address}
                    className={classes.addressForm}
                />
                <Map address={mapAddress} />
            </div>
        </ThemedDialogWithSpinner >
    )
});

export default AddressDialog;