import { UserType } from "../../stores";
import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { ValidationError } from "../../stores/models/ValidationError";
import { getValidationErrors } from "./Validation";

const userTypeChecks: ValidationCheck<UserType>[] = [{
    name: 'user type selected',
    condition: (userType: UserType) => {
        return userType !== UserType.None;
    },
    error: new ValidationError('You must select a user type.')
}];

export default function validateUserType(userType: UserType) {
    return getValidationErrors(userTypeChecks, userType);
}