import { CauseStore } from "./CauseStore"
import { OpportunityStore } from "./OpportunityStore";
import { OrganizationStore } from "./OrganizationStore";
import { SearchStore } from "./SearchStore";
import { SkillCategoryStore } from "./SkillCategoryStore";
import { VolunteerStore } from "./VolunteerStore";
import { createContext } from "react";
import { action, makeAutoObservable, reaction } from "mobx";
import { UserStore } from "./UserStore";
import { RegistrationStore } from "./RegistrationStore";
import { NavigationStore } from "./NavigationStore";
import { MapStore } from "./MapStore";
import { PaymentProcessingStore } from "./PaymentProcessingStore";
import { LocationStore } from "./LocationStore";
import { AutocompleteStore } from "./AutocompleteStore";
import { HelpStore } from "./HelpStore";
import { ShiftStore } from "./ShiftStore";
import { RequestQueue } from "./models";
import { SupplementalDataStore } from "./SupplementalDataStore";
import { APIError } from "./models/APIError";
import { FormStore } from "./FormStore";
import { ImageStore } from "./ImageStore";
import { VolunteerHoursStore } from "../modules/volunteer-hours/data-access/stores/VolunteerHoursStore";
import { IDisposer } from "mobx-utils";

export class RootStore {
    causeStore: CauseStore;
    opportunityStore: OpportunityStore;
    organizationStore: OrganizationStore;
    registrationStore: RegistrationStore;
    searchStore: SearchStore;
    skillCategoryStore: SkillCategoryStore;
    locationStore: LocationStore;
    userStore: UserStore;
    volunteerStore: VolunteerStore;
    navigationStore: NavigationStore;
    mapStore: MapStore;
    autocompleteStore: AutocompleteStore;
    paymentProcessingStore: PaymentProcessingStore;
    helpStore: HelpStore;
    shiftStore: ShiftStore;
    supplementalDataStore: SupplementalDataStore;
    formStore: FormStore;
    imageStore: ImageStore;
    volunteerHoursStore: VolunteerHoursStore;

    requestQueue: RequestQueue;
    clientHasUpdates: boolean = false;
    apiError?: APIError; 

    private onLogoutReaction?: IDisposer;

    constructor() {
        makeAutoObservable(this);

        this.requestQueue = new RequestQueue(this);

        this.causeStore = new CauseStore(this);
        this.opportunityStore = new OpportunityStore(this);
        this.organizationStore = new OrganizationStore(this);
        this.registrationStore = new RegistrationStore(this);
        this.skillCategoryStore = new SkillCategoryStore(this);
        this.searchStore = new SearchStore(this);
        this.locationStore = new LocationStore(this);
        this.userStore = new UserStore(this);
        this.volunteerStore = new VolunteerStore(this);
        this.navigationStore = new NavigationStore(this);
        this.mapStore = new MapStore(this);
        this.autocompleteStore = new AutocompleteStore(this);
        this.paymentProcessingStore = new PaymentProcessingStore(this);
        this.helpStore = new HelpStore(this);
        this.shiftStore = new ShiftStore(this);
        this.supplementalDataStore = new SupplementalDataStore(this);
        this.formStore = new FormStore(this);
        this.imageStore = new ImageStore(this);
        this.volunteerHoursStore = new VolunteerHoursStore(this);

        this.onLogoutReaction = reaction(
            () => [
                this.userStore.isAuthenticated
            ],
            () => {
                if (!this.userStore.isAuthenticated) {
                    this.volunteerHoursStore = new VolunteerHoursStore(this);
                }
            }
        );
    }

    @action setClientHasUpdates(clientHasUpdates: boolean) {
        this.clientHasUpdates = clientHasUpdates;
    }

    @action setAPIError(apiError?: APIError) {
        this.apiError = apiError;
    }
}

export const RootContext = createContext(new RootStore());