import { Fragment, useState } from "react";
import { Shift } from "../../../stores/models/Shift";
import {
    makeStyles,
    Theme,
    createStyles,
    Typography,
    Card,
    CardHeader,
    CardContent,
    useMediaQuery,
    CardActions,
    Button,
} from "@material-ui/core";
import { Delete, Autorenew, MapMarker, CalendarClock, Calendar, ChevronDoubleDown, ChevronDoubleUp, ClipboardEditOutline, ClipboardText, Lock, KeyChainVariant, Pencil, AccountMultiple, CardAccountDetailsOutline } from "mdi-material-ui";
import { observer } from "mobx-react";
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import clsx from 'clsx';
import EditButton from "../../Shared/EditButton";
import { RepetitionEndType } from "../../../stores/models/RepetitionEnding";
import ShiftList from "./ShiftList";
import REMAvatar from "../../../shared/modules/rem-conversion/components/Avatar";
import { ShiftLabels } from "../../../data/temporary/ShiftLabels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            display: 'flex'
        },
        shiftDetails: {
            flexGrow: 1,
            '& $row:not(:last-child) $icon': {
                marginBottom: 4
            }
        },
        icon: {
            marginRight: theme.spacing(1),
            color: theme.palette.action.active,
        },
        leftContent: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '-6px'
        },
        content: {
            marginTop: '-6px'
        },
        dateOfMonth: {
            fontWeight: 'bold'
        },
        expired: {
            color: theme.palette.text.secondary,
            background: 'rgba(255,0,0,.08) !important',
        },
        card: {
            width: '100%',
            background: 'rgba(0,0,0,.04)',
            border: `1px solid ${theme.palette.action.disabledBackground}`
        },
        cardContent: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        signUpWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexGrow: 1,
            flexShrink: 0,
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            '& button': {
                padding: '8px 24px'
            }
        },
        avatar: {
            [theme.breakpoints.down('sm')]: {
                width: '1.875rem',
                height: '1.875rem'
            }
        },
        repeats: {
            background: theme.palette.success.light
        },
        oneTime: {
            background: theme.palette.primary.light
        },
        button: {
            color: theme.palette.action.active
        },
        cardActions: {
            justifyContent: 'flex-end'
        },
        link: {
            color: theme.palette.primary.dark,
            textDecoration: 'underline',
            cursor: 'pointer'
        },
        unregisterButton: {
            color: theme.palette.action.active
        },
        signUpExplanationRow: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: theme.spacing(1),
            '& > svg': {
                color: theme.palette.error.light
            }
        },
        signUpExplanation: {
            fontStyle: 'italic',
            textAlign: 'right',
            marginRight: theme.spacing(.5)
        }
    })
);

export enum ShiftAction {
    Edit,
    Inactivate, // TODO: Add functionality for this action
    Delete,
    SignUp,
    EditRegistration
}

interface ShiftCardProps {
    shift: Shift;
    editable?: boolean;
    permissionNeeded?: boolean;
    assignmentOnly?: boolean;
    showSignUpOption?: boolean;
    displaySignUpAtBottom?: boolean;
    registered?: boolean;
    opportunityData?: {
        id: number;
        position: string;
        onClick?: () => void;
    }
    onShiftActionSelected?: (shift: Shift, action: ShiftAction) => void;
}

const ShiftCard = observer((props: ShiftCardProps) => {

    const classes = useStyles();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const [expanded, setExpanded] = useState(false);

    const onToggleExpanded = () => {
        setExpanded(!expanded);
    }

    const shift = props.shift;
    const formattedStartDate = shift.repetitionPattern.formattedStartDate;
    const repetitionDescription = shift.repetitionPattern.repetitionDescription;
    const expired = shift.repetitionPattern.hasEnded;
    const repeats = shift.repetitionPattern.repeats;
    const shiftLabel = ShiftLabels[shift.id];

    const signUpOrUnregisterButton = props.showSignUpOption && props.onShiftActionSelected
        ? props.registered || shift.registered || shift.registeredProxyVolunteerIds.length > 0
            ? !expired &&
            <Button
                variant='outlined'
                startIcon={<Pencil />}
                onClick={() => props.onShiftActionSelected!(shift, ShiftAction.EditRegistration)}
                className={classes.unregisterButton}
                size={xsDown ? 'small' : 'medium'}
            >
                Edit Registration
            </Button>
            : (shift.slotsLeft > 0 || shift.unlimitedSlots) &&
            <Button
                variant={'contained'}
                color='primary'
                startIcon={<ClipboardEditOutline />}
                onClick={() => props.onShiftActionSelected!(shift, ShiftAction.SignUp)}
                size={xsDown ? 'small' : 'medium'}
            >
                Sign Up
            </Button>
        : null;

    return (
        <Card elevation={0} className={clsx(classes.card, expired && props.editable ? classes.expired : undefined)}>
            <CardHeader
                avatar={
                    <REMAvatar className={clsx(classes.avatar, expired && props.editable ? classes.expired : repeats ? classes.repeats : classes.oneTime)}>
                        {repeats
                            ? <Autorenew fontSize={smDown ? "small" : "medium"} />
                            : <CalendarClock fontSize={smDown ? "small" : "medium"} />}
                    </REMAvatar>
                }
                title={
                    <Typography variant="subtitle1" className={classes.dateOfMonth}>
                        {repeats
                            ? repetitionDescription
                            : `${shift.repetitionPattern.firstOccurrenceDayOfWeek}, ${formattedStartDate}`
                        }
                        {expired && props.editable ? ` (Expired)` : undefined}
                    </Typography>
                }
                subheader={shift.repetitionPattern.formattedTime}
            />
            <CardContent className={classes.cardContent}>
                <div className={classes.shiftDetails}>
                    {shiftLabel
                        ? <div className={classes.row}>
                            <CardAccountDetailsOutline className={classes.icon} />
                            <Typography variant="body2">
                                {`Role: ${shiftLabel}`}
                            </Typography>
                        </div>
                        : null
                    }
                    <div className={classes.row}>
                        <HourglassEmptyIcon className={classes.icon} />
                        <Typography variant="body2">
                            {`Duration: ${shift.repetitionPattern.formattedDuration}`}
                        </Typography>
                    </div>
                    {shift.address
                        ? <div className={classes.row}>
                            <MapMarker className={classes.icon} />
                            <Typography variant="body2">{shift.address.addressString}</Typography>
                        </div>
                        : null
                    }
                    <div className={classes.row}>
                        <AccountMultiple className={classes.icon} />
                        <Typography variant="body2">
                            {props.editable
                                ? `Slots: ${shift.unlimitedSlots ? 'Unlimited' : shift.slots}`
                                : `Slots Remaining: ${shift.unlimitedSlots ? 'Unlimited' : shift.slotsLeft}`
                            }
                        </Typography>
                    </div>
                    {props.opportunityData &&
                        <div className={classes.row}>
                            <ClipboardText className={classes.icon} />
                            <Typography variant="body2">
                                {`Opportunity: `}
                                <a onClick={props.opportunityData.onClick} className={classes.link}>
                                    {props.opportunityData.position}
                                </a>
                            </Typography>
                        </div>
                    }
                    {repeats
                        ? <Fragment>
                            <div className={classes.row}>
                                <Calendar className={classes.icon} />
                                <Typography variant="body2">
                                    {`Next Occurrence: ${shift.formattedNextOccurrenceDate}`}
                                </Typography>
                            </div>
                            {shift.repetitionPattern.ending.endType !== RepetitionEndType.Never
                                ? <div className={classes.row}>
                                    <SyncDisabledIcon className={classes.icon} />
                                    <Typography variant="body2">
                                        {`Final Occurrence: ${shift.repetitionPattern.formattedFinalOccurrence} (${shift.occurrencesLeft} remaining)`}
                                    </Typography>
                                </div>
                                : null
                            }
                        </Fragment>
                        : null
                    }
                    {repeats
                        ? <Button
                            className={classes.button}
                            startIcon={expanded ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
                            onClick={() => onToggleExpanded()}
                        >
                            {expanded
                                ? 'Collapse Occurrences'
                                : 'Expand Occurrences'
                            }
                        </Button>
                        : null
                    }
                    {repeats && expanded
                        ? <ShiftList {...props} records={shift.instances} />
                        : null
                    }
                    {props.assignmentOnly
                        ? <div className={classes.signUpExplanationRow}>
                            <Typography className={classes.signUpExplanation} variant="body2">
                                The recruiter must assign you to this shift.
                            </Typography>
                            <Lock />
                        </div>
                        : props.permissionNeeded &&
                        <div className={classes.signUpExplanationRow}>
                            <Typography className={classes.signUpExplanation} variant="body2">
                                Permission required to sign up for shifts.
                            </Typography>
                            <KeyChainVariant />
                        </div>
                    }
                </div>
                {(!props.displaySignUpAtBottom && signUpOrUnregisterButton) &&
                    <div className={classes.signUpWrapper}>
                        {signUpOrUnregisterButton}
                    </div>
                }
            </CardContent>
            {props.onShiftActionSelected &&
                <Fragment>
                    {props.editable
                        ? <CardActions className={classes.cardActions}>
                            <EditButton
                                onClick={() => props.onShiftActionSelected!(shift, ShiftAction.Edit)}
                                showText
                                ButtonProps={{ className: classes.button }}
                            />
                            <Button
                                className={classes.button}
                                startIcon={<Delete />}
                                onClick={() => props.onShiftActionSelected!(shift, ShiftAction.Delete)}
                            >
                                Delete
                            </Button>
                        </CardActions>
                        : props.displaySignUpAtBottom &&
                        <CardActions className={classes.cardActions}>
                            {signUpOrUnregisterButton}
                        </CardActions>
                    }
                </Fragment>
            }
        </Card >
    )
});

export default ShiftCard;