import { IconName } from "./Icon";
import { observable, makeAutoObservable, computed, action } from "mobx";

export enum MenuOption {
    Volunteers,
    Search,
    LogHours,
    AboutOrganization,
    RecruiterSignUp,
    PublicPage,
    Account,
    Login,
    SignUp,
    Logout,
    Dashboard,
    ProfileInformation,
    Locations,
    Settings,
    CustomPortal,
    VolunteerShifts,
    VolunteerServiceHistory,
    OrganizationSparks,
    OpportunitySparks,
    HandsRaised,
    Subscription,
    Recruitment,
    Data,
    Reporting,
    PostOpportunity,
    Scheduling,
    OrganizationOpportunities,
    OrganizationAccount,
    VolunteerAccount,
    Sparks,
    Volunteering,
    Organization,
    ContactUs,
    OrganizationHelp,
    VolunteerHelp,
    Support,
    Authentication,
    ServiceData,
    UpdateClient
}

export interface IMenuItem {
    text: string;
    shortenedText?: string;
    option: MenuOption;
    icon?: IconName | string;
    linkPath?: string;
    queryParameters?: string;
    onClick?: () => void;
    subItems?: IMenuItem[];
    urlRegex?: RegExp;
    badge?: JSX.Element;
    active?: boolean;
}

export class MenuItem implements IMenuItem {
    @observable text = "";
    @observable shortenedText?: string | undefined;
    @observable option: MenuOption;
    @observable icon?: IconName | string;
    @observable linkPath?: string;
    @observable queryParameters?: string;
    @observable onClick?: () => void;
    @observable subItems?: MenuItem[];
    @observable urlRegex?: RegExp;
    @observable badge?: JSX.Element;
    @observable active: boolean;

    constructor(menuItem: IMenuItem) {
        makeAutoObservable(this);

        this.text = menuItem.text;
        this.shortenedText = menuItem.shortenedText;
        this.icon = menuItem.icon;
        this.option = menuItem.option;
        this.linkPath = menuItem.linkPath;
        this.queryParameters = menuItem.queryParameters;
        this.onClick = menuItem.onClick;
        this.subItems = menuItem.subItems?.map(subItem => { return new MenuItem(subItem); }) || [];
        this.urlRegex = menuItem.urlRegex;
        this.badge = menuItem.badge;
        this.active = menuItem.active || false;
    }

    @computed get fullLink() {
        if (!this.linkPath) return;
        if (this.queryParameters) {
            return this.linkPath + this.queryParameters;
        } else {
            return this.linkPath;
        }
    }

    @action setActive(active: boolean) {
        this.active = active;
    }
}