import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { Opportunity, RequestQueue } from './models';
import { IRequestConfig } from './models/RequestQueue';
import { ServerOpportunity, IServerOpportunity } from './models/server/ServerOpportunity';
import { getSupplementalDataRequest, SupplementalOrganizationDataRequest } from './models/SupplementalOrganizationData';

enum OpportunityRequestType {
    GetInformation = 'get-opp-information',
    PostOpportunity = 'post-opp',
    UpdateInformation = 'update-opp-information',
    DeleteOpportunity = 'delete-opp'
}

type OpportunityResponse = { opportunity: IServerOpportunity, warnings: Array<{ code: number }> };

export class OpportunityStore {

    private rootStore = {} as RootStore;
    private requestQueue: RequestQueue;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
        this.requestQueue = new RequestQueue(rootStore);
    }

    /*************** Get opportunity details ***************/

    async getOppInfo(organizationId: number, opportunityId: number) {
        try {
            if (isNaN(organizationId) || isNaN(opportunityId)) {
                throw ('Invalid organization id or opportunity id');
            } else {
                let supplementalData: SupplementalOrganizationDataRequest | undefined;
                const isOwnerOfOpportunity = organizationId === this.rootStore.userStore.user.organization?.id;
                if (isOwnerOfOpportunity) {
                    supplementalData = getSupplementalDataRequest(
                        ['locations', 'serviceEntryTags']
                    );
                }

                const data = supplementalData ? { supplementalData: supplementalData } : undefined;

                const response = await this.prepareRequest<OpportunityResponse>(
                    OpportunityRequestType.GetInformation,
                    organizationId,
                    opportunityId,
                    data
                );
                return new ServerOpportunity(response.opportunity).deserialize();
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Confirm opportunity edits ***************/

    confirmEdits(organizationId: number, opportunity: Opportunity) {
        if (opportunity.id < 0) {
            return this.addOrUpdateOpportunity(organizationId, opportunity, OpportunityRequestType.PostOpportunity);
        } else {
            return this.addOrUpdateOpportunity(organizationId, opportunity, OpportunityRequestType.UpdateInformation);
        }
    }

    /*************** Post or update opportunity information ***************/

    private async addOrUpdateOpportunity(organizationId: number, opportunity: Opportunity, reqType: OpportunityRequestType) {
        opportunity.setAllFieldsDirty();
        if (opportunity.validated) {
            const response = await this.prepareRequest<OpportunityResponse>(
                reqType,
                organizationId,
                opportunity.id,
                {
                    organization: {
                        id: organizationId
                    },
                    opportunity: opportunity.serialize()
                }
            );
            return {
                ...response,
                opportunity: new ServerOpportunity(response.opportunity).deserialize()
            }
        } else {
            return opportunity.validationErrors;
        }
    }

    /*************** Delete opportunity ***************/

    async deleteOpportunity(opportunityId: number) {
        try {
            const organizationId = this.rootStore.userStore.user.organization?.id;
            if (organizationId) {
                const response = await this.prepareRequest<OpportunityResponse>(
                    OpportunityRequestType.DeleteOpportunity,
                    organizationId,
                    opportunityId,
                );
                if (response.opportunity) {
                    return { success: true };
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    /*************** Private request handling methods ***************/

    private prepareRequest<T>(
        type: OpportunityRequestType,
        orgId: number,
        oppId: number,
        data?: any
    ) {
        return new Promise<T>(async (resolve, reject) => {
            try {
                const requestConfig = this.generateRequestConfig(type, orgId, oppId, data);
                this.verifyRequest(orgId, oppId, requestConfig);
                const responseData = await this.requestQueue.makeAPIRequest<T>(requestConfig);
                resolve(responseData);
            } catch (error) {
                reject(error);
            }
        });
    }

    private generateRequestConfig(
        type: OpportunityRequestType,
        orgId: number,
        oppId: number,
        data: any
    ): IRequestConfig {
        const apiEndpoint = '/api/opportunities';
        switch (type) {
            case OpportunityRequestType.GetInformation:
                return {
                    method: 'get',
                    url: `${apiEndpoint}/organization/${orgId}/opportunity/${oppId}`,
                    forceRefresh: true,
                    params: data
                };
            case OpportunityRequestType.PostOpportunity:
                return {
                    method: 'post',
                    url: `${apiEndpoint}/add`,
                    data: data
                };
            case OpportunityRequestType.UpdateInformation:
                return {
                    method: 'put',
                    url: `${apiEndpoint}/update`,
                    data: data
                };
            case OpportunityRequestType.DeleteOpportunity:
                return {
                    method: 'delete',
                    url: `${apiEndpoint}/delete/${oppId}`
                }
        }
    }

    private verifyRequest(orgId: number, oppId: number, config: IRequestConfig) {
        this.rootStore.organizationStore.verifyRequestForOrganization(orgId, config);
        const isPostRequest = (config.method === 'post');
        if (oppId < 1 && !isPostRequest) {
            throw new Error('Invalid opportunity ID.');
        }
    }
}

