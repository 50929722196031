import { useState } from "react";
import { Typography, Paper, Container, makeStyles, Theme, createStyles, IconButton, TableCell } from "@material-ui/core";
import { observer } from "mobx-react";
import { EditorState } from "../../../../../stores/models/EditorState";
import Editor from "../../../Editor";
import FieldSection from "../FieldSection";
import { ReportData, ReportNames } from "../../../../../stores/models/ReportData";
import { Download } from "mdi-material-ui";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FormLoadingSpinner from "../../../../Organization/VolunteerOpportunities/FormLoadingSpinner";
import { useNavigateInternally } from "../../../../Navigation/Hooks";
import { ReportNameSlugs, getReportingLink } from "../../../../Navigation/Links";
import { useParams } from "react-router-dom";
import { NavigateInternally } from "../../../../Navigation/Components";
import { Option } from "../../../../../stores/models/Option";
import DisplayTable from "../DisplayTable";
import { TableHeader } from "../../../../../stores/models/TableHeader";
import ReportRangeSelection from "./ReportRangeSelection";
import { TableSortingState } from "../../../../../stores/models/TableSortingState";
import { Sorter } from "../../../../../logic/Sorter";
import REMChip from "../../../../../shared/modules/rem-conversion/components/Chip";
import REMButton from "../../../../../shared/modules/rem-conversion/components/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            overflow: 'hidden',
            '&:not(:first-child)': {
                marginTop: theme.spacing(2)
            }
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        title: {
            wordBreak: 'normal',
            textOverflow: 'ellipsis',
            overflowX: 'hidden'
        },
        bold: {
            fontWeight: 'bold'
        },
        mobileBreadcrumbs: {
            marginBottom: theme.spacing(2),
            display: 'flex'
        },
        backButton: {
            padding: 0
        },
        fullWidth: {
            width: '100%'
        },
        downloadButton: {
            height: `calc(100% - 1.5rem - ${theme.spacing(1)}px)`,
            minHeight: theme.spacing(7),
            alignSelf: 'end',
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
            flexShrink: 0
        },
        flex: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        buttonWrapper: {
            display: 'flex',
            flexShrink: 0,
            flexDirection: 'column',
            justifyContent: 'end'
        },
    }),
);

interface ReportPageProps {
    reportData: ReportData | undefined;
    startDate: Date | null;
    endDate: Date | null;
    rangeError: string;
    loading: boolean;
    sortState: TableSortingState<string>;
    onStartDateChanged: (date: Date | null) => void;
    onEndDateChanged: (date: Date | null) => void;
    onDownload: (report: ReportNames) => void;
}

const ReportPage = observer((props: ReportPageProps) => {

    const { reportData, startDate, endDate, rangeError, loading, sortState, onStartDateChanged, onEndDateChanged, onDownload } = props;

    /********* React hooks *********/

    const classes = useStyles();
    const navigate = useNavigateInternally();
    const { reportName } = useParams() as { reportName: string };
    const reportType = ReportData.reportNameByUrlSlug[reportName as ReportNameSlugs];

    /********* State *********/

    const [tableHeaderCells] = useState(() => ReportData.headerCellsByReportType[reportType].map(headCell => new TableHeader(headCell)));
    const [displayFunctions] = useState(() => tableHeaderCells.map((header) => {
        return (
            (option: Option<any>, rowIndex: number, cellIndex: number) => {
                return (
                    <TableCell align={header.alignment} key={`table-cell-${rowIndex}-${cellIndex}`}>
                        {option.object[header.id]}
                    </TableCell>
                );
            }
        );
    }))

    /********* Event Handlers *********/

    const returnToReportingSummaryPage = () => {
        navigate(getReportingLink());
    }

    /********* Render *********/

    if (!reportType) {
        return <NavigateInternally to={getReportingLink()} replace />;
    }

    return (
        <div className={classes.fullWidth}>
            {/* Breadcrumbs */}
            <Container>
                <div className={classes.mobileBreadcrumbs}>
                    <IconButton className={classes.backButton} onClick={returnToReportingSummaryPage}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <REMChip label={`Back to Reporting`} onClick={returnToReportingSummaryPage} />
                </div>
            </Container>
            <Editor editsMade={false}>
                {(editorState: EditorState) => (
                    <Container>
                        <Paper className={classes.paper}>
                            <Container>
                                {/* {shouldDisplayFormError(editorState) ? <FormError /> : null} */}
                                <FieldSection>
                                    <div className={classes.header}>
                                        <Typography variant="h4" className={classes.title}>
                                            {ReportData.reportTitleByType[reportType]}
                                        </Typography>
                                    </div>
                                </FieldSection>
                                <FieldSection>
                                    {reportData?.summaryStatsByReport[reportType].map(summaryStats => {
                                        return <Typography><span className={classes.bold}>{summaryStats.label}:</span> {summaryStats.data || "-"}</Typography>
                                    })}
                                </FieldSection>
                                <div className={classes.flex}>
                                    <div className={classes.buttonWrapper}>
                                        <REMButton
                                            variant="contained"
                                            color="primary"
                                            onClick={() => reportData ? onDownload(reportType) : undefined}
                                            startIcon={<Download />}
                                            className={classes.downloadButton}
                                        >
                                            Download
                                        </REMButton>
                                    </div>
                                    {ReportData.rangeSpecificReports.findIndex(report => report === reportType) !== -1
                                        ? <ReportRangeSelection
                                            startDate={startDate}
                                            endDate={endDate}
                                            rangeError={rangeError}
                                            onStartDateChanged={onStartDateChanged}
                                            onEndDateChanged={onEndDateChanged}
                                        />
                                        : undefined
                                    }
                                </div>
                                <DisplayTable
                                    records={reportData ? Sorter.getSortedObjects(reportData.reportDataObjectsDefaultSorting[reportType] as any[], JSON.parse(sortState.stringifiedSortConfiguration)) : []}
                                    tableHeaderCells={tableHeaderCells}
                                    displayFunctions={displayFunctions}
                                    serverSorted
                                    sortState={sortState}
                                />
                            </Container>
                        </Paper>
                        {loading ? <FormLoadingSpinner /> : null}
                    </Container >
                )}
            </Editor >
        </div>
    )
});

export default ReportPage;