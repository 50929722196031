import { makeAutoObservable } from "mobx";
import { FormInputItem, IFormInputItem, isInputItem } from "./FormInputItem";

export enum FormItemTypes {
    ShortAnswer = 'short-answer',
    MultipleChoice = 'multiple-choice',
    Checkbox = 'checkbox',
    Text = 'text',
    Image = 'image'
}

export type FormTextItem = { title: string, description?: string, type: FormItemTypes.Text };
export type FormImageItem = { url: string, type: FormItemTypes.Image };
export type FormItem = FormInputItem | FormTextItem | FormImageItem;
export type IFormItem = IFormInputItem | FormTextItem | FormImageItem;

export interface IFormSection {
    title: string;
    items: IFormItem[];
}

export class FormSection implements IFormSection {
    title: string;
    items: FormItem[];

    constructor(section: IFormSection) {
        makeAutoObservable(this);

        this.title = section.title;
        this.items = section.items.map(item => {
            if (isInputItem(item)) {
                return new FormInputItem(item);
            }
            return item as FormItem;
        });
    }
}