import { ChangeEvent, Fragment, HTMLAttributes, useContext } from "react";
import { makeStyles, Theme, createStyles, Typography, MenuItem } from "@material-ui/core";
import clsx from 'clsx';
import { observer } from "mobx-react";
import { RootContext } from "../../../../../stores";
import TextFieldWithCharacterLimit from "../../../../Shared/TextFieldWithCharacterLimit";
import { VOLUNTEER_NAME_MAX_LENGTH } from "../../../../../logic/ValidationChecks/FieldLengths";
import FormError from "../../../../Organization/VolunteerOpportunities/FormError";
import { StubOrganizationVolunteer } from "../../../../../stores/models/StubOrganizationVolunteer";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import EmailInUseWarningField from "./EmailInUseWarningField";
import { Alert, AlertTitle } from "@material-ui/lab";
import REMSelect from "../../../../../shared/modules/rem-conversion/components/Select";
import REMKeyboardDatePicker from "../../../../../shared/modules/rem-conversion/components/KeyboardDatePicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formFields: {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                '&:not(:last-child)': {
                    marginBottom: theme.spacing(2),
                },
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    flexGrow: 1
                }
            }
        },
        fieldTitle: {
            fontWeight: 'bold'
        },
        required: {
            color: 'red'
        },
        warningIcon: {
            color: theme.palette.warning.main
        },
        checkIcon: {
            color: theme.palette.primary.main
        },
        alert: {
            marginBottom: theme.spacing(1)
        },
        emailInvitationAlert: {
            marginBottom: theme.spacing(2)
        },
        bold: {
            fontWeight: 'bold'
        }
    })
);

interface AddVolunteerFormProps extends HTMLAttributes<HTMLDivElement> {
    volunteerUser: StubOrganizationVolunteer;
    error?: boolean;
}

const AddVolunteerForm = observer((props: AddVolunteerFormProps) => {

    const volunteerUser = props.volunteerUser;
    const classes = useStyles();
    const rootStore = useContext(RootContext);
    const organizationVolunteerStatuses = rootStore.userStore.user.organization?.volunteerStatuses || [];

    /********** Event Handlers *********/

    const onFirstNameChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value !== undefined) {
            volunteerUser.setFirstName(event.target.value);
        }
    }

    const onLastNameChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value !== undefined) {
            volunteerUser.setLastName(event.target.value);
        }
    }

    const onEmailChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value !== undefined) {
            volunteerUser.setEmail(event.target.value);
        }
    }

    const onBirthdateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        volunteerUser.setBirthdate(date ? date.toUTCString() : undefined);
    }

    const onSelectedStatusChanged = (event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        volunteerUser.setStatusId(event.target.value as number);
    }

    /********** Render *********/

    return (
        <Fragment>
            <Alert severity="info" className={classes.emailInvitationAlert}>
                <AlertTitle>Email Invitation</AlertTitle>
                Including an email address will send an invitation to the volunteer to link to your organization, unless the volunteer is added with a "Banned" status.
            </Alert>
            <div className={clsx(classes.formFields, props.className)}>
                {props.error &&
                    <div>
                        <FormError />
                    </div>
                }
                <div>
                    <Typography className={classes.fieldTitle}>
                        First Name<span className={classes.required}> *</span>
                    </Typography>
                    <TextFieldWithCharacterLimit
                        characterLimit={VOLUNTEER_NAME_MAX_LENGTH}
                        TextFieldProps={{
                            onChange: onFirstNameChanged,
                            value: volunteerUser.firstName,
                            error: volunteerUser.isFieldInvalid('firstName'),
                            helperText: volunteerUser.getErrorForField('firstName'),
                            variant: 'outlined',
                            autoFocus: true
                        }}
                    />
                </div>
                <div>
                    <Typography className={classes.fieldTitle}>
                        Last Name<span className={classes.required}> *</span>
                    </Typography>
                    <TextFieldWithCharacterLimit
                        characterLimit={VOLUNTEER_NAME_MAX_LENGTH}
                        TextFieldProps={{
                            onChange: onLastNameChanged,
                            value: volunteerUser.lastName,
                            error: volunteerUser.isFieldInvalid('lastName'),
                            helperText: volunteerUser.getErrorForField('lastName'),
                            variant: 'outlined'
                        }}
                    />
                </div>
                <div>
                    <Typography className={classes.fieldTitle}>
                        Email
                    </Typography>
                    <EmailInUseWarningField
                        email={volunteerUser.email}
                        error={volunteerUser.getErrorForField('email')}
                        onEmailChanged={onEmailChanged}
                    />
                </div>
                {/* Birthdate */}
                <div>
                    <Typography className={classes.fieldTitle}>
                        Birthdate
                    </Typography>
                    <REMKeyboardDatePicker
                        id="birthdate-picker"
                        format="MM/dd/yyyy"
                        minDate={new Date(1900, 1, 1)}
                        value={volunteerUser.birthdate ? volunteerUser.birthdate : null}
                        onChange={onBirthdateChange}
                        inputVariant="outlined"
                        required
                        disableFuture
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        error={volunteerUser.isFieldInvalid('birthdate')}
                        helperText={volunteerUser.getErrorForField('birthdate')}
                    />
                </div>
                {/* Status */}
                <div>
                    <Typography className={classes.fieldTitle}>
                        Status<span className={classes.required}> *</span>
                    </Typography>
                    <REMSelect
                        value={volunteerUser.statusId}
                        variant={'outlined'}
                        onChange={onSelectedStatusChanged}
                    >
                        {organizationVolunteerStatuses.map(status => {
                            return (
                                <MenuItem value={status.id} key={status.id}>{status.status}</MenuItem>
                            )
                        })}
                    </REMSelect>
                </div>
            </div>
        </Fragment>
    )
});

export default AddVolunteerForm;