import { computed, makeObservable, observable } from "mobx";
import { Dictionary } from "../../logic/Dictionaries";
import { Fields } from "./Fields";
import { FormInputItem, IFormInputItem, isInputItem } from "./FormInputItem";
import { FormSection, IFormSection } from "./FormSection";

export interface IForm {
    version: number;
    title: string;
    description?: string;
    sections: IFormSection[];
}

export class Form extends Fields<IFormInputItem, FormInputItem> implements IForm {
    @observable version: number;
    @observable title: string;
    @observable description?: string;
    @observable sections: FormSection[];

    constructor(form: IForm) {
        super();
        makeObservable(this);

        this.version = form.version;
        this.title = form.title;
        this.description = form.description;
        this.sections = form.sections.map(section => new FormSection(section));
    }

    setAllFieldsDirty() {
        this.inputFields.forEach(inputField => inputField.setAllFieldsDirty());
    }

    @computed get formData() {
        const data = { version: this.version.toString() } as Dictionary<string, string | string[]>;
        this.inputFields.forEach(inputField => {
            if (inputField.value !== undefined) {
                data[inputField.id] = inputField.value
            }
            if (inputField.otherSelected && inputField.otherValue?.trim().length !== 0) {
                if (data[inputField.id] !== undefined && Array.isArray(data[inputField.id])) {
                    data[inputField.id] = (data[inputField.id] as string[]).concat('__other_option__');
                } else {
                    data[inputField.id] = ['__other_option__'];
                }
                data[`${inputField.id}.other_option_response`] = inputField.otherValue || '';
            }
        })
        return data;
    }

    @computed get validationErrors() {
        return this.inputFields.flatMap(inputField => inputField.validationErrors);
    }

    @computed private get inputFields() {
        return this.sections.flatMap(section => section.items).filter(item => isInputItem(item)) as FormInputItem[];
    }
}