import { useCallback, useState } from 'react';
// import { InjectionToken } from 'tsyringe'
import { IAction } from '../interfaces/Action';
import { useAction } from './useAction';

export const useActionLoadable = <Param>(action: IAction<Param>) => {
    // export const useActionLoadable = <Param>(action: InjectionToken<IAction<Param>>) => {
    const [isLoading, setLoading] = useState(false);
    const execute = useAction(action);

    const handleExecute = useCallback(
        async (param: Param) => {
            try {
                setLoading(true)
                await execute(param)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                throw error
            }
        }, [execute]);

    return [handleExecute, isLoading] as const;
}