import { IStatus } from "./Status";

type CategoryListKeys = 'serviceEntryStatuses';

export type CategoryListRequest = { [key in CategoryListKeys]?: boolean };

type CategoryListResponse = {
    serviceEntryStatuses: IStatus[];
}

export type ICategoryLists = { categoryLists: CategoryListResponse };

export const isCategoryListData = (x: any): x is ICategoryLists => {
    if (typeof x === 'object') {
        return ('categoryLists' in x);
    } else {
        return false;
    }
}

export const getCategoryListRequest = (dataToLoad: CategoryListKeys[]) => {
    let dataRequest = {} as CategoryListRequest;
    dataToLoad.forEach(key => {
        dataRequest[key] = true;
    });

    return dataRequest;
}