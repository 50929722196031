import { makeAutoObservable, observable } from "mobx";
import { FilterState } from "./FilterState";
import { IOrderByPair, OrderByPair } from "./OrderByPair";

export interface IAPIGetRequestParameters<T> {
    limit: number;
    offset: number;
    sort: IOrderByPair<T>[];
    filterState?: FilterState;
}

export class APIGetRequestParameters<T> implements IAPIGetRequestParameters<T> {
    @observable limit: number;
    @observable offset: number;
    @observable sort: OrderByPair<T>[] = [];
    @observable filterState?: FilterState | undefined;
    
    constructor(parameters: IAPIGetRequestParameters<T>) {
        makeAutoObservable(this);
        this.limit = parameters.limit;
        this.offset = parameters.offset;
        this.sort = parameters.sort.map(sortPair => new OrderByPair(sortPair));
        this.filterState = parameters.filterState;
    }

    serialize() {
        const copyOfThis = { ...this };
        delete copyOfThis.filterState;
        return {
            ...copyOfThis,
            sort: this.sort.map(sortPair => sortPair.serialize()).join(','),
            ...(this.filterState?.serialize())
        }
    }
}