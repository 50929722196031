import { Volunteer } from "../../stores/models/Volunteer";
import { ValidationCheck } from "../../stores/models/ValidationCheck";
import { getValidationErrors, getFieldRequiredValidationCheck, getMaxLengthValidationCheck, getBirthdateValidationCheck } from "./Validation";
import { VOLUNTEER_NAME_MAX_LENGTH } from "./FieldLengths";

const volunteerDemographicChecks =
    (birthdateRequired?: boolean, isCurrentUser?: boolean): (volunteer: Volunteer) => ValidationCheck<Volunteer>[] => {
        return (volunteer: Volunteer) => [
            getFieldRequiredValidationCheck(volunteer.firstName, 'firstName', 'Your first name is required.'),
            getMaxLengthValidationCheck(volunteer.firstName, 'firstName', VOLUNTEER_NAME_MAX_LENGTH),
            getFieldRequiredValidationCheck(volunteer.lastName, 'lastName', 'Your last name is required.'),
            getMaxLengthValidationCheck(volunteer.lastName, 'lastName', VOLUNTEER_NAME_MAX_LENGTH),
            getBirthdateValidationCheck(
                volunteer.birthdate && volunteer.birthdate.trim().length > 0 ? new Date(volunteer.birthdate) : null,
                'birthdate',
                birthdateRequired,
                isCurrentUser
            )
        ];
    }

export default function validateVolunteerAsCurrentUser(volunteer: Volunteer) {
    return getValidationErrors(volunteerDemographicChecks(true, true), volunteer);
}

export function validateVolunteerAsOrganizationUser(volunteer: Volunteer) {
    return getValidationErrors(volunteerDemographicChecks(false, false), volunteer);
}