import { makeStyles, Theme, createStyles, Typography, useMediaQuery, Avatar, Card, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { DialogState } from "../../../../../stores/models/DialogState";
import { LevelOnePlan, LevelTwoPlan, LevelThreePlan, FeatureHeaders, Feature, REMOTE_OPPORTUNITY_TOOLTIP } from "../../../../Authentication/RegistrationSteps/Organization/Plans";
import { ArrowLeft, ArrowRight, CheckBold } from "mdi-material-ui";
import clsx from 'clsx';
import InformationTooltip from "../../../../Authentication/RegistrationSteps/Organization/InformationTooltip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.lightBackground.main,
        },
        content: {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3)
            },
            display: 'flex',
            justifyContent: 'center'
        },
        arrows: {
            position: 'absolute',
            left: '-20px',
            right: '-20px',
            top: 100,
            [theme.breakpoints.up('sm')]: {
                top: 170,
                left: -25,
                right: -25
            },
            display: 'flex',
            justifyContent: 'space-between',
            '&$animated > $avatar': {
                animation: '$emphasizeArrow 1100ms 1',
            }
        },
        planCardWrapper: {
            position: 'relative'
        },
        planCard: {
            textAlign: 'center',
            position: 'relative',
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2),
        },
        elevatedCard: {
            background: theme.palette.common.white,
        },
        flatCard: {
            background: 'rgba(0,0,0,0.04)',
            border: `1px solid ${theme.palette.action.disabledBackground}`
        },
        planName: {
            fontWeight: 700,
            marginBottom: '4px',
        },
        price: {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
        planHeader: {
            marginBottom: theme.spacing(2)
        },
        features: {
            textAlign: 'left'
        },
        feature: {
            display: 'flex',
            marginBottom: '4px',
            color: theme.palette.text.secondary
        },
        cityFeature: {
            color: theme.palette.text.secondary,
            textAlign: 'center',
            '& span': {
                fontWeight: 'bold',
                [theme.breakpoints.down('xs')]: {
                    display: 'block'
                }
            },
            '&::after': {
                content: '"+"',
                fontWeight: 'bold',
                fontSize: '1.5em',
                display: 'inline',
                color: theme.palette.primary.main
            },
        },
        remotePostsFeature: {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
            justifyContent: 'center',
            alignItems: 'center',
            '& > p': {
                fontWeight: 'bold',
                display: 'inline',
            },
            '& span': {
                [theme.breakpoints.down('xs')]: {
                    display: 'block'
                }
            },
        },
        textAndIconWrapper: {
            display: 'inline-block',
            position: 'relative',
        },
        infoIcon: {
            position: 'absolute',
            right: -28,
            bottom: '10%'
        },
        check: {
            color: theme.palette.success.main,
            marginRight: theme.spacing(1)
        },
        avatar: {
            background: theme.palette.primary.main,
        },
        animated: {},
        hidden: {
            visibility: 'hidden'
        },
        currentPlanBanner: {
            position: 'absolute',
            background: theme.palette.accent.main,
            color: theme.palette.primary.contrastText,
            transform: 'rotate(-31deg)',
            padding: 4,
            left: -24,
            top: 12,
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(7),
            '& > p': {
                fontWeight: 'bold'
            },
            [theme.breakpoints.down(400)]: {
                transform: 'rotate(-90deg)',
                top: '25px',
                left: '-34px',
                paddingLeft: theme.spacing(2),
                '&:before': {
                    transform: 'rotate(90deg)',
                    content: '""',
                    position: 'absolute',
                    left: '-24px',
                    bottom: '8px',
                    borderLeft: `16px solid ${theme.palette.accent.main}`,
                    borderRight: `16px solid ${theme.palette.accent.main}`,
                    borderBottom: `16px solid transparent`,
                }
            },
        },
        selectButton: {
            marginTop: theme.spacing(1)
        },
        '@keyframes emphasizeArrow': {
            '0%': {
                marginRight: 10,
                marginLeft: 10
            },
            '25%': {
                marginRight: -10,
                marginLeft: -10
            },
            '50%': {
                marginRight: 10,
                marginLeft: 10
            },
            '75%': {
                marginRight: -10,
                marginLeft: -10
            },
            '100%': {
                marginRight: 0,
                marginLeft: 0
            }
        },
    }),
);

interface SubscriptionPlanCardsProps {
    planLevel?: number;
    currentPlanLevel?: number;
    hideSelectButton?: boolean;
    onPlanSelected?: (planName: string) => void;
    onPlanLevelChanged?: (planLevel: number) => void;
    elevated?: boolean;
}

const SubscriptionPlanCards = observer((props: SubscriptionPlanCardsProps) => {

    /***** React hooks *****/

    const classes = useStyles();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    /***** Helper method *****/

    const getStartingPlanIndex = () => {
        return props.planLevel === undefined || props.planLevel === -1 ? 0 : props.planLevel - 1;
    }

    /***** State *****/

    const [planIndex, setPlanIndex] = useState(getStartingPlanIndex());
    const [animationRun, setAnimationRun] = useState(false);
    const [expiredSessionDialogState] = useState(new DialogState());

    /***** Effect *****/

    useEffect(() => {
        if (props.onPlanLevelChanged) {
            props.onPlanLevelChanged(planIndex + 1);
        }
    }, [planIndex]);

    /***** Event handlers *****/

    const advancePlanIndex = () => {
        setAnimationRun(true);
        setPlanIndex(planIndex + 1);
    }

    const previousPlanIndex = () => {
        setAnimationRun(true);
        setPlanIndex(planIndex - 1);
    }

    /***** Helper methods *****/

    const handlePlanSelected = (planName: string) => {
        if (props.onPlanSelected) {
            props.onPlanSelected(planName);
        }
    }

    const getPlanLevel = () => {
        return planIndex + 1;
    }

    const newSelectionMatchesCurrentPlan = () => {
        return props.currentPlanLevel === getPlanLevel();
    }

    /***** Helper constant *****/

    const plans = [LevelOnePlan, LevelTwoPlan, LevelThreePlan];

    /***** Render *****/

    return (
        <div className={classes.content}>
            {plans.map((plan, planCardIndex) => {
                return (
                    planCardIndex === planIndex ?
                        <div className={smDown ? classes.planCardWrapper : undefined} key={`plan-card-${planCardIndex}`}>
                            <Card
                                className={clsx(classes.planCard, props.elevated ? classes.elevatedCard : classes.flatCard)}
                                elevation={props.elevated ? 24 : 0}
                            >
                                {newSelectionMatchesCurrentPlan()
                                    ? <div className={classes.currentPlanBanner}>
                                        <Typography>Current Plan</Typography>
                                    </div>
                                    : null
                                }
                                <div className={classes.planHeader}>
                                    <Typography variant="h5" className={classes.planName}>{plan.name}</Typography>
                                    <Typography variant="h4" className={classes.price}>{plan.price}</Typography>
                                    <Typography variant="body2">/month</Typography>
                                </div>
                                <div className={classes.features}>
                                    {Object.keys(FeatureHeaders).map((feature: string) => {
                                        const featureText = plan.featureText[feature as Feature];
                                        const isCityLimitationFeature = feature === Feature.CityLimitation;
                                        const isRemotePostsFeature = feature === Feature.RemotePosts;
                                        return (
                                            <div
                                                key={`${feature}-${plan.name}`}
                                                className={
                                                    feature === Feature.CityLimitation
                                                        ? classes.cityFeature
                                                        : feature === Feature.RemotePosts
                                                            ? classes.remotePostsFeature
                                                            : classes.feature
                                                }
                                            >
                                                {!isCityLimitationFeature && !isRemotePostsFeature
                                                    ? <CheckBold className={classes.check} />
                                                    : undefined
                                                }
                                                {isRemotePostsFeature
                                                    ? <div className={classes.textAndIconWrapper}>
                                                        <Typography color="textSecondary">
                                                            {featureText}
                                                        </Typography>
                                                        <div className={classes.infoIcon}>
                                                            <InformationTooltip title={REMOTE_OPPORTUNITY_TOOLTIP} />
                                                        </div>
                                                    </div>
                                                    : <Typography color="textSecondary">
                                                        {featureText}
                                                    </Typography>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                {
                                    props.hideSelectButton
                                        ? null
                                        : <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handlePlanSelected(plan.name)}
                                            type="submit"
                                            className={classes.selectButton}
                                        >
                                            Select
                                    </Button>
                                }
                            </Card>
                            <div className={clsx(classes.arrows, animationRun ? undefined : classes.animated)}>
                                <Avatar
                                    className={planIndex > 0
                                        ? classes.avatar
                                        : classes.hidden
                                    }
                                    onClick={previousPlanIndex}
                                >
                                    <ArrowLeft />
                                </Avatar>
                                <Avatar
                                    className={planIndex < plans.length - 1
                                        ? classes.avatar
                                        : classes.hidden
                                    }
                                    onClick={advancePlanIndex}
                                >
                                    <ArrowRight />
                                </Avatar>
                            </div>
                        </div>
                        : null
                )
            })}
        </div>
    );
});

export default SubscriptionPlanCards;