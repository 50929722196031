

import { makeStyles, Theme, createStyles, RadioGroup, FormControl, FormControlLabel, Radio, FormHelperText } from "@material-ui/core";
import { observer } from "mobx-react";
import { ChangeEvent, DetailedHTMLProps, HTMLAttributes } from "react";
import ElementDescription from "./ElementDescription";
import ElementTitle from "./ElementTitle";
import { FormInputItem } from "../../../../stores/models/FormInputItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemWithOptions: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(3)
            }
        }
    }),
);

interface MultipleChoiceItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    item: FormInputItem;
}

const MultipleChoiceItem = observer((props: MultipleChoiceItemProps) => {

    const classes = useStyles();

    const onChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
        props.item.setValue(value);
    }

    return (
        <div className={classes.itemWithOptions} {...props}>
            <ElementTitle item={props.item} />
            <ElementDescription item={props.item} />
            <FormControl component="fieldset" error={props.item.errors.value ? true : false}>
                <FormHelperText>{props.item.errors.value}</FormHelperText>
                <RadioGroup value={props.item.value} onChange={onChange}>
                    {(props.item.options as string[])?.map((option, index) => {
                        return (
                            <FormControlLabel
                                value={option}
                                control={<Radio color='primary' />}
                                label={option}
                                key={`item-${props.item.id}-index-${index}`}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    )
});

export default MultipleChoiceItem;