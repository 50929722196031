import React, { useContext } from "react";
import { observer } from "mobx-react";
import InactivationWarningDialog from "./InactivationWarningDialog";
import { RootContext } from "../../../../../stores";

const RemoteOppsLimitDialog = observer(() => {

    /********* React hook *********/

    const navigationStore = useContext(RootContext).navigationStore;
    
    /********* Render *********/

    return (
        <InactivationWarningDialog
            state={navigationStore.appwideDialogStates.remoteOppsLimitDialog}
            title={'Remote Opportunities Limit Exceeded'}
            details={`Please either upgrade your subscription to increase the number of remote ` +
                `opportunities you can have posted simultaneously or inactivate one of your other ` +
                `remote opportunities and then activate this one.`
            }
        />
    );
});

export default RemoteOppsLimitDialog;