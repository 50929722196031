import { Backdrop, CircularProgress, makeStyles, Theme, createStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingOverlay: {
            position: 'absolute',
            display: 'flex',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgb(255,255,255,0.7)',
            padding: theme.spacing(1),
            zIndex: 15
        }
    }),
);

const FormLoadingSpinner = () => {

    const classes = useStyles();

    return (
        <Backdrop open={true} className={classes.loadingOverlay}>
            <CircularProgress />
        </Backdrop>
    );
}

export default FormLoadingSpinner;