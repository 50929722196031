import { useState } from 'react';
import { RecordListStore } from '../data-access/stores/RecordListStore';
import { ObjectWithIdentifier } from '../../../../stores/models/NewOptionCollection';
import { SelectAllRecordsAction } from '../data-access/actions/SelectAllRecordsAction';

interface RecordListActionsProps<RecordType extends ObjectWithIdentifier<RecordType>> {
    store: RecordListStore<RecordType>;
}

export default function useRecordListActions<RecordType extends ObjectWithIdentifier<RecordType>>(props: RecordListActionsProps<RecordType>) {
    const [selectAll] = useState(new SelectAllRecordsAction(props.store));
    return { selectAll };
}