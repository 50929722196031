import React from "react";
import { observer } from "mobx-react";
import TextFieldWithCharacterLimit from "../../../../../Shared/TextFieldWithCharacterLimit";
import { VOLUNTEER_NAME_MAX_LENGTH } from "../../../../../../logic/ValidationChecks/FieldLengths";
import { StubOrganizationVolunteer } from "../../../../../../stores/models/StubOrganizationVolunteer";

interface NameFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    volunteer: StubOrganizationVolunteer;
    nameType: 'firstName' | 'lastName';
}

const NameField = observer((props: NameFieldProps) => {

    /********** Event Handlers *********/

    const onNameChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value !== undefined) {
            if (props.nameType === 'firstName') {
                props.volunteer.setFirstName(event.target.value);
            } else {
                props.volunteer.setLastName(event.target.value);
            }
        }
    }

    /********** Render *********/

    return (
        <TextFieldWithCharacterLimit
            characterLimit={VOLUNTEER_NAME_MAX_LENGTH}
            TextFieldProps={{
                onChange: (event) => onNameChanged(event),
                value: props.volunteer[props.nameType],
                error: props.volunteer.isFieldInvalid(props.nameType),
                helperText: props.volunteer.getErrorForField(props.nameType),
                variant: 'outlined',
            }}
        />
    )
});

export default NameField;